import React from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'
import paths from 'paths'
import { PerEntityModalProps, Site } from 'types'
import { INPUT_MAX_CHARACTER_LIMIT } from 'utils/constants'
import COUNTRIES from 'utils/countries'
import { TIMEZONE_SELECT_OPTIONS } from 'utils/timezones'

import EntityModal from './EntityModal'

/**
 * Renders the Create or Edit Site modal
 *
 *
 * @param entity - the modal name
 */

const AddOrEditSiteModal = <T extends boolean>({ isEditMode, onClose, entity: site }: PerEntityModalProps<T, Site>) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const defaultValues = getDefaultValues(site)
  const {
    formState: { isDirty, isValid, errors },
    getValues,
    control,
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleSubmit = async () => {
    const values = getValues()

    const country = COUNTRIES.find(country => country.code === values.country)
    const sitePayload = {
      name: values.name,
      timezone: values.timezone,
      address: {
        countryCode: values.country,
        countryName: country?.name || '',
        state: values.state,
      },
    }

    let res: SendToApiResponse<Site> | undefined = undefined

    if (isEditMode && site) {
      res = await service.updateSite(site?.id, sitePayload)
    }
    if (!isEditMode) {
      res = await service.createSite(sitePayload)
    }

    if (res?.type === 'success') {
      await query(getterKeys.sites(), service.getSites, { dispatch })
      if (!isEditMode) history.push(paths.inspect({ mode: 'site', params: { siteId: res.data.id } }))
    }

    onClose()
    return res
  }

  return (
    <EntityModal
      id="site-entity-modal"
      data-testid="add-edit-site-modal"
      entity="site"
      onSubmit={handleSubmit}
      onClose={onClose}
      isEditMode={isEditMode}
      disableSave={!isValid || !isDirty}
    >
      <>
        <Controller
          name="name"
          rules={{ required: 'Name is required' }}
          control={control}
          render={({ onChange, value }) => (
            <PrismInput
              label="site name (*)"
              data-testid="site-name-input"
              value={value}
              onChange={e => onChange(e.target.value)}
              errors={errors}
              type="text"
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
            />
          )}
        />
        <Controller
          name="timezone"
          rules={{ required: 'Timezone is required' }}
          control={control}
          render={({ ...props }) => (
            <Token label="Timezone (*)">
              <PrismSelect
                size="large"
                data-testid="timezone-select"
                showSearch
                optionFilterProp="title"
                options={TIMEZONE_SELECT_OPTIONS}
                {...props}
              />
            </Token>
          )}
        />
        <Controller
          name="country"
          rules={{ required: 'Country is required' }}
          control={control}
          render={({ ...props }) => (
            <Token label="Country (*)">
              <PrismSelect
                size="large"
                data-testid="country-select"
                data-test-attribute="country-select-options"
                showSearch
                filterOption={(input, option) => {
                  const title: string | undefined = option?.title
                  return title?.toLowerCase().includes(input.toLowerCase()) || false
                }}
                options={COUNTRIES.map(country => ({
                  value: country.code,
                  title: country.name,
                  dataTestId: `country-option-${country.code}`,
                  dataTest: 'country-options',
                }))}
                {...props}
              />
            </Token>
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ onChange, value }) => (
            <PrismInput
              label="state/region"
              data-testid="region-input"
              value={value}
              onChange={e => onChange(e.target.value)}
              errors={errors}
              type="text"
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
            />
          )}
        />
      </>
    </EntityModal>
  )
}

const getDefaultValues = (site: Site | undefined) => {
  if (!site) return
  return {
    ...site,
    country: site.address.countryCode,
    state: site.address.state,
  }
}

export default AddOrEditSiteModal
