import React, { useEffect, useMemo, useRef } from 'react'

import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { FixedSizeGrid } from 'react-window'
import { GridElementScrollerChildrenProps, ReactWindowElementScroller } from 'react-window-element-scroller'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { dismiss, warning } from 'components/PrismMessage/PrismMessage'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { OfflineTag } from 'components/Tag/Tag'
import VirtualizedCarousel from 'components/VirtualizedCarousel/VirtualizedCarousel'
import { useConnectionStatus, useData, useQueryParams } from 'hooks'
import { useIsCarouselOnScreen } from 'pages/RoutineOverview/LabelingScreen/LabelingGallery/GroupCarousel'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { Threshold, Tool, ToolLabel, TrainingResult, TrainingResultFlat } from 'types'
import {
  calculateOpacityByIndex,
  getDisplaySeverity,
  getLabelName,
  getterAddPage,
  isThresholdFromGARTool,
  sleep,
  titleCase,
} from 'utils'

import { CardItem } from './CardItem'
import ExpandedLabelGrid from './ExpandedLabelGrid'
import FullScreenTrainingResult from './FullScreenTrainingResult'
import { ThresholdByRoutineParentId } from './TrainingReport'
import Styles from './TrainingReport.module.scss'

const TRAINING_RESULT_FULL_LIST_SIZE = 100

type Props = {
  rowKey: string
  carouselKey?: string
  label: ToolLabel
  onShowAll: ({ labelId, componentId }: { labelId: string; componentId: string }) => void
  outerContainerRef?: React.RefObject<HTMLDivElement>
  expanded?: boolean
  threshold: Threshold | undefined
  tool?: Tool
  componentId: string
  loading?: boolean
  trainingResults?: (TrainingResultFlat & {
    calculatedLabelId: string | undefined
    isAboveThreshold: boolean | undefined
    predictedLabel: ToolLabel | undefined
  })[]
  modalLoaded: boolean
  toolLabels?: ToolLabel[]
  insightsEnabled?: boolean
  thresholdByRoutine?: ThresholdByRoutineParentId
  className?: string
  isRowExpanded: boolean
}

/**
 * Renders a label box which holds all individual training result items and shows the carousel
 * with pagination upon clicking on the arrows.
 *
 * @param label - Name of the label to show in the title
 * @param tool - Training tool
 * @param selectedRoutineId - In case we want to filter results by routine ID, use this prop
 * @param onShowAll - Handler for when user clicks show all button
 * @param threshold -Threshold for the current tool. Will affect if the card is marked as correct
 * @param expanded - Whether the current carousel is expanded
 * @param outerContainerRef - Reference to div container
 * @param confusionMatrixError - Whether there was an issue calculating the confusion matrix
 * @param loading - Whether the data to be used is still loading
 * @param trainingResults - List of filtered training results to show in report
 * @param toolLabels - Labels to use in the current tool
 * @param correct - Number of correct predictions
 * @param imagesUsed - Number of incorrect predictions
 * @param modalLoaded - Indicates wheter the parent modal is already open, we use this value
 * to know when to render the thumbnails.
 * @param thresholdByRoutine - Object including current threshold by routine,
 * used to calculate predictions for overriden threholds
 *
 * Business Rules: The training result items are fetched for each label. They are paginated
 * and are ordered by whether predicted result matches labeled result, in this sense failed
 * predictions will be shown first and correct predictions second.
 */

const LabelContainer = ({
  carouselKey,
  onShowAll,
  expanded,
  outerContainerRef,
  label,
  threshold,
  loading,
  tool,
  componentId,
  trainingResults,
  modalLoaded,
  toolLabels,
  thresholdByRoutine,
  insightsEnabled,
  className = '',
  isRowExpanded,
  rowKey,
}: Props) => {
  const dispatch = useDispatch()
  const [params] = useQueryParams<'training_result_id'>()
  const { training_result_id } = params

  // For carousels with arrows and show all button at the bottom

  const loadingResultsRef = useRef<boolean>(false)
  const gridRef = useRef<FixedSizeGrid>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const connectionStatus = useConnectionStatus()

  const { ref: carouselRef, hasBeenVisible } = useIsCarouselOnScreen()

  useEffect(() => {
    // We reset the branch so that expanded training results are refeched when we open the modal
    if (tool?.id)
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.trainingResultsExpandedByToolComponentAndLabelId(tool.id, componentId, label.id),
          updater: () => null,
        }),
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool?.id])

  let predictionOrdering: 'asc' | 'desc' = 'asc'
  let orderByPredictionEqCalculated = false

  // Warning: This sorting code is VERY fragile, as it depends on the calculations used by ML to determine the prediction score.
  // Sometimes scores are inverted, sometimes they aren't, don't modify this code unless all cases are properly considered.
  if (
    ((tool?.specification_name === 'graded-anomaly' || tool?.specification_name === 'deep-svdd') &&
      (label?.severity === 'good' || label?.severity === 'minor')) ||
    (tool?.specification_name === 'classifier' && label?.severity !== 'good')
  ) {
    predictionOrdering = 'desc'
  }

  if (tool?.specification_name === 'match-classifier') {
    orderByPredictionEqCalculated = true
  }

  // The graded anomaly minor label is a special case. In regular anomaly, defect, and match tools, the
  // carousels are always sorted in the same order regardless of the threshold. Graded anomaly minor
  // anomaly carousels must be sorted by: Above threshold, below threshold, within threshold, and secondly
  // by score.
  let orderByMinorAnomaly = false
  if (tool?.specification_name === 'graded-anomaly' && label?.severity === 'minor') {
    orderByMinorAnomaly = true
  }

  const trainingResultsFlat = useMemo(
    () =>
      trainingResults?.sort((trainingResultA, trainingResultB) => {
        if (orderByPredictionEqCalculated) {
          const correctPredictionA = trainingResultA.calculatedLabelId === trainingResultA.predictedLabel?.id
          const correctPredictionB = trainingResultB.calculatedLabelId === trainingResultB.predictedLabel?.id

          if (correctPredictionA && !correctPredictionB) return 1
          if (!correctPredictionA && correctPredictionB) return -1
          return (trainingResultA.prediction_score || 0) - (trainingResultB.prediction_score || 0)
        }

        if (orderByMinorAnomaly) {
          const withinRangeA =
            threshold &&
            isThresholdFromGARTool(threshold) &&
            trainingResultA.prediction_score &&
            trainingResultA.prediction_score < threshold.upperThreshold &&
            trainingResultA.prediction_score >= threshold.lowerThreshold
          const withinRangeB =
            threshold &&
            isThresholdFromGARTool(threshold) &&
            trainingResultB.prediction_score &&
            trainingResultB.prediction_score < threshold.upperThreshold &&
            trainingResultB.prediction_score >= threshold.lowerThreshold

          if (!withinRangeB && withinRangeA) return 1
          if (!withinRangeA && withinRangeB) return -1
        }

        if (
          orderByMinorAnomaly &&
          !isEqual(trainingResultB.calculated_labels, trainingResultB.prediction_labels) &&
          isEqual(trainingResultA.calculated_labels, trainingResultA.prediction_labels)
        ) {
          return -1
        }

        if (predictionOrdering === 'asc')
          return (trainingResultA.prediction_score || 0) - (trainingResultB.prediction_score || 0)
        else return (trainingResultB.prediction_score || 0) - (trainingResultA.prediction_score || 0)
      }),
    [trainingResults, orderByPredictionEqCalculated, orderByMinorAnomaly, predictionOrdering, threshold],
  )

  // Tool Training Results contain incomplete data, therefore we must fetch a smaller list of
  // results with aoi, item, tool result, etc.
  const trainingResultsExpanded = useData(
    tool ? getterKeys.trainingResultsExpandedByToolComponentAndLabelId(tool.id, componentId, label.id) : undefined,
  )?.results

  // Converting into a dict makes searching much easier and faster than using an array
  const trainingResultsExpandedMapped: { [id: string]: TrainingResult } | undefined = useMemo(() => {
    return trainingResultsExpanded?.reduce((dict, currentItem) => ({ ...dict, [currentItem.id]: currentItem }), {})
  }, [trainingResultsExpanded])

  const handleAfterUpdateTrainingResults = async (receivedTrainingResults: TrainingResultFlat[]) => {
    if (
      loadingResultsRef.current ||
      !tool ||
      !hasBeenVisible ||
      receivedTrainingResults.every(trainingResult => trainingResultsExpandedMapped?.[trainingResult.id])
    )
      return

    loadingResultsRef.current = true

    // If we get here, it means we must fetch a new page of results.
    // So we figure out which page we want to fetch given the current results we're showing
    const idxPosition = trainingResultsFlat?.findIndex(
      tr => tr.id === receivedTrainingResults[receivedTrainingResults.length - 1]?.id,
    )
    const offsetToUse = Math.floor((idxPosition || 0) / TRAINING_RESULT_FULL_LIST_SIZE)

    const nextTrainingResultIds = trainingResultsFlat
      ?.slice(TRAINING_RESULT_FULL_LIST_SIZE * offsetToUse, TRAINING_RESULT_FULL_LIST_SIZE * (offsetToUse + 1))
      .map(tr => tr.id)
    if (!nextTrainingResultIds?.length) return

    const res = await service.getToolTrainingResultsByIds(nextTrainingResultIds)

    if (res.type !== 'success') return

    dispatch(
      Actions.getterUpdate({
        key: getterKeys.trainingResultsExpandedByToolComponentAndLabelId(tool.id, componentId, label.id),
        updater: prevRes => getterAddPage(prevRes, res.data),
      }),
    )

    loadingResultsRef.current = false
  }

  // If we're missing scores for ALL training results, warn user that moving slider won't change live accuracy, etc
  useEffect(() => {
    if (
      trainingResultsFlat?.length &&
      trainingResultsFlat.every(tr => tr.prediction_score === null || tr.prediction_score === undefined)
    ) {
      warning({
        title: 'Retrain tool to see how threshold impacts accuracy',
        duration: 15000,
        id: 'retrain-warning-message',
      })
    }

    return () => {
      dismiss('retrain-warning-message')
    }
  }, [trainingResultsFlat])

  const renderCarouselTitle = () => {
    if (!label) return '--'
    return (
      <PrismResultButton severity={getDisplaySeverity(label)} value={getLabelName(label)} type="noFill" size="small" />
    )
  }

  const getEmptyState = () => {
    if (!tool) {
      return Array(3)
        .fill(undefined)
        .map((_, i) => <CardItem threshold={threshold} key={i} modalLoaded={modalLoaded} />)
    }
    if (trainingResultsFlat && trainingResultsFlat.length === 0) {
      return (
        <div className={Styles.noPredictedImagesContainer}>
          No images predicted as{' '}
          {titleCase(label?.severity === 'neutral' ? getLabelName(label) : label?.severity || '')}
        </div>
      )
    }
    return null
  }

  const ShowAllButton = () => {
    return (
      <Button
        onClick={async () => {
          onShowAll({ labelId: label.id, componentId })
          // wait just a bit for grid to be expanded
          await sleep(0)
          const tableRow = document.getElementById(rowKey)
          tableRow?.scrollIntoView(true)
        }}
        type="tertiary"
        size="small"
        className={Styles.showAllButton}
      >
        Show All
      </Button>
    )
  }

  return (
    <div ref={carouselRef} className={className}>
      {tool && !trainingResultsFlat && connectionStatus !== 'offline' && (
        <PrismLoader className={Styles.labelSpinner} />
      )}

      {connectionStatus === 'offline' && (
        <div className={`${Styles.offlineCarouselContainer} ${Shared.verticalChildrenGap8}`}>
          <div className={Styles.offlineCarouselHeaderContainer}>
            <div className={Styles.cardBoxHeaderLeft}>Labeled: {renderCarouselTitle()}</div>

            <OfflineTag />
          </div>

          <div className={Styles.offlineCardList}>
            {Array(4)
              .fill(undefined)
              .map((_, i) => (
                <OfflineCardItem key={i} index={i} />
              ))}
          </div>
        </div>
      )}

      {connectionStatus !== 'offline' && isRowExpanded && !expanded && trainingResultsFlat && (
        <VirtualizedCarousel
          key={carouselKey}
          data-testid={`training-samples-carousel-${label.value}-${label.severity}`}
          fullCards
          cardGap={16}
          carouselWrapperClassName={`${Styles.carouselWrapper} ${
            trainingResultsFlat.length ? Styles.transitionOpacity : ''
          }`}
          listContainerClassName={Styles.cardListCarouselContainer}
          loading={loading || !hasBeenVisible}
          title={<div className={Styles.cardBoxHeaderLeft}>Labeled: {renderCarouselTitle()}</div>}
          onAfterUpdate={handleAfterUpdateTrainingResults}
          cards={trainingResultsFlat}
          renderer={(trainingResult, firstCardRef) => {
            if (!hasBeenVisible) return <></>
            const trainingResultExpanded = trainingResultsExpandedMapped?.[trainingResult.id]

            return (
              <CardItem
                type="ghost4"
                data-test="training-sample"
                key={trainingResult.id}
                threshold={threshold}
                trainingResult={trainingResult}
                toolResult={trainingResultExpanded?.tool_result}
                insightsEnabled={insightsEnabled}
                modalLoaded={modalLoaded}
                toolLabels={toolLabels}
                cardRef={firstCardRef}
                tool={tool}
                thresholdByRoutine={thresholdByRoutine}
              />
            )
          }}
          emptyState={getEmptyState()}
          carouselCustomAction={
            !!trainingResultsFlat.length && trainingResultsFlat.length > 5 ? <ShowAllButton /> : <></>
          }
          hideActions={expanded}
          loaderClassName={Styles.carouselLoaderWrapper}
        />
      )}

      {connectionStatus !== 'offline' && isRowExpanded && expanded && outerContainerRef && (
        <div className={Styles.expandedItemsWrapper}>
          <ReactWindowElementScroller
            type="grid"
            scrollerElementRef={outerContainerRef}
            gridRef={gridRef}
            outerRef={outerRef}
            childrenStyle={{ height: 'auto' }}
          >
            {({ style, onScroll }: GridElementScrollerChildrenProps) => (
              <ExpandedLabelGrid
                gridRef={gridRef}
                outerRef={outerRef}
                galleryBodyRef={outerContainerRef}
                trainingResultsFlat={trainingResultsFlat}
                threshold={threshold}
                onAfterUpdate={handleAfterUpdateTrainingResults}
                modalLoaded={modalLoaded}
                toolLabels={toolLabels}
                trainingResultsExpanded={trainingResultsExpandedMapped}
                style={style}
                onScroll={onScroll}
                tool={tool}
                thresholdByRoutine={thresholdByRoutine}
              />
            )}
          </ReactWindowElementScroller>
        </div>
      )}

      {training_result_id && trainingResultsExpandedMapped?.[training_result_id] && (
        <FullScreenTrainingResult
          trainingResultById={trainingResultsExpandedMapped}
          trainingResultsFlat={trainingResultsFlat}
          onFetchMoreTrainingResults={handleAfterUpdateTrainingResults}
          threshold={threshold}
          thresholdByRoutine={thresholdByRoutine}
          insightsEnabled={insightsEnabled}
          tool={tool}
        />
      )}
    </div>
  )
}

const OfflineCardItem = ({ index }: { index: number }) => (
  <div style={{ opacity: calculateOpacityByIndex(index) }} className={Styles.cardItem}>
    <PrismElementaryCube className={Styles.emptyElementaryCube} />
  </div>
)

export const MemoLabelContainer = React.memo(LabelContainer)
MemoLabelContainer.displayName = 'LabelContainer'
