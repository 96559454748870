import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Reset Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A409
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismResetIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M12 4.94117C7.82718 4.94117 4.44444 8.31219 4.44444 12.4705C4.44444 14.7802 5.61358 16.9705 7.25745 18.3524L7.2279 16.6653C7.22335 16.4054 7.43105 16.1911 7.69181 16.1865C7.95257 16.182 8.16765 16.389 8.1722 16.6488L8.22165 19.4719C8.2262 19.7318 8.0185 19.9461 7.75774 19.9506L4.92484 19.9999C4.66407 20.0045 4.449 19.7975 4.44444 19.5376C4.43989 19.2778 4.64759 19.0634 4.90835 19.0589L6.60138 19.0294C4.75388 17.4764 3.5 15.0692 3.5 12.4705C3.5 7.79239 7.30558 4 12 4C16.6944 4 20.5 7.79239 20.5 12.4705C20.5 14.9005 19.4727 17.0918 17.8287 18.6359C17.6395 18.8137 17.342 18.7876 17.1743 18.5894C17.0057 18.3903 17.0321 18.0931 17.221 17.9131C18.6602 16.5417 19.5556 14.6105 19.5556 12.4705C19.5556 8.31219 16.1728 4.94117 12 4.94117Z"
        fill="currentColor"
      />
    </svg>
  )
}
