import React, { useCallback, useEffect, useMemo } from 'react'

import { useHistory } from 'react-router-dom'

import { getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import Layout from 'components/Layout/Layout'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useGoBack, usePrevious, useQueryParams } from 'hooks'
import paths from 'paths'
import { RecipeRoutine, RecipeRoutineExpanded } from 'types'
import { getAoisAndToolsFromRoutine } from 'utils'

import OverviewHeader from '../OverviewHeader'
import RecipeViews from '../RecipeViews'
import Styles from './Configure.module.scss'
import { AlignmentToolSettings } from './Tools/Alignment/AlignmentTool'
import { AnomalyToolSettings } from './Tools/AnomalyToolSettings'
import { BarcodeToolSettings } from './Tools/BarcodeToolSettings'
import { ColorToolSettings } from './Tools/ColorToolSettings'
import { GradedAnomalyToolSettings } from './Tools/GradedAnomalyToolSettings'
import { MatchToolSettings } from './Tools/Match/MatchToolSettings'
import MeasureToolSettings from './Tools/MeasureToolSettings'
import { OcrToolSettings } from './Tools/OcrToolSettings'
import { RandomToolSettings } from './Tools/RandomToolSettings'

interface Props {
  recipeId: string
  toolId: string
}

/**
 * Renders the screen for editing a tool. Thin wrapper around various tool forms
 * (ToolSettings) components.
 *
 * @param recipeId - Recipe id we are working on
 * @param toolId - Tool id
 */
const ToolScreen = ({ recipeId, toolId }: Props) => {
  const history = useHistory()
  const [params] = useQueryParams()
  const goBack = useGoBack(paths.inspect({ mode: 'product' }))

  const recipe = useQuery(getterKeys.recipe(recipeId), () => service.getRecipe(recipeId), { noRefetch: true }).data
    ?.data
  const recipeParent = useQuery(
    recipe ? getterKeys.recipeParent(recipe.parent_id) : undefined,
    recipe ? () => service.getRecipeParent(recipe.parent_id) : undefined,
    { noRefetch: true },
  ).data?.data
  const routineParentId =
    params.routineParentId ||
    recipe?.recipe_routines.find(recipeRoutine =>
      recipeRoutine.routine.aois.find(aoi => aoi.tools.find(tool => tool.id === toolId)),
    )?.routine.parent.id ||
    ''

  const routineId = recipe?.recipe_routines.find(recipe_routine => recipe_routine.routine.parent.id === routineParentId)
    ?.routine.id

  const routine = useQuery(routineId ? getterKeys.routine(routineId) : undefined, () =>
    service.getRoutine(routineId || ''),
  ).data?.data
  const tool = useMemo(
    () => getAoisAndToolsFromRoutine(routine).tools.find(tool => tool.id === toolId),
    [routine, toolId],
  )

  const currentView: RecipeRoutineExpanded | undefined = useMemo(() => {
    if (!recipe) return
    const foundView = recipe.recipe_routines.find(view => view.routine.id === routine?.id)
    if (!foundView) return

    return { ...foundView, recipe: recipe }
  }, [recipe, routine?.id])

  const recipeVersion = params.recipeVersion
  const readOnly = !!recipeVersion

  // We need to redirect the user to the configure screen if the version drawer changes in any way.
  const previousRecipeVersion = usePrevious(recipeVersion)

  useEffect(() => {
    if (previousRecipeVersion && recipeVersion && previousRecipeVersion !== recipeVersion && routineParentId && recipe)
      history.replace(
        paths.settingsRecipe(recipe.parent_id, 'configure', { routineParentId: routineParentId, recipeVersion }),
      )
  }, [routineParentId, recipe, history, previousRecipeVersion, recipeVersion])

  const updateVersionDrawer = useCallback(
    (recipeVersion?: string) => {
      if (!recipe) return
      const updatedParams = { ...params, routineParentId, recipeVersion }
      history.push(paths.settingsRecipe(recipe.parent_id, 'configure', updatedParams))
    },
    [history, params, recipe, routineParentId],
  )

  const handleExit = useCallback(() => {
    if (!routineParentId || !recipe) goBack()
    else
      history.replace(
        paths.settingsRecipe(recipe.parent_id, 'configure', { ...params, routineParentId: routineParentId }),
      )
  }, [routineParentId, recipe, goBack, history, params])

  const renderToolForm = () => {
    if (!tool || !routine || !recipe || !currentView) return <PrismLoader fullScreen />

    if (tool.specification_name === 'alignment') {
      return (
        <AlignmentToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
      )
    }

    if (tool.specification_name === 'random') {
      return (
        <RandomToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
      )
    }

    if (tool.specification_name === 'deep-svdd' || tool.specification_name === 'classifier') {
      return (
        <AnomalyToolSettings
          routine={routine}
          recipe={recipe}
          view={currentView}
          tool={tool}
          onExit={handleExit}
          readOnly={readOnly}
        />
      )
    }

    if (tool.specification_name === 'graded-anomaly') {
      return (
        <GradedAnomalyToolSettings
          routine={routine}
          recipe={recipe}
          view={currentView}
          tool={tool}
          onExit={handleExit}
          readOnly={readOnly}
        />
      )
    }

    if (tool.specification_name === 'match-classifier') {
      return (
        <MatchToolSettings
          routine={routine}
          recipe={recipe}
          view={currentView}
          tool={tool}
          onExit={handleExit}
          readOnly={readOnly}
        />
      )
    }

    if (tool.specification_name === 'detect-barcode') {
      return (
        <BarcodeToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
      )
    }

    if (tool.specification_name === 'ocr') {
      return <OcrToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
    }

    if (tool.specification_name === 'color-check') {
      return <ColorToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
    }

    if (tool.specification_name === 'measurement') {
      return (
        <MeasureToolSettings routine={routine} recipe={recipe} tool={tool} onExit={handleExit} readOnly={readOnly} />
      )
    }

    return (
      <div className={Styles.unsupportedTool}>
        <div className={Styles.unsupportedToolDescription}>This tool is not yet supported</div>
        <Button type="tertiary" onClick={handleExit}>
          Go Back
        </Button>
      </div>
    )
  }

  /** RENDER **/

  return (
    <Layout
      titleClassName={`${Styles.recipeHeader} ${readOnly ? Styles.collapseHeader : ''}`}
      mainClassName={`${Styles.recipeBody} ${readOnly ? Styles.collapse : Styles.expand}`}
      title={
        <OverviewHeader
          routineParentId={routine?.parent.id}
          recipe={recipe}
          recipeParent={recipeParent}
          recipeVersion={recipeVersion}
          updateVersionDrawer={updateVersionDrawer}
        />
      }
    >
      {recipe && recipe.recipe_routines.length > 1 && (
        <RecipeViews
          recipe={recipe}
          routineParentId={routineParentId}
          onClick={(recipeRoutine: RecipeRoutine) =>
            history.push(
              paths.settingsRecipe(recipe.parent_id, 'configure', {
                ...params,
                routineParentId: recipeRoutine.routine.parent.id,
              }),
            )
          }
        />
      )}
      {renderToolForm()}
    </Layout>
  )
}

export default ToolScreen
