import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Copy Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5956%3A11876
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismCopyIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M4.00016 3.33337C3.63197 3.33337 3.3335 3.63185 3.3335 4.00004V10.3334C3.3335 10.5175 3.48273 10.6667 3.66683 10.6667C3.85092 10.6667 4.00016 10.5175 4.00016 10.3334V4.00004H9.66683C9.85092 4.00004 10.0002 3.8508 10.0002 3.66671C10.0002 3.48261 9.85092 3.33337 9.66683 3.33337H4.00016Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3335 6.00004C5.3335 5.63185 5.63197 5.33337 6.00016 5.33337H12.0002C12.3684 5.33337 12.6668 5.63185 12.6668 6.00004V12.6667C12.6668 13.0349 12.3684 13.3334 12.0002 13.3334H6.00016C5.63197 13.3334 5.3335 13.0349 5.3335 12.6667V6.00004ZM6.00016 6.00004H12.0002V12.6667H6.00016L6.00016 6.00004Z"
        fill="currentColor"
      />
    </svg>
  )
}
