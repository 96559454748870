import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { isNumber } from 'lodash'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import PassCriteriaContainer from 'components/PassCriteria/PassCriteriaContainer'
import { PrismNavArrowIcon, TOOL_ICONS } from 'components/prismIcons'
import PrismLabelButton from 'components/PrismLabelButton/PrismLabelButton'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { error } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import SerialList from 'components/SerialList/SerialList'
import { Timeline, TimelineItem } from 'components/Timeline/Timeline'
import { useAllToolLabels, useToolLabels } from 'hooks'
import {
  MeasureToolSize,
  NonTrainableToolLabel,
  Outcome,
  PreprocessingOptions,
  Tool,
  ToolLabel,
  ToolResultEmptyOutcome,
  ToolResultOutcome,
  ToolResultsBatchLabel,
  ToolSpecificationName,
  UserLabelSet,
} from 'types'
import {
  calculatePercentage,
  commaSeparatedNumbers,
  convertPreprocessingOptionsToAlt,
  evaluateOutcomes,
  extractDerivativeLabels,
  filterOutDerivativeLabels,
  findSingleToolLabelFromPartialData,
  getDisplaySeverity,
  getDisplayThreshold,
  getLabelName,
  getLabels,
  getPredictionText,
  getToolLabelHotkey,
  getToolResultLabels,
  getToolResultValueSeverityAndLabels,
  handleLabelingKeydown,
  isToolUntrained,
  parsePredictionMetadata,
  renderToolName,
  sortByLabelKind,
  sortByValueAndSeverity,
  titleCase,
  updateToolResultBatchLabels,
  wasToolResultMuted,
} from 'utils'
import { SEVERITY_BY_OUTCOME, TOOLS_DESCRIPTIONS, TRAINABLE_TOOL_SPECIFICATION_NAMES } from 'utils/constants'
import { UNTRAINED_LABEL } from 'utils/labels'

import Styles from './ToolLabeling.module.scss'
import { LabelList } from './ToolListPanel'

const toolsWithPassCriteriaDetails: Partial<ToolSpecificationName[]> = [
  'alignment',
  'color-check',
  'ocr',
  'detect-barcode',
  'graded-anomaly',
  'match-classifier',
  'deep-svdd',
  'classifier',
  'measurement',
]

const nonTrainableLabels: NonTrainableToolLabel[] = [
  { id: 'pass', outcome: 'pass', displayName: 'pass', buttonIcon: 'pass', buttonTagType: 'pass' },
  { id: 'fail', outcome: 'fail', displayName: 'fail', buttonIcon: 'fail', buttonTagType: 'fail' },
]

/**
 * Renders a Timeline with the provided tool results and pass criteria,
 * also allowing the user to confirm or change the original result.
 *
 * @param toolResult, - toolResult element to change outcome
 * @param canEditOutcome - wether an user can edit the tool outcome
 * @param onRefresh - callback to refresh the item being edited
 * @param itemId - Item Id that's being edited
 */
export const ToolLabeling = ({
  toolResult,
  canEditOutcome,
  itemId,
  onRefresh,
  onBackItem,
}: {
  toolResult?: ToolResultEmptyOutcome
  canEditOutcome: boolean
  itemId?: string
  onRefresh?: () => any
  onBackItem?: () => void
}) => {
  const dispatch = useDispatch()
  const [changeLabel, setChangeLabel] = useState(false)
  const { allToolLabels, defaultLabels } = useAllToolLabels()

  const updatingLabelRef = useRef(false)

  const fetchedToolResultRes = useQuery(
    toolResult ? getterKeys.toolResult(toolResult.id) : undefined,
    toolResult ? () => service.getToolResult(toolResult.id) : undefined,
  )

  const currentToolResult = useMemo(() => {
    if (!fetchedToolResultRes || fetchedToolResultRes.data?.type !== 'success') return toolResult
    return fetchedToolResultRes.data?.data
  }, [fetchedToolResultRes, toolResult])

  const currentTool = currentToolResult?.tool

  const toolLabels = useToolLabels(currentTool ? [currentTool] : undefined)

  const predictedLabels = allToolLabels
    ?.filter(lbl => currentToolResult?.prediction_labels.includes(lbl.id))
    .sort(sortByLabelKind)
  const outcome =
    currentToolResult?.calculated_outcome !== 'empty'
      ? evaluateOutcomes([currentToolResult?.calculated_outcome || 'unknown'])
      : 'unknown' // This shouldn't be possible
  const predictionOutcome =
    currentToolResult?.prediction_outcome !== 'empty'
      ? evaluateOutcomes([currentToolResult?.prediction_outcome || 'unknown'])
      : undefined // this is possible

  const muted = toolResult ? wasToolResultMuted(toolResult) : false
  const errorCode = parsePredictionMetadata(
    currentToolResult?.prediction_metadata?.vision_processing_err_code,
    'number',
  )

  const labeledOutcome = currentToolResult?.active_user_label_set?.outcome

  useEffect(() => {
    setChangeLabel(false)
  }, [itemId])

  const labelingStatus = currentToolResult?.active_user_label_set
    ? labeledOutcome !== predictionOutcome
      ? 'relabeled'
      : 'confirmed'
    : 'default'

  const getResultCopy = (
    outcome: Outcome | undefined,
    muted: boolean,
    toolSpecificationName: ToolSpecificationName,
    errorCode?: number,
    hasErrorOutcome?: boolean,
  ) => {
    if (muted) return 'Tool muted, outcome of '
    if (errorCode === 2 && hasErrorOutcome) {
      return 'Tool failed. Outcome of'
    }

    if (toolSpecificationName === 'match-classifier' && !toolResult?.inference_user_args.expected_classes?.length)
      return 'Pass Criteria not set, outcome of '

    if (outcome === 'unknown') {
      return 'Outcome of'
    }

    return `Pass Criteria ${outcome === 'fail' ? 'not' : ''} met, outcome of `
  }

  const getDefaultCopy = (
    toolSpecificationName: ToolSpecificationName,
    outcome?: Outcome,
    errorCode?: number,
    muted?: boolean,
    hasErrorOutcome?: boolean,
  ) => {
    if (!outcome) return null

    const prefix = getResultCopy(outcome, !!muted, toolSpecificationName, errorCode, hasErrorOutcome)

    return (
      <div className={Styles.timelinePrediction} data-test={muted ? 'tool-labeling-muted-status' : ''}>
        {prefix}

        <PrismResultButton
          severity={outcome}
          value={outcome}
          className={Styles.resultTimelineFont}
          size="small"
          type="noFill"
        />
      </div>
    )
  }

  const getInitialTimelineItem = () => {
    if (!currentTool) return null
    const specificationName = currentTool.specification_name
    if (!toolsWithPassCriteriaDetails.includes(specificationName) || !currentToolResult) return null

    let text: React.ReactNode = ''
    let trailingText: React.ReactNode = ''
    let verb = undefined

    text = getPredictionText(currentToolResult) || '-- '

    if (isToolUntrained(currentTool))
      return (
        <TimelineItem
          className={Styles.timelineItem}
          status="default"
          date={moment(currentToolResult.created_at)}
          description={
            <TimeLinePrediction
              verb="No prediction, tool"
              text={
                // predictedLabels will be just an Untrained label in this case
                <LabelList
                  labels={filterOutDerivativeLabels(predictedLabels || [])}
                  labelClassName={Styles.resultTimelineFont}
                  tool={currentTool}
                />
              }
            />
          }
        />
      )

    if (
      specificationName === 'match-classifier' ||
      specificationName === 'graded-anomaly' ||
      specificationName === 'deep-svdd' ||
      specificationName === 'classifier'
    ) {
      text = predictedLabels ? (
        <LabelList
          labels={filterOutDerivativeLabels(predictedLabels)}
          labelClassName={Styles.resultTimelineFont}
          tool={currentTool}
        />
      ) : (
        ''
      )

      trailingText = (
        <span className={Styles.timelineDescription}>
          with a score of {getDisplayThreshold(currentToolResult.prediction_score, specificationName)}
        </span>
      )
    }

    if (specificationName === 'measurement') {
      text = '--'
      const width = parsePredictionMetadata(currentToolResult.prediction_metadata?.bbox_width, 'number')
      const height = parsePredictionMetadata(currentToolResult.prediction_metadata?.bbox_height, 'number')
      if (width !== undefined && height !== undefined) {
        verb = 'Predicted dimensions of '
        text =
          `${(width * 100).toFixed(1)}% width of search area and ${(height * 100).toFixed(1)}% height of search area` ||
          ''
      }
    }
    trailingText = (
      <>
        {trailingText}
        {', '}
        at time of inspection.
      </>
    )

    const predictedLabelFromSet = allToolLabels?.find(lbl => currentToolResult.prediction_labels.includes(lbl.id))

    let status = predictedLabelFromSet
      ? getStatusFromLabel(predictedLabelFromSet)
      : currentToolResult.prediction_outcome

    if (status === 'error' || status === 'needs-data' || status === 'empty') status = 'unknown'
    return (
      <TimelineItem
        className={Styles.timelineItem}
        status={status}
        date={moment(currentToolResult.created_at)}
        description={<TimeLinePrediction verb={verb} text={text} trailingText={trailingText} />}
      />
    )
  }

  const getPassCriteria = () => {
    if (!currentTool || !toolsWithPassCriteriaDetails.includes(currentTool.specification_name) || !currentToolResult)
      return null
    const conditions: { condition?: string; passValue: React.ReactNode; trailingText?: string }[] = []

    if (!currentToolResult.item) {
      return (
        <PassCriteria
          title="Training Data"
          fallbackText="This tool result was uploaded for the purposes of model training and is not related to an inspected item."
        />
      )
    }

    if (currentTool.specification_name === 'alignment') {
      conditions.push({
        condition: 'image aligned correctly',
        passValue: null,
      })
    }

    if (currentTool.specification_name === 'color-check') {
      const args = convertPreprocessingOptionsToAlt(currentTool.inference_args as PreprocessingOptions | undefined)
      if (args) {
        conditions.push({
          condition: 'between',
          passValue: `${args.pixel_count_min ? commaSeparatedNumbers(args.pixel_count_min) : 0} and ${
            args.pixel_count_max ? commaSeparatedNumbers(args.pixel_count_max) : 0
          } pixels `,
          trailingText: `matched target color, with hue of ${args.hsv_min_h || 0}-${
            args.hsv_max_h || 0
          }, saturation of ${args.hsv_min_s || 0} - ${args.hsv_max_s || 0} and value of ${args.hsv_min_v || 0}-${
            args.hsv_max_v || 0
          }`,
        })
      }
    }

    if (currentTool.specification_name === 'measurement') {
      const {
        target_max_height = 0,
        target_max_width = 0,
        target_min_height = 0,
        target_min_width = 0,
      } = (currentTool.inference_args as MeasureToolSize | undefined) || {}
      const { width: aoiWidth = 1, height: aoiHeight = 1 } = currentToolResult.aoi || {}
      conditions.push(
        ...[
          {
            condition: 'dimensions were within',
            passValue: `${calculatePercentage(target_min_width, aoiWidth).toFixed(1)}%-${calculatePercentage(
              target_max_width,
              aoiWidth,
            ).toFixed(1)}% `,
            trailingText: 'width of search area',
          },
          {
            passValue: `${calculatePercentage(target_min_height, aoiHeight).toFixed(1)}%-${calculatePercentage(
              target_max_height,
              aoiHeight,
            ).toFixed(1)}% `,
            trailingText: 'height of the search area',
          },
        ],
      )
    }
    if (currentTool?.specification_name === 'ocr') {
      conditions.push({
        condition: 'text matched',
        passValue: `"${currentToolResult.inference_user_args.regex || ''}"`,
      })
    }

    if (currentTool?.specification_name === 'detect-barcode') {
      conditions.push({
        condition: 'barcode matched',
        passValue: `"${currentToolResult.inference_user_args.regex || ''}"`,
      })
    }

    if (currentTool.state === 'uninitialized') {
      return <PassCriteria fallbackText="The tool was untrained and did not have Pass criteria." />
    }

    if (currentTool?.specification_name === 'graded-anomaly') {
      return (
        <PassCriteria
          fallbackText={
            <div className={Styles.passCriteriaDescriptionResult}>
              Set to{' '}
              <PrismResultButton
                value="pass"
                severity="pass"
                type="pureWhite"
                size="small"
                className={Styles.prismResultPass}
              />{' '}
              if prediction was
              {currentToolResult.inference_user_args.amber_is_pass && (
                <>
                  <PrismResultButton
                    value="minor"
                    severity="minor"
                    type="pureWhite"
                    size="small"
                    className={Styles.prismResult}
                  />{' '}
                  or
                </>
              )}
              <PrismResultButton
                value="good."
                severity="good"
                type="pureWhite"
                size="small"
                className={Styles.prismResult}
              />{' '}
              Scores within range of{' '}
              {getDisplayThreshold(
                currentToolResult.inference_user_args.upper_threshold,
                currentTool.specification_name,
              )}{' '}
              and{' '}
              {getDisplayThreshold(
                currentToolResult.inference_user_args.lower_threshold,
                currentTool.specification_name,
              )}{' '}
              set to{' '}
              <PrismResultButton
                value="minor,"
                severity="minor"
                type="pureWhite"
                size="small"
                className={Styles.prismResult}
              />{' '}
              above set to{' '}
              <PrismResultButton
                value="good"
                severity="good"
                type="pureWhite"
                size="small"
                className={Styles.prismResult}
              />
              .
            </div>
          }
        />
      )
    }

    if (currentTool?.specification_name === 'match-classifier') {
      const threshold = currentToolResult.inference_user_args.threshold
      const expectedClasses = allToolLabels ? getExpectedClasses(currentToolResult, allToolLabels) : undefined

      if (expectedClasses?.length === 0) {
        return (
          <PassCriteria
            fallbackText={
              <div className={Styles.passCriteriaDescriptionResult}>
                Set to{' '}
                <PrismResultButton
                  value="unknown"
                  severity="unknown"
                  type="pureWhite"
                  size="small"
                  className={Styles.prismResult}
                />{' '}
                because there was no expected label
              </div>
            }
          />
        )
      } else {
        conditions.push(
          {
            condition: 'prediction was ',
            passValue: expectedClasses ? <SerialList joint="or">{expectedClasses}</SerialList> : '...',
          },
          {
            condition: 'score exceeded',
            passValue: isNumber(threshold) ? getDisplayThreshold(threshold, currentTool.specification_name) : '--',
          },
        )
      }
    }

    if (currentTool?.specification_name === 'deep-svdd' || currentTool?.specification_name === 'classifier') {
      const threshold = currentToolResult.inference_user_args.threshold
      conditions.push({
        condition: 'score exceeded',
        passValue: isNumber(threshold) ? getDisplayThreshold(threshold, currentTool.specification_name) : '--',
      })
    }

    return <PassCriteria conditions={conditions} />
  }

  const getDefaultItemStatus = (
    muted: boolean,
    labelingStatus: 'relabeled' | 'confirmed' | 'default',
    outcome: Outcome,
    predictionOutcome: Outcome,
  ) => {
    if (muted) return 'pass'
    if (labelingStatus === 'default') return outcome
    return predictionOutcome
  }

  const renderUserLabelSetLabels = ({ labelSet }: { labelSet: UserLabelSet }) => {
    if (!currentTool) return null
    const isTrainable = TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(currentTool?.specification_name)

    if (isTrainable) {
      const userLabelSetLabels = allToolLabels?.filter(lbl => labelSet.tool_labels.includes(lbl.id))
      const usableLabels = userLabelSetLabels && filterOutDerivativeLabels(userLabelSetLabels)

      return (
        <>
          {usableLabels && (
            <LabelList labels={usableLabels} labelClassName={Styles.resultTimelineFont} tool={currentTool} />
          )}
        </>
      )
    } else {
      return (
        <PrismResultButton
          className={Styles.resultTimelineFont}
          severity={labelSet.outcome}
          value={labelSet.outcome}
          type="noFill"
        />
      )
    }
  }

  const getTimeLineItems = () => {
    if (!currentTool) return null
    const defaultItemStatus = predictionOutcome
      ? getDefaultItemStatus(muted, labelingStatus, outcome, predictionOutcome)
      : undefined

    const orderedLabelSets = [...(currentToolResult?.user_label_sets || [])]?.sort((labelSetA, labelSetB) => {
      if (labelSetB.created_at > labelSetA.created_at) return 1
      else return -1
    })

    return (
      <>
        {orderedLabelSets.map(labelSet => {
          const labelType: Outcome = labelSet.outcome

          const labelFromSet = allToolLabels?.find(lbl => labelSet.tool_labels.includes(lbl.id))

          const status = labelFromSet ? getStatusFromLabel(labelFromSet) : labelType

          const userName =
            labelSet.created_by_first_name && labelSet.created_by_last_name
              ? `${labelSet.created_by_first_name} ${labelSet.created_by_last_name?.substring(0, 1)}.`
              : undefined

          return (
            <Fragment key={labelSet.id}>
              {labelFromSet && labelSet.outcome !== currentToolResult?.prediction_outcome && (
                // This is the "Pass criteria met" item, which will only show if labeled outcome is different from prediction
                <TimelineItem
                  className={Styles.timelineItem}
                  status={labelType}
                  date={moment(labelSet.created_at)}
                  description={getDefaultCopy(currentTool.specification_name, labelSet.outcome)}
                />
              )}

              {/* This is the user label item */}
              <TimelineItem
                className={Styles.timelineItem}
                status={status}
                date={moment(labelSet.created_at)}
                description={
                  <div className={Styles.timelinePrediction}>
                    Labeled as {renderUserLabelSetLabels({ labelSet })}
                    {userName ? ` by ${userName}` : ''}
                  </div>
                }
              />
            </Fragment>
          )
        })}

        {/* This is the default first timeline item which is calculated with the prediction labels*/}
        {currentTool && defaultItemStatus && (
          <TimelineItem
            className={Styles.timelineItem}
            status={defaultItemStatus}
            date={moment(currentToolResult?.created_at)}
            description={getDefaultCopy(
              currentTool.specification_name,
              defaultItemStatus,
              errorCode,
              muted,
              currentToolResult?.calculated_outcome === 'error',
            )}
          />
        )}
      </>
    )
  }

  const patchToolResult = async (
    toolLabel: { toolLabel: ToolLabel } | { nonTrainableToolLabel: NonTrainableToolLabel },
  ) => {
    if (updatingLabelRef.current || !currentToolResult || !currentTool || !defaultLabels || !toolLabels) return

    updatingLabelRef.current = true

    const toolResultUpdateData = await updateToolResultBatchLabels({
      ...toolLabel,
      toolResults: [currentToolResult],
      dispatch,
      toolParentId: currentTool.parent_id,
      defaultLabels,
      currentToolLabels: toolLabels,
      toolSpecificationName: currentTool.specification_name,
    })

    updatingLabelRef.current = false
    if (!toolResultUpdateData) return

    setChangeLabel(false)

    await onRefresh?.()
  }

  const currentPredictedToolLabels = allToolLabels?.filter(lbl => currentToolResult?.prediction_labels.includes(lbl.id))

  const handleConfirmToolLabel = async () => {
    if (!currentTool) return
    const isTrainable = TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(currentTool.specification_name)
    if (isTrainable && currentPredictedToolLabels?.length) {
      const toolLabelToConfirm = getToolLabelToConfirm(currentPredictedToolLabels, currentTool.specification_name)
      if (!toolLabelToConfirm) return

      const updateBody: ToolResultsBatchLabel = {
        tool_results: [
          {
            id: currentToolResult.id,
            outcome: currentToolResult.prediction_outcome as Outcome,
            tool_label_ids: [toolLabelToConfirm.id],
          },
        ],
      }
      const res = await service.toolResultBatchLabel(updateBody)

      if (res.type !== 'success') {
        return error({ title: "Couldn't label results, try again" })
      }

      await onRefresh?.()
    }

    if (!isTrainable) {
      const currentPredictedOutcome = toolResult?.prediction_outcome
      const nonTrainableToolLabel = nonTrainableLabels.find(label => label.outcome === currentPredictedOutcome)
      if (nonTrainableToolLabel) {
        return patchToolResult({ nonTrainableToolLabel })
      }
    }
  }

  if (!currentTool) return null

  let labels =
    currentToolResult &&
    getLabels(currentToolResult, currentTool, allToolLabels)
      .map(lbl => lbl.value)
      .join(', ')

  const predictedLabelsToShow = predictedLabels ? filterOutDerivativeLabels(predictedLabels) : []

  if (!labels?.length) {
    labels = outcome
    if (currentTool.specification_name === 'match-classifier' || currentTool.specification_name === 'graded-anomaly') {
      labels = predictedLabelsToShow ? predictedLabelsToShow.map(lbl => lbl.value).join(', ') : ''
    }
  }

  const toolResultLabels =
    currentToolResult && allToolLabels && getToolResultLabels(currentToolResult, allToolLabels)?.sort(sortByLabelKind)

  let resultSeverity: 'pass' | 'fail' | 'discard' | 'unknown' = 'unknown'
  if (currentToolResult?.calculated_outcome === 'pass' || currentToolResult?.calculated_outcome === 'fail')
    resultSeverity = currentToolResult.calculated_outcome

  let extraOutcomeText: React.ReactNode = ''
  if (toolResultLabels && currentToolResult?.tool?.specification_name === 'match-classifier') {
    if (currentToolResult?.calculated_outcome === 'pass' || currentToolResult?.calculated_outcome === 'fail')
      extraOutcomeText = (
        <LabelList
          data-testid="tool-labeling-label-list"
          labels={extractDerivativeLabels(toolResultLabels)}
          labelClassName={Styles.resultHeadingFont}
          tool={currentTool}
        />
      )
  }

  const predictedUntrained = !!currentPredictedToolLabels?.find(
    label => label.id === findSingleToolLabelFromPartialData(allToolLabels, UNTRAINED_LABEL)?.id,
  )

  // If item for the current tool result is null, we only want to show the active user label,
  // we don't want to show the outcome and derivative labels.
  const renderResultForNullItem = () => {
    if (!currentToolResult) return '--'

    const { labels } = getToolResultValueSeverityAndLabels(currentToolResult, allToolLabels)

    if (!labels) return '--'

    return (
      <LabelList
        data-testid="tool-labeling-label-list-null-item"
        labels={labels}
        labelClassName={Styles.resultHeadingFont}
        tool={currentTool}
      />
    )
  }

  const renderResults = () => {
    if (!currentToolResult) return '--'

    if (currentToolResult.item === null) {
      return renderResultForNullItem()
    }

    return (
      <>
        {currentToolResult.calculated_outcome && (
          <PrismResultButton
            severity={resultSeverity}
            value={getResultLabelFromCalculatedOutcome(currentToolResult.calculated_outcome)}
            className={Styles.resultHeadingFont}
            size="small"
            type="noFill"
            data-testid="tool-labeling-outcome"
          />
        )}

        {toolResultLabels && (
          <>
            {getLinkingText(currentToolResult, toolResultLabels)} {extraOutcomeText}
            <LabelList
              data-testid="tool-labeling-label"
              labels={filterOutDerivativeLabels(toolResultLabels)}
              labelClassName={Styles.resultHeadingFont}
              tool={currentTool}
            />
          </>
        )}
      </>
    )
  }

  return (
    <div className={Styles.toolDetailWrapper}>
      <div className={Styles.toolDetailScrollContainer}>
        <ToolDetailHeader
          type={currentTool.specification_name}
          title={renderToolName(currentTool)}
          toolHasItem={!!itemId}
          onBackItem={onBackItem}
        />

        <div className={Styles.toolDetailBodyWrapper}>
          <div className={Styles.toolDetailBody}>
            {changeLabel && currentToolResult && (
              <LabelingButtons
                toolResult={currentToolResult}
                tool={currentTool}
                toolLabels={toolLabels}
                patchToolResult={patchToolResult}
              />
            )}

            <>
              {!changeLabel && currentToolResult && (
                <>
                  <div className={Styles.toolDetailSection}>
                    <div className={Styles.detailSectionTitle}>Result</div>
                    <div className={Styles.detailSectionResult}>{renderResults()}</div>
                  </div>

                  <div className={Styles.buttonsContainer}>
                    <Button
                      size="small"
                      type="secondary"
                      onClick={() => setChangeLabel(!changeLabel)}
                      disabled={!canEditOutcome}
                      data-testid="tool-labeling-change"
                    >
                      Change
                    </Button>

                    {labelingStatus === 'default' && (
                      <Button
                        size="small"
                        type="secondary"
                        onClick={handleConfirmToolLabel}
                        disabled={!canEditOutcome || toolResult?.prediction_outcome === 'error' || predictedUntrained}
                        data-testid="tool-labeling-confirm"
                      >
                        Confirm
                      </Button>
                    )}
                  </div>
                </>
              )}
            </>

            {!changeLabel && currentToolResult && currentToolResult.item && (
              <div className={Styles.toolDetailSection}>
                <div className={Styles.detailSectionTitle}>History</div>

                <div className={Styles.timelineWrapper}>
                  {!currentToolResult.user_label_sets && <PrismSkeleton />}

                  {currentToolResult.user_label_sets && (
                    <Timeline>
                      {getTimeLineItems()}

                      {muted && currentTool && (
                        <TimelineItem
                          className={Styles.timelineItem}
                          status={predictionOutcome}
                          description={getDefaultCopy(currentTool.specification_name, predictionOutcome)}
                        />
                      )}

                      {getInitialTimelineItem()}
                    </Timeline>
                  )}
                </div>
              </div>
            )}
            {changeLabel && (
              <Button
                size="small"
                type="secondary"
                className={Styles.cancelChangeLabel}
                onClick={() => setChangeLabel(!changeLabel)}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>

      {!changeLabel && getPassCriteria()}
    </div>
  )
}

export function ToolDetailHeader({
  type,
  title,
  toolHasItem,
  onBackItem,
}: {
  type: ToolSpecificationName
  title: string
  toolHasItem: boolean
  onBackItem?: () => void
}) {
  return (
    <div className={Styles.toolDetailHeader}>
      {toolHasItem && (
        <Button size="xsmall" type="secondary" onClick={onBackItem} badge={<PrismNavArrowIcon direction="left" />}>
          Back to item
        </Button>
      )}
      <div className={Styles.toolCardName}>
        <div className={Styles.toolIconContainer}>{TOOL_ICONS(type)}</div>
        <PrismOverflowTooltip content={title} />
      </div>
      <div className={Styles.toolCardDescription}>{TOOLS_DESCRIPTIONS[type]}</div>
    </div>
  )
}

function LabelingButtons({
  toolResult,
  tool,
  patchToolResult,
  toolLabels,
}: {
  toolResult: ToolResultEmptyOutcome
  tool: Tool
  patchToolResult: (
    toolLabel: { toolLabel: ToolLabel } | { nonTrainableToolLabel: NonTrainableToolLabel },
  ) => Promise<void>
  toolLabels?: ToolLabel[]
}) {
  const isTrainable = TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name)

  const labelsToUse: (ToolLabel | NonTrainableToolLabel)[] | undefined = useMemo(() => {
    if (!toolLabels) return
    const baseLabels: (ToolLabel | NonTrainableToolLabel)[] | undefined = isTrainable
      ? [...toolLabels.sort(sortByValueAndSeverity)]
      : [...nonTrainableLabels]

    return baseLabels
  }, [isTrainable, toolLabels])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      handleLabelingKeydown({
        key: e.key,
        handleToolResultsLabel: async toolResultLabel => {
          if ('toolLabel' in toolResultLabel) return patchToolResult({ toolLabel: toolResultLabel.toolLabel })
          else if ('nonTrainableToolLabel' in toolResultLabel) patchToolResult({ ...toolResultLabel })
        },
        toolLabels: labelsToUse,
      })
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [labelsToUse, patchToolResult, tool.specification_name, toolLabels])

  return (
    <div className={Styles.labelOutcome}>
      {labelsToUse?.map((toolLabel, key) => {
        let currentLabelActive: boolean = false

        if ('value' in toolLabel) {
          currentLabelActive = !!toolResult.active_user_label_set?.tool_labels.includes(toolLabel.id!)
        } else {
          currentLabelActive = toolResult.active_user_label_set?.outcome === toolLabel.outcome
        }

        const hotkey = getToolLabelHotkey({
          labelIdx: key,
        })

        return (
          <PrismLabelButton
            key={toolLabel.id}
            data-testid={`labeling-options-${
              'value' in toolLabel ? toolLabel.value.toLowerCase() : toolLabel.displayName.toLowerCase()
            }-${'value' in toolLabel ? toolLabel.severity : SEVERITY_BY_OUTCOME[toolLabel.outcome]}`}
            value={'value' in toolLabel ? getLabelName(toolLabel) : toolLabel.displayName}
            severity={'value' in toolLabel ? getDisplaySeverity(toolLabel) : SEVERITY_BY_OUTCOME[toolLabel.outcome]}
            active={currentLabelActive}
            hotkey={hotkey}
            onClick={() => {
              if ('value' in toolLabel) {
                patchToolResult({
                  toolLabel,
                })
              } else {
                patchToolResult({ nonTrainableToolLabel: toolLabel })
              }
            }}
            toolLabel={'value' in toolLabel ? toolLabel : undefined}
            toolParentId={tool.parent_id}
            toolSpecificationName={tool.specification_name}
            popoverClassName={Styles.toolModalInfoPopover}
          />
        )
      })}
    </div>
  )
}

const TimeLinePrediction = ({
  verb = 'Predicted ',
  text,
  trailingText,
}: {
  verb?: string
  text: React.ReactNode
  trailingText?: string | React.ReactNode
}) => (
  <div className={Styles.timelinePrediction}>
    {verb} <q>{text}</q> {trailingText}
  </div>
)

const PassCriteria = ({
  fallbackText,
  conditions,
  title,
}: {
  fallbackText?: React.ReactNode
  conditions?: { condition?: string; passValue?: React.ReactNode; trailingText?: string }[]
  title?: string
}) => (
  <div className={Styles.toolDetailFooter}>
    <PassCriteriaContainer title={title || 'Pass Criteria'}>
      {fallbackText}

      {!fallbackText && conditions && (
        <div className={Styles.passCriteriaDescriptionResult}>
          Set to{' '}
          <PrismResultButton
            value="pass"
            severity="pass"
            type="pureWhite"
            size="small"
            className={Styles.prismResult}
          />{' '}
          if{' '}
          <SerialList fullStop>
            {conditions.map(({ condition, passValue, trailingText }) => (
              <Fragment key={condition}>
                {condition}
                {passValue ? <> {passValue}</> : ''}
                {trailingText}
              </Fragment>
            ))}
          </SerialList>
        </div>
      )}
    </PassCriteriaContainer>
  </div>
)

export const ToolLabelingLoading = () => {
  return (
    <div className={`${Styles.toolDetailWrapper} ${Styles.isLoading}`}>
      <div className={Styles.toolDetailHeader}>
        <div className={Styles.toolCardName}>
          <PrismSkeleton />
        </div>
        <div className={Styles.toolCardDescription}>
          <PrismSkeleton />
        </div>

        <div className={Styles.toolLabelLoadingContainer}>
          <PrismSkeleton size="extraLarge" />
        </div>
        <div className={Styles.timelineLoadingContainer}>
          <ul className={Styles.timelineLoadingItemList}>
            {[0, 1].map(k => (
              <div key={k} className={Styles.timelineLoadingItems}>
                <div className={Styles.timelineLight}></div>
                <PrismSkeleton />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const getResultLabelFromCalculatedOutcome = (outcome: ToolResultOutcome | 'empty') => {
  if (outcome === 'needs-data' || outcome === 'error') return 'Unknown'
  return titleCase(outcome)
}

const getExpectedClasses = (toolResult: ToolResultEmptyOutcome, labels: ToolLabel[]): JSX.Element[] => {
  const expectedClasses = toolResult.inference_user_args.expected_classes

  if (!expectedClasses) return []

  return expectedClasses.map((labelId: string) => {
    // default to label id if no match to support old expected classes that had label values instead of ids
    const label = labels.find(label => labelId === label.id)
    if (!label) return '...'
    return (
      <PrismResultButton
        key={labelId}
        value={getLabelName(label)}
        severity={getDisplaySeverity(label)}
        type="noFill"
        size="small"
        className={Styles.prismResult}
      />
    )
  })
}

const getStatusFromLabel = (label: ToolLabel): 'pass' | 'fail' | 'unknown' | 'minor' | 'default' | undefined => {
  if (label.severity === 'critical') return 'fail'
  if (label.severity === 'good') return 'pass'
  if (label.severity === 'minor') return 'minor'
  if (label.severity === 'neutral') return 'default'
}

const getLinkingText = (toolResult: ToolResultEmptyOutcome, toolResultLabels: ToolLabel[]) => {
  const passWithSeverity =
    toolResult.calculated_outcome === 'pass' &&
    toolResultLabels.some(lbl => lbl.severity === 'critical' || lbl.severity === 'minor')

  return passWithSeverity ? 'with' : 'due to'
}

// At the moment when the user presses confirm we only want to persist one label,
// the match label prediction for the match tool, and the RCA prediction for any other tool, if available.
const getToolLabelToConfirm = (toolLabels: ToolLabel[], specificationName: ToolSpecificationName) => {
  if (specificationName === 'match-classifier') {
    return toolLabels.find(toolLabel => toolLabel.severity === 'neutral')
  }

  const rcaLabel = toolLabels.find(toolLabel => toolLabel.kind === 'custom')
  if (rcaLabel) return rcaLabel
  return toolLabels.find(toolLabel => toolLabel.kind === 'default')
}
