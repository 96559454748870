import React, { useState } from 'react'

import { Switch } from 'antd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { PreprocessingOptions, RoutineWithAois, Tool, TrainableToolSettingsProps } from 'types'
import { getRegex, getRegexCopy, protectedOnChange } from 'utils'
import { BARCODE_TYPES } from 'utils/constants'

import EditAoisHelper from './AOIEditing/EditAoisHelper'
import RotationAndScaleInputs from './Common/RotationAndScaleInputs'
import { useShowGrid } from './Common/toolSettingsHooks'
import RegexPlaygroundModal from './RegexPlaygroundModal'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Render Barcode Tool form.
 *
 * This shows the name of the anomaly tool and the dataset and level of training
 * linked to it.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const BarcodeToolSettings = ({
  routine,
  recipe,
  tool,
  onExit,
  readOnly,
}: Omit<TrainableToolSettingsProps, 'view'>) => {
  const history = useHistory()
  const { showGrid, setShowGrid } = useShowGrid()

  const [isRegexPlaygroundVisible, setIsRegexPlaygroundVisible] = useState<boolean>(false)
  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    control,
    formState: { errors },
    formState,
    reset,
    getValues,
    setValue,
    watch,
  } = formData

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const saveChanges = () => {
    const { expectedText, selectedType, inferenceArgs, overrideCustomId, aois } = getValues()
    const { dirtyFields } = formState

    const promises = []
    // Edit env_vars and inference_user_args
    if (dirtyFields.selectedType) {
      const newEnvs: { [key: string]: any } = {}

      // First, clean out the env vars that coincide with default barcode types
      for (const env of Object.keys(tool.inference_env)) {
        if (!Object.keys(BARCODE_TYPES).includes(env)) newEnvs[env] = tool.inference_env[env]
      }

      if (selectedType) {
        // Now we check if all has been selected, if so, set all default types
        if (selectedType === 'all') {
          for (const type of Object.keys(BARCODE_TYPES)) {
            // The env vars must be stored as "TRUE" and not a regular boolean true
            newEnvs[type] = 'TRUE'
          }
        } else {
          // Otherwise, just set the selected barcode as an ev var
          newEnvs[selectedType] = 'TRUE'
        }
      }
      promises.push(service.patchTool(tool.id, { inference_env: newEnvs }))
    }

    if (dirtyFields.expectedText || dirtyFields.overrideCustomId) {
      const updatedInferenceUserArgs = {
        ...tool.inference_user_args,

        regex: expectedText,
        override_custom_id: overrideCustomId,
      }
      promises.push(
        service.patchProtectedTool(tool.id, {
          aois: aois.map(aoi => ({ id: aoi.id, inference_user_args: updatedInferenceUserArgs })),
        }),
      )
    }

    if (dirtyFields.inferenceArgs) {
      promises.push(
        service.patchTool(tool.id, {
          inference_args: { ...tool.inference_args, ...inferenceArgs },
        }),
      )
    }

    return promises
  }

  const handleRegexPlaygroundApply = (newRegex: string) => {
    setValue('expectedText', newRegex, { shouldDirty: true })
    setIsRegexPlaygroundVisible(false)
  }

  const { expectedText, inferenceArgs } = watch(['expectedText', 'inferenceArgs'])

  const regexIsValid = !!getRegex(expectedText)

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            routine={routine}
            recipe={recipe}
            resetForm={resetForm}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            disableSaveTooltip={!regexIsValid ? 'Regex is invalid' : undefined}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    wrapperClassName={Styles.settingsItemInput}
                  />
                )}
              />
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemTitle}>Barcode Type</div>
              <div className={Styles.settingsItemInput}>
                <Controller
                  name="selectedType"
                  control={control}
                  render={({ onChange, value }) => (
                    <PrismSelect
                      onChange={protectedOnChange(val => onChange(val), { routine, history, recipe })}
                      value={value}
                      disabled={readOnly}
                      options={[
                        { value: 'all', title: 'All Types' },
                        ...Object.keys(BARCODE_TYPES).map(barcodeType => {
                          return { value: barcodeType, key: barcodeType, content: BARCODE_TYPES[barcodeType] }
                        }),
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemSwitchAndTitle}>
                <div className={Styles.settingsItemTitle}>Use barcode as item id</div>
                <Controller
                  name="overrideCustomId"
                  control={control}
                  render={({ onChange, value }) => (
                    <Switch
                      size="default"
                      checked={value}
                      onChange={checked => onChange(checked)}
                      disabled={readOnly}
                    />
                  )}
                />
              </div>
              <div className={Styles.settingsText}>
                Use the barcode found by this tool as the Item ID. If multiple results are found, they will be
                concatenated.
              </div>
            </div>

            <Controller
              control={control}
              name="inferenceArgs"
              render={({ onChange, value }) => {
                function setOptions<T>(options: { [key: string]: T }) {
                  onChange({
                    ...value,
                    ...options,
                  })
                }

                return (
                  <RotationAndScaleInputs
                    setShowGrid={setShowGrid}
                    setInferenceArgs={setOptions}
                    routine={routine}
                    inferenceArgs={value}
                    recipe={recipe}
                  />
                )
              }}
            />

            <div className={Styles.settingsItem}>
              <Controller
                name="expectedText"
                control={control}
                render={({ onChange, value }) => (
                  <>
                    <PrismInput
                      label="Pass Criteria"
                      wrapperClassName={Styles.grayCover}
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      className={Styles.regexInput}
                      disabled={readOnly}
                      data-testid="barcode-tool-settings-regex"
                    />
                    <div className={Styles.settingsText}>
                      {!regexIsValid && (
                        <>
                          <span className={Styles.errorText}>Regex is invalid</span>
                          <br />
                        </>
                      )}
                      <span>{getRegexCopy(value, 'detect-barcode')}</span>
                      <br />
                      <br />
                      Use normal characters or{' '}
                      <span
                        className={Styles.blueText}
                        onClick={() => setIsRegexPlaygroundVisible(true)}
                        data-testid="barcode-tool-settings-regex-link"
                      >
                        Regex
                      </span>{' '}
                      to update pass criteria
                      <>
                        {' '}
                        or{' '}
                        <span className={Styles.blueText} onClick={() => onChange('.*')}>
                          click here to reset.
                        </span>
                      </>
                    </div>
                  </>
                )}
              />
            </div>

            <RegexPlaygroundModal
              visible={isRegexPlaygroundVisible}
              onCloseModal={() => setIsRegexPlaygroundVisible(false)}
              regexString={expectedText}
              onApply={handleRegexPlaygroundApply}
            />
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              disableToggleButtons={isRegexPlaygroundVisible}
              showPreview
              previewOptions={{ inferenceArgs: { ...inferenceArgs, hsv_enable: false }, showGrid }}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  let initialType = 'all'

  for (const envVar of Object.keys(tool.inference_env)) {
    // In case the env var is set to false, we ignore it. Currently can't be set using frontend, but can be done manually
    if (tool.inference_env[envVar] !== 'TRUE') continue

    // if we find the current envVar in the possible barcode types, it means it has been set as true
    if (Object.keys(BARCODE_TYPES).includes(envVar)) {
      // If initialType has changed, an evVar has already been found, so we default the type to 'All' and stop iterating, it's not necessary
      if (initialType !== 'all') {
        initialType = 'all'
        break
      }
      initialType = envVar
    }
  }

  const { name, aois } = getToolNameAndAois(routine, tool)

  const inferenceArgs = tool.inference_args as PreprocessingOptions
  inferenceArgs.rotation_angle ??= 0
  inferenceArgs.resize_width ??= 1
  inferenceArgs.resize_height ??= 1

  return {
    aois,
    name,
    expectedText: (tool.inference_user_args.regex || '') as string,
    selectedType: initialType,
    inferenceArgs,
    overrideCustomId: !!tool.inference_user_args.override_custom_id as boolean,
  }
}
