import React, { useEffect, useMemo, useRef, useState } from 'react'

import { capitalize, Dictionary, groupBy } from 'lodash'
import { useDispatch } from 'react-redux'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import PrismAccordion from 'components/PrismAccordion/PrismAccordion'
import {
  PrismElementaryCube,
  PrismLineIcon,
  PrismRecipeIcon,
  PrismSearchIcon,
  PrismSiteIcon,
  PrismStationIcon,
} from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import RecipeNameWithThumbnail from 'components/RecipeNameWithThumbnail/RecipeNameWithThumbnail'
import { useQueryEntityFromSites } from 'hooks'
import { RecipeParent, Site, StationForSite, SubSite } from 'types'
import { getRecipeParentImage, getTimeAgoFromDate, matchRole, sortByName, sortByTimestampKeyFirst } from 'utils'

import Styles from './ArchiveView.module.scss'

export const ARCHIVED_ENTITY_ICONS = {
  site: <PrismSiteIcon />,
  line: <PrismLineIcon />,
  station: <PrismStationIcon />,
  recipe: <PrismRecipeIcon />,
  product: <PrismElementaryCube />,
}

type Entity = Site | SubSite | StationForSite | RecipeParent

function getEntityData(
  entity: Site | SubSite | StationForSite | RecipeParent,
  allStationsByBelongsToId: Dictionary<Site['stations']>,
) {
  if (isEntitySite(entity)) {
    return {
      children: entity.subsites,
      entityType: 'site',
    } as const
  }
  if (isEntitySubsite(entity)) {
    return {
      children: allStationsByBelongsToId[entity.id],
      entityType: 'line',
    } as const
  }
  if (isEntityStation(entity)) {
    return {
      children: entity.recipe_parents,
      entityType: 'station',
    } as const
  }
  return {
    entityType: 'recipe',
    children: undefined,
    img: getRecipeParentImage(entity, { preferThumbnail: true }),
  } as const
}

function isEntitySite(entity: Entity): entity is Site {
  return 'address' in entity
}

function isEntityStation(entity: Entity): entity is Site['stations'][0] {
  return 'recipe_parents' in entity
}

function isEntitySubsite(entity: Entity): entity is SubSite {
  return 'extra' in entity && 'type_id' in entity
}

const entitiesHierarchy = ['site', 'line', 'station', 'recipe'] as const

type AccordionBody = {
  id: string
  name: string
  archiveDate: string
  entityType: 'site' | 'line' | 'station' | 'recipe'
  img: string | undefined
  location: string
  showButton: boolean
  children: AccordionBody[]
}

export const ArchivedSitesLinesAndStations = ({
  searchText,
  setArchiveListIsEmpty,
}: {
  searchText: string | undefined
  setArchiveListIsEmpty: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const tableTitles = [{ title: 'name' }, { title: 'archive date' }, { title: 'type' }, { title: 'original location' }]
  const accordionBodyRef = useRef<HTMLDivElement>(null)
  const sites = useQueryEntityFromSites({ entity: 'site' })

  const archiveAccordions = useMemo(() => {
    const allStations = sites?.flatMap(site => site.stations.map(station => ({ ...station, site_id: site.id })))
    const allStationsByBelongsTo = groupBy(allStations, station => station.belongs_to_id)

    function extractData(
      entities: (Site | SubSite | StationForSite | RecipeParent)[] | undefined,
      accordions: AccordionBody[],
      nestedLevel: number = 0,
      siteName?: string,
      parentMatched?: boolean,
    ) {
      entities?.forEach(entity => {
        const { entityType, children, img } = getEntityData(entity, allStationsByBelongsTo)
        const entitySiteName = isEntitySite(entity) ? entity.name : siteName
        const entityMatchedSearch = !!searchText && entity.name.toLowerCase().includes(searchText.toLowerCase())

        if (entity.is_deleted) {
          // Get all the children below the archived entity
          const accordionChildren: AccordionBody[] = []
          extractData(
            children,
            accordionChildren,
            nestedLevel + 1,
            entitySiteName,
            entityMatchedSearch || parentMatched,
          )

          // If entity is a Site, we also need to append stations that are not assigned to any subsite
          if (isEntitySite(entity)) {
            extractData(
              allStationsByBelongsTo['null']?.filter(station => station.site_id === entity.id),
              accordionChildren,
              nestedLevel + 1,
              entitySiteName,
              entityMatchedSearch || parentMatched,
            )
          }

          if (!!searchText && !parentMatched && !entityMatchedSearch && !accordionChildren.length) return

          return accordions.push({
            id: entity.id,
            name: entity.name,
            archiveDate: entity.updated_at,
            img,
            entityType,
            showButton: nestedLevel === 0,
            location: siteName || '--',
            children: accordionChildren.sort((a, b) => {
              if (a.entityType === b.entityType) {
                // Order them by name
                return sortByName(a, b)
              }
              return entitiesHierarchy.indexOf(a.entityType) - entitiesHierarchy.indexOf(b.entityType)
            }),
          })
        }

        // We need to check every children to see if we have a nested archived entity
        extractData(children, accordions, nestedLevel, entitySiteName)

        // We also need to verify stations that are not assigned to any Subsite
        if (isEntitySite(entity)) {
          extractData(
            allStationsByBelongsTo['null']?.filter(station => station.site_id === entity.id),
            accordions,
            nestedLevel,
            entitySiteName,
          )
        }
      })
    }

    const accordions: AccordionBody[] = []
    extractData(sites, accordions)
    return accordions.sort((a, b) => sortByTimestampKeyFirst(a, b, 'archiveDate'))
  }, [searchText, sites])

  useEffect(() => {
    if (!!searchText) return

    setArchiveListIsEmpty(archiveAccordions.length === 0)
  }, [archiveAccordions, searchText, setArchiveListIsEmpty])

  return (
    <>
      {!!archiveAccordions.length && (
        <>
          <li className={Styles.stickyTableHeader}>
            <GridTableHeader columns={tableTitles} size="small" className={Styles.archiveGridTableHeader} />
          </li>
        </>
      )}
      {!!archiveAccordions.length &&
        archiveAccordions.map(accordion => (
          <SitesAndLinesArchiveAccordion
            parentRef={accordionBodyRef}
            key={accordion.id}
            accordion={accordion}
            className={Styles.multiLevelAccordion}
          />
        ))}

      {searchText && !archiveAccordions.length && (
        <GenericBlankStateMessage
          description="No results match your search"
          header={<PrismSearchIcon />}
          className={Styles.noMatchWrapper}
        />
      )}
    </>
  )
}

const updateEntiyByType: Record<
  AccordionBody['entityType'],
  (siteId: string, body: { is_deleted: boolean }) => Promise<SendToApiResponse<unknown>>
> = {
  site: service.updateSite,
  line: service.updateSubSite,
  station: service.updateStation,
  recipe: service.updateRecipeParent,
}

const SitesAndLinesHeader = ({ id, name, entityType, img, archiveDate, location, showButton }: AccordionBody) => {
  const dispatch = useDispatch()
  async function handleClick() {
    const res = await updateEntiyByType[entityType](id, { is_deleted: false })
    if (res.type !== 'success') return error({ title: 'An error occurred, please try again' })
    success({ title: capitalize(entityType) + ' unarchived' })
    await query(getterKeys.sites(), service.getSites, { dispatch })
  }

  return (
    <>
      <RecipeNameWithThumbnail
        image={img ? img : ARCHIVED_ENTITY_ICONS[entityType]}
        recipeName={name || '--'}
        className={Styles.archiveTitleContainer}
        textClassName={Styles.title}
      />
      <div className={Styles.archiveDate}>{archiveDate ? getTimeAgoFromDate(archiveDate).text : '--'}</div>
      <div className={Styles.archiveType}>{entityType || '--'}</div>
      <PrismOverflowTooltip content={location || '--'} textClassName={Styles.archiveLocation} />

      {showButton && matchRole('manager') && (
        <Button className={Styles.restoreButton} size="small" type="secondary" onClick={handleClick}>
          restore
        </Button>
      )}
    </>
  )
}

const SitesAndLinesArchiveAccordion = ({
  accordion,
  parentRef,
  className = '',
}: {
  accordion: AccordionBody
  parentRef: React.RefObject<HTMLDivElement>
  className?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <PrismAccordion
      key={accordion.id}
      isOpen={isOpen}
      showArrow={!!accordion.children.length}
      header={<SitesAndLinesHeader {...accordion} />}
      body={
        <div className={Styles.archiveAccordionBodyRow}>
          {accordion.children.map(childrenAccordion => (
            <SitesAndLinesArchiveAccordion
              key={childrenAccordion.id}
              parentRef={parentRef}
              accordion={childrenAccordion}
            />
          ))}
        </div>
      }
      setIsOpen={() => setIsOpen(!isOpen)}
      iconContainerClassName={Styles.archiveAccordionArrow}
      headerClassName={`${Styles.archiveAccordionHeader} ${Styles.archiveGrid} ${
        !accordion.children.length ? Styles.hideRowStates : ''
      }`}
      className={className}
    />
  )
}
