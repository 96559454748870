import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Eye Hide Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A488
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismEyeHideIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8476 14.5001C13.2284 14.5001 14.3476 13.3808 14.3476 12.0001C14.3476 11.5846 14.2463 11.1929 14.067 10.8482L14.7963 10.1136C15.1453 10.658 15.3476 11.3054 15.3476 12.0001C15.3476 13.9331 13.7806 15.5001 11.8476 15.5001C11.1549 15.5001 10.5092 15.2988 9.96582 14.9516L10.6905 14.2167C11.0365 14.3977 11.4301 14.5001 11.8476 14.5001Z"
        fill="currentColor"
      />
      <path
        d="M11.8477 9.5C10.4669 9.5 9.34766 10.6193 9.34766 12C9.34766 12.4165 9.44953 12.8093 9.62971 13.1547L8.90334 13.8931C8.5517 13.3474 8.34766 12.6975 8.34766 12C8.34766 10.067 9.91466 8.5 11.8477 8.5C12.5407 8.5 13.1867 8.70144 13.7303 9.04898L13.0029 9.78235C12.6574 9.60198 12.2644 9.5 11.8477 9.5Z"
        fill="currentColor"
      />
      <path
        d="M12.0474 17.0252C15.4237 17.0252 18.0735 15.113 19.9771 12.1511C19.0605 10.725 17.971 9.54223 16.7106 8.69909L17.4363 8C18.7768 8.93263 19.9177 10.1993 20.8591 11.6718C21.047 11.9657 21.047 12.3365 20.8591 12.6304C18.8399 15.7889 15.9027 18 12.0474 18C10.562 18 9.21286 17.6718 8 17.0875L8.76194 16.357C9.75802 16.7873 10.8508 17.0252 12.0474 17.0252Z"
        fill="currentColor"
      />
      <path
        d="M11.8476 7C8.51094 7 5.89227 8.96163 4.01099 12C4.9204 13.4687 6.00596 14.6867 7.25343 15.551L6.52535 16.2608C5.19908 15.3038 4.07036 14.0035 3.1392 12.4915C2.95353 12.19 2.95363 11.8098 3.13932 11.5083C5.13481 8.2682 8.03758 6 11.8476 6C13.3157 6 14.649 6.33674 15.8477 6.93607L15.112 7.69444C14.1139 7.24446 13.0255 7 11.8476 7Z"
        fill="currentColor"
      />
      <path d="M3.84766 19L18.8477 4" stroke="currentColor" />
    </svg>
  )
}
