import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Play Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A362
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismPlayIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4148 9.5904L7.4534 4.7174C7.12201 4.48543 6.66667 4.7225 6.66667 5.12702L6.66667 14.873C6.66667 15.2775 7.12201 15.5146 7.4534 15.2826L14.4148 10.4096C14.6992 10.2106 14.6992 9.78946 14.4148 9.5904Z"
        fill="currentColor"
      />
    </svg>
  )
}
