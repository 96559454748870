import React from 'react'

import { Checkbox, CheckboxProps } from 'antd'

import Styles from './PrismCheckbox.module.scss'

interface Props extends Omit<CheckboxProps, 'onClick'> {
  label?: React.ReactNode
  shape?: 'box' | 'circle'
  className?: string
  labelClassName?: string
  ref?: React.Ref<HTMLInputElement>
  partialSelection?: boolean
}

/**
 * Renders an antd checkbox with Prism styles
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=2128-11877&mode=design&t=JTP3y0LSNo22TuLd-0
 *
 * @param label - Aside from holding a label string, it can be used as a wrapper containing different elements.
 * @param shape - default shape is box, can be changed to circle
 * @param ref - a reference to the main wrapper
 * @param className - string to be passed to the main wrapper
 * @param labelClassName - string to be passed to the label container
 * @param partialSelection - Special case for checkbox that controls the value of other checkboxes, this changes the styles of the checkmark when its checked.
 *
 */
const PrismCheckbox = ({
  label,
  className = '',
  labelClassName = '',
  shape = 'box',
  ref,
  partialSelection,
  ...rest
}: Props) => {
  const classes = `${Styles.checkboxWrapper} ${label && label !== '' ? Styles.checkboxLabel : ''} ${
    shape === 'circle' ? Styles.circle : ''
  } ${partialSelection ? Styles.partialSelection : ''} ${className}`

  return (
    <Checkbox
      className={classes}
      {...rest}
      ref={ref}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {label && (
        <span
          className={labelClassName}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {label}
        </span>
      )}
    </Checkbox>
  )
}

export default PrismCheckbox
