import React, { useState } from 'react'

import { PrismArrowIcon } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'

import Styles from './PrismAccordion.module.scss'

type PrismAccordionProps = {
  header: React.ReactNode
  body: React.ReactNode
  className?: string
  headerClassName?: string
  bodyClassName?: string
  iconContainerClassName?: string
  iconClassName?: string
  isOpen: boolean
  onClick?: () => void
  setIsOpen: (isOpen: boolean) => void
  showArrow?: boolean
  loading?: boolean
  wrapperRef?: React.RefObject<HTMLDivElement>
  dataTestId?: string
}

/**
 * Renders an Accordion wrapper
 *
 * This component doesn't have internal styles, it only renders a wrapper with a header, which can * be clicked and the body is displayed by using a transition.
 *
 * @param header - Holds the header elements
 * @param body - mainly a list of elements
 * @param className - string class to be applied in the wrapper
 * @param headerClassName - string class to be applied to the header section
 * @param bodyClassName - string class to be applied to the body section
 * @param isOpen - A boolean to open or close the accordion
 * @param setIsopen - Function to change the boolean value
 *
 */
const PrismAccordion = ({
  header,
  body,
  headerClassName = '',
  bodyClassName = '',
  className = '',
  iconContainerClassName = '',
  iconClassName = '',
  isOpen,
  setIsOpen,
  onClick,
  loading,
  showArrow = true,
  wrapperRef,
  dataTestId,
}: PrismAccordionProps) => {
  // Removes the max-height limit when the animation end
  const [animationEnded, setAnimationEnded] = useState(false)

  return (
    <div
      className={`${className} ${Styles.accordionWrapper} `}
      ref={wrapperRef}
      onAnimationEnd={() => setAnimationEnded(true)}
      data-testid={dataTestId}
    >
      <div
        className={headerClassName}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setIsOpen(!isOpen)
          onClick?.()
          if (isOpen) setAnimationEnded(false)
        }}
      >
        {header}
        {showArrow && (
          <div className={iconContainerClassName}>
            <PrismArrowIcon direction={isOpen ? 'up' : 'down'} className={iconClassName} />
          </div>
        )}
      </div>
      <ul
        className={`${bodyClassName} ${Styles.accordionBody} ${isOpen ? Styles.isOpen : Styles.isClosed} ${
          animationEnded ? Styles.animationEnded : ''
        }`}
      >
        {isOpen && !loading && body}
        {isOpen && loading && (
          <div className={Styles.lineContentLoader}>
            <PrismSkeleton className={Styles.imgSkeleton} />
            <PrismSkeleton />
          </div>
        )}
      </ul>
    </div>
  )
}

export default PrismAccordion
