import React, { useEffect } from 'react'

import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Ellipse as EllipseType } from 'konva/lib/shapes/Ellipse'
import { Ellipse, Layer, Transformer } from 'react-konva'

import { useIsFlourish } from 'hooks'
import { Box, BoxWithShape, EllipseShapeField } from 'types'
import { AOI_COLORS } from 'utils/constants'

import { getCurrentEllipseBoundingBox } from '../EditAoisHelper'
import { MaskingRectangle } from '../Utils'
import {
  ACTIVE_STROKE_WIDTH,
  ANCHOR_SIZE,
  EditableShapeProps,
  HANDLE_STROKE_WIDTH,
  INACTIVE_STROKE_WIDTH,
  SHADOW_HOVER_STROKE,
  SHADOW_HOVER_STROKE_WIDTH,
  SHADOW_STROKE,
  SHADOW_STROKE_WIDTH,
} from './EditableShape'

interface Props extends EditableShapeProps {
  ellipseRef: React.RefObject<EllipseType>
  trRef: React.RefObject<Konva.Transformer>
  ellipseData?: EllipseData
  setEllipseData: (aoiId: string, ellipseData: EllipseData) => any
  handleTransform: (aoiId: string) => any
  handleTransformEnd: (aoiId: string) => any
  toExportAoi: () => BoxWithShape | undefined
}

/**
 * An ellipse that can be rotated, scaled, and dragged.
 *
 * @param aoi - The aoi being edited
 * @param inactive - If true, box doesn't have shadow to make background darker
 *     and make box stand out
 * @param disableTransform Should we disable resizing the AOI?
 * @param onUpdateBox Callback for when we update the AOI
 * @param onSelect Callback for when we click on the AOI
 * @param scaling Scaling used to translate between image coordinates and client coordinates
 * @param bounds Width and height of the image we're creating AOIs for, before scaling.
 * @param deselectAois Callback to deslect all aois
 */
const EditableEllipse = ({
  aoi, // NOTE: It's important to pass in (a potentially edited) version of the aoi to onUpdateBox, otherwise it's treated as a new aoi.
  isInactive = false,
  disableTransform = false,
  onUpdateAoiWithShape,
  onSelect,
  scaling,
  bounds,
  deselectAois,
  ellipseRef,
  trRef,
  ellipseData,
  setEllipseData,
  handleTransform,
  handleTransformEnd,
  toExportAoi,
}: Props) => {
  const { isFlourish } = useIsFlourish()
  const initialShapeData = (aoi.shape && aoi.shape.type === 'ellipse' && aoi.shape.data) || undefined
  const aoiShapeStroke = isFlourish ? AOI_COLORS.flourish.dark_100 : AOI_COLORS.prism.dark_100
  const aoiStrokeInactive = isFlourish ? AOI_COLORS.flourish.smokeyBlack_24 : AOI_COLORS.prism.smokeyBlack_24

  useEffect(() => {
    setEllipseData(aoi.id, getInitialEllipse(aoi, scaling, initialShapeData))
    // eslint-disable-next-line
  }, [aoi])

  useEffect(() => {
    // We verify that we're actually able to create a canvas and a rectangle
    // and that we want to allow resizing
    if (ellipseRef.current && !disableTransform && !isInactive) {
      // Attach the transformer to the fill for the rectangle
      trRef.current?.nodes([ellipseRef.current as Konva.Ellipse])
      trRef.current?.getLayer()?.batchDraw()
    } else {
      trRef.current?.nodes([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableTransform, ellipseRef.current, isInactive, trRef])

  // Set up the click listener to deselect all aois on the stage
  useEffect(() => {
    const stage = ellipseRef.current?.getStage()
    if (!stage) return
    const handleClickStage = () => stage && deselectAois(stage)

    stage.on('click', handleClickStage)
    return () => {
      stage.off('click', handleClickStage)
    }
  }, [deselectAois, ellipseRef])

  // When the circle is first created, it needs to persist the circle it can fit inside the box to the aoi.shape.data
  useEffect(() => {
    if (aoi.shape?.data) return
    onUpdateAoiWithShape({ ...aoi, ...toExportAoi() })
  }, [aoi]) // eslint-disable-line

  const handleDrag = (e: KonvaEventObject<DragEvent>) => {
    if (!ellipseData) return
    const ellipse = ellipseRef.current as Konva.Ellipse
    const { x, y } = constrainDragToBounds({ x: ellipse.x(), y: ellipse.y() })
    e.target.x(x)
    e.target.y(y)
    setEllipseData(aoi.id, { ...ellipseData, originX: x, originY: y })
  }

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    handleDrag(e)
    onUpdateAoiWithShape({ ...aoi, ...toExportAoi() })
  }

  const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    e.evt.stopPropagation()
    if (isInactive) onSelect(aoi)
  }

  const onMouseEnter = () => {
    if (!ellipseRef.current || !ellipseRef.current.getStage()) return
    ellipseRef.current!.getStage()!.container().style.cursor = 'move'
    if (isInactive) {
      const ghostRect = ellipseRef.current!.getLayer()!.findOne<Konva.Rect>('.aoi-ghost-stroke')!
      ghostRect.stroke(SHADOW_HOVER_STROKE)
      ghostRect.strokeWidth(SHADOW_HOVER_STROKE_WIDTH)
    }
  }

  const onMouseLeave = () => {
    if (!ellipseRef.current || !ellipseRef.current.getStage()) return
    ellipseRef.current!.getStage()!.container().style.cursor = 'default'
    if (isInactive) {
      const ghostRect = ellipseRef.current!.getLayer()!.findOne<Konva.Rect>('.aoi-ghost-stroke')!
      ghostRect.stroke(SHADOW_STROKE)
      ghostRect.strokeWidth(SHADOW_STROKE_WIDTH)
    }
  }

  const constrainDragToBounds = ({ x, y }: { x: number; y: number }): { x: number; y: number } => {
    const { y: top, x: left, width, height } = getCurrentEllipseBoundingBox(ellipseRef)
    const maxHeight = bounds.maxHeight * scaling
    const maxWidth = bounds.maxWidth * scaling

    let deltaX = 0
    let deltaY = 0

    if (top < 0) {
      deltaY = -top
    } else if (top + height > maxHeight) {
      deltaY = maxHeight - top - height
    }

    if (left < 0) {
      deltaX = -left
    } else if (left + width > maxWidth) {
      deltaX = maxWidth - left - width
    }
    return { x: x + deltaX, y: y + deltaY }
  }

  if (!ellipseData) return null

  const { originX, originY, radiusX, radiusY, rotationDegrees } = ellipseData

  return (
    <Layer>
      {!isInactive && <MaskingRectangle bounds={bounds} scaling={scaling} />}
      {/* The fill for the main AOI ellipse, which just cuts out the appropriate region from the masking rectangle. */}
      <Ellipse
        x={originX}
        y={originY}
        ref={ellipseRef}
        draggable
        resizeEnabled={!disableTransform}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDragMove={handleDrag}
        onDragEnd={handleDragEnd}
        onTransform={() => handleTransform(aoi.id)}
        onTransformEnd={() => handleTransformEnd(aoi.id)}
        onMouseDown={onMouseDown}
        radiusX={radiusX}
        radiusY={radiusY}
        rotation={rotationDegrees}
        fill={'black'}
        globalCompositeOperation={'destination-out'}
        name={'aoi-fill'}
      />
      {/* This is the shadow that is rendered when you hover over an inactive ellipse, for example when you've
          duplicated the AOI and want to switch between the active AOIs
        */}
      {isInactive && (
        <Ellipse
          x={originX}
          y={originY}
          radiusX={radiusX}
          radiusY={radiusY}
          stroke={SHADOW_STROKE}
          rotation={rotationDegrees}
          strokeWidth={SHADOW_STROKE_WIDTH}
          strokeScaleEnabled={false}
          listening={false}
          name={'aoi-ghost-stroke'}
        />
      )}
      {/* This is the actual stroke for the main ellipse
       */}
      <Ellipse
        x={originX}
        y={originY}
        radiusX={radiusX}
        radiusY={radiusY}
        rotation={rotationDegrees}
        stroke={isInactive ? aoiStrokeInactive : aoiShapeStroke}
        strokeWidth={isInactive ? INACTIVE_STROKE_WIDTH : ACTIVE_STROKE_WIDTH}
        strokeScaleEnabled={false}
        listening={false}
        name={'aoi-stroke'}
      />

      {!disableTransform && (
        <Transformer
          ref={trRef}
          rotateEnabled
          ignoreStroke
          useSingleNodeRotation
          padding={0}
          rotation={rotationDegrees}
          anchorSize={ANCHOR_SIZE}
          anchorStroke={aoiShapeStroke}
          anchorStrokeWidth={HANDLE_STROKE_WIDTH}
          borderStroke={aoiShapeStroke}
          borderStrokeWidth={ACTIVE_STROKE_WIDTH}
          keepRatio={false}
          flipEnabled={false}
        />
      )}
    </Layer>
  )
}

export default EditableEllipse

export type EllipseData = {
  originX: number
  originY: number
  radiusX: number
  radiusY: number
  rotationDegrees: number
}

const getInitialEllipse = (box: Box, scaling: number, data?: EllipseShapeField['data']): EllipseData => {
  let radiusX, radiusY, rotationDegrees
  const originX = (box.x + box.width / 2) * scaling
  const originY = (box.y + box.height / 2) * scaling
  if (data) {
    radiusX = data.radiusX * box.width * scaling
    radiusY = data.radiusY * box.height * scaling
    rotationDegrees = data.rotationDegrees
  } else {
    radiusX = (box.width * scaling) / 2
    radiusY = (box.height * scaling) / 2
    rotationDegrees = 0
  }
  return { originX, originY, radiusX, radiusY, rotationDegrees }
}
