import React from 'react'

import { isNil } from 'lodash'
import { shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, wsPaths } from 'api'
import { Button } from 'components/Button/Button'
import { Carousel } from 'components/Carousel/Carousel'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismCloseIcon, PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { Status } from 'components/Status/Status'
import { Token } from 'components/Token/Token'
import Video from 'components/Video/Video'
import {
  useData,
  useQueryCurrentInspection,
  useSortedRobotsWithStatus,
  useStationStatus,
  useTotalCountsAndCommonDefects,
  useTypedSelector,
} from 'hooks'
import paths from 'paths'
import { AnalyzeDefect, Robot, StationForSite, WithSiteId } from 'types'
import {
  appendDataToQueryString,
  calculatePercentage,
  getDisplaySeverity,
  getLabelName,
  matchRole,
  renderLargeNumber,
  setPinnedRobot,
} from 'utils'
import { STATUS_LABELS } from 'utils/constants'

import { SectionHeader } from '../InspectSites'
import EntityOverflowMenu from './EntityOverflowMenu'
import { STATION_LIST_METRICS_SUFIX } from './LinesList'
import Styles from './StationPreview.module.scss'

/**
 * Renders the Station Preview Cameras carousel
 *
 * @param onCameraClick - callback to be passed when clicking a camera button
 * @param isEmptyState - changes the looks of the carousel, instead of displaying a list of elements it displays only 1
 * @returns
 */
const StationCamerasCarousel = ({
  robots,
  isLoading,
  stationId,
}: {
  robots?: Robot[]
  isLoading?: boolean
  stationId?: string
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const pinnedCameraData = useTypedSelector(state => state.localStorage.pinnedCameraByStation, shallowEqual)

  const handleCameraClick = (robotId: string) => {
    if (!stationId) return
    setPinnedRobot({ stationId, robotId, currentPinnedCameraData: pinnedCameraData, dispatch })
    history.push(paths.stationDetail('overview', stationId))
  }

  const sortedRobotsWithStatus = useSortedRobotsWithStatus(robots)

  return (
    <Carousel
      type="twolines"
      carouselTitle="cameras"
      carouselTitleClassName={Styles.cameraTitle}
      carouselListClassName={Styles.camerasCarousel}
      className={Styles.camerasSection}
      scrollSize={112}
    >
      {!isLoading && (
        <>
          {!sortedRobotsWithStatus?.length && (
            <figure className={Styles.cameraContainer}>
              <PrismElementaryCube addBackground />
            </figure>
          )}

          {sortedRobotsWithStatus?.map(({ id: robotId }) => (
            <Video
              key={robotId}
              robotId={robotId}
              relativeUrl={wsPaths.videoBaslerThumbnail(robotId)}
              fallbackImage={<PrismElementaryCube addBackground />}
              videoClassName={Styles.cameraContainer}
              loader={<PrismSkeleton size="extraLarge" />}
              onClick={() => {
                handleCameraClick(robotId)
              }}
            />
          ))}
        </>
      )}

      {isLoading && (
        <figure className={Styles.cameraContainer}>
          <PrismSkeleton size="extraLarge" />
        </figure>
      )}
    </Carousel>
  )
}

/**
 * Renders the Station Preview Metrics section
 *
 * @param metricsList - Where the yield, failed and count data comes
 * @param isEmptyState - changes the looks of the component.
 */
const StationMetrics = ({ metricsList }: { metricsList: { label: string; value: string | number }[] }) => {
  return (
    <ul className={Styles.metricsList}>
      {metricsList.map(metric => {
        return (
          <li className={Styles.metricsItem} key={metric.label}>
            <Token
              inverted
              label={<div>{metric.label}</div>}
              className={Styles.metric}
              labelClassName={Styles.metricLabel}
              valueClassName={Styles.metricValue}
            >
              {metric.value}
            </Token>
          </li>
        )
      })}
    </ul>
  )
}

/**
 * Renders the Station Preview Defects list
 *
 * @param isEmptyState - changes the looks of the component.
 */
const StationDefects = ({
  mostCommonDefects,
  isEmptyState,
}: {
  mostCommonDefects: AnalyzeDefect[]
  isEmptyState: boolean
}) => {
  return (
    <div className={Styles.defectsListContainer}>
      <div className={Styles.defectsListTitle}>Top 5 defects</div>
      <ul className={Styles.defectsList}>
        {mostCommonDefects.map(defect => (
          <li className={Styles.defectItem} key={defect.toolLabel.id}>
            <PrismResultButton
              value={getLabelName(defect.toolLabel)}
              severity={getDisplaySeverity(defect.toolLabel)}
              type="noFill"
              className={Styles.defectLabel}
            />
            <span className={Styles.defectCount}>{!isEmptyState ? renderLargeNumber(defect.defectCount) : '--'}</span>
          </li>
        ))}
        {(!mostCommonDefects || mostCommonDefects.length === 0) &&
          Array(5)
            .fill(undefined)
            .map((_, idx) => (
              <li className={Styles.defectItem} key={idx}>
                <span className={Styles.defectLabel}>--</span>
                <span className={Styles.defectCount}>--</span>
              </li>
            ))}
      </ul>
    </div>
  )
}

interface Props {
  siteId: string
  station: WithSiteId<StationForSite>
  onClose: () => void
  className?: string
}

/**
 * Renders the Station Preview Component
 *
 * @param selectedStation - The selected station
 * @param onClose -
 * @param className - Station Preview container selector to add styles.
 */
const StationPreview = ({ station, onClose, className = '' }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const stationStatus = useStationStatus(station)
  const robotIds = station?.robots.map(robot => robot.id)
  const isBatchRunning = stationStatus === 'running'

  const inspection = useQueryCurrentInspection(isBatchRunning ? robotIds : [])

  // These metrics are fetched by StationRow
  const itemMetricsKey = inspection
    ? getterKeys.rtsMetrics('items', inspection.id, 'counts', `${STATION_LIST_METRICS_SUFIX}-outcome`)
    : undefined
  const itemsMetrics = useData(itemMetricsKey)?.results

  const labelMetrics = useData(
    inspection ? getterKeys.rtsMetrics('items', inspection.id, 'counts', STATION_LIST_METRICS_SUFIX) : undefined,
  )?.results

  const { totalCount, totalPass, totalFail, mostCommonDefects } = useTotalCountsAndCommonDefects({
    itemsMetrics,
    itemLabelsMetrics: labelMetrics,
  })

  return (
    <>
      <article className={`${Styles.stationPreview} ${className}`}>
        <div>
          <SectionHeader
            title={station?.name || '--'}
            subtitle={
              <Status
                status={stationStatus}
                className={Styles.stationPreviewStatus}
                labelClassName={Styles.stationPreviewStatusLabel}
                loaderClassName={Styles.stationPreviewStatusLoader}
              >
                <PrismOverflowTooltip
                  content={inspection ? inspection.name : STATUS_LABELS[stationStatus]}
                  canBeResized
                />
              </Status>
            }
            icons={
              <>
                {station && matchRole('manager') && (
                  <EntityOverflowMenu
                    entityType={'station'}
                    entity={station}
                    renderWithPortal={false}
                    onArchive={async () => {
                      appendDataToQueryString(history, { stationId: undefined })
                      await query(getterKeys.sites(), service.getSites, { dispatch })
                    }}
                    optionMenuSize="small"
                  />
                )}

                <IconButton icon={<PrismCloseIcon isThin />} onClick={onClose} type="tertiary" size="small" />
              </>
            }
            leftContainerClassName={Styles.stationPreviewHeaderLeftContainer}
          />
          <div className={Styles.sectionsList}>
            <StationCamerasCarousel stationId={station?.id} robots={station?.robots} isLoading={!station} />

            <StationMetrics
              metricsList={[
                {
                  label: 'Yield',
                  value:
                    !isNil(totalCount) && !isNil(totalPass)
                      ? `${calculatePercentage(totalPass, totalCount).toFixed(1)}%`
                      : '--',
                },
                { label: 'Failed', value: isNil(totalFail) ? '--' : renderLargeNumber(totalFail) },
                { label: 'Items', value: isNil(totalCount) ? '--' : renderLargeNumber(totalCount) },
              ]}
            />
            <StationDefects mostCommonDefects={mostCommonDefects?.slice(0, 5) || []} isEmptyState={!isBatchRunning} />
          </div>
        </div>

        <Button
          wide
          size="small"
          className={Styles.stationPreviewButton}
          onClick={() => history.push(paths.stationDetail('overview', station?.id))}
        >
          View Station
        </Button>
      </article>
    </>
  )
}

export default StationPreview
