import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import * as Sentry from '@sentry/react'
import { debounce, groupBy, isEqual, isNil, keyBy, sum, throttle, uniqBy } from 'lodash'
import moment, { isMoment } from 'moment'
import momentTz from 'moment-timezone'
import qs from 'qs'
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux'
import { QueryState } from 'react-redux-query/query'
import { useHistory, useLocation } from 'react-router-dom'

import {
  getAllPages,
  GetterData,
  getterKeys,
  query,
  SendToApiResponse,
  SendToApiResponseOnlyData,
  service,
  ToolResultsData,
  useQuery,
} from 'api'
import { prefetch } from 'components/Img/ImgFallback'
import { getAndSetRobotCapabilities } from 'components/OnLogin'
import { getAndSetEdgeParams, getRobotIdsFromEdgeParams } from 'components/OnMountApp'
import { CascaderOption } from 'components/PrismCascader/PrismCascader'
import { LabelButtonSeverity } from 'components/PrismResultButton/PrismResultButton'
import { HEAP_APP_ID } from 'env'
import { getAnalyzeDefects } from 'pages/Analyze/AnalyzeBase'
import { ExtraOptions, Filters, FiltersUpdater } from 'pages/Analyze/AnalyzeFilters'
import {
  getInitialThresholdByView,
  ThresholdByRoutineParentId,
} from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import {
  AnalyzeTab,
  AoiAndLabelMetrics,
  BackendThreshold,
  BucketS,
  CameraStatus,
  ConditionalType,
  ConfusionMatrix,
  CreateUpdateDeleteSubsBody,
  EventSub,
  EventTargets,
  EventType,
  Inspection,
  Outcome,
  RecipeExpanded,
  RecipeParent,
  RecipeRoutine,
  Robot,
  RobotDiscoveriesById,
  RootState,
  Site,
  StationForSite,
  StatusCommandRecipe,
  SubSite,
  SubSiteType,
  SuccessResponseOnlyData,
  Threshold,
  TimeSeriesDatePeriod,
  TimeSeriesResult,
  TimeSeriesType,
  Tool,
  ToolFlat,
  ToolFlatWithCurrentExperiment,
  ToolLabel,
  Toolset,
  Toolsets,
  ToolSpecificationName,
  ToolThreshold,
  TrainingMetrics,
  TrainingResultFlat,
  VpStatusStreamMessage,
  WithSiteId,
  WithStatus,
} from 'types'
import {
  calculateTrainingMetrics,
  combineOutcomeCounts,
  computeAvailablePeriodsForDateRange,
  computeConfusionMatrix,
  computeCurrentPeriodForDateRange,
  convertAllFiltersToBackendQueryParams,
  convertArrayToString,
  convertStringFromArray,
  extractDerivativeLabels,
  fetchInspectionsForProxy,
  fetchToolResultsWithNullPredictionScore,
  filterEventTypesByAllowedTargets,
  filterOutDeletedLabels,
  filterOutDerivativeLabels,
  filterOutUnusedLabels,
  findMultipleToolLabelsByPartialData,
  findSingleToolLabelFromPartialData,
  getAnalyzeAggS,
  getAnalyzeMetricsLabels,
  getData,
  getFiltersToProxyInspections,
  getLabelsTrainCountsFromDataset,
  getResults,
  getRobotInspectionId,
  getRobotStatus,
  getRtsParams,
  getStateUpdateMessagesByRobotId,
  getterAddPage,
  getThresholdFromTool,
  handleInspectionsProxyResultsEndReached,
  isNonTrainingLabel,
  isThresholdFromGARTool,
  ListItem,
  ListOrNever,
  METRICS_START_DATE_MS,
  refreshEventSubsBranches,
  sortByName,
  sortByStatusAndName,
  sortStationsByOrdering,
  waitFor,
} from 'utils'
import {
  ALL_TOOL_LABELS_GETTER_KEY,
  ANOMALY_DEFECT_TOOL_LABELS,
  DEFAULT_TOOL_LABELS_GETTER_KEY,
  GENERATED_HEAP_SCRIPT_ID,
  GRADED_ANOMALY_TOOL_LABELS,
  LABEL_SCREEN_TOOL_RESULTS_PAGE_SIZE,
  LOCAL_TIMEZONE,
  QUALITY_EVENTS_ALLOWED_TARGET_KEYS,
  QUALITY_EVENTS_ALLOWED_TARGET_TABLES,
  SECONDS_IN_DAY,
  STATUS_PRIORITY,
} from 'utils/constants'
import { initializeHeapScript as initializeHeap } from 'utils/heap'
import { DISCARD_LABEL, TEST_SET_LABEL, UNCERTAIN_LABEL } from 'utils/labels'
/**
 * Retrieves an object from the getter branch of the state tree.
 *
 * @param key - Key in getter branch
 *
 * @returns Data object at key if present
 */
export function useData<T extends keyof typeof getterKeys, K extends ReturnType<(typeof getterKeys)[T]>>(key?: K) {
  return useTypedSelector(state => getData(state.getter, key))
}

/**
 * Retrieves an array from the getter branch of the state tree.
 *
 * @param key - Key in getter branch. Key must point to types that extend ListResponseData
 *
 * @returns Results list at key if present
 */
export function useResults<T extends keyof typeof getterKeys, K extends ReturnType<(typeof getterKeys)[T]>>(key?: K) {
  return useTypedSelector(state => getResults(state.getter, key))
}

/**
 * Invokes `callback` when called and then every `interval` ms.
 *
 * @param callback - Function to invoke
 * @param intervalMs - Polling interval
 * @params options - Options
 */
export function useInterval(
  callback: () => void,
  intervalMs: number,
  options: { callImmediately?: boolean; stopped?: boolean } = {},
) {
  const { callImmediately = true, stopped = false } = options

  const timerId = useRef<number | undefined>(undefined)
  useEffect(() => {
    window.clearInterval(timerId.current)
    if (stopped) return

    if (callImmediately) callback() // Call it initially
    timerId.current = window.setInterval(callback, intervalMs) // Call it repeatedly

    return () => window.clearInterval(timerId.current)
  }, [callback, intervalMs, callImmediately, stopped])
}

export interface UsePaginationOptions {
  node: HTMLElement | null
  overflowScroll: boolean
  marginFromBottom?: number
}

/**
 * Awaits `getNextPage` whenever user scrolls to bottom of page. Works for touch
 * gestures, scroll, and mousewheel. Ensures callback not invoked repeatedly.
 *
 * @param getNextPage - Function called when user reaches bottom of page
 * @param usePaginationOptions - Config options
 */
export function usePagination(
  getNextPage: () => any,
  { node, overflowScroll, marginFromBottom }: UsePaginationOptions,
) {
  const loadingRef = useRef(false)
  const [counter, setCounter] = useState(0)

  // Ensure component using this hook rerenders until DOM has time to load and node can be found
  useEffect(() => {
    if (node) return
    setTimeout(() => setCounter(counter + 1), 750)
  }, [node, counter])

  useEffect(() => {
    if (!node) return

    const handleScroll = async () => {
      if (loadingRef.current) return

      const margin = marginFromBottom || 20
      let atBottom = false
      if (node) {
        // Set this flag to true if paginatable container has fixed sized and is scrollable; https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
        if (overflowScroll) {
          const distanceFromBottom = node.scrollHeight - node.clientHeight
          atBottom = node.scrollTop >= distanceFromBottom - margin
        } else {
          // TODO: why does this work? Also... DON'T touch this code without discussing with rest of team
          const distanceFromBottom = node.offsetHeight - window.innerHeight
          atBottom = document.documentElement.scrollTop >= distanceFromBottom - margin
        }
      }

      if (atBottom) {
        loadingRef.current = true
        await getNextPage()
        loadingRef.current = false
      }
    }
    const handle = node
    handle.addEventListener('touchmove', handleScroll)
    handle.addEventListener('mousewheel', handleScroll)
    handle.addEventListener('scroll', handleScroll)

    return () => {
      handle.removeEventListener('touchmove', handleScroll)
      handle.removeEventListener('mousewheel', handleScroll)
      handle.removeEventListener('scroll', handleScroll)
    }
  }, [getNextPage, marginFromBottom, node, overflowScroll])
}

/**
 * Returns the value of `value` in the previous render of a component. Returns
 * undefined on first render.
 *
 * @param value - Var whose value on previous render you want to know
 *
 * @returns Previous value passed into hook
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export function useTypedStore() {
  return useStore<RootState, Actions.Action>()
}

/**
 * This hook is the same as useState, except that it also returns a ref with the
 * current state value. It's equivalent to using useRef to store a mutable value
 * bound to the component, and useState to force the component to rerender when
 * this value is updated (which is how state works in class components).
 *
 * @param initialState - Initial state value
 * @param updateRefImmediately - If true, update ref as soon as setState is
 *     called, but before state is actually updated; doesn't work if user passes
 *     a function to setStateAndRef
 *
 * @returns State value, function for updating state, and ref containing state
 *     value
 */
export function useStateAndRef<S>(initialState: S | (() => S), updateRefImmediately = false) {
  const [state, setState] = useState<S>(initialState)
  const stateRef = useRef(state)

  const setStateAndRef = useCallback(
    (value: React.SetStateAction<S>) => {
      setState(value)
      if (updateRefImmediately && typeof value !== 'function') stateRef.current = value
    },
    [updateRefImmediately],
  )

  stateRef.current = state
  return [state, setStateAndRef, stateRef] as const
}

/**
 * This hook is the same as useRef, except that it updates the value inside ref
 * each time after component renders.
 *
 * @param value - Current ref value
 *
 * @returns Ref
 */
export function useSelfUpdatingRef<T>(value: T) {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  })
  return ref
}

/**
 * This hook runs on every render. If it detects the virtual keyboard, it
 * disables it. It enables the keyboard again on cleanup.
 */
export function useDisableVirtualKeyboard() {
  useEffect(() => {
    const keyboard = document.getElementById('virtualKeyboardChromeExtensionOverlayScrollExtend')?.parentElement
    if (keyboard) keyboard.style.display = 'none'
    return () => {
      const keyboard = document.getElementById('virtualKeyboardChromeExtensionOverlayScrollExtend')?.parentElement
      if (keyboard) keyboard.style.display = 'block'
    }
  })
}

/**
 * Takes a robot ids and returns the current inspection id for any of those robots, by
 * looking in the state update stream published by VP.
 * Passing more than one robot id would serve to know if at least one of them is running,
 * but only one id should be passed if we want to know what specific id is running on a robot.
 *
 * @param robotIds - Robot ids
 *
 * @returns Robot's current inspection id, undefined if robot currently has no inspection loaded,
 *  or null if we don't yet have messages to know
 */
export function useGetCurrentInspectionId(robotIds?: string[]) {
  return useTypedSelector(state => {
    if (!robotIds) return null
    let inspectionId: string | null | undefined = undefined
    for (const robotId of robotIds) {
      inspectionId = getRobotInspectionId(robotId, state)
      if (inspectionId) break
    }
    return inspectionId
  })
}

export const useQueryCurrentInspection = (robotIds?: string[]) => {
  const inspectionId = useGetCurrentInspectionId(robotIds)

  const inspection = useQuery(
    inspectionId ? getterKeys.inspection(inspectionId) : undefined,
    inspectionId ? () => service.getInspection(inspectionId) : undefined,
  ).data?.data

  return inspection
}

/**
 * Use location, parse query params from location, return both.
 *
 * @returns params and location
 */
export function useQueryParams<T extends string, K = string | undefined>() {
  const location = useLocation()
  const params: { [key in T]: K } = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location])
  return [params, location] as const
}

/**
 * Get image element from URL and return it once image is loaded.
 *
 * @param src - Image src URL
 *
 * @returns imageElement once it's loaded
 */
export function useImageElement(src?: string) {
  const [imageElement, setImageElement] = useState<HTMLImageElement>()

  useEffect(() => {
    if (!src) return setImageElement(undefined)
    const image = prefetch(src)
    image.onload = e => setImageElement(e.target as HTMLImageElement)
  }, [src])

  return [imageElement, setImageElement] as const
}

/**
 * Takes an inspection and creates a ref that holds the inspection duration in ms
 * @param inspection
 * @returns ref for inspection duration in ms
 */
export function useInspectionDurationRef(inspection: Inspection | undefined) {
  // Use a ref so we can use this value later in callback without it being frozen in closure
  const inspectionDurationMsRef = useRef<number>(0)

  let inspectionStartMs: number | undefined

  if (inspection?.started_at) {
    inspectionStartMs = moment(inspection.started_at).valueOf()
    if (inspection.ended_at) {
      inspectionDurationMsRef.current = moment(inspection.ended_at).valueOf() - inspectionStartMs
    } else {
      inspectionDurationMsRef.current = Date.now() - inspectionStartMs
    }
    inspectionDurationMsRef.current = Math.max(inspectionDurationMsRef.current, 1000)
  }

  return { inspectionDurationMsRef }
}

export const useColocatedStation = () => {
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(true)
  const colocatedStation = useData(getterKeys.colocatedStation())

  useEffect(() => {
    const fn = async () => {
      const params = await getAndSetEdgeParams(dispatch)
      const colocatedRobotIds = getRobotIdsFromEdgeParams(params)

      // Colocated robot(s)
      if (colocatedRobotIds.length > 0) {
        const stationsRes = await service.getStations({ robot_id: colocatedRobotIds[0] })

        if (stationsRes.type === 'success') {
          const station = stationsRes.data.results[0]
          if (station) {
            dispatch(
              Actions.getterSave({
                key: getterKeys.station(station.id),
                data: { ...stationsRes, data: station },
              }),
            )
            dispatch(
              Actions.getterSave({
                key: getterKeys.colocatedStation(),
                data: { ...stationsRes, data: station },
              }),
            )
          }
        }
      }
      setIsFetching(false)
    }

    fn()
  }, [dispatch])

  return { colocatedStation, isFetching }
}

/**
 * Compute and return robot status from the events branch of redux. Use in
 * conjunction with RobotsStatusListener.
 *
 * @param robotId - The robot id we want to find the status from
 */
export function useRobotStatus(robotId?: string) {
  const status = useTypedSelector(state => {
    if (!robotId) return 'disconnected'
    return getRobotStatus(robotId, { state })
  })

  return status
}

/**
 * Compute and return robot status values from the events branch of redux. Use
 * in conjunction with RobotsStatusListener.
 *
 * @param robotIds - The robot ids we want to find the status from
 */
export function useStatusByRobotId(robotIds: string[] | undefined) {
  const statusByRobotId = useTypedSelector(state => {
    if (!robotIds) return
    const statusByRobotId: { [robotId: string]: CameraStatus | undefined } = {}
    robotIds.forEach(robotId => {
      statusByRobotId[robotId] = getRobotStatus(robotId, { state })
    })
    return statusByRobotId
  }, shallowEqual)

  return statusByRobotId
}

/**
 * This functions returns a modified `from_s` param to send to the time series endpoint
 * We need this because some backfilled entries (from redis time series) could end up with a timestamp
 * below the desired from_s
 *
 * Example: The 30s buckets were backfilled with the 10m old buckets, so, if an inspection started at 10:02, the backfilled
 * entry timestamp would be 10:00, so we need to consider that to return a from_s that substracts 10m to get
 * all the desired data.
 *
 * @param inspection - Inspection
 * @param bucketS - current bucket_s, if needed, we substract time based on the bucket
 */
const getFromS = ({
  inspection,
  bucketS,
  pastS,
}: {
  inspection: Inspection
  bucketS: BucketS | undefined
  pastS: number | undefined
}) => {
  // We don't want to send fromS and pastS together as they overlap and could be overriden
  if (pastS) return undefined
  const startedAt = moment(inspection.started_at)

  // 1800s series where backfilled with old 60m series, so we need to substract 1hour
  // to be sure to get all the inspection data
  if (bucketS === 1800) return startedAt.subtract(1, 'hours').unix()

  // 30s series where backfilled with old 10m series, so we need to substract 10 minutes
  // to be sure to get all the inspection data
  return startedAt.subtract(10, 'minutes').unix()
}

export function useQueryItemMetrics(
  inspection: Inspection | undefined,
  seriesType: 'item_label_inspection' | 'item_outcome_inspection',
  manualTrigger: boolean,
  options?: {
    isHistoricBatch?: boolean
    pastS?: number
    getterKeySuffix?: string
    onlyCounts?: boolean
    preventFetch?: boolean
    forceNoneAggType?: boolean
  },
) {
  const dispatch = useDispatch()
  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const connectionStatus = useConnectionStatus()

  const { rtsParams, metricsCompaction, pollIntervalMs } = getRtsParams({
    seriesType,
    labels: { inspection_id: inspection?.id },
    rtsDatePeriod: {
      past_s: options?.pastS,
      to_s: options?.isHistoricBatch && inspection?.ended_at ? moment(inspection.ended_at).unix() : undefined,
    },
    metricParams: {
      isHistoricBatch: options?.isHistoricBatch,
      timeframe: options?.pastS,
      onlyCounts: options?.onlyCounts,
    },
    inspectionDuration: inspectionDurationMsRef.current,
  })
  const itemMetricsGetter = inspection
    ? () => {
        return service.readTimeSeries({
          ...rtsParams,
          from_s: getFromS({ inspection, bucketS: rtsParams.bucket_s, pastS: rtsParams.past_s }),
        })
      }
    : undefined

  // Don't poll until we have inspection, hence duration, hence we can compute `metricsCompaction`
  const itemMetricsKey =
    inspection && !options?.preventFetch
      ? getterKeys.rtsMetrics('items', inspection.id, metricsCompaction, options?.getterKeySuffix)
      : undefined

  const refetchKey = `${options?.pastS},${connectionStatus}`

  const { data: itemMetricsRes } = useQuery(inspection ? itemMetricsKey : undefined, itemMetricsGetter, {
    intervalMs: pollIntervalMs,
    intervalRedefineFetcher: true,
    refetchKey,
  })

  const recentToolResults = useData(inspection?.id ? getterKeys.inspectionRecentToolResults(inspection.id) : undefined)
  useEffect(() => {
    // WARNING: Refetch metrics after getting new items over the WS.
    // There's no guarantee RTS writes have happened at this point, because WS
    // and RTS write paths are different.

    if (!manualTrigger) return

    setTimeout(() => {
      if (itemMetricsKey && itemMetricsGetter) query(itemMetricsKey, itemMetricsGetter, { dispatch })
    }, 500)
  }, [recentToolResults, manualTrigger, itemMetricsKey]) // eslint-disable-line

  return { itemMetricsRes, agg_s: rtsParams.agg_s as number }
}

export function useQueryAoiMetrics(
  inspection: Inspection | undefined,
  manualTrigger: boolean,
  options?:
    | {
        isHistoricBatch?: boolean
        pastS?: number
        onlyCounts?: undefined
        getterKeyPrefix?: string
        preventFetch?: boolean
      }
    | {
        isHistoricBatch?: boolean
        pastS?: undefined
        onlyCounts?: boolean
        getterKeyPrefix?: string
        preventFetch?: boolean
      },
) {
  const dispatch = useDispatch()
  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const connectionStatus = useConnectionStatus()

  const { rtsParams, metricsCompaction, pollIntervalMs } = getRtsParams({
    inspectionDuration: inspectionDurationMsRef.current,
    seriesType: 'tool_result_outcome_inspection',
    rtsDatePeriod: {
      past_s: options?.pastS,
      to_s: options?.isHistoricBatch && inspection?.ended_at ? moment(inspection.ended_at).unix() : undefined,
    },
    labels: { inspection_id: inspection?.id || '' },
    metricParams: {
      timeframe: options?.pastS,
      isHistoricBatch: options?.isHistoricBatch,
      onlyCounts: options?.onlyCounts,
    },
  })

  // aoiMetrics contains count/pass/fail for tool aoi
  const aoiMetricsKey =
    inspection?.id && !options?.preventFetch
      ? getterKeys.rtsMetrics('aois', inspection.id, metricsCompaction, options?.getterKeyPrefix)
      : undefined

  const aoiMetricsGetter = inspection
    ? () => {
        return service.readTimeSeries({
          ...rtsParams,
          from_s: getFromS({ inspection, bucketS: rtsParams.bucket_s, pastS: rtsParams.past_s }),
        })
      }
    : undefined

  const refetchKey = `${options?.pastS},${connectionStatus}`

  // Poll much less frequently if we're using manual trigger; poll ensures we
  // send first request when component mounts, and simplifies code by also
  // subscribing to getter branch for metrics responses
  const { data: aoiMetricsRes, dataMs: lastPolledMs } = useQuery(aoiMetricsKey, aoiMetricsGetter, {
    intervalMs: manualTrigger ? 2 * 60 * 1000 : pollIntervalMs,
    intervalRedefineFetcher: true,
    refetchKey,
  })

  const recentToolResults = useData(inspection?.id ? getterKeys.inspectionRecentToolResults(inspection.id) : undefined)

  useEffect(() => {
    // WARNING: Refetch metrics after getting new batch log items over the WS.
    // There's no guarantee RTS writes have happened at this point, because WS
    // and RTS write paths are different.

    if (!manualTrigger) return

    setTimeout(() => {
      if (aoiMetricsKey && aoiMetricsGetter) query(aoiMetricsKey, aoiMetricsGetter, { dispatch })
    }, 500)
  }, [recentToolResults, manualTrigger, aoiMetricsKey]) // eslint-disable-line

  return { aoiMetricsRes, lastPolledMs }
}

/**
 * Returns a updater function that can be called to force a function component
 * to rerender.
 */
export function useForceUpdate() {
  const [, setUpdateCounter] = useState(0)
  return useCallback(() => setUpdateCounter(value => value + 1), [])
}

export interface UsePaginationIntersectionOptions {
  node: HTMLElement | null
  marginFromBottom?: number
}

export const usePaginationIntersection = (
  getNextPage: () => any,
  paginationBottomElement: HTMLElement | undefined | null,
  options?: UsePaginationIntersectionOptions,
) => {
  const isLoadingNextPage = useRef(false)

  const { node, marginFromBottom = 0 } = options || {}

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        // If the "loader" div is intersecting, we're at the bottom of the card list, so load more cards
        if (entry.isIntersecting && !isLoadingNextPage.current) {
          isLoadingNextPage.current = true
          getNextPage()
          isLoadingNextPage.current = false
        }
      })
    }

    const options = {
      root: node, // node is the element with which the paginationBottomElement will intersect with
      rootMargin: `0px 0px ${marginFromBottom}px`,
      threshold: 0, // As soon as we see any of the bottom element
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    if (paginationBottomElement) {
      observer.observe(paginationBottomElement)
    }

    return () => {
      if (paginationBottomElement) {
        observer.unobserve(paginationBottomElement)
      }
    }
  }, [getNextPage, marginFromBottom, node, paginationBottomElement])
}

/** A ref callback to be used with DOM elements. This updates a piece of state when the element is mounted or unmounted
 * which causes a rerender
 *
 * @returns [stateRef, setRef] - State and ref callback function
 */
export function useRerenderRef<T>() {
  const [stateRef, setStateRef] = useState<T | null>(null)
  const setRef = useCallback((node: T) => {
    setStateRef(node)
  }, [])
  return [stateRef, setRef] as const
}

/**
 * Gets the status of the the overall list of robots and inspections. This hook
 * will only report accurate data if there's a subscription to the robot streams
 * through a RobotsStatusListener
 *
 * @param robotIds - List of robots to evaluate
 * @returns
 *  inspectionLoading - Whether any inspection is loading
 *  inspectionStopping - Whether any inspection is stopping
 *  robotIsReady - Whether any robot is ready
 *  robotIsRunning - Whether any robot is running
 */
export const useRobotAndInspectionStatus = (robotIds: string[]) => {
  // Assign variables that depend on state update messages, ensure we don't rerender if variables don't change
  return useTypedSelector(state => {
    const messagesByRobotId = getStateUpdateMessagesByRobotId(state, robotIds)

    const inspectionLoading = Object.values(messagesByRobotId).some(messages => {
      const endState = messages?.[0]?.payload.status
      return endState === 'LOADING' || endState === 'LOADING_TOOLS' || endState === 'LOADED'
    })

    const inspectionStopping = Object.values(messagesByRobotId).some(messages => {
      const endState = messages?.[0]?.payload.status
      return endState === 'STOPPING'
    })

    const robotIsReady = Object.values(messagesByRobotId).some(messages => {
      const endState = messages?.[0]?.payload.status
      return endState === 'READY'
    })

    const robotIsRunning = Object.values(messagesByRobotId).some(messages => {
      const endState = messages?.[0]?.payload.status
      return endState === 'RUNNING'
    })

    return {
      inspectionLoading,
      inspectionStopping,
      robotIsReady,
      robotIsRunning,
    }
  }, shallowEqual)
}

/**
 * Retrieves the list of inspection and routine data by robot ID directly from VP.
 * This hook will retrieve the latest data that's been deployed to the robot.
 *
 * @param robotIds List of robot IDs in station
 */
export const useInspectionAndRecipeDefinition = (robotIds: string[]) => {
  const { inspectionDefinition, recipeDefinition, latestMessagesByRobot } = useTypedSelector(state => {
    const messagesByRobotId = getStateUpdateMessagesByRobotId(state, robotIds)

    const latestMessagesByRobot = Object.values(messagesByRobotId)
      .map(messages => messages?.[0])
      .filter((message): message is VpStatusStreamMessage => !!message)
    const messageWithDefinitions = latestMessagesByRobot.find(message => message.payload.status === 'RUNNING')

    const inspectionDefinitionEdge = messageWithDefinitions?.payload.data.inspection_definition
    // The started at timestamp received in these vp messages is incompatible with
    // our frontend code, it's received in string format and has the in the shape: 1663869771.5500846
    // in order to use the timestamp we must parse into a number, and multiply by 1000 to get a correct
    // unix timestamp
    const inspectionDefinition = inspectionDefinitionEdge
      ? { ...inspectionDefinitionEdge, started_at: Math.round(inspectionDefinitionEdge.started_at * 1000) }
      : undefined

    const baseRecipeDefinition = messageWithDefinitions?.payload.data.recipe_definition

    const recipeViews: RecipeRoutine[] = []

    // We want to create a new array of views containing the views obtined from its corresponding robots
    latestMessagesByRobot.forEach(message => {
      const robotId = message?.meta?.robot_id
      const currentRobotRecipeDefinition = message.payload.data.recipe_definition
      if (!robotId || !currentRobotRecipeDefinition) return
      const robotViews = currentRobotRecipeDefinition.recipe_routines.filter(view => view.robot_id === robotId)

      recipeViews.push(...robotViews)
    })

    const recipeDefinition: RecipeExpanded | undefined = baseRecipeDefinition
      ? { ...baseRecipeDefinition, recipe_routines: recipeViews }
      : undefined

    return {
      inspectionDefinition,
      recipeDefinition,
      latestMessagesByRobot,
    }
  }, isEqual)

  const messageIds = latestMessagesByRobot
    .map(message => message.message_id)
    .sort()
    .join()

  return useMemo(() => {
    return { inspectionDefinition, recipeDefinition }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageIds])
}

/**
 * DO NOT USE history.goBack as that can lead the user to a previous route outside of our app.
 * Essentially history.goBack does the same your browser does when clicking the left arrow to go back.
 *
 * What we do instead is use locationHistory to see if there is a valid goBack from within the app, else
 * we redirect to the default path.
 *
 * @param defaultPath - Path to redirect the user to if there is no previous path.
 *
 * @returns memoized function that can be used to goBack from the component that uses this hook.
 */
export function useGoBack(defaultPath: string, state?: {}) {
  const history = useHistory()
  const locationHistory = useTypedSelector(state => state.locationHistory)

  let path = defaultPath
  if (locationHistory.history[1]) path = locationHistory.history[1].pathname + locationHistory.history[1].search

  return useCallback(() => history.push(path, state), [history, path, state])
}

/**
 * Throttles or debounces a function.
 * Throttle: the original function be called at most once per specified period.
 * Debounce: the original function be called after the caller stops calling the decorated function after a specified period.

 * Easy to understand visualization here:
 * http://demo.nimius.net/debounce_throttle/
 *
 * @param cb - The function to throttle or debounce
 * @param delay - Time between function executions in ms
 *
 * @returns The modified function
 */
export function useThrottleOrDebounce<T extends any[], G extends any>(
  mode: 'throttle' | 'debounce',
  cb: (...args: T) => G,
  delay: number,
) {
  const cbRef = useRef(cb)
  // Use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb
  })

  const fn = mode === 'throttle' ? throttle : debounce

  return useMemo(() => fn((...args: T): G => cbRef.current(...args), delay), [delay, fn])
}

/**
 * Returns a function that scrolls to position and handles a ref that determines if a
 * programmatic scroll is currently happening
 * @param elementRef Ref for element which will be scrolled
 * @returns [scrollTo, isAutoScrollingRef]
 */
export function useScrollTo(elementRef: React.RefObject<HTMLElement>) {
  const isAutoScrollingRef = useRef(false)
  const scrollTo = useCallback((options: ScrollToOptions, onScrollStop?: () => any) => {
    if (!elementRef.current || Math.abs(elementRef.current.scrollTop - (options.top || 0)) <= 10)
      return onScrollStop?.() // Run scroll stop in case this doesn't run

    isAutoScrollingRef.current = true

    elementRef.current?.scrollTo(options)
    resetAutoScrollingRefWhenScrollStops(onScrollStop)
  }, []) // eslint-disable-line

  const resetAutoScrollingRefWhenScrollStops = (onScrollStop?: () => any) => {
    let isScrolling: number

    const scrollHandler = () => {
      // Clear our timeout throughout the scroll

      window.clearTimeout(isScrolling)

      // Set a timeout to run after scrolling ends
      isScrolling = window.setTimeout(function () {
        // Run the callback
        elementRef.current?.removeEventListener('scroll', scrollHandler)
        isAutoScrollingRef.current = false
        if (onScrollStop) onScrollStop()
      }, 50)
    }

    elementRef.current?.addEventListener('scroll', scrollHandler)
  }

  return [scrollTo, isAutoScrollingRef] as const
}

/**
 * When we programatically scroll, stop events that may result in scrolling,
 * resulting in running scroll handlers.
 *
 * @param stopScrollRef - A ref object. When current value is true, prevents scroll events
 */
export function usePreventUserScroll(stopScrollRef: React.MutableRefObject<boolean>) {
  useEffect(() => {
    const preventScroll = (e: Event) => {
      if (stopScrollRef.current) {
        e.preventDefault()
      }
    }

    window.addEventListener('DOMMouseScroll', preventScroll, false) // older FF
    window.addEventListener('wheel', preventScroll, { passive: false }) // modern desktop
    window.addEventListener('touchmove', preventScroll, { passive: false }) // mobile

    return () => {
      window.removeEventListener('DOMMouseScroll', preventScroll, false)
      window.removeEventListener('wheel', preventScroll)
      window.removeEventListener('touchmove', preventScroll)
    }
  }, [stopScrollRef])
}

/*
 * Gets timezone, date and time formats, those formats are based on the user preferences.
 *
 * @returns \{ timeFormat, timeZone, dateFormat, timeWithSecondsFormat, shortYearDateFormat }
 */
export const useDateTimePreferences = (): {
  timeFormat: 'h:mma' | 'H:mm'
  timeZone: string
  dateFormat: 'DD/MM/YYYY' | 'MM/DD/YYYY'
  timeWithSecondsFormat: 'h:mm:ssa' | 'H:mm:ss'
  shortYearDateFormat: 'DD/MM/YY' | 'MM/DD/YY'
  timeWithMillisecondsFormat: 'hh:mm:ss.SSa' | 'HH:mm:ss.SS'
} => {
  const me = useData(getterKeys.me())

  const timeZone = me?.timezone || LOCAL_TIMEZONE

  const timeFormat = me?.time_format === '12hr' ? 'h:mma' : 'H:mm'
  const timeWithSecondsFormat = me?.time_format === '12hr' ? 'h:mm:ssa' : 'H:mm:ss'
  const timeWithMillisecondsFormat = me?.time_format === '12hr' ? 'hh:mm:ss.SSa' : 'HH:mm:ss.SS'

  const dateFormat = me?.date_format === 'd/m/y' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  const shortYearDateFormat = me?.date_format === 'd/m/y' ? 'DD/MM/YY' : 'MM/DD/YY'

  return { timeFormat, timeZone, dateFormat, timeWithSecondsFormat, shortYearDateFormat, timeWithMillisecondsFormat }
}

/**
 * This hook allows us to calculate grid layouts like with CSS's display:grid programatically.
 * Returns memoized grid rows, columns, and item height and width, based on a number of items and container's width.
 * For use with react-window's grid components
 * @param containerDimensions
 * @param numberOfItems
 * @param options
 * @returns `{ rowCount, columnCount, columnWidth, rowHeight }`
 */
export function useGridDimensions(
  containerWidth: number,
  numberOfItems: number | undefined,
  options?: { gridGap?: number; minWidth?: number; elementRowHeight?: number },
) {
  const { gridGap = 0, minWidth = 161, elementRowHeight = 209 } = options || {}
  const returnValue = useMemo(() => {
    let columnCount = 1
    let columnWidth = 0
    const maxColumns = 10

    // Calculate grid number of columns and column width
    for (let i = 1; i <= maxColumns; i++) {
      if (minWidth * i + gridGap * (i - 1) < containerWidth) {
        columnCount = i
        columnWidth = (containerWidth - gridGap * (i - 1)) / i + gridGap
      }
    }

    const rowCount = Math.ceil((numberOfItems || 0) / columnCount)
    const rowHeight = elementRowHeight + gridGap

    return { rowCount, columnCount, columnWidth, rowHeight }
  }, [containerWidth, elementRowHeight, gridGap, minWidth, numberOfItems])

  return returnValue
}

/**
 * Gets the width and height of element that is passed as ref. If no ref is passed, measure window's width and height.
 *
 * Recalculates dimensions when window is resized.
 *
 * @returns  `containerDimensions: {width, height}`
 */
export function useContainerDimensions(
  elementRef?: React.RefObject<HTMLElement>,
  defaultToWindowDimension: boolean = true,
) {
  const [, setElementDimensions, elementDimensionsRef] = useStateAndRef<{
    width?: number
    height?: number
  }>({})
  useEffect(() => {
    const getElementDimensions = () => {
      const defaultHeight = defaultToWindowDimension ? window.innerHeight : undefined
      const defaultWidth = defaultToWindowDimension ? window.innerWidth : undefined
      setElementDimensions({
        height: elementRef?.current?.clientHeight || defaultHeight,
        width: elementRef?.current?.clientWidth || defaultWidth,
      })
    }

    getElementDimensions()
    window.addEventListener('resize', getElementDimensions)
    return () => {
      window.removeEventListener('resize', getElementDimensions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current])

  return elementDimensionsRef.current
}

/**
 * This hook is in charge of returning if the provided element ref is visible on the screen.
 * It is a wrapper arounnd IntersectionObserver
 *
 * @param ref The target element ref
 * @param options Additional options
 * @param options.rootMargin - string indicating the margin to use before intersection calculations.
 * @param options.thershold - number from 0 - 1 indicating how much of the element should be visible before the intersection triggers
 * 0 means whenever at least 1 px is visible, intersection triggers, 1 means all the element should be visible.
 * @param options.callback - Callback to be called whenever observer callback fires, to ensure this
 * effect is not running on every render, this function must be memoized by the client before passing it to this hook.
 * @returns
 */

export function useOnScreen(
  ref: RefObject<HTMLDivElement>,
  options: {
    rootMargin?: string
    threshold?: number
    callback?: (isIntersection: boolean) => any
    delayMs?: number
    idleCallbackTimeout?: number
  } = {},
) {
  const { rootMargin, threshold, callback, delayMs = 400, idleCallbackTimeout = 100 } = options

  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const { current } = ref
    let timer: NodeJS.Timeout
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            timer = setTimeout(() => {
              setIntersecting(true)
              callback?.(true)
            }, delayMs)
          } else {
            setIntersecting(false)
            clearTimeout(timer)
          }
        })
      },
      {
        rootMargin: rootMargin || '0px',
        threshold: threshold || 0,
      },
    )
    if (current) {
      observer.observe(current)
    }
    return () => {
      if (current) {
        observer.disconnect()
      }
    }
  }, [rootMargin, threshold, callback, delayMs, ref, idleCallbackTimeout])

  return isIntersecting
}

/**
 * This hook  handles a click event that happened outside of the provided element ref.
 *
 * @param ref The target element ref
 * @param handler The handler to call when the click event happens outside of the element ref, to ensure this
 * effect is not running on every render, this function must be memoized by the client before passing it to this hook.
 */
export const useOutsideRefClick = (
  ref: React.RefObject<HTMLElement>,
  callback: (event: MouseEvent) => any,
  preventListener?: boolean,
) => {
  useEffect(() => {
    if (preventListener) return
    const onClick = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) return
      callback(event)
    }

    setTimeout(() => document.addEventListener('click', onClick))

    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [ref, callback, preventListener])
}

/**
 * This hook gives the component which uses it full access to the filters in the query params.
 * Specifically, the filters used here are those which appear on the Analytics screens. This
 * hook converts all the query string filters into properly typed variables which can then be used
 * in the code. This hook also returns an `onUpdateFilters` and an `onResetFilters` functions
 * which let us update the query string filters easily.
 *
 * @param tab - Currently selected tab on analytics screens
 * @returns [fitlers, params] - where filters contains all the active filters on the query string to use and
 *  params contains additional information about the currently selected filters, as well as handlers for
 * updating said filters.
 */
export function useAnalyticsQueryFilters({
  tab,
  isSideGallery,
}: {
  tab: AnalyzeTab
  isSideGallery?: boolean
}): [Filters, ExtraOptions] {
  const [params] = useQueryParams()
  const history = useHistory()

  // If event_id is present in the qs, display the items corresponding to that event
  useEffect(() => {
    async function getEvent() {
      if (!params.event_id) return
      const res = await service.getEvent(params.event_id)
      if (res.type === 'success') {
        const metadata = res.data.meta

        let firstItemRecordTs: string | undefined
        let lastItemRecordTs: string | undefined

        if (metadata.table === 'tool_result') {
          const firstToolResult = await service.getToolResult(metadata.first_record_id)
          const lastToolResult = await service.getToolResult(metadata.last_record_id)

          if (firstToolResult.type === 'success' && lastToolResult.type === 'success') {
            firstItemRecordTs = firstToolResult.data.item?.created_at
            lastItemRecordTs = lastToolResult.data.item?.created_at
          }
        }

        if (metadata.table === 'item') {
          firstItemRecordTs = metadata.first_record_ts
          lastItemRecordTs = metadata.last_record_ts
        }

        const firstMomentRecordTs = firstItemRecordTs ? moment.utc(firstItemRecordTs) : undefined
        const lastMomentRecordTs = lastItemRecordTs ? moment.utc(lastItemRecordTs) : undefined

        history.replace(
          paths.analyze({
            mode: tab,
            params: {
              // Because backend filters are not inclusive, we need to add and subsctract one millisecond to include the appropiate items
              start: firstMomentRecordTs?.subtract(1, 'millisecond').toISOString(true),
              end: lastMomentRecordTs?.add(1, 'millisecond').toISOString(true),
              inspection_id: res.data.inspection_id,
            },
          }),
        )
      }
    }
    getEvent()
    // eslint-disable-next-line
  }, [])

  const filtersAreDefault =
    !params.component_id &&
    !params.inspection_id &&
    !params.recipe_id &&
    !params.user_id &&
    !params.search &&
    !params.serial_number &&
    !params.outcome &&
    !params.selected_item_id &&
    !params.tool_specification &&
    !params.prediction_label_id &&
    !params.user_label_id &&
    params.predictionScore === undefined &&
    params.start === undefined &&
    params.end === undefined &&
    params.site_id === undefined &&
    params.subsite_id === undefined &&
    !params.station_id &&
    (params.period === 'day' || !params.period)

  const componentId = tab === 'products' ? params.expanded || params.component_id : params.component_id
  const inspectionId = tab === 'batches' ? params.expanded || params.inspection_id : params.inspection_id
  const stationId = tab === 'stations' ? params.expanded || params.station_id : params.station_id

  const filterOnLabels = ['items', 'tools'].includes(tab) || isSideGallery

  const extraFilters = {
    event_id: params.event_id,
    component_id: componentId,
    inspection_id: inspectionId,
    recipe_id: params.recipe_id,
    user_id: params.user_id,
    prediction_label_id: filterOnLabels ? convertStringFromArray(params.prediction_label_id) : undefined,
    user_label_id: filterOnLabels ? convertStringFromArray(params.user_label_id) : undefined,
    predictionScore: params.predictionScore
      ? (convertStringFromArray(params.predictionScore)?.map(str => +str) as [number, number])
      : undefined,
    tool_specification: params.tool_specification as ToolSpecificationName,
    search: params.search,
    serial_number: params.serial_number,
    outcome: params.outcome as Outcome,
    insightsActive: !!params.insightsActive,
    selected_item_id: convertStringFromArray(params.selected_item_id),
    site_id: params.site_id,
    station_subtype_id: params.station_subtype_id,
    subsite_id: params.subsite_id,
    station_id: stationId,
    subsite_type_id: params.subsite_type_id,
  }

  const end = useMemo(() => {
    // When start and end date are undefined, we reset to 7 last days
    if (params.start === undefined && params.end === undefined) {
      return momentTz().endOf('day')
    } else {
      // On the other hand, if the values are null, we do want to set them, which applies an "All time" filter
      return params.end ? momentTz(params.end) : null
    }
  }, [params.end, params.start])

  const start = useMemo(() => {
    // When start and end date are undefined, we reset to 7 last days
    if (params.start === undefined && params.end === undefined) {
      return momentTz().subtract(7, 'days').startOf('day')
    } else {
      // On the other hand, if the values are null, we do want to set them, which applies an "All time" filter
      return params.start ? momentTz(params.start) : null
    }
  }, [params.end, params.start])

  const expanded = params.expanded

  let viewMode
  if (tab === 'items' || tab === 'tools') viewMode = (params.viewMode as 'gallery' | 'list') || 'gallery'

  let period = (params.period as TimeSeriesDatePeriod) || 'day'
  const periodsAvailable = computeAvailablePeriodsForDateRange(start, end)
  if (period) period = computeCurrentPeriodForDateRange(start, end, period)

  const onUpdateFilters: FiltersUpdater = useMemo(
    () =>
      debounce((changes, routerType = 'replace') => {
        const newParams = { ...params }

        for (const key in changes) {
          const value = changes[key as keyof typeof changes]
          if (isMoment(value)) newParams[key] = value.format()
          else if (typeof value === 'string') newParams[key] = value
          else if (typeof value === 'number') newParams[key] = value.toFixed()
          else if (typeof value === 'boolean' && value) newParams[key] = 'true'
          else if (typeof value === 'boolean' && !value) delete newParams[key]
          else if (Array.isArray(value)) {
            newParams[key] = convertArrayToString(value)

            // This means the dash options was chosen, so delete choices
            if (typeof value[0] === 'string' && (value as string[]).includes('')) delete newParams[key]
          }

          // when undefined or empty string is sent as a value, the parameter is deleted from the QS
          if (value === undefined || value === '') delete newParams[key]
          // When null is sent as a value, the parameter is set as empty, which has different functionality in some cases
          if (value === null) newParams[key] = ''
        }
        const newPath = { pathname: history.location.pathname, search: qs.stringify(newParams) }
        if (routerType === 'push') history.push(newPath)
        else history.replace(newPath)
      }, 500),
    [history, params],
  )

  const onResetFilters = () => {
    const newPath = {
      pathname: history.location.pathname,
      search: qs.stringify({ tab: params.tab, expanded: params.expanded, viewMode: params.viewMode }),
    }

    history.replace(newPath)
  }

  const extraFiltersKey = Object.entries(extraFilters)
    .sort()
    .map(([key, value]) => `${key}=${value}`)
    .join()

  const filterKey = `${end?.toString()}${start?.toString()}${extraFiltersKey}`

  return [
    {
      end,
      start,
      period,
      ...extraFilters,
    },
    {
      periodsAvailable,
      viewMode,
      onUpdateFilters,
      filtersAreDefault,
      onResetFilters,
      expanded,
      filterKey,
    },
  ]
}

/**
 * This hook fetches item metrics based on the query string parameters found.
 *
 * @param tab - Selected Analyze tab. This determines which filters to use
 * @returns {filteredMetrics, isFetching} - whether the element is fetching results, and the results themselves
 */
export const useAnalyzeItemMetrics = (
  tab: AnalyzeTab,
): { itemMetrics: TimeSeriesResult[] | undefined; isFetching: boolean } => {
  const [isFetching, setIsFetching] = useState(false)
  const [
    {
      component_id,
      inspection_id,
      recipe_id,
      user_id,
      station_id,
      outcome,
      start,
      end,
      site_id,
      subsite_id,
      subsite_type_id,
    },
    { periodsAvailable },
  ] = useAnalyticsQueryFilters({ tab })

  const filters = {
    component_id,
    inspection_id,
    recipe_id,
    user_id,
    station_id,
    outcome,
    site_id,
    subsite_id,
    subsite_type_id,
  }

  const metricsLabels = getAnalyzeMetricsLabels(filters)

  const filtersKey = Object.keys(filters)
    .sort()
    .map(key => `${key}=${filters[key as keyof typeof filters]}`)
    .join()

  const refetchKey = `${filtersKey}${start?.toString()}${end?.toString()}`

  const durationInMs = isMoment(end) && isMoment(start) ? (end || momentTz()).diff(start) : undefined

  const { rtsParams } = getRtsParams({
    inspectionDuration: durationInMs,
    seriesType:
      'inspection_created_by_id' in metricsLabels || 'inspection_id' in metricsLabels
        ? 'item_outcome_inspection'
        : 'item_outcome_station',
    labels: metricsLabels,
    rtsDatePeriod: {
      from_s: start ? start.unix() : undefined,
      to_s: end ? end.unix() : undefined,
    },
    metricParams: {},
  })

  const agg_s = getAnalyzeAggS(periodsAvailable)

  const itemMetrics = useQuery(
    getterKeys.metricsInspectionMonitorItems(),
    async () => {
      setIsFetching(true)
      const res = await service.readTimeSeries({ ...rtsParams, agg_s })

      setIsFetching(false)
      return res
    },
    { refetchKey },
  ).data?.data

  const filteredMetrics: TimeSeriesResult[] | undefined = useMemo(() => {
    // Filter by date
    return itemMetrics?.results.map(result => ({
      ...result,
      entries: result.entries.filter(data => {
        // Start of day and end of day relative to browser timezone
        if (start && data[0] < moment(start).startOf('day').unix()) return false
        if (end && data[0] >= moment(end).endOf('day').unix()) return false
        return true
      }),
    }))
  }, [end, itemMetrics, start])

  return { itemMetrics: filteredMetrics, isFetching } as const
}

type LabelMetricsParams = {
  paramFilters: Partial<Filters> & Partial<ExtraOptions>
  type?: TimeSeriesType
  inspectionSeriesType?: TimeSeriesType
  preventFetch?: boolean
}
/**
 * This hook fetches label metrics based on a list of params sent to the hook.
 *
 * @param paramFilters - filters to use for fetching re label results
 * @param type - the type of result we wish to obtain, has to match one of the rts valid strings.
 * @param inspectionSeriesType - For some filter combinations we need to fallback to `_inspection` series type
 *
 * @returns {filteredMetrics, isFetching} - whether the element is fetching results, and the results themselves
 */
export const useLabelMetrics = ({
  paramFilters,
  type = 'item_label_station',
  inspectionSeriesType = 'item_label_inspection',
  preventFetch,
}: LabelMetricsParams): {
  labelMetrics: TimeSeriesResult[] | undefined
  isFetchingLabelMetrics: boolean
  isFetchingRef: React.MutableRefObject<boolean>
} => {
  const dispatch = useDispatch()
  const connectionStatus = useConnectionStatus()
  const {
    start,
    end,
    component_id,
    inspection_id,
    user_id,
    station_id,
    outcome,
    tool_parent_id,
    site_id,
    subsite_id,
    subsite_type_id,
    recipe_id,
  } = paramFilters

  const momentStart = start || moment(METRICS_START_DATE_MS)
  const momentEnd = end || moment()

  const filters = {
    component_id,
    inspection_id,
    user_id,
    station_id,
    outcome,
    tool_parent_id,
    site_id,
    subsite_type_id,
    subsite_id,
    recipe_id,
  }
  const [isFetchingLabelMetrics, setIsFetchingLabelMetrics, isFetchingLabelMetricsRef] = useStateAndRef(false)

  const durationMs = momentEnd.diff(momentStart)

  const metricsLabels = getAnalyzeMetricsLabels(filters)

  const { rtsParams } = getRtsParams({
    inspectionDuration: durationMs,
    labels: metricsLabels,
    seriesType:
      'inspection_created_by_id' in metricsLabels || 'inspection_id' in metricsLabels ? inspectionSeriesType : type,
    metricParams: { onlyCounts: true },
    rtsDatePeriod: {
      from_s: start?.unix(),
      to_s: end?.unix(),
    },
  })

  const filtersKey = Object.keys(filters)
    .sort()
    .map(key => `${key}=${filters[key as keyof typeof filters]}`)
    .join()

  const refetchKey = `${filtersKey}${start?.toString()}${end?.toString()}${connectionStatus}`

  const labelMetricsKey = getterKeys.metricsInspectionLabels()

  const labelMetricsGetter = async () => {
    setIsFetchingLabelMetrics(true)

    const res = await service.readTimeSeries(rtsParams)

    setIsFetchingLabelMetrics(false)
    return res
  }

  const labelMetrics = useQuery(
    preventFetch ? undefined : labelMetricsKey,
    preventFetch ? undefined : labelMetricsGetter,
    {
      refetchKey,
      intervalRedefineFetcher: true,
    },
  ).data?.data

  const recentToolResults = useData(inspection_id ? getterKeys.inspectionRecentToolResults(inspection_id) : undefined)

  useEffect(() => {
    if (preventFetch || !recentToolResults) return

    setTimeout(() => {
      if (labelMetricsKey) query(labelMetricsKey, labelMetricsGetter, { dispatch })
    }, 500)
  }, [recentToolResults, labelMetricsKey]) // eslint-disable-line

  return {
    labelMetrics: labelMetrics?.results,
    isFetchingLabelMetrics,
    isFetchingRef: isFetchingLabelMetricsRef,
  } as const
}

/**
 * This hook is in charge of setting a keydown event handler used for hotkeys implementations.
 * It prevents the hot key from firing up if the active element is an input or a textarea.
 *
 * By default active elements allow hotkey interactions unless the active element is an input element.
 * You can override allowing active input elements to execute hotkeys anyways. For example the search label input in the labeling screen allows you to label while searching.
 * To do this, you must add the prop data-allowedhotkeys to the element you want to be able to execute hotkeys while it's active, with the hotkey values you're allowing.
 *
 * @param handler - hot key handler, it should be wrapped by useCallback
 * @param options.capture - option passed to addEventListener, if true, the event will be dispatched first to this listener, used to catch events before other listeners trigger.
 * @param options.allowedInputTypes - By default the handler is not called if the active element is an 'INPUT', however if the input type matches some of the provided allowedTypes, it will be called.
 */
export const useHotkeyPress = (handler: (e: KeyboardEvent) => void, options?: { capture?: boolean }) => {
  const keyHandlerWrapper = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey) return
      const activeElement = document.activeElement
      const nodeName = activeElement?.nodeName

      if (nodeName === 'TEXTAREA' || nodeName === 'INPUT') {
        const key = e.key.toLowerCase()

        const typedInputElement = activeElement as HTMLInputElement
        const allowedHotKeys = typedInputElement.dataset.allowedhotkeys?.split('|').map(key => key.toLowerCase()) || []

        if (!allowedHotKeys.includes(key)) return
      }

      handler(e)
    },
    [handler],
  )

  useEffect(() => {
    document.addEventListener('keydown', keyHandlerWrapper, options?.capture)

    return () => {
      document.removeEventListener('keydown', keyHandlerWrapper, options?.capture)
    }
  }, [keyHandlerWrapper, options?.capture])
}

export const useToolTrainingResults = (toolId?: string): TrainingResultFlat[] | undefined => {
  return useQuery(
    toolId ? getterKeys.toolTrainingResults(toolId) : undefined,
    toolId
      ? async () => {
          const res = await service.getToolTrainingResults(toolId)
          if (res.type !== 'success') return { ...res, queryData: null }
          if (res.data.results.length === 0) return { ...res, type: 'error', queryData: null }
          return res
        }
      : undefined,
    { noRefetch: true, dedupe: true },
  ).data?.data.results
}

/**
 * This hook is used to fetch only the organization default labels.
 *
 * @param preventFetch - If fetch should be prevented, used to delay fetch until some condition is met
 */
export const useDefaultToolLabels = () => {
  const defaultLabels = useQuery(
    getterKeys.toolLabels(DEFAULT_TOOL_LABELS_GETTER_KEY),
    () =>
      service.getToolLabels({
        kind__in: 'default',
      }),
    { noRefetch: true, dedupe: true },
  ).data?.data.results

  return defaultLabels
}

/** This hook adds the Heap integration script to the DOM, and customized the window.heap object with
 * the current user and organization.
 */
export function useHeap() {
  const me = useData(getterKeys.me())
  const currentUserOrg = useTypedSelector(state => state.currentOrg)
  const organization = useData(getterKeys.organization())
  const heapInitialized = useRef(false)
  function createHeapScript() {
    if (heapInitialized.current || !HEAP_APP_ID) return
    heapInitialized.current = true
    initializeHeap()
  }

  /**
   * Waits for Heap to load and then enriches the Heap user with
   * the user's organization, name, role, and email.
   *
   */
  const enrichHeapUser = useCallback(async () => {
    const loaded = await waitFor(() => !!(window.heap && window.heap.loaded))
    if (!loaded) return // In case Heap is down
    const heap = window.heap
    if (me && organization && heap?.loaded) {
      heap.identify(me.email)
      heap.addUserProperties({
        'User organization': organization.name,
        'User role': currentUserOrg?.role,
        email: me.email,
        'User name': `${me.first_name} ${me.last_name}`,
      })
    }
  }, [currentUserOrg?.role, me, organization])

  /**
   * If Heap is available, reset the user identity, otherwise do nothing.
   */
  function resetHeapIdentity() {
    window.heap?.resetIdentity?.()
  }

  useEffect(() => {
    if (organization && me && HEAP_APP_ID) {
      if (organization.user_tracking_enabled) {
        createHeapScript()

        enrichHeapUser()
      } else {
        // In case tracking is disabled while a user already has the app loaded
        document.getElementById(GENERATED_HEAP_SCRIPT_ID)?.remove()
        window.heap = undefined
      }
    }

    if (!me) {
      // If user logs out, reset the Heap identity
      resetHeapIdentity()
    }
  }, [enrichHeapUser, me, organization])
}

/**
 * This hook is in charge of getting all the related Tool Labels for the provided Tools.
 * This hook considers custom and default ToolLabels. This hook will return undefined if no tool
 * is defined, or if the default and custon labels haven't been fetched
 *
 * @param tools - Array of Tools, used to know which labels will be returned.
 */
export const useToolLabels = (tools?: Pick<Tool, 'specification_name' | 'parent_id'>[]) => {
  const defaultLabels = useDefaultToolLabels()

  const parentIds = tools
    ?.map(tool => tool.parent_id)
    .sort((a, b) => a.localeCompare(b))
    .join()

  const customLabels = useQuery(parentIds ? getterKeys.toolLabels(parentIds) : null, () =>
    service.getToolLabels({ tool_parent_id__in: parentIds }),
  ).data?.data.results

  // Finds the default labels to use according to the tool specification name
  const defaultLabelsToUse = useMemo(() => {
    if (!defaultLabels) return

    const labels = []

    if (
      tools?.some(tool => tool.specification_name === 'deep-svdd') ||
      tools?.some(tool => tool.specification_name === 'classifier')
    ) {
      labels.push(...findMultipleToolLabelsByPartialData(defaultLabels, ANOMALY_DEFECT_TOOL_LABELS))
    }

    if (tools?.some(tool => tool.specification_name === 'graded-anomaly')) {
      labels.push(...findMultipleToolLabelsByPartialData(defaultLabels, GRADED_ANOMALY_TOOL_LABELS))
    }

    labels.push(...findMultipleToolLabelsByPartialData(defaultLabels, [UNCERTAIN_LABEL, DISCARD_LABEL, TEST_SET_LABEL]))
    return labels
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLabels, parentIds])

  const allLabels = useMemo(() => {
    if (!defaultLabelsToUse || !customLabels) return
    const uniqueLabels = uniqBy([...defaultLabelsToUse, ...customLabels], label => label.id)

    return uniqueLabels
  }, [customLabels, defaultLabelsToUse])

  return allLabels
}

const ALL_TOOL_LABELS_PAGE_SIZE = 1000

/**
 * This hook is in charge of fetching all the Tool Labels in the org, including default and custom labels.
 * @param options.ignoreDerivativeLabels - Allows ignoring derivative labels in the allToolLabels array
 *
 * @returns { defaultLabels, customLabels, allToolLabels } - list of  default labels, custom labels, and a complete list of labels
 */
export const useAllToolLabels = ({
  ignoreDerivativeLabels,
  ignoreUnusedLabels,
  ignoreNonTrainingLabels,
  ignoreDeletedLabels,
}: {
  ignoreDerivativeLabels?: boolean
  ignoreUnusedLabels?: boolean
  ignoreNonTrainingLabels?: boolean
  ignoreDeletedLabels?: boolean
} = {}): {
  defaultLabels: ToolLabel[] | undefined
  customLabels: ToolLabel[] | undefined
  allToolLabels: ToolLabel[] | undefined
} => {
  const defaultLabels = useDefaultToolLabels()
  const customLabels = useQuery(
    getterKeys.toolLabels(ALL_TOOL_LABELS_GETTER_KEY),
    () => service.getToolLabels({ kind__in: 'custom', page_size: ALL_TOOL_LABELS_PAGE_SIZE }),
    { noRefetch: true, noRefetchMs: 10 * 60 * 1000, dedupe: true },
  ).data?.data.results

  const allToolLabels = useMemo(() => {
    if (!defaultLabels || !customLabels) return
    let labels = [...defaultLabels, ...customLabels]
    if (ignoreNonTrainingLabels) labels = labels.filter(toolLabel => !isNonTrainingLabel(toolLabel))
    if (ignoreDerivativeLabels) labels = filterOutDerivativeLabels(labels)
    if (ignoreUnusedLabels) labels = filterOutUnusedLabels(labels)
    if (ignoreDeletedLabels) labels = filterOutDeletedLabels(labels)
    return labels
  }, [
    customLabels,
    defaultLabels,
    ignoreDeletedLabels,
    ignoreDerivativeLabels,
    ignoreNonTrainingLabels,
    ignoreUnusedLabels,
  ])

  return { defaultLabels, customLabels, allToolLabels }
}

/**
 * Fetches robots discoveries directly from the edge. In the future, the discovery endpoint
 * will be able to handle multiple Robot IDs, until then, we use an array of promises
 * @param robotId - robot id to fetch capabilities from
 */
export const useRobotDiscovery = (robotIds?: string[]): RobotDiscoveriesById => {
  const dispatch = useDispatch()
  const robotIdsKey = robotIds?.sort().join()
  useEffect(() => {
    if (robotIds) getAndSetRobotCapabilities(dispatch, robotIds)
    // eslint-disable-next-line
  }, [robotIdsKey])

  const allRobotDiscoveries = useTypedSelector(state => state.robotDiscoveriesById)
  const robotDiscoveriesById: RobotDiscoveriesById = {}

  robotIds?.forEach(robotId => {
    const robotDiscovery = allRobotDiscoveries[robotId]
    if (robotDiscovery) robotDiscoveriesById[robotId] = robotDiscovery
  })
  return robotDiscoveriesById
}

/**
 * Returns the overall Station status based on the priority of its robots status.
 *
 * @param station - Station to get status from.
 */
export const useStationStatus = <T extends { robots: Robot[] }>(station?: T): CameraStatus => {
  const stationStatus = useTypedSelector(state => {
    if (!station) return 'loading'
    let status: CameraStatus = 'disconnected'

    for (const robot of station.robots) {
      const currRobotStatus = getRobotStatus(robot.id, { state })
      if (STATUS_PRIORITY[currRobotStatus] > STATUS_PRIORITY[status]) status = currRobotStatus
    }

    return status
  })
  return stationStatus
}

/**
 * Returns the internet connectivity status of the app.
 * Useful to drive rerenders and component logic around said status
 *
 */
export const useConnectionStatus = () => {
  return useTypedSelector(state => state.connectionStatus.status)
}

/**
 * Returns the colocated status of the app.
 *
 */
export const useIsColocated = () => {
  const dispatch = useDispatch()

  // Since we check colocated robots every 10 seconds, we want to make sure this hook also checks to avoid race conditions
  useEffect(() => {
    getAndSetEdgeParams(dispatch)
  }, [dispatch])

  const edge = useTypedSelector(state => state.edge)
  const robotIds = useMemo(() => getRobotIdsFromEdgeParams(edge), [edge])

  return { isColocated: robotIds.length > 0, colocatedRobotIds: robotIds }
}

/**
 * Hook that wraps around multiple hooks to return items for the analyze creen
 * @tab - Currently selected tab on analytics screens
 */
export const useItemsForAnalyzeWithProxyResults = ({ filters }: { filters: Filters }) => {
  const dispatch = useDispatch()
  const [reloadingItems, setReloadingItems] = useState(false)
  const [loadingMore, setLoadingMore, loadingMoreRef] = useStateAndRef(false)

  const paramFilters = convertAllFiltersToBackendQueryParams(filters)
  const backendFilters = useMemo(() => {
    return {
      ...paramFilters,
      search: undefined,
    }
  }, [paramFilters])

  useInspectionsProxyResults({
    getterKey: getterKeys.analyticsItems(),
    holdOff: 'event_id' in paramFilters,
    fetcher: scanInspectionIds =>
      service.getItems(
        backendFilters,
        scanInspectionIds ? { body: { scan_inspection_ids: scanInspectionIds }, method: 'POST' } : {},
      ),
    setReloadingResults: setReloadingItems,
    proxyGetterKey: 'analyzeItems',
    inspectionFilters: paramFilters,
    predictionScoreRangeFiltersActive: false,
    predictionScoreSortingActive: false,
  })

  const inspectionsForProxyFiltersData = useData(getterKeys.inspectionsForFiltersProxy('analyzeItems'))
  const inspectionsForProxyFilters = useMemo(
    () => inspectionsForProxyFiltersData?.results,
    [inspectionsForProxyFiltersData?.results],
  )

  const itemsData = useData(getterKeys.analyticsItems())

  const items = useMemo(() => {
    const { filtersCount: proxiesCount } = getFiltersToProxyInspections(paramFilters)
    // if we have filters that need to be proxied by inspections and we have exactly 0 inspections, it means we don't have results
    if (proxiesCount > 0 && inspectionsForProxyFilters?.length === 0) return []
    return itemsData?.results
  }, [inspectionsForProxyFilters?.length, itemsData?.results, paramFilters])

  // if we are fetching with inspections as proxy, this is the last scanned inspection id
  const lastScannedInspectionId = itemsData?.last_inspection_id
  const nextInspectionsPage = inspectionsForProxyFiltersData?.next
  const nextItemsPage = itemsData?.next || ''

  const handleEndReached = useCallback(async () => {
    if (!items || reloadingItems) return
    await handleInspectionsProxyResultsEndReached({
      getterKey: getterKeys.analyticsItems(),
      isLoadingMoreRef: loadingMoreRef,
      paramFilters,
      inspectionsForProxy: inspectionsForProxyFilters,
      setIsLoadingMore: setLoadingMore,
      proxyGetterKey: 'analyzeItems',
      dispatch,
      nextResultsPage: nextItemsPage,
      lastScannedInspectionId,
      nextInspectionsPage,
      resultsFetcher: scanIds =>
        service.getItems(backendFilters, { body: { scan_inspection_ids: scanIds }, method: 'POST' }),
    })
  }, [
    backendFilters,
    dispatch,
    inspectionsForProxyFilters,
    items,
    lastScannedInspectionId,
    loadingMoreRef,
    nextInspectionsPage,
    nextItemsPage,
    paramFilters,
    reloadingItems,
    setLoadingMore,
  ])

  return { items, handleEndReached, loadingMore, reloadingItems }
}

const MAX_ADDINTIONAL_INSPECTIONS_FOR_PROXY_PAGES_ON_INITIAL_LOAD = 4

/**
 * This hook is in charge of fetching Items or Tool Results in case we need inspections to proxy the provided filters, those inspections will be fetched before the results.
 *
 * @param getterKey - getter key
 * @param fetcher - service used to fetch results, the fetcher should support fetching results with scanInspectionIds
 * @param setReloadingState - function to set if results are loading
 * @param inspectionFilters - current param filters, used to fetch matching inspections for proxy
 * @param resultsFilters - filters to send to the service when fetching results
 * @param holdOff - Whether we should hold off on fetching. This can be used as a secondary dependency on the hook
 * @param refetchKey - Key used to trigger a refetch
 * @param nullPredictionFechedRef - A boolean ref used to track if we already fetched tool results with null prediction score
 * @param predictionScoreSortingActive - Whether prediction score sorting is active
 * @param predictionScoreRangeFiltersActive - Whether prediction score range filters are active
 */
export const useInspectionsProxyResults = <
  U extends ReturnType<(typeof getterKeys)['analyticsItems' | 'toolResults' | 'toolParentToolResults']>,
  V extends ListOrNever<GetterData<U>, ListItem<GetterData<U>>>,
>({
  getterKey,
  fetcher,
  setReloadingResults,
  inspectionFilters,
  proxyGetterKey,
  additionalInspectionFilters,
  holdOff,
  refetchKey,
  nullPredictionsFetchedRef,
  predictionScoreSortingActive,
  predictionScoreRangeFiltersActive,
}: {
  getterKey: U
  fetcher: (scanInspectionIds?: string[], additionalParams?: {}) => Promise<SendToApiResponse<V>>
  setReloadingResults: (reloading: boolean) => void
  inspectionFilters: { [key: string]: string | number | undefined }
  proxyGetterKey: string
  additionalInspectionFilters?: { [key: string]: string }
  holdOff?: boolean
  refetchKey?: string | number
  predictionScoreSortingActive?: boolean
  predictionScoreRangeFiltersActive?: boolean
  nullPredictionsFetchedRef?: React.MutableRefObject<boolean>
}) => {
  const dispatch = useDispatch()

  const filtersKey = Object.entries(inspectionFilters)
    .sort()
    .reduce((current, [key, val]) => {
      // A change in showPredictionScore switch should not trigger a refetch
      if (key === 'showPredictionScore') return current
      return `${current},${key}:${val}`
    }, '')

  const refreshKeyToUse = `${filtersKey}${refetchKey}`

  useEffect(() => {
    const fetchResults = async (
      nextPage?: string,
      prevResultsRes?: SuccessResponseOnlyData<V>,
      inspectionsPagesFetched?: number,
    ): Promise<void> => {
      if (holdOff) return
      setReloadingResults(true)
      // If the client passes this ref, we want to reset it, it is used later
      // to track if we already have fetched results with null prediction score.
      if (nullPredictionsFetchedRef) nullPredictionsFetchedRef.current = false

      const inspectionRes = await fetchInspectionsForProxy({
        paramFilters: inspectionFilters,
        additionalFilters: additionalInspectionFilters,
        proxyGetterKey,
        dispatch,
        next: nextPage,
      })

      const inspections = inspectionRes?.data.results

      // if we have exactly 0 inspections, we don't need to fetch anything
      // We check `=== 0` to avoid returning in case inspections are `undefined`
      if (inspections?.length === 0) {
        setReloadingResults(false)
        return
      }

      const currentResultsRes = await fetcher(inspections ? inspections.map(inspection => inspection.id) : undefined)

      if (currentResultsRes?.type !== 'success') {
        setReloadingResults(false)
        return
      }

      // On the initial fetch, we just return the results response, if another fetch is needed,
      // we add the new results as a new page.
      const resultsRes: SuccessResponseOnlyData<V> = prevResultsRes
        ? getterAddPage(prevResultsRes, currentResultsRes.data)
        : currentResultsRes

      const nextInspectionsPage = inspectionRes?.data.next
      dispatch(
        Actions.getterUpdate({
          key: getterKey,
          updater: () => {
            return resultsRes
          },
        }),
      )

      // If we don't find enough results to fill a page, and we are using inspections proxies, and we have a next page,
      // we fetch that next page and get more results.
      if (resultsRes.data.results.length < LABEL_SCREEN_TOOL_RESULTS_PAGE_SIZE && nextInspectionsPage) {
        // We will only fetch a given number of additional inspections pages.
        if (
          inspectionsPagesFetched &&
          inspectionsPagesFetched >= MAX_ADDINTIONAL_INSPECTIONS_FOR_PROXY_PAGES_ON_INITIAL_LOAD
        ) {
          setReloadingResults(false)
          return
        }
        return fetchResults(nextInspectionsPage, resultsRes, (inspectionsPagesFetched || 0) + 1)
      }

      // If we are fetching results with prediction_score filters and we don't have a full page of
      // results in the initial fetch, we need to fetch the tool results with null prediction score if needed.
      if (
        nullPredictionsFetchedRef !== undefined &&
        resultsRes.data.results.length < LABEL_SCREEN_TOOL_RESULTS_PAGE_SIZE
      ) {
        await fetchToolResultsWithNullPredictionScore({
          nullPredictionsFetchedRef,
          getterKey,
          resultsFetcher: fetcher,
          inspectionsForProxy: inspections,
          dispatch,
          predictionScoreSortingActive,
          predictionScoreRangeFiltersActive,
        })

        // EDGE CASE: There is an unlikely scenario where we could have a next page of flat inspections to fetch,
        // In this case, as we are alredy fetching the null results we want to set the next flat inspections page as null so that we don't try to fetch it.
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionsForFiltersProxy(proxyGetterKey),
            updater: res => {
              if (!res) return null
              return {
                ...res,
                data: { ...res.data, next: null },
              }
            },
          }),
        )
      }

      setReloadingResults(false)
    }

    fetchResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKeyToUse, holdOff])
}

/**
 * This hook returns training metrics used to display accuracy.
 * Returns the overall and by label id images used and successful predictions.
 *
 * @param tool - Current tool
 * @params toolFilteredTrainingResults - Filtered training results
 * @params confusionMatrix - The current computed confusion matrix
 * @params labelsToInclude - If provided, only these labels will be considered for the metrics calculation
 */
export const useTrainingMetrics = (
  tool: ToolFlat | undefined,
  toolFilteredTrainingResults?: TrainingResultFlat[],
  confusionMatrix?: ConfusionMatrix,
  labelsToInclude?: ToolLabel[],
): TrainingMetrics | undefined => {
  const trainingResults = useToolTrainingResults(tool?.id)
  const fetchedTool = useQuery(
    tool ? getterKeys.tool(tool.id) : undefined,
    tool ? () => service.getTool(tool.id) : undefined,
  ).data?.data
  // We need to fetch all the views to get the correct threshold from the routine -> tool link
  const toolViews = useQuery(fetchedTool ? getterKeys.toolParentRecipeRoutines(fetchedTool.parent_id) : undefined, () =>
    service.getRecipeRoutines({ tool_parent_id: fetchedTool?.parent_id, is_working_version: true }),
  ).data?.data.results
  const { defaultLabels, allToolLabels } = useAllToolLabels()

  const filteredTrainingResults = toolFilteredTrainingResults || trainingResults

  const threshold = tool ? getThresholdFromTool(tool) : { threshold: 0, userFacingThreshold: 0 }
  const backendThresholdByRoutine = fetchedTool && toolViews && getInitialThresholdByView(fetchedTool, toolViews)
  const params: {
    threshold: BackendThreshold
    defaultLabels: ToolLabel[] | undefined
    allToolLabels: ToolLabel[] | undefined
    backendThresholdByRoutine: ThresholdByRoutineParentId | undefined
  } = { threshold, defaultLabels, allToolLabels, backendThresholdByRoutine }
  if (!defaultLabels || !allToolLabels || !trainingResults) return
  const toolConfusionMatrix =
    filteredTrainingResults && tool?.specification_name && !confusionMatrix
      ? computeConfusionMatrix(filteredTrainingResults, tool?.specification_name, params)
      : {}

  const confusionMatrixToUse = confusionMatrix || toolConfusionMatrix

  const labelsToIncludeIds = labelsToInclude?.map(label => label.id)

  const trainingMetrics = calculateTrainingMetrics(confusionMatrixToUse, allToolLabels, tool, labelsToIncludeIds)
  return trainingMetrics
}

/**
 * This hook is in charge of fetching AOI and tool labels metrics at the same time
 * Used to calculate the percentage of items that have a certain label
 * If no inspection is passed in, this hook will not fetch any metrics
 *
 * @param manualTriger - Whether manual trigger is active for current inspection
 * @param inspection - Inspection for which to fetch metrics
 * @param preventFetch - Whether fetch should be prevented
 * @param shouldFetchLabelMetrics - Whether the hook should fetch label metrics, if false, it will only fetch AOI metrics
 * @param isHistoricBatch - Whether the inspection passed in is a historic batch, to use less granular metrics
 *
 */
export const useAoiAndLabelInspectionMetrics = ({
  manualTrigger,
  inspection,
  preventFetch,
  shouldFetchLabelMetrics,
  isHistoricBatch,
}: {
  manualTrigger: boolean
  inspection: Inspection | undefined
  preventFetch?: boolean
  shouldFetchLabelMetrics?: boolean
  isHistoricBatch: boolean
}) => {
  const dispatch = useDispatch()

  const [isFetchingLabelMetrics, setIsFetchingLabelMetrics, isFetchingLabelMetricsRef] = useStateAndRef(false)

  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)

  const getterKey = inspection ? getterKeys.aoiAndLabelMetrics(inspection.id) : undefined

  const { rtsParams: rtsLabelParams } = getRtsParams({
    inspectionDuration: inspectionDurationMsRef.current,
    seriesType: 'tool_result_label_inspection',
    rtsDatePeriod: {
      from_s: inspection ? moment(inspection.started_at).unix() : undefined,
      to_s: isHistoricBatch && inspection?.ended_at ? moment(inspection.ended_at).unix() : undefined,
    },
    labels: { inspection_id: inspection?.id },
    metricParams: { onlyCounts: true },
  })

  const fetchLabelMetrics = () => {
    return service.readTimeSeries(rtsLabelParams)
  }

  const { rtsParams: rtsAoiParams, pollIntervalMs } = getRtsParams({
    inspectionDuration: inspectionDurationMsRef.current,
    seriesType: 'tool_result_outcome_inspection',
    rtsDatePeriod: {
      from_s: inspection ? moment(inspection.started_at).unix() : undefined,
      to_s: isHistoricBatch && inspection?.ended_at ? moment(inspection.ended_at).unix() : undefined,
    },
    labels: { inspection_id: inspection?.id },
    metricParams: { isHistoricBatch },
  })

  const fetchAoiMetrics = () => {
    return service.readTimeSeries(rtsAoiParams)
  }

  const metricsGetter = async () => {
    setIsFetchingLabelMetrics(true)

    const promises = [fetchAoiMetrics()]
    if (shouldFetchLabelMetrics) promises.push(fetchLabelMetrics())

    const allRes = await Promise.all(promises)

    const [aoiRes, labelsRes] = allRes

    const data = {
      aoiMetrics: aoiRes?.type === 'success' ? aoiRes.data.results : undefined,
      labelMetrics: labelsRes?.type === 'success' ? labelsRes.data.results : undefined,
    }

    // We compose a response object from the two real responses
    const aoiAndLabelsRes: SendToApiResponseOnlyData<AoiAndLabelMetrics> = {
      type: 'success',
      queryData: {
        data,
      },
      data,
    }

    setIsFetchingLabelMetrics(false)

    return aoiAndLabelsRes
  }

  const aoiAndLabelMetrics = useQuery(preventFetch ? undefined : getterKey, preventFetch ? undefined : metricsGetter, {
    intervalMs: pollIntervalMs,
    intervalRedefineFetcher: true,
    refetchKey: shouldFetchLabelMetrics,
  }).data?.data

  const recentToolResults = useData(inspection ? getterKeys.inspectionRecentToolResults(inspection.id) : undefined)

  useEffect(() => {
    if (!manualTrigger || preventFetch) return

    setTimeout(() => {
      if (getterKey) query(getterKey, metricsGetter, { dispatch })
    }, 500)
  }, [recentToolResults, manualTrigger, getterKey]) // eslint-disable-line

  return {
    labelMetrics: aoiAndLabelMetrics?.labelMetrics,
    aoiMetrics: aoiAndLabelMetrics?.aoiMetrics,
    isFetchingLabelMetrics,
    isFetchingRef: isFetchingLabelMetricsRef,
  } as const
}

export const useToolsetsByRobotId = (robotIds: string[] | undefined, toolsetFilter?: (toolset: Toolset) => boolean) => {
  const { toolsetsByRobot, allRobotsDataMs } = useTypedSelector(state => {
    const toolsetsByRobot: { [robotId: string]: Toolset[] } = {}
    const allRobotsDataMs: string[] = []
    robotIds?.forEach(robotId => {
      const toolsetRes = state.getter[getterKeys.robotToolsets(robotId)]
      if (toolsetRes?.dataMs) {
        allRobotsDataMs.push(toolsetRes.dataMs.toString())
      }
      const toolsetsData = toolsetRes?.data?.data as Toolsets | undefined

      const toolsets = Object.values(toolsetsData || {})

      if (toolsetFilter) {
        const filteredToolsets = toolsets.filter(toolsetFilter)

        toolsetsByRobot[robotId] = filteredToolsets
      } else {
        toolsetsByRobot[robotId] = toolsets
      }
    })

    allRobotsDataMs.sort()
    return { toolsetsByRobot, allRobotsDataMs }
  }, isEqual)

  const dataMsKey = allRobotsDataMs.sort().join()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => toolsetsByRobot, [dataMsKey])
}

/**
 * This hook returns true if text is overflowing.
 * It's important to be used directly in the text container.
 *
 * @param textContainerRef - text container ref
 * @param canBeResized - Useful when the element change its size, based on being loaded or by being pushed from other elements
 * @param direction - Default value is horizontal. Use vertical for paragraphs with limited lines.
 */
export const useTextIsOverflowing = (
  textContainerRef: React.RefObject<HTMLDivElement>,
  canBeResized?: boolean,
  direction?: 'horizontal' | 'vertical',
) => {
  const [textIsOverflowing, setTextIsOverflowing] = useState(false)

  useEffect(() => {
    const textContainer = textContainerRef.current
    if (!textContainer) return

    if (direction === 'vertical') {
      // Checks if the text is larger than the rendered container
      setTextIsOverflowing(textContainer.scrollHeight > textContainer.offsetHeight)
    } else {
      // Checks if the text is wider than the rendered container
      setTextIsOverflowing(textContainer.scrollWidth > textContainer.offsetWidth)
    }
  }, [textContainerRef, direction])

  // Re-evaluates the size of the component when it resizes
  useEffect(() => {
    const textContainer = textContainerRef.current
    // if its already overflowing there's no reason to re-calculate
    if (textIsOverflowing || !canBeResized || !textContainer) return

    // helpful when the UI change and the component updates its width
    const handleResize = () => {
      setTextIsOverflowing(textContainer.scrollWidth > textContainer.offsetWidth)
    }

    const observer = new ResizeObserver(handleResize)
    observer.observe(textContainer)
    return () => {
      if (textContainer) observer.unobserve(textContainer)
    }
  }, [textIsOverflowing, canBeResized, textContainerRef])

  return textIsOverflowing
}

interface LiveBatchParams {
  tool: Tool
  aoiParentId: string | undefined
  inspectionId: string
  threshold: Threshold
}
interface DistinctParams {
  key: ReturnType<(typeof getterKeys)['toolResultsByThreshold']>
  serviceParams: { [key: string]: string | number | undefined }
  header: {
    value: string
    severity: LabelButtonSeverity
    description: string
  }
}

/**
 * This hook returns the carousels for the upload batch modal.
 *
 * @param toolSpecName - tool specification name
 * @param toolParentId - tool parent id
 * @param aoiParentId - aoi parent id
 * @param inspectionId - id of the current inspection
 * @param threshold - current selected threshold
 */
export const useLiveBatchCarousels = ({ tool, aoiParentId, inspectionId, threshold }: LiveBatchParams) => {
  const dispatch = useDispatch()
  const { customLabels } = useAllToolLabels()

  const [loading, setLoading] = useState(false)
  const distinctParams: DistinctParams[] | undefined = useMemo(() => {
    const sharedParams = {
      tool_parent_id: tool.parent_id,
      aoi_parent_id: aoiParentId,
      inspection_id: inspectionId,
      has_image: 'true',
    }

    // Since anomaly tool flips scores around, we need to invert the logic for that tool, as opposed to the defect tool
    if (tool.specification_name === 'deep-svdd')
      return [
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'above'),
          serviceParams: {
            ...sharedParams,
            prediction_score_lte: (threshold as ToolThreshold).threshold,
            ordering: '-prediction_score',
          },
          header: {
            value: 'good',
            severity: 'good',
            description: 'Sorted by score, low to high',
          },
        },
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'below'),
          serviceParams: {
            ...sharedParams,
            prediction_score_gt: (threshold as ToolThreshold).threshold,
            ordering: 'prediction_score',
          },
          header: {
            value: 'critical',
            severity: 'critical',
            description: 'Sorted by score, high to low',
          },
        },
      ]

    if (tool.specification_name === 'classifier')
      return [
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'above'),
          serviceParams: {
            ...sharedParams,
            prediction_score_gte: (threshold as ToolThreshold).threshold,
            ordering: 'prediction_score',
          },
          header: {
            value: 'good',
            severity: 'good',
            description: 'Sorted by score, low to high',
          },
        },
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'below'),
          serviceParams: {
            ...sharedParams,
            prediction_score_lt: (threshold as ToolThreshold).threshold,
            ordering: '-prediction_score',
          },
          header: {
            value: 'critical',
            severity: 'critical',
            description: 'Sorted by score, high to low',
          },
        },
      ]

    if (tool.specification_name === 'match-classifier') {
      const carouselParams = tool.metadata.prediction_labels?.reduce((carouselParams, predictionLabel) => {
        const label = customLabels?.find(toolLabel => toolLabel.id === (predictionLabel as { id: string }).id)
        if (!label) return carouselParams

        carouselParams.push({
          key: getterKeys.toolResultsByThreshold(inspectionId, `${label.id}-above`),
          serviceParams: {
            ...sharedParams,
            prediction_score_gte: (threshold as ToolThreshold).threshold,
            prediction_label_id: label.id,
            ordering: 'prediction_score',
          },
          header: {
            value: label.value,
            severity: 'neutral',
            description: 'Score above threshold, low to high',
          },
        } as DistinctParams)

        carouselParams.push({
          key: getterKeys.toolResultsByThreshold(inspectionId, `${label.id}-below`),
          serviceParams: {
            ...sharedParams,
            prediction_score_lt: (threshold as ToolThreshold).threshold,
            prediction_label_id: label.id,
            ordering: '-prediction_score',
          },
          header: {
            value: label.value,
            severity: 'neutral',
            description: 'Score below threshold, high to low',
          },
        } as DistinctParams)
        return carouselParams
      }, [] as DistinctParams[])

      return carouselParams?.sort((paramA, paramB) => {
        if (paramA.header.value.toLowerCase() < paramB.header.value.toLowerCase()) return -1
        if (paramA.header.value.toLowerCase() > paramB.header.value.toLowerCase()) return 1
        // Same label, carousel below threshold goes first
        if (paramA.serviceParams.ordering === '-prediction_score') return -1
        return 1
      })
    }

    if (tool.specification_name === 'graded-anomaly' && isThresholdFromGARTool(threshold)) {
      return [
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'above'),
          serviceParams: {
            ...sharedParams,
            prediction_score_lte: threshold.lowerThreshold,
            ordering: '-prediction_score',
          },
          header: {
            value: 'good',
            severity: 'good',
            description: 'Sorted by score, low to high',
          },
        },
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'middle'),
          serviceParams: {
            ...sharedParams,
            prediction_score_lt: threshold.upperThreshold,
            prediction_score_gt: threshold.lowerThreshold,
            ordering: '-prediction_score',
          },
          header: {
            value: 'minor',
            severity: 'minor',
            description: 'Sorted by score, low to high',
          },
        },
        {
          key: getterKeys.toolResultsByThreshold(inspectionId, 'below'),
          serviceParams: {
            ...sharedParams,
            prediction_score_gte: threshold.upperThreshold,
            ordering: 'prediction_score',
          },
          header: {
            value: 'critical',
            severity: 'critical',
            description: 'Sorted by score, high to low',
          },
        },
      ]
    }
  }, [
    tool.parent_id,
    tool.specification_name,
    tool.metadata.prediction_labels,
    aoiParentId,
    inspectionId,
    threshold,
    customLabels,
  ])

  const labelCarouselData = useTypedSelector(state => {
    const allCarouselData: { [key: string]: QueryState<SuccessResponseOnlyData> | undefined } = {}
    distinctParams?.forEach(param => {
      allCarouselData[param.key] = state.getter[param.key]
    })
    return allCarouselData
  }, shallowEqual)

  // Use effect in charge of fetching the tool results of all carousels
  useEffect(() => {
    if (threshold === undefined || !inspectionId || !distinctParams) return

    const fetchCarouselData = async () => {
      setLoading(true)
      const promises: Promise<SendToApiResponseOnlyData<ToolResultsData> | undefined>[] = []
      for (const request of distinctParams) {
        promises.push(query(request.key, () => service.getToolResults(request.serviceParams), { dispatch }))
      }
      await Promise.all(promises)
      setLoading(false)
    }

    fetchCarouselData()
  }, [dispatch, inspectionId, distinctParams, threshold])

  const fetchMore = useCallback(
    async (next: string, key: ReturnType<(typeof getterKeys)['toolResultsByThreshold']>) => {
      const res = await service.getNextPage<ToolResultsData>(next)
      if (res.type === 'success') {
        dispatch(
          Actions.getterUpdate({
            key,
            updater: prevRes => getterAddPage(prevRes, res.data),
          }),
        )
      }
    },
    [dispatch],
  )

  const carousels = useMemo(() => {
    if (!labelCarouselData) return
    return Object.entries(labelCarouselData).map(([getterKey, apiResponse]) => {
      const carouselInfo = distinctParams?.find(params => params.key === getterKey)!
      const toolResultsResponse: ToolResultsData | undefined = apiResponse?.data?.data
      return {
        header: carouselInfo.header,
        onEndReached: toolResultsResponse?.next
          ? () =>
              fetchMore(
                toolResultsResponse.next!,
                getterKey as ReturnType<(typeof getterKeys)['toolResultsByThreshold']>,
              )
          : undefined,
        dataSource: toolResultsResponse,
      }
    })
  }, [distinctParams, fetchMore, labelCarouselData])

  return { carousels, loading }
}

/**
 * This hook returns labels available for dataset and labels used in dataset
 *
 * @param lastTrainedTool - Tool used to get dataset for counts
 */
export const useTrainingLabelsCounts = (lastTrainedTool?: ToolFlatWithCurrentExperiment) => {
  const { derivativeLabelsIds } = useDerivativeLabels()

  const datasetId = lastTrainedTool?.currentExperiment?.dataset_id
  const dataset = useQuery(
    datasetId ? getterKeys.dataset(datasetId) : undefined,
    datasetId ? () => service.getDataset(datasetId) : undefined,
  ).data?.data
  const { labelsAvailableForDataset, labelsUsedInDataset } = getLabelsTrainCountsFromDataset(
    dataset,
    derivativeLabelsIds,
  )

  return { labelsAvailableForDataset, labelsUsedInDataset }
}

/**
 * This hook returns RecipeParents and Components related to a given ToolParent
 *
 * @param toolParentId - ToolParent to get data from
 */
export const useToolRecipeParentsAndComponents = (toolParentId: string) => {
  const recipeParents = useQuery(getterKeys.recipeParentsFilterOptions(toolParentId), () =>
    service.getRecipeParents({ tool_parent_id: toolParentId, has_tool_results: true }),
  ).data?.data.results

  const toolComponents = useMemo(() => {
    return recipeParents?.map(({ component_id, component_name, fallback_images }) => ({
      id: component_id,
      component_name,
      fallback_images,
    }))
  }, [recipeParents])

  const components = useMemo(() => {
    if (!toolComponents) return
    return uniqBy(toolComponents, component => component.id)
  }, [toolComponents])

  return { components, recipeParents }
}

/**
 * This hooks returns relevant label counts data for a given ToolParent
 * eg. countsByLabelId, totalLabeled, totalUnlabeled, rawCounts, etc.
 *
 * @param toolParentId - ToolParent to get counts data from
 */
export const useToolParentLabelCounts = ({ toolParentId }: { toolParentId?: string }) => {
  const defaultLabels = useDefaultToolLabels()
  const testSetLabelId = findSingleToolLabelFromPartialData(defaultLabels, TEST_SET_LABEL)?.id

  const counts = useQuery(
    toolParentId ? getterKeys.toolResultCounts(toolParentId, 'training-labels') : undefined,
    toolParentId ? () => service.countLabeledToolResults(toolParentId, { is_test_set: false }) : undefined,
  ).data?.data.results

  const testSetCounts = useQuery(toolParentId ? getterKeys.toolResultCounts(toolParentId, 'test-set') : undefined, () =>
    service.countLabeledToolResults(toolParentId!, { is_test_set: true }),
  ).data?.data.results

  // We want to refetch these counts in an interval in case the user is labeling mid inspection
  const totalCountData = useQuery(
    toolParentId ? getterKeys.toolTotalUnlabeledCount(toolParentId) : undefined,
    () =>
      service.readTimeSeries({
        bucket_s: 1800,
        agg_s: SECONDS_IN_DAY,
        labels: { tool_parent_id: toolParentId! },
        series_type: 'crop_tool_parent_org',
      }),
    { intervalMs: 10 * 1000 },
  ).data?.data.results

  const { derivativeLabelsIds } = useDerivativeLabels()

  const { countsByLabelId, countsByComponentId } = useMemo<{
    countsByLabelId: Record<string, number> | undefined
    countsByComponentId: Record<string, number> | undefined
  }>(() => {
    if (!counts || !derivativeLabelsIds) {
      return { countsByLabelId: undefined, countsByComponentId: undefined }
    }
    const countsByLabelIdToReturn: Record<string, number> = {}
    const countsByComponentIdToReturn: Record<string, number> = {}

    counts?.forEach(countObj => {
      const labelId = countObj.active_user_label_set__tool_labels__id
      const componentId = String(countObj.component_id)

      // Derivative labels should not be considered in label counts
      if (derivativeLabelsIds.includes(labelId)) return

      countsByLabelIdToReturn[labelId] ??= 0
      countsByLabelIdToReturn[labelId] += countObj.count

      countsByComponentIdToReturn[componentId] ??= 0
      countsByComponentIdToReturn[componentId] += countObj.count
    })
    return { countsByLabelId: countsByLabelIdToReturn, countsByComponentId: countsByComponentIdToReturn }
  }, [counts, derivativeLabelsIds])

  const testSetCountsByLabelId = useMemo(() => {
    if (!testSetCounts) return
    let byLabelId: Record<string, number> = {}

    testSetCounts.forEach(countObj => {
      const labelId = countObj.active_user_label_set__tool_labels__id

      byLabelId[labelId] ??= 0
      byLabelId[labelId] += countObj.count
    })

    // When counting ToolResults grouped by label_id with a test_set label we need to make sure that we are removing
    // duplicate counts from the backend, since ToolResults with test_set will get counted twice if the ToolResult has
    // another label other than test_set.
    if (testSetLabelId && testSetLabelId in byLabelId) {
      const { [testSetLabelId]: testSetCounts, ...rest } = byLabelId
      byLabelId = {
        ...rest,
        // We need to subtract the total count in test_set.id key minus the value of each key to remove duplicates
        [testSetLabelId]: testSetCounts! - Object.values(rest).reduce((a, b) => (a += b), 0),
      }
    }

    return byLabelId
  }, [testSetCounts, testSetLabelId])

  const totalCount = !totalCountData
    ? undefined
    : sum(totalCountData.flatMap(rtsResult => rtsResult.entries).map(entry => entry[1]))

  const totalLabeled =
    countsByLabelId && testSetCountsByLabelId
      ? Object.values(countsByLabelId)?.reduce((prev, curr) => (prev += curr), 0) +
        Object.values(testSetCountsByLabelId)?.reduce((prev, curr) => (prev += curr), 0)
      : undefined

  const totalUnlabeled = useMemo(() => {
    if (isNil(totalLabeled) || isNil(totalCount)) return
    return +totalCount - totalLabeled
  }, [totalCount, totalLabeled])

  return {
    countsByLabelId,
    countsByComponentId,
    rawCounts: counts,
    totalLabeled,
    totalUnlabeled,
    totalCount,
    metricsHaveLoaded: !!counts && !!testSetCounts,
    testSetCountsByLabelId,
  }
}

/**
 * This hook returns only the Derivative ToolLabels.
 */
const useDerivativeLabels = () => {
  const defaultLabels = useDefaultToolLabels()
  const derivativeLabels = useMemo(() => {
    if (!defaultLabels) return

    return extractDerivativeLabels(defaultLabels)
  }, [defaultLabels])

  const derivativeLabelsIds = useMemo(() => {
    return derivativeLabels?.map(toolLabel => toolLabel.id)
  }, [derivativeLabels])
  return { derivativeLabels, derivativeLabelsIds }
}

/**
 * This hook is responsible of keeping realSubs and templateSubs in synced
 * Rules:
 *  - If both templates are present, create a real sub if one doesn't already exist
 *  - If a single template and a real sub is present, create the missing template
 */
export function useKeepEventSubsInSync({
  userEventSubs,
  eventSubTypeTemplates,
  eventSubTargetTemplates,
  defaultEvents,
}: {
  userEventSubs: EventSub[] | undefined
  eventSubTypeTemplates: EventSub[] | undefined
  eventSubTargetTemplates: EventSub[] | undefined
  defaultEvents: EventType[] | undefined
}) {
  const dispatch = useDispatch()
  const user = useData(getterKeys.me())

  const trainFinishEventType = useMemo(
    () => defaultEvents?.find(event => event.name === 'train_finish'),
    [defaultEvents],
  )

  const allEventSubIds = [
    ...(userEventSubs?.map(sub => sub.id) || []),
    ...(eventSubTypeTemplates?.map(sub => sub.id) || []),
    ...(eventSubTargetTemplates?.map(sub => sub.id) || []),
  ]
  // we want to only run this when we have different EventSubs
  const refetchKey = allEventSubIds.sort().join()

  async function updateEventSubs(payload: CreateUpdateDeleteSubsBody) {
    await service.createUpdateDeleteCurrentUserSubs(payload)
    await refreshEventSubsBranches({ dispatch })
  }

  // Ensure that logic when user explicitly DELETES a template sub, at that point it deletes all real subs that depended on it or else this effect will recreate the deleted subs
  useEffect(() => {
    if (!userEventSubs || !eventSubTypeTemplates || !eventSubTargetTemplates) return
    const payload: CreateUpdateDeleteSubsBody = {
      create: [],
    }
    const userEventSubByTypeIds = groupBy(userEventSubs, 'type_id')
    const userEventSubByTargetIds = groupBy(userEventSubs, eventSub => Object.values(eventSub.targets)[0])

    const eventSubTypeTemplatesByTypeId = groupBy(eventSubTypeTemplates, eventSub => eventSub.type_id)

    const eventSubTargetTemplatesByTargetId = groupBy(
      eventSubTargetTemplates,
      eventSub => Object.values(eventSub.targets)[0],
    )

    // Create templates subs
    Object.entries(userEventSubByTypeIds).forEach(([typeId, userRealSubs]) => {
      // create eventSubTypeTemplates
      if (!(typeId in eventSubTypeTemplatesByTypeId)) {
        // We assume sms and email values are the same between all eventsubs with the same type
        const realSub = userRealSubs[0]
        if (realSub) {
          payload.create?.push({
            ...realSub,
            targets: {},
          })
        }
      }

      // create eventSubTargetTemplates
      userRealSubs.forEach(eventSub => {
        const entry = Object.entries(eventSub.targets)[0]
        if (!entry) return
        const [key, targetId] = entry
        if (!QUALITY_EVENTS_ALLOWED_TARGET_KEYS.includes(key as keyof EventTargets)) return
        if (!(targetId in eventSubTargetTemplatesByTargetId)) {
          // create target template
          payload.create?.push({
            ...eventSub,
            type_id: null,
          })
        }
      })
    })

    // create real subs
    Object.entries(eventSubTargetTemplatesByTargetId).forEach(([targetId, eventSubTargetTemplates]) => {
      // Since we only have one target per EventSub in v1, its safe to assume that targets are all the same here
      const targetTemplateSub = eventSubTargetTemplates[0]
      const typeIdsWithinTarget = userEventSubByTargetIds[targetId]?.map(eventSub => eventSub.type_id)
      if (targetTemplateSub) {
        eventSubTypeTemplates.forEach(typeTemplateSub => {
          // We need to special case all the `train_finish` events due to thats the only event where we just want to ahve one eventSub with `user_id` as its target
          if (typeTemplateSub.type_id === trainFinishEventType?.id) return
          // If we dont have a sub with that type id, create one
          if (!typeIdsWithinTarget?.includes(typeTemplateSub.type_id)) {
            payload.create?.push({
              ...typeTemplateSub,
              targets: targetTemplateSub.targets,
            })
          }
        })
      }
    })

    if (payload.create?.length) {
      // We have to be careful about what we are sending to sentry since it has a max payload of 300kb.
      const sentryEventSubs = userEventSubs.map(eventSub => {
        return {
          id: eventSub.id,
          via_sms: eventSub.via_sms,
          via_email: eventSub.via_email,
          type_id: eventSub.type_id,
          target_id: Object.values(eventSub.targets)[0],
        }
      })
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Fatal)
        scope.setUser({ email: user?.email })
        scope.setExtras({
          payload: JSON.stringify(payload),
          userEventSubs: sentryEventSubs,
          typeIdsFromTypeTemplates: Object.keys(eventSubTypeTemplatesByTypeId),
          targetIdsFromTargetTemplates: Object.keys(eventSubTargetTemplatesByTargetId),
        })
        Sentry.captureMessage('Created Event Subs via hook')
      })
      updateEventSubs(payload)
    }
    // eslint-disable-next-line
  }, [refetchKey])
}

export const useStationsWithStatus = <T extends { robots: Robot[] }>(
  stations: T[],
  sortFunction?: (a: T, b: T) => 0 | 1 | -1,
) => {
  const robotIds = useMemo(() => stations?.flatMap(station => station.robots.map(robot => robot.id)), [stations])

  const statusByRobotId = useStatusByRobotId(robotIds)

  const statusByRobotIdKey = statusByRobotId
    ? Object.entries(statusByRobotId)
        .map(([key, status]) => {
          return key + status
        })
        .join(',')
    : ''
  // We append the status according to the robot status to the station. Also, we sort stations by status

  const sortByStatus = (stationA: WithStatus<T>, stationB: WithStatus<T>) => {
    if (!stationB?.status || !stationA?.status) return 0
    return STATUS_PRIORITY[stationB.status] - STATUS_PRIORITY[stationA.status]
  }

  const stationsWithStatus: WithStatus<T>[] = useMemo(() => {
    stations.forEach(station => {
      station.robots.sort((robotA, robotB) => {
        const robotAStatus = statusByRobotId?.[robotA.id]
        const robotBStatus = statusByRobotId?.[robotB.id]
        if (!robotAStatus || !robotBStatus) return 0
        return STATUS_PRIORITY[robotBStatus] - STATUS_PRIORITY[robotAStatus]
      })
    })

    return stations
      .map(station => ({
        ...station,
        // We include the status to the station
        status: station.robots[0] ? statusByRobotId?.[station.robots[0].id] : undefined,
      }))
      .sort(sortFunction || sortByStatus)
    // eslint-disable-next-line
  }, [stations, statusByRobotIdKey])

  return { stationsWithStatus, statusByRobotId, robotIds }
}

export const useStationDeployedRecipe = (robotIds: string[]) => {
  const toolsetsByRobotId = useToolsetsByRobotId(robotIds)

  const deployedRecipes = useMemo(() => {
    const toolSets = Object.values(toolsetsByRobotId)
    const recipeToolSets = toolSets.reduce(
      (currArray, toolset) => [...currArray, ...Object.values(toolset)],
      [] as Toolset[],
    )

    const loadedToolsets = recipeToolSets.filter(recipeToolSet => recipeToolSet.recipe_status.state === 'LOADED')

    const groupedToolsets = groupBy(loadedToolsets, toolsets => toolsets.recipe?.parent_id)

    const filteredToolsets = []
    for (const recipeParentToolsets of Object.values(groupedToolsets)) {
      const sortedByLatest = recipeParentToolsets.sort(
        (toolsetA, toolsetB) =>
          toolsetB.recipe_status.metadata.deployed_at - toolsetA.recipe_status.metadata.deployed_at,
      )
      filteredToolsets.push(sortedByLatest[0]!)
    }

    return filteredToolsets
      .sort(
        (toolsetA, toolsetB) =>
          toolsetB.recipe_status.metadata.deployed_at - toolsetA.recipe_status.metadata.deployed_at,
      )
      .map(toolset => toolset.recipe)
      .filter((rd): rd is StatusCommandRecipe => !!rd)
  }, [toolsetsByRobotId])

  return { deployedRecipes, toolsetsByRobotId }
}

export const useSitesAndLinesCascaderOptions = ({
  showStations,
  stationFilterFn,
}: {
  showStations?: boolean
  stationFilterFn?: (station: StationForSite) => boolean
}) => {
  const sites = useQueryEntityFromSites({ entity: 'site', filterFn: site => !site.is_deleted, noRefetch: true })

  return useMemo(() => {
    if (!sites) return []
    const cascaderOptions: CascaderOption[] = []
    sites.sort(sortByName).forEach(site => {
      const stationsByBelongsToId = groupBy(
        site.stations.filter(station => !station.is_deleted),
        station => station.belongs_to_id || 'none',
      )

      // We should always have a StationTypeSubsite, since the backend creates one when we create a new site
      const siteTypeSubsiteId = site.subsite_types.find(subsite => subsite.normalized_name === 'station')?.id
      if (!siteTypeSubsiteId) return

      const lineOptions = site.subsites.sort(sortByName).reduce((acc, line) => {
        if (line.is_deleted) return acc

        const lineOption: CascaderOption = {
          value: line.id,
          label: line.name,
        }
        if (showStations) {
          const filteredStations = stationFilterFn
            ? stationsByBelongsToId[line.id]?.filter(stationFilterFn)
            : stationsByBelongsToId[line.id]

          const stationOptions = filteredStations?.map(station => ({
            value: station.id,
            label: station.name,
            name: station.name,
            ordering: station.ordering,
            belongs_to_id: station.belongs_to_id,
          }))
          if (stationOptions?.length) acc.push({ ...lineOption, children: stationOptions.sort(sortStationsByOrdering) })
        }

        if (!showStations) {
          acc.push(lineOption)
        }
        return acc
      }, [] as CascaderOption[])

      // Stations that don't belong to any line
      if (showStations) {
        const stationsWithNoLine = stationsByBelongsToId['none']?.map(station => ({
          value: station.id,
          label: station.name,
        }))

        if (stationsWithNoLine?.length)
          lineOptions.unshift({
            value: null,
            label: 'Independent Stations',
            children: stationsWithNoLine,
          })
      }

      // We always want to have `Independent Stations` line option
      if (!showStations) {
        lineOptions.unshift({
          value: null,
          label: 'Independent Stations',
        })
      }

      if (lineOptions.length) {
        cascaderOptions.push({
          value: `${site.id}_${siteTypeSubsiteId}`,
          label: site.name,
          children: lineOptions,
        })
      }
    })
    return [...cascaderOptions]
  }, [sites, showStations, stationFilterFn])
}

export const useTotalCountsAndCommonDefects = ({
  itemsMetrics,
  itemLabelsMetrics,
}: {
  itemsMetrics?: TimeSeriesResult[]
  itemLabelsMetrics?: TimeSeriesResult[]
}) => {
  const { allToolLabels } = useAllToolLabels()
  const totalCountsAndCommonDefects = useMemo(() => {
    if (!itemsMetrics || !itemLabelsMetrics || !allToolLabels) return {}
    const itemSeries = combineOutcomeCounts(itemsMetrics)
    const { totalCount, totalFail, totalPass } = (itemSeries || []).reduce(
      (itemCounts, outcomeData) => {
        itemCounts.totalPass += outcomeData.pass
        itemCounts.totalFail += outcomeData.fail
        itemCounts.totalCount += outcomeData.count
        return itemCounts
      },
      { totalCount: 0, totalFail: 0, totalPass: 0 },
    )

    const groupedLabelMetricsByLabelId = groupBy(itemLabelsMetrics, result => result.labels.tool_label_id)
    const mostCommonDefects = getAnalyzeDefects({ groupedLabelMetricsByLabelId, allToolLabels, count: totalCount })

    return { totalCount, totalFail, totalPass, mostCommonDefects }
  }, [allToolLabels, itemLabelsMetrics, itemsMetrics])

  return totalCountsAndCommonDefects
}

export const useInspectionCountMetrics = (
  inspection: Inspection | undefined,
  { getterKeySuffix }: { getterKeySuffix?: string },
) => {
  const { itemMetricsRes } = useQueryItemMetrics(inspection, 'item_outcome_inspection', false, {
    getterKeySuffix: `${getterKeySuffix}-outcome`,
    onlyCounts: true,
    isHistoricBatch: false,
  })
  const { itemMetricsRes: labelMetricRes } = useQueryItemMetrics(inspection, 'item_label_inspection', false, {
    getterKeySuffix,
    onlyCounts: true,
    isHistoricBatch: false,
  })
  const itemLabelsMetrics = labelMetricRes?.data.results
  const itemsMetrics = itemMetricsRes?.data.results

  const { totalCount, totalFail, totalPass, mostCommonDefects } = useTotalCountsAndCommonDefects({
    itemsMetrics,
    itemLabelsMetrics,
  })

  return { totalCount, totalFail, totalPass, mostCommonDefects }
}

type EntitytypeMapper = {
  site: Site
  line: SubSite
  station: StationForSite
  recipeParent: RecipeParent
  subSiteType: SubSiteType
}

type TypeGuardArgs = {
  entity: keyof EntitytypeMapper
  filterFn?: (args: any) => boolean
}

function isEntitySite(args: TypeGuardArgs): args is { entity: 'site'; filterFn?: (arg: Site) => boolean } {
  return args.entity === 'site'
}

function isEntityLine(args: TypeGuardArgs): args is { entity: 'line'; filterFn?: (arg: SubSite) => boolean } {
  return args.entity === 'line'
}

function isEntityStation(
  args: TypeGuardArgs,
): args is { entity: 'station'; filterFn?: (arg: StationForSite) => boolean } {
  return args.entity === 'station'
}

function isEntityRecipeParent(
  args: TypeGuardArgs,
): args is { entity: 'recipeParent'; filterFn?: (arg: RecipeParent) => boolean } {
  return args.entity === 'recipeParent'
}

function isEntitySubSiteType(
  args: TypeGuardArgs,
): args is { entity: 'subSiteType'; filterFn?: (arg: SubSiteType) => boolean } {
  return args.entity === 'subSiteType'
}

export const useQueryEntityFromSites = <T extends keyof EntitytypeMapper, U extends EntitytypeMapper[T]>({
  siteId,
  ...props
}: {
  entity: T
  filterFn?: (args: U) => boolean
  noRefetch?: boolean
} & ConditionalType<T, 'line' | 'station' | 'recipeParent' | 'subSiteType', { siteId: string | undefined }>):
  | WithSiteId<U>[]
  | undefined => {
  const sites = useQuery(getterKeys.sites(), () => service.getSites(), {
    dedupe: true,
    noRefetch: props.noRefetch,
  }).data?.data.results

  return useMemo(() => {
    if (isEntitySite(props)) {
      return (props.filterFn ? sites?.filter(props.filterFn) : sites) as any
    }

    const filteredSite = sites?.find(site => site.id === siteId)
    if (!filteredSite) return

    if (isEntityLine(props)) {
      const subsites = props.filterFn ? filteredSite.subsites.filter(props.filterFn) : filteredSite?.subsites
      return subsites?.map(subsite => ({ ...subsite, site_id: filteredSite.id })) as any
    }

    if (isEntityStation(props)) {
      const stations = props.filterFn ? filteredSite.stations.filter(props.filterFn) : filteredSite?.stations
      return stations?.map(station => ({ ...station, site_id: filteredSite.id })) as any
    }

    if (isEntityRecipeParent(props)) {
      const recipeParents = filteredSite.stations.flatMap(station => station.recipe_parents)
      return (props.filterFn ? recipeParents?.filter(props.filterFn) : recipeParents) as any
    }
    if (isEntitySubSiteType(props)) {
      return (props.filterFn ? filteredSite.subsite_types.filter(props.filterFn) : filteredSite.subsite_types) as any
    }
    // eslint-disable-next-line
  }, [props.entity, siteId, sites])
}

// We want to filter subs with only the allowed target keys
const filterAllowedTargetsSubs = (sub: EventSub) =>
  Object.keys(sub.targets).every(key => QUALITY_EVENTS_ALLOWED_TARGET_KEYS.includes(key as keyof EventTargets))

// This hook is in charge of fetching subs and subs templates and filter only the allowed targets
export const useQueryEventTypesSubsAndTemplates = () => {
  // We need to fetch the quality events to filter allowed targets
  const qualityEventsResults = useQuery(getterKeys.eventTypes('inspection'), () =>
    getAllPages(service.getEventTypes({ kind: 'inspection', is_deleted: false })),
  ).data?.data.results
  const defaultEvents = useQuery(getterKeys.eventTypes('default'), () => service.getEventTypes({ kind: 'default' }))
    .data?.data.results

  // Filter out events that are not yet supported in the frontend
  const qualityEvents = useMemo(() => {
    return qualityEventsResults?.filter(eventType =>
      eventType.event_scopes.every(eventScope => {
        // Frontend doesnt currently support more than one target per eventScope
        if (eventScope.targets.length > 1) return false
        return eventScope.targets.every(target => QUALITY_EVENTS_ALLOWED_TARGET_TABLES.includes(target.table))
      }),
    )
  }, [qualityEventsResults])

  const allEventTypesByTypeId = useMemo(() => {
    if (!qualityEvents || !defaultEvents) return
    return keyBy([...qualityEvents, ...defaultEvents], eventType => eventType.id)
  }, [defaultEvents, qualityEvents])

  // TODO: if customers ever have more than 5000 event subs this all stops working
  const userEventSubsResults = useQuery(getterKeys.eventSubs('realSub'), () =>
    getAllPages(service.getCurrentEventSubs({ has_targets: true, has_type: true })),
  ).data?.data.results
  const eventSubTypeTemplatesResults = useQuery(getterKeys.eventSubs('typeTemplate'), () =>
    getAllPages(service.getCurrentEventSubs({ has_targets: false })),
  ).data?.data.results
  const eventSubTargetTemplatesResults = useQuery(getterKeys.eventSubs('targetTemplate'), () =>
    getAllPages(service.getCurrentEventSubs({ has_type: false })),
  ).data?.data.results

  const userEventSubs = useMemo(() => {
    if (!userEventSubsResults) return
    return [...userEventSubsResults].filter(filterAllowedTargetsSubs)
  }, [userEventSubsResults])

  const eventSubTypeTemplates = useMemo(() => {
    if (!eventSubTypeTemplatesResults) return
    return [...eventSubTypeTemplatesResults].filter(subTypeTemplate => {
      const eventType = allEventTypesByTypeId?.[subTypeTemplate.type_id!]
      if (!eventType) return false
      if (eventType.kind !== 'inspection') return true

      return filterEventTypesByAllowedTargets(eventType)
    })
  }, [allEventTypesByTypeId, eventSubTypeTemplatesResults])

  const eventSubTargetTemplates = useMemo(() => {
    if (!eventSubTargetTemplatesResults) return
    return [...eventSubTargetTemplatesResults].filter(filterAllowedTargetsSubs)
  }, [eventSubTargetTemplatesResults])

  return { userEventSubs, eventSubTypeTemplates, eventSubTargetTemplates, qualityEvents, defaultEvents }
}

// This hook returns robots with status sorted by status and then by name
export const useSortedRobotsWithStatus = (robots?: Robot[]) => {
  const statusByRobotId = useStatusByRobotId(robots?.map(robot => robot.id))

  const sortedRobotsWithStatus = useMemo(() => {
    if (!robots || !statusByRobotId) return

    return robots
      .map(robot => ({ ...robot, name: robot.name || '', status: statusByRobotId[robot.id] }))
      .sort(sortByStatusAndName)
  }, [robots, statusByRobotId])

  return sortedRobotsWithStatus
}

export const useIsFlourish = () => {
  const organization = useData(getterKeys.organization())
  const isFlourish = !!organization?.rw_tenant_id

  const organizationData: {
    company: 'Rockwell' | 'Elementary'
    service: 'FactoryTalk Analytics VisionAI' | 'Prism'
    supportEmail: null | 'support@elementaryml.com'
  } = useMemo(() => {
    return isFlourish
      ? { company: 'Rockwell', service: 'FactoryTalk Analytics VisionAI', supportEmail: null }
      : { company: 'Elementary', service: 'Prism', supportEmail: 'support@elementaryml.com' }
  }, [isFlourish])

  return { isFlourish, organizationData }
}

export const useSetGlobalStyles = (isFlourish?: boolean) => {
  useEffect(() => {
    // Set special rockwell styles if user belongs to rockwell org
    if (isFlourish === undefined) return
    const root = document.documentElement

    if (isFlourish) {
      document.title = 'FactoryTalk Analytics VisionAI'
      root.classList.add('flourish-skin')
      const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      if (link) link.href = window.location.origin + '/flourish_favicon.ico'
    } else {
      document.title = 'Elementary: Prism Inspection Platform'
      root.classList.remove('flourish-skin')
      const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      if (link) link.href = window.location.origin + '/elementary_favicon.ico'
    }
  }, [isFlourish])
}

export const useIALimitDate = () => {
  const organization = useData(getterKeys.organization())

  const iaLimitDate = useMemo(() => {
    if (!organization) return
    return (
      moment()
        .startOf('day')
        // TODO: remove + 1000 in 5.x
        .subtract((organization.image_to_ia_days || 90) + 1000, 'days')
    )
  }, [organization])

  return iaLimitDate
}
