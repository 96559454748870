import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Error Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1405%3A166
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismErrorIcon = ({ hasBackground, className, isActive }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.errorIcon} ${isActive ? Styles.active : ''} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
        strokeMiterlimit="10"
        stroke="currentColor"
        fill="none"
      />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85356 8.14645C8.6583 7.95118 8.34172 7.95118 8.14645 8.14645C7.95119 8.34171 7.95119 8.65829 8.14645 8.85355L11.2929 12L8.14645 15.1464C7.95118 15.3417 7.95118 15.6583 8.14645 15.8536C8.34171 16.0488 8.65829 16.0488 8.85355 15.8536L12 12.7071L15.1465 15.8536C15.3417 16.0488 15.6583 16.0488 15.8536 15.8536C16.0488 15.6583 16.0488 15.3417 15.8536 15.1464L12.7071 12L15.8536 8.85355C16.0488 8.65829 16.0488 8.34171 15.8536 8.14645C15.6583 7.95119 15.3417 7.95119 15.1464 8.14645L12 11.2929L8.85356 8.14645Z"
        fill="currentColor"
      />
    </svg>
  )
}
