import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Align Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A22
 *
 * @param hasHover - Fill the icon with light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismAlignToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M16 4V5H19V8H20V4H16Z" fill="currentColor" />
      <path d="M16 20V19H19V16H20V20H16Z" fill="currentColor" />
      <path d="M4 8H5V5H8V4H4V8Z" fill="currentColor" />
      <path d="M4 16H5V19H8V20H4V16Z" fill="currentColor" />
    </svg>
  )
}
