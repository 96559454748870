import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconArrowProps } from './prismIconsTypes'

const directionClasses = {
  right: Styles.right,
  left: Styles.left,
  down: Styles.down,
  up: Styles.up,
  mirror: Styles.mirror,
}

/**
 * Prism Nav Arrow Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=98%3A2
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param direction - rotate the icon, the default value is right.
 * @param className - String for external styles.
 */
export const PrismNavArrowIcon = ({ hasBackground, direction = 'right', className }: PrismIconArrowProps) => {
  const directionClass = directionClasses[direction]
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${directionClass} ${hasBackground ? Styles.hasBackground : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 11.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5V11.5ZM17.5 12L17.8536 12.3536C18.0488 12.1583 18.0488 11.8417 17.8536 11.6464L17.5 12ZM15.3536 9.14645C15.1583 8.95118 14.8417 8.95118 14.6464 9.14645C14.4512 9.34171 14.4512 9.65829 14.6464 9.85355L15.3536 9.14645ZM14.6464 14.1464C14.4512 14.3417 14.4512 14.6583 14.6464 14.8536C14.8417 15.0488 15.1583 15.0488 15.3536 14.8536L14.6464 14.1464ZM6.5 12.5H17.5V11.5H6.5V12.5ZM14.6464 9.85355L17.1464 12.3536L17.8536 11.6464L15.3536 9.14645L14.6464 9.85355ZM17.1464 11.6464L14.6464 14.1464L15.3536 14.8536L17.8536 12.3536L17.1464 11.6464Z"
        fill="currentColor"
      />
    </svg>
  )
}
