import React, { useState } from 'react'

import { service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'

import Styles from './ToolDetailModal.module.scss'

type Props = {
  originalName: string
  itemId: string
  onCancel: () => any
}

/**
 * Reders a modal to rename a item
 *
 * @param originalName - default initial name of the item
 * @param itemId - item ID to edit
 * @param onCancel - callback for closing the modal
 *
 */

export const RenameItemModal = ({ originalName, itemId, onCancel }: Props) => {
  const [name, setName] = useState(originalName)

  const handleSaveName = async () => {
    const saveRes = await service.patchItem(itemId, { serial_number: name })
    if (saveRes.type === 'success') {
      success({ title: 'Item Renamed', 'data-testid': 'rename-item-success' })
    } else error({ title: 'Error renaming item' })

    onCancel()
  }
  return (
    <Modal
      id="rename-item-modal"
      data-testid="rename-item-modal"
      disableSave={name === originalName}
      onCancel={onCancel}
      overlayClassName={Styles.optionOverlayModal}
      onClose={onCancel}
      showCancel
      header="Rename Item"
      size="largeSimpleForm"
      onOk={handleSaveName}
      okText="Save"
    >
      <PrismInput
        data-testid="rename-item-modal-input"
        autoFocus
        value={name}
        onChange={e => setName(e.target.value)}
        placeholder="Item ID"
        label="Item ID"
      />
    </Modal>
  )
}
