import React, { useState } from 'react'

import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import ToolSettingsModalTemplate, {
  ToolSettingsModalProps,
} from 'components/ToolSettingsModalTemplate/ToolSettingsModalTemplate'

interface Props extends Omit<ToolSettingsModalProps, 'inferenceUserArg' | 'children'> {}

/**
 * Renders a modal that allows the user to change the expected text of the OCR tool.
 */
const RandomToolSettingsModal = (props: Props) => {
  const { tool } = props
  const [passRate, setPassRate] = useState<number>(
    tool.inference_user_args.pass_probability || tool.inference_args.pass_probability,
  )

  return (
    <>
      <ToolSettingsModalTemplate
        inferenceUserArg={{
          key: 'pass_probability',
          value: passRate,
        }}
        {...props}
        title="Edit Pass Rate"
      >
        <div className={''}>
          <div className={''}>
            <PrismSlider
              data-testid="random-tool-settings-pass-rate"
              label="Pass Rate"
              userFacingValue={`${(passRate * 100).toFixed(1)}%`}
              value={passRate}
              min={0}
              max={1}
              className={''}
              onChange={setPassRate}
              step={0.001}
            />
          </div>
        </div>
      </ToolSettingsModalTemplate>
    </>
  )
}

export default RandomToolSettingsModal
