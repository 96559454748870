import React from 'react'

import { DatePicker as AntDatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import { Moment } from 'moment'
import moment from 'moment-timezone'

const { RangePicker: AntRangePicker } = AntDatePicker

function disabledDate(current: Moment) {
  // Cannot select days after today
  return current && current > moment().endOf('day')
}

export interface Props extends Omit<RangePickerProps<Moment>, 'onChange'> {
  onChange: (value: [Moment | null, Moment | null] | undefined) => void
  showTime?: { format: string; use12Hours: true }
  'data-testid'?: string
}

/**
 * Renders a range picker with three preset options.
 *
 * @param value - Array of moment objects containing the currently selected start and end times
 * @param format - Moment-style format string
 * @param onChange - Callback function that runs when the user has selected or
 *    cleared a date
 * @param placeholder - Array of strings that indicate the placeholder values for the range picker [Start, End]
 * @param showTime - Optional object to convert into datetimepicker
 */
export default function RangePicker({ onChange, 'data-testid': dataTestId, ...rest }: Props) {
  return (
    <AntRangePicker
      allowClear
      inputReadOnly
      allowEmpty={[true, true]}
      disabledDate={disabledDate}
      onChange={(v, stringValues) => {
        // We create the moment objects this way so that the timezone is considered.
        const start = stringValues[0] ? moment(stringValues[0]) : null
        const end = stringValues[1] ? moment(stringValues[1]) : null

        onChange([start, end])
      }}
      ranges={{
        Today: [moment().startOf('day'), moment().endOf('day')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment().endOf('day')],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().endOf('day')],
        'All Time': [null, null],
      }}
      data-testid={dataTestId}
      {...rest}
    />
  )
}
