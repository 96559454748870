import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Edit Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A414
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismEditIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M12.9978 6.97932L16.0186 10.0001L8.94911 17.0695L5.02778 17.9701L5.92834 14.0488L12.9978 6.97932ZM16.7257 9.29298L13.7049 6.27222L15.77 4.20711L18.7908 7.22787L16.7257 9.29298Z"
        stroke="currentColor"
      />
    </svg>
  )
}
