import { useEffect } from 'react'
import React from 'react'

import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'

import { getterKeys, service, useQuery } from 'api'
import { dismiss, warning } from 'components/PrismNotification/PrismNotification'
import { open } from 'components/PrismNotification/PrismNotification'
import { NODE_ENV } from 'env'
import {
  useAllToolLabels,
  useColocatedStation,
  useConnectionStatus,
  useHeap,
  useInterval,
  useIsColocated,
  useIsFlourish,
  useSetGlobalStyles,
  useTypedSelector,
} from 'hooks'
import paths from 'paths'
import { robotCapabilitesSet } from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import { RobotDiscoveriesById } from 'types'
import { getMinifiedOrgId, matchRole } from 'utils'

import { Button } from './Button/Button'
import NotificationsListener from './NotificationsListener'
import RobotsStatusListener from './RobotsStatusListener'

const PRISM_IS_OFFLINE_NOTIFICATION = 'prism_is_offline_notification'

/**
 * Renders nothing. Use this for setting global state in Redux, e.g. by
 * subscribing to certain changes, setting up app wide logic that shouldn't be active on the login screen,
 * or by fetching useful data that requires the auth token to fetch. When the app mounts and user logs in.
 */
function OnLogin() {
  useAllToolLabels()
  useHeap()
  const { colocatedStation } = useColocatedStation()
  const connectionStatus = useConnectionStatus()
  const history = useHistory()
  const { isColocated } = useIsColocated()
  const dispatch = useDispatch()
  const { organizationData } = useIsFlourish()
  const currentOrg = useTypedSelector(state => state.currentOrg)

  // Check app version on nginx, prompt user to upgrade if possible.
  useInterval(() => checkAppVersion(organizationData.service), 30 * 60 * 1000, { callImmediately: true })

  // When user is offline, we want to block refreshing and back and forward navigation as nothing would load
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (
        e.key === 'F5' ||
        (e.key === 'r' && e.ctrlKey) ||
        (e.key === 'R' && e.ctrlKey) ||
        (e.key === 'ArrowLeft' && e.altKey) ||
        (e.key === 'ArrowRight' && e.altKey)
      )
        return e.preventDefault()
    }

    if (connectionStatus === 'offline') window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [connectionStatus])

  useQuery(getterKeys.organization(), service.getOrganization, {
    refetchKey: currentOrg?.organization.id,
    intervalMs: 15 * 60 * 1000,
  })

  useSetGlobalStyles(currentOrg ? !!currentOrg.organization.rw_tenant_id : undefined)

  const stations = useQuery(getterKeys.stations('all-with-robots'), () => service.getStations({ has_robots: true }))
    .data?.data.results

  useEffect(() => {
    if (stations) {
      const robotIds = stations.flatMap(station => station.robots.flatMap(robot => robot.id))
      getAndSetRobotCapabilities(dispatch, robotIds)
    }
  }, [dispatch, stations])

  // Shows a persistent notification when the user tries to navigate while offline
  useEffect(() => {
    if (!isColocated || !currentOrg) return
    const isAtStationScreen = history.location.pathname.startsWith(
      `/o/${getMinifiedOrgId(currentOrg.organization.id)}/station/`,
    )

    if (connectionStatus === 'offline') {
      if (!isAtStationScreen) {
        warning({
          id: PRISM_IS_OFFLINE_NOTIFICATION,
          title: `${organizationData.service} is offline`,
          description: `Only inspections are available while ${organizationData.service} is in offline mode.`,
          children: (
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                dismiss(PRISM_IS_OFFLINE_NOTIFICATION)
                history.push(paths.stationDetail('overview', colocatedStation?.id))
              }}
            >
              Go to Inspect
            </Button>
          ),
          duration: 0,
        })
      }

      if (isAtStationScreen) dismiss(PRISM_IS_OFFLINE_NOTIFICATION)
      return
    }
    // When back online or if navigating into inspection screen, remove notification
    dismiss(PRISM_IS_OFFLINE_NOTIFICATION)
  }, [
    history,
    history.location,
    connectionStatus,
    isColocated,
    colocatedStation?.id,
    organizationData.service,
    currentOrg,
  ])

  return (
    <>
      {/* Global organization notifications listener */}
      {matchRole('manager') && <NotificationsListener />}
      {/* Global colocated robots listener */}
      <RobotsStatusListener />
    </>
  )
}

export default OnLogin

export async function getAndSetRobotCapabilities(
  dispatch: Dispatch,
  robotIds: string[],
  store: TypedStore = typedStore,
) {
  const state = store.getState()
  // We start with the current values
  const robotDiscoveriesById: RobotDiscoveriesById = { ...state.robotDiscoveriesById }

  const res = await service.atomGetRobotDiscovery(robotIds)
  if (res.type === 'success') {
    robotIds.forEach(robotId => {
      // We only update the value of the fetched robot discoveries, the rest will remain the same
      robotDiscoveriesById[robotId] = { basler: res.data[robotId]?.basler }
    })
  }
  if (!isEqual(robotDiscoveriesById, state.robotDiscoveriesById)) dispatch(robotCapabilitesSet(robotDiscoveriesById))
}

/**
 * Pings our Nginx server to get our index.html file which we then compare the script tags of
 * to the script tags that we're currently running. Prompts the user to upgrade (reload) if the bundles
 * are different.
 *
 */
async function checkAppVersion(appName: string) {
  if (NODE_ENV !== 'production') return

  const origin = window.location.origin
  const res = await fetch(origin)
  const html = await res.text()

  const parser = new DOMParser()
  const serverHtml = parser.parseFromString(html, 'text/html')
  const serverScripts = serverHtml.querySelectorAll('script')
  const serverScriptUrls = [...serverScripts].map(s => s.src).filter(val => !!val && val.includes('/static/js/'))

  const activeScripts = document.querySelectorAll('script')
  const activeScriptUrls = [...activeScripts].map(s => s.src).filter(val => !!val && val.includes('/static/js/'))

  const activeScriptUrlsJoined = activeScriptUrls.sort().join()
  const serverScriptUrlsJoined = serverScriptUrls.sort().join()

  if (activeScriptUrlsJoined !== serverScriptUrlsJoined) {
    open({
      id: 'app-update-notification',
      closable: true,
      title: `${appName} Update Available`,
      description: `An improved version of ${appName} is ready for you. Update instantly to use it.`,
      duration: 0,
      children: (
        <Button type="primary" size="small" onClick={() => window.location.reload()}>
          Update
        </Button>
      ),
      position: 'bottom-left',
    })
  }
}
