import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Ellipsee Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4527%3A11833
 *
 * @param className - String for external styles.
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - adds special styles to the icon.
 */
export const PrismPolygonIcon = ({ hasBackground, isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${isActive ? Styles.active : ''} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M4.02828 9.40983L12 3.61803L19.9717 9.40983L16.9268 18.7812H7.0732L4.02828 9.40983Z"
        stroke="currentColor"
      />
    </svg>
  )
}
