import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Check thick Icon.
 * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1903%3A9801
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismCheckIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M17.0093 9.15079C17.3687 8.73147 17.3201 8.10017 16.9008 7.74074C16.4815 7.38132 15.8502 7.42988 15.4907 7.84921L17.0093 9.15079ZM10.25 15.5L9.54289 16.2071C9.73985 16.4041 10.01 16.51 10.2884 16.4993C10.5667 16.4886 10.828 16.3623 11.0093 16.1508L10.25 15.5ZM8.45711 12.2929C8.06658 11.9024 7.43342 11.9024 7.04289 12.2929C6.65237 12.6834 6.65237 13.3166 7.04289 13.7071L8.45711 12.2929ZM15.4907 7.84921L9.49074 14.8492L11.0093 16.1508L17.0093 9.15079L15.4907 7.84921ZM10.9571 14.7929L8.45711 12.2929L7.04289 13.7071L9.54289 16.2071L10.9571 14.7929Z"
        fill="currentColor"
      />
    </svg>
  )
}
