import React from 'react'

import { Token } from 'components/Token/Token'

import Styles from './DetailItemList.module.scss'

interface Props {
  list: { label: string; value: string | number; 'data-testid'?: string }[]
  className?: string
}

/**
 * Renders a box container with columns, where each item from the list is assigned to a column.
 *
 *
 * @param list - Array
 * @param className - string class
 *
 */
const DetailItemList = ({ list, className }: Props) => {
  return (
    <div className={`${Styles.detailItemList} ${className}`}>
      {list.map(listItem => (
        <Token
          key={listItem.label}
          label={listItem.label}
          className={Styles.detailItem}
          valueClassName={Styles.detailItemValue}
          data-testid={listItem['data-testid']}
        >
          {listItem.value}
        </Token>
      ))}
    </div>
  )
}

export default DetailItemList
