import React from 'react'

import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { Props as VideoFeedProps, VideoFeed } from 'components/Video/VideoFeed'
import { useTypedSelector } from 'hooks'
import { AtomElement } from 'types'

export interface Props
  extends Omit<VideoFeedProps, 'frame' | 'fallbackImage' | 'loader'>,
    React.ImgHTMLAttributes<HTMLImageElement>,
    JSX.IntrinsicAttributes {
  robotId: string
  element: AtomElement | ''
  stream: string
}

/**
 * Renders an image component which comes from redux. This component must be used together with
 * either a single stream connected to the same element and stream that we assign to this
 * element, or with the MultiVideoListener component that will fetch the necessary data.
 *
 * @param robotId - The robot ID to evaluate
 * @param element - The stream element we want to fetch
 * @param stream - Stream name to fetch
 *
 */
function ReduxVideo({ robotId, element, stream, showErrorOnFrameTimeout, ...rest }: Props) {
  const frameMeta = useTypedSelector(store => {
    return store.video[`${element}:${stream}:${robotId}`]
  })

  const isHardwareTrigger = useTypedSelector(state => {
    return state.triggerMode?.[robotId]?.triggerMode === 'hardware'
  })

  const frame = frameMeta?.frame
  const messageId = frameMeta?.message_id
  return (
    <VideoFeed
      fallbackImage={<PrismElementaryCube addBackground />}
      loader={<PrismSkeleton size="extraLarge" />}
      frame={frame}
      messageId={messageId}
      showErrorOnFrameTimeout={showErrorOnFrameTimeout ?? !isHardwareTrigger}
      {...rest}
    />
  )
}

export default ReduxVideo
