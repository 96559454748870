import React, { Fragment } from 'react'

/**
 * Component which converts an array of children react nodes into a serialized comma sepparated list of items.
 * It's usefult to have this component over a simple .join(', ') function, since this way we can serialize complex
 * jsx, for instance, an icon plus a string, or a series of buttons.
 * @param children - Array of react nodes to render
 * @param joint - joint to use for the list. If null, the list will simply be a comma separated list.
 * @param fullStop - whether we should show a fullstop at the end of the serial list
 */
const SerialList = ({
  children,
  joint = 'and',
  fullStop = false,
}: {
  children: JSX.Element[] | string[]
  joint?: 'and' | 'or' | null
  fullStop?: boolean
}) => {
  return (
    <>
      {children.map((child, key) => (
        <Fragment key={key}>
          {child}

          {children.length - 1 === key && fullStop && '.'}

          {((joint && children.length > 2) || (!joint && children.length > 1)) && key < children.length - 1 && ', '}

          {/* Special case: When array is of size 2 and we have a joint, we add a space  */}
          {joint && children.length === 2 && key === 0 && ' '}

          {joint && children.length > 1 && key === children.length - 2 && `${joint} `}
        </Fragment>
      ))}
    </>
  )
}

export default SerialList
