import { useEffect, useRef } from 'react'

import { useDispatch } from 'react-redux'

import { fetchRobotToolsets } from 'utils'

export interface Props {
  robotId: string
  intervalMs: number
  onLoadingStart?: (robotId: string) => void
  onLoadingEnd?: (robotId: string) => void
}

/**
 * Renders nothing. Makes sure we eventually fetch toolsets for robot.
 *
 * @param robotId - Robot id
 * @param intervalMs - If we don't have toolsets, wait this long before trying to
 *     fetch toolsets again
 * @param onLoadingStart - Callback for when we start loading a robot's toolsets
 * @param onLoadingEnd - Callback for when we finish loading a robot's toolsets
 */
function RobotToolsetsFetcher({ robotId, intervalMs, onLoadingStart, onLoadingEnd }: Props) {
  const mountedRef = useRef(true)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    onLoadingStart && onLoadingStart(robotId)
    function fn() {
      fetchRobotToolsets(robotId, dispatch).then(res => {
        if (res?.type !== 'success' && mountedRef.current) setTimeout(fn, intervalMs)
        if (res?.type === 'success') onLoadingEnd && onLoadingEnd(robotId)
      })
    }
    fn()
  }, [dispatch, robotId, intervalMs, onLoadingStart, onLoadingEnd])

  return null
}

export default RobotToolsetsFetcher
