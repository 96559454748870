import React, { useEffect, useRef } from 'react'

import { isEqual } from 'lodash'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'
import request from 'request-dot-js'

import { getterKeys, service, useQuery } from 'api'
import { ROBOT_FASTAPI_URL } from 'env'
import { useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import { Me, RootState } from 'types'
import { LOCAL_TIMEZONE } from 'utils/constants'

import OnLogin from './OnLogin'

/**
 * Renders nothing. Use this for setting global state in Redux, e.g. by
 * subscribing to certain changes or by setting intervals, when the app mounts.
 * This component should be mounted near the root of the render tree.
 */
function OnMountApp() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const edgeSetParamsIntervalId = useRef<number>()
  const meRes = useQuery(getterKeys.me(), async () => {
    const res = await service.me()

    if (res.type === 'success') {
      // We need to set the moment default timezone as soon as we have the user data
      setMomentDefaultTimezone(res.data.timezone)
    }

    return res
  })
  const currentOrg = useTypedSelector(state => state.currentOrg)

  const me = meRes.data?.data

  useEffect(() => {
    setMomentDefaultTimezone(me?.timezone)
  }, [me?.timezone])

  // Once this attribute is in head, other scripts know React web app has been parsed and run
  useEffect(() => {
    const headElement = document.getElementsByTagName('head')[0]
    headElement?.setAttribute('data-elementaryapploaded', 'true')
  }, [])

  // store edge params fetched from FastAPI
  useEffect(() => {
    getAndSetEdgeParams(dispatch)
    edgeSetParamsIntervalId.current = window.setInterval(() => getAndSetEdgeParams(dispatch), 10 * 1000)

    return () => window.clearInterval(edgeSetParamsIntervalId.current)
  }, [dispatch])

  // subscribe to browser location changes
  useEffect(() => {
    dispatch(Actions.locationUpdate({ location, action: history.action }))
  }, [dispatch, location, history])

  if (!me || !currentOrg?.is_active) return null
  return <OnLogin />
}

export default OnMountApp

/**
 * Get robot params from edge FastAPI server. This can be on an interval and the
 * result can be stored in Redux so the rest of the app knows whether a given
 * edge FastAPI instance is available.
 *
 * @param baseUrl - baseUrl of robot's FastAPI server
 *
 * @returns Is local FastAPI server available?
 */
async function fastApiGetRobotParams(baseUrl: string) {
  const res = await request<{ robot_id: string }>(`${baseUrl}/atom/robot_params`, {
    retry: { retries: 1, delay: 100 },
    timeout: 250,
  })
  if (res.type === 'success') return res.data
  return null
}

/**
 * Hits primary edge FastAPI server to get robot URLs.
 *
 * @returns Edge params, or null if there's an error
 */
async function fastApiGetRobotUrls() {
  const res = await request<string[]>(`${ROBOT_FASTAPI_URL}/atom/robot_urls`, {
    retry: { retries: 1, delay: 100 },
    timeout: 250,
  })
  if (res.type === 'success') return res.data
  return null
}

/**
 * Fetches robot URLs from primary FastAPI instance. Then takes these URLs, or
 * previously stored URLs if we fail to fetch these, and hits FastAPI server
 * instances listed therein to get params from each and determine which are
 * enabled. Sets this information in Redux and returns these params.
 *
 * @param dispatch - Redux dispatch function
 * @param store - Redux store instance for app
 *
 * @returns Params for each robot associated with edge FastAPI servers
 */
export async function getAndSetEdgeParams(dispatch: Dispatch, store: TypedStore = typedStore) {
  const edgeUrls = await fastApiGetRobotUrls()
  const state = store.getState()
  // Make sure we can get URLs even if primary FastAPI instance is down
  const urls = edgeUrls || state.edge.urls
  // Make sure we include primary FastAPI URL if primary is up
  if (edgeUrls !== null && !urls.includes(ROBOT_FASTAPI_URL)) urls.push(ROBOT_FASTAPI_URL)

  const edge: RootState['edge'] = { urls, paramsByEdgeUrl: {} }
  const responses = await Promise.all(urls.map(url => fastApiGetRobotParams(url)))
  urls.forEach((url, idx) => {
    const pastEdgeState = state.edge.paramsByEdgeUrl[url]
    const res = responses[idx]
    if (res) {
      // If we get a response from a URL, set params based on response
      const wsUrl = url.replace('http://', 'ws://').replace('https://', 'wss://')
      const { robot_id } = res
      edge.paramsByEdgeUrl[url] = { wsUrl, robot_id, enabled: true }
    } else if (pastEdgeState) {
      // If we get no response from a URL, but had gotten one previously, mark this URL as disabled
      edge.paramsByEdgeUrl[url] = { ...pastEdgeState, enabled: false }
    }
  })

  if (!isEqual(edge, state.edge)) dispatch(Actions.edgeSet(edge))
  return edge
}

/**
 * Gets unique, truthy robot ids from edge branch of state tree. Note, this
 * returns robots whose FastAPI servers may not be available.
 *
 * @param edge - `edge` branch of state tree
 *
 * @returns Array of robot ids
 */
export function getRobotIdsFromEdgeParams(edge: RootState['edge'], exclude_disabled = false) {
  let paramsArray = Object.values(edge.paramsByEdgeUrl)
  if (exclude_disabled) paramsArray = paramsArray.filter(obj => obj.enabled)
  const ids = paramsArray.map(obj => obj.robot_id).filter(id => id)
  return [...new Set(ids)]
}

const setMomentDefaultTimezone = (timezone?: Me['timezone']) => {
  moment.tz.setDefault(timezone || LOCAL_TIMEZONE)
}
