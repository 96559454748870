import { Action } from 'rdx/actions'
import { RootState } from 'types'

const BUFFER_LENGTH = 100

/**
 * This branch of state tree stores location objects,
 * https://developer.mozilla.org/en-US/docs/Web/API/Location, for locations
 * visited by app.
 *
 * The most recently visited locations are first in the stack. History stack
 * size grows by one each time user navigates to new route, and is limited by
 * buffer length.
 */
export default function reduce(state: RootState['locationHistory'] = { history: [] }, action: Action) {
  if (action.type === 'LOCATION_UPDATE') {
    const { location, action: historyAction } = action.payload
    const routes = [...state.history]

    // React router creates a pop action on going back AND going forward, which would eventually remove all locations from the stack
    if (historyAction === 'PUSH' || historyAction === 'POP') routes.unshift(location)
    if (historyAction === 'REPLACE') routes[0] = location

    return { ...state, history: routes.slice(0, BUFFER_LENGTH) }
  }
  return state
}
