import React, { useCallback } from 'react'

import FullScreen, { FullScreenHeader } from 'components/FullScreen/FullScreen'
import { IconButton } from 'components/IconButton/IconButton'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismArrowIcon } from 'components/prismIcons'
import { useHotkeyPress } from 'hooks'

import Styles from './LabelingScreen.module.scss'

/**
 * Renders a fullscreen carousel-like image view, used to show the user or fallback images from a Tool Label
 *
 * @param imageUrls - the current tool label image urls to be rendered
 * @param setSelectedImageIdx - function that sets the selected image index
 * @param selectedImageIdx - the current selected image index
 * @param label - Fullscreen header left side label
 */
const FullScreenLabelImages = ({
  imageUrls,
  selectedImageIdx,
  setSelectedImageIdx,
  label,
}: {
  imageUrls: string[]
  selectedImageIdx: number
  setSelectedImageIdx: (idx?: number) => void
  label?: string
}) => {
  const nextImage = selectedImageIdx !== undefined && imageUrls[selectedImageIdx + 1]
  const prevImage = selectedImageIdx !== undefined && imageUrls[selectedImageIdx - 1]

  const handleNextImage = useCallback(
    () => setSelectedImageIdx(selectedImageIdx + 1),
    [selectedImageIdx, setSelectedImageIdx],
  )

  const handlePrevImage = useCallback(
    () => setSelectedImageIdx(selectedImageIdx - 1),
    [selectedImageIdx, setSelectedImageIdx],
  )

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') setSelectedImageIdx(undefined)
      if (nextImage && e.key === 'ArrowRight') handleNextImage()
      if (prevImage && e.key === 'ArrowLeft') handlePrevImage()
    },
    [handleNextImage, handlePrevImage, nextImage, prevImage, setSelectedImageIdx],
  )

  useHotkeyPress(keyHandler, { capture: true })

  return (
    <FullScreen
      id="fullscreen-label-images"
      onClose={() => setSelectedImageIdx(undefined)}
      className={Styles.fullScreenWrapper}
    >
      <FullScreenHeader
        onCloseClick={() => setSelectedImageIdx(undefined)}
        leftSide={`Example ${selectedImageIdx + 1} ${label ? `of ${label}` : ''}`}
      />
      <ImgFallback src={imageUrls[selectedImageIdx]} className={Styles.fullscreenImageContainer} />

      {nextImage && (
        <IconButton
          isOnTop
          type="secondary"
          icon={<PrismArrowIcon direction="right" />}
          onClick={() => handleNextImage()}
          className={` ${Styles.fullScreenArrow} ${Styles.arrowRight}`}
        />
      )}

      {prevImage && (
        <IconButton
          isOnTop
          type="secondary"
          icon={<PrismArrowIcon direction="left" />}
          onClick={() => handlePrevImage()}
          className={` ${Styles.fullScreenArrow} ${Styles.arrowLeft}`}
        />
      )}
    </FullScreen>
  )
}

export default FullScreenLabelImages
