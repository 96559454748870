import React, { useMemo } from 'react'

import { ToolResultsData } from 'api'
import { LabelButtonSeverity, PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import VirtualizedCarousel from 'components/VirtualizedCarousel/VirtualizedCarousel'
import { useLiveBatchCarousels, useQueryParams } from 'hooks'
import { DetailModal } from 'pages/ItemDetail/DetailModal'
import { Threshold, Tool } from 'types'
import { titleCase } from 'utils'

import { CardItem } from './CardItem'
import { ThresholdByRoutineParentId } from './TrainingReport'
import Styles from './TrainingReport.module.scss'

/**
 * Renders carousels with item cards for current inspection running. This component should only show up in the inspection
 * screen, when there's an inspection running.
 *
 * @param inspectionId -Id of inspection running
 * @param threshold - Threshold to use. This is an array always since this will show only graded anomaly carousels
 * @param tool - Tool to show results for
 * @param modalLoaded - Whether the modal has already loaded. Images should only show up after the modal loaded
 * @param aoiParentId - Used to filter the results we want to get.
 * @param thresholdByRoutine - Object including current threshold by routine,
 * used to calculate predictions for overriden threholds
 */
export const LiveBatchCarousels = ({
  inspectionId,
  threshold,
  tool,
  modalLoaded,
  aoiParentId,
  thresholdByRoutine,
}: {
  inspectionId: string
  threshold: Threshold
  tool: Tool
  modalLoaded: boolean
  aoiParentId?: string
  thresholdByRoutine: ThresholdByRoutineParentId | undefined
}) => {
  const [params] = useQueryParams()
  const { detail_item_id, detail_tool_result_id } = params

  const { carousels, loading } = useLiveBatchCarousels({
    tool,
    aoiParentId,
    threshold,
    inspectionId,
  })

  const selectedToolResultBranch = useMemo(() => {
    if (!detail_tool_result_id || !carousels) return

    let toolResults: ToolResultsData | undefined = undefined
    for (const carousel of carousels) {
      if (carousel.dataSource?.results.find(toolRes => toolRes.id === detail_tool_result_id)) {
        toolResults = carousel.dataSource
      }
    }
    return toolResults
  }, [carousels, detail_tool_result_id])

  const renderTitle = (value: string, severity: LabelButtonSeverity) => (
    <PrismResultButton severity={severity} value={value} type="noFill" size="small" />
  )

  return (
    <>
      {carousels?.map(carouselData => {
        const { header, onEndReached, dataSource } = carouselData
        return (
          <VirtualizedCarousel
            key={header.value}
            loading={loading}
            title={
              <div className={Styles.cardBoxHeaderLeft}>Predicted: {renderTitle(header.value, header.severity)}</div>
            }
            description={header.description}
            onEndReached={onEndReached}
            cards={dataSource?.results || []}
            renderer={(toolResult, firstCardRef) => (
              <CardItem
                key={toolResult.id}
                threshold={threshold}
                toolResult={toolResult}
                modalLoaded={modalLoaded}
                tool={tool}
                type="ghost4"
                cardRef={firstCardRef}
                thresholdByRoutine={thresholdByRoutine}
              />
            )}
            emptyState={
              dataSource?.results.length === 0 ? (
                <div className={Styles.noPredictedImagesContainer}>
                  No images predicted as {titleCase(header.value)}
                </div>
              ) : null
            }
            fullCards
            cardGap={16}
            carouselWrapperClassName={`${Styles.carouselWrapper} ${
              dataSource?.results.length === 0 ? Styles.emptyStateWrapper : ''
            }`}
            carouselHeaderClassName={Styles.carouselHeader}
            carouselBodyClassName={Styles.caoruselBody}
            listContainerClassName={Styles.cardListCarouselContainer}
          />
        )
      })}

      {(detail_item_id || detail_tool_result_id) && <DetailModal toolResultsInitialRes={selectedToolResultBranch} />}
    </>
  )
}
