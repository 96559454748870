import React from 'react'
import { useEffect, useState } from 'react'

import { service } from 'api'
import { IconButton } from 'components/IconButton/IconButton'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismPlcIcon, PrismUserIcon } from 'components/prismIcons'
import { PrismCheckIcon } from 'components/prismIcons/PrismCheckIcon'
import { error, success } from 'components/PrismMessage/PrismMessage'

import Styles from './CommunicationsList.module.scss'

type Props = {
  robotId: string
}

function PlcDisabledToggle({ robotId }: Props) {
  const [isPlcDisabled, setIsPlcDisabled] = useState<boolean>()

  useEffect(() => {
    const getIsPlcDisabled = async () => {
      const res = await service.atomSendCommand<{ is_disabled: boolean }>(
        'integration-framework',
        'is_plc_disabled',
        robotId,
        { command_args: {} },
      )

      if (res.type === 'success' && res.data.success) {
        setIsPlcDisabled(res.data.result.data?.is_disabled || false)
      }
    }

    getIsPlcDisabled()
  }, [robotId])

  const setPlcDisabled = async (newValue: boolean) => {
    const res = await service.atomSendCommand('integration-framework', 'set_plc_disabled', robotId, {
      command_args: { is_disabled: newValue },
    })

    if (res.type !== 'success' || !res.data.success) {
      return error({ title: 'There was an issue updating the control mode, please try again' })
    }
    setIsPlcDisabled(newValue)
    success({ title: 'Control mode updated' })
  }

  const options = [
    {
      value: 'user',
      title: (
        <div className={Styles.itemList}>
          <PrismUserIcon /> <span>Operator control</span>
          <PrismCheckIcon className={`${Styles.checkmark} ${isPlcDisabled ? Styles.show : ''}`} />
        </div>
      ),
      isActive: isPlcDisabled,
    },
    {
      value: 'plc',
      title: (
        <div className={Styles.itemList}>
          <PrismPlcIcon /> <span>PLC control</span>
          <PrismCheckIcon className={`${Styles.checkmark} ${!isPlcDisabled ? Styles.show : ''}`} />
        </div>
      ),
      isActive: !isPlcDisabled,
    },
  ]

  return (
    <OptionMenu
      openWithClick
      options={options}
      onMenuItemClick={value => {
        setPlcDisabled(value === 'user' ? true : false)
      }}
      menuContainerClassName={Styles.dropdownList}
      menuItemClassName={Styles.menuItem}
    >
      <IconButton
        size="xsmall"
        type="tertiary"
        icon={isPlcDisabled ? <PrismUserIcon /> : <PrismPlcIcon />}
        disabled={isPlcDisabled === undefined}
      />
    </OptionMenu>
  )
}

export default PlcDisabledToggle
