import React from 'react'

import AppVersion from 'components/AppVersion/AppVersion'
import { Button } from 'components/Button/Button'
import FullscreenForm from 'components/FullscreenForm/FullscreenForm'
import { BrandLogo } from 'components/prismIcons'
import { PrismElementaryIsotypeIcon } from 'components/prismIcons/PrismElementaryIsotypeIcon'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { useIsColocated } from 'hooks'

import Styles from './LogInAndOut.module.scss'

const LogInAndOut = ({
  showLoadingState,
  onClick,
  rockwellLogin,
}: {
  showLoadingState: boolean
  rockwellLogin: boolean
  onClick: () => void
}) => {
  const { isColocated } = useIsColocated()
  const canShowLink = !rockwellLogin && !isColocated
  const formTitle = 'You are logged out'
  const formDescription = 'Log in to start a new session'

  const renderContent = () => {
    const toRender = []

    if (!rockwellLogin) {
      toRender.push(
        <div className={Styles.elementaryLogoContainer}>
          <PrismElementaryIsotypeIcon />
        </div>,
      )
    }

    if (rockwellLogin) {
      toRender.push(<BrandLogo className={Styles.formLogo} forceFlourishIcon />)
    }

    if (showLoadingState) {
      toRender.push(
        <div className={Styles.loaders}>
          {Array(2)
            .fill(undefined)
            .map((_, i) => (
              <div key={i}>
                <PrismSkeleton />
              </div>
            ))}
        </div>,
      )
    }

    if (!showLoadingState) {
      toRender.push(
        <>
          <h1 className={Styles.formTitle}>{formTitle}</h1>
          <p className={Styles.formDescription}>{formDescription}</p>
          <Button className={Styles.loginBtn} onClick={onClick} wide>
            Log In
          </Button>
        </>,
      )
    }

    if (!showLoadingState) {
      toRender.push(
        <AppVersion
          className={`${Styles.appVersion} ${canShowLink ? Styles.addLinkStyles : ''}`}
          rockwellLogin={rockwellLogin}
        />,
      )
    }
    return toRender
  }
  return (
    <FullscreenForm
      formClassName={`${Styles.formContainer}
      ${rockwellLogin ? Styles.flourish : Styles.prism}
       ${showLoadingState ? Styles.isLoading : Styles.logoutContainer}`}
    >
      {renderContent()}
    </FullscreenForm>
  )
}

export default LogInAndOut
