import { Action } from 'rdx/actions'
import { RootState, StreamMessage } from 'types'

const BUFFER_LENGTH = 100

/**
 * This branch of state tree stores Redis messages read over websocket, e.g.
 * using the `StreamListener` component. It stores ring buffers (most recent
 * message in first position) instead of most recent message only.
 *
 * https://stackoverflow.com/questions/48060887/javascript-efficiently-move-items-in-and-out-of-a-fixed-size-array
 */
export default function reduce(state: RootState['events'] = {}, action: Action): RootState['events'] {
  switch (action.type) {
    case 'EVENT': {
      const { messages: newMessages, key, ignoreStaleMessage = true, bufferLength = BUFFER_LENGTH } = action.payload
      const messages: StreamMessage[] = (state[key] || []).map(message => ({ ...message, new: false }))

      // put new message onto beginning of buffer, trim buffer if necessary
      for (const message of newMessages) {
        const firstMessage = messages[0] as StreamMessage | undefined
        // If we have already pushed this message onto buffer, don't push it onto buffer again
        if (ignoreStaleMessage && firstMessage && message.message_id <= firstMessage.message_id) continue
        messages.unshift({ ...message, new: true })
      }

      return {
        ...state,
        [key]: messages.slice(0, bufferLength),
      }
    }
    default:
      return state
  }
}
