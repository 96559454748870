import React, { useState } from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import { getterKeys, service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { useAllToolLabels, useData, useToolTrainingResults } from 'hooks'
import { RoutineWithAois, Tool, ToolThreshold, TrainableToolSettingsProps } from 'types'
import { calculateTrainingMetrics, computeConfusionMatrix, getThresholdFromTool } from 'utils'
import { DEFAULT_TOOL_LABELS_GETTER_KEY } from 'utils/constants'

import { SharedTooltip } from '../ToolCard'
import EditAoisHelper from './AOIEditing/EditAoisHelper'
import { isValidAoi } from './AOIEditing/Utils'
import ThresholdCriteria from './ThresholdCriteria'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders Anomaly Tool form.
 *
 * This shows the name of the anomaly tool and the dataset and level of training
 * linked to it. As well as the threshold the anomaly tool is using as its pass
 * criteria.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const AnomalyToolSettings = ({ routine, recipe, view, tool, onExit, readOnly }: TrainableToolSettingsProps) => {
  const [showThresholdModal, setShowThresholdModal] = useState(false)
  const { allToolLabels } = useAllToolLabels()

  const defaultValues = getDefaultFormValues(routine, tool)
  const formData = useForm({ defaultValues, mode: 'onChange' })

  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
  } = formData

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const trainingResults = useToolTrainingResults(tool.id) || []

  const defaultLabels = useData(getterKeys.toolLabels(DEFAULT_TOOL_LABELS_GETTER_KEY))?.results

  const saveChanges = () => {
    const { threshold, aois } = getValues()
    const { dirtyFields } = formState

    if (dirtyFields.threshold) {
      if (!defaultLabels) return null

      const matrix = computeConfusionMatrix(trainingResults, tool.specification_name, {
        defaultLabels,
        allToolLabels,
        threshold: threshold,
      })
      const training_metrics = calculateTrainingMetrics(matrix, allToolLabels, tool)

      const updatedInferenceUserArgs = {
        ...tool.inference_user_args,
        threshold: threshold.threshold,
      }

      const thresoldPromise = service.patchProtectedTool(tool.id, {
        aois: aois.map(aoi => ({ id: aoi.id, inference_user_args: updatedInferenceUserArgs })),
        metadata: { ...tool.metadata, training_metrics },
      })
      return [thresoldPromise]
    }
  }

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    wrapperClassName={Styles.settingsItemInput}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    data-testid="anomaly-tool-rename-input"
                  />
                )}
              />
              {tool.is_shared && <SharedTooltip iconClassName={Styles.sharedToolIcon} />}

              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItemTitle}>Pass Criteria</div>
            <div className={`${Styles.settingsItemCriteria} ${Styles.matchCriteriaContainer}`}>
              <ThresholdCriteria
                showThresholdModal={showThresholdModal}
                setShowThresholdModal={setShowThresholdModal}
                tool={tool}
                control={control}
                getValues={getValues}
                view={view}
              />
            </div>
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          rules={{ validate: aois => isValidAoi(aois[0]) }}
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              disableToggleButtons={showThresholdModal}
              enabledShapes={['rectangle', 'ellipse', 'polygon']}
              disableRectangleRotation={false}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const { name, aois } = getToolNameAndAois(routine, tool)
  return {
    name,
    aois,
    threshold: getThresholdFromTool(tool) as ToolThreshold,
  }
}
