import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Product Icon
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=5471-11325&mode=design&t=hzhtdFZnuBNoKzv7-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismProductIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M12 21.4786L11.75 21.9116C11.9047 22.0009 12.0953 22.0009 12.25 21.9116L12 21.4786ZM12 3L12.25 2.56699H11.75L12 3ZM12 12.2376L11.75 12.6706L12 12.815L12.25 12.6706L12 12.2376ZM19.97 16.8771L19.72 16.4441L19.97 16.8771ZM4.09 7.67076L4.34 7.23775L4.09 7.67076ZM4.09 7.56684L4.34 7.99985L4.09 7.56684ZM19.91 7.67416L20.16 8.10717L19.91 7.67416ZM4.03 16.8771L4.28 16.4441L4.03 16.8771ZM19.91 7.56684L20.16 7.13383L19.91 7.56684ZM19.91 7.67076L20.16 8.10378L19.91 7.67076ZM4.09 7.67415L3.84 8.10717L4.09 7.67415ZM3.84 8.10717L11.75 12.674L12.25 11.808L4.34 7.24114L3.84 8.10717ZM11.5 12.241L11.5 21.4786H12.5L12.5 12.241H11.5ZM12.25 21.0456L4.28 16.4441L3.78 17.3101L11.75 21.9116L12.25 21.0456ZM4.5 16.8252L4.5 7.72612H3.5L3.5 16.8252H4.5ZM11.75 3.43301L19.66 7.99985L20.16 7.13383L12.25 2.56699L11.75 3.43301ZM19.66 7.23775L11.75 11.8046L12.25 12.6706L20.16 8.10378L19.66 7.23775ZM12.25 11.8046L4.34 7.23775L3.84 8.10378L11.75 12.6706L12.25 11.8046ZM4.34 7.99985L12.25 3.43301L11.75 2.56699L3.84 7.13383L4.34 7.99985ZM12.25 12.674L20.16 8.10717L19.66 7.24114L11.75 11.808L12.25 12.674ZM19.5 7.72612V16.8252H20.5V7.72612H19.5ZM19.72 16.4441L11.75 21.0456L12.25 21.9116L20.22 17.3101L19.72 16.4441ZM19.5 16.8252C19.5 16.668 19.5839 16.5227 19.72 16.4441L20.22 17.3101C20.3933 17.2101 20.5 17.0252 20.5 16.8252H19.5ZM4.34 7.23775C4.63334 7.40711 4.63333 7.8305 4.34 7.99985L3.84 7.13383C3.46667 7.34937 3.46666 7.88823 3.84 8.10378L4.34 7.23775ZM20.16 8.10717C19.8667 8.27653 19.5 8.06482 19.5 7.72612H20.5C20.5 7.29503 20.0333 7.0256 19.66 7.24114L20.16 8.10717ZM4.28 16.4441C4.41614 16.5227 4.5 16.668 4.5 16.8252H3.5C3.5 17.0252 3.60674 17.2101 3.78 17.3101L4.28 16.4441ZM19.66 7.99985C19.3667 7.8305 19.3667 7.4071 19.66 7.23775L20.16 8.10378C20.5333 7.88824 20.5333 7.34938 20.16 7.13383L19.66 7.99985ZM4.34 7.24114C3.96667 7.0256 3.5 7.29502 3.5 7.72612H4.5C4.5 8.06483 4.13333 8.27652 3.84 8.10717L4.34 7.24114Z"
        fill="currentColor"
      />
    </svg>
  )
}
