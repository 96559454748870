import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconOutcomeProps } from './prismIconsTypes'

/**
 * Prism Unknown Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5412%3A11831
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  low: Styles.lowContrast,
  high: Styles.highContrast,
  dark: Styles.dark,
  iconColor: Styles.color,
}
export const PrismUnknownIcon = ({
  variant = 'transparent',
  viewboxSize = 'medium',
  className,
}: PrismIconOutcomeProps) => {
  const variantClass = variantClasses[variant]
  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.outcome} ${variantClass} ${className ?? ''}`

  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.063 6.18073C9.74028 6.15841 9.4176 6.22525 9.13026 6.37393C8.84292 6.52262 8.60198 6.74743 8.43378 7.02379C8.26558 7.30016 8.17658 7.61744 8.17652 7.94097C8.17646 8.26586 7.91304 8.52918 7.58815 8.52912C7.26326 8.52906 6.99994 8.26563 7 7.94075C7.0001 7.40153 7.14843 6.87273 7.42877 6.41212C7.7091 5.95151 8.11066 5.57682 8.58956 5.32902C9.06846 5.08121 9.60626 4.96981 10.1442 5.00701C10.6821 5.0442 11.1995 5.22855 11.6397 5.5399C12.0799 5.85126 12.4261 6.27764 12.6404 6.77245C12.8547 7.26726 12.9288 7.81144 12.8547 8.34554C12.7806 8.87964 12.5611 9.38308 12.2202 9.80086C11.8793 10.2186 11.4301 10.5347 10.9217 10.7144C10.807 10.755 10.7077 10.8301 10.6374 10.9295C10.5672 11.0289 10.5295 11.1476 10.5296 11.2693V11.4704C10.5296 11.7953 10.2662 12.0587 9.94131 12.0587C9.61642 12.0587 9.35305 11.7953 9.35305 11.4704V11.2699C9.35305 11.2698 9.35305 11.27 9.35305 11.2699C9.35292 10.9049 9.46597 10.5486 9.67662 10.2505C9.88734 9.95233 10.1853 9.72686 10.5296 9.60516C10.8346 9.49732 11.1041 9.3077 11.3086 9.05704C11.5132 8.80637 11.6449 8.5043 11.6893 8.18385C11.7338 7.86339 11.6893 7.53688 11.5608 7.23999C11.4322 6.94311 11.2245 6.68728 10.9603 6.50046C10.6962 6.31365 10.3858 6.20304 10.063 6.18073Z"
      />
      <path
        d="M9.94135 13.8234C9.88317 13.8234 9.82631 13.8407 9.77794 13.873C9.72957 13.9053 9.69187 13.9513 9.66961 14.005C9.64734 14.0587 9.64152 14.1179 9.65287 14.1749C9.66422 14.232 9.69223 14.2844 9.73337 14.3255C9.7745 14.3667 9.82691 14.3947 9.88396 14.406C9.94102 14.4174 10.0002 14.4116 10.0539 14.3893C10.1077 14.367 10.1536 14.3293 10.1859 14.281C10.2182 14.2326 10.2355 14.1757 10.2355 14.1176C10.2355 14.0395 10.2045 13.9647 10.1493 13.9096C10.0942 13.8544 10.0194 13.8234 9.94135 13.8234Z"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.451 13.3839C9.59611 13.287 9.76671 13.2352 9.94123 13.2352C10.1753 13.2352 10.3997 13.3282 10.5652 13.4937C10.7307 13.6592 10.8236 13.8836 10.8236 14.1176C10.8236 14.2921 10.7719 14.4627 10.6749 14.6079C10.578 14.753 10.4401 14.8661 10.2789 14.9328C10.1177 14.9996 9.94025 15.0171 9.76908 14.9831C9.59792 14.949 9.44069 14.865 9.31728 14.7416C9.19388 14.6182 9.10984 14.4609 9.07579 14.2898C9.04175 14.1186 9.05922 13.9412 9.12601 13.7799C9.19279 13.6187 9.30589 13.4809 9.451 13.3839ZM10.0538 14.3894C10.0715 14.382 10.0885 14.3729 10.1046 14.3622C10.1369 14.3406 10.1644 14.313 10.1858 14.281C10.1964 14.2651 10.2056 14.2481 10.213 14.2302C10.2278 14.1943 10.2354 14.1561 10.2354 14.1176C10.2354 14.0984 10.2335 14.0792 10.2297 14.0602C10.2184 14.0032 10.1903 13.9508 10.1492 13.9096C10.1081 13.8685 10.0557 13.8405 9.99861 13.8291C9.97962 13.8254 9.9604 13.8235 9.94123 13.8235C9.90279 13.8235 9.86453 13.831 9.82867 13.8459C9.81078 13.8533 9.79376 13.8624 9.77782 13.8731C9.74586 13.8944 9.71823 13.9219 9.69667 13.9542C9.68591 13.9703 9.67683 13.9873 9.66949 14.0051C9.65478 14.0406 9.6471 14.0788 9.6471 14.1176C9.6471 14.137 9.64902 14.1562 9.65275 14.175C9.66402 14.2316 9.69181 14.2842 9.73325 14.3256C9.77468 14.367 9.82721 14.3948 9.88385 14.4061C9.90261 14.4098 9.92182 14.4118 9.94123 14.4118C9.98004 14.4118 10.0183 14.4041 10.0538 14.3894Z"
      />
    </svg>
  )
}
