import React from 'react'

import { PrismNavArrowIcon } from 'components/prismIcons'

import Styles from './PrismAlert.module.scss'

const alertStatus = {
  running: Styles.alert_running,
  paused: Styles.alert_paused,
  available: Styles.alert_available,
  offline: Styles.alert_offline,
}

/**
 * Renders an Alert component (left dot with pre-defined text)
 *
 *See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3777%3A11481
 *
 * @param status – Change the color on the status circle
 * @param description – Adds the description
 * @param onClick - {onClick}
 * @param disabled - Adds the disabled styles
 * @param active - Adds the active styles
 * @param icon - Adds a space for an icon, it is placed as the first element from left to right
 */
export const Alert = ({
  status = 'offline',
  description,
  onClick,
  disabled,
  active,
  icon,
  className = '',
}: {
  status?: 'running' | 'paused' | 'available' | 'offline'
  description: string
  onClick?: () => Promise<any> | any
  disabled?: boolean
  active?: boolean
  icon?: React.ReactNode
  className?: string
}) => {
  const statusClass = alertStatus[status]
  return (
    <div
      className={`${Styles.alertContainer} ${disabled ? Styles.disabled : ''} ${active ? Styles.active : ''} ${
        !!onClick ? Styles.alertContainerClickable : ''
      } ${icon ? Styles.alertIcon : ''} ${className}`}
      onClick={onClick}
    >
      {icon ? icon : <span className={`${Styles.alert} ${statusClass}`}></span>}
      <div className={`${Styles.alertDescription} ${icon ? Styles.alertIcon : ''}`}>{description}</div>
      {!!onClick && <PrismNavArrowIcon className={Styles.arrowContainer} />}
    </div>
  )
}

export const AlertBlankState = () => {
  return (
    <div className={Styles.alertContainer}>
      <div className={Styles.alertBlankText}>Nothing to report</div>
    </div>
  )
}
