import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Warning Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1405%3A157
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismWarningIcon = ({ hasBackground, className, isActive }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.warningIcon} ${isActive ? Styles.active : ''} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path
        id="Vector_2"
        d="M11.731 7.7836L12.0001 13.5L12.2687 7.7836C12.2703 7.74705 12.2645 7.71055 12.2516 7.67633C12.2387 7.64211 12.2189 7.61088 12.1935 7.58456C12.1681 7.55824 12.1376 7.53737 12.1038 7.52323C12.0701 7.5091 12.0338 7.50199 11.9972 7.50235V7.50235C11.9611 7.50269 11.9254 7.51031 11.8923 7.52475C11.8591 7.5392 11.8292 7.56017 11.8044 7.58642C11.7795 7.61267 11.7602 7.64366 11.7476 7.67754C11.735 7.71142 11.7294 7.74749 11.731 7.7836V7.7836Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M12 17.2458C11.8146 17.2458 11.6333 17.1908 11.4792 17.0878C11.325 16.9848 11.2048 16.8383 11.1339 16.667C11.0629 16.4957 11.0443 16.3072 11.0805 16.1254C11.1167 15.9435 11.206 15.7765 11.3371 15.6454C11.4682 15.5142 11.6352 15.425 11.8171 15.3888C11.999 15.3526 12.1875 15.3712 12.3588 15.4421C12.5301 15.5131 12.6765 15.6333 12.7795 15.7874C12.8825 15.9416 12.9375 16.1229 12.9375 16.3083C12.9375 16.5569 12.8387 16.7954 12.6629 16.9712C12.4871 17.147 12.2486 17.2458 12 17.2458Z"
        fill="currentColor"
      />
    </svg>
  )
}
