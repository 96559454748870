import React from 'react'

import { PrismCascader, PrismCascaderProps } from 'components/PrismCascader/PrismCascader'
import { useSitesAndLinesCascaderOptions } from 'hooks'
import { StationForSite } from 'types'
import { SiteAndLineCascaderLocation } from 'utils'

export const SitesAndLinesCascader = ({
  value,
  showStations,
  stationFilterFn,
  selectedOptionClassName = '',
  ...rest
}: Omit<PrismCascaderProps, 'value' | 'options'> & {
  value?: SiteAndLineCascaderLocation | undefined
  showStations?: boolean
  stationFilterFn?: (station: StationForSite) => boolean
  'data-testid'?: string
  selectedOptionClassName?: string
}) => {
  const sitesAndLinesOptions = useSitesAndLinesCascaderOptions({ showStations, stationFilterFn })
  const cascaderTitles = ['site', 'line']
  if (showStations) cascaderTitles.push('station')

  return (
    <PrismCascader
      {...rest}
      multiple={false}
      titles={cascaderTitles}
      options={sitesAndLinesOptions}
      value={value as (string | number)[]}
      selectedOptionClassName={selectedOptionClassName}
      showSearch={{
        filter: (inputValue, path) => {
          // Filter out title options
          if (path.some(option => option.value?.toString().endsWith('_title'))) return false
          return path.some(option => (option.label as string).toLowerCase().includes(inputValue.toLowerCase()))
        },
      }}
    />
  )
}

export const getSitesAndLineCascaderValue = ({
  siteId,
  stationSubtypeId,
  lineId,
  stationId,
}: {
  siteId?: string
  stationSubtypeId?: string
  lineId?: string
  stationId?: string
}): SiteAndLineCascaderLocation | undefined => {
  if (!siteId || !stationSubtypeId) return undefined

  let value: SiteAndLineCascaderLocation = [`${siteId}_${stationSubtypeId}`]

  if (lineId || stationId) {
    value = [...value, lineId || null]
  }

  if (stationId) {
    value = [...value, stationId]
  }

  return value
}

export const getSiteLineAndStationIdsFromCascaderValue = (value: SiteAndLineCascaderLocation) => {
  const [siteAndType, lineId, stationId] = value
  const [siteId, stationSubtypeId] = siteAndType.split('_')
  return {
    siteId,
    stationSubtypeId,
    lineId,
    stationId,
  }
}
