import React, { useCallback, useEffect } from 'react'

import { InputRef } from 'antd'

import { PrismInput } from 'components/PrismInput/PrismInput'
import { LABELING_HOTKEYS } from 'utils/constants'

interface SearchLabelProps {
  search: string
  setSearch: (newState: string) => any
  onClose: () => any
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  className?: string
  wrapperClassName?: string
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
}

/**
 * Renders a floating search field for the user to filter the labels. This will refresh the keybinds, the user is able to use
 * the number keybinds while searching. Pressing ENTER or = closes and resets the search field.
 *
 * @param search - The current search string.
 * @param setSearch - Callback to set search state.
 * @param onClose - Handler to stop showing this search field and reset the search value.
 * @param prefix - allow the container to add an extra item positioned at the left side
 * @param suffix - allow the container to add an extra item positioned at the right side
 * @param className - string that hold the css selector
 * @param wrapperClassName - string that hold wrapper css selector
 */
const SearchLabel = React.forwardRef<InputRef, SearchLabelProps>((props, ref) => {
  const { search, setSearch, onClose, prefix, suffix, className, wrapperClassName, onFocus, onBlur } = props
  const handleKeyDown = useCallback(
    async (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === '=') onClose()
    },
    [onClose],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <PrismInput
      ref={ref}
      value={search}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => {
        // don't search by numbers or = because hotkeys are still enabled
        if (e.target.value.endsWith('=')) return
        if (e.target.value.match(/[0-9]+/)) return
        if (e.target.value.length > 30) return
        setSearch(e.target.value)
      }}
      placeholder="Search label"
      prefix={prefix}
      suffix={suffix}
      className={className}
      wrapperClassName={wrapperClassName}
      data-allowedhotkeys={LABELING_HOTKEYS}
    />
  )
})

export default SearchLabel
