import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Camera View
 *
 * * Reference: {https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5858-11459&t=a79zb7AJT3rs3KQu-0}
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismCameraViewIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M6 9.5C6 9.22386 6.22386 9 6.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H6.5C6.22386 10 6 9.77614 6 9.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C3.44772 6 3 6.44772 3 7V17C3 17.5523 3.44772 18 4 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 17.5523 6 17 6H4ZM17 7H4L4 17H17V7Z"
        fill="currentColor"
      />
      <path
        d="M20 8.5C20 8.22386 20.2239 8 20.5 8C20.7761 8 21 8.22386 21 8.5V15.5C21 15.7761 20.7761 16 20.5 16C20.2239 16 20 15.7761 20 15.5V8.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
