import React from 'react'

import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { getterKeys, wsPaths } from 'api'
import StreamListener from 'components/StreamListener'
import * as Actions from 'rdx/actions'
import { ToolsetDownloadStreamMessage } from 'types'
import { fetchRobotToolsets, includes, sleep } from 'utils'

export interface Props {
  robotId: string
  onMessages: (messages: ToolsetDownloadStreamMessage[]) => any
}

/**
 * Renders nothing. Subscribes to toolset download status updates and throws
 * them into Redux.
 *
 * @param robotId - Robot id
 * @param onMessages - Called with new messages
 */
function RobotToolsetsListener({ robotId, onMessages }: Props) {
  const dispatch = useDispatch()

  return (
    <StreamListener
      mode="message"
      connect={{ robotId, relativeUrl: wsPaths.assetManagementToolsets(robotId) }}
      params={{ past_ms: 5 * 1000 }}
      onMessages={(messages: ToolsetDownloadStreamMessage[]) => {
        onMessages(messages)
        handleMessages(messages, robotId, dispatch)
      }}
    />
  )
}

export default RobotToolsetsListener

export function handleMessages(messages: ToolsetDownloadStreamMessage[], robotId: string, dispatch: Dispatch) {
  if (messages.length === 0) return

  // Refetch robot toolsets if download was just completed or canceled or failed
  if (messages.some(msg => includes(['complete', 'canceled', 'failed', 'error'], msg.payload.status))) {
    // Wait 500ms before refetching to avoid race condition where asset management isn't ready to report the updated status
    sleep(500).then(() => {
      fetchRobotToolsets(robotId, dispatch, {
        retries: 3,
        delay: 5 * 1000,
        multiplier: 1,
        shouldRetry: res => res.type !== 'success',
      })
    })
  }

  dispatch(
    Actions.getterUpdate({
      key: getterKeys.robotToolsets(robotId),
      updater: prevRes => {
        if (!prevRes) return

        const updatedData = cloneDeep(prevRes.data)

        // Most recent message is last message
        for (const msg of messages) {
          // This is the routine id
          const { tool_set_id, progress } = msg.payload

          const recipeToUpdateId = Object.values(prevRes.data).find(toolset => {
            return !!toolset.tool_sets[tool_set_id]
          })?.recipe.id

          if (!recipeToUpdateId) return

          if (updatedData[recipeToUpdateId]?.tool_sets[tool_set_id]) {
            updatedData[recipeToUpdateId]!.tool_sets[tool_set_id]!.tool_set_status.metadata.progress = progress
          }
        }

        return { ...prevRes, data: updatedData }
      },
    }),
  )
}
