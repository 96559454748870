import React from 'react'

import { Radio, Table, Tooltip } from 'antd'
import { isEqual } from 'lodash'
import { Control, Controller } from 'react-hook-form'
import { useQuery } from 'react-redux-query'

import { getterKeys, service } from 'api'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { Status } from 'components/Status/Status'
import { Token } from 'components/Token/Token'
import { useStationsWithStatus, useTypedSelector } from 'hooks'
import { CameraStatus, Capabilities, RecipeExpanded, Robot, RoutineLinkedToRobotResponse, RoutineWithAois } from 'types'
import { cameraResolutionIsCompatibleWithRoutine, getRobotDisplayName, getRobotStatus, sortByName } from 'utils'

import { AddViewModalFormFields } from './AddViewModal'
import Styles from './ViewModal.module.scss'

// Interface used in LinkModal and AddViewModal
export interface ViewModalProps {
  recipe: RecipeExpanded
  onClose: () => any
  onOk: (params: Pick<RoutineLinkedToRobotResponse, 'recipe_parent_id' | 'routine_parent_id'>) => void
}

type RobotWithStatus = Robot & {
  status: CameraStatus
  name: string
}

/**
 * Renders a table with a list of static cameras so user can choose which robot they want to link the routine to.
 * @param recipe - Recipe we are currently working on. It's only used to show the active links between Robot and Routine.
 * @param control - This component is used in LinkModal and AddViewModal, so it's necessary to pass the form `control` so
 * we can propagate the robotId of the new Routine -> Robot link
 * @param modalLoaded - Whether the modal wrapping this table has been loaded.
 * @param selectedRobotId - Current selected robot id
 * @param routine - Used to validate the cam resolution when linking an existing view, not needed if a new view is being created
 * @param capabilitiesByRobotId - Capabilities used to validate camera resolution
 */

const RoutineLinkToCameraTable = ({
  recipe,
  control,
  modalLoaded,
  selectedRobotId,
  routine,
  capabilitiesByRobotId,
}: {
  recipe: RecipeExpanded
  control: Control<Partial<AddViewModalFormFields>>
  modalLoaded: boolean
  selectedRobotId: string | undefined
  routine?: RoutineWithAois
  capabilitiesByRobotId?: { [robotId: string]: Capabilities | null | undefined }
}) => {
  const stations = useQuery(
    getterKeys.stations('all-with-robots'),
    () => service.getStations({ has_robots: true, order_by: 'name' }),
    {
      noRefetch: true,
      noRefetchMs: 30 * 60 * 1000,
    },
  ).data?.data.results

  const { stationsWithStatus, robotIds } = useStationsWithStatus(stations || [])
  const robotsInCurrentStation = stationsWithStatus.find(station => station.id === recipe.parent.station_id)?.robots

  const viewNameByRobotId = recipe.recipe_routines.reduce((robotsObject, recipeRoutine) => {
    robotsObject[recipeRoutine.robot_id] = recipeRoutine.routine.parent.name
    return robotsObject
  }, {} as { [key: string]: string })

  const robotsWithStationNameAndStatus: RobotWithStatus[] | undefined = useTypedSelector(state => {
    if (!robotsInCurrentStation) return
    const toReturn: RobotWithStatus[] = []

    robotsInCurrentStation?.forEach(robot => {
      toReturn.push({
        ...robot,
        // Don't show robot as being offline due to "missing" heartbeat until we've read messages from streams
        status: getRobotStatus(robot.id, { state }),
        name: getRobotDisplayName(robot) || '',
      })
    })

    return toReturn.sort(sortByName)
  }, isEqual)

  const dataSource = robotsWithStationNameAndStatus
    ?.map(robot => {
      const compatibleWithRoutine = cameraResolutionIsCompatibleWithRoutine(
        capabilitiesByRobotId?.[robot.id],
        routine?.settings,
      )

      return {
        feed: { robotId: robot.id, robotStatus: robot.status },
        name: robot.name,
        view: viewNameByRobotId[robot.id],
        checked: robot.id,
        compatibleWithRoutine,
      }
    })
    .sort((robotA, robotB) => {
      // Show unlinked robots first
      if (robotA.view && !robotB.view) return 1
      if (!robotA.view && robotB.view) return -1
      return 0
    })

  const getColumns = (onChange?: (robotId?: string) => void) => {
    return [
      {
        key: 'feed',
        title: 'Feed',
        dataIndex: 'feed',
        render: ({ robotId, robotStatus }: { robotId: string; robotStatus: CameraStatus }) => {
          return (
            <div className={Styles.viewFeedContainer}>
              {modalLoaded && robotId ? (
                <>
                  <ReduxVideo element="transcoder-basler-image-thumbnail" stream="compressed" robotId={robotId} />

                  <Status status={robotStatus} showLabel={false} className={Styles.viewFeedStatus} />
                </>
              ) : (
                <PrismElementaryCube />
              )}
            </div>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        ellipsis: true,
        render: (text: string) => {
          return <PrismOverflowTooltip content={text} />
        },
      },
      {
        key: 'view',
        title: 'View',
        dataIndex: 'view',
        ellipsis: true,
        render: (viewName?: string) => {
          return <PrismOverflowTooltip content={viewName || '--'} />
        },
      },
      {
        key: 'checked',
        title: '',
        dataIndex: 'checked',
        render: (robotId: string, { compatibleWithRoutine }: { compatibleWithRoutine: boolean }) => {
          return (
            <Radio
              value={robotId}
              data-testid={`${robotId}-link-radio`}
              // Stop event propagation to avoid conflicts with row click
              onClick={e => {
                e.stopPropagation()
                if (selectedRobotId === robotId) {
                  onChange?.(undefined)
                }
              }}
              disabled={!compatibleWithRoutine}
            />
          )
        },
      },
    ]
  }

  return (
    <Token label="select camera" className={Styles.viewModalLabel} valueClassName={Styles.viewModalTable}>
      <GridTableHeader columns={getColumns()} size="large" className={Styles.gridTableHeader} />

      <RobotsStatusListener robotIds={robotIds} />

      <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
      <Controller
        name="robot_id"
        control={control}
        render={({ onChange, value }) => (
          <Radio.Group
            value={value}
            onChange={e => {
              onChange(e.target.value)
            }}
          >
            <Table
              columns={getColumns(onChange)}
              dataSource={dataSource}
              showHeader={false}
              pagination={false}
              className={Styles.tableContainer}
              rowClassName={robotData => {
                const className = `${Styles.gridTableRow} ${robotData.compatibleWithRoutine ? '' : Styles.disabledRow}`
                return className
              }}
              loading={{
                spinning: !dataSource,
                indicator: <PrismLoader className={Styles.tableLoader} />,
              }}
              onRow={robot => {
                return {
                  onClick: () => {
                    if (!robot.compatibleWithRoutine) {
                      return
                    }
                    if (value === robot.feed.robotId) {
                      onChange(undefined)
                    } else {
                      onChange(robot.feed.robotId)
                    }
                  },
                  compatibleWithRoutine: robot.compatibleWithRoutine,
                }
              }}
              components={{
                body: {
                  // Antd does not provide an interface for the rest of the props
                  row: ({ compatibleWithRoutine, ...rest }: { compatibleWithRoutine: boolean }) => (
                    <ConditionalWrapper
                      condition={!compatibleWithRoutine}
                      wrapper={children => (
                        <Tooltip
                          title="This camera has a different resolution than the View"
                          placement="right"
                          overlayClassName={Styles.tooltipContainer}
                        >
                          {children}
                        </Tooltip>
                      )}
                    >
                      <tr {...rest} />
                    </ConditionalWrapper>
                  ),
                },
              }}
            />
          </Radio.Group>
        )}
      />
    </Token>
  )
}

export default RoutineLinkToCameraTable
