import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState: RootState['inspector'] = {
  showInspectionSelectionOverlay: false,
  selectedRobotId: null,
  selectedRecipeId: null,
  staticInspectorItemsFilter: 'all',
  inspectionsAlertedAlignmentFailing: {},
  isInspectingManualPhoto: false,
  isManualTriggerDisabled: false,
  isinsightsEnabled: false,
}

/**
 * This branch of state tree stores state related to the ongoing inspection, in
 * part so this state can survive navigating away from the inspection screen and
 * back, and in part to make it easily available and updatable to the components
 * rendered in the inspection screens.
 */
export default function reduce(state = initialState, action: Action) {
  if (action.type === 'INSPECTOR_UPDATE') {
    return { ...state, ...action.payload }
  }
  return state
}
