import React, { useCallback, useMemo, useRef, useState } from 'react'

import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useDispatch } from 'react-redux'

import { EventTypesData, getterKeys, query, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import PrismAddButton from 'components/PrismAddBtn/PrismAddBtn'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismAddIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, usePagination } from 'hooks'
import { LabelList } from 'pages/ItemDetail/ToolListPanel'
import * as Actions from 'rdx/actions'
import { EventType, InspectionEventRules, ToolLabel } from 'types'
import { filterEventTypesByAllowedTargets, getEventTypeName, getterAddPage, pluralize } from 'utils'

import Shared from '../../styles/Shared.module.scss'
import Styles from './QualityEvents.module.scss'
import AddOrEditQualityEventModal, {
  getFormValuesFromRules,
} from './QualityEventsComponents/AddOrEditQualityEventModal'
import EventTypeOptionMenu from './QualityEventsComponents/EventOptionMenu'

export interface EventTypeWithCounts extends EventType {
  count?: number
}

/**
 * Renders The Quality Events tab
 *
 */
const QualityEvents = () => {
  const dispatch = useDispatch()
  const { allToolLabels } = useAllToolLabels()

  const [showQualityEventModal, setShowQualityEventModal] = useState(false)

  const eventTypesRes = useQuery(getterKeys.eventTypes('inspection'), () =>
    service.getEventTypes({ kind: 'inspection', is_deleted: false }),
  )

  const eventCounts = useQuery(getterKeys.eventsCounts(), () => service.countEvents()).data?.data.results

  const qualityEvents = useMemo(() => {
    const results = eventTypesRes?.data?.data.results
    if (!results) return
    return [...results].filter(filterEventTypesByAllowedTargets)
  }, [eventTypesRes?.data?.data.results])

  const nextEventTypesPage = useMemo(() => eventTypesRes.data?.data.next, [eventTypesRes.data?.data.next])

  const tableRef = useRef<HTMLDivElement>(null)
  const eventTypeToEdit = useRef<EventTypeWithCounts>()

  const handleEndReached = useCallback(async () => {
    if (!nextEventTypesPage) return

    const nextPageRes = await service.getNextPage<EventTypesData>(nextEventTypesPage)

    if (nextPageRes.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.eventTypes('inspection'),
          updater: prevRes => getterAddPage(prevRes, nextPageRes.data),
        }),
      )
    }
  }, [dispatch, nextEventTypesPage])

  usePagination(handleEndReached, {
    node: tableRef.current,
    overflowScroll: true,
  })

  const countsByEventTypeId = useMemo(() => {
    return eventCounts?.reduce((allCounts, currentCount) => {
      const updatedCounts = { ...allCounts }

      updatedCounts[currentCount.type_id] ??= 0
      updatedCounts[currentCount.type_id]! += currentCount.count

      return updatedCounts
    }, {} as Record<string, number>)
  }, [eventCounts])

  const qualityEventsWithCount = useMemo<EventTypeWithCounts[] | undefined>(() => {
    return qualityEvents?.map(eventType => {
      const count = countsByEventTypeId?.[eventType.id] || 0

      return { ...eventType, count }
    })
  }, [countsByEventTypeId, qualityEvents])

  const handleSuccess = async () => {
    await query(
      getterKeys.eventTypes('inspection'),
      () => service.getEventTypes({ kind: 'inspection', is_deleted: false }),
      { dispatch },
    )

    eventTypeToEdit.current = undefined
    setShowQualityEventModal(false)
  }

  const columns = getColumns({ handleSuccess, allToolLabels })

  const isQualityEventsListEmpty = qualityEventsWithCount && !qualityEventsWithCount.length

  return (
    <PrismContainer
      bodyRef={tableRef}
      title="Quality Events"
      headerTitleAction={
        <Button
          data-testid="add-quality-event-btn"
          size="small"
          type="secondary"
          onClick={() => setShowQualityEventModal(true)}
          badge={<PrismAddIcon />}
        >
          add
        </Button>
      }
      actions={<p className={Styles.headerDescription}>Track groups of defects when specific conditions are met.</p>}
      invertTitleAndActions
      className={`${Shared.rightSectionContainer} ${Styles.qualityEvents}`}
      headerActionsClassName={Styles.headerWrapper}
      bodyClassName={Styles.adminQualityEventsBodyWrapper}
    >
      {isQualityEventsListEmpty && (
        <PrismAddButton
          description="Start by adding a quality event"
          onClick={() => setShowQualityEventModal(true)}
          className={Styles.addButton}
        />
      )}

      {!isQualityEventsListEmpty && (
        <div className={Styles.adminQualityEvents}>
          <GridTableHeader columns={columns} size="small" className={Styles.gridTableHeader} />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={qualityEventsWithCount}
            className={Styles.tableContainer}
            rowClassName={Styles.tableRow}
            showHeader={false}
            pagination={false}
            onRow={record => {
              return {
                onClick: () => {
                  eventTypeToEdit.current = record
                  setShowQualityEventModal(true)
                },
                'data-testid': `${record.name}-row`,
              }
            }}
            loading={{
              spinning: !qualityEventsWithCount,
              indicator: <PrismLoader className={Styles.tableLoaderPoistion} />,
            }}
            locale={{
              emptyText: <div />,
            }}
          />
        </div>
      )}

      {showQualityEventModal && (
        <AddOrEditQualityEventModal
          id="add-or-edit-quality-event"
          onSuccess={handleSuccess}
          isEditMode={!!eventTypeToEdit.current}
          eventType={eventTypeToEdit.current}
          onClose={() => {
            eventTypeToEdit.current = undefined
            setShowQualityEventModal(false)
          }}
        />
      )}
    </PrismContainer>
  )
}

export default QualityEvents

export const getEventTypeListName = (eventType: EventType, allToolLabels: ToolLabel[] | undefined) => {
  if (eventType.kind === 'default') {
    return getEventTypeName(eventType)
  }

  const eventConfig = getFormValuesFromRules(eventType.rules as InspectionEventRules)

  if (eventType.rules.type === 'item_outcomes') {
    if (eventConfig.triggerType === 'multipleFailuresInARow') return `${eventConfig.count} failures in a row`
    if (eventConfig.triggerType === 'multipleFailuresOverATimePeriod')
      return `${eventConfig.count} failures over ${eventConfig.duration_s}s`
    if (eventConfig.triggerType === 'multipleFailuresOverANumberOfItems')
      return `${eventConfig.count} failures over ${eventConfig.total_count} items`
    if (eventConfig.triggerType === 'failRateExeedsValueForATimePeriod')
      return `Fail rate above ${eventConfig.ratio}% for ${eventConfig.duration_s}s`
  }

  if (eventType.rules.type === 'label_ids') {
    const foundLabels: ToolLabel[] = []

    eventType.rules.values.forEach(labelId => {
      const foundLabel = allToolLabels?.find(toolLabel => toolLabel.id === labelId)

      if (!foundLabel) return

      foundLabels.push(foundLabel)
    })

    const labelsToShow = foundLabels.slice(0, 3)
    const hiddenLabels = foundLabels.filter(label => !labelsToShow.includes(label))
    const otherPredictionText = hiddenLabels.length >= 1 && (
      <p className={Styles.endingDescription}>
        or {hiddenLabels.length} other {pluralize({ wordCount: hiddenLabels.length, word: 'prediction' })}
      </p>
    )

    const labelListEl = <LabelList labels={labelsToShow} labelClassName={Styles.eventLabelResult} joint={null} />

    if (eventConfig.triggerType === 'multiplePredictionsInARow') {
      return (
        <>
          <p className={Styles.mainDescription}>{eventConfig.count} predictions in a row of</p>
          {labelListEl}
          {otherPredictionText}
        </>
      )
    }

    if (eventConfig.triggerType === 'multiplePredictionsOverATimePeriod') {
      return (
        <>
          <p className={Styles.mainDescription}>
            {eventConfig.count} predictions over {eventConfig.duration_s}s of
          </p>
          {labelListEl}
          {otherPredictionText}
        </>
      )
    }

    if (eventConfig.triggerType === 'multiplePredictionsOverANumberOfItems') {
      return (
        <>
          <p className={Styles.mainDescription}>
            {eventConfig.count} predictions over {eventConfig.total_count} items of
          </p>
          {labelListEl}
          {otherPredictionText}
        </>
      )
    }

    if (eventConfig.triggerType === 'predictionsRateExeedsValueForATimePeriod') {
      return (
        <>
          <p className={Styles.mainDescription}>
            Prediction rate above {eventConfig.ratio}% for {eventConfig.duration_s}s for
          </p>
          {labelListEl}
          {otherPredictionText}
        </>
      )
    }
  }

  return ''
}

const getColumns = ({
  handleSuccess,
  allToolLabels,
}: {
  handleSuccess: () => Promise<void>
  allToolLabels: ToolLabel[] | undefined
}): ColumnsType<EventTypeWithCounts> => {
  return [
    {
      title: 'event',
      dataIndex: 'name',
      render: (_, eventType) => {
        const name = getEventTypeListName(eventType, allToolLabels)
        return (
          <div data-testid={`${eventType.name}-row-name`} className={Styles.eventRowDescription}>
            {name}
          </div>
        )
      },
    },
    {
      title: 'triggered',
      width: 108,
      render: (_, eventType) => {
        return <div data-testid={`${eventType.name}-row-count`}>{eventType.count}</div>
      },
    },
    {
      title: 'applies to',
      key: 'appliesTo',
      width: window.innerWidth <= 1280 ? 100 : 200,
      render: (_, eventType) => {
        return <EventTypeScopes eventType={eventType} />
      },
    },
    {
      title: '',
      dataIndex: 'row_menu',
      key: 'row_menu',
      width: 64,
      render: (_, eventType) => <EventTypeOptionMenu handleSuccess={handleSuccess} eventType={eventType} />,
    },
    {
      title: '',
      dataIndex: 'disabled',
      width: 66,
      render: (disabled: boolean, eventType) => {
        return (
          <EventSwitch
            dataTestId={`${eventType.name}-row-disabled`}
            checked={!disabled}
            onChange={async checked => {
              const patchRes = await service.patchEventType(eventType.id, { disabled: !checked })
              if (patchRes.type !== 'success') return error({ title: 'An error occurred, try again layer.' })

              await handleSuccess()
              return success({ title: `Event ${!checked ? 'disabled' : 'enabled'}` })
            }}
          />
        )
      },
    },
  ]
}

const EventSwitch = ({
  checked,
  onChange,
  dataTestId,
}: {
  checked: boolean
  onChange: (checked: boolean) => Promise<void>
  dataTestId?: string
}) => {
  const [isLoading, setIsLoading] = useState<boolean>()
  return (
    <Switch
      data-testid={dataTestId}
      data-test-attribute={checked}
      size="default"
      loading={isLoading}
      checked={isLoading ? !checked : checked}
      onChange={async (checked, event) => {
        event.stopPropagation()
        setIsLoading(true)
        await onChange(checked)
        setIsLoading(false)
      }}
    />
  )
}

const MAX_SCOPE_TARGETS = 5

const EventTypeScopes = ({ eventType }: { eventType: EventTypeWithCounts }) => {
  const eventScopes = eventType.event_scopes

  const appliesToData = useMemo(() => {
    const allTargets = eventScopes?.flatMap(scope => scope.targets)
    if (allTargets?.find(target => target.table === 'organization')) {
      return { appliesTo: 'All', showToolTip: false, targets: [] }
    }

    const firstTargetTable = allTargets?.[0]?.table === 'component' ? 'product' : allTargets?.[0]?.table

    if (!firstTargetTable) return

    return {
      appliesTo: `${allTargets.length} ${pluralize({ wordCount: allTargets.length, word: firstTargetTable })}`,
      targets: allTargets,
      showToolTip: true,
    }
  }, [eventScopes])

  const additionalTargetsCount = appliesToData?.targets.length ? appliesToData.targets.length - MAX_SCOPE_TARGETS : 0

  return (
    <div className={Styles.appliesToContainer}>
      <ConditionalWrapper
        condition={!!appliesToData?.showToolTip}
        wrapper={ch => (
          <PrismTooltip
            placement="bottom"
            title={
              <Token label={appliesToData?.appliesTo} labelClassName={Styles.popoverTitle}>
                <ul className={Styles.popoverList}>
                  {appliesToData?.targets?.slice(0, MAX_SCOPE_TARGETS).map(target => (
                    <li key={target.id}>{target.name}</li>
                  ))}
                  {additionalTargetsCount > 0 && (
                    <li>
                      <span>+ {additionalTargetsCount} more</span>
                    </li>
                  )}
                </ul>
              </Token>
            }
            overlayClassName={Styles.popoverWrapper}
          >
            {ch}
          </PrismTooltip>
        )}
      >
        <div data-testid={`${eventType.name}-row-applies-to`} className={Styles.appliesItem}>
          {appliesToData?.appliesTo}
        </div>
      </ConditionalWrapper>
    </div>
  )
}
