import React from 'react'

import { PrismCheckIcon, PrismCloseIcon, PrismWarningIcon } from 'components/prismIcons'
import { TagType } from 'types'

import Styles from './Tag.module.scss'

const sizeClasses = { small: Styles.small, large: Styles.large }

const typeClasses = {
  success: Styles.prismTag__success,
  danger: Styles.prismTag__danger,
  unknown: Styles.prismTag__unknown,
  default: Styles.prismTag__default,
  info: Styles.prismTag__info,
}
export interface TagProps {
  children: React.ReactNode
  type?: TagType
  size?: 'small' | 'large'
  shape?: 'pill' | 'rectangle'
  key?: string
  className?: string
  'data-test'?: string
  addBadge?: React.ReactNode
  tagTextClassName?: string
  tagBadgeClassName?: string
}

/**
 * Renders a Tag
 *
 * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=76-182&mode=design&t=S28iPklYBoPi2R1y-0
 *
 * @param children – The text to be rendered by this Tag
 * @param type – Will define the color of the Tag
 * @param size – Override size for the Tag
 * @param shape - Default value is Pill and can change to Rectangle, rectangle adds different look in shape and styles.
 * @param className – Custom className
 * @param addBadge - adds a space for icons and changes the padding. Can use the both tag type icons or external icons.
 */
export const Tag = ({
  children,
  type = 'default',
  size = 'small',
  shape = 'pill',
  className = '',
  key,
  'data-test': dataTest,
  addBadge,
  tagTextClassName = '',
  tagBadgeClassName = '',
}: TagProps) => {
  const typeClass = typeClasses[type]
  const sizeClass = sizeClasses[size]
  const shapeClass = Styles[shape]

  return (
    <span
      className={`${Styles.prismTag} ${typeClass} ${shape === 'rectangle' ? '' : sizeClass} ${shapeClass} ${
        addBadge ? Styles.tagWithBadge : ''
      } ${className}`}
      key={key}
      data-test={dataTest}
    >
      {addBadge && (
        <div className={`${Styles.tagBadge} ${tagBadgeClassName}`} data-testid={`${dataTest}-badge`}>
          {typeof addBadge !== 'boolean' ? addBadge : <TagBadge type={type} />}
        </div>
      )}
      <div className={`${Styles.tagText} ${tagTextClassName}`}>{children}</div>
    </span>
  )
}

/**
 * Renders special icons (used in labeling gallery)
 *
 * @param type – Defines the icon to be used.
 */
const TagBadge = ({ type }: { type: TagType }) => {
  return (
    <>
      {type === 'success' && <PrismCheckIcon />}
      {type === 'danger' && <PrismCloseIcon />}
      {type === 'unknown' && <PrismWarningIcon className={Styles.iconHideCircle} />}
    </>
  )
}

/**
 * Renders an offline Tag "rectangle" shape
 *
 * @param className – Custom className
 */
export const OfflineTag = ({ className = '' }: { className?: string }) => {
  return (
    <Tag shape="rectangle" type="unknown" className={className}>
      Offline
    </Tag>
  )
}

/**
 * Renders a Beta Tag "rectangle" shape
 *
 * @param className – Custom className
 */
export const BetaTag = ({ className = '' }: { className?: string }) => {
  return (
    <Tag type="success" shape="rectangle" className={`${Styles.betaStyles} ${className}`}>
      Beta
    </Tag>
  )
}
