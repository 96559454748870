import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Archive Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1430%3A4591
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismArchiveIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        id="Vector"
        d="M19.5 7.5V19.5H4.50002V7.5M19.5 7.5H20.5V4.5H3.5V7.5H4.50002M19.5 7.5H4.50002"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        id="Vector_2"
        d="M14.5 14L12 16.5M12 16.5L9.5 14M12 16.5C12 13.864 12 13.136 12 10.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </svg>
  )
}
