import React from 'react'

import Styles from './FullscreenForm.module.scss'

const FullscreenForm = ({ children, formClassName = '' }: { children: React.ReactNode; formClassName?: string }) => {
  return (
    <div className={Styles.fullscreenForm}>
      <div className={`${Styles.formContainer} ${formClassName}`}>{children}</div>
    </div>
  )
}

export default FullscreenForm
