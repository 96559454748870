import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism PLC Icon
 *
 * * Reference: {https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=10060-16877&mode=design&t=HZWFUiieSJOSggaf-0}
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismPlcIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M10 3V6.5M14 3V6.5M17.55 13.95H21.05M17.55 9.95H21.05M3 13.95H6.5M3 9.95H6.5M10 17.5V21M14 17.5V21M7.5 7H16.5C16.7761 7 17 7.22386 17 7.5V16.5C17 16.7761 16.7761 17 16.5 17H7.5C7.22386 17 7 16.7761 7 16.5V7.5C7 7.22386 7.22386 7 7.5 7Z"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  )
}
