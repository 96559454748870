import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState = {}

export default function reduce(state: RootState['video'] = initialState, action: Action): RootState['video'] {
  if (action.type === 'VIDEO_UPDATE') {
    const { frame, key, message_id } = action.payload
    if (!frame) return { ...state, [key]: undefined }
    return { ...state, [key]: { frame, dataMs: Date.now(), message_id } }
  }
  return state
}
