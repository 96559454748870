import { useCallback, useEffect, useRef, useState } from 'react'

import { UseFormMethods } from 'react-hook-form'

import {
  AreaOfInterestConfiguration,
  PreprocessingOptions,
  PreprocessingOptionsAlt,
  ToolSpecificationName,
} from 'types'
import { calculateHsvRanges, convertRgbaToHsv, getColorUnderCursor, log } from 'utils'

const preprocessingSupportedTools: ToolSpecificationName[] = ['detect-barcode', 'ocr', 'color-check']

// Currently only necessary for OCR and Barcode Tools
export const useImageToAoiCrop = ({
  imageElement,
  toolSpecificationName,
  aoi,
  setAoiImage,
  src,
  showPreview,
}: {
  imageElement: HTMLImageElement | undefined
  toolSpecificationName: ToolSpecificationName
  aoi?: AreaOfInterestConfiguration
  setAoiImage: (image: HTMLImageElement) => void
  src?: string
  showPreview?: boolean
}) => {
  useEffect(() => {
    if (!showPreview || !imageElement || !aoi || !preprocessingSupportedTools.includes(toolSpecificationName)) return

    const canvas = document.getElementById('aoiCanvas') as HTMLCanvasElement
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    ctx.drawImage(imageElement, aoi.x, aoi.y, aoi.width, aoi.height, 0, 0, aoi.width, aoi.height)

    // We need this crossOrigin as anonymous for openCv to be able to read and manipulate the image
    const img = new Image()
    img.id = 'aoiReplImg'
    img.crossOrigin = 'anonymous'

    img.width = aoi.width
    img.height = aoi.height

    // Gets the drawing of the image as a base64 url
    try {
      const canvasUrl = canvas.toDataURL()
      img.src = canvasUrl
    } catch (e) {
      log('src/pages/RoutineOverview/Configure/Configure.tsx', 'toDataURL error', e, src)
      window.location.reload()
      return
    }
    img.onload = () => setAoiImage(img)
  }, [aoi, imageElement, toolSpecificationName]) // eslint-disable-line
}

// This hook returns a handler to hide the gridlines after a timeout.
export const useShowGrid = () => {
  const [showGrid, innerSetShowGrid] = useState<boolean>(false)
  const gridTimeoutRef = useRef<number>()

  const setShowGrid = useCallback((val: boolean) => {
    innerSetShowGrid(val)
    window.clearTimeout(gridTimeoutRef.current)

    gridTimeoutRef.current = window.setTimeout(() => {
      innerSetShowGrid(false)
    }, 1000)
  }, [])

  return { showGrid, setShowGrid }
}

/**
 * This effect figures out if the color dropper option is selected, and if
 * so, draws a canvas element that contains the current Routine's image, from
 * which we can calculate each selected pixel under the mouse. We add an
 * event listener for whenever we move the mouse, and when we click on a
 * specific pixel. Same as an event listener for a click on the whole
 * window, which closes the color dropper if we click anywhere on the
 * screen.
 */
export const useColorDropper = ({
  imageElement,
  showEyeDropper,
  inferenceArgs,
  setShowEyeDropper,
  setValue,
  showPreview,
}: {
  imageElement: HTMLImageElement | undefined
  showEyeDropper?: boolean
  inferenceArgs: PreprocessingOptionsAlt | PreprocessingOptions | undefined
  setShowEyeDropper?: (val: boolean) => void
  setValue?: UseFormMethods['setValue']
  showPreview?: boolean
}) => {
  useEffect(() => {
    if (!showPreview || !showEyeDropper || !imageElement) return

    // Finds the canvas in which we will draw an image
    const canvas = document.getElementById('eye-dropper-canvas') as HTMLCanvasElement
    if (!canvas) return

    // Gets the context of said canvas
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    // Draws the selected image on the canvas
    ctx.drawImage(imageElement, 0, 0, imageElement.width, imageElement.height)

    canvas.onclick = e => {
      e.preventDefault()
      e.stopPropagation()

      const color = getColorUnderCursor(e, 'eye-dropper-canvas')
      if (!color) return

      const hsv = convertRgbaToHsv([...color])
      const hsvRanges = calculateHsvRanges(hsv)
      if (inferenceArgs)
        setValue?.(
          'inferenceArgs',
          {
            ...inferenceArgs,
            hsv_min_h: hsvRanges.min[0],
            hsv_min_s: hsvRanges.min[1],
            hsv_min_v: hsvRanges.min[2],
            hsv_max_h: hsvRanges.max[0],
            hsv_max_s: hsvRanges.max[1],
            hsv_max_v: hsvRanges.max[2],
          },
          { shouldDirty: true },
        )

      setShowEyeDropper?.(false)
    }

    window.addEventListener('click', () => {
      setShowEyeDropper?.(false)
    })

    canvas.onmousemove = e => {
      const color = getColorUnderCursor(e, 'eye-dropper-canvas')
      if (!color) return

      const dropperElement = document.getElementById('dropper-preview') as HTMLDivElement

      dropperElement.style.backgroundColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`
    }
    // eslint-disable-next-line
  }, [imageElement, showEyeDropper])
}
