import React, { useMemo, useState } from 'react'

import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import { IconButton } from 'components/IconButton/IconButton'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import PrismBadge from 'components/PrismBadge/PrismBadge'
import { PrismUploadIcon } from 'components/prismIcons'
import { PrismHistoryIcon } from 'components/prismIcons/PrismHistoryIcon'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismTabNav } from 'components/PrismTab/PrismTab'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useIsColocated, useQueryParams } from 'hooks'
import ChangeRecipeProductModal from 'pages/RecipesList/ChangeRecipeProductModal'
import RenameRecipeModal from 'pages/RecipesList/RenameRecipeModal'
import { useStationBreadcrumbs } from 'pages/StationDetail/StationDetail'
import ArchiveEntityModal from 'pages/StationList/Inspect/ArchiveSlsModal'
import paths from 'paths'
import { MenuActionsInOverviewHeader, RecipeExpanded, RecipeOverviewMode, RecipeParentExpanded } from 'types'
import { appendDataToQueryString, getAoisAndToolsFromRoutine, unarchiveRecipe } from 'utils'

import AddViewModal from './AddViewModal'
import DeployModal from './DeployModal/DeployModal'
import DuplicateOrMoveRecipeModal from './DuplicateOrMoveRecipeModal'
import Styles from './RecipeOverview.module.scss'
import RecipeVersionDrawer from './RecipeVersionDrawer'

function OverviewHeader({
  routineParentId,
  recipeParent,
  recipe,
  recipeVersion,
  updateVersionDrawer,
}: {
  routineParentId?: string
  recipeParent?: RecipeParentExpanded
  recipe?: RecipeExpanded
  recipeVersion?: string
  updateVersionDrawer: (recipeVersion?: string) => void
}) {
  const history = useHistory()
  const [params] = useQueryParams()
  const dispatch = useDispatch()
  const { isColocated } = useIsColocated()

  const [activeModalType, setActiveModalType] = useState<MenuActionsInOverviewHeader>()

  const stationId = recipe?.parent.station_id
  const station = useQuery(stationId ? getterKeys.station(stationId) : undefined, () => service.getStation(stationId!))
    .data?.data

  const versionDrawerOpen = !!recipeVersion
  const generatePath = (mode: RecipeOverviewMode) => {
    const recipeParentId = recipeParent?.id || recipe?.parent_id
    if (!recipeParentId) return paths.inspect({ mode: 'product' })
    if (routineParentId) params.routineParentId = routineParentId
    return paths.settingsRecipe(recipeParentId, mode, params)
  }

  const closeModal = () => setActiveModalType(undefined)

  const refreshRecipeParent = async () => {
    if (!recipeParent) return
    await query(getterKeys.recipeParent(recipeParent.id), () => service.getRecipeParent(recipeParent.id), { dispatch })
  }

  const BreadcrumbWithVersion = () => {
    return (
      <span className={Styles.breadcrumb} data-testid="overview-header-breadcrumb-recipe">
        <PrismOverflowTooltip content={`${recipeParent?.name || '...'}`} tooltipPlacement="bottom" />
        <span className={Styles.breadcrumbVersion}>v{recipe?.version || '...'}</span>
      </span>
    )
  }

  const recipeCanBeDeployed = useMemo(() => {
    if (!recipe) return false
    const linkedViews = recipe.recipe_routines.filter(recipeRoutine => !!recipeRoutine.robot_id)
    if (!linkedViews.length) return false

    return linkedViews.some(view => {
      const { tools } = getAoisAndToolsFromRoutine(view.routine)
      return tools.length > 0
    })
  }, [recipe])

  const showDeployReminder = useMemo(() => {
    if (!recipe || !recipeParent) return false

    if (!recipeCanBeDeployed) return false

    if (recipe.deployed) return false

    return recipeParent.working_version?.id === recipe.id
  }, [recipe, recipeCanBeDeployed, recipeParent])

  const stationBreadcrumbs = useStationBreadcrumbs({ isColocated, station })

  return (
    <div className={Styles.headerContainer}>
      <div className={Styles.headerLayout}>
        <div className={Styles.screenTitle}>
          {versionDrawerOpen ? (
            <Breadcrumbs
              crumbs={[
                {
                  menu: <BreadcrumbWithVersion />,
                },
              ]}
            />
          ) : (
            <Breadcrumbs
              crumbs={[
                ...stationBreadcrumbs,
                {
                  'data-testid': 'overview-header-breadcrumb-menu',
                  menu: <BreadcrumbWithVersion />,
                },
              ]}
            />
          )}
        </div>
        <div className={`${Styles.recipeActions} ${versionDrawerOpen ? Styles.hideActions : ''}`}>
          <OptionMenu
            onMenuItemClick={option => {
              if (option === 'unarchiveRecipe' && recipeParent && recipe)
                return unarchiveRecipe({
                  recipeParentId: recipe.parent.id,
                  onSuccess: () => {
                    query(getterKeys.recipeParent(recipeParent.id), () => service.getRecipeParent(recipeParent.id), {
                      dispatch,
                    })
                    query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })
                  },
                })
              setActiveModalType(option || undefined)
            }}
            openWithClick
            options={[
              {
                value: '',
                title: 'view',
              },
              {
                value: 'addView',
                title: 'add',
                'data-testid': 'add-view-overview-menu',
              },
              {
                value: '',
                title: 'recipe',
              },
              {
                value: 'renameRecipe',
                title: 'rename',
                'data-testid': 'rename-recipe-overview-menu',
              },
              {
                value: 'duplicateRecipe',
                title: 'duplicate',
                'data-testid': 'duplicate-recipe-overview-menu',
              },
              {
                value: 'moveRecipe',
                title: 'move',
                'data-testid': 'move-recipe-overview-menu',
              },
              {
                value: 'changeProduct',
                title: 'Change Product',
                'data-testid': 'change-product-recipe-overview-menu',
              },
              recipeParent?.is_deleted
                ? { value: 'unarchiveRecipe', title: 'unarchive', 'data-testid': 'unarchive-recipe-overview-menu' }
                : {
                    value: 'archiveRecipe',
                    title: 'archive',
                    'data-testid': 'archive-recipe-overview-menu',
                  },
            ]}
            menuContainerClassName={Styles.recipeHeaderMenu}
            hasOptionMenuGroups
            data-testid="recipe-overview-overflow-menu"
            iconButtonType="tertiary"
            closeOnClick
          />

          <PrismTooltip title="Version History">
            <IconButton
              icon={<PrismHistoryIcon />}
              size="small"
              type="tertiary"
              className={Styles.historyButton}
              onClick={() => updateVersionDrawer(recipeParent?.working_version?.id)}
              data-testid="overview-header-version-history"
            />
          </PrismTooltip>
          <Button
            badge={<PrismUploadIcon />}
            size="small"
            type="secondary"
            className={Styles.deployButton}
            onClick={() => appendDataToQueryString(history, { openDeployModal: true })}
            data-testid="recipe-overview-deploy-button"
          >
            {showDeployReminder && <PrismBadge className={Styles.deployButtonBadge} />}
            Deploy
          </Button>
        </div>
      </div>
      <div className={Styles.headerNavPosition}>
        <PrismTabNav
          data-test-attribute={`recipe-overview-tab-nav-${recipeParent ? 'ready' : 'loading'}`}
          className={Styles.routineNavMenu}
          items={[
            { path: generatePath('capture'), label: 'Image', 'data-testid': 'recipe-overview-header-capture' },
            {
              path: generatePath('configure'),
              label: 'Tools',
              partialMatch: /\/setup\/recipe.*\/configure/,
              'data-testid': 'recipe-overview-header-configure',
            },
            { path: generatePath('train'), label: 'Training', 'data-testid': 'recipe-overview-header-train' },
            { path: generatePath('settings'), label: 'Settings', 'data-testid': 'recipe-overview-header-settings' },
          ]}
          disabled={recipe?.recipe_routines.length === 0}
        />
      </div>
      <RecipeVersionDrawer
        recipeParent={recipeParent}
        recipeVersion={recipeVersion}
        updateVersionDrawer={updateVersionDrawer}
      />
      {activeModalType === 'addView' && recipe && (
        <AddViewModal
          onClose={closeModal}
          onOk={({ recipe_parent_id, routine_parent_id }) => {
            history.push(paths.settingsRecipe(recipe_parent_id, 'capture', { routineParentId: routine_parent_id }))
            setActiveModalType(undefined)
          }}
          recipe={recipe}
        />
      )}

      {activeModalType === 'duplicateRecipe' && recipeParent && (
        <DuplicateOrMoveRecipeModal
          id="duplicate-recipe"
          recipeParentId={recipeParent.id}
          defaultName={recipeParent.name}
          currentRecipeStationId={recipeParent.station_id}
          recipeParentWorkingVersionId={recipeParent.working_version?.id}
          isDuplicating
          onClose={closeModal}
          onSubmit={(recipe: RecipeExpanded) => history.push(paths.settingsRecipe(recipe.parent_id, 'capture'))}
        />
      )}

      {activeModalType === 'moveRecipe' && recipeParent && (
        <DuplicateOrMoveRecipeModal
          id="move-recipe"
          recipeParentId={recipeParent.id}
          defaultName={recipeParent.name}
          currentRecipeStationId={recipeParent.station_id}
          recipeParentWorkingVersionId={recipeParent.working_version?.id}
          onClose={closeModal}
          onSubmit={(recipe: RecipeExpanded) => history.push(paths.settingsRecipe(recipe.parent_id, 'capture'))}
        />
      )}

      {activeModalType === 'renameRecipe' && recipeParent && (
        <RenameRecipeModal
          recipeParentId={recipeParent.id}
          recipeParentName={recipeParent.name}
          onClose={() => setActiveModalType(undefined)}
          onUpdate={refreshRecipeParent}
        />
      )}

      {params.openDeployModal && recipeParent && recipe && (
        <DeployModal
          onClose={() => appendDataToQueryString(history, { openDeployModal: undefined })}
          recipeParent={recipeParent}
          recipe={recipe}
        />
      )}

      {activeModalType === 'archiveRecipe' && recipeParent && (
        <ArchiveEntityModal
          entityType="recipe"
          entityId={recipeParent.id}
          onClose={() => setActiveModalType(undefined)}
          onOk={() =>
            history.push(
              `${paths.inspect({ mode: 'product' })}?${qs.stringify({ component_id: recipeParent.component_id })}`,
            )
          }
        />
      )}

      {activeModalType === 'changeProduct' && recipeParent && (
        <ChangeRecipeProductModal
          recipeParentId={recipeParent.id}
          componentId={recipeParent.component_id}
          onClose={() => setActiveModalType(undefined)}
          onSuccess={refreshRecipeParent}
        />
      )}
    </div>
  )
}

export default OverviewHeader
