import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconArrowProps } from './prismIconsTypes'

/**
 * Reordering Arrow Icon.
 *
 * Reference: https://www.figma.com/file/Fp2lSfRJPQJ41gDcHQEQJT/Sites%2C-Lines-and-Stations-(3.0)?type=design&node-id=7150-13163&mode=design&t=Fi5VcfS7TvjfBFw8-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismReorderingArrowIcon = ({ hasBackground, className }: PrismIconArrowProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M5.83301 11.6667L9.99967 7.5L14.1663 11.6667H5.83301Z" fill="currentColor" />
    </svg>
  )
}
