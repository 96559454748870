import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { service } from 'api'
import { Button } from 'components/Button/Button'
import { IconButton } from 'components/IconButton/IconButton'
import Navbar from 'components/Navbar/Navbar'
import { PrismHeaderLogo } from 'components/PrismHeaderLogo/PrismHeaderLogo'
import { PrismMenuIcon, PrismNavArrowIcon } from 'components/prismIcons'
import QaTimer from 'components/QATimer/QaTimer'
import { useIsFlourish } from 'hooks'
import * as Actions from 'rdx/actions'

import Styles from './Layout.module.scss'

export interface Props {
  title: React.ReactNode
  showLogoutButton?: boolean
  showMenu?: boolean
  before?: React.ReactNode
  after?: React.ReactNode
  titleClassName?: string
  mainClassName?: string
  backButtonOnClick?: () => void
  layoutMenuClassName?: string
  children: React.ReactNode
  headerLogoClassName?: string
  showHeaderLogo?: boolean
}

/**
 * Renders the main Layout of for pages where the header and navbar are to be
 * displayed.
 *
 * @param title - A ReactNode (JSX Element) to be rendered in the header of the
 *    page
 * @param showMenu - a flag denoting whether we should show the menu in the header
 * @param showUser - a flag denoting whether we should show the user in the header
 * @param after - A ReactNode to be rendered after the user avatar in the
 *    header of the page
 * @param children - default React prop with the contents of the layout body
 * @param mainClassName - an optional string for the class name of the main body component
 * @param titleClassName - an optional string for the class name of the header component
 * @param backButtonOnClick - render a back button and call this action (overrides primary menu)
 * @param layoutMenuClassName - Class name to be applied to the layout menu
 * @param hideHeaderLogo - hides the header logo
 */
const Layout: React.FC<Props> = ({
  title,
  showMenu = true,
  showLogoutButton = false,
  before,
  after,
  children,
  mainClassName = '',
  titleClassName = '',
  backButtonOnClick,
  layoutMenuClassName = '',
  headerLogoClassName = '',
  showHeaderLogo = true,
}) => {
  const [navbarVisible, setNavbar] = useState(false)
  const dispatch = useDispatch()
  const { isFlourish } = useIsFlourish()

  // Reset scroll to top when new page loads
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Handle the logout
  const logout = () => {
    // Try to delete token stored in cookie, and only this token, from server
    service.logout({ retry: { retries: 3, delay: 5000 } })
    dispatch(Actions.authUnset('userLogout'))
  }

  // useCallback prevents the Navbar from rendering unnecessarily
  const handleCloseNavbarClick = useCallback(() => {
    setNavbar(false)
  }, [])

  const handleMenuClick = (e: SyntheticEvent) => {
    e.stopPropagation() // So that if menu icon is clicked navbar isn't closed immediately since icon is in header which closes navbar when clicked
    setNavbar(true)
  }

  return (
    <>
      <div className={`${Styles.header} ${titleClassName}`} onClick={handleCloseNavbarClick}>
        <div className={Styles.headerContainer}>
          {before}
          {showMenu && !backButtonOnClick && (
            <IconButton
              data-testid="navbar-open-button"
              icon={<PrismMenuIcon />}
              type="ghost"
              className={`${Styles.menuIcon} ${layoutMenuClassName}`}
              onClick={handleMenuClick}
            />
          )}
          {backButtonOnClick && (
            <IconButton
              data-testid="navbar-back-button"
              icon={<PrismNavArrowIcon direction="left" />}
              type="secondary"
              className={`${Styles.menuIcon} ${Styles.returnArrow} ${layoutMenuClassName}`}
              onClick={backButtonOnClick}
            />
          )}
          {isFlourish && showHeaderLogo && (
            <PrismHeaderLogo className={headerLogoClassName} dataTestId="flourish-header-icon" />
          )}
          <div className={Styles.contentTitle}>{title}</div>

          {showLogoutButton && (
            <Button
              size="small"
              htmlType="submit"
              type="secondary"
              className={Styles.logoutButton}
              data-testid="layout-logout"
              onClick={logout}
            >
              Log Out
            </Button>
          )}

          <QaTimer />

          {after}
        </div>
      </div>

      <Navbar navbarVisible={navbarVisible} setNavbarVisible={setNavbar} />

      <div className={`${Styles.main} ${mainClassName}`} onClick={handleCloseNavbarClick}>
        {children}
      </div>
    </>
  )
}

export default Layout
