import React from 'react'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismDownloadIcon } from 'components/prismIcons'

import ConfigureStyles from '../../Configure.module.scss'

/**
 * Renders a button to download the previewed image from tools with Preprocessing settings.
 * This button is only used for the QA stack.
 */
const QaPreviewDownload = ({ className }: { className?: string }) => (
  <IconButton
    size="xsmall"
    type="secondary"
    icon={<PrismDownloadIcon />}
    onClick={() => {
      const canvas = document.getElementById('canvasInput') as HTMLCanvasElement | null
      if (!canvas) return

      const url = canvas.toDataURL()

      const a = document.createElement('a')
      a.href = url
      a.download = 'preview.png'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    }}
    className={`${ConfigureStyles.ocrPreviewDownloadButton} ${className ?? ''}`}
  />
)

export default QaPreviewDownload
