import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { Token } from 'components/Token/Token'
import ProductSearch, { createProductOnTheFly } from 'pages/RoutineOverview/ProductSearch'
import paths from 'paths'
import { PerEntityModalProps, Recipe } from 'types'
import { SiteAndLineCascaderLocation, sortByName } from 'utils'
import { INPUT_MAX_CHARACTER_LIMIT } from 'utils/constants'

import EntityModal from './EntityModal'
import Styles from './EntityModal.module.scss'
import { getSiteLineAndStationIdsFromCascaderValue, SitesAndLinesCascader } from './SitesAndLinesCascader'

/**
 * Renders the Create or Edit Recipe modal
 *
 *
 * @param entity - the modal name
 */
type FormValuesType = {
  name: string | undefined
  product: string | undefined
  location: SiteAndLineCascaderLocation | undefined
}
// This modal doesn't have an edit counterpart
const AddOrEditRecipeModal = ({
  onClose,
  defaultLocation,
  defaultProductId,
}: PerEntityModalProps<false, Recipe> & {
  defaultLocation?: SiteAndLineCascaderLocation
  defaultProductId?: string
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [newComponentName, setNewComponentName] = useState<string>()
  const {
    formState: { errors, isDirty, isValid },
    getValues,
    control,
  } = useForm<FormValuesType>({
    mode: 'onChange',
    defaultValues: { location: defaultLocation, product: defaultProductId },
  })

  async function handleSubmit() {
    const values = getValues()
    if (!values.location || !values.product || !values.name) return

    const { stationId } = getSiteLineAndStationIdsFromCascaderValue(values.location)
    return await createProductOnTheFly({
      productSelectedId: values.product,
      newProductNameState: newComponentName,
      onError: async () => undefined,
      onSuccess: async productId => {
        const res = await service.createRecipe({
          parent: {
            name: values.name,
            station_id: stationId,
            component_id: productId,
          },
        })

        await query(
          getterKeys.filteredRecipeParents(productId, 'all'),
          () => service.getRecipeParents({ component_id: productId }),
          { dispatch },
        )

        if (res.type === 'success') {
          history.push(paths.settingsRecipe(res.data.parent.id, 'capture'))
        }

        onClose()

        return res
      },
    })
  }

  return (
    <EntityModal
      id="recipe-entity-modal"
      data-testid="recipe-modal"
      entity="recipe"
      onSubmit={handleSubmit}
      onClose={onClose}
      disableSave={!isDirty || !isValid}
      successMessage="Recipe created"
    >
      <>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ ...props }) => (
            <PrismInput
              label="recipe name (*)"
              {...props}
              errors={errors}
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
              data-testid="recipe-modal-name-input"
            />
          )}
        />

        <Controller
          name="location"
          control={control}
          rules={{ required: 'Location is required' }}
          render={({ ...props }) => (
            <Token label="Location (*)">
              <SitesAndLinesCascader
                {...props}
                showStations
                className={Styles.sizeExtraBig}
                data-testid="sites-and-lines-cascader"
                size="large"
              />
            </Token>
          )}
        />

        <Controller
          name="product"
          control={control}
          rules={{ required: 'Product is required' }}
          render={({ ...props }) => (
            <Token label="Product (*)">
              <ProductSearch
                {...props}
                data-testid="recipe-modal-product-select"
                getSelectOptionDataTestId={product => `recipe-modal-select-${product.name}-option`}
                size="large"
                sorter={sortByName}
                hideDashEmptyOption
                showArrow
                disableDropdownAnimation
                stopPropagationOnDropdownClick
                newProductNameState={newComponentName}
                setNewProductNameState={setNewComponentName}
              />
            </Token>
          )}
        />
      </>
    </EntityModal>
  )
}

export default AddOrEditRecipeModal
