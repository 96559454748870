import React, { useMemo, useState } from 'react'

import { uniqBy } from 'lodash'
import { shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { PrismCameraViewIcon } from 'components/prismIcons'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import { FrameWithMeta } from 'components/StreamListener'
import { useData, useInspectionAndRecipeDefinition, useSortedRobotsWithStatus, useTypedSelector } from 'hooks'
import paths from 'paths'
import { Inspection, Robot, Routine, RoutineWithAois, Station } from 'types'
import { evaluateOutcomes, matchRole, pluralize, setPinnedRobot } from 'utils'

import CameraFeed from './CameraFeed'
import Styles from './CameraView.module.scss'

/**
 * Renders the camera layout arrangement is based on screen size, layout is adjusted if a camera is pinned.
 *
 * https://www.figma.com/file/bJmWHlYXuZVaCJZF1MZ0mk/Station-Detail-(main)?node-id=5513%3A40500
 *
 * @param robots - Current station robots.
 * @param className - Container className.
 * @param camViewGridClassName - Camera grid container className.
 * @param cameraMenuClassName - Options menu className.
 * @param emptyState - Renders the empty state.
 * @param inspection - Current Inspection.
 * @param routines - Routines from current Inspection.
 * @param isInspectingManualPhoto - True if a manual inspection Item is being inspected
 * @param isLoading - Whether a manual inspected Item is loading
 * @param historicRoutinesByRobotId - historic routines
 * @param displayRoutineGoldenImage - whether we should display the routine golden image.
 * @param station - Current station
 */
export const CameraView = ({
  robots,
  className,
  camViewGridClassName,
  cameraMenuClassName,
  emptyState,
  inspection,
  routines,
  isInspectingManualPhoto,
  isLoading,
  historicRoutinesByRobotId,
  displayRoutineGoldenImage,
  station,
  'data-testid': dataTestId,
}: {
  robots: Robot[]
  className?: string
  camViewGridClassName?: string
  cameraMenuClassName?: string
  emptyState?: boolean
  inspection: Inspection | undefined
  routines: RoutineWithAois[] | undefined
  isInspectingManualPhoto: boolean
  isLoading: boolean
  historicRoutinesByRobotId?: { [robotId: string]: Routine }
  displayRoutineGoldenImage?: boolean
  station?: Station
  'data-testid'?: string
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pinnedCameraData = useTypedSelector(state => state.localStorage.pinnedCameraByStation, shallowEqual)

  const [selectedFullscreenRobotId, setSelectedFullscreenRobotId] = useState<string>()
  const [pinnedFrameWithMetaByRobotId, setPinnedFrameWithMetaByRobotId] = useState<{
    [robotId: string]: FrameWithMeta
  }>()

  const pinnedRobotId = station ? pinnedCameraData[station.id] : undefined

  const camerasCount = robots.length
  const cameraCountTitle = pluralize({ wordCount: camerasCount, word: 'camera' })

  const optionsToDisplay = (thisCamIsPinned: boolean) => {
    const options = [{ value: 'fullscreen', title: 'full screen', 'data-testid': 'full-screen' }]

    if (matchRole('manager')) {
      options.unshift({ value: 'cameraDetails', title: 'camera details', 'data-testid': 'camera-details' })
    }

    if (camerasCount > 1)
      options.push({
        value: 'pin',
        title: `${!thisCamIsPinned ? 'pin' : 'unpin'}`,
        'data-testid': `${!thisCamIsPinned ? 'pin' : 'unpin'}`,
      })

    return options
  }

  const handleMenuItemClick = (value: string, robotId?: string) => {
    if (value === 'pin') {
      if (!station) return
      setPinnedRobot({ stationId: station.id, robotId, dispatch, currentPinnedCameraData: pinnedCameraData })
    }

    if (!robotId) return

    if (value === 'cameraDetails') history.push(paths.cameraStatus(robotId))

    if (value === 'fullscreen') setSelectedFullscreenRobotId(robotId)
  }
  const pinnedRobot = robots.find(robot => robot.id === pinnedRobotId)

  const inspectionItemsByRobotId = useData(inspection ? getterKeys.inspectionItemsByRobot(inspection.id) : undefined)

  const inspectedItems = useMemo(() => {
    if (!inspectionItemsByRobotId) return
    return uniqBy(Object.values(inspectionItemsByRobotId), item => item.id)
  }, [inspectionItemsByRobotId])

  const inspectedItemsCount = useMemo(() => inspectedItems?.length, [inspectedItems?.length])

  const renderInspectedItemsOutcome = () => {
    if (!inspectedItems || !inspectedItemsCount) return null

    const itemsOutcome = evaluateOutcomes(inspectedItems.map(item => item.calculated_outcome))
    const copy = inspectedItemsCount > 1 ? `${inspectedItemsCount} items` : inspectedItems[0]?.serial_number

    return (
      <div className={`${Styles.camLayoutTitle} ${Styles.inspectionResult}`}>
        <PrismOutcome icon={itemsOutcome} outcome={itemsOutcome} size="small" variant="high" />
        <span>{copy}</span>
      </div>
    )
  }

  const cameraGridCountClassName = useMemo(() => {
    if (camerasCount === 1) return Styles.singleView
    if (camerasCount === 2) return Styles.twoCamGrid
    if (camerasCount === 3) return Styles.threeCamGrid
    if (camerasCount === 4) return Styles.fourCamGrid
    if (camerasCount === 5) return Styles.fiveCamGrid
    return ''
  }, [camerasCount])

  const cameraGridLayoutClassName = useMemo(() => {
    if (camerasCount >= 11) return Styles.largeGridLayout
    if (camerasCount > 4 && camerasCount <= 10) return Styles.mediumGridLayout
    return ''
  }, [camerasCount])

  const baseCameraLayoutClassName = `${Styles.camViewGrid} ${camViewGridClassName ?? ''} ${
    pinnedRobot ? Styles.camVerticalLayout : ''
  }`

  const { recipeDefinition } = useInspectionAndRecipeDefinition(robots?.map(rbt => rbt.id))
  const routineRobotIds = recipeDefinition?.recipe_routines.map(recipeRoutine => recipeRoutine.robot_id)

  const sortedRobots = useSortedRobotsWithStatus(robots)

  return (
    <div className={`${Styles.camViewContainer} ${className ?? ''} ${emptyState ? Styles.emptyCamViewContainer : ''}`}>
      {(!isInspectingManualPhoto || (isInspectingManualPhoto && !inspectedItemsCount)) && (
        <h4 className={Styles.camLayoutTitle}>{cameraCountTitle}</h4>
      )}

      {isInspectingManualPhoto && renderInspectedItemsOutcome()}

      <div
        className={`${Styles.camViewLayout} ${!camerasCount ? Styles.camViewNoCamera : ''} ${
          pinnedRobot ? Styles.layoutWithPinnedCam : ''
        }`}
      >
        <div className={`${baseCameraLayoutClassName} ${cameraGridLayoutClassName} ${cameraGridCountClassName}`}>
          {sortedRobots?.map(robot => (
            <CameraFeed
              key={robot.id}
              data-testid={`${dataTestId}-${robot.id}`}
              options={optionsToDisplay(false)}
              handleMenuItemClick={(value: string) => handleMenuItemClick(value, robot.id)}
              // we need to hide the feed instead of just filtering, to keep the feed alive.
              hideFeed={pinnedRobotId === robot.id}
              robot={robot}
              showFullscreen={robot.id === selectedFullscreenRobotId}
              setSelectedFullscreenRobotId={setSelectedFullscreenRobotId}
              inspection={inspection}
              routines={routines}
              isInspectingManualPhoto={isInspectingManualPhoto}
              isLoading={isLoading}
              displayRoutineGoldenImage={displayRoutineGoldenImage}
              historicRoutinesByRobotId={historicRoutinesByRobotId}
              cameraMenuClassName={cameraMenuClassName}
              onFrame={(frameWithMeta, robotId) =>
                setPinnedFrameWithMetaByRobotId(prev => ({ ...prev, [robotId]: frameWithMeta }))
              }
              camerasCount={camerasCount}
              isSomeCameraPinned={!!pinnedRobot}
              routineRobotIds={routineRobotIds}
            />
          ))}
        </div>

        {pinnedRobot && (
          <div className={`${Styles.pinnedCam} ${pinnedRobot ? Styles.showPinnedCam : ''}`}>
            <CameraFeed
              data-testid={`${dataTestId}-${pinnedRobot.id}-pin`}
              options={optionsToDisplay(true)}
              handleMenuItemClick={(value: string) => {
                // if selected option is `pin`, we send undefined to unpin this camera
                handleMenuItemClick(value, value === 'pin' ? undefined : pinnedRobot.id)
              }}
              robot={pinnedRobot}
              showFullscreen={pinnedRobot.id === selectedFullscreenRobotId}
              setSelectedFullscreenRobotId={setSelectedFullscreenRobotId}
              inspection={inspection}
              routines={routines}
              isInspectingManualPhoto={isInspectingManualPhoto}
              isLoading={isLoading}
              displayRoutineGoldenImage={displayRoutineGoldenImage}
              historicRoutinesByRobotId={historicRoutinesByRobotId}
              isPinned
              pinnedFrameWithMeta={pinnedFrameWithMetaByRobotId?.[pinnedRobot.id]}
              camerasCount={camerasCount}
              isSomeCameraPinned
              routineRobotIds={routineRobotIds}
            />
          </div>
        )}

        {!camerasCount && (
          <GenericBlankStateMessage
            header={
              <div className={Styles.noCameraIconContainer}>
                <PrismCameraViewIcon />
              </div>
            }
            description={<div className={Styles.noCameraTitle}>No {cameraCountTitle}</div>}
            className={Styles.noCameraWrapper}
          />
        )}
      </div>
    </div>
  )
}
