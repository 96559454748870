import { Action } from 'rdx/actions'
import { LocalStorageData, RootState } from 'types'
import { validateLocalStorage } from 'utils'
import {
  FROM_URL_LOCAL_STORAGE_KEY,
  MUTED_NOTIFICATIONS_LOCAL_STORAGE_KEY,
  PINNED_CAMERA_DATA_LOCAL_STORAGE_KEY,
  SHARE_DETAIL_MODAL_LOCAL_STORAGE_KEY,
  USER_PREFERENCES_BY_ID_LOCAL_STORAGE_KEY,
} from 'utils/constants'

export const LOCAL_STORAGE_KEYS: { [key in keyof LocalStorageData]: string } = {
  pinnedCameraByStation: PINNED_CAMERA_DATA_LOCAL_STORAGE_KEY,
  mutedNotifications: MUTED_NOTIFICATIONS_LOCAL_STORAGE_KEY,
  shareDetailModalSettings: SHARE_DETAIL_MODAL_LOCAL_STORAGE_KEY,
  fromUrl: FROM_URL_LOCAL_STORAGE_KEY,
  userPreferencesById: USER_PREFERENCES_BY_ID_LOCAL_STORAGE_KEY,
}

const getInitialStateFromLocalStorage = <T extends keyof LocalStorageData>(key: T) => {
  const localStorageData = localStorage.getItem(LOCAL_STORAGE_KEYS[key])
  if (!validateLocalStorage(localStorageData)) {
    localStorage.removeItem(key)
    return {}
  }

  return localStorageData ? (JSON.parse(localStorageData) as RootState['localStorage'][typeof key]) : {}
}

const getInitialState = (): RootState['localStorage'] => {
  const initialState = (Object.keys(LOCAL_STORAGE_KEYS) as Array<keyof typeof LOCAL_STORAGE_KEYS>).reduce(
    (all, currentKey) => {
      all[currentKey] = getInitialStateFromLocalStorage(currentKey)
      return all
    },
    {} as RootState['localStorage'],
  )

  return initialState
}

export default function reduce(state: RootState['localStorage'] = getInitialState(), action: Action) {
  switch (action.type) {
    case 'LOCAL_STORAGE_UPDATE': {
      const { key, data } = action.payload
      return { ...state, [key]: data }
    }

    default:
      return state
  }
}
