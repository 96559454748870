import React from 'react'

import round from 'lodash/round'
import { Rectangle, RectangleProps } from 'recharts'

import { LiveFeed } from 'components/LiveFeed/LiveFeed'
import { TimeSeriesData } from 'types'
import { calculatePercentage, renderLargeNumber } from 'utils'

import Styles from './CustomRecharts.module.scss'

export const colors = {
  emptyState: 'rgba(255, 255, 255, 0.10)',
  darkGrid: 'rgba(74, 74, 74, 1)',
  blueStroke: 'rgba(108, 159, 241, 1)',
  blueFill: 'rgba(108, 159, 241, 0.12)',
  greenFill: 'rgba(92, 214, 198, 0.12)',
  greenStroke: 'rgba(92, 214, 198, 1)',
  redFill: 'rgba(237,82,61,0.12)',
  redStroke: 'rgba(237,82,61,1)',

  whiteFill: 'rgba(248,248,249,0.12)',
  whiteStroke: 'rgba(248, 248, 249, 1)',
  axisStroke: 'rgba(147, 147, 147, 1)',

  pdfGreenFill: 'rgba(84, 192, 178, 0.8)',
  pdfGreenStroke: 'rgba(69, 147, 137, 1)',
  pdfRedFill: 'rgba(212, 76, 57, 0.8)',
  pdfRedStroke: 'rgba(162, 63, 49, 1)',
  pdfCountBarStroke: 'rgba(61, 128, 237, 1)',
  pdfCartesianGridStroke: 'rgba(201, 201, 201, 0.48)',
  pdfTicksStroke: 'rgba(117, 117, 117, 1)',
  pdfAxisStroke: 'rgba(237, 237, 240, 1)',
  pdfWhiteFill: 'rgba(147,147,147,0.7)',
  pdfWhiteStroke: 'rgba(147, 147, 147, 1)',

  flourishBlueStroke: 'rgba(157, 160, 236, 1)',
  flourishBlueFill: 'rgba(157, 160, 236, 0.12)',
  flourishRedStroke: 'rgba(237, 61, 61, 1)',
  flourishRedFill: 'rgba(237, 61, 61, 0.12)',
  flourishPdfRedStroke: 'rgba(162, 49, 49, 1)',
  flourishPdfRedFill: 'rgba(212, 57, 57, 0.8)',
  flourishPdfCountBarStroke: 'rgba(255, 0, 0, 1)',
}

type Data = TimeSeriesData<
  {
    count: number
    pass: number | null
    fail: number | null
    unknown: number | null
    pass_because_muted: number | null
  } & { [key: string]: any }
>

export interface YieldTooltipProps {
  active: boolean
  payload: { payload: Data }[] | null
  formatter: (data: Data) => string
  showFail?: boolean
  showUnknown?: boolean
  showPass?: boolean
}
const DataItem = ({ children }: { children: React.ReactChild[] | React.ReactChild | string }) => {
  return <span className={Styles.itemValue}>{children}</span>
}
/**
 * Renders custom yield tooltip for metrics graphs. See
 * <https://recharts.org/en-US/api/Tooltip> for description of props.
 *
 * @param active - If true, tooltip is shown.
 * @param payload - Payload from rechart tooltip.
 * @param formatter - Formats date string.
 * @param showUnknown - if true items with Unknown outcome are displayed.
 * @param showFail - if true items with Fail outcome are displayed.
 * @param showPass - if true items with Pass outcome are displayed.
 */
export function YieldTooltip({
  active,
  payload,
  formatter,
  showUnknown,
  showFail = true,
  showPass = true,
}: YieldTooltipProps) {
  if (!active || !payload || !payload[0]) return null

  const { payload: data } = payload[0]

  let content = <div>No items scanned</div>
  if (data.pass !== null && data.fail !== null && data.unknown !== null) {
    content = (
      <>
        {showPass && (
          <div className={Styles.itemContainer}>
            <LiveFeed status="pass">Pass</LiveFeed>{' '}
            <DataItem>{round(calculatePercentage(data.pass, data.count), 1)}%</DataItem>
          </div>
        )}
        {showFail && (
          <div className={Styles.itemContainer}>
            <LiveFeed status="fail">Fail </LiveFeed>{' '}
            <DataItem>{round(calculatePercentage(data.fail, data.count), 1)}%</DataItem>
          </div>
        )}
        {showUnknown && (
          <div className={Styles.itemContainer}>
            <LiveFeed status="unknown">Unknown </LiveFeed>{' '}
            <DataItem>{round(calculatePercentage(data.unknown, data.count), 1)}%</DataItem>
          </div>
        )}
      </>
    )
  }

  return (
    <div
      className={Styles.tooltipContainer}
      style={{
        opacity: data.pass !== null ? 1 : 0.8,
      }}
    >
      <div className={Styles.tooltipTitle}>{formatter(data)}</div>
      {content}
    </div>
  )
}

export function CountTooltip({ active, payload, formatter }: YieldTooltipProps) {
  if (!active || !payload || !payload[0]) return null

  const { payload: data } = payload[0]

  let content = <div>No items scanned</div>
  if (data.pass !== null && data.fail !== null && data.unknown !== null) {
    content = (
      <div className={Styles.itemContainer}>
        Count <DataItem>{renderLargeNumber(data.count)}</DataItem>
      </div>
    )
  }

  return (
    <div
      className={Styles.tooltipContainer}
      style={{
        opacity: data.pass !== null ? 1 : 0.8,
      }}
    >
      <div className={Styles.tooltipTitle}>{formatter(data)}</div>
      {content}
    </div>
  )
}

/**
 * Renders custom bar for use in bar charts. See
 * <https://recharts.org/en-US/api/Bar>.
 */
export function renderBar(props: RectangleProps) {
  // @ts-ignore
  if (props.count === 0) return null

  return <Rectangle {...props} />
}

/**
 * Renders custom bar background for use in bar charts. See
 * <https://recharts.org/en-US/api/Bar>.
 */
export function renderBarBackground(props: RectangleProps) {
  // @ts-ignore
  if (props.count > 0) return null
  return <Rectangle {...props} />
}

/**
 * Returns lowest yield value to be rendered on y-axis of yield graphs.
 *
 */
export function getMinYield(): number {
  return 0
}
