import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Elementary Isotype
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=15%3A278
 *
 */
export const PrismElementaryIsotypeIcon = ({ className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${className ?? ''} `}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6362 59.6152L57.4249 52.169V42.2417L29.6362 49.6878V59.6152Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99001 52.1319L29.6372 59.6152V49.6878L1.84976 42.2417L1.99001 52.1319Z"
        fill="#F8F8F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.426 42.2401L29.6373 34.7939L1.84976 42.2401L29.6373 49.6862L57.426 42.2401Z"
        fill="#3D80ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6362 42.2445L57.4249 34.7984V7.49756L29.6362 14.9437V42.2445Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99001 34.7612L29.6372 42.2445V14.9437L1.84976 7.49756L1.99001 34.7612Z"
        fill="#F8F8F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.426 7.49593L29.6373 0.0498047L1.84976 7.49593L29.6373 14.9421L57.426 7.49593Z"
        fill="#3B3A3A"
      />
    </svg>
  )
}
