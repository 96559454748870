import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismHelpIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import Shared from 'styles/Shared.module.scss'
import { RecipeExpanded } from 'types'

import Styles from './RoutineSettings.module.scss'

/**
 * Renders workflows settings tab
 *
 * @param recipe - The currently selected recipe
 * @param readOnly - Whether the settings should be editable
 */
const Workflows = ({ recipe, readOnly = false }: { recipe: RecipeExpanded; readOnly?: boolean }) => {
  const dispatch = useDispatch()

  const defaultValues = getDefaultFormValues(recipe)

  const {
    formState: { isDirty, isValid },
    control,
    getValues,
    trigger,
    reset,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    reset(getDefaultFormValues(recipe))
  }, [reset, recipe])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid) return error({ title: 'Error updating item settings.' })

    const { promptPrintPass, promptPrintFail, promptPrintUnknown } = getValues()

    const res = await service.patchProtectedRecipe(recipe.id, {
      user_settings: {
        ...recipe.user_settings,
        workflows: {
          prompt_print_pass: promptPrintPass,
          prompt_print_fail: promptPrintFail,
          prompt_print_unknown: promptPrintUnknown,
        },
      },
    })

    if (res.type !== 'success') return error({ title: 'Error updating recipe.' })

    await query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), {
      dispatch,
    })

    success({ title: 'Settings updated' })
  }

  const disableButton = !isDirty || !isValid || readOnly

  return (
    <>
      <div className={Shared.twoSideLayoutHeader}>
        <h1 className={Shared.twoSideLayoutHeaderTitle}>Workflows</h1>
        <div>
          <Button disabled={disableButton} size="small" onClick={handleSubmit} data-testid="">
            Save
          </Button>
        </div>
      </div>

      <Divider className={Styles.twoSideHorizontalDivider} />
      <div className={Styles.layoutBody}>
        <section className={Styles.captionContainer}>
          <PrismHelpIcon className={Styles.captionIcon} />
          <p className={Styles.caption}>
            Workflows guide inspection operators to take a specific action when a specific event occurs.
          </p>
        </section>

        <div className={Styles.workflowBodyTitle}>Prompt operator to print Item summary if Result is:</div>
        <div className={Styles.workflowOptions}>
          <Controller name="promptPrintPass" control={control} render={renderCheckbox('Pass', readOnly)} />
          <Controller name="promptPrintFail" control={control} render={renderCheckbox('Fail', readOnly)} />
          <Controller name="promptPrintUnknown" control={control} render={renderCheckbox('Unknown', readOnly)} />
        </div>
      </div>
      <LeavePagePrompt when={isDirty} onOk={() => reset(getDefaultFormValues(recipe))} />
    </>
  )
}

export default Workflows

const getDefaultFormValues = (recipe: RecipeExpanded) => {
  return {
    promptPrintPass: recipe.user_settings?.workflows?.prompt_print_pass || false,
    promptPrintFail: recipe.user_settings?.workflows?.prompt_print_fail || false,
    promptPrintUnknown: recipe.user_settings?.workflows?.prompt_print_unknown || false,
  }
}

const renderCheckbox =
  (label: string, disabled?: boolean) =>
  ({ onChange, value, name }: { onChange: (value: boolean) => any; value: boolean; name: string }) =>
    <PrismCheckbox name={name} checked={value} onChange={() => onChange(!value)} disabled={disabled} label={label} />
