import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Menu Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4035%3A102
 *
 * @param hasBackground - Fill the icon with light_100 when hovering the icon.
 * @param className - String for external styles.
 */
export const PrismMenuIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path id="Line" d="M19 16.5H5" strokeLinecap="round" stroke="currentColor" />
      <path id="Line_2" d="M5 13.5H19" strokeLinecap="round" stroke="currentColor" />
      <path id="Line_3" d="M5 7.5H19" strokeLinecap="round" stroke="currentColor" />
      <path id="Line_4" d="M5 10.5H19" strokeLinecap="round" stroke="currentColor" />
    </svg>
  )
}
