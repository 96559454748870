import { HEAP_APP_ID } from 'env'
import { log } from 'utils'

import { GENERATED_HEAP_SCRIPT_ID } from './constants'

/**
 * This code is migrated from the inline script Heap provides.
 * We don't have to worry about CSP policies even if this script
 * has to change something.
 * It has some weird stuff in here, and uses window.heap as an array
 * and object at the same time, so we ignore some types here.
 */
export function initializeHeapScript() {
  // @ts-ignore
  window.heap = window.heap || []
  if (!window.heap) return

  window.heap.load = function (appId, config) {
    if (!window.heap) return
    window.heap.appid = appId
    window.heap.config = config = config || {}
    const heapScript = document.createElement('script')
    heapScript.type = 'text/javascript'
    heapScript.id = `${GENERATED_HEAP_SCRIPT_ID}`
    heapScript.async = !0
    heapScript.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js'

    document.getElementsByTagName('head')[0]?.appendChild(heapScript)

    const heapProperties = [
      'addEventProperties',
      'addUserProperties',
      'clearEventProperties',
      'identify',
      'resetIdentity',
      'removeEventProperty',
      'setEventProperties',
      'track',
      'unsetEventProperty',
    ] as const
    const n = function (e: any) {
      return function () {
        // TODO: actually fix this
        // https://github.com/segmentio/analytics.js/issues/605
        // https://help.heap.io/getting-started/getting-started-faqs/heap-push-is-not-a-function-console-error/
        try {
          window.heap?.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        } catch (e) {
          log('src/utils/heap.ts', 'heap push error', e)
        }
      }
    }

    for (let i = 0; i < heapProperties.length; i++) {
      window.heap[heapProperties[i]!] = n(heapProperties[i])
    }
  }
  window.heap.load(`${HEAP_APP_ID}`)
}
