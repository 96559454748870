import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconOutcomeProps } from './prismIconsTypes'

/**
 * Prism Fail Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5412%3A11831
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  low: Styles.lowContrast,
  high: Styles.highContrast,
  dark: Styles.dark,
  iconColor: Styles.color,
}
export const PrismFailIcon = ({
  variant = 'transparent',
  viewboxSize = 'medium',
  className,
}: PrismIconOutcomeProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.outcome} ${Styles.fail} ${variantClass} ${className ?? ''}`

  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8874 12.948C11.3428 13.2974 10.6951 13.5 10 13.5C8.067 13.5 6.5 11.933 6.5 10C6.5 9.30495 6.7026 8.65721 7.05197 8.11263L11.8874 12.948ZM12.948 11.8874L8.11263 7.05197C8.65721 6.7026 9.30495 6.5 10 6.5C11.933 6.5 13.5 8.067 13.5 10C13.5 10.6951 13.2974 11.3428 12.948 11.8874ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
      />
    </svg>
  )
}
