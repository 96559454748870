import React from 'react'

import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { useConnectionStatus } from 'hooks'
import { CameraStatus } from 'types'
import { STATUS_LABELS } from 'utils/constants'

import Styles from './Status.module.scss'

export interface Props {
  status: CameraStatus | 'danger'
  children?: React.ReactNode
  flashing?: boolean
  showLabel?: boolean
  key?: string
  className?: string
  loaderClassName?: string
  lighterLoaderShade?: boolean
  'data-testid'?: string
  showLoadingCircle?: boolean
  labelClassName?: string
  hideLoader?: boolean
}

/**
 * Renders a Status component (left dot with pre-defined text)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A154
 *
 * @param status – status determines default text + color for Status
 * @param children – React Children element, render this instead of default status
 * @param flashing – The dot will blink if set to true, useful to indicate something is "alive"
 * @param showLabel - if false, the label container will not be rendered
 * @param className - container className
 * @param loaderClassName - laoder className
 * @param lighterLoaderShade - PrismLoader lighter shade variant
 * @param hideLoader - For special cases where we don't want to show the loader
 */
export const Status = ({
  status,
  children,
  flashing,
  showLabel = true,
  className = '',
  loaderClassName,
  lighterLoaderShade,
  'data-testid': dataTestId,
  showLoadingCircle,
  labelClassName = '',
  hideLoader = false,
}: Props) => {
  const connectionStatus = useConnectionStatus()
  const typeClass = Styles[`prismStatus_${status}`]
  const label = children || STATUS_LABELS[status]
  return (
    <>
      {!hideLoader && status === 'loading' && (showLabel || showLoadingCircle) && (
        <PrismSkeleton
          maxWidth={!showLoadingCircle ? 100 : undefined}
          className={`${showLoadingCircle ? Styles.circleLoader : ''} ${loaderClassName ?? ''}`}
          lighterShade={lighterLoaderShade}
          shape={showLoadingCircle ? 'circle' : 'square'}
        />
      )}

      {status !== 'loading' && (
        <div
          className={`${Styles.prismStatus} ${showLabel ? Styles.flexContainer : ''} ${typeClass} ${className ?? ''}`}
          data-testid={dataTestId}
        >
          <span
            className={`${Styles.statusEllipse} ${flashing ? Styles.flashing : ''}    
          ${status === 'running' && connectionStatus === 'offline' ? Styles.prismStatus_offlineAndRunning : ''}
           `}
          />

          {showLabel && <span className={labelClassName}>{label}</span>}
        </div>
      )}
    </>
  )
}
