import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Random Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=66%3A56
 *
 * @param hasHover - Fill the icon with light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismRandomToolIcon = ({
  isActive,
  hasHover,
  className,
  filled = true,
}: PrismIconProps & { filled?: boolean }) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.randomTool} ${hasHover ? Styles.hoverInBlue : ''} ${
        isActive ? Styles.active : ''
      } ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        id="Rectangle"
        d="M2 19.4144C2 15.9095 4.84127 13.0682 8.34615 13.0682C11.851 13.0682 14.6923 15.9095 14.6923 19.4144V20.2328C14.6923 20.3427 14.6032 20.4318 14.4933 20.4318H2.19902C2.0891 20.4318 2 20.3427 2 20.2328V19.4144Z"
        className={`${filled ? Styles.fill : ''} ${Styles.stroke}`}
        strokeLinecap="round"
      />
      <g id="Path">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.423 10.6136H18.5C20.6029 10.6136 22.3077 12.262 22.3077 14.2955V16.3409C22.3077 17.2446 21.55 17.9773 20.6153 17.9773H16.7114"
          className={filled ? Styles.fill : ''}
        />
        <path
          d="M13.423 10.6136H18.5C20.6029 10.6136 22.3077 12.262 22.3077 14.2955V16.3409C22.3077 17.2446 21.55 17.9773 20.6153 17.9773H16.7114"
          className={Styles.stroke}
          strokeLinecap="round"
        />
      </g>
      <ellipse
        id="Oval"
        cx="8.22525"
        cy="7.75"
        rx="3.14286"
        ry="3"
        className={`${filled ? Styles.fill : ''} ${Styles.stroke}`}
        strokeLinecap="round"
      />
      <ellipse
        id="Oval_2"
        cx="16.0824"
        cy="6.25"
        rx="2.35714"
        ry="2.25"
        className={`${filled ? Styles.fill : ''} ${Styles.stroke}`}
        strokeLinecap="round"
      />
    </svg>
  )
}
