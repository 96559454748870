import React from 'react'

import { InputRef } from 'antd'

import { PrismSearchIcon } from 'components/prismIcons'
import { PrismInput, PrismInputProps } from 'components/PrismInput/PrismInput'

import Styles from './SearchInput.module.scss'

const SearchInput = React.forwardRef<InputRef, PrismInputProps>((props, ref) => {
  const { className, ...rest } = props
  return (
    <PrismInput
      {...rest}
      ref={ref}
      className={`${Styles.searchField} ${className ?? ''}`}
      prefix={<PrismSearchIcon className={Styles.searchIcon} />}
    />
  )
})

SearchInput.displayName = 'SearchInput'

export default SearchInput
