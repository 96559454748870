import React, { useMemo } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismCameraIcon, PrismElementaryCube, PrismNavArrowIcon } from 'components/prismIcons'
import { OfflineTag } from 'components/Tag/Tag'
import {
  useConnectionStatus,
  useQueryAoiMetrics,
  useQueryItemMetrics,
  useRobotAndInspectionStatus,
  useRobotStatus,
  useStationStatus,
  useTypedSelector,
} from 'hooks'
import { TOOLTIP_POSITION } from 'pages/Analyze/AnalyzeBase'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { AreaOfInterestExpandedWithImage, Inspection, RecipeExpanded, Routine, RoutineWithAois, Station } from 'types'
import {
  calculateOpacityByIndex,
  calculatePercentage,
  combineOutcomeCounts,
  includes,
  isToolUntrained,
  matchRole,
  renderToolNameWithAoi,
  seriesFillGaps,
} from 'utils'
import { STATION_DETAIL_OVERVIEW_METRICS_PREFIX } from 'utils/constants'

import CountGraph from '../Components/CountGraph'
import { GraphSeries, YieldGraph } from '../Components/YieldGraph'
import { useTimeFrameInMs } from '../StationDetail'
import { getSortedAoisWithToolResults, useAoisWithToolResultCounts } from '../StationDetailTools/StationDetailTools'
import { CameraView } from './CameraView'
import { OverviewMetricsItem, OverviewMetricsTable, ToolWithDefectRate } from './OverviewMetricsItem'
import { RecentDefects } from './RecentDefects'
import Styles from './StationDetailOverview.module.scss'
import { TimeFrame } from './TimeFrame'

interface Props {
  inspection: Inspection | undefined
  routines: RoutineWithAois[] | undefined
  station: Station | undefined
  selectedRobotId: string | null
  historicRoutinesByRobotId?: { [robotId: string]: Routine }
  isHistoricBatch: boolean
  handleClickManualTrigger: () => any
  isLoadingCurrentItem: boolean
  fetchedRecipe: RecipeExpanded | undefined
}

export const StationDetailOverview = ({
  inspection,
  routines,
  station,
  selectedRobotId,
  isHistoricBatch,
  historicRoutinesByRobotId,
  handleClickManualTrigger,
  isLoadingCurrentItem,
  fetchedRecipe,
}: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const connectionStatus = useConnectionStatus()

  const manualTriggerDisabled = useTypedSelector(state => state.inspector.isManualTriggerDisabled)

  const setManualTriggerDisabled = (manualTriggerDisabled: boolean) => {
    dispatch(Actions.inspectorUpdate({ isManualTriggerDisabled: manualTriggerDisabled }))
  }

  const isInspectingManualPhoto = useTypedSelector(state => state.inspector.isInspectingManualPhoto)

  const robots = station?.robots
  const selectedRobot = robots?.find(r => r.id === selectedRobotId)
  const robotStatus = useRobotStatus(selectedRobot?.id)
  const stationStatus = useStationStatus(station)

  const setIsInspectingManualPhoto = (isInspecting: boolean) => {
    dispatch(Actions.inspectorUpdate({ isInspectingManualPhoto: isInspecting }))
  }

  const manualTrigger = routines?.[0]?.settings?.camera_trigger_mode === 'manual' && !isHistoricBatch

  const maxUpm = inspection?.user_settings?.production_targets?.units_per_minute || 0

  const fetchedRoutines = useMemo(() => {
    return fetchedRecipe?.recipe_routines.map(view => view.routine)
  }, [fetchedRecipe?.recipe_routines])

  const aois = useMemo(() => {
    const allAois: AreaOfInterestExpandedWithImage[] = []
    routines?.forEach(routine => {
      const currentFetchedRoutine = fetchedRoutines?.find(fetchedRoutine => fetchedRoutine.id === routine.id)
      const image = currentFetchedRoutine?.image || routine.image
      const aois = routine.aois.map(aoi => ({ ...aoi, image }))
      allAois.push(...aois)
    })
    return routines?.flatMap(routine => routine.aois)
  }, [fetchedRoutines, routines])

  const emptyStateEnabled = inspection === undefined

  // Assign variables that depend on state update messages, ensure we don't rerender if variables don't change
  const { inspectionLoading } = useRobotAndInspectionStatus(robots?.map(rbt => rbt.id) || [])

  const timeFrameMs = useTimeFrameInMs()

  // we fetch metrics until the effect sets a timeFrame
  const preventFetch = timeFrameMs === undefined

  const { itemMetricsRes, agg_s } = useQueryItemMetrics(inspection, 'item_outcome_inspection', manualTrigger, {
    isHistoricBatch,
    pastS: timeFrameMs ? timeFrameMs / 1000 : undefined,
    getterKeySuffix: STATION_DETAIL_OVERVIEW_METRICS_PREFIX,
    preventFetch,
  })
  const itemsMetrics = itemMetricsRes?.data.results

  const aoiMetrics = useQueryAoiMetrics(inspection, manualTrigger, {
    isHistoricBatch,
    pastS: timeFrameMs ? timeFrameMs / 1000 : undefined,
    getterKeyPrefix: STATION_DETAIL_OVERVIEW_METRICS_PREFIX,
    preventFetch,
  }).aoiMetricsRes?.data

  const itemSeries = useMemo(() => itemsMetrics && combineOutcomeCounts(itemsMetrics), [itemsMetrics])
  const totalCount = (itemSeries || []).reduce((aggr: number, data) => aggr + (data.count || 0), 0)
  const totalFail = (itemSeries || []).reduce((aggr: number, data) => aggr + (data.fail || 0), 0)

  const { itemSeriesGapsFilled }: { itemSeriesGapsFilled: GraphSeries[] } = useMemo(() => {
    const series = seriesFillGaps(itemSeries || [], agg_s)
    const itemSeriesGapsFilled = series.map(data => {
      return {
        ...data,
        failYield: (100 * (data.fail || 0)) / (data.count || 1),
        passYield: (100 * (data.pass || 0)) / (data.count || 1),
        unknownYield: (100 * (data.unknown || 0)) / (data.count || 1),
      }
    })

    return { itemSeriesGapsFilled }
  }, [itemSeries, agg_s])

  const aoisWithToolResultCounts = useAoisWithToolResultCounts({ aois, aoiMetrics: aoiMetrics?.results })

  const handleToolItemClick = (aoiId: string) => {
    history.push(
      paths.stationDetail('tools', station?.id, {
        historicInspectionId: isHistoricBatch ? inspection?.id : undefined,
        aoiId,
      }),
    )
  }

  const toolsWithDefectRatesData = useMemo(() => {
    const sortedAoisWithToolResultCounts = getSortedAoisWithToolResults(aoisWithToolResultCounts)

    return sortedAoisWithToolResultCounts.map(aoiWithToolResultsCount => {
      const { count, fail, aoi, tool } = aoiWithToolResultsCount
      const image = routines?.find(routine => routine.aois.find(routineAoi => routineAoi.id === aoi.id))?.image
      let failRate: string
      const isUntrained = isToolUntrained(tool)

      if (isUntrained) {
        failRate = 'Untrained'
      } else {
        const failCount = calculatePercentage(fail, count)
        const failPercentage = failCount.toFixed(1)
        failRate = `${failPercentage}%`
      }

      return { tool, aoi, image, failRate }
    })
  }, [aoisWithToolResultCounts, routines])

  const renderToolsByDefectRate = () => {
    return toolsWithDefectRatesData.map(({ aoi, tool, failRate, image }) => (
      <ToolWithDefectRate
        data-test="station-detail-overview-tools-by-defect"
        key={aoi.id}
        description={renderToolNameWithAoi(tool, aoi)}
        value={failRate}
        image={<ImageCloseUp loaderType="skeleton" src={image} key={image} region={aoi} />}
        onClick={() => handleToolItemClick(aoi.id)}
      />
    ))
  }

  const handleClickDoneInspecting = () => {
    setIsInspectingManualPhoto(false)
    setManualTriggerDisabled(false)
  }

  const renderTimeframe = () => {
    if (connectionStatus === 'offline' && stationStatus === 'running')
      return (
        <div className={`${Shared.verticalChildrenGap16} ${Styles.timeframeOfflineContainer}`}>
          <OfflineTag className={Styles.timeframeOfflineTag} />

          {Array(3)
            .fill(undefined)
            .map((_, i) => (
              <TimeframeOfflineCard key={i} index={i} />
            ))}

          <TimeframeOfflineAlternateCard />
        </div>
      )

    const toRender = [
      <TimeFrame
        isHistoricBatch={isHistoricBatch}
        className={connectionStatus === 'recovering' ? Styles.reconnectionCoat : ''}
        key="timeframe"
      >
        <OverviewMetricsItem
          data-testid="station-detail-overview-item-count"
          title="item count"
          value={emptyStateEnabled ? '--' : totalCount}
          chart={
            <>
              {inspection && (
                <CountGraph
                  graphSeries={itemSeriesGapsFilled}
                  chartWidth="99%"
                  chartHeight="100%"
                  syncId="overview"
                  mode="metrics"
                  upmThreshold={maxUpm}
                  hideXAxis
                  hideYAxis
                  fixedTooltipCoords={TOOLTIP_POSITION}
                  period="hour"
                />
              )}
              {!inspection && <div />}
            </>
          }
          emptyState={emptyStateEnabled}
        />
        <OverviewMetricsItem
          data-testid="station-detail-overview-failed-items"
          title="failed items"
          value={emptyStateEnabled ? '--' : totalFail}
          emptyState
        />

        <OverviewMetricsItem
          data-testid="station-detail-overview-fail-rate"
          title="fail rate"
          value={emptyStateEnabled ? '--' : totalFailRate.toFixed(1)}
          chart={
            <>
              {inspection && (
                <YieldGraph
                  yieldSeries={itemSeriesGapsFilled}
                  showPasses={false}
                  showUknowns={false}
                  chartWidth="99%"
                  chartHeight="100%"
                  syncId="overview"
                  mode="metrics"
                  hideXAxis
                  hideYAxis
                  fixedTooltipCoords={TOOLTIP_POSITION}
                  period="hour"
                />
              )}

              {!inspection && <div />}
            </>
          }
          emptyState={emptyStateEnabled}
        />

        <OverviewMetricsTable title="tools by defect rate" emptyState={emptyStateEnabled}>
          {renderToolsByDefectRate()}
        </OverviewMetricsTable>
      </TimeFrame>,
    ]

    return toRender
  }

  const renderDefects = () => {
    if (connectionStatus === 'offline' && stationStatus === 'running')
      return (
        <>
          <div className={Styles.offlineDefectsHeaderContainer}>
            <p className={Styles.offlineDefectsHeader}>Defective Items</p>
            <OfflineTag />
          </div>

          <div className={Styles.emptyDefectsContainer}>
            {Array(4)
              .fill(undefined)
              .map((_, i) => (
                <DefectiveItemsOfflineCard key={i} index={i} />
              ))}
          </div>
        </>
      )

    const toRender = [
      <RecentDefects
        inspection={inspection}
        itemsMetrics={itemsMetrics}
        station={station}
        routines={routines}
        key="recent-defects"
      />,
    ]

    return toRender
  }

  const totalFailRate = calculatePercentage(totalFail, totalCount)

  return (
    <>
      <div className={Styles.inspectionOverviewWrapper}>
        <section className={Styles.timeFrameSection}>{renderTimeframe()}</section>

        <Divider type="vertical" className={Styles.verticalDiv} />
        <section
          className={`${Styles.cameraViewSection} ${manualTrigger && !isHistoricBatch ? Styles.manualTrigger : ''}`}
        >
          <CameraView
            data-testid="station-detail-overview-camera-view"
            robots={robots || []}
            className={manualTrigger && !isHistoricBatch ? Styles.viewWithManualTrigger : ''}
            camViewGridClassName={manualTrigger && !isHistoricBatch ? Styles.camViewGrid : ''}
            cameraMenuClassName={isInspectingManualPhoto ? Styles.camMenuContainer : ''}
            emptyState={emptyStateEnabled}
            inspection={inspection}
            routines={routines}
            isInspectingManualPhoto={isInspectingManualPhoto}
            isLoading={isLoadingCurrentItem}
            displayRoutineGoldenImage={isHistoricBatch}
            historicRoutinesByRobotId={historicRoutinesByRobotId}
            station={station}
          />
          {manualTrigger && !isHistoricBatch && (
            <>
              {matchRole('inspector') && !isInspectingManualPhoto && (
                <Button
                  size="large"
                  onClick={handleClickManualTrigger}
                  disabled={
                    connectionStatus !== 'online' ||
                    manualTriggerDisabled ||
                    includes(['disconnected', 'loading'], robotStatus)
                  }
                  wide
                  className={Styles.takePhotoButton}
                  loading={inspectionLoading}
                  data-testid="sd-overview-take-photo"
                  badge={<PrismCameraIcon />}
                >
                  Take Photo
                </Button>
              )}
              {isInspectingManualPhoto && (
                <Button
                  size="large"
                  onClick={handleClickDoneInspecting}
                  wide
                  className={Styles.takePhotoButton}
                  type="secondary"
                  data-testid="sd-overview-done"
                  badge={<PrismNavArrowIcon />}
                  invertBadgePosition
                >
                  Done
                </Button>
              )}
            </>
          )}
        </section>

        <Divider className={Styles.horizontalDiv} />

        <section className={Styles.recentDefectsSection}>
          <div
            className={`${Styles.recentDefectsContainer} ${
              connectionStatus === 'offline' ? Styles.offlineDefectsContainer : ''
            } ${connectionStatus === 'recovering' ? Styles.reconnectionCoat : ''}`}
          >
            {renderDefects()}
          </div>
        </section>
      </div>
    </>
  )
}

/**
 * Renders an offline mode card to use in the timeframe section.
 *
 * @param index - Index of timeline item
 */
const TimeframeOfflineCard = ({ index }: { index: number }) => (
  <div style={{ opacity: calculateOpacityByIndex(index) }} className={Styles.emptyCardContainer}>
    <div className={Styles.emptyCardLeftContainer}>
      <div className={`${Styles.emptyBox} ${Styles.emptyCardFirstBar}`} />
    </div>
    <div className={Styles.emptyCardRightContainer}>
      <div className={`${Styles.emptyBox} ${Styles.emptyCardSecondBar}`} />
      <div className={`${Styles.emptyBox} ${Styles.emptyCardThirdBar}`} />
    </div>
  </div>
)

/**
 * Renders an alternate offline mode card to use in the timeframe section. This card is shown
 * At the bottom of the list.
 *
 * @param index - Index of timeline item
 */
const TimeframeOfflineAlternateCard = () => (
  <div className={Styles.emptyCardDefectRate}>
    <div className={`${Styles.emptyBox} ${Styles.emptyCardFirstBar}`} />
    {Array(3)
      .fill(undefined)
      .map((_, i) => (
        <div className={Styles.emptyCardRow} key={i}>
          <div className={`${Styles.emptyBox} ${Styles.leftBox}`} />
          <div className={`${Styles.emptyBox} ${Styles.midBox}`} />
          <div className={`${Styles.emptyBox} ${Styles.rightBox}`} />
        </div>
      ))}
  </div>
)

/**
 * Renders a single Defect card with an elementary cube image. This is used exclusively
 * for offline mode.
 *
 * @param index - index of current defective empty card
 */
const DefectiveItemsOfflineCard = ({ index }: { index: number }) => (
  <div style={{ opacity: calculateOpacityByIndex(index) }} className={Styles.emptyDefectiveItemContainer}>
    <PrismElementaryCube className={Styles.emptyElementaryCube} />
  </div>
)
