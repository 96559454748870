import React, { useState } from 'react'

import { Tooltip, TooltipProps } from 'antd'

import Styles from './PointerTooltip.module.scss'

/**
 * This component is a wrapper around antd Tooltip.
 * It was created as a workaround for the issue where disabled buttons don't handle the onMouseLeave event correctly,
 * so the tooltip would stay visible even when the user moves the mouse out of the button.
 * The solution was to use pointer events instead of mouse events. They are supported since Chrome 55.
 *
 * Pointer events docs: https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events
 * Related github issue: https://github.com/facebook/react/issues/18753#issuecomment-630771296
 *
 * @param props - All antd Tooltip props
 */
const PointerTooltip = ({ children, open: visibleProp, ...rest }: TooltipProps) => {
  const [innverVisible, setInnerVisible] = useState(false)
  const open = visibleProp !== undefined ? visibleProp : innverVisible
  return (
    <Tooltip {...rest} open={open} overlayClassName={Styles.tooltip}>
      <span onPointerEnter={() => setInnerVisible(true)} onPointerLeave={() => setInnerVisible(false)}>
        {children}
      </span>
    </Tooltip>
  )
}

export default PointerTooltip
