import React, { useEffect, useRef, useState } from 'react'

import { TooltipProps } from 'antd'

import PrismTooltip from 'components/PrismTooltip/PrismTooltip'

import Styles from './OverflowingTooltip.module.scss'

interface OverflowingTooltipPros extends Omit<TooltipProps, 'title' | 'trigger' | 'open' | 'onOpenChange'> {
  label: { parent: string; child: string }
  containerIsRendered: boolean
  subtitle?: boolean
}

/**
 * Renders a tooltip when the element scrollWidth is bigger than the offsetWidth.
 *
 * @param label - object containing the parent and child name.
 * @param containerIsRendered - indicates if the container is already rendered, used to know when to calculate
 * the text overflow
 * @param subtitle - Whether this element is a subtitle, special styles are applied if true.
 */
const OverflowingTooltip = ({ label, containerIsRendered, subtitle, ...rest }: OverflowingTooltipPros) => {
  const parentNameRef = useRef<null | HTMLHeadingElement>(null)
  const childNameRef = useRef<null | HTMLHeadingElement>(null)
  const [textIsOverflowing, setTextIsOverflowing] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!containerIsRendered || !parentNameRef.current || !childNameRef.current) return

    // if the text is larger than the rendered container, it means there's text overflowing
    setTextIsOverflowing(
      parentNameRef.current.scrollWidth > parentNameRef.current.offsetWidth ||
        childNameRef.current.scrollWidth > childNameRef.current.offsetWidth,
    )
  }, [containerIsRendered])

  // Tooltip will load only after the element has been hovered, otherwise tooltips are loaded when the modal opens slowing the app.
  const handleOpenChange = (open: boolean) => {
    setOpen(open)
  }

  return (
    <PrismTooltip
      title={subtitle ? `${label.parent} on ${label.child}` : `${label.parent} - ${label.child}`}
      placement="topLeft"
      trigger="hover"
      open={textIsOverflowing && open}
      onOpenChange={handleOpenChange}
      {...rest}
      anchorClassName={subtitle ? Styles.subtitle : Styles.title}
    >
      <>
        <span ref={parentNameRef} className={Styles.titleText}>
          {label.parent}
        </span>
        <span className={Styles.joint}>{subtitle ? ' on ' : ' - '}</span>
        <span ref={childNameRef} className={Styles.titleText}>
          {label.child}
        </span>
      </>
    </PrismTooltip>
  )
}

export default OverflowingTooltip
