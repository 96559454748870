import React, { useMemo } from 'react'

import { PrismElementaryCube } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import Shared from 'styles/Shared.module.scss'
import { commaSeparatedNumbers } from 'utils'

import Styles from './StationDetailOverview.module.scss'

const Cube = <PrismElementaryCube />

type Props = {
  title: string
  chart?: React.ReactNode
  value?: number | string
  emptyState?: boolean
  'data-testid'?: string
}

/**
 * Renders a metrics card to display on the overview left hand side
 * @param title - Title of the overview metrics card
 * @param chart - Chart element to display underneath the title
 * @param value - metric value to display
 * @param emptyState - Whether we should show the charts in blank state
 */
export const OverviewMetricsItem = ({ title, chart, value, emptyState, 'data-testid': dataTestId }: Props) => {
  const displayedValue = useMemo(() => {
    if (typeof value === 'number') {
      return commaSeparatedNumbers(value)
    }
    return value
  }, [value])

  const showSmallFont = useMemo(() => {
    if (typeof value === 'number') {
      const digitCount = value.toString().length
      return digitCount >= 6
    }
    return false
  }, [value])

  return (
    <div className={`${Styles.OverviewMetricsItem} ${Styles.OverviewMetricsItemTwoColumns}`}>
      <figure className={Styles.timeFrameLeftContainer}>
        <h6 className={Styles.timeFrameTitle}>{title}</h6>

        {emptyState ? '' : chart ? <div className={Styles.chartContainer}>{chart}</div> : ''}
      </figure>
      <div
        className={`${Styles.timeFrameRightContainer} ${showSmallFont ? Styles.valueWithMoreDecimals : ''}`}
        data-testid={`${dataTestId}-value`}
      >
        {displayedValue}
        {title === 'fail rate' && !emptyState && <span className={Styles.percentageFont}>%</span>}
      </div>
    </div>
  )
}

type OverviewMetricsTableProps = {
  title: string
  children?: React.ReactNode
  emptyState?: boolean
}

/**
 * Renders a card similar to the metrics item, but in this case it's a table with tools by defect rate
 * @param title - title of the card
 * @param children - List of tools and their corresponding defect rates
 * @returns
 */
export const OverviewMetricsTable = ({ title, children, emptyState }: OverviewMetricsTableProps) => {
  return (
    <div className={Styles.OverviewMetricsItem}>
      <div className={Shared.verticalChildrenGap16}>
        <h6 className={Styles.timeFrameTitle}>{title}</h6>
        {!emptyState && children}

        {emptyState &&
          Array(3)
            .fill(undefined)
            .map((_, i) => <ToolWithDefectRate key={i} />)}
      </div>
    </div>
  )
}

/**
 * Renders a "column" on the tools by defect chart.
 *
 * @param image - Image to show corresponding to the tool defect
 * @param description - The tool title
 * @param value - The percentage, or defect rate to show
 * @param onClick - Handler for when users click on the current row
 */
export const ToolWithDefectRate = ({
  image = Cube,
  description,
  value,
  onClick,
  'data-test': dataTest,
}: {
  image?: React.ReactNode
  description?: string
  value?: string | number
  emptyState?: boolean
  onClick?: React.MouseEventHandler
  'data-test'?: string
}) => {
  return (
    <div onClick={onClick} className={Styles.toolsByDefectRateItem} data-test={dataTest}>
      <figure className={Styles.toolImageContainer}>{image}</figure>
      <PrismOverflowTooltip
        data-testid={`${dataTest}-description`}
        className={Styles.toolDescription}
        content={description === undefined ? '--' : description}
      />
      <span data-testid={`${dataTest}-value`} className={Styles.toolValue}>
        {value === undefined ? '--' : ` ${value}`}
      </span>
    </div>
  )
}
