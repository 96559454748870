import React, { useMemo } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error } from 'components/PrismMessage/PrismMessage'
import { Token } from 'components/Token/Token'
import { useQueryEntityFromSites } from 'hooks'
import paths from 'paths'
import { PerEntityModalProps, Site, Station, StationForSite, WithSiteId } from 'types'
import { SiteAndLineCascaderLocation, sortStationsByOrdering } from 'utils'
import { INDEPENDENT_STATION_LINE_NAME, INPUT_MAX_CHARACTER_LIMIT } from 'utils/constants'

import EntityModal from './EntityModal'
import Styles from './EntityModal.module.scss'
import { getSiteLineAndStationIdsFromCascaderValue, SitesAndLinesCascader } from './SitesAndLinesCascader'

/**
 * Renders the Create or Edit Station modal
 *
 *
 * @param entity - the modal name
 */
type StationFormValuesType = {
  name: string | undefined
  location: SiteAndLineCascaderLocation | undefined
}

const AddOrEditStationModal = <T extends boolean>({
  entity: station,
  defaultLocation,
  isEditMode,
  onClose,
  preventNavigation,
}: PerEntityModalProps<T, WithSiteId<StationForSite>> & {
  defaultLocation?: SiteAndLineCascaderLocation
  preventNavigation?: boolean
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const sites = useQueryEntityFromSites({ entity: 'site', noRefetch: true })

  const defaultValues: StationFormValuesType = getDefaultStationSettingsValues(station, defaultLocation)
  const {
    formState: { errors, isDirty, isValid, dirtyFields },
    getValues,
    control,
  } = useForm({ defaultValues, mode: 'onChange' })

  const showLocationWarning = !!isEditMode && !!dirtyFields.location

  const locationWarningData = useMemo(() => {
    if (!showLocationWarning || !station) return

    const foundSite = sites?.find(site => site.id === station.site_id)

    const foundLine = foundSite?.subsites.find(line => line.id === station.belongs_to_id)

    const foundStation = foundSite?.stations.find(currentStation => currentStation.id === station.id)

    return {
      siteName: foundSite?.name,
      lineName: foundLine?.name || INDEPENDENT_STATION_LINE_NAME,
      stationName: foundStation?.name,
    }
  }, [showLocationWarning, sites, station])

  const renderLocationWarningCopy = () => {
    if (!locationWarningData) return null

    const { siteName, lineName, stationName } = locationWarningData
    return `${siteName} / ${lineName} / ${stationName}`
  }

  async function handleSubmit() {
    return handleStationSettingsSubmit({
      values: getValues(),
      isValid,
      station,
      sites,
      isEditMode,
      onFinish: async station => {
        if (station) {
          await query(getterKeys.station(station.id), () => service.getStation(station.id), { dispatch })
        }
        await query(getterKeys.sites(), service.getSites, { dispatch })
        if (!preventNavigation) {
          history.push(paths.inspect({ mode: 'site', params: { siteId: station?.site_id, stationId: station?.id } }), {
            lineIdToExpand: station?.belongs_to_id || null,
          })
        }
        onClose()
      },
    })
  }

  return (
    <EntityModal
      id="station-entity-modal"
      data-testid="add-station-modal"
      entity="station"
      isEditMode={isEditMode}
      onSubmit={handleSubmit}
      onClose={onClose}
      disableSave={!isValid || !isDirty}
    >
      <>
        <Controller
          name="name"
          rules={{ required: 'Station is required' }}
          control={control}
          render={({ ...props }) => (
            <PrismInput
              label="station name (*)"
              data-testid="station-name-input"
              {...props}
              errors={errors}
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
            />
          )}
        />
        <Controller
          name="location"
          rules={{ required: 'Location is required' }}
          control={control}
          render={({ ...props }) => (
            <Token label="location (*)">
              <SitesAndLinesCascader
                className={Styles.sizeExtraBig}
                data-testid="station-location-cascader"
                size="large"
                status={showLocationWarning && station ? 'warning' : undefined}
                {...props}
              />
              {showLocationWarning && station && (
                <div className={Styles.textMismatchWarning}>
                  Running and historical batches will continue to reference {renderLocationWarningCopy()}.
                </div>
              )}
            </Token>
          )}
        />
      </>
    </EntityModal>
  )
}

export default AddOrEditStationModal

export const getDefaultStationSettingsValues = (
  station: WithSiteId<StationForSite> | undefined,
  defaultLocation?: SiteAndLineCascaderLocation,
): StationFormValuesType => {
  return {
    name: station?.name,
    location: station ? [`${station.site_id}_${station?.type_id}`, station.belongs_to_id || null] : defaultLocation,
  }
}

export const handleStationSettingsSubmit = async ({
  values,
  isValid,
  isEditMode,
  station: stationToEdit,
  sites,
  onFinish,
}: {
  values: StationFormValuesType
  sites: Site[] | undefined
  isValid: boolean
  isEditMode?: boolean
  station?: StationForSite & { site_id: string }
  onFinish?: (station?: Station) => Promise<void>
}) => {
  if (!values.location || !isValid) return

  const { siteId, lineId, stationSubtypeId } = getSiteLineAndStationIdsFromCascaderValue(values.location)

  let res: SendToApiResponse<Station> | undefined = undefined

  if (!siteId) return

  let newStationOrder: number | null = null

  // If we are moving or creating a station in an actual line, we need to calculate the station.ordering field
  if (lineId) {
    const stationsOrderInNewLine = sites
      ?.find(site => site.id === siteId)
      ?.stations.filter(station => !station.is_deleted && station.belongs_to_id === lineId)
      ?.map(station => station.ordering) as number[]
    newStationOrder = stationsOrderInNewLine.length ? Math.max(...stationsOrderInNewLine) + 1 : 0
  }

  if (isEditMode && stationToEdit) {
    const isStationMoving = stationToEdit.belongs_to_id !== lineId

    res = await service.updateStation(stationToEdit.id, {
      name: values.name,
      belongs_to_id: lineId,
      type_id: stationSubtypeId,
      ordering: isStationMoving ? newStationOrder : stationToEdit.ordering,
    })

    // If we are moving a station we also want to update the stations order that belong to the prevous line
    if (stationToEdit.belongs_to_id && isStationMoving) {
      const stationsOrderInOldLinePayload = sites
        ?.find(site => site.id === stationToEdit.site_id)
        // Filter stations that belong to the previous line the edited station was in aswell as filtering the station we are gonna move
        ?.stations.filter(
          station =>
            !station.is_deleted &&
            station.belongs_to_id === stationToEdit.belongs_to_id &&
            station.id !== stationToEdit.id,
        )
        .sort(sortStationsByOrdering)
        .map((station, idx) => ({ station_id: station.id, ordering: idx }))

      const updateRes = await service.orderStations(stationToEdit.belongs_to_id, stationsOrderInOldLinePayload || [])
      if (updateRes.type !== 'success') return error({ title: 'An error occured, please try again' })
    }
  } else {
    res = await service.createStation({
      body: {
        type_id: stationSubtypeId,
        belongs_to_id: lineId,
        name: values.name,
        ordering: newStationOrder,
      },
    })
  }
  await onFinish?.(res?.type === 'success' ? res.data : undefined)
  return res
}
