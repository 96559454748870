import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Tag } from 'antd'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { useAllToolLabels, useToolTrainingResults } from 'hooks'
import { PatchProtectedToolBody, RoutineWithAois, Tool, ToolThreshold, TrainableToolSettingsProps } from 'types'
import {
  calculateTrainingMetrics,
  computeConfusionMatrix,
  getDisplaySeverity,
  getLabelName,
  getThresholdFromTool,
  getToolLabelImagesToShow,
} from 'utils'

import { SharedTooltip } from '../../ToolCard'
import EditAoisHelper from '../AOIEditing/EditAoisHelper'
import ThresholdCriteria from '../ThresholdCriteria'
import Styles from '../ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from '../ToolTemplate'

/**
 * Renders Match Tool form. The user can create custom labels here and select
 * the expected labels to find in an inspection.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const MatchToolSettings = ({ routine, recipe, view, tool, onExit, readOnly }: TrainableToolSettingsProps) => {
  const [showThresholdModal, setShowThresholdModal] = useState(false)

  const { defaultLabels, allToolLabels } = useAllToolLabels()

  const trainedLabels = useMemo(
    () => allToolLabels?.filter(label => tool.metadata.classes?.includes(label.id)),
    [allToolLabels, tool.metadata.classes],
  )

  const trainingResults = useToolTrainingResults(tool.id) || []

  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })
  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
  } = formData

  const resetForm = useCallback(
    ({ routine, tool }: ResetFormArgs) => {
      reset(getDefaultFormValues(routine, tool))
    },
    [reset],
  )

  useEffect(() => {
    resetForm({ routine, tool })
  }, [resetForm, routine, tool])

  // There's a gap here, because if the user doesn't actually save, any label is pointing to the previously saved value of expected_classes, not all labels.
  const saveChanges = () => {
    const { expectedLabels, threshold, aois } = getValues()
    const { dirtyFields } = formState

    if (dirtyFields.expectedLabels || dirtyFields.threshold) {
      const data: PatchProtectedToolBody = { metadata: { ...tool.metadata } }

      const aoiUpdates = aois.map(aoi => ({
        id: aoi.id,
        inference_user_args: { ...tool.inference_user_args },
      }))

      if (dirtyFields.expectedLabels) {
        aoiUpdates.forEach((_, idx) => {
          aoiUpdates[idx]!.inference_user_args.expected_classes = expectedLabels
        })
      }
      if (dirtyFields.threshold) {
        const matrix = computeConfusionMatrix(trainingResults, tool.specification_name, {
          defaultLabels,
          allToolLabels,
          threshold: threshold,
        })
        const training_metrics = calculateTrainingMetrics(matrix, allToolLabels, tool)

        aoiUpdates.forEach((_, idx) => {
          aoiUpdates[idx]!.inference_user_args.threshold = threshold.threshold
        })
        data.metadata = { ...data.metadata, training_metrics }
      }

      data.aois = aoiUpdates

      return [service.patchProtectedTool(tool.id, data)]
    }
  }

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    wrapperClassName={Styles.settingsItemInput}
                  />
                )}
              />

              {tool.is_shared && <SharedTooltip iconClassName={Styles.sharedToolIcon} />}

              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemTitle}>Pass Criteria</div>
              <div className={`${Styles.settingsItemCriteria} ${Styles.matchCriteriaContainer}`}>
                <div className={Styles.settingsItemTitle}>
                  <div>Expected Label</div>
                </div>

                <Controller
                  control={control}
                  name="expectedLabels"
                  render={({
                    onChange,
                    value,
                  }: {
                    onChange: (e: string[] | string) => any
                    value: string[] | string
                  }) => {
                    return (
                      <>
                        <PrismSelect
                          data-testid="match-tool-settings-expected-label"
                          value={value}
                          onChange={(e: string[] | string) => {
                            let expectedLabels = e
                            if (!Array.isArray(expectedLabels)) expectedLabels = [expectedLabels]
                            if (expectedLabels.includes('')) expectedLabels = []

                            onChange(expectedLabels)
                          }}
                          mode="multiple"
                          size="large"
                          disabled={readOnly || !trainedLabels || tool.state !== 'successful'}
                          loading={!trainedLabels}
                          multiClassName={Styles.multiTagContainer}
                          tagRender={({ closable, onClose, value }) => {
                            const foundLabel = allToolLabels?.find(tl => tl.id === value)
                            if (!foundLabel) return <span>--</span>
                            return (
                              <Tag closable={closable} onClose={onClose} className={Styles.labelTag}>
                                <PrismResultButton value={getLabelName(foundLabel)} severity="neutral" />
                              </Tag>
                            )
                          }}
                          optionFilterProp="filterName"
                          showSelectedOptionCheck
                          options={trainedLabels?.map(label => {
                            const image = getToolLabelImagesToShow(label)[0]
                            const labelName = getLabelName(label)
                            return {
                              value: label.id,
                              key: label.id,
                              filterName: labelName,
                              content: (
                                <PrismResultButton
                                  severity={getDisplaySeverity(label)}
                                  value={getLabelName(label)}
                                  type="noFill"
                                  className={Styles.selectItemText}
                                  size="small"
                                />
                              ),
                              image: {
                                content: image ? (
                                  <ImgFallback src={image} className={Styles.labelFormImage} loaderType="skeleton" />
                                ) : (
                                  <PrismElementaryCube />
                                ),
                              },
                            }
                          })}
                        />

                        <div className={Styles.settingsItemDescription}>
                          {tool.state === 'successful'
                            ? 'The tool will Pass if one of the above labels is found. If none of the above are found, it will Fail.'
                            : 'You can set the expected label for this tool after you have trained it.'}
                        </div>
                      </>
                    )
                  }}
                />

                <ThresholdCriteria
                  showThresholdModal={showThresholdModal}
                  setShowThresholdModal={setShowThresholdModal}
                  className={Styles.matchThresholdContainer}
                  tool={tool}
                  control={control}
                  getValues={getValues}
                  view={view}
                />
              </div>
            </div>
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              disableToggleButtons={showThresholdModal}
              aois={value}
              setAois={onChange}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

/**
 *
 * @param routine - routine to get aois from
 * @param tool - tool that we're editing
 * @returns default form values to use
 */
const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const { name, aois } = getToolNameAndAois(routine, tool)
  return {
    aois,
    name,
    threshold: getThresholdFromTool(tool) as ToolThreshold,
    expectedLabels: (tool.inference_user_args['expected_classes'] || []) as string[],
  }
}
