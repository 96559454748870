import { createStore, Store } from 'redux'

import appReducer from 'rdx/reducers/index'
import { RootState } from 'types'
import { validateLocalStorage, validateLocalStorageAuthData } from 'utils'

import { Action } from './actions'

/* To point local frontend to a customer stack, just uncomment the lines below, you'll only need to fill in the "token" field.
   Which you can get by logging into the customer stack through okta, then in the dev tools go to Application -> Storage -> Cookies
   Find the elementary_auth cookie and copy it's value into the `token` field below.
   Last thing you'll need is you'll want to remove the `NODE_ENV === 'development' &&` conditional from our `sendToApi` function.
*/

// const forcedAuth = {
//   auth: { id: '', expires_at: '', organization_id: '', token: '' },
//   rockwellIdpLogin: false,
// }
// localStorage.setItem('elementary:auth', JSON.stringify(forcedAuth))

const auth = localStorage.getItem('elementary:auth')

// On startup, ensure we have valid JSON stored in localStorage
if (!validateLocalStorage(auth) || !validateLocalStorageAuthData(auth)) {
  localStorage.removeItem('elementary:auth')
  window.location.reload()
}

const rootReducer = (state: any, action: Action) => {
  let appState = state
  // Clean state tree on logout to prevent leakage of (possibly sensitive) info
  // Keep context of rockwellIdpLogin for correct redirect logout logic, logoutAction gets set by the action after the appReducer runs
  if (action.type === 'AUTH_UNSET') {
    appState = { auth: { auth: null, logoutAction: null, rockwellIdpLogin: state.auth.rockwellIdpLogin } }
  }
  // Reset store to any valid state (for testing)
  if (action.type === 'RESET') appState = action.payload || {}
  return appReducer(appState, action)
}

export type TypedStore = Store<RootState, Action>

/**
 * Create a store with our `rootReducer` and an initial state that conforms to
 * our `RootState`. This can be used for testing.
 *
 * @param initialState - Initial state object
 *
 * @returns Redux store instance
 */
export function createTypedStore(initialState: Partial<RootState>): TypedStore {
  return createStore(rootReducer, initialState)
}

const initialState = {
  auth: {
    ...(auth ? (JSON.parse(auth) as Omit<RootState['auth'], 'logoutAction'>) : { auth: null, rockwellIdpLogin: false }),
    logoutAction: null,
  },
}

const store: TypedStore = createStore(rootReducer, initialState)

export default store
