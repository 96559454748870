import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CartesianGrid, RechartsFunction } from 'recharts'

import { getterKeys } from 'api'
import { IconButton } from 'components/IconButton/IconButton'
import { LiveFeed } from 'components/LiveFeed/LiveFeed'
import { PrismExpandIcon } from 'components/prismIcons'
import { Modal } from 'components/PrismModal/PrismModal'
import { useData, useInspectionDurationRef } from 'hooks'
import { Inspection } from 'types'
import { calculateMetricsCompactionParams, combineOutcomeCounts, seriesFillGaps } from 'utils'

import CountGraph from '../Components/CountGraph'
import { GraphSeries, YieldGraph } from '../Components/YieldGraph'
import TimelineStyles from './StationDetailTimeline.module.scss'

export interface TimelineGraphsProps {
  inspection: Inspection
  scrolledGroupTime: number
  onGraphClick: (groupIndex: number, time: number) => any
  inspectionDurationMs: number
  isHistoricBatch: boolean
}

/**
 * This fetches metrics data and renders the graph section of the Timeline tab in Station Detail page. Includes yield and count graphs.
 * @param inspection - Inspection to show metrics for
 * @param scrolledGroupTime - The time (ms) of the group at the top of the timeline
 * @param timelineCardGroups - The groups of cards in the timeline
 * @param onGraphClick - A function to execute when the graphs are clicked.
 *  Params are the groupIndex, if found, and end time of the clicked section
 * @param inspectionDurationMs - Inspection duration in ms. Used to calculate metrics parameters.
 */
export function TimelineGraphs({ inspection, scrolledGroupTime, onGraphClick, isHistoricBatch }: TimelineGraphsProps) {
  const [fullscreenGraph, setFullscreenGraph] = useState<'Items' | 'Yield'>()
  const yieldGraphGridRef = useRef<CartesianGrid>(null)
  const countsGraphGridRef = useRef<CartesianGrid>(null)
  const yieldThreshold = inspection?.user_settings?.production_targets?.target_yield || 0
  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const { metricsCompaction, agg_s } = calculateMetricsCompactionParams(inspectionDurationMsRef.current, {
    isHistoricBatch,
  })
  const maxUpm = inspection?.user_settings?.production_targets?.units_per_minute || 0

  // Don't poll until we have inspection, hence duration, hence we can compute `metricsCompaction`
  const itemMetricsKey = inspection ? getterKeys.rtsMetrics('items', inspection.id, metricsCompaction) : undefined

  const itemsData = useData(itemMetricsKey)?.results

  const itemSeries = useMemo(() => itemsData && combineOutcomeCounts(itemsData), [itemsData])

  const {
    itemSeriesGapsFilled,
    hasFail,
    hasPass,
    hasUnknown,
  }: { itemSeriesGapsFilled: GraphSeries[]; hasPass: boolean; hasFail: boolean; hasUnknown: boolean } = useMemo(() => {
    const series = seriesFillGaps(itemSeries || [], agg_s)
    const itemSeriesGapsFilled = series.map(data => {
      return {
        ...data,
        failYield: (100 * (data.fail || 0)) / (data.count || 1),
        passYield: (100 * (data.pass || 0)) / (data.count || 1),
        unknownYield: (100 * (data.unknown || 0)) / (data.count || 1),
      }
    })

    const hasFail = series.some(d => (d.fail || 0) > 0)
    const hasPass = series.some(d => (d.pass || 0) > 0)
    const hasUnknown = series.some(d => (d.unknown || 0) > 0)

    return { itemSeriesGapsFilled, hasFail, hasPass, hasUnknown }
  }, [itemSeries, agg_s])

  const handleClickGraph: RechartsFunction = e => {
    if (e?.activeLabel) {
      if (e?.activePayload?.every((payload: { value: number }) => payload.value === 0)) return // User clicked on chart section with no data
      const graphMs = e.activeLabel

      onGraphClick(graphMs, e.activeLabel + agg_s)
    }
  }

  const timelineSyncId = 'timelineGraph' // this is used to sync both graph tooltips

  return (
    <>
      {fullscreenGraph && (
        <Modal
          id="fullscreen-timeline-graphs"
          className={TimelineStyles.graphModal}
          header={<>{`${fullscreenGraph} / Minute`}</>}
          size="large"
          onClose={() => setFullscreenGraph(undefined)}
          okText="Analytics"
          cancelText="Close"
          modalBodyClassName={TimelineStyles.graphModalBody}
        >
          {fullscreenGraph === 'Yield' && (
            <YieldGraph
              yieldSeries={itemSeriesGapsFilled}
              chartHeight="100%"
              yieldThreshold={yieldThreshold || 0}
              mode="inspection"
            />
          )}

          {fullscreenGraph === 'Items' && (
            <CountGraph
              graphSeries={itemSeriesGapsFilled}
              mode="inspection"
              chartHeight="100%"
              upmThreshold={maxUpm}
              cartesianGrid
            />
          )}
        </Modal>
      )}

      <div className={TimelineStyles.graphsColumnWrapper}>
        <div className={TimelineStyles.graphSection}>
          <div className={TimelineStyles.yieldGraphHeader}>
            <span className={TimelineStyles.graphTitle}>Yield / Minute</span>
            <div className={TimelineStyles.graphTitleRightSection}>
              {hasPass && <LiveFeed status="pass">Pass</LiveFeed>}

              {hasFail && <LiveFeed status="fail">Fail</LiveFeed>}

              {hasUnknown && <LiveFeed status="unknown">Unknown</LiveFeed>}
              <IconButton
                icon={<PrismExpandIcon />}
                size="xsmall"
                className={TimelineStyles.expandGraphIcon}
                type="tertiary"
                onClick={() => setFullscreenGraph('Yield')}
              />
            </div>
          </div>

          <div className={TimelineStyles.graphContainer}>
            <YieldGraph
              yieldSeries={itemSeriesGapsFilled}
              yieldThreshold={yieldThreshold}
              graphRef={yieldGraphGridRef}
              chartHeight="100%"
              syncId={timelineSyncId}
              onChartClick={handleClickGraph}
              mode="inspection"
            />

            <GraphHighlight
              graphGridElement={yieldGraphGridRef.current}
              itemSeries={itemSeriesGapsFilled}
              seriesAggS={agg_s}
              scrolledGroupTime={scrolledGroupTime}
              graphType="yield"
            />
          </div>
        </div>
        <div className={TimelineStyles.graphSection}>
          <div className={TimelineStyles.yieldGraphHeader}>
            <span className={TimelineStyles.graphTitle}>Items / Minute</span>
            <div className={TimelineStyles.graphTitleRightSection}>
              <IconButton
                icon={<PrismExpandIcon />}
                size="xsmall"
                className={TimelineStyles.expandGraphIcon}
                type="tertiary"
                onClick={() => setFullscreenGraph('Items')}
              />
            </div>
          </div>

          <div className={TimelineStyles.graphContainer}>
            <CountGraph
              graphSeries={itemSeriesGapsFilled}
              syncId={timelineSyncId}
              onChartClick={handleClickGraph}
              mode="inspection"
              chartHeight="100%"
              upmThreshold={maxUpm}
              graphRef={countsGraphGridRef}
            />

            <GraphHighlight
              graphGridElement={countsGraphGridRef.current}
              itemSeries={itemSeriesGapsFilled}
              seriesAggS={agg_s}
              scrolledGroupTime={scrolledGroupTime}
              graphType="count"
            />
          </div>
        </div>
      </div>
    </>
  )
}

interface GraphHightlightProps {
  graphGridElement: CartesianGrid | null
  itemSeries: GraphSeries[]
  scrolledGroupTime: number
  seriesAggS: number
  graphType: 'yield' | 'count'
}

/**
 * Renders a div over a graph to highlight the time to which the timeline is scrolled to.
 */
function GraphHighlight({
  graphGridElement,
  itemSeries,
  scrolledGroupTime,
  seriesAggS,
  graphType,
}: GraphHightlightProps) {
  const [graphOverlay, setGraphOverlay] = useState<{
    top: number
    left: number
    height: number
    width: number
    edgePositionClassName?: string
  }>()

  useEffect(() => {
    // When data  changes, calculate highlight box positions
    if (!itemSeries.length || !graphGridElement) return
    const graphOrigin = 16
    const graphStart = graphGridElement.props.x || 0
    const graphHeight = graphGridElement.props.height || 0
    const graphY = graphGridElement.props.y || 0
    let chartBarWidth = (graphGridElement.props.width || 0) / (itemSeries.length - (graphType === 'count' ? 1 : 0) || 1)

    // We're using the points as an anchor to center the overlay and when only 2 points are visible the best solution is to get only half of the width
    if (graphType === 'count' && itemSeries.length === 2) chartBarWidth = chartBarWidth / 2

    const itemIndex = itemSeries.findIndex(s => scrolledGroupTime >= s.ts && scrolledGroupTime < s.ts + seriesAggS)
    const xStart = graphStart + chartBarWidth * (itemIndex > -1 ? itemIndex : itemSeries.length - 1) + graphOrigin

    // Special classes to be added to the count graph, when it's positioned in the first or the last points
    let edgePointClassName
    if (graphType === 'count' && itemSeries.length > 1) {
      if (itemIndex === 0) edgePointClassName = TimelineStyles.firstPointPosition
      if (itemIndex === itemSeries.length - 1) edgePointClassName = TimelineStyles.lastPointPosition
    }

    setGraphOverlay({
      top: graphY - 1,
      height: graphHeight,
      left: xStart,
      width: chartBarWidth,
      edgePositionClassName: edgePointClassName || '',
    })
  }, [itemSeries, scrolledGroupTime]) // eslint-disable-line

  if (!graphOverlay) return null

  return (
    <div
      className={`${TimelineStyles.graphOverlay} ${
        graphType === 'yield' || itemSeries.length === 1
          ? TimelineStyles.graphYieldOverlay
          : TimelineStyles.graphCountOverlay
      } ${graphOverlay.edgePositionClassName} ${
        graphType === 'count' && itemSeries.length === 2 ? TimelineStyles.countGraphWithTwoPoints : ''
      }`}
      style={graphOverlay}
    />
  )
}
