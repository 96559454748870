/**
 * Paths used in router and in links. SSOT allows us to ensures all links point
 * to correct paths, and makes changing paths trivial. The only place we can't
 * rely on SSOT for paths is in components link Navbar, which perform partial
 * matches on paths.
 */

import qs from 'qs'

import { RoutineSettingsTabs } from 'pages/RoutineOverview/RoutineSettings/RoutineSettings'
import {
  AccountSettingsMode,
  AdministrationSettingsMode,
  AnalyzeTab,
  InspectTabs,
  LabelingScreenMode,
  NotifyMode,
  RecipeOverviewMode,
  StationDetailMode,
  subscriptionTabsType,
} from 'types'
import { getLocationMinifiedOrgId } from 'utils'

export const nonOrgPaths = {
  login: () => '/login',
  loginRockwell: () => '/login/rockwell',
  acceptInvite: (orgId: string, userId: string) => `/invitation/${orgId}/${userId}`,
  resetPassword: (userId: string, token: string) => `/password/reset/${userId}/${token}`,
  resetPasswordEmail: () => '/reset_password',
  activateUser: (token: string) => `/activate/${token}`,
  notFound: () => '/not-found',
  uiPlayground: () => '/playground',
}

const getPrefixedPath = (defaultPath: string) => {
  const minifiedOrgId = getLocationMinifiedOrgId()
  if (!minifiedOrgId) return defaultPath
  return `/o/${minifiedOrgId}${defaultPath}`
}

const paths = {
  root: () => getPrefixedPath('/'),
  activateUserOrg: (orgId: string) => `/o/${orgId}/activate`,
  inspect: ({ mode, params }: { mode?: InspectTabs | ':mode'; params?: {} }) => {
    return getPrefixedPath(`/inspect/${mode || 'site'}/${qs.stringify(params, { addQueryPrefix: true })}`)
  },
  stationDetail: (
    mode: StationDetailMode | ':mode',
    stationId?: string,
    params?: { historicInspectionId?: string; aoiId?: string },
  ) => {
    const querystring = qs.stringify(params, { arrayFormat: 'comma', encode: false, addQueryPrefix: true })
    return getPrefixedPath(`/station/${mode}${stationId ? `/${stationId}` : ''}${querystring}`)
  },
  plcControl: (stationId?: string) => getPrefixedPath(`/station/plc_control${stationId ? `/${stationId}` : ''}`),
  analyze: ({ mode, onlyPrefix, params }: { mode?: AnalyzeTab | ':mode'; onlyPrefix?: boolean; params?: {} }) => {
    if (onlyPrefix) return getPrefixedPath('/analyze/')
    return getPrefixedPath(`/analyze/${mode || 'overview'}${qs.stringify(params, { addQueryPrefix: true })}`)
  },
  administrationSettings: ({
    mode,
    onlyPrefix,
    params,
  }: {
    mode?: AdministrationSettingsMode | ':mode'
    onlyPrefix?: boolean
    params?: { role__in?: string; name_or_email?: string; tab?: 'active' | 'deleted' }
  }) => {
    if (onlyPrefix) return getPrefixedPath('/settings/')
    const querystring = qs.stringify(params, { arrayFormat: 'comma', encode: false, addQueryPrefix: true })
    return getPrefixedPath(`/settings/${mode || 'users'}${querystring}`)
  },
  accountSettings: ({ mode, onlyPrefix }: { mode?: AccountSettingsMode | ':mode'; onlyPrefix?: boolean }) => {
    if (onlyPrefix) return '/account/'
    return getPrefixedPath(`/account/${mode || 'profile'}`)
  },
  settingsNotify: ({
    mode,
    onlyPrefix,
    params,
  }: {
    mode?: NotifyMode | ':mode'
    onlyPrefix?: boolean
    params?: {
      tab?: subscriptionTabsType
    }
  }) => {
    if (onlyPrefix) return getPrefixedPath('/setup/notify/')
    const notifyMode: string = mode || 'subscriptions'
    const querystring = qs.stringify(params, { arrayFormat: 'comma', encode: false, addQueryPrefix: true })
    return getPrefixedPath(`/setup/notify/${notifyMode}${querystring}`)
  },
  settingsRecipe: (
    recipeParentId: string,
    mode: RecipeOverviewMode | ':mode',
    params?: {
      robotIds?: string[]
      routineParentId?: string
      toolParentId?: string
      reportState?: string
      settingsTab?: RoutineSettingsTabs
      reportModelId?: string
      recipeVersion?: string
      openDeployModal?: boolean
      showTrainingModal?: boolean
    },
  ) => {
    const querystring = qs.stringify(params, { arrayFormat: 'comma', encode: false, addQueryPrefix: true })
    return getPrefixedPath(`/setup/recipes/${recipeParentId}/${mode}${querystring}`)
  },
  settingsTool: (recipeId: string, toolId: string, params?: { routineParentId?: string; recipeVersion?: string }) => {
    const querystring = qs.stringify(params, { arrayFormat: 'comma', encode: false, addQueryPrefix: true })
    return getPrefixedPath(`/setup/recipes/${recipeId}/configure/${toolId}${querystring}`)
  },
  labelingScreen: (toolParentId: string, mode: LabelingScreenMode | ':mode', params?: {}) => {
    return getPrefixedPath(
      `/setup/label/${toolParentId}/${mode}${qs.stringify(params, {
        addQueryPrefix: true,
      })}`,
    )
  },
  cameraStatus: (robotId: string) => getPrefixedPath(`/camera_status/${robotId}`),
  newBatch: (stationId?: string) => getPrefixedPath(`/station/new_batch${stationId ? `/${stationId}` : ''}`),
}

export default paths
