import React, { useCallback, useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { useHistory } from 'react-router'

import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { PrismArrowIcon, PrismElementaryCube } from 'components/prismIcons'
import { OfflineTag } from 'components/Tag/Tag'
import { useConnectionStatus, useInspectionDurationRef, useRerenderRef, useStationStatus } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { Inspection, RoutineWithAois, Station } from 'types'
import { appendDataToQueryString, calculateOpacityByIndex } from 'utils'

import TimelineStyles from './StationDetailTimeline.module.scss'
import { TimelineBlankState } from './TimelineBlankState'
import { TimelineGraphs } from './TimelineGraphs'
import { TimelineList } from './TimelineList'

interface Props {
  inspection: Inspection | undefined
  routines: RoutineWithAois[] | undefined
  station: Station | undefined
  isHistoricBatch: boolean
}

/** Renders timeline screen, where a user can see realtime results being shown in a timeline and yield and count graphs
 * @param inspection - Inspection that is currently running
 * @param routines - The routine selected to start an inspection
 * @param station - The selected station
 * @param isHistoricBatch - whether we're looking at a historic batch
 */
export default function StationDetailTimeline({ inspection, station, routines, isHistoricBatch }: Props) {
  const history = useHistory()
  const { inspectionDurationMsRef } = useInspectionDurationRef(inspection)
  const [timelineContainer, setTimelineContainerRef] = useRerenderRef<HTMLDivElement>()
  const [graphPositionObserver, setGraphPositionObserver] = useState<IntersectionObserver>()
  const [cardAtTopTime, setCardAtTopTime] = useState<number>(0)
  const [graphClickedTime, setGraphClickedTime] = useState<number>()
  const [showNewUnitsButton, setShowNewUnitsButton] = useState(false)
  const [isLiveMode, setIsLiveMode] = useState(true)
  const liveModeWaitingForToolResult = useRef(false)

  const connectionStatus = useConnectionStatus()

  const stationStatus = useStationStatus(station)

  // defaultTimelineTimeS will be used on page load to place the user at a certain time in the timeline
  const appendDefaultTimelineTimeMs = useCallback(
    (timeMs: number | undefined) => {
      appendDataToQueryString(history, { defaultTimelineTimeS: timeMs })
    },
    [history],
  )

  /** This effect creates the IntersectionObserver and handler for setting the chart highlight's position */
  useEffect(() => {
    const options = {
      root: timelineContainer,
      rootMargin: '0px 0px -100%', // Only scans in the very top of the container
      threshold: 0,
    }

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        // Only 1 entry should be intersecting: the card at the top
        if (entry.isIntersecting) {
          setCardAtTopTime(moment((entry.target as HTMLElement).dataset.time).unix())
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    setGraphPositionObserver(observer)

    return () => observer.disconnect()
  }, [timelineContainer])

  /** Scroll user to the top and enable preppending results from WS */
  const handleClickLoadNewResults = useCallback(() => {
    setIsLiveMode(true)
    setShowNewUnitsButton(false)
  }, [setShowNewUnitsButton])

  // This effect will hide the "New Items" button if it is active
  // and we are seeing an historic batch
  useEffect(() => {
    if (isHistoricBatch && showNewUnitsButton) {
      setShowNewUnitsButton(false)
    }
  }, [isHistoricBatch, showNewUnitsButton])

  const handleClickGraph = useCallback(
    async (graphMs: number, time: number) => {
      setGraphClickedTime(time)
      appendDefaultTimelineTimeMs(time)
      setCardAtTopTime(graphMs)
    },
    [appendDefaultTimelineTimeMs],
  )

  const renderTimelineContent = () => {
    if (connectionStatus === 'offline' && stationStatus === 'running')
      return (
        <div className={TimelineStyles.mainBody}>
          <OfflineGraphSection />
          <Divider type="vertical" className={TimelineStyles.divider} />
          <OfflineTimelineSection />
        </div>
      )

    const toRender = []
    if (!inspection || !routines) toRender.push(<TimelineBlankState />)
    else
      toRender.push(
        <div
          className={`${TimelineStyles.mainBody} ${
            connectionStatus === 'recovering' ? TimelineStyles.reconnectionCoat : ''
          }`}
        >
          <TimelineGraphs
            inspection={inspection}
            scrolledGroupTime={cardAtTopTime}
            onGraphClick={handleClickGraph}
            inspectionDurationMs={inspectionDurationMsRef.current}
            isHistoricBatch={isHistoricBatch}
          />

          <Divider type="vertical" className={TimelineStyles.divider} />

          <div className={TimelineStyles.timelineContainer}>
            {showNewUnitsButton && (
              <div className={TimelineStyles.loadNewResultsButtonContainer}>
                <Button
                  className={TimelineStyles.loadNewResultsButton}
                  type="secondary"
                  onClick={handleClickLoadNewResults}
                  wide
                  isOnTop
                  badge={<PrismArrowIcon direction="up" />}
                  invertBadgePosition
                >
                  <span>new items</span>
                </Button>
              </div>
            )}

            <div className={TimelineStyles.timelineResults} ref={setTimelineContainerRef}>
              <div className={TimelineStyles.timeline} data-testid="timeline-card-container">
                {graphPositionObserver && (
                  <>
                    <TimelineList
                      inspection={inspection}
                      graphPositionObserver={graphPositionObserver}
                      height={timelineContainer?.offsetHeight || 0}
                      width={timelineContainer?.offsetWidth || 0}
                      isHistoricBatch={isHistoricBatch}
                      routines={routines}
                      graphClickedTime={graphClickedTime}
                      setGraphClickedTime={setGraphClickedTime}
                      setShowNewUnitsButton={setShowNewUnitsButton}
                      setIsLiveMode={setIsLiveMode}
                      isLiveMode={isLiveMode}
                      liveModeWaitingForToolResult={liveModeWaitingForToolResult}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>,
      )

    return toRender
  }

  return <>{renderTimelineContent()}</>
}

/**
 * Renders an offline mode placeholder section for the graphs shown.
 */
const OfflineGraphSection = () => (
  <div className={`${TimelineStyles.offlineGraphSection} ${Shared.verticalChildrenGap32}`}>
    {Array(2)
      .fill(undefined)
      .map((_, i) => (
        <div key={i} className={TimelineStyles.offlineGraphContainer}>
          <div className={TimelineStyles.offlineGraphTitleContainer}>
            <div className={TimelineStyles.graphTitle}>{i === 0 ? 'yield / minute' : 'items / minute'}</div>
            <OfflineTag />
          </div>
        </div>
      ))}
  </div>
)

/**
 * Renders an offline mode placeholder section for the timeline section.
 */
const OfflineTimelineSection = () => (
  <div className={TimelineStyles.offlineTimelineSection}>
    <Divider type="vertical" className={TimelineStyles.offlineDivider} />

    {Array(4)
      .fill(undefined)
      .map((_, index) => (
        <div
          className={TimelineStyles.offlineCardContainer}
          style={{ opacity: calculateOpacityByIndex(index) }}
          key={index}
        >
          <div className={TimelineStyles.offlineTimestampContainer}>
            <div className={TimelineStyles.offlineTimestamp} />
            {index === 0 && <div className={TimelineStyles.offlineTimestamp} />}
          </div>
          <div className={TimelineStyles.offlineCardItem}>
            <PrismElementaryCube className={TimelineStyles.offlineCardCube} />
            <div className={TimelineStyles.offlineCardBox} />
            <div className={TimelineStyles.offlineCardBoxRight} />
          </div>
        </div>
      ))}
  </div>
)
