import React from 'react'

import { capitalize } from 'lodash'
import { useDispatch } from 'react-redux'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import { PrismWarningIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { EntityType } from 'types'

import Styles from '../Inspect.module.scss'

interface ArchiveSlsProps {
  entityType: EntityType
  entityId: string
  onOk?: () => Promise<void> | void
  onClose: () => void
}

const updateEntityMapper: Record<
  EntityType,
  (id: string, body: Record<string, unknown>) => Promise<SendToApiResponse<unknown>>
> = {
  site: service.updateSite,
  line: service.updateSubSite,
  station: service.updateStation,
  product: service.updateComponent,
  recipe: service.updateRecipeParent,
}

const ArchiveEntityModal = ({ entityType, entityId, onOk, onClose }: ArchiveSlsProps) => {
  const dispatch = useDispatch()
  async function handleOk() {
    const res = await updateEntityMapper[entityType](entityId, {
      is_deleted: true,
      ...(entityType === 'station' ? { ordering: null } : {}),
    })
    if (res.type !== 'success') {
      onClose()
      return error({ title: `There was an issue archiving the ${entityType}, try again` })
    }

    success({
      title: `${capitalize(entityType)} archived`,
      'data-testid': `archive-${entityType}-success-notification`,
    })
    await query(getterKeys.sites(), service.getSites, { dispatch })
    await onOk?.()
    onClose()
  }

  return (
    <Modal
      id="archive-entity-modal"
      data-testid={`archive-${entityType}-modal`}
      size="small"
      header={
        <div className={Styles.modalHeader}>
          <PrismWarningIcon isActive /> <span>Archive {entityType}?</span>
        </div>
      }
      onOk={handleOk}
      okText="Archive"
      onClose={onClose}
      danger
      hideCross
    >
      {getEntityArchiveMessage(entityType)}
    </Modal>
  )
}

export default ArchiveEntityModal

const getEntityArchiveMessage = (entityType: EntityType) => {
  return `Are you sure? You won't be able to use this ${entityType} as long as it's archived. You can unarchive at any time.`
}
