import React, { useEffect, useRef, useState } from 'react'

import Styles from './BlankState.module.scss'

const DASHES_WIDTH = 42

/** Renders an empty graph with dashes in it's x and y axis. Automatically determines width of container
 * and draws appropriate number of labels
 * @param className - Class name to be applied to the container
 * @param rows - the number of rows to display
 */
export const GraphBlankState = ({
  className,
  rowsClassName,
  bottomClassName,
  rows = 5,
}: {
  className?: string
  rowsClassName?: string
  bottomClassName?: string
  rows?: number
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [numberOfDashes, setNumberOfDashes] = useState(0)

  useEffect(() => {
    const { current } = containerRef
    const drawDashes = () => {
      if (!current) return
      setNumberOfDashes(Math.floor(current.clientWidth / DASHES_WIDTH))
    }

    // Draw dashes after first render, then when window is resized
    drawDashes()

    const resizeObserver = new ResizeObserver(() => {
      drawDashes()
    })

    if (current) resizeObserver.observe(current)

    return () => {
      if (current) resizeObserver.unobserve(current)
    }
  }, [])

  return (
    <div className={`${Styles.graphContainer} ${className ?? ''}`} ref={containerRef}>
      <div className={`${Styles.rowsContainer} ${rowsClassName ?? ''}`}>
        {Array(rows)
          .fill(undefined)
          .map((_, i) => (
            <LineAndCircles key={i} />
          ))}
      </div>
      <div className={`${Styles.dashesBottom} ${bottomClassName ?? ''}`}>
        {
          // Displays dashes necessary to fill container's width
          [...Array(numberOfDashes)].map((e, i) => (
            <Dashes key={i} />
          ))
        }
      </div>
    </div>
  )
}

const LineAndCircles = () => {
  return (
    <div className={Styles.lineContainer}>
      <div className={Styles.dashes}>--</div>
      <div className={Styles.lineAndCircles}>
        <div className={Styles.circle}></div>
        <div className={Styles.line}></div>
        <div className={Styles.circle}></div>
      </div>
    </div>
  )
}

const Dashes = () => {
  return <div className={Styles.dashes}>--</div>
}
