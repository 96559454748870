import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState = {
  status: 'online' as const,
}

export default function reduce(
  state: RootState['connectionStatus'] = initialState,
  action: Action,
): RootState['connectionStatus'] {
  if (action.type === 'CONNECTION_UPDATE') {
    const { status } = action.payload
    return { status }
  }
  return state
}
