import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react'

import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'
import { useHistory } from 'react-router-dom'
import { AnyAction, Dispatch } from 'redux'

import { getterKeys, service, wsPaths } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { error, info } from 'components/PrismMessage/PrismMessage'
import { info as infoNotification } from 'components/PrismNotification/PrismNotification'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import StreamListener from 'components/StreamListener'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useData, useDateTimePreferences, useQueryParams } from 'hooks'
import * as Actions from 'rdx/actions'
import { QsFilters, StreamMessage, ToolResult, ToolSpecificationName } from 'types'
import {
  appendDataToQueryString,
  calculatePercentage,
  convertPredictionScoreToBackendQueryParams,
  getLabelingScreenFiltersBranch,
  ObjectWithCreatedAt,
  sortByNewestFirst,
  sortByOldestFirst,
} from 'utils'
import { TOOLS_WITH_SCORE_INVERTED } from 'utils/constants'

import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'
import SmartGroupCarousel from './SmartGroupCarousel'

const SMART_GROUP_TOOL_RESULTS_BATCH_SIZE = 100

export type SmartGroupToolResult = {
  uuid: string
  prediction_outcome?: string
  labeled_outcome?: string
  inspection_id?: string
  created_at?: string
  user_label_ids?: string[]
  prediction_label_ids?: string[]
  prediction_score?: number
}

export type SmartGroup = {
  type?: 'pass' | 'fail' | 'class' | 'misc'
  results: SmartGroupToolResult[]
}

interface SmartGroupsProps {
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  showInsights: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  generatingSmartGroups: boolean
  setGeneratingSmartGroups: React.Dispatch<React.SetStateAction<boolean>>
  setDisableSmartGroupsButton: (disabled: boolean) => any
  galleryWrapperRef: RefObject<HTMLDivElement>
}

/**
 * Smart groups container, which is responsible for fetching smart groups, subscribe for updates and rendering a carousel for each smart group.
 *
 * @param toolParentId - The current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param showInsights - Whether to show insights
 * @param selectedToolResults - The current selected toolResults
 * @param setSelectedToolResults - Function that sets the current selected toolResults
 * @param generatingSmartGroups - Whether the smart groups are being generated
 * @param setGeneratingSmartGroups - Function that sets the current generating smart groups state
 * @param setDisableSmartGroupsButton - Function to disable the smart groups button
 * @param galleryWrapperRef - A ref from the labeling gallery wrapper, used to setup performant pagination
 *
 */
const SmartGroups = ({
  toolParentId,
  toolSpecificationName,
  showInsights,
  selectedToolResults,
  setSelectedToolResults,
  generatingSmartGroups,
  setGeneratingSmartGroups,
  setDisableSmartGroupsButton,
  galleryWrapperRef,
}: SmartGroupsProps) => {
  const [params] = useQueryParams()
  const history = useHistory()
  const { groups_id } = params

  const [validatedGroup, setValidatedGroup] = useState(generatingSmartGroups)
  const [showLimitNotification, setShowLimitNotification] = useState(true)
  const [expandedGroupId, setExpandedGroupId] = useState<string | undefined>(
    params.smart_group_idx && params.expanded ? getSmartGroupId(+params.smart_group_idx, groups_id, params) : undefined,
  )
  const [smartGroupProgress, setSmartGroupProgress] = useState(0)
  const [maxIdxToShow, setMaxIdxToShow] = useState<number>()

  const smartGroups = useSmartGroups({
    parentId: toolParentId,
    groupsId: groups_id,
    preventFetch: generatingSmartGroups || !validatedGroup,
  })

  const resetSmartGroups = useCallback(() => {
    appendDataToQueryString(history, { group_by: undefined, groups_id: undefined })
    setGeneratingSmartGroups(false)
    setDisableSmartGroupsButton(false)
  }, [history, setDisableSmartGroupsButton, setGeneratingSmartGroups])

  useEffect(() => {
    if (!groups_id || smartGroups || generatingSmartGroups || validatedGroup) return
    const mGetFetch = async () => {
      const res = await service.atomMget([], {
        hash_key: `smart_groups_events:${toolParentId}`,
        command: 'hmget',
        redis: 'default',
      })
      if (res.type !== 'success') return
      const hashData = res.data as { [key: string]: any }
      if (!hashData[groups_id]) {
        appendDataToQueryString(history, { group_by: undefined, groups_id: undefined, smart_group_idx: undefined })
        return error({ title: 'These groups have expired, try smart grouping again.' })
      }

      const groupData = JSON.parse(hashData[groups_id])

      if (groupData.state === 'done') {
        setGeneratingSmartGroups(false)
      } else if (groupData.state === 'failed') {
        error({ title: 'Failed to generate smart groups.' })
        resetSmartGroups()
      } else {
        setGeneratingSmartGroups(true)
      }
      setValidatedGroup(true)
    }
    mGetFetch()
  }, [
    toolParentId,
    generatingSmartGroups,
    groups_id,
    history,
    setGeneratingSmartGroups,
    smartGroups,
    validatedGroup,
    resetSmartGroups,
  ])

  /*
    "complete" doesn’t mean that the grouping is finished, it means that all of the toolResults selected by filters passed by frontend were included in smart grouping.
    We only include up to 50000 results in smart groups, so if filters select >50000 results, complete will be False
  */
  const handleSmartGroupEvents = (messages: StreamMessage[]) => {
    messages.forEach(message => {
      const { data } = message.payload
      // Done and total refer to the number of embeddings already downloaded.
      const { state, complete, done, total } = data

      if (state === 'downloading') {
        setSmartGroupProgress(calculatePercentage(done, total))
      }

      if (state === 'failed') {
        error({ title: 'Failed to generate smart groups.' })
        resetSmartGroups()
        return
      }

      if (state === 'done') {
        setSmartGroupProgress(0)
        setGeneratingSmartGroups(false)
        if (showLimitNotification && !complete) {
          infoNotification({
            title: 'Smart Group Limit',
            description:
              'You can smart group up to 50,000 images. Try filtering before you smart group to include specific images.',
            position: 'top-right',
            duration: 0,
          })
          setShowLimitNotification(false)
        }
      }
    })
  }

  // In this case, cancelling only means that we stop waiting for the groups to be generated.
  const handleCancelGroupGeneration = () => {
    info({ title: 'Smart grouping canceled' })
    resetSmartGroups()
  }

  const handleShowGallery = (groupId?: string, groupIdx?: number) => {
    setExpandedGroupId(groupId)
    appendDataToQueryString(
      history,
      {
        smart_group_idx: groupId ? groupIdx : undefined,
        expanded: groupId ? true : undefined,
      },
      { selectedToolResults },
    )
  }

  return (
    <>
      {generatingSmartGroups && groups_id && (
        <StreamListener
          mode="message"
          connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.smartGroupsEvents(toolParentId, groups_id)}` }}
          onMessages={handleSmartGroupEvents}
          params={{ last_n: 1 }}
        />
      )}

      {generatingSmartGroups && (
        <GenericBlankStateMessage
          headerClassName={Styles.genericBlankStateHeader}
          header={<ProgressBar progress={smartGroupProgress} height="small" data-testid="smart-groups-progress-bar" />}
          description={
            smartGroupProgress < 100 ? (
              <>
                Analyzing images for <br /> visual similarity…
              </>
            ) : (
              <>
                Finalizing smart <br /> groups…
              </>
            )
          }
        >
          <Button
            type="secondary"
            size="small"
            onClick={handleCancelGroupGeneration}
            className={Styles.cancelButtonGap}
          >
            Cancel
          </Button>
        </GenericBlankStateMessage>
      )}

      {!generatingSmartGroups && (
        <div className={Styles.groupsContainerLayout}>
          {smartGroups
            ?.filter((_, idx) => maxIdxToShow === undefined || idx <= maxIdxToShow)
            .map((smartGroup, idx) => {
              const groupId = getSmartGroupId(idx, groups_id, params)
              return (
                <SmartGroupCarousel
                  key={groupId}
                  smartGroup={smartGroup}
                  toolSpecificationName={toolSpecificationName}
                  toolParentId={toolParentId}
                  showInsights={showInsights}
                  selectedToolResults={selectedToolResults}
                  setSelectedToolResults={setSelectedToolResults}
                  outerContainerRef={galleryWrapperRef}
                  smartGroupId={groupId}
                  groupIndex={idx}
                  handleShowGallery={handleShowGallery}
                  expandedGroupId={expandedGroupId}
                  onHideNextCarousels={shouldHide => {
                    if (shouldHide) setMaxIdxToShow(idx)
                    // We only want to reset the condition if the currently opened carousel is the one that has either concluded or closed
                    if (!shouldHide && idx === maxIdxToShow) setMaxIdxToShow(undefined)
                  }}
                  data-test="smart-groups-container"
                />
              )
            })}
        </div>
      )}
    </>
  )
}

export default SmartGroups

/**
 * Hook that wraps the logic to fetch smart groups
 *
 * @param parentId - the tool parent id
 * @param groupsId - the id for the smart group cluster
 * @param preventFetch - boolean used to prevent fetching
 * @returns smart groups array
 */
export const useSmartGroups = ({
  parentId,
  groupsId,
  preventFetch,
}: {
  parentId?: string
  groupsId?: string
  preventFetch?: boolean
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const smartGroupRes = useData(groupsId ? getterKeys.smartGroups(groupsId) : undefined)

  const smartGroups = useMemo(() => smartGroupRes?.groups, [smartGroupRes?.groups])

  useEffect(() => {
    if (!groupsId || smartGroupRes || !parentId || preventFetch) return

    const fetchSmartGroups = async () => {
      const res = await query(getterKeys.smartGroups(groupsId), () => service.getSmartGroups(parentId, groupsId), {
        dispatch,
      })

      if (res?.type === 'error' && res.status === 404) {
        appendDataToQueryString(history, { group_by: undefined, groups_id: undefined, smart_group_idx: undefined })
        return error({ title: 'These groups have expired, try smart grouping again.' })
      }

      if (res?.type !== 'success') return error({ title: 'An error occurred, please try again' })
    }
    fetchSmartGroups()
  }, [parentId, dispatch, groupsId, history, smartGroupRes, preventFetch])

  return smartGroups
}

/**
 * This hook returns SmartGroupToolResults filtered by query params filters.
 *
 * @param memoizedToolResults - the smart group toolResults, they should be memoized to avoid refiltering
 * @param toolSpecificationName - the tool type, used to know which filters should be applied
 * @param preventFiltering - boolean used to prevent filtering
 */
export const useFilteredSmartGroupToolResults = (
  memoizedToolResults: SmartGroupToolResult[],
  toolSpecificationName?: ToolSpecificationName,
  preventFiltering?: boolean,
) => {
  const { timeZone } = useDateTimePreferences()
  const [params] = useQueryParams()
  const {
    end,
    inspection_id,
    start,
    user_label_set_isnull,
    user_label_id__in,
    calculated_outcome__in,
    prediction_label_id__in,
    user_outcome,
    prediction_score_max,
    prediction_score_min,
    ordering,
  } = params as QsFilters
  const filteredGroupToolResults = useMemo(() => {
    // toolSpecificationName can be undefined if tool is not already fetched, we just return until it is defined
    if (preventFiltering || !toolSpecificationName) return

    const filteredToolResults = memoizedToolResults.filter(toolResult => {
      let includeInResults = true

      if (user_label_set_isnull === 'true' && toolResult.labeled_outcome) {
        includeInResults = false
      }

      if (user_outcome === 'unknown' && toolResult.labeled_outcome !== 'unknown') {
        includeInResults = false
      }

      if (user_label_id__in && !toolResult.user_label_ids?.includes(user_label_id__in)) {
        includeInResults = false
      }

      if (prediction_label_id__in && !toolResult.prediction_label_ids?.includes(prediction_label_id__in)) {
        includeInResults = false
      }

      if (prediction_score_min || prediction_score_max) {
        if (!toolResult.prediction_score) includeInResults = false
        else {
          const backendParams = convertPredictionScoreToBackendQueryParams({
            predictionScore: [
              prediction_score_min ? parseFloat(prediction_score_min) : null,
              prediction_score_max ? parseFloat(prediction_score_max) : null,
            ],
            toolSpecificationName,
          })

          if (
            toolResult.prediction_score < (backendParams.prediction_score_gte || 0) ||
            toolResult.prediction_score > (backendParams.prediction_score_lte || 0)
          )
            includeInResults = false
        }
      }

      const outcome = toolResult.labeled_outcome || toolResult.prediction_outcome
      if (calculated_outcome__in && outcome && !calculated_outcome__in.includes(outcome)) {
        includeInResults = false
      }

      if (inspection_id) {
        if (toolResult.inspection_id !== inspection_id) {
          includeInResults = false
        }
      }

      if (start && end) {
        const momentStart = moment(start).tz(timeZone)
        const momentEnd = moment(end).tz(timeZone)
        const momentCreatedAt = moment(toolResult.created_at)
        if (!momentCreatedAt.isBetween(momentStart, momentEnd)) {
          includeInResults = false
        }
      }

      return includeInResults
    })

    if (ordering === '-prediction_score') {
      filteredToolResults.sort(
        TOOLS_WITH_SCORE_INVERTED.includes(toolSpecificationName)
          ? sortByHighPredictionScoreFirst
          : sortByLowPredictionScoreFirst,
      )
    }

    if (ordering === 'prediction_score') {
      filteredToolResults.sort(
        TOOLS_WITH_SCORE_INVERTED.includes(toolSpecificationName)
          ? sortByLowPredictionScoreFirst
          : sortByHighPredictionScoreFirst,
      )
    }

    if (ordering === '-created_at') {
      filteredToolResults.sort((a, b) => {
        if (!a.created_at || !b.created_at) return 0
        return sortByNewestFirst(a as ObjectWithCreatedAt, b as ObjectWithCreatedAt)
      })
    }

    if (ordering === 'created_at') {
      filteredToolResults.sort((a, b) => {
        if (!a.created_at || !b.created_at) return 0
        return sortByOldestFirst(a as ObjectWithCreatedAt, b as ObjectWithCreatedAt)
      })
    }

    return filteredToolResults
  }, [
    preventFiltering,
    toolSpecificationName,
    memoizedToolResults,
    ordering,
    user_label_set_isnull,
    user_outcome,
    user_label_id__in,
    prediction_label_id__in,
    prediction_score_min,
    prediction_score_max,
    calculated_outcome__in,
    inspection_id,
    start,
    end,
    timeZone,
  ])

  return filteredGroupToolResults || []
}

const getSmartGroupsToolResultsOrderingParam = (
  toolSpecificationName: ToolSpecificationName,
  ordering: string | undefined,
) => {
  if (ordering) {
    if (TOOLS_WITH_SCORE_INVERTED.includes(toolSpecificationName)) {
      if (ordering === '-prediction_score') return 'prediction_score'
      if (ordering === 'prediction_score') return '-prediction_score'
    }

    return ordering
  }

  return '-created_at'
}

/**
 * Function in charge of fetching pages of full representations for the provided smart group toolResults
 * As this function checks for fetchedToolResults existence and its length to decide which page to fetch, this can be used for initial fetching and pagination.
 *
 * @param fetchedToolResults - Current fetched toolResults, used to define the page we need to fetch
 * @param filteredGroupToolResults - the filtered smart group toolResults
 * @param toolResultsBranch - the current toolResults branch
 * @param dispatch - the redux dispatch
 */
export const fetchSmartGroupToolResults = async ({
  toolSpecificationName,
  fetchedToolResults,
  filteredGroupToolResults,
  toolResultsBranch,
  dispatch,
  params,
}: {
  toolSpecificationName: ToolSpecificationName
  fetchedToolResults?: ToolResult[]
  filteredGroupToolResults: SmartGroupToolResult[]
  toolResultsBranch?: string
  dispatch: Dispatch<AnyAction>
  params: { [key: string]: string | undefined }
}) => {
  if (!toolResultsBranch) return
  let pointerStart = 0
  let pointerEnd = SMART_GROUP_TOOL_RESULTS_BATCH_SIZE
  if (fetchedToolResults) {
    pointerStart = fetchedToolResults.length
    pointerEnd = pointerStart + SMART_GROUP_TOOL_RESULTS_BATCH_SIZE
  }

  const sortingParams = {
    ordering: getSmartGroupsToolResultsOrderingParam(toolSpecificationName, params.ordering),
  }

  if (pointerStart > filteredGroupToolResults.length) return
  const toolResultsToFetch = filteredGroupToolResults.slice(pointerStart, pointerEnd)
  const res = await service.getToolResultList(
    toolResultsToFetch.map(c => c.uuid),
    { retry: { retries: 3, delay: 200 }, params: sortingParams },
  )

  if (res.type !== 'success') return

  dispatch(
    Actions.getterUpdate({
      key: getterKeys.toolParentToolResults(toolResultsBranch),
      updater: prevRes => {
        if (!prevRes) return res
        return { ...prevRes, data: { ...prevRes.data, results: [...prevRes.data.results, ...res.data.results] } }
      },
    }),
  )

  return res.data.results[0]
}

export const getSmartGroupId = (
  index: number,
  groupsId: string | undefined = '',
  params: { [key: string]: string | undefined },
) => {
  return `smart-group-${groupsId}-${index}-${getLabelingScreenFiltersBranch(params)}`
}

interface ObjectWithPredictionScore {
  prediction_score?: number | null
}

const sortByHighPredictionScoreFirst = (a: ObjectWithPredictionScore, b: ObjectWithPredictionScore) => {
  const predA = a.prediction_score || 0
  const predB = b.prediction_score || 0
  return predA - predB
}

const sortByLowPredictionScoreFirst = (a: ObjectWithPredictionScore, b: ObjectWithPredictionScore) => {
  const predA = a.prediction_score || 0
  const predB = b.prediction_score || 0
  return predB - predA
}
