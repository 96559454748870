import React from 'react'

import Styles from './PassCriteria.module.scss'

/**
 * Renders the Pass Criteria container
 *
 *
 * @param title - holds the title of the element
 * @param children - receives a description with some highlighted text
 *
 */
const PassCriteriaContainer = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className={Styles.passCriteriaContainer}>
      <div className={Styles.passCriteriaTitle}>{title}</div>
      <div className={Styles.passCriteriaDescription}>{children}</div>
    </div>
  )
}

export default PassCriteriaContainer
