import React, { useEffect, useMemo, useRef } from 'react'

import { groupBy } from 'lodash'

import { getterKeys, service, useQuery } from 'api'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismGraphProgressBar } from 'components/PrismGraphProgressBar/PrismGraphProgressBar'
import { PrismGraphWrapper } from 'components/PrismGraphWrapper/PrismGraphWrapper'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useAllToolLabels, useAnalyticsQueryFilters, useAnalyzeItemMetrics, useLabelMetrics } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import { AnalyzeDefect, ProductWithFailYield, StationWithFailYield } from 'types'
import {
  calculatePercentage,
  combineOutcomeCounts,
  getDisplaySeverity,
  getDisplayStationLocation,
  getLabelName,
  renderLargeNumber,
  seriesFillGapsRtsDatePeriod,
  titleCase,
  truncateSeriesTimestampsRtsDatePeriod,
} from 'utils'

import Styles from './Analyze.module.scss'
import { ANALYZE_VERTICAL_PADDING, AnalyzeWithGalleryProps, dummyData, getAnalyzeDefects } from './AnalyzeBase'
import AnalyzeFilters from './AnalyzeFilters'
import { AnalyzeGallery } from './AnalyzeGallery'

// Renders the graph grid container, this container could live in it's own file. This component is being used in the overview and products tab.
export const AnalyzeOverview = ({
  isGalleryExpanded,
  setIsGalleryExpanded,
  setPdfData,
  setGalleryTransitionInProgress,
  galleryTransitionInProgress,
}: AnalyzeWithGalleryProps) => {
  const [filters] = useAnalyticsQueryFilters({ tab: 'overview' })

  const { allToolLabels } = useAllToolLabels()

  const { period, end, start, subsite_type_id } = filters
  const { itemMetrics, isFetching: isFetchingMetrics } = useAnalyzeItemMetrics('overview')

  const { labelMetrics, isFetchingLabelMetrics } = useLabelMetrics({
    paramFilters: { ...filters, subsite_type_id },
  })

  const { mostDefectiveStationIdsWithYield, mostDefectiveProductIdsWithYield } = useMemo(() => {
    const groupedByStationId = groupBy(
      itemMetrics?.filter(result => result.labels.station_id),
      result => result.labels.station_id,
    )

    const groupedByProductId = groupBy(
      itemMetrics?.filter(result => result.labels.component_id),
      result => result.labels.component_id,
    )

    const mostDefectiveStationIdsWithYield = Object.entries(groupedByStationId)
      // We need to filter grouped stations that are indexed by None, otherwse Django responds with an 'invalid Uuid' error
      .filter(([stationId]) => stationId !== 'None')
      .map(([stationId, results]) => {
        const combinedSeries = combineOutcomeCounts(results)

        let count = 0
        let failCount = 0

        for (const item of combinedSeries) {
          count += item.count || 0
          failCount += item.fail || 0
        }

        const failYield = calculatePercentage(failCount, count)

        return {
          stationId,
          failYield,
          failCount,
        }
      })
      .sort((a, b) => b.failYield - a.failYield)

    const mostDefectiveProductIdsWithYield = Object.entries(groupedByProductId)
      .filter(([productId]) => productId !== 'None')
      .map(([productId, results]) => {
        const combinedSeries = combineOutcomeCounts(results)

        let count = 0
        let failCount = 0

        for (const item of combinedSeries) {
          count += item.count || 0
          failCount += item.fail || 0
        }

        const failYield = calculatePercentage(failCount, count)

        return {
          productId,
          failYield,
          failCount,
        }
      })
      .sort((a, b) => b.failYield - a.failYield)

    return { mostDefectiveStationIdsWithYield, mostDefectiveProductIdsWithYield }
  }, [itemMetrics])
  const failedStationsRes = useQuery(getterKeys.stations('all-with-robots'), () =>
    service.getStations({ has_robots: true }),
  )
  const componentsRes = useQuery(getterKeys.components('analyze'), () => service.getComponents())

  const failedStationsWithFailYield: StationWithFailYield[] | undefined = useMemo(() => {
    const stations = failedStationsRes.data?.data.results

    return stations
      ?.map(station => {
        const stationIdWithYield = mostDefectiveStationIdsWithYield.find(grp => grp.stationId === station.id)

        return {
          ...station,
          failYield: stationIdWithYield?.failYield,
          failCount: stationIdWithYield?.failCount,
        }
      })
      .filter(station => station.failYield)
      .sort((a, b) => b.failYield! - a.failYield!)
  }, [failedStationsRes, mostDefectiveStationIdsWithYield])

  const failedProductsWithFailYield: ProductWithFailYield[] | undefined = useMemo(() => {
    const products = componentsRes.data?.data.results

    return products
      ?.map(product => {
        const productIdWithYield = mostDefectiveProductIdsWithYield.find(grp => grp.productId === product.id)
        return {
          ...product,
          failYield: productIdWithYield?.failYield,
          failCount: productIdWithYield?.failCount,
        }
      })
      .filter(product => product.failYield)
      .sort((a, b) => b.failYield! - a.failYield!)
  }, [componentsRes, mostDefectiveProductIdsWithYield])

  const combinedItemMetrics = useMemo(() => {
    if (!itemMetrics) return

    const typedPeriod = period === '30m' ? 'minute' : period || 'day'
    const numPeriods = period === '30m' ? 30 : 1

    const truncatedResults = itemMetrics.map(result => ({
      ...result,
      entries: truncateSeriesTimestampsRtsDatePeriod(result.entries, typedPeriod),
    }))
    const combinedCounts = combineOutcomeCounts(truncatedResults)

    const filledSeries = seriesFillGapsRtsDatePeriod(combinedCounts, typedPeriod, {
      end,
      start,
      numPeriods,
    })

    return filledSeries.map(data => ({
      ...data,
      passYield: calculatePercentage(data.pass, data.count),
      failYield: calculatePercentage(data.fail, data.count),
      unknownYield: calculatePercentage(data.unknown, data.count),
    }))
  }, [end, itemMetrics, period, start])

  const [totalYield, totalCount] = useMemo(() => {
    if (!combinedItemMetrics) return [0, 0]

    let count = 0
    let passed = 0

    for (const item of combinedItemMetrics) {
      count += item.count || 0
      passed += item.pass || 0
    }

    const curYield = calculatePercentage(passed, count)

    return [curYield, count]
  }, [combinedItemMetrics])

  const isLoading = useMemo(() => !combinedItemMetrics || isFetchingMetrics, [combinedItemMetrics, isFetchingMetrics])

  const mostCommonDefects: AnalyzeDefect[] = useMemo(() => {
    const groupedLabelMetricsByLabelId = groupBy(labelMetrics, result => result.labels.tool_label_id)

    return getAnalyzeDefects({ groupedLabelMetricsByLabelId, allToolLabels, count: totalCount })
  }, [allToolLabels, labelMetrics, totalCount])

  // This effect sets the chart data for the PDF
  useEffect(() => {
    if (!combinedItemMetrics || !failedProductsWithFailYield || !failedStationsWithFailYield || !mostCommonDefects)
      setPdfData(undefined)
    else
      setPdfData({
        type: 'charts',
        chartContent: {
          yield: {
            value: totalYield,
            data: combinedItemMetrics,
          },
          count: {
            value: totalCount,
            data: combinedItemMetrics,
          },
          productsWithFailYield: {
            data: failedProductsWithFailYield,
          },
          stationsWithFailYield: {
            data: failedStationsWithFailYield,
          },
          mostCommonDefects: {
            data: mostCommonDefects,
          },
        },
      })
  }, [
    combinedItemMetrics,
    failedProductsWithFailYield,
    failedStationsWithFailYield,
    mostCommonDefects,
    setPdfData,
    totalCount,
    totalYield,
  ])

  const renderDefectiveStations = (hidden?: boolean) => {
    return (
      <PrismGraphWrapper
        graphName="Most Defective Stations"
        graphCaption="% of items with defect per station"
        isLoading={!failedStationsWithFailYield || isFetchingMetrics}
      >
        <ConditionalWrapper
          condition={!!hidden}
          wrapper={ch => (
            <div id="pdf-generation-defective-stations" className={Styles.hiddenDiv}>
              {ch}
            </div>
          )}
        >
          <div className={!!hidden ? Styles.pdfGraphProgressContainer : Styles.graphBodyContainer}>
            {failedStationsWithFailYield?.map(station => (
              <PrismGraphProgressBar
                key={station.id}
                type="fail"
                graphName={station.name}
                tooltipSubtitle={getDisplayStationLocation(station.site_name, station.belongs_to?.name)}
                graphPercentage={station.failYield?.toFixed(1) || 0}
                graphCount={station.failCount || 0}
                isPdf={!!hidden}
              />
            ))}

            {(!failedStationsWithFailYield || failedStationsWithFailYield.length === 0) && (
              <div className={Styles.graphContainerEmptyState}>No stations match your filters</div>
            )}
          </div>
        </ConditionalWrapper>
      </PrismGraphWrapper>
    )
  }

  const canDisplayYield = combinedItemMetrics?.some(itemMetricsCount => itemMetricsCount.count)
  const parentContainerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)

  return (
    <section className={Styles.analyzeMain} ref={parentContainerRef}>
      <PrismContainer
        containerStyle={{ paddingTop: ANALYZE_VERTICAL_PADDING }}
        titleRef={titleRef}
        title="Overview"
        className={Styles.analyzeMainBody}
        headerActionsClassName={Styles.headerTitle}
        actions={<AnalyzeFilters tab="overview" data-testid="analyze-overview-filters" />}
        data-testid="analyze-overview-container"
      >
        <div
          className={`${Styles.analyzeGridContainer} ${isGalleryExpanded ? Styles.galleryIsExpanded : ''}`}
          data-testid="analyze-overview-graphs-container"
        >
          <PrismGraphWrapper
            graphName="Yield"
            graphValue={canDisplayYield ? `${totalYield.toFixed(1)}%` : '0.0%'}
            isLoading={isLoading}
            className={canDisplayYield ? '' : Styles.forceHideTooltip}
            data-testid="analyze-overview-yield"
          >
            <YieldGraph
              chartWidth="98%"
              chartHeight={112}
              yieldSeries={!combinedItemMetrics?.length ? dummyData({ start, end }) : combinedItemMetrics}
              start={start}
              end={end}
              period={period}
              syncId="overview"
              mode="metrics"
              allowEscapeViewBox={{ y: true }}
            />
          </PrismGraphWrapper>

          <PrismGraphWrapper
            graphName="Items Inspected"
            graphValue={totalCount !== 0 ? renderLargeNumber(totalCount, 1000) : '0'}
            isLoading={!combinedItemMetrics || isFetchingMetrics}
            className={totalCount !== 0 ? '' : `${Styles.forceHideTooltip} ${Styles.forceZeroPosition}`}
            data-testid="analyze-overview-items-inspected"
          >
            <CountGraph
              chartWidth="98%"
              chartHeight={112}
              graphSeries={!combinedItemMetrics?.length ? dummyData({ start, end }) : combinedItemMetrics}
              mode="metrics"
              start={start}
              end={end}
              period={period}
              syncId="overview"
              allowEscapeViewBox={{ y: true }}
            />
          </PrismGraphWrapper>

          <PrismGraphWrapper
            graphName="Most Common Defects"
            graphCaption="% of items with defect"
            isLoading={!failedProductsWithFailYield || isFetchingLabelMetrics}
          >
            <div className={Styles.graphBodyContainer}>
              {mostCommonDefects?.map(defect => (
                <PrismGraphProgressBar
                  key={defect.toolLabel.id}
                  type="fail"
                  graphName={
                    <PrismResultButton
                      severity={getDisplaySeverity(defect.toolLabel)}
                      value={getLabelName(defect.toolLabel)}
                      type="pureWhite"
                      className={Styles.defectItemTitle}
                    />
                  }
                  toolTipTitle={titleCase(getLabelName(defect.toolLabel))}
                  graphPercentage={defect.percentage?.toFixed(1) || 0}
                  graphCount={defect.defectCount}
                  data-testid={`common-defects-${defect.toolLabel.value}-bar`}
                />
              ))}

              {(!mostCommonDefects || mostCommonDefects.length === 0) && (
                <div data-testid="analyze-overview-empty-common-defects" className={Styles.graphContainerEmptyState}>
                  No defects match your filters
                </div>
              )}
            </div>
          </PrismGraphWrapper>

          <PrismGraphWrapper
            graphName="Most Defective Products"
            graphCaption="% of items with defect per product"
            isLoading={!failedProductsWithFailYield || isFetchingMetrics}
          >
            <div className={Styles.graphBodyContainer}>
              {failedProductsWithFailYield?.map(product => (
                <PrismGraphProgressBar
                  key={product.id}
                  type="fail"
                  graphName={product.name}
                  graphPercentage={product.failYield?.toFixed(1) || 0}
                  graphCount={product.failCount || 0}
                  data-testid={`defective-product-${product.name}-bar`}
                />
              ))}

              {(!failedProductsWithFailYield || failedProductsWithFailYield.length === 0) && (
                <div
                  data-testid="analyze-overview-empty-defective-products"
                  className={Styles.graphContainerEmptyState}
                >
                  No products match your filters
                </div>
              )}
            </div>
          </PrismGraphWrapper>

          {renderDefectiveStations()}
        </div>
      </PrismContainer>
      <AnalyzeGallery
        isGalleryExpanded={isGalleryExpanded}
        setIsGalleryExpanded={setIsGalleryExpanded}
        tab={'overview'}
        parentContainerRef={parentContainerRef}
        titleRef={titleRef}
        setTransitionInProgress={setGalleryTransitionInProgress}
        transitionInProgress={galleryTransitionInProgress}
      />
    </section>
  )
}
