import React from 'react'

import Styles from './PrismLoaders.module.scss'

const barsSizeClasses = { small: Styles.small, medium: Styles.medium }

const skeletonSizeClasses = {
  small: Styles.small,
  medium: Styles.medium,
  default: Styles.default,
  large: Styles.large,
  extraLarge: Styles.extraLarge,
}

const skeletonShapeClasses = { square: Styles.square, circle: Styles.circle }

/**
 * How to choose a loader guide:
 * Use Prism Loader to indicate loading in:
 *     Large video feeds (main video feed in inspection screen, etc)
 *     Large images (reference image in tool configure, big image in labeling, etc)
 *     List and chart containers (list of products / routines in Create, charts in Analyze, etc)
 *     Loading messages (Starting batch, Stopping batch, etc)
 *
 * Use Prism Skeleton to indicate loading in:
 *     Small video feeds (like those in station list and camera select modal)
 *     Small image thumbnails (like those in the labeling queue or labeling gallery)
 *     Small text or number values (in the Deploy table, Unlabeled count in labeling, etc)
 *
 * Keep using existing circular spinner to indicate loading in:
 *     Deploying “v5” in Deploy screen
 *
 * Extra ImageWithBoxes, ImgFallback, ImageCloseUp uses a loaderType prop to choose between bars or skeleton mode.
 */

/**
 * Renders the Prism "Block" Loader
 * This is a variation from the original design.
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1350%3A3351
 *
 * @param fullScreen - position the loader in the center of the screen based on the viewport size, if it's not used the default position will be the center of the parent's size.
 * @param withBackground - adds a small container around the loader with background color of $smokeyBlack_100 (#1d1d1d).
 * @param className - string class for the element's container
 * @param size - small size added for xsmall IconButtons
 */
export const PrismLoader = ({
  fullScreen,
  withBackground,
  className,
  size = 'medium',
  dataTestId,
}: {
  fullScreen?: boolean
  withBackground?: boolean
  className?: string
  size?: 'small' | 'medium'
  dataTestId?: string
}) => {
  const sizeClass = barsSizeClasses[size]
  return (
    <div
      data-testid={dataTestId}
      className={`${Styles.loaderWrapper} ${fullScreen ? Styles.fullScreen : Styles.component} ${className ?? ''}`}
    >
      <div
        className={`${Styles.loaderContainer} ${withBackground ? Styles.containerBackground : ''}  ${sizeClass ?? ''} `}
      >
        <div className={`${Styles.bar} ${Styles.bar_num1}`}></div>
        <div className={`${Styles.bar} ${Styles.bar_num2}`}></div>
        <div className={`${Styles.bar} ${Styles.bar_num3}`}></div>
        <div className={`${Styles.bar} ${Styles.bar_num4}`}></div>
        <div className={`${Styles.bar} ${Styles.bar_num5}`}></div>
      </div>
    </div>
  )
}

export interface PrismSkeletonProps {
  className?: string
  size?: 'small' | 'medium' | 'default' | 'large' | 'extraLarge'
  maxWidth?: number
  shape?: 'square' | 'circle'
  lighterShade?: boolean
}

/**
 * Renders the Prism Skeleton
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1350%3A3351
 *
 * @param className - string class for the element's container
 * @param size - Add a defined height for the container, except by the extraLarge option which uses the 100% height of the parent container.
 * @param maxWidth - the skeleton's maximum width based in px only accept numbers , when is not being used the max width will be equal to the parent's width (100%).
 * @param shape - can be circle or square.
 * @param lighterShade - if true, a lighter background color is displayed
 */
export const PrismSkeleton = ({
  className,
  size = 'default',
  maxWidth,
  shape = 'square',
  lighterShade,
}: PrismSkeletonProps) => {
  const sizeClass = skeletonSizeClasses[size]

  const shapeClass = skeletonShapeClasses[shape]

  const maxSize = `${maxWidth}px`
  return (
    <div
      className={`${Styles.skeletonContainer} ${sizeClass} ${className ?? ''} ${shapeClass ?? ''}`}
      style={{ maxWidth: maxSize, height: `${shape === 'circle' ? maxSize : ''}` }}
    >
      <div className={lighterShade ? Styles.lighterShade : Styles.skeleton}></div>
    </div>
  )
}
