import React from 'react'

import { Tooltip, TooltipProps } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'

interface Props extends Omit<TooltipProps, 'children'> {
  condition?: boolean
  anchorClassName?: string
  children: JSX.Element
  'data-testid'?: string
}

/**
 * This component is a wrapper around antd Tooltip.
 * Can be used both as simple tooltip that displays on hover, or as a conditional,
 * where the tooltip can be displayed under a certain condition
 *
 * Created to avoid the pattern <ConditionalWrapper wrapper={...<Tooltip>}>
 *
 * Warning!
 * There's a known issue where antd tooltip doesn't work with custom components.
 * https://github.com/ant-design/ant-design/issues/25214
 * A solution is to wrap the children in another tag.
 *
 * Don't use this inside tables, it will break the <tr> layout
 *
 * @param condition - condition that allow us to display the tooltip, without a condition it renders as a normal tooltip
 * @param anchorClassName - Adds styles to the wrapper around the children component
 * @param children - An element that triggers the tooltip
 */
const PrismTooltip = ({ anchorClassName = '', condition, children, 'data-testid': dataTestId, ...rest }: Props) => {
  const tooltipContent = (
    <div className={anchorClassName} data-testid={dataTestId}>
      {children}
    </div>
  )
  // we want to display the regular tooltip when 'condition' is not being used
  if (typeof condition !== 'boolean') return <Tooltip {...rest}>{tooltipContent}</Tooltip>

  return (
    <ConditionalWrapper
      condition={condition}
      wrapper={children => {
        return <Tooltip {...rest}>{children}</Tooltip>
      }}
    >
      {tooltipContent}
    </ConditionalWrapper>
  )
}

export default PrismTooltip
