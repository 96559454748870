import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

import { pixelsIntoPoints } from '../styles'

const iconSize = pixelsIntoPoints(20)

const PDFResultPass = () => (
  <Svg style={{ width: iconSize, height: iconSize }} viewBox="0 0 20 20">
    <Path
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
      fill="#5CD6C6"
    />
    <Path
      d="M15.0694 6.48809C15.339 6.1736 15.3026 5.70012 14.9881 5.43056C14.6736 5.16099 14.2001 5.19741 13.9306 5.51191L15.0694 6.48809ZM8.5 13L7.96967 13.5303C8.11739 13.6781 8.32003 13.7575 8.52878 13.7494C8.73754 13.7414 8.93349 13.6467 9.06944 13.4881L8.5 13ZM6.53033 9.96967C6.23744 9.67678 5.76256 9.67678 5.46967 9.96967C5.17678 10.2626 5.17678 10.7374 5.46967 11.0303L6.53033 9.96967ZM13.9306 5.51191L7.93056 12.5119L9.06944 13.4881L15.0694 6.48809L13.9306 5.51191ZM9.03033 12.4697L6.53033 9.96967L5.46967 11.0303L7.96967 13.5303L9.03033 12.4697Z"
      fill="#2C4946"
    />
  </Svg>
)
export default PDFResultPass
