import React, { useEffect, useRef } from 'react'

import { useQueryParams } from 'hooks'
import { LabelingMetrics, LabelingProgressObject, ToolLabel, ToolResult, ToolSpecificationName } from 'types'

import Styles from './LabelingGallery.module.scss'
import LabelingGalleryBody from './LabelingGalleryBody'
import LabelingGroups from './LabelingGroups'
import SmartGroups from './SmartGroups'

export const MAX_SELECTED_GALLERY_CLASSIFICATIONS = 150

export type ToolResultsMap = { [toolId: string]: ToolResult }

export type ToolResultsMapDispatch = React.Dispatch<React.SetStateAction<ToolResultsMap>>

interface Props {
  uniqueToolResults?: ToolResult[]
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  toolLabels?: ToolLabel[]
  metrics: LabelingMetrics | undefined
  testSetCountsByLabelId: LabelingMetrics | undefined
  fetchNextPage: () => Promise<ToolResult | undefined>
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  lastSelectedToolResultId: string | null
  setLastSelectedToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  generatingSmartGroups: boolean
  setGeneratingSmartGroups: React.Dispatch<React.SetStateAction<boolean>>
  setDisableSmartGroupsButton: (disabled: boolean) => any
  showInsights: boolean
  labelingProgress: LabelingProgressObject
  customToolLabels?: ToolLabel[]
}

/**
 * Renders the Labeling Gallery screen
 * This screen renders a grid view with multiple toolResult cards, this allows the user to label a bunch of toolResults at the same time.
 *
 * @param uniqueToolResults - The unique toolResults to render.
 * @param toolParentId - The current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param toolLabels - Tool labels for the current tool
 * @param metrics - Labeling metrics.
 * @param fetchNextPage - Callback to fetch the next page of toolResults.
 * @param currentToolResult - The current toolResult, used just in case tool_result_id is on the qs.
 * @param selectedToolResults - The object including the selected tool results by gallery
 * @param setSelectedToolResults - The function to set the selected tool results by gallery
 * @param lastSelectedToolResultId - The last selected tool result id
 * @param setLastSelectedToolResultId - The function to set the last selected tool result id
 * @param generatingSmartGroups - Whether the smart groups are being generated
 * @param setGeneratingSmartGroups - The function to set if smart groups are being generated
 * @param setDisableSmartGroupsButton - The function to set if the smart groups button is disabled
 * @param showInsights - Whether to show insights
 */
const LabelingGallery = ({
  uniqueToolResults,
  toolParentId,
  toolSpecificationName,
  toolLabels,
  metrics,
  testSetCountsByLabelId,
  fetchNextPage,
  selectedToolResults,
  setSelectedToolResults,
  lastSelectedToolResultId,
  setLastSelectedToolResultId,
  generatingSmartGroups,
  setGeneratingSmartGroups,
  setDisableSmartGroupsButton,
  showInsights,
  labelingProgress,
  customToolLabels,
}: Props): JSX.Element => {
  const [params] = useQueryParams()

  const groupsActive = areGroupsActive(params)
  const smartGroupsActive = areSmartGroupsActive(params)
  const galleryWrapperRef = useRef<HTMLDivElement>(null)

  // clear selected toolResults if qs params filters change
  useEffect(() => {
    setSelectedToolResults({})
  }, [params, setSelectedToolResults])

  // scroll to the top when we switch to another group
  useEffect(() => {
    galleryWrapperRef.current?.scrollTo({ top: 0 })
  }, [groupsActive, smartGroupsActive, galleryWrapperRef])

  // Reset the starting position of the scroll bar when a filter change
  useEffect(() => {
    if (!galleryWrapperRef.current) return
    galleryWrapperRef.current.scrollTo({ top: -32, behavior: 'smooth' })
  }, [
    params.user_label_id__in,
    params.ordeing,
    params.user_label_set_isnull,
    params.group_by,
    params.prediction_label_id__in,
    params.showPredictionScore,
    params.component_id,
    params.recipe_parent_id,
    params.inspection_id,
    params.start,
    params.end,
    params.calculated_outcome__in,
  ])

  return (
    <div ref={galleryWrapperRef} className={Styles.labelingGalleryWrapper}>
      {groupsActive && (
        <LabelingGroups
          toolParentId={toolParentId}
          toolSpecificationName={toolSpecificationName}
          showInsights={showInsights}
          shiftPressed={false}
          labels={toolLabels}
          selectedToolResults={selectedToolResults}
          setSelectedToolResults={setSelectedToolResults}
          galleryWrapperRef={galleryWrapperRef}
        />
      )}

      {smartGroupsActive && (
        <SmartGroups
          toolParentId={toolParentId}
          toolSpecificationName={toolSpecificationName}
          showInsights={showInsights}
          selectedToolResults={selectedToolResults}
          setSelectedToolResults={setSelectedToolResults}
          generatingSmartGroups={generatingSmartGroups}
          setGeneratingSmartGroups={setGeneratingSmartGroups}
          setDisableSmartGroupsButton={setDisableSmartGroupsButton}
          galleryWrapperRef={galleryWrapperRef}
        />
      )}

      {!groupsActive && !smartGroupsActive && (
        <LabelingGalleryBody
          uniqueToolResults={uniqueToolResults}
          toolParentId={toolParentId}
          metrics={metrics}
          testSetCountsByLabelId={testSetCountsByLabelId}
          showInsights={showInsights}
          selectedToolResults={selectedToolResults}
          setSelectedToolResults={setSelectedToolResults}
          fetchNextPage={fetchNextPage}
          lastSelectedToolResultId={lastSelectedToolResultId}
          setLastSelectedToolResultId={setLastSelectedToolResultId}
          toolSpecificationName={toolSpecificationName}
          labelingProgress={labelingProgress}
          outerContainerRef={galleryWrapperRef}
          customToolLabels={customToolLabels}
        />
      )}
    </div>
  )
}

export default LabelingGallery

/**
 * Returns whether smart groups are active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const areSmartGroupsActive = (params: { [key: string]: string | undefined }) => {
  return params.group_by === 'smart-group'
}

/**
 * Returns whether "standard" groups (any group by option other than smart-group) are active, based on qs params.
 * @param params query params object
 * @returns boolean
 */
export const areGroupsActive = (params: { [key: string]: string | undefined }) => {
  return !!params.group_by && params.group_by !== 'smart-group'
}
