import React from 'react'

import { Popover } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import Shared from 'styles/Shared.module.scss'
import { renderLargeNumber } from 'utils'

import Styles from './PrismGraphProgressBar.module.scss'

/**
 * Renders the Analyze graph Progress bar container
 *
 * https://www.figma.com/file/LNV0RsogObs9SSxNvwOtFC/Analyze-(main)?node-id=304%3A72086
 *
 * @param graphName - renders the name of the graph placed on the left side
 * @param graphValue - shows the total value of the current graph, placed on the right side
 * @param type - renders the color of the bars
 * @param tooltipSubtitle - adds a subtitle
 */
export const PrismGraphProgressBar = ({
  graphName,
  toolTipTitle,
  graphPercentage,
  graphCount,
  tooltipSubtitle,
  type,
  inactive,
  className,
  isPdf,
  'data-testid': dataTestId,
}: {
  graphName: React.ReactNode
  toolTipTitle?: React.ReactNode
  graphPercentage: number | string
  graphCount?: number
  tooltipSubtitle?: string
  type: 'white' | 'pass' | 'fail' | 'unknown'
  inactive?: Boolean
  className?: string
  isPdf?: boolean
  'data-testid'?: string
}) => {
  return (
    <Popover
      overlayClassName={Styles.graphPopoverContainer}
      title={
        <ConditionalWrapper
          wrapper={ch => (
            <div className={Styles.tooltipHeader}>
              {ch}
              <p className={Styles.tooltipSubtitle}>{tooltipSubtitle}</p>
            </div>
          )}
          condition={!!tooltipSubtitle}
        >
          <h4 className={Styles.graphPopoverTitle}>{toolTipTitle || graphName}</h4>
        </ConditionalWrapper>
      }
      content={
        <section className={Shared.verticalChildrenGap8}>
          <div className={Styles.graphPopoverDescription}>
            <div className={Styles.graphPopoverDescriptionTitle}>% of Items</div>
            <div className={Styles.graphPopoverDescriptionValue}>{graphPercentage}%</div>
          </div>
          {graphCount !== undefined && (
            <div className={Styles.graphPopoverDescription}>
              <div className={Styles.graphPopoverDescriptionTitle}># of Items</div>
              <div className={Styles.graphPopoverDescriptionValue}>{renderLargeNumber(graphCount)}</div>
            </div>
          )}
        </section>
      }
      placement="bottom"
    >
      <div className={`${Styles.prismGraphProgressBar} ${className ?? ''} ${inactive ? Styles.inactive : ''}`}>
        <div className={`${Styles.graphNameContainer} ${isPdf ? Styles.pdfGraphName : ''}`}>{graphName}</div>
        <div className={Styles.graphContainer}>
          <span>
            <ProgressBar
              type={type}
              height="extraMedium"
              progress={graphPercentage}
              className={isPdf ? Styles.pdfProgressBar : Styles.progressBar}
              data-testid={dataTestId}
            />
          </span>
        </div>

        <div className={`${Styles.graphValue} ${isPdf ? Styles.pdfGraphValue : ''}`}>{`${graphPercentage}%`}</div>
      </div>
    </Popover>
  )
}
