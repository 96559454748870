import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

import { pixelsIntoPoints } from '../styles'

const iconSize = pixelsIntoPoints(20)

const PDFResultFail = () => (
  <Svg style={{ width: iconSize, height: iconSize }} viewBox="0 0 20 20">
    <Path
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
      fill="#ED523D"
    />
    <Path
      fillRule="evenodd"
      d="M11.8874 12.948C11.3428 13.2974 10.6951 13.5 10 13.5C8.067 13.5 6.5 11.933 6.5 10C6.5 9.30495 6.7026 8.65721 7.05197 8.11263L11.8874 12.948ZM12.948 11.8874L8.11263 7.05197C8.65721 6.7026 9.30495 6.5 10 6.5C11.933 6.5 13.5 8.067 13.5 10C13.5 10.6951 13.2974 11.3428 12.948 11.8874ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
      fill="#4F2A25"
    />
  </Svg>
)
export default PDFResultFail
