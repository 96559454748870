import React from 'react'

import moment from 'moment-timezone'
import {
  AllowEscapeViewBox,
  CartesianGrid,
  Coordinate,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { colors, CountTooltip, YieldTooltipProps } from 'components/CustomRecharts'
import { useDateTimePreferences, useIsFlourish } from 'hooks'
import { DateValue, TimeSeriesDatePeriod } from 'types'
import { formatGraphTooltipDate, renderLargeNumber, xAxisFormatter } from 'utils'

import Styles from './GraphGlow.module.scss'
const Y_AXIS_WIDTH = 40
const PDF_Y_AXIS_WIDTH = 50
export interface CountSeries {
  ts: number
  count?: number
}

interface Props {
  graphSeries: CountSeries[]
  period?: TimeSeriesDatePeriod
  syncId?: string
  onChartClick?: () => any
  width?: number
  height?: number
  start?: DateValue
  end?: DateValue
  fixedTooltipCoords?: Coordinate
  chartHeight?: number | string
  chartWidth?: number | string
  containerClassName?: string
  upmThreshold?: number
  hideTicks?: boolean
  hideXAxis?: boolean
  hideYAxis?: boolean
  mode: 'inspection' | 'metrics'
  pdfFormat?: 'overview' | 'table'
  cartesianGrid?: boolean
  graphRef?: React.RefObject<CartesianGrid>
  allowEscapeViewBox?: AllowEscapeViewBox
}

/** Renders a line chart that displays the item count for a specified time span
 *
 * @param graphSeries - The series to use as chart's data. Must include ts (x axis) and count (y axis)
 * @param syncId - If other charts are rendered at the same time, passing the same syncId to the charts syncs their tooltip
 * @param onChartClick - Function called when user clicks on the chart
 */
export default function CountGraph({
  graphSeries,
  syncId,
  onChartClick,
  period,
  height,
  width,
  fixedTooltipCoords,
  chartHeight,
  chartWidth = '100%',
  containerClassName,
  start,
  end,
  upmThreshold,
  hideTicks,
  hideXAxis,
  hideYAxis,
  mode,
  pdfFormat,
  cartesianGrid = false,
  graphRef,
  allowEscapeViewBox,
}: Props) {
  const { timeZone, timeFormat } = useDateTimePreferences()
  const { isFlourish } = useIsFlourish()

  let tick: boolean | { fill: string } = !hideTicks
  if (pdfFormat) tick = { fill: colors.pdfTicksStroke }

  function uiCountGraphColors() {
    if (isFlourish) return { graphStroke: colors.flourishBlueStroke, graphFill: colors.flourishBlueFill }
    return { graphStroke: colors.blueStroke, graphFill: colors.blueFill }
  }
  const { graphStroke, graphFill } = uiCountGraphColors()

  return (
    <ConditionalWrapper
      condition={!!chartHeight}
      wrapper={content => (
        <ResponsiveContainer className={containerClassName} width={chartWidth} height={chartHeight}>
          {content}
        </ResponsiveContainer>
      )}
    >
      <LineChart
        data={graphSeries}
        syncId={syncId}
        width={width}
        height={height}
        onClick={onChartClick}
        style={{ cursor: onChartClick ? 'pointer' : 'default', backgroundColor: pdfFormat ? '#fff' : undefined }}
      >
        {(cartesianGrid || mode === 'inspection') && (
          <CartesianGrid stroke={colors.darkGrid} strokeDasharray="4" vertical={false} ref={graphRef} />
        )}

        {pdfFormat === 'overview' && <CartesianGrid stroke={colors.pdfCartesianGridStroke} strokeWidth={0.5} />}

        <XAxis
          stroke={pdfFormat ? colors.pdfAxisStroke : colors.axisStroke}
          hide={hideXAxis}
          tick={tick}
          height={hideTicks ? 1 : undefined}
          tickLine={mode === 'metrics' ? false : undefined}
          dataKey="ts"
          tickFormatter={ts => {
            if (mode === 'inspection')
              return moment(ts * 1000)
                .tz(timeZone)
                .format(timeFormat)
            return xAxisFormatter(ts, {
              startTime: start?.toDate().getTime(),
              endTime: end?.toDate().getTime(),
              timeFormat,
              timeZone,
            })
          }}
          interval="preserveStartEnd"
        />

        <YAxis
          width={pdfFormat ? PDF_Y_AXIS_WIDTH : Y_AXIS_WIDTH}
          hide={hideYAxis}
          tickLine={mode === 'metrics' ? false : undefined}
          axisLine={mode === 'metrics' ? false : undefined}
          stroke={pdfFormat ? '#757575' : colors.axisStroke}
          domain={[() => 0, upmThreshold ? dataMax => Math.max(Math.ceil(dataMax), upmThreshold) : 'dataMax']}
          tickFormatter={(d: number) => renderLargeNumber(d, 1000)}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 2 }}
          position={fixedTooltipCoords}
          allowEscapeViewBox={allowEscapeViewBox}
          cursor={{ stroke: '#FFF', strokeWidth: 0.5, fill: 'transparent' }}
          content={(props: YieldTooltipProps) => {
            return (
              <CountTooltip
                {...props}
                formatter={data =>
                  formatGraphTooltipDate({
                    ts: data.ts,
                    timeFormat,
                    timeZone,
                    period,
                  })
                }
              />
            )
          }}
        />
        <Line
          dataKey="count"
          type="monotone"
          stroke={graphStroke}
          fill={mode === 'inspection' ? undefined : pdfFormat ? graphStroke : graphFill}
          strokeWidth={2}
          isAnimationActive={false}
          dot={false}
          activeDot={{ stroke: graphStroke, strokeWidth: 1, fill: graphFill, r: 3 }}
          name="Count"
          className={`${Styles.countLine} ${pdfFormat ? Styles.pdfStyles : ''}`}
        />

        {upmThreshold && <ReferenceLine y={upmThreshold} stroke="#F8F8F9" />}
      </LineChart>
    </ConditionalWrapper>
  )
}
