import React, { useRef } from 'react'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismArrowIcon, PrismNavArrowIcon } from 'components/prismIcons'

import Styles from './Carousel.module.scss'

interface CarouselItemProps {
  children: React.ReactNode
  onClick?: () => any
  active: boolean
}
/**
 * Renders a carousel item
 *
 *
 * @param children -  receive the image
 * @param onClick - Callback to be called
 * @param active - It adds a line below the active element
 */
export const CarouselItem = ({ children, onClick, active }: CarouselItemProps) => {
  return (
    <figure className={`${Styles.carouselItem} ${active ? Styles.carouselItemActive : ''}`} onClick={onClick}>
      <div className={Styles.carouselImage}>{children}</div>
    </figure>
  )
}

interface CarouselLayoutProps {
  children?: React.ReactNode
  carouselTitle?: string
  carouselTitleClassName?: string
  type?: 'inline' | 'twolines'
  onClickNext?: () => any
  onClickPrev?: () => any
}

/**
 * Renders the Carousel Layout
 *
 * @param carouselTitle - Optional value to add a title, this will only be visible if the type is set to twolines
 * @param carouselTitleClassName -  Carousel title selector to add styles
 * @returns
 */
const CarouselLayout = ({
  children,
  carouselTitle,
  carouselTitleClassName = '',
  type,
  onClickNext,
  onClickPrev,
}: CarouselLayoutProps) => {
  if (type === 'inline') {
    return (
      <div className={Styles.inlineContainer}>
        <IconButton
          type="secondary"
          className={Styles.carouselArrow}
          onClick={onClickPrev}
          icon={<PrismArrowIcon direction="left" />}
        />

        {children}

        <IconButton
          type="secondary"
          className={Styles.carouselArrow}
          onClick={onClickNext}
          icon={<PrismArrowIcon direction="right" />}
        />
      </div>
    )
  }
  return (
    <div className={Styles.twoLines}>
      <div className={Styles.carouselHeader}>
        <p className={carouselTitleClassName}>{carouselTitle}</p>
        <div className={Styles.carouselArrows}>
          <IconButton
            type="tertiary"
            className={Styles.carouselArrow}
            onClick={onClickPrev}
            icon={<PrismNavArrowIcon direction="left" />}
            size="xsmall"
          />

          <IconButton
            type="tertiary"
            className={Styles.carouselArrow}
            onClick={onClickNext}
            icon={<PrismNavArrowIcon direction="right" />}
            size="xsmall"
          />
        </div>
      </div>
      {children}
    </div>
  )
}

interface CarouselProps extends CarouselLayoutProps {
  scrollSize?: number
  className?: string
  carouselListClassName?: string
}

/**
 * Renders 2 types of carousels
 *
 * - one line (inline): the arrows and the list is rendered in the same line
 * - two lines (twolines): Adds a two row layout, where the first row is for the title and the carousel arrows, and the list is placed below in the second row.
 *
 * @param children -  List items
 * @param onClickNext - Callback to be used when the next arrow is clicked
 * @param onClickPrev - Callback to be used when the prev arrow is clicked
 * @param scrollSize - user should add the width of a carousel item + gap for the scroll to move 1 unit
 * @param type - Changes the carousel layout, by default it will be rendered as inline
 * @param className - Main wrapper selector to add styles
 * @param carouselListClassName -  Carousel list selector to add styles
 */
export const Carousel = ({
  children,
  scrollSize = 48,
  className,
  type = 'inline',
  carouselListClassName = '',
  onClickNext,
  onClickPrev,
  ...props
}: CarouselProps) => {
  const ref = useRef<HTMLUListElement>(null)

  const scroll = (direction: 'left' | 'right') => {
    if (ref && ref.current) {
      if (direction === 'left') ref.current.scrollLeft -= scrollSize
      if (direction === 'right') ref.current.scrollLeft += scrollSize
    }
  }

  const handleClickNext = () => {
    if (onClickNext) onClickNext()
    scroll('right')
  }

  const handleClickPrev = () => {
    if (onClickPrev) onClickPrev()
    scroll('left')
  }

  return (
    <div className={`${Styles.carouselWrapper} ${className ?? ''}`}>
      <CarouselLayout onClickNext={handleClickNext} onClickPrev={handleClickPrev} {...props} type={type}>
        <ul className={`${Styles.carouselContainer} ${carouselListClassName}`} ref={ref}>
          {children}
        </ul>

        {type === 'inline' && <div className={Styles.carouselOverlay}></div>}
      </CarouselLayout>
    </div>
  )
}
