import React from 'react'

import Styles from './LiveFeed.module.scss'

const liveFeedStatusClass = {
  pass: Styles.liveFeed_pass,
  fail: Styles.liveFeed_fail,
  unknown: Styles.liveFeed_unknown,
  default: Styles.liveFeed_default,
  minor: Styles.liveFeed_minor,
}

export const LiveFeed = ({
  status = 'unknown',
  children,
  key,
  className = '',
  style,
}: {
  status?: 'pass' | 'fail' | 'unknown' | 'default' | 'minor'
  children?: React.ReactElement[] | React.ReactElement | React.ReactChild | React.ReactNode
  key?: string
  className?: string
  style?: React.CSSProperties
  showLabel?: boolean
}) => {
  const statusClass = liveFeedStatusClass[status]
  return (
    <span className={`${Styles.liveFeed} ${statusClass} ${className}`} style={style} key={key}>
      {children}
    </span>
  )
}
