import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { PdfGalleryCard } from 'types'
import { titleCase } from 'utils'

import PdfElementaryCube from './PdfSVG/PdfElementaryCube'
import PDFIcon from './PdfSVG/PdfIcon'
import PDFResultDiscard from './PdfSVG/PDFResultDiscard'
import PDFResultFail from './PdfSVG/PdfResultFail'
import PDFResultPass from './PdfSVG/PdfResultPass'
import PDFResultUnknown from './PdfSVG/PdfResultUnknown'
import styles, { pixelsIntoPoints } from './styles'

const ELEMENTARY_CUBE_SIZE = pixelsIntoPoints(110)

const LABELS_TO_SHOW = 2
/**
 * Renders a gallery of cards in PDF format
 *
 * @param cards - Card items to be shown in the gallery
 * @param orientation - PDF selected orientation to render
 */
export const PdfGallery = ({
  cards,
  orientation,
}: {
  cards: PdfGalleryCard[]
  orientation?: 'portrait' | 'landscape'
}) => {
  return (
    <View style={styles.gridCardWrapper}>
      {cards.map((card, key) => (
        <View
          key={key}
          style={{ ...styles.cardContainer, ...(orientation === 'landscape' ? styles.cardContainerLandscapeMode : {}) }}
          wrap={false}
        >
          <View style={{ ...styles.cardImageContainer, ...(!card.imageUrl ? styles.cardElementaryCube : {}) }}>
            {card.imageUrl ? (
              <Image src={card.imageUrl} style={styles.cardImage} />
            ) : (
              <PdfElementaryCube size={ELEMENTARY_CUBE_SIZE} />
            )}
          </View>

          <View style={styles.outcomeResultIcon}>
            {card.outcome === 'fail' && <PDFResultFail />}

            {card.outcome === 'pass' && <PDFResultPass />}

            {(card.outcome === 'unknown' || card.outcome === 'error' || card.outcome === 'needs-data') && (
              <PDFResultUnknown />
            )}
          </View>

          {/* Note: this used to be 'deleted' and hence was never rendered, with this change it could be rendered */}
          {card.outcome === 'discard' && <PDFResultDiscard />}

          <View style={styles.cardDescriptionContainer}>
            <View style={styles.cardResultWrapper}>
              {card.deleted && (
                <View style={styles.cardResultContainer}>
                  <View style={styles.cardResultIcon}>
                    <PDFIcon icon="discard" size="small" />
                  </View>
                  <Text style={styles.cardResult}>Discard</Text>
                </View>
              )}

              {!card.deleted && card.labels.length === 0 && <Text style={styles.cardResultEmpty}>- -</Text>}
              {/* We only want to show the top 2 labels */}
              {!card.deleted &&
                card.labels.slice(0, LABELS_TO_SHOW).map(lbl => (
                  <View key={lbl.id} style={styles.cardResultContainer}>
                    <View style={styles.cardResultIcon}>
                      {lbl.severity === 'good' && <PDFIcon icon="good" size="small" />}
                      {lbl.severity === 'neutral' && <PDFIcon icon="neutral" size="small" />}
                      {lbl.severity === 'minor' && <PDFIcon icon="minor" size="small" />}
                      {lbl.severity === 'critical' && <PDFIcon icon="critical" size="small" />}
                    </View>

                    <Text style={styles.cardResult}>{titleCase(lbl.value)}</Text>
                  </View>
                ))}

              {!card.deleted && card.labels.length > LABELS_TO_SHOW && (
                <Text style={styles.cardResultExtraLabels}>+ {card.labels.length - LABELS_TO_SHOW}</Text>
              )}
            </View>
          </View>
        </View>
      ))}
    </View>
  )
}
