import React, { useEffect, useRef, useState } from 'react'

import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'
import { VIDEO_LOADING_TIMEOUT_MS } from 'env'
import { usePrevious } from 'hooks'
import { Box } from 'types'

import Styles from './Video.module.scss'

type ConnectionState = 'connected' | 'waitingForFrame' | 'notConnected'

/**
 * Renders an `img` tag whose `src` attribute is set to video frames, one by
 * one, read via websocket.
 *
 * @param frame - Frame that we use to render video feed
 * @param messageId - Redis stream message id in which frame was published
 * @param videoStyle - Passed to containing div
 * @param videoClassName - Passed to containing div
 * @param extraContent - Extra content to render on top of video
 * @param fallbackImage - Image to show when no feed is received
 * @param onChangeState - Called when connection state changes
 * @param waitingForFrameTimeoutMs - How long we transition to error state if we
 *     get no frames
 * @param showErrorOnFrameTimeout - Show loading/error indicator in error state?
 */
export interface Props extends React.ImgHTMLAttributes<HTMLImageElement>, JSX.IntrinsicAttributes {
  frame: Blob | undefined
  videoStyle?: React.CSSProperties
  videoClassName?: string
  zoom?: 0.5 | 0.33 | 0.25
  loader?: React.ReactNode
  fallbackImage?: React.ReactNode
  extraContent?: React.ReactNode
  onChangeState?: (state: ConnectionState) => any
  waitingForFrameTimeoutMs?: number
  showErrorOnFrameTimeout?: boolean
  showSpinner?: boolean
  overlaySrc?: string
  overlayOpacity?: number
  forceRegion?: Box
  onRegionChange?: (region?: Box) => any
  'data-testid'?: string
  'data-test-attribute'?: string
  'data-test'?: string
  overlayScaleToOriginal?: boolean
  onOverlayLoad?: React.ReactEventHandler<HTMLImageElement>
}

export function VideoFeed({
  alt = 'Video feed',
  frame,
  messageId,
  videoStyle,
  videoClassName,
  extraContent,
  loader,
  zoom,
  fallbackImage,
  onChangeState,
  waitingForFrameTimeoutMs,
  showErrorOnFrameTimeout = true,
  showSpinner = true,
  overlaySrc,
  overlayOpacity,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  ...rest
}: Props & { messageId: string | undefined }) {
  const previousMessageId = usePrevious(messageId)

  const [frameUrl, setFrameUrl] = useState<string>()
  const [waitingForFrame, setWaitingForFrame] = useState(false)
  const [notConnected, setNotConnected] = useState(false)

  const waitingForFrameTimerId = useRef<number | undefined>(undefined)
  const notConnectedTimerId = useRef<number | undefined>(undefined)
  const mounted = useRef(false)

  const resolvedWaitingForFrameTimeoutMs = Math.max(waitingForFrameTimeoutMs || 0, VIDEO_LOADING_TIMEOUT_MS)
  const notConnectedTimeoutMs = resolvedWaitingForFrameTimeoutMs * 3

  useEffect(() => {
    if (!frame) return

    const frameUrl = URL.createObjectURL(frame)
    setFrameUrl(frameUrl)

    return () => {
      URL.revokeObjectURL(frameUrl)
    }
  }, [frame])

  useEffect(() => {
    if (!frame) return

    // If we don't have message id, or frame has same message id as previous frame, we're still waiting for a new frame
    if (!messageId || previousMessageId === messageId) return

    window.clearTimeout(waitingForFrameTimerId.current)
    window.clearTimeout(notConnectedTimerId.current)
    setWaitingForFrame(false)
    setNotConnected(false)
    waitingForFrameTimerId.current = window.setTimeout(() => setWaitingForFrame(true), resolvedWaitingForFrameTimeoutMs)
    notConnectedTimerId.current = window.setTimeout(() => setNotConnected(true), notConnectedTimeoutMs)
  }, [frame, previousMessageId, messageId, resolvedWaitingForFrameTimeoutMs, notConnectedTimeoutMs])

  useEffect(() => {
    if (!onChangeState) return
    let state: ConnectionState = 'connected'
    if (waitingForFrame) state = 'waitingForFrame'
    if (notConnected) state = 'notConnected'

    onChangeState(state)
  }, [waitingForFrame, notConnected]) // eslint-disable-line

  useEffect(() => {
    mounted.current = true

    waitingForFrameTimerId.current = window.setTimeout(() => {
      if (mounted.current) setNotConnected(true) // TODO: We should prevent other calls to setState when this component is not mounted
    }, notConnectedTimeoutMs)

    return () => {
      // This prevents react error related to updating unmounted components
      window.clearTimeout(waitingForFrameTimerId.current)
      window.clearTimeout(notConnectedTimerId.current)
      mounted.current = false
      clearTimeout(waitingForFrameTimerId.current)
    }
  }, [notConnectedTimeoutMs])

  // If we haven't loaded a frame yet, render black background with loading indicator; if we've been here for a *long* time, show video load error
  if (!frameUrl) {
    return (
      <div className={videoClassName || Styles.parent} style={videoStyle}>
        {notConnected && fallbackImage}
        {!notConnected && showSpinner && !loader && <PrismLoader className={Styles.loading} />}
        {!notConnected && showSpinner && loader}
      </div>
    )
  }

  let content = (
    <ZoomableImage
      data-testid={dataTestId}
      data-test={dataTest}
      containerClassName={Styles.zoomImageContainer}
      id={frame?.arrayBuffer.name}
      {...rest}
      src={frameUrl}
      scalingFactor={zoom}
      overlaySrc={overlaySrc}
      alt={alt}
      enableZoom={!!zoom}
      overlayOpacity={overlayOpacity}
      useCache={!!zoom}
    />
  )

  // If we're stuck waiting for next frame, render current frame and a loading indicator
  // If we've been stuck for a *long* time, show video load error

  if (notConnected && showErrorOnFrameTimeout) {
    content = <>{fallbackImage}</>
  } else if (waitingForFrame && showErrorOnFrameTimeout) {
    content = (
      <>
        {showSpinner && !loader && <PrismLoader className={Styles.loading} />}

        {showSpinner && loader}
        {
          <ZoomableImage
            containerClassName={Styles.zoomImageContainer}
            {...rest}
            src={frameUrl}
            scalingFactor={zoom}
            alt={alt}
            overlaySrc={overlaySrc}
            enableZoom={!!zoom}
            overlayOpacity={overlayOpacity}
            useCache={!!zoom}
          />
        }
      </>
    )
  }

  return (
    <div className={videoClassName || Styles.parent} style={videoStyle} data-test-attribute={dataTestAttribute}>
      {content}
      {extraContent}
    </div>
  )
}
