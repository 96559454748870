import React, { useState } from 'react'

import ImageCloseUp, { ImageCloseUpProps } from 'components/ImageCloseUp/ImageCloseUp'
import { Box } from 'types'
import { contain } from 'utils'

interface Props extends Omit<ImageCloseUpProps, 'region'> {
  containerClassName?: string
  imageBox?: Box
  overlaySrc?: string
  overlayOpacity?: number
  enableZoom?: boolean
  scalingFactor?: 0.5 | 0.33 | 0.25
  maskingRectangleFill?: string
  forceRegion?: Box
  onRegionChange?: (region?: Box) => any
  overlayScaleToOriginal?: boolean
  predictionScoreOverlay?: JSX.Element | undefined
  onOverlayLoad?: React.ReactEventHandler<HTMLImageElement>
  'data-testid'?: string
  'data-test'?: string
}

const ZoomableImage = ({
  containerClassName = '',
  imageBox,
  overlaySrc,
  enableZoom = true,
  scalingFactor = 0.5,
  maskingRectangleFill,
  overlayOpacity,
  forceRegion,
  predictionScoreOverlay,
  onRegionChange,
  onLoad,
  onOverlayLoad,
  'data-testid': dataTestId,
  'data-test': dataTest,
  ...rest
}: Props) => {
  // Zoomed in region
  const [region, setRegion] = useState<Box>()
  // Size of image we want to zoom
  const [currentImageBox, setCurrentImageBox] = useState(imageBox || { x: 0, y: 0, width: 0, height: 0 })

  const [overlayRegion, setOverlayRegion] = useState<Box>()
  const [overlayImageBox, setOverlayImageBox] = useState(imageBox || { x: 0, y: 0, width: 0, height: 0 })

  return (
    <div
      data-testid={dataTestId}
      data-test={dataTest}
      className={containerClassName}
      onMouseMove={e => {
        if (!enableZoom) return
        const target = e.currentTarget as HTMLDivElement
        const rect = target.getBoundingClientRect()

        const imageDimensions = contain(currentImageBox.width, currentImageBox.height, rect.width, rect.height)
        if (!imageDimensions) return
        const mouseX = e.clientX - rect.left // x position within the element.
        const mouseY = e.clientY - rect.top // y position within the element.

        // If mouse is over the element, but not over the image: don't zoom in
        if (
          mouseX < imageDimensions.x ||
          mouseY < imageDimensions.y ||
          mouseX > imageDimensions.x + imageDimensions.width ||
          mouseY > imageDimensions.y + imageDimensions.height
        ) {
          setRegion(undefined)
          setOverlayRegion(undefined)
          onRegionChange?.(undefined)
          return
        }
        const relativeX = (mouseX - imageDimensions.x) / imageDimensions.width
        const relativeY = (mouseY - imageDimensions.y) / imageDimensions.height

        const x = currentImageBox.x + (currentImageBox.width - currentImageBox.width * scalingFactor) * relativeX
        const y = currentImageBox.y + (currentImageBox.height - currentImageBox.height * scalingFactor) * relativeY
        setRegion({
          width: currentImageBox.width * scalingFactor,
          height: currentImageBox.height * scalingFactor,
          x,
          y,
        })
        setOverlayRegion({
          width: overlayImageBox.width * scalingFactor,
          height: overlayImageBox.height * scalingFactor,
          x: overlayImageBox.x + (overlayImageBox.width - overlayImageBox.width * scalingFactor) * relativeX,
          y: overlayImageBox.y + (overlayImageBox.height - overlayImageBox.height * scalingFactor) * relativeY,
        })
        onRegionChange?.({
          width: currentImageBox.width * scalingFactor,
          height: currentImageBox.height * scalingFactor,
          x,
          y,
        })
      }}
      onMouseLeave={() => {
        setRegion(undefined)
        setOverlayRegion(undefined)
        onRegionChange?.(undefined)
      }}
    >
      <ImageCloseUp
        data-testid={dataTestId}
        {...rest}
        region={forceRegion || region || currentImageBox}
        maskingRectangleFill={maskingRectangleFill}
        onLoad={e => {
          const img = e.currentTarget
          if (!imageBox) setCurrentImageBox({ x: 0, y: 0, width: img.naturalWidth, height: img.naturalHeight })
          onLoad?.(e)
        }}
        onOverlayLoad={e => {
          const overlayImg = e.currentTarget
          setOverlayImageBox({ x: 0, y: 0, width: overlayImg.naturalWidth, height: overlayImg.naturalHeight })
          onOverlayLoad?.(e)
        }}
        overlaySrc={overlaySrc}
        overlayOpacity={overlayOpacity}
        overlayRegion={overlayRegion}
        predictionScoreOverlay={predictionScoreOverlay}
      />
    </div>
  )
}

export default ZoomableImage
