import React, { useMemo } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import PointerTooltip from 'components/PointerTooltip/PointerTooltip'
import {
  PrismCloseIcon,
  PrismDiscardIcon,
  PrismHelpIcon,
  PrismPlayIcon,
  PrismSuccessIcon,
  PrismUploadIcon,
  PrismWarningIcon,
} from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Tag } from 'components/Tag/Tag'
import { Token } from 'components/Token/Token'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { DeploymentStatus } from 'types'
import { getAccumulatedDeploymentStatus, getTimeAgoFromDate, getToolsetsDeploymentStatus } from 'utils'

import { RobotWithToolsets } from './DeployModal'
import Styles from './DeployModal.module.scss'

interface RecipeVersionCardProps {
  recipeId: string
  showPrimaryDeployButton?: boolean
  onDeployRecipe: (recipeId: string) => Promise<void>
  loadingToolsets: boolean
  robotsWithToolsets: RobotWithToolsets[]
  removeRecipeFromStation: () => Promise<void>
  activeDeploymentRecipeId?: string
  cancelStationDeployment: () => Promise<void>
  stationStatus: { runningInspection: boolean; robotsLoading: boolean }
  cancelingDeployment: boolean
  startingDeploymentRecipeId: string | undefined
  latestStationDeployedRecipeId: string | undefined
}

/**
 * Renders a card for a single Recipe version in the Deploy modal
 *
 * @param recipeId - Current recipe Id
 * @param showPrimaryDeployButton - Whether the type of the deploy button should be "primary", otherwise it is "secondary"
 * @param onDeployRecipe - Handler to deploy the recipe to the station
 * @param loadingToolsets - Whether the station toolsets are being loaded
 * @param robotsWithToolsets - Robots with its corresponding toolsets
 * @param removeRecipeFromStation - Handler to remove the recipe from station
 * @param activeDeploymentRecipeId - The recipe id that is being deployed to the station
 * @param cancelStationDeployment - Handler to cancel the deployment
 * @param stationStatus - Whether an inspection is running in the current station or some robots are still loading
 * @param latestStationDeployedRecipeId - Latest deployed recipe id
 */
const RecipeVersionCard = ({
  recipeId,
  showPrimaryDeployButton,
  onDeployRecipe,
  loadingToolsets,
  robotsWithToolsets,
  removeRecipeFromStation,
  activeDeploymentRecipeId,
  cancelStationDeployment,
  stationStatus,
  cancelingDeployment,
  startingDeploymentRecipeId,
  latestStationDeployedRecipeId,
}: RecipeVersionCardProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const recipe = useQuery(getterKeys.recipe(recipeId), () => service.getRecipe(recipeId), { noRefetch: true }).data
    ?.data

  const robotsWithDeployStatus = useMemo(() => {
    const robots: (RobotWithToolsets & { deployStatus: DeploymentStatus })[] = []
    recipe?.recipe_routines.forEach(recipeRoutine => {
      const currentRobot = robotsWithToolsets.find(robot => robot.id === recipeRoutine.robot_id)
      if (!currentRobot) return

      const latestDeployedRobotRecipe = currentRobot.latestToolset?.recipe_status.name
      // Only show deploy status for latest deployed recipe
      if (
        latestDeployedRobotRecipe === recipe.id &&
        latestDeployedRobotRecipe === latestStationDeployedRecipeId &&
        currentRobot.toolsets
      ) {
        const newRobot = {
          ...currentRobot,
          deployStatus: getToolsetsDeploymentStatus(Object.values(currentRobot.toolsets), recipeId),
        }
        robots.push(newRobot)
        return
      }

      const newRobot = { ...currentRobot, deployStatus: 'not-deployed' as DeploymentStatus }
      robots.push(newRobot)
    })
    return robots
  }, [latestStationDeployedRecipeId, recipe?.id, recipe?.recipe_routines, recipeId, robotsWithToolsets])

  const deploymentStatus = useMemo(() => {
    if (
      loadingToolsets ||
      !recipe ||
      startingDeploymentRecipeId === recipe?.id ||
      activeDeploymentRecipeId === recipe?.id
    )
      return

    const allRobotStatus = robotsWithDeployStatus.map(robot => robot.deployStatus)

    return getAccumulatedDeploymentStatus(allRobotStatus)
  }, [activeDeploymentRecipeId, loadingToolsets, recipe, robotsWithDeployStatus, startingDeploymentRecipeId])

  const deployingThisRecipe = activeDeploymentRecipeId === recipe?.id
  const deployingAnyRecipe = activeDeploymentRecipeId !== undefined
  const camIsDeployed = deployingThisRecipe
    ? false
    : deploymentStatus === 'deployed' || deploymentStatus === 'semi-deployed'
  const semiDeployed = deploymentStatus === 'semi-deployed'

  return (
    <div className={Styles.recipeVersionCard}>
      <div className={Styles.recipeVersionDescription}>
        <div className={Styles.cardHeader}>
          <span>v{recipe?.version}</span>

          <div className={`${Styles.deployTagContainer} ${camIsDeployed ? Styles.showTag : Styles.hideTag}`}>
            <Tag type={!semiDeployed ? 'success' : 'unknown'}>{deploymentStatus}</Tag>
            {semiDeployed && (
              <PrismTooltip
                placement="bottom"
                overlayClassName={Styles.tooltipWrapper}
                title={
                  <div className={Styles.tooltipContent}>
                    <div className={Styles.tooltipDescription}>
                      This recipe did not deploy to all of its linked cameras. In order for a deploy to succeed:
                      <ul className={Styles.tooltipDescriptionList}>
                        <li className={Styles.tooltipDescriptionListItem}>All linked cameras must be online</li>
                        <li className={Styles.tooltipDescriptionListItem}>All views must have at least 1 tool</li>
                      </ul>
                    </div>

                    <Token label="deploy status" valueClassName={Styles.deployStatusList}>
                      {robotsWithDeployStatus.map(robot => {
                        const viewName = recipe?.recipe_routines.find(r => r.robot_id === robot.id)?.routine.parent.name
                        return (
                          <TooltipDeployStatusItem
                            key={robot.id}
                            camName={robot.name || ''}
                            viewName={viewName || ''}
                            deployStatus={robot.deployStatus}
                          />
                        )
                      })}
                    </Token>
                  </div>
                }
                anchorClassName={Styles.helpIcon}
              >
                <PrismHelpIcon />
              </PrismTooltip>
            )}
          </div>
        </div>
        <div className={Styles.cardTimestamp}>Edited {getTimeAgoFromDate(recipe?.updated_at || moment()).text}</div>
      </div>
      <div className={`${Styles.buttonsContainer} ${loadingToolsets ? Styles.toolSetLoader : ''}`}>
        {loadingToolsets && <PrismSkeleton lighterShade size="extraLarge" />}

        {!loadingToolsets && camIsDeployed && (
          <div
            className={`${Styles.secondaryButtons} ${camIsDeployed ? Styles.expand : ''} ${
              semiDeployed ? Styles.semiDeployed : ''
            } `}
          >
            <ConditionalWrapper
              condition={stationStatus.runningInspection}
              wrapper={button => <PointerTooltip title="Stop batch to remove">{button}</PointerTooltip>}
            >
              <Button
                badge={<PrismDiscardIcon />}
                size="small"
                type="secondary"
                disabled={stationStatus.runningInspection || stationStatus.robotsLoading}
                onClick={async () => {
                  if (!recipe?.parent_id) return
                  await removeRecipeFromStation()
                }}
              >
                remove
              </Button>
            </ConditionalWrapper>
            <ConditionalWrapper
              condition={stationStatus.runningInspection}
              wrapper={button => <PointerTooltip title="Stop batch to run">{button}</PointerTooltip>}
            >
              <Button
                badge={<PrismPlayIcon />}
                size="small"
                type="secondary"
                disabled={stationStatus.runningInspection || stationStatus.robotsLoading}
                onClick={() => {
                  if (!recipe) return
                  dispatch(Actions.inspectorUpdate({ selectedRecipeId: recipe.id }))

                  history.push(paths.newBatch(recipe.parent.station_id))
                }}
                data-testid={recipe ? `run-recipe-version-${recipe.version}-button` : ''}
              >
                run
              </Button>
            </ConditionalWrapper>
          </div>
        )}
        {!loadingToolsets && !deployingThisRecipe && (
          <Button
            type={showPrimaryDeployButton ? 'primary' : 'secondary'}
            size="small"
            badge={<PrismUploadIcon />}
            disabled={deployingAnyRecipe}
            onClick={async () => {
              if (!recipe) return
              await onDeployRecipe(recipe.id)
            }}
            className={`${Styles.deploybutton} ${camIsDeployed && !semiDeployed ? Styles.hide : ''} ${
              camIsDeployed && semiDeployed ? Styles.semiDeployed : ''
            } ${deployingAnyRecipe ? Styles.deployButtonDisabled : ''}`}
            data-testid={recipe ? `deploy-recipe-version-${recipe.version}-button` : ''}
          >
            Deploy
          </Button>
        )}
        {!loadingToolsets && deployingThisRecipe && (
          <Button
            loading={deployingThisRecipe && cancelingDeployment}
            badge={<PrismCloseIcon />}
            size="small"
            type="secondary"
            onClick={cancelStationDeployment}
          >
            Cancel
          </Button>
        )}
      </div>
    </div>
  )
}

export default RecipeVersionCard

const TooltipDeployStatusItem = ({
  camName,
  viewName,
  deployStatus,
}: {
  camName: string
  viewName: string
  deployStatus: DeploymentStatus
}) => {
  return (
    <div className={Styles.tooltipDeployStatusItem}>
      <PrismOverflowTooltip content={camName} className={Styles.tooltipDeployStatusValues} />
      <PrismOverflowTooltip content={viewName} className={Styles.tooltipDeployStatusValues} />
      <div className={Styles.deployStatusIcon}>
        {deployStatus !== 'deployed' ? <PrismWarningIcon isActive /> : <PrismSuccessIcon isActive />}
      </div>
    </div>
  )
}
