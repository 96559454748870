import React, { useRef } from 'react'

import { Button } from 'components/Button/Button'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismMuteIcon } from 'components/prismIcons'

import Styles from './Subscriptions.module.scss'

export type muteOptions = '5m' | '30m' | '1h' | 'until tomorrow' | 'indefinitely'

/**
 * Renders Subscription Menu
 *
 * @param isMuted - If the eventSub is muted or not
 * @param onClick - Callback when clicking any of the options
 */
const SubscriptionOptionMenu = ({
  isMuted,
  onClick,
  dataTestId,
}: {
  isMuted: boolean
  onClick: (action: 'unsubscribe' | 'mute' | 'unmute', timeToMute?: muteOptions) => void
  dataTestId?: string
}) => {
  const closeMenuRef = useRef<(() => void) | undefined>()
  const options = [
    {
      value: 'unsubscribe' as const,
      title: 'unsubscribe',
      badge: <hr className={Styles.line} />,
      className: Styles.optionMenuItem,
      'data-testid': 'unsubscribe',
    },
    isMuted
      ? {
          value: 'unmute' as const,
          title: 'unmute',
          badge: <PrismMuteIcon />,
          className: Styles.optionMenuItem,
          'data-testid': 'unmute',
        }
      : {
          value: 'mute' as const,
          title: (
            <MuteSubMenu
              onMenuItemClick={timeToMute => {
                onClick('mute', timeToMute)
                closeMenuRef.current?.()
              }}
            />
          ),
          className: Styles.optionMenuItemMute,
          'data-testid': 'mute',
        },
  ]

  return (
    <OptionMenu
      data-testid={dataTestId}
      options={options}
      onMenuItemClick={onClick}
      openWithClick
      closeOnClick
      iconButtonSize="xsmall"
      iconButtonType="tertiary"
      iconButtonClassName={Styles.iconButtonActive}
      menuContainerClassName={Styles.subscriptionsMainMenu}
      menuItemClassName={Styles.optionItemBtn}
      closeMenuRef={closeMenuRef}
      hasSubmenu
    />
  )
}

/**
 * Renders Mute Sub Menu
 *
 * @param onMenuItemClick -
 */
const MuteSubMenu = ({ onMenuItemClick }: { onMenuItemClick: (value: muteOptions) => any }) => {
  const submenuOptions = [
    {
      value: '5m' as const,
      title: 'for 5m',
    },
    {
      value: '30m' as const,
      title: 'for 30m',
    },
    {
      value: '1h' as const,
      title: 'for 1h',
    },
    {
      value: 'until tomorrow' as const,
      title: 'until tomorrow',
    },
    {
      value: 'indefinitely' as const,
      title: 'indefinitely',
    },
  ]
  return (
    <OptionMenu
      options={submenuOptions}
      onMenuItemClick={onMenuItemClick}
      openWithClick
      menuContainerClassName={Styles.submenuContainer}
      position="left"
      isSubmenu
      className={Styles.submenuAnchor}
    >
      <Button type="default" badge={<PrismMuteIcon />} className={Styles.optionMenuMuteBtn}>
        Mute
      </Button>
    </OptionMenu>
  )
}

export default SubscriptionOptionMenu
