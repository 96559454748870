import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'

import { batch, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { wsKeys, wsPaths } from 'api'
import StreamListener from 'components/StreamListener'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useInterval, useIsColocated } from 'hooks'
import * as Actions from 'rdx/actions'
import {
  BaslerHeartbeatStreamMessage,
  StreamDescriptor,
  StreamReadMessage,
  VpHeartbeatStreamMessage,
  VpStatus,
  VpStatusStreamMessage,
} from 'types'
import { getConnectionStatus, getIsColocated, getStreamReadMessage } from 'utils'
import { UPDATE_MESSAGES_READ_INTERVA_MS } from 'utils/constants'

import { dismiss, loading } from './PrismMessage/PrismMessage'

const transitionStates: VpStatus[] = ['LOADING', 'LOADING_TOOLS', 'LOADED', 'STOPPING']

const timeoutIdByRobotId: { [robotId: string]: number | undefined } = {}
const heartbeatConnectionByRobotId: { [robotId: string]: boolean | undefined } = {}
let connectionStatusTransitionTimerId = 0
const TRANSITION_TIMEOUTS = {
  ONLINE_TO_RECOVERING: 5 * 1000,
  RECOVERING_TO_OFFLINE: 3 * 1000,
  OFFLINE_TO_ONLINE: 10 * 1000,
}
const TRANSITION_MESSAGE_ID = 'connection-status-transition'

export interface Props {
  robotIds: string[]
  last_n?: number
}

type MessagesRead = {
  vpHeartbeat?: boolean
  baslerHeartbeat?: boolean
}

/**
 * Renders nothing. Subscribes to the robot vision-processing status update
 * stream and persists messages to events branch of state tree.
 *
 * NOTE: don't render this by itself. Just render RobotsStatusListener, which
 * renders this.
 *
 * @param robotIds - Robot id
 * @param last_n - Passed through to StreamListener
 *
 * BUSINESS RULES: Normally with a state machine, we only care about the last
 * message to know what state the machine is in, that would mean using `last_n =
 * 1`. However until VP is a real state machine, it can submit ERROR_MINOR
 * messages, which need to be ignored, and then we need an older message to
 * judge the real state of VP.
 *
 */
function RobotsVpListener({ robotIds, last_n = 5 }: Props) {
  const dispatch = useDispatch()
  const { colocatedRobotIds } = useIsColocated()

  const messagesReadByRobotIdRef = useRef<Record<string, MessagesRead | undefined>>({})

  const { colocatedRobotIdsToUse, cloudRobotIdsToUse } = useMemo(() => {
    const colocatedRobotIdsToUse = robotIds.filter(robotId => colocatedRobotIds.includes(robotId)).sort()
    const cloudRobotIdsToUse = robotIds.filter(robotId => !colocatedRobotIds.includes(robotId)).sort()
    return { colocatedRobotIdsToUse, cloudRobotIdsToUse }
  }, [robotIds, colocatedRobotIds])

  // Even if client code is bad and robotIds change shape, this component won't need to reconnect to the websockets
  const cloudRobotIdsToUseKey = cloudRobotIdsToUse.sort().join()

  const cloudStreams: StreamDescriptor[] = useMemo(() => {
    const streams: StreamDescriptor[] = []

    cloudRobotIdsToUse.forEach(robotId => {
      streams.push({ element: 'vision-processing', stream: 'status', robot_id: robotId, last_n })
      streams.push({ element: 'vision-processing', stream: 'heartbeat', robot_id: robotId, last_n: 1 })
      streams.push({ element: 'basler', stream: 'heartbeat', robot_id: robotId, last_n: 1 })
    })

    return streams
  }, [cloudRobotIdsToUseKey]) // eslint-disable-line

  // Even if client code is bad and robotIds change shape, this component won't need to reconnect to the websockets
  const colocatedRobotIdsToUseKey = colocatedRobotIdsToUse.sort().join()

  const colocatedStreamsByRobotId = useMemo(() => {
    const streamsByRobotId: { [robotId: string]: StreamDescriptor[] | undefined } = {}

    colocatedRobotIdsToUse.forEach(robotId => {
      streamsByRobotId[robotId] = [
        { element: 'vision-processing', stream: 'status', robot_id: robotId, last_n },
        { element: 'vision-processing', stream: 'heartbeat', robot_id: robotId, last_n: 1 },
        { element: 'basler', stream: 'heartbeat', robot_id: robotId, last_n: 1 },
      ]
    })

    return streamsByRobotId
  }, [colocatedRobotIdsToUseKey]) // eslint-disable-line

  const robotIdsKey = [...robotIds].sort().join()

  const setListeningStartedMessages = useCallback(
    (robotId: string) => {
      const listeningStartedMessage = getStreamReadMessage('listening')
      dispatch(
        Actions.event({
          key: wsKeys.streamListening(robotId, 'vpHeartbeat'),
          messages: [listeningStartedMessage],
        }),
      )

      dispatch(
        Actions.event({
          key: wsKeys.streamListening(robotId, 'baslerHeartbeat'),
          messages: [listeningStartedMessage],
        }),
      )
    },
    [dispatch],
  )

  // This effect is in charge of setting a "stream listening started" message for each robot and each heartbeat
  useEffect(() => {
    robotIds.forEach(robotId => {
      setListeningStartedMessages(robotId)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotIdsKey])

  // If messages haven't yet been read in a while from VP stream, we can mark robot state as loading instead of disconnected if our last heartbeat for robot is stale
  // This guarantees that we periodically rerun selectors and can mark camera as offline in case it "loses" heartbeat in real time; it also guarantees we know if we've recently read from VPN streams for a given robot
  const updateMessagesRead = useCallback(() => {
    batch(() => {
      for (const robotId of robotIds) {
        const readMessage: StreamReadMessage = getStreamReadMessage('read')

        if (messagesReadByRobotIdRef.current[robotId]?.vpHeartbeat) {
          dispatch(
            Actions.event({
              key: wsKeys.streamRead(robotId, 'vpHeartbeat'),
              messages: [readMessage],
            }),
          )
        }

        if (messagesReadByRobotIdRef.current[robotId]?.baslerHeartbeat) {
          dispatch(
            Actions.event({
              key: wsKeys.streamRead(robotId, 'baslerHeartbeat'),
              messages: [readMessage],
            }),
          )
        }
      }
    })
  }, [robotIdsKey]) // eslint-disable-line

  useInterval(updateMessagesRead, UPDATE_MESSAGES_READ_INTERVA_MS)

  return (
    <>
      {/* Once we have the camera consolidation work done, we can have a single colocated multistream listener */}
      {colocatedRobotIdsToUse.map(robotId => {
        if (colocatedStreamsByRobotId[robotId]?.length === 0) return null
        return (
          <StreamListener
            mode="message"
            key={robotId}
            connect={{ robotId, relativeUrl: wsPaths.multiStream() }}
            onMessages={(messages: VpStatusStreamMessage[]) => {
              handleMessages(messages, messagesReadByRobotIdRef, dispatch)
            }}
            streams={colocatedStreamsByRobotId[robotId]}
          />
        )
      })}

      {cloudStreams.length > 0 && (
        <StreamListener
          mode="message"
          connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.multiStream()}` }}
          onMessages={messages => {
            handleMessages(messages, messagesReadByRobotIdRef, dispatch)
          }}
          streams={cloudStreams}
        />
      )}
    </>
  )
}

export default RobotsVpListener

const updateSingleRobotMessagesRead = (
  robotId: string,
  stream: 'vpHeartbeat' | 'baslerHeartbeat',
  messagesReadByRobotIdRef: MutableRefObject<Record<string, MessagesRead | undefined>>,
) => {
  if (!messagesReadByRobotIdRef.current[robotId]?.[stream]) {
    const updated = { ...messagesReadByRobotIdRef.current }

    updated[robotId] ??= {}

    updated[robotId]![stream] = true

    messagesReadByRobotIdRef.current = updated
  }
}

function handleMessages(
  messages: VpStatusStreamMessage[] | VpHeartbeatStreamMessage[] | BaslerHeartbeatStreamMessage[],
  messagesReadByRobotIdRef: MutableRefObject<Record<string, MessagesRead | undefined>>,
  dispatch: Dispatch,
) {
  // Handle messages from multiplexed stream listener (multiple streams and or robots)

  batch(() => {
    for (const message of messages) {
      if (!message.meta) continue

      const robotId = message.meta.robot_id

      if (message.meta.element === 'vision-processing') {
        if (message.meta.stream === 'heartbeat') {
          updateSingleRobotMessagesRead(robotId, 'vpHeartbeat', messagesReadByRobotIdRef)
          handleVpHeartbeatMessage([message as VpHeartbeatStreamMessage], robotId, dispatch)
        } else {
          handleVpStatusMessage([message as VpStatusStreamMessage], robotId, dispatch)
        }
      }

      if (message.meta.element === 'basler' && message.meta.stream === 'heartbeat') {
        updateSingleRobotMessagesRead(robotId, 'baslerHeartbeat', messagesReadByRobotIdRef)
        handleBaslerHeartbeatMessage([message as BaslerHeartbeatStreamMessage], robotId, dispatch)
      }
    }
  })
}

function handleBaslerHeartbeatMessage(messages: BaslerHeartbeatStreamMessage[], robotId: string, dispatch: Dispatch) {
  const lastMessage = messages[messages.length - 1]
  if (!lastMessage) return

  dispatch(Actions.event({ key: wsKeys.baslerHeartbeat(robotId), messages }))
}

export function handleVpStatusMessage(messages: VpStatusStreamMessage[], robotId: string, dispatch: Dispatch) {
  // Ignore ERROR_MINOR because it's not a state according to the spec; VP isn't really a state machine yet
  const filtered = messages.filter(message => message.payload.status !== 'ERROR_MINOR')

  const lastMessage = filtered[filtered.length - 1]
  if (!lastMessage) return

  dispatch(
    Actions.event({
      messages: filtered,
      key: wsKeys.vpStatus(robotId),
    }),
  )

  const lastStatus = lastMessage.payload.status
  // If we get a new message, VP is no longer stuck
  window.clearTimeout(timeoutIdByRobotId[robotId])

  if (transitionStates.includes(lastStatus)) {
    const transitionStateTimeout = getTransitionStateTimeout(lastStatus)

    const vpIsStuckTransitioning = Date.now() - lastMessage.messageMs > transitionStateTimeout
    if (vpIsStuckTransitioning) {
      // If we're stuck in a transition state, unblock the user
      unblockVp(robotId, dispatch)
    } else {
      // Else start a timeout with however long is left until this transition state is considered stuck
      const timeUntilConsideredStuck = lastMessage.messageMs - Date.now() + transitionStateTimeout
      timeoutIdByRobotId[robotId] = window.setTimeout(() => {
        unblockVp(robotId, dispatch)
      }, timeUntilConsideredStuck)
    }
  }
}

export function handleVpHeartbeatMessage(messages: VpHeartbeatStreamMessage[], robotId: string, dispatch: Dispatch) {
  const lastMessage = messages[messages.length - 1]
  if (!lastMessage) return

  dispatch(Actions.event({ key: wsKeys.vpHeartbeat(robotId), messages }))

  const { colocatedRobotIds } = getIsColocated()
  const connectionStatus = getConnectionStatus()
  // We only want to consider colocated robots to transition to and from offline mode
  if (!colocatedRobotIds.includes(robotId)) return

  // If last message was over 20 seconds ago, VP is offline
  heartbeatConnectionByRobotId[robotId] =
    lastMessage.messageMs > Date.now() - 20 * 1000 ? lastMessage.payload.is_online : false

  const allRobotsOffline = colocatedRobotIds.every(robotId => !heartbeatConnectionByRobotId[robotId])

  if (connectionStatus === 'online') {
    if (!allRobotsOffline) {
      // A robot is back online, cancel transition
      window.clearTimeout(connectionStatusTransitionTimerId)
      connectionStatusTransitionTimerId = 0
    } else if (!connectionStatusTransitionTimerId) {
      // All robots are offline, initialize timer to transition to recovering
      connectionStatusTransitionTimerId = window.setTimeout(() => {
        loading({
          id: TRANSITION_MESSAGE_ID,
          title: 'Internet connection lost. Trying to reconnect.',
          duration: 0,
        })

        dispatch(
          Actions.connectionStatusUpdate({
            status: 'recovering',
          }),
        )
        connectionStatusTransitionTimerId = 0
      }, TRANSITION_TIMEOUTS.ONLINE_TO_RECOVERING)
    }
  }

  if (connectionStatus === 'recovering') {
    if (!allRobotsOffline) {
      // A robot is back online, transition back online
      dismiss(TRANSITION_MESSAGE_ID)
      dispatch(
        Actions.connectionStatusUpdate({
          status: 'online',
        }),
      )
      window.clearTimeout(connectionStatusTransitionTimerId)
      connectionStatusTransitionTimerId = 0
    } else if (!connectionStatusTransitionTimerId) {
      // All robots are still offline, initialize timer to transition to offline
      connectionStatusTransitionTimerId = window.setTimeout(() => {
        dismiss(TRANSITION_MESSAGE_ID)
        dispatch(
          Actions.connectionStatusUpdate({
            status: 'offline',
          }),
        )
        connectionStatusTransitionTimerId = 0
      }, TRANSITION_TIMEOUTS.RECOVERING_TO_OFFLINE)
    }
  }

  if (connectionStatus === 'offline') {
    if (allRobotsOffline) {
      // All robot are still offline, cancel transition back online
      window.clearTimeout(connectionStatusTransitionTimerId)
      connectionStatusTransitionTimerId = 0
    } else if (!connectionStatusTransitionTimerId) {
      // A robots is back online, initialize timer to transition to online
      connectionStatusTransitionTimerId = window.setTimeout(
        () => {
          dispatch(
            Actions.connectionStatusUpdate({
              status: 'online',
            }),
          )
          connectionStatusTransitionTimerId = 0
        },

        // We could transition instantly, but we want to give some time for VP to get back up and running
        TRANSITION_TIMEOUTS.OFFLINE_TO_ONLINE,
      )
    }
  }
}

const getTransitionStateTimeout = (state: VpStatus) => {
  if (state === 'LOADING_TOOLS') return 10 * 60 * 1000
  return 60 * 1000
}

const unblockVp = (robotId: string, dispatch: Dispatch) => {
  // TODO: instead of pushing fake message onto stream, we should prompt user to "restart" VP once that's implemented; we should also compare most recent message id with current Redis server timestamp to know how much time has elapsed, so we don't always force user to wait 60s for prompt
  const msg: VpStatusStreamMessage = {
    new: true,
    message_id: '0',
    payload: { status: 'READY', data: {} },
    receivedMs: Date.now(),
    messageMs: Date.now(),
  }
  dispatch(
    Actions.event({
      messages: [msg],
      key: wsKeys.vpStatus(robotId),
      ignoreStaleMessage: false,
    }),
  )
}
