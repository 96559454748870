import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconArrowProps } from './prismIconsTypes'

const directionClasses = {
  right: Styles.right,
  left: Styles.left,
  down: Styles.down,
  up: Styles.up,
  mirror: Styles.mirror,
}

/**
 * Prism Button Arrow Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=2040-11212&mode=design&t=uwCLsyC7zRcQy939-0
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param direction - rotate the icon, the default value is right.
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismButtonArrowIcon = ({
  hasBackground,
  direction = 'right',
  className,
  isActive,
}: PrismIconArrowProps) => {
  const directionClass = directionClasses[direction]
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.arrow} ${isActive ? Styles.active : ''} ${directionClass} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M10 8L14 12L10 16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
