import React, { useMemo } from 'react'

import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { LabelCard } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import { useAllToolLabels, useQueryParams } from 'hooks'
import { ToolResult } from 'types'
import { filterOutDerivativeLabels, getDisplayThreshold } from 'utils'

import { getToolResultCardId } from './LabelingScreen'
import Styles from './LabelingScreen.module.scss'

interface Props {
  toolResult: ToolResult
  selectedToolResult?: ToolResult
  onSetToolResult: (toolResult: ToolResult) => void
}

/**
 * Renders a toolResult card used by Labelling Gallery.
 * This card renders the checkbox that is used to select the toolResult.
 * It is also in charge of handling the selection of ranges by holding shift and clicking on another card.
 *
 * @param toolResult - The toolResult to represent in the card
 * @param selectedToolResult - The toolResult currently selected in focus mode
 * @param onSetToolResult - Handler for when user selects the current card
 */
export const FocusCard = ({ toolResult, selectedToolResult, onSetToolResult }: Props) => {
  const { allToolLabels } = useAllToolLabels()
  const [params] = useQueryParams()

  const toolLabels = useMemo(() => {
    if (!toolResult.active_user_label_set || !allToolLabels) return []
    // Ignore derivative labels on FocusCard
    const labelsWithoutDerivativeLabels = filterOutDerivativeLabels(allToolLabels)
    const labelIdsToUse = toolResult.active_user_label_set.tool_labels
    return labelsWithoutDerivativeLabels.filter(toolLabel => labelIdsToUse.includes(toolLabel.id))
  }, [allToolLabels, toolResult.active_user_label_set])

  return (
    <LabelCard
      data-test="label-screen-item"
      id={getToolResultCardId(toolResult.id)}
      key={toolResult.id}
      className={Styles.toolResultCardContainer}
      active={selectedToolResult?.id === toolResult.id}
      data-test-attribute={
        selectedToolResult?.id === toolResult.id ? 'label-screen-item-active' : 'label-screen-item-inactive'
      }
      onClick={() => onSetToolResult(toolResult)}
      label={
        <>
          {params.showPredictionScore && toolResult.tool?.specification_name && (
            <div className={Styles.cardPredictionScore}>
              {getDisplayThreshold(toolResult.prediction_score, toolResult.tool.specification_name)}
            </div>
          )}

          {toolLabels && <LabelsList labels={toolLabels} data-testid="focus-card" />}
        </>
      }
      image={
        toolResult.aoi ? (
          <ImageCloseUp
            loaderType="skeleton"
            src={toolResult.picture.image}
            thumbnailSrc={toolResult.picture.image_thumbnail}
            region={toolResult.aoi}
            customStyle={{ opacity: 0.8 }}
            maskingRectangleFill={selectedToolResult?.id === toolResult.id ? '#1b212c' : '#111111'}
          />
        ) : null
      }
      imageClassName={Styles.imageToken}
      data-testid="test"
    />
  )
}

export default FocusCard

export const MemoFocusCard = React.memo(FocusCard)
