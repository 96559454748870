import React, { useCallback, useEffect, useMemo, useState } from 'react'

import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, useQuery } from 'api'
import Layout from 'components/Layout/Layout'
import { Modal } from 'components/PrismModal/PrismModal'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { useQueryParams } from 'hooks'
import paths from 'paths'
import { RecipeOverviewMode, RecipeRoutine } from 'types'

import Capture, { defaultItemCorrelation, defaultRecipeRoutineItemCorrelationSettings } from './Capture'
import Configure from './Configure/Configure'
import OverviewHeader from './OverviewHeader'
import Styles from './RecipeOverview.module.scss'
import RecipeViews from './RecipeViews'
import RoutineSettings from './RoutineSettings/RoutineSettings'
import Train from './Train/Train'
import ViewEmptyState from './ViewEmptyState'

/**
 * Component that fetches all relevant data needed for Recipe Setup. It's a
 * navigational component that lets us navigate between all recipe setup
 * options like: Capture, Tools, Train and settings
 */
function RecipeOverview({ recipeParentId, mode }: { recipeParentId: string; mode: RecipeOverviewMode }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams()

  const [modal, setModal] = useState<{
    title: string
    content?: string
    onOk?: () => Promise<boolean>
    onCancel?: () => void
    'data-testid'?: string
  }>()

  const recipeParent = useQuery(getterKeys.recipeParent(recipeParentId), () => service.getRecipeParent(recipeParentId))
    .data?.data

  const recipeVersion = params.recipeVersion

  const recipeId = recipeVersion ? recipeVersion : recipeParent?.working_version?.id

  const selectedRecipeVersion = useQuery(
    recipeId ? getterKeys.recipe(recipeId) : null,
    recipeId ? () => service.getRecipe(recipeId) : null,
  ).data?.data

  const { routineParentId: routineParentIdParam, toolParentId: toolParentIdParam } = params

  const routineParentId = useMemo(() => {
    if (routineParentIdParam) return routineParentIdParam

    // If we have a toolParentId in the QS, try to find its routine
    if (toolParentIdParam) {
      const foundRoutine = selectedRecipeVersion?.recipe_routines.find(({ routine }) => {
        const routineTools = routine.aois.flatMap(aoi => aoi.tools)
        return routineTools.find(tool => tool.parent_id === toolParentIdParam)
      })

      if (foundRoutine) return foundRoutine.routine.parent.id
    }

    // Default to first routine from recipe
    return selectedRecipeVersion?.recipe_routines[0]?.routine.parent.id || ''
  }, [routineParentIdParam, selectedRecipeVersion?.recipe_routines, toolParentIdParam])

  const routineParentsInRecipe = useMemo(() => {
    return selectedRecipeVersion?.recipe_routines.map(recipeRoutine => recipeRoutine.routine.parent.id)
  }, [selectedRecipeVersion])

  // We could have a valid routineParent UUID that doesn't belong to the currently selected recipe version, so we need to make sure we default to a valid RoutineParent
  useEffect(() => {
    if (!params.routineParentId || !routineParentsInRecipe) return
    if (!routineParentsInRecipe.includes(params.routineParentId)) {
      const newPath = {
        pathname: paths.settingsRecipe(recipeParentId, 'capture'),
        search: qs.stringify({ ...params, routineParentId: null }),
      }

      return history.replace(newPath)
    }
  }, [history, params, params.routineParentId, recipeParentId, routineParentsInRecipe])

  const selectedRecipeRoutine = selectedRecipeVersion?.recipe_routines.find(recipeRoutine => {
    return recipeRoutine.routine.parent.id === routineParentId
  })

  const selectedRoutineVersion = selectedRecipeRoutine?.routine
  const linkedRobotId = selectedRecipeRoutine?.robot_id

  const station = useQuery(
    selectedRecipeVersion?.parent.station_id ? getterKeys.station(selectedRecipeVersion.parent.station_id) : undefined,
    selectedRecipeVersion?.parent.station_id
      ? () => service.getStation(selectedRecipeVersion.parent.station_id)
      : undefined,
  ).data?.data

  // If recipe settings have not yet been set, set the default values
  useEffect(() => {
    async function setDefaultRecipeSettings() {
      if (!selectedRecipeVersion || selectedRecipeVersion.user_settings) return

      const res = await service.patchRecipe(selectedRecipeVersion.id, {
        user_settings: {
          item_correlation: defaultItemCorrelation,
        },
      })
      if (res.type === 'success') {
        query(getterKeys.recipe(selectedRecipeVersion.id), () => service.getRecipe(selectedRecipeVersion.id), {
          dispatch,
        })
      }
    }
    setDefaultRecipeSettings()
  }, [dispatch, selectedRecipeVersion])

  // If RecipeRoutine settings have not yet been set, set them based on the selected routine and robot
  useEffect(() => {
    async function setDefaultRecipeRoutineSettings() {
      if (!selectedRecipeVersion) return
      const recipeRoutinesToUpdate = selectedRecipeVersion.recipe_routines.filter(
        recipeRoutine => !recipeRoutine.user_settings,
      )

      if (!recipeRoutinesToUpdate.length) return

      const responses = await Promise.allSettled(
        recipeRoutinesToUpdate.map(async recipeRoutine => {
          // In practice, there won't ever be a Recipe that is protected but doesn't have settings set

          const recipeRoutineRes = await service.patchProtectedRecipeRoutine(recipeRoutine.id, {
            user_settings: { item_correlation: defaultRecipeRoutineItemCorrelationSettings },
          })

          return recipeRoutineRes
        }),
      )

      if (responses.every(res => res.status === 'fulfilled')) {
        query(getterKeys.recipe(selectedRecipeVersion.id), () => service.getRecipe(selectedRecipeVersion.id), {
          dispatch,
        })
      }
    }
    setDefaultRecipeRoutineSettings()
  }, [selectedRecipeVersion, dispatch])

  const updateVersionDrawer = useCallback(
    (recipeVersion?: string) => {
      const updatedParams = { ...params, routineParentId, recipeVersion }
      history.push(paths.settingsRecipe(recipeParentId, mode, updatedParams))
    },
    [history, mode, params, recipeParentId, routineParentId],
  )

  const isEmptyRecipe = selectedRecipeVersion?.recipe_routines.length === 0

  const renderContent = () => {
    if (!selectedRecipeVersion) return null
    if (isEmptyRecipe) return <ViewEmptyState recipe={selectedRecipeVersion} />

    const toRender = []
    if (mode !== 'settings' && selectedRecipeVersion.recipe_routines.length > 1) {
      toRender.push(
        <RecipeViews
          recipe={selectedRecipeVersion}
          routineParentId={routineParentId}
          onClick={(recipeRoutine: RecipeRoutine) => {
            history.push(
              paths.settingsRecipe(recipeParentId, mode, {
                ...params,
                routineParentId: recipeRoutine.routine.parent.id,
              }),
            )
          }}
        />,
      )
    }

    if (!selectedRoutineVersion || !routineParentId || !recipeParent) return toRender

    if (mode === 'capture') {
      toRender.push(
        <>
          <RobotsStatusListener robotIds={station?.robots.map(robot => robot.id)} />
          <Capture
            key={selectedRoutineVersion.id}
            routine={selectedRoutineVersion}
            routineParentId={routineParentId}
            recipe={selectedRecipeVersion}
            robotId={linkedRobotId}
            confirmRemoveReferencePhotoIsOpen={!!modal}
            versionDrawerOpen={!!recipeVersion}
            station={station}
          />
        </>,
      )
    }

    if (mode === 'configure') {
      toRender.push(
        <Configure
          readOnly={!!recipeVersion}
          routine={selectedRoutineVersion}
          recipe={selectedRecipeVersion}
          routineParentId={routineParentId}
        />,
      )
    }
    if (mode === 'train') {
      toRender.push(
        <Train
          readOnly={!!recipeVersion}
          routine={selectedRoutineVersion}
          recipe={selectedRecipeVersion}
          routineParentId={routineParentId}
        />,
      )
    }
    if (mode === 'settings') {
      toRender.push(
        <RoutineSettings readOnly={!!recipeVersion} routine={selectedRoutineVersion} recipe={selectedRecipeVersion} />,
      )
    }

    return toRender
  }
  return (
    <Layout
      titleClassName={`${Styles.recipeHeader} ${recipeVersion ? Styles.collapseHeader : ''}`}
      mainClassName={`${Styles.recipeBody} ${recipeVersion && !isEmptyRecipe ? Styles.collapse : Styles.expand}`}
      title={
        <OverviewHeader
          routineParentId={routineParentId}
          recipeParent={recipeParent}
          recipe={selectedRecipeVersion}
          recipeVersion={recipeVersion}
          updateVersionDrawer={updateVersionDrawer}
        />
      }
      backButtonOnClick={recipeVersion ? () => updateVersionDrawer() : undefined}
    >
      {!!modal && (
        <Modal
          id="update-view"
          data-testid={modal['data-testid'] ?? ''}
          size="small"
          header={modal?.title}
          onClose={() => {
            if (modal?.onCancel) modal.onCancel()
            setModal(undefined)
          }}
          onOk={async () => {
            await modal.onOk?.()
            setModal(undefined)
          }}
          okText="Update"
        >
          {modal?.content}
        </Modal>
      )}
      {renderContent()}
    </Layout>
  )
}

export default RecipeOverview
