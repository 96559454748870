import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Create Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1388%3A3705
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismCreateIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.createIcon} ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="New"
        d="M8 3.5V2.5H8.5V2H9.5V2.5H10.5V2H11.5V2.5H12.5V2H13.5V2.5H14.5V2H15.5V2.5H16.5V2H17.5V2.5H18.5V2H19.5V2.5H20.5V2H21.5V2.5L22 2.5V3.5H21.5V4.5H22V5.5H21.5V6.5H22V7.5H21.5V8.5H22V9.5H21.5V10.5H22V11.5H21.5V12.5H22V13.5H21.5V14.5H22V15.5H21.5V16H20.5V15.5H19.5V16H18.5V15.5H17.5V16H16.5V15.5H15.5V16H14.5V15.5H13.5V16H12.5V15.5H11.5V16H10.5V15.5H9.5V16H8.5V15.5H8V14.5H8.5V13.5H8V12.5H8.5V11.5H8V10.5H8.5V9.5H8V8.5H8.5V7.5H8V6.5H8.5V5.5H8V4.5H8.5V3.5H8Z"
        strokeLinejoin="round"
        strokeDasharray="1 1"
        className={`${Styles.stroke} ${Styles.fill}`}
      />
      <g id="Add">
        <path
          id="Add_2"
          d="M2.5 21.5V7.5H16.5V21.5H2.5Z"
          strokeLinejoin="round"
          className={`${Styles.stroke} ${Styles.fill}`}
        />
        <path id="Plus" d="M9.50001 11.7V17.3M6.70001 14.5H12.3" strokeLinecap="round" className={Styles.stroke} />
      </g>
    </svg>
  )
}
