import React from 'react'

import Styles from './LabelInfo.module.scss'

/**
 * Renders LabelInfo container with a title.
 *
 * @param title - container title
 * @param addDescriptionGap - Adds a gap of 8px between the title and the description
 * @param children - react children
 * @param className - string to add extra styles
 */
const LabelInfo = ({
  title,
  addDescriptionGap,
  children,
  className = '',
}: {
  title?: string
  addDescriptionGap?: boolean
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={`${Styles.labelingInfoItemContainer} ${addDescriptionGap ? Styles.verticalGap : ''} ${className}`}>
      <h3 className={Styles.itemTitle}>{title}</h3>
      {children}
    </div>
  )
}

export default LabelInfo
