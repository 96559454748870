import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { debounce } from 'lodash'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, useQuery, UsersData } from 'api'
import { BackTop, useBackTopWithClassName } from 'components/BackTop/BackTop'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismFilterIcon, PrismInfoIcon, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { PrismTabNav } from 'components/PrismTab/PrismTab'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useData, useIsFlourish, usePagination, useQueryParams } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { User, UserRole, usersTabs, usersTabsType } from 'types'
import { appendDataToQueryString, getAllUserRoles, getterAddPage, getTimeAgoFromDate, includes } from 'utils'

import UserConfigurationModal from './UserConfigurationModal'
import Styles from './UsersTable.module.scss'

const sortByActive = (a: User, b: User) => {
  if (b.user_org.is_active && !a.user_org.is_active) return 1
  if (a.user_org.is_active && !b.user_org.is_active) return -1
  return 0
}

const allRolesString = getAllUserRoles().join()

const UsersTable = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const me = useData(getterKeys.me())!
  const org = useData(getterKeys.organization())
  const { isFlourish } = useIsFlourish()

  const [selectedUserId, setSelectedUserId] = useState<string>()
  const [definedUsers, setDefinedUsers] = useState<User[]>([])
  const [userConfigurationModalVisible, setUserConfigurationModalVisible] = useState<boolean>(false)
  const [tableLoading, setTableLoading] = useState(false)
  const [params] = useQueryParams()

  const userTab: usersTabsType = usersTabs.includes(params.tab || '') ? (params.tab as usersTabsType) : 'active'

  const userDomain = me.email.split('@')[1]
  const hideElemUsers = org && !includes(org.email_domain_skip_eula_allowlist, userDomain)

  const requestParams = useMemo(
    () => ({
      ...params,
      role__in: params.role__in || allRolesString,
      is_user_org_active: userTab === 'active',
      email_domain__not_in: hideElemUsers ? org?.email_domain_skip_eula_allowlist.join(',') : null,
    }),
    [hideElemUsers, org?.email_domain_skip_eula_allowlist, params, userTab],
  )

  const { data: usersRes } = useQuery(getterKeys.usersList({ params: qs.stringify(params), hideElemUsers }), () =>
    service.getUsers(requestParams),
  )

  const { users, next } = useMemo(() => {
    if (!usersRes) return { users: undefined, next: null }
    const users = usersRes.data.results.sort(sortByActive)
    const next = usersRes.data.next || null
    return { users, next }
  }, [usersRes])

  // This is necessary so that when we're loading a filtered set of users, we show the loading
  // indicator (users = undefined), but we also show the previous list of users on the table. Without
  // This state and effect, the table falls back to an empty list which looks awkward.
  useEffect(() => {
    if (!users) return
    setDefinedUsers(users)
  }, [users])

  const columns: ColumnsType<User> = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'firstName',
      render: value => <PrismOverflowTooltip content={value} tooltipPlacement="bottom" />,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'lastName',
      render: value => <PrismOverflowTooltip content={value} tooltipPlacement="bottom" />,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: value => <PrismOverflowTooltip content={value} tooltipPlacement="bottom" />,
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'login',
      render: value => {
        return value ? (
          <PrismOverflowTooltip className={Styles.lastLoginText} content={getTimeAgoFromDate(value).text} />
        ) : (
          'Never'
        )
      },
    },
    {
      title: (
        <div className={Styles.tableRoleContainer}>
          Role{' '}
          <PrismTooltip
            title={
              <div className={Styles.roleInfo}>
                <b>Admin:</b> Can set up inspections, run them, label items, and monitor results. Can edit admins,
                editors, operators, and viewers.
                <br />
                <br />
                <b>Editor:</b> Can set up inspections, run them, label items, and monitor results. Can edit operators
                and viewers.
                <br />
                <br />
                <b>Operator:</b> Can run inspections, look at items, and monitor results.
                <br />
                <br />
                <b>Viewer:</b> Can observe inspections, look at items, and monitor results.{' '}
              </div>
            }
            anchorClassName={Styles.iconWrapper}
          >
            <PrismInfoIcon />
          </PrismTooltip>
        </div>
      ),
      dataIndex: 'role',
      key: 'role',
      filters: [
        { text: 'Viewer', value: 'member' },
        { text: 'Operator', value: 'inspector' },
        { text: 'Editor', value: 'manager' },
        { text: 'Admin', value: 'owner' },
      ],
      filterMultiple: false,
      defaultFilteredValue: params.role__in && params.role__in !== allRolesString ? [params.role__in] : null,
      render: (_, user) => {
        return (
          <div>
            <span className={Styles.roleValue}>{renderRole(user.user_org.role)}</span>
          </div>
        )
      },
      filterIcon: <PrismFilterIcon className={Styles.tableFilterIcon} />,
    },
  ]

  useBackTopWithClassName('ant-table-body')

  const handleEndReached = useCallback(async () => {
    if (!next) return
    setTableLoading(true)
    const res = await service.getNextPage<UsersData>(next)
    if (res.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.usersList({ params: qs.stringify(params), hideElemUsers }),
          updater: prevRes => getterAddPage(prevRes, res.data),
        }),
      )
    }
    setTableLoading(false)
  }, [dispatch, hideElemUsers, next, params])

  usePagination(handleEndReached, { node: document.querySelector('.ant-table-body'), overflowScroll: true })

  const handleSearch = useMemo(
    () => debounce((value: string) => appendDataToQueryString(history, { name_or_email: value }), 400),
    [history],
  )

  const handleCancel = () => {
    setSelectedUserId(undefined)
    setUserConfigurationModalVisible(false)
  }

  const handleRefresh = async () => {
    await query(
      getterKeys.usersList({ params: qs.stringify(params), hideElemUsers }),
      () => service.getUsers(requestParams),
      { dispatch },
    )

    setSelectedUserId(undefined)
    setUserConfigurationModalVisible(false)
  }

  const filterByRole = async (
    _: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
  ) => {
    if (!filters) return

    let role: UserRole | undefined
    if (filters.role) {
      role = filters.role[0] as UserRole
    }

    appendDataToQueryString(history, { role__in: role })
  }

  return (
    <PrismContainer
      title="Users"
      headerTitleAction={
        <div className={Styles.searchAndButtonContainer}>
          <PrismSearchInput
            size="extraSmall"
            className={Styles.searchFieldContainer}
            defaultValue={params.name_or_email}
            onInputChange={e => handleSearch(e.currentTarget.value)}
            placeholder="Search"
            inputDataTestId="user-table-search"
            showIconPrefix
          />

          <Button
            size="small"
            onClick={() => {
              setUserConfigurationModalVisible(true)
            }}
            data-testid="user-table-add"
            disabled={isFlourish}
          >
            Add User
          </Button>
        </div>
      }
      actions={
        <div className={Styles.headerNavTabs}>
          <PrismTabNav
            className={Styles.routineNavMenu}
            items={[
              {
                path: paths.administrationSettings({ mode: 'users', params: { ...params, tab: 'active' } }),
                label: 'Active',
                forceState: userTab === 'active',
              },
              {
                path: paths.administrationSettings({ mode: 'users', params: { ...params, tab: 'deleted' } }),
                label: 'Deleted',
                forceState: userTab === 'deleted',
              },
            ]}
          />
        </div>
      }
      invertTitleAndActions
      className={`${Shared.rightSectionContainer} ${Styles.userTable}`}
      headerActionsClassName={Styles.headerWrapper}
    >
      <div className={Styles.userTableContainer}>
        <BackTop scrollContainerClassName="ant-table-body" />
        <Table
          rowKey="id"
          className={Styles.tableContainer}
          rowClassName={Styles.tableRow}
          dataSource={definedUsers}
          loading={{
            spinning: !users,
            indicator: <PrismLoader className={Styles.loaderPosition} />,
          }}
          columns={columns}
          onChange={filterByRole}
          pagination={false}
          sticky={{
            offsetHeader: 0,
          }}
          onRow={record => {
            return {
              onClick: () => {
                setUserConfigurationModalVisible(true)
                setSelectedUserId(record.id)
              },
              'data-testid': 'user-table-row',
            }
          }}
          locale={{
            emptyText: (
              <GenericBlankStateMessage header={<PrismSearchIcon />} description="No results match your search" />
            ),
          }}
        />

        {tableLoading && (
          <div className={Styles.bottomSpinner}>
            <PrismLoader />
          </div>
        )}
      </div>

      {userConfigurationModalVisible && (
        <UserConfigurationModal
          user={users?.find(user => user.id === selectedUserId)}
          onRefresh={handleRefresh}
          onCancel={handleCancel}
        />
      )}
    </PrismContainer>
  )
}

export default UsersTable

export const renderRole = (role: UserRole | undefined) => {
  switch (role) {
    case 'member':
      return 'Viewer'
    case 'inspector':
      return 'Operator'
    case 'manager':
      return 'Editor'
    case 'owner':
      return 'Admin'
    default:
      return ''
  }
}
