import React, { useMemo, useState } from 'react'

import { getterKeys, wsPaths } from 'api'
import FullScreen, { FullScreenHeader } from 'components/FullScreen/FullScreen'
import { IconButton } from 'components/IconButton/IconButton'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { PrismElementaryCube, PrismOverflowIcon, PrismVideoLoadError } from 'components/prismIcons'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import { Status } from 'components/Status/Status'
import { FrameWithMeta } from 'components/StreamListener'
import { VideoFeed } from 'components/Video/VideoFeed'
import VideoWithLastFrameTime, { VideoTimestamp } from 'components/Video/VideoWithLastFrameTime'
import { useContainerDimensions, useData, useRobotStatus } from 'hooks'
import { Inspection, Robot, Routine, RoutineWithAois } from 'types'
import { getRobotDisplayName } from 'utils'

import { ImageWithAoiBoxes } from '../Components/ImageWithAoiBoxes'
import Styles from './CameraView.module.scss'

const SINGLE_COLUMN_BREAKPOINT = 1279

interface Props {
  options: Option<string>[]
  handleMenuItemClick: (value: string) => void
  hideFeed?: boolean
  className?: string
  cameraMenuClassName?: string
  robot: Robot
  showFullscreen: boolean
  setSelectedFullscreenRobotId: (robotId?: string) => void
  inspection: Inspection | undefined
  routines: RoutineWithAois[] | undefined
  isInspectingManualPhoto: boolean
  isLoading: boolean
  historicRoutinesByRobotId?: { [robotId: string]: Routine }
  displayRoutineGoldenImage?: boolean
  isPinned?: boolean
  onFrame?: (frameWithMeta: FrameWithMeta, robotId: string) => void
  pinnedFrameWithMeta?: FrameWithMeta
  camerasCount: number
  isSomeCameraPinned: boolean
  'data-testid'?: string
  routineRobotIds?: string[]
}

/**
 * Renders a camera container feed
 *
 * https://www.figma.com/file/bJmWHlYXuZVaCJZF1MZ0mk/Station-Detail-(main)?node-id=5513%3A40455
 *
 * @param options - Array of options showed on the option menu.
 * @param handleMenuItemClick - Click handler for the option menu.
 * @param hideFeed - If this feed should be hidden, eg. hide it from the left side if it is pinned.
 * @param className - Container classname.
 * @param robot - The robot to show the feed from.
 * @param showFullscreen - Boolean indicating if fullscreen for the feed is active.
 * @param setSelectedFullscreenRobotId - Handler used to reset the fullscreen mode.
 * @param inspection - current Inspection.
 * @param routines - array of current inspection routines
 * @param isInspectingManualPhoto - True if a manual inspection Item is being inspected
 * @param isLoading - Whether a manual inspected Item is loading
 * @param historicRoutinesByRobotId - historic Routines
 * @param displayRoutineGoldenImage - Whether we need to show the golden image
 * @param isPinned - Whether this is the pinned live feed, used to know wich video component to render
 * @param onFrame - on video frame handler
 * @param pinnedFrameWithMeta - Frame with metadata for pinned Camera
 */
const CameraFeed = ({
  options,
  handleMenuItemClick,
  hideFeed,
  className,
  cameraMenuClassName,
  robot,
  showFullscreen,
  setSelectedFullscreenRobotId,
  inspection,
  routines,
  isInspectingManualPhoto,
  isLoading,
  historicRoutinesByRobotId,
  displayRoutineGoldenImage,
  isPinned,
  onFrame,
  pinnedFrameWithMeta,
  camerasCount,
  isSomeCameraPinned,
  'data-testid': dataTestId,
  routineRobotIds,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const routineId = inspection?.inspection_routines.find(
    inspectionRoutine => inspectionRoutine.robot_id === robot.id,
  )?.routine_id
  const routine = routines?.find(routine => routine.id === routineId)
  const currentItemsByRobot = useData(inspection ? getterKeys.inspectionItemsByRobot(inspection.id) : undefined)

  const currentItem = currentItemsByRobot?.[robot.id]

  const streamSource = wsPaths.videoBaslerMedium(robot.id)

  const triggerMode = routine?.settings?.camera_trigger_mode
  const isHardwareTrigger = triggerMode === 'hardware'
  const isContinuousVideoFeed = inspection && routine ? !isHardwareTrigger : undefined
  const [isPinnedCamContinuousFeed, setIsPinnedCamContinuousFeed] = useState<boolean | undefined>(false)

  const aois = routine?.aois
  const status = useRobotStatus(robot.id)

  const originalVideoFeed = (
    <VideoWithLastFrameTime
      data-testid={dataTestId}
      data-test-attribute={!isLoading ? (showFullscreen ? `${dataTestId}-full-screen` : `${dataTestId}-ready`) : ''}
      key={robot.id}
      robotId={robot.id}
      relativeUrl={streamSource}
      intervalMs={routine?.settings?.interval_ms}
      waitingForFrameTimeoutMs={(routine?.settings?.interval_ms || 0) * 2}
      videoClassName={Styles.camImage}
      timestampClassName={Styles.camTimestamp}
      isContinuousVideoFeed={isContinuousVideoFeed}
      videoStyle={{ display: isInspectingManualPhoto && currentItem ? 'none' : 'block' }} // Don't unmount this, instead change to display none, so that we don't have to reconnect to WS
      onFrame={frameWithMeta => {
        onFrame?.(frameWithMeta, robot.id)
      }}
      fallbackImage={<PrismVideoLoadError className={Styles.videoLoadError} />}
      onIsContinuousFeedChange={setIsPinnedCamContinuousFeed}
    />
  )

  const pinnedVideoFeed = (
    <VideoFeed
      fallbackImage={<PrismVideoLoadError className={Styles.videoLoadError} />}
      alt={robot.name}
      frame={pinnedFrameWithMeta?.frame}
      messageId={pinnedFrameWithMeta?.meta?.message_id}
      videoStyle={{ display: isInspectingManualPhoto && currentItem ? 'none' : 'block' }}
      extraContent={
        <VideoTimestamp
          lastFrameTime={pinnedFrameWithMeta?.time}
          intervalMs={routine?.settings?.interval_ms}
          timestampClassName={Styles.camTimestamp}
        />
      }
      videoClassName={Styles.cameraFeedVideo}
      showErrorOnFrameTimeout={isPinnedCamContinuousFeed}
    />
  )

  const videoSnapshot = (
    <ImageWithAoiBoxes
      robotId={robot.id}
      aois={aois}
      inspectionId={inspection?.id}
      isLoading={isLoading}
      data-testid={`camera-feed-image-${robot.serial_number}`}
    />
  )

  const renderElement = () => {
    const showInspectedPhoto = isInspectingManualPhoto && routineRobotIds?.includes(robot.id)

    // Show the golden image if we're inspecting historical batches
    if (displayRoutineGoldenImage) {
      const fallbackImages = historicRoutinesByRobotId?.[robot.id]?.fallback_images
      const imgUrl = fallbackImages?.image || fallbackImages?.image_thumbnail

      if (!imgUrl) return <PrismElementaryCube />

      return <ImageWithBoxes src={imgUrl} />
    }

    // Show the pinned video feed component when the camera is pinned
    if (isPinned) {
      // Show the original video feed when the camera is pinned and we enter full screen mode
      if (showFullscreen) return showInspectedPhoto ? videoSnapshot : originalVideoFeed
      return showInspectedPhoto ? videoSnapshot : pinnedVideoFeed
    }

    // Show the original video feed if we're not inspecting images
    if (!showInspectedPhoto) return originalVideoFeed

    return videoSnapshot
  }

  const containerDimensions = useContainerDimensions()
  const { width: windowWidth } = containerDimensions

  const isMenuOnCenter = useMemo(() => {
    if (isPinned) return false

    if ((windowWidth && windowWidth <= SINGLE_COLUMN_BREAKPOINT) || isSomeCameraPinned || camerasCount >= 4) {
      return true
    }

    return false
  }, [camerasCount, isPinned, isSomeCameraPinned, windowWidth])

  return (
    <>
      {showFullscreen && (
        <FullScreen id="fullscreen-camera-feed" onClose={() => setSelectedFullscreenRobotId(undefined)}>
          <FullScreenHeader onCloseClick={() => setSelectedFullscreenRobotId(undefined)} />
          {renderElement()}
        </FullScreen>
      )}
      <figure
        data-testid={`${dataTestId}-feed-hover`}
        className={`${Styles.camContainer}
        ${hideFeed ? Styles.hidePinnedCam : ''}
         ${className ?? ''}`}
      >
        <>
          <span
            className={`${Styles.camDetails} ${menuOpen ? Styles.persistedOverlay : ''} ${
              !!displayRoutineGoldenImage ? Styles.historicCamDetails : ''
            }`}
          >
            <Status status={status} showLabel={false} className={Styles.camStatus} />
            <RobotDisplayName
              dataTestId={`camera-feed-${robot.id}-robot-name`}
              robotName={getRobotDisplayName(robot)}
              className={Styles.camName}
            />
          </span>

          {!isInspectingManualPhoto && (
            <div
              className={`${Styles.smallFeedOverlay} ${menuOpen && isMenuOnCenter ? Styles.persistedOverlay : ''}`}
            />
          )}
          {renderElement()}

          {!displayRoutineGoldenImage && (
            <>
              <div
                className={`${
                  isMenuOnCenter && isMenuOnCenter
                    ? `${Styles.menuOnCenter} ${cameraMenuClassName ?? ''}`
                    : Styles.menuOnCorner
                } ${menuOpen ? Styles.persistedOverlay : ''} `}
              >
                <OptionMenu
                  data-testid={
                    isPinned ? `camera-feed-options-pin-menu-${robot.id}` : `camera-feed-options-menu-${robot.id}`
                  }
                  menuContainerClassName={`${Styles.optionMenuList} ${
                    camerasCount === 4 ? Styles.forceShowPinOption : ''
                  } ${isMenuOnCenter ? '' : Styles.portalMenu}`}
                  className={isMenuOnCenter ? Styles.smallMenuContainer : ''}
                  onMenuItemClick={handleMenuItemClick}
                  openWithClick
                  options={options}
                  renderWithPortal
                  position={isSomeCameraPinned && !isPinned ? 'bottomRight' : 'bottomLeft'}
                  onShowMenuChange={setMenuOpen}
                  menuItemClassName={Styles.optionMenuItem}
                >
                  {isMenuOnCenter && <PrismOverflowIcon />}

                  {!isMenuOnCenter && <IconButton type="secondary" icon={<PrismOverflowIcon />} isOnTop />}
                </OptionMenu>
              </div>
            </>
          )}
        </>
      </figure>
    </>
  )
}

export default CameraFeed
