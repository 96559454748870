import React, { useState } from 'react'

import { clamp } from 'lodash'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { PrismInput } from 'components/PrismInput/PrismInput'
import ProtectedInputNumber, { InputNumberValue } from 'components/ProtectedInputNumber/ProtectedInputNumber'
import { MeasureToolSize, NonTrainableToolSettingsProps, RoutineWithAois, Tool } from 'types'
import { calculatePercentage } from 'utils'

import EditAoisHelper from './AOIEditing/EditAoisHelper'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders the left side of the Measure Tool. The user can choose min and max height and width
 * relative to the selected aoi
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */

export default function MeasureToolSettings({
  routine,
  recipe,
  tool,
  onExit,
  readOnly,
}: NonTrainableToolSettingsProps) {
  const [activeSizeBox, setActiveSizeBox] = useState<'min' | 'max'>()
  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
    watch,
    setValue,
  } = formData

  // These are percentages, but have to be transformed to 0-1 before they're stored
  const { min_height, min_width, max_height, max_width } = watch()

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const saveChanges = () => {
    const { aois, max_height, min_height, max_width, min_width } = getValues()
    const { width: aoiWidth, height: aoiHeight } = aois[0] || {}
    const { dirtyFields } = formState

    if (
      dirtyFields.aois ||
      dirtyFields.max_height ||
      dirtyFields.min_height ||
      dirtyFields.max_width ||
      dirtyFields.min_width
    ) {
      return [
        service.patchTool(tool.id, {
          inference_args: {
            target_min_height: Math.round((min_height / 100) * (aoiHeight || 1)),
            target_max_height: Math.round((max_height / 100) * (aoiHeight || 1)),
            target_max_width: Math.round((max_width / 100) * (aoiWidth || 1)),
            target_min_width: Math.round((min_width / 100) * (aoiWidth || 1)),
          },
        }),
      ]
    }
  }

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    wrapperClassName={Styles.settingsItemInput}
                  />
                )}
              />
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>
            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemTitle}>Pass Criteria</div>
              <div className={`${Styles.settingsItemCriteria}`}>
                <div className={Styles.settingsItemTitle}>Max Size</div>
                <div className={Styles.splitInputContainer}>
                  <Controller
                    control={control}
                    name="max_width"
                    render={({
                      onChange,
                      value,
                    }: {
                      onChange: (e: InputNumberValue) => any
                      value: number | undefined
                    }) => {
                      return (
                        <div className={`${Styles.sizeContainer} ${Styles.splitInput}`}>
                          <span className={Styles.sizeLabel}>W</span>

                          <div>
                            <ProtectedInputNumber
                              routine={routine}
                              recipe={recipe}
                              size="small"
                              value={value}
                              onChange={onChange}
                              placeholder="Select options"
                              disabled={readOnly}
                              formatter={percentageFormatter}
                              parser={percentageParser}
                              max={100}
                              min={0}
                              step={1}
                              precision={1}
                              onFocus={() => setActiveSizeBox('max')}
                              onBlur={() => {
                                setValue('min_width', clamp(min_width, 0, max_width))
                                setActiveSizeBox(undefined)
                              }}
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="max_height"
                    render={({
                      onChange,
                      value,
                    }: {
                      onChange: (e: InputNumberValue) => any
                      value: number | undefined
                    }) => {
                      return (
                        <div className={Styles.sizeContainer}>
                          <span className={Styles.sizeLabel}>H</span>

                          <div>
                            <ProtectedInputNumber
                              routine={routine}
                              recipe={recipe}
                              size="small"
                              value={value}
                              onChange={onChange}
                              placeholder="Select options"
                              disabled={readOnly}
                              formatter={percentageFormatter}
                              parser={percentageParser}
                              max={100}
                              min={0}
                              step={1}
                              precision={1}
                              onFocus={() => setActiveSizeBox('max')}
                              onBlur={() => {
                                setValue('min_height', clamp(min_height, 0, max_height))
                                setActiveSizeBox(undefined)
                              }}
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>
                <div className={Styles.settingsItemTitle}>Min Size</div>
                <div className={Styles.splitInputContainer}>
                  <Controller
                    control={control}
                    name="min_width"
                    render={({
                      onChange,
                      value,
                    }: {
                      onChange: (e: InputNumberValue) => any
                      value: number | undefined
                    }) => {
                      return (
                        <div className={`${Styles.sizeContainer} ${Styles.splitInput}`}>
                          <span className={Styles.sizeLabel}>W</span>

                          <div>
                            <ProtectedInputNumber
                              size="small"
                              routine={routine}
                              recipe={recipe}
                              value={value}
                              onChange={onChange}
                              placeholder="Select options"
                              disabled={readOnly}
                              formatter={percentageFormatter}
                              parser={percentageParser}
                              max={100}
                              min={0}
                              step={1}
                              precision={1}
                              onFocus={() => setActiveSizeBox('min')}
                              onBlur={() => {
                                setValue('max_width', clamp(max_width, min_width, 100))
                                setActiveSizeBox(undefined)
                              }}
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="min_height"
                    render={({
                      onChange,
                      value,
                    }: {
                      onChange: (e: InputNumberValue) => any
                      value: number | undefined
                    }) => {
                      return (
                        <div className={Styles.sizeContainer}>
                          <span className={Styles.sizeLabel}>H</span>

                          <div>
                            <ProtectedInputNumber
                              routine={routine}
                              recipe={recipe}
                              size="small"
                              value={value}
                              onChange={onChange}
                              placeholder="Select options"
                              disabled={readOnly}
                              formatter={percentageFormatter}
                              parser={percentageParser}
                              max={100}
                              min={0}
                              step={1}
                              precision={1}
                              onFocus={() => setActiveSizeBox('min')}
                              onBlur={() => {
                                setValue('max_height', clamp(max_height, min_height, 100))
                                setActiveSizeBox(undefined)
                              }}
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>
                <div className={Styles.settingsItemDescription}>
                  This tool will Pass if the observed width is {(+min_width).toFixed(1)}% to {(+max_width).toFixed(1)}%
                  of the search area and the observed height is {(+min_height).toFixed(1)}% to{' '}
                  {(+max_height).toFixed(1)}% of the search area.
                </div>
              </div>
            </div>
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              showSizeBoxes={!!activeSizeBox}
              minSizeBoxWidth={min_width / 100}
              minSizeBoxHeight={min_height / 100}
              maxSizeBoxWidth={max_width / 100}
              maxSizeBoxHeight={max_height / 100}
              activeSizeBox={activeSizeBox}
              disableRectangleRotation={false}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

function getDefaultFormValues(routine: RoutineWithAois, tool: Tool) {
  const args = tool.inference_args as MeasureToolSize
  const { name, aois } = getToolNameAndAois(routine, tool)
  const firstAoi = aois[0]
  return {
    aois,
    name,
    min_height:
      args['target_min_height'] !== undefined ? calculatePercentage(args['target_min_height'], firstAoi?.height) : 30,
    min_width:
      args['target_min_width'] !== undefined ? calculatePercentage(args['target_min_width'], firstAoi?.width) : 30,
    max_height:
      args['target_max_height'] !== undefined ? calculatePercentage(args['target_max_height'], firstAoi?.height) : 75,
    max_width:
      args['target_max_width'] !== undefined ? calculatePercentage(args['target_max_width'], firstAoi?.width) : 75,
  }
}

const percentageFormatter = (val: string | number | undefined) => `${val}%`
const percentageParser = (val: string | undefined) => (val ? val.replace(/%/g, '') : '')
