import React from 'react'

import { VERSION, VERSION_RELEASE_NOTES_URL } from 'env'
import { useIsColocated, useIsFlourish } from 'hooks'

import Styles from './AppVersion.module.scss'

/**
 * Renders app version (company wide version, not frontend cut version).
 * If the user is not colocated, app version is clickable and navigates to release notes.
 *
 * @param className - It is the parent's responsibility to position this component correctly
 * @param rockwellLogin - identifies the org to deny/access the click
 */

function AppVersion({ className, rockwellLogin }: { className?: string; rockwellLogin?: boolean }) {
  const { isColocated } = useIsColocated()
  const { isFlourish } = useIsFlourish()

  const canShowLink = !isFlourish && !isColocated && !rockwellLogin
  const navigateToReleaseDocs = () => {
    if (!canShowLink) return
    window.open(VERSION_RELEASE_NOTES_URL, '_blank')?.focus()
  }

  return (
    <span
      className={`${className || ''} ${Styles.appVersion} ${canShowLink ? Styles.appVersionHover : ''}`}
      onClick={canShowLink ? navigateToReleaseDocs : undefined}
      data-testid="app-version-link"
      data-test-attribute={canShowLink ? 'app-version-link-ready' : 'app-version-without-link'}
    >
      {VERSION}
    </span>
  )
}

export default AppVersion
