import React, { useState } from 'react'

import { Input, Radio } from 'antd'
import { clamp } from 'lodash'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'

import { Button } from 'components/Button/Button'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import PrismImageDropzone, { ImageDropzoneCaption } from 'components/PrismImageDropzone/PrismImageDropzone'
import { InputError, PrismInput } from 'components/PrismInput/PrismInput'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { FileImageRenderer } from 'components/UploadModal/UploadModal'
import { useDefaultToolLabels, useIsColocated } from 'hooks'
import { FileCustomError, ToolLabel, ToolLabelSeverity, ToolSpecificationName } from 'types'
import { getSeverityOptionsByTool, replaceAll, showCausesAndActions } from 'utils'
import { LABEL_ADDITIONAL_DESCRIPTION_FIELDS_LENGTH, LABEL_VALUE_MAX_LENGTH } from 'utils/constants'

import { LabelFormFields, unarchiveLabelConfirm } from './AddLabelModal'
import Styles from './LabelingScreen.module.scss'
import { ToolLabelPLCId } from './ToolLabelDetail'

interface Props<T extends 'create' | 'edit'> {
  specificationName: T extends 'create' ? ToolSpecificationName : undefined
  control: Control<LabelFormFields>
  errors: DeepMap<LabelFormFields, FieldError>
  selectedSeverity?: ToolLabelSeverity | ''
  mode: T
  handleArchive?: (archive: boolean) => Promise<void>
  toolLabel?: ToolLabel
  severityRadioOption?: ToolLabelSeverity
}

/**
 * Renders a form for creating or editing a tool label.
 *
 * @param specificationName - current tool specification name
 * @param control - the form control from useForm
 * @param errors - the form errors from useForm
 * @param selectedSeverity - the selected severity
 * @param mode - the form mode
 * @param handleArchive - function that handles archiving/unarchiving the tool label
 * @param toolLabel - if provided, the tool label to edit
 * @param sevetiryRadioOption - if provided, it will be the only severity option
 */
const ToolLabelForm = <T extends 'create' | 'edit'>({
  specificationName,
  control,
  errors,
  selectedSeverity,
  mode,
  handleArchive,
  toolLabel,
  severityRadioOption,
}: Props<T>) => {
  const { isColocated } = useIsColocated()
  const severityOptions =
    mode === 'create'
      ? severityRadioOption
        ? [severityRadioOption]
        : getSeverityOptionsByTool(specificationName!)
      : undefined

  const defaultLabels = useDefaultToolLabels()

  const showRootAndCorrectiveActions = selectedSeverity && showCausesAndActions(selectedSeverity)

  const renderTextAreaField = (name: string, title: string, footer: string) => (
    <Controller
      control={control}
      name={name}
      render={({ onChange, ...rest }) => (
        <LabelInfo title={title} addDescriptionGap>
          <Input.TextArea
            {...rest}
            className={Styles.itemTextArea}
            onChange={e => onChange(e.target.value)}
            data-testid={`tool-label-form-${name}`}
            maxLength={LABEL_ADDITIONAL_DESCRIPTION_FIELDS_LENGTH}
          />
          <p className={Styles.itemTextAreaCaption}>{footer}</p>
        </LabelInfo>
      )}
    />
  )

  return (
    <>
      {mode === 'edit' && (
        <>
          {toolLabel?.is_deleted && (
            <div className={Styles.archivedCaptionContainer}>
              <p className={Styles.archivedCaption}>
                This label is archived and will not appear as an option for labeling.
              </p>
              <Button
                type="link"
                onClick={() => unarchiveLabelConfirm(toolLabel?.value, async () => await handleArchive?.(false))}
                data-testid="tool-label-form-unarchive-label"
              >
                Unarchive
              </Button>
            </div>
          )}

          <LabelInfo title="Rename Label" addDescriptionGap>
            <Controller
              control={control}
              name="value"
              rules={{
                required: 'Name is required',
                validate: value => {
                  return !!defaultLabels?.find(
                    label =>
                      replaceAll(label.value, '_', ' ').trim().toLowerCase() ===
                      replaceAll(value, '_', ' ').trim().toLowerCase(),
                  )
                    ? 'This label already exists'
                    : true
                },
              }}
              render={({ onChange, ...rest }) => (
                <>
                  <PrismInput
                    onChange={e => onChange(e.target.value)}
                    maxLength={LABEL_VALUE_MAX_LENGTH}
                    {...rest}
                    data-testid="tool-label-form-rename-label"
                  />

                  {errors.value && <InputError>{errors.value.message}</InputError>}
                </>
              )}
            />
          </LabelInfo>
        </>
      )}
      <LabelInfo title="Severity" addDescriptionGap>
        {mode === 'create' && (
          <Controller
            control={control}
            name="severity"
            rules={{ required: 'Severity is required' }}
            render={({ onChange, ...rest }) => (
              <>
                <Radio.Group {...rest} onChange={e => onChange(e.target.value)}>
                  {severityOptions?.map(severity => (
                    <div key={severity} className={Styles.radioWithPrismResult}>
                      <Radio
                        key={severity}
                        value={severity}
                        data-test="tool-label-form-severity"
                        data-testid={`tool-label-form-${severity}-option`}
                      >
                        <div className={Styles.severityContainer}>
                          <PrismResultButton severity={severity} type="fill" className={Styles.addLabelResultStates} />

                          <p className={Styles.severityText}>{severity}</p>
                        </div>
                      </Radio>
                    </div>
                  ))}
                </Radio.Group>
                {errors.severity && <InputError>{errors.severity.message}</InputError>}
              </>
            )}
          />
        )}

        {mode === 'edit' && selectedSeverity && (
          <PrismResultButton
            severity={selectedSeverity}
            value={selectedSeverity}
            type="fill"
            className={Styles.severityContainer}
          />
        )}
      </LabelInfo>

      {!isColocated && (
        <LabelInfo title="Example Images" addDescriptionGap>
          <Controller
            control={control}
            name="user_images"
            render={({ onChange, value }) => <ToolLabelImagesForm onChange={onChange} value={value} />}
          />
        </LabelInfo>
      )}

      {renderTextAreaField('description', 'Description', 'What does this label look like? When does it apply?')}

      {showRootAndCorrectiveActions &&
        renderTextAreaField('rootCauses', 'Root Causes', 'What can cause this defect to occur?')}

      {showRootAndCorrectiveActions &&
        renderTextAreaField('correctiveActions', 'Corrective Actions', 'How can this defect be fixed?')}

      {mode === 'edit' && toolLabel && <ToolLabelPLCId toolLabel={toolLabel} />}
    </>
  )
}

export default ToolLabelForm

const MAX_TOOL_LABEL_IMAGES = 3

/**
 * Renders a list of images and upload boxes to upload images to a given label
 *
 * @param onChange - handler for when images get uploaded
 * @param value - list of current images
 * @returns
 */
const ToolLabelImagesForm = ({
  onChange,
  value,
}: {
  onChange: (value: LabelFormFields['user_images']) => void
  value: LabelFormFields['user_images']
}) => {
  const [fileError, setFileError] = useState<FileCustomError>(null)

  const totalCurrentImages = value.toUpload.length + value.current.length
  const missingImages = clamp(MAX_TOOL_LABEL_IMAGES - totalCurrentImages, 0, MAX_TOOL_LABEL_IMAGES)

  const onFilesToUploadChange = (filesToUpload: File[]) => {
    const updatedValue = { ...value }
    updatedValue.toUpload = filesToUpload
    onChange(updatedValue)
  }

  const removeFileToUpload = (file: File) => {
    const updatedValue = { ...value }
    updatedValue.toUpload = updatedValue.toUpload.filter(f => f !== file)
    onChange(updatedValue)
  }

  const removeSavedImage = (url: string) => {
    const updatedValue = { ...value }
    updatedValue.current = updatedValue.current.filter(savedImage => savedImage.url !== url)
    onChange(updatedValue)
  }

  const handleCurrentFileReplace = (newFiles: File[], urlToReplace: string) => {
    const newFile = newFiles[0]
    if (!newFile) return

    const updatedValue = { ...value }

    updatedValue.current = updatedValue.current.filter(({ url }) => url !== urlToReplace)
    updatedValue.toUpload = [...updatedValue.toUpload, newFile]

    onChange(updatedValue)
  }

  return (
    <>
      <div className={Styles.examplesImagesWrapper} data-testid="tool-label-form-upload-images-container">
        {value.current.map(labelImage => {
          const imageToUse = labelImage.thumbnail_url || labelImage.url
          return (
            <PrismImageDropzone
              key={imageToUse}
              setError={setFileError}
              setFilesToUpload={newFiles => handleCurrentFileReplace(newFiles, labelImage.url)}
              maxFiles={1}
              image={
                imageToUse && <ImgFallback src={imageToUse} loaderType="skeleton" className={Styles.labelFormImage} />
              }
              onRemoveImage={() => removeSavedImage(labelImage.url)}
            />
          )
        })}
        {value?.toUpload.map((file, fileIdx) => (
          <PrismImageDropzone
            key={`to-upload-${fileIdx}`}
            setError={setFileError}
            setFilesToUpload={newFiles => {
              const newFile = newFiles[0]
              if (!newFile) return
              const updatedFilesToUpload = [...value.toUpload]
              updatedFilesToUpload[fileIdx] = newFile
              onFilesToUploadChange(updatedFilesToUpload)
            }}
            maxFiles={1}
            image={<FileImageRenderer file={file} className={Styles.labelFormImage} />}
            onRemoveImage={() => removeFileToUpload(file)}
          />
        ))}

        {Array(missingImages)
          .fill(undefined)
          .map((_, idx) => {
            return (
              <PrismImageDropzone
                key={`input-${idx}`}
                setFilesToUpload={newFiles => {
                  onFilesToUploadChange([...value.toUpload, ...newFiles])
                }}
                setError={setFileError}
                maxFiles={missingImages}
                error={fileError}
                isEmpty
              />
            )
          })}
      </div>

      <ImageDropzoneCaption title="Upload example images or wait to label a few" error={fileError} />
    </>
  )
}
