import React, { useRef, useState } from 'react'

import { Tooltip } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { useTextIsOverflowing } from 'hooks'
import FullScreenLabelImages from 'pages/RoutineOverview/LabelingScreen/FullScreenLabelImages'
import { LabelGlossaryModal } from 'pages/RoutineOverview/LabelingScreen/LabelGlossaryModal'
import { LabelingInfoPopover } from 'pages/RoutineOverview/LabelingScreen/LabelingInfoPopover'
import { AllOrNone, ToolLabel, ToolLabelSeverity, ToolSpecificationName } from 'types'
import { getToolLabelImagesToShow } from 'utils'

import Styles from './PrismResultButton.module.scss'
import PrismResultIcon from './PrismResultIcon'

const prismSeverityTypeClass = {
  fill: Styles.prismResultBg,
  noFill: Styles.prismResultNoFilledBg,
  ghost: Styles.prismResultGhost,
  noColor: Styles.prismResultNoColor,
  pureWhite: Styles.prismResultPureWhite,
  iconContainerFill: Styles.prismResultIconContainerBg,
}

const prismSeverityBackgroundClass = {
  good: Styles.prismResultBgPass,
  critical: Styles.prismResultBgFail,
  minor: Styles.prismResultBgUnknown,
  neutral: Styles.prismResultBgDefault,
  pass: Styles.prismResultBgPass,
  fail: Styles.prismResultBgFail,
  unknown: Styles.prismResultBgDefault,
  discard: Styles.prismResultBgDefault,
  test_set: Styles.prismResultBgDefault,
}

const sizeClasses = {
  xSmall: Styles.prismResultXSmall,
  small: Styles.prismResultSmall,
  medium: Styles.prismResultMedium,
}

const borderClasses = {
  border: '',
  borderless: Styles.removeBorder,
}

// DL: probably rename this, as it is not only representing the severity
export type LabelButtonSeverity = ToolLabelSeverity | 'pass' | 'fail' | 'unknown' | 'discard' | 'test_set'

export type ResultIconType = 'fill' | 'noFill' | 'ghost' | 'noColor' | 'pureWhite' | 'iconContainerFill'
export type ResultIconSize = 'xSmall' | 'small' | 'medium'

type Props = {
  value?: string | React.ReactNode
  severity: LabelButtonSeverity
  type?: ResultIconType
  size?: ResultIconSize
  active?: boolean
  onClick?: React.MouseEventHandler
  disabled?: boolean
  className?: string
  iconClassName?: string
  toolParentId?: string
  toolSpecificationName?: ToolSpecificationName
  popoverPlacement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight'
  popoverClassName?: string
  isPdf?: boolean
  labelRef?: React.RefObject<HTMLDivElement>
  'data-testid'?: string
  'data-test'?: string
  borderType?: 'border' | 'borderless'
}
export interface ToolLabelUseCaseProps {
  toolLabel: ToolLabel
  showPopOverOnClick: boolean
}

export type PrismResultButtonProps = AllOrNone<ToolLabelUseCaseProps> & Props

/**
 * Renders a severity component
 * This component can be used in 2 ways, the first one by showing an icon and a label, and the second one only as an icon. The advantage of using this component instead of an icon is that the svg color can change through css.
 *
 * https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=1859%3A38657
 *
 * @param value - Adds a text to the right of the icon
 * @param severity - Tool label severity or result
 * @param type - controlls the background color, text color, and icon color. In case you want to see it with background use the type fill. In case you might need to see hover state or active state add the prop onClick. if there's no 'onClick' the background color will be shown but it won't change.
 * @param size - Adjusts the svg size and the text typography. However there are some specific times when typography doesn't match with the size. In that case a className should be used to add the proper typography. Default values xSmall={label, 12px}, small={caption, 16px} and medium={headingS, 20px}
 * @param active - Controls the button active state.
 * @param onClick - onClick handler
 * @param disabled - whether the button is disabled.
 * @param className - string class that will be passed down to the button
 * @param iconClassName - classname for the Icon element.
 * @param toolParentId - The current tool parent id, used in case glossary is opened from popover
 * @param toolSpecificationName - The current tool specification name, used in case glossary is opened from popover
 * @param toolLabel - Optional tool label, used by popover and Glossary functionality
 * @param showPopOverOnClick - If true a popover with default from provided ToolLabel is shown on click.
 * @param popoverPlacement - controls popover placement
 * @param popoverClassName - classname for popover container
 * @param isPdf - whether the button is being used inside PDF
 * @param data-testid - Used by automated tests
 * @param border - By default this component has been showing a transparent border, border can be taken out by using borderless option
 */
export const PrismResultButton = ({
  value,
  severity,
  type = 'noColor',
  size = 'medium',
  active,
  onClick,
  disabled,
  className,
  iconClassName,
  toolParentId,
  toolLabel,
  showPopOverOnClick = false,
  popoverPlacement = 'bottom',
  popoverClassName,
  isPdf,
  labelRef,
  borderType = 'border',
  'data-testid': dataTestId,
  'data-test': dataTest,
}: PrismResultButtonProps) => {
  const [showLabelPopover, setShowLabelPopover] = useState(false)
  const [showGlossary, setShowGlossary] = useState(false)
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>()

  const typeClass = prismSeverityTypeClass[type]
  const severityClass = prismSeverityBackgroundClass[severity]
  const sizeClass = sizeClasses[size]
  const borderClass = borderClasses[borderType]

  const handleClick: React.MouseEventHandler = e => {
    if (showPopOverOnClick) {
      e.stopPropagation()
      return
    }
    onClick?.(e)
  }

  const textContainerRef = useRef<null | HTMLDivElement>(null)
  const textIsOverflowing = useTextIsOverflowing(textContainerRef)

  const images = toolLabel ? getToolLabelImagesToShow(toolLabel, 'full') : undefined

  return (
    <>
      {selectedImageIdx !== undefined && images?.[selectedImageIdx] && (
        <FullScreenLabelImages
          imageUrls={images}
          selectedImageIdx={selectedImageIdx}
          setSelectedImageIdx={setSelectedImageIdx}
          label={toolLabel?.value}
        />
      )}
      <ConditionalWrapper
        condition={showPopOverOnClick}
        wrapper={children => (
          <LabelingInfoPopover
            open={showLabelPopover}
            onOpenChange={setShowLabelPopover}
            toolLabel={toolLabel!}
            placement={popoverPlacement}
            popoverClassName={popoverClassName}
            onClose={() => setShowLabelPopover(false)}
            setSelectedImageIdx={setSelectedImageIdx}
            onEditLabelClick={() => {
              setShowGlossary(true)
              setShowLabelPopover(false)
            }}
          >
            <Tooltip
              title={
                <div className={Styles.moreInfoTooltip} onClick={e => e.stopPropagation()}>
                  More Info
                </div>
              }
              overlayClassName={Styles.moreInfoTooltipWrapper}
            >
              {children}
            </Tooltip>
          </LabelingInfoPopover>
        )}
      >
        <div
          className={`${Styles.prismResult} ${borderClass} ${typeClass ?? ''} ${severityClass ?? ''}  ${
            sizeClass ?? ''
          } ${disabled ? Styles.disabled : ''} ${active ? Styles.prismResultActive : ''}  ${
            value ? Styles.prismResultValueContainer : ''
          }   ${onClick || showPopOverOnClick ? Styles.clickable : Styles.notClickable}  ${className ?? ''}`}
          onClick={handleClick}
          ref={labelRef}
        >
          <PrismResultIcon severity={severity} viewboxSize={size} iconClassName={iconClassName} />

          {value && (
            <Tooltip
              title={
                typeof value === 'string' && textIsOverflowing ? (
                  <div onClick={e => e.stopPropagation()}>{value}</div>
                ) : undefined
              }
              placement="bottom"
            >
              <p
                data-testid={`${dataTestId}-value`}
                data-test={dataTest}
                data-test-attribute={`${dataTestId}-severity-${severity}`}
                className={`${Styles.prismResultLabel} ${isPdf ? Styles.pdfTextColor : ''} `}
                ref={textContainerRef}
              >
                {value}
              </p>
            </Tooltip>
          )}
        </div>
      </ConditionalWrapper>

      {showGlossary && (
        <LabelGlossaryModal
          onClose={() => setShowGlossary(false)}
          toolParentId={toolParentId}
          initialSelectedToolLabel={toolLabel}
        />
      )}
    </>
  )
}
