import React from 'react'

import { GraphBlankState } from 'components/BlankStates/GraphBlankState'
import { CardBox } from 'components/CardBox/CardBox'
import { Divider } from 'components/Divider/Divider'
import { PrismElementaryCube, PrismExpandIcon } from 'components/prismIcons'

import TimelineStyles from './StationDetailTimeline.module.scss'

export const TimelineBlankState = () => {
  return (
    <div className={TimelineStyles.mainBody}>
      <div className={`${TimelineStyles.graphsColumnWrapper} ${TimelineStyles.graphsBlankWapper}`}>
        {Array(2)
          .fill(undefined)
          .map((_, i) => (
            <div className={TimelineStyles.graphBlankState} key={i}>
              <CardBox
                title={i === 0 ? 'yield / minute' : 'items / minute'}
                actions={<PrismExpandIcon className={TimelineStyles.expandGraphIcon} />}
                className={TimelineStyles.cardBoxGraphBlankState}
              >
                <GraphBlankState
                  className={TimelineStyles.graphEmptyBox}
                  rowsClassName={TimelineStyles.graphRowsContainer}
                  bottomClassName={TimelineStyles.bottomDashesContainer}
                  rows={6}
                />
              </CardBox>
            </div>
          ))}
      </div>

      <Divider type="vertical" className={TimelineStyles.divider} />
      <div className={TimelineStyles.timelineResultsScrollableContainer}>
        <div className={TimelineStyles.timelineBlankContainer}>
          <Divider type="vertical" className={TimelineStyles.timelineStampLine} />

          {Array(5)
            .fill(undefined)
            .map((_, i) => (
              <div className={TimelineStyles.timeStampAndCardBlankState} key={i}>
                <div className={TimelineStyles.timeStampBlankState}>--</div>
                <div className={TimelineStyles.cardContainerBlankState}>
                  <div className={TimelineStyles.cardImageContainer}>
                    <PrismElementaryCube className={TimelineStyles.emptyImage} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
