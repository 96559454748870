import React from 'react'

import Styles from './PrismMenuList.module.scss'

export type MenuListItem<T> = {
  label: string
  value: T
  isActive: boolean
  icon?: React.ReactNode
  dataTestId?: string
}

type Props<T> = {
  className?: string
  onClick: (selectedTabName: T) => void
  list: MenuListItem<T>[]
  'data-testid'?: string
}

/**
 * Renders a list of nav items
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4979%3A11873
 *
 * @param list – Array of { label, value, isActive } where label is the name of the link, value of the link and isActive change the style for the active tab.
 * @param item.label- item label to display
 * @param item.value - the item value
 * @param className – classname to use for the container
 */

export const PrismMenuList = <T extends string>({ className, onClick, list }: Props<T>) => {
  return (
    <nav className={`${Styles.navContainer} ${className ?? ''}`}>
      <ul className={Styles.listContainer}>
        {list.map(item => (
          <li
            onClick={() => onClick(item.value)}
            className={`${Styles.listItem} ${item.isActive && Styles.listItemActive} ${
              !!item.icon ? Styles.hasIcon : ''
            }`}
            key={item.value}
            data-testid={item.dataTestId}
            data-test-attribute={item.isActive && 'active'}
          >
            {!!item.icon && <>{item.icon}</>}
            <p className={Styles.itemTitle}>{item.label}</p>
          </li>
        ))}
      </ul>
    </nav>
  )
}
