import React from 'react'

import { GraphBlankState } from 'components/BlankStates/GraphBlankState'
import ToolBlankState from 'components/BlankStates/ToolBlankState'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { LabelCard } from 'components/LabelCard/LabelCard'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import Shared from 'styles/Shared.module.scss'

import Styles from './StationDetailTools.module.scss'

const TOOL_CARDS_TO_SHOW = 4

/** Renders a screen with placeholder content for when Station Detail Tools tab has no content */
export const ToolStationBlankState = () => {
  return (
    <main className={Styles.mainBody}>
      <div className={Styles.toolListBlankState}>
        {Array(3)
          .fill(undefined)
          .map((_, i) => (
            <ToolBlankState key={i} />
          ))}
      </div>
      <Divider type="vertical" className={Styles.divider} />

      <div className={`${Styles.toolMainDetails} ${Styles.mainDetailsBlankState} `}>
        <div className={Styles.toolHeader}>
          <div className={Styles.toolHeaderTitleContainer}>
            <h1 className={Styles.toolHeaderTitle}>Tool</h1>
            <p className={Styles.lastUpdateTitle}>--</p>
          </div>
          <div className={Styles.toolHeaderButtons}>
            <Button disabled size="small" type="secondary">
              settings
            </Button>
          </div>
        </div>
        <div className={`${Styles.toolMainBody} ${Shared.verticalChildrenGap32}`}>
          <section className={`${Styles.passCriteriaSection} ${Shared.verticalChildrenGap8}`}>
            <h3 className={Styles.sectionTitle}>Pass Criteria</h3>
            <p className={Styles.passCriteriaDescription}>--</p>
          </section>

          <div className={`${Styles.graphSection}  ${Shared.verticalChildrenGap16}`}>
            <div className={Styles.graphHeader}>
              <h3 className={Styles.sectionTitle}>Outcomes</h3>
            </div>
            <div className={Styles.graphBody}>
              <GraphBlankState rows={2} className={Styles.graphBlankState} />
            </div>
          </div>
          <section className={`${Styles.predictionsSection} ${Shared.verticalChildrenGap8}`}>
            <h3 className={Styles.sectionTitle}>Predictions</h3>
          </section>
          <section className={Styles.toolCardsWrapper}>
            <div className={`${Styles.toolCardsContainer} ${Styles.blankLabelContainer}`}>
              {Array(TOOL_CARDS_TO_SHOW)
                .fill(undefined)
                .map((_, i) => (
                  <ToolsEmptyStateCard key={i} />
                ))}
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}

interface ToolsEmptyStateCardProps {
  loader?: boolean
}

export function ToolsEmptyStateCard({ loader }: ToolsEmptyStateCardProps) {
  return (
    <LabelCard
      type="notClickable"
      image={loader ? <PrismSkeleton size="extraLarge" /> : <PrismElementaryCube className={Styles.emptyCube} />}
      label={<div className={Styles.cardLabelContainer}>--</div>}
    />
  )
}
