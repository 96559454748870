import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment-timezone'

import { useDateTimePreferences } from 'hooks'

type timeFormats = 'hh:mm:ss' | 'timePassed' | 'h:mm A' | 'H:mm' | 'h:mma'

export interface Props {
  startTime?: number
  specialFormat?: timeFormats
  interval?: number
}

/**
 * Renders a timer that shows the time in seconds -> minutes -> hours passed
 *
 * The user can reset the timer outside of this scope by just changing
 * the key handed to the timer when a desired event occurs.

 * @param startTime - Initializes the timer depending on startTime value
 * @param specialFormat - optional date format, one of 'hh:mm:ss', 'timePassed' or 'h:mm A'
 * @param interval - miliseconds after the timer is reset
*/
const Timer = ({ startTime = Date.now(), specialFormat, interval }: Props) => {
  const timerId = useRef<number>()
  // This ensures startTime doesn't restart each time we rerender
  const [startTimeState, setStartTimeState] = useState(startTime)
  const [latestTime, setLatestTime] = useState(Date.now())
  const { timeZone } = useDateTimePreferences()

  useEffect(() => {
    timerId.current = window.setInterval(() => {
      setLatestTime(Date.now())
      if (interval && latestTime - startTimeState > interval) {
        setStartTimeState(Date.now())
      }
    }, 1000)

    return () => window.clearInterval(timerId.current)
  }, [interval, latestTime, startTimeState])

  return getTimeDifference({ startTime: startTimeState, endTime: latestTime, specialFormat, timeZone })
}

export default Timer

export const getTimeDifference = ({
  startTime,
  endTime,
  specialFormat,
  timeZone,
}: {
  startTime: number
  endTime: number
  specialFormat?: timeFormats
  timeZone: string
}) => {
  const timeInMs = endTime - startTime
  const timeInSeconds = Math.round(timeInMs / 1000)
  if (timeInSeconds < 0) return null // Don't render negative times

  if (specialFormat)
    return (
      <span
        data-testid="timer-station-detail-timer"
        data-test-attribute={new Date(timeInSeconds * 1000).toLocaleString(undefined, { timeZone })}
      >
        {specialFormats[specialFormat](timeInSeconds, timeZone)}
      </span>
    )

  let text = `${timeInSeconds}s`
  if (timeInSeconds >= 60) text = `${Math.floor(timeInSeconds / 60)}m`
  if (timeInSeconds >= 3600) text = `${Math.floor(timeInSeconds / 3600)}h`

  return <span>{text}</span>
}

const specialFormats = {
  'hh:mm:ss': (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60)
    const seconds = timeInSeconds - hours * 3600 - minutes * 60
    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`
  },
  timePassed: (timeInSeconds: number) => {
    if (timeInSeconds > 60) {
      return `${Math.floor(timeInSeconds / 60)}m`
    }

    return `${Math.floor(timeInSeconds)}s`
  },
  'h:mm A': (timeInSeconds: number, timeZone: string) => {
    return moment(timeInSeconds * 1000)
      .tz(timeZone)
      .format('h:mm A')
  },
  'h:mma': (timeInSeconds: number, timeZone: string) => {
    return moment(timeInSeconds * 1000)
      .tz(timeZone)
      .format('h:mma')
  },
  'H:mm': (timeInSeconds: number, timeZone: string) => {
    return moment(timeInSeconds * 1000)
      .tz(timeZone)
      .format('H:mm')
  },
}
