import { ToolLabel } from 'types'

type LabelDescriptions = Required<Pick<ToolLabel, 'severity' | 'value' | 'description'>>

const defaultLabelDescriptions: LabelDescriptions[] = [
  {
    value: 'normal',
    severity: 'good',
    description: 'No anomaly is detected. The prediction score is above the threshold for good.',
  },
  {
    value: 'anomaly',
    severity: 'critical',
    description: 'A critical anomaly is detected. The prediction score is below the threshold for good.',
  },
  { value: 'untrained', severity: 'neutral', description: 'The tool was not trained and could not make a prediction.' },
  {
    value: 'anomaly',
    severity: 'minor',
    description:
      'A minor anomaly is detected. The prediction score is below the threshold for good, but above the threshold for critical.',
  },
  {
    value: 'correct_match',
    severity: 'good',
    description: 'The expected label is detected. The prediction score is above the threshold.',
  },
  {
    value: 'wrong_match',
    severity: 'critical',
    description: 'The expected label is not detected or the prediction score is below the threshold.',
  },
  { value: 'correct_barcode', severity: 'good', description: 'The expected barcode is detected.' },
  { value: 'no_barcode', severity: 'critical', description: 'No barcode was detected.' },
  { value: 'wrong_barcode', severity: 'critical', description: 'The expected barcode is not detected.' },
  { value: 'correct_text', severity: 'good', description: 'The expected text is detected.' },
  { value: 'no_text', severity: 'critical', description: 'No text is detected.' },
  { value: 'wrong_text', severity: 'critical', description: 'The expected text is not detected.' },
  { value: 'correct_color', severity: 'good', description: 'The expected color and pixel count is detected.' },
  { value: 'wrong_color', severity: 'critical', description: 'The expected color or pixel count is not detected.' },
  { value: 'correct_size', severity: 'good', description: 'The expected dimensions are detected.' },
  { value: 'wrong_size', severity: 'critical', description: 'The expected dimensions are not detected.' },
  { value: 'correct_alignment', severity: 'good', description: 'The image is correctly aligned.' },
  { value: 'wrong_alignment', severity: 'critical', description: 'The image is incorrectly aligned.' },
  {
    value: 'test_set',
    severity: 'neutral',
    description: 'The image is reserved for model testing and will be excluded from training.',
  },
  { value: 'discard', severity: 'neutral', description: 'The image is not relevant.' },
  { value: 'uncertain', severity: 'neutral', description: "It's unclear how this image should be labeled." },
]

export default defaultLabelDescriptions
