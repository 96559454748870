import React from 'react'

import { PrismElementaryCube } from 'components/prismIcons'

import Styles from './ImageWithBoxes.module.scss'

/**
 * Renders a cube with text for the item detail
 * @param displayText - display text if image is not stored
 * @param className - Adds an alternative selector for external styles
 */
const ImageNotStored = ({
  className = '',
  displayText,
  addBackground,
}: {
  className?: string
  displayText?: string
  addBackground?: boolean
}) => {
  return (
    <div className={`${Styles.imageNotStoredWrapper} ${className}`}>
      <div className={`${Styles.imageNotStored} ${displayText ? Styles.enlargedImage : ''}`}>
        <PrismElementaryCube addBackground={addBackground} />
        {displayText && <p className={Styles.imageNotStoredDescription}>{displayText}</p>}
      </div>
    </div>
  )
}

export default ImageNotStored
