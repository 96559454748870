import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import { Station } from 'types'
import { getRobotDisplayName, matchRole } from 'utils'

import Styles from './EditStationCamerasModal.module.scss'

interface Props {
  station: Station
  handleClose: () => any
}

/**
 * Renders modal for editing a station's or robot's name.
 *
 * @param station - Station that holds robots.
 * @param handleClose - Callback to close modal.
 */
function EditStationCamerasModal({ station, handleClose }: Props) {
  const defaultValues = getDefaultValues(station)

  const {
    formState: { isValid, isDirty, errors },
    formState,
    control,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const saveStation = async () => {
    const valid = await trigger()
    if (!valid) return

    const robotNamesById = getValues()
    const { dirtyFields } = formState

    const somethingWentWrong = (customMsg?: string) => {
      error({ title: customMsg || 'There seems to be a connection problem, please try again' })
      setLoading(false)
      handleClose()
    }

    setLoading(true)

    for (const robot of station.robots) {
      if (dirtyFields[robot.id]) {
        const patchRobotRes = await service.patchRobot(robot.id, { body: { name: robotNamesById[robot.id] } })
        if (patchRobotRes.type !== 'success')
          return somethingWentWrong("There was a problem editing one of the camera's name")
      }
    }

    success({ title: 'Cameras edited' })
    await query(getterKeys.station(station.id), () => service.getStation(station.id), { dispatch })
    setLoading(false)
    handleClose()
  }

  return (
    <Modal
      id="edit-cameras"
      header="Edit Cameras"
      onClose={handleClose}
      onOk={saveStation}
      okText="Save"
      disableSave={!matchRole('manager') || !isValid || !isDirty}
      size="largeSimpleForm"
      data-testid="station-modal-edit"
    >
      <div className={Styles.modalBody}>
        {station.robots.map(robot => {
          return (
            <div key={robot.id}>
              <RobotDisplayName robotName={getRobotDisplayName(robot)} className={Styles.stationName} dataTestId="" />
              <Controller
                name={robot.id}
                rules={{ required: 'Camera Name is required' }}
                control={control}
                render={props => (
                  <PrismInput
                    {...props}
                    placeholder="Name this camera"
                    disabled={loading || !matchRole('manager')}
                    errors={errors}
                    data-testid={`station-modal-edit-camera-name-${robot.id}`}
                  />
                )}
              />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

const getDefaultValues = (station: Station) => {
  const values: { [key: string]: string } = {}
  station.robots.forEach(robot => {
    values[robot.id] = robot.name || ''
  })
  return values
}

export default EditStationCamerasModal
