import { useQuery } from 'react-redux-query'

import { getterKeys, service } from 'api'
import { RoutineSlot } from 'types'

/**
 * Renders nothing. Makes sure we eventually fetch RoutineSlots for robot.
 *
 * @param robotId - Robot id
 */
const RobotRoutineSlotsFetcher = ({ robotId }: { robotId: string }) => {
  useQuery(getterKeys.robotRoutineSlots(robotId), () =>
    service.atomSendCommandExtractData<RoutineSlot[]>('integration-framework', 'get_slots', robotId, {
      command_args: {},
    }),
  )

  return null
}

export default RobotRoutineSlotsFetcher
