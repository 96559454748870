import React, { useMemo, useRef } from 'react'

import { FixedSizeGrid, GridChildComponentProps, GridOnItemsRenderedProps } from 'react-window'

import { useContainerDimensions, useGridDimensions } from 'hooks'
import { ToolResult } from 'types'
import {
  LABELING_GALLERY_CARD_HEIGHT,
  LABELING_GALLERY_CARD_MIN_WIDTH,
  LABELING_GALLERY_GRID_GAP,
} from 'utils/constants'

import { MemoGalleryCard } from './GalleryCard'
import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'

/**
 * This is a wrapper around FixedSizeGrid that allows us to use the same behavior for
 * the Labeling Screen and the expanded Group By carousels.
 *
 * @param uniqueToolResults - The unique toolResults that are available to the user
 * @param toolParentId - The current tool parent id
 * @param showInsights - Whether or not to show the insights
 * @param setLastSelectedToolId - Function that sets the last selected toolResult id
 * @param onHoveredRangeChange - Function that handles the calculation of the hovered range
 * @param hoveredRange - The current hovered range
 * @param setHoveredRange - Function that sets the hovered range
 * @param shiftPressed - Whether or not the shift key is pressed
 * @param hoveredToolResultId - The id of the current hovered toolResult
 * @param setHoveredToolResultId - Function that sets the current hovered toolResult id
 * @param selectedToolResults - The currently selected toolResults
 * @param setSelectedToolResults - Function that sets the currently selected toolResults
 * @param fetchNextPage - Function that fetches the next page of toolResults
 * @param gridRef - The React ref for the grid
 * @param galleryBodyRef - The React ref for the gallery body
 * @param outerRef - The React ref for the outer container, used to handle the scroll bar
 * @param style - Styles provided by ReactWindowElementScroller
 * @param onScroll - function provided by ReactWindowElementScroller
 */
const GalleryCardsGrid = ({
  uniqueToolResults,
  toolParentId,
  showInsights,
  setLastSelectedToolResultId,
  onHoveredRangeChange,
  hoveredRange,
  setHoveredRange,
  shiftPressed,
  hoveredToolResultId,
  setHoveredToolResultId,
  selectedToolResults,
  onSelectedToolResultsChange,
  fetchNextPage,
  gridRef,
  galleryBodyRef,
  outerRef,
  style,
  onScroll,
  groupId,
}: {
  uniqueToolResults?: ToolResult[]
  toolParentId: string
  showInsights: boolean
  setLastSelectedToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  onHoveredRangeChange: (toolResultId: string) => void
  hoveredRange: ToolResultsMap
  setHoveredRange: ToolResultsMapDispatch
  shiftPressed: boolean
  hoveredToolResultId: string | null
  setHoveredToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  selectedToolResults: ToolResultsMap
  onSelectedToolResultsChange: (toolResults: ToolResultsMap) => void
  fetchNextPage: () => any
  gridRef?: React.RefObject<any>
  galleryBodyRef: React.RefObject<HTMLElement>
  outerRef?: any
  style?: any
  onScroll?: any
  groupId?: string
}) => {
  // Pagination refs and state
  const isFetchingMoreRef = useRef(false)
  const containerDimensions = useContainerDimensions(galleryBodyRef)
  const { rowCount, columnCount, columnWidth, rowHeight } = useGridDimensions(
    (containerDimensions?.width || 0) - LABELING_GALLERY_GRID_GAP - 6,
    uniqueToolResults?.length,
    {
      gridGap: LABELING_GALLERY_GRID_GAP,
      minWidth: LABELING_GALLERY_CARD_MIN_WIDTH,
      elementRowHeight: LABELING_GALLERY_CARD_HEIGHT,
    },
  )

  const handleItemsRendered = async ({ visibleRowStopIndex }: GridOnItemsRenderedProps) => {
    if (isFetchingMoreRef.current) return
    if (visibleRowStopIndex + 5 >= rowCount) {
      isFetchingMoreRef.current = true
      await fetchNextPage()
      isFetchingMoreRef.current = false
    }
  }

  return (
    <FixedSizeGrid
      ref={gridRef}
      width={(containerDimensions?.width || 0) - 24}
      height={containerDimensions?.height || 0}
      rowCount={rowCount}
      columnCount={columnCount}
      columnWidth={columnWidth}
      rowHeight={rowHeight}
      className={Styles.virtualizedGridContainer}
      onItemsRendered={handleItemsRendered}
      itemData={{
        uniqueToolResults,
        columnCount,
        showInsights,
        setLastSelectedToolResultId,
        toolParentId,
        onHoveredRangeChange,
        hoveredRange,
        setHoveredRange,
        shiftPressed,
        hoveredToolResultId,
        setHoveredToolResultId,
        selectedToolResults,
        onSelectedToolResultsChange,
        groupId,
      }}
      outerRef={outerRef}
      style={style}
      onScroll={onScroll}
    >
      {GalleryCardGridRenderer}
    </FixedSizeGrid>
  )
}

/**
 * GalleryCard component used for performant pagination implementations.
 */
const GalleryCardGridRenderer = React.memo(
  ({
    rowIndex,
    columnIndex,
    style,
    data,
  }: GridChildComponentProps<{
    uniqueToolResults: ToolResult[] | undefined
    columnCount: number
    showInsights: boolean
    setLastSelectedToolResultId: React.Dispatch<React.SetStateAction<string | null>>
    onHoveredRangeChange: (toolResultId: string) => void
    hoveredRange: ToolResultsMap
    setHoveredRange: ToolResultsMapDispatch
    shiftPressed: boolean
    hoveredToolResultId: string | null
    setHoveredToolResultId: React.Dispatch<React.SetStateAction<string | null>>
    selectedToolResults: ToolResultsMap
    onSelectedToolResultsChange: (toolResults: ToolResultsMap) => void
    groupId?: string
    toolParentId: string
  }>) => {
    const {
      uniqueToolResults,
      columnCount,
      showInsights,
      setLastSelectedToolResultId,
      onHoveredRangeChange,
      hoveredRange,
      setHoveredRange,
      shiftPressed,
      hoveredToolResultId,
      setHoveredToolResultId,
      selectedToolResults,
      onSelectedToolResultsChange,
      groupId,
      toolParentId,
    } = data

    const listIndex = rowIndex * columnCount + columnIndex

    const toolResult = uniqueToolResults?.[listIndex]

    const toolResultsCount = useMemo(() => {
      return Object.keys(selectedToolResults).length
    }, [selectedToolResults])

    if (!toolResult) return null

    return (
      <div className={Styles.galleryCardWrapper} style={style}>
        <MemoGalleryCard
          key={toolResult.id}
          data-test-attribute={listIndex.toString()}
          toolResult={toolResult}
          showInsights={showInsights}
          setLastSelectedToolResultId={setLastSelectedToolResultId}
          onHoveredRangeChange={onHoveredRangeChange}
          hoveredRange={hoveredRange}
          setHoveredRange={setHoveredRange}
          shiftPressed={shiftPressed}
          isHovered={hoveredToolResultId === toolResult.id}
          setHoveredToolResultId={setHoveredToolResultId}
          selectedToolResults={selectedToolResults}
          onSelectedToolResultsChange={onSelectedToolResultsChange}
          allSelectedToolResultsCount={toolResultsCount}
          groupId={groupId}
          toolParentId={toolParentId}
        />
      </div>
    )
  },
)

export default GalleryCardsGrid
