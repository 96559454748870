import React, { useEffect, useMemo } from 'react'

import { message } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import SessionExpiration from 'components/SessionExpiration/SessionExpiration'
import { useData, useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { Organization, UserOrg } from 'types'
import {
  getSessionLengthDisplayText,
  getSessionLengthSecondsFromFormValues,
  getSessionLengthUnitsAndTime,
  matchRole,
} from 'utils'

import Styles from './Settings.module.scss'

const errorTypes = {
  noMatch: 'no-match',
  wrongFormat: 'wrong-format',
  required: 'required',
}

// Checks that the input is at least 8  characters long. This is an external function in case we wish to add some more validations in the future, e.g. at least one uppercase or number or character
const validatePasswordType = (password?: string, confirmPassword?: string, checkConfirmationPassword?: boolean) => {
  if (!password) return

  if (checkConfirmationPassword && confirmPassword) {
    if (password !== confirmPassword) return 'Make sure New Password matches Confirm New Password'
    if (confirmPassword.length < 8) return 'Your password must be at least 8 characters long'

    return
  }

  if (password.length < 8) return 'Your password must be at least 8 characters long'
}

const ChangePasswordForm = () => {
  const isUserFromIDP = useTypedSelector(state => state.auth.auth?.uses_sso)
  const dispatch = useDispatch()

  const currentUserOrg = useTypedSelector(state => {
    return state.currentOrg
  })
  const organization = useData(getterKeys.organization())

  const defaultValues = getDefaultValues(currentUserOrg, organization, isUserFromIDP)
  const {
    formState: { isDirty, isValid, errors, dirtyFields },
    control,
    reset,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { currentPassword, newPassword, confirmNewPassword, sessionLengthTime, sessionLengthUnits } = watch()

  const isFormValid = useMemo(() => {
    const passwords = [currentPassword, newPassword, confirmNewPassword]
    // If we have a password field with a value, we also need to have every password field with values
    if (passwords.some(pass => !!pass) && !passwords.every(pass => !!pass)) return false
    return isValid
  }, [currentPassword, newPassword, confirmNewPassword, isValid])

  useEffect(() => {
    if (!currentUserOrg || !organization) return

    reset(getDefaultValues(currentUserOrg, organization, isUserFromIDP))
  }, [currentUserOrg, reset, organization, isUserFromIDP])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid || !currentUserOrg) return

    if (dirtyFields.sessionLengthTime || dirtyFields.sessionLengthUnits) {
      const { sessionLengthTime, sessionLengthUnits } = getValues()

      const sessionLengthSeconds = getSessionLengthSecondsFromFormValues({ sessionLengthTime, sessionLengthUnits })
      const res = await service.updateUserOrg(currentUserOrg?.id, { session_length_s: sessionLengthSeconds })

      if (res.type !== 'success') return error({ title: 'There was a problem updating your personal information' })

      success({ title: 'Information updated', 'data-testid': 'profile-update-success' })
      dispatch(Actions.orgUpdate({ session_length_s: sessionLengthSeconds }))
    }

    if (dirtyFields.newPassword) {
      const { currentPassword, newPassword } = getValues()

      if (!currentPassword || !newPassword) return

      const res = await service.setPassword(currentPassword, newPassword)

      if (res.type === 'success') {
        service.logout({ retry: { retries: 3, delay: 5000 } })
        dispatch(Actions.authUnset())
        return success({ title: 'Saved' })
      } else if (res.type === 'error' && res.data?.code === 'password_too_common') {
        message.error('Your password is too common')
      } else
        modal.error({
          id: 'change-password-error',
          header: "Couldn't change password",
          okText: 'Dismiss',
          content: (
            <div>
              <br />- Make sure you typed your current password correctly
              <br />- Your new password must not be too generic, e.g. "password"
            </div>
          ),
        })
      reset(getDefaultValues(currentUserOrg, organization, isUserFromIDP))
    }
  }

  /** This functions checks the password and triggers validation for confirmPassword.
   */
  const validatePasswords = () => {
    const { newPassword, confirmNewPassword } = getValues()
    if (newPassword) {
      if (confirmNewPassword) trigger('confirmNewPassword')

      return validatePasswordType(newPassword)
    }
  }

  /** This functions checks the password and triggers validation for newPassword.
   */
  const validateConfirmationPassword = () => {
    const { newPassword, confirmNewPassword } = getValues()
    if (confirmNewPassword) {
      if (newPassword) trigger('currentPassword')

      return validatePasswordType(newPassword, confirmNewPassword, true)
    }
  }

  return (
    <>
      <LeavePagePrompt when={isDirty} />

      <PrismContainer
        headerElementsAlign="vertical"
        title="Security"
        className={Shared.rightSectionContainer}
        bodyClassName={Styles.bodyContainer}
        actions={
          <Button
            htmlType="submit"
            disabled={!isDirty || !isFormValid}
            onClick={handleSubmit}
            size="small"
            data-testid="password-save-button"
          >
            Save
          </Button>
        }
      >
        <div className={`${Styles.formWrapper} ${Styles.passwordLayout}`}>
          {matchRole('manager') && (
            <>
              <div className={`${Styles.formContainer} ${Shared.verticalChildrenGap24} ${Styles.expirationSection}`}>
                <h2 className={Styles.formSectionTitle}>Session Expiration</h2>

                <SessionExpiration
                  formProps={{
                    control: control,
                    errors: errors,
                    inputTrigger: () => trigger('sessionLengthUnits'),
                    selectTrigger: () => trigger('sessionLengthTime'),
                    getValues: getValues,
                  }}
                  sessionLengthCaption={`You’ll be logged out automatically after
                    ${getSessionLengthDisplayText(sessionLengthTime, sessionLengthUnits)}.`}
                  labelClassName={Styles.sessionLengthTitle}
                />
              </div>
              <Divider />
            </>
          )}
          <div className={`${Styles.formContainer} ${Shared.verticalChildrenGap24} ${Styles.passwordSection}`}>
            <div className={Shared.verticalChildrenGap8}>
              <h2 className={Styles.formSectionTitle}>Change Password</h2>
              <p className={Styles.formSectionSubTitle}>
                {isUserFromIDP ? (
                  <>
                    Your account is managed by an external identity provider. <br />
                    Contact your IdP administrator to change your password.
                  </>
                ) : (
                  <>You will be logged out after changing your password.</>
                )}
              </p>
            </div>
            {!isUserFromIDP && (
              <>
                <Controller
                  name="currentPassword"
                  rules={{ validate: validatePasswordType }}
                  control={control}
                  render={props => (
                    <PasswordInput
                      {...props}
                      wrapperClassName={Styles.inputWrapper}
                      errors={errors}
                      label="Current Password"
                      data-testid="password-current"
                    />
                  )}
                />

                <Controller
                  name="newPassword"
                  rules={{ validate: () => validatePasswords() }}
                  control={control}
                  render={props => (
                    <PasswordInput
                      {...props}
                      wrapperClassName={Styles.inputWrapper}
                      errors={errors}
                      label="New Password"
                      data-testid="password-new"
                    />
                  )}
                />

                <Controller
                  name="confirmNewPassword"
                  rules={{ validate: () => validateConfirmationPassword() }}
                  control={control}
                  render={props => (
                    <PasswordInput
                      {...props}
                      wrapperClassName={Styles.inputWrapper}
                      errors={errors}
                      label="Confirm new password"
                      data-testid="password-confirm"
                    />
                  )}
                />
                {errors.confirmNewPassword?.message === errorTypes.noMatch && (
                  <div className={Styles.inlineErrorMessage}>Make sure New Password matches Confirm New Password</div>
                )}
              </>
            )}
          </div>
        </div>
      </PrismContainer>
    </>
  )
}

export default ChangePasswordForm

const getDefaultValues = (
  userOrg: UserOrg | null,
  organization: Organization | undefined,
  isUserFromIDP: boolean | undefined,
): {
  currentPassword?: string
  newPassword?: string
  confirmNewPassword?: string
  sessionLengthTime: number
  sessionLengthUnits: 'hour' | 'day' | 'minute'
} => {
  const timeAndUnits = getSessionLengthUnitsAndTime(userOrg?.session_length_s || organization?.session_length_s)
  if (isUserFromIDP)
    return {
      sessionLengthTime: timeAndUnits.time,
      sessionLengthUnits: timeAndUnits.unit,
    }

  return {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    sessionLengthTime: timeAndUnits.time,
    sessionLengthUnits: timeAndUnits.unit,
  }
}
