import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Expand Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1567%3A15
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismExpandIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.21 6.78578C13.21 6.50963 13.4339 6.28578 13.71 6.28578L17.2063 6.28578C17.3389 6.28578 17.466 6.33845 17.5598 6.43222C17.6536 6.52599 17.7063 6.65317 17.7063 6.78577L17.7063 10.29C17.7063 10.5661 17.4824 10.79 17.2063 10.79C16.9301 10.79 16.7063 10.5661 16.7063 10.29L16.7063 7.99289L7.99922 16.7L10.29 16.7C10.5661 16.7 10.79 16.9238 10.79 17.2C10.79 17.4761 10.5661 17.7 10.29 17.7L6.79211 17.7001C6.6595 17.7001 6.53232 17.6474 6.43855 17.5536C6.34478 17.4598 6.2921 17.3327 6.2921 17.2001L6.2921 13.7C6.2921 13.4239 6.51596 13.2 6.7921 13.2C7.06824 13.2 7.2921 13.4239 7.2921 13.7L7.2921 15.9929L15.9992 7.28578L13.71 7.28578C13.4339 7.28578 13.21 7.06192 13.21 6.78578Z"
      />
    </svg>
  )
}
