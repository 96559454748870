import React from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { PdfChartData } from 'types'

import styles from './styles'

/**
 * Renders the overview in pdf format. This should contain simply charts with title, subtitle and an image
 *
 * @param charts - Charts data to be rendered in the pdf
 * @param orientation - PDF selected orientation to render
 */
export const PdfDashboard = ({
  charts,
  orientation,
}: {
  charts: PdfChartData[]
  orientation: 'portrait' | 'landscape'
}) => {
  return (
    <View style={styles.gridCardWrapper}>
      {charts.map(
        (chart, k) =>
          chart && (
            <View
              key={k}
              style={orientation === 'landscape' ? styles.landscapeGraphContainer : styles.graphContainer}
              wrap={false}
            >
              <View style={styles.graphHeader}>
                <View style={styles.graphHeaderTitle}>
                  <Text>{chart.title}</Text>
                </View>

                <View style={chart.subtitleSize === 'small' ? styles.smallText : styles.bigText}>
                  <Text>{chart.subtitle}</Text>
                </View>
              </View>
              <View style={styles.graphBody}>
                {chart.imageUrl && <Image src={chart.imageUrl} />}
                {!chart.imageUrl && chart.fallbackText && (
                  <View style={styles.graphBodyEmpty}>
                    <Text style={styles.graphBodyEmptyText}>{chart.fallbackText}</Text>
                  </View>
                )}
              </View>
            </View>
          ),
      )}
    </View>
  )
}
