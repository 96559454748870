import React from 'react'

import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismCard } from 'components/PrismCard/PrismCard'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import SerialList from 'components/SerialList/SerialList'
import { useAllToolLabels } from 'hooks'
import {
  Inspection,
  ItemExpanded,
  RealtimePicture,
  Recipe,
  Tool,
  ToolLabel,
  ToolResultEmptyPredictionOutcome,
} from 'types'
import {
  evaluateOutcomes,
  getDisplaySeverity,
  getLabelName,
  getToolResultLabels,
  isLabelDiscard,
  renderToolName,
  sortByLabelKind,
} from 'utils'

import MetadataList from './MetadataList'
import Styles from './ToolListPanel.module.scss'

type Props = {
  item: ItemExpanded
  modalLoaded: boolean
  picture: RealtimePicture | undefined
  recipe: Recipe | undefined
  inspection: Inspection | undefined
  setHoveredAoiId: (id?: string) => any
  onClick: (id?: string) => any
  toolResults?: ToolResultEmptyPredictionOutcome[]
  toolListWrapperClassName?: string
}

/**
 * Renders List of tools to the right of the item detail modal, as well as a details panel with innformation about the item.
 *
 * @param item - Item element from which we will fetch list of toolResults
 * @param image - Picture's image to use
 * @param modalLoaded - Whether the modal has loaded. we only render images after modal has loaded
 * @param setHoveredAoiId - Callback to highlight the aoi that's currently hovered over
 * @param onClick - Callback to handle clicking on a tool
 * @param toolResults - List of all toolResults to show
 *
 */
export const ToolListPanel = ({
  item,
  recipe,
  inspection,
  toolResults,
  toolListWrapperClassName,
  picture,
  ...rest
}: Props) => {
  const { allToolLabels } = useAllToolLabels()

  const image = picture?.image

  return (
    <div className={`${Styles.toolListWrapper} ${toolListWrapperClassName ?? ''}`}>
      <div className={Styles.toolDescriptionWrapper}>
        <MetadataList recipe={recipe} inspection={inspection} picture={picture} item={item} />

        <ul className={Styles.toolList}>
          {toolResults?.map(toolResult => (
            <ItemDetailToolCard
              key={toolResult.id}
              toolResult={toolResult}
              allToolLabels={allToolLabels}
              image={image}
              {...rest}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

type ItemDetailToolCardProps = {
  toolResult: ToolResultEmptyPredictionOutcome
  image?: string
  modalLoaded: boolean
  setHoveredAoiId: (id?: string) => any
  onClick: (id?: string) => any
  toolResults?: ToolResultEmptyPredictionOutcome[]
  allToolLabels: ToolLabel[] | undefined
}

/**
 * Renders Item Detail Tool Card which contains data about tool predictions.
 *
 * @param toolResult - Tool Result that corresponds to current card
 * @param setHoveredAoiId - Sets the currently hovered AOI in order to highlight the AOI
 * @param onClick - Handler for clicking on current card
 * @param image - Image thumbnail to display on card
 * @param modalLoaded - Whether the modal has been loaded. We should only load images after the modal loaded
 * @param allToolLabels - List of all labels in organization
 */
const ItemDetailToolCard = ({
  toolResult,
  setHoveredAoiId,
  onClick,
  image,
  modalLoaded,
  allToolLabels,
}: ItemDetailToolCardProps) => {
  const tool = toolResult.tool || undefined
  const outcome = evaluateOutcomes([toolResult.calculated_outcome])
  // We assume the tool will always be defined for these tool results
  const labels = allToolLabels && getToolResultLabels(toolResult, allToolLabels)?.sort(sortByLabelKind)

  // If has been labeled as discard, we override the outcome
  const labeledAsDiscard = labels?.some(lbl => isLabelDiscard(lbl))
  return (
    <PrismCard
      data-test-attribute="tool-result-card"
      key={toolResult.id}
      onMouseEnter={() => setHoveredAoiId(toolResult.aoi?.id)}
      onMouseLeave={() => setHoveredAoiId(undefined)}
      onClick={() => {
        setHoveredAoiId(undefined)
        onClick(toolResult.id)
      }}
      data-testid={`tool-list-panel-name-${renderToolName(toolResult.tool).split(' ').join('-').toLowerCase()}`}
      data-test="tool-list-panel-classifier"
      image={
        <>
          {image && modalLoaded && toolResult.aoi && (
            <ImageCloseUp loaderType="skeleton" maskingRectangleFill="#262626" src={image} region={toolResult.aoi} />
          )}
          {(!image || !toolResult.aoi) && modalLoaded && <PrismElementaryCube addBackground />}
        </>
      }
      className={Styles.toolDetailItem}
      size="medium"
      type="ghost4"
    >
      {!allToolLabels && <PrismSkeleton className={Styles.toolLabelLoader} />}
      {allToolLabels && (
        <div className={`${Styles.prismCardResults} ${!labels?.length ? Styles.temporaryToolContainer : ''}`}>
          {labels?.length && (
            <LabelList
              data-testid="tool-list-panel-label-list"
              labels={labels}
              labelClassName={Styles.resultHeadingFont}
              tool={tool}
              showPopOverOnClick={false}
            />
          )}

          <PrismOverflowTooltip
            className={Styles.temporaryToolName}
            content={!labels?.length && renderToolName(toolResult.tool)}
          />
        </div>
      )}

      <PrismOutcome
        icon={labeledAsDiscard ? 'discard' : outcome}
        outcome={labeledAsDiscard ? undefined : outcome}
        variant="low"
        className={Styles.toolDetailIcon}
      />
    </PrismCard>
  )
}

export const LabelList = ({
  labels,
  labelClassName,
  tool,
  onClick,
  showPopOverOnClick = true,
  joint = 'and',
  'data-testid': dataTestId,
}: {
  labels: ToolLabel[]
  labelClassName?: string
  tool?: Tool
  onClick?: () => void
  joint?: 'and' | 'or' | null
  showPopOverOnClick?: boolean
  'data-testid'?: string
}) => {
  return (
    <SerialList joint={joint}>
      {labels.map(label => (
        <PrismResultButton
          data-testid={dataTestId}
          key={label.id}
          // We want to remove underscores from default labels
          value={getLabelName(label)}
          severity={getDisplaySeverity(label)}
          type="noFill"
          size="small"
          className={labelClassName}
          showPopOverOnClick={showPopOverOnClick}
          toolLabel={label}
          toolParentId={tool?.parent_id}
          toolSpecificationName={tool?.specification_name}
          onClick={onClick}
        />
      ))}
    </SerialList>
  )
}

export const ToolListPanelLoading = () => {
  return (
    <div className={`${Styles.toolListWrapper} ${Styles.loading}`}>
      <div className={Styles.toolDescriptionWrapper}>
        <div className={Styles.toolListHeader} />

        <ul className={Styles.toolList}>
          {[0, 1, 2].map(k => (
            <PrismCard
              key={k}
              type="ghost4"
              image={<PrismSkeleton size="extraLarge" />}
              className={Styles.toolDetailItem}
              size="medium"
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
