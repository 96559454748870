import React from 'react'

import { PrismCard } from 'components/PrismCard/PrismCard'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { Tag } from 'components/Tag/Tag'
import { Token } from 'components/Token/Token'

import ToolsStyles from './StationDetailTools.module.scss'

export function ToolListItem({
  img,
  toolName,
  failRate,
  onClick,
  active,
  muted,
  untrained,
  type = 'transparent',
  className,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
}: {
  img: React.ReactNode
  toolName: string
  failRate: number | string
  onClick: () => any
  active: boolean
  muted: boolean
  untrained: boolean
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94'
  className?: string
  'data-testid'?: string
  'data-test'?: string
  'data-test-attribute'?: string
}) {
  return (
    <PrismCard
      data-testid={dataTestId}
      data-test={dataTest}
      data-test-attribute={dataTestAttribute}
      type={type}
      image={
        <figure className={`${ToolsStyles.toolImageContainer} ${(muted || untrained) && ToolsStyles.mutedTool}`}>
          {img}
          {(muted || untrained) && <Tag className={ToolsStyles.mutedTag}>{muted ? 'muted' : 'untrained'}</Tag>}
        </figure>
      }
      onClick={onClick}
      active={active}
      className={`${active ? ToolsStyles.toolitemContainer_active : ToolsStyles.toolItemContainer} ${className ?? ''}`}
    >
      <div className={ToolsStyles.toolItem}>
        <Token label="tool" className={ToolsStyles.toolName} labelClassName={ToolsStyles.detailsLabel}>
          <PrismOverflowTooltip content={toolName} />
        </Token>

        <Token label="fail rate" labelClassName={ToolsStyles.detailsLabel}>
          {untrained ? '--' : failRate}
        </Token>
      </div>
    </PrismCard>
  )
}
