import React, { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { service } from 'api'
import { contextRockwellAuth0 } from 'App'
import { Button } from 'components/Button/Button'
import FullscreenForm from 'components/FullscreenForm/FullscreenForm'
import { BrandLogo } from 'components/prismIcons'
import { error } from 'components/PrismMessage/PrismMessage'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'

import Styles from './FlourishOrgSetup.module.scss'

const ERROR_MSG = 'There was a problem configuring the organization, please contact your administrator'

/**
 * Renders a form for Rockwell users to fill out. This form is displayed in an iframe on the Rockwell website.
 * If the user is not authenticated, they will be redirected to the RockwellLogin component. After logging in,
 * the user will be redirected back to this form to complete the organization setup.
 *
 * Design: https://www.figma.com/design/daYx7XLsDCb3ymtHQAoiV1/Log-In-(main)?node-id=989-1765&t=NzmSVUu9JsgliM4O-0
 *
 **/
const FlourishOrgSetup = ({
  tenantId,
  serviceId,
  onOrganizationSetup,
}: {
  tenantId: string
  serviceId: string
  onOrganizationSetup: (organizationId: string) => void
}) => {
  const [region, setRegion] = useState<'eu' | 'us' | 'ap'>()

  const { getAccessTokenSilently } = useAuth0(contextRockwellAuth0)
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!region) return
    const accessToken = await getAccessTokenSilently()

    try {
      const setupOrgRes = await service.organizationSetupRockwell({
        access_token: accessToken,
        region: region,
        rw_service_id: serviceId,
        rw_tenant_id: tenantId,
      })

      if (setupOrgRes.type !== 'success') return error({ title: ERROR_MSG })

      return onOrganizationSetup(setupOrgRes.data.id)
    } catch {
      return error({ title: ERROR_MSG })
    }
  }

  return (
    <FullscreenForm formClassName={Styles.orgSetup}>
      <figure className={Styles.orgSetupLogoContainer}>
        <BrandLogo className={Styles.orgSetupLogo} forceFlourishIcon />
      </figure>

      <h1 className={Styles.orgSetupTitle}>Organization Setup</h1>
      <section className={Styles.orgSetupFields}>
        <Token label="region" labelClassName={Styles.inputTitle}>
          <PrismSelect
            options={[{ title: 'North America', value: 'us' }]}
            size="large"
            onChange={val => setRegion(val)}
            value={region}
            forceFlourishIcon
            className={Styles.flourishSelectFont}
            popupClassName={Styles.flourishDropdown}
          />
          <p className={Styles.inputCaption}>
            The region where your data will be stored. This setting is permanent and cannot be changed.
          </p>
        </Token>
      </section>
      <section className={Styles.orgSetupButtons}>
        <Button size="medium" onClick={handleSubmit} className={Styles.flourishButton} disabled={!region}>
          Save
        </Button>
      </section>
    </FullscreenForm>
  )
}

export default FlourishOrgSetup
