import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Help Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A430
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismHelpIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <circle cx="12" cy="12" r="8.5" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1314 7.98101C11.7829 7.95691 11.4344 8.02909 11.1241 8.18966C10.8138 8.35023 10.5536 8.59302 10.3719 8.89148C10.1903 9.18994 10.0941 9.53259 10.0941 9.88199C10.094 10.2328 9.80952 10.5172 9.45866 10.5172C9.1078 10.5171 8.82342 10.2326 8.82349 9.88174C8.8236 9.29942 8.98378 8.72833 9.28653 8.2309C9.58928 7.73346 10.0229 7.32882 10.5401 7.0612C11.0573 6.79358 11.6381 6.67328 12.2191 6.71345C12.8 6.75361 13.3587 6.9527 13.8341 7.28895C14.3096 7.6252 14.6834 8.08567 14.9148 8.62004C15.1462 9.15441 15.2263 9.7421 15.1463 10.3189C15.0663 10.8957 14.8292 11.4394 14.4611 11.8906C14.0929 12.3418 13.6078 12.683 13.0588 12.8772C12.9349 12.921 12.8276 13.0021 12.7517 13.1095C12.6759 13.2168 12.6352 13.345 12.6353 13.4765V13.6936C12.6353 14.0445 12.3508 14.3289 12 14.3289C11.6491 14.3289 11.3647 14.0445 11.3647 13.6936V13.4771C11.3647 13.477 11.3647 13.4772 11.3647 13.4771C11.3645 13.0829 11.4866 12.6981 11.7141 12.3762C11.9417 12.0542 12.2635 11.8107 12.6353 11.6792C12.9647 11.5628 13.2557 11.358 13.4766 11.0873C13.6975 10.8166 13.8397 10.4904 13.8878 10.1443C13.9358 9.7982 13.8877 9.44558 13.7489 9.12496C13.61 8.80434 13.3857 8.52806 13.1005 8.32631C12.8152 8.12456 12.48 8.00511 12.1314 7.98101Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4117 15.3549C11.5859 15.2385 11.7906 15.1764 12 15.1764C12.2808 15.1764 12.5501 15.288 12.7487 15.4865C12.9473 15.6851 13.0588 15.9544 13.0588 16.2352C13.0588 16.4447 12.9967 16.6494 12.8804 16.8235C12.764 16.9976 12.5987 17.1333 12.4052 17.2135C12.2117 17.2936 11.9988 17.3146 11.7934 17.2737C11.588 17.2329 11.3994 17.132 11.2513 16.9839C11.1032 16.8359 11.0024 16.6472 10.9615 16.4418C10.9207 16.2364 10.9416 16.0235 11.0218 15.8301C11.1019 15.6366 11.2376 15.4712 11.4117 15.3549Z"
        fill="currentColor"
      />
    </svg>
  )
}
