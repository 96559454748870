import React, { useState } from 'react'

import { Popover } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'

import Styles from './PrismTooltipWithPopover.module.scss'

interface PrismTooltipWithPopoverProps {
  hoverTitle: React.ReactNode
  children: JSX.Element
  popoverContent?: React.ReactNode
  tooltipClassName?: string
  popoverClassName?: string
  popoverVisible: boolean
  handlePopoverVisibleChange: (visible: boolean) => void
}

/**
 * Renders a Tooltip on hover, additionally if `popoverContent` is added the children can be clicked to close the tooltip and open a popover.
 *
 * @param hoverTitle - (required) Text to be displayed on hover.
 * @param children - (required) this component might work better with iconButtons, but can be used with other elements.
 * @param popoverVisible - Wether the popover should be visible or not.
 * @param handlePopoverVisibleChange - Handler for when the popover visible state changes.
 * @param popoverContent - Content to be displayed after clicking the children.
 * @param tooltipClassName - string class for the tooltip.
 * @param popoverClassName - string class for the popover.
 *
 */

const PrismTooltipWithPopover = ({
  hoverTitle,
  popoverContent,
  children,
  popoverVisible,
  handlePopoverVisibleChange,
  tooltipClassName = '',
  popoverClassName = '',
}: PrismTooltipWithPopoverProps) => {
  const [hovered, setHovered] = useState(false)

  const handleClickChange = (open: boolean) => {
    setHovered(false)
    handlePopoverVisibleChange(open)
  }

  return (
    <PrismTooltip
      trigger="hover"
      placement="bottom"
      title={hoverTitle}
      overlayClassName={tooltipClassName}
      open={hovered && !popoverVisible}
      onOpenChange={setHovered}
    >
      <ConditionalWrapper
        condition={!!popoverContent}
        wrapper={children => (
          <Popover
            trigger="click"
            content={popoverContent}
            overlayClassName={`${Styles.prismPopoverWrapper} ${Styles.popoverOverlay} ${popoverClassName}`}
            open={popoverVisible}
            onOpenChange={handleClickChange}
            placement="bottomRight"
          >
            {children}
          </Popover>
        )}
        children={children}
      />
    </PrismTooltip>
  )
}

export default PrismTooltipWithPopover
