import React, { useEffect, useMemo, useRef, useState } from 'react'

import { clamp } from 'lodash'
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer } from 'recharts'

import { colors, renderBar } from 'components/CustomRecharts'
import { Box } from 'types'
import { CombinedOutcomeData, seriesFillGaps } from 'utils'

import { GraphSeries, YieldGraph } from '../Components/YieldGraph'
import { TimeFilters } from './StationDetailTools'
import Styles from './StationDetailTools.module.scss'

const RESPONSIVE_CHART_HEIGHT = 68

interface Props {
  itemSeries: CombinedOutcomeData[] | undefined
  aggS: number
  timeFilters: TimeFilters | undefined
  setTimeFilters: (filters: TimeFilters | undefined) => any
  yieldThreshold: number
}

export default function StationDetailToolsYieldGraph({
  itemSeries,
  aggS,
  setTimeFilters,
  timeFilters,
  yieldThreshold,
}: Props) {
  const graphRef = useRef<CartesianGrid>(null)
  const [rndProps, setRndProps] = useState<Box>({ height: 0, width: 0, x: 0, y: 0 })

  const seriesGapsFilled: GraphSeries[] = useMemo(() => {
    const series = seriesFillGaps(itemSeries || [], aggS)

    return series.map(data => {
      return {
        ...data,
        failYield: (100 * (data.fail || 0)) / (data.count || 1),
        passYield: (100 * (data.pass || 0)) / (data.count || 1),
        unknownYield: (100 * ((data.unknown || 0) + (data['needs-data'] || 0) + (data.error || 0))) / (data.count || 1),
      }
    })
  }, [itemSeries, aggS])

  const graphStart = graphRef.current?.props.x || 0
  const graphEnd = graphStart + (graphRef.current?.props?.width || 0)
  const graphHeight = graphRef?.current?.props.height || 0
  const graphY = graphRef?.current?.props.y || 0
  const chartBarWidth = (graphRef.current?.props.width || 0) / (seriesGapsFilled.length || 1)

  useEffect(() => {
    // When graph ref is loaded or graph changes, reset graph height and y
    setRndProps(currentRndProps => ({ ...currentRndProps, y: graphY, height: graphHeight + 2 }))
  }, [graphY]) // eslint-disable-line

  useEffect(() => {
    /** When time bucket changes, adjust time filter to nearest bucket, start time rounds down and end time rounds up
     * When time bucket changes, the query fetcher key is reset, don't run this until we have the new series fetched
     * and calculated or it will reset time filter */
    if (!timeFilters || !seriesGapsFilled.length) return

    let newStart = 0
    let newEnd = (seriesGapsFilled[seriesGapsFilled.length - 1]?.ts || 0) + aggS // Fallback in case the end is at the end of the graph
    const { start, end } = timeFilters
    seriesGapsFilled.forEach(s => {
      if (s.ts <= start && s.ts + aggS > start) newStart = s.ts
      if (s.ts >= end && s.ts - aggS < end) newEnd = s.ts
    })
    setTimeFilters({ start: newStart, end: newEnd })
  }, [aggS, seriesGapsFilled]) // eslint-disable-line

  useEffect(() => {
    // When data or time filter changes, find time filter start and end times in new graph and calculate time box positions from them
    if (!timeFilters || !seriesGapsFilled.length) return

    let xEndIdx = seriesGapsFilled.findIndex(s => s.ts === timeFilters.end)
    if (xEndIdx === -1) xEndIdx = seriesGapsFilled.length
    const xStart = graphStart + chartBarWidth * seriesGapsFilled.findIndex(s => s.ts === timeFilters.start)
    const xEnd = graphStart + chartBarWidth * xEndIdx

    setRndProps(currentRndProps => ({ ...currentRndProps, x: xStart, width: xEnd - xStart }))
  }, [seriesGapsFilled, timeFilters, graphStart]) // eslint-disable-line

  if (!itemSeries || itemSeries.length === 0) {
    return (
      <ResponsiveContainer width="100%" height={RESPONSIVE_CHART_HEIGHT}>
        <BarChart data={emptyStateChartData}>
          <CartesianGrid stroke={colors.darkGrid} strokeDasharray="4" vertical={false} />
          <Bar dataKey="count" fill={colors.emptyState} name="Count" shape={renderBar} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  const limitRNDtoGraphX = (x: number, width: number) => {
    return clamp(x, graphStart, graphEnd - width)
  }

  const setTimeFiltersFromTimeBox = (startX: number, endX: number) => {
    const startTs = getTsAtGraphPosition(startX)
    const endTs = getTsAtGraphPosition(endX)

    setTimeFilters({ start: startTs, end: endTs })
  }

  const getTsAtGraphPosition = (x: number) => {
    const dataIdx = Math.round((x - graphStart) / chartBarWidth) // X is off by decimals sometimes, so round it
    let ts = 0
    if (dataIdx > seriesGapsFilled.length - 1) {
      ts = (seriesGapsFilled[seriesGapsFilled.length - 1]?.ts || 0) + aggS
    } else {
      ts = seriesGapsFilled[dataIdx]?.ts || 0
    }
    return ts
  }
  const handleTimeBoxDrag: RndDragCallback = (e, d) => {
    // Limits dragging x to the CartesianGrid x boundaries
    // Have to use d.lastX instead of d.x due to RND behavior/bug with dragGrid prop //https://github.com/bokuweb/react-rnd/issues/453
    const limitedX = limitRNDtoGraphX(d.lastX, rndProps.width)

    setRndProps(currentRndProps => ({ ...currentRndProps, x: limitedX }))

    setTimeFiltersFromTimeBox(limitedX, limitedX + rndProps.width)
  }

  const handleTimeBoxResize: RndResizeCallback = (e, direction, ref, delta, position) => {
    e.stopPropagation()
    const limitedX = limitRNDtoGraphX(position.x, ref.clientWidth)
    setTimeFiltersFromTimeBox(limitedX, limitedX + ref.clientWidth)

    setRndProps(currentRndProps => ({
      ...currentRndProps,
      width: ref.clientWidth,
      x: limitedX,
    }))
  }

  // This can't be done with a classname. React-rnd applies styles to the element, but we can override them with our own styles
  const resizeHandleStyles = { background: 'white', height: '30%', width: 10, top: '35%', borderRadius: '1px' }

  return (
    <div className={Styles.metricsChartWrapper}>
      {timeFilters && (
        <Rnd
          size={{ ...rndProps }}
          position={{ ...rndProps }}
          enableResizing={{ right: true, left: true }}
          dragGrid={[chartBarWidth, 1]} // snaps drags to chart bar
          onDragStop={handleTimeBoxDrag}
          resizeHandleStyles={{
            left: {
              ...resizeHandleStyles,
              left: -3,
            },
            right: {
              ...resizeHandleStyles,
              right: -3,
            },
          }}
          resizeHandleWrapperStyle={{ display: 'flex' }}
          dragAxis="x"
          className={Styles.graphTimeFilter}
          onResizeStop={handleTimeBoxResize}
          resizeGrid={[chartBarWidth, 1]}
        />
      )}
      <YieldGraph
        yieldSeries={seriesGapsFilled}
        graphRef={graphRef}
        yieldThreshold={yieldThreshold}
        chartHeight={100}
        onChartClick={e => {
          if (!e?.activeLabel) return
          setTimeFilters({ start: +e.activeLabel, end: e.activeLabel + aggS })
        }}
        getBarClassName={props => getBarClassnameFromFilter(props, timeFilters)}
        mode="inspection"
      />
    </div>
  )
}

const getBarClassnameFromFilter = (props: any, timeFilters: TimeFilters | undefined) => {
  const className = []
  if (timeFilters && (props.ts < timeFilters.start || props.ts >= timeFilters.end))
    className.push(Styles.barNotInTimeFilter)
  return className.join(' ')
}

const emptyStateChartData = [
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
  { count: 0.1 },
  { count: 100 },
]
