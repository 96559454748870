import React, { ReactNode, RefObject } from 'react'

import { LeftOutlined } from '@ant-design/icons'

import Styles from './Card.module.scss'

interface PropTypes {
  className?: string
  headerClassName?: string
  bodyClassName?: string
  footerClassName?: string
  title?: string | JSX.Element
  actionButton?: JSX.Element
  footer?: JSX.Element
  children?: ReactNode
  onBackButton?: () => void
  bodyRef?: RefObject<HTMLDivElement>
  hideHeader?: boolean
}

/**
 * Renders a card component with a standardized format. This card element contains a title section and will render whatever is passed as children inside the body of the card. Some additional parameters can be set to render a footer, an action button or a back button
 *
 * @param className - The class name of the containing card
 * @param headerClassName - class name for the card container
 * @param bodyClassName - class name for the body container
 * @param footerClassName - class name for the footer container
 * @param title - (required) the title of the card element, can be a string or a react node
 * @param actionButton - React node that will render on the right side of the title component
 * @param footer - React node of the contents that will appear in the footer of the card
 * @param children - the content of the body of the Card
 * @param onBackButton - Callback function that will trigger when users click on the back button. If unset, no back button will be rendered
 * @param bodyRef - the reference to the body of the card
 * @param hideHeader - if set to true, the header will not be rendered
 */

function Card({
  title,
  actionButton,
  children,
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  footer,
  bodyRef,
  onBackButton,
  hideHeader,
}: PropTypes) {
  return (
    <div className={`${Styles.cardContainer} ${className ?? ''}`}>
      {!hideHeader && (
        <div className={`${Styles.cardHeader} ${headerClassName ?? ''}`}>
          {onBackButton && <LeftOutlined className={Styles.leftArrow} onClick={onBackButton} />}

          {title && <span className={Styles.cardTitle}>{title}</span>}

          {actionButton && <div className={Styles.cardActionButton}>{actionButton}</div>}
        </div>
      )}
      <div ref={bodyRef} className={`${Styles.cardBody} ${bodyClassName ?? ''}`}>
        {children}
      </div>

      {footer && <div className={`${Styles.cardFooter} ${footerClassName ?? ''}`}>{footer}</div>}
    </div>
  )
}

export default Card
