// https://raw.githubusercontent.com/giacomocerquone/opencv-react/master/src/lib/OpenCvProvider.js
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { OpenCvMat } from './openCvTypes'

const OpenCvContext = createContext<{ loaded: boolean; cv: OpenCvMat | undefined; reload: () => void } | null>(null)

const { Consumer: OpenCvConsumer, Provider } = OpenCvContext

export const OPENCV_URL = 'https://docs.opencv.org/4.3.0/opencv.js'

export { OpenCvConsumer, OpenCvContext }

type ModuleConfig = {
  wasmBinaryFile: string
  usingWasm: boolean
  onRuntimeInitialized?: () => void
}

const scriptId = 'opencv-react'

let moduleConfig: ModuleConfig = {
  wasmBinaryFile: 'opencv_js.wasm',
  usingWasm: true,
}

type Props = {
  openCvPath: string
  children: ReactNode
  onLoad: (cv?: OpenCvMat) => void
  error?: boolean
}

export const OpenCvProvider = ({ openCvPath, children, onLoad, error }: Props) => {
  const [loaded, setLoaded] = useState(false)

  const reload = () => {
    setLoaded(false)

    moduleConfig = {
      wasmBinaryFile: 'opencv_js.wasm',
      usingWasm: true,
    }

    const el = document.getElementById(scriptId)
    if (el) el.remove()

    if (window.cv) {
      delete window.cv
      delete window.Module
    }
  }

  const handleOnLoad = useCallback(() => {
    if (onLoad) {
      onLoad(window.cv)
    }
    setLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // This whole effect has the task of loading the opencv library into a js script mounted on the DOM

    // In case an error is received, we must unmount the script as it's now broken, and remount it
    if (error) {
      reload()
    }

    // If the element is already loaded, do nothing
    if (document.getElementById(scriptId) || window.cv) {
      setLoaded(true)
      return
    }

    // https://medium.com/code-divoire/integrating-opencv-js-with-an-angular-application-20ae11c7e217
    // https://stackoverflow.com/questions/56671436/cv-mat-is-not-a-constructor-opencv
    // From the links above, we must use onRuntimeInitialized as opposed to onMount, or similar, because as opencv is a pretty big library, it takes some time to initialize
    moduleConfig.onRuntimeInitialized = handleOnLoad
    window.Module = moduleConfig

    // This creates a <script> element with the proper args to load the OpenCV script
    // and then it appends it to the <body> tag in your browser
    const generateOpenCvScriptTag = () => {
      const js = document.createElement('script')
      js.id = scriptId
      js.src = openCvPath || OPENCV_URL

      js.nonce = 'true'
      js.defer = true
      js.async = true

      return js
    }

    // Sticks opencv script tag in document's body
    const openCvScriptNode = document.getElementById(scriptId)
    if (!openCvScriptNode) document.body.appendChild(generateOpenCvScriptTag())
  }, [openCvPath, handleOnLoad, error])

  const memoizedProviderValue = useMemo(() => ({ loaded, cv: window.cv, reload }), [loaded])

  return <Provider value={memoizedProviderValue}>{children}</Provider>
}

export const useOpenCv = () => useContext(OpenCvContext)
