import React, { useState } from 'react'

import OptionMenu, { IconButtonProps, OptionMenuProps } from 'components/OptionMenu/OptionMenu'
import PrismBadge from 'components/PrismBadge/PrismBadge'
import { PrismArrowIcon, PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'

import Styles from './PrismCard.module.scss'

const CARD_TITLE_SKELETON_MAX_WIDTH = 100
const CARD_VALUE_SKELETON_MAX_WIDTH = 60

const typeClasses = {
  transparent: Styles.transparent,
  ghost4: Styles.ghost4,
  smokey100: Styles.smokey100,
  smokey94: Styles.smokey94,
  notClickable: Styles.notClickable,
}
const optionsPositionClasses = {
  topLeft: Styles.topLeft,
  topCenter: Styles.topCenter,
  topRight: Styles.topRight,
  centerRight: Styles.centerRight,
  bottomRight: Styles.bottomRight,
  bottomCenter: Styles.bottomCenter,
  bottomLeft: Styles.bottomLeft,
  centerLeft: Styles.centerLeft,
}
const sizeClasses = {
  small: Styles.small,
  medium: Styles.medium,
  large: Styles.large,
  extraLarge: Styles.extraLarge,
}

export interface Props<T extends string> {
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94' | 'notClickable'
  size?: 'small' | 'medium' | 'large' | 'extraLarge'
  children?: React.ReactNode | React.ReactNode[]
  onClick?: () => void
  image?: React.ReactNode | null
  optionsPosition?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'centerRight'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft'
    | 'centerLeft'
  arrow?: boolean
  active?: boolean | string
  activeClassName?: string
  showBadge?: boolean | string
  badgeClassName?: string
  className?: string
  imageContainerClassName?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  optionMenuProps?: Omit<OptionMenuProps<T>, 'children'> & IconButtonProps
  imageContainerBorder?: boolean
  disabled?: boolean
  'data-testid'?: string
  'data-test'?: string
  'data-test-attribute'?: string
  shape?: 'rectangle' | 'card'
  badgeType?: 'default' | 'warning' | 'error' | 'pass'
}

/**
 * Renders a card component with an optional image, and children
 * Outline by default with fill on hover
 * And an optional menu on the right
 *
 * See figma.com/file/0YKkgzjRIApn3KEsFRfWC7
 *
 * More updates still WIP (small variant, vertical variant)
 *
 * @param type – type is related to the background design colors
 * @param size – this will be applied to the image container and the card will grow using this size.
 * @param key – React key
 * @param children – React Children elements, will be on the right side of the Card
 * @param onClick – If onClick is passed, it will add it to the entire Card element
 * @param image – Left side of the Card, will paint a small border around it
 * @param options – Use OptionsMenu component to paint a menu
 * @param optionsPosition – the position from the option menu within prism container
 * @param active – adds a special color to the card background
 * @param badge – adds a badge dot
 * @param disabled – Whether the card should show disabled styles
 * @param onMenuItemClick – Handle which item from OptionsMenu (option) was clicked
 * @param onMouseEnter – Handle pointer events
 * @param onMouseLeave – Handle pointer events
 * @param badgeType - Changes the badge color from blue (default), to red (error), yellow (warning), pass (green)
 */
export const PrismCard = <T extends string>({
  type = 'transparent',
  size = 'large',
  children,
  onClick,
  image,
  optionsPosition = 'centerRight',
  arrow,
  active,
  activeClassName = Styles.active,
  showBadge,
  className = '',
  imageContainerClassName,
  badgeClassName = '',
  onMouseEnter,
  onMouseLeave,
  optionMenuProps,
  imageContainerBorder,
  disabled,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  shape = 'rectangle',
  badgeType = 'default',
}: Props<T>) => {
  const [dotsAlwaysActive, setDotsAlwaysActive] = useState(false)

  const typeClass = typeClasses[type]
  const optionsPositionClass = optionsPositionClasses[optionsPosition]
  const sizeClass = sizeClasses[size]
  return (
    <div
      className={`${Styles.prismCard} ${typeClass} ${onClick ? Styles.clickable : ''} ${
        active ? activeClassName : ''
      } ${disabled ? Styles.disabled : ''} ${className} ${shape === 'card' ? Styles.cardItemShape : ''}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={dataTestId}
      data-test={dataTest}
      data-test-attribute={dataTestAttribute}
    >
      {showBadge && (
        <PrismBadge
          className={`${Styles.cardBadge} ${badgeClassName}`}
          type={badgeType}
          size="medium"
          data-test={`camera-badge-${badgeType}`}
        />
      )}
      {image && (
        <div
          className={`${Styles.image} ${imageContainerClassName ?? ''} ${
            imageContainerBorder ? Styles.imageContainerBorder : ''
          } ${sizeClass}`}
        >
          {image}
        </div>
      )}

      {children}

      {optionMenuProps && (
        <div
          className={`${Styles.threeDots} ${optionsPositionClass} ${dotsAlwaysActive ? Styles.dotsAlwaysActive : ''}`}
          onClick={e => e.stopPropagation()}
        >
          <OptionMenu
            {...optionMenuProps}
            onOpen={() => {
              setDotsAlwaysActive(true)
              optionMenuProps.onOpen?.()
            }}
            onClose={() => {
              setDotsAlwaysActive(false)
              optionMenuProps.onClose?.()
            }}
            iconButtonType="tertiary"
            iconButtonDataTest={dataTest ? `${dataTest}-option-menu` : ''}
            iconButtonDataTestId={dataTestId ? `${dataTestId}-option-menu` : ''}
            iconButtonIsOnTop
          />
        </div>
      )}

      {arrow && <PrismArrowIcon className={Styles.rightArrow} />}
    </div>
  )
}

export const LoadingPrismCard = ({ cardNumber = 3 }: { cardNumber?: number }) => {
  return (
    <>
      {Array(cardNumber)
        .fill(undefined)
        .map((_, key) => (
          <PrismCard
            image={<PrismSkeleton size="extraLarge" />}
            type="notClickable"
            className={Styles.loadingState}
            key={key}
          >
            <div className={Styles.loadingStateText}>
              <div>
                <PrismSkeleton maxWidth={CARD_TITLE_SKELETON_MAX_WIDTH} />
                <PrismSkeleton maxWidth={CARD_VALUE_SKELETON_MAX_WIDTH} />
              </div>

              <div className={Styles.statusLoaders}>
                <PrismSkeleton maxWidth={CARD_TITLE_SKELETON_MAX_WIDTH} />
                <PrismSkeleton maxWidth={CARD_VALUE_SKELETON_MAX_WIDTH} />
              </div>
            </div>
          </PrismCard>
        ))}
    </>
  )
}

export const PrismCardBlankState = ({
  type = 'smokey100',
  className,
}: {
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94' | 'notClickable'
  className?: string
}) => {
  return (
    <PrismCard
      type={type}
      image={<PrismElementaryCube />}
      imageContainerClassName={Styles.cardImageContainer}
      className={`${Styles.cardBlankState} ${className ?? ''}`}
      disabled
    />
  )
}
