import React, { useCallback, useEffect } from 'react'

import { useHistory } from 'react-router'

import { Divider } from 'components/Divider/Divider'
import { useQueryParams } from 'hooks'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { RecipeExpanded, RoutineWithAois } from 'types'
import { appendDataToQueryString } from 'utils'

import ItemCreation from './ItemCreation'
import ProductionTargets from './ProductionTargets'
import Styles from './RoutineSettings.module.scss'
import Workflows from './Workflows'

export type RoutineSettingsTabs = 'itemCreation' | 'productionTargets' | 'workflows'
export type Props = {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  readOnly?: boolean
}

/**
 * Renders Settings screen to configure additional settings for currently selected routine
 *
 * @param routine - Selected Routine
 * @param recipe - Recipe we are working on
 * @param readOnly - Whether the settings should be editable
 *
 */
function RoutineSettings({ routine, recipe, readOnly }: Props) {
  const history = useHistory()

  const [params] = useQueryParams()

  const settingsTab = params.settingsTab as RoutineSettingsTabs

  // this effect is in charge of removing the settingsTab key from qs whenever the user navigates out of this tab.
  useEffect(() => {
    return () => {
      appendDataToQueryString(history, { settingsTab: undefined })
    }
  }, [history])

  const changeTab = useCallback(
    (tab: RoutineSettingsTabs) => {
      if (!recipe) return
      history.replace(
        paths.settingsRecipe(recipe.parent.id, 'settings', {
          ...params,
          settingsTab: tab,
          routineParentId: routine.parent.id,
        }),
      )
    },
    [history, params, recipe, routine.parent.id],
  )

  useEffect(() => {
    if (!settingsTab) changeTab('itemCreation')
  }, [changeTab, settingsTab])

  return (
    <div className={Styles.settingsLayout}>
      <div className={Shared.twoSideLayoutLeft}>
        <div className={Shared.twoSideLayoutNavContainer}>
          <div
            onClick={() => changeTab('itemCreation')}
            className={`${Shared.twoSideLayoutNavItem} ${
              settingsTab === 'itemCreation' && Shared.twoSideLayoutNavItemActive
            }`}
            data-testid="recipe-settings-item-creation"
          >
            Item Creation
          </div>
          <div
            onClick={() => changeTab('productionTargets')}
            className={`${Shared.twoSideLayoutNavItem} ${
              settingsTab === 'productionTargets' && Shared.twoSideLayoutNavItemActive
            }`}
            data-testid="recipe-settings-production-targets"
          >
            Production Targets
          </div>
          <div
            onClick={() => changeTab('workflows')}
            className={`${Shared.twoSideLayoutNavItem} ${
              settingsTab === 'workflows' && Shared.twoSideLayoutNavItemActive
            }`}
            data-testid="recipe-settings-worflows"
          >
            Workflows
          </div>
        </div>
      </div>
      <Divider type="vertical" />
      <div className={Shared.twoSideLayoutRight}>
        {renderSettingsTab({ tab: settingsTab, routine, recipe, readOnly })}
      </div>
    </div>
  )
}

export default RoutineSettings

const renderSettingsTab = ({
  tab,
  routine,
  recipe,
  readOnly,
}: {
  tab: RoutineSettingsTabs
  routine: RoutineWithAois
  recipe: RecipeExpanded
  readOnly?: boolean
}) => {
  if (tab === 'workflows') return <Workflows recipe={recipe} readOnly={readOnly} />
  if (tab === 'productionTargets') return <ProductionTargets routine={routine} recipe={recipe} readOnly={readOnly} />
  if (tab === 'itemCreation') return <ItemCreation recipe={recipe} readOnly={readOnly} />
}
