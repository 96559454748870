import React, { useMemo } from 'react'

import { isEqual } from 'lodash'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getAllPages, getterKeys, query, service, useQuery } from 'api'
import Card from 'components/Card/Card'
import { Divider } from 'components/Divider/Divider'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismRobotViewWithStatus from 'components/PrismRobotViewWithStatus/PrismRobotViewWithStatus'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import RobotsStatusListener from 'components/RobotsStatusListener'
import RobotToolsetsFetcher from 'components/RobotToolsetsFetcher'
import { Status } from 'components/Status/Status'
import { useQueryParams, useRobotStatus, useTypedSelector } from 'hooks'
import paths from 'paths'
import { Robot, RoutineSlot, Station } from 'types'
import { calculateOpacityByIndex, getRobotDisplayName } from 'utils'

import Styles from './CommunicationsList.module.scss'
import PlcDisabledToggle from './PlcDisabledToggle'
import RoutineToolMapping from './RoutineToolMapping'

interface RobotWithDisplayName extends Robot {
  displayName: string
}

function CommunicationsList({ station }: { station: Station }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<'robot_id' | 'routine_slot_index'>()

  const robots = useQuery(getterKeys.robots(), async () => {
    const data = await getAllPages(service.getRobots({ station_id: station.id }))
    return data
  }).data?.data.results

  const robotDiscoveries = useTypedSelector(state => state.robotDiscoveriesById, isEqual)

  const sortedRobotsWithDisplayName = useMemo(() => {
    if (!robots) return

    return robots.map(robot => ({ ...robot, displayName: getRobotDisplayName(robot) })).sort(sortByDisplayName)
    // We need to add the robot discoveries as an effect dependency to get the updated robotDisplayName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robots, robotDiscoveries])

  const robotIds = useMemo(() => {
    if (!sortedRobotsWithDisplayName) return
    return sortedRobotsWithDisplayName.map(robot => robot.id)
  }, [sortedRobotsWithDisplayName])

  const selectedRobotId = params.robot_id || sortedRobotsWithDisplayName?.[0]?.id
  const selectedRobotStatus = useRobotStatus(selectedRobotId)

  const selectedRobot = useQuery(
    selectedRobotId ? getterKeys.robot(selectedRobotId) : null,
    selectedRobotId ? () => service.getRobot(selectedRobotId) : undefined,
  ).data?.data

  const selectedRobotRoutineSlots = useQuery(
    selectedRobotId ? getterKeys.robotRoutineSlots(selectedRobotId) : undefined,
    () =>
      service.atomSendCommandExtractData<RoutineSlot[]>('integration-framework', 'get_slots', selectedRobotId!, {
        command_args: {},
      }),
  ).data?.data

  const selectedRoutineParentIds = useMemo(() => {
    return selectedRobotRoutineSlots
      ?.map(routineSlot => routineSlot.routine_parent_id)
      .filter((routineParentId): routineParentId is string => !!routineParentId)
      .sort()
  }, [selectedRobotRoutineSlots])

  const activeRoutineParents = useQuery(
    selectedRoutineParentIds ? getterKeys.routineParents(selectedRoutineParentIds.join()) : undefined,
    () => service.getRoutineParents({ id__in: selectedRoutineParentIds?.join() }),
  ).data?.data.results

  const routineSlot =
    selectedRobotRoutineSlots?.find(routineSlot => routineSlot.index.toString() === params.routine_slot_index) ||
    selectedRobotRoutineSlots?.[0]

  const renderRoutineSlot = (slot: RoutineSlot) => {
    const foundRoutineParent = activeRoutineParents?.find(routineParent => routineParent.id === slot.routine_parent_id)
    return (
      <div
        onClick={() => {
          history.replace({
            pathname: paths.stationDetail('communications', station.id),
            search: qs.stringify({ ...params, routine_slot_index: slot.index }),
          })
        }}
        className={`${Styles.componentItem} ${slot.index === routineSlot?.index ? Styles.componentSelected : ''}`}
        data-test="recipe-slots-list"
        data-testid={`recipe-slots-list-slot-${slot.index}`}
        data-test-attribute={!foundRoutineParent ? 'recipe-slots-open' : 'recipe-slots-unavailable'}
        key={slot.index}
      >
        <PrismOverflowTooltip
          className={Styles.productList}
          content={
            <>
              {foundRoutineParent &&
                `${slot.index}. ${foundRoutineParent.component_name || ''} ${
                  foundRoutineParent.recipe_parent.name || ''
                } ${foundRoutineParent.name || ''}`}
              {!foundRoutineParent && `${slot.index}. Open`}
            </>
          }
        />
      </div>
    )
  }

  const refresh = async () => {
    if (!selectedRobotId) return
    await query(
      getterKeys.robotRoutineSlots(selectedRobotId),
      () =>
        service.atomSendCommandExtractData<RoutineSlot[]>('integration-framework', 'get_slots', selectedRobotId, {
          command_args: {},
        }),
      { dispatch },
    )
  }

  const showEmptyState =
    selectedRobotStatus === 'disconnected' || selectedRobotStatus === 'loading' || !selectedRobotRoutineSlots

  return (
    <>
      {!robots && <PrismLoader fullScreen />}
      {robots && (
        <div className={Styles.container}>
          <Card
            title="Cameras"
            className={Styles.leftContainer}
            headerClassName={Styles.cardHeader}
            bodyClassName={`${Styles.cardBody} ${Styles.camerasList}`}
          >
            {sortedRobotsWithDisplayName?.map(robot => (
              <RenderCamera key={robot.id} robot={robot} stationId={station.id} selectedRobotId={selectedRobotId} />
            ))}
          </Card>
          <Divider type="vertical" className={Styles.divider} />
          <div className={Styles.rightContainer}>
            <div className={`${Styles.slotsContainer}`}>
              {selectedRobotId && (
                <>
                  <Card
                    className={Styles.slotLeft}
                    headerClassName={Styles.cardHeader}
                    bodyClassName={Styles.cardBody}
                    title={<RecipeSlotHeader selectedRobot={selectedRobot} />}
                  >
                    <>
                      {showEmptyState && <EmptyStateSlots />}

                      {!showEmptyState && selectedRobotRoutineSlots.map(renderRoutineSlot)}
                    </>
                  </Card>
                  <Divider type="vertical" className={Styles.divider} />

                  <RoutineToolMapping
                    key={`${selectedRobotId}-${routineSlot?.index}`}
                    routineSlot={routineSlot}
                    robotId={selectedRobotId}
                    onRefresh={refresh}
                    robotStatus={selectedRobotStatus}
                    showEmptyState={showEmptyState}
                    selectedRoutineParentIds={selectedRoutineParentIds}
                    selectedRobotRoutineSlots={selectedRobotRoutineSlots}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {robotIds && (
        <>
          <RobotsStatusListener robotIds={robotIds} />
          <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
          {robotIds.map(robotId => (
            <RobotToolsetsFetcher key={robotId} robotId={robotId} intervalMs={5 * 1000} />
          ))}
        </>
      )}
    </>
  )
}

const MemoCommunicationsList = React.memo(CommunicationsList)
export default MemoCommunicationsList

const sortByDisplayName = (a: RobotWithDisplayName, b: RobotWithDisplayName) => {
  if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1
  if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) return 1

  return 0
}

/**
 * Renders the Recipe Slot Header, which contains the selectedRobot title and status, and the Plc toggle.
 *
 *
 * @param selectedRobot - the selected robot
 */
const RecipeSlotHeader = ({ selectedRobot }: { selectedRobot?: Robot }) => {
  const robotStatus = useRobotStatus(selectedRobot?.id)
  return (
    <div className={Styles.plcToggleWrapper}>
      <span className={Styles.cardTitle}>
        <span className={Styles.slotTitle}>Recipe Slots</span>-
        <RobotDisplayName robotName={getRobotDisplayName(selectedRobot)} className={Styles.robotName} />
      </span>
      <div className={Styles.toggleAndStatusContainer}>
        {selectedRobot && (
          <>
            <PlcDisabledToggle robotId={selectedRobot.id} />
            <Status status={robotStatus} className={Styles.recipeSlotStatus} loaderClassName={Styles.statusIsLoading} />
          </>
        )}
      </div>
    </div>
  )
}

/**
 * Renders a container with 3 empty slots .
 *
 */
export const EmptyStateSlots = () => {
  return (
    <div className={Styles.emptyStateContainer}>
      {Array(3)
        .fill(undefined)
        .map((_, index) => (
          <div className={Styles.emptyStateSlot} style={{ opacity: calculateOpacityByIndex(index) }} key={index} />
        ))}
    </div>
  )
}

const RenderCamera = ({
  robot,
  stationId,
  selectedRobotId,
}: {
  robot: RobotWithDisplayName
  stationId: string
  selectedRobotId?: string
}) => {
  const history = useHistory()
  const status = useRobotStatus(robot.id)
  return (
    <div
      onClick={() =>
        history.replace({
          pathname: paths.stationDetail('communications', stationId),
          search: qs.stringify({ robot_id: robot.id }),
        })
      }
      className={`${Styles.cameraItemList} ${robot.id === selectedRobotId && Styles.componentSelected}`}
      data-test="cameras-list"
      data-testid={`cameras-list-camera-${robot.displayName}`}
      data-test-attribute={status === 'connected' ? 'camera-list-available' : 'camera-list-unavailable'}
    >
      <PrismRobotViewWithStatus robotId={robot.id} className={Styles.prismRobotContainer} />

      <RobotDisplayName className={Styles.productList} robotName={robot.displayName} />
    </div>
  )
}
