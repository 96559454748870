import React, { CSSProperties } from 'react'

import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import Shared from 'styles/Shared.module.scss'

import Styles from './PrismContainer.module.scss'

interface PrismContainerProps {
  title?: String
  actions?: React.ReactNode
  headerElementsAlign?: 'vertical' | 'horizontal'
  children?: React.ReactNode
  footer?: React.ReactNode
  className?: String
  bodyClassName?: String
  headerTitleAction?: React.ReactNode
  headerActionsClassName?: String
  bodyRef?: any
  titleRef?: any
  containerStyle?: CSSProperties
  'data-testid'?: String
  invertTitleAndActions?: boolean
}

/**
 * Renders a PrismContainer with dynamic height, the body's height will change if there's a header or a footer container.
 *
 * @param title - renders the header container with a set of styles, like padding-bottom, color and typography.
 * @param actions - renders a container box below the title, it add a default space of 24px.
 * @param headerElementsAlign - by default the title and actions are displayed in the same row. Switch to vertical to allow the title to use the full row pushing the actions to jump into the next line.
 * @param children - the content of the body
 * @param footer - Adds a space for the footer.
 * @param className - The class name for the entire component
 * @param bodyClassName - The class name for the body
 * @param headerTitleAction - Adds a component before the title. It can be used in combination with invertTitleAndActions.
 * @param invertTitleAndActions, - useful when title, actions and headerTitleActions are being used at the same time. It will switch title with headerTitleAction position.
 * @param headerActionsClassName - The class name for the header
 * @param bodyRef - reference container to the body
 * @param titleRef - reference to the header component
 *
 *
 */
export const PrismContainer = ({
  title,
  actions,
  headerElementsAlign = 'horizontal',
  children,
  footer,
  className,
  bodyClassName,
  headerTitleAction,
  headerActionsClassName = '',
  bodyRef,
  titleRef,
  containerStyle,
  'data-testid': dataTestid,
  invertTitleAndActions,
}: PrismContainerProps) => {
  return (
    <div style={containerStyle} className={`${Styles.prismContainer}  ${className ?? ''}`}>
      {title && (
        <PrismContainerHeader
          titleRef={titleRef}
          data-testid={dataTestid}
          title={title}
          actions={actions}
          headerElementsAlign={headerElementsAlign}
          headerTitleAction={headerTitleAction}
          headerActionsClassName={headerActionsClassName}
          invertTitleAndActions={invertTitleAndActions}
        />
      )}
      <div className={`${bodyClassName ?? ''} ${Styles.prismContainerBody}`} ref={bodyRef}>
        {children}
      </div>
      {footer && <div className={Styles.prismContainerFooter}>{footer}</div>}
    </div>
  )
}

const PrismContainerHeader = ({
  title,
  actions,
  headerElementsAlign,
  headerTitleAction,
  headerActionsClassName,
  titleRef,
  'data-testid': dataTestId,
  invertTitleAndActions,
}: PrismContainerProps) => {
  return (
    <div
      ref={titleRef}
      className={`${Styles.prismContainerHeader} ${
        headerElementsAlign === 'vertical' ? Styles.itemsHorizontalAlign : Styles.itemsVerticalAlign
      } ${headerElementsAlign === 'horizontal' ? Shared.verticalChildrenGap24 : ''} ${headerActionsClassName}`}
    >
      <div className={`${Styles.headerTitleAndAction} ${invertTitleAndActions ? Styles.invertTitleAndActions : ''}`}>
        {headerTitleAction}
        <PrismOverflowTooltip content={title} className={Styles.title} data-testid={`${dataTestId}-header`} />
      </div>

      {actions}
    </div>
  )
}
