import React from 'react'

import { useIsColocated } from 'hooks'

import Styles from './RockwellSupport.module.scss'

/**
 * Renders Rockwell Support link
 * If the user is not colocated, it is clickable and navigates to https://rockwellautomation.custhelp.com/.
 *
 * @param className - It is the parent's responsibility to position this component correctly
 */

function RockwellSupport({ className }: { className?: string }) {
  const { isColocated } = useIsColocated()

  const canShowLink = isColocated === false
  const navigateToSupportLink = () => {
    if (!canShowLink) return
    window.open('https://rockwellautomation.custhelp.com/', '_blank')?.focus()
  }

  return (
    <span
      className={`${className || ''} ${canShowLink ? Styles.rockwellSupportLink : ''}`}
      onClick={navigateToSupportLink}
      data-testid="rockwell-support"
      data-test-attribute={!isColocated ? 'rockwell-support-ready' : ''}
    >
      Rockwell Support
    </span>
  )
}

export default RockwellSupport
