import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState = {}

export default function reduce(
  state: RootState['triggerMode'] = initialState,
  action: Action,
): RootState['triggerMode'] {
  if (action.type === 'TRIGGER_MODE_UPDATE') {
    const { robotId: robot_id, triggerMode } = action.payload
    return { ...state, [robot_id]: { triggerMode } }
  }
  return state
}
