import React from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'
import { useQueryEntityFromSites } from 'hooks'
import paths from 'paths'
import { PerEntityModalProps, SubSite } from 'types'
import { INPUT_MAX_CHARACTER_LIMIT } from 'utils/constants'

import EntityModal from './EntityModal'

/**
 * Renders the Create or Edit Line modal
 *
 *
 * @param entity - the modal name
 */
type EntityLineModalProps<T extends boolean> = PerEntityModalProps<T, SubSite> & { defaultSiteId?: string }

const AddOrEditLineModal = <T extends boolean>({
  isEditMode,
  onClose,
  entity: subSite,
  defaultSiteId,
}: EntityLineModalProps<T>) => {
  const history = useHistory()
  const defaultValues = getDefaultValues({ subSite, siteId: defaultSiteId })
  const sites = useQueryEntityFromSites({ entity: 'site', filterFn: site => !site.is_deleted, noRefetch: true })
  const {
    formState: { errors, isDirty, isValid },
    getValues,
    control,
  } = useForm({ defaultValues, mode: 'onChange' })
  const dispatch = useDispatch()

  async function handleSubmit() {
    const values = getValues()

    let res: SendToApiResponse<SubSite> | undefined = undefined

    if (isEditMode && subSite) {
      res = await service.updateSubSite(subSite.id, { name: values.name })
    }

    if (!isEditMode) {
      const subsiteTypes = await service.getSubSiteTypes({ site_id: values.site })

      if (subsiteTypes.type === 'success') {
        // We should always have a `Line` subsite type because we create one when creating a new site
        const lineSubSiteType = subsiteTypes.data.results.find(subsiteType => subsiteType.normalized_name === 'line')

        res = await service.createSubSite({ name: values.name, type_id: lineSubSiteType?.id })
      }
    }

    await query(getterKeys.sites(), service.getSites, { dispatch })

    if (!isEditMode && res?.type === 'success') {
      history.push(paths.inspect({ mode: 'site', params: { siteId: values.site } }), { lineIdToExpand: res.data.id })
    }

    onClose()

    return res
  }

  return (
    <EntityModal
      id="line-entity-modal"
      data-testid="add-line-modal"
      entity="line"
      onSubmit={handleSubmit}
      onClose={onClose}
      isEditMode={isEditMode}
      disableSave={!isValid || !isDirty}
    >
      <>
        <Controller
          name="name"
          rules={{ required: 'Name is required' }}
          control={control}
          render={({ ...props }) => (
            <PrismInput
              label="line name (*)"
              data-testid="line-name-input"
              {...props}
              errors={errors}
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
            />
          )}
        />
        <Controller
          name="site"
          rules={{ required: 'Site is required' }}
          control={control}
          render={({ ...props }) => (
            <Token label="site (*)">
              <PrismSelect
                {...props}
                data-testid="site-select"
                size="large"
                placeholder="Select site"
                disabled={isEditMode}
                filterOption={(input, option) => (option?.title as string)?.toLowerCase().includes(input.toLowerCase())}
                options={sites?.map(site => ({
                  value: site.id,
                  label: site.name,
                  title: site.name,
                  dataTestId: `site-${site.name}-option`,
                }))}
                showSearch
                showArrow
                disableDropdownAnimation
                stopPropagationOnDropdownClick
              />
            </Token>
          )}
        />
      </>
    </EntityModal>
  )
}

export default AddOrEditLineModal

const getDefaultValues = ({ subSite, siteId }: { subSite: SubSite | undefined; siteId: string | undefined }) => {
  return {
    name: subSite?.name,
    site: siteId,
  }
}
