/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react'

import { Path, Rect, Svg } from '@react-pdf/renderer'

const PDFCalendar = ({ iconBackground }: { iconBackground: string }) => (
  <Svg style={{ width: 11.4, height: 11.4 }} viewBox="0 0 24 24">
    <Rect width="24" height="24" fill={iconBackground} x="0" y="0" rx="12" ry="12" />

    <Path
      d="M6.333 9.958v6.209a.5.5 0 0 0 .5.5h10.334a.5.5 0 0 0 .5-.5V9.958m-11.334 0V7.833a.5.5 0 0 1 .5-.5h10.334a.5.5 0 0 1 .5.5v2.125m-11.334 0h11.334"
      stroke="#F8F8F9"
    />
    <Path d="M10 6.333v1.934M14 6.333v1.934" stroke="#F8F8F9" stroke-linecap="round" />
  </Svg>
)
export default PDFCalendar
