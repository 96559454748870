import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { PreprocessingOptions } from 'types'

import { OPENCV_URL, OpenCvProvider, useOpenCv } from '../OpenCv/OpenCvProvider'
import { OpenCvMat } from '../OpenCv/openCvTypes'
import Styles from './ImagePreprocessingPreview.module.scss'
import { dilateAndErode, hsvFilter, resize, rotate } from './transforms'

// note: using local one @ public folder is not working, likely CRA web server is not serving the JS file properly
const CANVAS_ID = 'canvasInput'

type ImagePreprocessingProps = {
  imgRef: HTMLImageElement | null
  settings?: PreprocessingOptions
  className?: string
  onPixelCountChange?: (whitePixelCount: number, totalImagePixelCount: number) => any
}

const ImagePreprocessing = ({ imgRef, settings, className, onPixelCountChange }: ImagePreprocessingProps) => {
  const cvObj = useOpenCv()
  const cv = cvObj?.cv as OpenCvMat
  const canvasRef = useRef(null)

  const renderImage = useCallback(() => {
    if (!cv || !settings) return null
    let dst = new cv.Mat()
    const src = cv.imread(imgRef)
    dst = src
    if (settings.rotation_angle !== undefined) dst = rotate(cv, src, settings.rotation_angle)

    if (settings.resize_width !== undefined && settings.resize_height !== undefined)
      dst = resize(cv, dst, settings.resize_width, settings.resize_height)

    // only run hsvFilter if settings.hsv_enable is true, or is not set (always enabled)
    if (settings.hsv_enable !== false) {
      dst = hsvFilter(cv, dst, settings.hsv_min, settings.hsv_max)

      // dilation/erosion requires hsv filter too
      dst = dilateAndErode(cv, dst, settings)
      const pixelCount = dst.data.reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

      onPixelCountChange && onPixelCountChange(pixelCount, dst.data.length)
    }

    cv.imshow(CANVAS_ID, dst)

    // in theory this should free memory
    dst.delete()
    src.delete()
  }, [cv, imgRef, onPixelCountChange, settings])

  useEffect(() => {
    if (cv && imgRef) {
      // If we try to preprocess an empty image we hit CORS errors
      if (imgRef.width === 0 && imgRef.height === 0) return
      renderImage()
    }
  }, [cv, settings, imgRef, renderImage])

  return (
    <canvas className={className} ref={canvasRef} id={CANVAS_ID} width={imgRef?.width} height={imgRef?.height}></canvas>
  )
}

export const ImagePreprocessingPreview = ({
  imgRef,
  settings,
  showEmptyPreview,
  ...rest
}: ImagePreprocessingProps & { showEmptyPreview: boolean }) => {
  const [scriptHasLoaded, setScriptHasLoaded] = useState<boolean>(false)

  const newSettings = useMemo(() => {
    if (settings) return scaleDown(settings)
  }, [settings])

  const handleLoad = () => {
    setScriptHasLoaded(true)
  }

  return (
    <ErrorBoundary
      onError={() => {
        // We could do something with these errors, but they're very noisy
        setScriptHasLoaded(false)
      }}
    >
      <OpenCvProvider openCvPath={OPENCV_URL} error={!scriptHasLoaded} onLoad={handleLoad}>
        {(!imgRef || !settings || !scriptHasLoaded) && !showEmptyPreview && (
          <div className={Styles.spinOnCenter}>
            <PrismLoader />
          </div>
        )}

        {scriptHasLoaded && <ImagePreprocessing imgRef={imgRef} settings={newSettings} {...rest} />}
      </OpenCvProvider>
    </ErrorBoundary>
  )
}

/**
 * Gets the standard settings for ocr and calculates a width and height scale that doesn't exceed 1.
 * E.g. Say user selects a scale of width: 4 and height: 2, this function will convert it into width: 1, height: 0.5.
 * The actual selected values will be stored as such in the database, but for performance reasons it's optimal to never
 * exceed a scale of 1 when using openCv client-side.
 *
 * @param settings - The ocr settings selected by the user
 */
const scaleDown = (settings: PreprocessingOptions) => {
  if (settings.resize_width === undefined || settings.resize_height === undefined) return settings
  const settingsCopy = { ...settings }
  if (settings.resize_width > 1 || settings.resize_height > 1) {
    let factor = 1
    if (settings.resize_width > settings.resize_height) factor = settings.resize_width
    else factor = settings.resize_height

    settingsCopy.resize_width = settings.resize_width / factor
    settingsCopy.resize_height = settings.resize_height / factor
  }
  return settingsCopy
}
