import React, { useEffect, useRef, useState } from 'react'

import { RefSelectProps, Select, SelectProps } from 'antd'
import { OptionProps } from 'rc-select/lib/Option'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { Divider } from 'components/Divider/Divider'
import { PrismArrowIcon, PrismElementaryCube, PrismPassIcon, PrismSearchIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { Status } from 'components/Status/Status'
import { SelectOption, SelectOptionImage, SelectOptionTooltip } from 'types'

import Styles from './PrismSelect.module.scss'
import SelectNotFound from './SelectNoResultOption'

const sizeClasses = { small: Styles.small, middle: Styles.middle, large: Styles.large }

/**
 * options Guide
 * @param value - value of the item, when the title is the same as the value, Value can be used alone.
 * @param title - when the title needs to be different than the value.
 * @param dataTestId - string to identify our data tests id
 * @param dataTest - string to identify our data test
 * @param disabled - adds disabled styles to the option
 * @param isHidden - hides the option from the list
 *
 */
export interface PrismSelectProps extends Omit<SelectProps<any>, 'options' | 'onChange'> {
  options?: SelectOption[]
  size?: 'small' | 'middle' | 'large'
  clearable?: boolean
  maxTagCount?: any
  multiClassName?: string
  popupClassName?: string
  onChange?: (val: any) => void
  stopPropagationOnDropdownClick?: boolean
  disableDropdownAnimation?: boolean
  hasGroups?: boolean
  'data-testid'?: string
  'data-test-attribute'?: string
  status?: 'warning' | 'error'
  showSelectedOptionCheck?: boolean
  wrapperClassName?: string
  thumbnailAndTextClassName?: string
  forceFlourishIcon?: boolean
}

/**
 * Renders a wrapper around ant select with custom Prism Styles
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?t=m76XGp9ZyLCkTnRt-0
 *
 * @param options – use it when the item is only text, and no need for special components to be rendered.
 * @param size – determine the height of the wrapper, the style and font.
 * @param clearable –
 * @param maxTagCount – Auto collapse to tag with responsive case. Not recommend use in large form case since responsive calculation has a perf cost.
 * @param multiClassName – special string to add  styles when the mode is multiple
 * @param onChange – Called when select an option or input value change
 * @param stopPropagationOnDropdownClick – Prevents the dropdown to be closed after clicking on it.
 * @param getPopupContainer – Parent Node which the selector should be rendered to. Default to body. When position issues happen, try to modify it into scrollable content and position it relative.
 * @param hasGroups - Changes the styles of the option menu list. if the option has empty value it will be interpreted as title, and different styles are applied.
 * @param status - Adds a color to the select border 'warning' = yellow , 'error' = red
 * @param showItemSelectedCheck - display a check mark on selected or active elements in the dropdown list
 * @param forceFlourishIcon - forces the flourish arrow icon
 */
export const PrismSelect = React.forwardRef<RefSelectProps, PrismSelectProps>(
  (
    {
      className,
      clearable,
      popupClassName,
      multiClassName,
      children,
      options,
      size = 'middle',
      maxTagCount,
      mode,
      notFoundContent,
      value,
      onChange,
      dropdownRender,
      stopPropagationOnDropdownClick,
      'data-testid': dataTestId,
      'data-test-attribute': dataTestAttribute,
      getPopupContainer,
      disableDropdownAnimation,
      status,
      hasGroups,
      showSelectedOptionCheck = false,
      wrapperClassName = '',
      thumbnailAndTextClassName = '',
      forceFlourishIcon,
      ...rest
    },
    ref,
  ) => {
    const sizeClass = sizeClasses[size]
    const [showSearchIcon, setShowSearchIcon] = useState(false)
    const [open, setOpen] = useState<boolean>(false)
    // This reference is passed down to the tooltip so it can calculate in which side to render
    const selectRef = useRef<HTMLDivElement>(null)
    const selectLeftPosition = useSelectRectPosition(selectRef)

    return (
      <div ref={selectRef} className={wrapperClassName}>
        <Select
          data-testid={dataTestId}
          data-test-attribute={dataTestAttribute}
          className={`${className ?? ''} ${sizeClass} ${Styles.selectWrapper} ${
            mode === 'multiple' ? multiClassName : ''
          }`}
          {...rest}
          value={value}
          onChange={val => {
            if (val === '' && hasGroups) return

            onChange?.(val)
          }}
          onDropdownVisibleChange={visible => {
            setShowSearchIcon(visible)
            setOpen(visible)
          }}
          dropdownStyle={!open && disableDropdownAnimation ? { display: 'none' } : {}}
          dropdownRender={options => {
            if (dropdownRender) return dropdownRender(options)

            return (
              <div onClick={e => stopPropagationOnDropdownClick && e.nativeEvent.stopImmediatePropagation()}>
                {clearable && mode !== 'multiple' && <PrismSelectOption value="">&mdash;</PrismSelectOption>}

                {options}

                {clearable && mode === 'multiple' && value && (
                  <div onClick={e => e.preventDefault()}>
                    <Divider />

                    <div onClick={() => onChange?.(undefined)} className={Styles.clearSelection}>
                      clear selection
                    </div>
                  </div>
                )}
              </div>
            )
          }}
          popupClassName={`${Styles.dropdownList} ${
            sizeClass === Styles.small ? Styles.smallDropdownList : Styles.largeDropdownList
          } ${showSelectedOptionCheck ? Styles.addSelectedMark : ''} ${popupClassName ?? ''}`}
          suffixIcon={
            <>
              {showSearchIcon ? (
                <PrismSearchIcon className={Styles.searchIcon} />
              ) : (
                <PrismArrowIcon direction="down" className={Styles.arrowIcon} forceFlourishIcon={forceFlourishIcon} />
              )}
            </>
          }
          ref={ref}
          maxTagCount={maxTagCount}
          mode={mode}
          size={size}
          notFoundContent={<SelectNotFound />}
          getPopupContainer={getPopupContainer}
          status={status}
          menuItemSelectedIcon={showSelectedOptionCheck && <PrismPassIcon className={Styles.checkIcon} />}
        >
          {options
            ?.filter(option => !option.isHidden)
            .map(
              ({
                className,
                value,
                title,
                dataTestId,
                dataTest,
                dataTestAttribute,
                disabled,
                tooltip,
                image,
                content,
                ...rest
              }) => (
                <PrismSelectOption
                  className={`${className ?? ''} ${value === '' && hasGroups ? Styles.groupTitle : ''}`}
                  title={title}
                  key={value}
                  value={value}
                  id={value}
                  data-testid={dataTestId}
                  data-test={dataTest}
                  data-test-attribute={dataTestAttribute}
                  disabled={disabled}
                  // Passed here to be able to use `content` as filter prop
                  content={content}
                  {...rest}
                >
                  <PrismSelectListItem
                    content={content || title || value}
                    tooltip={tooltip}
                    image={image}
                    selectLeftPosition={selectLeftPosition}
                    className={thumbnailAndTextClassName}
                  />
                </PrismSelectOption>
              ),
            )}

          {!options && children}
        </Select>
      </div>
    )
  },
)

// TODO: For some reason types are not working when using this component, we need to fix
// this to avoid issues in the future
export const PrismSelectOption = ({
  className,
  'data-test': dataTest,
  'data-testid': dataTestId,
  ...rest
}: OptionProps) => {
  return <Select.Option className={className ?? ''} {...rest} data-test={dataTest} data-testid={dataTestId} />
}

interface SelectListItemProps {
  content: React.ReactNode
  className?: string
  textClassName?: string
  'data-testid'?: string
  selectLeftPosition?: number
  canBeResized?: boolean
  image?: SelectOptionImage
  tooltip?: SelectOptionTooltip
}

/**
 * Renders a wrapper for the select content, which can be built by using text or adding an image
 *
 * @param image - Holds the values for content, hideBorder, and cameraStaus. Each adds different styles where the image is placed. Content is set for the image itself or a ReactNode that represents the image. hideBorder - displays or hides a border around the image container. cameraStatus - it'll display a status in the right bottom corner of the image.
 * @param content – The text or component holder.
 * @param tooltip - Holds values for tooltipCondition,tooltipPlacement,tooltipOverlayClassName and tooltipTitle. tooltipCondition – Replaces the overflowing text tooltip which happens in special cases where the tooltip is triggered by something external.tooltipPlacement – The tooltip position. tooltipOverlayClassName - A string for addiotional styles, which will be applied to the tooltip overlay. tooltipTitle – the tooltip text
 * @param className – String for additional styles, will apply to the container
 * @param textClassName – String for additional styles, will apply to the text container
 */
export const PrismSelectListItem = ({
  image,
  content,
  tooltip,
  selectLeftPosition,
  canBeResized,
  'data-testid': dataTestId,
  className = '',
  textClassName = '',
}: SelectListItemProps) => {
  return (
    <ConditionalWrapper
      condition={!!image?.content}
      wrapper={children => {
        const imageElement = image?.content && (
          <figure className={`${Styles.thumbnailContainer} ${image.hideImageBorder ? Styles.hideBorder : ''}`}>
            {image.content}

            {image.cameraStatus && (
              <Status status={image.cameraStatus} className={Styles.thumbnailStatus} showLabel={false} />
            )}
          </figure>
        )
        return (
          <div className={`${Styles.selectItem} ${className}`} data-testid={dataTestId}>
            {imageElement}

            {children}
          </div>
        )
      }}
    >
      <PrismOverflowTooltip
        content={content}
        tooltipOverlayClassName={tooltip?.tooltipOverlayClassName}
        tooltipCondition={tooltip?.tooltipCondition}
        tooltipTitle={tooltip?.tooltipTitle}
        tooltipPlacement={tooltip?.tooltipPlacement}
        className={`${textClassName} ${Styles.overflowContainer}`}
        anchorLeftPosition={selectLeftPosition}
        canBeResized={canBeResized}
      />
    </ConditionalWrapper>
  )
}

/**
 * Renders a select placeholder with the elementary cube
 *
 * @param title – The placeholder title
 * @param hideImage – A boolean to determine wether the cube should be visible or not.
 * @param hideImageBorder – A boolean to determine wether the border of the image is visible or not
 */
export const PrismSelectPlaceholder = ({
  title,
  hideImage = false,
  hideImageBorder = false,
}: {
  title: string
  hideImage?: boolean
  hideImageBorder?: boolean
}) => {
  return (
    <PrismSelectListItem
      content={title}
      image={{ content: !hideImage && <PrismElementaryCube />, hideImageBorder: hideImageBorder }}
    />
  )
}

/**
 * This hook returns a select DOM Rect left position.
 *
 * @param selectRef - container ref
 */
const useSelectRectPosition = (selectRef: React.RefObject<HTMLDivElement>) => {
  const [selectPosition, setSelectPosition] = useState(0)

  useEffect(() => {
    if (!selectRef.current) return

    const selectBoundingBox = selectRef.current?.getBoundingClientRect()
    setSelectPosition(selectBoundingBox.left)
  }, [selectRef])

  return selectPosition
}
