import React, { MouseEvent, SyntheticEvent, useCallback } from 'react'

import { useClick } from 'components/Button/Button'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useShouldPreventHotkeyTrigger } from 'components/ToggleButton/ToggleButton'
import { useHotkeyPress } from 'hooks'

import Styles from './IconButton.module.scss'

const sizeClasses = { xsmall: Styles.xsmall, small: Styles.small, medium: Styles.medium, large: Styles.large }
const typeClasses = {
  primary: Styles.primary,
  secondary: Styles.secondary,
  tertiary: Styles.tertiary,
  ghost: Styles.ghost,
  transparent: Styles.transparent,
}

interface IconButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
  onClick?: (e: SyntheticEvent) => Promise<any> | void | any
  onMouseDown?: (e: MouseEvent) => any
  onMouseUp?: (e: MouseEvent) => any
  type?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'transparent'
  isOnTop?: boolean
  icon: React.ReactNode
  className?: string
  loading?: boolean
  disabled?: boolean
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  to?: string
  'data-testid'?: string
  'data-test'?: string
  hideLoader?: boolean
  hotkey?: string
}

/**
 * Renders custom icon button component
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3608%3A0
 *
 * @param onClick - {onClick}
 * @param type - changes the looks of the button
 * @param icon - icon name to be shown
 * @param className - string class that will be passed down to the button
 *     element
 * @param loading - shows a loading indicator and prevents the button from being
 *     clicked
 * @param disabled - it prevents button from being clicked
 * @param size - increase/decrease size of padding and text inside the button
 * @param to - a path inside the app that the button will redirect to
 * @param isOnTop - adds a special hover background for buttons that are in front of other elements
 */
export function IconButton({
  onClick,
  onMouseDown,
  onMouseUp,
  icon,
  isOnTop,
  className,
  size = 'medium',
  type = 'primary',
  loading,
  disabled,
  to,
  hotkey,
  'data-testid': dataTestId,
  'data-test': dataTest,
  hideLoader = false,
  ...rest
}: IconButtonProps) {
  const shouldPreventHotkeyTrigger = useShouldPreventHotkeyTrigger()

  const { handleClick, innerLoading, done } = useClick({ to, onClick, loading })

  const isLoading = innerLoading || loading

  const sizeClass = sizeClasses[size]
  const typeClass = typeClasses[type]

  const buttonClassName = `${sizeClass} ${typeClass} ${Styles.button} ${className || ''} ${
    isLoading ? Styles.isLoading : ''
  } ${isOnTop ? Styles.isOnTop : ''}`

  const keyboardHandler = useCallback(
    (e: KeyboardEvent) => {
      if (!hotkey || shouldPreventHotkeyTrigger) return

      if (e.key.toLowerCase() === hotkey.toLowerCase()) {
        e.preventDefault()
        onClick?.(e as unknown as SyntheticEvent)
      }
    },
    [hotkey, onClick, shouldPreventHotkeyTrigger],
  )

  useHotkeyPress(keyboardHandler)

  return (
    <button
      className={buttonClassName}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      disabled={disabled || loading}
      data-testid={dataTestId}
      data-test={dataTest}
      type="button"
      data-test-attribute={
        isLoading
          ? `${dataTestId}-loading`
          : done
          ? `${dataTestId}-done`
          : disabled
          ? `${dataTestId}-disabled`
          : `${dataTestId}-active`
      }
      {...rest}
    >
      {icon}
      {isLoading && !hideLoader && (
        <PrismLoader className={Styles.loader} size={size === 'xsmall' ? 'small' : undefined} />
      )}
    </button>
  )
}
