import React, { ButtonHTMLAttributes, MouseEventHandler, SyntheticEvent, useState } from 'react'

import { isNil } from 'lodash'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismCloseIcon, PrismEditIcon } from 'components/prismIcons'
import { LabelButtonSeverity, PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import FullScreenLabelImages from 'pages/RoutineOverview/LabelingScreen/FullScreenLabelImages'
import { LabelGlossaryModal } from 'pages/RoutineOverview/LabelingScreen/LabelGlossaryModal'
import { LabelingInfoPopover } from 'pages/RoutineOverview/LabelingScreen/LabelingInfoPopover'
import { ToolLabel, ToolSpecificationName } from 'types'
import { getToolLabelImagesToShow } from 'utils'

import Styles from './PrismLabelButton.module.scss'

const typeClasses = {
  pass: Styles.labelPass,
  fail: Styles.labelFail,
  minor: Styles.labelUnknown,
  unknown: Styles.labelDiscard,
  discard: Styles.labelDiscard,
  critical: Styles.labelFail,
  good: Styles.labelPass,
  neutral: Styles.labelDiscard,
  test_set: Styles.labelDiscard,
}

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
  value?: string
  severity: LabelButtonSeverity
  hotkey?: number | string
  hotkeyClassName?: string
  readOnly?: boolean
  editMode?: boolean
  active?: boolean
  onEditClick?: (e: SyntheticEvent) => Promise<any> | void | any
  onDeleteClick?: (e: SyntheticEvent) => Promise<any> | void | any
  toolLabel?: ToolLabel
  toolParentId?: string
  toolSpecificationName?: ToolSpecificationName
  popoverClassName?: string
  'data-testid'?: string
  'data-test'?: string
}

/**
 * Renders a label button component
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A0
 *
 * @param value - The title of the button
 * @param severity - changes the looks of the button and the icon
 * @param hotkey - in case the button needs a hotkey
 * @param hotkeyClassName - string class selector to add special class into the hotkey container.
 * @param readOnly - indicates if the button is read only
 * @param editMode - display 2 actions inside the label, which can edit or delete.
 * @param active - adds the active styles and the bg styles depende on the severity.
 * @param onEditClick - edit the label
 * @param onDeleteClick - Deletes the label
 * @param className - string class that will be passed down to the button
 * @param disabled - it prevents button from being clicked
 * @param toolLabel - tool label used to show popover info
 * @param toolParentId - The current tool parent id, used in case glossary is opened from popover
 * @param toolSpecificationName - The current tool specification name, used in case glossary is opened from popover
 * @param popoverClassname - Popover component classname
 * @param onClick - onClick handler
 *
 */
const PrismLabelButton = ({
  value,
  severity,
  hotkey,
  hotkeyClassName = '',
  readOnly,
  editMode,
  active,
  onEditClick,
  onDeleteClick,
  className = '',
  disabled,
  toolLabel,
  toolParentId,
  toolSpecificationName,
  popoverClassName,
  'data-testid': dataTestId,
  'data-test': dataTest,
  onClick,
  ...rest
}: Props) => {
  const typeClass = typeClasses[severity]

  const [showLabelPopover, setShowLabelPopover] = useState(false)
  const [showGlossary, setShowGlossary] = useState(false)
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>()

  const showLabelHotkey = !isNil(hotkey)

  const handleClick: MouseEventHandler<HTMLDivElement> = e => {
    if (showLabelPopover || disabled || editMode) return
    onClick?.(e)
  }

  const images = toolLabel ? getToolLabelImagesToShow(toolLabel, 'full') : undefined

  const showPopover = !!toolLabel
  return (
    <>
      {selectedImageIdx !== undefined && images?.[selectedImageIdx] && (
        <FullScreenLabelImages
          imageUrls={images}
          selectedImageIdx={selectedImageIdx}
          setSelectedImageIdx={setSelectedImageIdx}
          label={toolLabel?.value}
        />
      )}
      <div
        data-testid={dataTestId}
        data-test={dataTest}
        {...rest}
        className={`${Styles.prismLabelButton} ${className ?? ''} ${editMode ? Styles.editMode : ''} ${
          editMode ? '' : typeClass
        } ${disabled ? Styles.disabled : ''} ${readOnly ? Styles.readOnly : ''} ${active ? Styles.active : ''}`}
        onClick={handleClick}
      >
        <PrismResultButton
          value={value}
          severity={severity}
          type="pureWhite"
          iconClassName={Styles.iconColor}
          className={Styles.labelButtonResult}
          data-testid={dataTestId}
        />

        {editMode && toolLabel?.kind === 'custom' && (
          <div className={Styles.hotkeyActions}>
            <IconButton
              icon={<PrismEditIcon />}
              type="secondary"
              size="xsmall"
              onClick={onEditClick}
              className={Styles.labelHotkey}
              data-testid={`${dataTestId}-edit`}
            />

            <PrismTooltip placement="bottom" title="Remove label">
              <IconButton
                icon={<PrismCloseIcon />}
                type="secondary"
                size="xsmall"
                onClick={onDeleteClick}
                className={Styles.labelHotkey}
                data-testid={`${dataTestId}-remove`}
              />
            </PrismTooltip>
          </div>
        )}

        {!editMode && (
          <ConditionalWrapper
            condition={showPopover}
            wrapper={children => (
              <LabelingInfoPopover
                open={showLabelPopover}
                onOpenChange={setShowLabelPopover}
                toolLabel={toolLabel!}
                popoverClassName={popoverClassName ?? ''}
                onClose={() => setShowLabelPopover(false)}
                setSelectedImageIdx={setSelectedImageIdx}
                onEditLabelClick={() => {
                  setShowGlossary(true)
                  setShowLabelPopover(false)
                }}
              >
                {/* We want to stop the labeling click whenever users click on labels that can be edited */}
                <div onClick={e => e.stopPropagation()}>{children}</div>
              </LabelingInfoPopover>
            )}
          >
            <div
              data-test={`${dataTest}-hotkey`}
              data-testid={`${dataTest}-hotkey-${toolLabel?.value}-${toolLabel?.severity}`}
              className={`${Styles.labelHotkey} ${hotkeyClassName ?? ''} ${
                !showLabelHotkey ? Styles.infoIconContainer : ''
              }`}
            >
              {showLabelHotkey && <span className={showPopover ? Styles.hotkey : ''}>{hotkey}</span>}

              <PrismTooltip
                data-testid="label-button-more-info-tooltip"
                title="More info"
                placement="bottom"
                overlayClassName={showLabelPopover ? Styles.hideTooltip : ''}
                anchorClassName={Styles.labelButtonTooltip}
              >
                <span className={Styles.infoIcon}>
                  <PrismResultButton severity="unknown" iconClassName={Styles.infoIconColor} type="pureWhite" />
                </span>
              </PrismTooltip>
            </div>
          </ConditionalWrapper>
        )}
      </div>

      {showGlossary && toolParentId && (
        <LabelGlossaryModal
          onClose={() => setShowGlossary(false)}
          initialSelectedToolLabel={toolLabel}
          toolParentId={toolParentId}
        />
      )}
    </>
  )
}

export default PrismLabelButton
