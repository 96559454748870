import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Smart Group Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5302%3A12054
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismSmartGroupIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.smartGroup} ${hasBackground ? Styles.hasBackground : ''} ${
        className ?? ''
      } `}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.66667" cy="4.66667" r="2.16667" className={Styles.circle} />
      <path
        d="M13.5 4.66667C13.5 5.86328 12.5299 6.83333 11.3333 6.83333C10.1367 6.83333 9.16666 5.86328 9.16666 4.66667C9.16666 3.47005 10.1367 2.5 11.3333 2.5C12.5299 2.5 13.5 3.47005 13.5 4.66667Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M6.83333 11.3334C6.83333 12.53 5.86328 13.5 4.66667 13.5C3.47005 13.5 2.5 12.53 2.5 11.3334C2.5 10.1367 3.47005 9.16669 4.66667 9.16669C5.86328 9.16669 6.83333 10.1367 6.83333 11.3334Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <circle cx="11.3333" cy="11.3334" r="2.16667" className={Styles.circle} />
    </svg>
  )
}
