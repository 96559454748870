import React from 'react'

import { PrismElementaryCube } from './PrismElementaryCube/PrismElementaryCube'
import Styles from './PrismIcons.module.scss'

export const PrismVideoLoadError = ({ className }: { className?: string }) => {
  return (
    <div className={`${Styles.videoLoadError} ${className ?? ''}`}>
      <PrismElementaryCube className={Styles.imageContainer} />
      <svg viewBox="0 0 320 80" className={Styles.svgText}>
        <text x="50%" y="10" fill="currentColor" className={Styles.title}>
          No camera detected
        </text>
        <text x="50%" y="46" fill="currentColor" className={Styles.description}>
          This may be due to connectivity issues
        </text>
        <text x="50%" y="65" fill="currentColor" className={Styles.description}>
          or a lack of power on the device
        </text>
      </svg>
    </div>
  )
}
