import React from 'react'

import Styles from './Token.module.scss'

const alignClasses = {
  left: Styles.prismToken_left,
  center: Styles.prismToken_center,
  right: Styles.prismToken_right,
}

interface TokenProps {
  label?: string | React.ReactNode
  secondaryLabel?: string | number | React.ReactNode
  children: React.ReactNode
  horizontal?: boolean
  inverted?: boolean
  title?: string
  align?: 'left' | 'center' | 'right'
  className?: string
  valueClassName?: string
  onClick?: () => void
  'data-testid'?: string
  'data-test'?: string
  'data-test-attribute'?: string
  labelClassName?: string
}

interface TokenLabelProps {
  label?: string | React.ReactNode
  secondaryLabel?: string | number | React.ReactNode
  className?: string
  'data-testid'?: string
}

/**
 * Renders a horizontal token with large label and large value
 *
 * @param label – Main text
 * @param secondaryLabel – Secondary text
 */
const TokenLabel = ({ label, secondaryLabel, className, 'data-testid': dataTestId }: TokenLabelProps) => {
  if (!label && !secondaryLabel) {
    return null
  }

  return (
    <dt
      data-testid={`${dataTestId}-label`}
      className={`${Styles.prismToken_label} ${className ?? ''} ${
        secondaryLabel ? Styles.prismToken_label_withSecondary : ''
      }`}
    >
      <div className={secondaryLabel ? Styles.textLeft : ''}>{label}</div>
      {secondaryLabel && <span>{secondaryLabel}</span>}
    </dt>
  )
}

/**
 * Renders a token (label + value)
 *
 * @param label – Main label
 * @param secondaryLabel – Secondary label
 * @param value – Main value to be rendered
 * @param type – Will change the value color
 * @param title – Complete text value of the content of the token. This prop is necessary in order to show the tooltip with
 * the full text when on ellipsis
 * @param horizontal – If true value will be rendered to the right of label
 * @param style – Custom styles
 * @param className – Custom className
 * @param onClick – optional onClick handler, has hover styles when added
 * @param labelClassName - adds custom styles to the label container
 */
export const Token = ({
  label,
  secondaryLabel,
  children,
  horizontal,
  inverted,
  align = 'left',
  className,
  valueClassName,
  onClick,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  labelClassName = '',
}: TokenProps) => {
  const alignClass = alignClasses[align]

  const TokenTag = !!onClick ? 'button' : 'span'

  return (
    <TokenTag
      className={`${Styles.prismToken} ${alignClass} ${horizontal ? Styles.prismTokenHorizontal : ''}
      ${inverted ? Styles.inverted : ''} ${className ?? ''} ${!!onClick ? Styles.clickable : ''}
      `}
      onClick={onClick}
      data-testid={dataTestId}
      data-test={dataTest}
      data-test-attribute={dataTestAttribute}
    >
      <TokenLabel
        data-testid={dataTestId}
        className={`${alignClass} ${labelClassName}`}
        label={label}
        secondaryLabel={secondaryLabel}
      />

      <dd className={valueClassName}>
        <div className={Styles.prismToken_value} data-testid={`${dataTestId}-value`}>
          {children}
        </div>
      </dd>
    </TokenTag>
  )
}
