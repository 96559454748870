import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Camera Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=7582%3A15330
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles.
 */
export const PrismCameraIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M12.3994 8.47314C13.6969 9.77065 13.6969 11.8743 12.3994 13.1719C11.1019 14.4694 8.99819 14.4694 7.70068 13.1719C6.40316 11.8743 6.40316 9.77065 7.70068 8.47314C8.99819 7.17562 11.1019 7.17562 12.3994 8.47314"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.50016V15.0002C17.5 15.921 16.7542 16.6668 15.8333 16.6668H4.16667C3.24583 16.6668 2.5 15.921 2.5 15.0002V7.50016C2.5 6.57933 3.24583 5.8335 4.16667 5.8335H5.83333L7.05167 3.74683C7.20083 3.491 7.475 3.3335 7.77167 3.3335H12.1917C12.4842 3.3335 12.755 3.48683 12.9058 3.73683L14.1667 5.8335H15.8333C16.7542 5.8335 17.5 6.57933 17.5 7.50016Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
