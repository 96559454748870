import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Mute Icon
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=10019-16902&mode=design&t=soOs78uHY4daf29P-0
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismMuteIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00018 10.76C7.00018 8.16921 9.19989 6 12.0002 6C13.7771 6 15.3122 6.87343 16.1969 8.17377L17.066 7.672C16.0013 6.06569 14.1304 5 12.0002 5C8.68647 5 6.00018 7.57884 6.00018 10.76V14.0608L7.00018 13.4835V10.76ZM7.00018 14.7678L6.00018 15.3451V15.3454L3.68319 16.6831C3.44404 16.8211 3.36211 17.1269 3.50018 17.3661L3.75007 17.7989L3.75018 17.7991L5.13426 17H5.50018H6.00018H7.00018H17.0002H18.0002H18.5002H19.0002V16.5C19.0002 16.2239 18.7763 16 18.5002 16H18.0002V10.76C18.0002 10.3759 17.961 10.0006 17.8863 9.63759L21.0707 7.7991L21.0706 7.79891L21.0705 7.79898L20.8205 7.36596C20.7725 7.28286 20.7042 7.21875 20.6256 7.17625C20.478 7.09656 20.2936 7.09303 20.1377 7.18307L17.5871 8.65562C17.5871 8.65556 17.5871 8.65549 17.5871 8.65542L16.7099 9.16183C16.7099 9.1619 16.71 9.16197 16.71 9.16204L7.00018 14.768V14.7678ZM7.00018 15.9227L16.962 10.1713C16.9872 10.3645 17.0002 10.561 17.0002 10.76V16H7.00018V15.9227Z"
        fill="currentColor"
      />
      <path
        d="M11.5 4.5C11.5 4.22386 11.7239 4 12 4C12.2761 4 12.5 4.22386 12.5 4.5V5.5H11.5V4.5Z"
        stroke="currentColor"
      />
      <path d="M10 16.5V18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18V16.5" stroke="currentColor" />
    </svg>
  )
}
