import React, { useMemo } from 'react'
import { useEffect, useRef, useState } from 'react'

import { Popover } from 'antd'
import { useHistory } from 'react-router-dom'

import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { IconButton } from 'components/IconButton/IconButton'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismAddIcon, PrismChartIcon, PrismEditIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { PrismResultButton, ResultIconSize, ResultIconType } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import SerialList from 'components/SerialList/SerialList'
import FullScreenLabelImages from 'pages/RoutineOverview/LabelingScreen/FullScreenLabelImages'
import { LabelGlossaryModal } from 'pages/RoutineOverview/LabelingScreen/LabelGlossaryModal'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel, ToolSpecificationName } from 'types'
import { findDefaultDescription, getDisplaySeverity, getLabelName, getToolLabelImagesToShow, matchRole } from 'utils'

import Styles from './LabelsList.module.scss'

interface LabelListElement extends ToolLabel {
  extraContent?: string
}

interface LabelsListProps {
  labels: LabelListElement[]
  toolParentId?: string
  toolSpecificationName?: ToolSpecificationName
  joint?: 'and' | 'or' | null
  showPopoverOnClick?: boolean
  className?: string
  labelClassName?: string
  popoverClassName?: string
  popoverPlacement?: 'bottom' | 'top'
  prismResultIconType?: ResultIconType
  prismResultIconSize?: ResultIconSize
  prismResultIconClassName?: string
  'data-testid'?: string
  'data-test'?: string
}

interface LabelsCounterProps {
  hiddenLabels: LabelListElement[]
  setGlossaryLabel: (toolLabel: ToolLabel) => void
  showPopoverOnClick?: boolean
  className?: string
  popoverPlacement?: 'bottom' | 'top'
  toolParentId?: string
  toolSpecificationName?: ToolSpecificationName
}

/**
 * Component which converts an array of Labels into a dynamic label container.
 * Rules:
 * - Always show 1 Label
 * - Renders +n, if there's no enough space in the container for other than the first label.
 * - +n, where n is the number of stored labels
 * - When +n is hovered it renders a list with the stored labels.
 * - Labels in the list can be selected (if showPopoverOnClick is true) to render the Label info.
 *
 * @param labels - array of Tool Labels
 * @param toolParentId - the current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param joint - allows us to change the joing between each label container, the default is set to single ','
 * @param showPopoverOnClick - when true it allows the selected element from the list to display the labelingInfo
 * @param className - string that hold the css selector
 * @param labelClassName - string that hold the css selector (specific for labels)
 * @param popoverClassName - string that hold the css selector (specific for popover menu)
 * @param popoverPlacement -  Indicates the Popover placement
 */

const LabelsList = ({
  labels,
  toolParentId,
  joint = null,
  showPopoverOnClick = false,
  className,
  labelClassName,
  popoverClassName,
  popoverPlacement,
  prismResultIconType = 'noFill',
  prismResultIconSize = 'small',
  prismResultIconClassName = '',
  'data-test': dataTest,
  'data-testid': dataTestId,
}: LabelsListProps) => {
  const labelListRef = useRef<HTMLDivElement>(null)
  const renderingFullListRef = useRef(false)

  const [glossaryLabel, setGlossaryLabel] = useState<ToolLabel>()
  const [labelsToShow, setLabelsToShow] = useState<LabelListElement[]>([])

  const labelsToHide = useMemo(() => {
    const labelsToShowIds = labelsToShow.map(toolLabel => toolLabel.id)

    return labels.filter(toolLabel => !labelsToShowIds.includes(toolLabel.id))
  }, [labels, labelsToShow])

  useEffect(() => {
    if (!labels.length) return
    const labelListCleanUp = labelListRef.current
    const renderWithAllLabels = () => {
      setLabelsToShow([...labels])
      renderingFullListRef.current = true
    }

    renderWithAllLabels()
    const observer = new ResizeObserver(() => {
      renderWithAllLabels()
    })

    if (labelListCleanUp) observer.observe(labelListCleanUp)

    window.addEventListener('resize', renderWithAllLabels)

    return () => {
      if (labelListCleanUp) observer.unobserve(labelListCleanUp)
      window.removeEventListener('resize', renderWithAllLabels)
    }
  }, [labels])

  // Renders all labels and figures out which ones actually fit in the container
  useEffect(() => {
    if (!labelsToShow.length || !labelListRef.current || !renderingFullListRef.current) return
    const labelListItems = labelListRef.current.querySelectorAll<HTMLDivElement>(':scope>div')
    const listContainerTop = labelListRef.current.offsetTop
    const newLabelsToShow: LabelListElement[] = []

    labelListItems.forEach((labelElement, i) => {
      const labelTop = labelElement.offsetTop
      const label = labels[i]

      if (labelTop === listContainerTop) {
        if (label) newLabelsToShow.push(label)
      }
    })

    setLabelsToShow(newLabelsToShow)
    renderingFullListRef.current = false
  }, [labelsToShow, labels])

  if (labels.length === 0)
    return (
      <span data-testid={`${dataTestId}-empty-labels`} className={Styles.labelCardEmpty}>
        --
      </span>
    )

  return (
    <div className={`${Styles.labelListWrapper} ${className ?? ''}`}>
      <div className={`${Styles.labelList} ${joint === null ? Styles.commaStyles : ''}`} ref={labelListRef}>
        <SerialList joint={joint}>
          {labelsToShow.map(label => (
            // This wrapper is to support rendering things like percentages next to the label, which happens in Analyze Products, Batches or Stations
            <ConditionalWrapper
              key={label.id}
              condition={!!label.extraContent}
              wrapper={content => (
                <div className={Styles.addPercentage}>
                  {content}
                  <div data-testid={`${dataTestId}-extra-content`} className={Styles.percentage}>
                    (<span>{label.extraContent}</span>)
                  </div>
                </div>
              )}
            >
              <PrismResultButton
                key={label.id}
                severity={getDisplaySeverity(label)}
                value={getLabelName(label)}
                size={prismResultIconSize}
                type={prismResultIconType}
                toolLabel={label}
                toolParentId={toolParentId}
                showPopOverOnClick={showPopoverOnClick}
                className={`${Styles.label} ${labelClassName ?? ''}`}
                iconClassName={prismResultIconClassName}
                data-testid={dataTestId}
                data-test={dataTest}
              />
            </ConditionalWrapper>
          ))}
        </SerialList>
      </div>

      {labels.length > 1 && (
        <LabelCounter
          setGlossaryLabel={setGlossaryLabel}
          hiddenLabels={labelsToHide}
          showPopoverOnClick={showPopoverOnClick}
          popoverPlacement={popoverPlacement}
          className={popoverClassName}
          toolParentId={toolParentId}
        />
      )}

      {glossaryLabel && toolParentId && (
        <LabelGlossaryModal
          onClose={() => setGlossaryLabel(undefined)}
          toolParentId={toolParentId}
          initialSelectedToolLabel={glossaryLabel}
        />
      )}
    </div>
  )
}

export default LabelsList

/**
 * Component that renders a + with a counter.
 *
 * @param toolParentId - the current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param hiddenLabels - array of Tool Labels
 * @param setGlossaryLabel - callback to open the label glossary modal
 * @param showPopoverOnClick - when true it allows the selected element from the list to display the labelingInfo
 * @param popoverPlacement -  Indicates the Popover placement
 * @param className - string that hold the css selector
 */
const LabelCounter = ({
  toolParentId,
  hiddenLabels,
  setGlossaryLabel,
  showPopoverOnClick,
  popoverPlacement = 'bottom',
  className,
}: LabelsCounterProps) => {
  const [selectedLabel, setSelectedLabel] = useState<ToolLabel>()
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>()

  return (
    <Popover
      overlayClassName={`${Styles.labelsInPopover} ${className ?? ''}`}
      placement={popoverPlacement}
      title={null}
      onOpenChange={() => setSelectedLabel(undefined)}
      content={
        <>
          {!selectedLabel && (
            <div className={Styles.labelsInPopoverBody}>
              {hiddenLabels.map(label => (
                // This wrapper is to support rendering things like percentages next to the label, which happens in Analyze Products, Batches or Stations
                <ConditionalWrapper
                  key={label.id}
                  condition={!!label.extraContent}
                  wrapper={content => (
                    <div className={Styles.addPercentage}>
                      {content}
                      <div className={Styles.percentage}>
                        (<span>{label.extraContent}</span>)
                      </div>
                    </div>
                  )}
                >
                  <PrismResultButton
                    severity={getDisplaySeverity(label)}
                    value={getLabelName(label)}
                    size="small"
                    type="noFill"
                    toolParentId={toolParentId}
                    toolLabel={label}
                    showPopOverOnClick={false}
                    onClick={() => {
                      setSelectedLabel(label)
                    }}
                    key={label.id}
                    className={Styles.tooltip}
                  />
                </ConditionalWrapper>
              ))}
            </div>
          )}
          {/* Renders the selected label info */}
          {selectedLabel && showPopoverOnClick && (
            <SelectedLabelInfo
              selectedLabel={selectedLabel}
              onEditLabelClick={() => {
                setGlossaryLabel(selectedLabel)
                setSelectedLabel(undefined)
              }}
              setSelectedImageIdx={setSelectedImageIdx}
              selectedImageIdx={selectedImageIdx}
            />
          )}
        </>
      }
    >
      <div className={`${Styles.plusOneResult} ${Styles.tooltip}`}>
        {hiddenLabels.length ? (
          <>
            <PrismAddIcon className={Styles.counterAddIcon} />
            {hiddenLabels?.length}
          </>
        ) : null}
      </div>
    </Popover>
  )
}

/**
 * Content to render inside of the popover when you click on a label.
 *
 * @param selectedLabel - label for which to render the content
 * @param onEditLabelClick - callback to call when clicking edit icon
 * @param setSelectedImageIdx - callback to move around the fullscreen images
 * @param selectedImageIdx - current fullscreen image
 */
const SelectedLabelInfo = ({
  selectedLabel,
  onEditLabelClick,
  setSelectedImageIdx,
  selectedImageIdx,
}: {
  selectedLabel: ToolLabel
  onEditLabelClick: (toolLabel: ToolLabel) => void
  setSelectedImageIdx: (idx?: number) => void
  selectedImageIdx?: number
}) => {
  const history = useHistory()

  const description = useMemo(() => {
    if (selectedLabel.kind === 'default') return findDefaultDescription(selectedLabel)

    return selectedLabel.description || ''
  }, [selectedLabel])

  const images = getToolLabelImagesToShow(selectedLabel)

  const handleViewResultsClick = () => {
    const newPath = paths.analyze({
      mode: 'items',
      params: {
        prediction_label_id: selectedLabel.id,
      },
    })
    history.push(newPath)
  }

  return (
    <>
      {selectedImageIdx !== undefined && images?.[selectedImageIdx] && (
        <FullScreenLabelImages
          imageUrls={images}
          selectedImageIdx={selectedImageIdx}
          setSelectedImageIdx={setSelectedImageIdx}
          label={selectedLabel?.value}
        />
      )}
      <div className={Styles.labelingInfo}>
        <div className={Styles.labelingInfoHeader}>
          <PrismOverflowTooltip className={Styles.labelingInfoHeaderTitle} content={getLabelName(selectedLabel)} />
          <div className={Styles.labelingInfoHeaderActions}>
            {matchRole('manager') && (
              <PrismTooltip title="Edit Label" open={selectedLabel?.kind !== 'default'}>
                <IconButton
                  disabled={selectedLabel?.kind === 'default'}
                  icon={<PrismEditIcon />}
                  type="tertiary"
                  size="xsmall"
                  onClick={() => {
                    onEditLabelClick(selectedLabel)
                  }}
                />
              </PrismTooltip>
            )}

            <PrismTooltip title="View Results">
              <IconButton icon={<PrismChartIcon />} type="tertiary" size="xsmall" onClick={handleViewResultsClick} />
            </PrismTooltip>
          </div>
        </div>

        <div className={`${Styles.labelingInfoBody} ${Shared.verticalChildrenGap24}`}>
          <LabelInfo title="Severity" addDescriptionGap>
            <PrismResultButton
              severity={getDisplaySeverity(selectedLabel)}
              value={selectedLabel.severity}
              type="fill"
              className={Styles.severityContainer}
            />
          </LabelInfo>

          {images.length > 0 && (
            <LabelInfo title="Example Images" addDescriptionGap>
              <div className={Styles.examplesImagesWrapper}>
                {images.map((img, idx) => (
                  <figure
                    key={idx}
                    className={`${Styles.exampleImageContainer}`}
                    onClick={() => setSelectedImageIdx(idx)}
                  >
                    <ImgFallback src={img} retries={5} className={Styles.labelFormImage} />
                  </figure>
                ))}
              </div>
            </LabelInfo>
          )}

          {description && (
            <LabelInfo title="Description">
              <p className={Styles.itemDescription}>{description}</p>
            </LabelInfo>
          )}

          {selectedLabel.root_cause && (
            <LabelInfo title="Root Causes">
              <p className={Styles.itemDescription}>{selectedLabel.root_cause}</p>
            </LabelInfo>
          )}

          {selectedLabel.corrective_actions && (
            <LabelInfo title="Corrective Actions">
              <p className={Styles.itemDescription}>{selectedLabel.corrective_actions}</p>
            </LabelInfo>
          )}
        </div>
      </div>
    </>
  )
}
