import React, { useEffect, useMemo, useState } from 'react'

import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { PrismCard } from 'components/PrismCard/PrismCard'
import { PrismAddIcon, PrismElementaryCube, PrismWarningIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { RecipeExpanded, RecipeRoutine } from 'types'
import { sortByName, sortByOldestFirst } from 'utils'

import AddViewModal from './AddViewModal'
import Styles from './RecipeView.module.scss'

interface Props {
  recipe: RecipeExpanded
  routineParentId: string
  onClick: (recipeRoutine: RecipeRoutine) => void
  className?: string
}

/**
 * Renders all the routines in a recipe.
 * @param recipeRoutines - All the links between Recipe and Routines.
 * @param routineParentId - RoutineParentId that is currently active. Used to check if the selected routine is active.
 * @param onClick - Event handler for when the user clicks a specific routine.
 * @param className - Class set to the whole section.
 */
const RecipeViews = ({ recipe, routineParentId, onClick, className = '' }: Props) => {
  const [addViewModal, setAddViewModal] = useState<boolean>(false)
  const recipeRoutines = useMemo(() => {
    return [...recipe.recipe_routines].sort(sortViewsByOldestFirstThenByName)
  }, [recipe])

  // Scrolls to active View item
  useEffect(() => {
    if (!recipeRoutines || !routineParentId) return
    const activeCardId = recipeRoutines.find(recipeRoutine => recipeRoutine.routine.parent.id === routineParentId)?.id

    const activeCardElement = document.getElementById(activeCardId || '')

    if (activeCardElement) {
      activeCardElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [routineParentId, recipeRoutines])
  return (
    <section className={`${Styles.viewSection} ${className}`}>
      <div className={Styles.viewContainer}>
        <ul className={Styles.viewList}>
          {recipeRoutines.map(recipeRoutine => (
            <li
              key={recipeRoutine.id}
              className={`${Styles.viewItem}  ${!Boolean(recipeRoutine.robot_id) ? Styles.unlinkedState : ''}`}
            >
              <PrismCard
                image={
                  <>
                    {recipeRoutine.routine.image ? (
                      <ImageWithBoxes src={recipeRoutine.routine.image} loaderType="skeleton" />
                    ) : (
                      <PrismElementaryCube />
                    )}

                    {!recipeRoutine.robot_id ? (
                      <div className={Styles.cardOverlay}>
                        <PrismWarningIcon isActive className={Styles.warningIcon} />
                      </div>
                    ) : null}
                  </>
                }
                className={Styles.viewItemCard}
                imageContainerClassName={Styles.viewItemImageContainer}
                active={routineParentId === recipeRoutine.routine.parent.id}
                onClick={() => onClick(recipeRoutine)}
                data-test="recipe-views-card"
                data-testid={`recipe-views-card-${recipeRoutine.robot_id}`}
                data-test-attribute={routineParentId === recipeRoutine.routine.parent.id ? 'active' : 'inactive'}
              >
                <PrismOverflowTooltip
                  content={recipeRoutine.routine.parent.name}
                  tooltipPlacement="bottomLeft"
                  className={Styles.viewItemTitle}
                />
              </PrismCard>
            </li>
          ))}
        </ul>
        <IconButton
          className={Styles.addMoreButton}
          icon={<PrismAddIcon />}
          data-testid="add-view-button"
          type="ghost"
          onClick={() => setAddViewModal(true)}
        />
      </div>
      <Divider type="vertical" className={Styles.viewDivider} />
      {addViewModal && (
        <AddViewModal onClose={() => setAddViewModal(false)} onOk={() => setAddViewModal(false)} recipe={recipe} />
      )}
    </section>
  )
}
export default RecipeViews

export const sortViewsByOldestFirstThenByName = (a: RecipeRoutine, b: RecipeRoutine) => {
  const sortByOldest = sortByOldestFirst(a, b)
  if (sortByOldest !== 0) return sortByOldest
  // secondary sort by name if records were created at the same time
  return sortByName(a.routine.parent, b.routine.parent)
}
