import React, { useEffect, useRef, useState } from 'react'

import { TooltipPlacement } from 'antd/lib/tooltip'

import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useTextIsOverflowing } from 'hooks'

import Styles from './PrismOverflowTooltip.module.scss'

interface PrismOverflowTooltipProps {
  content: React.ReactNode
  tooltipOverlayClassName?: string
  tooltipCondition?: boolean
  tooltipTitle?: React.ReactNode
  tooltipPlacement?: TooltipPlacement
  className?: string
  textClassName?: string
  anchorLeftPosition?: number
  'data-testid'?: string
  canBeResized?: boolean
  allowBreakLines?: boolean
}

/**
 * Renders a div container, it is aware when the text is overflowing,
 * adds the styles to truncate the text and displays a tooltip when needed.
 *
 * @param content – The text or component holder.
 * @param tooltipTitle – Adds a custom tooltip that replaces the text tooltip
 * @param tooltipCondition – This replace the overflowing text tooltip and it's used in special cases where the tooltip is triggered by something external
 * @param tooltipPlacement – The tooltip position
 * @param className – String for additional styles, will apply to the Wrapper
 * @param tooltipOverlayClassName - String for addiotional styles, will apply to the tooltip overlay
 * @param anchorLeftPosition - when position issues happen, try passing the anchor left position
 * @param canBeResized - When this component change it's size depending on other elements or by being loaded
 * @param allowBreakLines - Allow the text break into a second line
 */
const PrismOverflowTooltip = ({
  content,
  tooltipTitle,
  tooltipCondition = false,
  tooltipPlacement,
  className = '',
  tooltipOverlayClassName = '',
  textClassName = '',
  anchorLeftPosition,
  'data-testid': dataTestId,
  canBeResized = false,
  allowBreakLines,
}: PrismOverflowTooltipProps) => {
  const textContainerRef = useRef<null | HTMLDivElement>(null)
  const textIsOverflowing = useTextIsOverflowing(textContainerRef, canBeResized)
  const [inferredTooltipPosition, setInferredTooltipPosition] = useState('right')

  useEffect(() => {
    if (tooltipPlacement) return
    // It's only added if the user doesn't specify a value for the position.
    const textBoundingBox = textContainerRef.current?.getBoundingClientRect()
    const screenMidPointWidth = window.innerWidth / 2

    if (anchorLeftPosition) {
      if (anchorLeftPosition > screenMidPointWidth) setInferredTooltipPosition('left')
    }
    if (!anchorLeftPosition && (textBoundingBox?.left || 0) > screenMidPointWidth) setInferredTooltipPosition('left')
  }, [tooltipPlacement, anchorLeftPosition])

  return (
    <PrismTooltip
      condition={textIsOverflowing || tooltipCondition}
      title={tooltipTitle || content}
      placement={tooltipPlacement || (inferredTooltipPosition as TooltipPlacement)}
      anchorClassName={`${Styles.anchorTextContainer} ${allowBreakLines ? Styles.addLineClamp : ''} ${className}`}
      overlayClassName={`${Styles.tooltipOverlay} ${
        tooltipPlacement === 'left' || tooltipPlacement === 'right' || (inferredTooltipPosition && !tooltipPlacement)
          ? Styles.addSidesPadding
          : ''
      }  ${tooltipOverlayClassName}`}
      data-testid={dataTestId}
    >
      <div
        className={`${Styles.textContainer} ${allowBreakLines ? Styles.addLineClamp : ''}  ${textClassName}`}
        ref={textContainerRef}
      >
        {content}
      </div>
    </PrismTooltip>
  )
}

export default PrismOverflowTooltip
