import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, ToolResultsData, useQuery } from 'api'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import FullScreen, { FullScreenFooter } from 'components/FullScreen/FullScreen'
import { IconButton } from 'components/IconButton/IconButton'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { prefetch } from 'components/Img/ImgFallback'
import { ImgMightBeInGlacier } from 'components/ImgMightBeInGlacier/ImgMightBeInGlacier'
import InspectionSummaryPrintModal from 'components/InspectionSummaryPrintModal/InspectionSummaryPrintModal'
import { LabelCard } from 'components/LabelCard/LabelCard'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismArrowIcon, PrismCloseIcon, PrismElementaryCube, PrismShareIcon } from 'components/prismIcons'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { defaultMessage, dismiss, error, success } from 'components/PrismMessage/PrismMessage'
import { modal, ModalBody, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import { PrismOutcome } from 'components/PrismOutcome/PrismOutcome'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { Tag } from 'components/Tag/Tag'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { Token } from 'components/Token/Token'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'
import { useQueryParams, useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import {
  AreaOfInterest,
  Item,
  ItemExpanded,
  ListResponseData,
  PictureAreaOfInterest,
  RealtimePicture,
  ToolResult,
  ToolResultEmptyOutcome,
} from 'types'
import {
  appendDataToQueryString,
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  evaluateOutcomes,
  getData,
  getImageFromRoutine,
  getNonEmptyToolResults,
  matchRole,
  sortPicturesByRobot,
} from 'utils'

import { RenameItemModal } from './RenameItemModal'
import { ShareDetailModal } from './ShareDetailModal'
import Styles from './ToolDetailModal.module.scss'
import { ToolLabeling, ToolLabelingLoading } from './ToolLabeling'
import { ToolListPanel, ToolListPanelLoading } from './ToolListPanel'

// How many items before the end of the list we will fetch the next page
const FETCH_LIST_OFFSET = 5
const FETCH_ADJACENT_OFFSET = 3

export interface PictureAreaOfInterestWithToolResult extends PictureAreaOfInterest {
  toolResult?: ToolResult
}

export interface PreviousRobotAndPicture {
  robotId?: string
  pictureIdx?: number
}

type DetailModalProps = {
  onRefresh?: (item: ItemExpanded) => any
  onRefreshToolResult?: (toolResult: ToolResult) => any
  itemsInitialRes?: ListResponseData<Item | ItemExpanded>
  toolResultsInitialRes?: ToolResultsData
  onClose?: () => void
  appendLastSelectedId?: boolean
}

const FULLSCREEN_DETAIL_MODAL_ID = 'detail-modal-fullscreen'

/**
 * Renders an Item or Tool detail modal. It is important that both of them are wrapped by
 * exactly the same <PrismModal> component since, otherwise, navigating between the item
 * and tool detail modals would unmount one and mount the other, triggering two unnecessary
 * animations.
 *
 * This component takes a redux branch name, clones it and implements internal pagination and
 * item/toolResult fetching. We don't want to manipulate data outside of this component, that's
 * why we need to clone the data into its own redux branch.
 *
 * In some cases we don't want to paginate at all, for which we can simply not send in any branch
 * names and the component will still render the data properly.
 *
 * @param itemsInitialRes - the name of the item list redux branch we want to clone items from
 * @param onRefresh - Callback for refreshing the list of items.
 * @param onRefreshToolResult - Callback for refreshing toolResults
 * @param toolResultsInitialRes - The initial tool result list response
 * current carousel
 * of results is loaded
 * @param onClose - onClose modal handler
 *
 */
export const DetailModal = ({
  itemsInitialRes,
  onRefresh,
  onRefreshToolResult,
  toolResultsInitialRes,
  onClose,
  appendLastSelectedId = true,
}: DetailModalProps) => {
  const [params] = useQueryParams<'detail_item_id' | 'detail_tool_result_id' | 'detail_picture_id' | 'insights_on'>()
  const itemsGetter = useTypedSelector(state => {
    const filteredEntries = Object.entries(state.getter).filter(([key]) => {
      // We need to filter the relevant keys so that we don't trigger rerenders for every key changed in the redux getter
      return key.startsWith('items/')
    })
    return Object.fromEntries(filteredEntries)
  }, isEqual)

  // These query params will be appended to any screen that needs to support item or tool detail
  const { detail_item_id, detail_tool_result_id, detail_picture_id, insights_on } = params

  const dispatch = useDispatch()
  const history = useHistory()

  const itemPictureAmountRef = useRef<number>(0)

  const [modalLoaded, setModalLoaded] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadingNextRef = useRef(false)
  const previousRobotPicture = useRef<PreviousRobotAndPicture>({})
  const [renameItemModalOpen, setRenameItemModalOpen] = useState(false)
  const [hoveredAoiId, setHoveredAoiId] = useState<string>()
  const [showPrintModal, setShowPrintModal] = useState(false)

  const [areasEnabled, setAreasEnabled] = useState(true)
  const [referenceEnabled, setReferenceEnabled] = useState(false)
  const [zoomEnabled, setZoomEnabled] = useState(false)
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [imgError, setImgError] = useState(false)

  // This is generally not done anywhere in the app since we usually want to persist data in redux.
  // Nevertheless, for this particular component, we don't want to persist data after we leave the
  // page. For this reason we store the data we fetch in local state, which will be destroyed once we
  // close the modal.
  const [itemsRes, setItemsRes] = useState<ListResponseData<Item | ItemExpanded>>()
  const [toolResultsRes, setToolResultsRes] = useState<ToolResultsData>()

  useEffect(() => {
    if (itemsInitialRes && !itemsRes) setItemsRes(itemsInitialRes)
    if (toolResultsInitialRes && !toolResultsRes) setToolResultsRes(toolResultsInitialRes)
  }, [toolResultsInitialRes, toolResultsRes, dispatch, itemsInitialRes, itemsRes])

  useEffect(() => {
    if (fullScreenEnabled && zoomEnabled)
      return defaultMessage({
        id: 'zoom-in-message',
        title: 'Hit Z to show controls again',
        position: 'bottom-center',
        duration: 3000,
      })
    dismiss('zoom-in-message')
  }, [fullScreenEnabled, zoomEnabled])

  // This is the item list stored on local state
  const items = itemsRes?.results
  const nextItems = itemsRes?.next

  const toolResults = toolResultsRes?.results
  const nextToolResults = toolResultsRes?.next

  // Regardless of whether we're in item or toolResult mode, we must fetch the current item data
  const fetchedItem = useQuery(
    detail_item_id ? getterKeys.items(detail_item_id) : undefined,
    detail_item_id ? () => service.getItem(detail_item_id) : undefined,
    { dedupe: true, dedupeMs: 30 * 1000 },
  ).data?.data

  // If item list is of ItemExpanded, we can show the modal content immediately.
  const listItem = items?.find(item => item.id === detail_item_id)
  let listItemExpanded: ItemExpanded | undefined = undefined
  if (listItem && 'pictures' in listItem) listItemExpanded = listItem

  const item = fetchedItem || listItemExpanded

  const toolResult = useMemo(() => {
    // The modal can be openend without an item_id, in that case, as we would not have an item to get
    // tool results from, we fallback to the initial tool results passed as prop
    const toolResultsToUse =
      item?.pictures.flatMap(pic => {
        return pic.tool_results.map(toolResult => ({ ...toolResult, picture: pic })) as ToolResultEmptyOutcome[]
      }) || toolResults
    return toolResultsToUse?.find(toolResult => toolResult.id === detail_tool_result_id)
  }, [detail_tool_result_id, item?.pictures, toolResults])

  // Prefetches the adjacent items for better ux
  useEffect(() => {
    const itemsToUse = items || toolResults?.flatMap(toolResult => toolResult.item)
    if (itemsToUse && itemsToUse.length > 1) {
      const currentItemIdx = itemsToUse.findIndex(it => it?.id === detail_item_id)
      if (currentItemIdx === -1) return

      for (let i = -FETCH_ADJACENT_OFFSET; i <= FETCH_ADJACENT_OFFSET; i++) {
        if (i === 0) continue
        const itemToPrefetch = itemsToUse[currentItemIdx + i]
        // Don't prefetch item if item list is of ItemExpanded
        if (!itemToPrefetch || 'pictures' in itemToPrefetch) continue
        // Don't prefetch item if we already have it fetched
        if (getData(itemsGetter, getterKeys.items(itemToPrefetch.id))) continue
        query(getterKeys.items(itemToPrefetch.id), () => service.getItem(itemToPrefetch.id), {
          dispatch,
          dedupe: true,
          dedupeMs: 30 * 1000,
        })
      }
    }
  }, [detail_item_id, dispatch, itemsGetter, items, toolResults])

  // We store on a ref the number of pictures on the current item, so that when we load the next item, we expect it to
  // have the same number of pictures. This makes the loading state less jumpy and a more stable UX.
  useEffect(() => {
    if (!item?.pictures) return
    itemPictureAmountRef.current = item.pictures.length
  }, [item?.pictures])

  const inspection = useQuery(
    item?.inspection ? getterKeys.inspection(item.inspection) : undefined,
    item?.inspection ? () => service.getInspection(item.inspection) : undefined,
    { noRefetch: true },
  ).data?.data

  const routines = useQuery(
    inspection ? getterKeys.inspectionRoutines(inspection.id) : undefined,
    inspection ? () => service.getInspectionRoutines(inspection.id) : undefined,
    { noRefetch: true, noRefetchMs: 10 * 60 * 1000 },
  ).data?.data.results

  const itemToolResults = useMemo(
    () =>
      getNonEmptyToolResults(
        item?.pictures
          .find(pic => pic.id === detail_picture_id)
          ?.tool_results.sort((toolA, toolB) => {
            if (!toolA.aoi || !toolB.aoi) return 0

            if (toolA.aoi?.id > toolB.aoi?.id) return 1
            if (toolB.aoi?.id > toolA.aoi?.id) return -1
            return 0
          }),
      ),
    [item?.pictures, detail_picture_id],
  )

  const pictures = useMemo(() => {
    if (!item?.pictures) return
    return sortPicturesByRobot(item.pictures)
  }, [item])

  const picture = pictures?.find(pic => pic.id === detail_picture_id)

  const inspectionRoutine = routines?.find(routine =>
    inspection?.inspection_routines.find(
      inspectionRoutine =>
        inspectionRoutine.robot_id === picture?.robot_id && routine.id === inspectionRoutine.routine_id,
    ),
  )

  // If there are no inspection routines, this item was likely created through a fake inspection for item ingest.
  // Fetch the routine this way as a fallback to unblock showing the reference image in that case.
  const fallbackRoutine = useQuery(
    picture && routines?.length === 0 ? getterKeys.routine(picture.routine_id) : undefined,
    picture ? () => service.getRoutine(picture.routine_id) : undefined,
  ).data?.data
  const routine = inspectionRoutine || fallbackRoutine

  const recipe = useQuery(
    inspection?.recipe_id ? getterKeys.recipe(inspection?.recipe_id) : undefined,
    inspection?.recipe_id ? () => service.getRecipe(inspection?.recipe_id!) : undefined,
    { noRefetch: true, noRefetchMs: 10 * 60 * 1000 },
  ).data?.data

  const elementInList = useMemo(() => {
    if (detail_tool_result_id) return toolResults?.find(tool => tool.id === detail_tool_result_id)
    if (items) return items?.find(item => item.id === detail_item_id)
  }, [detail_tool_result_id, toolResults, items, detail_item_id])

  const previousElement = useMemo(() => {
    if (!elementInList) return
    if (detail_tool_result_id) {
      const currIdx = toolResults?.findIndex(tool => tool.id === detail_tool_result_id)
      if (currIdx !== undefined && currIdx > 0) return toolResults?.[currIdx - 1]
    } else {
      if (items) {
        const currIdx = items.findIndex(item => item.id === detail_item_id)
        if (currIdx !== undefined && currIdx > 0) return items[currIdx - 1]
      }
    }
  }, [elementInList, detail_tool_result_id, toolResults, items, detail_item_id])

  const nextElement = useMemo(() => {
    if (!elementInList) return
    if (detail_tool_result_id) {
      const currIdx = toolResults?.findIndex(tool => tool.id === detail_tool_result_id)

      if (currIdx !== undefined && toolResults && currIdx !== toolResults.length - 1) return toolResults[currIdx + 1]
    } else {
      if (items) {
        const currIdx = items.findIndex(item => item.id === detail_item_id)
        if (currIdx !== undefined && currIdx !== items.length - 1) return items[currIdx + 1]
      }
    }
  }, [elementInList, detail_tool_result_id, toolResults, items, detail_item_id])

  const handleFetchNextItems = useCallback(async () => {
    if (!nextItems) return

    loadingNextRef.current = true

    const res = await service.getNextPage<ListResponseData<Item>>(nextItems)

    if (res.type === 'success') {
      setItemsRes(prevRes => {
        if (!prevRes) return res.data

        return { ...res, results: [...prevRes.results, ...res.data.results] }
      })
    }

    loadingNextRef.current = false
  }, [nextItems])

  const handleFetchNextToolResults = useCallback(async () => {
    loadingNextRef.current = true

    if (nextToolResults) {
      const res = await service.getNextPage<ToolResultsData>(nextToolResults)

      if (res.type === 'success') {
        const parsedResResults = res.data
        setToolResultsRes(prevRes => {
          if (!prevRes) return parsedResResults

          return { ...res, results: [...prevRes.results, ...parsedResResults.results] }
        })
      }
    }
    loadingNextRef.current = false
  }, [nextToolResults])

  // This effect fetches new pages of results when the user is close to the end of the page.
  // This function got moved from the click handler into an effect since we also want to fetch
  // new results if the user happens to click into an item that's near the end of the list.
  useEffect(() => {
    if (!nextElement || !detail_item_id) return

    const fn = async () => {
      if (
        !loadingNextRef.current &&
        items &&
        items.findIndex(itm => itm.id === nextElement.id) + FETCH_LIST_OFFSET > items.length - 1
      )
        await handleFetchNextItems()
    }

    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail_item_id])

  useEffect(() => {
    if (!nextElement || !detail_tool_result_id) return

    const fn = async () => {
      if (
        !loadingNextRef.current &&
        toolResults &&
        toolResults.findIndex(itm => itm.id === nextElement.id) + FETCH_LIST_OFFSET > toolResults.length - 1
      )
        await handleFetchNextToolResults()
    }

    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail_tool_result_id])

  const handleNextClick = useCallback(
    async (e?: SyntheticEvent) => {
      e?.stopPropagation()
      if (!nextElement) return

      if (!detail_tool_result_id) {
        // Item Pagination
        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: nextElement.id })
      } else {
        const castToolResult = nextElement as ToolResult
        // ToolResult Pagination
        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
          itemId: castToolResult.item?.id,
          toolResultId: nextElement.id,
        })
      }
    },
    [detail_tool_result_id, history, nextElement],
  )

  const handlePrevClick = useCallback(
    async (e?: SyntheticEvent) => {
      e?.stopPropagation()
      if (!previousElement) return

      if (!detail_tool_result_id) {
        // Item Pagination
        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: previousElement.id })
      } else {
        const castToolResult = previousElement as ToolResult
        // ToolResult Pagination
        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
          itemId: castToolResult.item?.id,
          toolResultId: previousElement.id,
        })
      }
    },

    [detail_tool_result_id, history, previousElement],
  )

  // Point to previous picture rendered so that we can drive logic of what next picture to select
  useEffect(() => {
    return () => {
      if (!picture?.robot_id || !pictures) return
      const prevPicturesSameRobot = pictures?.filter(pic => pic.robot_id === picture?.robot_id)
      previousRobotPicture.current = {
        robotId: picture.robot_id,
        pictureIdx: prevPicturesSameRobot.findIndex(pic => pic.id === detail_picture_id),
      }
    }
    // eslint-disable-next-line
  }, [picture])

  useEffect(() => {
    if (!pictures || detail_picture_id) return
    if (detail_tool_result_id) {
      // If looking at tool results, select the picture that the tool result belongs to.
      appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
        itemId: detail_item_id,
        toolResultId: detail_tool_result_id,
        pictureId: pictures.find(pic => pic.tool_results.find(tool => tool.id === detail_tool_result_id))?.id,
      })
    } else {
      // If looking at items, select the picture that most closely relates to the previous selected picture
      const previousPictureIdx =
        previousRobotPicture.current.pictureIdx !== -1 ? previousRobotPicture.current.pictureIdx || 0 : 0

      // Get pictures that have the same robot as the previous picture
      const picturesSameRobot = pictures.filter(picture => picture.robot_id === previousRobotPicture.current.robotId)

      let nextPicture: RealtimePicture | undefined
      if (picturesSameRobot.length) {
        // If we have pictures with the same robot, we choose the matching index or the last one
        nextPicture = picturesSameRobot[previousPictureIdx] || picturesSameRobot[picturesSameRobot.length - 1]
      } else {
        nextPicture = pictures[0]
      }
      appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
        itemId: detail_item_id,
        pictureId: nextPicture?.id,
      })
    }
  }, [detail_item_id, detail_picture_id, detail_tool_result_id, history, pictures, picture])

  const handleMenuItemClick = async (value: 'rename' | 'delete' | 'restore' | 'print') => {
    if (value === 'rename') setRenameItemModalOpen(true)

    if (value === 'print') setShowPrintModal(true)
    if (value === 'delete')
      modal.confirm({
        id: 'confirm-item-delete',
        'data-testid': 'detail-modal-delete-item',
        header: 'Are you sure?',
        content: 'Are you sure you want to delete this item?',
        okText: 'Delete',
        overlayClassName: Styles.optionOverlayModal,
        danger: true,
        onOk: async close => {
          if (!item) return

          const saveRes = await service.patchItem(item.id, { is_deleted: true })
          if (saveRes.type === 'success') {
            success({ title: 'Item Deleted' })
          } else error({ title: 'Error deleting item' })
          close()
          handleRefreshItem()
        },
      })

    if (value === 'restore') {
      if (!item) return

      const saveRes = await service.patchItem(item.id, { is_deleted: false })
      if (saveRes.type === 'success') {
        await query(getterKeys.items(item.id), () => service.getItem(item.id), { dispatch })
        success({ title: 'Item Restored' })
      } else error({ title: 'Error restoring item' })
    }
  }

  const handleClose = useCallback(() => {
    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(
      history,
      appendLastSelectedId ? { lastSelectedId: detail_tool_result_id || detail_item_id } : {},
    )
    onClose?.()
  }, [history, appendLastSelectedId, detail_tool_result_id, detail_item_id, onClose])

  const setInsightsEnabled = useCallback(
    (enabled: boolean) => {
      appendDataToQueryString(history, { insights_on: enabled || undefined })
    },
    [history],
  )

  const handleBackToItem = useCallback(() => {
    setReferenceEnabled(false)
    setZoomEnabled(false)
    setInsightsEnabled(false)
    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
      itemId: detail_item_id,
      pictureId: detail_picture_id,
    })
  }, [detail_item_id, detail_picture_id, history, setInsightsEnabled])

  const handleRefreshItem = async () => {
    if (detail_tool_result_id) {
      const toolResultRes = await query(
        getterKeys.toolResult(detail_tool_result_id),
        () => service.getToolResult(detail_tool_result_id),
        {
          dispatch,
        },
      )
      if (toolResultRes?.type === 'success') onRefreshToolResult?.(toolResultRes.data)
    }

    if (detail_item_id) {
      const itemRes = await query(getterKeys.items(detail_item_id), () => service.getItem(detail_item_id), {
        dispatch,
      })

      if (itemRes?.type === 'success') {
        const newItem = itemRes.data
        if (onRefresh) onRefresh(newItem)

        // If item we changed is the item that is being inspected in station detail,
        // update redux so changes are also seen in station detail
        if (inspection) {
          dispatch(
            Actions.getterUpdate({
              key: getterKeys.inspectionItemsByRobot(inspection.id),
              updater: prevRes => {
                if (!prevRes) return

                const foundEntries = Object.entries(prevRes.data).filter(([, item]) => {
                  return item.id === newItem.id
                })

                if (!foundEntries.length) return

                const updatedData = { ...prevRes.data }

                foundEntries.forEach(([robotId]) => {
                  updatedData[robotId] = newItem
                })

                return { ...prevRes, data: updatedData }
              },
            }),
          )
        }

        // Update the item in the timeline item list from the DB.
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.stationDetailTimelineItems(newItem.inspection),
            updater: prevRes => {
              if (prevRes) {
                const newRes = prevRes.data.results.map(item => (item.id === newItem.id ? newItem : item))
                return { ...prevRes, data: { ...prevRes.data, results: newRes } }
              }
            },
          }),
        )

        // Update the item in the timeline item list from the Websocket.
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionRecentItems(newItem.inspection),
            updater: prevRes => {
              if (prevRes) {
                const newRes = prevRes.data.results.map(item => (item.id === newItem.id ? newItem : item))
                return { ...prevRes, data: { ...prevRes.data, results: newRes } }
              }
            },
          }),
        )

        // We update the toolResult with the item data from above to reflect the new labeled result and outcomes
        // but we preserve the previous Tool, because ItemExpanded endpoint does not return a complete representation of it.
        const allItemToolResults = getNonEmptyToolResults(newItem.pictures.flatMap(picture => picture.tool_results))
        const updatedToolResult = allItemToolResults.find(tResult => tResult.id === toolResult?.id)
        if (toolResult && updatedToolResult) {
          dispatch(
            Actions.getterUpdate({
              key: getterKeys.detailToolResult(updatedToolResult.id),
              updater: prevRes => {
                return {
                  ...prevRes,
                  data: { ...prevRes?.data, ...updatedToolResult, tool: toolResult.tool },
                }
              },
            }),
          )

          if (updatedToolResult.aoi) {
            // Update the outcome of the tools tab toolResult and recent toolResults array.
            dispatch(
              Actions.getterUpdate({
                key: getterKeys.stationDetailToolsLatestToolResults(updatedToolResult.aoi.id),
                updater: prevRes => {
                  if (prevRes) {
                    const newRes = prevRes.data.results.map(toolResult =>
                      toolResult.id === updatedToolResult.id
                        ? {
                            ...toolResult,
                            calculated_outcome: updatedToolResult.calculated_outcome,
                            active_user_label_set: updatedToolResult.active_user_label_set,
                          }
                        : toolResult,
                    )
                    return { ...prevRes, data: { ...prevRes.data, results: newRes } }
                  }
                },
              }),
            )

            dispatch(
              Actions.getterUpdate({
                key: getterKeys.inspectionRecentToolResults(newItem.inspection),
                updater: prevRes => {
                  if (prevRes) {
                    const newRes = prevRes.data.results.map(toolResult =>
                      toolResult.id === updatedToolResult.id
                        ? {
                            ...toolResult,
                            calculated_outcome: updatedToolResult.calculated_outcome,
                            active_user_label_set: updatedToolResult.active_user_label_set,
                          }
                        : toolResult,
                    )
                    return { ...prevRes, data: { ...prevRes.data, results: newRes } }
                  }
                },
              }),
            )
          }
        }
      }
    }
  }

  const goldenImage = getImageFromRoutine(routine)

  useEffect(() => {
    if (!item || !goldenImage) return
    prefetch(goldenImage)
  }, [goldenImage, item])

  const imageToUse = useMemo(() => {
    if (referenceEnabled) return goldenImage
    if (item) return picture?.image
    if (toolResult?.picture) return toolResult.picture.image
  }, [goldenImage, item, picture?.image, referenceEnabled, toolResult])

  const noImageStored = picture && !picture.image

  useEffect(() => {
    if (renameItemModalOpen) return

    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (fullScreenEnabled) return
        handleClose()
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [
    areasEnabled,
    detail_tool_result_id,
    fullScreenEnabled,
    handleClose,
    imageToUse,
    noImageStored,
    referenceEnabled,
    renameItemModalOpen,
    zoomEnabled,
    toolResult?.insight_image,
  ])

  const renderImageComponent = () => {
    // TODO: cleanup this function, very ugly and repeated conditionals
    if (!modalLoaded) return null
    if (detail_tool_result_id) {
      if (!zoomEnabled && toolResult && toolResult?.aoi && imageToUse)
        return (
          <ImageCloseUp
            data-testid="image-detail-modal"
            src={imageToUse}
            region={toolResult.aoi}
            setImgErr={setImgError}
            overlaySrc={
              insights_on
                ? // TODO: if aois have multiple toolResults, refactor this and pass list of src to overlay
                  toolResult.insight_image
                : ''
            }
            displayElementaryCubeText
            maskingRectangleFill="#1d1d1d"
            loaderType="bars"
          />
        )
      if (zoomEnabled && toolResult?.aoi && imageToUse)
        return (
          <ZoomableImage
            data-testid="zoomed-image-detail-modal"
            key={toolResult.aoi.id}
            imageBox={toolResult.aoi}
            containerClassName={Styles.imageWrapper}
            src={imageToUse}
            maskingRectangleFill="#1d1d1d"
            setImgErr={setImgError}
            overlaySrc={
              insights_on
                ? // TODO: if aois have multiple toolResults, refactor this and pass list of src to overlay
                  toolResult.insight_image
                : ''
            }
          />
        )
    } else {
      if (!zoomEnabled && imageToUse)
        return (
          <ImageWithBoxes
            displayCubeText
            data-testid="image-detail-modal"
            setImgErr={setImgError}
            boundingBoxes={boxes}
            className={Styles.imageWrapper}
            src={imageToUse}
            showOverlay={!!boxes?.length && !hoveredAoiId}
          />
        )
      if (zoomEnabled && imageToUse)
        return (
          <ZoomableImage
            data-testid="zoomed-image-detail-modal"
            containerClassName={Styles.imageWrapper}
            src={imageToUse}
            setImgErr={setImgError}
            maskingRectangleFill="#1d1d1d"
          />
        )
    }

    if (noImageStored && !imageToUse) {
      return (
        <div className={Styles.noStoredImageContainer}>
          <div className={Styles.noStoredImageAndDescription}>
            <PrismElementaryCube />
            <p className={Styles.noStoredImageDescription}>This image was inspected but not uploaded</p>
          </div>
        </div>
      )
    }

    return <PrismLoader />
  }

  const renderHeaderTitle = () => {
    if ((detail_item_id && !item) || (detail_tool_result_id && !toolResult)) {
      return (
        <div className={Styles.modalHeaderLoading}>
          <div className={Styles.headerIcon}>
            <PrismSkeleton />
          </div>
          <div className={Styles.headerTitleLoading}>
            <PrismSkeleton />
          </div>
        </div>
      )
    }

    if (item) {
      return (
        <>
          <PrismOutcome
            icon={item.calculated_outcome}
            variant="high"
            outcome={item.calculated_outcome}
            className={Styles.headerIcon}
            data-testid={`detail-modal-${item.calculated_outcome}-icon`}
          />

          <Breadcrumbs
            className={toolResult?.tool ? Styles.inactiveBreadcrumb : ''}
            crumbs={[
              {
                'data-testid': 'detail-modal-breadcrumb-item-serial',
                label: item.serial_number,
                onClick: detail_tool_result_id ? handleBackToItem : () => null,
              },
              ...(detail_tool_result_id
                ? [
                    {
                      'data-testid': 'detail-modal-breadcrumb-tool-result-id',
                      label: toolResult?.tool?.parent_name || '',
                      onClick: () => null,
                    },
                  ]
                : []),
            ]}
          />
        </>
      )
    }

    if (!item && toolResult) {
      return (
        <div className={Styles.emptyPictureItemHeader}>
          <Breadcrumbs
            className={toolResult?.tool ? Styles.inactiveBreadcrumb : ''}
            crumbs={[
              ...(detail_tool_result_id
                ? [
                    {
                      label: toolResult?.tool?.parent_name || '',
                      onClick: () => null,
                    },
                  ]
                : []),
            ]}
          />
          <Tag shape="rectangle" type="info" className={Styles.trainingDataTag}>
            Training Data
          </Tag>
        </div>
      )
    }
  }

  const renderHeaderButtons = () => {
    return (
      <>
        {item?.is_deleted && <Tag className={Styles.deletedTag}>Deleted</Tag>}

        <OptionMenu
          className={Styles.optionMenuContainer}
          onMenuItemClick={handleMenuItemClick}
          openWithClick
          options={[
            {
              value: 'print',
              title: 'Print Summary',
              'data-testid': 'detail-modal-print-summary-option',
            },
            { value: 'rename', title: 'Rename Item', 'data-testid': 'detail-modal-rename-item-option' },
            item?.is_deleted
              ? { value: 'restore', title: 'Restore Item', 'data-testid': 'detail-modal-restore-item-option' }
              : { value: 'delete', title: 'Delete Item', 'data-testid': 'detail-modal-delete-item-option' },
          ]}
          iconButtonType="tertiary"
          iconButtonDataTestId="detail-modal-option-menu"
        />

        <Button
          data-testid="share-button"
          onClick={() => setShowShareModal(true)}
          type="tertiary"
          size="small"
          badge={<PrismShareIcon />}
        >
          Share
        </Button>
      </>
    )
  }

  useEffect(() => {
    if (imgError) {
      setAreasEnabled(false)
      setReferenceEnabled(false)
      setZoomEnabled(false)
      setFullScreenEnabled(false)
      setShowShareModal(false)
      setInsightsEnabled(false)
    }
  }, [imgError, setInsightsEnabled])

  const insightsButtonDisabled = noImageStored || !toolResult?.insight_image

  const insightsActive = useMemo(() => {
    if (insightsButtonDisabled) return false

    return !!insights_on
  }, [insightsButtonDisabled, insights_on])

  const renderToggleButtons = (fullscreen: boolean) => {
    return (
      <>
        {detail_tool_result_id && (
          <ToggleButton
            onClick={() => {
              setInsightsEnabled(!insights_on)
              setReferenceEnabled(false)
            }}
            disabled={insightsButtonDisabled || imgError}
            title="Insights"
            hotkey="I"
            active={insightsActive}
            className={Styles.toggleButton}
            tooltip={!toolResult?.insight_image ? 'Insights are unavailable' : undefined}
            isOnTop
          />
        )}

        {!detail_tool_result_id && (
          <ToggleButton
            data-testid={`${fullscreen ? 'fullscreen-' : ''}areas-toggle-button`}
            onClick={() => {
              setAreasEnabled(!areasEnabled)
              setZoomEnabled(false)
            }}
            disabled={(noImageStored && !imageToUse) || imgError}
            title="Areas"
            hotkey="A"
            active={areasEnabled}
            className={Styles.toggleButton}
            isOnTop
          />
        )}

        <ToggleButton
          data-testid={`${fullscreen ? 'fullscreen-' : ''}reference-toggle-button`}
          onClick={() => {
            setReferenceEnabled(!referenceEnabled)
            setInsightsEnabled(false)
          }}
          title="Reference"
          hotkey="R"
          active={referenceEnabled}
          className={Styles.toggleButton}
          isOnTop
          disabled={!item || !goldenImage || imgError}
        />

        <ToggleButton
          data-testid={`${fullscreen ? 'fullscreen-' : ''}zoom-toggle-button`}
          onClick={() => {
            setZoomEnabled(!zoomEnabled)
            setAreasEnabled(false)
          }}
          disabled={(noImageStored && !imageToUse) || imgError}
          title="Zoom"
          hotkey="Z"
          active={zoomEnabled}
          className={Styles.toggleButton}
          isOnTop
          allowedModalIds={[FULLSCREEN_DETAIL_MODAL_ID]}
        />

        <ToggleButton
          data-testid={`${fullscreen ? 'fullscreen-' : ''}fullscreen-toggle-button`}
          onClick={() => {
            setFullScreenEnabled(!fullScreenEnabled)
          }}
          disabled={(noImageStored && !imageToUse) || imgError}
          title="Fullscreen"
          hotkey="F"
          active={fullScreenEnabled}
          className={Styles.toggleButton}
          isOnTop
        />
      </>
    )
  }

  const boxes = useMemo(
    () =>
      itemToolResults
        ?.flatMap(toolResult => toolResult.aoi)
        ?.filter(aoi => aoi && (areasEnabled ? true : aoi.id === hoveredAoiId))
        .map(aoi => {
          aoi = aoi as AreaOfInterest // We know this is a valid AOI because we filtered it above
          const toolResult = itemToolResults?.find(toolResult => toolResult.aoi?.id === aoi?.id)
          return {
            ...aoi,
            outcomeOrStatus: toolResult ? evaluateOutcomes([toolResult.calculated_outcome]) : undefined,
            applyMask: hoveredAoiId === aoi.id,
          }
        }),
    [areasEnabled, hoveredAoiId, itemToolResults],
  )

  const handleToolClick = (id?: string) => {
    if (!id) return

    setReferenceEnabled(false)
    setZoomEnabled(false)

    appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
      itemId: detail_item_id,
      toolResultId: id,
      pictureId: detail_picture_id,
    })
  }

  return (
    <>
      <PrismModal
        data-testid="detail-modal"
        onModalLoad={() => setModalLoaded(true)}
        size="extraLarge"
        overlayClassName={Styles.modalOverlayWrapper}
        className={Styles.modalWrapper}
        onClose={handleClose}
        id="detail-modal"
      >
        <ModalHeader onClose={handleClose}>
          <div className={Styles.headerTitle}>{renderHeaderTitle()}</div>

          <div className={Styles.headerActions}>{renderHeaderButtons()}</div>
        </ModalHeader>

        <div className={Styles.modalBodyContainer}>
          {!detail_tool_result_id && itemPictureAmountRef.current > 1 && (
            <section className={Styles.labelCardSection}>
              <div className={Styles.labelCardWrapper}>
                <div className={Styles.labelCardContainer}>
                  {(!pictures || pictures.length <= 0) &&
                    Array.apply(null, Array(itemPictureAmountRef.current)).map((_, i) => (
                      <LabelCard
                        key={i}
                        className={Styles.labelCardItem}
                        figureClassName={Styles.imageContainer}
                        image={<PrismSkeleton className={Styles.emptyImageLoader} />}
                        label={
                          <Token className={Styles.labelCardDetails}>
                            <PrismSkeleton size="small" />
                          </Token>
                        }
                      />
                    ))}

                  {pictures &&
                    pictures.length > 1 &&
                    pictures.map(pic => {
                      return (
                        <LabelCard
                          data-test="detail-modal-multicam-image"
                          key={pic.id}
                          className={Styles.labelCardItem}
                          figureClassName={Styles.imageContainer}
                          labelInModal
                          onClick={() => appendDataToQueryString(history, { detail_picture_id: pic.id })}
                          active={pic.id === detail_picture_id}
                          image={<ImgMightBeInGlacier itemOrPicture={pic} preferThumbnail loaderType="skeleton" />}
                          label={
                            <Token className={Styles.labelCardDetails}>
                              <div className={Styles.predictionDetails}>
                                <PrismOutcome
                                  icon={pic.calculated_outcome}
                                  outcome={pic.calculated_outcome}
                                  className={Styles.predictionIcon}
                                  size="small"
                                  variant="iconColor"
                                />
                                <PrismOverflowTooltip
                                  content={pic.calculated_outcome}
                                  className={Styles.predictionResult}
                                />
                              </div>
                            </Token>
                          }
                        />
                      )
                    })}
                </div>
              </div>
              <Divider type="vertical" className={Styles.labelCardDivider} />
            </section>
          )}
          <section className={Styles.modalMainBody}>
            <ModalBody className={Styles.modalBody}>
              <div className={Styles.toolDetailContainer}>
                <section className={Styles.leftWrapper}>
                  <div className={Styles.videoWrapper} id={picture?.id}>
                    {renderImageComponent()}
                  </div>
                  <div className={Styles.toggleButtonsWrapper}>{renderToggleButtons(false)}</div>
                </section>

                <Divider type="vertical" className={Styles.detailModalDivider} />

                <div className={Styles.rightWrapper}>
                  {!detail_tool_result_id && !item && <ToolListPanelLoading />}

                  {!detail_tool_result_id && item && (
                    <ToolListPanel
                      modalLoaded={modalLoaded}
                      toolResults={itemToolResults}
                      picture={picture}
                      recipe={recipe}
                      inspection={inspection}
                      onClick={handleToolClick}
                      setHoveredAoiId={setHoveredAoiId}
                      item={item}
                      toolListWrapperClassName={Styles.toolListWrapper}
                    />
                  )}

                  {detail_tool_result_id && !toolResult && <ToolLabelingLoading />}

                  {detail_tool_result_id && toolResult && !fullScreenEnabled && (
                    <ToolLabeling
                      itemId={item?.id}
                      toolResult={toolResult}
                      canEditOutcome={matchRole('inspector')}
                      onRefresh={handleRefreshItem}
                      onBackItem={handleBackToItem}
                    />
                  )}
                </div>
              </div>
            </ModalBody>

            {nextElement && (
              <IconButton
                isOnTop
                type="secondary"
                icon={<PrismArrowIcon direction="right" />}
                onClick={handleNextClick}
                className={`${Styles.arrowContainer} ${Styles.arrowRight}`}
                hotkey="ArrowRight"
              />
            )}

            {previousElement && (
              <IconButton
                isOnTop
                type="secondary"
                icon={<PrismArrowIcon direction="left" />}
                onClick={handlePrevClick}
                className={`${Styles.arrowContainer} ${Styles.arrowLeft}`}
                hotkey="ArrowLeft"
              />
            )}
          </section>
        </div>
      </PrismModal>

      {item && renameItemModalOpen && (
        <RenameItemModal
          onCancel={() => {
            handleRefreshItem()
            setRenameItemModalOpen(false)
          }}
          originalName={item.serial_number}
          itemId={item.id}
        />
      )}

      {fullScreenEnabled && (
        <FullScreen onClose={() => setFullScreenEnabled(false)} id={FULLSCREEN_DETAIL_MODAL_ID}>
          <figure data-testid="fullscreen-image" className={Styles.fullScreenVideoContainer}>
            {renderImageComponent()}
          </figure>
          <div className={Styles.fullScreenHeader}>
            <div className={`${Styles.fullScreenHeaderTitle} ${zoomEnabled ? Styles.hideWhileZoom : ''}`}>
              {renderHeaderTitle()}
            </div>

            <div className={Styles.fullScreenButtonsContainer}>
              <span className={`${Styles.extraButtons} ${zoomEnabled ? Styles.hideWhileZoom : ''}`}>
                {renderHeaderButtons()}
              </span>

              <IconButton
                className={Styles.fullScreenCloseButton}
                icon={<PrismCloseIcon />}
                size="small"
                type="tertiary"
                onClick={() => setFullScreenEnabled(false)}
              />
            </div>
          </div>

          <FullScreenFooter
            className={`${fullScreenEnabled && !zoomEnabled ? Styles.fullscreenBottomFade : ''} ${
              fullScreenEnabled && zoomEnabled ? Styles.hideButtonsContainer : ''
            }`}
          >
            {!(fullScreenEnabled && zoomEnabled) && renderToggleButtons(true)}
          </FullScreenFooter>

          {nextElement && (
            <IconButton
              isOnTop
              type="secondary"
              icon={<PrismArrowIcon direction="right" />}
              onClick={handleNextClick}
              className={`${Styles.arrowContainer} ${Styles.fullScreenArrow} ${Styles.arrowRight}`}
              hotkey="ArrowRight"
            />
          )}

          {previousElement && (
            <IconButton
              isOnTop
              type="secondary"
              icon={<PrismArrowIcon direction="left" />}
              onClick={handlePrevClick}
              className={`${Styles.arrowContainer} ${Styles.fullScreenArrow} ${Styles.arrowLeft}`}
              hotkey="ArrowLeft"
            />
          )}
        </FullScreen>
      )}

      {showPrintModal && item && <InspectionSummaryPrintModal item={item} onClose={() => setShowPrintModal(false)} />}

      {showShareModal && item && (
        <ShareDetailModal
          item={item}
          inspection={inspection}
          toolResult={toolResult}
          recipe={recipe}
          renderedPictureId={picture?.id}
          setShowShareModal={setShowShareModal}
          modalOverlayClassName={Styles.shareModalOverlay}
        />
      )}
    </>
  )
}

export const MemoDetailModal = React.memo(DetailModal)
