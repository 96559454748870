import React from 'react'

import { ComponentsData, getterKeys, service } from 'api'
import { SearchableSelect, SearchableSelectProps } from 'components/SearchableSelect/SearchableSelect'

type Props = Omit<
  SearchableSelectProps<'components/*', 'name', ComponentsData>,
  'placeholder' | 'getterKey' | 'mode' | 'fetcher' | 'missingOptionFetcher' | 'formatter'
> & {
  newProductNameState: string | undefined
  setNewProductNameState: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ProductSearch = ({ newProductNameState, setNewProductNameState, ...rest }: Props) => {
  return (
    <SearchableSelect
      {...rest}
      mode={undefined}
      placeholder="Select or create a product"
      getterKey={getterKeys.components('select-options')}
      fetcher={searchValue => service.getComponents({ name: searchValue, is_deleted: false })}
      missingOptionFetcher={id => service.getComponent(id)}
      formatter={component => component.name}
      createNewObjectOption={{
        key: 'name',
        createNewOptionVal: newProductNameState,
        setCreateNewOptionVal: setNewProductNameState,
      }}
    />
  )
}

export default ProductSearch

export const createProductOnTheFly = async <T extends object | void>({
  productSelectedId,
  newProductNameState,
  onSuccess,
  onError,
}: {
  productSelectedId: string
  newProductNameState: string | undefined
  onSuccess: (productId: string) => Promise<T>
  onError: () => Promise<T>
}): Promise<T> => {
  let productId = productSelectedId

  if (newProductNameState) {
    const res = await service.createComponent({ name: newProductNameState })
    if (res.type !== 'success') return await onError()

    productId = res.data.id
  }
  return await onSuccess(productId)
}
