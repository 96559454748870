import React from 'react'

import {
  PrismCriticalIcon,
  PrismDiscardIcon,
  PrismFailIcon,
  PrismGoodIcon,
  PrismMinorIcon,
  PrismNeutralIcon,
  PrismPassIcon,
  PrismTestSetIcon,
  PrismUnknownIcon,
} from 'components/prismIcons'

import { LabelButtonSeverity } from './PrismResultButton'
import Styles from './PrismResultButton.module.scss'

interface resultIconProps {
  severity: LabelButtonSeverity
  viewboxSize?: 'xSmall' | 'small' | 'medium'
  iconClassName?: string
}

const PrismResultIcon = ({ severity, viewboxSize = 'medium', iconClassName }: resultIconProps) => {
  return (
    <div className={`${Styles.prismResultSvg}  `}>
      {severity === 'good' && (
        <PrismGoodIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'minor' && (
        <PrismMinorIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'critical' && (
        <PrismCriticalIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'neutral' && (
        <PrismNeutralIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'pass' && (
        <PrismPassIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'fail' && (
        <PrismFailIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
      {severity === 'unknown' && (
        <PrismUnknownIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}

      {severity === 'discard' && (
        <PrismDiscardIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}

      {severity === 'test_set' && (
        <PrismTestSetIcon className={`${iconClassName ?? ''} ${Styles.svgColor}`} viewboxSize={viewboxSize} />
      )}
    </div>
  )
}

export default PrismResultIcon
