import React, { useState } from 'react'

import { Button } from 'components/Button/Button'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { NonProvisionedOrg, Organization } from 'types'
import { sortByName } from 'utils'

import FlourishOrgSetup from '../Flourish/FlourishOrgSetup'
import Styles from './LoginOrganizationPicker.module.scss'

const colors = [
  Styles.azure64,
  Styles.seafoam64,
  Styles.magicMantis64,
  Styles.sunglow64,
  Styles.fireOpal64,
  Styles.smokeyBlack60,
]

/**
 * Renders the Org Picker main layout
 *
 */
const LoginOrganizationPicker = ({
  organizations,
  onOrganizationClick,
}: {
  organizations: (Organization | NonProvisionedOrg)[]
  onOrganizationClick: (organizationId: string) => void
}) => {
  const [orgToProvision, setOrgToProvision] = useState<NonProvisionedOrg>()

  return (
    <>
      {orgToProvision && (
        <FlourishOrgSetup
          tenantId={orgToProvision.tenant_id}
          serviceId={orgToProvision.service_id}
          onOrganizationSetup={onOrganizationClick}
        />
      )}

      {!orgToProvision && (
        <div className={Styles.container}>
          <div className={Styles.loginOrgWrapper}>
            <div className={Styles.loginOrgContainer}>
              <h1 className={Styles.loginOrgTitle}>Which organization would you like to use?</h1>
              <ul className={Styles.loginOrgList}>
                {organizations
                  .map(org => ({ ...org, name: 'name' in org ? org.name : org.rw_tenant_name }))
                  .sort(sortByName)
                  .map((organization, idx) => {
                    const orgName = organization.name

                    const isNonProvisionedOrg = 'service_id' in organization

                    const disableOption = isNonProvisionedOrg && !organization.can_provision

                    return (
                      <li
                        className={Styles.orgItem}
                        key={isNonProvisionedOrg ? organization.service_id : organization.id}
                        data-test-attribute="org-picker-option"
                        data-testid={`org-picker-${orgName}`}
                      >
                        <PrismTooltip
                          title="This organization needs to be provisioned. Please contact your administrator"
                          trigger={disableOption ? ['hover'] : []}
                        >
                          <Button
                            type="default"
                            className={Styles.orgItemButton}
                            badgeClassName={`${Styles.avatar} ${colors[idx % colors.length]}`}
                            badge={orgName[0]}
                            disabled={disableOption}
                            onClick={() => {
                              if (isNonProvisionedOrg) {
                                return setOrgToProvision(organization)
                              }

                              onOrganizationClick(organization.id)
                            }}
                          >
                            <PrismOverflowTooltip content={orgName} className={Styles.orgName} />
                          </Button>
                        </PrismTooltip>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LoginOrganizationPicker
