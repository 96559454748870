import React from 'react'

import { capitalize } from 'lodash'

import { SendToApiResponse } from 'api'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalProps } from 'components/PrismModal/PrismModal'
import { Component, RecipeExpanded, Site, Station, SubSite } from 'types'

import Styles from './EntityModal.module.scss'

type responseMapper = {
  site: Site
  line: SubSite
  station: Station
  product: Component
  recipe: RecipeExpanded
}

export type EntityModalProps<T, U> = {
  isEditMode?: boolean
  onClose: () => void
  onSubmit: () => Promise<SendToApiResponse<U> | undefined | void>
  entity: T
  successMessage?: string
  successDataTestId?: string
}

/**
 * Renders a general modal with largeSimpleForm size
 *
 * @param children - the modal body children. Adds 24px gap between each element.
 */
const EntityModal = <T extends keyof responseMapper, U extends responseMapper[T]>({
  children,
  isEditMode,
  entity,
  onSubmit,
  onClose,
  successMessage,
  ...rest
}: ModalProps & EntityModalProps<T, U>) => {
  async function handleSubmit() {
    const successful = await onSubmit()

    if (successful?.type === 'success') {
      let entityName: string | undefined = undefined
      if (!('name' in successful.data)) {
        entityName = successful.data.parent.name
      } else entityName = successful.data.name

      return success({
        title: successMessage || `${capitalize(entity)} ${isEditMode ? 'updated' : 'added'}`,
        'data-testid': `${entity}-${entityName}-${isEditMode ? 'updated' : 'added'}-notification`,
      })
    }
    return error({ title: 'An error occurred, please try again later.' })
  }

  return (
    <Modal
      header={`${isEditMode ? 'edit' : 'add'} ${entity}`}
      size="largeSimpleForm"
      className={Styles.entityModal}
      headerClassName={Styles.entityModalHeader}
      modalBodyClassName={Styles.entityModalBody}
      onClose={onClose}
      onOk={handleSubmit}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default EntityModal

export const EntityModalCaption = ({ title }: { title: string }) => {
  return <p className={Styles.entityModalCaption}>{title}</p>
}
