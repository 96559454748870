import React from 'react'

import { Control, Controller } from 'react-hook-form'

import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import {
  THRESHOLD_MAX,
  THRESHOLD_MIN,
  THRESHOLD_STEP,
  TrainingReport,
} from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import { RecipeRoutineExpanded, Tool, ToolThreshold, UserFacingThreshold } from 'types'
import { getThresholdFromUserFacingValues, getUserFacingRecommendedValueFromTool, isThresholdFromGARTool } from 'utils'

import Styles from './ToolStyles.module.scss'

type Props = {
  tool: Tool
  control: Control<{
    threshold: ToolThreshold
  }>
  getValues: (formKey: 'threshold') => ToolThreshold
  className?: string
  showThresholdModal: boolean
  setShowThresholdModal: (isOpen: boolean) => void
  view: RecipeRoutineExpanded
}

const ThresholdCriteria = ({
  tool,
  control,
  className,
  getValues,
  showThresholdModal,
  setShowThresholdModal,
  view,
}: Props) => {
  const recommendedValue = getUserFacingRecommendedValueFromTool(tool)
  const recommendedValueText = recommendedValue ? `Recommended value is ${recommendedValue}.` : undefined

  const passText =
    tool.specification_name === 'match-classifier' ? 'This tool will Pass' : 'This tool will predict Good'

  return (
    <>
      <Controller
        control={control}
        name="threshold"
        render={({ onChange, value }: { onChange: (tool: ToolThreshold) => any; value: ToolThreshold }) => {
          const updateAndChange = (threshold: UserFacingThreshold) => {
            onChange(getThresholdFromUserFacingValues(threshold, tool.specification_name) as ToolThreshold)
          }
          return (
            <div className={className ?? ''}>
              <div data-testid="anomaly-tool-settings-threshold">
                <PrismSlider
                  label="THRESHOLD"
                  value={value.userFacingThreshold}
                  sliderColor="red"
                  disabled={tool.state !== 'successful'}
                  min={THRESHOLD_MIN}
                  max={THRESHOLD_MAX}
                  step={THRESHOLD_STEP}
                  onChange={(v: number) => updateAndChange({ userFacingThreshold: v })}
                />
              </div>

              {tool.state === 'successful' && (
                <div className={Styles.description}>
                  {passText} if its score exceeds the threshold. {recommendedValueText}
                  <br />
                  <br />
                  You can{' '}
                  <span onClick={() => setShowThresholdModal(true)} className={Styles.highlighted}>
                    test changes
                  </span>{' '}
                  to threshold on your training images.
                </div>
              )}

              {tool.state !== 'successful' && (
                <div className={Styles.description}>
                  You can set the threshold for this tool after you have trained it.
                </div>
              )}

              {showThresholdModal && (
                <TrainingReport
                  onClose={() => setShowThresholdModal(false)}
                  onCancelThreshold={() => setShowThresholdModal(false)}
                  toolId={tool.id}
                  modifiedThreshold={getValues('threshold')}
                  thresholdButtonText="Apply"
                  forceTitle="Threshold Test Ground"
                  forceThreshold
                  onSaveThreshold={threshold => {
                    if (isThresholdFromGARTool(threshold)) return
                    onChange(threshold)
                    setShowThresholdModal(false)
                  }}
                  viewInInspection={view}
                  toolWithOverridesContext={tool}
                />
              )}
            </div>
          )
        }}
      />
    </>
  )
}

export default ThresholdCriteria
