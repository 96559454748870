import React, { useEffect, useState } from 'react'

import { Radio } from 'antd'
import { useHistory } from 'react-router'

import { prefetch } from 'components/Img/ImgFallback'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismInfoIcon } from 'components/prismIcons'
import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import ProtectedInputNumber from 'components/ProtectedInputNumber/ProtectedInputNumber'
import { AlignmentToolInferenceArgs, RecipeExpanded, RoutineWithAois } from 'types'
import { protectedOnChange } from 'utils'

import Styles from './AlignmentTool.module.scss'

export interface Props {
  inferenceArgs: AlignmentToolInferenceArgs
  onChangeInferenceArgs: (args: AlignmentToolInferenceArgs) => any
  imageSrc: string | undefined
  readOnly: boolean
  routine: RoutineWithAois
  recipe: RecipeExpanded
}

/**
 * Renders the settings form for filling all the data related to inference_args
 * for an alignment tool. This component is almost an exact copy of
 * AnchorSettings.
 *
 * @param inferenceArgs - Tool's current inference args
 * @param onChangeInferenceArgs - Called when inference args are updated
 * @param imageSrc - Needed to fetch the image and set its width to the maxValue
 *     for max_shift
 * @param readOnly - If true can't edit any field
 */
const SettingsBody = ({ inferenceArgs, onChangeInferenceArgs, imageSrc, readOnly, routine, recipe }: Props) => {
  const [maxShift, setMaxShift] = useState(400)
  const history = useHistory()

  useEffect(() => {
    if (imageSrc) {
      const image = prefetch(imageSrc)
      image.onload = () => setMaxShift(image.width)
    }
  }, [imageSrc])

  if (!inferenceArgs) return null
  const setInferenceArgs = protectedOnChange(
    (newFields: Partial<AlignmentToolInferenceArgs>) => {
      if (readOnly) return
      onChangeInferenceArgs({ ...inferenceArgs, ...newFields })
    },
    { routine, recipe, history },
  )

  const showErrorOnMethods = !inferenceArgs?.feature_based?.active && !inferenceArgs?.area_based?.active
  return (
    <>
      <div className={Styles.settingsDivision}>
        <div className={Styles.settingsTitle}>
          Transformations
          <SettingsInfoIcon tooltipTitle="Selects which kinds of transformations may be applied to an inspection image to align it with the golden image." />
        </div>

        <div className={Styles.radioWrapper} onClick={() => setInferenceArgs({ transformation: 'translation_only' })}>
          <Radio
            checked={inferenceArgs.transformation === 'translation_only'}
            className={Styles.radioContainer}
            disabled={readOnly}
          >
            <div className={Styles.settingsInputLabel}>Translation Only</div>
          </Radio>
        </div>
        <div
          className={Styles.radioWrapper}
          onClick={() => setInferenceArgs({ transformation: 'translation_rotation_scale' })}
        >
          <Radio
            checked={inferenceArgs.transformation === 'translation_rotation_scale'}
            className={Styles.radioContainer}
            disabled={readOnly}
          >
            <div className={Styles.settingsInputLabel}>Translation, Rotation, Scale</div>
          </Radio>
        </div>
        <div className={Styles.radioWrapper} onClick={() => setInferenceArgs({ transformation: 'full_affine' })}>
          <Radio
            checked={inferenceArgs.transformation === 'full_affine'}
            className={Styles.radioContainer}
            disabled={readOnly}
          >
            <div className={Styles.settingsInputLabel}>Full Affine</div>
          </Radio>
        </div>
        <div className={Styles.radioWrapper} onClick={() => setInferenceArgs({ transformation: 'homography' })}>
          <Radio
            checked={inferenceArgs.transformation === 'homography'}
            className={Styles.radioContainer}
            disabled={readOnly}
          >
            <div className={Styles.settingsInputLabel}>Homography</div>
          </Radio>
        </div>
      </div>

      <div className={Styles.settingsDivision}>
        <PrismSlider
          min={0}
          max={maxShift}
          label="Max Shift"
          value={inferenceArgs.max_shift}
          actions={
            <SettingsInfoIcon tooltipTitle="Selects the maximum amount by which an inspection image may be translated to align it with the golden image" />
          }
          inputBox={
            <ProtectedInputNumber
              min={0}
              max={maxShift}
              size="small"
              value={inferenceArgs.max_shift}
              formatter={value => `${value}px`}
              parser={value => {
                if (!value) return ''
                value = value.replace('p', '')
                value = value.replace('x', '')
                return value.replace('px', '')
              }}
              onChange={val => setInferenceArgs({ max_shift: typeof val === 'string' ? parseInt(val) : val || 0 })}
              disabled={readOnly}
              routine={routine}
              recipe={recipe}
            />
          }
          onChange={val => setInferenceArgs({ max_shift: typeof val === 'string' ? parseInt(val) : val || 0 })}
          disabled={readOnly}
        />
      </div>

      {inferenceArgs.transformation !== 'translation_only' && (
        <div className={Styles.settingsDivision}>
          <PrismSlider
            label="Max Rotation"
            min={1}
            max={180}
            onChange={(val: number) => setInferenceArgs({ max_rotation: val })}
            value={inferenceArgs.max_rotation}
            disabled={readOnly}
            actions={
              <SettingsInfoIcon tooltipTitle="Selects the maximum amount by which an inspection image may be rotated to align it with the golden image" />
            }
            inputBox={
              <ProtectedInputNumber
                min={1}
                max={180}
                size="small"
                value={inferenceArgs.max_rotation}
                formatter={value => `${value}°`}
                parser={value => {
                  if (!value) return ''
                  return value.replace('°', '')
                }}
                onChange={val => setInferenceArgs({ max_rotation: typeof val === 'string' ? parseInt(val) : val || 1 })}
                disabled={readOnly}
                routine={routine}
                recipe={recipe}
              />
            }
          />
        </div>
      )}

      {inferenceArgs.transformation !== 'translation_only' && (
        <div className={Styles.settingsDivision}>
          <PrismSlider
            label="Max Scale"
            min={1}
            max={1.5}
            step={0.05}
            onChange={(val: number) => setInferenceArgs({ max_scale: val })}
            value={inferenceArgs.max_scale}
            disabled={readOnly}
            actions={
              <SettingsInfoIcon tooltipTitle="Selects the maximum amount by which an inspection image may be scaled to align it with the golden image (Both enlargements and reductions of the image are allowed; the minimum scale factor allowed in a reduction is the inverse of this value)" />
            }
            inputBox={
              <ProtectedInputNumber
                min={1}
                max={1.5}
                step={0.05}
                size="small"
                value={inferenceArgs.max_scale}
                formatter={value => `${value}x`}
                parser={value => {
                  if (!value) return ''
                  return value.replace('x', '')
                }}
                onChange={val => setInferenceArgs({ max_scale: typeof val === 'string' ? parseInt(val) : val || 1 })}
                disabled={readOnly}
                routine={routine}
                recipe={recipe}
              />
            }
          />
        </div>
      )}

      <div className={Styles.settingsDivision}>
        <div className={Styles.settingsTitle}>
          Method
          <SettingsInfoIcon tooltipTitle="Selects whether to align the image using a feature based or an area-based alignment algorithm. (May be used in combination, at least one is needed)." />
        </div>

        <div
          className={Styles.settingsInputField}
          onClick={() =>
            setInferenceArgs({
              feature_based: { ...inferenceArgs.feature_based, active: !inferenceArgs.feature_based?.active },
            })
          }
        >
          <PrismCheckbox
            checked={inferenceArgs.feature_based?.active}
            disabled={readOnly}
            labelClassName={Styles.checkboxLabelWrapper}
            label={
              <>
                <div className={Styles.settingsCheckboxLabel}>Feature Based</div>

                <SettingsInfoIcon tooltipTitle="Selects which feature detector to use in feature based alignment. Reprojection Threshold: Selects the threshold for considering a point to be an “inlier” when estimating an image transformation with RANSAC.  Smaller values correspond with stricter criteria for choosing a transformation" />
              </>
            }
          />
        </div>

        {inferenceArgs.feature_based?.active && (
          <>
            <div className={Styles.settingsSubContainer}>
              <div className={Styles.subContainerTitle}>Detector</div>
              <div
                className={Styles.radioWrapper}
                onClick={() => setInferenceArgs({ feature_based: { ...inferenceArgs.feature_based, detector: 'ORB' } })}
              >
                <Radio
                  checked={inferenceArgs.feature_based?.detector === 'ORB'}
                  className={Styles.radioContainer}
                  disabled={readOnly}
                >
                  <div className={Styles.settingsInputLabel}>ORB</div>
                </Radio>
              </div>
              <div
                className={Styles.radioWrapper}
                onClick={() =>
                  setInferenceArgs({ feature_based: { ...inferenceArgs.feature_based, detector: 'KAZE' } })
                }
              >
                <Radio
                  checked={inferenceArgs.feature_based?.detector === 'KAZE'}
                  className={Styles.radioContainer}
                  disabled={readOnly}
                >
                  <div className={Styles.settingsInputLabel}>KAZE</div>
                </Radio>
              </div>
              <div
                className={Styles.radioWrapper}
                onClick={() =>
                  setInferenceArgs({ feature_based: { ...inferenceArgs.feature_based, detector: 'AKAZE' } })
                }
              >
                <Radio
                  checked={inferenceArgs.feature_based?.detector === 'AKAZE'}
                  className={Styles.radioContainer}
                  disabled={readOnly}
                >
                  <div className={Styles.settingsInputLabel}>AKAZE</div>
                </Radio>
              </div>
              <div
                className={Styles.radioWrapper}
                onClick={() =>
                  setInferenceArgs({ feature_based: { ...inferenceArgs.feature_based, detector: 'MSER' } })
                }
              >
                <Radio
                  checked={inferenceArgs.feature_based?.detector === 'MSER'}
                  className={Styles.radioContainer}
                  disabled={readOnly}
                >
                  <div className={Styles.settingsInputLabel}>MSER</div>
                </Radio>
              </div>

              <PrismSlider
                label="Reprojection Threshold"
                min={1}
                max={20}
                value={inferenceArgs.feature_based?.reprojection_threshold}
                onChange={val =>
                  setInferenceArgs({
                    feature_based: {
                      ...inferenceArgs.feature_based,
                      reprojection_threshold: typeof val === 'string' ? parseInt(val) : val || 1,
                    },
                  })
                }
                disabled={readOnly}
              />
            </div>
          </>
        )}

        <div
          className={Styles.settingsInputField}
          onClick={() =>
            setInferenceArgs({
              area_based: { ...inferenceArgs.area_based, active: !inferenceArgs.area_based?.active },
            })
          }
        >
          <PrismCheckbox
            checked={inferenceArgs.area_based?.active}
            disabled={readOnly}
            label={
              <>
                <div className={Styles.settingsCheckboxLabel}>Area-Based</div>
                <SettingsInfoIcon tooltipTitle="Selects the number of levels in a multi-resolution image pyramid (applied to all images before alignment if using the area-based method).  Each level of the pyramid coarsens the image resolution by a factor of 2" />
              </>
            }
            labelClassName={Styles.checkboxLabelWrapper}
          />
        </div>

        {inferenceArgs.area_based?.active && (
          <>
            <div className={Styles.settingsSubContainer}>
              <PrismSlider
                label="Pyramid Levels"
                min={1}
                max={5}
                value={inferenceArgs.area_based?.number_of_pyramid_levels}
                onChange={val =>
                  setInferenceArgs({
                    area_based: {
                      ...inferenceArgs.area_based,
                      number_of_pyramid_levels: typeof val === 'string' ? parseInt(val) : val || 1,
                    },
                  })
                }
                disabled={readOnly}
              />

              <PrismSlider
                label="Pyramid Levels used"
                min={1}
                max={inferenceArgs.area_based?.number_of_pyramid_levels || 5}
                step={1}
                value={inferenceArgs.area_based?.number_of_pyramid_levels_used}
                onChange={val =>
                  setInferenceArgs({
                    area_based: {
                      ...inferenceArgs.area_based,
                      number_of_pyramid_levels_used: typeof val === 'string' ? parseInt(val) : val || 1,
                    },
                  })
                }
                disabled={readOnly}
              />
            </div>
          </>
        )}
        {showErrorOnMethods && <span className={Styles.errorMessage}>Please select a checkbox</span>}
      </div>
    </>
  )
}

export default SettingsBody

/**
 * Renders an info icon and adds a text to a tooltip
 * @param tooltipTitle - string to be passed to the tooltip
 */
const SettingsInfoIcon = ({ tooltipTitle }: { tooltipTitle?: string }) => {
  return (
    <PrismTooltip title={tooltipTitle} anchorClassName={Styles.infoIconPosition}>
      <PrismInfoIcon />
    </PrismTooltip>
  )
}
