import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import Styles from './PrismTab.module.scss'

type PrismTabNavItem = {
  label?: string
  path: string
  'data-testid'?: string
  partialMatch?: RegExp
  forceState?: boolean
}

interface Props {
  items: PrismTabNavItem[]
  className?: string
  disabled?: boolean
  'data-test-attribute'?: string
}

/**
 * Renders a tab menu which recieves a set of items and navigates to a given url path
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3116%3A3482
 *
 * @param items - the set of items to display in the menu
 * @param item.label - Menu item label to display
 * @param item.path - The path to redirect to
 * @param item.partialMatch - In case a sub-route should match the current item, the regex condition to compare to the
 *  current path.
 */
export const PrismTabNav = ({ items, className, disabled, 'data-test-attribute': dataTestAttribute }: Props) => {
  const location = useLocation()
  const history = useHistory()
  const navItems = items.map(item => ({
    value: item.path,
    'data-testid': item['data-testid'],
    label: item.label,
  }))

  const selectedItems = items
    .filter(item => {
      const itemPathName = item.path?.split('?')[0]

      if (item.forceState !== undefined) return item.forceState
      if (item.partialMatch) return location.pathname.match(item.partialMatch)
      return itemPathName === location.pathname
    })
    .map(item => item.path)

  return (
    <PrismTabMenu
      data-test-attribute={dataTestAttribute}
      items={navItems}
      selectedItems={selectedItems}
      onSelect={value => history.push(value!)}
      className={className}
      disabled={disabled}
    />
  )
}

type PrismTabMenuProps<T extends string> = {
  items: { value: T; label?: string; 'data-testid'?: string }[]
  selectedItems: T[]
  onSelect: (value: T) => void
  className?: string
  disabled?: boolean
  'data-test-attribute'?: string
}
/**
 * Renders a tab menu which recieves a set of items, a selected item, and a callback to change the currently selected item.
 * This component is being used only when the url path doesn't change.
 *
 * @param items - the set of items to display in the menu
 * @param item.label - Menu item label to display
 * @param item.path - The path to redirect to
 * @param item.partialMatch - In case a sub-route should match the current item, the regex condition to compare to the
 *  current path.
 */
export function PrismTabMenu<T extends string>({
  items,
  className,
  selectedItems,
  onSelect,
  disabled,
  'data-test-attribute': dataTestAttribute,
}: PrismTabMenuProps<T>) {
  return (
    <nav className={`${Styles.menu} ${className ?? ''}`} data-test-attribute={dataTestAttribute}>
      {items.map((item, index) => {
        return (
          <div
            onClick={() => {
              if (disabled) return
              onSelect(item.value)
            }}
            className={`${Styles.menuOption} ${(selectedItems.includes(item.value) && Styles.selected) || ''} ${
              disabled ? Styles.menuOptionDisabled : ''
            }`}
            data-test-attribute={selectedItems.includes(item.value) ? 'tab-active' : 'not-active'}
            key={index}
            data-testid={item['data-testid']}
          >
            {item.label || item.value}
          </div>
        )
      })}
    </nav>
  )
}
