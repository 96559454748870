/**
 * This is the only module in the code base in which we read from `process.env`.
 * In this module, we parse possibly undefined env var strings to `string`,
 * `boolean`, `number` and other types.
 *
 * Read more in the README section on environment variables.
 */
export function parseBoolean(s?: string) {
  return s ? s.toLowerCase() === 'true' : false
}

export function parseNumber(s?: string, defaultValue = 0) {
  const n = Number(s)
  return isNaN(n) ? defaultValue : n
}

export function parseArray(s?: string) {
  return s ? (s as string).split(',') : []
}

export function parseString(s?: string) {
  return (s || '').trim()
}

/**
 * Since our env vars get bundled up in the deploy process through the
 * `substitute_vars.sh` script. It's ABSOLUTELY NECESSARY that we add a
 * default for the env var to `defaults.env` for it to be able to be parsed.
 */

// This env var is always defined by CRA and cannot be overwritten
export const NODE_ENV = process.env.NODE_ENV as 'development' | 'test' | 'production'

export const ENABLE_UI_PLAYGROUND =
  parseBoolean(process.env.REACT_APP_ENABLE_UI_PLAYGROUND) && NODE_ENV !== 'production'

export const BACKEND_URL = parseString(process.env.REACT_APP_BACKEND_URL)
export const CLOUD_FASTAPI_URL = parseString(process.env.REACT_APP_CLOUD_FASTAPI_URL)
export const CLOUD_FASTAPI_WS_URL = parseString(process.env.REACT_APP_CLOUD_FASTAPI_WS_URL)
export const ROBOT_FASTAPI_URL = parseString(process.env.REACT_APP_FASTAPI_URL)

export const IS_QA = parseBoolean(process.env.REACT_APP_IS_QA)
export const ENABLE_COMMUNICATIONS = parseBoolean(process.env.REACT_APP_ENABLE_COMMUNICATIONS)
export const VIDEO_LOADING_TIMEOUT_MS = parseNumber(process.env.REACT_APP_VIDEO_LOADING_TIMEOUT_MS, 3000)
export const ALIGNMENT_FAILURE_NOTIFICATION_RATE = parseNumber(
  process.env.REACT_APP_ALIGNMENT_FAILURE_NOTIFICATION_RATE,
  0.5,
)
export const ALIGNMENT_FAILURE_NOTIFICATION_NUMBER = parseNumber(
  process.env.REACT_APP_ALIGNMENT_FAILURE_NOTIFICATION_NUMBER,
  6,
)
export const VERSION = parseString(process.env.REACT_APP_VERSION)
export const VERSION_RELEASE_NOTES_URL = parseString(process.env.REACT_APP_VERSION_RELEASE_NOTES_URL)
export const HEAP_APP_ID = parseString(process.env.REACT_APP_HEAP_APP_ID)
// If we ever support running a local backend instead of connecting to the cloud, we'll need to set this env var through the ansible deploy process instead
export const IS_LOCAL_DEPLOY = process.env.REACT_APP_BACKEND_URL?.includes('localhost')

export const AUTH0_DOMAIN = parseString(process.env.REACT_APP_AUTH0_DOMAIN)
export const AUTH0_CLIENTID = parseString(process.env.REACT_APP_AUTH0_CLIENTID)
export const AUTH0_AUDIENCE = parseString(process.env.REACT_APP_AUTH0_AUDIENCE)

export const AUTH0_ROCKWELL_DOMAIN = parseString(process.env.REACT_APP_AUTH0_ROCKWELL_DOMAIN)
export const AUTH0_ROCKWELL_CLIENTID = parseString(process.env.REACT_APP_AUTH0_ROCKWELL_CLIENTID)
export const AUTH0_ROCKWELL_AUDIENCE = parseString(process.env.REACT_APP_AUTH0_ROCKWELL_AUDIENCE)
export const ROCKWELL_LOGOUT_REDIRECT_URL = parseString(process.env.REACT_APP_ROCKWELL_LOGOUT_REDIRECT_URL)
