import React, { FormEvent, useEffect, useRef } from 'react'

import { InputRef } from 'antd'
import { Controller, useForm } from 'react-hook-form'

import { service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { Token } from 'components/Token/Token'
import Shared from 'styles/Shared.module.scss'

import Styles from './RecipesList.module.scss'

export type RenameRecipeModalProps = {
  recipeParentId: string
  recipeParentName: string
  onClose: () => void
  onUpdate: () => Promise<void>
}

const RenameRecipeModal = ({ recipeParentId, recipeParentName, onClose, onUpdate }: RenameRecipeModalProps) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const defaultValues = { name: recipeParentName }
  const {
    formState: { isDirty, isValid, errors },
    control,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleSave = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const { name } = getValues()

    const res = await service.updateRecipeParent(recipeParentId, {
      name,
    })

    if (res.type !== 'success') {
      return error({ title: 'There was an error renaming the recipe, please try again' })
    }

    await onUpdate()
    success({ title: 'Recipe renamed', 'data-testid': 'rename-recipe-success-notification' })
    onClose()
  }

  return (
    <Modal
      id="rename-recipe"
      header="Rename Recipe"
      headerClassName={Styles.recipeModalHeader}
      onClose={onClose}
      onOk={handleSave}
      disableSave={!isDirty || !isValid}
      okText="Rename"
      data-testid="rename-recipe-modal"
      size="largeSimpleForm"
      modalFooterClassName={Styles.recipeModalFooter}
      modalBodyClassName={Shared.verticalChildrenGap24}
    >
      <form onSubmit={handleSave}>
        <Token label="recipe name" className={Styles.selectWrapper}>
          <Controller
            name="name"
            rules={{ required: 'Required' }}
            control={control}
            render={props => (
              <PrismInput
                {...props}
                ref={inputRef}
                errors={errors}
                placeholder="Name Recipe"
                data-testid="add-recipe-name-input"
              />
            )}
          />
        </Token>
      </form>
    </Modal>
  )
}

export default RenameRecipeModal
