import { Point } from './Point'

const MINIMUM_ANGLE_CHANGE = Math.PI / 2
const MINIMUM_DISTANCE = 2
const MAXIMUM_DISTANCE = 80

/**
 * This function is used to add vertices to a polygon as the user moves their cursor. It takes in the current location
 * of the cursor and a buffer of previous points and compares the current point to the previous points to decide whether
 * to create a vertex at the current point.
 * @param curr The current point, corresponds to the user's mouse position
 * @param previousPoints The list of previous points needed to decide whether to place the current point
 * @returns
 */
export function recordPoint(curr: Point, previousPoints: Point[]): [boolean, Point[]] {
  // This can be made much more sophisticated. Do we want to take into account the time since the last point recorded
  // and drop points at a constant rate, under the assumption that the user is drawing the path with roughly constant
  // arc length per item time, or do we want to maybe adapt to the rate that the user is drawing? We can also maintain a
  // parallel collection of points corresponding to location where we didn't drop vertices at the cost of some memory
  // increase.

  // Using bezier curves might be nicer initially at the cost of making editing considerably more difficult. Also, the
  // performance seems to degrade with 100 points or so. I don't have a clear sense of how many points we can expect
  // users to input.

  // Takes in a new location, decides whether to lay a vertex at that point, and records it if so.

  if (previousPoints.length === 0) {
    // If we have no previous vertices dropped, record the current location and don't place anything.
    return [false, [curr]]
  } else {
    const last = previousPoints[previousPoints.length - 1]
    if (!last) return [false, previousPoints]

    const distance = Point.distance(curr, last)
    // Otherwise check the distance between the current point and the last vertex
    if (distance > MAXIMUM_DISTANCE) {
      // If it exceeds the maximum set above, drop a new vertex and return
      return [true, _recordPoint(curr, previousPoints)]
    }
    if (previousPoints.length > 1 && distance > MINIMUM_DISTANCE) {
      // If there are at least two previous vertices and the current point is above a minimum distance from the
      // preceeding vertex, then we check the angle formed by [secondToLast, last, current].
      const secondToLast = previousPoints[previousPoints.length - 2]
      if (!secondToLast) return [false, previousPoints]
      const prevVec = last.subtract(secondToLast)

      const currVec = curr.subtract(last)
      const absAngle = Math.abs(Point.angle(currVec, prevVec))
      if (absAngle > MINIMUM_ANGLE_CHANGE) {
        // If this angle exceeds a fixed threshold we drop a new vertex and record it.
        return [true, _recordPoint(curr, previousPoints)]
      }
    }
  }
  // Otherwise don't drop a point and don't record anything
  return [false, previousPoints]
}

/**
 * A helper function that is run whenever we are going to drop a new vertex. This modifies the previousPoints array to
 * generate the array that will be returned by the call to recordPoints. Currently we only keep track of the last two
 * vertices placed.
 * @param curr The current point.
 * @param previousPoints The array of previous points.
 * @returns
 */
function _recordPoint(curr: Point, previousPoints: Point[]) {
  const finalPoints = previousPoints.slice()
  if (finalPoints.length >= 2) {
    finalPoints.shift()
  }
  finalPoints.push(curr)
  return finalPoints
}
