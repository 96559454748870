import React from 'react'

import { Button } from 'components/Button/Button'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismNavArrowIcon, PrismSharedToolIcon } from 'components/prismIcons'
import { TOOL_ICONS } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import { ToolSpecificationName } from 'types'
import { TOOLS_DESCRIPTIONS } from 'utils/constants'

import Styles from './ToolCard.module.scss'

type Props = {
  type: ToolSpecificationName
  title: string
  actionButton?: JSX.Element
  children: React.ReactNode
  saveDisabled: boolean
  readOnly: boolean
  isSharedTool?: boolean
  onSave: () => any
  onExit: () => any
}
function ToolCard({ type, children, title, actionButton, saveDisabled, isSharedTool, onSave, onExit }: Props) {
  return (
    <div className={Styles.toolCardContainer}>
      <div className={Styles.toolCardScrollable}>
        <div className={Styles.toolCardHeader}>
          <div className={Styles.toolCardName}>
            <div className={Styles.toolIconContainer}>{TOOL_ICONS(type)}</div>
            <PrismOverflowTooltip content={title} />

            {actionButton && <div className={Styles.actionButton}>{actionButton}</div>}
          </div>

          <div className={Styles.toolCardDescription}>{TOOLS_DESCRIPTIONS[type]}</div>
        </div>

        {isSharedTool && (
          <PrismTooltip
            title={
              <Token label="Shared Tool">
                This tool is used in multiple views. Its name, labels, models, and training are shared. <br /> <br />{' '}
                Other settings, like the size and position of its areas, are unique to each view.
              </Token>
            }
            mouseEnterDelay={0.01}
            placement="rightTop"
            overlayClassName={Styles.tooltipContainer}
            anchorClassName={Styles.sharedTool}
          >
            <>
              <PrismSharedToolIcon />
              <span>Shared tool used in multiple views</span>
            </>
          </PrismTooltip>
        )}
        <div className={Styles.toolCardBody}>{children}</div>
      </div>

      <div className={Styles.toolCardFooter}>
        <IconButton
          className={Styles.iconButtonContainer}
          icon={<PrismNavArrowIcon direction="left" />}
          type="secondary"
          size="small"
          onClick={onExit}
          data-testid="tool-go-back-button"
        />
        <Button type="secondary" size="small" wide onClick={onExit} data-testid="tool-form-cancel-button">
          Cancel
        </Button>
        <Button size="small" wide onClick={onSave} disabled={saveDisabled} data-testid="tool-form-save-button">
          Save
        </Button>
      </div>
    </div>
  )
}

export default ToolCard

export const SharedTooltip = ({ iconClassName }: { iconClassName?: string }) => {
  return (
    <PrismTooltip
      title={<Token label="Shared Setting">Changing this setting may affect multiple views.</Token>}
      mouseEnterDelay={0.01}
      placement="rightTop"
      overlayClassName={Styles.tooltipContainer}
      anchorClassName={`${Styles.sharedToolIcon} ${iconClassName ?? ''}`}
    >
      <PrismSharedToolIcon />
    </PrismTooltip>
  )
}
