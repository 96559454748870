import React from 'react'

import { useHistory } from 'react-router'

import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import { PreprocessingOptions, PreprocessingOptionsAlt, RecipeExpanded, RoutineWithAois } from 'types'
import { protectedOnChange } from 'utils'

import Styles from '../ToolStyles.module.scss'

/**
 * Renders the inputs for the common preprocessing settings: Rotation, Height Scale and Width Scale.
 *
 * @param setShowGrid - Function that sets whether we should show the grid overlay.
 * @param setInferenceArgs - Function that sets the given inferenceArgs.
 * @param routine - The current Routine.
 * @param inferenceArgs - The tool inference args.
 * @param readOnly - Whether we are in read only mode.
 */

const RotationAndScaleInputs = ({
  setShowGrid,
  setInferenceArgs,
  routine,
  recipe,
  inferenceArgs,
  readOnly,
}: {
  setShowGrid: (val: any) => void
  setInferenceArgs: (val: any) => void
  routine: RoutineWithAois
  recipe: RecipeExpanded
  inferenceArgs: PreprocessingOptions | PreprocessingOptionsAlt
  readOnly?: boolean
}) => {
  const history = useHistory()
  return (
    <>
      <div className={Styles.settingsItem}>
        <PrismSlider
          label="Rotation"
          min={-180}
          max={180}
          disabled={readOnly}
          value={inferenceArgs?.rotation_angle}
          onChange={protectedOnChange(
            val => {
              setShowGrid(true)
              setInferenceArgs({ rotation_angle: val })
            },
            { routine, recipe, history },
          )}
        />
      </div>
      <div className={Styles.settingsItem}>
        <PrismSlider
          label="Height Scale"
          value={inferenceArgs?.resize_height}
          min={0.25}
          max={4}
          step={0.25}
          disabled={readOnly}
          onChange={protectedOnChange(
            val => {
              setShowGrid(true)
              setInferenceArgs({ resize_height: val })
            },
            { routine, recipe, history },
          )}
        />
      </div>
      <div className={Styles.settingsItem}>
        <PrismSlider
          label="Width Scale"
          min={0.25}
          max={4}
          step={0.25}
          disabled={readOnly}
          value={inferenceArgs?.resize_width}
          onChange={protectedOnChange(
            val => {
              setShowGrid(true)
              setInferenceArgs({ resize_width: val })
            },
            { routine, recipe, history },
          )}
        />
      </div>
    </>
  )
}

export default RotationAndScaleInputs
