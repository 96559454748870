import React, { useMemo, useState } from 'react'

import { Cascader, CascaderProps } from 'antd'

import { PrismArrowIcon, PrismButtonArrowIcon, PrismSearchIcon } from 'components/prismIcons'

import Styles from './PrismCascader.module.scss'

export type CascaderOption = { value?: string | null; label: string | React.ReactNode; children?: CascaderOption[] }

export type PrismCascaderProps = CascaderProps & {
  titles?: string[]
  onChange?: (val: (string | number)[]) => void
  options: CascaderOption[]
  selectedOptionClassName?: string
}

/**
 * Renders a wrapper around ant Cascader
 * Prism Design: https://www.figma.com/file/Fp2lSfRJPQJ41gDcHQEQJT/Sites%2C-Lines-and-Stations-(3.0)?node-id=6152%3A22770&mode=dev
 * Ant Link: https://ant.design/components/cascader#cascader-demo-custom-dropdown
 *
 * @param options – Menu for each cascade. MUST be memoized.
 * @param titles – An array of string, the cascade wrapper adds a place for the titles at the top of each menu
 * @param className – Class name to be applied to the container
 * @param dropdownClassName – Class name to be applied to the dropdown wrapper
 */
export const PrismCascader = ({
  className = '',
  dropdownClassName = '',
  titles,
  options,
  value,
  changeOnSelect,
  onChange,
  selectedOptionClassName = '',
  ...rest
}: PrismCascaderProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const titlesKey = titles ? [...titles].sort().join() : ''
  const optionList = useMemo(() => {
    if (!titles || titles.length === 0 || isSearching) return options

    // Adds the title to each cascader menu (column)
    const addTitlesRecursively = (options: CascaderOption[], loopLevel = 0): CascaderOption[] => {
      const titleOption = { label: titles[loopLevel] || '', value: `${loopLevel}_title` }

      return [
        titleOption,
        ...options.map<CascaderOption>(option => {
          if (!option.children) return option

          // Call the function again untill the options stop containing a children key
          const childrenWithTitles = addTitlesRecursively(option.children, loopLevel + 1)

          return { ...option, children: childrenWithTitles }
        }),
      ]
    }

    return addTitlesRecursively(options)
  }, [options, titlesKey, isSearching]) // eslint-disable-line

  return (
    <Cascader
      {...rest}
      multiple={false}
      // change on select completely breaks search functionality
      changeOnSelect={!isSearching && changeOnSelect}
      value={value}
      className={`${className} ${Styles.selectWrapper}`}
      onDropdownVisibleChange={isDropdownVisible => {
        if (!isDropdownVisible && isSearching) setIsSearching(false)
      }}
      onSearch={value => setIsSearching(!!value)}
      onChange={val => {
        onChange?.(val)
        setIsSearching(false)
      }}
      dropdownClassName={`${Styles.dropdownContainer} ${
        !isSearching ? Styles.firstOptionTitle : ''
      } ${dropdownClassName}`}
      options={isSearching ? options : optionList}
      suffixIcon={<PrismArrowIcon direction="down" className={`${Styles.arrowIconDown} ${Styles.arrowIcon}`} />}
      expandIcon={<PrismButtonArrowIcon className={Styles.arrowIconRight} />}
      displayRender={list => <CascaderSelectedOption optionsList={list} className={selectedOptionClassName} />}
      notFoundContent={
        <div className={Styles.notFoundContainer}>
          <PrismSearchIcon className={Styles.notFoundIcon} />
          <p className={Styles.notFoundText}>No results found</p>
        </div>
      }
    />
  )
}

const CascaderSelectedOption = ({ optionsList, className = '' }: { optionsList: string[]; className?: string }) => {
  // to determine when to truncate an element
  let maxOptionWidth = 120
  if (optionsList.length === 3) maxOptionWidth = maxOptionWidth / 2

  return (
    <ul className={`${Styles.cascaderSelectedList} ${className}`}>
      {optionsList.map((option, idx) => {
        const isLastElement = optionsList.length - 1 === idx
        return (
          <li
            key={idx}
            className={Styles.cascaderSelectedOption}
            style={{ maxWidth: maxOptionWidth * (idx + 1) + 'px' }}
          >
            <div className={Styles.textOverflow}>{option}</div>
            {!isLastElement && <span className={Styles.slash}>/</span>}
          </li>
        )
      })}
    </ul>
  )
}
