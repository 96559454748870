import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Eye Show Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A503
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismEyeShowIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="3" stroke="currentColor" />
      <path
        d="M3.71742 11.7705C5.65653 8.62196 8.42065 6.5 12 6.5C15.5794 6.5 18.3435 8.62196 20.2826 11.7705C20.3692 11.9112 20.3692 12.0888 20.2826 12.2295C18.3435 15.378 15.5794 17.5 12 17.5C8.42065 17.5 5.65653 15.378 3.71742 12.2295C3.63076 12.0888 3.63076 11.9112 3.71742 11.7705Z"
        fill="none"
        stroke="currentColor"
      />
    </svg>
  )
}
