import React from 'react'
import { useEffect, useRef } from 'react'

import { AoiOutlineConfiguration } from 'components/ImageWithBoxes/AoiOutline'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { PrismElementaryCube } from 'components/prismIcons'
import { AlignmentAnchor, RoutineWithAois, Tool } from 'types'
import { getAoisAndToolsFromRoutine } from 'utils'

import Styles from './GoldenImageWithAois.module.scss'

interface GoldenImageProps {
  routine: RoutineWithAois
  hoveredTool: Tool | undefined
  readOnly?: boolean
  showAois: boolean
}

/**
 * Renders the golden image with AOI outlines.
 *
 * This shows the golden image from the routine, and the aois on top of it when
 * a user hovers over a tool.
 *
 * @param routine - The routine we are working on.
 * @param hoveredTool - The tool the user is hovering over.
 * @param readOnly - Is the screen in read only mode.
 * @param showAois - Whether to show AOIs
 */
export const GoldenImageWithAois = ({ routine, hoveredTool, readOnly, showAois }: GoldenImageProps) => {
  const referenceImageContainer = useRef<ImageWithBoxes>(null)
  const goldenImage = routine.image

  let aois = getAoisAndToolsFromRoutine(routine).aois
  const filteredAois = getAoisAndToolsFromRoutine(routine).aois.filter(aoi =>
    aoi.tools.find(tool => tool.id === hoveredTool?.id),
  )

  const alignmentAoi = aois.find(aoi => aoi.tools.find(tool => tool.specification_name === 'alignment'))
  // There can only ever be 1 alignment AOI:
  const alignmentTool = alignmentAoi?.tools[0]

  if (alignmentAoi) {
    // Filter out the aois with alignment, as they render a full-size box that's not correct
    aois = aois.filter(aoi => alignmentAoi.id !== aoi.id)
  }

  useEffect(() => {
    if (!referenceImageContainer.current) return

    // This timeout waits for the version management drawer to close and then recalculates the image size using the draw function
    window.setTimeout(() => referenceImageContainer.current?.draw(), 300)
  }, [readOnly])

  let boundingBoxes: AoiOutlineConfiguration[] = []

  if (hoveredTool) {
    boundingBoxes = filteredAois.map(aoi => ({
      ...aoi,
      applyMask: !!aoi.tools.find(tool => tool.id === hoveredTool?.id),
    }))
  }

  if (hoveredTool && alignmentTool?.id === hoveredTool?.id) {
    boundingBoxes = hoveredTool?.inference_args?.b_boxes?.map((box: AlignmentAnchor) => ({
      ...box,
      applyMask: true,
    }))
  }

  if (showAois) {
    boundingBoxes = aois.map(aoi => ({
      ...aoi,
      applyMask: !!aoi.tools.find(tool => tool.id === hoveredTool?.id),
    }))

    if (alignmentTool) {
      boundingBoxes.push(
        ...alignmentTool?.inference_args?.b_boxes?.map((box: AlignmentAnchor) => ({
          ...box,
          applyMask: alignmentTool?.id === hoveredTool?.id,
        })),
      )
    }
  }

  const showBlackOverlay = boundingBoxes.length > 0

  return (
    <div className={Styles.goldenImageContainer}>
      {goldenImage && (
        <ImageWithBoxes
          src={goldenImage}
          boundingBoxes={boundingBoxes}
          ref={referenceImageContainer}
          showOverlay={showBlackOverlay}
        />
      )}
      {!goldenImage && <PrismElementaryCube />}
    </div>
  )
}
