import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponseOnlyData, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Divider } from 'components/Divider/Divider'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismCard, PrismCardBlankState } from 'components/PrismCard/PrismCard'
import { PrismInfoIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import UploadModal from 'components/UploadModal/UploadModal'
import { useAllToolLabels, useQueryParams } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import {
  BackendThreshold,
  ListResponseData,
  RecipeExpanded,
  RoutineWithAois,
  Tool,
  ToolLabel,
  TrainingResultFlat,
} from 'types'
import {
  calculateTrainingMetrics,
  computeConfusionMatrix,
  getAoisAndToolsFromRoutine,
  getThresholdFromTool,
  getTimeAgoFromDate,
  renderToolName,
  sleep,
  sortByNewestFirst,
} from 'utils'
import { DEFAULT_TOOL_LABELS_GETTER_KEY, TRAINABLE_TOOL_SPECIFICATION_NAMES, TRAINING_STATES } from 'utils/constants'

import Styles from '../RecipeOverview.module.scss'
import ViewHeader from '../ViewHeader'
import { ToolInfoCard } from './ToolInfoCard/ToolInfoCard'

interface Props {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  readOnly: boolean
  routineParentId: string
}

/**
 * Renders the screen that allows users to kick off model training jobs. See the current labeling progress
 * for each tool and see the previously trained at level of their tools.
 *
 * @param routine - Currently selected routine.
 * @param recipe - The Recipe we are working on.
 * @param readOnly - Whether the train screen is interactible or not.
 * @param routineParentId - The routine parent we're editing.
 *
 * Business Rules: The accuracy measured is received directly in the tool's metadata
 * within the field `training_metrics`. The information used to populate this screen comes
 * both from the tool itself and from the experiment/training run associated to it.
 */
const Train = ({ routine, recipe, readOnly, routineParentId }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [params] = useQueryParams()

  const { toolParentId } = params

  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  const [aois, tools] = useMemo(() => {
    const { aois, tools: routineTools } = getAoisAndToolsFromRoutine(routine)

    // Only select tools that can be trained
    const tools = routineTools
      .filter(tool => TRAINABLE_TOOL_SPECIFICATION_NAMES.includes(tool.specification_name))
      .sort((toolA, toolB) => {
        if (toolA.parent_name > toolB.parent_name) return 1
        if (toolB.parent_name > toolA.parent_name) return -1
        return 0
      })
    return [aois, tools]
  }, [routine])

  const selectedTool = useMemo(() => {
    return tools.find(tool => tool.parent_id === toolParentId) || tools[0]
  }, [toolParentId, tools])

  const handleChangeTool = useCallback(
    (tool: Tool) => {
      history.push(
        paths.settingsRecipe(recipe.parent_id, 'train', {
          ...params,
          toolParentId: tool.parent_id,
          routineParentId: routine.parent.id,
        }),
      )
    },
    [history, params, recipe.parent_id, routine.parent.id],
  )

  const { defaultLabels, allToolLabels } = useAllToolLabels()

  useEffect(() => {
    // This effect is used to rewrite the training_metrics field whenever it's not there or
    // if it's in an unwanted format.
    tools.forEach(async tool => {
      if (
        tool.state === 'successful' &&
        tool.experiment &&
        (!tool.metadata.training_metrics ||
          // The following condition checks to see if the `training_metrics` is empty.
          !Object.keys(tool.metadata.training_metrics).length)
      ) {
        const waitMs = 10000
        // Whenever we train a tool and a new tool is created, the training results are created
        // asynchronously. This means we must refetch training results until we're sure that the list
        // of results contains all training results for a given tool. Otherwise the confusion
        // matrix calculation will be incorrect.
        const refetchTrainingResults = async (
          prevRes?: SendToApiResponseOnlyData<ListResponseData<TrainingResultFlat>, {}>,
        ): Promise<SendToApiResponseOnlyData<ListResponseData<TrainingResultFlat>, {}>> => {
          const trainingResultsRes = await query(
            getterKeys.toolTrainingResults(tool.id),
            async () => {
              const res = await service.getToolTrainingResults(tool.id)
              if (res.type !== 'success') return { ...res, queryData: null }
              if (res.data.results.length === 0) return { ...res, type: 'error', queryData: null }
              return res
            },
            { dispatch },
          )
          if (trainingResultsRes?.type === 'success') {
            let toolResultCount = 0
            tool.experiment?.dataset.result_counts.label_counts?.forEach(labelCount => {
              if (labelCount.type === 'tool_label') {
                toolResultCount += labelCount.count
              }
            })

            // If the training results obtained match the amount of toolresults used for the dataset, we know we have
            // the complete list
            if (trainingResultsRes.data.results.length === toolResultCount) {
              return trainingResultsRes
            }

            // If the previous list of results matches the current list of results, we pretty much guarantee
            // that no new training results are being generated
            if (prevRes?.type === 'success' && prevRes.data.results.length === trainingResultsRes.data.results.length)
              return trainingResultsRes
          }

          await sleep(waitMs)
          return await refetchTrainingResults(trainingResultsRes)
        }

        const trainingResultsRes = await refetchTrainingResults()

        let defaultLabelsToUse = defaultLabels
        if (!defaultLabelsToUse) {
          const defaultLabelsRes = await query(
            getterKeys.toolLabels(DEFAULT_TOOL_LABELS_GETTER_KEY),
            async () =>
              service.getToolLabels({
                kind__in: 'default',
              }),
            { dispatch },
          )
          if (defaultLabelsRes?.type !== 'success') return
          defaultLabelsToUse = defaultLabelsRes.data.results
        }

        // Return silently, we can retry this the next time the component mounts, this functionality isn't crucial
        if (trainingResultsRes?.type !== 'success') return
        const toolThreshold = getThresholdFromTool(tool)
        const matrixParams: {
          threshold: BackendThreshold
          defaultLabels: ToolLabel[]
          allToolLabels: ToolLabel[] | undefined
        } = { threshold: toolThreshold, defaultLabels: defaultLabelsToUse, allToolLabels }

        const matrix = computeConfusionMatrix(trainingResultsRes.data.results, tool.specification_name, matrixParams)
        const training_metrics = calculateTrainingMetrics(matrix, allToolLabels, tool)

        const res = await service.patchProtectedTool(tool.id, { metadata: { ...tool.metadata, training_metrics } })
        if (res.type === 'success') {
          query(getterKeys.routine(routine.id), () => service.getRoutine(routine.id), {
            dispatch,
          })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tools])

  const handleImagesUpload = (files: File[]) => {
    setUploadModalOpen(false)
    dispatch(
      Actions.itemUploadUpdate({
        files,
        routine,
        recipe,
      }),
    )
  }

  const goldenImage = routine?.image

  const renderCards = useCallback(() => {
    if (tools.length === 0)
      return Array(3)
        .fill(undefined)
        .map((_, id) => <PrismCardBlankState key={id} />)

    return tools.map(tool => {
      const aoi = aois.find(aoi => aoi.tools.find(aoiTool => aoiTool.id === tool.id))

      return (
        <PrismCard
          type="transparent"
          size="medium"
          key={tool.id}
          data-testid={`train-tool-card-${renderToolName(tool).toLowerCase().split(' ').join('-')}`}
          data-test="train-tool-card"
          onClick={() => handleChangeTool(tool)}
          image={
            aoi && <ImageCloseUp loaderType="skeleton" src={goldenImage} region={aoi} maskingRectangleFill="#111111" />
          }
          active={tool.parent_id === selectedTool?.parent_id}
          imageContainerClassName={Styles.cardImageContainer}
          className={Styles.toolCard}
        >
          <PrismOverflowTooltip content={renderToolName(tool)} className={Styles.cardName} />
        </PrismCard>
      )
    })
  }, [tools, aois, goldenImage, selectedTool?.parent_id, handleChangeTool])

  return (
    <div className={Styles.trainLayoutWrapper}>
      <div className={Styles.trainWrapper}>
        <div className={`${Styles.toolsWrapper} ${readOnly ? Styles.versionDrawerIsOpen : ''}`}>
          <section className={Styles.toolsListWrapper}>
            <ViewHeader
              recipe={recipe}
              routine={routine}
              readoOnly={readOnly}
              routineParentId={routineParentId}
              className={Styles.toolsListHeader}
              mode="train"
            />
            <div className={Styles.toolsList}>{renderCards()}</div>
          </section>

          <Divider type="vertical" className={Styles.trainDivider} />

          <section className={`${Styles.toolsInfo} ${tools.length === 0 ? Styles.toolsInfoBlankWrapper : ''}`}>
            {tools.length === 0 && (
              <GenericBlankStateMessage
                title="No tools to train"
                description="You haven’t added any tools that require training."
                header={<PrismInfoIcon />}
              />
            )}

            {selectedTool && <ToolInfoCard readOnly={readOnly} routine={routine} tool={selectedTool} recipe={recipe} />}
          </section>
        </div>
      </div>

      {uploadModalOpen && (
        <UploadModal
          title="Upload Images for Labeling"
          multiple
          referenceImageUrl={routine.image}
          onClose={() => setUploadModalOpen(false)}
          onUpload={handleImagesUpload}
        />
      )}
    </div>
  )
}

export default Train

export const statesThatHaveStatus = ['invoked', 'in_progress', 'successful', 'failed']

// Renders the current trained status of a routine, returns in order of message priority
export function getTrainedStatusForRoutine(tools: Tool[]): {
  label: string
  status: 'failed' | 'in_progress' | 'successful' | 'never' | 'canceled'
} {
  if (tools.find(tool => tool.state === 'failed')) return { label: 'Error', status: 'failed' }
  if (tools.find(tool => tool.state && TRAINING_STATES.includes(tool.state)))
    return { label: 'In Progress', status: 'in_progress' }
  if (tools.find(tool => tool.state === 'canceled'))
    return {
      label: 'Canceled',
      status: 'canceled',
    }

  const lastTrain = tools.filter(tool => tool.training_completed_at).sort(sortByNewestFirst)[0]
  if (lastTrain?.training_completed_at) {
    return {
      label: getTimeAgoFromDate(lastTrain.training_completed_at).text,
      status: 'successful',
    }
  }

  return { label: 'Never', status: 'never' }
}

export function getLabeledSinceTrain(labeledCount: number, previousTrainCount: number) {
  if (previousTrainCount === 0) return '--'
  return labeledCount - previousTrainCount
}
