import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism User Icon
 *
 * * Reference: {https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=10060-16911&mode=design&t=HZWFUiieSJOSggaf-0}
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismUserIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g>
        <path
          id="Rectangle"
          d="M5 19.8108C5 16.0493 8.0493 13 11.8108 13H12.1892C15.9507 13 19 16.0493 19 19.8108V19.8108C19 19.9153 18.9153 20 18.8108 20H5.18919C5.0847 20 5 19.9153 5 19.8108V19.8108Z"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <circle id="Oval" cx="12" cy="7" r="3" stroke="currentColor" strokeLinecap="round" />
      </g>
    </svg>
  )
}
