import React, { useEffect, useState } from 'react'

import { wsPaths } from 'api'
import { PrismVideoLoadError } from 'components/prismIcons'
import StreamListener, { FrameWithMeta } from 'components/StreamListener'
import { IS_QA } from 'env'
import { useTypedSelector } from 'hooks'
import { Box, HalSettingsStreamMessage } from 'types'
import { fastApiWsUrl, log } from 'utils'

import { Props as VideoFeedProps, VideoFeed } from './VideoFeed'

export interface Props
  extends Omit<VideoFeedProps, 'frame'>,
    React.ImgHTMLAttributes<HTMLImageElement>,
    JSX.IntrinsicAttributes {
  relativeUrl: string
  framerate?: number
  onFrame?: (FrameWithMeta: FrameWithMeta) => any
  robotId: string
  last_n?: number
  zoom?: 0.5 | 0.33 | 0.25
  alt?: string
  isContinuousVideoFeed?: boolean
  overlaySrc?: string
  overlayOpacity?: number
  forceRegion?: Box
  onRegionChange?: (region?: Box) => any
  'data-testid'?: string
  'data-test-attribute'?: string
  'data-test'?: string
  onOverlayLoad?: React.ReactEventHandler<HTMLImageElement>
  onIsContinuousFeedChange?: (isContinuousFeed?: boolean) => void
}

/**
 * Renders an `img` tag whose `src` attribute is set to video frames, one by
 * one, read via websocket.
 *
 * @param relativeUrl - Path (excluding host and port) to endpoint that returns
 *     streaming response
 * @param robotId - Id of robot whose message stream we're consuming
 * @param onFrame - Callback that receives each frame as it comes in
 * @param framerate - Max framerate at which to read video stream
 * @param last_n - Passed through to websocket connection
 * @param onIsContinuousFeedChange - Function called whenever isContinuousFeed value changes
 */
function Video({
  relativeUrl,
  framerate,
  isContinuousVideoFeed,
  showErrorOnFrameTimeout,
  onFrame,
  last_n = 0,
  robotId,
  zoom,
  alt = 'Video Feed',
  overlaySrc,
  overlayOpacity,
  fallbackImage,
  onIsContinuousFeedChange,
  'data-testid': dataTestId,
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  ...rest
}: Props) {
  const [frame, setFrame] = useState<Blob>()
  const [messageId, setMessageId] = useState<string>()
  const [isContinuousFeed, setIsContinuousFeed] = useState(false)

  // Only for use in QA
  const edge = useTypedSelector(state => {
    if (!IS_QA) return
    return state.edge
  })

  useEffect(() => {
    if (isContinuousVideoFeed !== undefined) {
      setIsContinuousFeed(isContinuousVideoFeed)
    }
  }, [isContinuousVideoFeed])

  useEffect(() => {
    onIsContinuousFeedChange?.(isContinuousFeed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContinuousFeed])

  useEffect(() => {
    if (edge) log('Video.tsx', 'ws url', `${fastApiWsUrl(edge, robotId)}${relativeUrl}`)
  }, [edge, robotId, relativeUrl])

  const handleFrame = ({ frame, meta }: FrameWithMeta) => {
    setFrame(frame)
    setMessageId(meta?.message_id)
    if (onFrame) onFrame({ frame, meta })
  }

  const handleSettingsMessage = (messages: HalSettingsStreamMessage[]) => {
    if (messages.length === 0 || isContinuousVideoFeed !== undefined) return

    messages.forEach(msg => {
      setIsContinuousFeed(msg.payload.data.camera_trigger_mode !== 'hardware')
    })
  }

  return (
    <>
      <StreamListener
        connect={{ relativeUrl, robotId }}
        onFrame={handleFrame}
        mode="video"
        params={{
          framerate,
          last_n: last_n ?? isContinuousFeed ? undefined : 1,
        }}
      />

      {/* Get settings applied to hal */}
      <StreamListener
        connect={{ relativeUrl: wsPaths.halSettings(robotId), robotId }}
        onMessages={handleSettingsMessage}
        mode="message"
        params={{ last_n: 1 }}
      />

      <VideoFeed
        data-testid={dataTestId}
        data-test-attribute={dataTestAttribute}
        data-test={dataTest}
        fallbackImage={fallbackImage || <PrismVideoLoadError />}
        zoom={zoom}
        alt={alt}
        frame={frame}
        messageId={messageId}
        showErrorOnFrameTimeout={showErrorOnFrameTimeout ?? isContinuousFeed}
        overlaySrc={overlaySrc}
        overlayOpacity={overlayOpacity}
        {...rest}
      />
    </>
  )
}

export default Video
