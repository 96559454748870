import { TooltipPlacement } from 'antd/es/tooltip'
import { History, Location as HistoryLocation } from 'history'
import { Moment } from 'moment'
import { QueryBranch } from 'react-redux-query'
import { SuccessResponse } from 'request-dot-js'

import { OpenCvMat } from 'components/OpenCv/openCvTypes'
import pdfStyles from 'components/PrismPdf/styles'
import { CountSeries } from 'pages/StationDetail/Components/CountGraph'
import { GraphSeries } from 'pages/StationDetail/Components/YieldGraph'
import { TypedStore } from 'rdx/store'
import { LABELING_SCREEN_MODES, QS_FILTER_KEYS } from 'utils/constants'

// Our service methods return responses with all properties present on SuccessResponse (status, statusText, etc), but we don't require that updates to cached responses preserve this metadata, because it isn't useful, and it would make update calls more cumbersome. Therefore, we don't guarantee that responses retrieved from the getter branch contain this metadata.
export interface SuccessResponseOnlyData<T = any> extends Partial<SuccessResponse> {
  data: T
}

export interface EventsBranch<MT extends StreamMessage> {
  [key: string]: MT[] | undefined
}

export type LogoutAction = 'sessionExpired' | 'userLogout' | 'eulaNotSigned'

export interface AuthState {
  auth: Auth | null
  logoutAction: LogoutAction | null
  rockwellIdpLogin: boolean
}

export type CurrentOrgState = UserOrg | null

export interface RootState {
  getter: QueryBranch<SuccessResponseOnlyData>
  events: EventsBranch<StreamMessage>
  auth: AuthState
  currentOrg: CurrentOrgState
  robotDiscoveriesById: RobotDiscoveriesById
  inspector: {
    selectedRecipeId: string | null
    selectedRobotId: string | null
    showInspectionSelectionOverlay: boolean
    staticInspectorItemsFilter: 'all' | 'failed'
    inspectionsAlertedAlignmentFailing: { [inspectionId: string]: true }
    isInspectingManualPhoto: boolean
    isManualTriggerDisabled: boolean
    isinsightsEnabled: boolean
  }
  connectionStatus: {
    status: ConnectionStatus
  }
  itemUpload: { files: File[]; routine: RoutineWithAois; recipe: RecipeExpanded } | null
  locationHistory: LocationHistory
  edge: {
    urls: string[]
    paramsByEdgeUrl: {
      [url: string]: { enabled: boolean; robot_id: string; wsUrl: string }
    }
  }
  video: { [key: string]: { frame: Blob; dataMs: number; message_id: string } | undefined }
  localStorage: LocalStorageData
  triggerMode: {
    [robotId: string]: { triggerMode: RoutineSettings['camera_trigger_mode'] } | undefined
  }
  modals: string[]
}

// This type is used internally by Ant components. It's necessary for RangePicker components that have nullable parameters.
export type DateValue = moment.Moment | null

export type UserDateFormats = 'd/m/y' | 'm/d/y'
export type UserTimeFormats = '12hr' | '24hr'

export interface BaseUserOrg {
  id: string
  role: UserRole
  is_active: boolean
  session_length_s: number | null
  daily_inspections_report?: boolean
  weekly_inspections_report?: boolean
}

export interface UserOrg extends BaseUserOrg {
  organization: Organization
}

export interface User extends DbObject {
  email: string
  first_name: string
  last_name: string
  avatar: string
  is_active: boolean
  session_length_s: number
  user_org: BaseUserOrg
  rw_user_id?: string
  last_login?: string
}

export interface Me extends Omit<User, 'user_org'> {
  phone_number?: string
  is_phone_verified?: boolean
  daily_inspections_report: boolean
  weekly_inspections_report: boolean
  timezone: string
  date_format: UserDateFormats
  time_format: UserTimeFormats
  accepted_eula: string | null
  accepted_eula_version: string | null
  event_subs: EventSub[]
  user_orgs: UserOrg[]
}

export interface NonProvisionedOrg {
  service_id: string
  tenant_id: string
  rw_tenant_name: string
  can_provision: boolean
}

export interface Organization extends DbObject {
  session_length_s: number
  name: string
  alert_email: string
  user_tracking_enabled: boolean
  email_domain_skip_eula_allowlist: string[]
  accepted_eula: string | null
  accepted_eula_version: string | null
  rw_tenant_id: string | null
  is_active: boolean
  supported_tool_specs: ToolSpecificationName[]
  image_to_ia_days: number
}

export interface StationForSite extends DbObject {
  belongs_to_id: string | null
  is_deleted: boolean
  type_id: string
  name: string
  ordering: number | null
  recipe_parents: RecipeParent[]
  robots: Robot[]
}

export interface Site extends DbObject {
  address: {
    countryCode: string
    countryName: string
    state?: string
  }
  name: string
  organization_id: string
  subsites: SubSite[]
  stations: StationForSite[]
  timezone: string
  extra: Record<string, unknown>
  is_deleted: boolean
  subsite_types: SubSiteType[]
}

export interface SubSite extends DbObject {
  name: string
  type_id: string
  belongs_to_id: string
  extra: Record<string, unknown>
  is_deleted: boolean
}

export interface SubSiteExpanded extends SubSite {
  site_id: string
  // for v1, this won't be used since we are only going to have `line` as a subsite
  // belongs_to: SubSite | null
  // child_subsites: SubSite[] | null
}

export interface SubSiteType extends DbObject {
  name: string
  normalized_name: 'line' | 'station'
  site_id: string
  extra_schema: Record<string, unknown>
  // for v1, this won't be used since we are only going to have `line` as a subsite
  // belongs_to_type: SubSiteType | null
  // child_types: SubSiteType[] | null
}

export type EntitytypeMapper = {
  site: Site
  line: SubSite
  station: StationForSite
  recipeParent: RecipeParent
  subSiteType: SubSiteType
}

export interface Auth {
  id: string
  expires_at: string
  organization_id: string | null
  uses_sso: boolean
  // token will only be used if NODE_ENV === 'development'. In production we use cookies to auth.
  token?: string
}

export type UserRole =
  | 'owner' // Admin
  | 'manager' // Editor
  | 'inspector' // Operator
  | 'member' // Viewer

export interface ListResponseData<T = {}> {
  next?: string | null
  previous?: string | null
  results: T[]
}

export interface InspectionCountResult {
  count: number
  max_started_at: string | null
  recipe_id: string
}

export type BucketS = 30 | 1800

export type ReadTimeSeriesBody = Partial<{
  bucket_s: BucketS
  series_type: TimeSeriesType
  from_s: number
  to_s: number
  past_s: number
  agg_s: number
  labels: Record<string, string> | Record<string, string>[]
  with_labels: boolean
}>

export type TimeSeriesData<T = {}> = {
  ts: number
  value: number
} & T

export interface TimeSeriesMeta {
  from_s: number
  to_s: number
  agg_s: number | null
}

export interface TimeSeriesResult {
  // First element is the timestamp, second one is the count.
  entries: [number, number][]
  labels: Record<string, string>
}

export type AoiAndLabelMetrics = {
  aoiMetrics: TimeSeriesResult[] | undefined
  labelMetrics: TimeSeriesResult[] | undefined
}

// Determined by: https://momentjs.com/docs/#/manipulating/start-of/
export type TimeSeriesDatePeriod = '30m' | 'hour' | 'day' | 'week' | 'month'

// Will come back to add comments for each type
export type TimeSeriesType =
  | 'item_outcome_inspection'
  | 'item_outcome_station'
  | 'item_outcome_site'
  | 'item_label_inspection'
  | 'item_label_station'
  | 'item_label_site'
  | 'tool_result_outcome_inspection'
  | 'tool_result_outcome_station'
  | 'tool_result_outcome_site'
  | 'tool_result_label_inspection'
  | 'tool_result_label_station'
  | 'tool_result_label_site'
  | 'picture_outcome_site'
  | 'picture_label_site'
  | 'crop_tool_parent_org'

export type TimeSeriesCompaction = 'none' | '10m' | '60m' | '1440m'

export interface DbObject {
  id: string
  created_at: string
  updated_at: string
}

export interface NamedDbObject extends DbObject {
  name: string
}

export type Outcome = 'pass' | 'fail' | 'unknown'
export type ToolResultOutcome = Outcome | 'error' | 'needs-data'

export type MetricsToolResultOutcome = ToolResultOutcome | 'pass_because_muted'

export type NonEmptyArray<T> = [T, ...T[]]

export type AllOrNone<T> = Required<T> | Partial<Record<keyof T, undefined>>

export interface ItemExpanded extends DbObject {
  component: string
  inspection: string
  is_deleted: boolean
  pictures: NonEmptyArray<RealtimePicture>
  serial_number: string
  created_by_id: string
  calculated_labels?: string[]
  calculated_outcome: Outcome
}

export interface RoutineInItem extends Omit<Routine, 'parent'> {
  parent: {
    id: string
    is_deleted: boolean
    name: string
    recipe_parent_id: string
    recipe_parent_name: string
    recipe_parent_version: number
  }
}

export type Item = Omit<ItemExpanded, 'pictures' | 'created_by_id' | 'component' | 'inspection' | 'robot'> & {
  component: NamedDbObject & { image: string }
  inspection: DbObject & { name: string; inspector_name: string; routines?: RoutineInItem[] }
  fallback_images?: ItemFallbackImages
  inspection_id?: string
}

export type BatchItem = {
  id?: string
  inspection_id?: string
  serial_number: string
  pictures?: BatchPicture[]
}

export type BatchPicture = {
  id?: string
  routine_id: string
  image?: string
  tool_results?: BatchToolResult[]
}

export type BatchToolResult = {
  id?: string
  tool_id?: string
  aoi_id?: string
  prediction_outcome: ToolResultOutcome | ''
  prediction_metadata: PredictionMetadata
  muted?: boolean
  inference_user_args?: { [key: string]: any }
  insight_image?: string
  embedding?: string
}

export type FallbackImagePicture = Pick<
  RealtimePicture,
  'image' | 'image_thumbnail' | 'robot_id' | 'taken_at' | 'routine_id'
>

export type ItemFallbackImages = {
  pictures: FallbackImagePicture[]
  routines: { image: string; image_thumbnail: string; routine_id: string | null }[]
}

export type ItemWithFallbackImages = Item & {
  fallback_images: ItemFallbackImages
}

export interface ToolSpecification extends DbObject {
  is_deleted: boolean
  name: ToolSpecificationName
  display_name: string
}

// We have custom tool setup code for each of these tool kinds
export type ToolSpecificationName =
  | 'detect-barcode'
  | 'deep-svdd'
  | 'graded-anomaly'
  | 'classifier'
  | 'alignment'
  | 'random'
  | 'match-classifier'
  | 'ocr'
  | 'color-check'
  | 'measurement'

export type Confusion = { [labelId: string]: { [componentId: string]: { [labelId: string]: number } } }
export type ThresholdConfusion = {
  [labelId: string]: { [componentId: string]: { [labelId: string]: { gte: number; lt: number } } }
}
export type ConfusionMatrix = {
  all_data_confusion?: Confusion
  threshold_confusion?: ThresholdConfusion
}

export interface Tool extends DbObject {
  specification_id: string
  specification_name: ToolSpecificationName
  specification_display_name: string
  parent_id: string
  parent_name: string
  experiment_id?: string | null
  inference_element: string
  inference_command: string
  inference_args: { [key: string]: any }
  inference_user_args: { [key: string]: any }
  tool_inference_user_args: { [key: string]: any }
  inference_env: { [key: string]: string }
  inference_assets: string
  inference_assets_md5: string
  metadata: ToolMetadata
  train_triggered_by_user_id?: string
  state: ToolTrainingState | null
  training_completed_at: string | null
  // Experiment is only available when we get a tool nested in a routine from getRoutine call
  experiment?: { id: string; dataset: Dataset; tool_id: string }
  is_shared?: boolean
  version?: number | null
}

export type ToolFlat = Pick<
  Tool,
  | 'experiment_id'
  | 'created_at'
  | 'id'
  | 'updated_at'
  | 'version'
  | 'metadata'
  | 'inference_user_args'
  | 'specification_name'
>

export interface ToolThreshold {
  userFacingThreshold: number | undefined
  threshold: number
}

export interface GARToolThreshold {
  userFacingLowerThreshold: number | undefined
  userFacingUpperThreshold: number | undefined
  lowerThreshold: number
  upperThreshold: number
}

export type UserFacingThreshold =
  | Pick<ToolThreshold, 'userFacingThreshold'>
  | Pick<GARToolThreshold, 'userFacingLowerThreshold' | 'userFacingUpperThreshold'>

export type BackendThreshold =
  | Pick<ToolThreshold, 'threshold'>
  | Pick<GARToolThreshold, 'lowerThreshold' | 'upperThreshold'>

export type Threshold = ToolThreshold | GARToolThreshold

export interface ThresholdMetadata {
  threshold?: number
  lower_threshold?: number
  upper_threshold?: number
}

interface AoiParentThresholdOverridesMetadata {
  default?: ThresholdMetadata
  user_override?: ThresholdMetadata
}

export interface ToolMetadata {
  classes?: string[]
  training_metrics?: TrainingMetrics | undefined
  prediction_labels?: MetadataPredictionLabel[]
  aoi_thresholds?: { [aoiParentId: string]: AoiParentThresholdOverridesMetadata }
  // all of the other possible fields in Tool.metadata
  [key: string]: any
}

export type MetadataPredictionLabel = PartialToolLabel | { id: string }

export interface ToolParent extends DbObject {
  name: string
  tools: ToolFlat[]
  experiments: Experiment[]
  added_at?: string
  trained_at?: string
  is_shared?: boolean
}

interface AoiWithRoutineImage extends AreaOfInterest {
  routine_image: string
  routine_image_thumbnail: string
}

export interface ToolParentWithAoi extends ToolParent {
  aoi?: AoiWithRoutineImage
}

export type ExperimentState = 'failed' | 'successful' | 'in_progress' | 'invoked' | 'generating_dataset' | 'canceled'

// TODO: soon Experiment will lose its FK to tool_id, it's redundant with tool's FK to experiment
export interface Experiment extends DbObject {
  state: ExperimentState
  tools: string[]
  dataset_id: string
  state_updated_at: string
}

export type ToolLabelSeverity = 'good' | 'neutral' | 'minor' | 'critical'
// For default tool labels
export type ToolLabelValue =
  | 'normal'
  | 'anomaly'
  | 'untrained'
  | 'correct_match'
  | 'wrong_match'
  | 'correct_alignment'
  | 'wrong_alignment'
  | 'correct_text'
  | 'no_text'
  | 'wrong_text'
  | 'correct_barcode'
  | 'no_barcode'
  | 'wrong_barcode'
  | 'correct_color'
  | 'wrong_color'
  | 'correct_size'
  | 'wrong_size'
  | 'discard'
  | 'uncertain'
  | 'test_set'

export type ToolLabelKind = 'default' | 'custom'

export type ToolLabelImage = { url: string; thumbnail_url?: string }

export interface ToolLabel extends DbObject {
  // This is always lowercase in the frontend; should we enforce this in Django as well? Probably
  value: string
  severity: ToolLabelSeverity
  is_deleted: boolean
  is_auto_generated: boolean
  is_editable: boolean
  kind: ToolLabelKind
  description?: string
  root_cause?: string
  corrective_actions?: string
  user_images: ToolLabelImage[]
  fallback_images: ToolLabelImage[]
}

export interface UserLabelSet extends DbObject {
  tool_id: string
  created_by_id?: string
  created_by_first_name?: string
  created_by_last_name?: string
  tool_labels: string[]
  outcome: Outcome
  tool_result_id: string
}

export type ToolTrainingState =
  | 'uninitialized'
  | 'queued'
  | 'generating_dataset'
  | 'invoked'
  | 'in_progress'
  | 'successful'
  | 'failed'
  | 'canceled'

export interface Dataset {
  id: string
  created_at: string
  updated_at: string
  state: 'uninitialized' | 'generated'
  result_counts: {
    label_counts?: {
      type: 'labeled_outcome' | 'tool_label'
      count: number
      name: string
      id?: string
      // Might be undefined for Datasets generated before this key was added
      available_count?: number
    }[]
  }
}

export interface AlignmentToolInferenceArgs {
  b_boxes: AlignmentAnchor[]
  transformation: 'translation_only' | 'translation_rotation_scale' | 'full_affine' | 'homography'
  max_shift: number
  max_rotation: number
  max_scale: number
  feature_based: {
    active: boolean
    detector: 'ORB' | 'KAZE' | 'AKAZE' | 'MSER'
    reprojection_threshold: number
  }
  area_based: {
    active: boolean
    number_of_pyramid_levels: number
    number_of_pyramid_levels_used: number
  }
}

export interface Picture extends DbObject {
  image?: string
  image_thumbnail?: string
}

export interface RealtimePicture extends DbObject {
  image?: string
  image_thumbnail?: string
  robot_id: string | null
  calculated_outcome: Outcome
  routine_id: string
  tool_results: ToolResultEmptyOutcome[]
  taken_at: string
}

export interface Station extends DbObject {
  name: string
  site_id: string
  site_name: string
  ordering: number | null
  belongs_to: SubSite | null
  belongs_to_id: string | null
  recipe_parents: RecipeParent[]
  is_deleted: boolean
  type_id: string
  robots: Robot[]
}

export interface RobotWithStatus extends Robot {
  status: CameraStatus | undefined
}

export interface tationWithStatus extends Station {
  status: CameraStatus | undefined
}

export interface Robot extends DbObject {
  ip_address?: string
  serial_number?: string
  name?: string
  image?: string
  n_routine_slots: number
  n_tool_slots: number
  integration_plc_active: boolean
  integration_plc_settings: any
  integration_plc_tags_layout: IntegrationPlcTagLayout
}

export type Capabilities = {
  cam_model: string
  is_color: boolean
  cam_max_height_pixels: number
  cam_max_width_pixels: number
  cam_max_fps: number
  firmware_version: string
  ip_address: string
  subnet_mask: string
  mac_address: string
  sn: string
  grayscale: boolean
  linesource_supported: boolean
  lineselector_supported: boolean
  linesource_values: string[]
  lineselector_values_in: string[]
  lineselector_values_out: string[]
  settings_supported?: {
    image_flip_vertical?: boolean
  }
  cam_max_gain: number
  cam_min_gain: number
}

export interface RobotDiscovery {
  basler:
    | {
        capabilities: Capabilities | null
        commands: string[]
      }
    | undefined
}

export type RobotDiscoveriesById = { [robot_id: string]: RobotDiscovery }

export type IntegrationPlcTagLayout = {
  tags?: RobotTagLayout[]
}

export type RoutineSlot = {
  index: number
  routine_id: string | null
  routine_parent_id: string | null
  recipe_id: string | null
  recipe_plc_code: string | null
  tool_slots: ToolSlot[]
}

export type ToolSlot = {
  tool_id: string | null
  tool_parent_id: string | null
  index: number
}

type ToolsetState = 'LOADED' | 'MISSING' | 'CORRUPTED' | 'FAILED' | 'DOWNLOADING' | 'ERROR' | 'QUEUED' | 'CANCELED'

// Take download stream message status, map 'complete' -> 'LOADED', uppercase everything else, take union with state from status command
export type ToolsetStatus = {
  name: string // name is really routineId
  state: ToolsetState
  metadata: {
    deployed_at: number
    progress?: number
  }
}

type RecipeStatus = {
  metadata: { deployed_at: number }
  name: string
  state: ToolsetState
}

export interface Toolset {
  recipe: StatusCommandRecipe
  recipe_status: RecipeStatus
  tool_sets: { [routineId: string]: { tool_set_status: ToolsetStatus } }
}

export interface Toolsets {
  [recipeId: string]: Toolset
}

export interface RoutineParent extends DbObject {
  name: string
  routines?: BaseRoutine[]
  recipe_parent: BaseRecipeParent
  working_version_id: string | null
  recipe_parent_id: string
  is_deleted: boolean
  component_id?: string
  component_name?: string
}

export type RecipeItemCorrelationSettings =
  | { type: 'distinct' }
  | { type: 'multiple_item' }
  | { type: 'composite'; window_ms: number } // AKA composite_single_picture_multiple_camera
  | { type: 'composite_multiple_picture_single_camera'; self_window_ms: number }
  | {
      type: 'composite_multiple_picture_multiple_camera'
      self_window_ms: number
      window_ms: number
    }

export interface RecipeRoutineItemCorrelationSettings {
  offset_ms?: number
  item_group?: number
}

interface WorkflowsSettings {
  prompt_print_pass: boolean
  prompt_print_fail: boolean
  prompt_print_unknown: boolean
}

interface ProductionTargetsSettings {
  target_yield?: number
  units_per_minute?: number
}

export interface RecipeRoutineUserSettings {
  item_correlation?: RecipeRoutineItemCorrelationSettings
}
export interface RecipeRoutine extends DbObject {
  routine: RoutineWithAois
  robot_id: string
  user_settings?: RecipeRoutineUserSettings
}

export interface RecipeRoutineExpanded extends RecipeRoutine {
  recipe: Recipe
}

export interface StationFlat extends DbObject {
  belongs_to: SubSite | null
  site_name: string
  site_id: string
  name: string
  is_deleted: boolean
}

export interface BaseRecipeParent extends DbObject {
  working_version_id: string | null
  name: string
  component_id: string
  station_id: string
}

export interface RecipeParent extends Omit<BaseRecipeParent, 'working_version_id'> {
  // rene - remove `?` after talking with edge team
  station?: StationFlat
  working_version: ({ version: number } & DbObject) | null
  component_name: string
  is_deleted: boolean
  fallback_images: { image: string; image_thumbnail: string; created_at: string }[]
}

// TODO: we should add component_name to the serializer. We need this field to be able to properly render a missing option in our `SearchableSelect`
export interface RecipeParentExpanded extends Omit<RecipeParent, 'component_name'> {
  recipes: BaseRecipe[]
}

export interface BaseRecipe extends DbObject {
  version: number
  unrestorable: boolean
}

export interface RecipeUserSettings {
  item_correlation?: RecipeItemCorrelationSettings
  workflows?: WorkflowsSettings
  production_targets?: ProductionTargetsSettings
}
export interface Recipe extends BaseRecipe {
  deployed: boolean
  user_settings?: RecipeUserSettings
  parent: RecipeParent
  parent_id: string
  is_protected: boolean
}

export interface StatusCommandRecipe extends Recipe {
  recipe_routines: { robot_id: string; routine_id: string }[]
}

export interface RecipeExpanded extends Recipe {
  recipe_routines: RecipeRoutine[]
}

export interface BaseRoutine extends DbObject {
  image: string
  image_thumbnail?: string
  fallback_images?: {
    image?: string
    image_thumbnail?: string
  }
  version: number
}

export interface Routine extends BaseRoutine {
  parent: RoutineParent
  settings: RoutineSettings | null
}

export interface RoutineLinkedToRobotResponse {
  robot_id: string
  recipe_id: string
  routine_id: string
  robot_name: string
  routine_parent_name: string
  routine_parent_id: string
  recipe_parent_id: string
}

export interface RoutineWithAois extends Routine {
  aois: AreaOfInterestExpanded[]
  is_protected: boolean
}

export interface InspectionRoutine {
  id: string
  inspection_id: string
  routine_id: string
  robot_id: string | null
  routine_definition?: RoutineWithAois
  item_group: number
  user_settings?: RecipeRoutineUserSettings
}

export interface Inspection extends DbObject {
  component: Component
  user_settings?: RecipeUserSettings
  routines?: Routine[]
  inspection_routines: InspectionRoutine[]
  name: string
  started_at: string | null
  ended_at: string | null
  created_by_id: string | null
  // station_id, recipe_id, site and subsites can be null for fake generated inspections, for example to generate new tool results
  station_id: string | null
  recipe_id: string | null
  site: { id: string; name: string } | null
  station_name?: string
  subsites: SubSite[] | null
  max_item_created_at?: string | null // This is returned by inspection list endpoint
}

export type FlatInspection = Pick<Inspection, 'id' | 'started_at'>

export interface Component extends DbObject {
  name: string
  image: string
  is_deleted: boolean
  recipe_parents: (Pick<
    RecipeParent,
    'id' | 'name' | 'station_id' | 'updated_at' | 'is_deleted' | 'fallback_images' | 'working_version'
  > & { station_name: string })[]
}

export interface ToolResult extends DbObject {
  prediction_outcome: ToolResultOutcome
  user_outcome: ToolResultOutcome
  routine_id?: string
  routine_name?: string
  recipe_parent_id?: string
  recipe_parent_name?: string
  prediction_metadata: PredictionMetadata
  prediction_score: number | null
  picture: Picture
  tool: Tool | null
  aoi: AreaOfInterest | null
  item?: Item | null
  to_ia?: boolean
  // TODO: Remove the muted key after related migrations are finished
  muted: boolean
  active_user_label_set?: UserLabelSet
  prediction_labels: string[]
  inference_user_args: { [key: string]: any }
  embedding?: string
  insight_image?: string
  calculated_outcome: ToolResultOutcome
  user_label_sets?: UserLabelSet[]
}

export interface ToolResultEmptyPredictionOutcome
  extends Omit<ToolResult, 'prediction_outcome' | 'prediction_metadata'> {
  prediction_outcome: ToolResultOutcome | 'empty'
  prediction_metadata: PredictionMetadata | undefined
}

export interface ToolResultEmptyOutcome extends Omit<ToolResultEmptyPredictionOutcome, 'calculated_outcome'> {
  calculated_outcome: ToolResultOutcome | 'empty'
}

export interface PredictionMetadataErrors {
  vision_processing_err_code?: number
  vision_processing_err_str?: string
}

export interface MeasurementRawResult {
  bbox_width: number
  bbox_height: number
  width_units: number
  height_units: number
  bbox_x: number
  bbox_y: number
  diff_width_max: number
  diff_height_max: number
  diff_width_min: number
  diff_height_min: number
}

export type PredictionMetadata = PredictionMetadataErrors & {
  barcode?: string
  codes?: BarcodeCode[]
  predicted_class?: string
  raw_result?: { text?: string }
  text?: string
  num_pixels?: number
} & Partial<MeasurementRawResult>

export interface BarcodeCode {
  x?: number
  y?: number
  width?: number
  height?: number
  code?: string
  format?: string
  points?: [number, number][]
}

export interface AreaOfInterest extends DbObject, BoxWithShape {
  routine_id: string
  parent_id: string | null
  img_w: number
  img_h: number
}

export interface AreaOfInterestConfiguration extends Omit<AreaOfInterest, 'created_at' | 'updated_at' | 'parent_id'> {
  id: string
  parentName: string
  toolIds: string[]
  parent?: (DbObject & { name: string }) | null
  tools?: Tool[]
}

export interface AreaOfInterestExpanded extends DbObject, AreaOfInterest {
  parent: (DbObject & { name: string }) | null
  tools: Tool[]
}

export interface AreaOfInterestWithOutcome extends DbObject, AreaOfInterest {
  calculated_outcome: Outcome
  tool_results: ToolResultEmptyOutcome[]
  parent?: (DbObject & { name: string }) | null
  tools: Tool[]
}

export type PictureAreaOfInterest = AreaOfInterestWithOutcome & {
  picture_id: string
  aoi_id: string
}

export type Box = {
  x: number
  y: number
  width: number
  height: number
}

export interface BoxWithShape extends Box {
  shape?: AreaOfInterestShapeField | null
}

export interface MaxBounds {
  maxWidth: number
  maxHeight: number
}

export type Shape = 'rectangle' | 'polygon' | 'ellipse'

export type PolygonShapeField = { type: 'polygon'; data?: [number, number][] }
export type RectangleShapeField = {
  type: 'rectangle'
  data?: { width: number; height: number; rotationDegrees: number }
}
export type EllipseShapeField = {
  type: 'ellipse'
  data?: { radiusX: number; radiusY: number; rotationDegrees: number }
}

export type AreaOfInterestShapeField = PolygonShapeField | RectangleShapeField | EllipseShapeField

export type AlignmentAnchor = Box & {
  id: string
}

export interface LocationHistory {
  history: HistoryLocation[]
}

export interface ImageUpload {
  type: 'picture' | 'picture_thumb' | 'embedding' | 'insight'
  url: string
  // Depending on type, id is either picture id or tool result id
  id: string
  item_id: string
}

// Stream types

export type RequestStreamBody = {
  input_element: 'basler'
  input_stream: 'image'
  output_stream: 'compressed'
  output_fps: number
  output_max_len: number
  timeout_ms: number
  output_element: 'standard' | 'high' | 'highest'
  output_pixels: number
  output_quality: number
}

export type AtomElement =
  | 'vision-processing'
  | 'asset-management'
  | 'status'
  | 'basler'
  | 'transcoder-basler-image-medium'
  | 'transcoder-basler-image-thumbnail'
  | 'transcoder-basler-image-standard'
  | 'transcoder-basler-image-high'
  | 'transcoder-basler-image-highest'
  | 'transcoder-vision-trigger-image-standard'
  | 'transcoder-vision-trigger-image-medium'
  | 'transcoder'
  | 'hal'
  | 'integration-framework'

// Whenever a new command type is added, it needs to be in FastAPI
export type AtomCommand =
  | 'download_cancel'
  | 'download_resume'
  | 'load_tool_set'
  | 'status'
  | 'download_recipe'
  | 'get_recipe_definition'
  | 'remove_recipes'
  | 'start_inspection'
  | 'stop_inspection'
  | 'mute'
  | 'update_tool_settings'
  | 'trigger'
  | 'hide'
  | 'settings'
  | 'request_streams'
  | 'is_plc_disabled'
  | 'set_plc_disabled'
  | 'read_all_tags'
  | 'read_tags'
  | 'write_tags'
  | 'read_status'
  | 'get_slots'
  | 'set_slot'
  | 'clear_slot'

export type StreamDescriptor = {
  stream: string
  element?: AtomElement // Required (and present in messages from server) for edge streams
  robot_id?: string // Required (and present in messages from server) for edge streams
  latest_id?: string
  past_ms?: number
  last_n?: number
  from_iot_client?: boolean // Required for VPN streams
}

export type StreamMultiMeta = {
  stream: string
  element: AtomElement | ''
  robot_id: string
  message_id?: string // <unix_ts_in_ms>-<integer>
}

export type StreamMessage = {
  new: boolean
  message_id: string
  payload: any
  receivedMs: number // Local ts when message was received
  messageMs: number // Redis ts, which should be synchronized now that we're running Chrony
  meta?: StreamMultiMeta // For messages returned by multi-stream ws endpoints
}

export interface StreamReadMessage extends StreamMessage {
  payload: 'listening' | 'read'
}

export interface VpnHeartbeatStreamMessage extends StreamMessage {
  payload: { data: 'ok' }
}

export type InspectionDefinition = {
  name: string
  started_at: number
}
export interface VpStatusStreamMessage extends StreamMessage {
  payload: {
    status: VpStatus
    data: {
      slot?: string | null
      inspection_definition?: InspectionDefinition // This parameter will only be defined when the status is 'RUNNING'
      recipe_definition?: RecipeExpanded // This parameter will only be defined when the status is 'RUNNING'
      routine_id?: string // This parameter will be defined when the status is 'RUNNING', 'LOADED', 'LOADING' or 'LOADING_TOOLS'
      recipe_id?: string // This parameter will be defined when the status is 'RUNNING', 'LOADED', 'LOADING' or 'LOADING_TOOLS'
      inspection_id?: string // This parameter will be defined when the status is 'RUNNING' or 'LOADED'
    }
  }
}

export interface VpHeartbeatStreamMessage extends StreamMessage {
  payload: { is_online: boolean }
}

export interface BaslerHeartbeatStreamMessage extends StreamMessage {
  payload: { data: 'ok' }
}

export interface ToolsetDownloadStreamMessage extends StreamMessage {
  payload: {
    progress: number
    status: 'failed' | 'downloading' | 'complete' | 'error' | 'queued' | 'canceled'
    tool_set_id: string
    time_s: number
  }
}

export interface HalSettingsStreamMessage extends StreamMessage {
  payload: {
    data: RoutineSettings
  }
}

export interface ApiStreamMessage extends StreamMessage {
  payload:
    | { type: 'inspection-create'; payload: Inspection }
    | { type: 'inspection-update'; payload: Inspection }
    | { type: 'items-create'; payload: ItemExpanded[] }
    | { type: 'items-update'; payload: ItemExpanded[] }
    | { type: 'item-create'; payload: ItemExpanded }
    | { type: 'item-update'; payload: ItemExpanded }
    | { type: 'images-update'; payload: ImageUpload[] }
    | {
        type: 'prediction-labels-update'
        payload: {
          tool_result_id: string // tool result id
          prediction_labels: string[] // tool label ids
        }[]
      }
}

export interface OrganizationStreamMessage extends StreamMessage {
  payload:
    | {
        type: 'tool-update'
        payload: Tool & { experiment_via_routine_id: string; experiment_via_routine_parent_id: string }
      }
    | { type: 'routine-create'; payload: { new_routine_id: string; new_routine_parent_id: string } }
}

// End stream types

export type VpStatus =
  | 'LOADING' // VP has received a valid start_inspection command and is working on launching the inspection. This should take 5-20s and involves launching the containers via asset-management, starting handlers, etc.
  | 'LOADING_TOOLS' // VP is waiting for the tools to boot up before starting to generate items.
  | 'LOADED' // The inspection has been successfully launched and the inspection will automatically enter the RUNNING state
  | 'RUNNING' // VP is actively running the inspection
  | 'PAUSED' // VP has the inspection loaded but it is not actively running, i.e. frames are being ignored. This is not yet implemented but is part of the designs.
  | 'STOPPING' // VP has received a stop_inspection command and is working on cleaning up. This involves stopping all handlers and telling asset-management to tear down the containers which can take 5-20s.
  | 'READY' // VP is generally booted and ready for a start_inspection command
  | 'ERROR_MINOR' // (NOTE: do not drive ui based on this state; IGNORE IT AND REVERT TO PREVIOUS STATE). Something minor went wrong. This is not a state and there's no action required to clear this, VP will auto-recover on its own if anything needs to be done. If any commands fail we typically throw this status on the stream in case anyone listening cares (integration framework).
  | 'ERROR_MAJOR' // Something major went wrong. This is not a state, but we will eventually do something like docker-compose down -t0 && docker-compose up -d the stack if/when we see this and decide to handle the error. This is currently unimplemented.

export type CameraStatus = 'running' | 'stopped' | 'connected' | 'disconnected' | 'loading'

export type AoiReviewSettings = {
  filterPictures: boolean
  filterAois: boolean
  selectedAoiId: string
  selectedPictureId: string
}

export interface RoutineSettings {
  grayscale: boolean
  exposure_ms: number
  exposure_output?: string
  exposure_output_invert: boolean
  rotate_180?: boolean
  image_rotation_degrees: number
  camera_trigger_mode: TriggerMode
  trigger_input: string
  trigger_edge: boolean
  trigger_delay_ms: number
  trigger_debounce_ms: number
  interval_ms: number
  image_flip_vertical: boolean
  sensor_aoi: {
    x: number
    y: number
    width: number
    height: number
  }
  gamma: number
  gain: number
  // Don't use camera_properties to drive logic. It's optional and for record keeping only
  camera_properties?: {
    cam_model: string
    cam_max_height_pixels: number
    cam_max_width_pixels: number
  }
}

export type DetectionAoiXYWH = [number, number, number, number]

export type TriggerMode = 'hardware' | 'automatic' | 'manual'

export interface CameraSettings {
  routine: RoutineSettings
}

// error `message` if value falls outside this range
type Range = {
  min?: {
    value: number
    message?: string
  }
  max?: {
    value: number
    message?: string
  }
}

export type SettingsRules = Partial<{
  exposure_ms: Range
  trigger_delay_ms: Range
  trigger_debounce_ms: Range
  interval_ms: Range
}>

export const recipeOverviewModes = ['capture', 'configure', 'train', 'settings'] as const
export type RecipeOverviewMode = (typeof recipeOverviewModes)[number]

export const inspectTabs = ['site', 'product'] as const
export type InspectTabs = (typeof inspectTabs)[number]
export const stationDetailPrivateModes = [
  'recipes',
  // TODO: restore this path for CameraConsolidation work
  // 'settings',
  'communications',
] as const
export const stationDetailModes = [...stationDetailPrivateModes, 'overview', 'tools', 'timeline', ''] as const
export type StationDetailMode = (typeof stationDetailModes)[number]

export const notifyModes = ['subscriptions', 'notifications', 'contactInformation'] as const
export type NotifyMode = (typeof notifyModes)[number]

export const administrationSettingsModes = ['users', 'quality_events', 'general'] as const
export type AdministrationSettingsMode = (typeof administrationSettingsModes)[number]

export const accountSettingsModes = ['profile', 'security', 'datetime'] as const
export type AccountSettingsMode = (typeof accountSettingsModes)[number]

export const subscriptionTabs = ['stations', 'products']
export type subscriptionTabsType = 'stations' | 'products'

export const usersTabs = ['active', 'deleted']
export type usersTabsType = 'active' | 'deleted'

export interface PreprocessingOptions {
  rotation_angle?: number
  resize_height?: number
  resize_width?: number
  hsv_enable?: boolean
  hsv_min: number[]
  hsv_max: number[]
  erosion_enable: boolean
  erosion_width: number
  erosion_height: number
  erosion_iterations: number
  dilation_enable: boolean
  dilation_width: number
  dilation_height: number
  dilation_iterations: number
  erosion_first: boolean
  pixel_info?: PixelInfo
}

export interface PreprocessingOptionsAlt extends Omit<PreprocessingOptions, 'hsv_min' | 'hsv_max' | 'pixel_info'> {
  hsv_min_h: number
  hsv_min_s: number
  hsv_min_v: number
  hsv_max_h: number
  hsv_max_s: number
  hsv_max_v: number
  pixel_count_min?: number
  pixel_count_max?: number
}

export type PixelInfo = {
  pixel_count: number[]
}

export type StatusEntry = {
  description: string
  key: string
  msg: string
  name: string
  status_type: 'ok' | 'warning' | 'error' | 'timed_out'
  write_ts_ms: number
  timeout_ms: number
} & (
  | {
      data: string
      type: 'string'
    }
  | {
      data: boolean
      type: 'boolean'
    }
  | {
      data: number
      type: 'number'
    }
)

export type StatusEntries = { entries: { [type_colon_element: string]: StatusEntry | LogEntry }; current_ts_ms: number }

export interface LogEntry {
  type: 'log' | 'log_unified'
  element: 'status'
  stream: string
  write_ts_ms: number
}

export interface InspectionCreateResponseData {
  inspection: {
    id: string
  }
}

export interface ToolResultCount {
  results: {
    user_outcome: Outcome
    calculated_outcome: Outcome
    tool_parent_id: string
    count: number
    active_user_label_set__tool_labels__id: string
    component_id: string | null
  }[]
}

export interface AreaOfInterestExpandedWithImage extends AreaOfInterestExpanded {
  image?: string
}

export interface AoiWithToolResultCount {
  aoi: AreaOfInterestExpandedWithImage
  tool: Tool | undefined
  count: number
  fail: number
  pass: number
  unknown?: number
  muted?: number
}

export interface ServerTime {
  time: number
}

export interface LabelingProgressObject {
  labeledCount: number
  goalsForThisLevelById: { [labelNameOrId: string]: number }
  labelsStatusById: {
    [labelNameOrId: string]: { status: ToolLabelLabelingStatus; neededForBalance: number } | undefined
  }
}

export type RoutineWithDeployment = Routine & { deployed_at: number }

export type ToolsetMappedArray = {
  id: string
  deployed_at: number
}[]

export type PlcTags = {
  [tagName: string]: PlcTag
}

export type PlcTag = {
  tag_name: string
} & (
  | {
      tag_type: string
      tag_value: never
    }
  | {
      tag_value: boolean
      tag_type: 'BOOL'
    }
  | {
      tag_value: number
      tag_type: 'SINT' | 'INT' | 'DINT' | 'LINT' | 'USINT' | 'UINT' | 'UDINT' | 'ULINT'
    }
  | {
      tag_value: string
      tag_type: 'STRING' | 'STRING2' | 'STRINGN' | 'SHORT_STRING' | 'LOGIX_STRING' | 'STRINGI'
    }
)

// This is the format with which the items will be stored in Django
export interface RobotTagLayout {
  color: 'grey' | 'red' | 'green' | 'yellow'
  type: 'text' | 'input' | 'toggle' | 'button'
  index: number
  tag: string
  enable_tag?: string
  label?: string
  min_user_role: UserRole
}

// TODO: Ideally we should type narrow the value prop according to the type
export interface PlcTagLayout extends RobotTagLayout {
  disabled?: boolean
  value?: string | number | boolean
  dataType:
    | 'BOOL'
    | 'SINT'
    | 'INT'
    | 'DINT'
    | 'LINT'
    | 'USINT'
    | 'UINT'
    | 'UDINT'
    | 'ULINT'
    | 'STRING'
    | 'STRING2'
    | 'STRINGN'
    | 'SHORT_STRING'
    | 'LOGIX_STRING'
    | 'STRINGI'
}

export interface TrainingResult extends DbObject {
  prediction_labels: string[]
  prediction_score: number | null
  calculated_labels: string[]
  tool_result: ToolResult
  insight_image: string
  prediction_metadata?: PredictionMetadata
  routine_parent_id: string
}

export interface TrainingResultFlat {
  id: string
  experiment_id: string
  aoi_id: string
  prediction_labels: string[]
  calculated_labels: string[]
  routine_id: string
  routine_parent_id: string
  component_id: string | null
  set_type: 'train' | 'validation' | 'test'
  insight_image?: string
  recipe_id: string
  recipe_parent_id: string
  prediction_metadata?: PredictionMetadata
  prediction_score: number | null
}

export type InspectedComponent = {
  id: string
  name: string
  station_ids: string[]
}

// We can narrow down the expected query params by explicitly declaring their types:

export type StationListParams = {
  // Comma-separated list of status
  status?: string
  // Comma-separated list of products
  products?: string
  search?: string
  selected_station_id?: string
  selected_robot_id?: string
}

export type ToolResultsBatchLabel = {
  tool_results: ToolResultBatchLabelChild[]
}
export type ToolResultBatchLabelChild = {
  id: string
  outcome: ToolResultOutcome | ''
  tool_label_ids?: string[]
  set_null?: boolean
}

export type LabelingScreenMode = (typeof LABELING_SCREEN_MODES)[number]

export type LabelingMetrics = { [outcomeOrLabelId: string]: number }

export type MeasureToolSize = {
  target_max_width: number
  target_max_height: number
  target_min_width: number
  target_min_height: number
}

export const analyzeTabs = ['items', 'tools', 'products', 'overview', 'stations', 'batches'] as const
export type AnalyzeTab = (typeof analyzeTabs)[number]

export type AnalyzeDefect = {
  toolLabel: ToolLabel
  defectCount: number
  percentage: number
}

export type ProductWithFailYield = {
  failYield: number | undefined
  failCount: number | undefined
  name: string
  image: string
  is_deleted: boolean
  id: string
  created_at: string
  updated_at: string
}

export type StationWithFailYield = Station & {
  failYield: number | undefined
  failCount?: number | undefined
}

export type PdfChartData = {
  title: string
  subtitle: string
  subtitleSize: 'big' | 'small'
  imageUrl?: string | null
  fallbackText?: string
}

export type PdfColumn = {
  displayName: string
  key: string
  headerClassName?: keyof typeof pdfStyles
  className?: keyof typeof pdfStyles
}

export type PdfDataItem = {
  [columnKey: string]: {
    value?: string | number | { text: string; severity: ToolLabelSeverity }[]
    image?: string | null
  }
}

export type PdfGalleryCard = {
  outcome: ToolResultOutcome | 'discard'
  labels: ToolLabel[]
  imageUrl?: string | null
  deleted: boolean
}

export type PdfChartContent = {
  yield: {
    value: number
    data: GraphSeries[]
  }
  count: {
    value: number
    data: CountSeries[]
  }
  productsWithFailYield?: {
    data?: ProductWithFailYield[]
  }
  stationsWithFailYield?: {
    data?: StationWithFailYield[]
  }
  mostCommonDefects?: {
    data?: AnalyzeDefect[]
  }
  productInspected?: {
    value: string
  }
}

export type PdfYieldAndCountRow = {
  name: string
  yield: number
  count: number
  series: GraphSeries[]
  defects: AnalyzeDefect[] | undefined
}

export type PdfData =
  | {
      type: 'charts'
      chartContent: PdfChartContent
    }
  | {
      type: 'yieldAndCount'
      title: 'product' | 'station' | 'batch'
      rows: PdfYieldAndCountRow[]
    }
  | {
      type: 'items'
      viewMode: 'gallery' | 'list'
      content: ItemWithFallbackImages[]
    }
  | {
      type: 'toolResults'
      viewMode: 'gallery' | 'list'
      content: ToolResult[]
    }

export type PdfContent = { startDate: Moment | 'allTime'; endDate: Moment | 'allTime' } & (
  | {
      type: 'table'
      columns: PdfColumn[]
      dataSource: PdfDataItem[]
    }
  | {
      type: 'gallery'
      cards: PdfGalleryCard[]
    }
  | {
      type: 'dashboard'
      charts: PdfChartData[]
    }
)

export type TagType = 'success' | 'danger' | 'unknown' | 'default' | 'info'

export type LabelButtonTagType = 'pass' | 'fail' | 'unknown' | 'discard' | 'default' | 'generic' | 'minor'

export type LabelButtonIcon = 'pass' | 'fail' | 'unknown' | 'discard' | 'test_set'

// When modifying this type, remember to also modify TOOL_METADATA_SCHEMA on Django
export type TrainingMetrics = {
  succesful: number
  falseNegative: number
  falsePositive: number
  falseMinorOrCritical: number
  total: number
  byLabelId: {
    [labelId: string]: { [componentId: string]: Omit<TrainingMetrics, 'byLabelId'> }
  }
}

export interface NonTrainableToolLabel {
  id: string
  displayName: string
  outcome: Outcome
  buttonIcon?: LabelButtonIcon
  buttonTagType: LabelButtonTagType
}

export interface PartialToolLabel {
  value: ToolLabelValue
  severity: ToolLabelSeverity
}

export type QsFilterKey = (typeof QS_FILTER_KEYS)[number]

export type QsFilters = { [filterKey in QsFilterKey]?: string | undefined }

export type ToolLabelBody = Partial<ToolLabel>
declare global {
  interface Window {
    heap?: {
      appid: string
      loaded?: boolean
      userId?: string
      identify(id: string): void
      resetIdentity?(): void
      addUserProperties(properties: object): void
      config: {}
      load: (appId: string, config?: any) => void
      push: (e: any) => void
      // Functions below are not properly typed, we have to look at the docs if we ever have to use them
      track: any
      removeEventProperty: any
      clearEventProperties: any
      unsetEventProperty: any
      setEventProperties: any
      addEventProperties: any
    }
    cv?: OpenCvMat
    Module: any
  }
}

export type LabelModalMode = 'glossary' | 'addLabel'

export type ItemsByRobotId = { [robotId: string]: ItemExpanded }

export type ConnectionStatus = 'online' | 'recovering' | 'offline'

export type ToolLabelLabelingStatus = 'optimal' | 'off-balance' | 'below-minimum' | 'in-progress'

export type ToolLabelsMilestoneData = {
  labelingLevels: number[]
  countBySeverity: { [severity in ToolLabelSeverity]?: number }
}

export interface MutedNotifications {
  [userId: string]: { [inspectionId: string]: { errorCodes: number[] } }
}

export type PinnedCameraByStation = {
  [stationId: string]: string | undefined
}

export type ShareDetailModalSettings = {
  lastEmailAdress?: string
  includeImage?: boolean
}

export type LocalUserPreferences = {
  shareToolsOnRecipeDuplication?: boolean
  shareToolsOnViewDuplication?: boolean
}

export type LocalStorageData = {
  mutedNotifications: MutedNotifications
  pinnedCameraByStation: PinnedCameraByStation
  shareDetailModalSettings: ShareDetailModalSettings
  fromUrl: { from?: HistoryLocation }
  userPreferencesById: { [userId: string]: LocalUserPreferences }
}

export interface Eula {
  html: string
  version: string
}

export type ToolParentsCount = {
  results: { count: number; tool_parent_id: string }[]
}

export interface ToolFlatWithCurrentExperiment extends ToolFlat {
  currentExperiment?: Experiment
}

export interface DeepCopyRoutineBody {
  name?: string
  stage?: string
  copy_parents?: boolean
  copy_family?: boolean
  station_id?: string
  recipe_parent_id?: string
  robot_id?: string
}

export type MenuActionsInCameraSettings = 'duplicate' | 'move' | 'view' | 'rename' | 'link' | 'delete'

export type MenuActionsInOverviewHeader =
  | 'addView'
  | 'renameRecipe'
  | 'duplicateRecipe'
  | 'moveRecipe'
  | 'changeProduct'
  | 'archiveRecipe'
  | 'unarchiveRecipe'

export type DeploymentStatus = 'deployed' | 'not-deployed' | 'semi-deployed'

export type UpdateLiveToolSettingsCommandArgs = {
  tool: string
  tool_settings: { setting: string; value: any }[]
  update_db?: boolean
}

export type UpdateLiveSettingsCommand = {
  commandName: 'update_tool_settings'
  commandArgs: UpdateLiveToolSettingsCommandArgs
}

export type AoiUpdate = { id: string; inference_user_args: { [key: string]: any } }

export type PatchProtectedToolBody = Partial<Tool> & { aois?: AoiUpdate[] }

export type EventKind = 'default' | 'inspection'

export type DefaultEventType =
  | 'inspection_stop'
  | 'inspection_start'
  | 'tool_update_user_args'
  | 'tool_mute'
  | 'tool_unmute'
  | 'train_finish'
  | 'recipe_deploy'
  | 'recipe_remove'
  | 'compute_offline'
  | 'compute_online'
  | 'got_bad_recipe_code'

export type ItemEventType = 'item_outcomes' | 'tool_result_outcomes' | 'label_severities' | 'label_ids'
export type SeverityEventType = 'label_severity_minor' | 'label_severity_critical'

export type InspectionEventType = ItemEventType | SeverityEventType

export interface InspectionEventRules {
  type: InspectionEventType
  count?: number
  total_count?: number
  duration_s?: number
  ratio?: number
  values: string[]
}

export interface DefaultEventRules {
  type: DefaultEventType
}

export type EventTypeRules = InspectionEventRules | DefaultEventRules

export type EventTargetTable = 'organization' | 'station' | 'recipe_parent' | 'component' | 'tool_parent' | 'tool'

// This Target shape is only returned for EventScopes
export interface EventTargetWithName {
  id: string
  table: EventTargetTable
  name?: string
}

export interface Event extends DbObject {
  record_id: string
  organization_id: string
  type: string
  // User Email
  user: string
  inspection_id: string
  meta: {
    first_record_id: string
    first_record_ts: string
    last_record_id: string
    last_record_ts: string
    table: 'item' | 'tool_result'
  }
  created_at_db: string
}

export interface EventType extends DbObject {
  id: string
  organization_id: string
  name: string
  rules: EventTypeRules
  kind: EventKind
  event_scopes: EventScope[]
  disabled: boolean
  is_deleted: boolean
  recipe_code: string
}

export interface EventScope {
  id: string
  type_id: string
  targets: EventTargetWithName[]
}

// TODO: Probably needs a better name
interface EventNotificationViaFields {
  via_email: boolean
  via_sms: boolean
  via_app: boolean
}

export interface EventSub extends EventNotificationViaFields {
  id: string
  user_id: string
  type_id: string | null
  targets: EventTargets
  disabled_until_s?: number | null
}

export interface EventNotification extends DbObject, EventNotificationViaFields {
  user_id: string
  event_id: string
}

export interface EventSubTypeTemplate extends EventSub {
  targets: {}
}

export type GroupByNotificationsCount =
  | 'event__meta__targets__station_ids'
  | 'event__meta__targets__component_ids'
  | 'event__meta__targets__recipe_parent_ids'

export type NotificationCounts = {
  results: ({ count: number; last_notification: string } & { [key in GroupByNotificationsCount]?: string })[]
}

export interface CreateUpdateDeleteSubsBody {
  create?: (Pick<EventSub, 'via_sms' | 'via_app' | 'via_email' | 'type_id' | 'disabled_until_s'> & {
    targets: EventTargets
  })[]
  update?: Partial<Pick<EventSub, 'id' | 'via_sms' | 'via_app' | 'via_email' | 'disabled_until_s' | 'targets'>>[]
  // Ids of EventSubs to delete
  delete?: { id: string }[]
}

export type EventTypeBody = { kind: 'inspection'; name: string; rules: EventTypeRules }

export interface EventsCount {
  results: [{ count: number; type_id: string }]
}

export interface EventTargets {
  organization_id?: string
  station_id?: string
  component_id?: string
  recipe_parent_id?: string
  tool_id?: string
  tool_parent_id?: string
  user_id?: string
}

export interface EventScopeBody {
  type_id: string
  targets: EventTargets
}

export interface CreateUpdateDeleteEventScopesBody {
  create?: EventScopeBody[]
  update?: { id: string; targets: EventTargets }[]
  delete?: { id: string }[]
}

export type TestImagesSource = 'inspection' | 'training'

export type TrainingReportTab = 'all' | 'train' | 'validation' | 'test'

export interface TrainableToolSettingsProps {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  view: RecipeRoutineExpanded
  tool: Tool
  onExit: () => any
  readOnly: boolean
}

export type NonTrainableToolSettingsProps = Omit<TrainableToolSettingsProps, 'view'>

export type EntityType = 'site' | 'line' | 'station' | 'product' | 'recipe'

export type MenuPosition = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft' | 'left' | 'right' | 'center'

export type FileCustomError =
  | 'file-too-large'
  | 'file-too-small'
  | 'too-many-files'
  | 'file-invalid-type'
  | 'reference-image-mismatch'
  | null

export type ConditionalType<T, S, K extends object> = T extends S ? K : Partial<Record<keyof K, undefined>>

export type PerEntityModalProps<IsEditMode extends boolean, EntityProp extends {}> = {
  isEditMode?: IsEditMode
  onClose: () => void
} & ConditionalType<IsEditMode, true, { entity: EntityProp }>

export type SelectOptionImage = {
  content: React.ReactNode
  cameraStatus?: CameraStatus
  hideImageBorder?: boolean
}

export type SelectOptionTooltip = {
  tooltipCondition?: boolean
  tooltipTitle?: string
  tooltipOverlayClassName?: string
  tooltipPlacement?: TooltipPlacement
}

export type SelectOption = {
  key?: string
  value?: string | number
  title?: string | React.ReactNode
  dataTestId?: string
  dataTest?: string
  dataTestAttribute?: string
  disabled?: boolean
  isHidden?: boolean
  className?: string
  id?: string
  content?: React.ReactNode
  tooltip?: SelectOptionTooltip
  image?: SelectOptionImage
}

export type WithStatus<T extends object> = T & {
  status: CameraStatus | undefined
}

export type WithSiteId<T extends object> = T & { site_id: string }

export interface DeepCopyConfig {
  history: History
  routineParentId?: string
  recipe: RecipeExpanded
  onBeforeCreate?: () => void
  onCreate?: (newRecipe: RecipeExpanded, newRoutine?: RoutineWithAois) => Promise<void> | void
  onCancel?: () => void
  store?: TypedStore
}
