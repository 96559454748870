import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { debounce, isNil } from 'lodash'
import { useDispatch } from 'react-redux'
import { query, useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { PrismInfoIcon, PrismSharedToolIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { ModalBody, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import {
  useAllToolLabels,
  useConnectionStatus,
  useData,
  useQueryParams,
  useToolTrainingResults,
  useTrainingMetrics,
} from 'hooks'
import paths from 'paths'
import {
  AoiUpdate,
  AreaOfInterestConfiguration,
  ConfusionMatrix,
  PatchProtectedToolBody,
  RecipeRoutineExpanded,
  RoutineWithAois,
  TestImagesSource,
  Threshold,
  Tool,
  ToolLabel,
  ToolMetadata,
  ToolSpecificationName,
  TrainingMetrics,
  TrainingReportTab,
  TrainingResultFlat,
  UserFacingThreshold,
} from 'types'
import {
  appendDataToQueryString,
  computeConfusionMatrix,
  getAoisAndToolsFromRoutine,
  getLabelsFromPredictionLabels,
  getThresholdFromTool,
  getThresholdFromToolAoiThresholdMetadata,
  getThresholdFromUserFacingValues,
  getToolAoisFromRoutine,
  getUserFacingRecommendedValueFromTool,
  isThresholdFromGARTool,
} from 'utils'

import { ThresholdSideBar } from './ThresholdSideBar'
import Styles from './TrainingReport.module.scss'
import { TrainingReportFilters } from './TrainingReportFilters'
import { TrainingReportMetrics } from './TrainingReportMetrics'
import { TrainingReportSamples } from './TrainingReportSamples'

export const THRESHOLD_MIN = 0
export const THRESHOLD_MAX = 100
export const THRESHOLD_STEP = 0.01

export type ThresholdByViewData = {
  [viewId: string]: {
    overriden: boolean
    aoiParentId: string
    aois: AreaOfInterestConfiguration[]
    tool: Tool
    routine: RoutineWithAois
    threshold: Threshold
  }
}

export type ThresholdByRoutineParentId = {
  [RoutineParentId: string]: {
    overriden: boolean
    threshold: Threshold
  }
}

interface TrainingReportProps {
  onClose: () => any
  forceThreshold?: boolean
  modifiedThreshold?: Threshold
  onSaveThreshold?: (threshold: Threshold, trainingMetrics?: TrainingMetrics, aoiUpdates?: AoiUpdate[]) => any
  onCancelThreshold?: () => any
  forceTitle?: string
  inspectionId?: string | null
  thresholdButtonText?: string
  aoiParentId?: string
  toolId: string
  toolWithOverridesContext: Tool | undefined
  viewInInspection?: RecipeRoutineExpanded
  onSaveThresholdSuccess?: () => Promise<void> | void
}

/**
 * Renders the training report modal. This component is also used to render the threshold editing modal.
 *
 * @param onClose - Handler for closing the modal
 * @param toolId - Tool id to use for training results. This component will fetch the tool's data
 * @param inspectionId - Inspection ID to show data from. If defied, the component asumes we're in the StationDetail Tools tab
 * @param modifiedThreshold - If we are testing a new Threshold without being saved on the tool's inference_user_args
 * @param onSaveThreshold - Handler to use when saving. If undefined, the component will save changes normally.
 * @param forceThreshold - Whether we should force the threshold drawer to show up
 * @param thresholdButtonText - Text to show on the threshold drawer save button
 * @param forceTitle - Title to show if we wish to override default behaviour
 * @param onCancelThreshold - Handler to override default cancel threshold behaviour
 * @param viewInInspection - If provided, only this view's threshold will show on the sidebar
 */
export const TrainingReport = ({
  onClose,
  toolId,
  toolWithOverridesContext,
  inspectionId,
  modifiedThreshold,
  onSaveThreshold,
  forceThreshold,
  thresholdButtonText = 'Save',
  forceTitle,
  onCancelThreshold,
  aoiParentId,
  viewInInspection,
  onSaveThresholdSuccess,
}: TrainingReportProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [params] = useQueryParams<'insights_on'>()

  const fetchedTool = useQuery(toolWithOverridesContext ? undefined : getterKeys.tool(toolId), () =>
    service.getTool(toolId),
  ).data?.data

  const fetchedInspection = useData(inspectionId ? getterKeys.inspection(inspectionId) : undefined)

  // NOTE: Training Report can operate with a toolWithOverridesContext or a toolId, in most of the places across the app
  // we are able to provide a toolWithOverridesContext, expect for the reports for non active models in the training tab
  // as the overriden context does not exist for non active models.
  //
  // 1. If we provide toolWithOverridesContext, we will use this tool instance to get the thresholds.
  //   a. If the Tool is shared we can get the "global" threshold from its `tool_inferece_user_args` and the per view
  //   threshold if obtained from the fetched views
  //   b. If the tool is not shared, we can get the threshold from its `inference_user_args` as this will contain the overriden values
  // 2. If we don't provide a toolWithOVerridenContext we will fetch the tool based on the provided toolId prop.
  //   a. As we don't have the context for the overriden values, we will only be able to update the global threshold for these tools.
  const tool = toolWithOverridesContext || fetchedTool

  const [modalLoaded, setModalLoaded] = useState(false)
  const [loadingThreshold, setLoadingThreshold] = useState(false)
  const [testImagesSource, setTestImagesSource] = useState<TestImagesSource>(!inspectionId ? 'training' : 'inspection')
  const [trainingReportTab, setTrainingReportTab] = useState<TrainingReportTab>('all')
  const [selectedRoutineParentId, setSelectedRoutineParentId] = useState<string>()
  const [selectedComponentId, setSelectedComponentId] = useState<string>()

  const { insights_on } = params

  const [thresholdByView, setThresholdByView] = useState<ThresholdByViewData>()
  const [backendThresholdByRoutineParent, setBackendThresholdByRoutineParent] = useState<ThresholdByRoutineParentId>()

  // These states are necessary to show how the results change as the user moves around the threshold
  const [threshold, setThreshold] = useState<Threshold>()
  const [confusionMatrix, setConfusionMatrix] = useState<ConfusionMatrix>()
  const [showSidebar, setShowSidebar] = useState<boolean>(!!forceThreshold)

  const fetchingViewsRef = useRef(true)

  const modalThresholdRef = useRef<HTMLDivElement>(null)

  const { defaultLabels, allToolLabels } = useAllToolLabels({ ignoreDerivativeLabels: true })

  const labelsUsed = useMemo(() => {
    // If we know what the tool can predict, we only render those labels
    if (tool?.metadata.prediction_labels) {
      return getLabelsFromPredictionLabels(allToolLabels || [], tool.metadata.prediction_labels)
    }

    const labelsIdsUsed = confusionMatrix?.all_data_confusion && Object.keys(confusionMatrix.all_data_confusion)
    const labelsUsed = allToolLabels?.filter(label => {
      return labelsIdsUsed?.includes(label.id)
    })

    return labelsUsed
  }, [allToolLabels, confusionMatrix?.all_data_confusion, tool?.metadata.prediction_labels])

  const connectionStatus = useConnectionStatus()

  const trainingResultsFlat = useToolTrainingResults(tool?.id)

  const isTrainingReportV1 = !trainingResultsFlat?.[0]?.set_type

  const trainingResultsFiltered = useMemo(() => {
    // If training report modal opened from StationDetailTools, only show training results from the product that is currently being inspected
    if (inspectionId && fetchedInspection && !isTrainingReportV1)
      return trainingResultsFlat?.filter(
        trainingResult => trainingResult.component_id === fetchedInspection.component.id,
      )

    const filters: { [key in keyof TrainingResultFlat]?: TrainingResultFlat[key] | undefined } = {
      component_id: selectedComponentId,
      routine_parent_id: selectedRoutineParentId,
      set_type: trainingReportTab === 'all' ? undefined : trainingReportTab,
    }
    // Return early if no filters are applied
    if (Object.values(filters).every(value => !value)) return trainingResultsFlat

    return trainingResultsFlat?.filter(trainingResult => {
      return (Object.keys(filters) as Array<keyof TrainingResultFlat>).every(key => {
        return filters[key] ? filters[key] === trainingResult[key] : true
      })
    })
  }, [
    fetchedInspection,
    inspectionId,
    isTrainingReportV1,
    selectedComponentId,
    selectedRoutineParentId,
    trainingReportTab,
    trainingResultsFlat,
  ])

  const isTestSetEmpty = trainingReportTab === 'test' && !trainingResultsFiltered?.length

  const trainingMetrics = useTrainingMetrics(tool, trainingResultsFiltered, confusionMatrix, labelsUsed)

  const { componentIdsFromTrainingResults, routineParentIdsFromTrainingResults } = useMemo(() => {
    const routineParentIds = new Set<string>()
    const componentIds = new Set<string>()
    trainingResultsFlat?.forEach(trainingResult => {
      routineParentIds.add(trainingResult.routine_parent_id)
      componentIds.add(String(trainingResult.component_id))
    })

    return {
      routineParentIdsFromTrainingResults: [...routineParentIds],
      componentIdsFromTrainingResults: [...componentIds],
    }
  }, [trainingResultsFlat])

  const joinedRoutineParentIds = routineParentIdsFromTrainingResults.join()

  const routineParentsForFilters = useQuery(
    joinedRoutineParentIds ? getterKeys.toolParentRoutineParents(joinedRoutineParentIds) : undefined,
    () => service.getRoutineParents({ id__in: joinedRoutineParentIds }),
  ).data?.data.results

  const joinedComponentIds = componentIdsFromTrainingResults.filter(componentId => componentId !== 'null').join()

  const componentsForFilters = useQuery(
    joinedComponentIds ? getterKeys.toolParentComponents(joinedComponentIds) : undefined,
    () => service.getComponents({ id__in: joinedComponentIds }),
  ).data?.data.results

  const isSharedTool = tool?.is_shared

  const viewsRes = useQuery(
    tool && !viewInInspection ? getterKeys.toolParentRecipeRoutines(tool.parent_id) : undefined,
    async () => {
      fetchingViewsRef.current = true
      const res = await service.getRecipeRoutines({
        tool_parent_id: tool?.parent_id,
        is_working_version: true,
      })
      fetchingViewsRef.current = false
      return res
    },
  )

  const views = useMemo(() => {
    if (viewInInspection) return [viewInInspection]

    return viewsRes?.data?.data.results
  }, [viewInInspection, viewsRes?.data?.data.results])

  useEffect(() => {
    if (!tool?.specification_name) return
    const updatedThresholdByRoutine = Object.values(thresholdByView || {}).reduce((all, current) => {
      return { ...all, [current.routine.parent.id]: { ...current } }
    }, {} as ThresholdByRoutineParentId)

    setBackendThresholdByRoutineParent(updatedThresholdByRoutine)
  }, [thresholdByView, tool?.specification_name])

  // Update matrix when trainingResultsFiltered changes
  useEffect(() => {
    if (!trainingResultsFiltered || !tool?.specification_name || !threshold) return

    const matrix = computeConfusionMatrix(trainingResultsFiltered, tool.specification_name, {
      threshold,
      defaultLabels,
      allToolLabels,
      backendThresholdByRoutine: backendThresholdByRoutineParent,
    })

    setConfusionMatrix(matrix)
  }, [allToolLabels, backendThresholdByRoutineParent, defaultLabels, threshold, tool, trainingResultsFiltered])

  // This function recieves user facing threshold
  const handleChangeThreshold = useMemo(
    () =>
      debounce(
        (currentThreshold: UserFacingThreshold) => {
          if (!tool) return
          setLoadingThreshold(true)
          const toolThreshold = getThresholdFromUserFacingValues(currentThreshold, tool.specification_name)
          const matrixParams: {
            threshold: Threshold
            defaultLabels: ToolLabel[]
            allToolLabels: ToolLabel[] | undefined
          } = { threshold: toolThreshold, allToolLabels, defaultLabels: defaultLabels || [] }
          setThreshold(toolThreshold)

          // We don't want to fetch the confusion matrix if we're on live inspection mode
          if (testImagesSource === 'inspection' || !trainingResultsFiltered) return setLoadingThreshold(false)

          const matrix = computeConfusionMatrix(trainingResultsFiltered, tool?.specification_name, {
            ...matrixParams,
            backendThresholdByRoutine: backendThresholdByRoutineParent,
          })
          setConfusionMatrix(matrix)

          setLoadingThreshold(false)
        },
        500,
        // This option tells the debounce util to call the function when the first event is triggered, as well as after 500ms,
        // this makes the UX more snappy as otherwise it will wait 1s to send the command.
        // https://css-tricks.com/debouncing-throttling-explained-examples/#leading-edge-or-immediate
        { leading: true },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tool, defaultLabels, testImagesSource, inspectionId, trainingResultsFiltered, backendThresholdByRoutineParent],
  )

  const defaultThreshold = useMemo(() => {
    if (!tool) return

    if (modifiedThreshold !== undefined) return modifiedThreshold

    const toolToGetThresholdFrom = Object.assign({}, tool)
    // We check on the current tool to also validate if currentTool was provided
    if (toolWithOverridesContext?.is_shared) {
      toolToGetThresholdFrom.inference_user_args = toolWithOverridesContext.tool_inference_user_args
    }
    return getThresholdFromTool(toolToGetThresholdFrom)
  }, [modifiedThreshold, tool, toolWithOverridesContext?.is_shared, toolWithOverridesContext?.tool_inference_user_args])

  const resetThresholdData = useCallback(() => {
    if (!tool) return
    // We must cancel any left over calls on the debounce function when resetting
    handleChangeThreshold.cancel()

    if (defaultThreshold !== undefined) {
      handleChangeThreshold(defaultThreshold)
    }
  }, [tool, handleChangeThreshold, defaultThreshold])

  useEffect(() => {
    if (!defaultThreshold) return

    setThreshold(defaultThreshold)
  }, [defaultThreshold])

  const getPatchToolBody = useCallback(
    ({
      inferenceUserArgs,
      aoisUpdates,
      aoisThresholdMetadata,
      isToolShared,
    }: {
      inferenceUserArgs: Tool['inference_user_args']
      aoisUpdates: AoiUpdate[]
      aoisThresholdMetadata: ToolMetadata['aoi_thresholds']
      isToolShared?: boolean
    }): PatchProtectedToolBody => {
      const patchToolBody: PatchProtectedToolBody = {
        aois: aoisUpdates,
        metadata: {
          ...tool?.metadata,
          training_metrics: trainingMetrics,
        },
      }

      // A toolWithOverridesContext can only exist if it is the active model, so,
      // if it is not provided, we can infer that it is not the active model
      const isCurrentModelActive = !!toolWithOverridesContext

      // We only update the global threshold if the tool is shared or if the tool is not active
      const shouldUpdateGlobalInferenceUserArgs = isToolShared || !isCurrentModelActive

      if (shouldUpdateGlobalInferenceUserArgs) {
        patchToolBody.inference_user_args = inferenceUserArgs
      }

      if (isToolShared) {
        patchToolBody.metadata!.aoi_thresholds = { ...aoisThresholdMetadata }
      } else {
        patchToolBody.metadata!.aoi_thresholds = {}
      }

      return patchToolBody
    },
    [tool?.metadata, toolWithOverridesContext, trainingMetrics],
  )

  // This function recieves user facing threshold
  const handleSaveThreshold = useCallback(
    async (
      userFacingThreshold: UserFacingThreshold,
      aoisUpdates: AoiUpdate[],
      aoisThresholdMetadata: ToolMetadata['aoi_thresholds'],
    ) => {
      if (!tool) return
      // We must copy the object, otherwise the threshold would be assigned to the current tool
      // It's correct to use tool.inference_user_args here because we're talking about the global threshold.
      // This is the only place in the app where we can look directly at a tool's inference_user_args without it having
      // gone through a serializer that would've placed the overrides on top.
      const inferenceUserArgs = tool.tool_inference_user_args || tool.inference_user_args
      const toolThreshold = getThresholdFromUserFacingValues(userFacingThreshold, tool.specification_name)
      if (isThresholdFromGARTool(toolThreshold)) {
        inferenceUserArgs.lower_threshold = toolThreshold.lowerThreshold
        inferenceUserArgs.upper_threshold = toolThreshold.upperThreshold
      } else {
        inferenceUserArgs.threshold = toolThreshold.threshold
      }

      // We can send a `onSaveThreshold` callback to this function if we wish to do something different from storing the threshold in the
      // tool when clicking on save. This is necessary for the configure screen, where we don't want to save the threshold yet, but
      // rather apply the threshold value to the tool being edited.
      if (onSaveThreshold) {
        // If we are editing an specific view, we send that view threshold to the callback
        const thresholdForCallback =
          tool.is_shared && viewInInspection
            ? thresholdByView?.[viewInInspection.routine.parent.id]?.threshold
            : toolThreshold
        if (isNil(thresholdForCallback)) return
        return onSaveThreshold(thresholdForCallback, trainingMetrics, aoisUpdates)
      }

      const patchToolBody = getPatchToolBody({
        inferenceUserArgs,
        aoisUpdates,
        aoisThresholdMetadata,
        isToolShared: tool.is_shared,
      })

      const res = await service.patchProtectedTool(tool?.id, patchToolBody)

      if (res.type === 'success') {
        fetchingViewsRef.current = true
        const refreshPromises: Promise<any>[] = []
        if (!toolWithOverridesContext) {
          refreshPromises.push(query(getterKeys.tool(toolId), () => service.getTool(toolId), { dispatch }))
        }
        refreshPromises.push(
          query(
            getterKeys.toolParentRecipeRoutines(tool.parent_id),
            async () => {
              const res = await service.getRecipeRoutines({
                tool_parent_id: tool?.parent_id,
                is_working_version: true,
              })
              return res
            },
            { dispatch },
          ),
        )

        await Promise.all(refreshPromises)

        await onSaveThresholdSuccess?.()
        fetchingViewsRef.current = false
        success({ 'data-testid': 'threshold-saved-success-message', title: 'Threshold updated' })
      } else {
        error({ title: 'Error saving threshold, please try again later' })
      }
      if (forceThreshold && onCancelThreshold) onCancelThreshold()
    },
    [
      dispatch,
      forceThreshold,
      getPatchToolBody,
      onCancelThreshold,
      onSaveThreshold,
      onSaveThresholdSuccess,
      thresholdByView,
      tool,
      toolId,
      toolWithOverridesContext,
      trainingMetrics,
      viewInInspection,
    ],
  )

  const showCurrentBatchCarousels = testImagesSource === 'inspection' && !!inspectionId && threshold !== undefined

  const someViewThresholdOverriden = useMemo(() => {
    if (!thresholdByView) return false
    return Object.values(thresholdByView).some(({ overriden }) => overriden)
  }, [thresholdByView])

  const thresholdText = useMemo(() => {
    if (!tool) return '--'
    if (tool.is_shared && someViewThresholdOverriden) return 'By View'
    if (threshold === undefined) return '--'

    if (isThresholdFromGARTool(threshold))
      return `${isNil(threshold.userFacingLowerThreshold) ? '--' : threshold.userFacingLowerThreshold} - ${
        isNil(threshold.userFacingUpperThreshold) ? '--' : threshold.userFacingUpperThreshold
      }`
    return threshold.userFacingThreshold?.toString() || '--'
  }, [tool, someViewThresholdOverriden, threshold])

  const recommendedValue = getUserFacingRecommendedValueFromTool(tool)

  useEffect(() => {
    if ((!viewInInspection && fetchingViewsRef.current) || !tool || !views) return

    setThresholdByView(getInitialThresholdByView(tool, views, modifiedThreshold))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool, views])

  const thresholdForLiveCarousels = useMemo(() => {
    if (isSharedTool && viewInInspection && backendThresholdByRoutineParent)
      return backendThresholdByRoutineParent[viewInInspection.routine.parent.id]?.threshold

    return threshold
  }, [backendThresholdByRoutineParent, viewInInspection, isSharedTool, threshold])

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const setInsightsEnabled = (enabled: boolean) => {
    appendDataToQueryString(history, { insights_on: enabled || undefined })
  }

  const TestSetEmptyState = () => {
    return (
      <GenericBlankStateMessage
        header={<PrismInfoIcon />}
        title="Label your test set"
        description={
          <>
            <span>
              A test set is meant to represent new, unseen data that a model will encounter in real-world scenarios.
            </span>
            <br />
            <br />
            <span>
              Label images with a standard label and a Test Set label. Then train a model to see how it performs.
            </span>
          </>
        }
        className={Styles.testSetEmptyMessage}
      >
        <Button
          size="small"
          className={Styles.labelButton}
          onClick={() => {
            if (!tool?.parent_id) return
            history.push(
              paths.labelingScreen(tool.parent_id, 'gallery', {
                user_label_set_isnull: true,
                is_deleted: false,
                ordering: '-created_at',
              }),
            )
          }}
        >
          Label
        </Button>
      </GenericBlankStateMessage>
    )
  }

  return (
    <PrismModal
      id="training-report"
      onModalLoad={() => setModalLoaded(true)}
      onClose={handleClose}
      size="wide"
      className={Styles.trainingReportModal}
    >
      <ModalHeader onClose={onClose} className={Styles.trainingReportHeader}>
        <div className={Styles.trainingReportModalHeader}>
          <div className={Styles.modalTitleWithIcon} data-testid="training-modal-header-title">
            <PrismOverflowTooltip
              content={
                forceTitle || `${tool?.version ? `Model ${tool?.version} of ` : ''} ${tool?.parent_name || ''}` || '--'
              }
              className={Styles.modalTitle}
            />

            {isSharedTool && <PrismSharedToolIcon className={Styles.modalTitleIcon} />}
          </div>

          <TrainingReportFilters
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            inspectionId={inspectionId}
            isSharedTool={isSharedTool}
            testImagesSource={testImagesSource}
            setTestImagesSource={setTestImagesSource}
            tool={tool}
            selectedRoutineParentId={selectedRoutineParentId}
            setSelectedRoutineParentId={setSelectedRoutineParentId}
            selectedComponentId={selectedComponentId}
            setSelectedComponentId={setSelectedComponentId}
            routineParentsForFilters={routineParentsForFilters}
            componentsForFilters={componentsForFilters}
            trainingReportTab={trainingReportTab}
            setTrainingReportTab={setTrainingReportTab}
            isTrainingReportV1={isTrainingReportV1}
            connectionStatus={connectionStatus}
            insightsEnabled={!!insights_on}
            setInsightsEnabled={setInsightsEnabled}
            thresholdText={thresholdText}
          />
        </div>
      </ModalHeader>
      <ModalBody className={Styles.trainingReportBody}>
        <aside
          className={`${Styles.thresholdSidebar} ${(showSidebar || forceThreshold) && tool ? Styles.expanded : ''}`}
        >
          {(showSidebar || forceThreshold) && tool && (
            <ThresholdSideBar
              recommendedValue={recommendedValue}
              thresholdButtonText={thresholdButtonText}
              specificationName={tool.specification_name}
              initialValue={threshold}
              loading={loadingThreshold}
              onCancel={() => {
                resetThresholdData()
                setThresholdByView(getInitialThresholdByView(tool, views || [], modifiedThreshold))
                if (onCancelThreshold) onCancelThreshold()
                else {
                  setShowSidebar(false)
                }
              }}
              onChange={handleChangeThreshold}
              onSave={handleSaveThreshold}
              isSharedTool={isSharedTool}
              toolVersion={tool.version || undefined}
              thresholdByView={thresholdByView}
              setThresholdByView={setThresholdByView}
              views={viewInInspection ? [viewInInspection] : views}
              someViewThresholdOverriden={someViewThresholdOverriden}
              forcedView={!!isSharedTool && !!viewInInspection}
              toolId={tool.id}
            />
          )}
        </aside>

        <div className={Styles.main} ref={modalThresholdRef}>
          {!isTestSetEmpty && (
            <>
              <TrainingReportMetrics
                testImagesSource={testImagesSource}
                tool={tool}
                trainingMetrics={trainingMetrics}
              />

              <TrainingReportSamples
                tool={tool}
                trainingReportTab={trainingReportTab}
                showCurrentBatchCarousels={showCurrentBatchCarousels}
                connectionStatus={connectionStatus}
                thresholdForLiveCarousels={thresholdForLiveCarousels}
                inspectionId={inspectionId}
                aoiParentId={aoiParentId}
                components={componentsForFilters}
                trainingResults={trainingResultsFiltered}
                containerRef={modalThresholdRef}
                allToolLabels={allToolLabels}
                threshold={threshold}
                loadingThreshold={loadingThreshold}
                labelsUsed={labelsUsed}
                insightsEnabled={!!insights_on}
                backendThresholdByRoutine={backendThresholdByRoutineParent}
                trainingMetricsByLabelAndComponent={trainingMetrics?.['byLabelId']}
                modalLoaded={modalLoaded}
              />
            </>
          )}
          {isTestSetEmpty && <TestSetEmptyState />}
        </div>
      </ModalBody>
    </PrismModal>
  )
}

export const getInitialThresholdByView = (
  tool: Tool,
  views: RecipeRoutineExpanded[],
  modifiedThreshold?: Threshold,
) => {
  const initialThresholdByView: ThresholdByViewData = {}
  views.forEach(view => {
    const { tools } = getAoisAndToolsFromRoutine(view.routine)

    const sharedTool = tools.find(currentTool => currentTool.parent_id === tool.parent_id)
    if (!sharedTool) return
    const aois = getToolAoisFromRoutine(view.routine, sharedTool.id)
    const aoiParentId = aois[0]?.parent?.id

    if (!aoiParentId) return

    const common = {
      aoiParentId,
      aois,
      tool: sharedTool,
      routine: view.routine,
    }
    // If tool is active, we grab the thresholds from the inference_user_args of the routine.aoi.tool and compare them to the `global` tool_inference_user_args
    if (sharedTool.version === tool?.version) {
      // In case we are testing a modified Threshold, we use that instead of the one we have on routine.aoi.tool
      const thresholdToUse = modifiedThreshold || getThresholdFromTool(sharedTool)
      if (thresholdToUse)
        initialThresholdByView[view.routine.parent.id] = {
          threshold: thresholdToUse,
          // If a tool is NOT shared, we should never consider the threshold as overriden
          overriden: tool.is_shared
            ? isThresholdOverriden(
                tool.specification_name,
                sharedTool.inference_user_args,
                sharedTool.tool_inference_user_args,
              )
            : false,
          ...common,
        }
      return
    }

    // Otherwise, we default to the thresholds we have stored on the tool.metadata.aoi_thresholds
    const metadataThreshold = getThresholdFromToolAoiThresholdMetadata(tool, aoiParentId)
    const inferenceUserArgs = tool.tool_inference_user_args || tool.inference_user_args

    if (!isNil(metadataThreshold)) {
      initialThresholdByView[view.routine.parent.id] = {
        threshold: metadataThreshold,
        overriden: isThresholdOverriden(
          tool.specification_name,
          inferenceUserArgs,
          tool.metadata.aoi_thresholds?.[aoiParentId]?.user_override,
        ),
        ...common,
      }
      return
    }

    const toolToGetThreshold = { ...tool }
    toolToGetThreshold.inference_user_args = inferenceUserArgs
    const currentToolThreshold = getThresholdFromTool(toolToGetThreshold)
    if (isNil(currentToolThreshold)) return

    initialThresholdByView[view.routine.parent.id] = {
      threshold: currentToolThreshold,
      overriden: false,
      ...common,
    }
  })

  return initialThresholdByView
}

const isThresholdOverriden = (
  specificationName: ToolSpecificationName,
  inferenceArgsA?: Tool['inference_user_args'],
  inferenceArgsB?: Tool['inference_user_args'],
) => {
  if (specificationName === 'graded-anomaly') {
    return (
      inferenceArgsA?.lower_threshold !== inferenceArgsB?.lower_threshold ||
      inferenceArgsA?.upper_threshold !== inferenceArgsB?.upper_threshold
    )
  }

  return inferenceArgsA?.threshold !== inferenceArgsB?.threshold
}

export const MemoTrainingReport = React.memo(TrainingReport)
