import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Settings Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=67%3A2
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismSettingsIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.settingsIcon}  ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M12 17.8182H21.8808" strokeLinecap="round" className={Styles.line} />
      <path d="M2 17.8182H7.38462" className={Styles.line} strokeLinecap="round" />
      <ellipse cx="9.69246" cy="17.8182" rx="2.30769" ry="2.18182" className={Styles.circle} />
      <path
        d="M2 11.5H1.5V12.5H2V11.5ZM17.3846 12.5H17.8846V11.5H17.3846V12.5ZM2 12.5H2.48077V11.5H2V12.5ZM3.44231 12.5H4.40385V11.5H3.44231V12.5ZM5.36538 12.5H6.32692V11.5H5.36538V12.5ZM7.28846 12.5H8.25V11.5H7.28846V12.5ZM9.21154 12.5H10.1731V11.5H9.21154V12.5ZM11.1346 12.5H12.0962V11.5H11.1346V12.5ZM13.0577 12.5H14.0192V11.5H13.0577V12.5ZM14.9808 12.5H15.9423V11.5H14.9808V12.5ZM16.9038 12.5H17.3846V11.5H16.9038V12.5Z"
        className={Styles.dashes}
      />
      <ellipse cx="19.6925" cy="12" rx="2.30769" ry="2.18182" className={Styles.circle} />
      <path d="M6.61548 6.18182H21.8809" strokeLinecap="round" className={Styles.line} />
      <ellipse cx="4.30769" cy="6.18182" rx="2.30769" ry="2.18182" className={Styles.circle} />
    </svg>
  )
}
