import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react'

import { Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { query, useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, ToolParentsWithAoiData } from 'api'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { useImageElement, useOnScreen, usePagination } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { AreaOfInterest, RecipeExpanded, RoutineWithAois, ToolParentWithAoi } from 'types'
import {
  getAoisAndToolsFromRoutine,
  getterAddPage,
  getTimeAgoFromDate,
  getToolParentMostRecentValidTool,
  isRecipeOrRoutineResponseProtected,
  titleCase,
} from 'utils'
import { TRAINABLE_TOOL_SPECIFICATION_NAMES } from 'utils/constants'

import Styles from './Configure.module.scss'

type ToolParentWithRoutineCountAndSharedStatus = ToolParentWithAoi & {
  isAlreadyInUse: boolean
  recipesCount: number
}

interface ExistingToolsProps {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  modalRef: RefObject<HTMLDivElement>
}

const EXISTING_TOOL_PARENTS_PARAMS = { spec_name__in: TRAINABLE_TOOL_SPECIFICATION_NAMES.join(), ordering: '-added_at' }

/**
 * Renders the section to select an existing Tool to add to the Routine.
 *
 * @param routine - The current Routine
 * @param recipe - The current Recipe
 */
const ExistingTools = ({ routine, recipe, modalRef }: ExistingToolsProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [toolParentSearchValue, setToolParentSearchValue] = useState('')
  const [selectedProductId, setSelectedProductId] = useState('')
  const [selectedRecipeParentId, setSelectedRecipeParentId] = useState('')
  const [loadingToolParents, setLoadingToolParents] = useState(false)

  const loadingMoreToolParentsRef = useRef(false)

  const toolParentsWithAoiRes = useQuery(getterKeys.toolParentsWithAoi(), () =>
    service.getToolParents(EXISTING_TOOL_PARENTS_PARAMS),
  ).data
  const toolParentsWithAoi = useMemo(() => toolParentsWithAoiRes?.data.results, [toolParentsWithAoiRes])
  const nextToolParentsPage = toolParentsWithAoiRes?.data.next

  const toolParentsCountRes = useQuery(getterKeys.toolParentsCount(), () => service.countToolParents())
  const toolParentsCount = useMemo(() => toolParentsCountRes.data?.data.results, [toolParentsCountRes])

  // this image has already been fetched in the GoldenImage component, so this will be instantaneous
  const [imageElement] = useImageElement(routine.image)

  const routineToolIds = useMemo(() => {
    const { tools } = getAoisAndToolsFromRoutine(routine)
    return tools.map(tool => tool.id)
  }, [routine])

  const handleEndReached = useCallback(async () => {
    if (loadingMoreToolParentsRef.current || !nextToolParentsPage) return

    loadingMoreToolParentsRef.current = true

    const nextPageRes = await service.getNextPage<ToolParentsWithAoiData>(nextToolParentsPage)
    if (nextPageRes.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.toolParentsWithAoi(),
          updater: prevRes => getterAddPage(prevRes, nextPageRes.data),
        }),
      )
    }
    loadingMoreToolParentsRef.current = false
  }, [dispatch, nextToolParentsPage])

  usePagination(handleEndReached, {
    node: modalRef.current?.querySelector('.ant-table-body') || null,
    overflowScroll: true,
  })

  // This funcion is used to refetch result immediately after an option from a Select is selected, so there's no need to debounce it
  const refetchToolParents = useCallback(
    async (search?: string, productId?: string, recipeParentId?: string) => {
      setLoadingToolParents(true)
      await query(
        getterKeys.toolParentsWithAoi(),
        () =>
          service.getToolParents({
            name: search || undefined,
            recipe_parent_id: recipeParentId || undefined,
            product_id: productId || undefined,
            ...EXISTING_TOOL_PARENTS_PARAMS,
          }),
        { dispatch },
      )
      setLoadingToolParents(false)
    },
    [dispatch],
  )

  // This funcion is used when SearchInput changes, we need to debounce it to avoid multiple service calls
  const refetchToolParentsDebounced = useMemo(
    () =>
      debounce(async (search?: string, productId?: string, recipeId?: string) => {
        refetchToolParents(search, productId, recipeId)
      }, 500),
    [refetchToolParents],
  )

  const toolParentsWithRoutinesCountAndSharedStatus = useMemo<
    ToolParentWithRoutineCountAndSharedStatus[] | undefined
  >(() => {
    return toolParentsWithAoi?.map(toolParent => {
      const count = toolParentsCount?.find(entry => entry.tool_parent_id === toolParent.id)?.count || 0
      return {
        ...toolParent,
        recipesCount: count,
        isAlreadyInUse: toolParent.tools.some(tool => routineToolIds.includes(tool.id)),
      }
    })
  }, [routineToolIds, toolParentsWithAoi, toolParentsCount])

  const addExistingTool = async (
    toolId: string,
    recipe: RecipeExpanded,
    routine: RoutineWithAois,
    newRecipeCreated?: boolean,
  ) => {
    if (!imageElement) {
      return error({ title: 'Take a reference photo to add this tool' })
    }
    const aoi = {
      x: Math.round(imageElement.width / 4),
      y: Math.round(imageElement.height / 4),
      width: Math.round(imageElement.width / 2),
      height: Math.round(imageElement.height / 2),
      routine_id: routine.id,
      parent_name: 'Area',
      img_w: imageElement.width,
      img_h: imageElement.height,
      tool_ids: [toolId],
    }

    const aoiRes = await service.createAoi(aoi)
    if (
      isRecipeOrRoutineResponseProtected(aoiRes, {
        routineParentId: routine.parent.id,
        recipe,
        history,
        onCreate: async (createdRecipe, routine) => {
          if (!routine) return
          await addExistingTool(toolId, createdRecipe, routine, true)
        },
      })
    ) {
      return
    }

    if (aoiRes.type !== 'success')
      return error({ title: 'An error occurred while adding the Tool, please try again later.' })

    if (!newRecipeCreated) {
      const routineRes = await query(getterKeys.routine(routine.id), () => service.getRoutine(routine.id), { dispatch })

      if (routineRes?.type !== 'success') return error({ title: 'An error occurred' })
    }
    success({ title: 'Tool added', 'data-testid': 'tool-creation-modal-success', duration: 100 })

    history.push(paths.settingsTool(recipe.id, toolId, { routineParentId: routine.parent.id }))
  }

  return (
    <div className={Styles.existingModalBody}>
      <section className={Styles.filtersWrapper}>
        <PrismSearchInput
          size="small"
          name="search"
          placeholder="Search"
          onInputChange={e => {
            const newValue = e.target.value
            setToolParentSearchValue(newValue)
            refetchToolParentsDebounced(newValue, selectedProductId, selectedRecipeParentId)
          }}
          className={Styles.searchIconSize}
          value={toolParentSearchValue}
          showIconPrefix
        />

        <SearchableSelect
          placeholder="product"
          getterKey={getterKeys.metricsFilteredComponents()}
          fetcher={searchValue => service.getComponents({ name: searchValue })}
          missingOptionFetcher={id => service.getComponent(id)}
          formatter={product => product.name}
          value={selectedProductId || null}
          onSelect={newProductId => {
            refetchToolParents(toolParentSearchValue, newProductId, selectedRecipeParentId)
            setSelectedProductId(newProductId)
          }}
        />

        <SearchableSelect
          placeholder="recipe"
          getterKey={getterKeys.recipeParentsFilterOptions('add-existing-tools')}
          fetcher={searchValue =>
            service.getRecipeParents({ name: searchValue || undefined, component_id: selectedProductId || undefined })
          }
          formatter={recipeParent => recipeParent.name}
          value={selectedRecipeParentId || null}
          onSelect={newRecipeParentId => {
            refetchToolParents(toolParentSearchValue, selectedProductId, newRecipeParentId)
            setSelectedRecipeParentId(newRecipeParentId)
          }}
          queryOptions={{ refetchKey: selectedProductId }}
        />
      </section>

      <section className={Styles.tableWrapper}>
        <MemoToolParentsTable
          toolParentsWithRoutinesCountAndSharedStatus={toolParentsWithRoutinesCountAndSharedStatus}
          spinning={!toolParentsWithAoi || loadingToolParents}
          addExistingTool={addExistingTool}
          routine={routine}
          recipe={recipe}
        />
      </section>
    </div>
  )
}

const ToolParentsTable = ({
  toolParentsWithRoutinesCountAndSharedStatus,
  spinning,
  addExistingTool,
  routine,
  recipe,
}: {
  toolParentsWithRoutinesCountAndSharedStatus?: ToolParentWithRoutineCountAndSharedStatus[]
  spinning: boolean
  addExistingTool: (toolId: string, recipe: RecipeExpanded, routine: RoutineWithAois) => Promise<void>
  routine: RoutineWithAois
  recipe: RecipeExpanded
}) => {
  const [addingExitingTool, setAddingExistingTool] = useState(false)
  return (
    <Table
      rowKey={toolParent => toolParent.id}
      className={Styles.tableContainer}
      rowClassName={(toolParent: ToolParentWithRoutineCountAndSharedStatus) => {
        return `${Styles.tableRow} ${toolParent.isAlreadyInUse ? Styles.disabledRow : ''}`
      }}
      columns={columns}
      dataSource={toolParentsWithRoutinesCountAndSharedStatus}
      pagination={false}
      loading={{
        spinning: spinning || addingExitingTool,
        indicator: <PrismLoader className={Styles.tableLoader} />,
      }}
      onRow={toolParent => {
        return {
          onClick: async () => {
            if (toolParent.isAlreadyInUse) return

            setAddingExistingTool(true)
            // We need to fetch the full tool parent to get the experiments list
            const toolParentRes = await service.getToolParent(toolParent.id)
            if (toolParentRes.type !== 'success') {
              setAddingExistingTool(false)
              return error({ title: 'An error occurred while adding the tool, try again later.' })
            }

            const fetchedToolParent = toolParentRes.data

            const mostRecentValidModel = getToolParentMostRecentValidTool(fetchedToolParent)

            if (!mostRecentValidModel) {
              setAddingExistingTool(false)
              return error({ title: 'An error occurred while adding the tool, try again later.' })
            }

            await addExistingTool(mostRecentValidModel.id, recipe, routine)
            setAddingExistingTool(false)
          },
          isAlreadyInUse: toolParent.isAlreadyInUse,
        }
      }}
      components={{
        body: {
          // Antd does not provide an interface for the rest of the props
          row: ({ isAlreadyInUse, ...rest }: { isAlreadyInUse: boolean }) => (
            <ConditionalWrapper
              condition={!!isAlreadyInUse}
              wrapper={children => <Tooltip title="This tool is already in use">{children}</Tooltip>}
            >
              <tr {...rest} />
            </ConditionalWrapper>
          ),
        },
      }}
      sticky={{
        offsetHeader: 0,
      }}
    />
  )
}

const MemoToolParentsTable = React.memo(ToolParentsTable, (prev, next) => {
  // We need to do a deep compare to avoid unnecessary rerenders, otherwise the whole table is rerendered everytime
  const prevJson = JSON.stringify(prev)
  const nextJson = JSON.stringify(next)
  const jsonEqual = prevJson === nextJson
  return jsonEqual
})

const ToolParentTitle = ({
  routineImage,
  routineThumbnail,
  name,
  aoi,
}: {
  routineImage?: string
  routineThumbnail?: string
  name: string
  aoi?: AreaOfInterest
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const onScreen = useOnScreen(ref)

  return (
    <div ref={ref} className={Styles.toolColumn}>
      <figure className={Styles.toolColumnImageContainer}>
        {onScreen && aoi ? (
          <ImageCloseUp src={routineImage} thumbnailSrc={routineThumbnail} region={aoi} loaderType="skeleton" />
        ) : (
          <PrismElementaryCube />
        )}
      </figure>
      <PrismOverflowTooltip content={name} />
    </div>
  )
}

const MemoToolParentTitle = React.memo(ToolParentTitle)

const columns: ColumnsType<ToolParentWithRoutineCountAndSharedStatus> = [
  {
    key: 'title',
    title: 'Tool',

    render: (_, toolParent) => {
      return (
        <MemoToolParentTitle
          routineImage={toolParent.aoi?.routine_image}
          routineThumbnail={toolParent.aoi?.routine_image_thumbnail}
          name={toolParent.name}
          aoi={toolParent.aoi}
        />
      )
    },
  },
  {
    key: 'last-added',
    title: 'Last Added',
    dataIndex: 'added_at',

    render: addedAt => {
      return (
        <div className={Styles.dateColumn}>{addedAt ? titleCase(getTimeAgoFromDate(addedAt).text, true) : '--'}</div>
      )
    },
  },
  {
    key: 'trained',
    title: 'Trained',
    dataIndex: 'trained_at',

    render: trainedAt => {
      return (
        <div className={Styles.dateColumn}>
          {trainedAt ? titleCase(getTimeAgoFromDate(trainedAt).text, true) : '--'}
        </div>
      )
    },
  },
  {
    key: 'recipes',
    title: 'Recipes',
    dataIndex: 'recipesCount',
  },
]

export default ExistingTools
