import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'

import { batch, useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { wsKeys, wsPaths } from 'api'
import StreamListener from 'components/StreamListener'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useInterval } from 'hooks'
import * as Actions from 'rdx/actions'
import { StreamDescriptor, VpnHeartbeatStreamMessage } from 'types'
import { getStreamReadMessage } from 'utils'
import { UPDATE_MESSAGES_READ_INTERVA_MS } from 'utils/constants'

export interface Props {
  robotIds: string[]
}

/**
 * Renders nothing. Listens to VPN events and heartbeats for robots with
 * robotIds.
 *
 * NOTE: don't render this by itself. Just render RobotsStatusListener, which
 * renders this.
 *
 * @param robotIds - Robot ids
 * @param onMessages - Called with new messages
 */
export function RobotsVpnListener({ robotIds }: Props) {
  const dispatch = useDispatch()

  const messagesReadByRobotIdRef = useRef<Record<string, boolean>>({})

  // Even if client code is bad and robotIds change shape, this component won't need to reconnect to the websockets
  const robotIdsKey = robotIds.sort().join()

  const streams = useMemo(() => {
    const streams: StreamDescriptor[] = []
    for (const id of robotIds) {
      streams.push({ from_iot_client: true, stream: 'heartbeat', robot_id: id, last_n: 1 })
    }
    return streams
  }, [robotIdsKey]) // eslint-disable-line

  // This effect is in charge of setting a "vpn status stream listening started" message for each robot
  useEffect(() => {
    const listeningStartedMessage = getStreamReadMessage('listening')
    robotIds.forEach(robotId => {
      dispatch(
        Actions.event({
          key: wsKeys.streamListening(robotId, 'vpnStatus'),
          messages: [listeningStartedMessage],
        }),
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robotIdsKey])

  // If messages haven't yet been read in a while from VPN stream, we can mark robot state as loading instead of disconnected if our last heartbeat for robot is stale
  // This guarantees that we periodically rerun selectors and can mark camera as offline in case it "loses" heartbeat in real time; it also guarantees we know if we've recently read from VPN streams for a given robot
  const updateMessagesRead = useCallback(() => {
    batch(() => {
      for (const robotId of robotIds) {
        const readMessage = getStreamReadMessage('read')
        if (messagesReadByRobotIdRef.current[robotId]) {
          dispatch(
            Actions.event({
              key: wsKeys.streamRead(robotId, 'vpnStatus'),
              messages: [readMessage],
            }),
          )
        }
      }
    })
  }, [robotIdsKey]) // eslint-disable-line

  useInterval(updateMessagesRead, UPDATE_MESSAGES_READ_INTERVA_MS)

  if (streams.length === 0) return null
  return (
    <StreamListener
      mode="message"
      connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.multiStreamOrganization()}` }}
      onMessages={messages => {
        handleMessages(messages, messagesReadByRobotIdRef, dispatch)
      }}
      streams={streams}
      params={{ deserialization: 'none' }}
    />
  )
}

export function handleMessages(
  messages: VpnHeartbeatStreamMessage[],
  messagesReadByRobotIdRef: MutableRefObject<Record<string, boolean>>,
  dispatch: Dispatch,
) {
  if (messages.length === 0) return

  // Results in only one rerender: https://react-redux.js.org/api/batch
  batch(() => {
    for (const message of messages) {
      if (!message.meta) continue

      const robotId = message.meta.robot_id

      if (message.meta.stream === 'heartbeat') {
        if (!messagesReadByRobotIdRef.current[robotId]) {
          messagesReadByRobotIdRef.current[robotId] = true
        }

        dispatch(Actions.event({ key: wsKeys.vpnHeartbeat(robotId), messages: [message] }))
      }
    }
  })
}
