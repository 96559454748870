import React, { ButtonHTMLAttributes, useCallback, useContext, useMemo } from 'react'

import { Button } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { ModalContext } from 'components/PrismModal/PrismModal'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useHotkeyPress, useTypedSelector } from 'hooks'

import Styles from './ToggleButton.module.scss'

/**
 * Renders a toggle button component
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4484%3A11730
 *
 * @param title - button title
 * @param hotkey - in case the button needs a hotkey
 * @param type - changes the looks of the button
 * @param size - the height of the toggle button
 * @param className - string class that will be passed to the div container
 * @param disabled - it prevents button from being clicked
 * @param onClick - click event
 * @param isOnTop - adds a different background color on hover to prevent transparency
 * @param allowedModalIds - If the last opened modal is included in this param, we allow the
 * execution of the hotkey
 */

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'> {
  title: string
  hotkey?: React.ReactNode | string
  hiddenHotkeys?: string[]
  className?: string
  size?: 'xsmall' | 'small'
  disabled?: boolean
  tooltip?: string
  active?: boolean
  isOnTop?: boolean
  onClick?: () => void
  allowedModalIds?: string[]
  childrenClassName?: string
}

export const ToggleButton = ({
  title,
  hotkey,
  hiddenHotkeys,
  size = 'small',
  disabled = false,
  className,
  tooltip,
  active,
  onClick,
  isOnTop,
  allowedModalIds,
  childrenClassName = '',
  ...rest
}: Props) => {
  const shouldPreventHotkeyTrigger = useShouldPreventHotkeyTrigger({ allowedModalIds })

  const keyboardHandler = useCallback(
    (e: KeyboardEvent) => {
      let hotkeys: string[] = []
      if (typeof hotkey === 'string') hotkeys.push(hotkey)
      if (hiddenHotkeys) hotkeys = [...hotkeys, ...hiddenHotkeys]
      if (disabled || !hotkeys.length) return
      // We must check that the either the hotkey matches exactly, its uppercase, or lowercase match, so that we can send
      //  ArrowUp, ArrowDown, "I", "i", and have the handler work
      if (hotkeys.includes(e.key) || hotkeys.includes(e.key.toUpperCase()) || hotkeys.includes(e.key.toLowerCase())) {
        e.preventDefault()

        if (shouldPreventHotkeyTrigger) return

        onClick?.()
      }
    },
    [disabled, hiddenHotkeys, hotkey, onClick, shouldPreventHotkeyTrigger],
  )

  useHotkeyPress(keyboardHandler)

  return (
    <ConditionalWrapper
      condition={typeof tooltip === 'string'}
      wrapper={ch => {
        return (
          <PrismTooltip title={title} anchorClassName={Styles.tooltipChildren}>
            {ch}
          </PrismTooltip>
        )
      }}
    >
      <Button
        {...rest}
        type="default"
        onClick={onClick}
        disabled={disabled}
        className={`${Styles.labelContainer} ${isOnTop ? Styles.isOnTop : ''} ${hotkey ? Styles.hotkeyContainer : ''} ${
          className ?? ''
        } ${size === 'xsmall' ? Styles.xsmall : ''} ${active ? Styles.buttonIsActive : ''} ${
          disabled ? Styles.disabled : ''
        }`}
        badge={hotkey ? <div className={Styles.labelHotkey}>{hotkey}</div> : undefined}
        invertBadgePosition
        badgeClassName={Styles.badge}
        childrenClassName={childrenClassName}
      >
        <div className={Styles.labelName}>{title}</div>
      </Button>
    </ConditionalWrapper>
  )
}

export const useShouldPreventHotkeyTrigger = ({ allowedModalIds }: { allowedModalIds?: string[] } = {}) => {
  const modals = useTypedSelector(state => state.modals)
  const lastOpenedModal = modals[0]
  const currentModal = useContext(ModalContext).id

  const preventHotkeyTrigger = useMemo(() => {
    if (lastOpenedModal && allowedModalIds?.includes(lastOpenedModal)) {
      return false
    }

    if (!currentModal && lastOpenedModal) return true

    if (lastOpenedModal !== currentModal) return true

    return false
  }, [allowedModalIds, currentModal, lastOpenedModal])

  return preventHotkeyTrigger
}
