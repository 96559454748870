import React, { useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { getterKeys } from 'api'
import { useData, usePrevious, useQueryParams } from 'hooks'
import { ToolSpecificationName } from 'types'
import { getToolResultsBranch } from 'utils'

import { useIsCarouselOnScreen } from './GroupCarousel'
import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import LabelingGroupWrapper, { renderToolResultsCount } from './LabelingGroupWrapper'
import { fetchSmartGroupToolResults, SmartGroup, useFilteredSmartGroupToolResults } from './SmartGroups'

interface Props {
  smartGroup: SmartGroup
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  showInsights: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  outerContainerRef: React.RefObject<HTMLElement>
  smartGroupId: string
  groupIndex: number
  handleShowGallery: (groupId?: string, groupIdx?: number) => void
  expandedGroupId?: string
  onHideNextCarousels: (shouldHide: boolean) => void
  'data-test'?: string
}

/**
 * Smart groups carousel, renders a LabelingGroupWrapper and is in charge of fetching the smart group's toolResults.
 *
 * @param smartGroup - The current smart group
 * @param toolParentId - The current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param showInsights - Whether to show insights
 * @param selectedToolResults - The current selected toolResults
 * @param setSelectedToolResults - Function that sets the current selected toolResults
 * @param outerContainerRef - The outer container ref, use by ReactWindowElementScroller
 * @param smartGroupId - The current smart group id
 * @param groupIndex - The current smart group index
 * @param handleShowGallery - Handler for show the expanded gallery
 * @param expandedGroupId - The currently expanded group id
 * @param onHideNextCarousels - Used to determine whether more items are being fetched in the currently opened carousel. If so, hide subsequent carousels.
 *
 */
const SmartGroupCarousel = ({
  smartGroup,
  toolParentId,
  toolSpecificationName,
  showInsights,
  outerContainerRef,
  selectedToolResults,
  setSelectedToolResults,
  smartGroupId,
  groupIndex,
  handleShowGallery,
  expandedGroupId,
  onHideNextCarousels,
  'data-test': dataTest,
}: Props) => {
  const [params] = useQueryParams()
  const dispatch = useDispatch()

  const showGallery = expandedGroupId === smartGroupId

  const toolResultsBranch = getToolResultsBranch({
    params,
    toolParentId,
    groupId: smartGroupId,
    smartGroupActive: true,
  })

  const toolResultsRes = useData(getterKeys.toolParentToolResults(toolResultsBranch))
  const { toolResults } = useMemo(() => ({ toolResults: toolResultsRes?.results }), [toolResultsRes])

  const memoizedToolResults = useMemo(() => {
    return smartGroup.results
  }, [smartGroupId]) //eslint-disable-line

  const filteredGroupToolResults = useFilteredSmartGroupToolResults(memoizedToolResults, toolSpecificationName)

  const { ref, hasBeenVisible } = useIsCarouselOnScreen()

  const prevToolResultsBranch = usePrevious(toolResultsBranch)

  useEffect(() => {
    if (!hasBeenVisible) return

    // If filters have not changed and we already have toolResults, we don't need to fetch them again.
    if (prevToolResultsBranch && prevToolResultsBranch === toolResultsBranch && toolResults) return
    const fetchToolResults = async () => {
      await fetchSmartGroupToolResults({
        toolSpecificationName,
        dispatch,
        filteredGroupToolResults,
        toolResultsBranch,
        params,
      })
    }
    fetchToolResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolResultsBranch, dispatch, filteredGroupToolResults, hasBeenVisible])

  const handleEndReached = async () => {
    await fetchSmartGroupToolResults({
      toolSpecificationName,
      fetchedToolResults: toolResults,
      filteredGroupToolResults,
      toolResultsBranch,
      dispatch,
      params,
    })
  }

  const groupToolResultsCount = renderToolResultsCount(filteredGroupToolResults.length)

  // This effect handles the carousel hiding logic. It tells the parent component when to hide the subsequent carousels
  // if pagination is still in progress
  useEffect(() => {
    if (toolResults?.length !== filteredGroupToolResults.length && showGallery) onHideNextCarousels(true)
    else onHideNextCarousels(false)
  }, [filteredGroupToolResults.length, onHideNextCarousels, showGallery, toolResults?.length])

  return (
    <LabelingGroupWrapper
      carouselWrapperRef={ref}
      toolParentId={toolParentId}
      showInsights={showInsights}
      selectedToolResults={selectedToolResults}
      setSelectedToolResults={setSelectedToolResults}
      outerContainerRef={outerContainerRef}
      groupLabel={getSmartGroupLabel(smartGroup, groupIndex)}
      groupId={groupIndex.toString()}
      loadingToolResults={!toolResults}
      toolResults={toolResults}
      handleEndReached={handleEndReached}
      groupToolResultsCount={groupToolResultsCount}
      showGallery={showGallery}
      handleShowGallery={() => {
        handleShowGallery(showGallery ? undefined : smartGroupId, groupIndex)
      }}
      isFirstGroup={groupIndex === 0}
      data-test={dataTest}
    />
  )
}

// Starting charcode is the code for "A"
const BASE_CHAR_CODE = 65

const ALPHABET_LENGTH = 26

const groupTypesLabel = {
  pass: 'Good',
  fail: 'Defect',
}

/**
 * Returns the smart group label, based on the group type and index
 * @param group - the smart group
 * @param groupIndex - the group index
 *
 * @returns string
 */
const getSmartGroupLabel = (group: SmartGroup, groupIndex: number) => {
  const suffix = Math.floor(groupIndex / ALPHABET_LENGTH)
  let groupName = String.fromCharCode(BASE_CHAR_CODE + (groupIndex % ALPHABET_LENGTH))

  if (suffix) groupName += suffix

  if (group.type === 'pass' || group.type === 'fail') {
    groupName += `: Likely ${groupTypesLabel[group.type]}`
  }

  return `Smart Group ${groupName}`
}

export default SmartGroupCarousel
