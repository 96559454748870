import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Color Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3678%3A34
 *
 * @param hasHover - Fill the icon with light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismColorToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3546 14.9076C10.6022 15.6721 9.55546 16.1461 8.39805 16.1461C6.10715 16.1461 4.25 14.289 4.25 11.998C4.25 9.70714 6.10715 7.85 8.39805 7.85C9.55546 7.85 10.6022 8.32403 11.3546 9.08853C10.7865 9.91623 10.4541 10.9183 10.4541 11.998C10.4541 13.0778 10.7865 14.0799 11.3546 14.9076ZM12.0001 15.676C11.0717 16.5854 9.80034 17.1461 8.39805 17.1461C5.55486 17.1461 3.25 14.8412 3.25 11.998C3.25 9.15486 5.55486 6.85 8.39805 6.85C9.80034 6.85 11.0717 7.41067 12.0001 8.32006C12.9286 7.41067 14.1999 6.85 15.6022 6.85C18.4454 6.85 20.7502 9.15486 20.7502 11.998C20.7502 14.8412 18.4454 17.1461 15.6022 17.1461C14.1999 17.1461 12.9286 16.5854 12.0001 15.676Z"
        fill="currentColor"
      />
    </svg>
  )
}
