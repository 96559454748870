import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Edit Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=8010%3A15430
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismNotifyIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.notifyIcon}  ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M5 17V6C5 5.44772 5.44772 5 6 5H14H19C19.5523 5 20 5.44772 20 6V8.25V17C20 17.5523 19.5523 18 19 18H14.3508C14.1237 18 13.9034 18.0773 13.7261 18.2191L9.81235 21.3501C9.48497 21.612 9 21.3789 9 20.9597V19C9 18.4477 8.55228 18 8 18H6C5.44772 18 5 17.5523 5 17Z"
        className={Styles.main}
      />
      <path d="M2 13V3C2 2.44772 2.44771 2 3 2H13" strokeDasharray="1 1" className={Styles.dashes} />
      <path d="M9 13H14" className={Styles.line} strokeLinecap="round" />
      <path d="M9 10H16" className={Styles.line} strokeLinecap="round" />
      <circle cx="20" cy="5" r="2.5" className={Styles.circle} />
    </svg>
  )
}
