import React, { useEffect, useState } from 'react'

import moment from 'moment-timezone'

import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { Modal } from 'components/PrismModal/PrismModal'
import { useAllToolLabels, useDateTimePreferences } from 'hooks'
import { ItemExpanded, ToolLabel, ToolResultEmptyPredictionOutcome, ToolSpecificationName } from 'types'
import {
  evaluateOutcomes,
  findMultipleToolLabelsByPartialData,
  getLabelName,
  getNonEmptyToolResults,
  isLabelDiscard,
  parsePredictionMetadata,
  renderToolName,
} from 'utils'
import { GRADED_ANOMALY_TOOL_LABELS } from 'utils/constants'

import Styles from './InspectionSummary.module.scss'

const InspectionSummaryPrintModal = ({ item, onClose }: { item: ItemExpanded; onClose: () => void }) => {
  const allToolResults = getNonEmptyToolResults(item.pictures.flatMap(picture => picture.tool_results))

  const sortedToolResults = allToolResults.sort(printSort)
  const { allToolLabels } = useAllToolLabels()

  const [modalLoaded, setModalLoaded] = useState(false)
  const { timeZone, shortYearDateFormat, timeFormat } = useDateTimePreferences()

  // We call window.print inside this effect instead of onModalLoad to avoid an issue where
  // it hangs when called inside a setTimeout callback.
  useEffect(() => {
    if (modalLoaded && allToolLabels) window.print()
  }, [modalLoaded, allToolLabels, item.id])

  return (
    <Modal
      id="inspection-summary"
      header="Inspection Summary"
      className={Styles.printModal}
      overlayClassName={Styles.printModalOverlay}
      showCancel={false}
      onClose={onClose}
      onModalLoad={() => {
        setModalLoaded(true)
      }}
      modalBodyClassName={Styles.printModalBody}
    >
      <div className={Styles.gridModalBody}>
        <p>
          <span className={Styles.label}>Item:</span>{' '}
          <span className={Styles.result}>{evaluateOutcomes([item.calculated_outcome])}.</span>{' '}
          <span className={Styles.label}>Date:</span>{' '}
          <span className={Styles.result}>
            {moment(item.created_at).tz(timeZone).format(shortYearDateFormat)}{' '}
            {moment(item.created_at).tz(timeZone).format(timeFormat)}.{' '}
          </span>
          {sortedToolResults.map(toolResult => {
            return (
              <React.Fragment key={toolResult.id}>
                <span className={Styles.label}>{renderToolName(toolResult.tool)}:</span>{' '}
                <span className={Styles.result}>{getResult(toolResult, allToolLabels)}.</span>{' '}
              </React.Fragment>
            )
          })}
        </p>
      </div>
    </Modal>
  )
}

export default InspectionSummaryPrintModal

const getResult = (toolResult: ToolResultEmptyPredictionOutcome, allToolLabels: ToolLabel[] | undefined) => {
  if (!toolResult.tool) {
    return '--'
  }

  const toolLabelIds = getToolLabelsToUse(toolResult)
  const toolLabels = allToolLabels?.filter(label => toolLabelIds?.includes(label.id))

  if (toolLabels?.some(label => isLabelDiscard(label))) return 'Deleted'

  const toolOutcome = evaluateOutcomes([toolResult.calculated_outcome])
  if (toolOutcome === 'unknown') return 'Unknown'

  const toolType = toolResult.tool.specification_name

  if (toolType === 'detect-barcode') {
    const codes = parsePredictionMetadata(toolResult.prediction_metadata?.codes, 'array')
    const codesText =
      codes
        ?.filter(codeDict => !!codeDict.code)
        .map(codeDict => codeDict.code)
        .join(', ') || 'No barcode observed'
    return `${toolOutcome} (${codesText})`
  }

  if (toolType === 'ocr') {
    const primaryText = parsePredictionMetadata(toolResult.prediction_metadata?.text, 'string')
    const secondaryText = parsePredictionMetadata(toolResult.prediction_metadata?.raw_result?.text, 'string')
    const textObserved = primaryText || secondaryText || 'No text observed'
    return `${toolOutcome} (${textObserved})`
  }

  if (toolType === 'measurement') {
    const width = parsePredictionMetadata(toolResult.prediction_metadata?.bbox_width, 'number')
    const height = parsePredictionMetadata(toolResult.prediction_metadata?.bbox_height, 'number')

    if (width !== undefined && height !== undefined) {
      return `${toolResult.calculated_outcome} (${(width * 100).toFixed(1)}% width, ${(height * 100).toFixed(
        1,
      )}% height)`
    }
  }

  if (toolType === 'color-check') {
    const pixels = parsePredictionMetadata(toolResult.prediction_metadata?.num_pixels, 'number') || 0
    return `${toolOutcome} (${pixels}px)`
  }

  if (toolType === 'match-classifier' || toolType === 'graded-anomaly') {
    if (!allToolLabels) return <PrismSkeleton maxWidth={200} size="medium" />

    if (toolLabels) {
      if (toolType === 'graded-anomaly') {
        const gradedAnomalyLabels = findMultipleToolLabelsByPartialData(toolLabels, GRADED_ANOMALY_TOOL_LABELS)
          .map(lbl => getLabelName(lbl))
          .join(', ')

        return `${toolOutcome} (${gradedAnomalyLabels || '--'})`
      }

      const matchLabels = toolLabels.map(lbl => getLabelName(lbl)).join(', ')
      return `${toolOutcome} (${matchLabels || '--'})`
    }
    // This is fallback code used for backwards compatibility, for tool results generated with the match tool before the 2.13 migration
    const predictedClass = parsePredictionMetadata(toolResult.prediction_metadata?.predicted_class, 'string') || '--'
    return `${toolOutcome} (${predictedClass})`
  }

  if (toolType === 'deep-svdd' || toolType === 'classifier' || toolType === 'alignment' || toolType === 'random') {
    return toolOutcome
  }
}

// This simple util should be used to fetch the labels to use when displaying information about a tool
const getToolLabelsToUse = (toolResult: ToolResultEmptyPredictionOutcome) => {
  if (toolResult.active_user_label_set?.tool_labels.length) return toolResult.active_user_label_set?.tool_labels
  if (toolResult.prediction_labels.length) return toolResult.prediction_labels
}

const specificationNamePrintOrder: ToolSpecificationName[] = [
  'detect-barcode',
  'ocr',
  'measurement',
  'color-check',
  'match-classifier',
  'classifier',
  'deep-svdd',
  'graded-anomaly',
  'alignment',
  'random',
]

const printSort = (toolResultA: ToolResultEmptyPredictionOutcome, toolResultB: ToolResultEmptyPredictionOutcome) => {
  if (!toolResultA.tool || !toolResultB.tool) return 0

  if (toolResultA.tool.specification_name === toolResultB.tool.specification_name) {
    if (toolResultA.tool.id > toolResultB.tool.id) return 1
    if (toolResultA.tool.id < toolResultB.tool.id) return -1
    return 0
  }

  const specOrderA = specificationNamePrintOrder.indexOf(toolResultA.tool.specification_name)
  const specOrderB = specificationNamePrintOrder.indexOf(toolResultB.tool.specification_name)
  return specOrderA - specOrderB
}
