import React from 'react'

import { Location as HistoryLocation } from 'history'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { backendErrorCodes, getterKeys, query, service } from 'api'
import AppVersion from 'components/AppVersion/AppVersion'
import { Button } from 'components/Button/Button'
import FullscreenForm from 'components/FullscreenForm/FullscreenForm'
import PasswordInput from 'components/PasswordInput/PasswordInput'
import { PrismElementaryIsotypeIcon } from 'components/prismIcons/PrismElementaryIsotypeIcon'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error } from 'components/PrismMessage/PrismMessage'
import paths, { nonOrgPaths } from 'paths'
import * as Actions from 'rdx/actions'
import { doesUserBelongsToAnActiveOrg, getMinifiedOrgId } from 'utils'

import Styles from './LoginContainer.module.scss'

interface Props {
  from: HistoryLocation
}
/**
 * Renders login screen. User has to type their email/password in.
 * @param from - Where to redirect user to after login
 */
const LoginContainer = ({ from }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const defaultValues = getDefaultValues()
  const {
    formState: { isValid, errors },
    control,
    reset,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const { email, password } = getValues()

    const res = await service.login(email, password)

    if (res.type === 'success') {
      dispatch(Actions.authSet({ auth: res.data, rockwellIdpLogin: false }))
      const meRes = await query(getterKeys.me(), service.me, { dispatch })

      if (meRes?.type !== 'success') {
        return error({ title: 'An error ocurred' })
      }

      const isUserActivatingItsAcc = meRes.data.user_orgs.some(
        userOrg => from.pathname === paths.activateUserOrg(getMinifiedOrgId(userOrg.organization.id)),
      )

      if (!doesUserBelongsToAnActiveOrg(meRes.data) && !isUserActivatingItsAcc) {
        error({ title: 'You tried to log in with a user that has no organization...' })
        return
      }
      dispatch(Actions.authSet({ auth: res.data, rockwellIdpLogin: false }))
      // fetch rest of user details if user logged in successfully
      await query(getterKeys.organization(), service.getOrganization, { dispatch })
      history.push(from.pathname)
    }

    if (res.type === 'error') {
      let title = 'Incorrect email or password'
      if (res.data.code === backendErrorCodes.userLockoutTooManyAttempts) {
        title =
          'You have been locked out of this account due to too many failed login attempts. Please reset your password to continue.'
      }
      reset({ email, password: '' })
      error({ title })
    }
    if (res.type === 'exception') error({ title: 'Check your internet connection' })
  }

  return (
    <FullscreenForm formClassName={Styles.formContainer}>
      <div className={Styles.elementaryLogoContainer}>
        <PrismElementaryIsotypeIcon />
      </div>

      <form className={Styles.form} onSubmit={handleSubmit} autoComplete="off">
        <Controller
          name="email"
          rules={{ required: 'Email is required' }}
          control={control}
          render={({ onChange, value }) => (
            <PrismInput
              wrapperClassName={Styles.emailInputWrapper}
              value={value}
              name="email"
              type="text"
              placeholder="Email"
              onChange={e => onChange(e.target.value)}
              data-testid="login-email-input"
              errors={errors}
            />
          )}
        />

        <Controller
          name="password"
          rules={{ required: 'Password is required' }}
          control={control}
          render={({ onChange, value }) => (
            <PasswordInput
              wrapperClassName={Styles.passwordInputContainer}
              value={value}
              name="password"
              placeholder="Password"
              onChange={e => onChange(e.target.value)}
              data-testid="login-password-input"
              errors={errors}
            />
          )}
        />
        <Button htmlType="submit" className={Styles.submitButton} disabled={!isValid} data-testid="login-submit-button">
          Log in
        </Button>
      </form>

      <Button type="link" className={Styles.link} to={nonOrgPaths.resetPasswordEmail()}>
        Forgot your password?
      </Button>

      <AppVersion className={Styles.appVersion} />
    </FullscreenForm>
  )
}

const getDefaultValues = () => {
  return {
    email: '',
    password: '',
  }
}

export default LoginContainer
