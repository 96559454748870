import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Recipe Icon
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=5859-11479&mode=design&t=US7Cn8H7N454U86q-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismRecipeIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M8 7.5C8 7.22386 8.22386 7 8.5 7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H8.5C8.22386 8 8 7.77614 8 7.5Z"
        fill="currentColor"
      />
      <path
        d="M8 11.5C8 11.2239 8.22386 11 8.5 11H15.5C15.7761 11 16 11.2239 16 11.5C16 11.7761 15.7761 12 15.5 12H8.5C8.22386 12 8 11.7761 8 11.5Z"
        fill="currentColor"
      />
      <path
        d="M8 15.5C8 15.2239 8.22386 15 8.5 15H12.5C12.7761 15 13 15.2239 13 15.5C13 15.7761 12.7761 16 12.5 16H8.5C8.22386 16 8 15.7761 8 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C19.5523 3 20 3.44772 20 4V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V4C4 3.44772 4.44772 3 5 3ZM19 4H5V19H19V4Z"
        fill="currentColor"
      />
    </svg>
  )
}
