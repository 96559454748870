import React from 'react'

import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { PrismHelpIcon, PrismInfoIcon, PrismSearchIcon, PrismSuccessIcon } from 'components/prismIcons'

import Styles from './LabelingScreen.module.scss'

interface Props {
  onClick: React.MouseEventHandler
}

/**
 * Renders different empty states for the labeling screen
 *
 * https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=772%3A2586
 *
 * @param onClick - handle for the button click
 */

// Filter is active [AND] Results == 0 [AND] Unlabeled != 0
export const EmptyStateNoImagesMatch = () => {
  return (
    <GenericBlankStateMessage
      header={<PrismSearchIcon />}
      description="There are no images that match your filters"
      dataTestId="labeling-gallery-body-filter-mismatch"
    />
  )
}

// Filter == Unlabeled [AND] Unlabeled == 0 [AND] Labeled > 0
export const EmptyStateNoImagesToLabel = () => {
  return (
    <GenericBlankStateMessage
      header={<PrismSuccessIcon />}
      description="You have labeled all of your images!"
      dataTestId="labeling-gallery-body-labeling-complete"
    />
  )
}

// Unlabeled == 0 [AND] Routine Parent Pictures == 0 [AND] Routine != Deployed
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EmptyStateDeployAndCollectImages = () => {
  return (
    <GenericBlankStateMessage
      header={<PrismInfoIcon />}
      description="Deploy your recipe and run an inspection to collect images for labeling."
      dataTestId="labeling-gallery-body-no-images"
    />
  )
}

// Unlabeled == 0 [AND] Routine Parent Pictures == 0 [AND] Routine = Deployed
export const EmptyStateCollectImages = () => {
  return (
    <GenericBlankStateMessage
      header={<PrismInfoIcon />}
      description="Run an inspection to collect images and start labeling."
    />
  )
}

// Unlabeled == 0 [AND] Routine Parent Pictures > Labeled Images
export const EmptyStateLabelImagesCollectedBeforeToolCreation = ({ onClick }: Props) => {
  return (
    <GenericBlankStateMessage
      header={<PrismHelpIcon />}
      description="Would you like to label images collected before this tool was created?"
    >
      <Button size="small" onClick={onClick} className={Styles.blankStateButton}>
        Yes
      </Button>
    </GenericBlankStateMessage>
  )
}

/**
 * Renders the appropriate empty labeling state for the Gallery and Focus labeling screens.
 *
 * @param filtering - Whether the user has some active filters.
 * @param userHasLabeled - Whether the user has already labeled some ToolResults.
 * @param routineParentId - The current Routine Parent id.
 *
 */
export const EmptyLabelingState = ({ filtering, userHasLabeled }: { filtering: boolean; userHasLabeled: boolean }) => {
  if (filtering) {
    return <EmptyStateNoImagesMatch />
  }
  if (userHasLabeled) {
    return <EmptyStateNoImagesToLabel />
  }

  return <EmptyStateDeployAndCollectImages />
}
