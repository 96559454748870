import React, { useEffect, useRef, useState } from 'react'

import { Input } from 'antd'

import { Modal } from 'components/PrismModal/PrismModal'
import { Tag } from 'components/Tag/Tag'
import { getRegex } from 'utils'

import Styles from './ToolStyles.module.scss'

interface Props {
  onCloseModal: () => any
  visible: boolean
  regexString: string
  onApply: (regex: string) => void
}

export default function RegexPlaygroundModal({ visible, onCloseModal, regexString, onApply }: Props) {
  const [testRegex, setTestRegex] = useState(regexString)
  const [testText, setTestText] = useState('')
  const [matches, setMatches] = useState(false)
  const [isRegexValid, setIsRegexValid] = useState(true)
  const [highligtedText, setHighligtedText] = useState<JSX.Element[]>([])
  const highlightDivRef = useRef<HTMLDivElement>(null)

  const handleTestTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestText(e.target.value)
  }

  const handleTestTextScroll = (e: HTMLTextAreaElement) => {
    const scrollTop = e.scrollTop
    if (highlightDivRef?.current) {
      highlightDivRef.current.scrollTop = scrollTop
    }
  }

  const handleTestRegexChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTestRegex(e.target.value)
  }

  useEffect(() => {
    setTestRegex(regexString)
  }, [regexString])

  useEffect(() => {
    const regex = getRegex(testRegex)
    if (!regex) {
      setIsRegexValid(false)
      setMatches(false)
      setHighligtedText([])
      return
    }

    setIsRegexValid(true)
    const regExResult = [...testText.matchAll(regex)]
    if (!regExResult.length) {
      setMatches(false)
      setHighligtedText([])
      return
    }
    setMatches(true)
    const highlightedJsx = [
      <span className={Styles.highlight} key={0}>
        {testText.substring(0, regExResult[0]!.index)}
      </span>,
    ]
    regExResult.forEach((currentResult, i) => {
      const prevResult = regExResult[i - 1]
      if (prevResult) {
        const prevText = testText.substring(
          (prevResult.index || 0) + (prevResult[0]?.length || 0),
          currentResult.index || 0,
        )
        if (prevText) {
          highlightedJsx.push(
            <span className={Styles.highlight} key={highlightedJsx.length}>
              {testText.substring((prevResult.index || 0) + (prevResult[0]?.length || 0), currentResult.index || 0)}
            </span>,
          )
        }
      }

      const matchedText = testText.substr(currentResult.index || 0, currentResult[0]?.length || 0)
      highlightedJsx.push(
        <mark key={highlightedJsx.length} className={Styles.highlight}>
          {matchedText}
        </mark>,
      )
    })
    const lastText = testText.substring(
      (regExResult[regExResult.length - 1]?.index || 0) + (regExResult[regExResult.length - 1]?.[0]?.length || 0),
    )
    // Appending new line fixes scrolling bug
    highlightedJsx.push(
      <span className={Styles.highlight} key={highlightedJsx.length}>
        {lastText + '\n'}
      </span>,
    )

    setHighligtedText(highlightedJsx)
  }, [testRegex, testText])

  const showMatchBadge = matches && testRegex && testText

  return (
    <>
      {visible && (
        <Modal
          id="apply-regex"
          onClose={onCloseModal}
          showCancel={false}
          onOk={() => onApply(testRegex)}
          header="Test Ground"
          okText="Apply"
          disableSave={!isRegexValid}
          size="wide"
          modalBodyClassName={Styles.modalBodyPlayground}
          data-testid="regex-playground-modal"
        >
          <div className={Styles.playgroundContainer}>
            <div className={`${Styles.playgroundSection} ${Styles.leftContainer}`}>
              <div className={Styles.playgroundForm}>
                <div>
                  <div className={Styles.playgroundSettingsItemInput}>
                    <div className={Styles.playgroundMatchHeaderContainer}>
                      <label className={Styles.settingsItemTitle}>Regular Expression</label>
                    </div>

                    <Input.TextArea
                      placeholder="Insert your regular expression here"
                      value={testRegex}
                      onChange={handleTestRegexChange}
                      data-testid="regex-playground-regex-input"
                    />

                    {!isRegexValid && <span className={Styles.playgroundInvalidRegexText}>Regex is invalid.</span>}
                  </div>

                  <div className={Styles.playgroundSettingsItemInput}>
                    <div className={Styles.playgroundMatchHeaderContainer}>
                      <label className={Styles.settingsItemTitle}>Test Text</label>

                      {showMatchBadge && <Tag type="success">Match</Tag>}

                      {!showMatchBadge && testText && <Tag type="danger">No match</Tag>}
                    </div>

                    <div className={Styles.highlightContainer}>
                      <div ref={highlightDivRef} className={Styles.highlights}>
                        {highligtedText}
                      </div>
                      <Input.TextArea
                        placeholder="Insert your test text here"
                        value={testText}
                        onChange={handleTestTextChange}
                        onScroll={e => handleTestTextScroll(e.currentTarget)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${Styles.playgroundSection} ${Styles.rightContainer}`}>
              <span className={Styles.playgroundSectionHeader}>Example</span>

              <div className={Styles.playgroundExamples}>
                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>Exact match with spaces</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>abc 123</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>abc 123</span>
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>Character count only</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>{'[a-zA-Z0-9]{10}'}</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>n8fdgj291x</span>
                  <br />
                  For any characters:<span className={Styles.exampleHighlight}>{'.{10}'}</span>
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>Starts with or ends with</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>^abc.*456$</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>abc def 123 456</span>
                  <br />
                  No match:<span className={Styles.exampleHighlight}>^def</span>
                  <br />
                  No match:<span className={Styles.exampleHighlight}>123$</span>
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>To match restricted regex characters, escape with \</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>{'\\.\\+\\*\\^\\$\\?\\(\\[\\{\\|'}</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>{'.+*^$?([{|'}</span>
                  <br />
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>Number range alone or in combo with exact match</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>[01][0-9]-[0-3][0-9]</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>12-25 date</span>
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>
                    Character count with numerical or alpha range, could be in combo with exact match
                  </span>
                  <br />
                  Regex:
                  <span className={Styles.exampleHighlight}>{'[1-3]{10}, or [123]{10}'}</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>1212321332</span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>{'[a-c]{10} exact'}</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>acbabcbbac exact</span>
                </div>

                <div className={Styles.regexExample}>
                  <span className={Styles.regexExampleHeader}>
                    Exact match on portion or portions with character count only on another section or sections
                  </span>
                  <br />
                  Regex:<span className={Styles.exampleHighlight}>{'abc .{10} 123'}</span>
                  <br />
                  Text:<span className={Styles.exampleHighlight}>abc gsfwu9f8vd 123</span>
                  <br />
                  For any number of characters in the middle:<span className={Styles.exampleHighlight}>abc .* 123</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
