import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import SessionExpiration from 'components/SessionExpiration/SessionExpiration'
import { useData } from 'hooks'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { Organization } from 'types'
import { getSessionLengthDisplayText, getSessionLengthSecondsFromFormValues, getSessionLengthUnitsAndTime } from 'utils'

import Styles from './GeneralOrg.module.scss'

const GeneralOrg = () => {
  const dispatch = useDispatch()
  const organization = useData(getterKeys.organization())
  const defaultValues = getDefaultValues(organization)
  const {
    formState: { isDirty, isValid, errors },
    control,
    trigger,
    getValues,
    reset,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { sessionLengthTime, sessionLengthUnits } = watch()

  useEffect(() => {
    if (!organization) return
    reset(getDefaultValues(organization))
  }, [organization, reset])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid || !organization) return

    const { sessionLengthTime, sessionLengthUnits, organization_name } = getValues()

    const sessionLengthSeconds = getSessionLengthSecondsFromFormValues({ sessionLengthTime, sessionLengthUnits })

    const payload: Partial<Organization> = {
      name: organization_name,
      session_length_s: sessionLengthSeconds,
    }
    const res = await service.updateOrganization(payload)
    if (res.type !== 'success') return error({ title: 'There was an error updating Organization settings' })
    success({ title: 'Settings updated' })
    dispatch(
      Actions.getterUpdate({
        key: getterKeys.organization(),
        updater: prev => {
          return { ...prev, data: res.data }
        },
      }),
    )
  }

  return (
    <>
      <LeavePagePrompt when={isDirty} />
      <PrismContainer
        title="General"
        actions={
          <Button
            htmlType="submit"
            disabled={!isDirty || !isValid}
            onClick={handleSubmit}
            size="small"
            data-testid="organization-config-save-button"
          >
            Save
          </Button>
        }
        headerElementsAlign="vertical"
        className={Shared.rightSectionContainer}
        headerActionsClassName={Styles.generalHeaderContainer}
      >
        <div className={Styles.formContainer}>
          <Controller
            control={control}
            rules={{ required: 'Required' }}
            render={props => <PrismInput label="name of organization" {...props} />}
            name="organization_name"
          />
          <Divider />
          <SessionExpiration
            formProps={{
              control: control,
              errors: errors,
              inputTrigger: () => trigger('sessionLengthUnits'),
              selectTrigger: () => trigger('sessionLengthTime'),
              getValues: getValues,
            }}
            sessionLengthCaption={`
            Users will be logged out automatically after ${getSessionLengthDisplayText(
              sessionLengthTime,
              sessionLengthUnits,
            )}. You can override this setting for specific users.`}
            labelClassName={Styles.sessionExpirationLabel}
            disabled={false}
          />
        </div>
      </PrismContainer>
    </>
  )
}

export default GeneralOrg

const getDefaultValues = (organization: Organization | undefined) => {
  const timeAndUnits = getSessionLengthUnitsAndTime(organization?.session_length_s || 0)
  return {
    organization_name: organization?.name,
    sessionLengthTime: timeAndUnits?.time,
    sessionLengthUnits: timeAndUnits?.unit,
  }
}
