import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { nonOrgPaths } from 'paths'

import Styles from './SendResetPasswordEmail.module.scss'

/**
 * Renders form for sending reset password email to user.
 */
const SendEmailForm = () => {
  const history = useHistory()
  const location = useLocation<{ email?: string } | undefined>()

  const initialEmail = location.state?.email || ''
  const [email, setEmail] = useState(initialEmail)

  const handleSubmit = async () => {
    const res = await service.sendResetPasswordEmail(email)
    if (res.type === 'success') {
      success({ title: 'Review your inbox for an email with reset instructions' })
      history.replace(nonOrgPaths.login())
    } else {
      error({ title: "Couldn't send email" })
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.formContainer}>
        <h3 className={Styles.title}>Reset your password</h3>
        <form
          className={Styles.form}
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <PrismInput
            wrapperClassName={Styles.field}
            name="email"
            type="email"
            value={email}
            placeholder="Enter your email address"
            onChange={e => setEmail(e.target.value)}
          />

          <div className={Styles.buttonsContainer}>
            <Button size="small" disabled={!email} htmlType="submit">
              Submit
            </Button>

            <Button
              size="small"
              type="tertiary"
              className={Styles.goBackButton}
              onClick={() => history.replace(nonOrgPaths.login())}
            >
              Go Back
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SendEmailForm
