import React, { useMemo } from 'react'

import { SelectProps } from 'antd'

import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect, PrismSelectProps } from 'components/PrismSelect/PrismSelect'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useAllToolLabels } from 'hooks'
import { getDisplaySeverity, getLabelName } from 'utils'

import { Filters, FiltersUpdater } from './AnalyzeFilters'

const MAX_LABELS = 3

interface LabelSelectProps extends Omit<PrismSelectProps, 'options' | 'onChange'> {
  labelType: 'prediction' | 'user'
  onUpdateFilters: FiltersUpdater
  filters: Filters | undefined
  disabled?: boolean
  selectClassName?: string
  popupClassName?: string
  tooltipClassName?: string
  resultButtonClassName?: string
  wrapperClassName?: string
  optionClassName?: string
}

export const LabelSelect = ({
  labelType,
  filters,
  disabled,
  onUpdateFilters,
  selectClassName,
  popupClassName,
  tooltipClassName,
  resultButtonClassName,
  wrapperClassName = '',
  optionClassName = '',
  ...rest
}: LabelSelectProps) => {
  const toolLabelOptions =
    labelType === 'prediction'
      ? { ignoreUnusedLabels: true, ignoreUncertainOrDiscardLabels: true, ignoreDeletedLabels: true }
      : { ignoreUnusedLabels: true, ignoreDerivativeLabels: true, ignoreDeletedLabels: true }
  const { allToolLabels: labelOptions } = useAllToolLabels(toolLabelOptions)

  const filtersValue = labelType === 'prediction' ? 'prediction_label_id' : 'user_label_id'
  const selectedLabels = filters?.[filtersValue]

  const sortedLabelOptions = useMemo(() => {
    return labelOptions?.sort((labelA, labelB) => labelA.value.localeCompare(labelB.value))
  }, [labelOptions])

  const handleFilterSelect: SelectProps['filterOption'] = (inputValue, option) => {
    if (!option?.title) return false

    return option.title.toLowerCase().includes(inputValue.toLowerCase())
  }

  return (
    <PrismSelect
      placeholder={labelType === 'prediction' ? 'Prediction' : 'Label'}
      size={'small'}
      {...rest}
      data-testid={`analyze-filters-${labelType}-label`}
      mode="multiple"
      onKeyUp={e => e.preventDefault()}
      clearable
      showArrow
      value={selectedLabels}
      disabled={disabled}
      onChange={val => {
        onUpdateFilters({ [filtersValue]: val })
      }}
      filterOption={handleFilterSelect}
      stopPropagationOnDropdownClick
      className={selectClassName}
      popupClassName={popupClassName}
      showSelectedOptionCheck
      wrapperClassName={wrapperClassName}
      options={sortedLabelOptions?.map(label => {
        const disabledOption =
          selectedLabels && selectedLabels.length >= MAX_LABELS && !selectedLabels.includes(label.id)
        const labelName = getLabelName(label)
        return {
          value: label.id,
          key: label.id,
          title: labelName,
          dataTestId: `analyze-filters-${labelType}-${label.value}`,
          disabled: disabledOption,
          className: optionClassName,
          content: (
            <PrismTooltip
              condition={!!disabledOption}
              title={`Cannot filter by more than ${MAX_LABELS} ${
                labelType === 'prediction' ? 'predictions' : 'labels'
              }`}
              anchorClassName={tooltipClassName}
            >
              <PrismResultButton
                severity={getDisplaySeverity(label)}
                value={labelName}
                type="pureWhite"
                size="small"
                className={resultButtonClassName}
              />
            </PrismTooltip>
          ),
        }
      })}
    />
  )
}
