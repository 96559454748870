import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import Tooltip, { TooltipPropsWithTitle } from 'antd/lib/tooltip'

import { useClick } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismOverflowIcon } from 'components/prismIcons'
import { useOutsideRefClick } from 'hooks'
import { MenuPosition } from 'types'

import Styles from './OptionMenu.module.scss'

export type Option<T extends string> = {
  value: T
  disabled?: boolean
  title?: React.ReactNode
  'data-test-attribute'?: string
  'data-testid'?: string
  'data-test'?: string
  tooltipProps?: TooltipPropsWithTitle
  isActive?: boolean
  badge?: React.ReactNode
  className?: string
}

export type OptionMenuProps<T extends string> = {
  options: Option<T>[] | undefined
  onMenuItemClick: (value: T) => any
  onOpen?: () => any
  onClose?: () => any
  children?: React.ReactNode
  className?: string
  activeClassName?: string
  menuContainerClassName?: string
  bottomOffset?: number
  key?: string
  openWithClick?: boolean
  closeOnClick?: boolean
  buttonDisabled?: boolean
  position?: MenuPosition
  'data-testid'?: string
  'data-test'?: string
  renderWithPortal?: boolean
  onShowMenuChange?: (show: boolean) => void
  menuItemClassName?: string
  hasOptionMenuGroups?: boolean
  closeMenuRef?: MutableRefObject<(() => void) | undefined>
  isSubmenu?: boolean
  hasSubmenu?: boolean
  menuWidth?: number
}

export type IconButtonProps = {
  iconButtonIsOnTop?: boolean
  iconButtonSize?: 'xsmall' | 'small' | 'medium'
  iconButtonType?: 'secondary' | 'tertiary' | 'ghost'
  iconButtonDataTest?: string
  iconButtonDataTestId?: string
  iconButtonClassName?: string
}

/**
 * Renders component for a hoverable menu. This component wraps any element, and triggers a menu when the element is hovered. If no children is added an IconButton with overflow icon will render as the main button to open the menu.
 *
 * @param options - Array of different available options to be shown in the menu.
 * @param onMenuItemClick - event handler for when a menu item is clicked.
 * @param onOpen - Callback for when the menu opens
 * @param onClose - Callback for when the menu closes
 * @param children - The element that will trigger the menu open on hover.
 * @param className - Optional class name for the option menu (only affects the parent container)
 * @param menuContainerClassName - classname for the option menu container.
 * @param openWithClick - Set to true if you want to disable hover functionality and use click to open
 * @param position - The direction in which the menu will be rendered, by default it is place to the bottomleft direction of the parent.
 * @param bottomOffset - The container's offset from the bottom of the screen. This is the padding or margin used
 * @param buttonDisabled - Help to hide the menu when the button is disabled
 * @param dataTestId - The data-testid property used in Cypress tests
 * to determine the breakpoint at which the menu will show above instead of under the trigger.
 * @param renderWithPortal - if true, the options will be rendered by a React portal
 * @param onShowMenuChange - event handler for when we show/not show the menu
 * @param menuItemClassName - Class name to be applied to the menu item
 * @param iconButtonIsOnTop - When default overflow menu is present: adds additional background-color to the IconButton
 * @param iconButtonSize - When default overflow menu is present: change the size of the IconButton
 * @param iconButtontype - When default overflow menu is present: change the IconButton type
 * @param iconButtonDataTest - When default overflow menu is present: adds the data-test to the IconButton
 * @param iconButtonDatatTestId - When default overflow menu is present: adds the data-testid to the IconButton
 * @param hasOptionMenuGroups - Changes the styles of the option menu list. if the option has empty value it will be interpreted as title, and different styles are applied.
 * @param menuItemIsActive - for persistent option to be active
 * @param isSubmenu - Adds a special style to display the submenu in the same horizontal position as the parent/anchor, this works alongside the .menuTop style.
 * @param hasSubmenu - it will break the overflow to allow the submenu to be visible and to scroll with it.
 * @param menuWidth - Users can alternatively add the menu's width to avoid jumps in the layout when calculating the position.
 *
 */

function OptionMenu<T extends string>({
  options,
  onMenuItemClick,
  onOpen,
  onClose,
  children,
  className = '',
  activeClassName = '',
  menuContainerClassName = '',
  position = 'bottomLeft',
  openWithClick,
  closeOnClick,
  bottomOffset = 0,
  buttonDisabled = false,
  key,
  'data-testid': dataTestId,
  'data-test': dataTest,
  renderWithPortal,
  onShowMenuChange,
  iconButtonIsOnTop,
  iconButtonSize = 'small',
  iconButtonType,
  iconButtonDataTest,
  iconButtonDataTestId,
  menuItemClassName = '',
  hasOptionMenuGroups,
  iconButtonClassName = '',
  closeMenuRef,
  isSubmenu,
  hasSubmenu,
  menuWidth,
}: OptionMenuProps<T> & IconButtonProps) {
  const menuRef = useRef<HTMLDivElement>(null)
  const menuContainerRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)
  const [menuIsCloseToBottom, setMenuIsCloseToBottom] = useState(false)

  useEffect(() => {
    if (closeMenuRef) {
      closeMenuRef.current = () => setShowMenu(false)
    }
  }, [closeMenuRef])

  const handleOutsideClick = useCallback(() => {
    // do not try to close the menu if the menu is already closed
    if (!showMenu) return

    setShowMenu(false)
    onClose?.()
  }, [onClose, showMenu])

  useOutsideRefClick(menuRef, handleOutsideClick, !showMenu)

  useEffect(() => {
    onShowMenuChange?.(showMenu)
  }, [onShowMenuChange, showMenu])

  // This listener is added so that the hover menu is displayed on top of the three dot icon if it reaches a certain height
  const calculateHeight = () => {
    if (!menuContainerRef.current || !menuRef.current) return

    const optionHeight = 51
    const optionHeightSum = (options?.length || 0) * optionHeight
    const heightOffset = optionHeightSum > 200 ? 200 : optionHeightSum // This is the distance the element will have from the bottom of the screen in order to trigger the menu to the top

    const { bottom } = menuContainerRef.current.getBoundingClientRect()

    if (bottom + heightOffset + bottomOffset > window.innerHeight) {
      setMenuIsCloseToBottom(true)
      menuRef.current.classList.add(Styles.menuTop || '')
    } else {
      setMenuIsCloseToBottom(false)
      menuRef.current.classList.remove(Styles.menuTop || '')
    }
  }

  const handleMouseLeave = () => {
    if (!menuRef.current || openWithClick) return
    menuRef.current.classList.remove(Styles.menuTop || '')
    setShowMenu(false)
    onClose?.()
  }

  const handleMouseEnter = () => {
    calculateHeight()
    if (!openWithClick) {
      setShowMenu(true)
    }
  }

  const handleMenuItemClick = (option: Option<T>) => {
    calculateHeight()

    onMenuItemClick(option.value)
    if (closeOnClick) {
      setShowMenu(false)
      onClose?.()
    }
  }
  const menuContainerDOMRect = renderWithPortal ? menuContainerRef.current?.getBoundingClientRect() : undefined
  const optionsList = options?.map((option, index) => (
    <OptionMenuListItem
      option={option}
      hasOptionMenuGroups={hasOptionMenuGroups}
      onClick={() => handleMenuItemClick(option)}
      menuItemClassName={menuItemClassName}
      showMenu={showMenu}
      dataTest={dataTest}
      dataTestId={dataTestId}
      key={index}
    />
  ))

  return (
    <div
      className={`${Styles.container} ${className || ''} ${showMenu ? activeClassName : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={key}
      ref={menuContainerRef}
      data-testid={dataTestId}
      data-test={dataTest}
      onClick={e => {
        e.stopPropagation()
        if (buttonDisabled || !openWithClick) return
        setShowMenu(!showMenu)

        // We don't want to call onOpen if the menu was already open
        if (showMenu) return
        onOpen && onOpen()
      }}
    >
      {children ? (
        children
      ) : (
        <IconButton
          icon={<PrismOverflowIcon />}
          data-test={iconButtonDataTest}
          data-testid={iconButtonDataTestId}
          size={iconButtonSize}
          isOnTop={iconButtonIsOnTop}
          type={iconButtonType}
          disabled={buttonDisabled}
          className={`${iconButtonClassName} ${showMenu ? Styles.menuIsOpen : ''}`}
        />
      )}

      <div
        ref={menuRef}
        className={`${Styles.menuContainer} ${Styles[position]} ${hasSubmenu ? Styles.hasSubmenu : ''} ${
          isSubmenu ? Styles.submenuWrapper : ''
        }`}
      >
        {!renderWithPortal && (
          <ul
            className={`${Styles.menuInnerContainer} ${menuContainerClassName} ${
              showMenu ? Styles.showMenu : Styles.hideMenu
            }`}
          >
            {optionsList}
          </ul>
        )}

        {renderWithPortal && menuContainerDOMRect && (
          <OptionsPortalWrapper
            showOptions={showMenu}
            parentRect={menuContainerDOMRect}
            menuContainerClassName={menuContainerClassName}
            position={position}
            menuIsCloseToBottom={menuIsCloseToBottom}
            menuWidth={menuWidth}
          >
            {optionsList}
          </OptionsPortalWrapper>
        )}
      </div>
    </div>
  )
}

/**
 * Renders a menu list at the bottom of the DOM
 *
 * @param menuContainerClassName - classname for the option menu container.
 * @param parentRefRect - DomRect from element used as anchor.
 * @param showOptions - wheter to show the options or not.
 * @param position - The direction in which the menu will be rendered, by default it is place to the bottomleft direction of the parent.
 * @param menuGap - this is the space between the menu and the anchor.
 */
const OptionsPortalWrapper = ({
  menuContainerClassName = '',
  parentRect,
  showOptions,
  children,
  menuGap = 8,
  position = 'bottomLeft',
  menuIsCloseToBottom,
  menuWidth,
}: {
  menuContainerClassName?: string
  parentRect: DOMRect
  showOptions: boolean
  children: React.ReactNode
  menuGap?: number
  position?: MenuPosition
  menuIsCloseToBottom?: boolean
  menuWidth?: number
}) => {
  const portalContainerRef = useRef<HTMLDivElement>(document.createElement('div'))
  const portalMenuRef = useRef<HTMLUListElement>(null)
  const [menuSize, setMenuSize] = useState({ height: 0, width: menuWidth || 0 })
  const [portalFixedPosition, setPortalFixedPosition] = useState<'topRight' | 'topLeft'>('topLeft')

  // Changes the user position when the menu list goes below the window height
  useEffect(() => {
    if (!menuIsCloseToBottom) return
    if (position === 'bottomLeft') setPortalFixedPosition('topLeft')
    if (position === 'bottomRight') setPortalFixedPosition('topRight')
    // eslint-disable-next-line
  }, [menuIsCloseToBottom])

  useEffect(() => {
    document.body.appendChild(portalContainerRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  // Position when the menu is vertically align to the anchor
  const topPosition = parentRect.top - menuSize.height - menuGap
  const bottomPosition = parentRect.top + parentRect.height + menuGap

  const left = parentRect.left + parentRect.width - menuSize.width

  // Position when the menu is horizontally align as the anchor
  const inlineLeft = parentRect.left - menuSize.width - menuGap
  const inlineRight = parentRect.left + parentRect.width + menuGap

  const userPositionClass = (position: MenuPosition) => {
    if (position === 'topRight') return { top: topPosition, left: parentRect.left }
    if (position === 'topLeft') return { top: topPosition, left: left }
    if (position === 'bottomRight') return { top: bottomPosition, left: parentRect.left }
    if (position === 'bottomLeft')
      return {
        top: bottomPosition,
        left: left,
      }
    if (position === 'right') return { top: parentRect.top, left: inlineRight }
    if (position === 'left')
      return {
        top: parentRect.top,
        left: inlineLeft,
      }
    return { top: parentRect.bottom + menuGap, left: parentRect.left + parentRect.width / 2 - menuSize.width / 2 }
  }

  useEffect(() => {
    if (!portalMenuRef.current) return
    const menuRect = portalMenuRef.current.getBoundingClientRect()

    setMenuSize({ height: menuRect.height, width: menuWidth || Math.floor(menuRect.width) })
  }, [showOptions, menuWidth])

  return ReactDOM.createPortal(
    <ul
      style={{
        display: showOptions ? 'block' : 'none',
        ...(menuIsCloseToBottom ? userPositionClass(portalFixedPosition) : userPositionClass(position)),
      }}
      className={`${Styles.portalContainer} ${Styles.menuInnerContainer} ${menuContainerClassName} ${
        showOptions ? Styles.showSmooth : ''
      }`}
      ref={portalMenuRef}
    >
      {children}
    </ul>,
    portalContainerRef.current,
  )
}

export default OptionMenu

const OptionMenuListItem = <T extends string>({
  option,
  hasOptionMenuGroups,
  onClick,
  menuItemClassName,
  showMenu,
  dataTest,
  dataTestId,
  dataTestAttribute,
}: {
  option: Option<T>
  hasOptionMenuGroups?: boolean
  onClick: (option: Option<T>) => void
  menuItemClassName?: string
  showMenu: boolean
  dataTest?: string
  dataTestId?: string
  dataTestAttribute?: string
}) => {
  const { handleClick } = useClick({ onClick })

  return (
    <ConditionalWrapper
      condition={!!option.tooltipProps && showMenu}
      wrapper={ch => (
        <Tooltip // We explicitly set the title prop to narrow down the tooltip props type
          key={option.value}
          title={option.tooltipProps?.title}
          placement={option.tooltipProps?.placement}
        >
          {ch}
        </Tooltip>
      )}
    >
      <li
        className={`${option.value === '' ? Styles.menuItemTitleContainer : Styles.menuItemContainer} ${
          hasOptionMenuGroups ? Styles.optionMenuGroup : ''
        } ${option.badge ? Styles.optionBadgeWrapper : ''} ${option.className ?? ''}`}
        onClick={!option.disabled ? handleClick : undefined}
        data-testid={dataTestId ? `${dataTestId}-${option['data-testid']}` : option['data-testid']}
        data-test={dataTest ? `${dataTest}-${option['data-test']}` : option['data-test']}
        data-test-attribute={
          dataTestAttribute ? `${dataTestAttribute}-${option['data-test-attribute']}` : option['data-test-attribute']
        }
      >
        <div
          className={`${Styles.menuItem} ${menuItemClassName} ${option.disabled ? Styles.disabled : ''} ${
            option.value === '' && hasOptionMenuGroups ? Styles.menuItemTitle : ''
          } ${option.isActive ? Styles.isActive : ''}`}
        >
          {option.badge && <span className={Styles.menuItemBadge}>{option.badge}</span>}
          <span className={Styles.menuItemText}>{option.title || option.value}</span>
        </div>
      </li>
    </ConditionalWrapper>
  )
}
