import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconSharedTool } from './prismIconsTypes'

/**
 * Prism Shared Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=7766%3A15370
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismSharedToolIcon = ({ hasBackground, className, variant = 'square' }: PrismIconSharedTool) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      {variant === 'square' && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 5H11C10.4477 5 10 5.44772 10 6V8H9V6C9 4.89543 9.89543 4 11 4H18C19.1046 4 20 4.89543 20 6V13C20 14.1046 19.1046 15 18 15H11C9.89543 15 9 14.1046 9 13V11H10V13C10 13.5523 10.4477 14 11 14H18C18.5523 14 19 13.5523 19 13V6C19 5.44772 18.5523 5 18 5Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 19H13C13.5523 19 14 18.5523 14 18V16H15V18C15 19.1046 14.1046 20 13 20H6C4.89543 20 4 19.1046 4 18V11C4 9.89543 4.89543 9 6 9H13C14.1046 9 15 9.89543 15 11V13H14V11C14 10.4477 13.5523 10 13 10H6C5.44772 10 5 10.4477 5 11V18C5 18.5523 5.44772 19 6 19Z"
            fill="currentColor"
          />
        </>
      )}
      {variant === 'circle' && (
        <>
          <path
            d="M13.9843 4C11.7106 4 9.73156 5.25822 8.70856 7.11408C9.11889 7.0392 9.54155 7.00018 9.97303 7.00018C10.8878 5.78555 12.3441 5.00018 13.9843 5.00018C16.7533 5.00018 18.9978 7.23888 18.9978 10C18.9978 11.6358 18.2099 13.0881 16.992 14.0004C16.6751 14.2377 16.3289 14.4384 15.9598 14.5962C15.5957 14.7523 15.209 14.8662 14.8064 14.9323C14.5388 14.9763 14.2642 14.9998 13.984 14.9998C13.9103 14.9998 13.837 14.9981 13.764 14.9949C11.1702 14.8831 9.08751 12.8061 8.97541 10.2194C8.62924 10.3104 8.30175 10.4472 8 10.6237C8.29153 13.4393 10.5356 15.6773 13.3589 15.968C13.5645 15.9891 13.7732 16 13.984 16C14.1503 16 14.3153 15.993 14.4785 15.9796C14.9107 15.9445 15.3299 15.8639 15.7314 15.7427C16.1329 15.6214 16.5164 15.4593 16.8774 15.2614C18.7387 14.2412 20 12.2675 20 10C20.0007 6.68624 17.3071 4 13.9843 4Z"
            fill="currentColor"
          />
          <path
            d="M13.9056 16.9986C13.006 18.1606 11.5963 18.9091 10.0116 18.9091C7.29517 18.9091 5.09299 16.711 5.09299 13.9996C5.09299 12.4176 5.8429 11.0105 7.00704 10.113C7.31983 9.87179 7.6622 9.66753 8.02818 9.5065C8.36034 9.36025 8.71152 9.2495 9.07714 9.17954C9.37971 9.12153 9.69214 9.09059 10.0116 9.09059C10.0465 9.09059 10.081 9.09129 10.1155 9.092C12.7492 9.14649 14.8739 11.2672 14.9288 13.8959C15.3096 13.8073 15.6696 13.6646 16 13.4762C15.7492 10.5802 13.4375 8.27317 10.5361 8.02285C10.3632 8.00809 10.1884 8 10.0116 8C9.80979 8 9.61078 8.0102 9.41388 8.02953C9.01867 8.06856 8.63473 8.1459 8.26559 8.25735C7.86439 8.37865 7.4808 8.54072 7.11976 8.73866C5.2603 9.75858 4 11.7323 4 13.9996C4 17.3136 6.69144 20 10.0116 20C12.2836 20 14.2607 18.7421 15.2828 16.8857C14.8728 16.9606 14.4505 17 14.019 17C13.981 17 13.9433 16.9993 13.9056 16.9986Z"
            fill="currentColor"
          />
        </>
      )}
    </svg>
  )
}
