import React from 'react'

import { Redirect } from 'react-router-dom'

import Layout from 'components/Layout/Layout'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useColocatedStation, useTypedSelector } from 'hooks'
import paths from 'paths'
import { getLastStationDetailMode } from 'utils'

/**
 * Forces user into Station Detail screen if colocated and not high role enough to access Inspect screen.
 */
export default function ColocatedStationRedirect({ inspectTab }: { inspectTab?: 'site' | 'product' }) {
  const locationHistory = useTypedSelector(state => state.locationHistory)

  const { colocatedStation, isFetching } = useColocatedStation()
  if (isFetching && !colocatedStation) {
    return (
      <Layout title={null}>
        <PrismLoader fullScreen />
      </Layout>
    )
  }

  if (!colocatedStation) return <Redirect to={{ pathname: paths.inspect({ mode: inspectTab || 'site' }) }} />

  // If we have colocated robots, render the colocated station's detail page with the last mode the user was on.
  const lastStationDetailMode = getLastStationDetailMode(locationHistory, colocatedStation.id)
  return <Redirect to={paths.stationDetail(lastStationDetailMode, colocatedStation.id)} />
}
