import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism download Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A599&t=4VXZ2VbRSNoNeNpm-0
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismDownloadIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M10 11.5L12.5 14M12.5 14L15 11.5M12.5 14C12.5 11.364 12.5 6.63604 12.5 4"
        stroke="#C9C9C9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20 10V19H5V10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
