import { Action } from 'rdx/actions'
import { RootState } from 'types'

const getInitialState = () => {
  return []
}

export default function reduce(state: RootState['modals'] = getInitialState(), action: Action) {
  switch (action.type) {
    case 'MODALS_UPDATE': {
      const { operation, modalId } = action.payload
      if (operation === 'add') {
        if (state.includes(modalId)) return state

        return [modalId, ...state]
      }
      if (operation === 'remove') {
        return state.filter(id => id !== modalId)
      }
      return { ...state }
    }

    default:
      return state
  }
}
