import React from 'react'

import Styles from './Divider.module.scss'

/**
 * Renders a horizontal divider with space above and below
 *
 * @param type – Allow divider to be vertical (still WIP)
 * @param size – Add space around the divider
 * @param className - Custom className
 */
export const Divider = ({
  type = 'horizontal',
  className = '',
  key,
}: {
  type?: 'horizontal' | 'vertical'
  key?: string
  className?: string
}) => {
  if (type === 'vertical') {
    return <div className={`${Styles.prismDividerVertical} ${className}`} key={key} />
  }
  return <div className={`${Styles.prismDivider} ${className}`} key={key} />
}
