import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Info Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1405%3A148
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismInfoIcon = ({ hasBackground, isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${isActive ? Styles.active : ''} ${Styles.infoIcon} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        id="Vector"
        d="M12 3C7.02995 3 3 7.02995 3 12C3 16.9701 7.02995 21 12 21C16.9701 21 21 16.9701 21 12C21 7.02995 16.9701 3 12 3Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
      <path id="Vector_2" d="M12 11V16.5" stroke="currentColor" strokeLinecap="round" />
      <path
        id="Vector_3"
        d="M12 7C11.8022 7 11.6089 7.05865 11.4444 7.16853C11.28 7.27841 11.1518 7.43459 11.0761 7.61732C11.0004 7.80004 10.9806 8.00111 11.0192 8.19509C11.0578 8.38907 11.153 8.56725 11.2929 8.70711C11.4327 8.84696 11.6109 8.9422 11.8049 8.98078C11.9989 9.01937 12.2 8.99957 12.3827 8.92388C12.5654 8.84819 12.7216 8.72002 12.8315 8.55557C12.9414 8.39112 13 8.19778 13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7Z"
        fill="currentColor"
      />
    </svg>
  )
}
