import React from 'react'

import { BreadcrumbLabel } from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'components/Layout/Layout'
import PrismSideNavLayout from 'components/PrismSideNavLayout/PrismSideNavLayout'
import paths from 'paths'

import ContactInformation from './ContactInformation'
import { Notifications } from './Notifications'
import Styles from './Notify.module.scss'
import Subscriptions from './Subscriptions/Subscriptions'

/*
 * E&N v1 feature considerations:
 * - The backend allows for a lot of flexibility and the designs do not match that. Thats the main reason why
 *   the frontend has to assume certain things, aswell as deal with some edge cases.
 * - Since the backend supports a lot of things, the frontend is responsible for keeping the data `in synced`
 * - Be ***extremely careful*** when changing anything regarding this feature, since this could introduce corrupt data to the db
 *
 * Glossary:
 * - EventSubTypeTemplates: An `EventSub` record with an empty array as their `EventSub.targets` field.
 *   - Backend:
 *     - will use this template to subscribe newly created stations/products to only the `EventSub.type_id` values in these templates.
 *   - Frontend:
 *     - Is responsible for deleting and creating them when a user modifies the `Notifications` tab.
 *
 * - EventSubTargetTemplates: An `EventSub` record with a null `EventSub.type_id` field.
 *   - Backend:
 *     - will use this template to subscribe newly created quality events to only the `EventSub.targets` values in these templates.
 *     - On deploy, the backend will run a migration to create these templates for every station and product across all orgs.
 *   - Frontend:
 *     - Is responsible for deleting and creating them when suscribing/unsubscribing a station/product in the `Subscriptions` tab.
 *     - Will use this template to render the suscribed stations/products on the `Subscriptions` tab
 *
 * - EventSubs with multiple elements in their targets field: This would be useful when creating a subscription of only a subset
 *   of specific targets. For example, if we have `[{id:1, table: station}, {id:1, table: component} ]` this would mean that we
 *   are only subscribed to that Component in that particular Station.
 */
const Notify = () => {
  return (
    <Layout title={<BreadcrumbLabel className={Styles.headerBreadcrumb}>Notify</BreadcrumbLabel>}>
      <PrismSideNavLayout
        getPath={paths.settingsNotify}
        data-testid="notify"
        listItems={[
          { label: 'Subscriptions', value: 'subscriptions', component: <Subscriptions /> },
          { label: 'Notifications', value: 'notifications', component: <Notifications /> },
          {
            label: 'Contact Information',
            value: 'contactInformation',
            component: <ContactInformation />,
          },
        ]}
      />
    </Layout>
  )
}

export default Notify
