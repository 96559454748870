import React, { useState } from 'react'

import { Radio } from 'antd'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { getterKeys, service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, useData, useToolTrainingResults } from 'hooks'
import {
  THRESHOLD_MAX,
  THRESHOLD_MIN,
  THRESHOLD_STEP,
  TrainingReport,
} from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import Shared from 'styles/Shared.module.scss'
import { GARToolThreshold, RoutineWithAois, Tool, TrainableToolSettingsProps } from 'types'
import {
  calculateTrainingMetrics,
  computeConfusionMatrix,
  getThresholdFromBackendValues,
  getThresholdFromUserFacingValues,
  getUserFacingRecommendedValueFromTool,
} from 'utils'
import { DEFAULT_TOOL_LABELS_GETTER_KEY } from 'utils/constants'

import { SharedTooltip } from '../ToolCard'
import EditAoisHelper from './AOIEditing/EditAoisHelper'
import { isValidAoi } from './AOIEditing/Utils'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders Anomaly Tool form.
 *
 * This shows the name of the anomaly tool and the dataset and level of training
 * linked to it. As well as the threshold the anomaly tool is using as it's pass criteria.
 *
 * @param routine - The routine we are working on.`
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const GradedAnomalyToolSettings = ({
  routine,
  recipe,
  tool,
  view,
  onExit,
  readOnly,
}: TrainableToolSettingsProps) => {
  const [showThresholdModal, setShowThresholdModal] = useState(false)
  const { allToolLabels } = useAllToolLabels()
  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
  } = formData

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const trainingResults = useToolTrainingResults(tool.id) || []

  const defaultLabels = useData(getterKeys.toolLabels(DEFAULT_TOOL_LABELS_GETTER_KEY))?.results

  const saveChanges = () => {
    const { thresholds, amber_is_pass, aois } = getValues()
    const { dirtyFields } = formState

    if (dirtyFields.thresholds || dirtyFields.amber_is_pass) {
      const matrix = computeConfusionMatrix(trainingResults, tool.specification_name, {
        defaultLabels,
        allToolLabels,
        threshold: thresholds,
      })
      const training_metrics = calculateTrainingMetrics(matrix, allToolLabels, tool)

      const updatedInferenceUserArgs = {
        ...tool.inference_user_args,
        lower_threshold: thresholds.lowerThreshold,
        upper_threshold: thresholds.upperThreshold,
        amber_is_pass,
      }

      return [
        service.patchProtectedTool(tool.id, {
          aois: aois.map(aoi => ({ id: aoi.id, inference_user_args: updatedInferenceUserArgs })),
          metadata: { ...tool.metadata, training_metrics },
        }),
      ]
    }
  }

  const sliderEnabled = tool.state === 'successful'

  const recommendedValue = getUserFacingRecommendedValueFromTool(tool)

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    wrapperClassName={Styles.settingsItemInput}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    data-testid="anomaly-tool-rename-input"
                  />
                )}
              />
              {tool.is_shared && <SharedTooltip iconClassName={Styles.sharedToolIcon} />}
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItemTitle}>Pass Criteria</div>
            <div
              className={`${Styles.settingsItemCriteria} ${Styles.matchCriteriaContainer} ${Shared.verticalChildrenGap24}`}
            >
              <Controller
                control={control}
                name="thresholds"
                render={({
                  onChange,
                  value,
                }: {
                  onChange: (threshold: GARToolThreshold) => any
                  value: GARToolThreshold
                }) => {
                  return (
                    <div className={tool.is_shared ? Styles.sharedTool : ''}>
                      <div data-testid="graded-anomaly-tool-settings-threshold">
                        <PrismSliderRange
                          label="Edit Threshold"
                          valueStart={value.userFacingLowerThreshold}
                          sliderColor="amber"
                          hideRangeText={!sliderEnabled}
                          valueEnd={value.userFacingUpperThreshold}
                          disabled={!sliderEnabled}
                          min={THRESHOLD_MIN}
                          max={THRESHOLD_MAX}
                          step={THRESHOLD_STEP}
                          onChange={(v: [number, number]) => {
                            const updatedThreshold = getThresholdFromUserFacingValues(
                              { userFacingLowerThreshold: v[0], userFacingUpperThreshold: v[1] },
                              tool.specification_name,
                            )
                            onChange(updatedThreshold as GARToolThreshold)
                          }}
                        />
                      </div>

                      {sliderEnabled && (
                        <div className={Styles.description}>
                          This tool will predict Good if its score is above range, Minor if within range, and Critical
                          if below. Good results in Pass, Minor optionally results in Pass or Fail, and Critical in
                          Fail.
                          <br />
                          <br />
                          You can{' '}
                          <span onClick={() => setShowThresholdModal(true)} className={Styles.highlighted}>
                            test changes
                          </span>{' '}
                          to threshold on your training images.
                          {recommendedValue && ` Recommended range is ${recommendedValue}.`}
                          <br />
                          <br />
                          Changes will only impact new items.
                        </div>
                      )}

                      {!sliderEnabled && (
                        <div className={Styles.description}>
                          You can set the threshold for this tool after you have trained it.
                        </div>
                      )}

                      {showThresholdModal && (
                        <TrainingReport
                          onClose={() => setShowThresholdModal(false)}
                          onCancelThreshold={() => setShowThresholdModal(false)}
                          toolId={tool.id}
                          modifiedThreshold={getValues('thresholds')}
                          thresholdButtonText="Apply"
                          forceTitle="Threshold Test Ground"
                          forceThreshold
                          onSaveThreshold={thresholds => {
                            onChange(thresholds as GARToolThreshold)
                            setShowThresholdModal(false)
                          }}
                          viewInInspection={view}
                          toolWithOverridesContext={tool}
                        />
                      )}
                    </div>
                  )
                }}
              />

              <Token
                label="Outcome of Minor Defects"
                className={Styles.radioWrapper}
                labelClassName={Styles.radioTitle}
              >
                <Controller
                  control={control}
                  name="amber_is_pass"
                  render={({ onChange, value }) => (
                    <Radio.Group onChange={e => onChange(e.target.value)} value={value} className={Shared.container8}>
                      <Radio value className={Styles.radioContainer} data-testid="gar-tool-settings-amber-pass">
                        Pass
                      </Radio>
                      <Radio value={false} className={Styles.radioContainer} data-testid="gar-tool-settings-amber-fail">
                        Fail
                      </Radio>
                    </Radio.Group>
                  )}
                />
              </Token>
            </div>
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          rules={{ validate: aois => isValidAoi(aois[0]) }}
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              disableToggleButtons={showThresholdModal}
              setAois={(...args) => {
                onChange(...args)
              }}
              enabledShapes={['rectangle', 'ellipse', 'polygon']}
              disableRectangleRotation={false}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const lowerThreshold =
    tool.inference_user_args.lower_threshold !== undefined
      ? tool.inference_user_args.lower_threshold
      : tool.metadata.lower_threshold

  const upperThreshold =
    tool.inference_user_args.upper_threshold !== undefined
      ? tool.inference_user_args.upper_threshold
      : tool.metadata.upper_threshold

  const { name, aois } = getToolNameAndAois(routine, tool)

  return {
    name,
    aois,
    // Flip from backend threshold to user facing threshold
    thresholds: getThresholdFromBackendValues(
      { lowerThreshold, upperThreshold },
      tool.specification_name,
    ) as GARToolThreshold,
    amber_is_pass: tool.inference_user_args.amber_is_pass,
  }
}
