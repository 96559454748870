import React, { RefObject } from 'react'

import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { Token } from 'components/Token/Token'

import Styles from './PrismMetrics.module.scss'

type ProgressBarProps =
  | {
      hasProgressBar: true
      value: number
    }
  | { hasProgressBar?: false; value: React.ReactNode }

type Props = {
  label: string
  count?: number | string
  icon?: React.ReactNode
  onClick?: () => void
  active?: boolean
  className?: string
  'data-testid'?: string
  'data-test-attribute'?: string
  textRef?: RefObject<HTMLHeadingElement>
} & ProgressBarProps

/**
 * Renders a Prism Metric Container, can be used in two forms.
 * The first form is activated when hasProgressBar is added as a prop and it consist of 3 elementes: the icon severity, the label and the progress bar.
 *
 * https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=1859%3A16678
 *
 * The second form when hasProgressBar is not added, it displays a token with a label and a value.
 *
 *  https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=1859%3A16678
 *
 * @param label - string for the title.
 * @param count - the metrics count, only visible if hasProgressBar is been used.
 * @param hasProgressBar - when true displays the progressbar and the icon space
 * @param icon - An icon placed on the upper right corner, only visible if hasProgressBar is been used.
 * @param value - if hasProgressBar is true, it is the value of the progress bar, otherwise a React node representing the metrics value.
 * @param onClick - click handler
 * @param active - a boolean that add a special background color
 * @param className - string that hold the css selector
 */
export const PrismMetrics = (props: Props) => {
  // Use destructuring here instead of params so that TS can infer the type of the value prop based on hasProgressBar prop,
  // that's why we only use props.hasProgressBar and props.value directly.
  const {
    label,
    count,
    icon,
    onClick,
    active = false,
    className,
    'data-testid': dataTestId,
    'data-test-attribute': dataTestAttribute,
    textRef,
  } = props

  return (
    <Token
      className={`${Styles.prismMetricsContainer} ${active ? Styles.active : ''} ${className ?? ''} `}
      label={
        <div className={Styles.metricsLabelContainer}>
          {props.icon ? icon : ''}
          <h6 className={Styles.metricsLabel} ref={textRef}>
            {label}
          </h6>
          {props.hasProgressBar ? <p className={Styles.metricsCount}>{count}</p> : ''}
        </div>
      }
      onClick={onClick}
      data-testid={dataTestId}
      data-test-attribute={dataTestAttribute}
    >
      {props.hasProgressBar && (
        <ProgressBar
          className={`${Styles.prismMetricsProgressBar} ${
            props.value >= 100 ? Styles.prismMetricsProgressBarFilled : ''
          }`}
          progress={props.value}
          height="small"
        />
      )}

      {!props.hasProgressBar && (
        <div className={Styles.prismMetricsValue} data-testid={`${dataTestId}-count`}>
          {props.value}
        </div>
      )}
    </Token>
  )
}
