import { PreprocessingOptions } from 'types'

import { OpenCvMat } from '../OpenCv/openCvTypes'

export const resize = (cv: OpenCvMat, src: OpenCvMat, scaleX: number, scaleY: number) => {
  const dst = new cv.Mat()
  const { height: h, width: w } = src.size()
  const scaledSizeX = w * scaleX
  const scaledSizeY = h * scaleY
  const dsize = new cv.Size(scaledSizeX, scaledSizeY)
  cv.resize(src, dst, dsize, 0, 0, cv.INTER_AREA)
  return dst
}

export const rotate = (cv: OpenCvMat, src: OpenCvMat, angle: number, scale = 1) => {
  const dst = new cv.Mat()

  const { height: h, width: w } = src.size()
  const center = new cv.Point(w / 2, h / 2)

  const M = cv.getRotationMatrix2D(center, -angle, scale)
  const cos = Math.abs(M.doubleAt(0))
  const sin = Math.abs(M.doubleAt(3))

  // compute the new bounding dimensions of the image
  const nW = Math.floor(h * sin + w * cos)
  const nH = Math.floor(h * cos + w * sin)

  // Use new mat to avoid overwriting the first one when generating nW, nH
  const M2 = M.clone()
  M2['data64F'][2] = M.doubleAt(2) + (nW / 2 - center.x)
  M2['data64F'][5] = M.doubleAt(5) + (nH / 2 - center.y)

  cv.warpAffine(src, dst, M2, new cv.Size(nW, nH))

  return dst
}

// https://docs.opencv.org/3.4/db/d64/tutorial_js_colorspaces.html
export const hsvFilter = (cv: OpenCvMat, src: OpenCvMat, hsv_min: number[], hsv_max: number[]) => {
  const dst = new cv.Mat()
  cv.cvtColor(src, dst, cv.COLOR_RGB2HSV)

  const low = new cv.Mat(dst.rows, dst.cols, dst.type(), [...hsv_min, 0])
  const high = new cv.Mat(dst.rows, dst.cols, dst.type(), [...hsv_max, 255])

  cv.inRange(dst, low, high, dst)

  return dst
}

const dilate = (
  cv: OpenCvMat,
  src: OpenCvMat,
  dilation_iterations: PreprocessingOptions['dilation_iterations'],
  dilation_width: PreprocessingOptions['dilation_width'],
  dilation_height: PreprocessingOptions['dilation_height'],
) => {
  const dst = new cv.Mat()

  const kernel = cv.Mat.ones(dilation_height, dilation_width, cv.CV_8U)
  // https://docs.opencv.org/master/d4/d76/tutorial_js_morphological_ops.html
  const anchor = new cv.Point(-1, -1)

  cv.dilate(src, dst, kernel, anchor, dilation_iterations, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue())
  return dst
}

const erode = (
  cv: OpenCvMat,
  src: OpenCvMat,
  erosion_iterations: PreprocessingOptions['erosion_iterations'],
  erosion_width: PreprocessingOptions['erosion_width'],
  erosion_height: PreprocessingOptions['erosion_height'],
) => {
  const dst = new cv.Mat()

  const kernel = cv.Mat.ones(erosion_height, erosion_width, cv.CV_8U)
  // https://docs.opencv.org/master/d4/d76/tutorial_js_morphological_ops.html
  const anchor = new cv.Point(-1, -1)

  cv.erode(src, dst, kernel, anchor, erosion_iterations, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue())
  return dst
}

export const dilateAndErode = (cv: OpenCvMat, src: OpenCvMat, settings: PreprocessingOptions) => {
  if (settings.dilation_enable && settings.erosion_enable) {
    if (settings.erosion_first) {
      const eroded = erode(cv, src, settings.erosion_iterations, settings.erosion_width, settings.erosion_height)
      const erodedDilated = dilate(
        cv,
        eroded,
        settings.dilation_iterations,
        settings.dilation_width,
        settings.dilation_height,
      )
      return erodedDilated
    } else {
      const dilated = dilate(cv, src, settings.dilation_iterations, settings.dilation_width, settings.dilation_height)
      const dilatedEroded = erode(
        cv,
        dilated,
        settings.erosion_iterations,
        settings.erosion_width,
        settings.erosion_height,
      )
      return dilatedEroded
    }
  }
  if (settings.dilation_enable) {
    return dilate(cv, src, settings.dilation_iterations, settings.dilation_width, settings.dilation_height)
  }
  if (settings.erosion_enable) {
    return erode(cv, src, settings.erosion_iterations, settings.erosion_width, settings.erosion_height)
  }
  return src
}
