import React from 'react'

import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { PrismSearchIcon } from 'components/prismIcons'
import { AnalyzeTab } from 'types'

import Styles from './Analyze.module.scss'

export const AnalyzeEmptyState = ({ isGalleryView, tab }: { isGalleryView?: boolean; tab: AnalyzeTab }) => {
  return (
    <GenericBlankStateMessage
      description={`No ${tab} match your filters`}
      descriptionDataTestId="analyze-empty-state-message"
      header={<PrismSearchIcon />}
      className={`${Styles.emptyStateWrapper} ${isGalleryView ? Styles.emptyStateGallery : ''}`}
    />
  )
}
