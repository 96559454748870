import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

import { pixelsIntoPoints } from '../styles'

const iconSize = pixelsIntoPoints(22)

const iconColor = '#4a4a4a'

const viewboxSizeClasses = {
  small: '5 2 16 16',
  medium: '0 0 20 20',
}

interface iconProps {
  icon: 'good' | 'neutral' | 'minor' | 'critical' | 'pass' | 'fail' | 'unknown' | 'discard'
  size?: 'small' | 'medium'
}

const PDFIcon = ({ icon, size = 'medium' }: iconProps) => {
  const sizeClass = viewboxSizeClasses[size]
  return (
    <Svg style={{ width: iconSize, height: iconSize }} viewBox={sizeClass}>
      {icon === 'good' && (
        <Path
          d="M6.46967 10.4697C6.17678 10.7626 6.17678 11.2374 6.46967 11.5303C6.76256 11.8232 7.23744 11.8232 7.53033 11.5303L6.46967 10.4697ZM10 8L10.5303 7.46967C10.3897 7.32902 10.1989 7.25 10 7.25C9.80109 7.25 9.61032 7.32902 9.46967 7.46967L10 8ZM12.4697 11.5303C12.7626 11.8232 13.2374 11.8232 13.5303 11.5303C13.8232 11.2374 13.8232 10.7626 13.5303 10.4697L12.4697 11.5303ZM7.53033 11.5303L10.5303 8.53033L9.46967 7.46967L6.46967 10.4697L7.53033 11.5303ZM9.46967 8.53033L12.4697 11.5303L13.5303 10.4697L10.5303 7.46967L9.46967 8.53033Z"
          fill={iconColor}
        />
      )}

      {icon === 'minor' && (
        <Path
          d="M6.46967 10.4697C6.17678 10.7626 6.17678 11.2374 6.46967 11.5303C6.76256 11.8232 7.23744 11.8232 7.53033 11.5303L6.46967 10.4697ZM10 8L10.5303 7.46967C10.3897 7.32902 10.1989 7.25 10 7.25C9.80109 7.25 9.61032 7.32902 9.46967 7.46967L10 8ZM12.4697 11.5303C12.7626 11.8232 13.2374 11.8232 13.5303 11.5303C13.8232 11.2374 13.8232 10.7626 13.5303 10.4697L12.4697 11.5303ZM7.53033 11.5303L10.5303 8.53033L9.46967 7.46967L6.46967 10.4697L7.53033 11.5303ZM9.46967 8.53033L12.4697 11.5303L13.5303 10.4697L10.5303 7.46967L9.46967 8.53033Z"
          fill={iconColor}
        />
      )}

      {icon === 'critical' && (
        <>
          <Path
            d="M13.5303 11.5303C13.8232 11.2374 13.8232 10.7626 13.5303 10.4697C13.2374 10.1768 12.7626 10.1768 12.4697 10.4697L13.5303 11.5303ZM10 14L9.46967 14.5303C9.61032 14.671 9.80109 14.75 10 14.75C10.1989 14.75 10.3897 14.671 10.5303 14.5303L10 14ZM7.53033 10.4697C7.23744 10.1768 6.76256 10.1768 6.46967 10.4697C6.17678 10.7626 6.17678 11.2374 6.46967 11.5303L7.53033 10.4697ZM12.4697 10.4697L9.46967 13.4697L10.5303 14.5303L13.5303 11.5303L12.4697 10.4697ZM10.5303 13.4697L7.53033 10.4697L6.46967 11.5303L9.46967 14.5303L10.5303 13.4697Z"
            fill={iconColor}
          />
          <Path
            d="M13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967C13.2374 5.17678 12.7626 5.17678 12.4697 5.46967L13.5303 6.53033ZM10 9L9.46967 9.53033C9.61032 9.67098 9.80109 9.75 10 9.75C10.1989 9.75 10.3897 9.67098 10.5303 9.53033L10 9ZM7.53033 5.46967C7.23744 5.17678 6.76256 5.17678 6.46967 5.46967C6.17678 5.76256 6.17678 6.23744 6.46967 6.53033L7.53033 5.46967ZM12.4697 5.46967L9.46967 8.46967L10.5303 9.53033L13.5303 6.53033L12.4697 5.46967ZM10.5303 8.46967L7.53033 5.46967L6.46967 6.53033L9.46967 9.53033L10.5303 8.46967Z"
            fill={iconColor}
          />
        </>
      )}

      {icon === 'neutral' && (
        <>
          <Path
            d="M7 7.75C7 7.33579 7.33579 7 7.75 7H12.25C12.6642 7 13 7.33579 13 7.75C13 8.16421 12.6642 8.5 12.25 8.5H7.75C7.33579 8.5 7 8.16421 7 7.75Z"
            fill={iconColor}
          />
          <Path
            d="M7 11.75C7 11.3358 7.33579 11 7.75 11H12.25C12.6642 11 13 11.3358 13 11.75C13 12.1642 12.6642 12.5 12.25 12.5H7.75C7.33579 12.5 7 12.1642 7 11.75Z"
            fill={iconColor}
          />
        </>
      )}

      {icon === 'pass' && (
        <Path
          d="M15.0694 6.48809C15.339 6.1736 15.3026 5.70012 14.9881 5.43056C14.6736 5.16099 14.2001 5.19741 13.9306 5.51191L15.0694 6.48809ZM8.5 13L7.96967 13.5303C8.11739 13.6781 8.32003 13.7575 8.52878 13.7494C8.73754 13.7414 8.93349 13.6467 9.06944 13.4881L8.5 13ZM6.53033 9.96967C6.23744 9.67678 5.76256 9.67678 5.46967 9.96967C5.17678 10.2626 5.17678 10.7374 5.46967 11.0303L6.53033 9.96967ZM13.9306 5.51191L7.93056 12.5119L9.06944 13.4881L15.0694 6.48809L13.9306 5.51191ZM9.03033 12.4697L6.53033 9.96967L5.46967 11.0303L7.96967 13.5303L9.03033 12.4697Z"
          fill={iconColor}
        />
      )}

      {icon === 'fail' && (
        <Path
          fillRule="evenodd"
          d="M11.8874 12.948C11.3428 13.2974 10.6951 13.5 10 13.5C8.067 13.5 6.5 11.933 6.5 10C6.5 9.30495 6.7026 8.65721 7.05197 8.11263L11.8874 12.948ZM12.948 11.8874L8.11263 7.05197C8.65721 6.7026 9.30495 6.5 10 6.5C11.933 6.5 13.5 8.067 13.5 10C13.5 10.6951 13.2974 11.3428 12.948 11.8874ZM15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
          fill={iconColor}
        />
      )}

      {icon === 'unknown' && (
        <>
          <Path
            fillRule="evenodd"
            d="M10.5225 5.85784C10.1513 5.83217 9.78024 5.90904 9.4498 6.08002C9.11936 6.25101 8.84228 6.50954 8.64885 6.82736C8.45542 7.14518 8.35307 7.51006 8.353 7.88212C8.35293 8.25574 8.04999 8.55856 7.67637 8.55849C7.30275 8.55842 6.99993 8.25548 7 7.88186C7.00012 7.26176 7.17069 6.65364 7.49308 6.12393C7.81547 5.59423 8.27726 5.16335 8.82799 4.87837C9.37872 4.59339 9.99719 4.46529 10.6158 4.50806C11.2344 4.55083 11.8294 4.76283 12.3357 5.12089C12.8419 5.47895 13.24 5.96929 13.4864 6.53832C13.7329 7.10734 13.8181 7.73316 13.7329 8.34737C13.6477 8.96158 13.3953 9.54055 13.0032 10.021C12.6112 10.5014 12.0946 10.8649 11.51 11.0716C11.3781 11.1182 11.2638 11.2046 11.183 11.3189C11.1023 11.4332 11.0589 11.5698 11.059 11.7097V11.941C11.059 12.3146 10.7561 12.6175 10.3825 12.6175C10.0089 12.6175 9.706 12.3146 9.706 11.941V11.7104C9.706 11.7103 9.706 11.7106 9.706 11.7104C9.70586 11.2907 9.83586 10.8809 10.0781 10.5381C10.3204 10.1952 10.6631 9.93589 11.059 9.79594C11.4098 9.67192 11.7197 9.45386 11.9549 9.1656C12.1902 8.87733 12.3416 8.52995 12.3927 8.16142C12.4439 7.7929 12.3927 7.41741 12.2449 7.07599C12.097 6.73457 11.8582 6.44037 11.5544 6.22553C11.2506 6.0107 10.8937 5.8835 10.5225 5.85784Z"
            fill={iconColor}
          />
          <Path
            d="M10.3824 14.647C10.3155 14.647 10.2501 14.6668 10.1945 14.704C10.1389 14.7412 10.0955 14.794 10.0699 14.8558C10.0443 14.9176 10.0376 14.9856 10.0507 15.0512C10.0637 15.1168 10.096 15.1771 10.1433 15.2244C10.1906 15.2717 10.2508 15.3039 10.3164 15.317C10.3821 15.33 10.4501 15.3233 10.5119 15.2977C10.5737 15.2721 10.6265 15.2288 10.6637 15.1732C10.7009 15.1175 10.7207 15.0521 10.7207 14.9852C10.7207 14.8955 10.6851 14.8095 10.6216 14.7461C10.5582 14.6826 10.4721 14.647 10.3824 14.647Z"
            fill={iconColor}
          />
          <Path
            fillRule="evenodd"
            d="M9.81866 14.1415C9.98554 14.03 10.1817 13.9705 10.3824 13.9705C10.6516 13.9705 10.9097 14.0774 11.1 14.2677C11.2903 14.458 11.3972 14.7161 11.3972 14.9853C11.3972 15.186 11.3377 15.3821 11.2262 15.549C11.1147 15.7159 10.9562 15.846 10.7708 15.9228C10.5853 15.9996 10.3813 16.0197 10.1845 15.9805C9.98762 15.9414 9.80681 15.8447 9.66489 15.7028C9.52297 15.5609 9.42633 15.3801 9.38717 15.1832C9.34802 14.9864 9.36811 14.7824 9.44492 14.5969C9.52172 14.4115 9.65179 14.253 9.81866 14.1415ZM10.5119 15.2978C10.5322 15.2893 10.5518 15.2789 10.5703 15.2665C10.6075 15.2417 10.6391 15.2099 10.6637 15.1732C10.6759 15.1548 10.6864 15.1353 10.6949 15.1147C10.712 15.0735 10.7207 15.0295 10.7207 14.9853C10.7207 14.9632 10.7185 14.9411 10.7142 14.9193C10.7011 14.8537 10.6689 14.7934 10.6216 14.7461C10.5743 14.6988 10.514 14.6666 10.4484 14.6535C10.4266 14.6492 10.4045 14.647 10.3824 14.647C10.3382 14.647 10.2942 14.6557 10.253 14.6728C10.2324 14.6813 10.2128 14.6918 10.1945 14.704C10.1577 14.7286 10.126 14.7602 10.1012 14.7973C10.0888 14.8158 10.0784 14.8354 10.0699 14.8558C10.053 14.8967 10.0442 14.9406 10.0442 14.9853C10.0442 15.0076 10.0464 15.0297 10.0507 15.0512C10.0636 15.1164 10.0956 15.1768 10.1432 15.2244C10.1909 15.2721 10.2513 15.3041 10.3164 15.317C10.338 15.3213 10.3601 15.3235 10.3824 15.3235C10.4271 15.3235 10.471 15.3147 10.5119 15.2978Z"
            fill={iconColor}
          />
        </>
      )}
      {icon === 'discard' && (
        <>
          <Path
            fillRule="evenodd"
            d="M4.74991 6.11758L5.35967 15.2998C5.38583 15.6938 5.7131 16.0001 6.10803 16.0001H13.8919C14.2869 16.0001 14.6141 15.6938 14.6403 15.2998L15.2501 6.11758H4.74991ZM6.08567 7.36758L6.57592 14.7501H13.4241L13.9143 7.36758H6.08567Z"
            fill={iconColor}
          />
          <Path
            d="M6.99994 4.74995C6.99994 4.33573 7.33573 3.99994 7.74995 3.99994H12.25C12.6642 3.99994 13 4.33573 13 4.74995V4.99994H6.99994V4.74995Z"
            fill={iconColor}
          />
          <Path
            fillRule="evenodd"
            d="M16.25 7.11758V6.86759C16.25 6.45337 15.9142 6.11758 15.5 6.11758H4.50001C4.08579 6.11758 3.75 6.45337 3.75 6.86759V7.11758H16.25Z"
            fill={iconColor}
          />
        </>
      )}
    </Svg>
  )
}
export default PDFIcon
