import React from 'react'

import Styles from './CardBox.module.scss'

const borderClasses = {
  border: Styles.borderContainer,
  borderless: Styles.borderless,
}

export const CardBoxHeader = ({
  title,
  actions,
  className,
}: {
  title: React.ReactNode
  actions: React.ReactNode
  className?: string
}) => {
  return (
    <div className={`${Styles.cardBoxHeader} ${className ?? ''}`}>
      <div className={Styles.cardBoxHeader_title}>{title}</div>
      <div className={Styles.cardBoxHeader_actions}>{actions}</div>
    </div>
  )
}

export const CardBox = ({
  className,
  title,
  actions,
  children,
  borderType = 'border',
  headerClassName,
  hideHeader,
}: {
  className?: string
  title: string | React.ReactNode
  actions: React.ReactNode
  children: React.ReactNode
  borderType?: 'border' | 'borderless'
  headerClassName?: string
  hideHeader?: boolean
}) => {
  const borderClass = borderClasses[borderType]
  return (
    <div className={`${Styles.cardBox} ${borderClass} ${className ?? ''}`}>
      {!hideHeader && <CardBoxHeader title={title} actions={actions} className={headerClassName ?? ''} />}
      <div className={Styles.cardBox_content}>{children}</div>
    </div>
  )
}
