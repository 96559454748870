import React from 'react'

import { PrismCard } from 'components/PrismCard/PrismCard'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { Token } from 'components/Token/Token'

import Styles from './BlankState.module.scss'

/** Renders a card with placeholder dashes to use when Station Detail Tools tab has no content */
const ToolBlankState = ({ isLoading }: { isLoading?: boolean }) => (
  <div className={Styles.noRoutineContainer}>
    <PrismCard
      type="notClickable"
      image={
        <>
          {isLoading && <PrismSkeleton size="extraLarge" />}
          {!isLoading && (
            <figure className={Styles.noRoutineImageContainer}>
              <PrismElementaryCube className={Styles.emptyCube} />
            </figure>
          )}
        </>
      }
    >
      <div className={Styles.multipleTokensContainer}>
        <TokenLoader label="Tool" loaderWidth={80} isLoading={isLoading} />

        <div className={Styles.bottomContainer}>
          <TokenLoader label="fail rate" loaderWidth={60} isLoading={isLoading} />
        </div>
      </div>
    </PrismCard>
  </div>
)

export default ToolBlankState

/** Renders a Token label and skeleton loader */
const TokenLoader = ({
  label,
  loaderWidth,
  isLoading,
}: {
  label: string
  loaderWidth: number
  isLoading?: boolean
}) => {
  return (
    <Token
      label={label}
      className={Styles.noRoutineToken}
      labelClassName={Styles.toolTitleColor}
      valueClassName={Styles.emptyToolValue}
    >
      {isLoading ? <PrismSkeleton size="medium" maxWidth={loaderWidth} /> : '--'}
    </Token>
  )
}
