import React from 'react'

import { isNumber } from 'lodash'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import { NonTrainableToolSettingsProps, RoutineWithAois, Tool } from 'types'
import { protectedOnChange } from 'utils'

import EditAoisHelper from './AOIEditing/EditAoisHelper'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders Random Tool form. This shows the name of the random tool and the
 * slider to set the passing rate.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const RandomToolSettings = ({ routine, recipe, tool, onExit, readOnly }: NonTrainableToolSettingsProps) => {
  const history = useHistory()

  const defaultValues = getDefaultFormValues(routine, tool)
  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    reset,
    formState,
    formState: { errors },
    control,
    getValues,
  } = formData

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const saveChanges = () => {
    const { passRate } = getValues()
    const { dirtyFields } = formState

    if (dirtyFields.passRate) {
      return [
        service.patchTool(tool.id, {
          inference_args: { ...tool.inference_args, pass_probability: passRate },
        }),
      ]
    }
  }

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    data-testid="random-tool-settings-name"
                    wrapperClassName={Styles.settingsItemInput}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                  />
                )}
              />
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemSlider}>
                <Controller
                  control={control}
                  name="passRate"
                  render={({ onChange, value }) => (
                    <PrismSlider
                      data-testid="random-tool-settings-pass-rate"
                      label="Pass Rate"
                      userFacingValue={`${(value * 100).toFixed(1)}%`}
                      value={value}
                      min={0}
                      max={1}
                      className={Styles.sliderOverride}
                      onChange={protectedOnChange((val: number) => onChange(val), { routine, history, recipe })}
                      disabled={readOnly}
                      step={0.001}
                    />
                  )}
                />
              </div>
            </div>
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              enabledShapes={['rectangle', 'ellipse', 'polygon']}
              disableRectangleRotation={false}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const { name, aois } = getToolNameAndAois(routine, tool)

  return {
    aois,
    name,
    passRate: isNumber(tool.inference_args.pass_probability) ? tool.inference_args.pass_probability : 0.5,
  }
}
