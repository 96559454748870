import React, { useMemo } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, SendToApiResponse, service } from 'api'
import { Button } from 'components/Button/Button'
import { PrismWarningIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, modal } from 'components/PrismModal/PrismModal'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import SessionExpiration from 'components/SessionExpiration/SessionExpiration'
import { useData, useIsFlourish, useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import { Organization, User, UserOrg, UserRole } from 'types'
import {
  getSessionLengthDisplayText,
  getSessionLengthSecondsFromFormValues,
  getSessionLengthUnitsAndTime,
  includes,
} from 'utils'

import { renderRole } from './UsersTable'
import Styles from './UsersTable.module.scss'

type Props = {
  onCancel: () => any
  onRefresh: () => Promise<void>
  user?: User
}
function UserConfigurationModal({ user, onRefresh, onCancel }: Props) {
  const me = useData(getterKeys.me())
  const { isFlourish } = useIsFlourish()
  const dispatch = useDispatch()
  const currentUserOrg = useTypedSelector(state => state.currentOrg)
  const organization = useData(getterKeys.organization())

  const defaultValues = getDefaultFormValues(user, organization)
  const {
    formState: { isDirty, isValid, errors, dirtyFields },
    control,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { sessionLengthTime, sessionLengthUnits } = watch()

  // If we're trying to edit a user that's an owner or a manager when logged in as anything but `owner`
  const noPermissionToEdit = useMemo(() => {
    return includes(['owner', 'manager'], user?.user_org.role) && currentUserOrg?.role !== 'owner'
  }, [user?.user_org.role, currentUserOrg?.role])

  const inactiveUser = user && !user.user_org.is_active

  const restoreSaveOrEditUser = async () => {
    if (inactiveUser) {
      editUserIsActive(true)
      return
    }

    const valid = await trigger()
    if (!valid) return 'invalid'

    const { first_name, last_name, email, role, sessionLengthTime, sessionLengthUnits } = getValues()

    const sessionLengthSeconds = getSessionLengthSecondsFromFormValues({ sessionLengthTime, sessionLengthUnits })
    const isSessionLengthDirty = dirtyFields.sessionLengthTime || dirtyFields.sessionLengthUnits

    // editing
    if (user?.id) {
      const promises: Promise<SendToApiResponse<User | UserOrg>>[] = []

      promises.push(service.patchUser(user.id, { first_name, last_name }))
      if (user.user_org && (role || sessionLengthSeconds))
        promises.push(
          service.updateUserOrg(user.user_org.id, {
            role,
            session_length_s: isSessionLengthDirty ? sessionLengthSeconds : undefined,
          }),
        )

      const promiseRes = await Promise.all(promises)
      if (promiseRes.some(res => res.type !== 'success'))
        return error({ title: 'Could not update user, make sure fields are correct' })

      // we only want to update redux if we are updating our own uer
      if (currentUserOrg && user.id === me?.id && (dirtyFields.role || isSessionLengthDirty))
        dispatch(Actions.orgUpdate({ role, session_length_s: sessionLengthSeconds }))

      success({ title: 'Edited' })
    }

    // creating
    else {
      const res = await service.createUserOrg({
        user_first_name: first_name,
        user_last_name: last_name,
        email,
        role,
        session_length_s: isSessionLengthDirty ? sessionLengthSeconds : undefined,
      })

      if (res.type !== 'success') return error({ title: 'Could not create user, make sure fields are correct' })

      success({ title: `Activation email sent to ${first_name}`, 'data-testid': 'new-user-success' })
    }

    await onRefresh()
  }

  const editUserIsActive = async (activate: boolean) => {
    if (!user) return

    const res = await service.updateUserOrg(user.user_org.id, { is_active: activate })

    if (res.type !== 'success') {
      error({ title: 'Failure to update user' })
    } else {
      const messageText = activate
        ? `${user.first_name} ${user.last_name} restored`
        : `${user.first_name} ${user.last_name} deleted`

      success({ title: messageText })
    }

    await onRefresh()
  }

  let extraButtons
  if (user?.id) {
    if (user.user_org.is_active)
      extraButtons = (
        <Button
          type="secondary"
          size="small"
          onClick={() => {
            modal.warning({
              id: 'delete-user-confirmation',
              header: (
                <div className={Styles.warningModalTitle}>
                  <PrismWarningIcon className={Styles.warningIconContainer} />
                  Are you sure ?
                </div>
              ),
              onOk: async close => {
                await editUserIsActive(false)
                close()
              },
              okText: 'Delete',
              content: 'This user will no longer be able to log in. Their inspections, however, will be preserved.',
            })
          }}
          disabled={noPermissionToEdit || isFlourish}
          data-testid="user-delete"
        >
          Delete
        </Button>
      )
  }

  let modalTitle = 'New User'
  if (user) {
    modalTitle = 'Edit User'
    if (!user.user_org.is_active) modalTitle = 'Restore User'
  }

  return (
    <>
      <Modal
        id="add-user"
        className={Styles.modalContainer}
        modalBodyClassName={Styles.modalBodyContainer}
        onClose={onCancel}
        size="largeNarrow"
        header={modalTitle}
        okText={inactiveUser ? 'Restore' : 'Save'}
        onOk={restoreSaveOrEditUser}
        extraButtons={extraButtons}
        disableSave={(!isDirty || !isValid || noPermissionToEdit) && !inactiveUser}
        data-testid="new-user-modal"
      >
        <Controller
          name="first_name"
          control={control}
          render={props => (
            <PrismInput
              size="small"
              autoFocus
              {...props}
              label="First Name"
              disabled={noPermissionToEdit || inactiveUser || isFlourish}
              errors={errors}
              data-testid="new-user-first-name"
            />
          )}
        />

        <Controller
          name="last_name"
          control={control}
          render={props => (
            <PrismInput
              size="small"
              {...props}
              label="Last Name"
              disabled={noPermissionToEdit || inactiveUser || isFlourish}
              errors={errors}
              data-testid="new-user-last-name"
            />
          )}
        />

        <Controller
          name="email"
          rules={{ required: 'Email is required' }}
          control={control}
          render={props => (
            <PrismInput
              size="small"
              {...props}
              label="Email Address"
              disabled={noPermissionToEdit || inactiveUser || isFlourish}
              errors={errors}
              data-testid="new-user-email"
            />
          )}
        />

        <div>
          <div className={Styles.label}>Role</div>
          <Controller
            name="role"
            rules={{ required: true }}
            control={control}
            render={({ onChange, value }) => (
              <>
                {noPermissionToEdit ? (
                  <div className={Styles.disabledInput}>{renderRole(value)}</div>
                ) : (
                  <PrismSelect
                    className={Styles.roleInput}
                    popupClassName={Styles.roleDropdownItems}
                    value={value}
                    onChange={onChange}
                    disabled={noPermissionToEdit || inactiveUser}
                    data-testid="user-roles"
                    options={[
                      {
                        value: 'owner',
                        title: 'admin',
                        isHidden: !(currentUserOrg?.role === 'owner'),
                        dataTestId: 'user-role-owner',
                      },
                      {
                        value: 'manager',
                        title: 'editor',
                        isHidden: !(currentUserOrg?.role === 'owner'),
                        dataTestId: 'user-role-manager',
                      },
                      { value: 'inspector', title: 'operator', dataTestId: 'user-role-inspector' },
                      { value: 'member', title: 'viewer', dataTestId: 'user-role-member' },
                    ]}
                  />
                )}

                <div className={Styles.roleDescription}>{renderRoleDescription(value)}</div>
              </>
            )}
          />
        </div>

        <SessionExpiration
          formProps={{
            control: control,
            errors: errors,
            inputTrigger: () => trigger('sessionLengthUnits'),
            selectTrigger: () => trigger('sessionLengthTime'),
            getValues: getValues,
          }}
          sessionLengthCaption={`User will be logged out ${getSessionLengthDisplayText(
            sessionLengthTime,
            sessionLengthUnits,
          )} after they log in.`}
          labelClassName={Styles.label}
          disabled={noPermissionToEdit || inactiveUser}
        />
      </Modal>
    </>
  )
}

const getDefaultFormValues = (user: User | undefined, currentOrg: Organization | undefined) => {
  const timeAndUnits = getSessionLengthUnitsAndTime(user?.user_org?.session_length_s || currentOrg?.session_length_s)

  return {
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    role: user?.user_org.role || 'member',
    sessionLengthTime: timeAndUnits?.time,
    sessionLengthUnits: timeAndUnits?.unit,
  }
}

const renderRoleDescription = (role: UserRole | undefined) => {
  switch (role) {
    case 'member':
      return 'Can observe inspections, look at items, and monitor results'
    case 'inspector':
      return 'Can run inspections, look at items, and monitor results.'
    case 'manager':
      return 'Can set up inspections, run them, label items, and monitor results. Can edit operators and viewers.'
    case 'owner':
      return 'Can set up inspections, run them, label items, and monitor results. Can edit admins, editors, operators, and viewers.'
    default:
      return ''
  }
}

export default UserConfigurationModal
