import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconSeverityProps } from './prismIconsTypes'

/**
 * Prism Neutral Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6986%3A12294
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  iconColor: Styles.iconColor,
  iconAndBackground: Styles.iconAndBackground,
}
export const PrismNeutralIcon = ({
  className,
  viewboxSize = 'medium',
  variant = 'transparent',
}: PrismIconSeverityProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.severity} ${Styles.neutral} ${variantClass} ${className ?? ''}`
  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 7.75C7 7.33579 7.33579 7 7.75 7H12.25C12.6642 7 13 7.33579 13 7.75C13 8.16421 12.6642 8.5 12.25 8.5H7.75C7.33579 8.5 7 8.16421 7 7.75Z"
        fill="currentColor"
      />
      <path
        d="M7 11.75C7 11.3358 7.33579 11 7.75 11H12.25C12.6642 11 13 11.3358 13 11.75C13 12.1642 12.6642 12.5 12.25 12.5H7.75C7.33579 12.5 7 12.1642 7 11.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
