import React from 'react'

import toast, { ToastOptions } from 'react-hot-toast'

import { Button } from 'components/Button/Button'
import { PrismCloseIcon } from 'components/prismIcons'
import { NOTIFICATION_ICONS } from 'components/prismIcons'

import Styles from './PrismNotification.module.scss'

export const DEFAULT_TIMEOUT_MS = 4500

export type Props = {
  title?: React.ReactNode
  description?: React.ReactNode
  className?: string
  type?: 'success' | 'error' | 'warning' | 'info' | 'loading' | 'discard' | 'mute'
  children?: React.ReactNode
  closable?: boolean
  handleClose?: (id?: any) => void
  'data-testid'?: string
} & ToastOptions

export const dismiss = (toastId?: string) => {
  toast.dismiss(toastId)
}

/**
 * Renders a Notification component (left dot with pre-defined text)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param title – Adds the title
 * @param description – Adds the description
 * @param type – Determines the type of icon
 * @param children – Renders a button or a progress bar
 */

export const Notification = ({
  title,
  description,
  className,
  closable = true,
  type,
  children,
  handleClose,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div className={`${Styles.notificationContainer} ${className}`} data-testid={dataTestId}>
      <div className={`${Styles.notification} ${type ? Styles.notificationIcon : ''}`}>
        {type && <div className={Styles.iconContainer}>{NOTIFICATION_ICONS(type)}</div>}
        <div className={Styles.notificationText}>
          <h4 className={Styles.notificationTitle}>{title}</h4>
          <div className={Styles.notificationDescription}>{description}</div>
          {children && <div className={Styles.childrenContainer}>{children}</div>}
        </div>
      </div>
      {closable && (
        <div className={Styles.closeContainer}>
          <Button
            isOnTop
            className={Styles.closeButton}
            type="secondary"
            onClick={handleClose}
            data-testid={`${dataTestId}-close`}
          >
            <PrismCloseIcon className={Styles.iconContainer} />
          </Button>
        </div>
      )}
    </div>
  )
}

export const getDuration = (options: ToastOptions & Props) => {
  let duration = options.duration === undefined ? DEFAULT_TIMEOUT_MS : options.duration
  if (options.duration === 0) {
    duration = Infinity
  }
  return duration
}

/**
 * Show a notification toast
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param type – Determines the type of icon that will be shown
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
const toastWrapper = (options: Props & ToastOptions) => {
  const duration = getDuration(options)

  const innerHandleClose = (toastId: string) => {
    toast.dismiss(toastId)
    options.handleClose && options.handleClose()
  }

  return toast.custom(
    toastProps => {
      const { visible } = toastProps
      return (
        <Notification
          key={toastProps.id}
          className={`${options.className ?? ''} ${!visible ? Styles.notVisible : ''}`}
          {...options}
          handleClose={() => innerHandleClose(toastProps.id)}
        >
          {options.children}
        </Notification>
      )
    },
    {
      duration: duration,
      id: options.id,
      position: options.position || 'bottom-left',
    },
  )
}

/**
 * Show a success toast (green)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const success = (options: Props) => toastWrapper({ type: 'success', ...options })

/**
 * Show an error toast (red)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const error = (options: Props) => toastWrapper({ type: 'error', ...options })

/**
 * Show a warning toast (yellow)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const warning = (options: Props) => toastWrapper({ type: 'warning', ...options })

/**
 * Show an info toast (blue)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const info = (options: Props) => toastWrapper({ type: 'info', ...options })

/**
 * Show a notification toast
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A161
 *
 * @param id – Use an id to update a notification
 * @param title – Adds the title
 * @param description – Adds the description
 * @param type – Determines the type of icon and color that will be shown: 'success' | 'error' | 'warning' | 'info'
 * @param children – Renders a button or a progress bar
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const open = (options: Props) => toastWrapper(options)
