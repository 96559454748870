import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

import { pixelsIntoPoints } from '../styles'

const iconSize = pixelsIntoPoints(20)

const PDFResultUnknown = () => (
  <Svg style={{ width: iconSize, height: iconSize }} viewBox="0 0 20 20">
    <Path
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      d="M10.063 6.18073C9.74028 6.15841 9.4176 6.22525 9.13026 6.37393C8.84292 6.52262 8.60198 6.74743 8.43378 7.02379C8.26558 7.30016 8.17658 7.61744 8.17652 7.94097C8.17646 8.26586 7.91304 8.52918 7.58815 8.52912C7.26326 8.52906 6.99994 8.26563 7 7.94075C7.0001 7.40153 7.14843 6.87273 7.42877 6.41212C7.7091 5.95151 8.11066 5.57682 8.58956 5.32902C9.06846 5.08121 9.60626 4.96981 10.1442 5.00701C10.6821 5.0442 11.1995 5.22855 11.6397 5.5399C12.0799 5.85126 12.4261 6.27764 12.6404 6.77245C12.8547 7.26725 12.9288 7.81144 12.8547 8.34554C12.7806 8.87963 12.5611 9.38308 12.2202 9.80086C11.8793 10.2186 11.4301 10.5347 10.9217 10.7144C10.807 10.755 10.7077 10.8301 10.6374 10.9295C10.5672 11.0289 10.5295 11.1476 10.5296 11.2693V11.4704C10.5296 11.7953 10.2662 12.0587 9.94131 12.0587C9.61642 12.0587 9.35305 11.7953 9.35305 11.4704V11.2699C9.35305 11.2698 9.35305 11.27 9.35305 11.2699C9.35292 10.9049 9.46597 10.5486 9.67662 10.2505C9.88734 9.95233 10.1853 9.72686 10.5296 9.60516C10.8346 9.49732 11.1041 9.3077 11.3086 9.05704C11.5132 8.80637 11.6449 8.5043 11.6893 8.18385C11.7338 7.86339 11.6893 7.53688 11.5608 7.23999C11.4322 6.94311 11.2245 6.68728 10.9603 6.50046C10.6962 6.31365 10.3858 6.20304 10.063 6.18073Z"
      fill="#4A4A4A"
    />
    <Path
      d="M9.35305 11.2699V11.4704C9.35305 11.7953 9.61642 12.0587 9.94131 12.0587C10.2662 12.0587 10.5296 11.7953 10.5296 11.4704V11.2693C10.5295 11.1476 10.5672 11.0289 10.6374 10.9295C10.7077 10.8301 10.807 10.755 10.9217 10.7144C11.4301 10.5347 11.8793 10.2186 12.2202 9.80086C12.5611 9.38308 12.7806 8.87963 12.8547 8.34554C12.9288 7.81144 12.8547 7.26725 12.6404 6.77245C12.4261 6.27764 12.0799 5.85126 11.6397 5.5399C11.1995 5.22855 10.6821 5.0442 10.1442 5.00701C9.60626 4.96981 9.06846 5.08121 8.58956 5.32902C8.11066 5.57682 7.7091 5.95151 7.42877 6.41212C7.14843 6.87273 7.0001 7.40153 7 7.94075C6.99994 8.26563 7.26326 8.52906 7.58815 8.52912C7.91304 8.52918 8.17646 8.26586 8.17652 7.94097C8.17658 7.61744 8.26558 7.30016 8.43378 7.02379C8.60198 6.74743 8.84292 6.52262 9.13026 6.37393C9.4176 6.22525 9.74028 6.15841 10.063 6.18073C10.3858 6.20304 10.6962 6.31365 10.9603 6.50046C11.2245 6.68728 11.4322 6.94311 11.5608 7.23999C11.6893 7.53688 11.7338 7.86339 11.6893 8.18385C11.6449 8.5043 11.5132 8.80637 11.3086 9.05704C11.1041 9.3077 10.8346 9.49732 10.5296 9.60516C10.1853 9.72686 9.88734 9.95233 9.67662 10.2505C9.46597 10.5486 9.35292 10.9049 9.35305 11.2699ZM9.35305 11.2699C9.35305 11.27 9.35305 11.2698 9.35305 11.2699Z"
      stroke="#4A4A4A"
      strokeWidth="0.125"
    />
    <Path
      d="M9.94135 13.8232C9.88317 13.8232 9.82631 13.8405 9.77794 13.8728C9.72957 13.9051 9.69187 13.9511 9.66961 14.0048C9.64734 14.0586 9.64152 14.1177 9.65287 14.1748C9.66422 14.2318 9.69223 14.2842 9.73337 14.3254C9.7745 14.3665 9.82691 14.3945 9.88396 14.4059C9.94102 14.4172 10.0002 14.4114 10.0539 14.3891C10.1077 14.3669 10.1536 14.3292 10.1859 14.2808C10.2182 14.2324 10.2355 14.1755 10.2355 14.1174C10.2355 14.0394 10.2045 13.9646 10.1493 13.9094C10.0942 13.8542 10.0194 13.8232 9.94135 13.8232Z"
      fill="#4A4A4A"
    />
    <Path
      fillRule="evenodd"
      d="M9.451 13.3841C9.59611 13.2871 9.76671 13.2354 9.94123 13.2354C10.1753 13.2354 10.3997 13.3283 10.5652 13.4938C10.7307 13.6593 10.8236 13.8837 10.8236 14.1177C10.8236 14.2923 10.7719 14.4629 10.6749 14.608C10.578 14.7531 10.4401 14.8662 10.2789 14.933C10.1177 14.9998 9.94025 15.0172 9.76908 14.9832C9.59792 14.9491 9.44069 14.8651 9.31728 14.7417C9.19388 14.6183 9.10984 14.4611 9.07579 14.2899C9.04175 14.1187 9.05922 13.9413 9.12601 13.7801C9.19279 13.6188 9.30589 13.481 9.451 13.3841ZM10.0538 14.3895C10.0715 14.3821 10.0885 14.3731 10.1046 14.3623C10.1369 14.3407 10.1644 14.3131 10.1858 14.2812C10.1964 14.2652 10.2056 14.2482 10.213 14.2303C10.2278 14.1944 10.2354 14.1562 10.2354 14.1177C10.2354 14.0986 10.2335 14.0793 10.2297 14.0604C10.2184 14.0033 10.1903 13.9509 10.1492 13.9098C10.1081 13.8686 10.0557 13.8406 9.99861 13.8293C9.97962 13.8255 9.9604 13.8236 9.94123 13.8236C9.90279 13.8236 9.86453 13.8311 9.82867 13.846C9.81078 13.8534 9.79376 13.8625 9.77782 13.8732C9.74586 13.8945 9.71823 13.9221 9.69667 13.9543C9.68591 13.9704 9.67683 13.9875 9.66949 14.0052C9.65478 14.0407 9.6471 14.0789 9.6471 14.1177C9.6471 14.1372 9.64902 14.1564 9.65275 14.1751C9.66402 14.2318 9.69181 14.2843 9.73325 14.3257C9.77468 14.3672 9.82721 14.395 9.88385 14.4062C9.90261 14.41 9.92182 14.4119 9.94123 14.4119C9.98004 14.4119 10.0183 14.4042 10.0538 14.3895Z"
      fill="#4A4A4A"
      stroke="#4A4A4A"
      strokeWidth="0.125"
    />
  </Svg>
)
export default PDFResultUnknown
