import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState = null

export default function reduce(state: RootState['itemUpload'] = initialState, action: Action): RootState['itemUpload'] {
  if (action.type === 'ITEM_UPLOAD_UPDATE') {
    const files = action.payload
    return files
  }
  return state
}
