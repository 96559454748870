import React from 'react'

import { BreadcrumbLabel } from 'components/Breadcrumbs/Breadcrumbs'
import Layout from 'components/Layout/Layout'
import { PrismFlagIcon, PrismGearIcon, PrismRandomToolIcon } from 'components/prismIcons'
import PrismSideNavLayout from 'components/PrismSideNavLayout/PrismSideNavLayout'
import paths from 'paths'

import GeneralOrg from './GeneralOrg'
import QualityEvents from './QualityEvents'
import Styles from './QualityEvents.module.scss'
import UsersTable from './UsersTable'

const Administration = () => {
  return (
    <Layout title={<BreadcrumbLabel className={Styles.headerBreadcrumb}>Administration</BreadcrumbLabel>}>
      <PrismSideNavLayout
        getPath={paths.administrationSettings}
        data-testid="administration"
        listItems={[
          {
            label: 'Users',
            value: 'users',
            component: <UsersTable />,
            icon: <PrismRandomToolIcon filled={false} />,
          },
          {
            label: 'Quality Events',
            value: 'quality_events',
            component: <QualityEvents />,
            icon: <PrismFlagIcon />,
          },
          {
            label: 'General',
            value: 'general',
            component: <GeneralOrg />,
            icon: <PrismGearIcon />,
          },
        ]}
      />
    </Layout>
  )
}

export default Administration
