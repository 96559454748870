import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { IconButton } from 'components/IconButton/IconButton'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismOverflowIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import paths from 'paths'
import { MenuActionsInCameraSettings, RecipeExpanded, RecipeOverviewMode, Routine, RoutineWithAois } from 'types'
import { refreshRoutineAndRecipe } from 'utils'

import DeleteViewModal from './DeleteViewModal'
import DuplicateOrMoveViewModal from './DuplicateOrMoveViewModal'
import LinkViewModal from './LinkViewModal'
import RenameViewModal from './RenameViewModal'
import Styles from './ViewHeader.module.scss'

interface Props {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  routineParentId: string
  mode: RecipeOverviewMode
  className?: string
  readoOnly?: boolean
}

/**
 * Renders the View header title with the overflow menu and the modals related to the view.
 *
 * @param routine - Currently selected routine.
 * @param recipe - The Recipe we are working on.
 * @param routineParentId - The routine parent we're editing.
 * @param className - For additional styles selector.
 * @param readOnly - Whether we are in read only mode.
 */
const ViewHeader = ({ recipe, routine, routineParentId, mode, className = '', readoOnly = false }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeModalType, setActiveModalType] = useState<MenuActionsInCameraSettings>()
  const viewName = recipe.recipe_routines.find(recipeRoutine => recipeRoutine.routine.parent.id === routineParentId)
    ?.routine.parent.name
  return (
    <>
      <div className={`${Styles.viewHeader} ${className}`}>
        <PrismOverflowTooltip className={Styles.viewHeaderTitle} content={routine.parent.name} />
        <OptionMenu
          onMenuItemClick={setActiveModalType}
          buttonDisabled={readoOnly}
          openWithClick
          options={[
            {
              value: 'duplicate',
              title: 'duplicate',
              'data-testid': 'camera-settings-duplicate-view-option',
            },
            {
              value: 'move',
              title: 'move',
              'data-testid': 'camera-settings-move-view-option',
            },
            {
              value: 'rename',
              title: 'rename',
              'data-testid': 'camera-settings-rename-view-option',
            },
            {
              value: 'link',
              title: 'link to camera',
              'data-testid': 'camera-settings-link-view-option',
            },
            {
              value: 'delete',
              title: 'delete',
              'data-testid': 'camera-settings-delete-view-option',
            },
          ]}
          menuContainerClassName={Styles.viewOverflowMenu}
          closeOnClick
          data-testid="view-settings-option-menu"
        >
          <IconButton
            icon={<PrismOverflowIcon />}
            size="xsmall"
            type="tertiary"
            className={Styles.viewOverflowIcon}
            disabled={readoOnly}
          />
        </OptionMenu>
      </div>

      {activeModalType === 'duplicate' && (
        <DuplicateOrMoveViewModal
          id="duplicate-view"
          isDuplicating
          onClose={() => setActiveModalType(undefined)}
          recipe={recipe}
          routine={routine}
          onSubmit={(routine: Routine) =>
            history.push(
              paths.settingsRecipe(routine.parent.recipe_parent.id, 'capture', {
                routineParentId: routine.parent.id,
              }),
            )
          }
        />
      )}

      {activeModalType === 'move' && (
        <DuplicateOrMoveViewModal
          id="move-view"
          onClose={() => setActiveModalType(undefined)}
          recipe={recipe}
          routine={routine}
          onSubmit={(routine: Routine) =>
            history.push(
              paths.settingsRecipe(routine.parent.recipe_parent.id, 'capture', {
                routineParentId: routine.parent.id,
              }),
            )
          }
        />
      )}

      {activeModalType === 'rename' && (
        <RenameViewModal
          onClose={() => setActiveModalType(undefined)}
          onUpdate={async () => {
            refreshRoutineAndRecipe({
              routineId: routine.id,
              recipeId: recipe.id,
              recipeParentId: recipe.parent_id,
              dispatch,
            })
            setActiveModalType(undefined)
          }}
          defaultName={viewName}
          routineParentId={routineParentId}
        />
      )}
      {activeModalType === 'link' && (
        <LinkViewModal
          onClose={() => setActiveModalType(undefined)}
          onOk={() => setActiveModalType(undefined)}
          recipe={recipe}
          routine={routine}
        />
      )}

      {activeModalType === 'delete' && (
        <DeleteViewModal onClose={() => setActiveModalType(undefined)} routine={routine} recipe={recipe} mode={mode} />
      )}
    </>
  )
}

export default ViewHeader
