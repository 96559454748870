import React, { useState } from 'react'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismEyeHideIcon, PrismEyeShowIcon } from 'components/prismIcons'
import { PrismInput, PrismInputProps } from 'components/PrismInput/PrismInput'

const PasswordInput = (props: Omit<PrismInputProps, 'type' | 'suffix'>) => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <PrismInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      suffix={<SeePasswordButton onClick={() => setShowPassword(!showPassword)} showPassword={showPassword} />}
      autoComplete="off"
    />
  )
}

const SeePasswordButton = ({ onClick, showPassword }: { onClick: () => void; showPassword: boolean }) => {
  const icon = showPassword ? <PrismEyeHideIcon /> : <PrismEyeShowIcon />
  return (
    <IconButton
      onClick={onClick}
      onMouseDown={e => e.preventDefault()}
      onMouseUp={e => e.preventDefault()}
      icon={icon}
      type="tertiary"
      size="small"
    />
  )
}

export default PasswordInput
