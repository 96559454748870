import { useEffect } from 'react'

import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { getterKeys, query, service, useQuery } from 'api'
import { useConnectionStatus, useGetCurrentInspectionId } from 'hooks'
import * as Actions from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import { ApiStreamMessage, ImageUpload, Item, ItemExpanded, ItemsByRobotId, RealtimePicture, ToolResult } from 'types'
import { getData, getterAddOrUpdateResultsAndSort, sortByNewestFirst } from 'utils'
import { MAX_RECENT_RESULTS } from 'utils/constants'

/**
 * Renders nothing. Fetches data needed for any inspection / identification use
 * case, and "subscribes to" robot state update events over websocket.
 *
 * @param robotId - Id of robot performing inspection
 * @param historicInspectionId - Id of the inspection selected
 */
function InspectorData({ robotId, historicInspectionId }: { robotId: string; historicInspectionId?: string }) {
  const dispatch = useDispatch()
  const defaultInspectionId = useGetCurrentInspectionId([robotId])
  const inspectionId = historicInspectionId || defaultInspectionId
  const connectionStatus = useConnectionStatus()
  const isOnline = connectionStatus === 'online'
  // Fetch robot once, later updates should come in from websocket events
  useQuery(getterKeys.robot(robotId), () => service.getRobot(robotId))

  // Fetch inspection, routines and items when inspectionId changes
  useEffect(() => {
    if (!inspectionId) return
    query(getterKeys.inspection(inspectionId), () => service.getInspection(inspectionId), { dispatch })
    // query(recipe)
    query(getterKeys.inspectionRoutines(inspectionId), () => service.getInspectionRoutines(inspectionId), { dispatch })
  }, [inspectionId, dispatch, isOnline])

  return null
}

export default InspectorData

/**
 * Process a message from the inspection websocket stream and updates the redux
 * representation of items and pictures accordingly.
 */
export function handleInspectionMessage(
  dispatch: Dispatch,
  message: ApiStreamMessage,
  inspectionId: string,
  options: { store?: TypedStore; isManual: boolean } = { isManual: false },
) {
  const { payload } = message
  const { store = typedStore } = options

  const rootState = store.getState()

  const setLatestItems = (items: ItemExpanded[]) => {
    dispatch(
      Actions.getterUpdate({
        key: getterKeys.inspectionItemsByRobot(inspectionId),
        updater: prevRes => {
          const updatedItemsByRobotId: ItemsByRobotId = prevRes ? { ...prevRes.data } : {}

          items.forEach(item => {
            item.pictures.forEach(picture => {
              const robotId = picture.robot_id
              if (!robotId) return
              updatedItemsByRobotId[robotId] = item
            })
          })

          return { ...prevRes, data: updatedItemsByRobotId }
        },
      }),
    )
  }

  // We got multiple items, e.g. when VP batches item creation
  if (payload.type === 'items-create' || payload.type === 'items-update') {
    const allItems = payload.payload
    const inspectionId = allItems?.[0]?.inspection

    if (options.isManual) {
      setLatestItems(allItems)
    }

    const allToolResultsWithPictures = allItems.flatMap(item => [
      ...item.pictures.flatMap(picture =>
        picture.tool_results.map<ToolResult>(
          toolResult =>
            ({
              ...toolResult,
              created_at: picture.created_at,
              picture: { ...picture },
              item: { id: item.id } as Item,
            } as ToolResult),
        ),
      ),
    ])

    if (!inspectionId) return

    dispatch(
      Actions.getterUpdate({
        key: getterKeys.inspectionRecentItems(inspectionId),
        updater: prevRes =>
          getterAddOrUpdateResultsAndSort(prevRes, {
            results: allItems.map(item => ({
              ...item,
              ...(item.pictures[0]?.image
                ? {
                    fallback_images: {
                      pictures: [
                        {
                          image: item.pictures[0].image,
                          image_thumbnail: item.pictures[0].image_thumbnail || '',
                          routine_id: item.pictures[0].routine_id,
                        },
                      ],
                      routines: [],
                    },
                  }
                : {}),
            })),
            sort: sortByNewestFirst,
            sliceEndIdx: MAX_RECENT_RESULTS,
          }),
      }),
    )

    dispatch(
      Actions.getterUpdate({
        key: getterKeys.inspectionRecentToolResults(inspectionId),
        updater: prevRes =>
          getterAddOrUpdateResultsAndSort(prevRes, {
            results: allToolResultsWithPictures,
            sort: sortByNewestFirst,
            sliceEndIdx: MAX_RECENT_RESULTS,
          }),
      }),
    )
  }

  // Update the images, embeddings and insights for pictures and results when URLs are saved to DB records
  if (payload.type === 'images-update') {
    payload.payload.forEach(imageUploadData => {
      // Update the image from the current inspected items
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionItemsByRobot(inspectionId),
          updater: prevRes => {
            if (!prevRes) return

            // Only update if image update payload is for inspectionItem
            const foundEntries = Object.entries(prevRes.data).filter(([, item]) => {
              return item.id === imageUploadData.item_id
            })

            if (!foundEntries.length) return

            const updatedData = { ...prevRes.data }
            foundEntries.forEach(([robotId, item]) => {
              const updatedItem = updateItemImages(item, imageUploadData)
              updatedData[robotId] = updatedItem
            })

            return { ...prevRes, data: updatedData }
          },
        }),
      )

      // Update the image from recent inspected toolResults.
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionRecentToolResults(inspectionId),
          updater: prevRes => {
            if (!prevRes) return

            // TODO: If we ever want to show insights on the tools tab we will need to handle the `insight` type here as well.
            if (imageUploadData.type === 'picture' || imageUploadData.type === 'picture_thumb') {
              const pictureId = imageUploadData.id

              const toolResultsToUpdateById: { [toolResultId: string]: ToolResult } = {}
              const toolResultsWithPictureToUpdate = prevRes.data.results.filter(
                toolResult => toolResult.picture.id === pictureId,
              )

              toolResultsWithPictureToUpdate.forEach(toolResult => {
                if (imageUploadData.type === 'picture') {
                  toolResult.picture = { ...toolResult.picture, image: imageUploadData.url }
                } else toolResult.picture = { ...toolResult.picture, image_thumbnail: imageUploadData.url }
                toolResultsToUpdateById[toolResult.id] = { ...toolResult }
              })

              const updatedResults = [...prevRes.data.results].map(toolResult => {
                if (toolResultsToUpdateById[toolResult.id]) return toolResultsToUpdateById[toolResult.id]!
                return toolResult
              })

              return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
            }
          },
        }),
      )

      // Update the image from recent inspection items.
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionRecentItems(inspectionId),
          updater: prevRes => {
            if (!prevRes) return
            const itemIndex = prevRes.data.results.findIndex(item => item.id === imageUploadData.item_id)

            const item = prevRes.data.results[itemIndex] as ItemExpanded | undefined

            if (!item) return

            const updatedResults = [...prevRes.data.results]
            updatedResults[itemIndex] = updateItemImages(item, imageUploadData)

            return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
          },
        }),
      )
    })
  }

  // Inspection info was changed by PLC or somewhere else, update our redux state
  if (payload.type === 'inspection-create' || payload.type === 'inspection-update') {
    const { name, ended_at, started_at, updated_at, id: inspectionId } = payload.payload
    const storedInspection = getData(rootState.getter, getterKeys.inspection(inspectionId))

    // Ony update stored data if we already have it. If we don't whenever we fetch it we'll get the newest version
    if (storedInspection && storedInspection?.updated_at <= updated_at) {
      dispatch(
        Actions.getterSave({
          data: { data: { ...storedInspection, name, updated_at, ended_at, started_at } },
          key: getterKeys.inspection(inspectionId),
        }),
      )
    }
  }

  // Cloud Root Cause Analysis reports a label for a tool result
  if (payload.type === 'prediction-labels-update') {
    payload.payload.forEach(predictionLabelsData => {
      const { tool_result_id, prediction_labels } = predictionLabelsData

      // Update the prediction labels from the current inspected items
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionItemsByRobot(inspectionId),
          updater: prevRes => {
            if (!prevRes) return

            const itemWithToolResultEntry = Object.entries(prevRes.data).find(([, item]) => {
              return item.pictures.find(pic => pic.tool_results.find(toolResult => toolResult.id === tool_result_id))
            })

            if (!itemWithToolResultEntry) return
            const [robotId, item] = itemWithToolResultEntry
            const updatedData = { ...prevRes.data }

            const updatedItem = { ...item }
            updatedItem.pictures.forEach(pic => {
              const updatedToolResult = pic.tool_results.find(toolResult => toolResult.id === tool_result_id)
              if (updatedToolResult) {
                updatedToolResult.prediction_labels = prediction_labels
              }
            })

            updatedData[robotId] = updatedItem

            return { ...prevRes, data: updatedData }
          },
        }),
      )

      // Update the prediction labels from recent inspected toolResults.
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionRecentToolResults(inspectionId),
          updater: prevRes => {
            if (!prevRes) return

            const updatedResults = [...prevRes.data.results].map(toolResult => {
              if (toolResult.id === tool_result_id) {
                const updatedToolResult = { ...toolResult }
                updatedToolResult.prediction_labels = prediction_labels
                return updatedToolResult
              }
              return toolResult
            })

            return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
          },
        }),
      )

      // Update the prediction labels from recent inspection items.
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionRecentItems(inspectionId),
          updater: prevRes => {
            if (!prevRes) return
            const itemToUpdate = prevRes.data.results.find(item =>
              item.pictures.find(pic => pic.tool_results.find(toolResult => toolResult.id === tool_result_id)),
            )

            if (!itemToUpdate) return

            const updatedResults = [...prevRes.data.results].map(item => {
              if (itemToUpdate.id === item.id) {
                const updatedItem = { ...item }
                updatedItem.pictures.forEach(pic => {
                  const updatedToolResult = pic.tool_results.find(toolResult => toolResult.id === tool_result_id)
                  if (updatedToolResult) {
                    updatedToolResult.prediction_labels = prediction_labels
                  }
                })
                return updatedItem
              }
              return item
            })

            return { ...prevRes, data: { ...prevRes.data, results: updatedResults } }
          },
        }),
      )
    })
  }
}

/**
 * Takes an item and the imageUploadData to return a cloned item with the images attached to it
 */
const updateItemImages = (initialItem: ItemExpanded, imageUploadData: ImageUpload): ItemExpanded => {
  const item = cloneDeep(initialItem)

  // We're updating a picture in the item
  if (imageUploadData.type === 'picture' || imageUploadData.type === 'picture_thumb') {
    const idx = item.pictures.findIndex(p => p.id === imageUploadData.id)

    const picture = item.pictures[idx]
    if (picture) {
      if (imageUploadData.type === 'picture') picture.image = imageUploadData.url
      else picture.image_thumbnail = imageUploadData.url
      item.pictures[idx] = picture
    }
  }

  // We're updating a toolResult nested in some picture in the item
  else {
    let pictureIdx: number | null = null
    let updatedPicture: RealtimePicture | null = null

    for (const [idx, picture] of item.pictures.entries()) {
      const toolResultIdx = picture.tool_results.findIndex(c => c.id === imageUploadData.id)

      const toolResult = picture.tool_results[toolResultIdx]
      if (toolResult) {
        if (imageUploadData.type === 'embedding') toolResult.embedding = imageUploadData.url
        else toolResult.insight_image = imageUploadData.url

        picture.tool_results[toolResultIdx] = toolResult
        pictureIdx = idx
        updatedPicture = picture
        break
      }
    }

    if (pictureIdx !== null && updatedPicture !== null) item.pictures[pictureIdx] = updatedPicture
  }

  return item
}
