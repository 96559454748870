import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism History Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5787%3A11405&t=A70EWjOWG8izpgp7-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismHistoryIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55401 10.9595C5.06089 7.31044 8.19725 4.5 11.9911 4.5C16.1386 4.5 19.4999 7.85838 19.4999 12C19.4999 16.1416 16.1386 19.5 11.9911 19.5C9.53464 19.5 7.35383 18.3223 5.98317 16.4995C5.81721 16.2788 5.50375 16.2344 5.28305 16.4004C5.06234 16.5663 5.01796 16.8798 5.18392 17.1005C6.73551 19.1639 9.20728 20.5 11.9911 20.5C16.6899 20.5 20.4999 16.6949 20.4999 12C20.4999 7.30506 16.6899 3.5 11.9911 3.5C7.76109 3.5 4.25165 6.58353 3.59329 10.6244L2.90003 9.7C2.73434 9.47908 2.42094 9.43431 2.20003 9.6C1.97912 9.76568 1.93434 10.0791 2.10003 10.3L3.60003 12.3C3.76571 12.5209 4.07912 12.5657 4.30003 12.4L6.30003 10.9C6.52094 10.7343 6.56571 10.4209 6.40003 10.2C6.23434 9.97908 5.92094 9.93431 5.70003 10.1L4.55401 10.9595ZM12 7.5C12.2761 7.5 12.5 7.72386 12.5 8V12.2098L15.3088 13.8149C15.5485 13.9519 15.6318 14.2573 15.4948 14.497C15.3578 14.7368 15.0524 14.8201 14.8126 14.6831L11.5 12.7902V8C11.5 7.72386 11.7239 7.5 12 7.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
