import React from 'react'

import { PrismElementaryCube } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'

import Styles from './LabelCard.module.scss'

const LONG_SKELETON_WIDTH = 80

const typeClasses = {
  transparent: Styles.transparent,
  ghost4: Styles.ghost4,
  smokey100: Styles.smokey100,
  smokey94: Styles.smokey94,
  notClickable: Styles.notClickable,
}
const listDirectionClasses = {
  horizontal: Styles.horizontal,
  vertical: Styles.vertical,
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label?: React.ReactNode
  image?: React.ReactNode
  active?: boolean
  overlay?: React.ReactNode
  imageClassName?: string
  figureClassName?: string
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94' | 'notClickable'
  'data-test'?: string
  'data-test-attribute'?: string
  isLoading?: boolean
  imageContainerBorder?: boolean
  outcome?: React.ReactNode
  labelInModal?: boolean
  incorrectLabel?: boolean
  timeStamp?: string
  cardRef?: React.Ref<HTMLDivElement>
  hasCubeWithBackground?: boolean
  outcomeClassName?: string
  borderType?: 'border' | 'borderless'
}

export const LabelCard = ({
  label,
  className = '',
  active = false,
  overlay,
  imageClassName = '',
  figureClassName = '',
  type = 'transparent',
  'data-test': dataTest,
  'data-test-attribute': dataTestAttribute,
  isLoading,
  outcome,
  imageContainerBorder,
  labelInModal,
  incorrectLabel,
  timeStamp,
  cardRef,
  hasCubeWithBackground = true,
  image = <PrismElementaryCube addBackground={hasCubeWithBackground} />,
  outcomeClassName = '',
  borderType = 'border',
  ...rest
}: Props) => {
  const typeClass = typeClasses[type]

  return (
    <div
      className={`${Styles.PrismLabelCard} ${typeClass} ${active ? Styles.labelCardActive : ''} ${
        labelInModal ? Styles.labelInModal : ''
      } ${borderType === 'borderless' ? Styles.borderless : ''} ${className}`}
      data-test={dataTest}
      data-test-attribute={dataTestAttribute}
      ref={cardRef}
      {...rest}
    >
      {outcome && <div className={outcomeClassName || Styles.outcomeContainer}>{outcome}</div>}

      {timeStamp && <div className={Styles.timeStamp}>{timeStamp}</div>}

      <figure
        className={`${Styles.labelCardImage} ${
          imageContainerBorder ? Styles.imageContainerBorder : ''
        } ${figureClassName}`}
      >
        {incorrectLabel && <span className={Styles.incorrectLabelPosition}>incorrect</span>}{' '}
        <div className={`${Styles.image} ${imageClassName}`}>
          {image}

          {overlay}
        </div>
      </figure>

      {isLoading && <PrismSkeleton maxWidth={LONG_SKELETON_WIDTH} />}

      {!isLoading && label}
    </div>
  )
}

/**
 *
 * @param cardCount - Number of skeleton cards to render
 * @param className - Container div className
 * @param cardClassName - ClassName applied to each card
 * @param listDirection - Skeleton List direction, one of `horizontal` or `vertical`
 */
export const LoadingLabelCardList = ({
  cardCount = 3,
  className,
  cardClassName,
  listDirection = 'vertical',
}: {
  cardCount?: number
  className?: string
  cardClassName?: string
  listDirection?: 'horizontal' | 'vertical'
}) => {
  const listDirectionClass = listDirectionClasses[listDirection]
  return (
    <div className={`${Styles.loadingList} ${listDirectionClass ?? ''} ${className ?? ''}`}>
      {Array(cardCount)
        .fill(undefined)
        .map((_, key) => (
          <LabelCard
            className={`${Styles.skeletonCard} ${cardClassName ?? ''}`}
            figureClassName={Styles.imageSkeletonContainer}
            imageClassName={Styles.imageSkeleton}
            image={<PrismSkeleton size="extraLarge" lighterShade />}
            key={key}
            isLoading
            type="ghost4"
          />
        ))}
    </div>
  )
}
