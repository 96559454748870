import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Stop Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3343%3A12031
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismStopIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 5L5.5 5C5.22386 5 5 5.22386 5 5.5L5 14.5C5 14.7761 5.22386 15 5.5 15L14.5 15C14.7761 15 15 14.7761 15 14.5L15 5.5C15 5.22386 14.7761 5 14.5 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
