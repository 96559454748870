import momentTz from 'moment-timezone'

import {
  DefaultEventType,
  EventTargets,
  EventTargetTable,
  Outcome,
  RequestStreamBody,
  ToolLabelSeverity,
  ToolSpecificationName,
} from 'types'

import {
  CORRECT_ALIGNMENT_LABEL,
  CORRECT_BARCODE_LABEL,
  CORRECT_COLOR_LABEL,
  CORRECT_MATCH_LABEL,
  CORRECT_SIZE_LABEL,
  CORRECT_TEXT_LABEL,
  CRITICAL_ANOMALY_LABEL,
  GOOD_NORMAL_LABEL,
  MINOR_ANOMALY_LABEL,
  NO_BARCODE_LABEL,
  NO_TEXT_LABEL,
  WRONG_ALIGNMENT_LABEL,
  WRONG_BARCODE_LABEL,
  WRONG_COLOR_LABEL,
  WRONG_MATCH_LABEL,
  WRONG_SIZE_LABEL,
  WRONG_TEXT_LABEL,
} from './labels'

export const OLD_DATE = '1900-01-01T00:00:00.000000Z'

export const BARCODE_TYPES: { [barcodeName: string]: string } = {
  // Most of these types are disabled due to a limitation on asset-management, they should be enabled as soon as asset-management fixes its issue
  BC_EAN_UPC: 'EAN/UPC',
  BC_CODE128: 'Code 128',
  BC_2_OF_5: 'Interleaved and IATA 2 of 5',
  BC_CODE39: 'Code 39',
  BC_QR: 'QR',
  BC_DATA_MATRIX: 'Datamatrix (inc DPM)',
  // BC_GS1_BAR: 'GS1 Databar',
  // BC_MSI_PLESSEY: 'MSI Plessy',
  // BC_CODE32: 'Code 32',
  // BC_CODE93: 'Code 93',
  // BC_CODE11: 'Code 11',
  // BC_UQR: 'Micro QR',
  // BC_AZTEC: 'Aztec',
  // BC_MAXICODE: 'Maxicode',
  // BC_PDF417: 'PDF417',
  // BC_UPDF417: 'MicroPDF',
  // BC_CODABAR: 'Codabar',
  // BC_GS1_DOT: 'GS1 Dotcode',
}

export const CAMERA_STATUS_BY_VP_STATUS = {
  READY: 'connected',
  LOADED: 'running',
  LOADING_TOOLS: 'running',
  LOADING: 'running',
  RUNNING: 'running',
  STOPPING: 'running',
  PAUSED: 'stopped',
  ERROR_MINOR: 'disconnected',
  ERROR_MAJOR: 'disconnected',
} as const

export const STATUS_LABELS = {
  running: 'Running',
  stopped: 'Paused',
  connected: 'Idle',
  disconnected: 'Offline',
  loading: 'Loading',
  notDetected: 'NotDetected',
  danger: 'Danger',
} as const

export const LOCAL_TIMEZONE_ABBR = momentTz().tz(momentTz.tz.guess()).format('z')
export const LOCAL_TIMEZONE = momentTz.tz.guess()

export const LABELING_SCREEN_MODES = ['gallery', 'focus'] as const

export const NUM_UUIDS_IN_QS = 100

export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24

export const TOOL_NAMES: Record<ToolSpecificationName, string> = {
  alignment: 'Align',
  'deep-svdd': 'Anomaly',
  random: 'Random',
  classifier: 'Defect',
  'graded-anomaly': 'Graded Anomaly',
  'match-classifier': 'Classify',
  'detect-barcode': 'Barcode',
  ocr: 'Text',
  'color-check': 'Color',
  measurement: 'Measure',
}

export const TOOLS_DESCRIPTIONS: Record<ToolSpecificationName, string> = {
  'detect-barcode': 'Read and confirm 1D and 2D barcodes' as const,
  'deep-svdd': 'Spot surface defects, contaminants, foreign objects, and other defect types' as const,
  classifier: 'Spot common defects that occur frequently' as const,
  'graded-anomaly': 'Spot defects and grade them as minor or critical severity' as const,
  alignment: 'Align images that vary in position or rotation' as const,
  random: 'Report random results according to the probability you set' as const,
  'match-classifier': 'Classify parts for identification and sorting' as const,
  ocr: 'Read and verify text with OCR' as const,
  'color-check': 'Verify a color or color range' as const,
  measurement: 'Verify the dimensions of objects & features' as const,
}

export const ANALYZE_HORIZONTAL_PADDING_BREAKPOINT = 1024
export const ANALYZE_ROW_WITH_CHART_HEIGHT = 29
export const ANALYZE_MINI_CHARTS_WIDTH = 110
export const ANALYZE_TOOLTIP_Y_DISTANCE = 36

export const TABLE_HEADER_HEIGHT = 42
export const ANALYZE_TABLE_PAGINATION_ROWS = 10
export const ANALYZE_ITEMS_OR_TOOLS_TABLE_ROW_HEIGHT = 93

export const SEVERITY_BY_OUTCOME: { [key in Outcome]: ToolLabelSeverity } = {
  pass: 'good',
  fail: 'critical',
  unknown: 'neutral',
}

export const ANOMALY_DEFECT_TOOL_LABELS = [GOOD_NORMAL_LABEL, CRITICAL_ANOMALY_LABEL]
export const GRADED_ANOMALY_TOOL_LABELS = [GOOD_NORMAL_LABEL, MINOR_ANOMALY_LABEL, CRITICAL_ANOMALY_LABEL]

export const TRAINABLE_TOOL_SPECIFICATION_NAMES: ToolSpecificationName[] = [
  'classifier',
  'deep-svdd',
  'graded-anomaly',
  'match-classifier',
]

export const INSIGHTS_STREAM_TOOL_SPECIFICATION_NAMES: ToolSpecificationName[] = [
  'deep-svdd',
  'graded-anomaly',
  'detect-barcode',
  'measurement',
  'ocr',
]
export const TRAINING_STATES = ['queued', 'generating_dataset', 'invoked', 'in_progress']

export const PDF_TABLE_LIMIT = 100

export const PDF_CARD_LIMIT = 100

export const DEFAULT_TOOL_LABELS_GETTER_KEY = 'default-tool-labels'
export const ALL_TOOL_LABELS_GETTER_KEY = 'all-tool-labels'
export const DELETED_LABELS_GETTER_KEY = 'deleted-tool-labels'

export const QS_FILTER_KEYS = [
  'user_label_id__in',
  'user_outcome',
  'calculated_outcome__in',
  'user_label_set_isnull',
  'group_by',
  'groups_id',
  'group_id',
  'start',
  'end',
  'inspection_id',
  'prediction_label_id__in',
  'prediction_label_id',
  'prediction_score_min',
  'prediction_score_max',
  'recipe_parent_id',
  'component_id',
  'ordering',
  'showPredictionScore',
] as const

export const GENERATED_HEAP_SCRIPT_ID = 'heap-script-generated'

// Maximum number of defects to show on analyze
export const MAX_DEFECT_COUNT = 5

export const LABEL_VALUE_MAX_LENGTH = 40
export const LABEL_ADDITIONAL_DESCRIPTION_FIELDS_LENGTH = 1000

export const LABEL_FALLBACK_IMAGES_TO_USE = 3

export const ANALYTICS_RESULTS_PAGE_SIZE = 100
export const LABEL_SCREEN_TOOL_RESULTS_PAGE_SIZE = 100
export const EVENT_SUBS_RESULTS_PAGE_SIZE = 1000

export const MAX_ADDITIONAL_INSPECTIONS_FOR_PROXY_PAGES_ON_INITIAL_LOAD = 4

export const MAX_ADDITIONAL_TOOL_RESULT_PAGES_ON_INITIAL_LOAD = 10

// These are labels derived, but not directly assigned
export const DERIVATIVE_LABELS = [
  CORRECT_MATCH_LABEL,
  WRONG_MATCH_LABEL,
  CORRECT_ALIGNMENT_LABEL,
  WRONG_ALIGNMENT_LABEL,
  CORRECT_BARCODE_LABEL,
  NO_BARCODE_LABEL,
  WRONG_BARCODE_LABEL,
  CORRECT_COLOR_LABEL,
  WRONG_COLOR_LABEL,
  CORRECT_SIZE_LABEL,
  WRONG_SIZE_LABEL,
  CORRECT_TEXT_LABEL,
  NO_TEXT_LABEL,
  WRONG_TEXT_LABEL,
]

// List of labels that aren't currently used in the app
export const UNUSED_LABELS = [
  CORRECT_ALIGNMENT_LABEL,
  WRONG_ALIGNMENT_LABEL,
  CORRECT_BARCODE_LABEL,
  NO_BARCODE_LABEL,
  WRONG_BARCODE_LABEL,
  CORRECT_COLOR_LABEL,
  WRONG_COLOR_LABEL,
  CORRECT_SIZE_LABEL,
  WRONG_SIZE_LABEL,
  CORRECT_TEXT_LABEL,
  NO_TEXT_LABEL,
  WRONG_TEXT_LABEL,
]

export const PINNED_CAMERA_DATA_LOCAL_STORAGE_KEY = 'elementary:pinnedCamerasByStationId'
export const MUTED_NOTIFICATIONS_LOCAL_STORAGE_KEY = 'elementary:mutedNotifications'
export const SHARE_DETAIL_MODAL_LOCAL_STORAGE_KEY = 'elementary:shareDetailModal'
export const FROM_URL_LOCAL_STORAGE_KEY = 'elementary:fromUrl'
export const USER_PREFERENCES_BY_ID_LOCAL_STORAGE_KEY = 'elementary:userPreferencesById'

export const TOOLS_WITH_SCORE_ZERO_TO_ONE: ToolSpecificationName[] = ['classifier', 'match-classifier']
export const TOOLS_WITH_SCORE_INVERTED: ToolSpecificationName[] = ['graded-anomaly', 'deep-svdd']

export const FILTER_KEYS_TO_PROXY = [
  'component_id',
  'station_id',
  'routine_id',
  'recipe_id',
  'tool_specification',
  'user_id',
  'routine_parent_id',
  'aoi_parent_id',
  'prediction_score_gt',
  'prediction_score_gte',
  'prediction_score_lt',
  'prediction_score_lte',
  'recipe_id',
  'recipe_parent_id',
  'site_id',
  'subsite_id',
]

export const LABELING_GALLERY_CARD_HEIGHT = 254
export const LABELING_GALLERY_CARD_HEIGHT_WITH_WRAPPER_PADDING = 278
export const LABELING_GALLERY_CARD_MIN_WIDTH = 215
export const LABELING_GALLERY_GRID_GAP = 24

const BASE_STREAM_PARAMS = {
  input_element: 'basler' as const,
  input_stream: 'image' as const,
  output_stream: 'compressed' as const,
  output_fps: 5,
  output_max_len: 5,
  timeout_ms: 30000,
}
export const STANDARD_QUALITY_STREAM_PARAMS: RequestStreamBody = {
  ...BASE_STREAM_PARAMS,
  output_element: 'standard',
  output_pixels: 1000000,
  output_quality: 35,
}
export const HIGH_QUALITY_STREAM_PARAMS: RequestStreamBody = {
  ...BASE_STREAM_PARAMS,
  output_element: 'high',
  output_pixels: 2000000,
  output_quality: 65,
}
export const HIGHEST_QUALITY_STREAM_PARAMS: RequestStreamBody = {
  ...BASE_STREAM_PARAMS,
  output_element: 'highest',
  output_pixels: 4000000,
  output_quality: 65,
}

export const LABELING_HOTKEYS = '1|2|3|4|5|6|7|8|9|0'

export const DEFAULT_INSEPCTION_EVENTS: DefaultEventType[] = [
  'inspection_start',
  'inspection_stop',
  'tool_update_user_args',
  'tool_mute',
  'tool_unmute',
  'got_bad_recipe_code',
]
export const DEFAULT_TOOL_TRAINING_EVENTS: DefaultEventType[] = ['train_finish']
export const DEFAULT_COMPUTE_EVENTS: DefaultEventType[] = ['compute_online', 'compute_offline']

export const UPDATE_MESSAGES_READ_INTERVA_MS = 6 * 1000

export const HEARTBEAT_DISCONNECTED_TIMEOUT = 10 * 1000

export const INPUT_MAX_CHARACTER_LIMIT = 60
export const MINIMUM_LABELS_COUNT_FOR_DEFECT_AND_MATCH_TRAINING = 2
export const MINIMUM_NORMAL_IMAGES_FOR_GAR_AND_ANOMALY_TRAINING = 5

export const MAX_RTS_METRICS_LABELS_DICTS = 16

export const STATUS_PRIORITY = { running: 4, connected: 3, loading: 2, stopped: 1, disconnected: 0 }

export const MAX_IMAGE_UPLOAD_WIDTH = 10000
export const MAX_IMAGE_UPLOAD_HEIGHT = 10000
export const MIN_IMAGE_UPLOAD_WIDTH = 50
export const MIN_IMAGE_UPLOAD_HEIGHT = 50

export const QUALITY_EVENTS_ALLOWED_TARGET_TABLES: EventTargetTable[] = ['organization', 'station', 'component']
export const QUALITY_EVENTS_ALLOWED_TARGET_KEYS: (keyof EventTargets)[] = [
  'organization_id',
  'station_id',
  'component_id',
  'user_id',
]

export const INDEPENDENT_STATION_LINE_NAME = 'Independent Stations'

export const STATION_DETAIL_OVERVIEW_METRICS_PREFIX = 'detail-overview'

export const MAX_RECENT_RESULTS = 1000

export const MAX_32_BIT_INT = Math.pow(2, 32 - 1)

export const MAX_SESSION_LENGTH = 365 * 24 * 60 * 60 // One year

export const SESSION_LENGTH_TIME_OPTIONS = [
  { value: 'day', title: 'days', dataTestId: 'session-length-days' },
  { value: 'hour', title: 'hours', dataTestId: 'session-length-hours' },
  { value: 'minute', title: 'minutes', dataTestId: 'session-length-minutes' },
]

export const AOI_COLORS = {
  prism: {
    dark_100: '#1563e2',
    smokeyBlack_24: '#c9c9c9',
    sunglow_100: '#ffce41',
    fireOpal_100: '#ed523d',
    seafoam_100: '#5cd6c6',
    azure_100: '#3D80ED',
  },
  flourish: {
    dark_100: '#5457c1',
    smokeyBlack_24: '#c2c2c9',
    sunglow_100: '#ffce41',
    fireOpal_100: '#ed3d3d',
    seafoam_100: '#5cd6c6',
    azure_100: '#6C70E3',
  },
} as const

export const MAX_EXPOSURE_MS = 1000
export const MAX_INTERVAL_MS = 130000
export const MAX_DELAY_MS = 1800000

export const VISION_AI_HOST_SUBSTRING = 'visionai'

export const IMAGE_IN_GLACIER_TEXT = 'This image was inspected but not permanently stored'
export const IMAGE_NOT_UPLOADED_TEXT = 'This image was inspected but not uploaded'
