import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Share Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6497%3A12039
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismShareIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2.5H8.5C8.22386 2.5 8 2.72386 8 3C8 3.27614 8.22386 3.5 8.5 3.5H11.7929L7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355C7.84171 8.54882 8.15829 8.54882 8.35355 8.35355L12.5 4.20711V7.5C12.5 7.77614 12.7239 8 13 8C13.2761 8 13.5 7.77614 13.5 7.5V3C13.5 2.72386 13.2761 2.5 13 2.5ZM3.5 4.33333C3.5 4.05719 3.27614 3.83333 3 3.83333C2.72386 3.83333 2.5 4.05719 2.5 4.33333V13C2.5 13.2761 2.72386 13.5 3 13.5H11.6667C11.9428 13.5 12.1667 13.2761 12.1667 13C12.1667 12.7239 11.9428 12.5 11.6667 12.5H3.5V4.33333Z"
        fill="currentColor"
      />
    </svg>
  )
}
