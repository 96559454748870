import { Action } from 'rdx/actions'
import { RootState } from 'types'

const initialState: RootState['edge'] = {
  urls: [],
  paramsByEdgeUrl: {},
}

/**
 * This branch of state tree stores information related to the edge FastAPI
 * instance(s) that run as part of the robot's IOT stack.
 */
export default function reduce(state = initialState, action: Action) {
  if (action.type === 'EDGE_SET') {
    return action.payload
  }
  return state
}
