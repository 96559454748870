import React, { useMemo } from 'react'

import { Popover } from 'antd'
import { useHistory } from 'react-router'

import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { LabelCard } from 'components/LabelCard/LabelCard'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { Token } from 'components/Token/Token'
import { useDefaultToolLabels } from 'hooks'
import { Threshold, Tool, ToolLabel, ToolResult, ToolSpecificationName, TrainingResultFlat } from 'types'
import {
  appendDataToQueryString,
  appendItemOrToolResultIdPictureIdOrLastSelectedToQs,
  calculateTrainingResultPrediction,
  extractLabelIdFromCalculatedLabels,
  getDisplaySeverity,
  getLabelName,
} from 'utils'
import { getDisplayThreshold } from 'utils'

import { ThresholdByRoutineParentId } from './TrainingReport'
import Styles from './TrainingReport.module.scss'

/**
 * Renders a card item which contains a training result image and data.
 *
 * @param trainingResult - The training result entry to render in this card
 * Business Rules: The result will be labeled as a "Correct" if the labeled result matches
 * the predicted result.
 * @param toolResult - Current Tool Result.
 * @param threshold - Current threshold value.
 * @param modalLoaded - Indicates wheter the parent modal is already open, we use this value
 * to know when to render the thumbnail image.
 * @param type - Card Type
 * @param tool - Tool to which the card item belongs
 * @param toolLabels - Organization Tool Labels.
 * @param cardRef - ref to be passed to the card element.
 * @param thresholdByRoutine - Object including current threshold by routine,
 * used to calculate predictions for overriden threholds
 */
function UnmemoizedCardItem({
  trainingResult,
  toolResult,
  threshold,
  modalLoaded,
  insightsEnabled,
  type = 'transparent',
  toolLabels,
  tool,
  cardRef,
  thresholdByRoutine,
  onClick,
  'data-test': dataTest,
}: {
  trainingResult?: TrainingResultFlat
  toolResult?: ToolResult
  insightsEnabled?: boolean
  threshold: Threshold | undefined
  modalLoaded: boolean
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94'
  toolLabels?: ToolLabel[]
  tool?: Tool
  cardRef?: React.Ref<HTMLDivElement>
  thresholdByRoutine?: ThresholdByRoutineParentId
  onClick?: () => void
  'data-test'?: string
}) {
  const history = useHistory()
  const defaultLabels = useDefaultToolLabels()

  const predictionScore = trainingResult?.prediction_score || toolResult?.prediction_score

  // TODO: we want to revisit this once we have more than one calculated label
  const calculatedLabelId = extractLabelIdFromCalculatedLabels(
    toolLabels || [],
    trainingResult?.calculated_labels || [],
  )
  const calculatedLabel = toolLabels?.find(label => label.id === calculatedLabelId)

  const { isAboveThreshold, predictedLabel } = useMemo(() => {
    if (!trainingResult || !tool || !threshold || !toolLabels || !defaultLabels)
      return { isAboveThreshold: undefined, predictedLabel: undefined }

    return calculateTrainingResultPrediction({
      trainingResult,
      specName: tool?.specification_name,
      threshold,
      defaultLabels,
      allToolLabels: toolLabels,
      thresholdByRoutine,
    })
  }, [defaultLabels, threshold, thresholdByRoutine, tool, toolLabels, trainingResult])

  const aoi = toolResult?.aoi
  const imageUrl = toolResult?.picture.image
  const thumbnailUrl = toolResult?.picture.image_thumbnail || toolResult?.picture.image

  const userFacingPredictionScore = tool
    ? getDisplayThreshold(predictionScore, tool.specification_name)
    : predictionScore?.toFixed(2)

  const predictionType = useMemo(() => {
    if (tool?.specification_name === 'match-classifier') {
      const correctPrediction = calculatedLabel?.id === predictedLabel?.id
      if (!correctPrediction) return 'incorrect-prediction'

      if (!isAboveThreshold) return 'below-threshold-prediction'
    }

    if (
      tool?.specification_name === 'deep-svdd' ||
      tool?.specification_name === 'graded-anomaly' ||
      tool?.specification_name === 'classifier'
    ) {
      const correctPrediction = calculatedLabel?.severity === predictedLabel?.severity
      if (!correctPrediction) return 'incorrect-prediction'
    }

    return 'correct-prediction'
  }, [calculatedLabel?.id, predictedLabel?.id, isAboveThreshold, tool?.specification_name]) // eslint-disable-line

  const renderContent = () => {
    if (trainingResult)
      return (
        <div className={Styles.detailsContainer}>
          <div className={Styles.predictedWrapper}>
            Predicted
            {predictedLabel && (
              <PrismResultButton
                size="small"
                type="noFill"
                className={Styles.cardResult}
                value={
                  tool?.specification_name === 'match-classifier'
                    ? getLabelName(predictedLabel)
                    : predictedLabel.severity
                }
                severity={getDisplaySeverity(predictedLabel)}
              />
            )}
          </div>
          <div className={Styles.scoreWrapper}>
            scoring <span className={Styles.scoreHighlighted}>{userFacingPredictionScore}</span>
          </div>
        </div>
      )

    return (
      <div className={Styles.detailsContainer}>
        scoring <span className={Styles.scoreHighlighted}>{userFacingPredictionScore || '--'}</span>
      </div>
    )
  }

  const handleClick = () => {
    if (trainingResult)
      return appendDataToQueryString(history, {
        training_result_id: trainingResult?.id,
      })

    if (toolResult)
      return appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, {
        itemId: toolResult.item?.id,
        toolResultId: toolResult.id,
      })
  }

  return (
    <div onClick={onClick || handleClick} ref={cardRef} data-test={dataTest}>
      {trainingResult && (
        <Popover
          mouseEnterDelay={0.5}
          placement="bottom"
          title={null}
          key={trainingResult.id}
          content={
            <div className={Styles.popover}>
              <Token label={predictionType === 'correct-prediction' ? 'correct prediction' : 'incorrect prediction'}>
                {predictionType === 'correct-prediction' && (
                  <>
                    Your tool is correctly predicting this image is{' '}
                    <span className={Styles.labelText}>{getLabelText(predictedLabel, tool?.specification_name)}</span>.
                  </>
                )}

                {predictionType === 'incorrect-prediction' && (
                  <>
                    Your tool is confusing this image of{' '}
                    <span className={Styles.labelText}>{getLabelText(calculatedLabel, tool?.specification_name)}</span>{' '}
                    with{' '}
                    <span className={Styles.labelText}>{getLabelText(predictedLabel, tool?.specification_name)}</span>.
                  </>
                )}

                {predictionType === 'below-threshold-prediction' && (
                  <>
                    Your tool is predicting this image as{' '}
                    <span className={Styles.labelText}>{getLabelText(predictedLabel, tool?.specification_name)}</span>,
                    but its prediction score is below the threshold.
                  </>
                )}
              </Token>
            </div>
          }
        >
          <LabelCard
            type={type}
            className={Styles.cardItem}
            image={
              aoi && modalLoaded ? (
                <ImageCloseUp
                  overlaySrc={insightsEnabled ? trainingResult.insight_image : ''}
                  loaderType="skeleton"
                  thumbnailSrc={thumbnailUrl}
                  src={imageUrl}
                  region={aoi}
                />
              ) : (
                <PrismElementaryCube />
              )
            }
            label={renderContent()}
            incorrectLabel={predictionType !== 'correct-prediction'}
          />
        </Popover>
      )}

      {!trainingResult && (
        <LabelCard
          type={type}
          className={Styles.cardItem}
          image={
            aoi && modalLoaded ? (
              <ImageCloseUp loaderType="skeleton" thumbnailSrc={thumbnailUrl} src={imageUrl} region={aoi} />
            ) : (
              <PrismElementaryCube />
            )
          }
          label={renderContent()}
        />
      )}
    </div>
  )
}

export const CardItem = React.memo(UnmemoizedCardItem)

const getLabelText = (toolLabel?: ToolLabel | undefined, specificationName?: ToolSpecificationName) =>
  specificationName === 'match-classifier' ? toolLabel?.value : toolLabel?.severity
