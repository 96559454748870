import React, { MutableRefObject, useState } from 'react'

import { useHistory } from 'react-router-dom'

import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { PrismArchiveIcon, PrismGearIcon, PrismRecipeIcon } from 'components/prismIcons'
import AddOrEditLineModal from 'pages/StationDetail/Components/EntityModals/AddOrEditLineModal'
import AddOrEditProductModal from 'pages/StationDetail/Components/EntityModals/AddOrEditProductModal'
import AddOrEditStationModal from 'pages/StationDetail/Components/EntityModals/AddOrEditStationModal'
import paths from 'paths'
import { Component, StationForSite, SubSiteExpanded } from 'types'

import ArchiveEntityModal from './ArchiveSlsModal'
import Styles from './LinesList.module.scss'

/**
 * Renders an optionMenu list with options for lines, station or product
 *
 * @param entity - Renders different options
 * @param renderWithPortal - Renders the menu using a portal
 * @param optionMenuSize - Changes the size of the icon Button
 * @returns
 */

type EntityOverflowMenuProps = {
  renderWithPortal: boolean
  optionMenuSize?: 'xsmall' | 'small' | 'medium'
  className?: string
  closeMenuRef?: MutableRefObject<(() => void) | undefined>
  onArchive?: () => Promise<void>
} & (
  | {
      entityType: 'line'
      entity: SubSiteExpanded
    }
  | {
      entityType: 'station'
      entity: StationForSite & { site_id: string }
    }
  | {
      entityType: 'product'
      entity: Component
    }
)

type OptionValues = 'settings' | 'archive' | 'recipes'

const EntityOverflowMenu = ({
  entityType,
  entity,
  renderWithPortal,
  onArchive,
  optionMenuSize = 'xsmall',
  className = '',
  closeMenuRef,
}: EntityOverflowMenuProps) => {
  const history = useHistory()
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const options: Option<OptionValues>[] = [
    { value: 'settings', badge: <PrismGearIcon />, 'data-testid': 'settings' },
    { value: 'archive', badge: <PrismArchiveIcon />, 'data-testid': 'archive' },
  ]
  if (entityType === 'station') options.unshift({ value: 'recipes', badge: <PrismRecipeIcon /> })

  const handleOptionMenuClick = (option: OptionValues) => {
    if (option === 'recipes') {
      history.push(paths.stationDetail('recipes', entity.id))
    }
    if (option === 'settings') {
      setShowSettingsModal(true)
    }
    if (option === 'archive') {
      setShowArchiveModal(true)
    }
  }

  return (
    <>
      <OptionMenu
        data-testid={`${entityType}-${entity.name}-overflow-menu`}
        options={options}
        onMenuItemClick={option => handleOptionMenuClick(option)}
        openWithClick
        iconButtonType="tertiary"
        renderWithPortal={renderWithPortal}
        position="bottomLeft"
        className={`${Styles.menu} ${className}`}
        menuContainerClassName={Styles.menuContainer}
        iconButtonClassName={Styles.overflowMenuIcon}
        iconButtonSize={optionMenuSize}
        menuWidth={216}
        closeMenuRef={closeMenuRef}
      />

      {showSettingsModal && entityType === 'line' && (
        <AddOrEditLineModal
          defaultSiteId={entity.site_id}
          isEditMode
          onClose={() => setShowSettingsModal(false)}
          entity={entity}
        />
      )}

      {showSettingsModal && entityType === 'station' && (
        <AddOrEditStationModal isEditMode onClose={() => setShowSettingsModal(false)} entity={entity} />
      )}

      {showSettingsModal && entityType === 'product' && (
        <AddOrEditProductModal isEditMode onClose={() => setShowSettingsModal(false)} entity={entity} />
      )}

      {showArchiveModal && (
        <ArchiveEntityModal
          entityType={entityType}
          entityId={entity.id}
          onOk={onArchive}
          onClose={() => setShowArchiveModal(false)}
        />
      )}
    </>
  )
}

export default EntityOverflowMenu
