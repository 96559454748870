import React, { useEffect, useMemo, useState } from 'react'

import parsePhoneNumber, { AsYouType, CountryCallingCode, isPossiblePhoneNumber } from 'libphonenumber-js'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Alert } from 'components/PrismAlert/PrismAlert'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismPassIcon, PrismUnknownIcon, PrismWarningIcon } from 'components/prismIcons'
import { InputError, PrismInput } from 'components/PrismInput/PrismInput'
import { error, info, success } from 'components/PrismMessage/PrismMessage'
import { useData } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { Me } from 'types'

import Styles from './Notify.module.scss'

/**
 * Renders form to allow user to change their notification settings.
 */
const ContactInformation = () => {
  const me = useData(getterKeys.me())

  const [showPhoneVerification, setShowPhoneVerification] = useState(false)
  const dispatch = useDispatch()

  const atleastOneSmsSubscription = useMemo(() => {
    return me?.event_subs.some(eventSub => eventSub.via_sms) || false
  }, [me?.event_subs])

  const defaultValues = getDefaultFormValues(me)
  const {
    reset,
    formState: { isDirty, errors, isValid },
    control,
    getValues,
    trigger,
    setError,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    if (me) reset(getDefaultFormValues(me))
    if (!me?.is_phone_verified && me?.phone_number) setShowPhoneVerification(true)
  }, [reset, me])

  const handleSubmit = async () => {
    const valid = await trigger()

    if (!me || !valid) return

    const { phoneCountryCode, phoneNumber } = getValues()

    if (!!phoneCountryCode !== !!phoneNumber) {
      if (!!phoneCountryCode) {
        setError('phoneNumber', { type: 'manual', message: 'Both phone number and country code are required' })
      } else if (!!phoneNumber) {
        setError('phoneCountryCode', { type: 'manual', message: 'Both phone number and country code are required' })
      }
      return error({ title: 'An error occurred, please try again' })
    }
    const completePhone =
      phoneCountryCode && phoneNumber ? parsePhoneNumber(phoneCountryCode + phoneNumber)?.number || '' : ''

    const userRes = await service.updateMe({
      phone_number: completePhone,
    })

    if (userRes.type === 'success') {
      success({ title: 'Preferences saved' })
      const userData = userRes.queryData.data
      if (!userData.is_phone_verified && userData.phone_number) {
        info({ title: 'Check your text messages for a verification code' })
        setShowPhoneVerification(true)
      } else {
        setShowPhoneVerification(false)
      }

      query(getterKeys.me(), () => service.me(), { dispatch }) // Refetch user to render page again
    } else {
      error({ title: 'An error occurred, please try again' })
    }
  }

  const handleVerificationTextChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!me) return
    const { value } = e.target
    if (value.length === 6) {
      const verificationRes = await service.sendPhoneVerificationToken({ token: value })

      if (verificationRes.type === 'success') {
        success({ title: 'Verification succesful' })
        setShowPhoneVerification(false)
        query('me', () => service.me(), { dispatch }) // Refetch user to render page again
      } else {
        error({ title: 'Verification code incorrect' })
      }
    }
  }

  const handleVerificationTextResend = async () => {
    const resendTokenRes = await service.resendPhoneVerificationToken()
    if (resendTokenRes.type === 'success') {
      info({ title: 'Check your text messages for a verification code' })
    } else {
      error({ title: 'An error occurred, please try again' })
    }
  }

  return (
    <PrismContainer
      title="Contact Information"
      className={Shared.rightSectionContainer}
      bodyClassName={Styles.bodyContainer}
      headerTitleAction={
        <Button htmlType="submit" disabled={!isDirty || !isValid} onClick={handleSubmit} size="small">
          Save
        </Button>
      }
      invertTitleAndActions
      actions={<p className={Styles.headerDescription}>Where you prefer to receive notifications.</p>}
      headerActionsClassName={Styles.headerWrapper}
    >
      <div className={Shared.verticalChildrenGap32}>
        {!me?.phone_number && atleastOneSmsSubscription && (
          <Alert icon={<PrismWarningIcon isActive />} description="Set up your SMS number to receive messages" />
        )}
        <div className={`${Shared.verticalChildrenGap32} ${Styles.formWrapper}`}>
          <Controller
            name="email"
            rules={{ required: true }}
            control={control}
            render={props => (
              <PrismInput
                {...props}
                value={me?.email}
                errors={errors}
                label="Email"
                disabled
                labelClassName={Styles.inputLabel}
                size="small"
              />
            )}
          />

          <section
            className={`${Styles.smsSection} ${
              showPhoneVerification ? Styles.phoneVerification : Styles.noPhoneVerification
            }`}
          >
            <Controller
              control={control}
              name="phoneCountryCode"
              rules={{
                pattern: {
                  value: /^\+\d{1,4}$/,
                  message: 'Country code must be a + sign followed by 1 to 4 numbers',
                },
              }}
              render={({ onChange, ...rest }) => (
                <PrismInput
                  {...rest}
                  placeholder="+1"
                  label="mobile"
                  onChange={e => {
                    const value = e.target.value
                    // only allow + and numbers
                    if (value.match(/^\+?\d*$/)) {
                      onChange(value)
                    }
                  }}
                  wrapperClassName={Styles.countryCode}
                  labelClassName={Styles.inputLabel}
                  size="small"
                />
              )}
            />

            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                validate: () => {
                  const { phoneCountryCode, phoneNumber } = getValues()
                  if (!phoneCountryCode || !phoneNumber) return true
                  if (isPossiblePhoneNumber(phoneCountryCode + phoneNumber)) return true
                  return 'Phone number is invalid'
                },
              }}
              render={props => (
                <PrismInput
                  wrapperClassName={Styles.phone}
                  {...props}
                  value={props.value}
                  onChange={e => {
                    // Masks input to 555-555-5555 phone format
                    const { phoneCountryCode } = getValues()
                    const { value: localPhone } = e.target
                    if (!localPhone) props.onChange(localPhone)
                    const masked = maskPhoneNumber(localPhone, phoneCountryCode)

                    // Only allow numbers and spaces. The masked format requires spaces, but they are cleaned before submit.
                    if (masked?.match(/^[0-9\s]*$/)) {
                      props.onChange(masked)
                    }
                  }}
                  placeholder={'555-555-5555'}
                  suffix={
                    me?.is_phone_verified ? (
                      <PrismPassIcon variant="low" className={Styles.verifiedIcon} />
                    ) : showPhoneVerification ? (
                      <PrismUnknownIcon variant="low" className={Styles.verifiedIcon} />
                    ) : null
                  }
                  size="small"
                />
              )}
            />
            <InputError>{errors.phoneNumber?.message || errors.phoneCountryCode?.message}</InputError>
            {showPhoneVerification && (
              <section className={Styles.verificationSection}>
                <PrismInput
                  placeholder="000-000"
                  onChange={handleVerificationTextChange}
                  label="Verification code"
                  size="small"
                  labelClassName={Styles.inputLabel}
                />
                <Button
                  type="tertiary"
                  size="small"
                  className={Styles.resendVerificationButton}
                  onClick={handleVerificationTextResend}
                >
                  Resend
                </Button>
              </section>
            )}
          </section>
        </div>
      </div>
    </PrismContainer>
  )
}

export default ContactInformation

const getDefaultFormValues = (me: Me | undefined) => {
  let number
  if (me?.phone_number) {
    number = parsePhoneNumber(me?.phone_number)
  }
  const code = number?.countryCallingCode ? `+${number.countryCallingCode}` : ''
  const returnVal = {
    email: me?.email,
    phoneCountryCode: number?.countryCallingCode ? '+' + number.countryCallingCode : '',
    phoneNumber: maskPhoneNumber(number?.nationalNumber as string, code) || '',
  }
  return returnVal
}

const maskPhoneNumber = (localphone: string | undefined, callingCode?: CountryCallingCode) => {
  if (!localphone) return

  if (!callingCode) return localphone

  const asYouTypeFormatter = new AsYouType()
  const formattedNumber = asYouTypeFormatter.input(callingCode + localphone)
  if (!asYouTypeFormatter.getCallingCode()) {
    return localphone
  }

  return formattedNumber.split('+' + asYouTypeFormatter.getCallingCode())[1]
}
