import React, { useCallback, useEffect, useState } from 'react'

import { Radio, Switch } from 'antd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismDropperIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSlider, PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import { NonTrainableToolSettingsProps, PreprocessingOptions, RoutineWithAois, Tool } from 'types'
import {
  convertAltToPreprocessingOptions,
  convertPreprocessingOptionsToAlt,
  getRegex,
  getRegexCopy,
  getRgbFromHsvRanges,
  protectedOnChange,
} from 'utils'

import EditAoisHelper from './AOIEditing/EditAoisHelper'
import RotationAndScaleInputs from './Common/RotationAndScaleInputs'
import { useShowGrid } from './Common/toolSettingsHooks'
import RegexPlaygroundModal from './RegexPlaygroundModal'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders the left side of the Ocr Tool. The user can set all preprocessing
 * parameters in this panel, expected text, hsv values and transformation values
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const OcrToolSettings = ({ routine, tool, recipe, onExit, readOnly }: NonTrainableToolSettingsProps) => {
  const history = useHistory()
  const { showGrid, setShowGrid } = useShowGrid()

  const [showEyeDropper, setShowEyeDropper] = useState<boolean>(false)

  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
    watch,
    setValue,
  } = formData

  const { regex, inferenceArgs } = watch(['regex', 'inferenceArgs'])

  const handleRegexPlaygroundApply = (regex: string) => {
    setValue('regex', regex, { shouldValidate: true, shouldDirty: true })
    setIsRegexPlaygroundVisible(false)
  }

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const handlerFunction = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') setShowEyeDropper(false)
  }, [])

  const [isRegexPlaygroundVisible, setIsRegexPlaygroundVisible] = useState(false)

  const saveChanges = () => {
    const { regex, inferenceArgs, overrideCustomId, aois } = getValues()

    const { dirtyFields } = formState

    const promises = []
    if (dirtyFields.regex || dirtyFields.overrideCustomId) {
      const updatedInferenceUserArgs = { ...tool.inference_user_args, regex, override_custom_id: overrideCustomId }
      promises.push(
        service.patchProtectedTool(tool.id, {
          aois: aois.map(aoi => ({ id: aoi.id, inference_user_args: updatedInferenceUserArgs })),
        }),
      )
    }

    if (dirtyFields.inferenceArgs) {
      promises.push(
        service.patchTool(tool.id, {
          inference_args: {
            ...tool.inference_args,
            ...(inferenceArgs ? convertAltToPreprocessingOptions(inferenceArgs) : {}),
          },
        }),
      )
    }
    return promises
  }

  useEffect(() => {
    if (showEyeDropper) {
      window.addEventListener('keydown', handlerFunction)
    } else {
      window.removeEventListener('keydown', handlerFunction)
    }
  }, [showEyeDropper, handlerFunction])

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    wrapperClassName={Styles.settingsItemInput}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                  />
                )}
              />
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <div className={Styles.settingsItem}>
              <div className={Styles.settingsItemSwitchAndTitle}>
                <div className={Styles.settingsItemTitle}>Use text as item id</div>
                <Controller
                  name="overrideCustomId"
                  control={control}
                  render={({ onChange, value }) => (
                    <Switch
                      size="default"
                      checked={value}
                      onChange={checked => onChange(checked)}
                      disabled={readOnly}
                    />
                  )}
                />
              </div>
              <div className={Styles.settingsText}>
                Use the text found by this tool as the Item ID. If multiple results are found, they will be
                concatenated.
              </div>
            </div>

            <Controller
              control={control}
              name="inferenceArgs"
              render={({ onChange, value }) => {
                function setOptions<T>(options: { [key: string]: T }) {
                  onChange({
                    ...value,
                    ...options,
                  })
                }

                return (
                  <>
                    <RotationAndScaleInputs
                      setShowGrid={setShowGrid}
                      setInferenceArgs={setOptions}
                      routine={routine}
                      recipe={recipe}
                      inferenceArgs={value}
                      readOnly={readOnly}
                    />
                    <div className={Styles.settingsItem}>
                      <div className={Styles.settingsItemTitleWithSwitch}>
                        <div className={Styles.switchTitle}>Color Filter</div>

                        <div className={Styles.settingsTitleSwitch}>
                          <Switch
                            checked={value?.hsv_enable}
                            disabled={readOnly}
                            onChange={protectedOnChange(val => setOptions({ hsv_enable: val }), {
                              routine,
                              history,
                              recipe,
                            })}
                            size="small"
                          />
                        </div>
                      </div>

                      {value?.hsv_enable && (
                        <div className={Styles.settingsSubContainer}>
                          <div className={Styles.settingsItemTitleWithSwitch}>
                            <div className={Styles.switchTitle}>Target</div>

                            <div className={Styles.dropperContainer}>
                              <div
                                className={Styles.dropper}
                                onClick={protectedOnChange(
                                  e => {
                                    e.stopPropagation()
                                    setShowEyeDropper(!showEyeDropper)
                                  },
                                  { routine, history, recipe },
                                )}
                              >
                                <PrismDropperIcon hasBackground />
                              </div>
                              <div
                                className={Styles.settingsDropperPreview}
                                style={{
                                  backgroundColor: `${getRgbFromHsvRanges(value).rgbString}`,
                                }}
                              ></div>
                            </div>
                          </div>

                          <PrismSliderRange
                            label="Hue"
                            min={0}
                            max={179}
                            valueStart={value.hsv_min_h}
                            valueEnd={value.hsv_max_h}
                            onChange={protectedOnChange(val => setOptions({ hsv_min_h: val[0], hsv_max_h: val[1] }), {
                              routine,
                              history,
                              recipe,
                            })}
                          />
                          <PrismSliderRange
                            label="Saturation"
                            min={0}
                            max={255}
                            valueStart={value.hsv_min_s}
                            valueEnd={value.hsv_max_s}
                            onChange={protectedOnChange(val => setOptions({ hsv_min_s: val[0], hsv_max_s: val[1] }), {
                              routine,
                              history,
                              recipe,
                            })}
                          />

                          <PrismSliderRange
                            label="Value"
                            min={0}
                            max={255}
                            valueStart={value.hsv_min_v}
                            valueEnd={value.hsv_max_v}
                            onChange={protectedOnChange(val => setOptions({ hsv_min_v: val[0], hsv_max_v: val[1] }), {
                              routine,
                              history,
                              recipe,
                            })}
                          />
                        </div>
                      )}
                    </div>

                    {value.hsv_enable && (
                      <div className={Styles.settingsItem}>
                        <div className={Styles.settingsItemTitle}>Transform Filter</div>

                        <div className={`${Styles.grayCover} ${Styles.settingsTransformFilters}`}>
                          <div className={Styles.settingsTransformFilterItem}>
                            <PrismCheckbox
                              className={Styles.filterCheckbox}
                              onChange={protectedOnChange(e => setOptions({ dilation_enable: e.target.checked }), {
                                routine,
                                history,
                                recipe,
                              })}
                              checked={value.dilation_enable}
                              label="Dilate"
                            />

                            {value.dilation_enable && (
                              <div className={Styles.settingsSubContainer}>
                                <PrismSlider
                                  label="Kernel Width"
                                  value={value.dilation_width}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ dilation_width: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />
                                <PrismSlider
                                  label="Kernel Height"
                                  value={value.dilation_height}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ dilation_height: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Iterations"
                                  value={value.dilation_iterations}
                                  min={1}
                                  max={10}
                                  onChange={protectedOnChange(val => setOptions({ dilation_iterations: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />
                              </div>
                            )}
                          </div>

                          <div className={Styles.settingsTransformFilterItem}>
                            <PrismCheckbox
                              className={Styles.filterCheckbox}
                              onChange={protectedOnChange(e => setOptions({ erosion_enable: e.target.checked }), {
                                routine,
                                history,
                                recipe,
                              })}
                              checked={value.erosion_enable}
                              label="Erode"
                            />

                            {value.erosion_enable && (
                              <div className={Styles.settingsSubContainer}>
                                <PrismSlider
                                  label="Kernel Width"
                                  value={value.erosion_width}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ erosion_width: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Kernel Height"
                                  value={value.erosion_height}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ erosion_height: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Iterations"
                                  value={value.erosion_iterations}
                                  min={1}
                                  max={10}
                                  onChange={protectedOnChange(val => setOptions({ erosion_iterations: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />
                              </div>
                            )}
                          </div>

                          {value.erosion_enable && value.dilation_enable && (
                            <div className={Styles.settingsTransformFilterItem}>
                              <div className={Styles.settingsItemTitle}>Transform Order</div>

                              <Radio.Group
                                className={Styles.transformOrderOptions}
                                onChange={protectedOnChange(
                                  e => setOptions({ erosion_first: e.target.value === 'erosion' }),
                                  { routine, history, recipe },
                                )}
                                value={value.erosion_first ? 'erosion' : 'dilation'}
                              >
                                <Radio value="dilation" className={Styles.radioContainer}>
                                  Dilation First
                                </Radio>

                                <Radio value="erosion" className={Styles.radioContainer}>
                                  Erosion First
                                </Radio>
                              </Radio.Group>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={Styles.settingsItem}>
                      <Controller
                        control={control}
                        name="regex"
                        rules={{ validate: value => !!getRegex(value) }}
                        render={({ value, onChange, ...rest }) => {
                          const regexIsValid = !!getRegex(value)
                          return (
                            <>
                              <PrismInput
                                {...rest}
                                onChange={onChange}
                                wrapperClassName={Styles.grayCover}
                                label="Pass Criteria"
                                value={value}
                                className={Styles.regexInput}
                                disabled={readOnly}
                                data-testid="ocr-tool-settings-regex"
                              />

                              <div className={Styles.settingsText}>
                                {!regexIsValid && (
                                  <>
                                    <span className={Styles.errorText}>Regex is invalid</span>
                                    <br />
                                  </>
                                )}
                                <span>{getRegexCopy(value, 'ocr')}</span>
                                <br />
                                <br />
                                Use normal characters or{' '}
                                <span className={Styles.blueText} onClick={() => setIsRegexPlaygroundVisible(true)}>
                                  Regex
                                </span>{' '}
                                to update pass criteria
                                <>
                                  {' '}
                                  or{' '}
                                  <span className={Styles.blueText} onClick={() => onChange('.*')}>
                                    click here to reset.
                                  </span>
                                </>
                              </div>
                            </>
                          )
                        }}
                      />
                    </div>
                  </>
                )
              }}
            />
            <RegexPlaygroundModal
              visible={isRegexPlaygroundVisible}
              onCloseModal={() => setIsRegexPlaygroundVisible(false)}
              regexString={regex}
              onApply={handleRegexPlaygroundApply}
            />
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              showPreview
              disableToggleButtons={isRegexPlaygroundVisible}
              previewOptions={{ inferenceArgs, showGrid, showEyeDropper, setShowEyeDropper, setValue }}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const { name, aois } = getToolNameAndAois(routine, tool)

  return {
    aois,
    name,
    regex: tool.inference_user_args.regex || '',
    inferenceArgs: convertPreprocessingOptionsToAlt(tool.inference_args as PreprocessingOptions | undefined),
    overrideCustomId: !!tool.inference_user_args.override_custom_id as boolean,
  }
}
