import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { Robot } from 'types'

import Styles from './Capture.module.scss'

interface Props {
  onClose: () => any
  visible: boolean
  robot: Robot | undefined
  onRenameRobot: (name: string) => any
}

export default function RenameRobotModal({ onClose, visible, robot, onRenameRobot }: Props) {
  const defaultValues = getDefaultValues(robot)
  const {
    formState: { isDirty, dirtyFields },
    control,
    reset,
    getValues,
    errors,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    reset(getDefaultValues(robot))
  }, [reset, robot])

  const renameRobot = async () => {
    const { cameraName } = getValues()

    if (robot && cameraName && dirtyFields.cameraName) {
      const renameRes = await service.patchRobot(robot?.id, { body: { name: cameraName } })
      if (renameRes.type === 'success') {
        success({ title: 'Camera renamed' })
        onRenameRobot(cameraName)
      } else error({ title: 'Failed to rename camera, please try again' })

      reset(getDefaultValues(renameRes.queryData?.data))
    }
    onClose()
  }

  return (
    <>
      {visible && (
        <Modal
          id="rename-robot"
          header="Rename camera"
          onClose={onClose}
          okText="Rename"
          onOk={renameRobot}
          disableSave={!isDirty || !!errors.cameraName}
          size="largeSimpleForm"
          className={Styles.renameRobotoModal}
          headerClassName={Styles.modalHeader}
          modalFooterClassName={Styles.modalFooter}
        >
          <Controller
            control={control}
            rules={{ required: "Name can't be blank" }}
            name="cameraName"
            render={props => <PrismInput {...props} label="Rename camera" errors={errors} />}
          />
        </Modal>
      )}
    </>
  )
}

const getDefaultValues = (robot: Robot | undefined) => {
  return {
    cameraName: robot?.name,
  }
}
