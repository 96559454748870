import React, { SyntheticEvent } from 'react'

import { TooltipPlacement } from 'antd/lib/tooltip'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismPassIcon } from 'components/prismIcons'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'

import Styles from './PrismCheckmark.module.scss'

type Props = {
  className?: string
  disabled?: boolean
  tooltipDescription: string
  checked: boolean
  onChecked: (checked: boolean, event: SyntheticEvent) => any
  placement?: TooltipPlacement
}

/**
 * Renders a checkmark button. It works like a checkbox but with a checkmark icon
 * @param className - Classname for the icon button
 * @param disabled - Whether the button should be disabled
 * @param tooltipDescription - The tooltip to show in case it's necessary
 * @param placement - tooltip's placement
 * @param checked - Whether the checkmark is checked or not
 * @param onChecked - handler for when the checkmark is clicked
 */
export const PrismCheckmark = ({ className, disabled, tooltipDescription, placement, checked, onChecked }: Props) => {
  return (
    <PrismTooltip title={tooltipDescription} className={Styles.simpleTooltip} placement={placement}>
      <IconButton
        icon={
          <div className={`${Styles.checkboxContainer} ${checked ? Styles.isChecked : ''} `}>
            <PrismPassIcon className={Styles.checkIcon} />
          </div>
        }
        type="tertiary"
        size="xsmall"
        onClick={e => onChecked(!checked, e)}
        className={className ?? ''}
        disabled={disabled}
      />
    </PrismTooltip>
  )
}
