import React from 'react'

import Styles from './ProgressBar.module.scss'

const heightClasses = {
  small: Styles.prismProgressBar_small,
  medium: Styles.prismProgressBar_medium,
  extraMedium: Styles.prismProgressBar_extraMedium,
  large: Styles.prismProgressBar_large,
  extraLarge: Styles.prismProgressBar_extraLarge,
}
const typeClasses = {
  default: Styles.defaultColor,
  white: Styles.whiteColor,
  pass: Styles.passColor,
  fail: Styles.failColor,
  unknown: Styles.unknownColor,
}

interface Props {
  progress: number | string
  height?: 'small' | 'medium' | 'extraMedium' | 'large' | 'extraLarge'
  type?: 'default' | 'white' | 'pass' | 'fail' | 'unknown'
  className?: string
  style?: React.CSSProperties
  key?: string
  'data-testid'?: string
}

/**
 * Renders a custom Progress bar
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12-159&t=SnBOEy7XNpBT7rX9-0
 *
 *
 * @param progress – Number from 0 to 100, will fill the progress bar progress
 * @param height – Change the vertical height (make it slim or thick)
 * @param type – Change the colors and shape of the graph, the default is set to white and has rounded borders.
 * @param className – Optional class to be appended
 * @param style – Optional styles object
 */

export const ProgressBar = ({
  progress = 0,
  height = 'extraLarge',
  type = 'default',
  className = '',
  style,
  key,
  'data-testid': dataTestId,
}: Props) => {
  const heightClass = heightClasses[height]
  const typeClass = typeClasses[type]
  return (
    <span
      className={`${Styles.prismProgressBarContainer} ${className ?? ''} ${typeClass}`}
      style={style}
      key={key}
      data-testid={dataTestId}
      data-test-attribute={progress}
    >
      <div className={`${Styles.prismProgressBar} ${heightClass} `} style={{ width: `${progress}%` }}>
        &nbsp;
      </div>
    </span>
  )
}
