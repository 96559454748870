import React from 'react'

import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { AlignmentAnchor, NonTrainableToolSettingsProps, RoutineWithAois, Tool } from 'types'
import { protectedOnChange } from 'utils'

import EditAoisHelper from '../AOIEditing/EditAoisHelper'
import Styles from '../ToolStyles.module.scss'
import { ResetFormArgs, ToolTemplate } from '../ToolTemplate'
import SettingsBody from './SettingsBody'

/**
 * Renders the Alignment Tool.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes
 *     done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const AlignmentToolSettings = ({ routine, recipe, tool, onExit, readOnly }: NonTrainableToolSettingsProps) => {
  const history = useHistory()

  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const { reset, control, getValues } = formData

  const saveChanges = () => {
    const { b_boxes, inferenceArgs } = getValues()
    const boxes = b_boxes.map(box => ({ x: box.x, y: box.y, width: box.width, height: box.height, id: box.id }))
    return [service.patchTool(tool.id, { inference_args: { ...inferenceArgs, b_boxes: boxes } })]
  }

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const disableSaveTooltip = tool.inference_args.b_boxes?.length === 0 ? 'You need at least one anchor' : undefined

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            resetForm={resetForm}
            routine={routine}
            recipe={recipe}
            tool={tool}
            readOnly={readOnly}
            disableSaveTooltip={disableSaveTooltip}
            saveChanges={saveChanges}
            onExit={onExit}
            canDuplicateTool={false}
          >
            <Controller
              control={control}
              name="inferenceArgs"
              render={({ onChange, value }) => (
                <SettingsBody
                  inferenceArgs={value}
                  recipe={recipe}
                  onChangeInferenceArgs={protectedOnChange(e => onChange(e), { routine, history, recipe })}
                  imageSrc={routine.image}
                  readOnly={readOnly}
                  routine={routine}
                />
              )}
            />
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />

      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="b_boxes"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              mode="anchors"
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  return {
    b_boxes: (tool.inference_args.b_boxes as AlignmentAnchor[]) || [],
    inferenceArgs: tool.inference_args,
  }
}
