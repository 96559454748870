import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Bell Icon
 *
 * * Reference: https://www.figma.com/file/dRaRzBnU4t2ST2kqN1KwR0/Notify-(main)?type=design&node-id=201-4869&mode=design&t=jhL8FkPjEuNosNz9-0
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismBellIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M5.5 8.96675C5.5 6.61096 7.49529 4.66675 10 4.66675C12.5047 4.66675 14.5 6.61096 14.5 8.96675V13.6667H5.5V8.96675Z"
        stroke="currentColor"
      />
      <mask id="path-3-inside-1_201_4964" fill="white">
        <path d="M4.16667 13.8335C4.16667 13.5574 4.39053 13.3335 4.66667 13.3335H15.3333C15.6095 13.3335 15.8333 13.5574 15.8333 13.8335V14.1668H4.16667V13.8335Z" />
      </mask>
      <path
        d="M15.8333 14.1668V15.1668H16.8333V14.1668H15.8333ZM4.16667 14.1668H3.16667V15.1668H4.16667V14.1668ZM4.66667 14.3335H15.3333V12.3335H4.66667V14.3335ZM14.8333 13.8335V14.1668H16.8333V13.8335H14.8333ZM15.8333 13.1668H4.16667V15.1668H15.8333V13.1668ZM5.16667 14.1668V13.8335H3.16667V14.1668H5.16667ZM15.3333 14.3335C15.0572 14.3335 14.8333 14.1096 14.8333 13.8335H16.8333C16.8333 13.0051 16.1618 12.3335 15.3333 12.3335V14.3335ZM4.66667 12.3335C3.83824 12.3335 3.16667 13.0051 3.16667 13.8335H5.16667C5.16667 14.1096 4.94281 14.3335 4.66667 14.3335V12.3335Z"
        fill="currentColor"
        mask="url(#path-3-inside-1_201_4964)"
      />
      <path
        d="M9.66667 3.75008C9.66667 3.56599 9.81591 3.41675 10 3.41675C10.1841 3.41675 10.3333 3.56599 10.3333 3.75008V4.50008H9.66667V3.75008Z"
        stroke="currentColor"
      />
      <path
        d="M8.33334 13.75V15C8.33334 15.9205 9.07954 16.6667 10 16.6667C10.9205 16.6667 11.6667 15.9205 11.6667 15V13.75"
        stroke="currentColor"
      />
    </svg>
  )
}
