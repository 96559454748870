import React, { useCallback, useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'

import { service } from 'api'
import { Button } from 'components/Button/Button'
import { error as errorMessage } from 'components/PrismMessage/PrismMessage'
import { error, open, success } from 'components/PrismNotification/PrismNotification'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import { BatchItem, RoutineWithAois } from 'types'
import { calculatePercentage, sleep, ulidUuid } from 'utils'

import Styles from './ItemUploader.module.scss'

const MAX_ITEMS_UPLOAD = 25
const MAX_PRESIGNED = 1000

/**
 * Renders the notification at the bottom of the screen which shows the progress. This component returns
 * null because the notification is generated dynamically. This component fetches the list of files to
 * upload from redux and goes through the whole process of creating an inspection, retrieving the
 * upload presigned urls, uploading images and creating items for the current routine.
 */
function ItemUploader() {
  const dispatch = useDispatch()
  const urlsToUpload = useRef<string[]>([])
  const inspectionId = useRef<string>()
  // add a unique generated uuid for each upload so notification can be tracked properly
  const [notificationId, setNotificationId] = useState(ulidUuid())
  /*
   * Business Rules:
   * We don't want the useEffect to be called again as that would create duplicate inspections and
   * we need these three states to handle three different cases: 1. If no cancel has been initiated or
   * cancel didn't go through, continue the normal upload. 2. If the cancel button is clicked and
   * we're waiting for confirmation and 3. if the user canceled and confirmed, we want to break out of
   * the while loop and finish the upload abruptly.
   */

  // We need a separate ref and state for cancelling because this ref is used inside a while loop
  const cancellingRef = useRef<'off' | 'confirm' | 'on' | 'error'>('off')

  // Retrieve items from redux
  const itemUpload = useTypedSelector(state => state.itemUpload)
  const { files, routine, recipe } = itemUpload || {}

  const [uploadProgress, setUploadProgress] = useState<number | 'done'>()

  // We also do want to keep track of this state in to rerender the notification with the cancel confirmation
  const [cancelling, setCancelling] = useState(false)

  const showError = useCallback(() => {
    error({
      id: notificationId,
      title: 'Upload Aborted',
      description: <div>The upload couldn't be completed due to an issue with your connection.</div>,
      position: 'bottom-left',
    })

    cancellingRef.current = 'error'
    setCancelling(false)
    setUploadProgress(undefined)
    dispatch(Actions.itemUploadUpdate(null))
    urlsToUpload.current = []
    setNotificationId(ulidUuid())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const saveItems = useCallback(async () => {
    const inspectionIdString = inspectionId.current
    if (!files || !routine || !inspectionIdString) return

    const urls = urlsToUpload.current
    const items = urls.map(url => getDefaultItemByUrl(url, routine, inspectionIdString))

    const res = await service.batchItemCreate(items, { retry: { retries: 7, delay: 10000 } })

    if (res.type === 'success') {
      urlsToUpload.current = []
    } else {
      showError()
    }
  }, [files, routine, showError])

  // This effect allows us to save all pending images when the user attempts to
  // navigate away from the app.
  useEffect(() => {
    if (!files || !routine) return

    const handler = async () => {
      await saveItems()
    }

    // Show confirmation before leaving the page, and save the items just in case
    window.onbeforeunload = handler

    return () => {
      window.onbeforeunload = null
    }
  }, [files, routine, saveItems])

  // This effect runs once for every image upload, and is responsible for sending all commands to
  // Django for starting inspections, creating items and uploading pictures from the current picture
  // set. We need this to be an effect as we only want it to run in case we get a new set of
  // files.
  useEffect(() => {
    if (!files || !routine || !recipe) return
    const fn = async () => {
      // We first create a fake inspection
      const res = await service.createFakeInspection(routine.id, recipe.id, `Image Upload ${moment().format()}`)
      if (res.type !== 'success') {
        return errorMessage({ title: "Couldn't create inspection" })
      }
      inspectionId.current = res.data.inspection.id

      const presignedResponses = []
      // Secondly, we fetch presigned urls for every single image we want to upload
      for (let i = 1; i <= Math.ceil(files.length / MAX_PRESIGNED); i++) {
        // We need to iterate here because we can fetch maximum 1000 presigned urls, so we send a command
        // For every thousand urls.
        // The following will let us get either 1000 urls or however many urls we have left to fetch
        const urlsToFetch = files.length > i * MAX_PRESIGNED ? MAX_PRESIGNED : files.length - (i - 1) * MAX_PRESIGNED

        // We don't have to worry about png because the Uploader is converting all images into jpg
        // We mark these uploads as `routine_image` not `picture_image` such that they don't get moved to glacier
        const urlsRes = await service.getBatchPresignedUrls('routine_image', 'jpg', urlsToFetch)
        if (urlsRes.type === 'success') presignedResponses.push(...urlsRes.data.results)
        else {
          showError()
          return
        }
      }

      const filesToUpload = [...files]
      let i = 0

      while (filesToUpload.length) {
        if (cancellingRef.current === 'confirm') {
          // If we're waiting for confirmation on the notification, wait infinitely on this while loop
          await sleep(200)
          continue
        }

        if (cancellingRef.current === 'on') {
          // If the user decided to cancel the upload, break out of the loop
          break
        }

        if (cancellingRef.current === 'error') {
          // If there's an error in the upload, break out and stop everything
          return
        }

        // Remove first element of array
        const file = filesToUpload.shift()
        if (!file) break
        const presignedRes = presignedResponses[i]
        if (presignedRes) {
          const { url, headers } = presignedRes
          setUploadProgress(i + 1)

          const uploadRes = await service.uploadFile(file, url, headers, {
            retry: { retries: 7, delay: 10000 },
          })

          if (uploadRes.type === 'success') {
            urlsToUpload.current.push(url)
          } else {
            showError()
            return
          }
        }
        if (urlsToUpload.current.length === MAX_ITEMS_UPLOAD) await saveItems()
        i++
      }

      if (urlsToUpload.current.length) await saveItems()
      setUploadProgress('done')
    }

    fn()
  }, [dispatch, files, recipe, routine, saveItems, showError])

  // Used just to show a loader in the cancel button untile the other effects cancel the upload
  const waitUntilCancelled = async (): Promise<boolean> => {
    if (cancellingRef.current === 'on') {
      await sleep(100)
      return waitUntilCancelled()
    }

    return true
  }

  // Notification effect
  useEffect(() => {
    if (!files || !routine) return

    const cancelButton = (
      <Button
        type="secondary"
        size="small"
        onClick={() => {
          setCancelling(true)
          cancellingRef.current = 'confirm'
        }}
      >
        Cancel
      </Button>
    )

    const cancelConfirmButtons = (
      <div className={Styles.buttonsContainer}>
        <Button
          type="danger"
          size="small"
          onClick={async () => {
            cancellingRef.current = 'on'
            await waitUntilCancelled()
          }}
        >
          Yes, Cancel
        </Button>

        <Button
          type="secondary"
          size="small"
          onClick={() => {
            setCancelling(false)
            cancellingRef.current = 'off'
          }}
        >
          No, Continue
        </Button>
      </div>
    )

    if (cancelling && typeof uploadProgress === 'number') {
      error({
        id: notificationId,
        closable: false,
        title: <div className={Styles.notificationTitle}>Are you sure?</div>,
        description: (
          <div className={Styles.notificationDescription}>
            {uploadProgress || 0} of {files.length} images have already uploaded. Do you want to cancel the other{' '}
            {files.length - (uploadProgress || 0)} images?
          </div>
        ),
        position: 'bottom-left',
        duration: 0,
        children: cancelConfirmButtons,
      })
      return
    }

    if (uploadProgress === 'done' && cancelling) {
      error({
        id: notificationId,
        title: 'Upload Canceled',
        position: 'bottom-left',
        description: <div>You have canceled your image upload.</div>,
      })
      cancellingRef.current = 'off'
      setCancelling(false)
      setUploadProgress(undefined)
      setNotificationId(ulidUuid())
      dispatch(Actions.itemUploadUpdate(null))
      return
    }

    if (uploadProgress === 'done') {
      success({
        id: notificationId,
        title: 'Images Uploaded',
        description: (
          <div>
            {files.length} of {files.length} images uploaded for labeling on {routine.parent.name} v{routine.version}.
          </div>
        ),
        position: 'bottom-left',
      })
      cancellingRef.current = 'off'
      setCancelling(false)
      setUploadProgress(undefined)
      setNotificationId(ulidUuid())
      dispatch(Actions.itemUploadUpdate(null))
      return
    }

    if (uploadProgress) {
      open({
        id: notificationId,
        closable: false,
        title: 'Uploading images',
        description: (
          <div className={Styles.notificationDescription}>
            {uploadProgress} of {files.length} images uploaded for labeling on {routine.parent.name} v{routine.version}
            .
            <ProgressBar
              className={Styles.notificationProgressBar}
              height="medium"
              progress={calculatePercentage(uploadProgress, files.length)}
            />
          </div>
        ),
        duration: 0,
        children: uploadProgress !== files.length && cancelButton,
        position: 'bottom-left',
      })
      return
    }

    open({
      id: notificationId,
      title: 'Uploading images',
      closable: false,
      description: <div>Starting Upload...</div>,
      duration: 0,
      children: cancelButton,
      position: 'bottom-left',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelling, files, routine, uploadProgress])

  return null
}

/**
 * Returns a shell item in the format necessary for batch creating items.
 *
 * @param url the url of the image we uploaded
 * @param routine The full routine item
 */
const getDefaultItemByUrl = (url: string, routine: RoutineWithAois, inspectionId: string): BatchItem => {
  const urlParts = url.split('/')
  const fileName = urlParts[urlParts.length - 1]?.substr(0, 15)
  return {
    inspection_id: inspectionId,
    serial_number: fileName || '',
    pictures: [
      {
        routine_id: routine.id,
        image: url,
        tool_results: routine.aois.flatMap(aoi =>
          aoi.tools.map(tool => ({
            tool_id: tool.id,
            aoi_id: aoi.id,
            prediction_outcome: 'unknown',
            prediction_metadata: {},
            inference_user_args: tool.inference_user_args,
          })),
        ),
      },
    ],
  }
}

export default ItemUploader
