import React, { SyntheticEvent, useState } from 'react'

import { isNil } from 'lodash'
import moment from 'moment'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismArrowIcon, PrismNavArrowIcon } from 'components/prismIcons'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { useDateTimePreferences, useIsColocated } from 'hooks'
import paths from 'paths'
import { Inspection, ItemExpanded, RealtimePicture, Recipe } from 'types'
import { getRobotDisplayName, matchRole } from 'utils'

import Styles from './MetadataList.module.scss'

type MetadataItemProps = {
  title: string
  description?: string
  onClick?: () => void
  'data-testid'?: string
  isLoading?: boolean
}

type MetadataListProps = {
  recipe?: Recipe
  inspection?: Inspection
  picture?: RealtimePicture
  item: ItemExpanded
}

/**
 * Renders a metadataItem to the list
 *
 * @param title - The title of the item
 * @param description - The description of the item
 * @param onClick - Function to be called when clicking the iconButton
 * @param dataTestId - The data-testid
 * @param isLoading - A condition to switch between the loading state and the loaded data.
 *
 */
const MetadataItem = ({ title, description, onClick, 'data-testid': dataTestId, isLoading }: MetadataItemProps) => {
  if (isLoading)
    return (
      <div className={`${Styles.metaDataItemLoading}`}>
        <PrismSkeleton />
        <PrismSkeleton />
      </div>
    )

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClick?.()
  }

  return (
    <li className={Styles.metaDataItem}>
      <div className={Styles.text}>
        <div className={Styles.title}>{title}</div>

        <PrismOverflowTooltip
          data-testid={dataTestId}
          content={description || '--'}
          className={Styles.description}
          tooltipPlacement="bottom"
        />
      </div>
      {onClick && (
        <IconButton
          icon={<PrismNavArrowIcon />}
          className={Styles.navArrow}
          size="xsmall"
          type="tertiary"
          onClick={handleClick}
        />
      )}
    </li>
  )
}

/**
 * Renders the MetadataList
 *
 * @param recipe - The recipe from which will get the recipe parent name and version
 * @param inspection - The inspection from which will get the inspection name
 * @param picture - Picture's image to use
 * @param item - Item element from which we will get the item date
 *
 */
const MetadataList = ({ recipe, inspection, picture, item }: MetadataListProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { dateFormat, timeFormat, timeZone } = useDateTimePreferences()
  const { isColocated } = useIsColocated()

  const robot = useQuery(
    picture?.robot_id ? getterKeys.robot(picture.robot_id) : undefined,
    () => service.getRobot(picture?.robot_id!),
    { noRefetch: true },
  ).data?.data

  const recipeName = recipe ? `${recipe.parent.name} v${recipe.version}` : undefined
  const componentName = inspection?.component.name
  const itemCreatedAt = moment(item.created_at).tz(timeZone).format(`${dateFormat}, ${timeFormat}`)
  const inspectionName = inspection?.name
  // These two handle the fake inspection case for item injection
  const stationName = inspection?.station_name || '--'
  const robotName = picture?.robot_id === null ? '--' : getRobotDisplayName(robot)

  const handleStationItemClick = () => {
    if (!inspection) return
    history.push(paths.stationDetail('overview', inspection.station_id || undefined))
  }

  const handleProductItemClick = () => {
    if (!inspection) return
    history.push(paths.inspect({ mode: 'product', params: { componentId: inspection.component.id } }))
  }

  const handleRecipeItemClick = () => {
    if (!recipe) return
    history.push(paths.settingsRecipe(recipe.parent_id, 'capture'))
  }

  const handleBatchItemClick = () => {
    if (!inspection || !inspection.station_id) return
    history.push(
      paths.stationDetail('overview', inspection.station_id, {
        historicInspectionId: inspection.id,
      }),
    )
  }

  const handleLineItemClick = () => {
    history.push(paths.inspect({ mode: 'site', params: { siteId: inspection?.site?.id } }), {
      lineIdToExpand: inspection?.subsites?.[0]?.id,
    })
  }

  const handleSiteItemClick = () => {
    history.push(paths.inspect({ mode: 'site', params: { siteId: inspection?.site?.id } }))
  }

  return (
    <div
      className={`${Styles.metaDataWrapper} ${isOpen ? Styles.wrapperIsOpen : ''}`}
      onClick={() => setIsOpen(true)}
      data-testid="metadata-list"
    >
      <div className={Styles.metaDataContainer}>
        <ul className={Styles.metaDataList}>
          <MetadataItem title="date" description={itemCreatedAt} data-testid="tool-list-panel-date-inspected" />
          <MetadataItem
            isLoading={isNil(stationName)}
            title="station"
            description={stationName}
            onClick={!isColocated && inspection?.station_id !== null ? handleStationItemClick : undefined}
            data-testid="station-name-metadata"
          />
          {!isOpen && <PrismArrowIcon direction="down" className={Styles.metaDataOpenArrow} />}
        </ul>

        <ul className={`${Styles.metaDataList} ${Styles.canBeHidden}`}>
          <MetadataItem
            isLoading={!inspection}
            title="line"
            description={inspection?.subsites?.[0]?.name}
            onClick={!isColocated && !!inspection?.subsites?.[0]?.name ? handleLineItemClick : undefined}
            data-testid="line-name-metadata"
          />
          <MetadataItem
            isLoading={!inspection}
            title="site"
            description={inspection?.site?.name}
            onClick={!isColocated && !!inspection?.site?.name ? handleSiteItemClick : undefined}
            data-testid="site-name-metadata"
          />
          <MetadataItem isLoading={isNil(robotName)} title="camera" description={robotName} />
          <MetadataItem
            isLoading={isNil(componentName)}
            title="product"
            description={componentName}
            onClick={!isColocated && matchRole('manager') ? handleProductItemClick : undefined}
            data-testid="product-name-metadata"
          />
          <MetadataItem
            isLoading={isNil(recipeName)}
            title="recipe"
            description={recipeName}
            onClick={!isColocated && matchRole('manager') ? handleRecipeItemClick : undefined}
            data-testid="recipe-name-metadata"
          />
          <MetadataItem
            isLoading={isNil(inspectionName)}
            title="batch"
            description={inspectionName}
            // if station id is null, it's a fake inspection used to inject items
            onClick={!isColocated && inspection?.station_id ? handleBatchItemClick : undefined}
            data-testid="batch-name-metadata"
          />
        </ul>
      </div>

      {isOpen && (
        <IconButton
          size="small"
          type="tertiary"
          icon={<PrismArrowIcon direction="up" />}
          className={Styles.metaDataCloseButton}
          onClick={e => {
            e.stopPropagation()
            setIsOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default MetadataList
