import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Distinct Item Icon.
 *
 * * Reference: https://www.figma.com/file/fP3hoBKxgwb0wQLl8jizIr/Routine-Settings-(main)?node-id=613%3A9952
 *
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles
 */
export const PrismDistinctItemIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.distinctItem} ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5 50.9572L32.2 51.4768C32.3856 51.584 32.6144 51.584 32.8 51.4768L32.5 50.9572ZM32.5 14L32.8 13.4804H32.2L32.5 14ZM32.5 32.4752L32.2 32.9948L32.5 33.168L32.8 32.9948L32.5 32.4752ZM48.464 41.7404L48.164 41.2208L48.464 41.7404ZM16.608 23.3L16.308 23.8196L16.608 23.3ZM16.608 23.1753L16.308 22.6556L16.608 23.1753ZM48.392 23.3067L48.692 23.8264L48.392 23.3067ZM16.536 41.7404L16.836 41.2208L16.536 41.7404ZM48.392 23.1753L48.092 23.6949L48.392 23.1753ZM48.392 23.3L48.692 23.8196L48.392 23.3ZM16.608 23.3067L16.308 23.8264L16.608 23.3067ZM16.308 23.8264L32.2 33.0016L32.8 31.9624L16.908 22.7871L16.308 23.8264ZM31.9 32.482L31.9 50.9572H33.1L33.1 32.482H31.9ZM32.8 50.4376L16.836 41.2208L16.236 42.26L32.2 51.4768L32.8 50.4376ZM17.1 41.678L17.1 23.3691H15.9L15.9 41.678H17.1ZM32.2 14.5196L48.092 23.6949L48.692 22.6556L32.8 13.4804L32.2 14.5196ZM48.092 22.7803L32.2 31.9556L32.8 32.9948L48.692 23.8196L48.092 22.7803ZM32.8 31.9556L16.908 22.7803L16.308 23.8196L32.2 32.9948L32.8 31.9556ZM16.908 23.6949L32.8 14.5196L32.2 13.4804L16.308 22.6556L16.908 23.6949ZM32.8 33.0016L48.692 23.8264L48.092 22.7871L32.2 31.9624L32.8 33.0016ZM47.9 23.3691V41.678H49.1V23.3691H47.9ZM48.164 41.2208L32.2 50.4376L32.8 51.4768L48.764 42.26L48.164 41.2208ZM47.9 41.678C47.9 41.4894 48.0007 41.3151 48.164 41.2208L48.764 42.26C48.9719 42.14 49.1 41.9181 49.1 41.678H47.9ZM16.908 22.7803C17.26 22.9836 17.26 23.4916 16.908 23.6949L16.308 22.6556C15.86 22.9143 15.86 23.5609 16.308 23.8196L16.908 22.7803ZM48.692 23.8264C48.34 24.0296 47.9 23.7756 47.9 23.3691H49.1C49.1 22.8518 48.54 22.5285 48.092 22.7871L48.692 23.8264ZM16.836 41.2208C16.9994 41.3151 17.1 41.4894 17.1 41.678H15.9C15.9 41.9181 16.0281 42.1399 16.236 42.26L16.836 41.2208ZM48.092 23.6949C47.74 23.4916 47.74 22.9836 48.092 22.7803L48.692 23.8196C49.14 23.5609 49.14 22.9143 48.692 22.6556L48.092 23.6949ZM16.908 22.7871C16.46 22.5285 15.9 22.8518 15.9 23.3691H17.1C17.1 23.7755 16.66 24.0296 16.308 23.8264L16.908 22.7871Z"
        className={Styles.cube}
      />
      <rect x="1" y="0.5" width="63" height="63" className={Styles.frame} strokeOpacity="0.64" />
    </svg>
  )
}
