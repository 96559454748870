import React, { useEffect, useMemo } from 'react'

import { isNil } from 'lodash'

import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { success, warning } from 'components/PrismMessage/PrismMessage'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import { Status } from 'components/Status/Status'
import { Token } from 'components/Token/Token'
import { RecipeExpanded, RecipeRoutine } from 'types'
import { getRobotDisplayName } from 'utils'

import { sortViewsByOldestFirstThenByName } from '../RecipeViews'
import { RobotWithToolsets } from './DeployModal'
import Styles from './DeployModal.module.scss'

interface Props {
  isModalLoaded?: boolean
  stationName?: string
  robotsWithToolsets: RobotWithToolsets[]
  deployingRobotIds: Set<string>
  deployedRobotIds: Set<string>
  setDeployedRobotIds: React.Dispatch<React.SetStateAction<Set<string>>>
  deployProgressByRobotId: { [robotId: string]: number }
  setDeployProgressByRobotId: React.Dispatch<React.SetStateAction<{ [robotId: string]: number }>>
  activeDeploymentRecipeId?: string
  resetDeployingState: () => void
  refetchToolsets: () => Promise<void>
  robotsCountForFullDeployment: number | undefined
  startingDeploymentRecipeId: string | undefined
  failedDeploymentRobotIds: string[]
  recipe: RecipeExpanded
}

/**
 * Render the Recipe versions section of Deploy modal
 *
 * @param isModalLoaded - Whether the modal is already loaded
 * @param stationName - Name of current station
 * @param robotsWithToolsets - Robots with its corresponding toolsets
 * @param deployingRobotIds - A Set that stores the robots that have a deploy in progress
 * @param setDeployingRobotIds - State updater for deployingRobotIds
 * @param deployedRobotIds - A Set that  stores the Ids of robots whose deployment has been completed
 * @param setDeployedRobotIds - State updater for deployedRobotIds
 * @param deployProgressByRobotId - An object indicating the deploy progress of each robot id
 * @param setDeployProgressByRobotId - State updater for deployProgressByRobotId
 * @param activeDeploymentRecipeId - The recipe id that is being deployed to the station
 * @param resetDeployingState - Resets all the relevant deploying states, called after station deploy finishes
 * @param refetchToolsets - Function to refetch all station robots toolsets
 * @param startingDeploymentRecipeId - If a deployment is starting, the id of the recipe that is being deployed
 * @param failedDeploymentRobotIds - Ids of robots whose deployment has failed
 */
const DeployModalStationStatus = ({
  isModalLoaded,
  stationName,
  robotsWithToolsets,
  deployingRobotIds,
  deployedRobotIds,
  setDeployedRobotIds,
  deployProgressByRobotId,
  setDeployProgressByRobotId,
  activeDeploymentRecipeId,
  resetDeployingState,
  refetchToolsets,
  robotsCountForFullDeployment,
  startingDeploymentRecipeId,
  failedDeploymentRobotIds,
  recipe,
}: Props) => {
  const totalProgress = Object.values(deployProgressByRobotId).reduce((prev, curr) => prev + curr, 0)

  const stationDeployProgress = deployingRobotIds.size ? (totalProgress / deployingRobotIds.size) * 100 : 0

  const sortedRobots = useMemo(() => {
    const robotsWithCurrentView: (RobotWithToolsets & { currentView: RecipeRoutine })[] = []
    const robotsWithoutCurrentView: RobotWithToolsets[] = []

    robotsWithToolsets.forEach(robot => {
      const currentView = recipe.recipe_routines.find(view => view.robot_id === robot.id)
      if (currentView) robotsWithCurrentView.push({ ...robot, currentView })
      else robotsWithoutCurrentView.push(robot)
    })

    return [
      ...robotsWithCurrentView.sort((robotA, robotB) =>
        sortViewsByOldestFirstThenByName(robotA.currentView, robotB.currentView),
      ),
      ...robotsWithoutCurrentView,
    ]
  }, [recipe, robotsWithToolsets])

  // This effect is in charge of resetting the deployment states when the deploy
  // for all the robots linked to the recipe ends
  useEffect(() => {
    if (!activeDeploymentRecipeId || !deployingRobotIds.size) return

    const allFinishedRobotIdsCount = deployedRobotIds.size + failedDeploymentRobotIds.length

    if (deployingRobotIds.size === allFinishedRobotIdsCount) {
      const fullDeployment = deployedRobotIds.size === robotsCountForFullDeployment
      if (fullDeployment) {
        success({ title: 'Deploy successful', 'data-testid': 'deploy-recipe-successful-message' })
      } else {
        warning({
          title: 'Deploy not successful for all cameras',
          'data-testid': 'deploy-recipe-not-successful-message',
        })
      }

      resetDeployingState()
    }
  }, [
    activeDeploymentRecipeId,
    deployedRobotIds,
    deployingRobotIds,
    failedDeploymentRobotIds,
    refetchToolsets,
    resetDeployingState,
    robotsCountForFullDeployment,
  ])

  return (
    <>
      <div className={Styles.recipeStationAndCamList}>
        <Token label="Station" className={Styles.recipeDescriptionLabel} valueClassName={Styles.deployingStation}>
          <PrismOverflowTooltip content={stationName} />

          {(activeDeploymentRecipeId || startingDeploymentRecipeId) && (
            <ProgressBar
              progress={stationDeployProgress}
              height="small"
              className={`${Styles.deployingProgressBar} ${
                stationDeployProgress >= 100 ? Styles.progressBarFilled : ''
              }`}
              data-testid={'deploy-recipe-progress-bar'}
            />
          )}
        </Token>
        <Token label="camera" className={Styles.recipeDescriptionLabel} valueClassName={Styles.recipeCamList}>
          {sortedRobots.map(robot => {
            return (
              <RecipeCam
                key={robot.id}
                robotWithToolsets={robot}
                deploying={deployingRobotIds.has(robot.id)}
                deployed={deployedRobotIds.has(robot.id)}
                isModalLoaded={isModalLoaded}
                setDeployedRobotsIds={setDeployedRobotIds}
                setDeployProgressByRobotId={setDeployProgressByRobotId}
                activeDeploymentRecipeId={activeDeploymentRecipeId}
              />
            )
          })}
        </Token>
      </div>
    </>
  )
}

export default DeployModalStationStatus

/**
 * Renders the Robot status and deploy progress
 * @param isModalLoaded - Whether the modal is already loaded
 * @param robotsWithToolsets - Robots with its corresponding toolsets
 * @param deploying - Whether a recipe is being deployed to the current robot
 * @param deployed - Whether the current deployment has been completed for the robot
 * @param setDeployedRobotsIds - Handler that updated which robots deploy have finished
 * @param setDeployProgressByRobotId - Handler that updates the current deploy progress by robot id
 */
const RecipeCam = ({
  isModalLoaded,
  robotWithToolsets,
  deploying,
  deployed,
  setDeployedRobotsIds,
  setDeployProgressByRobotId,
  activeDeploymentRecipeId,
}: {
  robotWithToolsets: RobotWithToolsets
  isModalLoaded?: boolean
  deploying: boolean
  deployed: boolean
  setDeployedRobotsIds: React.Dispatch<React.SetStateAction<Set<string>>>
  setDeployProgressByRobotId: React.Dispatch<React.SetStateAction<{ [robotId: string]: number }>>
  activeDeploymentRecipeId: string | undefined
}) => {
  const currentRoutineId = robotWithToolsets.latestToolset?.recipe?.recipe_routines.find(
    entry => entry.robot_id === robotWithToolsets.id,
  )?.routine_id

  const toolsetStatus = currentRoutineId
    ? robotWithToolsets.latestToolset?.tool_sets[currentRoutineId]?.tool_set_status
    : undefined
  const state = toolsetStatus ? toolsetStatus.state : undefined
  const progress = toolsetStatus ? toolsetStatus.metadata.progress : undefined
  const robotStatus = robotWithToolsets.status || 'loading'

  // This effect is in charge of updating the relevant states when the deployment for this robot finishes
  useEffect(() => {
    if (activeDeploymentRecipeId && robotWithToolsets.latestToolset?.recipe_status.name !== activeDeploymentRecipeId)
      return
    if (activeDeploymentRecipeId && deploying && state === 'LOADED') {
      setDeployedRobotsIds(prev => {
        const updated = new Set(prev)
        updated.add(robotWithToolsets.id)
        return updated
      })
      setDeployProgressByRobotId(prev => {
        const updated = { ...prev }
        updated[robotWithToolsets.id] = 1
        return updated
      })
    }
  }, [
    robotWithToolsets.id,
    setDeployProgressByRobotId,
    setDeployedRobotsIds,
    deploying,
    state,
    activeDeploymentRecipeId,
    robotWithToolsets.latestToolset?.recipe_status.name,
  ])

  // This effect updates the deployment progress for this robot
  useEffect(() => {
    if (deploying && !deployed && !isNil(progress)) {
      setDeployProgressByRobotId(prev => {
        const updated = { ...prev }
        updated[robotWithToolsets.id] = progress
        return updated
      })
    }
  }, [deployed, progress, robotWithToolsets.id, setDeployProgressByRobotId, deploying])

  const progressBarValue = getProgressBarValue({ progress, deployed, deploying })

  return (
    <>
      <div className={Styles.recipeCam}>
        <figure className={Styles.camContainer}>
          {isModalLoaded ? (
            <ReduxVideo
              element="transcoder-basler-image-thumbnail"
              stream="compressed"
              robotId={robotWithToolsets.id}
            />
          ) : (
            <PrismSkeleton className={Styles.recipeCamLoader} size="extraLarge" />
          )}
        </figure>
        <RobotDisplayName className={Styles.camName} robotName={getRobotDisplayName(robotWithToolsets)} />

        <div className={`${Styles.camStatus} ${!isNil(progressBarValue) ? Styles.progressBarContainer : ''}`}>
          {!isNil(progressBarValue) && (
            <ProgressBar
              progress={progressBarValue || 0}
              height="small"
              className={progressBarValue >= 100 ? Styles.progressBarFilled : ''}
            />
          )}

          {isNil(progressBarValue) && (
            <Status status={robotStatus} lighterLoaderShade showLabel={false} showLoadingCircle>
              {displayByDeployStatus[robotStatus]}
            </Status>
          )}
        </div>
      </div>
    </>
  )
}

const getProgressBarValue = ({
  progress,
  deployed,
  deploying,
}: {
  progress?: number
  deployed: boolean
  deploying: boolean
}) => {
  if (progress) return progress * 100
  if (deployed) return 100
  if (deploying) return 0
}

const displayByDeployStatus = {
  connected: 'Idle',
  stopped: 'Running Batch',
  running: 'Running Batch',
  disconnected: 'Offline',
  deploying: 'Deployed',
  queued: 'Queued for download',
  startingDeploy: 'Starting deploy',
  loading: 'Loading',
}
