import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Line Icon
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=5857-11444&mode=design&t=US7Cn8H7N454U86q-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismLineIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M14.3123 4.10954C14.0967 3.93703 13.7821 3.97199 13.6096 4.18763C13.4371 4.40326 13.472 4.7179 13.6877 4.89041L15.0746 5.99997H7.5C7.22386 5.99997 7 6.22383 7 6.49997C7 6.77611 7.22386 6.99997 7.5 6.99997H15.0746L13.6877 8.10954C13.472 8.28204 13.4371 8.59669 13.6096 8.81232C13.7821 9.02795 14.0967 9.06291 14.3123 8.89041L16.8123 6.89041C16.931 6.79552 17 6.65186 17 6.49997C17 6.34808 16.931 6.20442 16.8123 6.10954L14.3123 4.10954Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 18C7.32843 18 8 17.3284 8 16.5C8 15.6715 7.32843 15 6.5 15C5.67157 15 5 15.6715 5 16.5C5 17.3284 5.67157 18 6.5 18ZM6.5 17C6.77614 17 7 16.7761 7 16.5C7 16.2238 6.77614 16 6.5 16C6.22386 16 6 16.2238 6 16.5C6 16.7761 6.22386 17 6.5 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 16.5C19 17.3284 18.3284 18 17.5 18C16.6716 18 16 17.3284 16 16.5C16 15.6715 16.6716 15 17.5 15C18.3284 15 19 15.6715 19 16.5ZM18 16.5C18 16.7761 17.7761 17 17.5 17C17.2239 17 17 16.7761 17 16.5C17 16.2238 17.2239 16 17.5 16C17.7761 16 18 16.2238 18 16.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6715 12.8284 15 12 15C11.1716 15 10.5 15.6715 10.5 16.5C10.5 17.3284 11.1716 18 12 18ZM12 17C12.2761 17 12.5 16.7761 12.5 16.5C12.5 16.2238 12.2761 16 12 16C11.7239 16 11.5 16.2238 11.5 16.5C11.5 16.7761 11.7239 17 12 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 13C4.567 13 3 14.567 3 16.5C3 18.433 4.567 20 6.5 20H17.5C19.433 20 21 18.433 21 16.5C21 14.567 19.433 13 17.5 13H6.5ZM17.5 14H6.5C5.11929 14 4 15.1193 4 16.5C4 17.8807 5.11929 19 6.5 19H17.5C18.8807 19 20 17.8807 20 16.5C20 15.1193 18.8807 14 17.5 14Z"
        fill="currentColor"
      />
    </svg>
  )
}
