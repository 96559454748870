import React from 'react'

import Styles from './BlankState.module.scss'

export interface Props {
  header?: React.ReactNode
  title?: string
  description: React.ReactNode
  className?: string
  children?: React.ReactNode
  dataTestId?: string
  dataTestAttribute?: string
  headerClassName?: string
  descriptionDataTestId?: string
  descriptionClassName?: string
}

/**
 * Renders a generic empty state message
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5779%3A11368&t=Pu0OPCkBhVc2Sj5d-0
 *
 * @param header - Regularly used with icons, but can be replaced with other components.
 * @param title - Main title
 * @param description - string meesage displayed below the title
 * @param children - Optional react children
 */

const GenericBlankStateMessage = ({
  header,
  title,
  description,
  children,
  dataTestId,
  dataTestAttribute,
  className = '',
  headerClassName,
  descriptionDataTestId,
  descriptionClassName = '',
}: Props) => {
  return (
    <div
      className={`${Styles.genericBlankStateWrapper} ${className}`}
      data-testid={dataTestId}
      data-test-attribute={dataTestAttribute}
    >
      <div className={Styles.genericBlankState}>
        {header && <div className={`${Styles.header} ${headerClassName ?? ''}`}>{header}</div>}

        {title && <h3 className={Styles.title}>{title}</h3>}

        <p
          className={`${Styles.description} ${
            title ? Styles.descriptionSmall : Styles.descriptionBig
          } ${descriptionClassName}`}
          data-testid={descriptionDataTestId ?? undefined}
        >
          {description}
        </p>
        {children}
      </div>
    </div>
  )
}

export default GenericBlankStateMessage
