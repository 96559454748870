import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Cloud Connected Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=7599%3A15330
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismCloudConnectedIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.cloudConnected} ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.38867 11.0463L5.04661 10.9456L5.20767 10.2998C5.96705 7.25488 8.72144 5 12 5C14.618 5 16.9018 6.43679 18.1035 8.56964L18.3393 8.98823L18.8135 9.06569C21.1878 9.45358 23 11.5161 23 14C23 15.4807 22.3576 16.8101 21.3331 17.7271C21.2261 17.8229 21.1149 17.9141 21 18.0005V19.1973C21.3584 18.99 21.6934 18.7466 22 18.4722C23.2275 17.3736 24 15.777 24 14C24 11.0182 21.8249 8.54439 18.9747 8.07877C17.603 5.64419 14.9935 4 12 4C8.25154 4 5.10522 6.57805 4.23739 10.0578C1.83793 10.425 0 12.4979 0 15C0 17.7614 2.23858 20 5 20V19C2.79086 19 1 17.2091 1 15C1 12.9992 2.46997 11.3399 4.38867 11.0463Z"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        className={Styles.circleWithCheck}
        d="M13 12C16.3125 12 19 14.6875 19 18C19 21.3125 16.3125 24 13 24C9.6875 24 7 21.3125 7 18C7 14.6875 9.6875 12 13 12ZM16.3815 16.3232C16.56 16.1125 16.5339 15.797 16.3232 15.6185C16.1125 15.44 15.797 15.4661 15.6185 15.6768L11.7363 20.2599L10.3556 18.8628C10.1615 18.6664 9.84496 18.6645 9.64854 18.8586C9.45213 19.0528 9.45026 19.3693 9.64436 19.5657L11.4091 21.3515C11.5077 21.4512 11.6437 21.505 11.7839 21.4996C11.9241 21.4943 12.0555 21.4302 12.1462 21.3232L16.3815 16.3232Z"
      />
    </svg>
  )
}
