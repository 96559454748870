import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { Token } from 'components/Token/Token'
import { RecipeExpanded } from 'types'
import { isRecipeOrRoutineResponseProtected, linkRoutineRobotAndRecipe, refreshRoutineAndRecipe } from 'utils'

import RoutineLinkToCameraTable, { ViewModalProps } from './RoutineLinkToCameraTable'
import Styles from './ViewModal.module.scss'

const ERROR_MSG = 'There was an error creating the view, please try again'

export type AddViewModalFormFields = { view_name: string; robot_id: string | undefined }

const AddViewModal = ({ onClose, onOk, recipe }: ViewModalProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modalLoaded, setModalLoaded] = useState(false)

  const defaultValues = getDefaultValues(recipe.recipe_routines.length + 1)
  const {
    formState: { isValid, errors },
    control,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const selectedRobotId = watch('robot_id')

  const handleSave = async (
    {
      recipe,
      e,
      viewName,
      robotId,
    }: {
      recipe: RecipeExpanded
      e?: React.FormEvent<HTMLFormElement>
      viewName: string
      robotId?: string
    },
    newRecipeCreated?: boolean,
  ) => {
    e?.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const createRoutineRes = await service.createRoutine({
      parent: {
        name: viewName,
        component: recipe.parent.component_id,
      },
      recipe_id: recipe.id,
    })

    if (
      isRecipeOrRoutineResponseProtected(createRoutineRes, {
        recipe,
        history,
        onCreate: async newRecipe => {
          await handleSave({ recipe: newRecipe, e, viewName, robotId }, true)
        },
      })
    ) {
      return
    }

    if (createRoutineRes.type !== 'success') {
      return error({ title: ERROR_MSG })
    }

    if (!robotId) {
      if (!newRecipeCreated) {
        await refreshRoutineAndRecipe({
          routineId: createRoutineRes.data.id,
          recipeId: recipe.id,
          recipeParentId: recipe.parent_id,
          dispatch,
        })
      }
      onOk?.({ recipe_parent_id: recipe.parent.id, routine_parent_id: createRoutineRes.data.parent.id })
      return
    }

    await linkRoutineRobotAndRecipe({
      routineId: createRoutineRes.data.id,
      robotId,
      recipe,
      errorMsg: ERROR_MSG,
      dispatch,
      onOk,
      history,
    })
  }

  return (
    <Modal
      id="add-view"
      size="largeSimpleForm"
      header="Add view"
      onClose={onClose}
      onOk={async () => {
        const { view_name, robot_id } = getValues()
        await handleSave({ recipe, viewName: view_name, robotId: robot_id })
      }}
      disableSave={!isValid}
      okText="add"
      data-testid="add-view-modal"
      headerClassName={Styles.viewModalHeader}
      modalBodyClassName={Styles.viewModalBody}
      modalFooterClassName={Styles.viewModalFooter}
      onModalLoad={() => setModalLoaded(true)}
    >
      <form
        onSubmit={async e => {
          const { view_name, robot_id } = getValues()
          await handleSave({ recipe, e, viewName: view_name, robotId: robot_id })
        }}
        className={Styles.viewModalBody}
      >
        <Token label="view name" className={Styles.viewModalLabel}>
          <Controller
            name="view_name"
            rules={{ required: 'Required' }}
            control={control}
            render={props => <PrismInput {...props} errors={errors} data-testid="add-view-name-input" />}
          />
        </Token>
        <RoutineLinkToCameraTable
          recipe={recipe}
          control={control}
          modalLoaded={modalLoaded}
          selectedRobotId={selectedRobotId}
        />
      </form>
    </Modal>
  )
}

export default AddViewModal

const getDefaultValues = (viewNumber: number): AddViewModalFormFields => ({
  view_name: `View ${viewNumber}`,
  robot_id: undefined,
})
