import React from 'react'

import Styles from './PrismBadge.module.scss'

interface BadgeProps {
  type?: 'default' | 'pass' | 'warning' | 'error' | 'smokey'
  count?: number
  shape?: 'circle' | 'box' | 'roundedBox'
  size?: 'small' | 'medium' | 'big'
  className?: string
  'data-testid'?: string
  'data-test'?: string
}

const typeClasses = {
  default: Styles.default,
  pass: Styles.pass,
  warning: Styles.warning,
  error: Styles.error,
  smokey: Styles.smokey,
}

const shapeClasses = {
  circle: Styles.circle,
  box: Styles.box,
  roundedBox: Styles.roundedBox,
}

const sizeClasses = {
  small: Styles.small,
  medium: Styles.medium,
  big: Styles.big,
}

/**
 * Renders a Badge - Dot component
 *
 * * Can be used as a counter.
 *
 * @param type – Changes the badge color from blue (default), to red (error), yellow (warning), pass (green).
 * @param count – For notification counts
 * @param shape – Changes the shape of the badge, circle (default).
 * @param size - Changes the badge size.
 * @param className - Optional string class
 */
const PrismBadge = ({
  type = 'default',
  count,
  shape = 'circle',
  className = '',
  size = 'small',
  'data-testid': dataTestId,
  'data-test': dataTest,
}: BadgeProps) => {
  const typeClass = typeClasses[type]

  const shapeClass = shapeClasses[shape]

  const sizeClass = sizeClasses[size]

  return (
    <span
      className={`${Styles.badge} ${typeClass} ${shapeClass} ${sizeClass} ${className}`}
      data-testid={dataTestId}
      data-test={dataTest}
    >
      {count}
    </span>
  )
}

export default PrismBadge
