import React from 'react'

import { Button } from 'components/Button/Button'
import { PrismWarningIcon } from 'components/prismIcons'
import { Status } from 'components/Status/Status'
import { CameraStatus } from 'types'

import Styles from './RoutineTools.module.scss'

/**
 * Renders an alert box on the new batch screen if any error is thrown.
 * @param description - The text to be shown on the alert box.
 * @param buttonTitle - The button title.
 * @param to - Where the button will redirect to. If empty, button is not shown.
 * @param status - Alert status.
 * @param warning - If it is a Warning alert, a special layout is rendered.
 */
export const AlertTool = ({
  description,
  buttonTitle,
  to,
  status,
  warning,
}: {
  description: string
  buttonTitle?: string
  to?: string
  status?: CameraStatus | 'danger'
  warning?: boolean
}) => (
  <div className={`${Styles.alertContainer} ${to ? Styles.addButton : ''} `}>
    {/* Warning alert has a different layout  */}
    {!warning && status && <Status status={status} showLabel={false} className={Styles.status} />}
    <div className={Styles.alertDescription}>{description}</div>
    {warning && <PrismWarningIcon className={Styles.warningIcon} isActive />}

    {to && (
      <Button type="secondary" size="small" to={to} className={Styles.button}>
        {buttonTitle}
      </Button>
    )}
  </div>
)
