import React from 'react'

import Styles from './AddSignAnimation.module.scss'

/**
 * Renders an Add (sign) Icon, with animation when isActive
 *
 * @param isActive - adds an animation to hide the vertical line
 * @param className - string class
 */
export const AddSignAnimation = ({ isActive, className = '' }: { isActive: boolean; className?: string }) => {
  return <div className={`${Styles.signAnimationContainer} ${isActive ? Styles.isActive : ''} ${className}`} />
}
