import { Action } from 'rdx/actions'
import { RootState } from 'types'

/**
 * This branch of state tree stores information about the current organization
 */
export default function reduce(state: RootState['currentOrg'] = null, action: Action): RootState['currentOrg'] {
  switch (action.type) {
    case 'ORG_SET': {
      return action.payload
    }
    case 'ORG_UPDATE': {
      if (!state) return state

      return { ...state, ...action.payload }
    }

    default:
      return state
  }
}
