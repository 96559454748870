import React from 'react'

import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { Station } from 'types'

import Styles from './FetchingColocatedRobot.module.scss'

const FetchingColocatedRobot = ({ station }: { station: Station | undefined | null }) => {
  if (station === undefined) return <PrismLoader />
  if (station === null)
    return (
      <div className={Styles.colocatedRobotNotFound}>
        You're colocated with a Camera but it was not found in the database. This could be due to an internet connection
        issue, or your colocated setup is not configured correctly.
      </div>
    )
  return null
}

export default FetchingColocatedRobot
