import React from 'react'

import toast, { ToastOptions } from 'react-hot-toast'

import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { NOTIFICATION_ICONS } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { externalRender } from 'components/PrismModal/PrismModal'
import { getDuration } from 'components/PrismNotification/PrismNotification'

import Styles from './PrismMessage.module.scss'

export { dismiss } from 'components/PrismNotification/PrismNotification'

export type Props = {
  type?: 'success' | 'error' | 'warning' | 'info' | 'loading' | 'mute' | 'discard'
  title?: React.ReactNode
  children?: React.ReactNode
  className?: string
  id?: string
  'data-testid'?: string
  buttonText?: string
  onButtonClick?: () => void
} & ToastOptions

/**
 * Renders a Message component (left dot with pre-defined text)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param type – Determines the type of icon
 * @param children – Renders the text
 */
export const Message = ({
  type,
  children,
  className,
  id,
  'data-testid': dataTestId,
  onButtonClick,
  buttonText,
}: Props) => {
  const icon = NOTIFICATION_ICONS(type)

  return (
    <div
      className={`${Styles.messageContainer} ${className} ${!icon ? Styles.noIcon : ''}`}
      key={id}
      data-testid={dataTestId}
    >
      {icon && type !== 'loading' && <div className={Styles.iconContainer}>{icon}</div>}
      {type === 'loading' && <PrismLoader />}
      <p className={`${Styles.messageText} ${!icon ? Styles.noIconMessage : ''}`}>{children}</p>
      {buttonText && (
        <>
          <Divider type="vertical" className={Styles.messageDivider} />
          <Button type="link" onClick={onButtonClick} className={Styles.meesageLinkButton}>
            {buttonText}
          </Button>
        </>
      )}
    </div>
  )
}

const PrismMessageContainer = ({ containerClassName, ...rest }: { containerClassName?: string } & Props) => {
  return (
    <div className={`${Styles.messageContainer} ${Styles.persistentMessage} ${containerClassName ?? ''}`}>
      <Message {...rest} />
    </div>
  )
}

export const PrismMessage = externalRender(PrismMessageContainer)

/**
 * Show a notification toast
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Adds the title
 * @param type – Determines the type of icon that will be shown
 */
const toastWrapper = (options: Props) => {
  const duration = getDuration(options)

  toast.custom(
    toastProps => {
      const { visible } = toastProps
      const { id, className, ...rest } = options
      return (
        <Message className={`${className ?? ''} ${!visible ? Styles.notVisible : ''}`} key={id} id={id} {...rest}>
          {options.title}
        </Message>
      )
    },
    {
      id: options.id,
      duration: duration,
      position: options.position || 'top-center',
    },
  )
}

/**
 * Show a success toast (green)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const success = (options?: Props) => toastWrapper({ type: 'success', ...options })

/**
 * Show an error toast (red)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const error = (options?: Props) => toastWrapper({ type: 'error', ...options })

/**
 * Show a warning toast (yellow)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const warning = (options?: Props) => toastWrapper({ type: 'warning', ...options })

/**
 * Show an info toast with no icon
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const defaultMessage = (options?: Props) => toastWrapper({ ...options })

/**
 * Show an info toast (blue)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const info = (options?: Props) => toastWrapper({ type: 'info', ...options })

/**
 * Show a loading toast (blue)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const loading = (options?: Props) => toastWrapper({ type: 'loading', ...options })

/**
 * Show a muted toast (gray)
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const mute = (options?: Props) => toastWrapper({ type: 'mute', ...options })

/**
 * Show a discard toast (gray)
 *
 * See https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1822%3A9737
 *
 * @param title – Main text that will be rendered
 * @param id – Use an id to update a notification
 * @param position – Where to show the notification: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left' | 'top-center' | 'bottom-center'
 * @param duration – Milliseconds to hide the notification, defaults to DEFAULT_TIMEOUT_MS
 */
export const discard = (options?: Props) => toastWrapper({ type: 'discard', ...options })
