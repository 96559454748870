import React, { useMemo, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

import { getterKeys, query, service } from 'api'
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import { PrismAddIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal, ModalFooter, ModalHeader, PrismModal } from 'components/PrismModal/PrismModal'
import { useDefaultToolLabels } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel, ToolLabelBody, ToolLabelImage, ToolLabelSeverity, ToolSpecificationName } from 'types'
import {
  addExistingLabelsToTool,
  addLabelToTool,
  getSeverityOptionsByTool,
  showCausesAndActions,
  uploadToolLabelUserImages,
} from 'utils'
import { ALL_TOOL_LABELS_GETTER_KEY } from 'utils/constants'

import { addLabelToGetter } from './LabelGlossaryModal'
import Styles from './LabelingScreen.module.scss'
import ToolLabelDetail from './ToolLabelDetail'
import ToolLabelForm from './ToolLabelForm'
import ToolLabelSearch from './ToolLabelSearch'

export const CREATE_NEW_LABEL_OPTION = 'create-new-label'
export const EDIT_LABELS_OPTION = 'edit-labels'

const LABEL_ADDED_MESSAGE = 'Label added'

interface AddLabelModalProps {
  onClose: () => void
  currentToolLabels?: ToolLabel[]
  toolSpecificationName: ToolSpecificationName
  toolParentId: string
  onShowGlossaryClick: () => void
  initialNewLabelValue?: string
  onLabelAddedToTool?: (label: ToolLabel) => void
}

/**
 * Renders a modal to add either an existing one, or a new  label to a tool.
 *
 * @param onClose - a function that closes the modal
 * @param toolSpecificationName - Current Tool specification name
 * @param toolParentId - toolParentId
 * @param onShowGlossaryClick - a function that shows the glossary
 * @param currentToolLabels - all the labels related to the current tool
 * @param initialNewLabelValue - initial label value in case a new label is created
 * @param onLabelAddedToTool - Callback for when a new label is added to the current tool
 */
export const AddToolLabelModal = ({
  onClose,
  toolSpecificationName,
  toolParentId,
  onShowGlossaryClick,
  currentToolLabels,
  initialNewLabelValue,
  onLabelAddedToTool,
}: AddLabelModalProps) => {
  const dispatch = useDispatch()

  const [modalLoaded, setModalLoaded] = useState(false)
  const [labelSelectValue, setLabelSelectValue] = useState(initialNewLabelValue ? CREATE_NEW_LABEL_OPTION : '')
  const [newLabelValue, setNewLabelValue] = useState(initialNewLabelValue || '')
  const [selectedLabel, setSelectedLabel] = useState<ToolLabel>()
  const [severityRadioOption, setSeverityRadioOption] = useState<ToolLabelSeverity>()

  const defaultLabels = useDefaultToolLabels()

  const modalScrollRef = useRef<HTMLDivElement>(null)

  const {
    formState: { isValid },
    control,
    getValues,
    trigger,
    setValue,
    watch,
    errors,
  } = useForm({
    defaultValues: getDefaultFormValues(toolSpecificationName),
    mode: 'onChange',
  })

  const showForm = !selectedLabel && labelSelectValue.includes(CREATE_NEW_LABEL_OPTION)

  const selectedSeverity = watch('severity')

  const showRootAndCorrectiveActions = selectedSeverity && showCausesAndActions(selectedSeverity)

  const renderSelectFooter = () => {
    if (selectedLabel) {
      return (
        <div className={Styles.itemCaption}>
          You can edit this label in the
          <Button
            onClick={() => {
              onClose()
              onShowGlossaryClick()
            }}
            type="link"
            className={Styles.labelGlossaryButton}
          >
            Label Glossary
          </Button>
        </div>
      )
    }
    if (showForm) {
      return <p className={Styles.itemCaption}>Label names should be short and descriptive</p>
    }
    return null
  }

  const onAddClick = async () => {
    if (selectedLabel) {
      await addExistingLabelsToToolAndUpdate({ toolLabels: [selectedLabel], currentToolLabels, toolParentId, dispatch })

      onLabelAddedToTool?.(selectedLabel)

      onClose()
    }
    if (showForm && currentToolLabels) {
      const valid = await trigger()
      if (!valid) return

      const { severity, description, rootCauses, correctiveActions, user_images } = getValues()
      if (!severity) return

      const userImages = await uploadToolLabelUserImages(user_images)

      const labelBody: ToolLabelBody = {
        value: newLabelValue,
        severity,
        description,
        user_images: userImages || [],
      }

      if (showRootAndCorrectiveActions) {
        labelBody.root_cause = rootCauses
        labelBody.corrective_actions = correctiveActions
      }

      const labelRes = await addLabelToTool({
        labelBody,
        labels: currentToolLabels.filter(tl => tl.kind === 'custom'),
        dispatch,
        toolParentId,
      })

      if (labelRes.status === 'failure') return error({ title: 'Failed to add label to tool' })

      success({ title: LABEL_ADDED_MESSAGE, 'data-testid': 'add-label-modal-add-label-success' })
      if (labelRes.addedLabel) {
        addLabelToGetter({ toolLabel: labelRes.addedLabel, labelsGetterKey: ALL_TOOL_LABELS_GETTER_KEY, dispatch })
        onLabelAddedToTool?.(labelRes.addedLabel)
      }

      onClose()
    }
  }

  const disableAddButton = useMemo(() => {
    if ((!showForm && !selectedLabel) || !isValid) return true
    if (showForm) return false
  }, [isValid, selectedLabel, showForm])

  return (
    <PrismModal
      id="add-label"
      className={Styles.labelGlossaryModal}
      onClose={onClose}
      size="largeSimpleForm"
      onModalLoad={() => setModalLoaded(true)}
      data-testid="add-label-modal"
    >
      <ModalHeader onClose={onClose}>
        <Breadcrumbs
          crumbs={[
            {
              label: 'Add Label',
            },
          ]}
        />
      </ModalHeader>
      <div
        className={`${Styles.labelGlossaryModalBody} ${Shared.verticalChildrenGap24} ${Styles.modalBottomPadding}`}
        ref={modalScrollRef}
      >
        <ToolLabelSearch
          modalLoaded={modalLoaded}
          labelSelectValue={labelSelectValue}
          setLabelSelectValue={setLabelSelectValue}
          defaultLabels={defaultLabels}
          renderSelectFooter={renderSelectFooter}
          newLabelValue={newLabelValue}
          setNewLabelValue={setNewLabelValue}
          currentToolLabels={currentToolLabels}
          setValue={setValue}
          toolSpecificationName={toolSpecificationName}
          toolParentId={toolParentId}
          onClose={onClose}
          onShowGlossaryClick={onShowGlossaryClick}
          setSelectedLabel={setSelectedLabel}
          setSeverityRadioOption={setSeverityRadioOption}
          selectScrollParentRef={modalScrollRef}
        />

        {selectedLabel && <ToolLabelDetail toolLabel={selectedLabel} />}

        {showForm && (
          <ToolLabelForm
            mode="create"
            control={control}
            errors={errors}
            selectedSeverity={selectedSeverity}
            severityRadioOption={severityRadioOption}
            specificationName={toolSpecificationName}
          />
        )}
      </div>

      <ModalFooter className={Styles.addLabelModalFooter}>
        <Button onClick={onClose} size="small" type="ghost" data-testid="add-label-modal-cancel">
          Cancel
        </Button>
        <Button
          disabled={disableAddButton}
          onClick={() =>
            selectedLabel?.is_deleted ? unarchiveLabelConfirm(selectedLabel.value, onAddClick) : onAddClick()
          }
          badge={<PrismAddIcon />}
          size="small"
          data-testid="add-label-modal-add"
        >
          Add
        </Button>
      </ModalFooter>
    </PrismModal>
  )
}

export default AddToolLabelModal

export type LabelFormFields = {
  severity: ToolLabelSeverity | ''
  value?: string
  description?: string
  rootCauses?: string
  correctiveActions?: string
  user_images: { current: ToolLabelImage[]; toUpload: File[] }
}

/**
 * Default fetcher function for form
 * @param toolSpecificationName - Current tool specification name
 * @returns default label properties
 */
const getDefaultFormValues = (toolSpecificationName: ToolSpecificationName): LabelFormFields => {
  const severities = getSeverityOptionsByTool(toolSpecificationName)
  const severity = severities[0]
  return {
    severity: severity || '',
    description: '',
    rootCauses: '',
    correctiveActions: '',
    user_images: { current: [], toUpload: [] },
  }
}

/**
 * This function is a wrapper on addExistingLabelsToTool, it simply handles the error and success messages
 * once the request has been sent
 * @param toolLabel - Existing Tool Label to add
 * @param currentToolLabels - All current labels belonging to a tool
 * @param toolParentId - Tool Parent id to add the label to
 * @param dispatch - Redux dispatch
 */
export const addExistingLabelsToToolAndUpdate = async ({
  toolLabels,
  currentToolLabels,
  toolParentId,
  dispatch,
}: {
  toolLabels: ToolLabel[]
  currentToolLabels?: ToolLabel[]
  toolParentId: string
  dispatch: Dispatch
}) => {
  const res = await addExistingLabelsToTool({
    toolLabels,
    currentToolLabels,
    toolParentId,
  })

  if (res === 'error') return error({ title: 'Failed to add label, try again later' })

  if (res === 'success')
    success({ title: LABEL_ADDED_MESSAGE, 'data-testid': 'add-label-modal-add-existing-label-success' })
  await query(getterKeys.toolLabels(toolParentId), () => service.getToolLabels({ tool_parent_id: toolParentId }), {
    dispatch,
  })
}

/**
 * Pops up a confirmation modal asking the user if they want to unarchive the currently selected archived label
 *
 * @param label Name of the label to unarchive, this is only used to show the label name in the modal
 * @param postConfirmAction Action to be performed after the user has confirmed the modal
 */
export function unarchiveLabelConfirm(label: string, postConfirmAction: () => any) {
  modal.confirm({
    id: 'unarchive-label-confirmation',
    header: 'Are you sure?',
    'data-testid': 'add-label-modal-unarchive-modal',
    content: (
      <>
        <p>Unarchive the label "{label}"?</p>
        <br />
        <p>This will restore it as an option for labeling.</p>
      </>
    ),
    okText: 'Unarchive',
    onOk: close => {
      postConfirmAction()
      close()
    },
  })
}
