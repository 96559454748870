import React, { useEffect, useMemo } from 'react'

import { Radio } from 'antd'
import { difference, isNil } from 'lodash'
import { Control, Controller, FieldError, FieldErrors, FormProvider, useForm, useFormContext } from 'react-hook-form'

import { backendErrorCodes, getterKeys, service } from 'api'
import { Divider } from 'components/Divider/Divider'
import { InputError, PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalProps } from 'components/PrismModal/PrismModal'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { BetaTag } from 'components/Tag/Tag'
import { Token } from 'components/Token/Token'
import { useAllToolLabels, useData } from 'hooks'
import { SearchableStationsWithStatus } from 'pages/RoutineOverview/DuplicateOrMoveRecipeModal'
import {
  CreateUpdateDeleteEventScopesBody,
  EventScope,
  EventScopeBody,
  EventTargetTable,
  EventTargetWithName,
  EventTypeBody,
  InspectionEventRules,
} from 'types'
import { getDisplaySeverity, getLabelName, sortByValueAndSeverity } from 'utils'

import Shared from '../../../styles/Shared.module.scss'
import { EventTypeWithCounts } from '../QualityEvents'
import Styles from '../QualityEvents.module.scss'

type EventModalProps = {
  isEditMode?: boolean
  onSuccess: () => Promise<void>
  eventType?: EventTypeWithCounts
} & ModalProps

type BaseEventTypeBody = Pick<EventTypeBody, 'rules' | 'name'>

interface BaseEventScopeConfig {
  targetTable: EventTargetTable | 'all'
  targetIds?: string[]
  organizationId: string
}

const eventTypeFields = ['count', 'ratio', 'duration_s', 'total_count'] as const
type EventTypeFieldNames = (typeof eventTypeFields)[number]

const FAILURES_TRIGGER_TYPES = [
  'multipleFailuresInARow',
  'multipleFailuresOverATimePeriod',
  'multipleFailuresOverANumberOfItems',
  'failRateExeedsValueForATimePeriod',
] as const

const PREDICTIONS_TRIGGER_TYPES = [
  'multiplePredictionsInARow',
  'multiplePredictionsOverATimePeriod',
  'multiplePredictionsOverANumberOfItems',
  'predictionsRateExeedsValueForATimePeriod',
] as const

type FailuresTriggerType = (typeof FAILURES_TRIGGER_TYPES)[number]
type PredictionsTriggerType = (typeof PREDICTIONS_TRIGGER_TYPES)[number]

type TriggerType = FailuresTriggerType | PredictionsTriggerType

const EVENT_TRIGGER_OPTIONS: {
  value: TriggerType | ''
  title: React.ReactNode
  dataTestId?: string
  fields: {
    dataTestId?: string
    name: EventTypeFieldNames
    label: string
    min: number
    max: number
  }[]
}[] = [
  { title: 'Item Failures', value: '', fields: [] },
  {
    title: 'Multiple failures in a row',
    value: 'multipleFailuresInARow',
    dataTestId: 'multiple-failures-row',
    fields: [{ name: 'count', label: 'Number of Failures', min: 5, max: 1000, dataTestId: 'number-failures-input' }],
  },
  {
    value: 'multipleFailuresOverATimePeriod',
    title: 'Multiple failures over a time period',
    dataTestId: 'multiple-failures-over-time',
    fields: [
      { name: 'count', label: 'Number of Failures', min: 5, max: 1000, dataTestId: 'number-failures-input' },
      { name: 'duration_s', label: 'Timeframe (In Seconds)', min: 5, max: 100, dataTestId: 'duration-input' },
    ],
  },
  {
    value: 'multipleFailuresOverANumberOfItems',
    title: 'Multiple failures over a number of items',
    dataTestId: 'multiple-failures-over-items',
    fields: [
      { name: 'count', label: 'Number of Failures', min: 3, max: 1000, dataTestId: 'number-failures-input' },
      { name: 'total_count', label: 'Number of items', min: 100, max: 1000, dataTestId: 'number-items-input' },
    ],
  },
  {
    value: 'failRateExeedsValueForATimePeriod',
    title: 'Fail rate above a value for a time period',
    dataTestId: 'fail-rate-over-time',
    fields: [
      { name: 'ratio', label: 'Fail Rate (Percentage)', min: 0.5, max: 99.9, dataTestId: 'fail-rate-input' },
      { name: 'duration_s', label: 'Timeframe (In Seconds)', min: 5, max: 100, dataTestId: 'duration-input' },
    ],
  },
  {
    title: (
      <p className={Styles.optionWithBetaTag}>
        Item Predictions<span className={Styles.selectOptionBetaTag}>[beta]</span>
      </p>
    ),
    value: '',
    fields: [],
  },
  {
    title: 'Multiple predictions in a row',
    value: 'multiplePredictionsInARow',
    dataTestId: 'multiple-predictions-row',
    fields: [{ name: 'count', label: 'Number of Predictions', min: 5, max: 1000 }],
  },
  {
    value: 'multiplePredictionsOverATimePeriod',
    title: 'Multiple predictions over a time period',
    dataTestId: 'multiple-predictions-over-time',
    fields: [
      { name: 'count', label: 'Number of Predictions', min: 5, max: 1000 },
      { name: 'duration_s', label: 'Timeframe (In Seconds)', min: 5, max: 100 },
    ],
  },
  {
    value: 'multiplePredictionsOverANumberOfItems',
    title: 'Multiple predictions over a number of items',
    dataTestId: 'multiple-predictions-over-items',
    fields: [
      { name: 'count', label: 'Number of Predictions', min: 3, max: 1000 },
      { name: 'total_count', label: 'Number of items', min: 100, max: 1000 },
    ],
  },
  {
    value: 'predictionsRateExeedsValueForATimePeriod',
    title: 'Prediction rate above a value for a time period',
    dataTestId: 'predicition-rate-over-time',
    fields: [
      { name: 'ratio', label: 'Prediction Rate (Percentage)', min: 0.5, max: 99.9 },
      { name: 'duration_s', label: 'Timeframe (In Seconds)', min: 5, max: 100 },
    ],
  },
]

/**
 * Renders Quality Event Modal
 *
 * @param isEditMode - a boolean value that changes the modal
 * @param onClose - handler for closing the fullscreen modal
 */
const AddOrEditQualityEventModal = ({ isEditMode = false, onSuccess, eventType, ...rest }: EventModalProps) => {
  const organization = useData(getterKeys.organization())
  const { allToolLabels } = useAllToolLabels()

  const defaultValues = useMemo(() => getEventTypeDefaultValues(eventType), [eventType])

  const form = useForm({ defaultValues, mode: 'onChange' })
  const {
    control,
    getValues,
    watch,
    formState: { isDirty, isValid, dirtyFields },
    trigger,
    setError,
    clearErrors,
    errors,
    reset,
  } = form
  const { targetTable, triggerType } = watch(['targetTable', 'triggerType', 'count', 'total_count'])

  useEffect(() => {
    // Reset values when the triggertype changes (with the exception of the first time it renders)
    if (triggerType !== defaultValues.triggerType) reset({ ...getEventTypeDefaultValues(), triggerType })
  }, [defaultValues.triggerType, reset, triggerType])

  const getEventTypeConfig = (): { rulesData: InspectionEventRules; name: string } | undefined => {
    const { ratio, count, duration_s, triggerType, total_count, values } = getValues()

    const getLabelNames = () => {
      const labelNames = values
        .map(labelId => {
          const foundLabel = allToolLabels?.find(toolLabel => toolLabel.id === labelId)

          if (!foundLabel) return ''

          return getLabelName(foundLabel)
        })
        .join()

      return labelNames
    }

    const predictionTrigger = isPredictionTrigger(triggerType as TriggerType)

    const valuesToSet = predictionTrigger ? values : ['fail']
    const typeToSet = predictionTrigger ? 'label_ids' : 'item_outcomes'

    if (triggerType === 'multipleFailuresInARow' || triggerType === 'multiplePredictionsInARow') {
      return {
        rulesData: { type: typeToSet, count: count || undefined, total_count: count || undefined, values: valuesToSet },
        name: predictionTrigger ? `${count} predictions in a row of ${getLabelNames()}` : `${count} failures in a row`,
      }
    }

    if (triggerType === 'multipleFailuresOverATimePeriod' || triggerType === 'multiplePredictionsOverATimePeriod') {
      return {
        rulesData: {
          type: typeToSet,
          count: count || undefined,
          duration_s: duration_s || undefined,
          values: valuesToSet,
        },
        name: predictionTrigger
          ? `${count} predictions over ${duration_s}s of ${getLabelNames()}`
          : `${count} failures over ${duration_s}s`,
      }
    }

    if (
      triggerType === 'multipleFailuresOverANumberOfItems' ||
      triggerType === 'multiplePredictionsOverANumberOfItems'
    ) {
      return {
        rulesData: {
          type: typeToSet,
          count: count || undefined,
          total_count: total_count || undefined,
          values: valuesToSet,
        },
        name: predictionTrigger
          ? `${count} predictions over ${total_count} items of ${getLabelNames()}`
          : `${count} failures over ${total_count} items`,
      }
    }

    if (
      triggerType === 'failRateExeedsValueForATimePeriod' ||
      triggerType === 'predictionsRateExeedsValueForATimePeriod'
    ) {
      const backendRatio = ratio ? ratio / 100 : 0
      return {
        rulesData: {
          type: typeToSet,
          ratio: backendRatio,
          duration_s: duration_s || undefined,
          values: valuesToSet,
        },
        name: predictionTrigger
          ? `prediction rate above ${ratio}% for ${duration_s}s for ${getLabelNames()}`
          : `fail rate above ${ratio}% for ${duration_s}s`,
      }
    }
  }

  const createEventType = async (baseEventBody: BaseEventTypeBody, baseEventScopeConfig: BaseEventScopeConfig) => {
    const eventTypeBody: EventTypeBody = {
      kind: 'inspection',
      ...baseEventBody,
    }

    // Create EventType
    const eventTypeRes = await service.createEventType(eventTypeBody)

    if (eventTypeRes.type === 'error' && eventTypeRes.data.code === backendErrorCodes.eventTypeRulesAlreadyExists) {
      return error({ title: 'An event matching these trigger settings already exists' })
    }

    if (eventTypeRes.type !== 'success') {
      return error({ title: QUALITY_EVENT_ERROR_MESSAGE })
    }

    const createdEventType = eventTypeRes.data

    const eventScopesBody = getEventScopesBodyFromTargetIds({
      eventTypeId: createdEventType.id,
      ...baseEventScopeConfig,
    })

    const eventScopesRes = await service.createUpdateDeleteEventScopes({ create: eventScopesBody })

    if (eventScopesRes.type !== 'success') {
      return error({ title: QUALITY_EVENT_ERROR_MESSAGE })
    }

    await onSuccess()
    success({ title: 'Quality event created', 'data-testid': 'quality-event-created' })
  }

  const updateEventType = async ({
    baseEventBody,
    eventTypeId,
    baseEventScopeConfig,
    existingEventScopes,
  }: {
    baseEventBody: BaseEventTypeBody
    eventTypeId?: string
    baseEventScopeConfig: BaseEventScopeConfig
    existingEventScopes?: EventScope[]
  }) => {
    if (!eventTypeId || !existingEventScopes) return

    const patchEventTypeRes = await service.patchEventType(eventTypeId, baseEventBody)

    if (
      patchEventTypeRes.type === 'error' &&
      patchEventTypeRes.data.code === backendErrorCodes.eventTypeRulesAlreadyExists
    ) {
      return error({ title: 'An event matching these trigger settings already exists' })
    }

    if (patchEventTypeRes.type !== 'success') {
      return error({ title: QUALITY_EVENT_ERROR_MESSAGE })
    }

    if (!dirtyFields.targetTable && !dirtyFields.targetIds) {
      await onSuccess()
      return success({ title: 'Quality event edited' })
    }

    const createUpdateEventScpopesBody = getCreateUpdateEventScopeBody({
      eventTypeId,
      baseEventScopeConfig,
      existingEventScopes,
    })

    if (
      createUpdateEventScpopesBody.create?.length ||
      createUpdateEventScpopesBody.update?.length ||
      createUpdateEventScpopesBody.delete?.length
    ) {
      const createUpdateScopesRes = await service.createUpdateDeleteEventScopes(createUpdateEventScpopesBody)

      if (createUpdateScopesRes.type !== 'success') return error({ title: QUALITY_EVENT_ERROR_MESSAGE })
    }

    await onSuccess()

    success({ title: 'Quality event edited', 'data-testid': 'quality-event-updated' })
  }

  const handleSubmit = async () => {
    const isValid = await trigger()
    const { targetIds, targetTable } = getValues()
    if (!isValid || !targetTable || !organization) return

    if (targetTable !== 'all' && !targetIds) return

    const eventTypeConfig = getEventTypeConfig()

    if (!eventTypeConfig) return

    const { name, rulesData } = eventTypeConfig

    const baseEventBody: BaseEventTypeBody = {
      name,
      rules: { ...rulesData },
    }

    const baseEventScopeConfig = { targetTable, targetIds, organizationId: organization.id }

    if (isEditMode) {
      await updateEventType({
        baseEventBody,
        eventTypeId: eventType?.id,
        baseEventScopeConfig,
        existingEventScopes: eventType?.event_scopes,
      })
    } else {
      await createEventType(baseEventBody, baseEventScopeConfig)
    }
  }

  return (
    <Modal
      {...rest}
      data-testid="add-qualit-event-modal"
      header={`${isEditMode ? 'Edit' : 'add'} quality event`}
      onOk={handleSubmit}
      disableSave={!isDirty || !isValid}
      okText="save"
      size="largeSimpleForm"
      modalBodyClassName={Shared.verticalChildrenGap32}
      headerClassName={Styles.modalHeader}
      className={Styles.modalWrapper}
    >
      <FormProvider {...form}>
        <TriggerSection control={control} errors={errors} setError={setError} clearErrors={clearErrors} />
        <Divider />
        <AppliesToSection
          control={control}
          targetTable={targetTable}
          errors={errors}
          clearErrors={clearErrors}
          setError={setError}
        />
      </FormProvider>
    </Modal>
  )
}

export default AddOrEditQualityEventModal

/**
 * Renders input containers with labels, each of these correspond to fields in triggerOptionList
 *
 * @param triggerOption - value of the selected option
 * @param className - string class for the container
 */
const TriggerAdditionalFields = ({
  triggerOption,
  className = '',
  errors,
}: {
  triggerOption: TriggerType
  className?: string
  errors: FieldErrors<EventTypeFormValues>
  setError: (input: string, error: { type: string; message: string }) => void
  clearErrors: (input?: string | string[]) => void
}) => {
  const { watch, trigger, control } = useFormContext<EventTypeFormValues>()
  const { total_count, count } = watch(['total_count', 'count'])

  // gets the max item/predictions number based on total count
  const getInputMaxValue = ({ maxConfig, fieldName }: { maxConfig: number; fieldName: EventTypeFieldNames }) => {
    if (
      (triggerOption === 'multipleFailuresOverANumberOfItems' ||
        triggerOption === 'multiplePredictionsOverANumberOfItems') &&
      fieldName === 'count'
    ) {
      if (!isNil(total_count)) return total_count
    }

    return maxConfig
  }

  // This effect is in charge of validating the number of failures for trigger types
  // based on number of items
  useEffect(() => {
    if (
      (triggerOption === 'multipleFailuresOverANumberOfItems' ||
        triggerOption === 'multiplePredictionsOverANumberOfItems') &&
      !isNil(count)
    ) {
      trigger('count')
    }
  }, [count, total_count, trigger, triggerOption])

  const currentTriggerTypeFields = EVENT_TRIGGER_OPTIONS.find(option => option.value === triggerOption)?.fields
  return currentTriggerTypeFields?.map(({ name, label, min, max, dataTestId }) => {
    const maxInputValue = getInputMaxValue({ maxConfig: max, fieldName: name })
    return (
      <Controller
        key={name}
        control={control}
        name={name}
        rules={{
          required: 'Required field',
          max: { value: maxInputValue, message: 'Cannot be higher than number of items' },
        }}
        render={props => (
          <Token
            label={label}
            labelClassName={Styles.triggerFieldLabel}
            className={`${currentTriggerTypeFields.length > 1 ? Styles.halfSize : Styles.fullSize} ${className}`}
          >
            <PrismInputNumber
              {...props}
              data-testid={dataTestId}
              errors={errors}
              placeholder="0"
              min={min}
              max={maxInputValue}
            />
          </Token>
        )}
      />
    )
  })
}

const PredictionsSelect = ({
  control,
  errors,
}: {
  control: Control<EventTypeFormValues>
  errors: FieldErrors<EventTypeFormValues>
}) => {
  const correctlyTypedError = errors.values as FieldError | undefined
  return (
    <Controller
      control={control}
      name="values"
      rules={{
        required: 'Required field',
        validate: (val: string[]) => (!val.length ? 'Field is required' : true),
      }}
      render={props => (
        <Token
          label="Prediction"
          secondaryLabel={<BetaTag className={Styles.qualityEventBetaTag} />}
          labelClassName={Styles.triggerFieldLabel}
          className={Styles.fullSize}
        >
          <SearchableSelect
            {...props}
            mode="multiple"
            size="large"
            status={correctlyTypedError ? 'error' : undefined}
            sorter={(labelA, labelB) => sortByValueAndSeverity(labelA, labelB, ['neutral', 'minor', 'critical'])}
            placeholder="Select predictions"
            showArrow
            fetcher={searchValue =>
              service.getToolLabels({ value: searchValue, is_deleted: false, severity__in: 'neutral,critical,minor' })
            }
            missingOptionFetcher={id => service.getToolLabel(id)}
            formatter={toolLabel => (
              <PrismResultButton
                severity={getDisplaySeverity(toolLabel)}
                value={getLabelName(toolLabel)}
                size="small"
                type="noFill"
                className={Styles.selectPrismResult}
              />
            )}
            getterKey={getterKeys.toolLabels('quality-events-predictions')}
            disableDropdownAnimation
            stopPropagationOnDropdownClick
            multiClassName={Styles.multiSelect}
          />
          {correctlyTypedError && (
            <InputError className={Styles.errorCaption}>{correctlyTypedError.message}</InputError>
          )}
          <p className={Styles.predictionsBetaCaption}>Prediction based events are an experimental feature</p>
        </Token>
      )}
    />
  )
}

/**
 * Renders the Trigger section of the modal
 *
 * @param triggerOption - value of the selected option
 * @param onSelectChange - handler for the select value
 */
const TriggerSection = ({
  control,
  errors,
  setError,
  clearErrors,
}: {
  control: Control<EventTypeFormValues>
  errors: FieldErrors<EventTypeFormValues>
  setError: (input: string, error: { type: string; message: string }) => void
  clearErrors: (input?: string | string[]) => void
}) => {
  return (
    <section className={`${Shared.verticalChildrenGap24} ${Styles.modalSection}`}>
      <Token label="1. Trigger" labelClassName={Styles.sectionTitle} valueClassName={Styles.sectionDescription}>
        Select a trigger for recording an incident.
      </Token>
      <Controller
        control={control}
        name="triggerType"
        rules={{ required: 'Field is required' }}
        render={props => {
          return (
            <>
              <PrismSelect
                data-testid="quality-event-trigger-select"
                value={props.value || null}
                onChange={props.onChange}
                size="large"
                placeholder={'Select a trigger'}
                options={EVENT_TRIGGER_OPTIONS}
                popupClassName={Styles.textTransform}
                className={Styles.textTransform}
                status={errors.triggerType ? 'error' : undefined}
                hasGroups
              />
              {errors.triggerType && (
                <InputError className={Styles.errorCaption}>{errors.triggerType.message}</InputError>
              )}
              {isPredictionTrigger(props.value) && <PredictionsSelect control={control} errors={errors} />}
              <div className={`${Styles.triggerOptionFields} ${props.value ? Styles.show : Styles.hide}`}>
                <TriggerAdditionalFields
                  errors={errors}
                  triggerOption={props.value}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </div>
            </>
          )
        }}
      />
    </section>
  )
}

/**
 * Renders the Applies to section of the modal
 *
 * @param triggerAppliesTo - Recieve a value from the modal, can be stations or products
 * @param onRadioChange - handler for the Radio function value
 */
const AppliesToSection = ({
  control,
  targetTable,
  errors,
  clearErrors,
  setError,
}: {
  control: Control<EventTypeFormValues>
  targetTable: EventTargetTable | 'all' | undefined | null
  errors: FieldErrors<EventTypeFormValues>
  clearErrors: (input?: string | string[]) => void
  setError: (input: string, error: { type: string; message: string }) => void
}) => {
  return (
    <section className={`${Shared.verticalChildrenGap24} ${Styles.modalSection}`}>
      <div className={Styles.appliesToWrapper}>
        <Token label="2. applies to" labelClassName={Styles.sectionTitle} valueClassName={Styles.sectionDescription}>
          Select which entities this trigger applies to.
        </Token>
        <Controller
          control={control}
          name="targetTable"
          rules={{
            required: 'Field is required',
          }}
          render={props => (
            <Radio.Group
              value={props.value}
              onChange={e => {
                props.onChange(e.target.value)
                control.setValue('targetIds', [], { shouldValidate: true, shouldDirty: true })
                if (e.target.value === 'all') clearErrors('targetIdsMissing')
              }}
              className={Styles.triggerAppliesContainer}
            >
              <Radio data-testid="applies-to-all" value="all" className={Styles.radioContainer}>
                <span>All</span>
              </Radio>
              <Radio data-testid="applies-to-station" value="station" className={Styles.radioContainer}>
                <span>Specific Stations</span>
              </Radio>
              <Radio data-testid="applies-to-component" value="component" className={Styles.radioContainer}>
                <span>Specific Products</span>
              </Radio>
            </Radio.Group>
          )}
        />
        {errors.targetTable && <InputError className={Styles.errorCaption}> {errors.targetTable.message}</InputError>}
      </div>
      <Controller
        control={control}
        name="targetIds"
        rules={{
          required: 'Field is required',
          validate: () => {
            const selectedTargetTable = control.getValues('targetTable')
            const targetIds = control.getValues('targetIds')
            // Target ids are not required if we are selecting the `all` option
            if (selectedTargetTable !== 'all' && !targetIds?.length) {
              setError('targetIdsMissing', {
                type: 'custom',
                message: 'Field is required',
              })
              return true
            }
            clearErrors('targetIdsMissing')
            return true
          },
        }}
        render={props => {
          return (
            <>
              {targetTable === 'station' && (
                <SearchableStationsWithStatus
                  data-testid="applies-to-station-select"
                  data-test="station-select-options"
                  mode="multiple"
                  stationId={props.value}
                  setStationId={props.onChange}
                  placeholderTitle="Applies to"
                  modalLoaded
                  multiClassName={`${Styles.multiSelect} ${Styles.multiSelectWithVideoFeed}`}
                  status={!errors.targetTable && errors.targetIds ? 'error' : undefined}
                />
              )}

              {targetTable === 'component' && (
                <SearchableSelect
                  {...props}
                  data-testid="applies-to-component-select"
                  key="component"
                  mode="multiple"
                  size="large"
                  placeholder="Applies to"
                  fetcher={searchValue => service.getComponents({ name: searchValue, is_deleted: false })}
                  missingOptionFetcher={id => service.getComponent(id)}
                  formatter={product => product.name}
                  getterKey={getterKeys.metricsFilteredComponents()}
                  queryOptions={{ noRefetch: true, noRefetchMs: 30 * 1000 }}
                  showArrow
                  disableDropdownAnimation
                  stopPropagationOnDropdownClick
                  multiClassName={Styles.multiSelect}
                  status={!errors.targetTable && errors.targetIds ? 'error' : undefined}
                />
              )}
              {/* As targetIds is an array the types assumes that an array of errors will be returned, but that is not the case
              we need to do this conversion in order to avoid misleading errors */}
              {!errors.targetTable && errors.targetIds && (
                <InputError className={Styles.errorCaption}>
                  {(errors.targetIds as unknown as FieldError).message}
                </InputError>
              )}
            </>
          )
        }}
      />
    </section>
  )
}

const QUALITY_EVENT_ERROR_MESSAGE = 'An error occurred while creating the Quality event'

type OrNull<T> = { [K in keyof T]: T[K] | null }

export interface EventTypeFormValues extends OrNull<Omit<InspectionEventRules, 'type'>> {
  triggerType?: TriggerType | null
  targetTable?: EventTargetTable | 'all' | null
  targetIds?: string[]
  values: string[]
}

export const getFormValuesFromRules = (rules: InspectionEventRules): EventTypeFormValues => {
  const { ratio, count, total_count, duration_s, values, type } = rules

  const isItemFailureTrigger = type === 'item_outcomes' && values[0] === 'fail'

  if (!isNil(count) && !isNil(total_count)) {
    if (count === total_count) {
      return {
        triggerType: isItemFailureTrigger ? 'multipleFailuresInARow' : 'multiplePredictionsInARow',
        count,
        values,
      }
    }

    return {
      triggerType: isItemFailureTrigger
        ? 'multipleFailuresOverANumberOfItems'
        : 'multiplePredictionsOverANumberOfItems',
      count,
      total_count,
      values,
    }
  }

  if (!isNil(count) && !isNil(duration_s)) {
    return {
      triggerType: isItemFailureTrigger ? 'multipleFailuresOverATimePeriod' : 'multiplePredictionsOverATimePeriod',
      count,
      duration_s,
      values,
    }
  }

  if (!isNil(ratio) && !isNil(duration_s)) {
    const formRatio = ratio * 100
    return {
      triggerType: isItemFailureTrigger
        ? 'failRateExeedsValueForATimePeriod'
        : 'predictionsRateExeedsValueForATimePeriod',
      ratio: parseFloat(formRatio.toFixed(2)),
      duration_s,
      values,
    }
  }

  return { values: [] }
}

const getFormTargetValues = (
  targets: EventTargetWithName[],
): { targetTable: EventTargetTable | 'all' | undefined; targetIds: string[] } => {
  const isSomeTargetOrganization = targets.some(target => target.table === 'organization')
  if (isSomeTargetOrganization) {
    return { targetTable: 'all', targetIds: [] }
  }

  const targetTable = targets?.[0]?.table
  // Safe check, in case we end up with a combination of targets,
  // we only grab the ids from the first valid targettable
  const targetIds = targets?.filter(target => target.table === targetTable).map(target => target.id)
  return { targetTable, targetIds }
}

const getEventTypeDefaultValues = (eventType?: EventTypeWithCounts): EventTypeFormValues => {
  if (eventType && (eventType.rules.type === 'item_outcomes' || eventType.rules.type === 'label_ids')) {
    const allTargets = eventType.event_scopes.flatMap(scope => scope.targets)

    return {
      ...getFormValuesFromRules(eventType.rules),
      ...getFormTargetValues(allTargets),
    }
  }

  // We need to default to null values because useReactForm for some reason, doesn't update `useWatch` values if they are undefined
  // TODO: When we update react-hook-form, verify that we still need to set these values to null; reset.keepDefaultValues looks like it would fix it
  return {
    triggerType: null,
    count: null,
    ratio: null,
    duration_s: null,
    total_count: null,
    targetTable: null,
    targetIds: [],
    values: [],
  }
}

const isPredictionTrigger = (triggerType: string) => {
  return PREDICTIONS_TRIGGER_TYPES.includes(triggerType as PredictionsTriggerType)
}

const getEventScopesBodyFromTargetIds = ({
  eventTypeId,
  targetTable,
  targetIds,
  organizationId,
}: {
  eventTypeId: string
} & BaseEventScopeConfig): EventScopeBody[] => {
  if (targetTable === 'all') {
    return [{ type_id: eventTypeId, targets: { organization_id: organizationId } }]
  }
  return (
    targetIds?.map(targetId => {
      const eventScope: EventScopeBody = { type_id: eventTypeId, targets: {} }

      if (targetTable === 'station') {
        eventScope.targets.station_id = targetId
      }

      if (targetTable === 'component') {
        eventScope.targets.component_id = targetId
      }

      if (targetTable === 'recipe_parent') {
        eventScope.targets.recipe_parent_id = targetId
      }

      return eventScope
    }) || []
  )
}

const getCreateUpdateEventScopeBody = ({
  eventTypeId,
  baseEventScopeConfig,
  existingEventScopes,
}: {
  eventTypeId: string
  baseEventScopeConfig: BaseEventScopeConfig
  existingEventScopes: EventScope[]
}): CreateUpdateDeleteEventScopesBody => {
  const existingTargetIds = existingEventScopes.flatMap(eventScope => eventScope.targets.map(target => target.id))

  if (baseEventScopeConfig.targetTable === 'all') {
    // If the new target is "all", we want to check if we already have an "organization" EventScope for this EventType
    const existingOrgScope = existingEventScopes.find(scope =>
      scope.targets.some(
        target => target.table === 'organization' && target.id === baseEventScopeConfig.organizationId,
      ),
    )

    // If we don't have an org EventScope, we create one and delete the rest of the scopes
    return {
      create: existingOrgScope
        ? []
        : [{ type_id: eventTypeId, targets: { organization_id: baseEventScopeConfig.organizationId } }],
      delete: existingEventScopes
        .filter(eventScope => eventScope.id !== existingOrgScope?.id)
        .map(({ id }) => ({ id })),
    }
  }

  const newTargetIds = baseEventScopeConfig.targetIds || []

  const targetIdsToCreate = difference(newTargetIds, existingTargetIds)

  const targetIdsToDelete = difference(existingTargetIds, newTargetIds)

  const eventScopesIdToDelete = existingEventScopes
    .filter(scope => {
      return scope.targets.some(target => targetIdsToDelete.includes(target.id))
    })
    .map(scope => scope.id)

  return {
    create: getEventScopesBodyFromTargetIds({ eventTypeId, ...baseEventScopeConfig, targetIds: targetIdsToCreate }),
    delete: eventScopesIdToDelete.map(id => ({ id })),
  }
}
