import React, { useEffect, useMemo, useRef, useState } from 'react'

import { FixedSizeGrid } from 'react-window'
import { GridElementScrollerChildrenProps, ReactWindowElementScroller } from 'react-window-element-scroller'

import { PrismHelpIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { info } from 'components/PrismMessage/PrismMessage'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Tag } from 'components/Tag/Tag'
import { useContainerDimensions, useGridDimensions, useQueryParams, useToolLabels } from 'hooks'
import {
  LabelingMetrics,
  LabelingProgressObject,
  TagType,
  ToolLabel,
  ToolLabelLabelingStatus,
  ToolResult,
  ToolSpecificationName,
} from 'types'
import {
  getDisplaySeverity,
  getLabelName,
  isAnyQsFilterActive,
  isLabelTestSet,
  isLabelUncertainOrDiscard,
  pluralize,
} from 'utils'
import {
  LABELING_GALLERY_CARD_HEIGHT,
  LABELING_GALLERY_CARD_HEIGHT_WITH_WRAPPER_PADDING,
  LABELING_GALLERY_CARD_MIN_WIDTH,
  LABELING_GALLERY_GRID_GAP,
} from 'utils/constants'

import { EmptyLabelingState } from '../LabelingEmptyStates'
import GalleryCardsGrid from './GalleryCardsGrid'
import { MAX_SELECTED_GALLERY_CLASSIFICATIONS, ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'

const TAG_TYPE_BY_LABELING_STATUS: { [status in ToolLabelLabelingStatus | 'excluded']: TagType } = {
  optimal: 'success',
  'below-minimum': 'danger',
  'in-progress': 'info',
  'off-balance': 'unknown',
  excluded: 'default',
}

const TITLE_BY_LABELING_STATUS: { [balance in ToolLabelLabelingStatus | 'excluded']: string } = {
  optimal: 'Optimal',
  'below-minimum': 'Below Minimum',
  'in-progress': 'In progress',
  'off-balance': 'Off balance',
  excluded: 'Excluded',
}

const TestSetDescription = (descriptionType: 'tag' | 'icon') => {
  let additionalDescription = (
    <span className={Styles.testSetText}>
      Images in the Test Set are not trained on and do not count towards your labeling goals.
    </span>
  )
  if (descriptionType === 'icon')
    additionalDescription = (
      <span className={Styles.testSetText}>
        Add a standard label and a Test Set label to an image. Then train a model to see how it performs.
      </span>
    )
  return (
    <p className={Styles.testSetDescription}>
      <span className={Styles.testSetText}>
        The Test Set is meant to represent new, unseen data that a model will encounter in real-world scenarios.
      </span>

      {additionalDescription}
    </p>
  )
}

interface Props {
  uniqueToolResults?: ToolResult[]
  toolParentId: string
  metrics: LabelingMetrics | undefined
  testSetCountsByLabelId: LabelingMetrics | undefined
  showInsights: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  fetchNextPage: () => any
  lastSelectedToolResultId: string | null
  setLastSelectedToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  toolSpecificationName: ToolSpecificationName
  labelingProgress: LabelingProgressObject
  outerContainerRef?: React.RefObject<HTMLDivElement>
  customToolLabels: ToolLabel[] | undefined
}

/**
 * Render the Labeling Gallery body containing the toolResult cards.
 *
 * @param uniqueToolResults - The unique toolResults to render
 * @param toolParentId - The current tool parent id
 * @param metrics - Labeling metrics
 * @param showInsights - Wheter we render the insights for each card
 * @param selectedToolResults - Object representing the selected toolResults,
 *  the object keys are toolResult ids, and the values are the toolResults itself.
 * @param currentToolResult - The current toolResult
 * @param lastSelectedToolResultId - The last selected tool result id
 * @param setLastSelectedToolResultId - State handler for lastSelectedToolResultId
 *
 */
const LabelingGalleryBody = ({
  uniqueToolResults,
  toolParentId,
  metrics,
  testSetCountsByLabelId,
  showInsights,
  selectedToolResults,
  setSelectedToolResults,
  fetchNextPage,
  lastSelectedToolResultId,
  setLastSelectedToolResultId,
  toolSpecificationName,
  labelingProgress,
  outerContainerRef,
  customToolLabels,
}: Props) => {
  const [hoveredRange, setHoveredRange] = useState<ToolResultsMap>({})
  const [hoveredToolResultId, setHoveredToolResultId] = useState<string | null>(null)
  const [showHoldShiftMessage, setShowHoldShiftMessage] = useState(true)

  const toolLabels = useToolLabels([{ specification_name: toolSpecificationName, parent_id: toolParentId }])

  const [params] = useQueryParams<'user_label_id__in' | 'tool_result_id' | 'user_label_set_isnull'>()
  const filtering = isAnyQsFilterActive(params)

  const scrolledRef = useRef(false)
  // Pagination refs and state
  const galleryBodyRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<FixedSizeGrid>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const { width: containerWidth, height: containerHeight } = useContainerDimensions(galleryBodyRef)
  const { columnCount, rowCount, rowHeight } = useGridDimensions(
    (containerWidth || 0) - LABELING_GALLERY_GRID_GAP - 6,
    uniqueToolResults?.length,
    {
      gridGap: LABELING_GALLERY_GRID_GAP,
      minWidth: LABELING_GALLERY_CARD_MIN_WIDTH,
      elementRowHeight: LABELING_GALLERY_CARD_HEIGHT,
    },
  )

  const selectedToolResultsCount = useMemo(() => {
    return Object.keys(selectedToolResults).length
  }, [selectedToolResults])

  useEffect(() => {
    if (!params.tool_result_id || scrolledRef.current || containerWidth === undefined) return
    const toolResultIndex = uniqueToolResults?.findIndex(tr => tr.id === params.tool_result_id)
    if (!toolResultIndex) return

    // Only scroll if we have a scrollbar, otherwise we end up with empty space on the gallery
    const isScrollbarVisible = rowHeight * rowCount > (containerHeight || 0)
    if (isScrollbarVisible) {
      const rowIndex = Math.floor(toolResultIndex / columnCount)
      const heightToScroll = rowIndex * LABELING_GALLERY_CARD_HEIGHT_WITH_WRAPPER_PADDING
      gridRef.current?.scrollTo({ scrollTop: heightToScroll })
      // We just want to scroll once, as this effect relies on the fetched tool results
      // we want this ref to avoid a scroll when a new page is fetched
      scrolledRef.current = true
    }
  }, [columnCount, containerHeight, containerWidth, params.tool_result_id, rowCount, rowHeight, uniqueToolResults])

  useEffect(() => {
    if (showHoldShiftMessage && Object.keys(selectedToolResults).length > 0) {
      info({
        title: 'Hold Shift to select many images at a time',
        duration: 5000,
        'data-testid': 'labeling-gallery-body-multi-select-message',
      })
      setShowHoldShiftMessage(false)
    }
  }, [selectedToolResults]) //eslint-disable-line

  const { shiftPressed } = useGalleryShiftKeyPress({
    hoveredToolResultId,
    lastSelectedToolResultId,
    setHoveredRange,
    selectedToolResults,
    toolResults: uniqueToolResults,
  })

  const userHasLabeled = useMemo(() => Object.values(metrics || {}).reduce((aggr, val) => aggr + val, 0) > 0, [metrics])

  const handleHoveredRangeChange = (toolResultId: string) => {
    handleHoveredRange({
      hoveredId: toolResultId,
      lastSelectedToolResultId,
      selectedToolResults,
      setHoveredRange,
      toolResults: uniqueToolResults,
      allSelectedToolResultsCount: selectedToolResultsCount,
    })
  }

  const labelForTitle: ToolLabel | null = useMemo(() => {
    const selectedUserLabelId = params.user_label_id__in
    if (!toolLabels || !selectedUserLabelId) return null

    const foundLabel = toolLabels.find(toolLabel => toolLabel.id === selectedUserLabelId)

    return foundLabel || null
  }, [params.user_label_id__in, toolLabels])

  const getLabelingStatusForTag = (
    toolLabel: ToolLabel,
  ): {
    status: ToolLabelLabelingStatus | 'excluded'
    neededForBalance: number
  } | null => {
    if (!toolLabel || isLabelTestSet(toolLabel)) return null
    if (isLabelUncertainOrDiscard(toolLabel)) {
      return { status: 'excluded', neededForBalance: 0 }
    }

    return labelingProgress.labelsStatusById[toolLabel.id] || null
  }

  const renderLabelTitle = () => {
    if (!labelForTitle) {
      // This qs param value is read as a string
      const title = params.user_label_set_isnull === 'true' ? 'Unlabeled' : 'All Images'
      return (
        <div className={Styles.galleryLabelTitle} data-testid={`gallery-header-title-${title}`}>
          {title}
        </div>
      )
    }

    const labelBalance = getLabelingStatusForTag(labelForTitle)

    const testSetCountForCurrentLabel = !isLabelTestSet(labelForTitle)
      ? testSetCountsByLabelId?.[labelForTitle.id]
      : undefined

    const isTestSetTab = isLabelTestSet(labelForTitle)

    return (
      <div className={Styles.galleryLabelTitle}>
        <div className={Styles.galleryLabel} data-testid={`gallery-header-title-${labelForTitle.value}`}>
          <PrismResultButton
            type="noFill"
            value={getLabelName(labelForTitle)}
            severity={getDisplaySeverity(labelForTitle)}
          />

          {labelForTitle && isLabelTestSet(labelForTitle) && (
            <PrismTooltip
              title={TestSetDescription('icon')}
              overlayClassName={Styles.testSetHelpIconTooltip}
              anchorClassName={Styles.testSetHelpIcon}
            >
              <PrismHelpIcon />
            </PrismTooltip>
          )}
        </div>

        <div className={Styles.tagsContainer}>
          {testSetCountForCurrentLabel && (
            <PrismTooltip
              title={TestSetDescription('tag')}
              placement="bottom"
              overlayClassName={Styles.galleryLabelTooltip}
              anchorClassName={Styles.tagAnchorTooltip}
            >
              <Tag shape="rectangle" type="default" addBadge>
                {testSetCountForCurrentLabel} in test set
              </Tag>
            </PrismTooltip>
          )}

          {labelBalance && (
            <PrismTooltip
              title={
                labelForTitle && isLabelUncertainOrDiscard(labelForTitle)
                  ? 'Images with this label are excluded from training and do not count towards your labeling goals.'
                  : renderLabelingStatusDescription(labelBalance)
              }
              placement="bottom"
              overlayClassName={Styles.galleryLabelTooltip}
              anchorClassName={Styles.tagAnchorTooltip}
            >
              <Tag shape="rectangle" type={TAG_TYPE_BY_LABELING_STATUS[labelBalance.status]} addBadge>
                {TITLE_BY_LABELING_STATUS[labelBalance.status]}
              </Tag>
            </PrismTooltip>
          )}

          {isTestSetTab && (
            <TestSetTag customToolLabels={customToolLabels} testSetCountByLabelId={testSetCountsByLabelId} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={Styles.labelingGalleryBody} ref={galleryBodyRef}>
      {!uniqueToolResults && <PrismLoader className={Styles.loaderContainer} />}

      {uniqueToolResults?.length === 0 ? (
        <>
          {renderLabelTitle()}
          <EmptyLabelingState filtering={filtering} userHasLabeled={userHasLabeled} />
        </>
      ) : (
        <ReactWindowElementScroller
          type="grid"
          scrollerElementRef={outerContainerRef}
          gridRef={gridRef}
          outerRef={outerRef}
          childrenStyle={{ height: 'auto' }}
        >
          {({ style, onScroll }: GridElementScrollerChildrenProps) => (
            <>
              {uniqueToolResults && renderLabelTitle()}

              <GalleryCardsGrid
                gridRef={gridRef}
                galleryBodyRef={galleryBodyRef}
                uniqueToolResults={uniqueToolResults}
                toolParentId={toolParentId}
                showInsights={showInsights}
                setLastSelectedToolResultId={setLastSelectedToolResultId}
                onHoveredRangeChange={handleHoveredRangeChange}
                hoveredRange={hoveredRange}
                setHoveredRange={setHoveredRange}
                shiftPressed={shiftPressed}
                hoveredToolResultId={hoveredToolResultId}
                setHoveredToolResultId={setHoveredToolResultId}
                selectedToolResults={selectedToolResults}
                onSelectedToolResultsChange={setSelectedToolResults}
                fetchNextPage={fetchNextPage}
                outerRef={outerRef}
                onScroll={onScroll}
                style={style}
              />
            </>
          )}
        </ReactWindowElementScroller>
      )}
    </div>
  )
}

export default LabelingGalleryBody

/**
 * Effect in charge of handling wheter the shift key is pressed or not.
 * Additionally, it handles the hovered range if a card is currently hovered.
 *
 * @param hoveredToolResultId - The current hovered toolResult id
 * @param lastSelectedToolId - The last selected toolResult id
 * @param setHoveredRange - The setter for the hovered range
 * @param selectedToolResults - The current selected toolResults
 * @toolResults - The current toolResults set
 */
export const useGalleryShiftKeyPress = ({
  hoveredToolResultId,
  lastSelectedToolResultId,
  setHoveredRange,
  selectedToolResults,
  toolResults,
}: {
  hoveredToolResultId: string | null
  lastSelectedToolResultId: string | null
  setHoveredRange: (range: ToolResultsMap) => void
  selectedToolResults: ToolResultsMap
  toolResults?: ToolResult[]
}) => {
  const [shiftPressed, setShiftPressed] = useState(false)

  const selectedToolResultsCount = useMemo(() => {
    return Object.keys(selectedToolResults).length
  }, [selectedToolResults])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setShiftPressed(true)
        if (hoveredToolResultId) {
          handleHoveredRange({
            hoveredId: hoveredToolResultId,
            lastSelectedToolResultId: lastSelectedToolResultId,
            selectedToolResults,
            setHoveredRange,
            toolResults,
            allSelectedToolResultsCount: selectedToolResultsCount,
          })
        }
      }
    }
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setHoveredRange({})
        setShiftPressed(false)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [
    toolResults,
    hoveredToolResultId,
    lastSelectedToolResultId,
    selectedToolResults,
    selectedToolResultsCount,
    setHoveredRange,
  ])

  return { shiftPressed }
}

/**
 * This function handles the hoverd range based on the current hovered toolResult and the last selected toolResult.
 * It handles the max selected toolResults limits and crops the range if that limit is already reached.
 *
 * @param hoveredIndex - The current hovered toolResult index
 * @param toolResults - The current toolResults set
 * @param lastSelectedToolIdIndex - The last selected toolResult index
 * @param selectedToolResults - The current selected toolResults
 * @param setHoveredRange - The setter for the hovered range
 * @param allSelectedToolResultsCount - The count of all the selected toolResults count, for group by mode, this should include
 * all the groups count
 */
export const handleHoveredRange = ({
  hoveredId,
  lastSelectedToolResultId,
  toolResults,
  selectedToolResults,
  setHoveredRange,
  allSelectedToolResultsCount,
}: {
  hoveredId: string
  lastSelectedToolResultId: string | null
  toolResults?: ToolResult[]
  selectedToolResults: ToolResultsMap
  setHoveredRange: (range: ToolResultsMap) => void
  allSelectedToolResultsCount: number
}) => {
  if (!lastSelectedToolResultId || !toolResults) return

  const lastSelectedToolResultIdIndex = toolResults.findIndex(toolResult => toolResult.id === lastSelectedToolResultId)
  const hoveredIdIndex = toolResults.findIndex(tool => tool.id === hoveredId)
  if (lastSelectedToolResultIdIndex < 0 || hoveredIdIndex < 0) return

  if (!toolResults) return

  const selectingForward = hoveredIdIndex >= lastSelectedToolResultIdIndex

  let end: number
  let start: number

  // we set the start and end of the range based on the direction of the selection
  if (selectingForward) {
    start = lastSelectedToolResultIdIndex
    end = hoveredIdIndex
  } else {
    start = hoveredIdIndex
    end = lastSelectedToolResultIdIndex
  }

  // we create a temp range to and keep track of the selected items within that temp selection
  // so that later we can figure out if the max limits are reached.
  const tempRange = toolResults.slice(start, end + 1)
  const selectedCount = tempRange.filter(tool => !!selectedToolResults[tool.id]).length

  const allSelected = tempRange.length === selectedCount

  // the length of just the toolResults that are going to be added.
  const idsRangeLength = end + 1 - start - selectedCount

  const croppedRangeLength = MAX_SELECTED_GALLERY_CLASSIFICATIONS - allSelectedToolResultsCount

  // we figure out if the max limit is reached only if there are toolResults in the range that are not selected,
  // otherwise we just use the original range.
  if (!allSelected && allSelectedToolResultsCount + idsRangeLength > MAX_SELECTED_GALLERY_CLASSIFICATIONS) {
    if (selectingForward) {
      end = start + croppedRangeLength + selectedCount - 1
    } else {
      start = end - croppedRangeLength - selectedCount + 1
    }
  }

  const idsRange = toolResults.slice(start, end + 1)
  if (idsRange) {
    const newRange = idsRange.reduce((range, toolResult) => {
      range[toolResult.id] = toolResult
      return range
    }, {} as ToolResultsMap)
    setHoveredRange(newRange)
  }
}

const renderLabelingStatusDescription = ({
  status,
  neededForBalance,
}: {
  status: ToolLabelLabelingStatus | 'excluded'
  neededForBalance: number
}) => {
  const hasMoreImages = pluralize({ wordCount: neededForBalance, word: 'image' })
  if (status === 'optimal') {
    return <>This label has enough images for optimal training</>
  }

  if (status === 'in-progress') {
    return (
      <>
        This label could use {neededForBalance} more {hasMoreImages} for optimal training
      </>
    )
  }

  if (status === 'off-balance') {
    return (
      <>
        This label needs {neededForBalance} more {hasMoreImages} to be in balance with your other labels
      </>
    )
  }

  if (status === 'below-minimum') {
    return <>This label needs {neededForBalance} more image to meet the training minimum</>
  }
}

/**
 * Renders a test set tag.
 * It displays a tooltip, when hovered, with a list of custom tool labels and counts.
 *
 * @param customToolLabels - list of ToolLabel
 * @param testSetCountByLabelId - test set counts by label
 */
const TestSetTag = ({
  customToolLabels,
  testSetCountByLabelId,
}: {
  customToolLabels: ToolLabel[] | undefined
  testSetCountByLabelId: Record<string, number> | undefined
}) => {
  if (!customToolLabels || customToolLabels.length === 0) return
  const labelsInToolCount = customToolLabels.length

  let activeLabelsWithTestSet = 0

  const labelsInTooltip = customToolLabels.map(toolLabel => {
    const toolLabelCount = testSetCountByLabelId?.[toolLabel.id] || 0
    if (toolLabelCount !== 0) activeLabelsWithTestSet += 1
    return (
      <li className={Styles.tooltipLabel} key={toolLabel.id}>
        <PrismResultButton value={toolLabel.value} severity={toolLabel.severity} type="noFill" size="small" />
        <span>{toolLabelCount}</span>
      </li>
    )
  })
  return (
    <PrismTooltip
      title={<ul className={Styles.tooltipLabelsList}>{labelsInTooltip}</ul>}
      placement="bottom"
      overlayClassName={Styles.testSetTooltipList}
      anchorClassName={Styles.tagAnchorTooltip}
    >
      <Tag shape="rectangle" type="default">
        {activeLabelsWithTestSet}/{labelsInToolCount} Labels
      </Tag>
    </PrismTooltip>
  )
}
