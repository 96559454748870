import React, { useState } from 'react'

import { InputRef } from 'antd'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismSearchIcon } from 'components/prismIcons'
import { PrismInput, PrismInputProps } from 'components/PrismInput/PrismInput'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'

import Styles from './PrismSearchInput.module.scss'

const heightClasses = {
  height24: Styles.height24,
  extraSmall: Styles.height32,
  small: Styles.height40,
  large: Styles.height48,
}

interface searchInputProps<T extends string> extends PrismInputProps {
  addAnimation?: boolean
  showIconPrefix?: boolean
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearchButtonClick?: (showInput: boolean) => void
  inputClassName?: T
  searchButtonClassName?: T
  className?: T
  buttonDataTestId?: T
  buttonDisabled?: boolean
  inputDataTestId?: T
}

/**
 * Renders a search icon button with an optional animated input.
 *
 * @param addAnimation - when added the search input is displayed with a smooth animation.
 * @param showIconPrefix - displays the search icon inside the input and hides the input button.
 * @param onInputChange - callback function that runs when the user use input
 * @param onSearchButtonClick - callback function that runs when the searc icon button is clicked
 * @param inputClassName - string for the input styles
 * @param searchButtonClassName - string for the button styles
 * @param buttonDataTestId - string for data-test-id
 * @param inputDataTestId - string for data-test-id
 * @param onSearchButtonCick - callback function that runs when the user use the button
 *
 */
const PrismSearchInput = React.forwardRef<InputRef, searchInputProps<string>>((props, ref) => {
  const {
    size = 'extraSmall',
    buttonDataTestId,
    inputDataTestId,
    onInputChange,
    onSearchButtonClick,
    buttonDisabled,
    addAnimation,
    showIconPrefix,
    inputClassName = '',
    searchButtonClassName = '',
    className = '',
    ...rest
  } = props
  const [showInput, setShowInput] = useState(false)
  const handleButtonClick = () => {
    setShowInput(!showInput)
    onSearchButtonClick?.(!showInput)
  }
  const heightClass = heightClasses[size]

  return (
    <div
      className={`${Styles.searchAndInputWrapper} ${heightClass} ${showInput || !addAnimation ? Styles.show : ''} ${
        showIconPrefix ? Styles.fixedWidthWrapper : ''
      } ${className} `}
    >
      {(showInput || !addAnimation) && (
        <PrismInput
          autoFocus={!!addAnimation}
          {...rest}
          ref={ref}
          data-testid={inputDataTestId}
          size={size}
          onChange={onInputChange}
          wrapperClassName={`${Styles.searchInput}  ${inputClassName}`}
          prefix={showIconPrefix ? <PrismSearchIcon className={Styles.prefixInputIcon} /> : undefined}
        />
      )}

      {!showIconPrefix && (
        <PrismTooltip
          mouseEnterDelay={0.5}
          title="Search"
          anchorClassName={`${Styles.searchButton} ${searchButtonClassName} `}
        >
          <IconButton
            data-testid={buttonDataTestId}
            className={`${Styles.iconButton} ${showInput ? Styles.activeButton : ''}`}
            icon={<PrismSearchIcon />}
            type="tertiary"
            onClick={handleButtonClick}
            size="small"
            disabled={buttonDisabled}
          />
        </PrismTooltip>
      )}
    </div>
  )
})

export default PrismSearchInput
