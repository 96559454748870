import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Search Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=69%3A9
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismSearchIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1575 6.57116C16.2524 8.66606 16.2524 12.0625 14.1575 14.1574C12.0626 16.2523 8.66608 16.2523 6.57118 14.1574C4.47629 12.0626 4.47629 8.66606 6.57118 6.57116C8.66608 4.47627 12.0626 4.47627 14.1575 6.57116ZM15.2044 14.4973C17.3443 11.9969 17.231 8.23049 14.8646 5.86406C12.3792 3.37864 8.34949 3.37864 5.86408 5.86406C3.37866 8.34947 3.37866 12.3791 5.86408 14.8646C8.2305 17.231 11.9969 17.3443 14.4973 15.2044L19.1464 19.8536C19.3417 20.0488 19.6583 20.0488 19.8536 19.8536C20.0488 19.6583 20.0488 19.3417 19.8536 19.1464L15.2044 14.4973Z"
      />
    </svg>
  )
}
