import React, { useMemo, useState } from 'react'

import ImageNotStored from 'components/ImageWithBoxes/ImageNotStored'
import ImgFallback, { FailCacheEntry, Props as ImgFallbackProps } from 'components/Img/ImgFallback'
import { useIALimitDate } from 'hooks'
import { FallbackImagePicture, Item, ItemExpanded } from 'types'
import { getImageFromItem, getImageFromPicture } from 'utils'
import { IMAGE_IN_GLACIER_TEXT, IMAGE_NOT_UPLOADED_TEXT } from 'utils/constants'

interface Props extends ImgFallbackProps {
  itemOrPicture: Item | ItemExpanded | FallbackImagePicture
  displayCubeText?: boolean
  preferThumbnail?: boolean
  forceThumbnail?: boolean
  addBackground?: boolean
}

// @TO_IA
export function ImgMightBeInGlacier({
  itemOrPicture,
  displayCubeText,
  preferThumbnail,
  forceThumbnail,
  ...rest
}: Props) {
  const iaLimitDate = useIALimitDate()

  const [loadImgError, setLoadImgError] = useState<FailCacheEntry>()

  const { image, imageInGlacier } = useMemo(() => {
    if ('component' in itemOrPicture) {
      return getImageFromItem(itemOrPicture, iaLimitDate?.toISOString(), { preferThumbnail, forceThumbnail })
    }
    return getImageFromPicture(itemOrPicture, iaLimitDate?.toISOString(), { preferThumbnail, forceThumbnail })
  }, [forceThumbnail, iaLimitDate, itemOrPicture, preferThumbnail])

  const shouldRenderImg = !imageInGlacier && !loadImgError

  const displayText = useMemo(() => {
    if (shouldRenderImg || !displayCubeText) return undefined
    if (imageInGlacier) return IMAGE_IN_GLACIER_TEXT
    return IMAGE_NOT_UPLOADED_TEXT
  }, [displayCubeText, imageInGlacier, shouldRenderImg])

  if (!image || !shouldRenderImg) return <ImageNotStored displayText={displayText} {...rest} />
  return <ImgFallback src={image} onImgLoadError={setLoadImgError} {...rest} />
}
