import React, { useRef } from 'react'

import { useOutsideRefClick } from 'hooks'

import Styles from './PrismPopover.module.scss'

interface PrismPopverProps {
  onClose: (e: MouseEvent) => any
  className?: String
  bodyClassName?: String
  position?: 'left' | 'center' | 'right'
  hideArrow?: boolean
  children: React.ReactNode
}

/**
 * Renders a popover container that can be used to display a menu, and will be closed when user clicks outside it's area
 *
 * @param onClose - Callback for when clicking outside of this
 * @param className - Optional classname to be applied to menu
 * @param bodyClassName - Optional classname to be applied to body
 *
 */

export const PrismPopover = ({
  children,
  className,
  bodyClassName,
  onClose,
  position = 'center',
  hideArrow = false,
}: PrismPopverProps) => {
  const positionClass = Styles[position]
  const menuRef = useRef<HTMLDivElement>(null)

  useOutsideRefClick(menuRef, onClose)

  return (
    <div
      className={`${Styles.prismPopoverContainer} ${hideArrow ? Styles.hideArrow : ''}  ${positionClass} ${
        className ?? ''
      }`}
      onClick={e => e.stopPropagation()}
      ref={menuRef}
    >
      <div className={`${Styles.prismPopoverBody} ${bodyClassName ?? ''}`}>{children}</div>
    </div>
  )
}
