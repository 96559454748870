import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconArrowProps } from './prismIconsTypes'

const directionClasses = {
  right: Styles.right,
  left: Styles.left,
  down: Styles.down,
  up: Styles.up,
  mirror: Styles.mirror,
}

/**
 * Prism Menu Expand Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4035%3A146
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param direction - rotate the icon, the default value is right. * direction='mirror' convert Menu Expant icon into `Menu Collapse`
 * @param className - String for external styles.
 */
export const PrismMenuExpandIcon = ({ hasBackground, direction = 'right', className }: PrismIconArrowProps) => {
  const directionClass = directionClasses[direction]
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${directionClass} ${hasBackground ? Styles.hasBackground : ''} ${
        className ?? ''
      } `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path id="Line" d="M18.5 16.5H4.5" strokeLinecap="round" stroke="currentColor" />
      <path id="Line_2" d="M4.5 13.5H11.5" strokeLinecap="round" stroke="currentColor" />
      <path id="Line_3" d="M4.5 7.5H8.5" strokeLinecap="round" stroke="currentColor" />
      <path
        id="Vector"
        d="M4.5 10.5H19.5M19.5 10.5L16.5 7.5M19.5 10.5L16.5 13.5"
        strokeLinecap="round"
        stroke="currentColor"
      />
    </svg>
  )
}
