import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconOutcomeProps } from './prismIconsTypes'

/**
 * Prism Discard Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5412%3A11831
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  low: Styles.lowContrast,
  high: Styles.highContrast,
  dark: Styles.dark,
  iconColor: Styles.color,
}
export const PrismDiscardIcon = ({
  variant = 'transparent',
  viewboxSize = 'medium',
  className,
}: PrismIconOutcomeProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.outcome}  ${variantClass} ${className ?? ''}`

  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74991 6.11758L5.35967 15.2998C5.38583 15.6938 5.7131 16.0001 6.10803 16.0001H13.8919C14.2869 16.0001 14.6141 15.6938 14.6403 15.2998L15.2501 6.11758H4.74991ZM6.08567 7.36758L6.57592 14.7501H13.4241L13.9143 7.36758H6.08567Z"
      />
      <path
        d="M6.99994 4.74995C6.99994 4.33573 7.33573 3.99994 7.74995 3.99994H12.25C12.6642 3.99994 13 4.33573 13 4.74995V4.99994H6.99994V4.74995Z"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 7.11758V6.86759C16.25 6.45337 15.9142 6.11758 15.5 6.11758H4.50001C4.08579 6.11758 3.75 6.45337 3.75 6.86759V7.11758H16.25Z"
      />
    </svg>
  )
}
