import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Take Photo Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=7805%3A15362
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismTakePhotoIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8636 2H6.13639C5.172 2 4.24703 2.38316 3.5651 3.0651C2.88316 3.74703 2.5 4.672 2.5 5.63639V14.3636C2.5 15.328 2.88316 16.253 3.5651 16.9349C4.24703 17.6168 5.172 18 6.13639 18H14.8636C15.828 18 16.753 17.6168 17.4349 16.9349C18.1168 16.253 18.5 15.328 18.5 14.3636V5.63639C18.5 4.672 18.1168 3.74703 17.4349 3.0651C16.753 2.38316 15.828 2 14.8636 2ZM6.13639 3.45458H14.8636C15.4423 3.45458 15.9972 3.68445 16.4063 4.09366C16.8155 4.50275 17.0454 5.05771 17.0454 5.63639V11.0401L15.9472 9.9419C15.6747 9.671 15.3061 9.51894 14.9218 9.51894C14.5376 9.51894 14.1689 9.67101 13.8963 9.9419L12.0127 11.8255L8.67451 8.48738C8.40203 8.21648 8.03337 8.06442 7.64913 8.06442C7.26475 8.06442 6.89609 8.21649 6.62361 8.48738L3.95455 11.1566V5.6366C3.95455 5.05793 4.18442 4.50297 4.59363 4.09388C5.00272 3.68467 5.55771 3.45458 6.13639 3.45458ZM14.8636 16.5454H6.13639C5.55771 16.5454 5.00275 16.3155 4.59366 15.9063C4.18445 15.4972 3.95458 14.9423 3.95458 14.3636V13.2072L7.64931 9.51246L10.9875 12.8506C11.26 13.1216 11.6285 13.2736 12.0129 13.2736C12.3971 13.2736 12.7658 13.1216 13.0383 12.8506L14.9219 10.967L17.0455 13.0907V14.3634C17.0455 14.9421 16.8156 15.497 16.4064 15.9061C15.9973 16.3153 15.4423 16.5454 14.8636 16.5454Z"
        fill="currentColor"
      />
      <path
        d="M15.2273 6.72729C15.2273 7.32972 14.7389 7.81819 14.1364 7.81819C13.5339 7.81819 13.0455 7.32972 13.0455 6.72729C13.0455 6.12486 13.5339 5.63639 14.1364 5.63639C14.7389 5.63639 15.2273 6.12486 15.2273 6.72729Z"
        fill="currentColor"
      />
    </svg>
  )
}
