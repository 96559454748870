import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Closee Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1408%3A4147
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismCloseIcon = ({ hasBackground, className, 'data-testid': dataTestId }: PrismIconProps) => {
  return (
    <svg
      data-testid={dataTestId}
      className={`${Styles.icon} ${Styles.default}  ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11082 7.64645C7.91555 7.45118 7.59897 7.45118 7.40371 7.64645C7.20845 7.84171 7.20845 8.15829 7.40371 8.35355L11.2928 12.2427L7.40371 16.1318C7.20845 16.3271 7.20845 16.6436 7.40371 16.8389C7.59897 17.0342 7.91555 17.0342 8.11082 16.8389L11.9999 12.9498L15.889 16.8388C16.0843 17.0341 16.4008 17.0341 16.5961 16.8388C16.7914 16.6436 16.7914 16.327 16.5961 16.1317L12.707 12.2427L16.5961 8.35362C16.7914 8.15836 16.7914 7.84178 16.5961 7.64652C16.4008 7.45125 16.0843 7.45125 15.889 7.64652L11.9999 11.5356L8.11082 7.64645Z"
        fill="currentColor"
      />
    </svg>
  )
}
