import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismNavArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useContainerDimensions, useStateAndRef } from 'hooks'
import { sleep } from 'utils'

import Styles from './VirtualizedCarousel.module.scss'

export interface VirtualizedCarouselProps<T extends { id: string }> {
  title: React.ReactNode
  description?: string | React.ReactNode
  carouselWrapperClassName?: string
  carouselHeaderClassName?: string
  carouselBodyClassName?: string
  onStartReached?: () => Promise<any>
  onEndReached?: () => Promise<any>
  loading?: boolean
  borderType?: 'border' | 'borderless'
  loaderClassName?: string
  listContainerClassName?: string
  carouselActionsClassName?: string
  onScroll?: (direction: 'right' | 'left') => void
  containerRef?: React.RefObject<HTMLDivElement>
  cards: T[]
  onAfterUpdate?: (cards: T[]) => void
  renderer: (card: T, firstCardRef?: React.RefCallback<HTMLDivElement>) => JSX.Element
  fullCards?: boolean
  cardGap?: number
  emptyState?: React.ReactNode
  hideActions?: boolean
  scrollRef?: React.MutableRefObject<((idx: number) => Promise<void>) | undefined>
  enableEdgesInteraction?: boolean
  carouselCustomAction?: React.ReactNode
  'data-testid'?: string
}

/**
 * Renders a virtualized carousel, need to be feed with cards.
 *
 * @param title - String for the name or title.
 * @param description - Extra information, will be placed to the left of the arrows
 * @param carouselWrapperClassName - Classname passed to the carousel container
 * @param carouselHeaderClassName - Classname passed to the carousel header
 * @param carouselBodyClassName - Classname passed to the carousel body
 * @param onStartReached - Handler that will trigger when user click on left arrow and the scroll will reach the start
 * @param onEndReached - Handler that will trigger when user clicks on right arrow and the scroll will reach its end.
 * @param onAfterUpdate - Handler that will trigger after render has completed. Sends list of rendered cards
 * @param loading - shows a loading indicator.
 * @param loaderClassname - ClassName passed to the PrismLoader wrapper
 * @param listContainerClassName - ClassName passed to the children wrapper container
 * @param carouselActionsClassName - ClassName passed to the CardBox actions container
 * @param onScroll - Handler executed when any arrow button is clicked, it receives the scroll direction
 * @param containerRef - If provided, this ref will be used for the list container
 * @param cards - The cards that should be rendered by the carousel
 * @param renderer - A function that will be used to render each of the cards that need to be showed.
 * @param fullCards - Whether the carousel will show full cards, we use this to know how to calculate the cards per page. The number of cards should be passed through css using the prop listContainerClassName
 * @param cardGap - Gap betwen the cards, usually used when we have margins between cards instead of paddings. Usefull when fullCards prop is in use and needs to match with the css variable "--cardListGap"
 * @param emptyState - If provided, we render this element instead of the cards.
 * @param hideActions - If true, the arrow buttons will not be rendered.
 * @param scrollRef - ref to call the scroll left method from outside the component
 * @param enableEdgesInteraction - by default prevents interaction of incomplete cards that are on the edge of the carousel.
 */

const DEFAULT_CARDS_TO_SHOW = 6
const BASE_TRANSITION = 'transform 0.4s ease-in-out'
const getCarouselTransformation = (scrollSize: number) => `translateX(${scrollSize}px)`

function VirtualizedCarousel<T extends { id: string }>({
  title,
  description,
  carouselWrapperClassName,
  carouselHeaderClassName,
  carouselBodyClassName,
  onStartReached,
  onEndReached,
  onAfterUpdate,
  loading,
  loaderClassName,
  listContainerClassName,
  carouselActionsClassName,
  onScroll,
  containerRef,
  cards,
  renderer,
  fullCards,
  cardGap = 0,
  emptyState,
  hideActions,
  scrollRef,
  enableEdgesInteraction = true,
  carouselCustomAction,
  'data-testid': dataTestId,
}: VirtualizedCarouselProps<T>) {
  // Used as a default ref in case containerRef is undefined.
  const defaultRef = useRef<HTMLDivElement>(null)
  const ref = containerRef || defaultRef
  const innerRef = useRef<HTMLDivElement>(null)
  const cardWidthCalculatedRef = useRef<boolean>(false)
  const isScrollingRef = useRef(false)

  const [pageIdx, setPageIdx] = useState(0)
  const [cardsPerPage, setCardsPerPage, cardsPerPageRef] = useStateAndRef(0)

  const { width: containerWidth } = useContainerDimensions(ref, false)

  const [cardWidth, setCardWidth, cardWidthRef] = useStateAndRef<number | undefined>(undefined)

  // Used to get the size of the first image
  const firstCardRefCallback: React.RefCallback<HTMLDivElement> = node => {
    if (node === null || (cardWidth && cardWidth > 0)) return
    setCardWidth(node.offsetWidth)
  }

  // TODO: When we have cut cards, we're setting the cards per page value twice, causing two renders. It would be nice
  // to only do so once
  useEffect(() => {
    // If we are rendering full cards, we can get a rounded down integer as cards per page.
    // This works because we don't need to calculate scroll size for partial cards, we just need to scroll the whole page.
    if (fullCards && cardWidth && !loading && innerRef.current && containerWidth) {
      const partialCardsPerPage = containerWidth / cardWidth
      const intCards = Math.floor(partialCardsPerPage)

      cardWidthCalculatedRef.current = true
      setCardsPerPage(intCards)
    }

    // If we are not rendering full cards, we store a float number of cards per page, this allow us to calculate the scroll size.
    if (!fullCards && cardWidth && containerWidth) {
      cardWidthCalculatedRef.current = true
      setCardsPerPage(containerWidth / cardWidth)
    }

    // If we don't have the cardWidth, we return a fixed number of cards to render first and then we get the card width.
    if (!cardWidth) {
      cardWidthCalculatedRef.current = false
      setCardsPerPage(DEFAULT_CARDS_TO_SHOW)
    }
  }, [cardWidth, containerWidth, fullCards, loading, setCardsPerPage])

  // The cards we need to show, including the current page, one before and one after.
  const currentCards = useMemo(() => {
    if (!cardsPerPage) return
    const fixedCardsPerPage = Math.floor(cardsPerPage)
    const start = Math.max((pageIdx - 1) * fixedCardsPerPage, 0)
    const end = (pageIdx + 2) * fixedCardsPerPage
    return forLoopSlice(cards, start, end)
  }, [cardsPerPage, cards, pageIdx])

  // This effect will run whenever the cards get updated
  useEffect(() => {
    if (!currentCards?.length) return
    onAfterUpdate?.(currentCards)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCards])

  // Gets the cards only for the provided page
  const getPageCards = useCallback(
    (pageNumber: number) => {
      const fixedCardsPerPage = Math.floor(cardsPerPageRef.current)
      const start = pageNumber * fixedCardsPerPage
      const end = (pageNumber + 1) * fixedCardsPerPage
      return forLoopSlice(cards, start, end)
    },
    [cardsPerPageRef, cards],
  )

  const willEndBeReached = useCallback(() => {
    if (!cardsPerPageRef.current) return
    const nextPage = pageIdx + 1

    const cardsForNextPage = getPageCards(nextPage)

    return cardsForNextPage.length < Math.floor(cardsPerPageRef.current)
  }, [cardsPerPageRef, getPageCards, pageIdx])

  // We want to get the fractional part of cards per page to calculate the needed scroll size to reach the correct card.
  const lastVisibleCardFraction = cardsPerPage ? cardsPerPage % 1 : 0

  const getScrollSize = useCallback(() => {
    if (!containerWidth || !cardWidthRef.current) return
    // If we are showing full cards, we only need to scroll the container width and the card gap
    if (fullCards) return containerWidth + cardGap

    // If we are not showing full cards we need to scroll the container with minus the width of the last partial card
    return containerWidth - lastVisibleCardFraction * cardWidthRef.current
  }, [cardGap, cardWidthRef, containerWidth, fullCards, lastVisibleCardFraction])

  const scrollLeft = useCallback(
    async (newPageIndex: number) => {
      if (!ref.current || pageIdx === 0 || isScrollingRef.current) return
      const scrollSize = getScrollSize()
      if (!scrollSize) return

      // We set the initial transition and transform
      ref.current.style.transition = BASE_TRANSITION
      // Moving to 0 means we are moving to the left
      ref.current.style.transform = getCarouselTransformation(0)

      const handler = async () => {
        if (!ref.current) return

        let pageIndexToUse = newPageIndex
        const newPageCards = getPageCards(newPageIndex)
        // If we would scroll to an empty page, scroll to the last available page
        if (!newPageCards.length) {
          const lastCardIndex = cards.length - 1
          const fixedPageIndex = Math.floor(lastCardIndex / cardsPerPageRef.current)
          pageIndexToUse = fixedPageIndex
        }

        setPageIdx(pageIndexToUse)
        onScroll?.('left')
        // Let the state update before transition runs
        await sleep(0)
        ref.current.style.transition = 'none'

        if (newPageIndex !== 0) {
          // If the new page is not the first one, we need to silently return to the correct place
          ref.current.style.transform = getCarouselTransformation(-scrollSize)
        }
        isScrollingRef.current = false
        if (newPageIndex === 0) onStartReached?.()
      }

      // by setting once to true, the listner will only be invoked once and then be removed.
      ref.current.addEventListener('transitionend', handler, { once: true })
      isScrollingRef.current = true
    },
    [cards.length, cardsPerPageRef, getPageCards, getScrollSize, onScroll, onStartReached, pageIdx, ref],
  )

  const scrollRight = useCallback(
    async (newIdx: number) => {
      if (!ref.current || isScrollingRef.current) return

      const currentPage = getPageCards(pageIdx)
      // If we are alredy on an incomplete page, we cam asume we are on the last page.
      if (currentPage.length < Math.floor(cardsPerPageRef.current)) return

      if (willEndBeReached()) {
        await onEndReached?.()
        // we need to do this to validate the length with the new cards count ref
        await sleep(0)
        const nextPageCards = getPageCards(newIdx)
        // If after `onEndReached` the next page will be empty, don't scroll
        if (!nextPageCards.length) return
      }

      const scrollSize = getScrollSize()
      if (!scrollSize) return

      // apply the transitions and tranform styles when clicked
      ref.current.style.transition = BASE_TRANSITION
      if (pageIdx === 0) {
        // if we are on the first page, we just one to move one page to the right
        ref.current.style.transform = getCarouselTransformation(-scrollSize)
      } else {
        // if we are NOT in the first page, we move twice the scroll size, one for the current page, and another for the next page
        ref.current.style.transform = getCarouselTransformation(-scrollSize * 2)
      }
      const handler = async () => {
        if (!ref.current) return
        setPageIdx(newIdx)
        onScroll?.('right')
        // Let the state update before transition runs
        await sleep(0)
        // we "silently" (without transition animation) return to the correct place
        ref.current.style.transition = 'none'
        ref.current.style.transform = getCarouselTransformation(-scrollSize)
        isScrollingRef.current = false
      }

      // by setting once to true, the listner will only be invoked once and then be removed.
      ref.current.addEventListener('transitionend', handler, { once: true })
      isScrollingRef.current = true
    },
    [cardsPerPageRef, willEndBeReached, getPageCards, getScrollSize, onEndReached, onScroll, pageIdx, ref],
  )

  const scrollToIndex = useCallback(
    async (idx: number) => {
      const targetPage = Math.floor(idx / cardsPerPageRef.current)
      if (targetPage > pageIdx) {
        await scrollRight(targetPage)
      }
      if (targetPage < pageIdx) {
        await scrollLeft(targetPage)
      }
    },
    [cardsPerPageRef, pageIdx, scrollLeft, scrollRight],
  )

  // This effect is in charge of scrolling to the last card in case
  // cards from current page are removed, this way the carousel will not get
  // stuck in an empty page
  useEffect(() => {
    if (pageIdx < 0) return
    const currentPageCards = getPageCards(pageIdx)
    if (currentPageCards.length > 0) return
    const doScroll = async () => {
      await scrollToIndex(cards.length - 1)
    }

    doScroll()
  }, [cards.length, getPageCards, pageIdx, scrollToIndex])

  useEffect(() => {
    if (scrollRef) {
      const scrollFunction = async (idx: number, retries = 5): Promise<void> => {
        if (retries === 0) return
        if (cardWidthCalculatedRef.current) return await scrollToIndex(idx)
        await sleep(50)
        return await scrollFunction(idx, retries - 1)
      }
      scrollRef.current = scrollFunction
    }
  }, [scrollLeft, scrollRef, scrollToIndex])

  return (
    <div className={`${Styles.carouselWrapper} ${carouselWrapperClassName ?? ''}`} data-testid={dataTestId}>
      {!carouselCustomAction && (
        <div className={`${Styles.carouselHeader} ${carouselHeaderClassName ?? ''}`}>
          <div>{title}</div>

          {!hideActions && (
            <div className={`${Styles.carouselHeaderRight} ${carouselActionsClassName ?? ''}`}>
              <div className={Styles.carouselHeaderDescription}>{description}</div>

              <CarouselActions
                pageIdx={pageIdx}
                fixedCardsPerPage={Math.floor(cardsPerPage)}
                cards={cards}
                onLeftClick={() => scrollLeft(Math.max(pageIdx - 1, 0))}
                onRightClick={() => scrollRight(pageIdx + 1)}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`${Styles.carouselBody} ${enableEdgesInteraction ? Styles.edgesInteraction : ''} ${
          carouselBodyClassName ?? ''
        }`}
      >
        {loading && (
          <div className={`${Styles.loadOnCenter} ${loaderClassName ?? ''}`}>
            <PrismLoader />
          </div>
        )}

        {!loading && (
          <div className={Styles.labelContainer} ref={ref}>
            <div
              className={`${Styles.labelInnerContainer} ${fullCards ? Styles.fullCardsContainer : ''}  ${
                listContainerClassName ?? ''
              }`}
              ref={innerRef}
            >
              <>
                {emptyState}

                {!emptyState &&
                  currentCards?.map((card, idx) => {
                    return renderer(card, idx === 0 ? firstCardRefCallback : undefined)
                  })}
              </>
            </div>
          </div>
        )}
      </div>
      {carouselCustomAction && !hideActions && (
        <CarouselActions
          customAction={carouselCustomAction}
          pageIdx={pageIdx}
          fixedCardsPerPage={Math.floor(cardsPerPage)}
          cards={cards}
          onLeftClick={() => scrollLeft(Math.max(pageIdx - 1, 0))}
          onRightClick={() => scrollRight(pageIdx + 1)}
        />
      )}
    </div>
  )
}

export default VirtualizedCarousel

/**
 * Renders the Virtualized Carousel Actions section
 *
 * @param customAction - custom element to render
 * @param pageIdx
 * @param fixedCardsPerPage - Fixed number of cards per page, rounded down, used to know if next page is available
 * @param cards - carousel cards
 * @param onLeftClick - click handler for left arrow
 * @param onRightClick - handler for right arrow
 *
 *
 */
const CarouselActions = ({
  customAction,
  pageIdx,
  fixedCardsPerPage,
  cards,
  onLeftClick,
  onRightClick,
}: {
  customAction?: React.ReactNode
  pageIdx: number
  fixedCardsPerPage: number
  cards: any
  onLeftClick: (e: React.SyntheticEvent<Element, Event>) => any
  onRightClick: (e: React.SyntheticEvent<Element, Event>) => any
}) => {
  return (
    <div className={Styles.modalCarouselArrows}>
      <IconButton
        icon={<PrismNavArrowIcon direction="left" className={Styles.iconColor} />}
        type="tertiary"
        className={Styles.cardIcon}
        onClick={onLeftClick}
        disabled={pageIdx === 0}
      />
      {customAction}
      <IconButton
        disabled={fixedCardsPerPage * (pageIdx + 1) >= cards.length}
        icon={<PrismNavArrowIcon className={Styles.iconColor} />}
        type="tertiary"
        className={Styles.cardIcon}
        onClick={onRightClick}
      />
    </div>
  )
}

// The slice operation is more time efficient, but less memory efficient with large arrays, in the carousel we want to use this
// method to prevent high memory usage.
const forLoopSlice = <T extends {}>(target: T[], start: number, end: number) => {
  const results: T[] = []
  for (let idx = Math.floor(start); idx < end; idx++) {
    const current = target[idx]
    if (current) results.push(current)
  }

  return results
}
