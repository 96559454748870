import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, useQuery } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { PrismElementaryCube, PrismNavArrowIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal, ModalProps } from 'components/PrismModal/PrismModal'
import { PrismSelect, PrismSelectListItem, PrismSelectPlaceholder } from 'components/PrismSelect/PrismSelect'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { Token } from 'components/Token/Token'
import { useData, useRobotDiscovery, useStationsWithStatus, useTypedSelector } from 'hooks'
import Shared from 'styles/Shared.module.scss'
import { Capabilities, DeepCopyRoutineBody, RecipeExpanded, Routine } from 'types'
import {
  cameraResolutionIsCompatibleWithRoutine,
  getRobotDisplayName,
  isRecipeOrRoutineResponseProtected,
  linkRoutineRobotAndRecipe,
  refreshRoutineAndRecipe,
  sortByNewestFirst,
  updateUserLocalPreference,
} from 'utils'

import { ShareToolsSwitch } from './DuplicateOrMoveRecipeModal'
import Styles from './DuplicateOrMoveRecipeModal.module.scss'

type Props = {
  isDuplicating?: boolean
  recipe: RecipeExpanded
  routine: Routine
  onSubmit?: (routine: Routine) => any
} & ModalProps

/**
 * Renders a wrapper over antd's Modal, which allows us to duplicate/move a routine with its parents, and set a new name to that routine parent
 * @param isDuplicating - Whether we are duplicating or moving the routine
 * @param recipe - Current recipe we are working on
 * @param routine - Routine we want to duplicate/move
 * @param onSubmit - Function that is called when the user clicks submit
 */
function DuplicateOrMoveViewModal({ isDuplicating = false, recipe, routine, onSubmit, onClose, ...rest }: Props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const me = useData(getterKeys.me())

  const userLocalPreferences = useTypedSelector(
    state => state.localStorage.userPreferencesById[me?.id || ''],
    shallowEqual,
  )

  // Wait until the modal is fully opened before rendering images
  const [modalLoaded, setModalLoaded] = useState(false)

  const stations = useQuery(getterKeys.stations('all-with-robots'), () => service.getStations({ has_robots: true }), {
    noRefetch: true,
    noRefetchMs: 30 * 60 * 1000,
  }).data?.data.results
  const { stationsWithStatus, robotIds } = useStationsWithStatus(stations || [])

  const discoveries = useRobotDiscovery(robotIds)
  const capabilitiesByRobotId: { [robotId: string]: Capabilities | null | undefined } = {}
  Object.entries(discoveries || {}).forEach(([robotId, discovery]) => {
    capabilitiesByRobotId[robotId] = discovery?.basler?.capabilities
  })

  const currentRecipeImages = recipe.parent.fallback_images.sort(sortByNewestFirst)
  const currentRecipeImage = currentRecipeImages[0]?.image_thumbnail || currentRecipeImages[0]?.image

  const errorMsg = `There was an error ${isDuplicating ? 'duplicating' : 'moving'} the view, please try again`
  const defaultValues = getDefaultFormValues(
    routine.parent.name,
    userLocalPreferences?.shareToolsOnViewDuplication || false,
  )
  const {
    formState: { isDirty, isValid },
    control,
    getValues,
    setValue,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const { station_id, recipe_parent_id } = watch(['station_id', 'recipe_parent_id'])

  // Options for Recipe select
  const recipeParentsInStation = useQuery(
    getterKeys.recipeParents(),
    station_id ? () => service.getRecipeParents({ station_id, is_deleted: false }) : null,
    { refetchKey: station_id },
  ).data?.data.results

  // Options for camera select
  const robotsInNewStation = stationsWithStatus.find(station => station.id === station_id)?.robots

  // Set newRecipeWorkingVersion
  const newRecipeParent = recipeParentsInStation?.find(recipeParent => recipeParent.id === recipe_parent_id)
  const newRecipeWorkingVersion = useQuery(
    newRecipeParent?.working_version ? getterKeys.recipe(newRecipeParent.working_version.id) : null,
    newRecipeParent?.working_version ? () => service.getRecipe(newRecipeParent.working_version!.id) : null,
    { refetchKey: newRecipeParent?.working_version?.id },
  ).data?.data

  const handleSubmit = async (recipe: RecipeExpanded, routine: Routine) => {
    if (!me) return
    // When we are moving the view, we need to delete the RecipeRoutine record
    if (!isDuplicating) {
      const res = await service.deleteRoutineFromRecipe(routine.id, recipe.id)
      if (res.type === 'exception') return error({ title: errorMsg })
      if (
        isRecipeOrRoutineResponseProtected(res, {
          recipe,
          history,
          routineParentId: routine.parent.id,
          onCreate: (newRecipe, newRoutine) => {
            if (newRoutine) handleSubmit(newRecipe, newRoutine)
          },
        })
      ) {
        return
      }
    }

    const body: DeepCopyRoutineBody & { share_tools?: boolean } = getValues()
    const { share_tools } = body

    if (!body.station_id || !body.recipe_parent_id || !newRecipeWorkingVersion) return

    if (isDuplicating) {
      body.copy_parents = true
    } else {
      delete body.share_tools
      body.copy_family = true
      body.name = routine.parent.name
    }

    const robotIdByRoutineId: { [routineId: string]: string } = {}
    newRecipeWorkingVersion.recipe_routines.forEach(recipeRoutine => {
      if (recipeRoutine.robot_id) {
        robotIdByRoutineId[recipeRoutine.routine.id] = recipeRoutine.robot_id
      }
    })
    // We need to rev the recipe we are tryng to move/duplicate the view to
    const newRecipeVersionRes = await service.duplicateRecipeParent(newRecipeWorkingVersion.parent_id, {
      robot_id_by_routine_id: robotIdByRoutineId,
    })
    if (newRecipeVersionRes.type !== 'success') return error({ title: errorMsg })

    const res = await service.deepCopyRoutine(routine.id, body)

    if (res.type !== 'success') return error({ title: errorMsg })

    const newRoutine = res.data

    if (body.robot_id) {
      linkRoutineRobotAndRecipe({
        routineId: newRoutine.id,
        robotId: body.robot_id,
        recipe: newRecipeVersionRes.data,
        errorMsg,
        dispatch,
        history,
      })
    } else {
      await refreshRoutineAndRecipe({
        routineId: newRoutine.id,
        recipeId: newRecipeVersionRes.data.id,
        recipeParentId: newRecipeVersionRes.data.parent_id,
        dispatch,
      })
    }
    updateUserLocalPreference(me.id, { shareToolsOnViewDuplication: share_tools })
    await onSubmit?.(res.data)
    success({
      title: `${newRoutine.parent.name} ${isDuplicating ? 'duplicated' : 'moved'}`,
      'data-testid': `${isDuplicating ? 'duplicate' : 'move'}-success-notification`,
    })
    onClose?.()
  }

  return (
    <Modal
      header={isDuplicating ? 'Duplicate View' : 'Move View'}
      {...rest}
      onOk={() => handleSubmit(recipe, routine)}
      onClose={onClose}
      okText={isDuplicating ? 'Duplicate' : 'Move'}
      size="largeSimpleForm"
      data-testid="duplicate-view-modal"
      headerClassName={Styles.recipeModalHeader}
      modalFooterClassName={Styles.recipeModalFooter}
      className={Styles.recipeDuplicateModal}
      disableSave={!isDirty || !isValid}
      onModalLoad={() => setModalLoaded(true)}
      extraButtons={
        isDuplicating ? (
          <Controller
            control={control}
            name="share_tools"
            render={({ value, ...props }) => <ShareToolsSwitch type="view" checked={value} {...props} />}
          />
        ) : undefined
      }
    >
      <form onSubmit={() => handleSubmit(recipe, routine)} className={Shared.verticalChildrenGap24}>
        {isDuplicating && (
          <Controller
            name="name"
            rules={{ required: 'Required' }}
            control={control}
            render={props => (
              <PrismInput
                {...props}
                label="View Name"
                className={Styles.input}
                data-testid="duplicate-view-modal-input"
              />
            )}
          />
        )}

        <div className={Styles.duplicateOptionGrid}>
          <Token label="current station" labelClassName={Styles.selectLabel}>
            <PrismSelect
              size="large"
              value={stationsWithStatus.length ? recipe.parent.station_id : undefined}
              disabled
              className={Styles.duplicateSelect}
              placeholder={<PrismSelectPlaceholder title={recipe.parent.station?.name || ''} hideImageBorder />}
              options={stationsWithStatus.map(station => {
                // First robot is guaranteed to be prioritized by status thanks to `useStationWithStatus`
                const robotId = station.robots[0]?.id
                return {
                  key: station.id,
                  value: station.id,
                  content: station.name,
                  image: {
                    cameraStatus: station.status,
                    hideBorderImage: !robotId,
                    content:
                      modalLoaded && robotId ? (
                        <ReduxVideo element="transcoder-basler-image-thumbnail" stream="compressed" robotId={robotId} />
                      ) : (
                        <PrismElementaryCube />
                      ),
                  },
                }
              })}
            />
          </Token>

          <PrismNavArrowIcon className={Styles.arrowIcon} />

          <Token label="new station" labelClassName={Styles.selectLabel}>
            <Controller
              name={'station_id'}
              control={control}
              rules={{ required: 'Required' }}
              render={({ onChange, value }) => (
                <PrismSelect
                  data-testid="station-duplicate-move-modal"
                  size="large"
                  value={value}
                  onChange={val => {
                    onChange(val)
                    setValue('robot_id', undefined)
                  }}
                  className={Styles.duplicateSelect}
                  placeholder={<PrismSelectPlaceholder title="Station" hideImageBorder />}
                  showSearch
                  optionFilterProp="content"
                  options={stationsWithStatus.map(station => {
                    const robotId = station.robots[0]?.id
                    return {
                      key: station.id,
                      value: station.id,
                      content: station.name,
                      dataTestId: `station-options-duplicate-move-modal-${station.id}`,
                      image: {
                        cameraStatus: station.status,
                        content:
                          modalLoaded && robotId ? (
                            <ReduxVideo
                              element="transcoder-basler-image-thumbnail"
                              stream="compressed"
                              robotId={robotId}
                            />
                          ) : (
                            <PrismElementaryCube />
                          ),
                      },
                    }
                  })}
                />
              )}
            />
          </Token>

          <Token label="current recipe" labelClassName={Styles.selectLabel}>
            <PrismSelect
              size="large"
              value={recipe.id}
              disabled
              className={Styles.duplicateSelect}
              placeholder={<PrismSelectPlaceholder title="Recipe" hideImageBorder />}
              options={[
                {
                  key: recipe.id,
                  value: recipe.id,
                  content: recipe.parent.name,
                  image: {
                    hideImageBorder: !currentRecipeImage,
                    content:
                      modalLoaded && currentRecipeImage ? (
                        <ImgFallback loaderType="skeleton" src={currentRecipeImage} alt="" />
                      ) : (
                        <PrismElementaryCube />
                      ),
                  },
                },
              ]}
            />
          </Token>

          <PrismNavArrowIcon className={Styles.arrowIcon} />

          <Token label="new recipe" labelClassName={Styles.selectLabel}>
            <Controller
              name={'recipe_parent_id'}
              rules={{ required: 'Required' }}
              control={control}
              render={({ value, onChange }) => (
                <SearchableSelect
                  data-testid="recipe-duplicate-move-modal"
                  value={value}
                  onSelect={id => onChange(id)}
                  size="large"
                  className={Styles.duplicateSelect}
                  placeholder={<PrismSelectPlaceholder title="Recipe" hideImageBorder />}
                  disabled={!station_id}
                  getterKey={getterKeys.recipeParents()}
                  fetcher={searchValue =>
                    service.getRecipeParents({ station_id, search: searchValue, is_deleted: false })
                  }
                  getSelectOptionDataTestId={recipeParent => `recipe-duplicate-move-modal-${recipeParent.name}`}
                  isOptionDisabled={recipeParent => {
                    if (isDuplicating) return false

                    return recipeParent.id === recipe.parent_id
                  }}
                  formatter={recipeParent => {
                    const images = recipeParent.fallback_images.sort(sortByNewestFirst)
                    const image = images[0]?.image_thumbnail || images[0]?.image

                    return (
                      <PrismSelectListItem
                        content={`${recipeParent.name} - ${recipeParent.component_name}`}
                        image={{
                          content:
                            modalLoaded && image ? (
                              <ImgFallback loaderType="skeleton" src={image} alt="" />
                            ) : (
                              <PrismElementaryCube />
                            ),
                          hideImageBorder: !image,
                        }}
                      />
                    )
                  }}
                />
              )}
            />
          </Token>

          <Token label="view" labelClassName={Styles.selectLabel}>
            <PrismSelect
              size="large"
              value={routine.id}
              disabled
              className={Styles.duplicateSelect}
              placeholder={<PrismSelectPlaceholder title="View" hideImageBorder />}
              options={[
                {
                  key: routine.id,
                  value: routine.id,
                  content: routine.parent.name,
                  image: {
                    hideImageBorder: !routine.image && !routine.image_thumbnail,
                    content:
                      modalLoaded && (routine.image || routine.image_thumbnail) ? (
                        <ImgFallback loaderType="skeleton" src={routine.image_thumbnail || routine.image} alt="" />
                      ) : (
                        <PrismElementaryCube />
                      ),
                  },
                },
              ]}
            />
          </Token>

          <PrismNavArrowIcon className={Styles.arrowIcon} />

          <Token label="new camera" labelClassName={Styles.selectLabel}>
            <Controller
              name={'robot_id'}
              control={control}
              render={props => (
                <PrismSelect
                  data-testid="view-duplicate-move-modal"
                  size="large"
                  {...props}
                  className={Styles.duplicateSelect}
                  placeholder={<PrismSelectPlaceholder title="Camera" hideImageBorder />}
                  disabled={!station_id}
                  showSearch
                  optionFilterProp="content"
                  options={robotsInNewStation?.map(robot => {
                    const robotName = getRobotDisplayName(robot) || ''
                    const compatibleWithRoutine = cameraResolutionIsCompatibleWithRoutine(
                      capabilitiesByRobotId?.[robot.id],
                      routine?.settings,
                    )
                    return {
                      key: robot.id,
                      value: robot.id,
                      content: robotName,
                      disabled: !compatibleWithRoutine,
                      dataTestId: `view-duplicate-move-modal-${robot.id}`,
                      tooltip: {
                        tooltipCondition: !compatibleWithRoutine,
                        tooltipTitle: 'This camera has a different resolution than the View',
                        tooltipOverlayClassName: Styles.tooltipContainer,
                        image: {
                          content: modalLoaded ? (
                            <ReduxVideo
                              element="transcoder-basler-image-thumbnail"
                              stream="compressed"
                              robotId={robot.id}
                            />
                          ) : (
                            <PrismElementaryCube />
                          ),
                        },
                      },
                    }
                  })}
                />
              )}
            />
          </Token>
        </div>

        {robotIds && <RobotsStatusListener robotIds={robotIds} />}
        {robotIds && (
          <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
        )}
      </form>
    </Modal>
  )
}

const getDefaultFormValues = (
  name: string,
  shareTools: boolean,
): {
  name: string
  station_id: string | undefined
  recipe_parent_id: string | undefined
  robot_id: string | undefined
  share_tools: boolean
} => {
  return {
    name: `Duplicate of ${name}`,
    station_id: undefined,
    recipe_parent_id: undefined,
    robot_id: undefined,
    share_tools: shareTools,
  }
}

export default DuplicateOrMoveViewModal
