import React from 'react'

import { TooltipPlacement } from 'antd/lib/tooltip'

import { IconButton } from 'components/IconButton/IconButton'
import { IconButtonProps } from 'components/OptionMenu/OptionMenu'
import { PrismArchiveIcon } from 'components/prismIcons'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'

import Styles from './ArchiveIconButton.module.scss'

interface Props extends IconButtonProps {
  onClick: () => void
  isActive: boolean
  disabled?: boolean
  className?: string
  dataTestId?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large'
  wrapperClassName?: string
  tooltipPlacement?: TooltipPlacement
  loading?: boolean
}

/**
 * Renders custom Archvive icon button
 *
 * - Displays a common title {View active or  View archive}
 *
 * @param onClick - {onClick}
 * @param isActive - changes the look of the button and the tooltip description
 * @param disabled - it prevents button from being clicked
 * @param className - string class that will be passed down to the button
 * @param wrapperClassName - string class that will be passed down to the button
 * @param datatTestId - string to identify the iconButton
 * @param loading - shows the loading state
 *
 */
const ArchiveIconButton = ({
  isActive,
  disabled,
  onClick,
  className = '',
  dataTestId,
  size = 'small',
  wrapperClassName = '',
  tooltipPlacement = 'top',
  loading,
  ...rest
}: Props) => {
  return (
    <PrismTooltip
      title={isActive ? 'View active' : 'View archive'}
      mouseEnterDelay={0.5}
      placement={tooltipPlacement}
      anchorClassName={wrapperClassName}
    >
      <IconButton
        data-testid={dataTestId}
        className={`${className} ${isActive ? Styles.isActive : ''}`}
        icon={<PrismArchiveIcon />}
        type="tertiary"
        size={size}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        {...rest}
      />
    </PrismTooltip>
  )
}

export default ArchiveIconButton
