import React, { Fragment } from 'react'

import { Image, Text, View } from '@react-pdf/renderer'

import { PdfColumn, PdfDataItem } from 'types'

import PdfElementaryCube from './PdfSVG/PdfElementaryCube'
import PDFIcon from './PdfSVG/PdfIcon'
import PdfIcon from './PdfSVG/PdfIcon'
import styles, { pixelsIntoPoints } from './styles'

export type TableProps = { columns: PdfColumn[]; orientation?: 'portrait' | 'landscape'; dataSource: PdfDataItem[] }

const ELEMENTARY_CUBE_SIZE = pixelsIntoPoints(110)
const ELEMENTARY_CUBE_TABLE_SIZE = pixelsIntoPoints(50)

/**
 * Renders table with content in PDF format
 * @param columns - Column definition for the table
 * @param dataSource - The data source of each row to be rendered
 */
export const PdfTable = ({ columns, dataSource, orientation = 'portrait' }: TableProps) => {
  return (
    <View style={styles.tableContainer}>
      {/* TableHeader */}
      <View style={styles.tableHeaderRow}>
        {columns.map(column => (
          <View
            key={column.key}
            style={{
              ...(column.headerClassName ? styles[column.headerClassName] : {}),
              ...(column.className ? styles[column.className] : {}),
            }}
          >
            <Text style={styles.tableHeaderCell}>{column.displayName}</Text>
          </View>
        ))}
      </View>

      {/* TableBody */}
      {dataSource.map((data, key) => {
        return (
          <View wrap={false} key={key} style={styles.tableBodyRow}>
            {columns.map((column, k) => {
              const key = column.key
              const value = data[key]?.value
              const image = data[key]?.image

              return (
                <Fragment key={k}>
                  {value !== undefined && (
                    <View key={key} style={column.className ? styles[column.className] : undefined}>
                      {key === 'outcome' && value === 'pass' && (
                        <View style={styles.pdfIconContainer}>
                          <PdfIcon icon="pass" />
                        </View>
                      )}
                      {key === 'outcome' && value === 'discard' && (
                        <View style={styles.pdfIconContainer}>
                          <PdfIcon icon="discard" />
                        </View>
                      )}

                      {key === 'outcome' && value === 'fail' && (
                        <View style={styles.pdfIconContainer}>
                          <PdfIcon icon="fail" />
                        </View>
                      )}

                      {key === 'outcome' && value === 'unknown' && (
                        <View style={styles.pdfIconContainer}>
                          <PdfIcon icon="unknown" />
                        </View>
                      )}

                      {!Array.isArray(value) && (
                        <Text
                          style={{
                            ...styles.tableCell,
                            ...(image ? styles.fixedText : {}),
                          }}
                        >
                          {transformText(value + '', orientation, columns)}
                        </Text>
                      )}

                      {Array.isArray(value) && (
                        <>
                          {!value.length && (
                            <Text
                              style={{
                                ...styles.tableCell,
                                ...(image ? styles.fixedText : {}),
                              }}
                            >
                              --
                            </Text>
                          )}
                          {value.map((val, k) => (
                            <Fragment key={k}>
                              <View style={styles.pdfTableIcon}>
                                {val.severity === 'good' && <PDFIcon icon={'good'} />}
                                {val.severity === 'neutral' && <PDFIcon icon={'neutral'} />}
                                {val.severity === 'minor' && <PDFIcon icon={'minor'} />}
                                {val.severity === 'critical' && <PDFIcon icon={'critical'} />}
                              </View>

                              <Text style={styles.pdfTableIconText}>
                                {transformText(val.text + '', orientation, columns)}
                              </Text>
                            </Fragment>
                          ))}
                        </>
                      )}

                      {image !== undefined && (
                        <View style={styles.graphImageContainer}>
                          {image && <Image src={image} style={styles.graphImage} />}

                          {!image && <PdfElementaryCube size={ELEMENTARY_CUBE_SIZE} />}
                        </View>
                      )}
                    </View>
                  )}

                  {value === undefined && image !== undefined && (
                    <View style={styles.photoInFirstColumn}>
                      {image && <Image style={styles.photo} src={image} />}

                      {!image && <PdfElementaryCube size={ELEMENTARY_CUBE_TABLE_SIZE} />}
                    </View>
                  )}
                </Fragment>
              )
            })}
          </View>
        )
      })}
    </View>
  )
}

const transformText = (value: string, orientation: 'landscape' | 'portrait', columns: PdfColumn[]) => {
  const totalNumber = orientation === 'portrait' ? 138 : 186
  const fixedPhotoSize = 20

  const fixedFirstColumn = columns[0]?.key === 'photo'

  let characterLimit = 13

  if (fixedFirstColumn) {
    characterLimit = (totalNumber - fixedPhotoSize) / (columns.length - 1)
  } else {
    characterLimit = totalNumber / columns.length
  }

  characterLimit = Math.floor(characterLimit)

  if (value.length <= characterLimit) return value

  return value.substring(0, characterLimit - 3) + '…'
}
