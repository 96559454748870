import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Inspect Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1332%3A0
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismInspectIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.inspectIcon} ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Inspector"
        d="M2.5 13.5V3.5C2.5 2.94772 2.94772 2.5 3.5 2.5H8.5H13.5C14.0523 2.5 14.5 2.94772 14.5 3.5V5.75V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H6.5H3.5C2.94772 14.5 2.5 14.0523 2.5 13.5Z"
      />
      <path
        id="Inspector_2"
        d="M22 13C22 15.2687 21.0556 17.3169 19.5385 18.7728C18.1011 20.1522 16.1495 21 14 21C9.58172 21 6 17.4183 6 13C6 8.58172 9.58172 5 14 5C18.4183 5 22 8.58172 22 13Z"
      />
      <path
        id="Union"
        d="M15 6.5H14V7H15V6.5ZM14 8H15V9H14V8ZM15 10H14V11H15V10ZM14 12H15V13H14V12ZM9 14H8V15H9V14ZM10 14H11V15H10V14ZM13 14H12V15H13V14ZM15 14H14V15C14.5523 15 15 14.5523 15 14Z"
      />
    </svg>
  )
}
