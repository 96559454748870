import 'antd/dist/antd.less'
import 'styles/index.scss'

import React, { createContext, useEffect, useMemo } from 'react'

import { Auth0ContextInterface, Auth0Provider, User } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { includes } from 'lodash'
import { Toaster } from 'react-hot-toast'
import { Provider, useDispatch } from 'react-redux'
import { ConfigContext as QueryConfigContext } from 'react-redux-query'
import { BrowserRouter, match, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'

import { getterKeys } from 'api/dataKeys'
import ItemUploader from 'components/ItemUploader/ItemUploader'
import NewBatch from 'components/NewBatch/NewBatch'
import OnMountApp from 'components/OnMountApp'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENTID,
  AUTH0_DOMAIN,
  AUTH0_ROCKWELL_AUDIENCE,
  AUTH0_ROCKWELL_CLIENTID,
  AUTH0_ROCKWELL_DOMAIN,
  ENABLE_UI_PLAYGROUND,
  NODE_ENV,
} from 'env'
import { useData, useIsColocated, useQueryParams, useTypedSelector } from 'hooks'
import Administration from 'pages/Administration/Administration'
import AnalyzeBase from 'pages/Analyze/AnalyzeBase'
import CameraStatus from 'pages/CameraStatus/CameraStatus'
import ActivateUserOrg from 'pages/Establish/ActivateUserOrg'
import EstablishPassword from 'pages/Establish/Establish'
import Login from 'pages/Login/Login'
import LoginOrganizationPicker from 'pages/Login/LoginOrganizationPicker/LoginOrganizationPicker'
import NotFound from 'pages/NotFound/NotFound'
import Notify from 'pages/Notify/Notify'
import PrivateRoute from 'pages/PrivateRoute'
import ToolScreen from 'pages/RoutineOverview/Configure/ToolScreen'
import LabelingScreen from 'pages/RoutineOverview/LabelingScreen/LabelingScreen'
import RecipeOverview from 'pages/RoutineOverview/RecipeOverview'
import SendResetPasswordEmail from 'pages/SendResetPasswordEmail/SendResetPasswordEmail'
import Settings from 'pages/Settings/Settings'
import StationDetailRoot from 'pages/StaticInspector/StationDetailRoot'
import ColocatedStationRedirect from 'pages/StationList/ColocatedStationRedirect'
import Inspect from 'pages/StationList/Inspect'
import UiPlayground from 'pages/UiPlayground/UiPlayground'
import paths, { nonOrgPaths } from 'paths'
import * as Actions from 'rdx/actions'
import typedStore, { TypedStore } from 'rdx/store'
import {
  AccountSettingsMode,
  accountSettingsModes,
  AdministrationSettingsMode,
  administrationSettingsModes,
  AnalyzeTab,
  analyzeTabs,
  InspectTabs,
  inspectTabs,
  LabelingScreenMode,
  Me,
  NotifyMode,
  notifyModes,
  RecipeOverviewMode,
  recipeOverviewModes,
  StationDetailMode,
  stationDetailModes,
  stationDetailPrivateModes,
} from 'types'
import { appendOrgIdToCurrentLocation, getActiveUserOrgs, getLoginPath, getMinifiedOrgId, matchRole } from 'utils'

if (NODE_ENV === 'production') {
  // https://docs.sentry.io/platforms/javascript/react/
  Sentry.init({
    dsn: 'https://f25bcc97355041b4bf4eb18b0ac6ae59@o272992.ingest.sentry.io/1486906',
    // ResizeObserver error can be safely ignored to reduce log spam: https://stackoverflow.com/a/66260608
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: NODE_ENV,
    // Reduce payload size
    maxBreadcrumbs: 30,
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console' && breadcrumb.level !== 'error') return null
      return breadcrumb
    },
  })
}

const AdministrationWrapper = ({ match }: { match: match<{ mode: AdministrationSettingsMode }> }) => {
  const { mode } = match.params

  if (!administrationSettingsModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.administrationSettings({ mode: 'users' }) }} />
  }
  return <Administration />
}

const InspectWrapper = ({ match }: { match: match<{ mode: InspectTabs }> }) => {
  const { mode } = match.params
  const { isColocated } = useIsColocated()

  if (isColocated && !matchRole('manager')) {
    return <Redirect to={{ pathname: paths.root() }} />
  }

  if (!inspectTabs.includes(mode)) {
    return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />
  }

  return <Inspect inspectTab={mode} />
}

const NewBatchWrapper = ({ match }: { match?: match<{ stationId: string }> }) => {
  return <NewBatch stationId={match?.params.stationId} />
}

const AcceptInviteWrapper = ({ match }: { match: match<{ orgId: string; userId: string }> }) => {
  const [params] = useQueryParams<'password_reset_token'>()
  const { userId, orgId } = match.params
  const dispatch = useDispatch()

  const minifiedOrgId = getMinifiedOrgId(orgId)

  dispatch(Actions.authUnset())

  if (!userId) return <Redirect to={{ pathname: nonOrgPaths.notFound() }} />

  // If we don't have this param, we assume the user already exists
  if (!params.password_reset_token) {
    return <Redirect to={{ pathname: paths.activateUserOrg(minifiedOrgId) }} />
  }

  return (
    <EstablishPassword minifiedOrgId={minifiedOrgId} userId={match.params.userId} token={params.password_reset_token} />
  )
}

const ResetPasswordWrapper = ({ match }: { match: match<{ userId: string; token: string }> }) => {
  return <EstablishPassword userId={match.params.userId} token={match.params.token} />
}

const AnalyzeWrapper = ({ match }: { match: match<{ mode: AnalyzeTab }> }) => {
  const { mode } = match.params

  if (!analyzeTabs.includes(mode)) {
    return <Redirect to={{ pathname: paths.analyze({ mode: 'overview' }) }} />
  }
  return <AnalyzeBase />
}

const NotifyWrapper = ({ match }: { match: match<{ mode: NotifyMode }> }) => {
  const { mode } = match.params

  if (!notifyModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.settingsNotify({ mode: 'subscriptions' }) }} />
  }
  return <Notify />
}

const AccountSettingsWrapper = ({ match }: { match: match<{ mode: AccountSettingsMode }> }) => {
  const { mode } = match.params

  if (!accountSettingsModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.accountSettings({ mode: 'profile' }) }} />
  }
  return <Settings />
}

const RecipeOverviewWrapper = ({ match }: { match: match<{ recipeParentId: string; mode: RecipeOverviewMode }> }) => {
  const { recipeParentId, mode } = match.params

  if (!recipeOverviewModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.settingsRecipe(recipeParentId, 'configure') }} />
  }
  return <RecipeOverview recipeParentId={recipeParentId} mode={mode} />
}

const StationDetailWrapper = ({ match }: { match: match<{ stationId: string; mode: StationDetailMode }> }) => {
  const [params] = useQueryParams()

  const { mode, stationId } = match.params
  const historicInspectionId = useMemo(() => params.historicInspectionId, [params])

  // Allow devs to check out inspection screens without running any robots (not allowed in production)
  if (!stationId && NODE_ENV === 'production') return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />

  if (!stationDetailModes.includes(mode)) {
    return <Redirect to={{ pathname: paths.stationDetail('overview', stationId) }} />
  }

  if (includes(stationDetailPrivateModes, mode) && !matchRole('manager')) {
    return <Redirect to={{ pathname: paths.stationDetail('overview', stationId) }} />
  }

  return <StationDetailRoot stationId={stationId} mode={mode} historicInspectionId={historicInspectionId} />
}
const LabelingScreenWrapper = ({ match }: { match: match<{ toolParentId: string; mode: LabelingScreenMode }> }) => {
  const { toolParentId, mode } = match.params

  if (['gallery', 'focus'].includes(mode)) {
    return <LabelingScreen toolParentId={toolParentId} mode={mode} />
  }
  return <Redirect to={{ pathname: paths.labelingScreen(toolParentId, 'gallery') }} />
}

const ToolScreenWrapper = ({ match }: { match: match<{ recipeId: string; toolId: string }> }) => {
  const { recipeId, toolId } = match.params
  return <ToolScreen recipeId={recipeId} toolId={toolId} />
}

const RedirectFromRoot = () => {
  return <ColocatedStationRedirect inspectTab="site" />
}

const findUserOrg = (me: Me, minimizedOrgId: string) => {
  return me.user_orgs.find(userOrg => getMinifiedOrgId(userOrg.organization.id) === minimizedOrgId)
}

const OrganizationRoutes = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orgId } = useParams<{ orgId: string }>()
  const me = useData(getterKeys.me())
  const auth = useTypedSelector(state => state.auth)
  const currentOrg = useTypedSelector(state => state.currentOrg)

  // This effect is in charge of setting the org id that will be used across the app
  useEffect(() => {
    // We return early if the currentOrg in the Redux store matches the orgId from the URL.
    // This prevents unnecessary updates when the current organization is already correct.
    // Previously, there was a bug because the early return only checked if currentOrg existed,
    // without verifying if it matched the orgId. As a result, currentOrg could become stale.
    if (!me || !auth.auth || getMinifiedOrgId(currentOrg?.organization.id || '') === orgId) return

    const foundOrg = findUserOrg(me, orgId)

    if (!foundOrg) return history.push(nonOrgPaths.notFound())

    dispatch(Actions.orgSet(foundOrg))
  }, [auth.auth, currentOrg, dispatch, history, me, orgId])

  return (
    <>
      <Switch>
        <PrivateRoute exact path={paths.root()} component={RedirectFromRoot} />

        <PrivateRoute
          exact
          bypassCurrentOrgActive
          bypassOrgActive
          bypassEula
          path={paths.activateUserOrg(':orgId')}
          component={ActivateUserOrg}
        />

        <PrivateRoute exact path={paths.inspect({ mode: ':mode' })} component={InspectWrapper} />

        <PrivateRoute exact path={paths.newBatch(':stationId?')} component={NewBatchWrapper} />

        <PrivateRoute exact path={paths.analyze({ mode: ':mode' })} component={AnalyzeWrapper} requiredRole="manager" />

        <PrivateRoute
          exact
          path={paths.administrationSettings({ mode: ':mode' })}
          component={AdministrationWrapper}
          requiredRole="manager"
        />

        <PrivateRoute exact path={paths.accountSettings({ mode: ':mode' })} component={AccountSettingsWrapper} />

        <PrivateRoute
          exact
          path={paths.settingsNotify({ mode: ':mode' })}
          component={NotifyWrapper}
          requiredRole="manager"
        />

        <PrivateRoute
          exact
          path={paths.settingsRecipe(':recipeParentId', ':mode')}
          component={RecipeOverviewWrapper}
          requiredRole="manager"
        />

        <PrivateRoute exact path={paths.stationDetail(':mode', ':stationId')} component={StationDetailWrapper} />

        <PrivateRoute exact path={paths.settingsTool(':recipeId', ':toolId')} component={ToolScreenWrapper} />

        <PrivateRoute exact path={paths.labelingScreen(':toolParentId', ':mode')} component={LabelingScreenWrapper} />

        <PrivateRoute exact path={paths.cameraStatus(':robotId')} component={CameraStatus} />

        {/* If there are no matches with routes above, render 404 page */}
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

const OrgPicker = () => {
  const auth = useTypedSelector(state => state.auth)
  const location = useLocation()
  const me = useData(getterKeys.me())
  const history = useHistory()

  if (!auth.auth) {
    return (
      <Redirect
        push
        to={{
          pathname: getLoginPath(auth),
          state: { from: location, loggedOut: auth.logoutAction ? true : false },
        }}
      />
    )
  }

  if (!me) return <PrismLoader fullScreen />

  // If user only belongs to one org, we directly use that org
  const activeUserOrgs = getActiveUserOrgs(me)
  if (activeUserOrgs.length === 1) {
    history.replace(appendOrgIdToCurrentLocation(activeUserOrgs[0]!.organization.id))
    return
  }

  return (
    <LoginOrganizationPicker
      organizations={getActiveUserOrgs(me).map(userOrg => userOrg.organization)}
      onOrganizationClick={organizationId => {
        history.replace(appendOrgIdToCurrentLocation(organizationId))
      }}
    />
  )
}

/**
 * Renders all routes in app. Separate from `App` to facilitate testing.
 */
export function Routes() {
  return (
    <>
      <OnMountApp />

      {/* Ensure clicking outside of virtual keyboard dismisses it */}
      <div
        onClick={() => {
          const keyboard = document.getElementById('virtualKeyboardChromeExtensionOverlayScrollExtend')
          if (keyboard?.style) keyboard.style.display = 'none'
        }}
      >
        <Switch>
          <Route exact path={nonOrgPaths.login()} component={Login} />
          <Route exact path={nonOrgPaths.loginRockwell()} component={Login} />
          <Route exact path={nonOrgPaths.acceptInvite(':orgId', ':userId')} component={AcceptInviteWrapper} />
          <Route exact path={nonOrgPaths.resetPassword(':userId', ':token')} component={ResetPasswordWrapper} />
          <Route exact path={nonOrgPaths.resetPasswordEmail()} component={SendResetPasswordEmail} />
          {ENABLE_UI_PLAYGROUND && <Route exact path={nonOrgPaths.uiPlayground()} component={UiPlayground} />}

          <Route exact path={nonOrgPaths.notFound()} component={NotFound} />

          <Route path="/o/:orgId" component={OrganizationRoutes} />

          <Route component={OrgPicker} />
        </Switch>
      </div>
    </>
  )
}

/**
 * Renders component at root of render tree. Mounts our Router, all routes, and
 * connects everything to Redux.
 *
 * @param store - Redux store instance for app
 */

// Define the shape of RedirectLoginResult
interface RedirectLoginResult {
  appState?: any
}

const defaultAuth0Context: Auth0ContextInterface<User> = {
  isAuthenticated: false,
  user: undefined,
  isLoading: false,
  loginWithPopup: async () => {},
  handleRedirectCallback: async (): Promise<RedirectLoginResult> => {
    return {
      appState: undefined,
    }
  },
  getIdTokenClaims: async () => undefined,
  loginWithRedirect: async () => {},
  // @ts-expect-error getAccessTokenSilently has a valid shape for the default context.
  getAccessTokenSilently: async () => '',
  getAccessTokenWithPopup: async () => '',
  logout: () => {},
}

export const contextRockwellAuth0 = createContext<Auth0ContextInterface<User>>(defaultAuth0Context)

const App = ({ store }: { store?: TypedStore }) => {
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      audience={AUTH0_AUDIENCE}
      clientId={AUTH0_CLIENTID}
      redirectUri={window.location.origin + nonOrgPaths.login()}
      skipRedirectCallback={window.location.href.includes(nonOrgPaths.loginRockwell())}
    >
      <Auth0Provider
        context={contextRockwellAuth0}
        domain={AUTH0_ROCKWELL_DOMAIN}
        audience={AUTH0_ROCKWELL_AUDIENCE}
        clientId={AUTH0_ROCKWELL_CLIENTID}
        redirectUri={window.location.origin + nonOrgPaths.loginRockwell()}
        skipRedirectCallback={
          window.location.href.includes(nonOrgPaths.login()) &&
          !window.location.href.includes(nonOrgPaths.loginRockwell())
        }
      >
        <Provider store={store || typedStore}>
          <ItemUploader />
          <Toaster />

          <QueryConfigContext.Provider value={{ branchName: 'getter', catchError: false }}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </QueryConfigContext.Provider>
        </Provider>
      </Auth0Provider>
    </Auth0Provider>
  )
}

export default App
