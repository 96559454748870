import React from 'react'

import { SkeletonProps } from 'antd'

import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
/**
 * Simple wrapper that renders a PrismSkeleton if needed.
 *
 * @param loading - If true, we render an Skeleton.
 * @param children - Children to render if loading is false
 */
const SkeletonWrapper = ({
  loading,
  children,
  datatestId,
  skeletonProps = {},
}: {
  loading: boolean
  children: React.ReactNode
  skeletonProps?: SkeletonProps
  datatestId?: string
}) => {
  if (loading) return <PrismSkeleton {...skeletonProps} />
  return <span data-testid={datatestId}>{children}</span>
}

export default SkeletonWrapper
