import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { PrismElementaryCube } from 'components/prismIcons'
import ArchiveEntityModal from 'pages/StationList/Inspect/ArchiveSlsModal'
import paths from 'paths'
import { RecipeExpanded } from 'types'
import { unarchiveRecipe } from 'utils'

import AddViewModal from './AddViewModal'
import Styles from './ViewEmptyState.module.scss'

const ViewEmptyState = ({ recipe }: { recipe: RecipeExpanded }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [showAddViewModal, setShowAddViewModal] = useState<boolean>(false)
  const [showArchiveRecipeModal, setshowArchiveRecipeModal] = useState<boolean>(false)
  return (
    <div className={Styles.viewEmptyState}>
      <GenericBlankStateMessage
        header={<PrismElementaryCube className={Styles.emptyStateIcon} />}
        title="No View"
        description="Either archive this recipe or add a view to begin setup."
        className={Styles.messageContainer}
      />
      <div className={Styles.buttonContainer}>
        <Button
          type="secondary"
          size="small"
          onClick={() => {
            if (recipe.parent.is_deleted)
              unarchiveRecipe({
                recipeParentId: recipe.parent.id,
                onSuccess: async () => {
                  await query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), {
                    dispatch,
                  })
                },
              })
            else setshowArchiveRecipeModal(true)
          }}
          disabled={recipe.parent.is_deleted}
        >
          Archive recipe
        </Button>
        <Button type="secondary" size="small" onClick={() => setShowAddViewModal(true)}>
          Add new view
        </Button>
      </div>

      {showAddViewModal && (
        <AddViewModal
          onClose={() => setShowAddViewModal(false)}
          onOk={() => setShowAddViewModal(false)}
          recipe={recipe}
        />
      )}

      {showArchiveRecipeModal && (
        <ArchiveEntityModal
          entityType="recipe"
          entityId={recipe.parent.id}
          onClose={() => setshowArchiveRecipeModal(false)}
          onOk={() =>
            history.push(paths.inspect({ mode: 'product', params: { component_id: recipe.parent.component_id } }))
          }
        />
      )}
    </div>
  )
}

export default ViewEmptyState
