import React from 'react'

import ReduxVideo from 'components/MultiVideoListener/ReduxVideo'
import { Status } from 'components/Status/Status'
import { useRobotStatus } from 'hooks'

import Styles from './PrismRobotViewWithStatus.module.scss'

interface Props {
  robotId: string
  hideStatus?: boolean
  className?: string
}

/**
 * Renders a container (for the video feed) with a camera status
 *
 * @param robotId - the robot id to get the current status
 * @param hideStatus - hides the status
 * @param className - string class
 */
const PrismRobotViewWithStatus = ({ robotId, hideStatus, className = '' }: Props) => {
  const status = useRobotStatus(robotId)

  return (
    <div className={`${Styles.containerSize} ${className}`}>
      <ReduxVideo element="transcoder-basler-image-thumbnail" stream="compressed" robotId={robotId} />

      {!hideStatus && (
        <span className={Styles.statusWrapper}>
          <Status
            showLabel={false}
            status={status}
            className={Styles.statusPosition}
            showLoadingCircle={status === 'loading'}
            loaderClassName={Styles.statusIsLoading}
          />
        </span>
      )}
    </div>
  )
}

export default PrismRobotViewWithStatus
