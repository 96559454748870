import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { debounce, groupBy, isNil, keyBy } from 'lodash'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { query } from 'react-redux-query'

import { getterKeys, InspectionsData, service, useQuery } from 'api'
import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import GridTableHeader from 'components/GridTableHeader/GridTableHeader'
import { PrismElementaryCube, PrismSearchIcon } from 'components/prismIcons'
import { PrismLoader, PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSearchInput from 'components/PrismSearchInput/PrismSearchInput'
import SkeletonWrapper from 'components/SkeletonWrapper/SkeletonWrapper'
import { Status } from 'components/Status/Status'
import { useData, useDateTimePreferences, useInspectionDurationRef, usePagination, useStateAndRef } from 'hooks'
import * as Actions from 'rdx/actions'
import { Inspection, Station, TimeSeriesResult } from 'types'
import {
  calculateMetricsCompactionParams,
  calculatePercentage,
  combineOutcomeCounts,
  getImageThumbnailFromRoutine,
  getterAddPage,
  renderLargeNumber,
} from 'utils'
import { MAX_RTS_METRICS_LABELS_DICTS, SECONDS_IN_DAY, STATION_DETAIL_OVERVIEW_METRICS_PREFIX } from 'utils/constants'

import Styles from './StationDetail.module.scss'

type BatchSelectorProps = {
  selectedInspectionId?: string
  robotIsRunning: boolean
  setSelectedInspectionId: (inspectionId?: string) => any
  onClose: () => any
  station: Station
}
/**
 * Renders a selector menu with data regarding the last 15 inspections run in the current station. This
 * componen must close itself when clicking outside of it
 *
 * @param selectedInspectionId - name of the currently selected inspection
 * @param station - Selected Station
 * @param robotIsRunning - Whether there's a robot running in the current station
 * @param onClose - Callback for when clicking outside of this
 * @param setSelectedInspectionId - Callback for when clicking on an inspection we want to select
 *
 */

const BatchSelector = ({
  station,
  robotIsRunning,
  onClose,
  selectedInspectionId,
  setSelectedInspectionId,
}: BatchSelectorProps) => {
  const dispatch = useDispatch()
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [searchValue, setSearchValue] = useState<string>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const { shortYearDateFormat } = useDateTimePreferences()

  useEffect(() => {
    const handler = () => {
      onClose()
    }

    // We need to do this so that the scheduled click event from the historic batch
    // button is not fired right after the listener is set
    setTimeout(() => window.addEventListener('click', handler))

    return () => {
      window.removeEventListener('click', handler)
    }
  }, [onClose])

  const handleSearchChange = useMemo(
    () =>
      debounce(async (val: string) => {
        await query(
          getterKeys.stationDetailInspections(station.id),
          () =>
            service.getInspections({
              page_size: 15,
              order_by: '-created_at,name',
              station_id: station.id,
              inspection_or_component_name: val,
            }),
          { dispatch },
        )

        setSearchValue(val)
      }, 400),
    [dispatch, station.id],
  )

  const { results: inspections, next } =
    useQuery(getterKeys.stationDetailInspections(station.id), () =>
      service.getInspections({
        page_size: 15,
        order_by: '-created_at,name',
        station_id: station.id,
      }),
    ).data?.data || {}

  const handleEndReached = useCallback(async () => {
    if (!next) return
    setIsLoadingMore(true)
    const nextPageRes = await service.getNextPage<InspectionsData>(next)

    if (nextPageRes.type === 'success') {
      dispatch(
        Actions.getterUpdate({
          key: getterKeys.stationDetailInspections(station.id),
          updater: prevRes => getterAddPage(prevRes, nextPageRes.data),
        }),
      )
    }
    setIsLoadingMore(false)
  }, [dispatch, next, station.id])

  usePagination(handleEndReached, {
    node: tableContainerRef.current?.querySelector('.ant-table-body') || null,
    overflowScroll: true,
  })

  const { metricsByInspection } = useHistoricBatchesMetrics(robotIsRunning, inspections)

  const columns = getColumns(robotIsRunning, shortYearDateFormat, !metricsByInspection)

  const inspectionsWithMetrics = useMemo(() => {
    if (!metricsByInspection) return
    // All this code is duplocated from Analyze Batches, we might want to do something about it
    const inspectionByInspectionId = keyBy(inspections, inspection => inspection.id)
    const groups = Object.entries(metricsByInspection)
      .map(([inspectionId, results]) => {
        const truncatedResults = results.filter(
          results => results.labels.inspection_id && results.labels.inspection_id in inspectionByInspectionId,
        )
        const combinedSeries = combineOutcomeCounts(truncatedResults)

        let count = 0
        let passed = 0

        for (const item of combinedSeries) {
          count += item.count || 0
          passed += item.pass || 0
        }
        const curYield = calculatePercentage(passed, count)

        const inspection = inspectionByInspectionId[inspectionId]

        return {
          ...inspection,
          itemsInspected: count,
          yield: curYield,
        } as InspectionWithMetrics
      })
      .sort((a, b) => ((a.created_at || '') < (b.created_at || '') ? 1 : -1))

    const groupedInspectionsWithMetrics = groups.filter(group => group.itemsInspected)

    // Live Inspections that haven't generated items aren't included in the metrics response, so we must manually add them
    if (robotIsRunning && inspections?.[0]?.ended_at === null) {
      const runningInspection = inspections?.[0]
      if (
        groupedInspectionsWithMetrics &&
        runningInspection &&
        groupedInspectionsWithMetrics[0]?.id !== runningInspection.id
      )
        groupedInspectionsWithMetrics.unshift({
          ...runningInspection,
          itemsInspected: 0,
          yield: 0,
        })
    }
    return groupedInspectionsWithMetrics
  }, [metricsByInspection, inspections, robotIsRunning])

  // Create a portal with react so that this component gets rendered at the root of the DOM.
  // This is important due to some issues regarding css stacking context.
  return ReactDOM.createPortal(
    <div
      data-testid="batch-selector-tooltip"
      onClick={e => e.stopPropagation()}
      className={Styles.batchTooltipContainer}
      ref={tableContainerRef}
    >
      <PrismSearchInput
        disabled={!inspections?.length && !searchValue}
        onInputChange={e => handleSearchChange(e.target.value)}
        placeholder="Search"
        size="small"
        className={Styles.searchField}
        showIconPrefix
      />

      <section className={Styles.tableWrapper}>
        <GridTableHeader
          columns={columns}
          size="small"
          className={`${Styles.tableHeader} ${
            inspections && inspectionsWithMetrics?.length === 0 ? Styles.hideHeaderTable : ''
          }`}
        />
        <Table
          dataSource={inspectionsWithMetrics}
          columns={columns}
          onRow={(inspection, idx) => ({
            onClick: () => {
              const inspectionIsRunning = robotIsRunning && idx === 0 && isNil(inspection.ended_at)

              setSelectedInspectionId(inspectionIsRunning ? undefined : inspection.id)
            },
            'data-testid': `historic-batch-${inspection.name}`,
            'data-test':
              robotIsRunning && idx === 0 && isNil(inspection.ended_at) ? 'historic-batch-running' : 'historic-batch',
          })}
          locale={{
            emptyText:
              inspections && inspectionsWithMetrics?.length === 0 ? (
                <GenericBlankStateMessage
                  className={Styles.noInspectionMessage}
                  header={<PrismSearchIcon />}
                  dataTestId="batch-selector-empty-state"
                  dataTestAttribute={searchValue ? 'empty-search' : 'empty-inspections'}
                  description={searchValue ? 'No batches match your search' : 'No batches have run on this station'}
                />
              ) : (
                <></>
              ),
          }}
          rowKey="id"
          pagination={false}
          sticky={{ offsetHeader: 0 }}
          rowClassName={inspection =>
            `${Styles.batchTableRow} ${inspection.id === selectedInspectionId ? Styles.selectedBatch : ''}`
          }
          loading={{
            spinning: inspections === undefined,
            indicator: <PrismLoader className={Styles.tableLoader} />,
          }}
          showHeader={false}
        />

        {isLoadingMore && <PrismLoader className={Styles.moreElementsLoader} />}
      </section>
    </div>,
    document.body,
  )
}

interface InspectionWithMetrics extends Inspection {
  yield: number
  itemsInspected: number
}

const getColumns = (
  robotIsRunning: boolean,
  shortYearDateFormat: 'DD/MM/YY' | 'MM/DD/YY',
  isFetchingMetrics: boolean,
): ColumnsType<InspectionWithMetrics> => {
  return [
    {
      title: 'Batches',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: '80px',
      render: (_, record) => {
        const image = getImageThumbnailFromRoutine(record.routines?.[0])
        return !image ? (
          <PrismElementaryCube addBackground />
        ) : (
          <img src={image} alt="" className={Styles.batchImageContainer} />
        )
      },
    },
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (_, record) => (
        <div className={Styles.batchWrapper}>
          <PrismOverflowTooltip
            data-testid={`historic-batch-${record.name}-name`}
            content={record.name}
            className={Styles.batchLabel}
          />
          <PrismOverflowTooltip content={record.component.name} className={Styles.batchValue} />
        </div>
      ),
    },
    {
      title: 'Yield',
      dataIndex: 'yield',
      key: 'yield',
      width: '90px',
      render: (_, record) => (
        <SkeletonWrapper loading={isFetchingMetrics} datatestId={`historic-batch-${record.name}-yield`}>
          {record.yield.toFixed(1)}%
        </SkeletonWrapper>
      ),
    },
    {
      title: 'Count',
      dataIndex: 'itemsInspected',
      key: 'itemsInspected',
      width: '82px',
      render: (_, record) => (
        <div data-testid={`historic-batch-${record.name}-count`}>
          {isFetchingMetrics ? <PrismSkeleton /> : renderLargeNumber(record.itemsInspected, 1000)}
        </div>
      ),
    },
    {
      title: 'Ended',
      dataIndex: 'ended_at',
      key: 'ended_at',
      width: '114px',
      render: (_, inspection, idx) => {
        const inspectionIsRunning = robotIsRunning && idx === 0 && isNil(inspection.ended_at)
        if (inspectionIsRunning)
          return <Status data-testid={`historic-batch-${inspection.name}-end-date`} status="running" />

        const endDate = inspection.ended_at && moment(inspection.ended_at).format(shortYearDateFormat)
        return <span data-testid={`historic-batch-${inspection.name}-end-date`}>{endDate || '--'}</span>
      },
    },
  ]
}

export default BatchSelector

// This hook is just in charge of getting item metrics for the current running inspection
const useRunningInspectionMetrics = (robotRunning: boolean, inspections?: Inspection[]) => {
  const runningInspection = useMemo(() => {
    if (!robotRunning || inspections?.[0]?.ended_at !== null) return

    return inspections?.[0]
  }, [inspections, robotRunning])

  const { inspectionDurationMsRef } = useInspectionDurationRef(runningInspection)

  const runningInspectionMetricsCompaction = useMemo(() => {
    if (!runningInspection) return
    const { metricsCompaction } = calculateMetricsCompactionParams(inspectionDurationMsRef.current, {
      isHistoricBatch: false,
    })

    return metricsCompaction
  }, [inspectionDurationMsRef, runningInspection])

  const itemMetricsKey =
    runningInspection && runningInspectionMetricsCompaction
      ? getterKeys.rtsMetrics(
          'items',
          runningInspection.id,
          runningInspectionMetricsCompaction,
          STATION_DETAIL_OVERVIEW_METRICS_PREFIX,
        )
      : undefined

  // Fetched from StationDeatilOverview
  const runningInspectionItemsData = useData(itemMetricsKey)?.results

  return { runningInspectionItemsData, runningInspection }
}

const useHistoricBatchesMetrics = (robotRunning: boolean, inspections?: Inspection[]) => {
  const dispatch = useDispatch()

  const metricsByInspectionData = useData(getterKeys.inspectionsMetricsByInspectionId('items'))
  const [fetchingMetrics, setFetchingMetrics, fetchingMetricsRef] = useStateAndRef(false)

  const { runningInspectionItemsData, runningInspection } = useRunningInspectionMetrics(robotRunning, inspections)

  useEffect(() => {
    if (!inspections || fetchingMetricsRef.current) return

    const missingMetricsInspectionIds = inspections
      .filter(inspection => !metricsByInspectionData?.[inspection.id])
      .map(inspection => inspection.id)

    if (!missingMetricsInspectionIds.length) {
      // Set inital empty data if there aren't inspections needed
      if (!metricsByInspectionData) {
        dispatch(
          Actions.getterUpdate({
            key: getterKeys.inspectionsMetricsByInspectionId('items'),
            updater: prev => {
              return {
                ...prev,
                data: {},
              }
            },
          }),
        )
      }

      return
    }

    const fetchInspections = async () => {
      if (fetchingMetricsRef.current) return
      setFetchingMetrics(true)
      const inspectionsChunks = []

      for (let idx = 0; idx < missingMetricsInspectionIds.length; idx += MAX_RTS_METRICS_LABELS_DICTS) {
        const chunk = missingMetricsInspectionIds.slice(idx, idx + MAX_RTS_METRICS_LABELS_DICTS)
        inspectionsChunks.push(chunk)
      }

      const allResponses = await Promise.allSettled(
        inspectionsChunks.map(chunk => {
          return service.readTimeSeries({
            series_type: 'item_outcome_inspection',
            bucket_s: 1800,
            agg_s: SECONDS_IN_DAY,
            labels: chunk?.map(inspectionId => ({ inspection_id: inspectionId })),
          })
        }),
      )

      const allResults = allResponses.flatMap(response => {
        if (response.status !== 'fulfilled' || response.value.type !== 'success') {
          return []
        }

        return response.value.data.results
      })

      const groupedResults = groupBy(allResults, result => result.labels.inspection_id)

      const newMetricsByInspection = missingMetricsInspectionIds.reduce((all, inspectionId) => {
        const results = groupedResults[inspectionId] || []
        return { ...all, [inspectionId]: results }
      }, {} as Record<string, TimeSeriesResult[]>)

      dispatch(
        Actions.getterUpdate({
          key: getterKeys.inspectionsMetricsByInspectionId('items'),
          updater: prev => {
            return {
              ...prev,
              data: { ...prev?.data, ...newMetricsByInspection },
            }
          },
        }),
      )

      setFetchingMetrics(false)
    }

    fetchInspections()
  }, [dispatch, fetchingMetricsRef, inspections, metricsByInspectionData, setFetchingMetrics])

  const metricsByInspection = useMemo(() => {
    const toReturn = { ...metricsByInspectionData }

    // Override fetched metrics for running inspection by the ones from StationDetail
    if (runningInspection && runningInspectionItemsData) {
      toReturn[runningInspection.id] = runningInspectionItemsData
    }

    return toReturn
  }, [metricsByInspectionData, runningInspection, runningInspectionItemsData])

  return { metricsByInspection, fetchingMetrics }
}
