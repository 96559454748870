import React, { useEffect } from 'react'

import qs from 'qs'
import { useHistory } from 'react-router-dom'

import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import { useQueryParams, useTypedSelector } from 'hooks'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { LocationHistory } from 'types'
import { appendDataToQueryString, searchLocationHistory } from 'utils'

import Styles from './StationDetailOverview.module.scss'

const TIME_FRAME_OPTIONS = ['batch', '5m', '1h', '4h'] as const

type TimeFrameOption = (typeof TIME_FRAME_OPTIONS)[number]

/**
 * Renders the left side panel of station detail overview. Handles the state for selecting the timeframe
 * and the container with metrics and charts
 *
 * @param children - Children to render in container
 * @param isHistoricBatch - Whether we're displaying a historical batch or not
 */
export const TimeFrame = ({
  children,
  isHistoricBatch,
  className,
}: {
  children: React.ReactNode
  isHistoricBatch: boolean
  className?: string
}) => {
  const locationHistory = useTypedSelector(state => state.locationHistory)
  const [params] = useQueryParams()
  const history = useHistory()
  const timeFrame = params.timeFrame as TimeFrameOption

  const updateTimeFrame = (timeFrame: TimeFrameOption) => {
    appendDataToQueryString(history, { timeFrame })
  }

  useEffect(() => {
    if (isHistoricBatch && timeFrame !== 'batch') {
      return updateTimeFrame('batch')
    }
    if (timeFrame) return
    const lastTimeFrame = getLastSelectedTimeframe(locationHistory)
    updateTimeFrame(lastTimeFrame)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search])

  return (
    <div className={`${Styles.timeFrameContainer} ${className ?? ''}`}>
      <div className={Styles.timeFrameHeader}>
        <PrismToggleGroup
          data-testid="time-frame"
          className={Styles.toggleGroupContainer}
          options={[
            { label: 'batch', type: 'default', value: 'batch', dataTestId: 'time-frame-batch' },
            { label: 'last 5m', type: 'default', value: '5m', dataTestId: 'time-frame-5m' },
            { label: '1h', type: 'default', value: '1h', dataTestId: 'time-frame-1h' },
            { label: '4h', type: 'default', value: '4h', dataTestId: 'time-frame-4h' },
          ]}
          onChange={updateTimeFrame}
          value={timeFrame}
          optionClassName={Styles.toggleOption}
          disabled={isHistoricBatch}
          size="auto"
        />
      </div>
      <div className={`${Styles.timeFrameBody} ${Shared.verticalChildrenGap16}`}>{children}</div>
    </div>
  )
}

/**
 * Function to retrieve the last selected timeframe from history
 * @param locationHistory - location history to search in
 * @returns the last selected time frame option
 */
const getLastSelectedTimeframe = (locationHistory: LocationHistory): TimeFrameOption => {
  const lastStationOverviewPath = searchLocationHistory(
    locationHistory.history,
    historyEntry =>
      historyEntry.search.includes('timeFrame') && historyEntry.pathname.includes(paths.stationDetail('overview')),
  )
  if (lastStationOverviewPath) {
    const params = qs.parse(lastStationOverviewPath.search, { ignoreQueryPrefix: true })
    if (
      params.timeFrame &&
      typeof params.timeFrame === 'string' &&
      (TIME_FRAME_OPTIONS as ReadonlyArray<string>).includes(params.timeFrame)
    )
      return params.timeFrame as TimeFrameOption
  }
  return 'batch'
}
