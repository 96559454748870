import React, { useMemo } from 'react'

import { PrismSkeleton } from 'components/PrismLoaders/PrismLoaders'
import { CameraStatus, ConnectionStatus } from 'types'
import { STATUS_LABELS } from 'utils/constants'

import Styles from './StackLight.module.scss'

interface StackLightProps {
  isManageMode: boolean
  isStationLoading: boolean
  isHistoricBatch: boolean
  stationStatus: CameraStatus
  connectionStatus: ConnectionStatus
  itemsSyncing: boolean
}

/**
 * Renders the StackLight for the sidebar Status
 *
 * @param isManageMode - change the looks of the stacklight and show the `Managing Station` text
 * @param isStationLoading - Whether the robot is loading or not
 * @param isHistoricBatch - Whether we're displaying a historical batch or not
 * @param stationStatus - The different camera status
 * @param connectionStatus - The different connection status
 * @param itemsSyncing - Whether items are Syncing or not
 *
 */
const StackLight = ({
  isManageMode,
  isStationLoading,
  isHistoricBatch,
  stationStatus,
  connectionStatus,
  itemsSyncing,
}: StackLightProps) => {
  const statusClassName = useMemo(() => {
    if (isHistoricBatch) return ''
    if (stationStatus === 'running') {
      if (connectionStatus === 'offline') return Styles.offlineStatus
      return Styles.runningStatus
    }

    if (stationStatus === 'disconnected') return Styles.offlineStatus
    return ''
  }, [isHistoricBatch, connectionStatus, stationStatus])

  return (
    <div className={`${Styles.statusWrapper} ${statusClassName || Styles.idleStatus}`}>
      <div
        className={`${Styles.statusContainer} ${isManageMode ? Styles.managingMode : ''}`}
        data-testid={`${!isStationLoading && connectionStatus === 'online' ? 'sd-sidebar-status-online' : ''}`}
      >
        {isStationLoading && <PrismSkeleton lighterShade />}

        {!isStationLoading && <span className={Styles.statusEllipse} />}

        {!isStationLoading && !isHistoricBatch && STATUS_LABELS[stationStatus]}

        {!isStationLoading && isHistoricBatch && 'finished'}

        {!isStationLoading && connectionStatus === 'offline' && stationStatus === 'running' && (
          <div
            className={`${Styles.systemConnectionWrapper} ${Styles.offlineRunning} ${
              isManageMode ? Styles.isManageMode : ''
            } `}
          >
            Inspection running offline
          </div>
        )}

        {!isStationLoading && connectionStatus === 'online' && itemsSyncing && (
          <div className={`${Styles.systemConnectionWrapper} ${Styles.onlineRunning}`}>Metrics Delayed</div>
        )}
      </div>
      <div className={`${Styles.statusManagingMode} ${isManageMode ? Styles.show : ''}`}>Managing Station</div>
    </div>
  )
}

export default StackLight
