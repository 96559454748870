import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FileRejection, useDropzone } from 'react-dropzone'

import { PrismElementaryCube, PrismErrorIcon, PrismUploadIcon } from 'components/prismIcons'
import { Modal } from 'components/PrismModal/PrismModal'
import { BetaTag } from 'components/Tag/Tag'
import { FileCustomError } from 'types'
import { createCompressedJpegFromPngFile } from 'utils'
import {
  MAX_IMAGE_UPLOAD_HEIGHT,
  MAX_IMAGE_UPLOAD_WIDTH,
  MIN_IMAGE_UPLOAD_HEIGHT,
  MIN_IMAGE_UPLOAD_WIDTH,
} from 'utils/constants'

import Styles from './UploadModal.module.scss'

const TIMEOUT = 5000

const imageUploadErrorMessages = {
  'file-too-large': `The maximum image size is ${MAX_IMAGE_UPLOAD_WIDTH} by ${MAX_IMAGE_UPLOAD_HEIGHT} pixels`,
  'file-too-small': `The minimum image size is ${MIN_IMAGE_UPLOAD_WIDTH} by ${MIN_IMAGE_UPLOAD_HEIGHT} pixels`,
  'too-many-files': 'Only one image can be uploaded as a reference',
  'file-invalid-type': 'Only JPG and PNG files are supported',
  'reference-image-mismatch': 'Image dimensions must match reference image',
}

const errorOrdering = {
  undefined: -1,
  'file-too-large': 0,
  'file-too-small': 0,
  'reference-image-mismatch': 0,
  'file-invalid-type': 1,
  'too-many-files': 2,
}

type Props = {
  onClose: () => any
  title: string
  multiple?: boolean
  onUpload: (files: File[]) => any
  referenceImageUrl?: string
}

/**
 * Renders an uploading modal with a dropzone. This modal only allows png and jpg upload.
 *
 * @param onClose - Handler for when user decides to close the modal
 * @param title - The title of the modal
 * @param multiple - Whether to allow only a single image upload or multiple
 * @param onUpload - Handler for when the user clicks on upload
 *
 * Business Rules: Jpgs are the prefered format for upload as they are usually more lightweight.
 * For this reason, this uploader also converts all pngs into compressed jpgs.
 */
function UploadModal({ onClose, title, multiple = false, onUpload, referenceImageUrl }: Props) {
  const [imagesToUpload, setImagesToUpload] = useState<{ file: File; selected: boolean }[]>([])
  const referenceImageDimensionsRef = useRef<[number, number]>([0, 0])
  const errorTimeoutRef = useRef<number>()

  useEffect(() => {
    const fn = async () => {
      if (!referenceImageUrl) return
      referenceImageDimensionsRef.current = await loadImageFromUrl(referenceImageUrl)
    }

    fn()
  }, [referenceImageUrl])

  const [referenceImageWidth, referenceImageHeight] = referenceImageDimensionsRef.current

  const filteredImages = useMemo(() => imagesToUpload?.filter(img => img.selected), [imagesToUpload])
  const [fileError, setFileError] = useState<FileCustomError>(null)
  const [errorTimestamp, setErrorTimestamp] = useState(Date.now())

  useEffect(() => {
    if (!fileError) return

    window.clearTimeout(errorTimeoutRef.current)

    errorTimeoutRef.current = window.setTimeout(() => {
      setFileError(null)
    }, TIMEOUT)

    return () => {
      window.clearTimeout(errorTimeoutRef.current)
    }
  }, [fileError, errorTimestamp])

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejections: FileRejection[]) => {
      // Unfortunately, react-dropzone's validator doesn't support async validation, so we need to validate the
      // size of the images in this callback, and filter out any images that don't meet the requirements

      const fileErrors = await getFileErrors({
        acceptedFiles,
        referenceImageUrl,
        referenceImageWidth,
        referenceImageHeight,
      })

      const errors = rejections.flatMap(rj => rj.errors.flatMap(err => err.code))

      const mostImportantError = getMostImportantError(fileErrors, errors)

      setFileError(mostImportantError)

      // We need to add a timestamp to state to forcefully restart the timer for removing the error message
      setErrorTimestamp(Date.now())

      if (!fileError)
        for (const error of fileErrors) {
          if (!error) continue
          setFileError(error)
          break
        }

      const mappedFiles = acceptedFiles.filter((file, idx) => !fileErrors[idx])

      let copyOfImages = []

      const newFiles: { file: File; selected: boolean }[] = await Promise.all(
        mappedFiles.map(async (file: File) => {
          if (file.type === 'image/png') {
            file = await createCompressedJpegFromPngFile(file)
          }
          return { file, selected: true }
        }),
      )

      // Add more images to the list if we're in multiple upload mode, otherwise, replace the selected
      // image
      if (multiple) copyOfImages = [...imagesToUpload, ...newFiles]
      else copyOfImages = newFiles

      setImagesToUpload(copyOfImages)
    },
    [fileError, imagesToUpload, multiple, referenceImageHeight, referenceImageUrl, referenceImageWidth],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: !multiple ? 1 : 1000,
    multiple: multiple,
    accept: 'image/jpeg, image/png',
  })

  const handleSelectToggle = (e: React.MouseEvent<HTMLElement, MouseEvent>, idx: number) => {
    if (!imagesToUpload) return
    e.stopPropagation()
    e.preventDefault()

    const copyOfImages = [...imagesToUpload]
    const copyOfImagesByIdx = copyOfImages[idx]
    if (copyOfImagesByIdx) {
      copyOfImages[idx] = {
        ...copyOfImagesByIdx,
        selected: !copyOfImagesByIdx.selected,
      }
    }

    setImagesToUpload(copyOfImages)
  }

  const handleUpload = () => {
    onUpload(filteredImages.map(img => img.file))
  }

  return (
    <Modal
      id="upload-modal"
      className={Styles.uploadModalWrapper}
      size="large"
      header={title}
      extraButtons={
        <div className={Styles.extraButton}>
          {!imagesToUpload?.length ? <BetaTag /> : `${filteredImages.length} / ${imagesToUpload?.length} selected`}
        </div>
      }
      onClose={onClose}
      onOk={handleUpload}
      disableSave={!filteredImages.length}
      okText="Upload"
    >
      <div
        {...getRootProps()}
        className={`${Styles.uploadInputContainer} ${fileError ? Styles.uploadErrorContainer : ''}`}
      >
        <input {...getInputProps()} />

        {(!imagesToUpload || !imagesToUpload.length || fileError) && (
          <>
            <div className={isDragActive ? Styles.dragContainer : Styles.uploadImagesMessage}>
              {!fileError && (
                <PrismUploadIcon
                  className={`${Styles.iconSize} ${Styles.uploadIcon} ${isDragActive ? Styles.dragHover : ''}`}
                />
              )}

              {fileError && <PrismErrorIcon isActive className={Styles.iconSize} />}

              <p className={`${Styles.uploadMessage} ${isDragActive ? Styles.dragFiles : ''}`}>
                {!fileError && isDragActive && 'Drop the files here ...'}

                {!fileError &&
                  !isDragActive &&
                  multiple &&
                  'Drag up to 1,000 images or click here to browse your device'}

                {!fileError && !isDragActive && !multiple && 'Drag 1 image here or click to browse your device'}

                {fileError && imageUploadErrorMessages[fileError]}
              </p>
              <p className={`${Styles.imageTypes} ${isDragActive ? Styles.dragFiles : ''}`}>
                {!fileError &&
                  `JPG or PNG. ${
                    multiple
                      ? 'Image Dimensions must match reference.'
                      : `Max ${MAX_IMAGE_UPLOAD_WIDTH} by ${MAX_IMAGE_UPLOAD_HEIGHT} Pixels.`
                  }`}

                {fileError && fileError !== 'reference-image-mismatch' && 'Please try again'}
                {fileError === 'reference-image-mismatch' &&
                  `Try with ${referenceImageWidth} by ${referenceImageHeight} pixel images`}
              </p>
            </div>
          </>
        )}

        {!fileError && imagesToUpload && imagesToUpload.length !== 0 && !multiple && (
          <div className={Styles.mainImagesWrapper}>
            <div className={Styles.singleWrapper}>
              <figure
                onClick={e => handleSelectToggle(e, 0)}
                className={`${Styles.imageContainer} ${
                  !imagesToUpload[0]?.selected ? Styles.imageContainerDisabled : ''
                }`}
              >
                {!imagesToUpload[0]?.selected ? <div className={Styles.imageOverlay}></div> : ''}
                {imagesToUpload[0] && <FileImageRenderer file={imagesToUpload[0].file} />}
              </figure>
            </div>
          </div>
        )}

        {!fileError && imagesToUpload && imagesToUpload.length !== 0 && multiple && (
          <div className={Styles.mainImagesWrapper}>
            <div className={Styles.multipleWrapper}>
              {imagesToUpload.map((imgObject, key) => {
                return (
                  <figure
                    key={key}
                    onClick={e => handleSelectToggle(e, key)}
                    className={`${Styles.imageContainer} ${!imgObject.selected ? Styles.imageContainerDisabled : ''}`}
                  >
                    {!imgObject.selected ? <div className={Styles.imageOverlay}></div> : ''}
                    <FileImageRenderer file={imgObject.file} />
                  </figure>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

/**
 * Renders an image from a given file, if not able to load the image, render the elementary cube image.
 *
 * @param file - the file to render
 * @param className - className of the image element
 *
 */
export const FileImageRenderer = ({ file, className = '' }: { file: File; className?: string }) => {
  const [imageSrc, setImageSrc] = useState<string>()
  const reader = useMemo(() => new FileReader(), [])

  useEffect(() => {
    reader.onloadend = function () {
      if (typeof reader.result === 'string') setImageSrc(reader.result || '')
    }

    reader.readAsDataURL(file)
  }, [file, reader])

  if (!imageSrc) return <PrismElementaryCube className={className} />

  return <img className={className} src={imageSrc} alt={file.name} />
}

export default UploadModal

const loadImageFromUrl = async (url: string) => {
  return new Promise<[number, number]>((resolve, reject) => {
    const img = new Image()
    // convert image file to base64 string
    img.onload = () => {
      resolve([img.width, img.height])
    }
    img.onerror = () => {
      reject()
    }
    img.src = url
  })
}

const loadImageFromFile = async (file: File) => {
  const reader = new FileReader()
  return new Promise<[number, number]>((resolve, reject) => {
    reader.addEventListener(
      'load',
      () => {
        if (!reader.result || typeof reader.result !== 'string') return
        loadImageFromUrl(reader.result)
          .then(val => resolve(val))
          .catch(val => reject(val))
      },
      false,
    )

    reader.readAsDataURL(file)
  })
}

export const getFileErrors = ({
  acceptedFiles,
  referenceImageUrl,
  referenceImageWidth,
  referenceImageHeight,
}: {
  acceptedFiles: File[]
  referenceImageUrl?: string
  referenceImageWidth?: number
  referenceImageHeight?: number
}) => {
  return Promise.all(
    // We use this syntax so that we process all files asynchronously
    acceptedFiles.map(async (file: File) => {
      const [width, height] = await loadImageFromFile(file)
      if (referenceImageUrl) {
        // If we have reference image data, the dimensions must match
        if (width !== referenceImageWidth || height !== referenceImageHeight) return 'reference-image-mismatch'
      } else {
        // Otherwise, we make sure the image doesn't go outside of the required threshold
        if (width > MAX_IMAGE_UPLOAD_WIDTH || height > MAX_IMAGE_UPLOAD_HEIGHT) {
          return 'file-too-large'
        }

        if (width < MIN_IMAGE_UPLOAD_WIDTH || height < MIN_IMAGE_UPLOAD_HEIGHT) {
          return 'file-too-small'
        }
      }
      return null
    }),
  )
}

export const getMostImportantError = (fileErrors: FileCustomError[], dropzoneErrors: string[]) => {
  let mostImportantError: FileCustomError = null

  for (const error of [...dropzoneErrors, ...fileErrors]) {
    // The react-dropzone library supports any string as an error, but we only want to handle these 5.
    // We can't do Array.includes(error) since we also want to reduce the type of error to match the
    // type of `mostImportantError` which only considers those 5 errors
    if (
      error !== 'file-too-large' &&
      error !== 'file-too-small' &&
      error !== 'too-many-files' &&
      error !== 'file-invalid-type' &&
      error !== 'reference-image-mismatch'
    )
      continue

    if (!mostImportantError) {
      mostImportantError = error
      continue
    }

    if (errorOrdering[error] > errorOrdering[mostImportantError]) mostImportantError = error
  }

  return mostImportantError
}
