import React from 'react'

import { Control, Controller } from 'react-hook-form'

import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { Token } from 'components/Token/Token'
import { getSessionLengthSecondsFromFormValues } from 'utils'
import { MAX_SESSION_LENGTH, SESSION_LENGTH_TIME_OPTIONS } from 'utils/constants'

import Styles from './SessionExpiration.module.scss'

interface Props {
  formProps: {
    errors: any
    control: Control
    inputTrigger: () => Promise<boolean>
    selectTrigger: () => Promise<boolean>
    getValues: () => {
      sessionLengthUnits: 'day' | 'hour' | 'minute'
      sessionLengthTime: number
    }
  }
  sessionLengthCaption?: string
  className?: string
  labelClassName?: string
  disabled?: boolean
}

/**
 * Renders the session expiration component
 * Includes an Input to add numbers and a select for time units
 *
 *
 * @param getValues - Reads the form values
 * @param control - contains methods for registering components into React Hook Form
 * @param errors - Error displayed below the input container
 * @param inputTrigger: useful when you have dependant validation
 * @param selectTrigger: useful when you have dependant validation
 * @param sessionLengthCaption - adds a specific caption to warn the user about the used data
 * @param className - string for additional styling
 */
const SessionExpiration = ({
  formProps,
  sessionLengthCaption,
  disabled,
  className = '',
  labelClassName = '',
}: Props) => {
  const validateSessionLength = () => {
    const { sessionLengthUnits, sessionLengthTime } = formProps.getValues()
    const sessionLengthS = getSessionLengthSecondsFromFormValues({ sessionLengthUnits, sessionLengthTime })
    if (sessionLengthTime < 1 || (sessionLengthUnits === 'minute' && sessionLengthTime < 15))
      return 'Minimum length is 15 minutes'
    if (sessionLengthS >= MAX_SESSION_LENGTH) return 'Maximum length is one year'
  }

  return (
    <div className={`${Styles.sessionExpirationContainer} ${className}`}>
      <Token label="Session length" labelClassName={labelClassName} valueClassName={Styles.sessionExpirationFilters}>
        <Controller
          name="sessionLengthTime"
          rules={{
            required: true,
            validate: () => validateSessionLength(),
          }}
          control={formProps.control}
          render={props => (
            <>
              <PrismInputNumber
                {...props}
                onChange={value => {
                  props.onChange(value || 0)
                  formProps.inputTrigger()
                }}
                errors={formProps.errors}
                data-testid="session-length-input"
                type="number"
                disabled={disabled}
              />
            </>
          )}
        />
        <Controller
          name="sessionLengthUnits"
          control={formProps.control}
          rules={{
            required: true,
            validate: () => validateSessionLength(),
          }}
          render={props => (
            <PrismSelect
              {...props}
              onChange={value => {
                props.onChange(value)
                formProps.selectTrigger()
              }}
              data-testid="session-length-unit"
              size="large"
              disabled={disabled}
              options={SESSION_LENGTH_TIME_OPTIONS}
            />
          )}
        />
      </Token>

      {!!sessionLengthCaption && !formProps.errors.sessionLengthTime && !formProps.errors.sessionLengthUnits && (
        <p className={Styles.sessionExpirationCaption}>{sessionLengthCaption}</p>
      )}
    </div>
  )
}

export default SessionExpiration
