import React, { useMemo } from 'react'

import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'

import { getAllPages, getterKeys, query, service } from 'api'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import RobotsStatusListener from 'components/RobotsStatusListener'
import { useQueryEventTypesSubsAndTemplates, useQueryParams, useStationsWithStatus } from 'hooks'
import { Component, EventSub, EventType, Station, subscriptionTabs, subscriptionTabsType } from 'types'

import SubscriptionsBody from './SubscriptionsBody'

const stationsKey = getterKeys.stations('subscribed')
const componentsKey = getterKeys.components('subscribed')
/**
 * Renders Subscription Screen
 * The frontend will use `EventSubTargetTemplates` to render the subscribed station/products.
 * To get the unsuscribed station/product we compare all station/products to the ones in
 * the `EventSubTargetTemplates`
 *
 * Assumptions:
 * - For E&N v1 every `EventSub` will have the same `EventSub.type_id` across all different `EventSub.targets`
 *
 */
const Subscriptions = () => {
  // We fetch all stations/products to compare them against the `EventSubTargetTemplates`. If the station/product is present on these templates it means that the user is subscribed to it
  const stations = useQuery(stationsKey, () => getAllPages(service.getStations({ has_robots: true }))).data?.data
    .results
  // We also fetch all components since we will need them in the SubscriptionsBody component
  const components = useQuery(componentsKey, () => getAllPages(service.getComponents({ is_deleted: false }))).data?.data
    .results
  const { eventSubTargetTemplates, userEventSubs, eventSubTypeTemplates, defaultEvents } =
    useQueryEventTypesSubsAndTemplates()
  const [params] = useQueryParams()

  const robotIds = useMemo(() => stations?.flatMap(station => station.robots.map(robot => robot.id)), [stations])
  const subscriptionTab: subscriptionTabsType = subscriptionTabs.includes(params.tab || '')
    ? (params.tab as subscriptionTabsType)
    : 'stations'

  return (
    <>
      {robotIds && <RobotsStatusListener robotIds={robotIds} />}
      {robotIds && (
        <MultiVideoListener element="transcoder-basler-image-thumbnail" stream="compressed" robotIds={robotIds} />
      )}
      {subscriptionTab === 'stations' && (
        <StationSubscriptions
          stations={stations}
          components={components}
          eventSubTargetTemplates={eventSubTargetTemplates}
          userEventSubs={userEventSubs}
          eventSubTypeTemplates={eventSubTypeTemplates}
          defaultEventsTypes={defaultEvents}
        />
      )}
      {subscriptionTab === 'products' && (
        <ComponentSubscriptions
          stations={stations}
          components={components}
          userEventSubs={userEventSubs}
          eventSubTypeTemplates={eventSubTypeTemplates}
          eventSubTargetTemplates={eventSubTargetTemplates}
          defaultEventsTypes={defaultEvents}
        />
      )}
    </>
  )
}

type subscriptionTabProps = {
  userEventSubs: EventSub[] | undefined
  eventSubTypeTemplates: EventSub[] | undefined
  eventSubTargetTemplates: EventSub[] | undefined
  defaultEventsTypes: EventType[] | undefined
  stations: Station[] | undefined
  components: Component[] | undefined
}

export function StationSubscriptions({
  userEventSubs,
  eventSubTypeTemplates,
  eventSubTargetTemplates,
  defaultEventsTypes,
  stations,
  components,
}: subscriptionTabProps) {
  const dispatch = useDispatch()

  const stationNotifCounts = useQuery(getterKeys.notificationCounts('station'), () =>
    service.countNotifications({
      group_by: 'event__meta__targets__station_ids',
      start: moment().subtract(1, 'day').toISOString(),
    }),
  ).data?.data.results

  const { stationsWithStatus } = useStationsWithStatus(stations || [])

  const subscribedStations = useMemo(() => {
    const subscribedStationIds = eventSubTargetTemplates
      ?.filter(eventSub => {
        return !!eventSub.targets.station_id
      })
      .map<string>(eventsub => eventsub.targets.station_id!)

    return stationsWithStatus.filter(station => {
      return subscribedStationIds?.includes(station.id)
    })
  }, [eventSubTargetTemplates, stationsWithStatus])

  async function refetchData(searchValue?: string) {
    await query(stationsKey, () => getAllPages(service.getStations({ search: searchValue, has_robots: true })), {
      dispatch,
    })
  }

  return (
    <>
      <SubscriptionsBody
        subscriptionTab="stations"
        subscriptionList={subscribedStations}
        notificationCounts={stationNotifCounts}
        stations={stations}
        components={components}
        eventSubTargetTemplates={eventSubTargetTemplates}
        subscriptionRefetchData={refetchData}
        userEventSubs={userEventSubs}
        eventSubTypeTemplates={eventSubTypeTemplates}
        defaultEventTypes={defaultEventsTypes}
      />
    </>
  )
}

export function ComponentSubscriptions({
  userEventSubs,
  eventSubTypeTemplates,
  eventSubTargetTemplates,
  defaultEventsTypes,
  stations,
  components,
}: subscriptionTabProps) {
  const dispatch = useDispatch()

  const componentNotifCounts = useQuery(getterKeys.notificationCounts('component'), () =>
    service.countNotifications({
      group_by: 'event__meta__targets__component_ids',
      start: moment().subtract(1, 'day').toISOString(),
    }),
  ).data?.data.results

  const subscribedComponents = useMemo(() => {
    const subscribedComponentIds = eventSubTargetTemplates
      ?.filter(eventSub => {
        return !!eventSub.targets.component_id
      })
      .map<string>(eventsub => eventsub.targets.component_id!)

    return components?.filter(component => {
      return subscribedComponentIds?.includes(component.id)
    })
  }, [components, eventSubTargetTemplates])

  async function refetchData(searchValue?: string) {
    await query(componentsKey, () => getAllPages(service.getComponents({ name: searchValue, is_deleted: false })), {
      dispatch,
    })
  }

  return (
    <>
      <SubscriptionsBody
        stations={stations}
        components={components}
        subscriptionTab="products"
        subscriptionList={subscribedComponents}
        notificationCounts={componentNotifCounts}
        userEventSubs={userEventSubs}
        eventSubTypeTemplates={eventSubTypeTemplates}
        eventSubTargetTemplates={eventSubTargetTemplates}
        defaultEventTypes={defaultEventsTypes}
        subscriptionRefetchData={refetchData}
      />
    </>
  )
}

export default Subscriptions
