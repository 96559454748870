import React, { useState } from 'react'

import { Button } from 'components/Button/Button'
import EntityButton from 'components/EntityButton/EntityButton'
import OptionMenu from 'components/OptionMenu/OptionMenu'
import { PrismLayout } from 'components/PrismLayout/PrismLayout'
import { PrismTabNav } from 'components/PrismTab/PrismTab'
import paths from 'paths'
import { InspectTabs, Site } from 'types'
import { matchRole } from 'utils'

import Styles from './Inspect.module.scss'
import InspectProducts from './InspectProducts/InspectProducts'
import InspectSites from './InspectSites'

const Inspect = ({ inspectTab }: { inspectTab: InspectTabs }) => {
  const [showArchive, setShowArchive] = useState(false)

  const [selectedSite, onSelectedSiteChange] = useState<Site>()
  const tabs = [{ label: 'sites', path: paths.inspect({ mode: 'site' }), 'data-testid': 'inspect-site-tab' }]
  if (matchRole('manager'))
    tabs.push({
      label: 'products',
      path: paths.inspect({ mode: 'product' }),
      'data-testid': 'inspect-product-tab',
    })

  const options = showArchive
    ? { title: 'Close Archive', value: 'close_archive' }
    : { title: 'View Archive', value: 'view_archive' }

  return (
    <PrismLayout
      breadcrumbs={[{ label: 'Inspect' }]}
      menuItems={
        <div className={Styles.headerButtons}>
          {!showArchive && (
            <>
              <OptionMenu
                options={[options]}
                onMenuItemClick={() => setShowArchive(!showArchive)}
                openWithClick
                iconButtonType="tertiary"
              />
              {matchRole('manager') && !showArchive && (
                <EntityButton siteId={selectedSite?.id} menuPosition="bottomLeft" />
              )}
            </>
          )}
          {showArchive && (
            <Button
              data-testid="close-archive-button"
              type="secondary"
              size="small"
              onClick={() => setShowArchive(!showArchive)}
            >
              close archive
            </Button>
          )}
        </div>
      }
      tabs={<PrismTabNav items={tabs} className={Styles.inspectTabNav} />}
    >
      {inspectTab === 'site' && (
        <InspectSites showArchive={showArchive} setSelectedSite={newSite => onSelectedSiteChange(newSite)} />
      )}
      {inspectTab === 'product' && matchRole('manager') && <InspectProducts showArchive={showArchive} />}
    </PrismLayout>
  )
}

export default Inspect
