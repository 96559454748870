import React, { RefObject, useEffect } from 'react'

import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Rect as RectType } from 'konva/lib/shapes/Rect'
import { Layer, Rect, Transformer } from 'react-konva'

import { useIsFlourish } from 'hooks'
import { Box, BoxWithShape, RectangleShapeField } from 'types'
import { AOI_COLORS } from 'utils/constants'

import { computeCornerFromOrigin, computeOriginFromCorner, getCurrentRectangleBoundingBox } from '../EditAoisHelper'
import { MaskingRectangle } from '../Utils'
import {
  ACTIVE_STROKE_WIDTH,
  ANCHOR_SIZE,
  CORNER_RADIUS,
  EditableShapeProps,
  HANDLE_FILL,
  INACTIVE_STROKE_WIDTH,
  SHADOW_HOVER_STROKE,
  SHADOW_HOVER_STROKE_WIDTH,
  SHADOW_STROKE,
  SHADOW_STROKE_WIDTH,
} from './EditableShape'

export const SNAP_ROTATION_ANGLES = [0, -0, 90, -90, 180, -180]
const SNAP_ROTATION_THRESHOLD = 0.75
/**
 * Renders a resizable and draggable rectangular AOI.
 *
 * @param aoi - The aoi being edited
 * @param inactive - If true, box doesn't have shadow to make background darker
 *     and make box stand out
 * @param disableTransform Should we disable resizing the AOI?
 * @param onUpdateBox Callback for when we update the AOI
 * @param onClick Callback for when we click on the AOI
 * @param scaling Scaling used to translate between image coordinates and client coordinates
 * @param bounds Width and height of the image we're creating AOIs for, before scaling.
 * @param disableRotation Do we disable users from rotating rectangles
 * @param deselctAois Callback used to deselect all AOIs on the stage
 */
const EditableRectangle = ({
  aoi,
  isInactive = false,
  disableTransform = false,
  onUpdateAoiWithShape,
  onSelect,
  scaling,
  bounds,
  disableRotation = true,
  showSizeBoxes,
  maxSizeBoxHeight,
  maxSizeBoxWidth,
  minSizeBoxWidth,
  minSizeBoxHeight,
  deselectAois,
  activeSizeBox,
  rectangleData,
  setRectangleData,
  rectRef,
  trRef,
  handleTransform,
  handleTransformEnd,
  toExportAoi,
}: EditableShapeProps & {
  disableRotation?: boolean
  showSizeBoxes?: boolean
  minSizeBoxWidth?: number
  minSizeBoxHeight?: number
  maxSizeBoxWidth?: number
  maxSizeBoxHeight?: number
  activeSizeBox?: 'min' | 'max'
  setRectangleData?: (aoiId: string, rectangleData: RectangleData) => any
  rectangleData?: RectangleData
  rectRef: React.RefObject<RectType>
  trRef: RefObject<Konva.Transformer>
  handleTransform: (aoiId: string) => any
  handleTransformEnd: (aoiId: string) => any
  toExportAoi: () => BoxWithShape | undefined
}) => {
  const { isFlourish } = useIsFlourish()
  const aoiShapeAndHandleStroke = isFlourish ? AOI_COLORS.flourish.dark_100 : AOI_COLORS.prism.dark_100
  const aoiStrokeInactive = isFlourish ? AOI_COLORS.flourish.smokeyBlack_24 : AOI_COLORS.prism.smokeyBlack_24
  const initialShapeData = (aoi.shape && aoi.shape.type === 'rectangle' && aoi.shape.data) || undefined

  useEffect(() => {
    setRectangleData?.(aoi.id, getInitialRectangle(aoi as Box, scaling, initialShapeData))
    // eslint-disable-next-line
  }, [aoi])

  useEffect(() => {
    // We verify that we're actually able to create a canvas and a rectangle
    // and that we want to allow resizing
    if (rectRef && !disableTransform) {
      // Attach the transformer to the fill for the rectangle
      trRef.current?.nodes([rectRef.current as Konva.Rect])
      trRef.current?.getLayer()?.batchDraw()
      trRef.current?.rotationSnaps(SNAP_ROTATION_ANGLES)
      trRef.current?.rotationSnapTolerance(SNAP_ROTATION_THRESHOLD)
    }
  }, [disableTransform, rectRef, trRef])

  // Set up the click listener to deselect all aois on the stage
  useEffect(() => {
    const stage = rectRef.current?.getStage()
    if (!stage) return
    const handleClickStage = () => stage && deselectAois(stage)
    stage.on('click', handleClickStage)
    return () => {
      stage.off('click', handleClickStage)
    }
  }, [deselectAois, rectRef])

  const handleDrag = (e: KonvaEventObject<DragEvent>) => {
    if (!rectangleData) return
    const rect = rectRef.current as Konva.Rect
    const { x, y } = constrainDragToBounds({ x: rect.x(), y: rect.y() })
    const { originX: newOriginX, originY: newOriginY } = computeOriginFromCorner(
      x,
      y,
      rectangleData.width,
      rectangleData.height,
      rectangleData.rotationDegrees,
    )
    e.target.x(x)
    e.target.y(y)
    setRectangleData?.(aoi.id, { ...rectangleData, originX: newOriginX, originY: newOriginY })
    updateToMatchBox(rect.getLayer()!)
  }

  // Add the transformer to the rectangle when the rectangle can be transformed.
  useEffect(() => {
    if (!disableTransform && rectRef.current) {
      trRef.current?.nodes()
      trRef.current?.nodes([rectRef.current])
    }
  })

  const handleDragEnd = (e: KonvaEventObject<DragEvent>) => {
    handleDrag(e)
    onUpdateAoiWithShape({ ...aoi, ...toExportAoi() })
  }

  const updateToMatchBox = (layer: Konva.Layer) => {
    const strokeRect = layer!.findOne<Konva.Rect>('.aoi-stroke')
    const rect = rectRef.current! as Konva.Rect
    strokeRect.position(rect.position())
    strokeRect.size(rect.size())
    strokeRect.rotation(rect.rotation())
  }

  const onMouseEnter = () => {
    if (!rectRef.current || !rectRef.current.getStage()) return
    rectRef.current!.getStage()!.container().style.cursor = 'move'
    if (isInactive) {
      const ghostRect = rectRef.current!.getLayer()!.findOne<Konva.Rect>('.aoi-ghost-stroke')!
      ghostRect.stroke(SHADOW_HOVER_STROKE)
      ghostRect.strokeWidth(SHADOW_HOVER_STROKE_WIDTH)
    }
  }

  const onMouseLeave = () => {
    if (!rectRef.current || !rectRef.current.getStage()) return
    rectRef.current!.getStage()!.container().style.cursor = 'default'
    if (isInactive) {
      const ghostRect = rectRef.current!.getLayer()!.findOne<Konva.Rect>('.aoi-ghost-stroke')!
      ghostRect.stroke(SHADOW_STROKE)
      ghostRect.strokeWidth(SHADOW_STROKE_WIDTH)
    }
  }

  const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    e.evt.stopPropagation()
    if (isInactive) {
      onSelect(aoi)
    } else {
      return
    }
  }

  const constrainDragToBounds = ({ x, y }: { x: number; y: number }): { x: number; y: number } => {
    const { y: top, x: left, width, height } = getCurrentRectangleBoundingBox({ rectRef })
    const maxHeight = bounds.maxHeight * scaling
    const maxWidth = bounds.maxWidth * scaling

    let deltaX = 0
    let deltaY = 0

    if (top < 0) {
      deltaY = -top
    } else if (top + height > maxHeight) {
      deltaY = maxHeight - top - height
    }

    if (left < 0) {
      deltaX = -left
    } else if (left + width > maxWidth) {
      deltaX = maxWidth - left - width
    }
    return { x: x + deltaX, y: y + deltaY }
  }

  if (!rectangleData) return null

  const { originX, originY, width, height, rotationDegrees } = rectangleData
  const { x, y } = computeCornerFromOrigin(originX, originY, width, height, rotationDegrees)

  const renderMinMaxSizeBoxes = () => {
    if (
      !showSizeBoxes ||
      minSizeBoxHeight === undefined ||
      minSizeBoxWidth === undefined ||
      maxSizeBoxHeight === undefined ||
      maxSizeBoxWidth === undefined
    )
      return null

    // When the aoi is rotated beyonf 45 degrees ranges, we switch the orientation of the size boxes, so that width and height are always aligned with the perspective of the aoi.
    const switchOrientation =
      (rotationDegrees < 90 && rotationDegrees > 45) ||
      (rotationDegrees < 135 && rotationDegrees > 90) ||
      (rotationDegrees < -90 && rotationDegrees > -135) ||
      (rotationDegrees < -45 && rotationDegrees > -90)

    const minWidthToUse = width * (switchOrientation ? minSizeBoxHeight : minSizeBoxWidth)
    const minHeightToUse = height * (switchOrientation ? minSizeBoxWidth : minSizeBoxHeight)

    const { x: minX, y: minY } = computeCornerFromOrigin(
      originX,
      originY,
      minWidthToUse,
      minHeightToUse,
      rotationDegrees,
    )

    const maxWidthToUse = width * (switchOrientation ? maxSizeBoxHeight : maxSizeBoxWidth)
    const maxHeightToUse = height * (switchOrientation ? maxSizeBoxWidth : maxSizeBoxHeight)

    const { x: maxX, y: maxY } = computeCornerFromOrigin(
      originX,
      originY,
      maxWidthToUse,
      maxHeightToUse,
      rotationDegrees,
    )

    return (
      <>
        <Rect
          x={minX}
          y={minY}
          cornerRadius={CORNER_RADIUS}
          rotation={rotationDegrees}
          width={minWidthToUse}
          height={minHeightToUse}
          stroke={activeSizeBox === 'min' ? aoiShapeAndHandleStroke : aoiStrokeInactive}
          strokeWidth={activeSizeBox === 'min' ? ACTIVE_STROKE_WIDTH : INACTIVE_STROKE_WIDTH}
          strokeScaleEnabled={false}
          listening={false}
          name={'aoi-stroke-min-size'}
        />
        <Rect
          x={maxX}
          y={maxY}
          cornerRadius={CORNER_RADIUS}
          rotation={rotationDegrees}
          height={maxHeightToUse}
          width={maxWidthToUse}
          stroke={activeSizeBox === 'max' ? aoiShapeAndHandleStroke : aoiStrokeInactive}
          strokeWidth={activeSizeBox === 'max' ? ACTIVE_STROKE_WIDTH : INACTIVE_STROKE_WIDTH}
          strokeScaleEnabled={false}
          listening={false}
          name={'aoi-stroke-max-size'}
        />
      </>
    )
  }

  // This is the cutout from the masking rectangle, which is exactly the area filled by the rectangle AOI. See the docs
  // here: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation for a demo
  // of how the global composite operation works. Konva exposes that API directly. Note that there's no way to apply the
  // compositing operation only to one of the stroke or the fill. That's why I have to first render the fill to get the
  // cutout and then separately render the stroke on top of that without any fill and using the default compositing
  // behavior.
  const aoiFill = (
    <Rect
      x={x}
      y={y}
      ref={rectRef}
      draggable
      resizeEnabled={!disableTransform}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDragMove={handleDrag}
      onDragEnd={handleDragEnd}
      onTransform={() => handleTransform(aoi.id)}
      onTransformEnd={() => handleTransformEnd(aoi.id)}
      rotation={rotationDegrees}
      width={width}
      height={height}
      fill={'black'}
      globalCompositeOperation={'destination-out'}
      name={'aoi-fill'}
      cornerRadius={CORNER_RADIUS}
      onMouseDown={onMouseDown}
    />
  )

  // This is the shadow behind the AOI outline that should appear when we hover over an inactive AOI
  const aoiOutlineShadow = (
    <Rect
      x={x}
      y={y}
      cornerRadius={CORNER_RADIUS}
      width={width}
      height={height}
      rotation={rotationDegrees}
      stroke={SHADOW_STROKE}
      strokeWidth={SHADOW_STROKE_WIDTH}
      strokeScaleEnabled={false}
      listening={false}
      name={'aoi-ghost-stroke'}
    />
  )

  // The AOI outline
  const aoiOutline = (
    <Rect
      key={`aoi-outline-${aoi.id}`}
      x={x}
      y={y}
      cornerRadius={CORNER_RADIUS}
      rotation={rotationDegrees}
      width={width}
      height={height}
      stroke={isInactive || activeSizeBox ? aoiStrokeInactive : aoiShapeAndHandleStroke}
      strokeWidth={isInactive || activeSizeBox ? INACTIVE_STROKE_WIDTH : ACTIVE_STROKE_WIDTH}
      strokeScaleEnabled={false}
      listening={false}
      name={'aoi-stroke'}
    />
  )

  return (
    <Layer>
      {!isInactive && !showSizeBoxes && <MaskingRectangle bounds={bounds} scaling={scaling} />}
      {/* The fill for the main AOI rectangle */}
      {!activeSizeBox && aoiFill}
      {/* Stroke for main AOI rectangle */}
      {isInactive && aoiOutlineShadow}
      {aoiOutline}
      {showSizeBoxes && renderMinMaxSizeBoxes()}
      {!isInactive && !disableTransform && (
        <Transformer
          ref={trRef}
          rotateEnabled={!disableRotation}
          useSingleNodeRotation
          ignoreStroke
          anchorSize={ANCHOR_SIZE}
          anchorStroke={aoiShapeAndHandleStroke}
          anchorFill={HANDLE_FILL}
          borderStrokeWidth={ACTIVE_STROKE_WIDTH}
          borderStroke={aoiShapeAndHandleStroke}
          keepRatio={false}
          flipEnabled={false}
          rotation={rotationDegrees}
        />
      )}
    </Layer>
  )
}

export default EditableRectangle

export type RectangleData = {
  originX: number
  originY: number
  width: number
  height: number
  rotationDegrees: number
}

/** Transforms saved AOI box with image coordinates into client-side coordinates shape when this rectangle is loaded
 * @param box - Saved box in image coordinates
 * @param scaling - clientWidth/imageWidth scale
 * @param data - Saved shape data
 * @returns Shape in client side coordinates
 */
export const getInitialRectangle = (box: Box, scaling: number, data?: RectangleShapeField['data']): RectangleData => {
  // The box here is in client-side coordinates. We'll convert back to image coordinates when saving.
  let width, height, rotationDegrees
  const originX = (box.x + box.width / 2) * scaling
  const originY = (box.y + box.height / 2) * scaling
  if (!data) {
    width = box.width * scaling
    height = box.height * scaling
    rotationDegrees = 0
  } else {
    width = data.width * box.width * scaling
    height = data.height * box.height * scaling
    rotationDegrees = data.rotationDegrees
  }
  return { originX, originY, width, height, rotationDegrees }
}
