import React from 'react'

import { service } from 'api'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { useConnectionStatus } from 'hooks'
import { Tool, UpdateLiveToolSettingsCommandArgs } from 'types'
import { renderToolName, updateLiveSettings } from 'utils'

export interface ToolSettingsModalProps {
  tool: Tool
  title?: string
  onClose: (refetch?: boolean) => any
  onBeforeSave?: (saveCallback: () => void) => void | Promise<void>
  robotIdsRunningRoutine?: string[]
  children: React.ReactNode
  inferenceUserArg: { key: string; value: any }
  onToolUpdate?: (inferenceArgs: { [key: string]: any }) => void
  disableSave?: boolean
  aoiIds: string[]
}

/**
 * Renders a template for a modal to be shown on the inspection screen, which allows users to set inference_user_args ONLY
 *
 * @param tool - The tool object corresponding to the current tool
 * @param title - Title can be optionally set to override default title
 * @param onClose - Callback to exit the modal.
 * @param robotIdsRunningRoutine - If used during an inspection, allows to notify vision processing of the change.
 * @param onBeforeSave - If defined, this function will run when users click on the save button. the regular saving process will only run once the saveCallback is called
 * @param children - inner React components
 * @param inferenceUserArg - key and value of the inference user arg to set depending on the selected tool
 * @param onToolUpdate - Handler for updating tool settings in local state
 * @param disableSave - Whether the save button should be disabled
 */
const ToolSettingsModalTemplate = ({
  tool,
  onClose,
  onBeforeSave,
  title,
  robotIdsRunningRoutine,
  inferenceUserArg,
  children,
  disableSave,
  onToolUpdate,
  aoiIds,
}: ToolSettingsModalProps) => {
  const connectionStatus = useConnectionStatus()
  const saveTool = async () => {
    const handleSave = async () => {
      const newInferenceUserArgs = { ...tool.inference_user_args, [inferenceUserArg.key]: inferenceUserArg.value }
      const body = {
        aois: aoiIds.map(aoiId => ({
          id: aoiId,
          inference_user_args: newInferenceUserArgs,
        })),
      }

      let updateResType = ''
      const commandArgs: UpdateLiveToolSettingsCommandArgs = {
        tool: tool.id,
        tool_settings: [{ setting: inferenceUserArg.key, value: inferenceUserArg.value }],
      }

      const updateTool = () => service.patchProtectedTool(tool.id, body)

      if (connectionStatus === 'online') {
        const toolRes = await updateTool()
        updateResType = toolRes.type

        if (updateResType === 'success') commandArgs.update_db = false
        if (updateResType === 'error') {
          error({ title: 'There was an error connecting to the server, the update might not be applied' })
        }
      } else {
        // If the connection status isn't online, there might still be an intermittent connection, so updating Django
        // might not fail, but we don't want to wait for that request to respond, and we don't want to show an error
        updateTool()
      }

      // This means this action was executed mid inspection and the robot needs to get notified of the change
      if (robotIdsRunningRoutine) {
        const liveSettingsUpdated = await updateLiveSettings(robotIdsRunningRoutine, {
          commandName: 'update_tool_settings',
          commandArgs,
        })

        if (!liveSettingsUpdated) return error({ title: 'An error occurred updating the camera, please try again' })
      }

      onToolUpdate?.({ ...tool.inference_user_args, [inferenceUserArg.key]: inferenceUserArg.value })
      onClose(true)
      success({ title: 'Tool updated', 'data-testid': 'tool-settings-modal-save-success' })
    }

    if (onBeforeSave) {
      await onBeforeSave(handleSave)
    } else {
      await handleSave()
    }
  }

  return (
    <Modal
      id="save-tool"
      header={title || `Configure ${renderToolName(tool)}`}
      onClose={() => onClose()}
      onOk={saveTool}
      okText="Save"
      disableSave={disableSave}
      hideCross
      size="largeSimpleForm"
      data-testid="tool-settings-modal"
    >
      {children}
    </Modal>
  )
}

export default ToolSettingsModalTemplate
