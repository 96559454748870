import React from 'react'

import {
  PrismDiscardIcon,
  PrismFailIcon,
  PrismPassIcon,
  PrismTestSetIcon,
  PrismUnknownIcon,
} from 'components/prismIcons'
import { LabelButtonIcon, Outcome } from 'types'

import Styles from './PrismOutcome.module.scss'

interface Props {
  label?: string
  variant?: 'low' | 'high' | 'dark' | 'transparent' | 'iconColor'
  outcome?: Outcome
  className?: string
  icon: LabelButtonIcon
  size?: 'xSmall' | 'small' | 'medium'
  'data-testid'?: string
  'data-test-attribute'?: string
}

const sizeClasses = {
  xSmall: Styles.prismOutcomeSvgXsmall,
  small: Styles.prismOutcomeSvgSmall,
  medium: Styles.prismOutcomeSvgMedium,
}

export const PrismOutcome = ({
  label,
  icon,
  variant = 'transparent',
  className,
  size = 'medium',
  'data-testid': dataTestId,
  'data-test-attribute': dataTestAttribute,
}: Props) => {
  const sizeClass = sizeClasses[size]
  return (
    <div className={`${Styles.prismOutcome} ${label ? Styles.prismOutcomeWithLabel : ''} ${className ?? ''}`}>
      <figure
        className={`${Styles.prismOutcomeSvg} ${sizeClass}`}
        data-testid={`${dataTestId}`}
        data-test-attribute={dataTestAttribute}
      >
        {icon === 'pass' && <PrismPassIcon variant={variant} viewboxSize={size} />}
        {icon === 'fail' && <PrismFailIcon variant={variant} viewboxSize={size} />}
        {icon === 'unknown' && <PrismUnknownIcon variant={variant} viewboxSize={size} />}
        {icon === 'discard' && <PrismDiscardIcon variant={variant} viewboxSize={size} />}
        {icon === 'test_set' && <PrismTestSetIcon variant={variant} viewboxSize={size} />}
      </figure>
      {label && <p className={Styles.prismOutcomeLabel}>{label}</p>}
    </div>
  )
}
