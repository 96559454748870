import React, { useRef } from 'react'

import { useHistory } from 'react-router-dom'

import { PrismInputNumber, PrismInputNumberProps } from 'components/PrismInput/PrismInput'
import { RecipeExpanded, RoutineWithAois } from 'types'
import { protectedOnChange } from 'utils'

export type InputNumberValue = string | number | null | undefined

interface Props extends Omit<PrismInputNumberProps, 'onChange'> {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  onChange: (value: InputNumberValue) => void
}

const ProtectedInputNumber = ({ onChange, routine, recipe, ...rest }: Props) => {
  const history = useHistory()
  const ref = useRef<HTMLInputElement>(null)

  const internalOnChange = (value: InputNumberValue) => {
    if (routine.is_protected) ref.current?.blur()
    protectedOnChange(onChange, { routine, history, recipe })(value)
  }

  return <PrismInputNumber ref={ref} {...rest} onChange={internalOnChange} />
}

export default ProtectedInputNumber
