import React from 'react'

import { PrismCard } from 'components/PrismCard/PrismCard'
import { Props as PrismProps } from 'components/PrismCard/PrismCard'
import { PrismElementaryCube } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { Status } from 'components/Status/Status'
import { Token } from 'components/Token/Token'
import { useRobotStatus } from 'hooks'

import Styles from './CardWithThumbnailAndStatus.module.scss'

interface Props extends PrismProps<string> {
  name?: string
  title: string
  robotId: string
  type?: 'transparent' | 'ghost4' | 'smokey100' | 'smokey94'
  badgeType?: 'default' | 'warning' | 'error' | 'pass'
  detailsClassName?: string
}
/**
 * Renders a box with the camera or station details to be shown, as well as the live thumbnail
 * stream and the current robot status.
 *
 * @param highlitght - Whether we should show the blue dot as an edited camera
 * @param active - Whether the the camera is selected as the active camera
 * @param img - The camera's image component
 * @param name - The camera's name
 * @param label - The label for the status. Will actually always be "Status"
 * @param status - The camera's status
 * @param onClick - Click handler for when clicking on the prism card
 * @param badgeType - Changes the badge color from blue (default), to red (error), yellow (warning), pass (green)
 */
export const CardWithThumbnailAndStatus = ({
  name,
  robotId,
  title,
  type,
  children,
  badgeType = 'pass',
  detailsClassName = '',
  ...rest
}: Props) => {
  const status = useRobotStatus(robotId)

  return (
    <PrismCard
      data-test={'card-live-thumbnail-status-camera'}
      data-testid={`card-live-thumbnail-status-camera-${robotId}`}
      type={type}
      image={
        <figure className={Styles.toolImageContainer}>
          {status === 'disconnected' ? <PrismElementaryCube addBackground /> : children}
        </figure>
      }
      badgeType={badgeType}
      {...rest}
      className={Styles.card}
    >
      <div className={`${Styles.toolItem} ${detailsClassName}`}>
        <Token labelClassName={Styles.detailsLabel} className={Styles.detailsContainer} label={title}>
          <PrismOverflowTooltip content={name ? name : '--'} />
        </Token>

        {/* This token is being shown only when the screen is bigger than 1024px */}
        <Token
          labelClassName={Styles.detailsLabel}
          className={`${Styles.secondToken} ${Styles.detailsContainer}`}
          label="Status"
        >
          <Status status={status} />
        </Token>
        {/* Token is replaced by the status when the screen es smaller than 1024px */}
        <Status showLabel={false} status={status} className={Styles.status} loaderClassName={Styles.statusLoader} />
      </div>
    </PrismCard>
  )
}
