import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

import { pixelsIntoPoints } from '../styles'

const iconSize = pixelsIntoPoints(20)

const PDFResultDiscard = () => (
  <Svg style={{ width: iconSize, height: iconSize }} viewBox="0 0 20 20">
    <Path
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2Z"
      fill="#F8F8F9"
    />
    <Path
      fillRule="evenodd"
      d="M4.74991 6.11758L5.35967 15.2998C5.38583 15.6938 5.7131 16.0001 6.10803 16.0001H13.8919C14.2869 16.0001 14.6141 15.6938 14.6403 15.2998L15.2501 6.11758H4.74991ZM6.08567 7.36758L6.57592 14.7501H13.4241L13.9143 7.36758H6.08567Z"
      fill="#4A4A4A"
    />
    <Path
      d="M6.99994 4.74995C6.99994 4.33573 7.33573 3.99994 7.74995 3.99994H12.25C12.6642 3.99994 13 4.33573 13 4.74995V4.99994H6.99994V4.74995Z"
      fill="#4A4A4A"
    />
    <Path
      fillRule="evenodd"
      d="M16.25 7.11758V6.86759C16.25 6.45337 15.9142 6.11758 15.5 6.11758H4.50001C4.08579 6.11758 3.75 6.45337 3.75 6.86759V7.11758H16.25Z"
      fill="#4A4A4A"
    />
  </Svg>
)
export default PDFResultDiscard
