import React, { useEffect, useMemo, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Divider } from 'components/Divider/Divider'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { useQueryEntityFromSites, useQueryParams } from 'hooks'
import AddOrEditSiteModal from 'pages/StationDetail/Components/EntityModals/AddOrEditSiteModal'
import { CameraStatus, Site } from 'types'
import { appendDataToQueryString, matchRole, sortByName } from 'utils'

import Styles from './Inspect.module.scss'
import SiteDetails from './Inspect/SiteDetails'
import SitesList from './Inspect/SitesList'
import StationPreview from './Inspect/StationPreview'
import InspectEmptyState from './InspectEmptyState/InspectEmptyState'

export type SiteType = {
  site: string
  location: string
  line?: {
    lineName: string
    lineList: {
      stationName: string
      stationProduct: string
      stationStatus: CameraStatus
      stationMetrics: { yield: number; failed: number; count: number }
    }
  }
}

export type InspectSitesProps = {
  showArchive: boolean
  setSelectedSite: (site: Site | undefined) => void
}

/**
 * Render the Main Inspect Layout Screen
 * @returns
 */
const InspectSites = ({ setSelectedSite, showArchive }: InspectSitesProps) => {
  const [params] = useQueryParams<'siteId' | 'stationId'>()
  const history = useHistory()
  const [showCreateSiteModal, setShowCreateSiteModal] = useState(false)

  const sites = useQueryEntityFromSites({ entity: 'site', filterFn: site => !site.is_deleted })
  const sortedSites = useMemo(() => {
    if (!sites) return
    return [...sites].sort(sortByName)
  }, [sites])

  const selectedSite = useMemo(() => {
    return sortedSites?.find(site => site.id === params.siteId) || sortedSites?.[0]
  }, [params.siteId, sortedSites])

  const stationsInSite = useQueryEntityFromSites({ entity: 'station', siteId: selectedSite?.id })
  const station = stationsInSite?.find(station => station.id === params.stationId)

  function selectStation(stationId: string | undefined) {
    appendDataToQueryString(history, { stationId })
  }

  useEffect(() => {
    setSelectedSite(selectedSite)
  }, [setSelectedSite, selectedSite])

  const siteMainDetails = () => {
    if (!sortedSites) return <PrismLoader />
    if (showArchive)
      return (
        <SiteDetails
          key={selectedSite?.id}
          selectedSite={selectedSite}
          showArchive={showArchive}
          onStationSelect={selectStation}
        />
      )
    if (sortedSites.length === 0)
      return (
        <InspectEmptyState
          mode="Site"
          canAddEntity={matchRole('manager')}
          onClick={() => setShowCreateSiteModal(true)}
        />
      )

    return (
      <>
        {sortedSites.length > 1 && (
          <section className={`${Styles.sitesSection} ${showArchive ? Styles.hideSitesSection : ''}`}>
            <SitesList sites={sortedSites} selectedSite={selectedSite} />
            <Divider type="vertical" className={Styles.sitesDivider} />
          </section>
        )}
        <SiteDetails
          key={selectedSite?.id}
          selectedSite={selectedSite}
          showArchive={showArchive}
          onStationSelect={selectStation}
        />
        {station && !station.is_deleted && selectedSite && (
          <>
            <Divider type="vertical" className={Styles.stationDivider} />
            <StationPreview
              onClose={() => selectStation(undefined)}
              siteId={selectedSite.id}
              station={station}
              className={Styles.stationPreviewWrapper}
            />
          </>
        )}
      </>
    )
  }

  return (
    <div
      className={`${Styles.inspectSitesWrapper} ${
        params.stationId && !showArchive ? Styles.stationPreviewFixedWidth : ''
      }`}
    >
      {siteMainDetails()}

      {showCreateSiteModal && <AddOrEditSiteModal isEditMode={false} onClose={() => setShowCreateSiteModal(false)} />}
    </div>
  )
}

export default InspectSites

/**
 * Render the Lines, Archive and Station Preview Main Header
 * @param title - The header's main title
 * @param subtitle - label below the title
 * @param icons - renders a section to the right for icons
 * @param leftContainerClassName - additional styles for the header left section
 */
export const SectionHeader = ({
  title,
  subtitle,
  icons,
  leftContainerClassName = '',
}: {
  title: string
  subtitle?: React.ReactNode
  icons: React.ReactNode
  leftContainerClassName?: string
}) => {
  return (
    <div className={`${Styles.siteDetailsHeader} ${subtitle ? Styles.hasSubtitle : Styles.singleTitle} `}>
      <div className={`${Styles.siteDetailsHeaderText} ${leftContainerClassName}`}>
        <PrismOverflowTooltip
          tooltipPlacement="bottom"
          content={title}
          textClassName={Styles.siteDetailsHeaderTitle}
          canBeResized
        />
        {subtitle}
      </div>
      <div className={Styles.siteDetailsHeaderIcons}>{icons}</div>
    </div>
  )
}
