import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { PrismHelpIcon } from 'components/prismIcons'
import { PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { PrismSlider } from 'components/PrismSlider/PrismSlider'
import Shared from 'styles/Shared.module.scss'
import { RecipeExpanded } from 'types'
import { protectedOnChange } from 'utils'

import { Props } from './RoutineSettings'
import Styles from './RoutineSettings.module.scss'

/**
 * Renders production targets settings to edit for the current recipe
 *
 * @param recipe - Selected Recipe
 * @param readOnly - Whether the settings should be editable
 */
const ProductionTargets = ({ routine, recipe, readOnly }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const defaultValues = getDefaultFormValues(recipe)
  const {
    formState: { isDirty, isValid },
    control,
    reset,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    reset(getDefaultFormValues(recipe))
  }, [reset, recipe])

  const handleSave = async () => {
    const { target_yield, units_per_minute } = getValues()
    const res = await service.patchRecipe(recipe.id, {
      user_settings: { ...recipe.user_settings, production_targets: { target_yield, units_per_minute } },
    })
    if (res.type !== 'success') return error({ title: 'An error occurred saving targets' })

    await query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), {
      dispatch,
    })

    success({ title: 'Targets updated', 'data-testid': 'production-targets-update-successs' })
  }
  return (
    <>
      <div className={Shared.twoSideLayoutHeader}>
        <h1 className={Shared.twoSideLayoutHeaderTitle}>Production Targets</h1>
        <div>
          <Button
            disabled={readOnly || !isDirty || !isValid}
            size="small"
            onClick={handleSave}
            data-testid="production-targets-save"
          >
            Save
          </Button>
        </div>
      </div>

      <Divider className={Styles.twoSideHorizontalDivider} />

      <div className={Styles.layoutBody}>
        <div className={Styles.captionContainer}>
          <PrismHelpIcon className={Styles.captionIcon} />

          <p className={Styles.caption}>Production targets appear on a station when a batch is running.</p>
        </div>

        <div className={Styles.compositeItem} data-testid="production-targets-yield">
          <Controller
            control={control}
            name="target_yield"
            render={({ onChange, value }) => {
              const handleChange = protectedOnChange(
                (threshold: string | number | null | undefined) => {
                  if (typeof threshold !== 'number') threshold = 0

                  onChange(threshold)
                },
                { routine, history, recipe },
              )

              const sliderAndInputProps = {
                disabled: readOnly,
                value,
                onChange: handleChange,
                min: 0,
                max: 100,
                step: 0.1,
              }

              return (
                <PrismSlider
                  label="Yield"
                  {...sliderAndInputProps}
                  inputBox={
                    <PrismInputNumber
                      className={Styles.targetInput}
                      {...sliderAndInputProps}
                      size="large"
                      data-testid="production-targets-yield-input"
                    />
                  }
                />
              )
            }}
          />

          <p className={Styles.itemCaption}>
            The percentage of non-defective items of all produced items. Set to zero to disable this target.
          </p>
        </div>

        <div className={Styles.compositeItem} data-testid="production-targets-items-per-minute">
          <Controller
            control={control}
            name="units_per_minute"
            render={({ onChange, value }) => {
              const handleChange = protectedOnChange(
                (upmTarget: string | number | null | undefined) => {
                  if (typeof upmTarget !== 'number') upmTarget = 0

                  onChange(upmTarget)
                },
                { routine, history, recipe },
              )

              const sliderAndInputProps = {
                disabled: readOnly,
                value,
                onChange: handleChange,
                min: 0,
                max: 2400,
              }

              return (
                <PrismSlider
                  label="Items Per Minute"
                  {...sliderAndInputProps}
                  inputBox={
                    <PrismInputNumber
                      className={Styles.targetInput}
                      {...sliderAndInputProps}
                      size="large"
                      data-testid="production-targets-items-per-minute-input"
                    />
                  }
                />
              )
            }}
          />

          <p className={Styles.itemCaption}>
            The number of items you expect to produce per minute. Set to zero to disable this target.
          </p>
        </div>
      </div>

      <LeavePagePrompt when={isDirty} onOk={() => reset(getDefaultFormValues(recipe))} />
    </>
  )
}

export default ProductionTargets

/**
 *
 * @param recipe - Recipe to calculate settings from
 * @returns Default form values for item creation
 */
const getDefaultFormValues = (recipe: RecipeExpanded) => {
  return {
    target_yield: recipe.user_settings?.production_targets?.target_yield || 0,
    units_per_minute: recipe.user_settings?.production_targets?.units_per_minute || 0,
  }
}
