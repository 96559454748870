import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button } from 'components/Button/Button'
import Shared from 'styles/Shared.module.scss'

import Styles from './NotFound.module.scss'

/**
 * Renders a screen to be shown if user navigates to a URL that doesn't exist.
 */
function NotFound() {
  const history = useHistory()
  return (
    <div className={`${Shared.absoluteCenter} ${Styles.mainWrapper}`}>
      <div className={Styles.wrapperHeader}>
        <span>404</span>
        <div className={Styles.iconContainer}>
          <div className={Styles.iconContainerDivider}>
            <div className={Styles.circleIcon} />
          </div>
          <div className={Styles.dotsContainer}>
            <div className={Styles.dots} />
            <div className={Styles.dots} />
            <div className={Styles.dots} />
          </div>
        </div>
      </div>
      <div className={Styles.detailsContainer}>
        <div className={Styles.details}>
          <div className={Styles.mainTitle}>Page Not Found</div>
          <div className={Styles.description}>
            The page you're looking for may not exist or
            <br /> you may not have permissions to see it
          </div>
        </div>
        <Button onClick={() => history.push('/')}>Go Back</Button>
      </div>
    </div>
  )
}

export default NotFound
