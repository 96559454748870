import React, { useEffect, useState } from 'react'

import { ModalFuncProps } from 'antd'
import { Prompt, useHistory } from 'react-router-dom'

import { modal } from 'components/PrismModal/PrismModal'

type Props = {
  when: boolean
} & ModalFuncProps

/**
 * Renders a modal whenever a user navigates away from the current page and the condition is set to true.
 * This component can receive additional props which will override the modal's props
 * @param when - Whether to show the modal to prevent navigation away
 */
function LeavePagePrompt({ when, onOk, ...rest }: Props) {
  const history = useHistory()
  // This piece of state is necessary so we don't show multiple modals at once
  const [showModal, setShowModal] = useState<boolean>(false)

  // This effect allows us to use the browser's native `onbeforeunload` function, which when defined, shows a native `Leave Page?` or `Reload Page?` modal which will prevent user from navigating away if they have dirty changes.
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => ''
    } else {
      window.onbeforeunload = null
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [when])

  return (
    <Prompt
      when={when && !showModal}
      message={location => {
        setShowModal(true)
        modal.confirm({
          'data-testid': 'leave-page-modal',
          id: 'leave-page-promp',
          content: 'Leave without saving?',
          okText: 'Leave',
          onOk: async close => {
            await history.push(location)
            setShowModal(false)
            onOk?.()
            close()
          },
          onClose: () => {
            setShowModal(false)
          },
          ...rest,
        })
        return false
      }}
    />
  )
}

export default LeavePagePrompt
