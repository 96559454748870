import React, { memo, useEffect, useRef } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys } from 'api'
import AppVersion from 'components/AppVersion/AppVersion'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import {
  BrandLogo,
  PrismAnalyzeIcon,
  PrismInspectIcon,
  PrismMenuExpandIcon,
  PrismNotifyIcon,
  PrismSettingsIcon,
} from 'components/prismIcons'
import { useConnectionStatus, useData } from 'hooks'
import paths from 'paths'
import { matchRole } from 'utils'

import Styles from './Navbar.module.scss'

export const DashboardMenuItem = ({
  path,
  partialMatch,
  onClick,
  active,
  label,
  'data-testid': dataTestId,
}: {
  path?: string
  onClick?: () => void
  partialMatch?: RegExp
  active?: boolean
  label: 'Administration' | 'Analyze' | 'Inspect' | 'Notify'
  'data-testid'?: string
}) => {
  const history = useHistory()
  const location = useLocation()

  const connectionStatus = useConnectionStatus()

  const isActive = !!(
    active ||
    (path && location.pathname.startsWith(path)) ||
    (partialMatch && location.pathname.match(partialMatch))
  )

  const icons = {
    Inspect: <PrismInspectIcon isActive={isActive} />,
    Analyze: <PrismAnalyzeIcon isActive={isActive} />,
    Notify: <PrismNotifyIcon isActive={isActive} />,
    Administration: <PrismSettingsIcon isActive={isActive} />,
  }[label]

  return (
    <Button
      type="default"
      className={`${Styles.menuItemWrapper} ${Styles.menuItemColors} ${isActive ? Styles.menuItemActive : ''} ${
        label !== 'Inspect' && connectionStatus !== 'online' ? Styles.offlineItem : ''
      }`}
      onClick={() => {
        if (onClick) return onClick()
        if (path) return history.push(path)
      }}
      disabled={label !== 'Inspect' && connectionStatus !== 'online'}
      data-testid={dataTestId}
      badge={icons}
      wide
    >
      {label}
    </Button>
  )
}

export interface Props {
  navbarVisible: boolean
  setNavbarVisible?: (visible: boolean) => void
}

const Avatar = () => {
  const me = useData(getterKeys.me())

  if (!me || !me?.first_name) {
    return <figure className={Styles.userPic}>MA</figure>
  }

  return (
    <figure className={Styles.userPic}>
      {me.first_name[0]} {me.last_name[0]}
    </figure>
  )
}

const MyAccount = () => {
  const history = useHistory()
  const location = useLocation()
  const isActive = location.pathname.includes(paths.accountSettings({ onlyPrefix: true }))
  const me = useData(getterKeys.me())

  const connectionStatus = useConnectionStatus()

  if (!me) return null
  return (
    <Button
      type="default"
      className={`${Styles.userContainer}
      ${Styles.menuItemColors} ${isActive ? Styles.menuItemActive : ''}
       ${connectionStatus !== 'online' ? Styles.offlineItem : ''}`}
      onClick={() => history.push(paths.accountSettings({ mode: 'profile' }))}
      disabled={connectionStatus !== 'online'}
      wide
      data-testid="navbar-account-button"
    >
      <Avatar />
      <p className={Styles.userName}>My Account</p>
    </Button>
  )
}

/**
 * Renders a collapsible navbar with links to the different sections of the
 * app.
 *
 * @param navbarVisible - Is the navbar visible?
 * @param setNavbarVisible - Gives the option to close the navbar from within
 */
function Navbar({ navbarVisible, setNavbarVisible }: Props) {
  const history = useHistory()
  const menuRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  // handle behavior of menu when using click instead of hover
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      // do not try to close the menu if the menu is already closed
      if (!navbarVisible) return
      // if click outside, close the menu
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setNavbarVisible && setNavbarVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [navbarVisible, setNavbarVisible])

  const inspectNavOptionIsActive =
    location.pathname.includes('/station/') ||
    location.pathname.includes('/setup/recipes') ||
    location.pathname.includes('/inspect')

  return (
    <div className={navbarVisible ? Styles.navOverlay : ''}>
      <div className={`${Styles.navbar} ${navbarVisible ? Styles.navbarVisible : ''}`} ref={menuRef}>
        <div className={Styles.leftPanel}>
          <div className={Styles.panelLogo}>
            <BrandLogo className={Styles.elementaryLogo} />
          </div>

          <div className={Styles.panelNav}>
            <div className={Styles.panelNavMenu}>
              <DashboardMenuItem
                data-testid="navbar-inspect-button"
                label="Inspect"
                path={paths.inspect({ mode: 'site' })}
                active={inspectNavOptionIsActive}
              />

              {matchRole('manager') && (
                <DashboardMenuItem
                  data-testid="navbar-analyze-button"
                  label="Analyze"
                  path={paths.analyze({ onlyPrefix: true })}
                  onClick={() => history.push(paths.analyze({ mode: 'overview' }))}
                />
              )}

              {matchRole('manager') && (
                <DashboardMenuItem
                  data-testid="navbar-notify-button"
                  label="Notify"
                  path={paths.settingsNotify({ onlyPrefix: true })}
                  onClick={() => history.push(paths.settingsNotify({ mode: 'subscriptions' }))}
                />
              )}
            </div>
          </div>

          <Divider className={Styles.divider} />

          <div className={Styles.panelUserSettings}>
            {matchRole('manager') && (
              <DashboardMenuItem
                data-testid="navbar-administration-button"
                label="Administration"
                path={paths.administrationSettings({ onlyPrefix: true })}
                onClick={() => history.push(paths.administrationSettings({ mode: 'users' }))}
              />
            )}

            <MyAccount />
            {setNavbarVisible && (
              <IconButton
                type="ghost"
                icon={<PrismMenuExpandIcon direction="mirror" />}
                className={Styles.dashBoardMenuIcon}
                onClick={() => setNavbarVisible(false)}
              />
            )}

            <AppVersion className={Styles.appVersion} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Navbar)
