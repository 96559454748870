import React from 'react'

import { PrismSearchIcon } from 'components/prismIcons'

import Styles from './PrismSelect.module.scss'

const SelectNotFound = () => {
  return (
    <div className={Styles.notFoundContainer}>
      <PrismSearchIcon className={Styles.notFoundIcon} />
      <p className={Styles.notFoundText}>No results found</p>
    </div>
  )
}

export default SelectNotFound
