import React, { useCallback } from 'react'

import { Popover, PopoverProps } from 'antd'
import { useHistory } from 'react-router-dom'

import { Divider } from 'components/Divider/Divider'
import { IconButton } from 'components/IconButton/IconButton'
import { PrismEditIcon } from 'components/prismIcons'
import { PrismChartIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useHotkeyPress } from 'hooks'
import paths from 'paths'
import Shared from 'styles/Shared.module.scss'
import { ToolLabel } from 'types'
import { getLabelName, matchRole } from 'utils'

import Styles from './LabelingScreen.module.scss'
import { ToolLabelDetailFields } from './ToolLabelDetail'

interface LabelInfoProps extends Omit<PopoverProps, 'title'> {
  popoverClassName?: string
  toolLabel: ToolLabel
  onClose: () => void
  onEditLabelClick: (toolLabel: ToolLabel) => void
  setSelectedImageIdx: (idx?: number) => void
  children: React.ReactNode
}

/**
 * Renders a Popover with the label info.
 *
 * https://www.figma.com/file/Jdxw39LY47tE18HWvLOe0K/Train-%26-Label-(main)?node-id=1859%3A37274
 *
 *
 * @param toolLabel - tool label to show info for
 * @param className - string that hold the css selector
 * @param onClose - function to closed the popover
 * @param onEditLabelClick - function to show the glossary in edit mode
 * @param open -  whether the button is open
 * @param onopenChange - handler called when visibility changes
 * @param children - popover children
 *
 */

export const LabelingInfoPopover = ({
  toolLabel,
  popoverClassName,
  onClose,
  onEditLabelClick,
  placement = 'bottomRight',
  open,
  onOpenChange,
  children,
  setSelectedImageIdx,
  ...rest
}: LabelInfoProps) => {
  const history = useHistory()

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (open && e.key === 'Escape') {
        e.stopPropagation()
        onClose()
      }
    },
    [onClose, open],
  )

  useHotkeyPress(keyHandler, { capture: true })

  const handleViewResultsClick = () => {
    const newPath = paths.analyze({ mode: 'items', params: { prediction_label_id: toolLabel.id } })
    history.push(newPath)
  }

  const handleImageClick = (idx: number) => {
    setSelectedImageIdx(idx)
    onOpenChange?.(false)
  }

  return (
    <>
      <Popover
        open={open}
        placement={placement}
        onOpenChange={onOpenChange}
        overlayClassName={`${Styles.labelingInfoPopover} ${popoverClassName ?? ''}`}
        trigger="click"
        title={
          <>
            <header
              className={Styles.labelingInfoHeader}
              data-testid="labeling-info-popover-header"
              onClick={e => e.stopPropagation()}
            >
              <PrismOverflowTooltip
                content={getLabelName(toolLabel)}
                className={Styles.labelingInfoHeaderTitle}
                tooltipPlacement="bottom"
                data-testid="label-popover-value"
              />

              <div className={Styles.labelingInfoHeaderActions}>
                {matchRole('manager') && (
                  <PrismTooltip title="Edit Label" condition={toolLabel.kind !== 'default'}>
                    <IconButton
                      disabled={toolLabel.kind === 'default'}
                      icon={<PrismEditIcon />}
                      type="tertiary"
                      size="xsmall"
                      onClick={() => onEditLabelClick(toolLabel)}
                    />
                  </PrismTooltip>
                )}
                <PrismTooltip title="View Results">
                  <IconButton
                    icon={<PrismChartIcon />}
                    type="tertiary"
                    size="xsmall"
                    onClick={handleViewResultsClick}
                  />
                </PrismTooltip>
              </div>
            </header>
            <Divider className={Styles.labelingInfoDivider} />
          </>
        }
        content={
          <div
            className={`${Styles.labelingInfoBody} ${Shared.verticalChildrenGap24}`}
            onClick={e => e.stopPropagation()}
          >
            <ToolLabelDetailFields toolLabel={toolLabel} onImageClick={handleImageClick} />
          </div>
        }
        {...rest}
      >
        {children}
      </Popover>
    </>
  )
}
