import React, { useMemo, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service } from 'api'
import { Modal } from 'components/PrismModal/PrismModal'
import { useRobotDiscovery } from 'hooks'
import { Capabilities, RoutineWithAois } from 'types'
import { linkRoutineRobotAndRecipe } from 'utils'

import RoutineLinkToCameraTable, { ViewModalProps } from './RoutineLinkToCameraTable'
import Styles from './ViewModal.module.scss'

const ERROR_MSG = 'There was an error linking the view, please try again'

interface LinkViewModalProps extends ViewModalProps {
  routine: RoutineWithAois
}

/**
 * Renders a modal with a list of static cameras so user can choose which robot they want to link the routine to.
 * @param recipe - Recipe we are working on
 * @param routine - Routine we are linking
 */

const LinkViewModal = ({ recipe, routine, onClose, onOk }: LinkViewModalProps) => {
  const [modalLoaded, setModalLoaded] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const stations = useQuery(getterKeys.stations('all-with-robots'), () =>
    service.getStations({ has_robots: true, order_by: 'name' }),
  ).data?.data.results

  const robotIds = useMemo(() => {
    return stations?.flatMap(station => station.robots.map(r => r.id)) || []
  }, [stations])

  const discoveries = useRobotDiscovery(robotIds)

  const capabilitiesByRobotId: { [robotId: string]: Capabilities | null | undefined } = {}
  Object.entries(discoveries || {}).forEach(([robotId, discovery]) => {
    capabilitiesByRobotId[robotId] = discovery?.basler?.capabilities
  })

  const routineAlreadyLinked = recipe.recipe_routines.find(
    recipe_routine => recipe_routine.routine.id === routine.id && recipe_routine.robot_id,
  )

  const defaultValues = getDefaultValues(routineAlreadyLinked?.robot_id)

  const {
    formState: { isDirty, isValid },
    control,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  const selectRobotId = watch('robot_id')

  const handleSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const { robot_id: robotId } = getValues()

    const robotCapabilites = capabilitiesByRobotId[robotId]
    if (!robotCapabilites) return
    await linkRoutineRobotAndRecipe({
      routineId: routine.id,
      robotId,
      recipe,
      robotCapabilites,
      errorMsg: ERROR_MSG,
      dispatch,
      onOk,
      history,
    })
  }

  return (
    <form onSubmit={handleSave}>
      <Modal
        id="link-view"
        size="largeSimpleForm"
        header="Link View to Camera"
        onClose={onClose}
        onOk={handleSave}
        disableSave={!isDirty || !isValid}
        okText="link"
        data-testid="link-view-modal"
        headerClassName={Styles.viewModalHeader}
        modalBodyClassName={Styles.viewModalBody}
        modalFooterClassName={Styles.viewModalFooter}
        onModalLoad={() => setModalLoaded(true)}
      >
        <RoutineLinkToCameraTable
          recipe={recipe}
          routine={routine}
          capabilitiesByRobotId={capabilitiesByRobotId}
          control={control}
          modalLoaded={modalLoaded}
          selectedRobotId={selectRobotId}
        />
      </Modal>
    </form>
  )
}

export default LinkViewModal

const getDefaultValues = (robotId: string | undefined) => ({
  robot_id: robotId || '',
})
