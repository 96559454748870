import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Add Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1430%3A4568
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isThin - boolean to change the aspect of the icon.
 * @param className - String for external styles.
 */
export const PrismAddIcon = ({ hasBackground, className, isThin = false }: PrismIconProps) => {
  const isThinVariant = isThin
    ? {
        viewbox: '0 0 32 32',
        d: 'M15.4999 23.3333C15.4999 23.6095 15.7238 23.8333 15.9999 23.8333C16.2761 23.8333 16.4999 23.6095 16.4999 23.3333V16.5H23.3334C23.6095 16.5 23.8334 16.2761 23.8334 16C23.8334 15.7239 23.6095 15.5 23.3334 15.5H16.4999V8.66667C16.4999 8.39053 16.2761 8.16667 15.9999 8.16667C15.7238 8.16667 15.4999 8.39053 15.4999 8.66667V15.5H8.6667C8.39056 15.5 8.1667 15.7239 8.1667 16C8.1667 16.2761 8.39056 16.5 8.6667 16.5H15.4999V23.3333Z',
      }
    : {
        viewbox: '0 0 24 24',
        d: 'M11.2499 17.5C11.2499 17.9142 11.5857 18.25 11.9999 18.25C12.4142 18.25 12.7499 17.9142 12.7499 17.5V12.75H17.5C17.9142 12.75 18.25 12.4142 18.25 12C18.25 11.5858 17.9142 11.25 17.5 11.25H12.7499V6.5C12.7499 6.08579 12.4142 5.75 11.9999 5.75C11.5857 5.75 11.2499 6.08579 11.2499 6.5V11.25H6.50002C6.08581 11.25 5.75002 11.5858 5.75002 12C5.75002 12.4142 6.08581 12.75 6.50002 12.75H11.2499V17.5Z',
      }
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox={isThinVariant.viewbox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={isThinVariant.d}
        fill="currentColor"
        stroke={isThinVariant ? undefined : 'currentColor'}
      />
    </svg>
  )
}
