import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Overlay Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6726%3A12107
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismOverlayIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.overlayIcon} ${hasBackground ? Styles.hasBackground : ''} ${
        className ?? ''
      } `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="4.5" width="10" height="10" stroke="currentColor" />
      <rect x="9.5" y="9.5" width="10" height="10" stroke="currentColor" />
      <rect x="9" y="9" width="6" height="6" fill="currentColor" />
    </svg>
  )
}
