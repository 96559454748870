import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Detail Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6996%3A15486
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismDetailIcon = ({ hasBackground, isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${isActive ? Styles.active : ''} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <rect x="4.5" y="4.5" width="4" height="3" stroke="currentColor" />
      <rect x="11.5" y="4.5" width="8" height="15" stroke="currentColor" />
      <rect x="4.5" y="10.5" width="4" height="3" stroke="currentColor" />
      <rect x="4.5" y="16.5" width="4" height="3" stroke="currentColor" />
    </svg>
  )
}
