import React, { useState } from 'react'

import { PrismInput } from 'components/PrismInput/PrismInput'
import ToolSettingsModalTemplate, {
  ToolSettingsModalProps,
} from 'components/ToolSettingsModalTemplate/ToolSettingsModalTemplate'
import RegexPlaygroundModal from 'pages/RoutineOverview/Configure/Tools/RegexPlaygroundModal'
import { getRegex, getRegexCopy } from 'utils'

import Styles from './BarcodeToolSettingsModal.module.scss'

interface Props extends Omit<ToolSettingsModalProps, 'inferenceUserArg' | 'children'> {}

/**
 * Renders a modal that allows the user to change the expected barcode text of a barcode tool.
 */
const BarcodeToolSettingsModal = (props: Props) => {
  const { tool } = props

  const [currentRegex, setCurrentRegex] = useState<string>(tool.inference_user_args.regex || '')
  const [isRegexPlaygroundVisible, setIsRegexPlaygroundVisible] = useState(false)

  const handleRegexPlaygroundApply = (newRegex: string) => {
    setCurrentRegex(newRegex)
    setIsRegexPlaygroundVisible(false)
  }

  const regexIsValid = !!getRegex(currentRegex)

  return (
    <>
      <ToolSettingsModalTemplate
        inferenceUserArg={{
          key: 'regex',
          value: currentRegex,
        }}
        {...props}
        disableSave={!regexIsValid}
        title="Edit Expected Barcode"
      >
        <div className={Styles.title}>Pass Criteria</div>

        <div className={Styles.specificMatchContainer}>
          <PrismInput value={currentRegex} onChange={e => setCurrentRegex(e.target.value)} />

          <div className={Styles.settingsText}>
            {!regexIsValid && (
              <>
                <span className={Styles.errorText}>Regex is invalid</span>
                <br />
              </>
            )}
            <span>{getRegexCopy(currentRegex, 'detect-barcode')}</span>
            <br />
            <br />
            Use normal characters or{' '}
            <span className={Styles.blueText} onClick={() => setIsRegexPlaygroundVisible(true)}>
              Regex
            </span>{' '}
            to update pass criteria
            <>
              {' '}
              or{' '}
              <span className={Styles.blueText} onClick={() => setCurrentRegex('.*')}>
                click here to reset.
              </span>
            </>
          </div>
        </div>
      </ToolSettingsModalTemplate>
      <RegexPlaygroundModal
        visible={isRegexPlaygroundVisible}
        onCloseModal={() => setIsRegexPlaygroundVisible(false)}
        regexString={currentRegex}
        onApply={handleRegexPlaygroundApply}
      />
    </>
  )
}

export default BarcodeToolSettingsModal
