import React, { RefObject, useEffect, useMemo, useState } from 'react'

import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { useDefaultToolLabels, useQueryParams } from 'hooks'
import { QsFilters, ToolLabel, ToolSpecificationName } from 'types'
import {
  findSingleToolLabelFromPartialData,
  getDisplaySeverity,
  getLabelName,
  isNonTrainingLabel,
  sortByValueAndSeverity,
} from 'utils'
import { UNTRAINED_LABEL } from 'utils/labels'

import GroupCarousel from './GroupCarousel'
import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'

export type GroupData = {
  id: string
  label: React.ReactNode
  queryData: QsFilters
  showGallery: boolean
  isFirstGroup?: boolean
}

/**
 * This component render the Group By mode of the Labeling Gallery.
 * For each group, it renders a GroupCarousel.
 * The groups are shown according to the tool type and the selected Group By option.
 *
 * @param toolParentId - The current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param labels - Match tool labels
 * @param showInsights - Whether to show insights
 * @param selectedToolResults - The current selected toolResults
 * @param setSelectedToolResults - The function to set the selected toolResults
 * @param galleryWrapperRef - A ref from the labeling gallery wrapper, used to setup performant pagination
 */
const LabelingGroups = ({
  toolParentId,
  toolSpecificationName,
  labels,
  showInsights,
  selectedToolResults,
  setSelectedToolResults,
  galleryWrapperRef,
}: {
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  labels?: ToolLabel[]
  showInsights: boolean
  shiftPressed: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  galleryWrapperRef: RefObject<HTMLDivElement>
}) => {
  const [params] = useQueryParams()
  const { group_by } = params

  const defaultLabels = useDefaultToolLabels()
  const untrainedLabel = useMemo(
    () => findSingleToolLabelFromPartialData(defaultLabels, UNTRAINED_LABEL),
    [defaultLabels],
  )

  const [maxIdxToShow, setMaxIdxToShow] = useState<number>()
  const [groupsData, setGroupsData] = useState<GroupData[]>(
    getLabelingGroups(group_by || '', labels || [], params, untrainedLabel),
  )

  // This effect is in charge of updating the groups when needed.
  // It does not depend on params, as we just want to set this data whenever the group_by option changes,
  // params are only needed to know if the group is expanded, filters are handled by each group carousel
  useEffect(() => {
    setGroupsData(getLabelingGroups(group_by || '', labels || [], params, untrainedLabel))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_by, labels])

  return (
    <div className={Styles.groupsContainerLayout}>
      {groupsData
        .filter((_, idx) => maxIdxToShow === undefined || idx <= maxIdxToShow)
        .map((group, idx) => (
          <GroupCarousel
            key={group.id}
            group={group}
            setGroupsData={setGroupsData}
            toolParentId={toolParentId}
            toolSpecificationName={toolSpecificationName}
            groupByOption={group_by || ''}
            showInsights={showInsights}
            selectedToolResults={selectedToolResults}
            setSelectedToolResults={setSelectedToolResults}
            outerContainerRef={galleryWrapperRef}
            onHideNextCarousels={shouldHide => {
              if (shouldHide) setMaxIdxToShow(idx)
              // We only want to reset the condition if the currently opened carousel is the one that is either done fetching all pages or was closed
              if (!shouldHide && idx === maxIdxToShow) setMaxIdxToShow(undefined)
            }}
            data-testid="labeling-groups-carousel"
          />
        ))}
    </div>
  )
}

export default LabelingGroups

const getGroup = ({
  id,
  label,
  queryData,
  params,
  isFirstGroup,
}: Omit<GroupData, 'showGallery'> & { params?: { [key: string]: string | undefined } }): GroupData => {
  const showGallery = !!params?.expanded && params?.group_id === id
  return {
    id,
    label,
    queryData,
    showGallery,
    isFirstGroup,
  }
}

export const getLabelingGroups = (
  groupByOption: string,
  labels: ToolLabel[],
  params?: { [key: string]: string | undefined },
  untrainedLabel?: ToolLabel,
): GroupData[] => {
  if (groupByOption === 'predicted') {
    // Uncertain, Discard and TestSet can't be predicted, but untrained can.
    const labelsToUse = [...labels.filter(label => !isNonTrainingLabel(label))]
    if (untrainedLabel) labelsToUse.push(untrainedLabel)

    return labelsToUse.map((toolLabel, index) =>
      getGroup({
        id: `predicted-${toolLabel.id}`,
        label: (
          <PrismResultButton
            severity={getDisplaySeverity(toolLabel)}
            value={getLabelName(toolLabel)}
            type="noFill"
            size="small"
          />
        ),
        queryData: {
          prediction_label_id__in: toolLabel.id,
        },
        params,
        isFirstGroup: index === 0,
      }),
    )
  }

  if (groupByOption === 'label') {
    const labelGroups = labels.sort(sortByValueAndSeverity).map((toolLabel, index) =>
      getGroup({
        id: `label-${toolLabel.id}`,
        label: (
          <PrismResultButton
            severity={getDisplaySeverity(toolLabel)}
            value={getLabelName(toolLabel)}
            type="noFill"
            size="small"
          />
        ),
        queryData: {
          user_label_id__in: toolLabel.id,
        },
        params,
        isFirstGroup: index === 0,
      }),
    )

    return [
      ...labelGroups,
      getGroup({
        id: 'label-unlabeled',
        label: <span className={Styles.groupByCarouselTitle}>Unlabeled</span>,
        queryData: { user_label_set_isnull: 'true' },
        params,
      }),
    ]
  }

  return []
}
