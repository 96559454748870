import React, { useRef, useState } from 'react'

import moment from 'moment-timezone'

import { FrameWithMeta } from 'components/StreamListener'
import Video, { Props as VideoProps } from 'components/Video/Video'
import { useDateTimePreferences } from 'hooks'

import Styles from './Video.module.scss'

export type Props = Omit<VideoProps, 'framerate' | 'extraContent'> & {
  intervalMs?: number
  showTimestamp?: boolean
  'data-test-attribute'?: string
  timestampClassName?: string
  'data-testid'?: string
}

/**
 * Renders video with overlaid last frame timestamp. Putting this in its own
 * component means we can rerender as each new frame comes in without rendering
 * entire inspection screen.
 *
 * @param intervalMs - Camera frame interval in ms (inverse of framerate)
 * @param showTimestamp - Do we actually show the frame timestamp?
 *
 */
function VideoWithLastFrameTime({
  intervalMs,
  showTimestamp = true,
  onFrame,
  timestampClassName,
  'data-test-attribute': dataTestAttribute,
  'data-testid': dataTestId,
  ...rest
}: Props) {
  const [lastFrameTime, setLastFrameTime] = useState<number | null>(null)
  const messageIdRef = useRef<string>()

  const handleVideoFrame = ({ frame, meta }: FrameWithMeta) => {
    const messageId = meta?.message_id
    const timeStamp = messageId?.split('-')[0]
    const time = Number(timeStamp)
    // time can be NaN if timeStamp is undefined
    if (time) setLastFrameTime(time)
    messageIdRef.current = messageId
    if (onFrame) onFrame({ frame, meta, time })
  }

  const renderVideoTimestamp = () => {
    if (!lastFrameTime || !showTimestamp) return null

    return (
      <VideoTimestamp timestampClassName={timestampClassName} lastFrameTime={lastFrameTime} intervalMs={intervalMs} />
    )
  }

  return (
    <Video
      {...rest}
      data-test-attribute={dataTestAttribute}
      data-testid={dataTestId}
      framerate={intervalMs ? Math.min(24, 1000 / intervalMs) : undefined}
      onFrame={handleVideoFrame}
      extraContent={renderVideoTimestamp()}
    />
  )
}

export default VideoWithLastFrameTime

/**
 * Renders the video timestamp
 * 
 * @param timestampClassName - container className
 * @param lastFrameTime - last frame timestamp
 * @param intervalMs - routine interval_ms

*/
export const VideoTimestamp = ({
  timestampClassName,
  lastFrameTime,
  intervalMs,
}: {
  timestampClassName?: string
  lastFrameTime: number | null | undefined
  intervalMs?: number
}) => {
  const { timeWithMillisecondsFormat, timeWithSecondsFormat, timeZone } = useDateTimePreferences()

  let fmt: string = timeWithMillisecondsFormat

  if ((intervalMs || 0) > 1000) fmt = timeWithSecondsFormat
  return (
    <span className={`${Styles.timestamp} ${timestampClassName ?? ''}`}>
      {moment(lastFrameTime).tz(timeZone).format(fmt)}
    </span>
  )
}
