import React from 'react'

import { PrismCard } from 'components/PrismCard/PrismCard'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import { Tag } from 'components/Tag/Tag'
import { Token } from 'components/Token/Token'

import Styles from './ToolCard.module.scss'

/**
 * Renders a tool card which holds all information and status of a given
 * tool in the new batch screen.
 *
 * @param label - The lael of the subtext. In this case, it's always "Tool"
 * @param name - The name of the tool
 * @param optionMenu - The options to show for the tool
 * @param img - The image for the current tool
 * @param muted - whether the current tool is muted
 * @param onMouseEnter - Handler for when the mouse enters the current card
 * @param onMouseLeave - Handler for when the mouse leaves the current card
 */
export const ToolCard = ({
  label,
  name,
  optionMenu,
  img,
  muted,
  onMouseEnter,
  onMouseLeave,
  className = '',
  innerRightWrapperClassName = '',
  imageContainerClassName = '',
  'data-test-name': dataTestName,
}: {
  label: string
  name: string
  optionMenu: React.ReactNode
  img: React.ReactNode
  muted: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  className?: string
  innerRightWrapperClassName?: string
  imageContainerClassName?: string
  'data-test-name'?: string
}) => {
  return (
    <PrismCard
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type="notClickable"
      className={className}
      image={
        <figure className={`${Styles.camImageContainer} ${muted ? Styles.muted : ''}`}>
          {muted && (
            <div className={Styles.muteTokenContainer}>
              <Tag>Muted</Tag>
            </div>
          )}
          {img}
        </figure>
      }
      imageContainerClassName={imageContainerClassName}
    >
      <div className={`${Styles.multiCamItem} ${innerRightWrapperClassName}`}>
        <Token
          label={label}
          className={Styles.cardToken}
          labelClassName={Styles.cardName}
          valueClassName={Styles.cardValue}
          data-testid={dataTestName}
        >
          <PrismOverflowTooltip content={name} />
        </Token>

        {optionMenu}
      </div>
    </PrismCard>
  )
}
