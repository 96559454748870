/**
 * getterKeys is used in functions that save and read data from the state getter
 * branch.
 *
 * This ensures that the there is only one place where keys can be declared,
 * they can be reused, and also allows typing the getter branch.
 *
 * The functions that use getterKeys are typed depending on the key that is
 * passed. The type is declared in the GetterData type.
 */
import {
  ComponentsData,
  InspectionsData,
  ItemsData,
  RobotsData,
  RoutineParentsData,
  StationsData,
  ToolLabelsData,
  ToolResultsData,
  ToolSpecificationData,
} from 'api'
import {
  AoiAndLabelMetrics,
  BaslerHeartbeatStreamMessage,
  Component,
  Dataset,
  Eula,
  EventKind,
  EventsCount,
  Inspection,
  InspectionCountResult,
  Item,
  ItemExpanded,
  ItemsByRobotId,
  ListResponseData,
  Me,
  NotificationCounts,
  Organization,
  RecipeExpanded,
  RecipeParentExpanded,
  Robot,
  RobotDiscoveriesById,
  RoutineParent,
  RoutineSlot,
  RoutineWithAois,
  Station,
  StatusEntries,
  StreamReadMessage,
  TimeSeriesResult,
  Tool,
  ToolParent,
  ToolParentsCount,
  ToolResult,
  ToolResultCount,
  ToolResultEmptyPredictionOutcome,
  Toolsets,
  TrainingResult,
  TrainingResultFlat,
  VpHeartbeatStreamMessage,
  VpnHeartbeatStreamMessage,
  VpStatusStreamMessage,
} from 'types'
import { METRICS_COMPACTION_GETTER_KEY } from 'utils'

import {
  EventSubsData,
  EventTypesData,
  FlatInspectionData,
  ItemsExpandedData,
  RecipeParentsData,
  RecipeRoutineData,
  RecipesData,
  RtsResultsData,
  SitesData,
  SmartGroupsData,
  ToolParentsWithAoiData,
  UsersData,
} from './service'

/**
 * Functions used to construct keys for the getter branch.
 */
export const getterKeys = {
  // When adding a new key here, make sure to follow the pattern for casting the output.
  // For example, the template string `x/y/${foo}/z/${bar}` should be cast to 'x/y/*/z/*'.
  // By doing this we ensure that each getter key has a type corresponding to its path and that each path has only a single type.
  //
  // Moreover, if any two getter keys get cast to the same type then that must be because they could resolve to the same path,
  // and then the GetterData type corresponding to the two keys will be the same!
  me: () => 'me' as 'me',
  usersList: ({ params = '', hideElemUsers = false }: { params?: string; hideElemUsers?: boolean }) =>
    `usersList/${params}/${hideElemUsers ? 'hideElemUsers' : ''}` as 'usersList/*',
  users: () => 'users' as 'users',
  organization: () => 'organization' as 'organization',
  sites: (key?: string) => `sites/${key}` as 'sites/*',
  robot: (robotId: string) => `robots/${robotId}` as 'robots/*',
  robotStatus: (robotId: string) => `robots/status/${robotId}` as 'robots/status/*',
  robots: () => 'robots' as 'robots',
  routine: (routineId: string) => `routines/${routineId}` as 'routines/*',
  routineParent: (routineParentId: string) => `routineParents/${routineParentId}` as 'routineParents/*',
  routineParents: (key: string = '') => `routineParentsList/${key}` as 'routineParentsList/*',
  recipe: (recipeId: string) => `recipes/${recipeId}` as 'recipes/*',
  toolParentRecipeRoutines: (toolParentId: string) =>
    `toolParentRecipeRoutines/${toolParentId}` as 'toolParentRecipeRoutines/*',
  recipeParent: (recipeParentId: string) => `recipe_parents/${recipeParentId}` as 'recipe_parents/*',
  recipeParents: () => 'recipe_parents' as 'recipe_parents',
  filteredRecipeParents: (key: string, recipeFilter: 'all' | 'archived') =>
    `components/${key}/recipeParents/${recipeFilter}` as 'components/*/recipeParents/*',
  tool: (toolId: string) => `tools/${toolId}` as 'tools/*',
  toolSpecifications: () => 'tool_specifications' as 'tool_specifications',
  toolTotalUnlabeledCount: (toolId: string) =>
    `tools/total_unlabeled_count/${toolId}` as 'tools/total_unlabeled_count/*',
  items: (itemId: string) => `items/${itemId}` as 'items/*',
  component: (componentId: string) => `component/${componentId}` as 'component/*',
  components: (
    componentsKey:
      | 'all-unarchived'
      | 'archived'
      | 'subscribed'
      | 'not_subscribed'
      | 'select-options'
      | 'analyze'
      | `training-modal-${string}`,
  ) => `components/${componentsKey}` as 'components/*',
  inspection: (inspectionId: string) => `inspections/${inspectionId}` as 'inspections/*',
  inspectionRoutines: (inspectionId: string) => `inspections/${inspectionId}/routines` as 'inspections/*/routines',
  metricsMonitorItems: () => 'metrics/monitorItems' as 'metrics/monitorItems',
  metricsFilteredComponents: () => 'metrics/filteredComponents' as 'metrics/filteredComponents',
  metricsFilteredRecipes: () => 'metrics/filteredRecipes' as 'metrics/filteredRecipes',
  metricsFilteredInspectionsData: () => 'metrics/filteredInspectionsData' as 'metrics/filteredInspectionsData',
  metricsInspectionMonitorItems: () => 'metrics/inspectionMonitorItems' as 'metrics/inspectionMonitorItems',
  metricsInspectionLabels: () => 'metrics/inspectionLabels' as 'metrics/inspectionLabels',
  inspectionsForFiltersProxy: (key: string) => `/filtersProxy/inspections/${key}` as '/filtersProxy/inspections/*',
  analyticsBatches: () => '/analytics/batches' as '/analytics/batches',
  analyticsBatch: (id: string) => `/analytics/batches/${id}` as '/analytics/batches/*',
  analyticsStations: () => '/analytics/stations' as '/analytics/stations',
  analyticsStation: (id: string) => `/analytics/stations/${id}` as '/analytics/stations/*',
  analyticsProducts: () => '/analytics/products' as '/analytics/products',
  analyticsProduct: (id: string) => `/analytics/products/${id}` as '/analytics/products/*',
  robotToolsets: (robotId: string) => `robotToolsets/${robotId}` as 'robotToolsets/*',
  analyticsItems: () => 'analytics/items' as 'analytics/items',
  inspectionItemsByRobot: (inspectionId: string) =>
    `inspections/${inspectionId}/itemsByRobotId` as 'inspections/*/itemsByRobotId',
  toolLabels: (toolParentId: string) => `tool/labels/${toolParentId}` as 'tool/labels/*',
  toolResults: () => 'toolResults' as 'toolResults',
  toolResult: (toolResultId: string) => `toolResult/${toolResultId}` as 'toolResult/*',
  toolResultCounts: (toolParentId: string, countsType: 'training-labels' | 'test-set' | 'whitelisted') =>
    `tool/${toolParentId}/${countsType}/counts` as 'tool/*/*/counts',
  toolParent: (toolParentId: string) => `toolIdentifers/${toolParentId}` as 'toolIdentifers/*',
  toolParentsCount: () => 'toolParents/count' as 'toolParents/count',
  toolParentsWithAoi: () => 'toolParentsWithAoi' as 'toolParentsWithAoi',
  toolParentToolResults: (toolParentId: string) =>
    `toolParents/${toolParentId}/toolResults` as 'toolParents/*/toolResults',
  rtsMetrics: (
    type: 'items' | 'aois',
    inspectionId: string,
    metricsCompaction: METRICS_COMPACTION_GETTER_KEY,
    keySuffix: string = '',
  ) =>
    `rtsMetrics/${type}/inspection/${inspectionId}/compaction/${metricsCompaction}/${keySuffix}` as 'rtsMetrics/*/inspection/*/compaction/*/*',
  aoiAndLabelMetrics: (inspectionId: string) =>
    `inspections/${inspectionId}/aoiAndLabelMetrics` as 'inspections/*/aoiAndLabelMetrics',
  stations: (stationsKey: 'all' | 'all-with-robots' | 'subscribed' | 'not_subscribed' | 'select-options') =>
    `stations/${stationsKey}` as 'stations/*',
  station: (id: string) => `station/${id}` as 'station/*',
  colocatedStation: () => 'station/colocated' as 'station/colocated',
  inspectionCounts: (stationId: string) =>
    `components/${stationId}/inspectionCounts` as 'components/*/inspectionCounts',
  toolTrainingResults: (toolId: string) => `toolTrainingResults/${toolId}` as 'toolTrainingResults/*',
  inspectionRecentItems: (inspectionId: string) =>
    `inspections/${inspectionId}/recentItems` as 'inspections/*/recentItems',
  inspectionRecentToolResults: (inspectionId: string) =>
    `inspections/${inspectionId}/recentToolResults` as 'inspections/*/recentToolResults',
  stationDetailToolsLatestToolResults: (aoiId: string) =>
    `inspections/${aoiId}/failedRecentToolResults` as 'inspections/*/failedRecentToolResults',
  stationOverviewFailedItems: (inspectionId: string) =>
    `inspections/${inspectionId}/failedItems` as 'inspections/*/failedItems',
  stationDetailTimelineItems: (inspectionId: string) =>
    `inspections/${inspectionId}/timelineItems` as 'inspections/*/timelineItems',
  stationDetailInspections: (stationId: string) => `inspections/${stationId}/list` as 'inspections/*/list',
  toolResultsByThreshold: (inspectionId: string, order: string) =>
    `thresholdToolResults/${inspectionId}/${order}` as 'thresholdToolResults/*/*',
  detailToolResult: (toolResultId: string) => `detailToolResult/${toolResultId}` as 'detailToolResult/*',
  smartGroups: (groupsId: string) => `smartGroups/${groupsId}` as 'smartGroups/*',
  robotDiscovery: (robotId: string) => `robotDiscovery/${robotId}` as 'robotDiscovery/*',
  eula: () => 'eula' as 'eula',
  trainingResultsExpandedByToolComponentAndLabelId: (toolId: string, componentId: string, labelId: string) =>
    `/trainingResultsExpandedByToolId/${componentId}/${toolId}/${labelId}` as '/trainingResultsExpandedByToolId/*/*/*',
  fullScreenTrainingResultsExpanded: () => '/fullScreenTrainingResultsExpanded' as 'fullScreenTrainingResultsExpanded',
  toolParentFilteredInspections: (toolParentId: string) =>
    `toolParentFilteredInspections/${toolParentId}` as 'toolParentFilteredInspections/*',
  toolParentRoutineParents: (toolParentId: string) =>
    `toolParentRoutineParents/${toolParentId}` as 'toolParentRoutineParents/*',
  toolParentComponents: (toolParentId: string) => `toolParentComponents/${toolParentId}` as 'toolParentComponents/*',
  recipeParentsFilterOptions: (toolParentId: string) =>
    `recipeParentFilterOptions/${toolParentId}` as 'recipeParentFilterOptions/*',
  dataset: (datasetId: string) => `dataset/${datasetId}` as 'dataset/*',
  robotRoutineSlots: (robotId: string) => `robotRoutineSlots/${robotId}` as 'robotRoutineSlots/*',
  eventSubs: (type: 'realSub' | 'targetTemplate' | 'typeTemplate') => `eventSubs/${type}` as 'eventSubs/*',
  notificationCounts: (targetTable: 'station' | 'component') =>
    `notificationCounts/${targetTable}` as 'notificationCounts/*',
  eventTypes: (eventKind: EventKind) => `eventTypes/${eventKind}` as 'eventTypes/*',
  eventsCounts: (key: string = '') => `eventsCounts/${key}` as 'eventsCounts/*',
  inspectionsMetricsByInspectionId: (type: 'items' | 'labels') =>
    `inspectionMetricsByInspectionId/${type}/` as 'inspectionMetricsByInspectionId/*',
}

/**
 * Helper type for return type of getterKeys[key] so we don't have to type it every time
 */
type GRT<GK extends keyof typeof getterKeys> = ReturnType<(typeof getterKeys)[GK]>

export type GetterData<T> = T extends GRT<'me'>
  ? Me
  : T extends GRT<'organization'>
  ? Organization
  : T extends GRT<'sites'>
  ? SitesData
  : T extends GRT<'robot'>
  ? Robot
  : T extends GRT<'robotStatus'>
  ? StatusEntries
  : T extends GRT<'routine'>
  ? RoutineWithAois
  : T extends GRT<'routineParent'>
  ? RoutineParent
  : T extends GRT<'routineParents'>
  ? RoutineParentsData
  : T extends GRT<'items'>
  ? ItemExpanded
  : T extends GRT<'component'>
  ? Component
  : T extends GRT<'components'>
  ? ComponentsData
  : T extends GRT<'inspection'>
  ? Inspection
  : T extends GRT<'inspectionRoutines'>
  ? ListResponseData<RoutineWithAois>
  : T extends GRT<'metricsMonitorItems'>
  ? RtsResultsData
  : T extends GRT<'metricsFilteredComponents'>
  ? ComponentsData
  : T extends GRT<'metricsFilteredRecipes'>
  ? RecipesData
  : T extends GRT<'recipe'>
  ? RecipeExpanded
  : T extends GRT<'toolParentRecipeRoutines'>
  ? RecipeRoutineData
  : T extends GRT<'recipeParent'>
  ? RecipeParentExpanded
  : T extends GRT<'recipeParents'>
  ? RecipeParentsData
  : T extends GRT<'filteredRecipeParents'>
  ? RecipeParentsData
  : T extends GRT<'metricsFilteredInspectionsData'>
  ? InspectionsData
  : T extends GRT<'metricsInspectionMonitorItems'>
  ? RtsResultsData
  : T extends GRT<'metricsInspectionLabels'>
  ? RtsResultsData
  : T extends GRT<'users'>
  ? UsersData
  : T extends GRT<'usersList'>
  ? UsersData
  : T extends GRT<'robotToolsets'>
  ? Toolsets
  : T extends GRT<'analyticsItems'>
  ? ItemsData
  : T extends GRT<'inspectionItemsByRobot'>
  ? ItemsByRobotId
  : T extends GRT<'toolLabels'>
  ? ToolLabelsData
  : T extends GRT<'toolResultCounts'>
  ? ToolResultCount
  : T extends GRT<'toolParent'>
  ? ToolParent
  : T extends GRT<'toolParentsWithAoi'>
  ? ToolParentsWithAoiData
  : T extends GRT<'toolParentsCount'>
  ? ToolParentsCount
  : T extends GRT<'rtsMetrics'>
  ? RtsResultsData
  : T extends GRT<'aoiAndLabelMetrics'>
  ? AoiAndLabelMetrics
  : T extends GRT<'stations'>
  ? StationsData
  : T extends GRT<'station'>
  ? Station
  : T extends GRT<'colocatedStation'>
  ? Station
  : T extends GRT<'tool'>
  ? Tool
  : T extends GRT<'toolSpecifications'>
  ? ToolSpecificationData
  : T extends GRT<'toolTotalUnlabeledCount'>
  ? RtsResultsData
  : T extends GRT<'robots'>
  ? RobotsData
  : T extends GRT<'inspectionCounts'>
  ? { results: InspectionCountResult[] }
  : T extends GRT<'toolParentToolResults'>
  ? ToolResultsData
  : T extends GRT<'inspectionRecentItems'>
  ? ListResponseData<ItemExpanded>
  : T extends GRT<'toolResults'>
  ? ToolResultsData
  : T extends GRT<'toolResult'>
  ? ToolResult
  : T extends GRT<'inspectionRecentToolResults'>
  ? ToolResultsData
  : T extends GRT<'stationDetailTimelineItems'>
  ? ListResponseData<ItemExpanded | Item>
  : T extends GRT<'stationOverviewFailedItems'>
  ? ItemsExpandedData
  : T extends GRT<'stationDetailToolsLatestToolResults'>
  ? ToolResultsData
  : T extends GRT<'toolTrainingResults'>
  ? ListResponseData<TrainingResultFlat>
  : T extends GRT<'stationDetailInspections'>
  ? InspectionsData
  : T extends GRT<'toolResultsByThreshold'>
  ? ToolResultsData
  : T extends GRT<'detailToolResult'>
  ? ToolResultEmptyPredictionOutcome
  : T extends GRT<'inspectionsForFiltersProxy'>
  ? FlatInspectionData
  : T extends GRT<'analyticsBatches'>
  ? InspectionsData
  : T extends GRT<'analyticsBatch'>
  ? Inspection
  : T extends GRT<'analyticsStations'>
  ? StationsData
  : T extends GRT<'analyticsStation'>
  ? Station
  : T extends GRT<'analyticsProducts'>
  ? ComponentsData
  : T extends GRT<'analyticsProduct'>
  ? Component
  : T extends GRT<'smartGroups'>
  ? SmartGroupsData
  : T extends GRT<'robotDiscovery'>
  ? RobotDiscoveriesById
  : T extends GRT<'eula'>
  ? Eula
  : T extends GRT<'trainingResultsExpandedByToolComponentAndLabelId'>
  ? ListResponseData<TrainingResult>
  : T extends GRT<'fullScreenTrainingResultsExpanded'>
  ? ListResponseData<TrainingResult>
  : T extends GRT<'toolParentFilteredInspections'>
  ? InspectionsData
  : T extends GRT<'toolParentRoutineParents'>
  ? RoutineParentsData
  : T extends GRT<'toolParentComponents'>
  ? ComponentsData
  : T extends GRT<'recipeParentsFilterOptions'>
  ? RecipeParentsData
  : T extends GRT<'dataset'>
  ? Dataset
  : T extends GRT<'robotRoutineSlots'>
  ? RoutineSlot[]
  : T extends GRT<'eventTypes'>
  ? EventTypesData
  : T extends GRT<'notificationCounts'>
  ? NotificationCounts
  : T extends GRT<'eventsCounts'>
  ? EventsCount
  : T extends GRT<'eventSubs'>
  ? EventSubsData
  : T extends GRT<'inspectionsMetricsByInspectionId'>
  ? Record<string, TimeSeriesResult[]>
  : never

// Use these functions to generate paths used to hit stream or video websocket endpoints
export const wsPaths = {
  multiStream: () => '/atom_ws/streams',
  multiStreamOrganization: () => '/atom_ws/organization_streams',
  smartGroupsEvents: (parentId: string, groupsId: string) =>
    `/atom_ws/organization_stream/smart_groups_events:${parentId}:${groupsId}`,
  organizationEvents: () => '/atom_ws/organization_stream/organization_events',
  inspectionEvents: (inspectionId: string) => `/atom_ws/django_stream/inspection_events/${inspectionId}`,
  statusLog: (logStream: string, robotId: string) => `/atom_ws/stream/status/${logStream}/${robotId}`,
  visionProcessingStatus: (robotId: string) => `/atom_ws/stream/vision-processing/status/${robotId}`,
  visionProcessingHeartbeat: (robotId: string) => `/atom_ws/stream/vision-processing/heartbeat/${robotId}`,
  visionProcessingItemUploadQueue: (robotId: string) => `/atom_ws/stream/vision-processing/item_uploads/${robotId}`,
  assetManagementToolsets: (robotId: string) => `/atom_ws/stream/asset-management/tool-sets:download/${robotId}`,
  // Video paths
  videoBaslerStandard: (robotId: string) => `/atom_ws/video/transcoder-basler-image-standard/compressed/${robotId}`,
  videoBaslerHigh: (robotId: string) => `/atom_ws/video/transcoder-basler-image-high/compressed/${robotId}`,
  videoBaslerHighest: (robotId: string) => `/atom_ws/video/transcoder-basler-image-highest/compressed/${robotId}`,
  videoBaslerMedium: (robotId: string) => `/atom_ws/video/transcoder-basler-image-medium/compressed/${robotId}`,
  videoBaslerThumbnail: (robotId: string) => `/atom_ws/video/transcoder-basler-image-thumbnail/compressed/${robotId}`,
  videoVisionTriggerMedium: (robotId: string) =>
    `/atom_ws/video/transcoder-vision-trigger-image-medium/compressed/${robotId}`,
  videoVisionProcessingInsight: (robotId: string) => `/atom_ws/video/vision-processing-INSIGHT-0/insight/${robotId}`,
  videoMultiStream: () => '/atom_ws/videos',
  halSettings: (robotId: string) => `/atom_ws/stream/hal/settings/${robotId}`,
}

/**
 * Use these fuctions to build keys for message lists stored in "events" branch, instead of building keys by hand.
 */
export const wsKeys = {
  // When adding a new key here, make sure to follow the pattern for casting the output to a string matching the template
  // string. For example, the template string `x/y/${foo}/z/${bar}` should be cast to 'x/y/*/z/*'.
  // By doing this we ensure that each getter key has a type corresponding to its path and that each path has only a single type.
  //
  // Moreover, if any two getter keys get cast to the same type then that must be because they could resolve to the same path,
  // and then the GetterData type corresponding to the two keys will be the same!
  vpStatus: (robotId: string) => `robot-vp-status/${robotId}` as 'robot-vp-status/*',
  vpHeartbeat: (robotId: string) => `robot-vp-heartbeat/${robotId}` as 'robot-vp-heartbeat/*',
  vpnHeartbeat: (robotId: string) => `robot-vpn-heartbeat/${robotId}` as 'robot-vpn-heartbeat/*',
  baslerHeartbeat: (robotId: string) => `robot-basler-heartbeat/${robotId}` as 'robot-basler-heartbeat/*',
  streamRead: (robotId: string, stream: 'vpnStatus' | 'vpHeartbeat' | 'baslerHeartbeat') =>
    `streamRead/${stream}/${robotId}` as 'streamRead/*/*',
  streamListening: (robotId: string, stream: 'vpnStatus' | 'vpHeartbeat' | 'baslerHeartbeat') =>
    `streamListening/${stream}/${robotId}` as 'streamListening/*/*',
}

type WKT<WK extends keyof typeof wsKeys> = ReturnType<(typeof wsKeys)[WK]>

export type WsData<T> = T extends WKT<'vpStatus'>
  ? VpStatusStreamMessage
  : T extends WKT<'vpHeartbeat'>
  ? VpHeartbeatStreamMessage
  : T extends WKT<'vpnHeartbeat'>
  ? VpnHeartbeatStreamMessage
  : T extends WKT<'baslerHeartbeat'>
  ? BaslerHeartbeatStreamMessage
  : T extends WKT<'streamRead'>
  ? StreamReadMessage
  : T extends WKT<'streamListening'>
  ? StreamReadMessage
  : never
