import React from 'react'

import { isNil } from 'lodash'

import { PrismSuccessIcon, PrismWarningIcon } from 'components/prismIcons'

import Styles from './DeployedVersion.module.scss'

/**
 * Renders a deployed version with an icon
 *
 * @param latestRecipeVersion -
 * @param latestDeployedVersion -
 * @param className - for additional styles
 */
const DeployedVersion = ({
  latestRecipeVersion,
  latestDeployedVersion,
  className = '',
}: {
  latestRecipeVersion?: number
  latestDeployedVersion?: number
  className?: string
}) => {
  const renderVersion = () => {
    if (isNil(latestDeployedVersion)) return <span className={Styles.deployedVersionEmpty}>--</span>
    let icon = <PrismSuccessIcon isActive />
    if (latestRecipeVersion !== latestDeployedVersion) icon = <PrismWarningIcon isActive />
    return (
      <>
        <span className={Styles.deployedVersionIcon}>{icon}</span>
        <span>{`v${latestDeployedVersion}`} </span>
      </>
    )
  }

  return <div className={`${Styles.deployedVersionContainer} ${className}`}>{renderVersion()}</div>
}

export default DeployedVersion
