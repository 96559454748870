import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Filter Icon
 *
 * * Reference: {https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=10461-2299&mode=design&t=zEyecIibUW44nuxQ-0}
 *
 * Guide
 * 1) Add the iconName in the component name like Prism{iconName}Icon
 * 2) Before inserting the svg, make sure that the viewBox, or width and height are equal to 24px.
 * 3) Copy de the new svg body, without the svg tag and insert the path.
 * 4) if the icon uses stroke or fill to add color, replace the color value for 'currentColor'
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismFilterIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M2 1L5 6V11L7 9V6L10 1H2Z" fill="currentColor" />
    </svg>
  )
}
