import React, { ReactNode } from 'react'

import { log } from 'utils/utils'

type Props = {
  onError: (error: any, errorInfo: any) => void
  children: ReactNode
}

type State = {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    log('ErrorBoundary.tsx', 'Catching an error', [error, errorInfo])
    this.props.onError(error, errorInfo)
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

export default ErrorBoundary
