import { useRef } from 'react'
import React from 'react'

import { useHistory } from 'react-router-dom'

import { getterKeys } from 'api'
import { AoiOutlineConfiguration } from 'components/ImageWithBoxes/AoiOutline'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { getFromCache } from 'components/Img/ImgFallback'
import { useConnectionStatus, useData, usePrevious } from 'hooks'
import { AreaOfInterestExpanded } from 'types'
import { appendItemOrToolResultIdPictureIdOrLastSelectedToQs, evaluateOutcomes } from 'utils'

import Styles from '../StationDetail.module.scss'

interface ImageWithAoiBoxesProps {
  inspectionId: string | undefined
  aois: AreaOfInterestExpanded[] | undefined
  robotId: string
  insightsEnabled?: boolean
  insightsToolsHidden?: { [toolId: string]: boolean }
  isLoading?: boolean
  'data-testid'?: string
  'data-test'?: string
}

/**
 * Renders an image with overlayed aoi boxes colored depening on their outcome
 * @param inspectionId - used to get currentItem
 * @param robotId - The currently selected robot ID
 * @param aois - used to determine where and what color to draw boxes
 * @param insightsEnabled - Whether we should show insights on the image
 */
export function ImageWithAoiBoxes({
  inspectionId,
  aois,
  robotId,
  insightsEnabled,
  insightsToolsHidden,
  isLoading,
  'data-testid': dataTestId,
  'data-test': dataTest,
}: ImageWithAoiBoxesProps) {
  const history = useHistory()
  const connectionStatus = useConnectionStatus()
  // Image URLs we've gotten for current item's first picture
  const currentImageUrlsRef = useRef<Set<string>>(new Set())

  const inspectionItemsByRobot = useData(inspectionId ? getterKeys.inspectionItemsByRobot(inspectionId) : undefined)
  const currentItem = inspectionItemsByRobot?.[robotId]

  // We need to still use this image in case we don't have a robotID stored in the picture entry
  const fallbackFirstImagePicture = currentItem?.pictures[0]

  const pictureId =
    currentItem?.pictures.find(picture => picture.robot_id === robotId)?.id || fallbackFirstImagePicture?.id
  const prevPictureId = usePrevious(pictureId)

  // If we get a new picture, clear sets of images we've seen for current picture; we don't put this code in an effect because effects run after render, by which time we could already have rendered a stale URL for our previous image
  if (prevPictureId !== pictureId) {
    currentImageUrlsRef.current.clear()
  }

  const picture = currentItem?.pictures.find(picture => picture.robot_id === robotId) || fallbackFirstImagePicture

  let { image } = picture || {}

  if (image) currentImageUrlsRef.current.add(image)

  for (const url of currentImageUrlsRef.current) {
    if (getFromCache(url)?.state === 'success') image = url
  }
  const noImageYet = !image

  return (
    <ImageWithBoxes
      src={image}
      boundingBoxes={
        picture &&
        aois &&
        aois.map<AoiOutlineConfiguration>(aoi => {
          const isAlignmentAoi = aoi.tools.some(tool => tool.specification_name === 'alignment')
          const aoiToolResults = picture.tool_results.filter(toolResult => toolResult.aoi?.id === aoi.id)

          const evaluatedOutcome = evaluateOutcomes(aoiToolResults.map(toolResult => toolResult.calculated_outcome))
          const isInsightDisabled = aoi.tools.some(tool => insightsToolsHidden?.[tool.id])

          return {
            ...aoi,
            top: aoi.y,
            left: aoi.x,
            outcomeOrStatus: evaluatedOutcome,
            fill: isAlignmentAoi ? 'transparent' : undefined,
            overlaySrc: insightsEnabled && !isInsightDisabled ? aoiToolResults?.[0]?.insight_image : undefined,
          }
        })
      }
      imageClassName={noImageYet ? Styles.blurImage : ''}
      onClick={() => {
        if (connectionStatus === 'offline' || !picture || !currentItem) return

        appendItemOrToolResultIdPictureIdOrLastSelectedToQs(history, { itemId: currentItem.id, pictureId: picture.id })
      }}
      showLoader={isLoading}
      data-testid={dataTestId}
      data-test={dataTest}
    />
  )
}
