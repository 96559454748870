import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Dropper Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4043%3A1
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismDropperIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.535 9.99784L11.8885 9.64429L11.535 9.29074L10.6511 8.40685L13.126 5.93198L14.0098 6.81586L14.3634 7.16942L14.7169 6.81586L16.8383 4.69454L18.2525 6.10876L19.6667 7.52297L17.5454 9.64429L17.1918 9.99784L17.5454 10.3514L18.4293 11.2353L15.9544 13.7102L15.0705 12.8263L14.7169 12.4727L14.3634 12.8263L8.59653 18.5931L5.77956 19.1721C5.42784 19.2444 5.11685 18.9334 5.18913 18.5817L5.76811 15.7647L11.535 9.99784Z"
        stroke="currentColor"
      />
    </svg>
  )
}
