import { WsData, wsKeys } from 'api'
import { CameraStatus, EventsBranch, RootState, ToolsetDownloadStreamMessage, ToolsetStatus } from 'types'

import { CAMERA_STATUS_BY_VP_STATUS, HEARTBEAT_DISCONNECTED_TIMEOUT } from './constants'
import { getIsColocated } from './utils'

/**
 * Retrieves messages from the events branch of the state tree.
 *
 * @param events - Current events branch of the state tree
 * @param key - Key in events branch
 *
 * @returns Messages array at key if present
 */
export function getMessages<T extends keyof typeof wsKeys, U extends ReturnType<(typeof wsKeys)[T]>>(
  events: EventsBranch<WsData<U>>,
  key?: U,
) {
  if (!key) return undefined
  return events[key]
}

/**
 * Takes a robot id and state and returns robot VP status message.
 *
 * @param robotId - Robot id
 * @param state - Redux state
 *
 * @returns The last Robot VP status message or undefined if we don't have messages
 */
export function getRobotVpStatusMessage(robotId: string, state: RootState) {
  const messages = getMessages(state.events, wsKeys.vpStatus(robotId))
  if (!messages || !messages[0]) return

  return messages[0]
}

/**
 * Takes a robot id and state and returns the current inspection id for that
 * robot, by looking in the state update stream published by VP.
 *
 * @param robotId - Robot id
 * @param state - Redux state
 *
 * @returns Robot's current inspection id, undefined if robot currently has no inspection loaded,
 *  or null if we don't yet have messages to know
 */
export function getRobotInspectionId(robotId: string, state: RootState) {
  const message = getRobotVpStatusMessage(robotId, state)
  if (!message) return null
  return message.payload.data.inspection_id
}

/**
 * Takes a robot id and state and returns robot VP status.
 *
 * @param robotId - Robot id
 * @param state - Redux state
 *
 * @returns Robot VP status
 */
export function getRobotVpStatus(robotId: string, state: RootState) {
  return getRobotVpStatusMessage(robotId, state)?.payload.status
}

/**
 * Get robot status, based on VP status stream, plus optionally IOT heartbeat
 * and vpn_event streams, all read from Redux.
 *
 * NOTE: if you don't have a RobotsStatusListener for this robot in the render
 * tree, this function will always return disconnected.
 *
 * We should be careful using this function to prevent the user from trying to
 * do something that's critical, because we can't be sure whether the robot will
 * respond if you send it a command, regardless of what this function returns.
 *
 * @param robotId - Id of robot whose status we're getting
 * @param state - Redux state
 *
 * @returns Robot status
 */
export function getRobotStatus(robotId: string, options: { state: RootState }): CameraStatus {
  const { state } = options
  const { colocatedRobotIds } = getIsColocated({ state })

  // Ignore VPN status for colocated robots
  if (!colocatedRobotIds.includes(robotId)) {
    const vpnStatus = getVpnStatus(robotId, state)
    if (vpnStatus !== 'connected') return vpnStatus
  }

  const baslerHeartbeat = getBaslerHeartbeat(robotId, state)

  if (baslerHeartbeat !== 'connected') return baslerHeartbeat

  const vpHeartbeat = getVpHeartbeat(robotId, colocatedRobotIds.includes(robotId), state)
  if (vpHeartbeat !== 'connected') return vpHeartbeat

  const vpState = getRobotVpStatus(robotId, state)
  if (!vpState) return 'loading'
  return CAMERA_STATUS_BY_VP_STATUS[vpState] || 'disconnected'
}

/**
 * Get VPN connection status based on IOT heartbeat and vpn_event streams.
 *
 * @param robotId - Id of robot whose status we're getting
 * @param state - Redux state
 *
 * @returns Connection status
 */
export function getVpnStatus(robotId: string, state: RootState) {
  const vpnStatusListeningStarted = getMessages(state.events, wsKeys.streamListening(robotId, 'vpnStatus'))?.[0]
  const lastVpnStatusRead = getMessages(state.events, wsKeys.streamRead(robotId, 'vpnStatus'))?.[0]

  const vpnHeartbeat = getMessages(state.events, wsKeys.vpnHeartbeat(robotId))?.[0]

  if (!vpnStatusListeningStarted) return 'loading'

  if (!lastVpnStatusRead) {
    if (Date.now() - vpnStatusListeningStarted.receivedMs < HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

    return 'disconnected'
  }

  if (Date.now() - lastVpnStatusRead.receivedMs > HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

  // If there's been no heartbeat, it's definitely offline
  if (!vpnHeartbeat) return 'disconnected'

  // If there hasn't been VPN heartbeat in a long time, it's offline;
  return vpnHeartbeat.messageMs > Date.now() - HEARTBEAT_DISCONNECTED_TIMEOUT ? 'connected' : 'disconnected'
}

/**
 * Get VP internet connection status based on heartbeat.
 *
 * @param robotId - Id of robot whose status we're getting
 * @param isColocated - If this robot id is colocated
 * @param state - Redux state
 *
 * @returns Connection status
 */
export function getVpHeartbeat(robotId: string, isColocated: boolean, state: RootState) {
  const vpHeartbeatListeningStarted = getMessages(state.events, wsKeys.streamListening(robotId, 'vpHeartbeat'))?.[0]
  const lastVpHeartbeatRead = getMessages(state.events, wsKeys.streamRead(robotId, 'vpHeartbeat'))?.[0]

  const vpHeartbeat = getMessages(state.events, wsKeys.vpHeartbeat(robotId))?.[0]
  // TODO: once camera consolidation goes in, we'll want to implement a vpRead event,
  // similiar to the vpnRead event, to be able to handle loading states

  if (!vpHeartbeatListeningStarted) return 'loading'

  if (!lastVpHeartbeatRead) {
    if (Date.now() - vpHeartbeatListeningStarted.receivedMs < HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

    return 'disconnected'
  }

  if (Date.now() - lastVpHeartbeatRead.receivedMs > HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

  if (!vpHeartbeat) return 'disconnected'

  if (!vpHeartbeat.payload.is_online && !isColocated) return 'disconnected'

  // If there hasn't been VP heartbeat in double the amount of time it publishes, VP isn't up;
  return vpHeartbeat.messageMs > Date.now() - HEARTBEAT_DISCONNECTED_TIMEOUT ? 'connected' : 'disconnected'
}

export function getBaslerHeartbeat(robotId: string, state: RootState) {
  const baslerHeartbeatListeningStarted = getMessages(
    state.events,
    wsKeys.streamListening(robotId, 'baslerHeartbeat'),
  )?.[0]
  const lastBaslerHeartbeatRead = getMessages(state.events, wsKeys.streamRead(robotId, 'baslerHeartbeat'))?.[0]

  if (!baslerHeartbeatListeningStarted) return 'loading'

  if (!lastBaslerHeartbeatRead) {
    if (Date.now() - baslerHeartbeatListeningStarted.receivedMs < HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

    return 'disconnected'
  }

  if (Date.now() - lastBaslerHeartbeatRead.receivedMs > HEARTBEAT_DISCONNECTED_TIMEOUT) return 'loading'

  const baslerHeartbeat = getMessages(state.events, wsKeys.baslerHeartbeat(robotId))?.[0]

  if (!baslerHeartbeat) return 'disconnected'

  return baslerHeartbeat.messageMs > Date.now() - HEARTBEAT_DISCONNECTED_TIMEOUT ? 'connected' : 'disconnected'
}

/**
 * Update toolset status using download stream message.
 *
 * @param message - Toolset download stream message
 * @param prevToolsetStatus - Previous toolset status
 *
 * @returns Updated tool set status
 */
export function toolsetStatusFromDownloadMessage(
  message: ToolsetDownloadStreamMessage,
  prevToolsetStatus?: ToolsetStatus,
): ToolsetStatus {
  const { progress, status, tool_set_id, time_s } = message.payload
  const state =
    status === 'complete'
      ? 'LOADED'
      : (status.toUpperCase() as Exclude<ToolsetStatus['state'], 'MISSING' | 'CORRUPTED'>)

  if (prevToolsetStatus) {
    prevToolsetStatus.state = state
    prevToolsetStatus.metadata.progress = progress
    if (!prevToolsetStatus.metadata.deployed_at) prevToolsetStatus.metadata.deployed_at = time_s
    return prevToolsetStatus
  }

  return {
    name: tool_set_id,
    state,
    metadata: {
      deployed_at: time_s,
      progress,
    },
  }
}
