import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FixedSizeGrid } from 'react-window'
import { GridElementScrollerChildrenProps, ReactWindowElementScroller } from 'react-window-element-scroller'

import { Button } from 'components/Button/Button'
import { CardBox } from 'components/CardBox/CardBox'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import VirtualizedCarousel from 'components/VirtualizedCarousel/VirtualizedCarousel'
import { useContainerDimensions, useGridDimensions, useQueryParams } from 'hooks'
import { ToolResult } from 'types'
import { sleep, waitForScrollToEnd } from 'utils'
import {
  LABELING_GALLERY_CARD_HEIGHT,
  LABELING_GALLERY_CARD_HEIGHT_WITH_WRAPPER_PADDING,
  LABELING_GALLERY_CARD_MIN_WIDTH,
  LABELING_GALLERY_GRID_GAP,
} from 'utils/constants'

import { MemoGalleryCard } from './GalleryCard'
import GalleryCardsGrid from './GalleryCardsGrid'
import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'
import { handleHoveredRange, useGalleryShiftKeyPress } from './LabelingGalleryBody'

interface Props {
  toolParentId: string
  showInsights: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  outerContainerRef: React.RefObject<HTMLElement>
  groupLabel: React.ReactNode
  groupId: string
  toolResults?: ToolResult[]
  loadingToolResults: boolean
  handleEndReached: () => Promise<any>
  groupToolResultsCount?: string | number
  carouselWrapperRef: React.RefObject<HTMLDivElement>
  showGallery: boolean
  handleShowGallery: () => any
  isFirstGroup: boolean
  'data-testid'?: string
  'data-test'?: string
}

/**
 * This component wraps the functionality for toolResults groups, used for standard and smart groups.
 * It handles the rendering of carousel and gallery mode, single card and range selection.
 *
 * @param toolParentId - The current tool parent id
 * @param showInsights - Whether to show insights
 * @param selectedToolResults - The current selected toolResults
 * @param setSelectedToolResults - Function that sets the current selected toolResults
 * @param outerContainerRef - The outer container ref, use by ReactWindowElementScroller
 * @param groupLabel - The label to show on top of the group content
 * @param groupId - The group id
 * @param toolResults - The toolResults to show in the group
 * @param loadingToolResults - Whether the toolResults are loading
 * @param handleEndReached - Function that handles the carousel end reached event
 * @param groupToolResultsCount - The number of toolResults in the group
 * @param carouselWrapperRef - The wrapper div ref
 * @param showGallery - Whether to show the gallery
 * @param handleShowGallery - Function to handle the show gallery button click
 * @param isFirstGroup - Whether the current group is the first one in the list
 */
const LabelingGroupWrapper = ({
  toolParentId,
  showInsights,
  selectedToolResults,
  setSelectedToolResults,
  outerContainerRef,
  groupLabel,
  groupId,
  toolResults,
  loadingToolResults,
  handleEndReached,
  groupToolResultsCount,
  carouselWrapperRef,
  showGallery,
  handleShowGallery,
  isFirstGroup,
  'data-testid': dataTestId,
  'data-test': dataTest,
}: Props) => {
  const [params] = useQueryParams<'tool_result_id'>()

  const gridRef = useRef<FixedSizeGrid>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const haveScrolledRef = useRef(false)
  const scrollToIndexCarouselRef = useRef<(idx: number) => Promise<void>>()

  const [hoveredRange, setHoveredRange] = useState<ToolResultsMap>({})
  const [selectedGroupToolResults, setSelectedGroupToolResults] = useState<ToolResultsMap>(selectedToolResults)
  const [hoveredToolResultId, setHoveredToolResultId] = useState<string | null>(null)
  const [lastSelectedToolResultId, setLastSelectedToolResultId] = useState<string | null>(null)

  const containerDimensions = useContainerDimensions(outerContainerRef)
  const { columnCount } = useGridDimensions(
    (containerDimensions?.width || 0) - LABELING_GALLERY_GRID_GAP - 6,
    toolResults?.length,
    {
      gridGap: LABELING_GALLERY_GRID_GAP,
      minWidth: LABELING_GALLERY_CARD_MIN_WIDTH,
      elementRowHeight: LABELING_GALLERY_CARD_HEIGHT,
    },
  )

  const selectedToolResultsCount = useMemo(() => {
    return Object.keys(selectedToolResults).length
  }, [selectedToolResults])

  // Clear the group selected toolResults whenever we unselect all toolResults eg. the esc key is pressed
  useEffect(() => {
    if (selectedToolResultsCount === 0) {
      setSelectedGroupToolResults({})
      setLastSelectedToolResultId(null)
    }
  }, [selectedToolResultsCount])

  const { shiftPressed } = useGalleryShiftKeyPress({
    hoveredToolResultId,
    lastSelectedToolResultId,
    selectedToolResults,
    setHoveredRange,
    toolResults: toolResults,
  })

  const handleSetSelectedToolResults = (newSelectedlassifications: ToolResultsMap) => {
    const newToolResultsCount = Object.keys(newSelectedlassifications).length
    const selectedGroupToolResultsCount = Object.keys(selectedGroupToolResults).length
    setSelectedGroupToolResults(newSelectedlassifications)
    // If some toolResults were removed from the group, we also remove them from the general selected toolResults map.
    if (newToolResultsCount < selectedGroupToolResultsCount) {
      const removedToolResults = Object.keys(selectedGroupToolResults).filter(
        toolResultId => !newSelectedlassifications[toolResultId],
      )

      const updatedMap = { ...selectedToolResults }
      removedToolResults.forEach(toolResultId => {
        delete updatedMap[toolResultId]
      })
      setSelectedToolResults(updatedMap)
    } else {
      setSelectedToolResults({ ...selectedToolResults, ...newSelectedlassifications })
    }
  }

  const scrollGroupIntoView = useCallback(() => {
    if (!outerContainerRef.current || !carouselWrapperRef.current) return
    outerContainerRef.current.scrollTo({ top: carouselWrapperRef.current.offsetTop - 24, behavior: 'smooth' })
  }, [carouselWrapperRef, outerContainerRef])

  const handleHoveredRangeChange = (toolResultId: string) => {
    handleHoveredRange({
      hoveredId: toolResultId,
      lastSelectedToolResultId,
      selectedToolResults: selectedGroupToolResults,
      setHoveredRange,
      toolResults: toolResults,
      allSelectedToolResultsCount: selectedToolResultsCount,
    })
  }

  const handleShowGalleryWrapper = async () => {
    handleShowGallery()
    // Wait for groups to be expanded before scrolling
    await sleep(0)

    scrollGroupIntoView()
  }

  const toolResultId = params.tool_result_id

  // This effect is in charge of finding the selected tool result id in this group,
  // and scroll it into view if it is found
  useEffect(() => {
    if (haveScrolledRef.current || !toolResults || containerDimensions.width === undefined) return

    const scrollHandler = async () => {
      if (!outerContainerRef.current) return

      const foundToolResultIdx = toolResults.findIndex(tr => tr.id === toolResultId)
      if (foundToolResultIdx < 0) {
        haveScrolledRef.current = true
        return
      }

      // If this is the first group, we don't need to do any scrolling
      if (!isFirstGroup) {
        scrollGroupIntoView()

        await waitForScrollToEnd(outerContainerRef.current)
      }

      if (showGallery) {
        const rowIndex = Math.floor(foundToolResultIdx / columnCount)
        gridRef.current?.scrollTo({ scrollTop: rowIndex * LABELING_GALLERY_CARD_HEIGHT_WITH_WRAPPER_PADDING })
      } else {
        scrollToIndexCarouselRef.current?.(foundToolResultIdx)
      }
      haveScrolledRef.current = true
    }

    scrollHandler()
  }, [
    columnCount,
    containerDimensions.width,
    isFirstGroup,
    outerContainerRef,
    scrollGroupIntoView,
    showGallery,
    toolResultId,
    toolResults,
  ])

  return (
    <div
      ref={carouselWrapperRef}
      className={`${Styles.groupCarouselWrapper} ${showGallery ? Styles.galleryItemsGap : ''}`}
      data-testid={dataTestId}
      data-test={dataTest}
    >
      {!showGallery && (
        <VirtualizedCarousel
          scrollRef={scrollToIndexCarouselRef}
          fullCards
          cardGap={24}
          carouselWrapperClassName={Styles.groupCarousel}
          carouselHeaderClassName={Styles.carouselHeader}
          title={<div className={Styles.carouselTitle}>{groupLabel}</div>}
          listContainerClassName={toolResults?.length === 0 ? Styles.listContainer : Styles.cardListCarouselContainer}
          loaderClassName={Styles.loaderContainer}
          loading={loadingToolResults}
          onEndReached={handleEndReached}
          hideActions={toolResults?.length === 0}
          description={
            <>
              <span className={Styles.totalImages}>{groupToolResultsCount}</span>
              <Button
                type="tertiary"
                size="small"
                className={Styles.showAllButton}
                onClick={() => {
                  handleShowGalleryWrapper()
                }}
              >
                Show All
              </Button>
            </>
          }
          cards={toolResults || []}
          renderer={(toolResult, firstCardRef) => (
            <MemoGalleryCard
              key={toolResult.id}
              toolResult={toolResult}
              showInsights={showInsights}
              setLastSelectedToolResultId={setLastSelectedToolResultId}
              onHoveredRangeChange={handleHoveredRangeChange}
              hoveredRange={hoveredRange}
              setHoveredRange={setHoveredRange}
              shiftPressed={shiftPressed}
              setHoveredToolResultId={setHoveredToolResultId}
              selectedToolResults={selectedGroupToolResults}
              onSelectedToolResultsChange={handleSetSelectedToolResults}
              isHovered={hoveredToolResultId === toolResult.id}
              allSelectedToolResultsCount={selectedToolResultsCount}
              groupId={groupId}
              cardRef={firstCardRef}
              toolParentId={toolParentId}
            />
          )}
          emptyState={toolResults?.length === 0 ? <div className={Styles.noImageContainer}>No images</div> : null}
        />
      )}

      {showGallery && (
        <div className={Styles.galleryContainerGroup}>
          <CardBox
            title={<div className={Styles.galleryContainerTitle}>{groupLabel}</div>}
            headerClassName={`${Styles.cardboxHeader} ${Styles.expandedGroupHeader}`}
            className={Styles.galleryCardboxContainer}
            borderType="borderless"
            actions={
              <Button
                type="tertiary"
                size="small"
                onClick={() => {
                  handleShowGalleryWrapper()
                }}
              >
                Show Less
              </Button>
            }
          >
            {!toolResults && <PrismLoader className={Styles.loadOnCenter} />}

            {toolResults && (
              <ReactWindowElementScroller
                type="grid"
                scrollerElementRef={outerContainerRef}
                gridRef={gridRef}
                outerRef={outerRef}
                childrenStyle={{ height: 'auto' }}
              >
                {({ style, onScroll }: GridElementScrollerChildrenProps) => (
                  <GalleryCardsGrid
                    gridRef={gridRef}
                    outerRef={outerRef}
                    galleryBodyRef={outerContainerRef}
                    uniqueToolResults={toolResults}
                    showInsights={showInsights}
                    setLastSelectedToolResultId={setLastSelectedToolResultId}
                    onHoveredRangeChange={handleHoveredRangeChange}
                    hoveredRange={hoveredRange}
                    setHoveredRange={setHoveredRange}
                    shiftPressed={shiftPressed}
                    hoveredToolResultId={hoveredToolResultId}
                    setHoveredToolResultId={setHoveredToolResultId}
                    selectedToolResults={selectedGroupToolResults}
                    onSelectedToolResultsChange={handleSetSelectedToolResults}
                    fetchNextPage={handleEndReached}
                    style={style}
                    onScroll={onScroll}
                    groupId={groupId}
                    toolParentId={toolParentId}
                  />
                )}
              </ReactWindowElementScroller>
            )}
          </CardBox>
        </div>
      )}
    </div>
  )
}

export default LabelingGroupWrapper

export const renderToolResultsCount = (count?: number) => {
  if (!count) return

  if (count < 100) return count

  return '100+'
}
