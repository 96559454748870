import React from 'react'

import { Circle, Rect } from 'react-konva'

import { Box, BoxWithShape, MaxBounds } from 'types'

export const MaskingRectangle = ({ bounds, scaling }: { bounds: MaxBounds; scaling: number }) => {
  return (
    <Rect
      x={0}
      y={0}
      width={bounds.maxWidth * scaling}
      height={bounds.maxHeight * scaling}
      fill={'rgba(0,0,0 ,0.3)'}
      listening={false}
      name={'shadow'}
    />
  )
}

/**
 * Renders a circle that can be dragged to resize the rectangle.
 *
 * @param x x-coordinate of the origin
 * @param y y-coordinate of the origin
 * @param name the name that will be used to find this handle to update its position
 */

export const ResizeHandle = ({ x, y, name }: { x: number; y: number; name: string }) => {
  return (
    <Circle x={x} y={y} stroke={'blue'} fill={'#fff'} radius={5} strokeWidth={2} name={name} listening={false}></Circle>
  )
}

export const scaleBox = (box: Box, scalar: number): Box => {
  return { x: box.x * scalar, y: box.y * scalar, width: box.width * scalar, height: box.height * scalar }
}

export const roundBox = (box: Box): Box => {
  return { x: Math.round(box.x), y: Math.round(box.y), width: Math.round(box.width), height: Math.round(box.height) }
}

export const isValidAoi = (aoi: BoxWithShape): boolean => {
  if (!aoi.shape || aoi.shape.type === 'ellipse' || aoi.shape.type === 'rectangle') return true
  if (aoi.shape.data && aoi.shape.data.length >= 3) return true
  return false
}
