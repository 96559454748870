import React, { useEffect } from 'react'

import { UpOutlined } from '@ant-design/icons'

import Shared from 'styles/Shared.module.scss'

export const BACK_TOP_ID = 'inspections-filters-affix'

/*
 * Use useBackTopWithClassName to attach it to the parent's node (the className)
 * so that when the user scrolls, the BackTop button will appear
 * Find a good example of a use case in src/pages/UsersTable/UsersTable.tsx
 */
export const useBackTopWithClassName = (className: string) => {
  let node = document.getElementsByClassName(className)[0]
  useEffect(() => {
    const timer = setTimeout(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      node = document.getElementsByClassName(className)[0]
      if (node) {
        document.getElementsByClassName(className)[0]?.addEventListener('scroll', e => {
          handleScrollTable(e as unknown as React.UIEvent<HTMLElement>)
        })
        return
      }
    }, 350)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  }, [])

  return null
}

// TODO: we should probably be using refs instead of ids to be consistent with the rest of the code base
export const handleScrollTable = (
  e: React.UIEvent<HTMLElement>,
  affixId: string = BACK_TOP_ID,
  offsetHeader: number = 250,
) => {
  const affix = document.getElementById(affixId)
  if (e.currentTarget.scrollTop >= offsetHeader) {
    affix?.classList.add(Shared.backtopWrapperVisible || '')
  } else {
    affix?.classList.remove(Shared.backtopWrapperVisible || '')
  }
}

export const BackTop = ({
  scrollContainerId,
  scrollContainerClassName,
}: {
  scrollContainerId?: string
  scrollContainerClassName?: string
}) => (
  <div id={BACK_TOP_ID} className={Shared.backtopWrapper}>
    <div
      className={Shared.backTopCircle}
      onClick={() => {
        const node = scrollContainerId
          ? document.getElementById(scrollContainerId)
          : document.getElementsByClassName(scrollContainerClassName || '')[0]
        if (node) node.scrollTo({ top: 0, behavior: 'smooth' })
      }}
    >
      <UpOutlined className={Shared.backTopIcon} />
    </div>
  </div>
)
