import React, { useEffect, useRef, useState } from 'react'

import { Collapse } from 'antd'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'

import { getterKeys, service, useQuery, wsPaths } from 'api'
import { IconButton } from 'components/IconButton/IconButton'
import Layout from 'components/Layout/Layout'
import { PrismArrowIcon, PrismCloseIcon, PrismInfoIcon } from 'components/prismIcons'
import { dismiss, warning } from 'components/PrismMessage/PrismMessage'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import RobotsStatusListener from 'components/RobotsStatusListener'
import RockwellSupport from 'components/RockwellSupport/RockwellSupport'
import StreamListener from 'components/StreamListener'
import { useGoBack, useIsFlourish, useTypedStore } from 'hooks'
import paths from 'paths'
import { LogEntry, StatusEntries, StatusEntry, StreamMessage, VpStatus } from 'types'
import { getBaslerHeartbeat, getRobotDisplayName, getRobotVpStatus, getVpHeartbeat, getVpnStatus } from 'utils'

import Styles from './CameraStatus.module.scss'

/**
 * Renders the screen that allows users to check the status of all elements from their camera,
 * these elements need to report said status and logs to the atom status element to show up here.
 */
function CameraStatus() {
  const goBack = useGoBack(paths.inspect({ mode: 'site' }))
  const { organizationData } = useIsFlourish()
  const { robotId } = useParams<{ robotId: string }>()
  const logTerminalRef = useRef<HTMLDivElement>(null)
  const store = useTypedStore()

  const [statusEntriesByEntryName, setStatusEntriesByEntryName] = useState<{ [entryName: string]: StatusEntry[] }>({})
  const [logElements, setLogElements] = useState<LogEntry[]>([])
  const [selectedLogStream, setSelectedLogStream] = useState<string>()
  const [logMessages, setLogMessages] = useState<string[]>([])
  const [statusElementDisconnected, setStatusElementDisconnected] = useState<boolean>(false)
  const [userScrolledUp, setUserScrolledUp] = useState(false)
  const robot = useQuery(getterKeys.robot(robotId), () => service.getRobot(robotId)).data?.data

  const entries = useQuery(
    getterKeys.robotStatus(robotId),
    async () => {
      const res = await service.atomSendCommandExtractData<StatusEntries>('status', 'read_status', robotId, {
        command_args: null,
      })
      setStatusElementDisconnected(res.type !== 'success')
      return res
    },
    { intervalMs: 5000 },
  ).data?.data

  // Separate entries into logElements and statusEntries whenever we get new data
  useEffect(() => {
    if (!entries) return
    const logsAndStatuses = Object.values(entries.entries)
    // Split into elements and logs
    const logElements = logsAndStatuses.filter(
      entry => entry.type === 'log' || entry.type === 'log_unified',
    ) as LogEntry[]

    const statusEntries = logsAndStatuses.filter(
      entry => entry.type !== 'log' && entry.type !== 'log_unified',
    ) as StatusEntry[]

    // Manually add a section for the VP status we get through the RobotsStatusListener
    const state = store.getState()
    const vpnStatus = getVpnStatus(robotId, state)
    const vpHeartbeat = getVpHeartbeat(robotId, false, state)
    const baslerHeartbeat = getBaslerHeartbeat(robotId, state)
    const vpState = getRobotVpStatus(robotId, state) || 'loading'
    const robotStatusEntries = getRobotStatusEntries(vpState, vpHeartbeat, vpnStatus, baslerHeartbeat)
    statusEntries.push(...robotStatusEntries)

    const statusEntriesByEntryName: { [entryName: string]: StatusEntry[] } = {}
    statusEntries.forEach(status => {
      if (!statusEntriesByEntryName[status.name]) statusEntriesByEntryName[status.name] = []
      statusEntriesByEntryName[status.name]!.push(status)
    })

    setStatusEntriesByEntryName(statusEntriesByEntryName)
    setLogElements(logElements)
  }, [entries]) // eslint-disable-line

  // when we fetch the log elements for the first time, point to 'log_unified'
  useEffect(() => {
    if (logElements.length > 0 && !selectedLogStream) {
      setSelectedLogStream('log_unified')
    }
  }, [logElements, selectedLogStream])

  // effect to autoscroll on every new log message, unless user has explicitly scrolled top
  useEffect(() => {
    if (userScrolledUp) return
    logTerminalRef.current?.scroll(0, logTerminalRef.current.scrollHeight)
  }, [logMessages, userScrolledUp])

  // Effect to latch the event listeners to the div. This works no problem because the div is mounted on first render, thus the ref is already
  // assigned at the end of the first render, which is when this effect will run
  useEffect(() => {
    const div = logTerminalRef.current
    function checkScrollDirection(event: WheelEvent) {
      if (scrollDirectionIsUp(event)) return setUserScrolledUp(true)
    }
    function checkScrollPosition() {
      if (scrollBarAtBottom(div)) setUserScrolledUp(false)
    }

    div?.addEventListener('wheel', checkScrollDirection)
    div?.addEventListener('scroll', checkScrollPosition)
    return () => {
      div?.removeEventListener('wheel', checkScrollDirection)
      div?.removeEventListener('scroll', checkScrollPosition)
    }
  }, [])

  const handleStreamMessages = (messages: StreamMessage[]) => {
    const newLogs = messages.map(message => {
      const { write_ts_ms, msg } = message.payload.data
      const dateTime = moment(write_ts_ms).utc().format('MMM DD kk:mm:ss z')
      return `${dateTime} -> ${msg}`
    })
    setLogMessages(oldLogs => [...oldLogs, ...newLogs])
  }

  // Show a notification if we lose connection to the status element
  useEffect(() => {
    if (statusElementDisconnected) {
      let text = 'No status available. '
      if (entries) {
        text += `Last status recieved was at ${moment(entries.current_ts_ms).format('HH:mm:ss DD-MM-YYYY')}`
      }
      warning({
        title: text,
        duration: 0,
        id: 'status-element-disconnected',
      })
    }

    if (!statusElementDisconnected) dismiss('status-element-disconnected')
  }, [statusElementDisconnected]) //eslint-disable-line

  useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dismiss('status-element-disconnected')
        goBack()
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [goBack])

  return (
    <Layout
      showMenu={false}
      before={
        <IconButton
          data-testid="camera-details-close-button"
          icon={<PrismCloseIcon />}
          type="ghost"
          className={Styles.exitScreenContainer}
          onClick={() => {
            dismiss('status-element-disconnected')
            goBack()
          }}
        />
      }
      title={
        <div className={Styles.titleContainer}>
          <RobotDisplayName
            robotName={getRobotDisplayName(robot, { showModelAndSerial: true })}
            className={Styles.cameraName}
          />
          <span>Status</span>
        </div>
      }
      after={<div className={Styles.emailText}>{organizationData.supportEmail ?? <RockwellSupport />}</div>}
      titleClassName={Styles.cameraStatusHeader}
      showHeaderLogo={false}
    >
      <div className={Styles.bodyContainer}>
        <div className={Styles.elementsList}>
          <Collapse
            accordion
            className="antd-collapse-gray-content"
            expandIconPosition="right"
            expandIcon={() => <PrismArrowIcon direction="down" className={Styles.expandIcon} />}
          >
            {/* 
                Business rules: Since we group entries by their name together, we need to gather some conclusions off of the group.
                The status circle will show green (online === true) if all elements are 'ok', if at least one isn't it will be red.
                The description is intended to be exactly the same on all elements with the same name, in case it isn't we will render 
                the first description we find.
             */}
            {Object.entries(statusEntriesByEntryName).map((entry, idx) => {
              const [name, statusEntries] = entry
              const circleStatusClassName = getCircleStatusForParentEntry(statusEntries, statusElementDisconnected)
              const description = statusEntries.find(status => status.description)?.description
              return (
                <Collapse.Panel
                  header={
                    <div className={Styles.collapsePanelTitle}>
                      <span className={`${Styles.circle} ${circleStatusClassName}`} />
                      <div className={Styles.elementName}>
                        {name}
                        {description && (
                          <PrismTooltip title={description} placement="top" anchorClassName={Styles.infoIconContainer}>
                            <PrismInfoIcon />
                          </PrismTooltip>
                        )}
                      </div>
                    </div>
                  }
                  key={statusEntries[0]?.key || idx}
                  className={Styles.collapsePanel}
                >
                  {statusEntries.map(status => {
                    const circleStatusClassName = getCircleStatusForEntry(status, statusElementDisconnected)
                    return (
                      <div key={status.key} className={Styles.statusEntry}>
                        {status.data && (
                          <div className={`${Styles.elementData} ${circleStatusClassName}`}>{status.data}</div>
                        )}
                        <div className={Styles.elementDataDescription}>{renderEntryMessage(status)}</div>
                      </div>
                    )
                  })}
                </Collapse.Panel>
              )
            })}
          </Collapse>
        </div>
        <div className={Styles.logsContainer}>
          <div className={Styles.logsContainerHeader}>
            <div className={Styles.logsContainerHeaderTitle}>Log</div>

            <PrismSelect
              className={Styles.transparentSelect}
              popupClassName={Styles.dropdownContainer}
              size="middle"
              placeholder="Log stream"
              onChange={value => {
                setSelectedLogStream(value)
                setLogMessages([])
              }}
              value={selectedLogStream}
              options={logElements.map(entry => ({ value: entry.stream, key: entry.stream }))}
            />
          </div>

          <div className={Styles.logsTerminal} ref={logTerminalRef}>
            {logMessages.map((value, index) => {
              return (
                <div key={value} className={Styles.logItem}>
                  <p className={Styles.logItemIndex}>{index + 1}:</p>{' '}
                  <p className={Styles.logItemDescription}>{value}</p>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {selectedLogStream && (
        <StreamListener
          mode="message"
          connect={{ relativeUrl: wsPaths.statusLog(selectedLogStream, robotId), robotId }}
          onMessages={handleStreamMessages}
          params={{ last_n: 50 }}
        />
      )}

      <RobotsStatusListener robotIds={[robotId]} />
    </Layout>
  )
}

export default CameraStatus

function renderEntryMessage(status: StatusEntry) {
  if (status.status_type === 'timed_out') {
    const time = moment(status.write_ts_ms).format('HH:mm:ss DD-MM-YYYY')
    return `This element appears to be disconnected as the status has timed out. Last message recieved from the element was at ${time}`
  }
  return status.msg
}

function getCircleStatusForParentEntry(statusEntries: StatusEntry[], statusElementDisconnected: boolean) {
  if (statusElementDisconnected) return Styles.yellow
  if (statusEntries.every(status => status.status_type === 'ok')) return Styles.green
  return Styles.red
}

function getCircleStatusForEntry(status: StatusEntry, statusElementDisconnected: boolean) {
  if (statusElementDisconnected) return Styles.yellow
  if (status.status_type === 'ok') return Styles.green
  return Styles.red
}

function scrollDirectionIsUp(event: WheelEvent) {
  return event.deltaY < 0
}
function scrollBarAtBottom(div: HTMLDivElement | null) {
  if (!div) return false
  return div.scrollHeight - div.scrollTop <= div.clientHeight
}

function getHeartbeatStatusType(status: 'loading' | 'disconnected' | 'connected') {
  if (status === 'disconnected') return 'error'
  return 'ok'
}

function getRobotStatusEntries(
  vpState: VpStatus | 'loading',
  vpHeartbeat: 'loading' | 'disconnected' | 'connected',
  vpnStatus: 'loading' | 'disconnected' | 'connected',
  baslerHeartbeat: 'loading' | 'disconnected' | 'connected',
): StatusEntry[] {
  return [
    {
      description: 'Camera status states reported by the Vision Processing and IOT elements',
      key: 'vp-status',
      msg: 'Vision Processing Status',
      name: 'Camera Heartbeat',
      status_type: vpState === 'ERROR_MAJOR' ? 'error' : 'ok',
      write_ts_ms: 0,
      timeout_ms: 0,
      type: 'string',
      data: vpState,
    },
    {
      description: 'Camera status states reported by the Vision Processing and IOT elements',
      key: 'vp-heartbeat',
      msg: 'Vision Processing Heartbeat',
      name: 'Camera Heartbeat',
      status_type: getHeartbeatStatusType(vpHeartbeat),
      write_ts_ms: 0,
      timeout_ms: 0,
      type: 'string',
      data: vpHeartbeat,
    },
    {
      description: 'Camera status states reported by the Vision Processing and IOT elements',
      key: 'basler-heartbeat',
      msg: 'Camera Heartbeat',
      name: 'Camera Heartbeat',
      status_type: getHeartbeatStatusType(baslerHeartbeat),
      write_ts_ms: 0,
      timeout_ms: 0,
      type: 'string',
      data: baslerHeartbeat,
    },
    {
      description: 'Camera status states reported by the Vision Processing and IOT elements',
      key: 'vpn-heartbeat',
      msg: 'IOT Heartbeat',
      name: 'Camera Heartbeat',
      status_type: getHeartbeatStatusType(vpnStatus),
      write_ts_ms: 0,
      timeout_ms: 0,
      type: 'string',
      data: vpnStatus,
    },
  ]
}
