import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import PrismImageDropzone from 'components/PrismImageDropzone/PrismImageDropzone'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { Token } from 'components/Token/Token'
import { FileImageRenderer } from 'components/UploadModal/UploadModal'
import { useIsColocated } from 'hooks'
import paths from 'paths'
import { Component, FileCustomError, PerEntityModalProps } from 'types'
import { uploadAndGenerateOnlyThumbnails } from 'utils'
import { INPUT_MAX_CHARACTER_LIMIT } from 'utils/constants'

import EntityModal from './EntityModal'
import Styles from './EntityModal.module.scss'

/**
 * Renders the Create or Edit Product modal
 *
 *
 * @param entity - the modal name
 */

type productModalForm = {
  name: string | undefined
  image: File | string | undefined
}

const AddOrEditProductModal = <T extends boolean>({
  isEditMode,
  onClose,
  entity: product,
}: PerEntityModalProps<T, Component>) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isColocated } = useIsColocated()
  const defaultValues = getDefaultValues(product)
  const [fileError, setFileError] = useState<FileCustomError>(null)

  const {
    formState: { errors, isDirty, isValid },
    getValues,
    control,
  } = useForm<productModalForm>({ defaultValues, mode: 'onChange' })

  async function getProductImg(img: string | File | undefined) {
    // product already has a valid img, so we don't update anything
    if (typeof img === 'string') return

    if (img) return (await uploadAndGenerateOnlyThumbnails([img]))?.[0]
    // User removed the product image
    if (!isColocated) return ''
  }

  async function handleSubmit() {
    const values = getValues()

    const payload = {
      name: values.name,
      image: await getProductImg(values.image),
    }

    let res: SendToApiResponse<Component> | undefined = undefined

    if (isEditMode && product) {
      res = await service.updateComponent(product.id, payload)
    }
    if (!isEditMode) {
      res = await service.createComponent(payload)
    }

    await query(getterKeys.components('all-unarchived'), () => service.getComponents({ is_deleted: false }), {
      dispatch,
    })
    onClose()

    if (!isEditMode && res?.type === 'success') {
      history.push(paths.inspect({ mode: 'product' }), { productIdToExpand: res?.data.id })
    }

    return res
  }

  function getImageRender(file: File | string | undefined) {
    if (!file) return

    if (typeof file === 'object') {
      return <FileImageRenderer file={file} />
    }

    return <ImgFallback src={file} loaderType="skeleton" className={Styles.labelFormImage} />
  }

  return (
    <EntityModal
      id="product-entity-modal"
      data-testid="product-modal"
      entity="product"
      onSubmit={handleSubmit}
      onClose={onClose}
      isEditMode={isEditMode}
      disableSave={!isDirty || !isValid}
    >
      <>
        <Controller
          name="name"
          rules={{ required: 'Product is required' }}
          control={control}
          render={({ ...props }) => (
            <PrismInput
              label="product name (*)"
              {...props}
              errors={errors}
              maxLength={INPUT_MAX_CHARACTER_LIMIT}
              data-testid="product-modal-name-input"
            />
          )}
        />
        {!isColocated && (
          <Controller
            name="image"
            control={control}
            render={({ value, onChange }) => (
              <Token label="photo">
                <div className={Styles.verticalChildrenGap8}>
                  <PrismImageDropzone
                    setFilesToUpload={files => onChange(files[0])}
                    setError={setFileError}
                    maxFiles={1}
                    image={getImageRender(value)}
                    onRemoveImage={() => onChange(null)}
                    isEmpty={!value}
                    caption="Upload a photo to represent this product"
                    error={fileError}
                  />
                </div>
              </Token>
            )}
          />
        )}
      </>
    </EntityModal>
  )
}

export default AddOrEditProductModal

const getDefaultValues = (product: Component | undefined) => {
  return {
    name: product?.name,
    image: product?.image,
  }
}
