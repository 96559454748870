import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { uniqBy } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys, SendToApiResponse, service, ToolResultsData } from 'api'
import { dismiss, loading } from 'components/PrismMessage/PrismMessage'
import { useData, useInspectionsProxyResults, useOnScreen, useQueryParams } from 'hooks'
import { QsFilterKey, QsFilters, ToolSpecificationName } from 'types'
import {
  appendDataToQueryString,
  areFiltersActive,
  convertAllFiltersToBackendQueryParams,
  getFiltersToProxyInspections,
  getToolResultsBranch,
  handleInspectionsProxyResultsEndReached,
  titleCase,
} from 'utils'

import { FILTERING_IMAGES_MESSAGE_ID, useLabelingScreenFilteredBackendParams } from '../LabelingScreen'
import { ToolResultsMap, ToolResultsMapDispatch } from './LabelingGallery'
import { GroupData } from './LabelingGroups'
import LabelingGroupWrapper, { renderToolResultsCount } from './LabelingGroupWrapper'

interface Props {
  group: GroupData
  toolParentId: string
  toolSpecificationName: ToolSpecificationName
  groupByOption: string
  showInsights: boolean
  selectedToolResults: ToolResultsMap
  setSelectedToolResults: ToolResultsMapDispatch
  outerContainerRef: React.RefObject<HTMLElement>
  setGroupsData: React.Dispatch<React.SetStateAction<GroupData[]>>
  onHideNextCarousels: (shouldHide: boolean) => void
  'data-testid'?: string
}

/**
 * Carousel for each group of the labeling gallery GroupBy mode.
 * Each carousel handles and sync the selected clasifications cards.
 * This component also renders the expanded gallery view for the group.
 *
 * @param group - The current group data
 * @param toolParentId - The current tool parent id
 * @param toolSpecificationName - The current tool specification name
 * @param groupByOption - The selected group by option
 * @param showInsights - Whether to show insights
 * @param selectedToolResults - The current selected toolResults
 * @param setSelectedToolResults - Function that sets the current selected toolResults
 * @param outerContainerRef - The outer container ref, use by ReactWindowElementScroller
 * @param setGroupsData - Function that sets the groups data
 * @param onHideNextCarousels - Used to determine whether more items are being fetched in the currently opened carousel. If so, hide subsequent carousels.
 */
const GroupCarousel = ({
  group,
  toolParentId,
  toolSpecificationName,
  groupByOption,
  showInsights,
  selectedToolResults,
  setSelectedToolResults,
  outerContainerRef,
  setGroupsData,
  onHideNextCarousels,
  'data-testid': dataTestId,
}: Props) => {
  const dispatch = useDispatch()
  const [params] = useQueryParams()
  const location = useLocation<{ redirectedFromOtherMode?: boolean }>()
  const history = useHistory()

  const PROXY_KEY = `${group.id}-labeling-group`

  const backendParams = useLabelingScreenFilteredBackendParams(params)

  const { ref, hasBeenVisible } = useIsCarouselOnScreen()

  const shouldFetchToolResults = useMemo(() => {
    return evaluateFetchByFilters(params, group)
  }, [group, params])

  const toolResultsBranch = useMemo(() => {
    return getToolResultsBranch({ params, toolParentId, groupId: group.id })
  }, [toolParentId, group.id, params])

  const toolResultsData = useData(getterKeys.toolParentToolResults(toolResultsBranch))

  const { filtersCount } = getFiltersToProxyInspections(backendParams)
  const inspectionsProxyNeeded = !!filtersCount

  // This effect handles the carousel hiding logic. It tells the parent component when to hide the subsequent carousels
  // if pagination is still in progress
  useEffect(() => {
    if (group.showGallery && toolResultsData?.next) {
      onHideNextCarousels(true)
    } else {
      onHideNextCarousels(false)
    }
  }, [group.showGallery, onHideNextCarousels, toolResultsData?.next])

  const inspectionsForProxyFiltersData = useData(getterKeys.inspectionsForFiltersProxy(PROXY_KEY))
  const inspectionsForProxyFilters = useMemo(() => {
    if (!inspectionsProxyNeeded) return
    return inspectionsForProxyFiltersData?.results
  }, [inspectionsForProxyFiltersData?.results, inspectionsProxyNeeded])
  const nextInspectionsPage = inspectionsForProxyFiltersData?.next

  const queryData = useMemo(() => {
    return group.queryData
  }, [group.id]) //eslint-disable-line

  const toolResults = useMemo(() => {
    // if we have filters that need to be proxied by inspections and we have exactly 0 inspections, it means we don't have results
    if (inspectionsProxyNeeded && inspectionsForProxyFilters?.length === 0) return []
    return toolResultsData?.results
  }, [inspectionsForProxyFilters?.length, inspectionsProxyNeeded, toolResultsData?.results])

  const nextToolResultsPage = toolResultsData?.next || ''
  const lastScannedInspectionId = toolResultsData?.last_inspection_id

  const holdOff = Boolean(
    !shouldFetchToolResults || !hasBeenVisible || (location.state?.redirectedFromOtherMode && toolResults),
  )

  const toolResultsFilters = useMemo(() => {
    const backendFilters = convertAllFiltersToBackendQueryParams({
      ...backendParams,
      // we add the tool_specification here just to know the correct prediction score filters we need for each tool, we will remove it later
      tool_specification: toolSpecificationName,
      predictionScore: [backendParams.prediction_score_min, backendParams.prediction_score_max],
    })

    return {
      ...backendFilters,
      // we don't need to send the tool_specification as we are already sending the tool parent id
      tool_specification: undefined,
      // These two params should always persist
      tool_parent_id: toolParentId,
      has_image: 'true',
      ...queryData,
    }
  }, [queryData, backendParams, toolParentId, toolSpecificationName])

  const nullPredictionsFetchedRef = useRef(false)
  const { predictionScoreSortingActive, predictionScoreRangeFiltersActive } = areFiltersActive(params)

  useInspectionsProxyResults({
    getterKey: getterKeys.toolParentToolResults(toolResultsBranch),
    fetcher: async (scanInspectionIds, additionalParams) => {
      const res = await service.getToolResults(
        {
          ...toolResultsFilters,
          ...additionalParams,
        },
        // include_empty is passed so that we fetch the "imported" tool results
        {
          include_empty: true,
          ...(scanInspectionIds ? { body: { scan_inspection_ids: scanInspectionIds }, method: 'POST' } : {}),
        },
      )

      return res as SendToApiResponse<ToolResultsData>
    },
    holdOff,
    setReloadingResults: reloading => {
      if (reloading) {
        loading({ title: 'Filtering images', id: FILTERING_IMAGES_MESSAGE_ID })
      } else {
        dismiss(FILTERING_IMAGES_MESSAGE_ID)
      }
    },
    inspectionFilters: backendParams,
    proxyGetterKey: PROXY_KEY,
    nullPredictionsFetchedRef,
    predictionScoreRangeFiltersActive,
    predictionScoreSortingActive,
  })

  // It may be possible for us to have more than one element with the same ID in the array
  // of toolResults, so we must remove duplicates
  const uniqueToolResults = useMemo(() => {
    if (!shouldFetchToolResults) return []
    if (!toolResults) return

    // It's necessary to create a copy of toolResults, for the uniqBy function to work properly
    // otherwise the array collapses into a single element.
    const copyOfToolResults = [...toolResults]
    return uniqBy(copyOfToolResults, toolResult => toolResult.id)
  }, [toolResults, shouldFetchToolResults])

  const handleEndReached = async () => {
    if (!toolResultsBranch) return

    await handleInspectionsProxyResultsEndReached({
      paramFilters: backendParams,
      getterKey: getterKeys.toolParentToolResults(toolResultsBranch),
      inspectionsForProxy: inspectionsForProxyFilters,
      dispatch,
      nextResultsPage: nextToolResultsPage,
      lastScannedInspectionId,
      proxyGetterKey: PROXY_KEY,
      nextInspectionsPage,
      resultsFetcher: (scanIds, params) =>
        service.getToolResults(params, { body: { scan_inspection_ids: scanIds }, method: 'POST' }),
      nullPredictionsFetchedRef,
      predictionScoreRangeFiltersActive,
      predictionScoreSortingActive,
    })
  }

  const handleShowGallery = () => {
    const showGallery = !group.showGallery

    appendDataToQueryString(
      history,
      { group_id: group.id, expanded: showGallery || undefined },
      { selectedToolResults },
    )

    setGroupsData(prevGroupsData => {
      return prevGroupsData.map(prevGroup => {
        if (prevGroup.id === group.id) {
          return { ...group, showGallery }
        }

        // if we are showing a group, we want to hide all other groups.
        if (showGallery) {
          return { ...prevGroup, showGallery: false }
        }

        return prevGroup
      })
    })
  }

  const toolResultsCount = renderToolResultsCount(uniqueToolResults?.length)

  const groupLabel = (
    <>
      {titleCase(groupByOption)}: {group.label}
    </>
  )

  return (
    <LabelingGroupWrapper
      carouselWrapperRef={ref}
      toolParentId={toolParentId}
      showInsights={showInsights}
      selectedToolResults={selectedToolResults}
      setSelectedToolResults={setSelectedToolResults}
      outerContainerRef={outerContainerRef}
      groupLabel={groupLabel}
      groupId={group.id}
      loadingToolResults={!uniqueToolResults && shouldFetchToolResults}
      toolResults={uniqueToolResults}
      handleEndReached={handleEndReached}
      groupToolResultsCount={toolResultsCount}
      showGallery={group.showGallery}
      handleShowGallery={handleShowGallery}
      isFirstGroup={!!group.isFirstGroup}
      data-testid={`${dataTestId}-${group.id}`}
    />
  )
}

export default GroupCarousel

/**
 * This function returns a boolean indicating if we should fetch toolResults for this group.
 * This outcome is based on the qs filters and the selected group by option.
 * If we are applying a filter for the same selected group by option, we only fetch the matching group.
 * eg. if we are on the User Labels groups and we filter by user label "pass", we should only fetch toolResults for the "Pass" group
 *
 * @param params query string parms
 * @param group the current group data
 */
const evaluateFetchByFilters = (params: QsFilters, group: GroupData): boolean => {
  const { group_by, calculated_outcome__in, user_label_id__in, user_outcome, prediction_label_id__in } = params
  const { queryData } = group

  if (group_by === 'label') {
    for (const filter of ['calculated_outcome__in', 'user_label_id__in', 'user_outcome'] as QsFilterKey[]) {
      if (params[filter] && params[filter] === queryData[filter]) return true
    }

    // We return true for all groups if "no filters" option is active
    if (!params.user_label_set_isnull && !params.user_label_id__in) return true

    if (!user_label_id__in && !calculated_outcome__in && !user_outcome && queryData.user_label_set_isnull === 'true') {
      return true
    }

    return false
  }

  if (group_by === 'predicted') {
    if (!prediction_label_id__in) return true
    if (prediction_label_id__in === queryData.prediction_label_id__in) return true
    if (group.id === 'predicted-unknown' && !prediction_label_id__in) return true
    return false
  }

  return false
}

/**
 * Wrapper around useOnScreen that returns a ref and whether the related element to that ref has been visible
 */
export const useIsCarouselOnScreen = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)
  const handleIntersection = useCallback(
    (isOnScreen: boolean) => {
      if (hasBeenVisible) return

      if (isOnScreen) setHasBeenVisible(true)
    },
    [hasBeenVisible],
  )

  useOnScreen(ref, { threshold: 0.3, callback: handleIntersection })

  return { ref, hasBeenVisible }
}
