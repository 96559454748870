import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Site Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=5846-11432&mode=design&t=US7Cn8H7N454U86q-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismSiteIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M8.50006 13C8.22392 13 8.00006 13.2239 8.00006 13.5C8.00006 13.7761 8.22392 14 8.50006 14H11.5001C11.7762 14 12.0001 13.7761 12.0001 13.5C12.0001 13.2239 11.7762 13 11.5001 13H8.50006Z"
        fill="currentColor"
      />
      <path
        d="M8.00006 15.5C8.00006 15.2239 8.22392 15 8.50006 15H11.5001C11.7762 15 12.0001 15.2239 12.0001 15.5C12.0001 15.7761 11.7762 16 11.5001 16H8.50006C8.22392 16 8.00006 15.7761 8.00006 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5001 5.99999L9.00001 8.49999V4H4V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5.99999L14.5001 8.49999V5.99999ZM19 7.553V19H5V5H8.00001V10.053L13.5001 7.55299V10.053L19 7.553Z"
        fill="currentColor"
      />
    </svg>
  )
}
