/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react'

import { Path, Rect, Svg } from '@react-pdf/renderer'

const PDFLink = ({ iconBackground }: { iconBackground: string }) => (
  <Svg style={{ width: 11.4, height: 11.4 }} viewBox="0 0 24 24">
    <Rect width="24" height="24" fill={iconBackground} x="0" y="0" rx="12" ry="12" />

    <Path
      d="M15.86 10.669c.557-.556.732-1.91-.136-2.778-.867-.867-2.253-.725-2.81-.168L10.7 9.938l-.589-.59 2.215-2.215c1.055-1.054 2.84-.979 3.989.17 1.148 1.147 1.19 2.9.136 3.955l-2.215 2.215-.59-.59 2.216-2.214ZM7.338 13.364c-.556.556-.73 1.91.137 2.777.867.868 2.22.693 2.777.137l2.215-2.216.59.59-2.216 2.215c-1.054 1.054-2.808 1.011-3.956-.137-1.148-1.148-1.223-2.933-.168-3.988l2.215-2.215.589.59-2.183 2.247Z"
      fill="#F8F8F9"
    />
    <Path d="m13.056 10.527-2.946 2.946" stroke="#F8F8F9" />
  </Svg>
)
export default PDFLink
