import React, { useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { useData, useTypedSelector } from 'hooks'
import { renderRole } from 'pages/Administration/UsersTable'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { Me, UserRole } from 'types'
import { getterUpdateData } from 'utils'

import Styles from './Settings.module.scss'

const ChangeInfoForm = () => {
  const dispatch = useDispatch()

  const me = useData(getterKeys.me())
  const currentUserOrg = useTypedSelector(state => state.currentOrg)
  const defaultValues = getDefaultValues(me, currentUserOrg?.role || 'member')
  const {
    formState: { isDirty, errors },
    control,
    reset,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    if (!me) return
    reset(getDefaultValues(me, currentUserOrg?.role || 'member'))
  }, [currentUserOrg?.role, me, reset])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid) return

    const { firstName, lastName } = getValues()

    const res = await service.updateMe({ first_name: firstName, last_name: lastName })
    if (res.type === 'success') {
      dispatch(Actions.getterUpdate({ key: 'me', updater: prevRes => getterUpdateData(prevRes, res.data) }))
      success({ title: 'Information updated', 'data-testid': 'profile-update-success' })
    } else {
      error({ title: 'There was a problem updating your personal information' })
    }
  }

  const disableButton = !isDirty || Object.keys(errors).length > 0

  return (
    <>
      <LeavePagePrompt when={isDirty} />
      <PrismContainer
        headerElementsAlign="vertical"
        title="Profile"
        className={Shared.rightSectionContainer}
        bodyClassName={Styles.bodyContainer}
        actions={
          <Button
            htmlType="submit"
            disabled={disableButton}
            onClick={handleSubmit}
            size="small"
            data-testid="profile-save-button"
          >
            Save
          </Button>
        }
      >
        <div className={Styles.formWrapper}>
          <div className={Shared.verticalChildrenGap24}>
            <h2 className={Styles.formSectionTitle}>Personal Information</h2>

            <Controller
              name="firstName"
              control={control}
              render={props => (
                <PrismInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  label="First Name"
                  errors={errors}
                  data-testid="profile-first-name"
                />
              )}
            />

            <Controller
              name="lastName"
              control={control}
              render={props => (
                <PrismInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  label="Last Name"
                  errors={errors}
                  data-testid="profile-last-name"
                />
              )}
            />

            <Controller
              name="role"
              rules={{ required: 'Role is required' }}
              control={control}
              render={props => (
                <PrismInput
                  {...props}
                  wrapperClassName={Styles.inputWrapper}
                  label="Role"
                  errors={errors}
                  disabled
                  data-testid="profile-role"
                />
              )}
            />
          </div>
        </div>
      </PrismContainer>
    </>
  )
}

const getDefaultValues = (user: Me | undefined, role: UserRole) => {
  return {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    role: renderRole(role),
  }
}

export default ChangeInfoForm
