import React, { useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Location as HistoryLocation } from 'history'
import { isEqual } from 'lodash'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { contextRockwellAuth0 } from 'App'
import { error } from 'components/PrismMessage/PrismMessage'
import { info } from 'components/PrismNotification/PrismNotification'
import { info as infoNotification } from 'components/PrismNotification/PrismNotification'
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENTID,
  AUTH0_DOMAIN,
  AUTH0_ROCKWELL_AUDIENCE,
  AUTH0_ROCKWELL_CLIENTID,
  AUTH0_ROCKWELL_DOMAIN,
  ROCKWELL_LOGOUT_REDIRECT_URL,
} from 'env'
import { useQueryParams, useSetGlobalStyles, useTypedSelector } from 'hooks'
import LogInAndOut from 'pages/Logout/LogInAndOut'
import paths, { nonOrgPaths } from 'paths'
import * as Actions from 'rdx/actions'
import { AuthState, NonProvisionedOrg, Organization } from 'types'
import { doesUserBelongsToAnActiveOrg, getLocationMinifiedOrgId, getMinifiedOrgId } from 'utils'

import LoginContainer from './LoginContainer'
import LoginOrganizationPicker from './LoginOrganizationPicker/LoginOrganizationPicker'

const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

/**
 * Renders the login screen or redirects already logged in users.
 */
const Login = () => {
  const location = useLocation<{ from?: HistoryLocation; loggedOut?: boolean }>()
  const history = useHistory()
  const [params] = useQueryParams<'loggedOut'>()
  const fromLocalStorageLocation = useTypedSelector(state => state.localStorage.fromUrl, isEqual).from

  const auth = useTypedSelector(state => state.auth)
  const from: HistoryLocation = location.state?.from ||
    fromLocalStorageLocation || { pathname: '/', search: '', hash: '', state: '' }

  const loggedOut = !!params.loggedOut || !!location.state?.loggedOut
  const rockwellLogin = window.location.pathname === nonOrgPaths.loginRockwell()

  // Only redirect user if they are already authenticated on mount,
  // since we login and then logut owners who haven't accepted eula,
  // we don't want this effect to run every time auth changes
  // we handle further redirects event based
  useEffect(() => {
    if (auth.auth) {
      return history.push(from)
    }
    // eslint-disable-next-line
  }, [])

  useSetGlobalStyles(rockwellLogin)

  // Enable login form in app in case we're not using Auth0
  if (
    (!AUTH0_AUDIENCE || !AUTH0_CLIENTID || !AUTH0_DOMAIN) &&
    (!AUTH0_ROCKWELL_AUDIENCE || !AUTH0_ROCKWELL_CLIENTID || !AUTH0_ROCKWELL_DOMAIN)
  )
    return <LoginContainer from={from} />

  return <Auth0LoginContainer from={from} auth={auth} loggedOut={loggedOut} rockwellLogin={rockwellLogin} />
}

export default Login

function Auth0LoginContainer({
  from,
  auth,
  loggedOut,
  rockwellLogin,
}: {
  from: HistoryLocation
  auth: AuthState
  loggedOut: boolean
  rockwellLogin: boolean
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [params] = useQueryParams<'logoutCode' | 'code', LogoutMessageCode>()
  const {
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: auth0Logout,
    isAuthenticated,
  } = useAuth0(rockwellLogin ? contextRockwellAuth0 : undefined)
  // force show loading state when coming back from auth0 to exchange tokens and when waiting for the redirect on logging out
  const showLoadingState = !!auth.logoutAction || !!params.code || isAuthenticated
  const [orgsToChoose, setOrgsToChoose] = useState<(Organization | NonProvisionedOrg)[]>()

  const loginPath = rockwellLogin ? nonOrgPaths.loginRockwell() : nonOrgPaths.login()

  useEffect(() => {
    // If the user is authenticated, remove the stored url
    if (auth.auth) return localStorage.removeItem('elementary:fromUrl')

    // stores the last visited url. If the user logs out through the logout button, don't set 'from' to be account settings screen
    if (!from.pathname.includes(paths.accountSettings({ onlyPrefix: true }))) {
      dispatch(Actions.localStorageUpdate({ key: 'fromUrl', data: { from: from } }))
    }
  }, [auth.auth, dispatch, from])

  const loginThroughAuth0 = () => {
    loginWithRedirect({ redirectUri: window.location.origin + loginPath })
  }

  const logoutFromAuth0 = (logoutMessageCode?: LogoutMessageCode, redirectToFthub?: boolean) => {
    const qsParams: { [key: string]: any } = { loggedOut: true }
    if (logoutMessageCode) {
      logoutMessages[logoutMessageCode]?.()
      qsParams.logoutCode = logoutMessageCode
    }
    // Redirect to FTHub when a rockwell user logs out
    auth0Logout({
      returnTo: redirectToFthub
        ? ROCKWELL_LOGOUT_REDIRECT_URL
        : window.location.origin + loginPath + qs.stringify(qsParams, { addQueryPrefix: true }),
    })
  }

  // We get the tokens generated by auth0 to exchange them with Django for our auth token
  // which we can then use to fetch our user.
  const exchangeAuth0Tokens = async (organizationId?: string) => {
    const access_token = await getAccessTokenSilently()
    const id_token = await getIdTokenClaims()

    if (!access_token || !id_token) return
    const exchangeEndpoint = rockwellLogin ? service.exchangeAuth0TokenRockwell : service.exchangeAuth0Token
    const authRes = await exchangeEndpoint({
      access_token,
      id_token: id_token.__raw,
      organization_id: organizationId,
    })

    if (authRes.type !== 'success') {
      if ('code' in authRes.data) {
        if (authRes.data.code === 'no_organizations') {
          dispatch(Actions.authUnset())
          logoutFromAuth0('userNoOrg')
          return
        }

        if (authRes.data.code === 'switch_stack') {
          const validUrl = URL_REGEX.test(authRes.data.stack_url)

          if (validUrl) {
            window.location.href = `${authRes.data.stack_url}${nonOrgPaths.loginRockwell()}}`
            return
          }
          dispatch(Actions.authUnset())
          logoutFromAuth0('errorLoggingIn')
          return
        }
      }

      logoutFromAuth0('errorLoggingIn')
      return
    }

    // This exchangeAuth0Tokens function runs twice on the rockwell login flow, first to exchange auth0 tokens for user orgs.
    // Then once an org is chosen, the tokens + org are exchanged for a django token
    if ('orgs' in authRes.data) {
      const fromMinifiedOrgId = getLocationMinifiedOrgId(from.pathname)
      const activeOrgs = authRes.data.orgs.filter(org => org.is_active)
      const foundOrg = fromMinifiedOrgId
        ? activeOrgs.find(org => getMinifiedOrgId(org.id) === fromMinifiedOrgId)
        : undefined

      if (!foundOrg) {
        const orgsToChoose = [...activeOrgs, ...authRes.data.non_provisioned_orgs]
        if (orgsToChoose.length === 0) {
          dispatch(Actions.authUnset())
          logoutFromAuth0('userNoOrg')
          return
        }

        return setOrgsToChoose(orgsToChoose)
      }

      exchangeAuth0Tokens(foundOrg.id)
      return
    }

    dispatch(
      Actions.authSet({
        auth: authRes.data,
        rockwellIdpLogin: rockwellLogin,
      }),
    )

    // fetch rest of user details if user logged in successfully
    const meRes = await query(getterKeys.me(), service.me, { dispatch })

    if (meRes?.type !== 'success') {
      dispatch(Actions.authUnset())
      logoutFromAuth0('errorLoggingIn')
      return
    }

    const isUserActivatingItsAcc = meRes.data.user_orgs.some(
      userOrg => from.pathname === paths.activateUserOrg(getMinifiedOrgId(userOrg.organization.id)),
    )

    if (!doesUserBelongsToAnActiveOrg(meRes.data) && !isUserActivatingItsAcc) {
      dispatch(Actions.authUnset())
      logoutFromAuth0('userNoOrg')
      return
    }

    if (organizationId) {
      const fromMinifiedOrgId = getLocationMinifiedOrgId(from.pathname)
      if (fromMinifiedOrgId) {
        return history.push(from)
      }

      return history.replace(`/o/${getMinifiedOrgId(organizationId)}/`)
    }

    return history.push(from)
  }

  useEffect(() => {
    if (params.logoutCode) logoutMessages[params.logoutCode]?.()
    if (isLoading) return

    // If logged out from app but not from auth0, force logout from auth0 for security reasons.
    if (!auth.auth && auth.logoutAction) {
      logoutFromAuth0(
        auth.logoutAction === 'sessionExpired' || auth.logoutAction === 'eulaNotSigned' ? auth.logoutAction : undefined,
        auth.rockwellIdpLogin && auth.logoutAction === 'userLogout',
      )
      return
    }

    // If authenticated with auth0, we now need to authenticate with django.
    // Also exchange if isLoading AND isAuthenticated are both false, while having a code param. This is a bug on the auth0 sdk when authenticating through the okta dashboard.
    if (isAuthenticated || (!isLoading && !isAuthenticated && params.code)) {
      exchangeAuth0Tokens()
      return
    }

    // Redirect automatically to auth0 if not showing the login button
    if (!loggedOut) {
      loginThroughAuth0()
      return
    }

    // eslint-disable-next-line
  }, [isLoading])

  if (orgsToChoose)
    return (
      <LoginOrganizationPicker
        organizations={orgsToChoose}
        onOrganizationClick={async organizationId => {
          await exchangeAuth0Tokens(organizationId)
        }}
      />
    )

  return (
    <LogInAndOut
      showLoadingState={!loggedOut || showLoadingState}
      rockwellLogin={rockwellLogin}
      onClick={loginThroughAuth0}
    />
  )
}

type LogoutMessageCode = 'sessionExpired' | 'eulaNotSigned' | 'userNoOrg' | 'errorSigningEula' | 'errorLoggingIn'
export const logoutMessages = {
  sessionExpired: () =>
    infoNotification({
      id: 'session-expired-notification',
      title: 'Your session expired',
      description: 'You were logged out because of your security settings. Log in to start a new session.',
      position: 'bottom-left',
      duration: 0,
    }),
  userNoOrg: () => error({ id: 'user-no-org-error-message', title: 'You do not belong to any organizations' }),
  eulaNotSigned: () =>
    info({
      id: 'eula-not-signed-message',
      title: 'End User Licence Agreement',
      description:
        'An Admin user in your organization must approve the End User Licence Agreement before any other user can log in.',
      position: 'bottom-left',
      duration: 0,
    }),
  errorSigningEula: () => {
    error({ id: 'eula-signing-error', title: 'There was an issue signing the EULA. Please try again.' })
  },
  errorLoggingIn: () => {
    error({ id: 'token-exchange-error', title: 'There was an issue logging in. Please contact the Administrator' })
  },
}
