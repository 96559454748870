import React, { useEffect, useMemo, useState } from 'react'

import { cloneDeep, groupBy } from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-redux-query'
import { useHistory } from 'react-router-dom'

import { getterKeys, service, wsPaths } from 'api'
import { Button } from 'components/Button/Button'
import { CardWithThumbnailAndStatus } from 'components/CardWithThumbnailAndStatus/CardWithThumbnailAndStatus'
import { Divider } from 'components/Divider/Divider'
import FetchingColocatedRobot from 'components/FetchingColocatedRobot/FetchingColocatedRobot'
import MultiVideoListener from 'components/MultiVideoListener/MultiVideoListener'
import { PrismElementaryCube } from 'components/prismIcons'
import { PrismLayout } from 'components/PrismLayout/PrismLayout'
import { loading } from 'components/PrismMessage/PrismMessage'
import { success } from 'components/PrismMessage/PrismMessage'
import { dismiss, warning } from 'components/PrismMessage/PrismMessage'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import RobotsStatusListener from 'components/RobotsStatusListener'
import RobotToolsetsFetcher from 'components/RobotToolsetsFetcher'
import { OfflineTag } from 'components/Tag/Tag'
import Video from 'components/Video/Video'
import {
  useColocatedStation,
  useConnectionStatus,
  useData,
  useStationStatus,
  useStatusByRobotId,
  useToolsetsByRobotId,
  useTypedSelector,
} from 'hooks'
import { START_BATCH_MESSAGE_ID } from 'pages/StationDetail/StationDetail'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import {
  DeploymentStatus,
  RecipeExpanded,
  RecipeRoutine,
  Robot,
  RoutineWithAois,
  StatusCommandRecipe,
  Toolset,
} from 'types'
import { getAccumulatedDeploymentStatus, getRobotDisplayName, getToolsetsDeploymentStatus, log } from 'utils'

import Styles from './NewBatch.module.scss'
import RecipeSelection from './RecipeSelection'
import RoutineSelectionScreen from './RoutineSelectionScreen'

export const BATCH_TIMESTAMP_FORMAT = 'MM-DD-YY HH:mm:ss'

export const BatchErrorLevels = {
  SemiDeployedRecipe: 'warning',
  TriggerMismatch: 'error',
  AllRobotsOffline: 'error',
  SomeRobotsOffline: 'warning',
} as const

/**
 * Renders the new batch screens where users will be able to modify the name
 * and parameters of each robot on a given station.
 *
 * Business Rules:
 * - The batch's default format will be `[Station Name] [Current date]`
 * - All routines deployed on a given inspection must have the same trigger type
 * - A station is considered multi cam if it has more than one associated robot
 * - The routines available in a given robot's dropdown are determined by the **deployments**
 *   that robot has and grouped by routine parent. This means each robot will only show
 *   a single version per routine parent.
 * - An inspection can only be started if a routine is set on a robot
 * - When starting an inspection, an inspection entry is created using Django and a
 *   command is sent directly to vision-processing to start the inspection.
 *
 * @param stationId - The selected station for which we want to add a new batch
 */
const NewBatch = ({ stationId }: { stationId?: string }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [recipeFromEdge, setRecipeFromEdge] = useState<RecipeExpanded>()
  const [selectedRecipe, setSelectedRecipe] = useState<RecipeExpanded>()
  const [selectedRobotId, setSelectedRobotId] = useState<string>()
  const [batchName, setBatchName] = useState<string>()

  const timestamp = useMemo(() => moment(), [])

  const { colocatedStation } = useColocatedStation()
  const fetchedStation = useQuery(stationId ? getterKeys.station(stationId) : undefined, () =>
    service.getStation(stationId!),
  ).data?.data

  const station = useMemo(() => {
    if (colocatedStation?.id === stationId) return colocatedStation
    return fetchedStation
  }, [colocatedStation, fetchedStation, stationId])

  const connectionStatus = useConnectionStatus()

  const me = useData(getterKeys.me())

  const stationStatus = useStationStatus(station)
  const robotIds = station?.robots.map(rbt => rbt.id)
  const statusByRobotId = useStatusByRobotId(robotIds)

  const isMultiCamStation = (station?.robots.length || 0) > 1
  const selectedRobot = station?.robots.find(robot => robot.id === selectedRobotId)

  const selectedRecipeIdFromRedux = useTypedSelector(state => state.inspector.selectedRecipeId)

  // When the user selects another recipe we need to wipe what we had fetched from asset management
  useEffect(() => {
    setRecipeFromEdge(undefined)
  }, [selectedRecipeIdFromRedux])

  const toolsetsByRobotId = useToolsetsByRobotId(robotIds)

  const deployedRecipes = useMemo(() => {
    const toolSets = Object.values(toolsetsByRobotId)
    const recipeToolSets = toolSets.reduce(
      (currArray, toolset) => [...currArray, ...Object.values(toolset)],
      [] as Toolset[],
    )

    const loadedToolsets = recipeToolSets.filter(recipeToolSet => recipeToolSet.recipe_status.state === 'LOADED')

    const groupedToolsets = groupBy(loadedToolsets, toolsets => toolsets.recipe?.parent_id)

    const filteredToolsets = []
    for (const recipeParentToolsets of Object.values(groupedToolsets)) {
      const sortedByLatest = recipeParentToolsets.sort(
        (toolsetA, toolsetB) =>
          toolsetB.recipe_status.metadata.deployed_at - toolsetA.recipe_status.metadata.deployed_at,
      )
      filteredToolsets.push(sortedByLatest[0]!)
    }

    return filteredToolsets
      .sort(
        (toolsetA, toolsetB) =>
          toolsetB.recipe_status.metadata.deployed_at - toolsetA.recipe_status.metadata.deployed_at,
      )
      .map(toolset => toolset.recipe)
      .filter((rd): rd is StatusCommandRecipe => !!rd)
  }, [toolsetsByRobotId])

  const recipeFromDb = useQuery(
    selectedRecipeIdFromRedux ? getterKeys.recipe(selectedRecipeIdFromRedux) : undefined,
    selectedRecipeIdFromRedux ? () => service.getRecipe(selectedRecipeIdFromRedux) : undefined,
  ).data?.data

  useEffect(() => {
    if (!selectedRecipeIdFromRedux) return

    let robotIdToFetchRecipeExpandedFrom = ''
    for (const [robotId, toolsets] of Object.entries(toolsetsByRobotId)) {
      const recipeIsDeployedToRobot = toolsets.find(toolset => selectedRecipeIdFromRedux === toolset.recipe.id)

      if (recipeIsDeployedToRobot) {
        robotIdToFetchRecipeExpandedFrom = robotId
        break
      }
    }

    if (!robotIdToFetchRecipeExpandedFrom) return

    const getRecipeExpandedFromEdge = async () => {
      const res = await service.atomSendCommandExtractData<{ recipe: RecipeExpanded }>(
        'asset-management',
        'get_recipe_definition',
        robotIdToFetchRecipeExpandedFrom,
        {
          command_args: { id: selectedRecipeIdFromRedux, load_golden_image: false },
        },
      )

      if (res.type !== 'success') return
      setRecipeFromEdge(res.data.recipe)
    }

    getRecipeExpandedFromEdge()
  }, [toolsetsByRobotId, selectedRecipeIdFromRedux])

  useEffect(() => {
    const recipeToUse = recipeFromDb || recipeFromEdge
    const recipeClone = cloneDeep(recipeToUse)
    if (recipeClone) {
      // Remove recipe routines that aren't deployed
      recipeClone.recipe_routines = recipeClone.recipe_routines.filter(
        recipeRoutine =>
          !!toolsetsByRobotId[recipeRoutine.robot_id]?.find(toolset => toolset.recipe.id === recipeClone.id),
      )
    }
    setSelectedRecipe(recipeClone)
  }, [recipeFromDb, recipeFromEdge, toolsetsByRobotId])

  const robotsWithDeployStatus = useMemo(() => {
    const robotsWithStatus: { [robotId: string]: DeploymentStatus } = {}

    selectedRecipe?.recipe_routines.forEach(recipeRoutine => {
      const currentToolSets = toolsetsByRobotId[recipeRoutine.robot_id]
      if (!currentToolSets || !selectedRecipe.id) return
      robotsWithStatus[recipeRoutine.robot_id] = getToolsetsDeploymentStatus(currentToolSets, selectedRecipe.id)
    })
    return robotsWithStatus
  }, [selectedRecipe?.recipe_routines, toolsetsByRobotId, selectedRecipe?.id])

  const deploymentStatus = useMemo(() => {
    if (!selectedRecipe || !robotsWithDeployStatus) return

    const allRobotStatus = Object.values(robotsWithDeployStatus)

    return getAccumulatedDeploymentStatus(allRobotStatus)
  }, [selectedRecipe, robotsWithDeployStatus])

  const batchErrorType: keyof typeof BatchErrorLevels | undefined = useMemo(() => {
    const robotStatuses = Object.values(statusByRobotId || {})

    if (robotStatuses.every(status => status === 'disconnected')) return 'AllRobotsOffline'

    if (robotStatuses.some(status => status === 'disconnected')) return 'SomeRobotsOffline'

    if (deploymentStatus === 'semi-deployed') return 'SemiDeployedRecipe'

    // Routine to compare against to find if we have mismatched routines selected
    if (selectedRecipe && areTriggersMismatched(selectedRecipe.recipe_routines)) return 'TriggerMismatch'
  }, [deploymentStatus, selectedRecipe, statusByRobotId])

  useEffect(() => {
    // This effect gets cleans up the selected recipe id when the component unmounts
    return () => {
      dispatch(
        Actions.inspectorUpdate({
          selectedRecipeId: null,
        }),
      )
    }
  }, [dispatch])

  const robotIdsWithLinkedRoutines = selectedRecipe?.recipe_routines.map(recipeRoutine => recipeRoutine.robot_id)

  const handleStartInspectionButtonClick = async () => {
    if (!selectedRecipe) return

    loading({
      title: 'Starting batch...',
      id: START_BATCH_MESSAGE_ID,
      duration: 0,
      'data-testid': 'new-batch-starting-message',
    })

    const startInspection = async (robotId: string) => {
      const res = await service.atomSendCommand(
        'vision-processing',
        'start_inspection',
        robotId,
        {
          command_args: {
            recipe_id: selectedRecipe.id,
            recipe: selectedRecipe,
            inspection_name: batchName || timestamp.format(BATCH_TIMESTAMP_FORMAT),
            created_by_id: me?.id,
          },
        },
        // Containers can take a while to spin up and successfully launch an inspection
        { timeout: 10 * 60 * 1000 },
      )

      return { robotId, success: res.type === 'success' && res.data.success, response: res }
    }

    const asyncHandler = async () => {
      if (!robotIdsWithLinkedRoutines) return
      const responses = await Promise.all(robotIdsWithLinkedRoutines.map(startInspection))

      // All successes
      if (responses.every(r => r?.success)) {
        dismiss(START_BATCH_MESSAGE_ID)
        success({ title: 'Batch started', 'data-testid': 'new-batch-started-success' })
      }

      // Some successes
      if (responses.some(r => r?.success) && responses.some(r => !r?.success)) {
        const successes = responses.filter(r => r?.success).length
        const failures = responses.length - successes
        dismiss(START_BATCH_MESSAGE_ID)

        // Let user know that this was partially successful
        warning({
          title: `Successfully created inspection for ${successes} camera(s), but couldn't create inspection for ${failures} camera(s)`,
        })
      }

      // All failures
      if (responses.every(r => !r?.success)) {
        dismiss(START_BATCH_MESSAGE_ID)
        warning({ title: "Couldn't create an inspection, please try again" })
        log('src/components/InspectionSelectionOverlay/InspectionSelectionOverlay.tsx', 'createInspectionError', {
          responses,
        })
      }
    }

    asyncHandler()
    history.push(redirectPath)
  }

  useEffect(() => {
    if (!station?.robots) return

    // Automatically select the first robot on the list
    if (!selectedRobotId) setSelectedRobotId(station.robots[0]?.id)
  }, [station, selectedRobotId])

  const getRobotHighlightType = (robot: Robot) => {
    // Special recipe states
    if (batchErrorType === 'TriggerMismatch') return 'error'
    if (robotsWithDeployStatus[robot.id] === 'not-deployed') return 'warning'
    // partial/all cameras are offline
    if (Object.values(statusByRobotId || {}).every(status => status === 'disconnected')) return 'error'
    if (statusByRobotId?.[robot.id] === 'disconnected') return 'warning'

    return 'pass'
  }

  const redirectPath = useMemo(
    () => (station ? paths.stationDetail('overview', station?.id) : paths.inspect({ mode: 'site' })),
    [station],
  )

  let stateListener = null
  if (station) stateListener = <RobotsStatusListener robotIds={station.robots.map(robot => robot.id)} />
  const inspectionRunning = stationStatus === 'running'

  const handleRecipeUpdate = (newRoutineDefinition: RoutineWithAois) => {
    if (!selectedRecipe) return

    const modifiedRecipe = { ...selectedRecipe }
    selectedRecipe.recipe_routines.forEach((recipeRoutine, idx) => {
      if (recipeRoutine.routine.id === newRoutineDefinition.id) {
        modifiedRecipe.recipe_routines[idx]!.routine = newRoutineDefinition
      }
    })
    setSelectedRecipe(modifiedRecipe)
  }

  return (
    <PrismLayout
      breadcrumbs={[
        { label: 'Inspect', path: paths.inspect({ mode: 'site' }) },
        { label: station?.name || 'Station', path: redirectPath },
        {
          menu: (
            <>
              <span className={Styles.newBatchBreadcrumb}>New Batch</span>
              {connectionStatus === 'offline' && <OfflineTag className={Styles.offlineTag} />}
            </>
          ),
        },
      ]}
      menuItems={
        <>
          <Button to={redirectPath} size="small" type="secondary" data-testid="new-batch-cancel-button">
            Cancel
          </Button>

          <PrismTooltip
            condition={inspectionRunning}
            placement="bottomRight"
            title="Batch already running on station"
            anchorClassName={Styles.startButtonWrapper}
          >
            <Button
              className={Styles.startButton}
              size="small"
              disabled={
                (batchErrorType && BatchErrorLevels[batchErrorType] === 'error') || inspectionRunning || !selectedRecipe
              }
              onClick={handleStartInspectionButtonClick}
              data-testid="new-batch-start-batch-button"
            >
              Run Batch
            </Button>
          </PrismTooltip>
        </>
      }
    >
      {!station ? (
        <FetchingColocatedRobot station={station} />
      ) : (
        <main className={`${Styles.newBatchMain} ${isMultiCamStation ? Styles.multiCamLayout : ''}`}>
          {stateListener}
          {station.robots.map(robot => (
            <RobotToolsetsFetcher key={robot.id} robotId={robot.id} intervalMs={5 * 1000} />
          ))}
          {selectedRobot && (
            <RecipeSelection
              selectedRecipe={selectedRecipe}
              onSelectRecipe={(recipeId: string) => {
                dispatch(
                  Actions.inspectorUpdate({
                    selectedRecipeId: recipeId,
                  }),
                )
              }}
              deployedRecipes={deployedRecipes}
              batchErrorType={batchErrorType}
              className={Styles.recipeSection}
              showNewBatchAlert={
                Object.keys(toolsetsByRobotId).length === station?.robots.length && deployedRecipes.length === 0
              }
              batchName={batchName}
              setBatchName={setBatchName}
              stationId={station.id}
            />
          )}
          <Divider type="vertical" />
          {isMultiCamStation && (
            <>
              <section className={Styles.camListSection}>
                {station.robots.map(robot => (
                  <CardWithThumbnailAndStatus
                    onClick={() => {
                      setSelectedRobotId(robot.id)
                    }}
                    key={robot.id}
                    robotId={robot.id}
                    title="Camera"
                    name={getRobotDisplayName(robot)}
                    active={robot.id === selectedRobotId}
                    badgeType={getRobotHighlightType(robot)}
                    showBadge={robotIdsWithLinkedRoutines?.includes(robot.id)}
                    imageContainerClassName={Styles.imageContainer}
                    className={Styles.camCard}
                  >
                    <Video
                      key={robot.id}
                      robotId={robot.id}
                      relativeUrl={wsPaths.videoBaslerThumbnail(robot.id)}
                      showSpinner={false}
                      fallbackImage={<PrismElementaryCube addBackground />}
                    />
                  </CardWithThumbnailAndStatus>
                ))}
              </section>
              <Divider type="vertical" />
            </>
          )}

          {selectedRobot && (
            <RoutineSelectionScreen
              robot={selectedRobot}
              isMulticam={isMultiCamStation}
              selectedRecipe={selectedRecipe}
              onRoutineUpdate={handleRecipeUpdate}
              batchErrorType={batchErrorType}
              recipeNotDeployed={robotsWithDeployStatus[selectedRobot.id] === 'not-deployed'}
              key={selectedRobot.id}
            />
          )}

          {isMultiCamStation && (
            <MultiVideoListener
              element="transcoder-basler-image-thumbnail"
              stream="compressed"
              robotIds={station.robots.map(robot => robot.id)}
            />
          )}
        </main>
      )}
    </PrismLayout>
  )
}

export const areTriggersMismatched = (recipeRoutines: RecipeRoutine[]) => {
  const firstRoutine = recipeRoutines[0]?.routine
  return recipeRoutines?.some(
    recipeRoutine =>
      recipeRoutine.routine?.settings?.camera_trigger_mode !== firstRoutine?.settings?.camera_trigger_mode,
  )
}

export default NewBatch
