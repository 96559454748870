import { getterKeys, query, service } from 'api'
import { dismiss, error, info, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import paths from 'paths'
import typedStore from 'rdx/store'
import { DeepCopyConfig, RecipeOverviewMode, recipeOverviewModes } from 'types'
import { searchLocationHistory, windowBlur } from 'utils'

import Styles from './PromptDeepCopy.module.scss'

const CONFIRM_MODAL_ID = 'deep-copy-confirmation-modal'

/**
 * Show a modal prompting the user to create a "new version" of routine
 * specified in config object.
 *
 * DON'T USE THIS FUNCTION to prevent the user from editing fields that are not
 * actually protected, such as Tool.inference_user_args, Tool.muted,
 * or the name of any AOI, or Tool.
 *
 * @param config - Deep copy config object
 */
export function promptDeepCopy(config: DeepCopyConfig) {
  const { recipe, routineParentId, history, onBeforeCreate, onCreate, store = typedStore } = config
  const state = store.getState()

  // Make sure the element on the background is deselcted, otherwise users will be able to keep editing while the modal is open.
  // Possibly move this into PrismModal component
  windowBlur()

  const handleOk = async () => {
    const okMessage = 'ok-message'
    info({ title: 'Creating new version', duration: 0, id: okMessage })

    const robotIdByRoutineId: { [routineId: string]: string } = {}
    recipe.recipe_routines.forEach(recipeRoutine => {
      if (recipeRoutine.robot_id) {
        robotIdByRoutineId[recipeRoutine.routine.id] = recipeRoutine.robot_id
      }
    })

    const res = await service.duplicateRecipeParent(recipe.parent_id, { robot_id_by_routine_id: robotIdByRoutineId })
    if (res.type !== 'success') {
      dismiss(okMessage)
      return error({ title: 'There was an issue creating the new version please try again', duration: 5000 })
    }

    const location = searchLocationHistory(
      state.locationHistory.history,
      loc => loc.pathname.includes('/setup/recipes/') && !loc.pathname.includes('/angles/'),
    )
    const queryString = location?.search || ''

    const prevMode = getPrevMode(location?.pathname)
    history.push(paths.settingsRecipe(recipe.parent_id, prevMode) + queryString)

    const routine = res.data.recipe_routines.find(
      recipeRoutine => recipeRoutine.routine.parent.id === routineParentId,
    )?.routine
    success({ title: 'Created new version of recipe', 'data-testid': 'utils-new-recipe-version-success' })

    await onCreate?.(res.data, routine)
    // Refetch recipe parent after creating new version, because working_version has changed
    dismiss(okMessage)
    await query(getterKeys.recipeParent(recipe.parent_id), () => service.getRecipeParent(recipe.parent_id), {
      dispatch: store.dispatch,
    })
  }

  modal.confirm({
    header: 'Create new version of this recipe?',
    id: CONFIRM_MODAL_ID,
    content: 'This recipe was already deployed. If you want to update it, you need to create a new version.',
    okText: 'Create New Version',
    overlayClassName: Styles.deepCopyModalOverlay,
    onCancel: () => config.onCancel?.(),
    onOk: async close => {
      if (onBeforeCreate) onBeforeCreate()
      await handleOk()
      close()
    },
    'data-testid': 'utils-create-new-recipe-version',
  })
}

const getPrevMode = (pathname?: string): RecipeOverviewMode => {
  if (!pathname) return 'capture'

  for (const mode of recipeOverviewModes) {
    if (pathname.includes(mode)) return mode
  }

  return 'capture'
}
