import React from 'react'

import GenericBlankStateMessage from 'components/BlankStates/GenericBlankStateMessage'
import { Button } from 'components/Button/Button'
import { PrismAddIcon, PrismProductIcon, PrismSiteIcon, PrismStationIcon } from 'components/prismIcons'

import Styles from './InspectEmptyState.module.scss'

const iconsToShow = {
  Site: <PrismSiteIcon />,
  Product: <PrismProductIcon />,
  Station: <PrismStationIcon />,
}

const InspectEmptyState = ({
  mode,
  onClick,
  canAddEntity,
}: {
  mode: 'Site' | 'Product' | 'Station'
  onClick: () => void
  canAddEntity?: boolean
}) => {
  const inspectEmptyData: {
    title: string
    description: string | undefined
  } = canAddEntity
    ? { title: 'Start by adding a new ', description: undefined }
    : { title: 'No assigned ', description: 'Please contact your manager for more details.' }

  return (
    <GenericBlankStateMessage
      header={iconsToShow[mode]}
      title={`${inspectEmptyData.title} ${mode}${!canAddEntity ? 's' : ''}`}
      description={inspectEmptyData.description}
      className={Styles.emptyStateContainer}
    >
      {canAddEntity && (
        <Button onClick={onClick} size="small" className={Styles.productsEmptyStateButton} badge={<PrismAddIcon />}>
          Add {mode}
        </Button>
      )}
    </GenericBlankStateMessage>
  )
}
export default InspectEmptyState
