import { useEffect, useMemo } from 'react'

import { useDispatch } from 'react-redux'

import { getterKeys } from 'api'
import { error } from 'components/PrismNotification/PrismNotification'
import { ALIGNMENT_FAILURE_NOTIFICATION_NUMBER, ALIGNMENT_FAILURE_NOTIFICATION_RATE } from 'env'
import { useData, useQueryItemMetrics, useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import { Inspection } from 'types'
import { CombinedOutcomeData, combineOutcomeCounts } from 'utils'

interface AdjustAlignmentNotifierProps {
  inspection: Inspection | undefined
  manualTrigger: boolean
  isHistoricBatch: boolean
}

/**
 * Figures out through metrics if we should render the notification to the user
 * to indicate that he should adjust his alignment as it's failing repeatedly.
 *
 * @param inspection - The running inspection
 * @param manualTrigger - Whether it's a manual inspection
 * @param isHistoricBatch - Whether we're looking at a historic batch
 */
function AdjustAlignmentNotifier({ inspection, manualTrigger, isHistoricBatch }: AdjustAlignmentNotifierProps) {
  const dispatch = useDispatch()
  const routines = useData(inspection ? getterKeys.inspectionRoutines(inspection.id) : undefined)
  const aois = useMemo(() => routines?.results.flatMap(routine => routine.aois), [routines])
  const { inspectionsAlertedAlignmentFailing } = useTypedSelector(state => state.inspector)

  const itemsData = useQueryItemMetrics(inspection, 'item_outcome_inspection', manualTrigger, {
    isHistoricBatch,
  }).itemMetricsRes?.data.results

  const aoiAndLabelsMetricsData = useData(inspection ? getterKeys.aoiAndLabelMetrics(inspection.id) : undefined)
  const aoiData = aoiAndLabelsMetricsData?.aoiMetrics

  const itemSeries = useMemo(() => itemsData && combineOutcomeCounts(itemsData), [itemsData])

  const totalCount = (itemSeries || []).reduce((aggr: number, data) => aggr + (data.count || 0), 0)

  const seriesByAoiId = useMemo(() => {
    const toReturn: { [aoiId: string]: CombinedOutcomeData[] } = {}
    if (aoiData) {
      const aoiIds = [...new Set(aoiData.map(result => result.labels.aoi_id))]
      for (const id of aoiIds) {
        toReturn[id!] = combineOutcomeCounts(aoiData.filter(result => result.labels.aoi_id === id))
      }
    }
    return toReturn
  }, [aoiData])

  useEffect(() => {
    if (!inspection?.id) return
    if (inspectionsAlertedAlignmentFailing[inspection.id]) return
    if (totalCount < ALIGNMENT_FAILURE_NOTIFICATION_NUMBER) return

    const alignmentAoiId = aois?.find(aoi => aoi.tools.find(tool => tool.specification_name === 'alignment'))?.id
    if (!alignmentAoiId) return

    const alignmentAoiCounts = seriesByAoiId[alignmentAoiId]
    if (!alignmentAoiCounts) return

    const fail = alignmentAoiCounts.reduce((aggr: number, data) => aggr + (data.fail || 0), 0)
    const count = alignmentAoiCounts.reduce((aggr: number, data) => aggr + (data.count || 0), 0)
    const failRate = fail / (count || 1)

    if (failRate >= ALIGNMENT_FAILURE_NOTIFICATION_RATE) {
      inspectionsAlertedAlignmentFailing[inspection.id] = true
      dispatch(Actions.inspectorUpdate({ inspectionsAlertedAlignmentFailing }))
      error({
        title: 'Adjust Anchoring',
        description:
          'Your anchoring is failing at a high rate. Try adjusting the anchors and anchor settings in this recipe or positioning the item differently.',
        position: 'top-left',
        duration: 0,
      })
    }
    // only check for this when we refetch metrics
  }, [seriesByAoiId]) // eslint-disable-line
  return null
}

export default AdjustAlignmentNotifier
