import React, { useRef } from 'react'

import { ListChildComponentProps, ListOnItemsRenderedProps, VariableSizeList } from 'react-window'

import { useContainerDimensions } from 'hooks'
import { ToolResult } from 'types'

import { MemoFocusCard } from './FocusCard'
import Styles from './LabelingScreen.module.scss'

/**
 * This is a wrapper around FixedSizeGrid that allows us to use the same behavior for
 * the Labeling Screen and the expanded Group By carousels.
 *
 * @param uniqueToolResults - The unique toolResults that are available to the user
 * @param selectedToolResult - The currently selected toolResult
 * @param onSetToolResult - Handler for when clicking on a given tool card
 * @param fetchNextPage - Function that fetches the next page of toolResults
 * @param listRef - The React ref for the list
 * @param galleryBodyRef - The React ref for the gallery body
 */
const FocusCarousel = ({
  uniqueToolResults,
  selectedToolResult,
  onSetToolResult,
  fetchNextPage,
  listRef,
  galleryBodyRef,
}: {
  uniqueToolResults?: ToolResult[]
  selectedToolResult?: ToolResult
  onSetToolResult: (toolResult: ToolResult) => void
  fetchNextPage: () => Promise<ToolResult | undefined>
  listRef?: React.RefObject<VariableSizeList>
  galleryBodyRef: React.RefObject<HTMLElement>
}) => {
  // Pagination refs and state
  const isFetchingMoreRef = useRef(false)
  const containerDimensions = useContainerDimensions(galleryBodyRef)

  const handleItemsRendered = async ({ visibleStopIndex }: ListOnItemsRenderedProps) => {
    if (isFetchingMoreRef.current) return
    if (visibleStopIndex + 5 >= (uniqueToolResults?.length || 0)) {
      isFetchingMoreRef.current = true
      await fetchNextPage()
      isFetchingMoreRef.current = false
    }
  }

  const getItemSize = (index: number) => {
    // The first item must have a different height since the carousel has an extra 16px margin
    // on top. This couldn't be fixed with pure css since the virtual list uses position:absolute
    // which ignores margins and paddings in containers
    if (index === 0) return 221
    return 205
  }

  return (
    <VariableSizeList
      ref={listRef}
      width={containerDimensions?.width || 0}
      height={containerDimensions?.height || 0}
      itemCount={uniqueToolResults?.length || 0}
      itemSize={getItemSize}
      className={Styles.virtualizedGridContainer}
      onItemsRendered={handleItemsRendered}
      itemData={{
        uniqueToolResults,
        selectedToolResult,
        onSetToolResult,
      }}
    >
      {GalleryCardListRenderer}
    </VariableSizeList>
  )
}

/**
 * GalleryCard component used for performant pagination implementations.
 */
const GalleryCardListRenderer = React.memo(
  ({
    index,
    style,
    data,
  }: ListChildComponentProps<{
    uniqueToolResults: ToolResult[] | undefined
    selectedToolResult?: ToolResult
    onSetToolResult: (toolResult: ToolResult) => void
  }>) => {
    const { uniqueToolResults, selectedToolResult, onSetToolResult } = data

    const toolResult = uniqueToolResults?.[index]

    if (!toolResult) return null

    return (
      <div className={Styles.focusCardWrapper} style={style}>
        <MemoFocusCard
          key={toolResult.id}
          toolResult={toolResult}
          selectedToolResult={selectedToolResult}
          onSetToolResult={onSetToolResult}
        />
      </div>
    )
  },
)

export default FocusCarousel
