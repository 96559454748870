import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconSeverityProps } from './prismIconsTypes'

/**
 * Prism Minor Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6986%3A12294
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  iconColor: Styles.iconColor,
  iconAndBackground: Styles.iconAndBackground,
}
export const PrismMinorIcon = ({
  className,
  viewboxSize = 'medium',
  variant = 'transparent',
}: PrismIconSeverityProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]
  const iconClassName = `${Styles.icon} ${Styles.severity} ${Styles.minor} ${variantClass} ${className ?? ''}`
  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 9L10 12L13 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" />
    </svg>
  )
}
