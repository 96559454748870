/**
 *  Calculate HSV value from OpenCv library from RGBA
 *
 * @param rgbaArray an array of 4 elements, r, g, b, and a
 */
export const convertRgbaToHsv = (rgbaArray: number[]) => {
  const r = (rgbaArray[0] || 0) / 255
  const g = (rgbaArray[1] || 0) / 255
  const b = (rgbaArray[2] || 0) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h = 0
  let s = 0
  let v = max
  var d = max - min
  s = max === 0 ? 0 : d / max

  if (max === min) {
    h = 0 // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }

    h /= 6
  }

  h = h * 179
  s = s * 255
  v = v * 255
  return [h, s, v]
}

const rangeSv = 20
const rangeH = 35

const getHsvLimits = (value: number, range: number, limit: number) => {
  if (value - range < 0) {
    return {
      min: 0,
      max: Math.floor(value * 2),
    }
  }

  if (value + range > limit) {
    const diff = limit - value
    return {
      min: Math.floor(value - diff),
      max: limit,
    }
  }
  return {
    min: Math.floor(value - range),
    max: Math.floor(value + range),
  }
}

/**
 * Calculate the ranges min and max of hsv values depending on some arbitrary ranges calculated by trial and error.
 *
 * @param hsvValues an array of three elements, h,s and v
 */
export const calculateHsvRanges = (hsvValues: number[]) => {
  const { min: hMin, max: hMax } = getHsvLimits(hsvValues[0] || 0, rangeH, 179)

  const { min: sMin, max: sMax } = getHsvLimits(hsvValues[1] || 0, rangeSv, 255)

  const { min: vMin, max: vMax } = getHsvLimits(hsvValues[2] || 0, rangeSv, 255)

  return { min: [hMin, sMin, vMin], max: [hMax, sMax, vMax] }
}

// From: https://gist.github.com/mjackson/5311256#file-color-conversion-algorithms-js-L109

/**
 * Converts HSV values into RGB
 * @param h Hue number
 * @param s Saturation number
 * @param v Value number
 */
export function hsvToRgb(h: number, s: number, v: number) {
  let r = 0,
    g = 0,
    b = 0

  const i = Math.floor(h * 6)
  const f = h * 6 - i
  const p = v * (1 - s)
  const q = v * (1 - f * s)
  const t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
      r = v
      g = p
      b = q
      break
  }

  return [r * 255, g * 255, b * 255]
}

/**
 * Get the rgb array and string from the hsv ranges selected
 *
 * @param hsvMin array of minimum values of hsv
 * @param hsvMax array of maximum values of hsv
 * @returns - An object containing an array of rgb values and a string of rgb values
 */
export const getRgbFromHsvRanges = ({
  hsv_min_h,
  hsv_min_s,
  hsv_min_v,
  hsv_max_h,
  hsv_max_s,
  hsv_max_v,
}: {
  hsv_min_h: number
  hsv_min_s: number
  hsv_min_v: number
  hsv_max_h: number
  hsv_max_s: number
  hsv_max_v: number
}) => {
  // First we get the average hsv value, and convert to hsv value ranges (0 - 1, 0 - 1, 0 - 1) as opposed to openCv values (0 - 179, 0 - 255, 0 - 255)
  const h = ((hsv_min_h + hsv_max_h) / 2) * (1 / 179)
  const s = ((hsv_min_s + hsv_max_s) / 2) * (1 / 255)
  const v = ((hsv_min_v + hsv_max_v) / 2) * (1 / 255)

  const rgbArray = hsvToRgb(h, s, v)

  return { rgbArr: rgbArray, rgbString: `rgb(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]})` }
}

export const getColorUnderCursor = (e: any, id: string) => {
  // Gets the canvas again inside the listener callback
  const canvas = document.getElementById(id) as HTMLCanvasElement

  if (!canvas) return

  // Get the size of the canvas
  const rect = canvas.getBoundingClientRect()

  // Gets the scale of the canas
  const scaleX = canvas.width / rect.width
  const scaleY = canvas.height / rect.height

  // Finds where exactly the user's mouse is relative to the canvas scale
  const x = (e.clientX - rect.left) * scaleX
  const y = (e.clientY - rect.top) * scaleY

  const ctx = canvas.getContext('2d')
  if (!ctx) return

  // returns the color underneath the cursor
  return ctx.getImageData(x, y, 1, 1).data
}
