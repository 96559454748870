import React from 'react'

import {
  PrismAlignToolIcon,
  PrismAnomalyToolIcon,
  PrismBarcodeToolIcon,
  PrismColorToolIcon,
  PrismDefectToolIcon,
  PrismDiscardIcon,
  PrismErrorIcon,
  PrismFailIcon,
  PrismGradedAnomalyToolIcon,
  PrismInfoIcon,
  PrismMatchToolIcon,
  PrismMeasureToolIcon,
  PrismMuteIcon,
  PrismPassIcon,
  PrismRandomToolIcon,
  PrismSuccessIcon,
  PrismTextToolIcon,
  PrismUnknownIcon,
  PrismWarningIcon,
} from 'components/prismIcons'
import { Outcome, ToolSpecificationName } from 'types'

// Warning: We shouldn't declare JSX within variables as the rendering breaks:
// E.g. Don't do SOME_ICON_VAR = {a: <SomeComp />, b: <SomeOtherComp />}
// E.g. Instead, do SOME_ICON_GETTER = (var: 'a' | 'b') => if(var === 'a') return <SomeComp />, etc...
export const ICON_BY_RESULT = (outcome: Outcome | undefined) => {
  if (outcome === 'pass') return <PrismPassIcon variant="low" />
  if (outcome === 'fail') return <PrismFailIcon variant="low" />
  if (outcome === 'unknown') return <PrismUnknownIcon variant="low" />
  return null
}

export const TOOL_ICONS = (toolSpecification: ToolSpecificationName | undefined, hasHover?: boolean) => {
  if (toolSpecification === 'detect-barcode') return <PrismBarcodeToolIcon hasHover={hasHover} />
  if (toolSpecification === 'deep-svdd') return <PrismAnomalyToolIcon hasHover={hasHover} />
  if (toolSpecification === 'classifier') return <PrismDefectToolIcon hasHover={hasHover} />
  if (toolSpecification === 'graded-anomaly') return <PrismGradedAnomalyToolIcon hasHover={hasHover} />
  if (toolSpecification === 'alignment') return <PrismAlignToolIcon hasHover={hasHover} />
  if (toolSpecification === 'random') return <PrismRandomToolIcon hasHover={hasHover} />
  if (toolSpecification === 'match-classifier') return <PrismMatchToolIcon hasHover={hasHover} />
  if (toolSpecification === 'ocr') return <PrismTextToolIcon hasHover={hasHover} />
  if (toolSpecification === 'color-check') return <PrismColorToolIcon hasHover={hasHover} />
  if (toolSpecification === 'measurement') return <PrismMeasureToolIcon hasHover={hasHover} />
  return null
}

export const NOTIFICATION_ICONS = (
  type: 'success' | 'error' | 'warning' | 'info' | 'loading' | 'mute' | 'discard' | undefined,
) => {
  if (type === 'success') return <PrismSuccessIcon isActive />
  if (type === 'error') return <PrismErrorIcon isActive />
  if (type === 'warning') return <PrismWarningIcon isActive />
  if (type === 'info') return <PrismInfoIcon isActive />
  if (type === 'loading') return <PrismInfoIcon isActive />
  if (type === 'mute') return <PrismMuteIcon />
  if (type === 'discard') return <PrismDiscardIcon />
  return null
}
