import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import DetailItemList from 'components/DetailItemList/DetailItemList'
import { GoldenImageWithAois } from 'components/GoldenImageWithAois/GoldenImageWithAois'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { PrismElementaryCube } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import { RobotDisplayName } from 'components/RobotDisplayName/RobotDisplayName'
import { Status } from 'components/Status/Status'
import BarcodeToolSettingsModal from 'components/ToolSettingsModals/BarcodeToolSettingsModal/BarcodeToolSettingsModal'
import GradedAnomalyResultModal from 'components/ToolSettingsModals/GradedAnomalyResultModal/GradedAnomalyResultModal'
import MatchToolSettingsModal from 'components/ToolSettingsModals/MatchToolSettingsModal/MatchToolSettingsModal'
import OcrToolSettingsModal from 'components/ToolSettingsModals/OcrToolSettingsModal/OcrToolSettingsModal'
import RandomToolSettingsModal from 'components/ToolSettingsModals/RandomToolSettingsModals/RandomToolSettingsModal'
import { useConnectionStatus, useData, useRobotStatus } from 'hooks'
import { TrainingReport } from 'pages/RoutineOverview/Train/TrainingReport/TrainingReport'
import {
  AoiUpdate,
  RecipeExpanded,
  RecipeRoutineExpanded,
  Robot,
  RoutineWithAois,
  Threshold,
  Tool,
  TrainingMetrics,
} from 'types'
import {
  getAoisAndToolsFromRoutine,
  getRobotDisplayName,
  getToolAoisFromRoutine,
  isToolMuted,
  renderTriggerMode,
} from 'utils'

import { AlertTool } from './AlertTool'
import { BatchErrorLevels } from './NewBatch'
import Styles from './NewBatch.module.scss'
import { ToolCard } from './ToolCard'

type Props = {
  robot: Robot
  isMulticam?: boolean
  selectedRecipe?: RecipeExpanded
  onRoutineUpdate?: (routineDefinition: RoutineWithAois) => void
  batchErrorType?: keyof typeof BatchErrorLevels
  recipeNotDeployed?: boolean
}

/**
 * Renders the configuration screen for routine selection of a given robot. This screen will
 * show a blank state when nothing is selected, and renders a select which will show all
 * available routines that can be started on the selected robot. All AOIs and tools for
 * the selected routine are shown on this screen, and the options to configure them is also
 * available.
 *
 * @param robot - The selected robot
 * @param isMulticam - Whether the station is multicam
 * @param selectedRecipe - The currently selected recipe
 * @param onRoutineUpdate - Handler to update routine settings locally
 * @param batchErrorType - Type of error in the batch screen
 * @param recipeNotDeployed - If the recipe is not deployed to the current routine-robot combination
 *  we could be using.
 */
function RoutineSelectionScreen({
  robot,
  selectedRecipe,
  onRoutineUpdate,
  isMulticam,
  batchErrorType,
  recipeNotDeployed,
}: Props) {
  const dispatch = useDispatch()

  const robotToolsets = useData(getterKeys.robotToolsets(robot.id))
  const connectionStatus = useConnectionStatus()

  const [activeToolId, setActiveToolId] = useState<string>()
  const [editType, setEditType] = useState<'expected_pass_threshold' | 'expected_label' | 'minor_anomaly_result'>()
  const [configTool, setConfigTool] = useState<Tool>()
  const [mutingTool, setMutingTool] = useState<string>()

  const status = useRobotStatus(robot.id)

  const currentView: RecipeRoutineExpanded | undefined = useMemo(() => {
    if (!selectedRecipe) return
    const foundView = selectedRecipe.recipe_routines.find(recipeRoutine => recipeRoutine.robot_id === robot.id)
    if (!foundView) return

    return { ...foundView, recipe: selectedRecipe }
  }, [robot.id, selectedRecipe])

  const linkedRoutine = useMemo(() => {
    return currentView?.routine
  }, [currentView])

  // Make sure to update the selected routine with what we're currently editing in the frontend.
  useEffect(() => {
    if (!linkedRoutine) return
    onRoutineUpdate?.(linkedRoutine)
  }, [linkedRoutine]) // eslint-disable-line

  const { tools } = useMemo(() => getAoisAndToolsFromRoutine(linkedRoutine), [linkedRoutine])

  const updateToolInRoutine = useCallback(
    (modifiedTool: Tool) => {
      if (!linkedRoutine) return

      const modifiedRoutine: RoutineWithAois = {
        ...linkedRoutine,
        aois: linkedRoutine.aois.map(aoi => ({
          ...aoi,
          tools: aoi.tools.map(tool => {
            return {
              ...tool,
              ...(tool.id === modifiedTool.id ? modifiedTool : {}),
            }
          }),
        })),
      }

      onRoutineUpdate?.(modifiedRoutine)
    },
    [onRoutineUpdate, linkedRoutine],
  )

  const handleMuteTool = useCallback(
    async (tool: Tool) => {
      if (!linkedRoutine) return
      const toolIsMuted = isToolMuted(tool)
      modal.confirm({
        id: 'mute-tool',
        header: 'Are you sure?',
        content: toolIsMuted
          ? 'When unmuted, this tool will no longer mark Fails as Pass.'
          : 'When muted, this tool will mark Fails as Pass. This can be useful when testing or training a tool.',
        okText: toolIsMuted ? 'Unmute' : 'Mute',
        onOk: async onClose => {
          // TODO: Most of the code for muting tools is duplicated in the tools tab,
          // we might want to refactor it
          setMutingTool(tool.id)
          if (!tool) return
          const isMuted = !toolIsMuted
          const currentToolAois = getToolAoisFromRoutine(linkedRoutine, tool.id)
          const updatedInferenceUserArgs = {
            ...tool.inference_user_args,
            is_muted: isMuted,
          }
          const mutedAoisUpdates: AoiUpdate[] = currentToolAois.map(aoi => ({
            id: aoi.id,
            inference_user_args: updatedInferenceUserArgs,
          }))

          const updateTool = () => service.patchProtectedTool(tool.id, { aois: mutedAoisUpdates })

          if (connectionStatus === 'online') {
            const res = await updateTool()
            if (res.type === 'error') {
              setMutingTool(undefined)
              return error({ title: 'An error occurred, please try again' })
            }

            if (res.type === 'success') {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
          } else {
            // If the connection status isn't online, there might still be an intermittent connection, so updating Django
            // might not fail, but we don't want to wait for that request to respond, and we don't want to show an error
            updateTool()
          }

          const modifiedTool: Tool = { ...tool, inference_user_args: updatedInferenceUserArgs }

          // Always persist changes locally in case VP goes offline right before we start the inspection, such that VP would fail
          // to fetch the updated routine definitions from Django
          updateToolInRoutine(modifiedTool)

          success({ title: toolIsMuted ? 'Tool is now unmuted' : 'Tool is now muted' })
          setMutingTool(undefined)
          onClose()
        },
      })
    },
    [connectionStatus, dispatch, linkedRoutine, updateToolInRoutine],
  )

  const handleActivateTool = (tool: Tool, activate: boolean) => {
    if (activate) setActiveToolId(tool.id)
    else setActiveToolId(undefined)
  }

  const handleMenuItemClick = useCallback(
    (menuItemKey: string, tool: Tool) => {
      if (menuItemKey === 'mute') handleMuteTool(tool)

      if (menuItemKey === 'configure') setConfigTool(tool)

      if (menuItemKey === 'expected_label') {
        setEditType('expected_label')
        setConfigTool(tool)
      }

      if (menuItemKey === 'expected_pass_threshold') {
        setEditType('expected_pass_threshold')
        setConfigTool(tool)
      }

      if (menuItemKey === 'minor_anomaly_result') {
        setEditType('minor_anomaly_result')
        setConfigTool(tool)
      }
    },
    [handleMuteTool],
  )

  const updateOfflineArgs = useCallback(
    (partialInferenceUserArgs: { [key: string]: any }) => {
      if (!configTool) return
      const modifiedTool: Tool = { ...configTool, inference_user_args: partialInferenceUserArgs }
      updateToolInRoutine(modifiedTool)
    },
    [configTool, updateToolInRoutine],
  )

  const handleSaveThreshold = useCallback(
    async (threshold: Threshold, trainingMetrics?: TrainingMetrics, aoiUpdates?: AoiUpdate[]) => {
      if (!configTool) return

      const updateTool = () =>
        service.patchProtectedTool(configTool.id, {
          aois: aoiUpdates,
          metadata: { ...configTool.metadata, training_metrics: trainingMetrics },
        })

      if (connectionStatus === 'online') {
        const res = await updateTool()

        if (res.type === 'error') {
          error({ title: 'An error occurred updating the pass criteria' })
        }

        if (res.type === 'success') {
          if (linkedRoutine) {
            await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
              dispatch,
            })
          }
        }
      } else {
        // If the connection status isn't online, there might still be an intermittent connection, so updating Django
        // might not fail, but we don't want to wait for that request to respond, and we don't want to show an error
        updateTool()
      }

      const updatedArgs = aoiUpdates?.[0]?.inference_user_args

      updateOfflineArgs({ ...configTool.inference_user_args, ...updatedArgs })
      success({ title: 'Threshold updated' })
      setConfigTool(undefined)
    },
    [configTool, connectionStatus, updateOfflineArgs, linkedRoutine, dispatch],
  )

  const renderToolCard = useCallback(
    (tool: Tool) => {
      const options: Option<string>[] = [{ value: 'mute', title: isToolMuted(tool) ? 'Unmute Tool' : 'Mute Tool' }]

      if (tool.specification_name === 'match-classifier')
        options.push(
          {
            value: 'expected_label',
            title: 'Edit Expected Label',
          },
          {
            value: 'expected_pass_threshold',
            title: 'Edit Threshold',
            disabled: tool.state !== 'successful',
          },
        )

      if (tool.specification_name === 'ocr') options.push({ value: 'configure', title: 'Edit Expected Text' })

      if (tool.specification_name === 'detect-barcode')
        options.push({ value: 'configure', title: 'Edit Expected Barcode' })

      if (tool.specification_name === 'random') options.push({ value: 'configure', title: 'Edit Pass Rate' })

      if (tool.specification_name === 'deep-svdd' || tool.specification_name === 'classifier')
        options.push({ value: 'configure', title: 'Edit Threshold', disabled: tool.state !== 'successful' })

      if (tool.specification_name === 'graded-anomaly')
        options.push(
          {
            value: 'minor_anomaly_result',
            title: 'Edit Outcome of Minor Defects',
            disabled: tool.state !== 'successful',
          },
          {
            value: 'expected_pass_threshold',
            title: 'Edit Threshold',
            disabled: tool.state !== 'successful',
          },
        )

      const region = linkedRoutine?.aois.find(aoi => aoi.tools.find(tl => tl.id === tool.id))

      const isOffline = connectionStatus === 'offline'
      return (
        <ToolCard
          key={tool.id}
          onMouseEnter={() => handleActivateTool(tool, true)}
          onMouseLeave={() => handleActivateTool(tool, false)}
          label="Tool"
          name={tool.parent_name}
          data-test-name="tool-name"
          muted={isToolMuted(tool)}
          img={
            region && (
              <ImageCloseUp
                // We want to remount the image component when we go back online
                key={+isOffline}
                src={linkedRoutine?.image}
                region={region}
                maskingRectangleFill="#1d1d1d"
                loaderType="skeleton"
              />
            )
          }
          optionMenu={
            <OptionMenu openWithClick options={options} onMenuItemClick={val => handleMenuItemClick(val, tool)}>
              <Button size="small" type="secondary" loading={tool.id === mutingTool}>
                Settings
              </Button>
            </OptionMenu>
          }
          imageContainerClassName={Styles.toolCardImageContainer}
        />
      )
    },
    [connectionStatus, handleMenuItemClick, mutingTool, linkedRoutine?.aois, linkedRoutine?.image],
  )

  const getErrorMessage = () => {
    if (!batchErrorType) return

    const getErrorMessage = () => {
      if (batchErrorType === 'SemiDeployedRecipe' && recipeNotDeployed)
        return `${
          selectedRecipe ? selectedRecipe.parent.name + ' v' + selectedRecipe.version : 'Recipe'
        } is not deployed to this camera`
      if (batchErrorType === 'TriggerMismatch') return "This view's trigger mode does not match all the others"
      if (['AllRobotsOffline', 'SomeRobotsOffline'].includes(batchErrorType) && status === 'disconnected')
        return `This camera is offline and will not run ${
          selectedRecipe ? selectedRecipe.parent.name + ' v' + selectedRecipe.version : ''
        }`
      return ''
    }

    return (
      <AlertTool
        status={BatchErrorLevels[batchErrorType] === 'warning' ? 'disconnected' : 'danger'}
        description={getErrorMessage()}
      />
    )
  }

  const aoiIds = useMemo(() => {
    if (!linkedRoutine || !configTool) return []
    return getToolAoisFromRoutine(linkedRoutine, configTool.id).map(aoi => aoi.id)
  }, [configTool, linkedRoutine])

  return (
    <section className={Styles.mainSection}>
      <div className={Styles.cameraDetailsHeader}>
        <RobotDisplayName className={Styles.cameraDetailsTitle} robotName={getRobotDisplayName(robot)} />
        <Status status={status} data-testid="new-batch-robot-status-indicator" className={Styles.cameraDetailsStatus} />
      </div>

      <div
        className={`${Styles.cameraDetailsBody} ${
          isMulticam ? Styles.multiCamDetailsBody : Styles.singleCamDetailsBody
        }`}
      >
        <section className={Styles.cameraSettings}>
          {batchErrorType && getErrorMessage()}

          <DetailItemList
            list={[
              { label: 'view', value: linkedRoutine?.parent.name || '--' },
              {
                label: 'exposure',
                value: linkedRoutine?.settings?.exposure_ms
                  ? `${linkedRoutine?.settings?.exposure_ms.toFixed(3)}ms`
                  : '--',
              },
              {
                label: 'trigger',
                value: renderTriggerMode(linkedRoutine?.settings?.camera_trigger_mode),
                'data-testid': 'trigger',
              },
              {
                label: 'min interval',
                value: linkedRoutine?.settings?.interval_ms ? `${linkedRoutine?.settings?.interval_ms}ms` : '--',
              },
            ]}
          />
        </section>

        <div className={Styles.newBatchToolsList}>
          {linkedRoutine && robotToolsets && <div className={Styles.camToolList}>{tools.map(renderToolCard)}</div>}
          {!linkedRoutine && <CardListEmptyState />}
        </div>

        {!linkedRoutine && (
          <figure className={Styles.aoiContainer}>
            <PrismElementaryCube />
          </figure>
        )}

        {linkedRoutine && (
          <figure className={Styles.aoiContainer}>
            <GoldenImageWithAois
              routine={linkedRoutine}
              showAois
              hoveredTool={tools.find(csfr => csfr.id === activeToolId)}
            />
          </figure>
        )}
      </div>

      {configTool?.specification_name === 'match-classifier' && editType === 'expected_label' && (
        <MatchToolSettingsModal
          tool={configTool}
          onToolUpdate={updateOfflineArgs}
          onClose={async refetch => {
            if (refetch && linkedRoutine) {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
            setConfigTool(undefined)
            setEditType(undefined)
          }}
          aoiIds={aoiIds}
        />
      )}

      {editType === 'minor_anomaly_result' && configTool?.specification_name === 'graded-anomaly' && (
        <GradedAnomalyResultModal
          tool={configTool}
          onToolUpdate={updateOfflineArgs}
          onClose={async refetch => {
            if (refetch && linkedRoutine) {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
            setConfigTool(undefined)
          }}
          aoiIds={aoiIds}
        />
      )}

      {configTool?.specification_name === 'graded-anomaly' &&
        linkedRoutine &&
        editType === 'expected_pass_threshold' && (
          <TrainingReport
            onClose={() => {
              setConfigTool(undefined)
              setEditType(undefined)
            }}
            onCancelThreshold={() => {
              setConfigTool(undefined)
              setEditType(undefined)
            }}
            forceTitle="Edit Minor Anomaly Threshold"
            onSaveThreshold={handleSaveThreshold}
            toolId={configTool.id}
            thresholdButtonText="Apply"
            forceThreshold
            viewInInspection={currentView}
            toolWithOverridesContext={configTool}
          />
        )}

      {configTool?.specification_name === 'match-classifier' &&
        linkedRoutine &&
        editType === 'expected_pass_threshold' && (
          <TrainingReport
            onClose={() => {
              setConfigTool(undefined)
              setEditType(undefined)
            }}
            onCancelThreshold={() => {
              setConfigTool(undefined)
              setEditType(undefined)
            }}
            forceThreshold
            forceTitle="Edit Threshold"
            onSaveThreshold={handleSaveThreshold}
            toolId={configTool.id}
            viewInInspection={currentView}
            toolWithOverridesContext={configTool}
          />
        )}

      {configTool?.specification_name === 'ocr' && (
        <OcrToolSettingsModal
          tool={configTool}
          onToolUpdate={updateOfflineArgs}
          onClose={async refetch => {
            if (refetch && linkedRoutine) {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
            setConfigTool(undefined)
          }}
          aoiIds={aoiIds}
        />
      )}

      {configTool?.specification_name === 'detect-barcode' && (
        <BarcodeToolSettingsModal
          tool={configTool}
          onToolUpdate={updateOfflineArgs}
          onClose={async refetch => {
            if (refetch && linkedRoutine) {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
            setConfigTool(undefined)
          }}
          aoiIds={aoiIds}
        />
      )}

      {configTool &&
        linkedRoutine &&
        (configTool.specification_name === 'deep-svdd' || configTool.specification_name === 'classifier') && (
          <TrainingReport
            onClose={() => setConfigTool(undefined)}
            onCancelThreshold={() => setConfigTool(undefined)}
            forceThreshold
            thresholdButtonText="Save"
            forceTitle="Edit Threshold"
            onSaveThreshold={handleSaveThreshold}
            toolId={configTool.id}
            viewInInspection={currentView}
            toolWithOverridesContext={configTool}
          />
        )}

      {configTool?.specification_name === 'random' && (
        <RandomToolSettingsModal
          tool={configTool}
          onToolUpdate={updateOfflineArgs}
          onClose={async refetch => {
            if (refetch && linkedRoutine) {
              await query(getterKeys.routine(linkedRoutine.id), () => service.getRoutine(linkedRoutine.id), {
                dispatch,
              })
            }
            setConfigTool(undefined)
          }}
          aoiIds={aoiIds}
        />
      )}
    </section>
  )
}

export default RoutineSelectionScreen

const CardListEmptyState = () => {
  return (
    <div className={Styles.camToolList}>
      {Array(3)
        .fill(undefined)
        .map((_, id) => (
          <ToolCard
            key={id}
            label="Tool"
            name="--"
            optionMenu={undefined}
            img={<PrismElementaryCube />}
            muted={false}
            onMouseEnter={() => null}
            onMouseLeave={() => null}
            imageContainerClassName={Styles.toolCardImageContainer}
          />
        ))}
    </div>
  )
}
