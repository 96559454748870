import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { PrismWarningIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import paths from 'paths'
import { RecipeExpanded, RecipeOverviewMode, RoutineWithAois } from 'types'
import { isRecipeOrRoutineResponseProtected, refreshRoutineAndRecipe } from 'utils'

import Styles from './ViewModal.module.scss'

const ERROR_MSG = 'There was an error deleting the view'

interface Props {
  onClose: () => any
  routine: RoutineWithAois
  recipe: RecipeExpanded
  mode: RecipeOverviewMode
}

const DeleteViewModal = ({ onClose, routine, recipe, mode }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleDelete = async (recipe: RecipeExpanded, routine: RoutineWithAois, newRecipeCreated?: boolean) => {
    const res = await service.deleteRoutineFromRecipe(routine.id, recipe.id)
    if (res.type === 'exception') return error({ title: ERROR_MSG })
    if (
      isRecipeOrRoutineResponseProtected(res, {
        recipe,
        routineParentId: routine.parent.id,
        history,
        onCreate: (newRecipe, newRoutine) => {
          if (newRoutine) handleDelete(newRecipe, newRoutine, true)
        },
      })
    ) {
      return
    }

    success({ title: `${routine.parent.name} deleted`, 'data-testid': 'delete-view-modal-delete-success' })
    if (!newRecipeCreated) {
      refreshRoutineAndRecipe({
        routineId: routine.id,
        recipeId: recipe.id,
        recipeParentId: recipe.parent_id,
        dispatch,
      })
    }
    onClose()
    history.push(paths.settingsRecipe(recipe.parent.id, mode))
  }

  return (
    <Modal
      id="delete-view"
      size="small"
      header={
        <div className={Styles.viewHeaderWithIcon}>
          <PrismWarningIcon isActive /> <span>Delete View</span>
        </div>
      }
      onClose={onClose}
      onOk={() => handleDelete(recipe, routine)}
      okText="delete"
      danger
      modalBodyClassName={Styles.viewModalBody}
      modalFooterClassName={Styles.viewModalFooter}
      data-testid="delete-view-modal"
      hideCross
    >
      <div className={Styles.deleteViewDescription}>
        Are you sure you want to delete {routine.parent.name}? You cannot undo this action.
      </div>
    </Modal>
  )
}

export default DeleteViewModal
