import React from 'react'

import { Button } from 'components/Button/Button'
import { PrismAddIcon } from 'components/prismIcons'

import Styles from './PrismAddBtn.module.scss'

interface Props {
  description: string
  onClick: () => void
  className?: string
}

/**
 * Renders large (row) Add Button with a plus/add icon
 *
 * @param onClick - the click function
 * @param description - the button descption
 * @param className - a selector to add styles
 */

const PrismAddButton = ({ onClick, description, className = '' }: Props) => {
  return (
    <Button
      badge={<PrismAddIcon className={Styles.addIcon} isThin />}
      className={`${Styles.addWrapperButton} ${className}`}
      onClick={onClick}
      type="default"
    >
      {description}
    </Button>
  )
}

export default PrismAddButton
