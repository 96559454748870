import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Station Icon
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?type=design&node-id=3318-583&mode=design&t=Bu8Lx0C0aioVTuUB-0
 *
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismStationIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <mask id="path-1-inside-1_3318_248" fill="white">
        <rect x="3" y="5" width="18" height="11" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="5"
        width="18"
        height="11"
        rx="0.5"
        stroke="currentColor"
        strokeWidth="2"
        mask="url(#path-1-inside-1_3318_248)"
      />

      <rect
        width="2"
        height="4"
        rx="0.5"
        transform="matrix(1 0 0 -1 11 19)"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0.3"
      />
      <mask id="path-3-inside-3_3318_248" fill="white">
        <path d="M7 18.85C7 18.9328 7.06716 19 7.15 19H16.85C16.9328 19 17 18.9328 17 18.85V18.5C17 18.2239 16.7761 18 16.5 18H7.5C7.22386 18 7 18.2239 7 18.5V18.85Z" />
      </mask>
      <path
        d="M7 18.85C7 18.9328 7.06716 19 7.15 19H16.85C16.9328 19 17 18.9328 17 18.85V18.5C17 18.2239 16.7761 18 16.5 18H7.5C7.22386 18 7 18.2239 7 18.5V18.85Z"
        stroke="currentColor"
        mask="url(#path-3-inside-3_3318_248)"
      />
    </svg>
  )
}
