import React, { useMemo, useState } from 'react'

import { Drawer } from 'antd'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'
import paths from 'paths'
import { BaseRecipe, RecipeExpanded, RecipeParentExpanded } from 'types'
import { calculatePercentage, combineOutcomeCounts, getRtsParams, getTimeAgoFromDate, renderLargeNumber } from 'utils'

import DuplicateOrMoveRecipeModal from './DuplicateOrMoveRecipeModal'
import Styles from './RecipeOverview.module.scss'

function RecipeVersionDrawer({
  recipeParent,
  recipeVersion,
  updateVersionDrawer,
}: {
  recipeParent?: RecipeParentExpanded
  recipeVersion?: string
  updateVersionDrawer: (recipeVersion?: string) => void
}) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false)

  const selectedRecipeVersion = useQuery(
    recipeVersion ? getterKeys.recipe(recipeVersion) : null,
    recipeVersion ? () => service.getRecipe(recipeVersion!) : null,
    {},
  ).data?.data

  const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false)

  const inspectionCounts = useQuery(
    recipeParent ? getterKeys.inspectionCounts(recipeParent.station_id) : undefined,
    () => service.countInspections({ station_id: recipeParent?.station_id }),
  ).data?.data.results

  const { rtsParams } = getRtsParams({
    seriesType: 'item_outcome_station',
    metricParams: { onlyCounts: true },
    rtsDatePeriod: {},
    labels: { station_id: recipeParent?.station_id, recipe_parent_id: recipeParent?.id },
  })

  const itemsMetrics = useQuery(recipeParent ? getterKeys.metricsMonitorItems() : undefined, async () => {
    const res = await service.readTimeSeries(rtsParams)

    return res
  }).data?.data.results

  const metrics = useMemo(() => {
    if (!recipeParent?.recipes || !itemsMetrics) return

    const compactedSeries: { [recipeId: string]: { yield: number; count: number; batches: number } } = {}

    for (const recipe of recipeParent.recipes) {
      const filteredMetrics = itemsMetrics.filter(item => item.labels.recipe_id?.includes(recipe.id))
      const combinedSeries = combineOutcomeCounts(filteredMetrics)
      const recipeInspectionsCount =
        inspectionCounts?.reduce((total, currentCount) => {
          if (currentCount.recipe_id === recipe.id) {
            return total + currentCount.count
          }

          return total
        }, 0) || 0

      if (!combinedSeries) compactedSeries[recipe.id] = { yield: 0, count: 0, batches: recipeInspectionsCount }

      let count = 0
      let passed = 0

      for (const item of combinedSeries) {
        count += item.count || 0
        passed += item.pass || 0
      }
      const currentYield = calculatePercentage(passed, count)

      compactedSeries[recipe.id] = { yield: currentYield, count, batches: recipeInspectionsCount }
    }

    return compactedSeries
  }, [recipeParent, itemsMetrics, inspectionCounts])

  const restoreVersion = async (id: string | undefined) => {
    if (!recipeParent || !id) return
    const res = await service.updateRecipeParent(recipeParent.id, { working_version_id: id })

    if (res.type !== 'success') {
      error({ title: "Couldn't restore recipe, try again" })
      return
    }

    query(getterKeys.recipeParent(recipeParent.id), () => service.getRecipeParent(recipeParent.id), {
      dispatch,
    })
    setShowConfirmRestoreModal(false)
    updateVersionDrawer()

    success({
      title: `Restored: ${recipeParent.name} v${recipeParent.recipes?.find(recipe => recipe.id === id)?.version}`,
    })
  }

  const renderVersion = (recipe: BaseRecipe) => (
    <div
      data-testid={`recipe-version-info-v${recipe.version}`}
      onClick={() => updateVersionDrawer(recipe.id)}
      className={`${Styles.versionContainer} ${recipe.id === recipeVersion ? Styles.active : ''}`}
      key={recipe.id}
    >
      <Token label={`v${recipe.version}`} className={Styles.versionId} valueClassName={Styles.versionDate}>
        {'Edited '}
        {getTimeAgoFromDate(recipe.updated_at || moment()).text}
      </Token>

      <div className={Styles.versionMetricsContainer}>
        <MetricVersionItem
          title="Yield"
          value={`${metrics?.[recipe.id]?.yield.toFixed(1) || 0}%`}
          data-testid="recipe-version-info-yield"
        />

        <MetricVersionItem
          title="Count"
          value={renderLargeNumber(metrics?.[recipe.id]?.count || 0)}
          data-testid="recipe-version-info-count"
        />

        <MetricVersionItem
          title="Batches"
          value={metrics?.[recipe.id]?.batches || 0}
          data-testid="recipe-version-info-batches"
        />
      </div>
    </div>
  )

  return (
    <>
      <Drawer
        className={Styles.versionPanelContainer}
        placement="right"
        mask={false}
        closable={false}
        onClose={() => updateVersionDrawer()}
        open={!!recipeVersion}
        footer={
          // VM - Actions Start
          <div className={Styles.versionActions}>
            <Button
              className={Styles.restoreButton}
              type="secondary"
              size="small"
              onClick={() => setShowDuplicateModal(true)}
            >
              Duplicate
            </Button>
            {recipeVersion !== recipeParent?.working_version?.id && (
              <PrismTooltip
                condition={!!selectedRecipeVersion?.unrestorable}
                title="This recipe cannot be restored, duplicate it instead"
                anchorClassName={Styles.restoreButton}
              >
                <Button
                  className={Styles.restoreButton}
                  disabled={selectedRecipeVersion?.unrestorable}
                  type="secondary"
                  size="small"
                  onClick={() => setShowConfirmRestoreModal(true)}
                >
                  Restore
                </Button>
              </PrismTooltip>
            )}
          </div>
          // VM - Actions End
        }
        width={256}
      >
        <div>
          {/* VM - Versions Start */}

          <div data-testid="recipe-version-drawer-container">
            {recipeParent?.recipes.sort((a, b) => b.version - a.version).map(renderVersion)}
          </div>

          {/* VM - Versions End */}
        </div>
      </Drawer>

      {showConfirmRestoreModal && (
        <Modal
          id="version-drawer-restore-recipe-version"
          header={`Restore Version ${selectedRecipeVersion?.version}`}
          okText="Restore"
          onOk={() => restoreVersion(selectedRecipeVersion?.id)}
          onClose={() => setShowConfirmRestoreModal(false)}
          size="largeSimpleForm"
        >
          <p className={Styles.restoreRoutineDescription}>
            Your recipe will revert to v{selectedRecipeVersion?.version}. You will still, however, have access to
            subsequent versions.
          </p>
        </Modal>
      )}

      {showDuplicateModal && recipeParent && (
        <DuplicateOrMoveRecipeModal
          id="version-drawer-duplicate-recipe"
          recipeParentId={recipeParent.id}
          defaultName={recipeParent.name}
          currentRecipeStationId={recipeParent.station_id}
          recipeParentWorkingVersionId={recipeParent.working_version?.id}
          recipe={selectedRecipeVersion}
          isDuplicating
          onClose={() => setShowDuplicateModal(false)}
          onSubmit={(recipe: RecipeExpanded) => history.push(paths.settingsRecipe(recipe.parent_id, 'capture'))}
        />
      )}
    </>
  )
}

export default RecipeVersionDrawer

const MetricVersionItem = ({
  value,
  title,
  'data-testid': datatTestId,
}: {
  value: string | number
  title: string
  'data-testid'?: string
}) => {
  return (
    <Token
      data-testid={datatTestId}
      label={title}
      className={Styles.metricItem}
      valueClassName={Styles.metricValue}
      inverted
    >
      {value}
    </Token>
  )
}
