import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { useTypedSelector } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'

function ActivateUserOrg() {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUserOrg = useTypedSelector(state => state.currentOrg)

  useEffect(() => {
    async function acceptInvitation() {
      if (!currentUserOrg) return

      if (!currentUserOrg.is_active) {
        const res = await service.updateUserOrg(currentUserOrg.id, { is_active: true })
        if (res.type === 'success') dispatch(Actions.orgUpdate({ is_active: res.data.is_active }))
      }

      history.replace(paths.inspect({ mode: 'site' }))
    }

    acceptInvitation()

    // eslint-disable-next-line
  }, [])
  return <> </>
}

export default ActivateUserOrg
