import React, { useState } from 'react'

import { Radio } from 'antd'

import ToolSettingsModalTemplate, {
  ToolSettingsModalProps,
} from 'components/ToolSettingsModalTemplate/ToolSettingsModalTemplate'
import Shared from 'styles/Shared.module.scss'

interface Props extends Omit<ToolSettingsModalProps, 'inferenceUserArg' | 'children'> {}

/**
 * Renders a modal that allows the user to change the expected result of a graded anomaly tool.
 */
const GradedAnomalyResultModal = (props: Props) => {
  const { tool } = props

  const [minorAnomalyIsPass, setminorAnomalyIsPass] = useState<boolean>(
    tool.inference_user_args.amber_is_pass as boolean,
  )
  return (
    <ToolSettingsModalTemplate
      title="Edit Outcome of Minor Defects"
      inferenceUserArg={{
        key: 'amber_is_pass',
        value: minorAnomalyIsPass,
      }}
      {...props}
    >
      <div className={Shared.verticalChildrenGap8}>
        <div>Outcome of Minor Defects</div>

        <Radio.Group
          onChange={e => setminorAnomalyIsPass(e.target.value)}
          value={minorAnomalyIsPass}
          className={Shared.verticalChildrenGap8}
        >
          <Radio value className={Shared.inputRadioAndLabel}>
            Pass
          </Radio>
          <Radio value={false} className={Shared.inputRadioAndLabel}>
            Fail
          </Radio>
        </Radio.Group>
      </div>
    </ToolSettingsModalTemplate>
  )
}

export default GradedAnomalyResultModal
