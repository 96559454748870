import React, { RefObject } from 'react'

import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'

import Styles from './RecipeNameWithThumbnail.module.scss'

/**
 * Renders a wrapper for the thumbnail and recipe name.
 *
 * Elementary cube is displayed by default when no image is passed
 * Recipe name handles overflow properties and displays a tooltip
 *
 * @param image -
 * @param recipeName -
 * @param className -
 */
const RecipeNameWithThumbnail = ({
  image,
  recipeName,
  className = '',
  bodyRef,
  textClassName = '',
}: {
  image: string | React.ReactNode | undefined
  recipeName: string
  className?: string
  bodyRef?: RefObject<HTMLDivElement>
  textClassName?: string
}) => {
  const imageToUse = () => {
    if (typeof image === 'string') return (image = <ImgFallback loaderType="skeleton" src={image} alt="" />)
    if (!image) return (image = <PrismElementaryCube />)
    if (React.isValidElement(image)) return image
  }

  return (
    <div ref={bodyRef} className={`${Styles.recipeNameContainer} ${className}`}>
      <figure className={Styles.thumbnailContainer}>{imageToUse()}</figure>
      <PrismOverflowTooltip className={Styles.recipeName} textClassName={textClassName} content={recipeName} />
    </div>
  )
}
export default RecipeNameWithThumbnail
