import React, { useState } from 'react'

import { Button } from 'components/Button/Button'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import {
  PrismAddIcon,
  PrismLineIcon,
  PrismProductIcon,
  PrismRecipeIcon,
  PrismSiteIcon,
  PrismStationIcon,
} from 'components/prismIcons'
import { useQueryEntityFromSites } from 'hooks'
import AddOrEditLineModal from 'pages/StationDetail/Components/EntityModals/AddOrEditLineModal'
import AddOrEditProductModal from 'pages/StationDetail/Components/EntityModals/AddOrEditProductModal'
import AddOrEditRecipeModal from 'pages/StationDetail/Components/EntityModals/AddOrEditRecipeModal'
import AddOrEditSiteModal from 'pages/StationDetail/Components/EntityModals/AddOrEditSiteModal'
import AddOrEditStationModal from 'pages/StationDetail/Components/EntityModals/AddOrEditStationModal'
import { EntityType, MenuPosition } from 'types'

import Styles from './EntityButton.module.scss'

interface Props {
  menuPosition: MenuPosition
  buttonType?: 'textButton' | 'iconButton'
  buttonClassName?: string
  siteId?: string
  lineId?: string
  stationId?: string
  disabled?: boolean
}

const ENTITY_OPTIONS: Option<'site' | 'line' | 'station' | 'product' | 'recipe'>[] = [
  { value: 'site', badge: <PrismSiteIcon />, 'data-testid': 'entity-button-site-option' },
  { value: 'line', badge: <PrismLineIcon />, 'data-testid': 'entity-button-line-option' },
  { value: 'station', badge: <PrismStationIcon />, 'data-testid': 'entity-button-station-option' },
  { value: 'product', badge: <PrismProductIcon />, 'data-testid': 'entity-button-product-option' },
  { value: 'recipe', badge: <PrismRecipeIcon />, 'data-testid': 'entity-button-recipe-option' },
]

/**
 * Renders an Entity button, by default render as a button with the "Add" text and icon
 *
 * link : https://www.figma.com/file/Fp2lSfRJPQJ41gDcHQEQJT/Sites%2C-Lines-and-Stations-(3.0)?type=design&node-id=5769-11724&mode=design&t=Kv2C9YXp1Sjrsg0Y-0
 *
 * @param isEditMode - change the looks of Modal's title and children
 * @param menuPosition - the direction in which the dropdown is going to display
 * @param buttonType - switches the look of the anchor button from a Text button to an Icon button
 * @param buttonClassName - Additional styles to be applied to the anchor button
 */
const EntityButton = ({
  menuPosition,
  buttonType = 'textButton',
  buttonClassName = '',
  siteId,
  lineId,
  stationId,
  disabled,
}: Props) => {
  const [entityOption, setEntityOption] = useState<EntityType | undefined>(undefined)
  const [buttonIsActive, setButtonIsActive] = useState(false)
  const stationSubtype = useQueryEntityFromSites({
    entity: 'subSiteType',
    siteId,
    filterFn: subtype => subtype.normalized_name === 'station',
  })?.[0]

  return (
    <>
      <OptionMenu
        options={ENTITY_OPTIONS}
        buttonDisabled={disabled}
        openWithClick
        closeOnClick
        onMenuItemClick={entity => setEntityOption(entity)}
        position={menuPosition}
        menuContainerClassName={Styles.addEntityMenu}
        menuItemClassName={Styles.addEntityMenuItem}
        onClose={() => setButtonIsActive(false)}
      >
        {buttonType === 'textButton' && (
          <Button
            data-testid="entity-button"
            type="secondary"
            badge={<PrismAddIcon isThin />}
            size="small"
            onClick={() => setButtonIsActive(!buttonIsActive)}
            className={`${buttonClassName} ${buttonIsActive ? Styles.isActive : ''}`}
            disabled={disabled}
          >
            <span className={Styles.addButtonText}>add</span>
          </Button>
        )}
      </OptionMenu>

      {/* Entity modal options */}

      {entityOption === 'site' && <AddOrEditSiteModal isEditMode={false} onClose={() => setEntityOption(undefined)} />}
      {entityOption === 'line' && (
        <AddOrEditLineModal isEditMode={false} onClose={() => setEntityOption(undefined)} defaultSiteId={siteId} />
      )}
      {entityOption === 'station' && (
        <AddOrEditStationModal
          isEditMode={false}
          onClose={() => setEntityOption(undefined)}
          defaultLocation={siteId && stationSubtype ? [`${siteId}_${stationSubtype.id}`, lineId || null] : undefined}
        />
      )}
      {entityOption === 'product' && (
        <AddOrEditProductModal isEditMode={false} onClose={() => setEntityOption(undefined)} />
      )}
      {entityOption === 'recipe' && (
        <AddOrEditRecipeModal
          isEditMode={false}
          onClose={() => setEntityOption(undefined)}
          defaultLocation={
            siteId && stationSubtype && stationId
              ? [`${siteId}_${stationSubtype.id}`, lineId || null, stationId]
              : undefined
          }
        />
      )}
    </>
  )
}

export default EntityButton
