/* eslint-disable no-inline-styles/no-inline-styles */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'

import { isEqual } from 'lodash'
import throttle from 'lodash/throttle'
import { Layer, Stage } from 'react-konva'

import AoiOutline, { AoiOutlineConfiguration } from 'components/ImageWithBoxes/AoiOutline'
import ImageNotStored from 'components/ImageWithBoxes/ImageNotStored'
import ImgFallback, { FailCacheEntry, Props as ImgFallbackProps } from 'components/Img/ImgFallback'
import { Box } from 'types'
import { contain } from 'utils'
import { IMAGE_IN_GLACIER_TEXT } from 'utils/constants'

import Styles from '../ImageWithBoxes/ImageWithBoxes.module.scss'

const MIN_SIZE_THUMBNAIL_CROP = {
  HEIGHT: 300,
  WIDTH: 300,
}
export interface ImageCloseUpProps extends ImgFallbackProps {
  thumbnailSrc?: string
  region: AoiOutlineConfiguration & { img_w?: number; img_h?: number }
  customStyle?: React.CSSProperties
  overlaySrc?: string
  overlayOpacity?: number
  maskingRectangleFill?: string
  loaderType?: 'bars' | 'skeleton'
  onOverlayLoad?: React.ReactEventHandler<HTMLImageElement>
  onCanvasDrawn?: () => void
  overlayRegion?: Box
  'data-testid'?: string
  displayElementaryCubeText?: boolean
  predictionScoreOverlay?: JSX.Element | undefined
  setImgErr?: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * Renders a canvas with an image zoomed into the specified region.
 *
 * @param src - Src of the image to draw
 * @param region - Region of the image to zoom into
 * @param onLoad - Callback from parent to trigger logic when image has loaded
 * @param overlaySrc - Overlay image shown on top. Tipically used to show insights on a tool's result.
 */
function ImageCloseUp({
  src = '',
  thumbnailSrc = '',
  region,
  customStyle,
  onLoad,
  onOverlayLoad,
  onCanvasDrawn,
  overlaySrc,
  maskingRectangleFill,
  loaderType = 'bars',
  overlayOpacity = 1,
  overlayRegion,
  'data-testid': dataTestId,
  predictionScoreOverlay,
  displayElementaryCubeText,
  setImgErr,
  ...rest
}: ImageCloseUpProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [imageDimensions, setImageDimensions] = useState<Box>()
  const [img, setImage] = useState<{ image: HTMLImageElement | undefined }>({ image: undefined })
  const [overlayImg, setOverlayImage] = useState<{ image: HTMLImageElement | undefined }>({ image: undefined })
  const [loadImgError, setLoadImgError] = useState<FailCacheEntry>()

  const cantCropThumbnailRef = useRef(false)
  const shouldRenderOverlayRef = useRef(false)

  const [regionToUse, sourceToUse] = useMemo(() => {
    // If we don't have dimensions of golden image, can't use thumbnail to crop
    if (!region.img_w || !thumbnailSrc) return [region, src]
    // Region doesn't matter here, we just want to load the thumbnailSrc into the img state
    if (!img.image) return [region, thumbnailSrc]

    const scalingFactor = region.img_w / img.image.naturalWidth

    const scaledRegion = {
      width: region.width / scalingFactor,
      height: region.height / scalingFactor,
      x: region.x / scalingFactor,
      y: region.y / scalingFactor,
    }

    // When this function runs with img.image being the thumbnail crop the scaled region will crop on the thumbnail,
    // if that load sets the cantCropThumbnailRef to true, then the next render will return src here and we will start to load the full res image.
    // Once it loads and we trigger the next render scaledRegion will be === to region, so we will now be cropping on the full res
    return [scaledRegion, cantCropThumbnailRef.current ? src : thumbnailSrc]
  }, [region, img, src, thumbnailSrc])

  const drawImage = useMemo(
    () =>
      throttle(
        (
          region: Box,
          img: HTMLImageElement,
          overlayImg?: HTMLImageElement,
          overlayOpacity?: number,
          overlayRegion?: Box,
        ) => {
          const canvas = canvasRef.current
          const container = imageContainerRef.current
          const ctx = canvas?.getContext('2d')
          if (!canvas || !ctx || !container) return

          // Getting container size to make this resizeable while keeping the canvas's bitmap and element width and height equal
          const containerSize = container.getBoundingClientRect()
          canvas.width = containerSize.width
          canvas.height = containerSize.height
          canvas.style.width = containerSize.width + 'px'
          canvas.style.height = containerSize.height + 'px'

          const renderableRect = contain(region.width, region.height, canvas.width, canvas.height)
          if (!renderableRect) return

          const { x, y, width, height } = renderableRect

          try {
            // TODO: added try/catch to avoid crashed on local dev, not sure if we need to dig deeper into this
            ctx.drawImage(img, region.x, region.y, region.width, region.height, x, y, width, height)
          } catch (e) {}
          setImageDimensions({ x, y, width, height })

          if (overlayImg && shouldRenderOverlayRef.current) {
            // globalAlpha serves as opacity for canvas. It will affect anything drawn to canvas AFTER it is set.
            if (overlayOpacity) ctx.globalAlpha = overlayOpacity

            if (overlayRegion) {
              ctx.drawImage(
                overlayImg,
                overlayRegion.x,
                overlayRegion.y,
                overlayRegion.width,
                overlayRegion.height,
                x,
                y,
                width,
                height,
              )
            } else {
              ctx.drawImage(overlayImg, x, y, width, height)
            }
          }
        },
        50,
      ),
    [],
  )

  // Attach redraw logic to ResizeObserver and
  // whenever image or region we're focusing on changes, we redraw image
  useEffect(() => {
    const resizeDraw = () => {
      if (!img.image) return

      drawImage(regionToUse, img.image, overlayImg.image, overlayOpacity, overlayRegion)
      onCanvasDrawn?.()
    }
    const { current } = imageContainerRef
    const observer = new ResizeObserver(() => {
      setTimeout(() => resizeDraw())
    })

    // Set this ref in case overlay img takes long to load and it has changed to none,
    // otherwise, the overlay will render in the canvas when it's onLoad fires
    if (overlaySrc) {
      shouldRenderOverlayRef.current = true
    } else {
      shouldRenderOverlayRef.current = false
    }

    if (current) observer.observe(current)

    return () => {
      if (current) observer.unobserve(current)
    }
  }, [drawImage, img, onCanvasDrawn, overlayImg, overlayOpacity, overlayRegion, overlaySrc, regionToUse])

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '100%', display: 'block', ...customStyle }}
      ref={imageContainerRef}
    >
      {predictionScoreOverlay}
      {/* We load the image through ImgFallback to take advantage of its caching logic */}
      <ImgFallback
        // For live feeds that can be zoomed in, we don't want to show the loaders as they flicker on every frame
        loaderType={img.image ? 'none' : loaderType}
        onImgLoadError={err => {
          setLoadImgError(err)
          setImgErr?.(!!err)
        }}
        onLoad={e => {
          if (
            regionToUse.width <= MIN_SIZE_THUMBNAIL_CROP.WIDTH ||
            regionToUse.height <= MIN_SIZE_THUMBNAIL_CROP.HEIGHT
          ) {
            // If the resulting crop would be too small, we want to fetch the full res image afterwards to crop that instead
            cantCropThumbnailRef.current = true
          }

          // The element that e.currentTarget points to doesn't change even if attributes of the element (like src) change
          // however, onLoad does trigger when attributes of the element change, so by just creating a new object everytime
          // we can force our redrawing useEffect to run.
          setImage({ image: e.currentTarget })
          onLoad?.(e)
        }}
        src={sourceToUse}
        style={{ display: 'none' }}
        data-testid={dataTestId}
        {...rest}
      />

      {/* @TO_IA*/}
      {loadImgError !== 'imageInGlacier' && <canvas style={{ display: 'block' }} ref={canvasRef} />}

      {loadImgError === 'imageInGlacier' && (
        <ImageNotStored displayText={displayElementaryCubeText ? IMAGE_IN_GLACIER_TEXT : undefined} />
      )}

      {overlaySrc && (
        <ImgFallback
          onLoad={e => {
            setOverlayImage({ image: e.currentTarget })
            onOverlayLoad?.(e)
          }}
          src={overlaySrc}
          style={{ display: 'none' }}
          {...rest}
        />
      )}

      <Stage
        className={Styles.stage}
        width={Math.ceil(imageDimensions?.width || 0)}
        height={Math.ceil(imageDimensions?.height || 0)}
        style={{ top: imageDimensions?.y, left: imageDimensions?.x }}
      >
        <Layer>
          <AoiOutline
            {...region}
            stroke={region.shape ? region.stroke : 'transparent'}
            scaling={1}
            width={imageDimensions?.width || 0}
            height={imageDimensions?.height || 0}
            x={0}
            y={0}
            imageWidth={Math.ceil(imageDimensions?.width || 0)}
            imageHeight={Math.ceil(imageDimensions?.height || 0)}
            applyMask
            maskingRectangleFill={maskingRectangleFill || '#111111'}
            onClick={rest.onClick}
          />
        </Layer>
      </Stage>
    </div>
  )
}

/* Is equal ensures component isn't re-rendered if even if the Region objects changes
   if the values don't change. (isEqual does deep comparison but Region is an object with numbers only)
*/
export default memo(ImageCloseUp, isEqual)
