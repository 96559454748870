import React, { useState } from 'react'

import { Breadcrumb, Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { IconButton } from 'components/IconButton/IconButton'
import Navbar from 'components/Navbar/Navbar'
import { PrismHeaderLogo } from 'components/PrismHeaderLogo/PrismHeaderLogo'
import { PrismMenuIcon } from 'components/prismIcons'
import { useIsFlourish } from 'hooks'

import Styles from './PrismLayout.module.scss'

interface PrismLayoutProps {
  breadcrumbs: Breadcrumb[]
  menuItems?: React.ReactNode
  tabs?: React.ReactNode
  children: React.ReactNode
  headerIcon?: React.ReactNode
  headerRightIcon?: React.ReactNode
  'data-testid'?: string
  'data-test-attribute'?: string
  headerClassName?: string
  className?: string
  showHeaderLogo?: boolean
}

/**
 * Renders a general Layout container, it splits the content in header and body, and allow us to maintain consistents layouts.
 *
 *
 * @param breadcrumbs - Uses the component breadcrumb, which receives 1 or more strings, they're positioned on the left side of the header right after the icon button
 * @param menuItems - Additional space on the right side of the header.
 * @param tabs - Placed below the header a sub menu with tabs can be added by using this prop.
 * @param children - receives the content in the body.
 * @param headerIcon - an alternative in case the icon is changed and needs other action than opening the nav menu, by default the menu icon (hamburger) is displayed.
 */
export const PrismLayout = ({
  breadcrumbs,
  menuItems,
  tabs,
  children,
  headerIcon: headerIconProp,
  headerRightIcon,
  'data-testid': dataTestId,
  'data-test-attribute': dataTestAttribute,
  headerClassName = '',
  className = '',
  showHeaderLogo = true,
}: PrismLayoutProps) => {
  const [showMenu, setShowMenu] = useState(false)

  const headerIcon = headerIconProp || (
    <IconButton
      icon={<PrismMenuIcon />}
      type="ghost"
      onClick={() => setShowMenu(!showMenu)}
      className={Styles.headerButton}
      data-testid="navbar-open-button"
    />
  )

  return (
    <div
      className={`${Styles.flexLayout} ${className}`}
      data-testid={dataTestId}
      data-test-attribute={dataTestAttribute}
    >
      <Navbar navbarVisible={showMenu} setNavbarVisible={setShowMenu} />
      <main className={Styles.flexMain}>
        <div className={`${Styles.main} ${tabs ? Styles.mainWrapperWithTabs : Styles.mainWrapper} `}>
          <Header
            headerIcon={headerIcon}
            headerRightIcon={headerRightIcon}
            breadcrumbs={breadcrumbs}
            menuItems={menuItems}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            tabs={tabs}
            className={headerClassName}
            showHeaderLogo={showHeaderLogo}
          />

          <AppBody>{children}</AppBody>
        </div>
      </main>
    </div>
  )
}

const Header = ({
  breadcrumbs,
  menuItems,
  headerIcon,
  headerRightIcon,
  tabs,
  className = '',
  showHeaderLogo = true,
}: {
  breadcrumbs: Breadcrumb[]
  menuItems?: React.ReactNode
  showMenu: boolean
  setShowMenu: (showMenu: boolean) => void
  headerIcon: React.ReactNode
  headerRightIcon?: React.ReactNode
  tabs?: React.ReactNode
  className?: string
  showHeaderLogo?: boolean
}) => {
  const { isFlourish } = useIsFlourish()
  return (
    <header className={`${Styles.wrapperHeader} ${className}`}>
      <div className={Styles.header_left}>
        {headerIcon}
        {isFlourish && showHeaderLogo && <PrismHeaderLogo className={Styles.headerLogo} />}

        <Breadcrumbs
          crumbs={breadcrumbs}
          wrapperClassName={`${Styles.headerBreadcrumb} ${headerRightIcon ? Styles.titleWithIcon : ''}`}
        />
        {headerRightIcon}
      </div>
      <div className={Styles.header_right}>{menuItems}</div>
      {tabs && <div className={Styles.secondNav}>{tabs}</div>}
    </header>
  )
}

const AppBody = ({ children }: { children: React.ReactNode }) => {
  return <main className={Styles.wrapperBody}>{children}</main>
}
