import React from 'react'

import { Slider, SliderSingleProps, TooltipProps } from 'antd'

import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { Token } from 'components/Token/Token'

import Styles from './PrismSlider.module.scss'

export interface PrismSliderProps extends SliderSingleProps {
  className?: string
  label?: React.ReactNode
  actions?: React.ReactNode
  sliderColor?: 'red' | 'amber'
  extraTooltip?: TooltipProps
  userFacingValue?: string | number
  onChange?: (value: any) => void
  onAfterChange?: (value: any) => void
  inverted?: boolean
  inputBox?: React.ReactNode
  inputBoxClassName?: string
  'data-testid'?: string
  hideMarksLabel?: boolean
}
interface SliderRangeProps extends Omit<PrismSliderProps, 'defaultValue'> {
  valueStart?: number
  valueEnd?: number
  inputBoxLeft?: React.ReactNode
  hideRangeText?: boolean
  inputBoxRight?: React.ReactNode
  defaultValue?: [number, number]
  'data-testid'?: string
  showError?: boolean
}

/**
 * Renders the Prism Slider, it can show a label, a value and the slider. By default the slider's value is going to be displayed in the same line as the label. If inputBox is active this value is going to hide and an input container can be added to show the value.
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3155%3A238
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A2
 *
 * @param className - string class for the element container
 * @param label - Slider title, this is shown on the top left side
 * @param actions - Adds a secondary label shown on the top right side
 * @param min - The slider's min value
 * @param max - The slider's max value
 * @param step - The granularity the slider can step through values
 * @param value - The slider's value.
 * @param sliderValue - When the slider value needs additional math.
 * @param onChange - Callback function that is fired when the user changes the slider's value
 * @param onAfterChange - Fire when onmouseup is fired
 * @param disable
 * @param inputBox - Adds a space to the side of the slider, It can be used by an input component
 * @param inverted - change the position of the slider and the input, by default the input is placed to the left of the slider.
 */
export const PrismSlider = ({
  className,
  label,
  actions,
  min,
  max,
  step = 1,
  value,
  sliderColor,
  userFacingValue,
  onChange,
  onAfterChange,
  disabled,
  inverted,
  inputBox,
  extraTooltip,
  defaultValue,
  'data-testid': dataTestId,
  hideMarksLabel,
  ...rest
}: PrismSliderProps) => {
  const content = (
    <div
      className={`${Styles.sliderAndInput} ${inputBox ? Styles.inputBox : ''}  ${inverted ? Styles.inverted : ''} ${
        hideMarksLabel ? Styles.hideMarksLabel : ''
      }`}
      data-testid={dataTestId}
    >
      {inputBox && <div className={Styles.inputBox}> {inputBox}</div>}

      <Slider
        className={Styles.slider}
        min={min}
        max={max}
        trackStyle={sliderColor && { backgroundColor: sliderColors[sliderColor] }}
        handleStyle={sliderColor && { borderColor: sliderColors[sliderColor] }}
        step={step}
        value={value}
        onChange={onChange}
        onAfterChange={onAfterChange}
        disabled={disabled}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  )

  return (
    <div className={className ?? ''}>
      <Token
        label={<div className={Styles.sliderLabel}>{label}</div>}
        className={Styles.sliderHeader}
        secondaryLabel={
          <div className={Styles.secondaryLabel}>
            {!inputBox && !disabled && <div className={Styles.values}>{userFacingValue || value}</div>}
            {actions && <div className={Styles.actionsContainer}>{actions}</div>}
          </div>
        }
      >
        {extraTooltip && <PrismTooltip {...extraTooltip}>{content}</PrismTooltip>}
        {!extraTooltip && content}
      </Token>
    </div>
  )
}

/**
 * Renders the Prism Slider with a ranged value.
 * These values can be shown inside an input by adding the prop inputBoxLeft and inputBoxRight. Without these props the values are going to be placed in the same line as the slider's label.
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3155%3A238
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=12%3A2
 *
 * @param className - string class for the element container
 * @param label - Slider title, this is shown on the top left side
 * @param actions - Adds a secondary label shown on the top right side
 * @param min - The slider's min value
 * @param max - The slider's max value
 * @param step - The granularity the slider can step through values
 * @param onChange - Callback function that is fired when the user changes the slider's value
 * @param onAfterChange - Fire when onmouseup is fired
 * @param disable
 * @param valueStart - the starting value
 * @param valueEnd - the ending value
 * @param inputBoxLeft - Optional react node that can receive an input
 * @param inputBoxRight - Optional react node that can receive an input

 */
export const PrismSliderRange = ({
  className,
  label,
  actions,
  min,
  max,
  step = 1,
  onChange,
  onAfterChange,
  disabled,
  sliderColor,
  hideRangeText,
  valueStart,
  valueEnd,
  inputBoxLeft,
  inputBoxRight,
  inputBoxClassName,
  defaultValue,
  showError,
  'data-testid': dataTestId,
}: SliderRangeProps) => {
  // Range component disables interaction if value is set.
  // Setting value={undefined} doesn't work properly, so we have to remove the value prop entirely.
  // This is for use with defaultValue and onAfterChange
  // https://github.com/react-component/slider/issues/366#issuecomment-742898471
  const value =
    valueStart !== undefined && valueEnd !== undefined ? ([valueStart, valueEnd] as [number, number]) : undefined
  const valueHack = value ? { value } : {}

  return (
    <div className={`${className ?? ''} ${Styles.sliderContainer}`}>
      <Token
        label={label}
        className={Styles.sliderSpan}
        secondaryLabel={
          !hideRangeText && (
            <div className={Styles.secondaryLabel}>
              {!inputBoxLeft && !inputBoxRight && (
                <div className={Styles.values}>
                  {label === 'Pixel Count' ? `${valueStart}% - ${valueEnd}%` : `${valueStart} - ${valueEnd}`}
                </div>
              )}
              {actions && <div className={Styles.actionsContainer}>{actions}</div>}
            </div>
          )
        }
        labelClassName={Styles.sliderLabel}
      >
        <div
          className={Styles.sliderAndInput}
          data-testid={dataTestId}
          data-test-attribute={disabled ? `${dataTestId}-disabled` : `${dataTestId}-enabled`}
        >
          {inputBoxLeft && (
            <div className={`${inputBoxClassName ?? ''} ${Styles.inputBox} ${showError ? Styles.inputBoxError : ''}`}>
              {' '}
              {inputBoxLeft}
            </div>
          )}

          <Slider
            className={Styles.slider}
            range
            trackStyle={sliderColor && [{ backgroundColor: sliderColors[sliderColor] }]}
            handleStyle={
              sliderColor && [{ borderColor: sliderColors[sliderColor] }, { borderColor: sliderColors[sliderColor] }]
            }
            defaultValue={defaultValue}
            {...valueHack}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            onAfterChange={onAfterChange}
            disabled={disabled}
          />
          {inputBoxRight && (
            <div className={`${inputBoxClassName ?? ''} ${Styles.inputBox} ${showError ? Styles.inputBoxError : ''}`}>
              {' '}
              {inputBoxRight}
            </div>
          )}
        </div>
      </Token>
    </div>
  )
}

const sliderColors = {
  red: '#D44C39',
  amber: '#E4B93D',
}
