import React, { ReactNode, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getterKeys, query, SendToApiResponse, service } from 'api'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'
import paths from 'paths'
import { AreaOfInterestConfiguration, RecipeExpanded, RoutineWithAois, Tool } from 'types'
import {
  deleteTool,
  duplicateTool,
  getAoisAndToolsFromRoutine,
  getToolAoisFromRoutine,
  prepareBatchCreateUpdateDeleteBody,
  renderToolName,
} from 'utils'

import ToolCard from '../ToolCard'

export interface ResetFormArgs {
  routine: RoutineWithAois
  tool: Tool
}

interface Props {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  tool: Tool
  readOnly: boolean
  children: ReactNode
  disableSaveTooltip?: string
  resetForm: ({ routine, tool }: ResetFormArgs) => any
  canDuplicateTool?: boolean
  saveChanges: () => Promise<SendToApiResponse<any>>[] | null | undefined
  onExit: () => any
}

/**
 * Renders children wrapped with UI used to add a new tool to the app.
 *
 * The children for this wrapper basically represent the form for the specific
 * tool passed in. This component can be thought of as a form handler.
 *
 * IMPORTANT WARNING about saveChanges; if we send two requests to update a
 * tool in parellel, e.g. by calling patchTool,
 * patchProtectedTool in parallel, we can get into a "last-writer wins"
 * situation. The requests get served concurrently by separate threads /
 * processes. So don't send these requests in parallel with `Promise.all`.
 *
 * @param routine - The routine we are working on
 * @param tool - The selected tool
 * @param readOnly - Whether we are in read only mode
 * @param children - Form to configure tool settings
 * @param disableSaveTooltip - If a string is passed, the save button is
 *     disabled and a tooltip can be seen with the string if hovered over
 * @param saveChanges - Function to send all requests to save tool settings. If this function returns `null`,
 * it indicates an error saving
 * @param resetForm - Function to reset the form once the save has been completed
 * @param canDuplicateTool - Can user duplicate this tool for this routine?
 * @param onExit - Callback to deselect tool and return to default view, changes
 *     done but not saved are discarded
 */
export const ToolTemplate = ({
  routine,
  recipe,
  tool,
  readOnly,
  disableSaveTooltip,
  onExit,
  saveChanges,
  resetForm,
  canDuplicateTool = true,
  children: toolForm,
}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    formState: { isDirty, errors },
    formState,
    getValues,
    trigger,
    reset,
  } = useFormContext<{ aois: AreaOfInterestConfiguration[]; name: string; [key: string]: any }>()

  useEffect(() => {
    resetForm({ routine, tool })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool, routine])

  const formHasErrors = Object.keys(errors).length > 0

  const handleSave = async () => {
    const valid = await trigger()
    if (!valid) return 'invalid'

    const { aois, name } = getValues()
    const { dirtyFields } = formState

    const requestsToExecute: Promise<SendToApiResponse<any>>[] = []

    if (dirtyFields.name && tool.specification_name !== 'alignment') {
      requestsToExecute.push(service.patchToolParent(tool.parent_id, { name }))
    }

    if (dirtyFields.aois && tool.specification_name !== 'alignment') {
      if (routine.is_protected) {
        const { aois: originalAois } = getToolNameAndAois(routine, tool)
        // The only field editable within an AOI of a protected routine is the parent name, this condition handles that edit
        for (const originalAoi of originalAois) {
          const editedAoi = aois.find(editedAoi => editedAoi.id === originalAoi.id)

          if (editedAoi?.parent && editedAoi?.parentName !== originalAoi.parentName) {
            requestsToExecute.push(
              service.patchAoiParent(editedAoi.parent.id, {
                name: editedAoi?.parentName,
              }),
            )
          }
        }
      } else {
        const body = prepareBatchCreateUpdateDeleteBody(aois, tool)

        const aoisRes = await service.batchCreateUpdateDeleteAois(body)

        if (aoisRes.type !== 'success') {
          return error({ title: 'There was a problem editing the tool, try again' })
        }
      }
    }

    const additionalPromises = saveChanges()

    if (additionalPromises) requestsToExecute.push(...additionalPromises)

    const responses = await Promise.all(requestsToExecute)

    // We're using `null` as a fallback error value.
    const allRequestsSuccessful = responses.every(res => res && res.type === 'success') && additionalPromises !== null

    // After saving, refetch all the data initially fetched in ToolScreen and passed down to tool forms
    if (allRequestsSuccessful) {
      const refetchRoutineRes = await query(getterKeys.routine(routine.id), () => service.getRoutine(routine.id), {
        dispatch,
      })

      success({ title: 'Tool saved', 'data-testid': 'tool-saved-success' })

      const refetchedRoutine = refetchRoutineRes?.type === 'success' ? refetchRoutineRes?.data : undefined
      const refetchedTool = getAoisAndToolsFromRoutine(refetchedRoutine).tools.find(
        fetchedTool => fetchedTool.id === tool.id,
      )

      if (refetchedRoutine && refetchedTool) {
        resetForm({ routine: refetchedRoutine, tool: refetchedTool })
      }
    } else error({ title: 'There was a problem editing the tool, try again' })
  }

  const handleDeleteTool = async () => {
    modal.warning({
      id: 'tool-template-delete-tool-confirmation',
      content: 'Are you sure you want to delete this tool?',
      okText: 'Delete',
      onOk: close => {
        deleteTool({
          routineId: routine.id,
          toolId: tool.id,
          history,
          recipe,
          dispatch,
          close,
          onDelete: () => {
            query(getterKeys.recipe(recipe.id), () => service.getRecipe(recipe.id), { dispatch })
            onExit()
          },
        })
        reset()
      },
    })
  }

  const handleOptionClick = async (option: string) => {
    if (option === 'duplicate') {
      if (isDirty) {
        modal.confirm({
          id: 'toool-template-save-tool-confirmation',
          header: 'Save Tool?',
          content: 'You must save your changes to this tool before you can duplicate it.',
          okText: 'Save and Duplicate',
          onOk: async close => {
            await handleSave()
            resetForm({ routine, tool })
            await handleDuplicateTool()
            close()
          },
        })
      } else {
        await handleDuplicateTool()
      }
    } else {
      await handleDeleteTool()
    }
  }

  const handleDuplicateTool = async () => {
    const newTool = await duplicateTool({
      routineId: routine.id,
      routineParentId: routine.parent.id,
      toolId: tool.id,
      toolParentId: tool.parent_id,
      recipe,
      history,
      dispatch,
    })
    if (newTool) history.push(paths.settingsTool(recipe.id, newTool.id))
  }

  const options: Option<'delete' | 'duplicate'>[] = [{ value: 'delete', title: 'Delete' }]

  if (canDuplicateTool)
    options.unshift({
      value: 'duplicate',
      title: 'Duplicate',
      disabled: tool.is_shared,
      tooltipProps: tool.is_shared ? { title: 'You can not duplicate a shared tool', placement: 'right' } : undefined,
    })

  return (
    <ToolCard
      title={renderToolName(tool)}
      actionButton={
        <OptionMenu options={options} onMenuItemClick={handleOptionClick} openWithClick iconButtonType="tertiary" />
      }
      type={tool.specification_name}
      saveDisabled={readOnly || !!disableSaveTooltip || !isDirty || !!formHasErrors}
      readOnly={readOnly}
      onSave={handleSave}
      isSharedTool={tool.is_shared}
      onExit={onExit}
    >
      <LeavePagePrompt when={isDirty} onOk={() => resetForm({ routine, tool })} />
      {toolForm}
    </ToolCard>
  )
}

export const getToolNameAndAois = (routine: RoutineWithAois, tool: Tool) => {
  return {
    name: tool.parent_name || '',
    aois: getToolAoisFromRoutine(routine, tool.id),
  }
}
