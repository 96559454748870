import React from 'react'

import { ClipPath, Defs, G, Path, Svg } from '@react-pdf/renderer'

const PdfElementaryCube = ({ size }: { size: number }) => (
  <Svg viewBox="0 0 239 273" style={{ width: size, height: size }}>
    <G clip-path="url(#a)">
      <Path fill="#E8E8E8" d="m119.5 136.211 114.49976333-66.1065.0000029 132.213-114.49976332 66.1065z" />
      <Path fill="#F6F6F6" d="m5 70.104 114.49976333 66.1065-.00000292 132.213L4.9999971 202.317z" />
      <Path fill="#DBDBDB" d="m119.5 4 114.49976333 66.1065L119.5 136.213 5.00023667 70.1065z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h229v264.424H0z" transform="translate(5 4)" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default PdfElementaryCube
