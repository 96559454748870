import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconOutcomeProps } from './prismIconsTypes'

/**
 * Prism Pass Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=5412%3A11831
 *
 * @param variant - adds special styles to the icon.
 * @param className - String for external styles.
 * @param viewboxSize - manipulates the viewbox value of the icon, default value is medium: 0 0 20 20;
 */
const viewboxSizeClasses = {
  xSmall: '4 4 12 12',
  small: '2 2 16 16',
  medium: '0 0 20 20',
}

const variantClasses = {
  transparent: '',
  low: Styles.lowContrast,
  high: Styles.highContrast,
  dark: Styles.dark,
  iconColor: Styles.color,
}
export const PrismPassIcon = ({
  variant = 'transparent',
  viewboxSize = 'medium',
  className,
}: PrismIconOutcomeProps) => {
  const variantClass = variantClasses[variant]

  const viewboxSizeClass = viewboxSizeClasses[viewboxSize]

  const iconClassName = `${Styles.icon} ${Styles.outcome} ${Styles.pass} ${variantClass} ${className ?? ''}`

  return (
    <svg className={iconClassName} viewBox={viewboxSizeClass} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0694 6.48809C15.339 6.1736 15.3026 5.70012 14.9881 5.43056C14.6736 5.16099 14.2001 5.19741 13.9306 5.51191L15.0694 6.48809ZM8.5 13L7.96967 13.5303C8.11739 13.6781 8.32003 13.7575 8.52878 13.7494C8.73754 13.7414 8.93349 13.6467 9.06944 13.4881L8.5 13ZM6.53033 9.96967C6.23744 9.67678 5.76256 9.67678 5.46967 9.96967C5.17678 10.2626 5.17678 10.7374 5.46967 11.0303L6.53033 9.96967ZM13.9306 5.51191L7.93056 12.5119L9.06944 13.4881L15.0694 6.48809L13.9306 5.51191ZM9.03033 12.4697L6.53033 9.96967L5.46967 11.0303L7.96967 13.5303L9.03033 12.4697Z"
        fill="currentColor"
      />
    </svg>
  )
}
