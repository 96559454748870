import React, { useState } from 'react'

import { PrismArrowIcon } from 'components/prismIcons'

import Styles from './PrismCollapse.module.scss'
type Props = {
  label: string
  defaultHidden?: boolean
  children: React.ReactNode
  labelClassName?: string
  contentClassName?: string
}

/**
 * Displays a collapsable container with a title and an arrow. The arrow icon can be overriden.
 *
 * @param label - Label to show on the collapse. This label will always be shown
 * @param defaultHidden - Whether the content should be shown or hidden by default
 * @param children - Content to display inside collapse
 * @param labelClassName - Classname used for the label
 * @param contentClassName - Classname used for the content container
 */
function Collapse({ label, defaultHidden = true, children, labelClassName, contentClassName }: Props) {
  const [showContent, setShowContent] = useState(!defaultHidden)
  return (
    <div>
      {/* Label container */}
      <div onClick={() => setShowContent(!showContent)} className={`${labelClassName ?? ''} ${Styles.labelContainer}`}>
        <div className={Styles.title}>{label}</div>

        <PrismArrowIcon direction={showContent ? 'up' : 'down'} />
      </div>

      {/* Content container */}
      {showContent && <div className={contentClassName ?? ''}>{children}</div>}
    </div>
  )
}

export default Collapse
