import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch } from 'react-redux'

import { getterKeys, query, service } from 'api'
import { Button } from 'components/Button/Button'
import { Divider } from 'components/Divider/Divider'
import OverflowingTooltip from 'components/OverflowingTooltip/OverflowingTooltip'
import { PrismInfoIcon } from 'components/prismIcons'
import { error } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { SearchableSelect } from 'components/SearchableSelect/SearchableSelect'
import { Token } from 'components/Token/Token'
import { useData } from 'hooks'
import { RoutineParent } from 'types'

import Styles from '../RecipeOverview.module.scss'

interface Props {
  fetchToolResults: () => void
  toolId: string
  toolParentId: string
}

/**
 * Renders a button to generate old tool results, the button will only show up if there are tool results to generate.
 *
 * @param toolId - The tool we are generating tool results for
 * @param fetchToolResults - Callback to refetch tool results after generating them
 * @param toolParentId - The current toolParent id
 */
const GenerateEmptyToolResultsButton = ({ fetchToolResults, toolId, toolParentId }: Props) => {
  const [showGenerateMoreResultsButton, setShowGenerateMoreResultsButton] = useState(false)
  const [showCreateMoreResultsModal, setShowCreateMoreResultsModal] = useState(false)

  const canGenerateMoreToolResults = useCallback(async () => {
    const canCreateEmptyResultsRes = await service.createEmptyToolResults(toolId, { count: 500, dry_run: true })

    // Dry run succeeded, and there are results to create, so allow user to create them
    if (canCreateEmptyResultsRes.type === 'success' && canCreateEmptyResultsRes.data.can_be_created > 0) return true
    return false
  }, [toolId])

  useEffect(() => {
    const fn = async () => {
      const canGenerate = await canGenerateMoreToolResults()
      setShowGenerateMoreResultsButton(canGenerate)
    }

    fn()
  }, [canGenerateMoreToolResults])

  if (!showGenerateMoreResultsButton) return null

  return (
    <>
      <Button
        onClick={() => setShowCreateMoreResultsModal(true)}
        badge={<PrismInfoIcon />}
        size="small"
        type="ghost"
        className={Styles.createMoreToolResultsButton}
        data-testid="tool-results-more-images"
      >
        More Images
      </Button>

      {showCreateMoreResultsModal && (
        <CreateEmptyResultsModal
          onClose={(canGenerateMore = true) => {
            setShowCreateMoreResultsModal(false)
            if (!canGenerateMore) setShowGenerateMoreResultsButton(false)
          }}
          toolId={toolId}
          toolParentId={toolParentId}
          canGenerateMoreToolResults={canGenerateMoreToolResults}
          fetchToolResults={fetchToolResults}
        />
      )}
      <Divider />
    </>
  )
}

export default GenerateEmptyToolResultsButton

interface EmptyResultsModalProps {
  toolId: string
  toolParentId: string
  onClose: (canGenerateMore?: boolean) => void
  fetchToolResults: () => void
  canGenerateMoreToolResults: () => Promise<boolean>
}

const CreateEmptyResultsModal = ({
  toolId,
  toolParentId,
  onClose,
  fetchToolResults,
  canGenerateMoreToolResults,
}: EmptyResultsModalProps) => {
  const dispatch = useDispatch()

  const [selectedRoutineParent, setSelectedRoutineParent] = useState<RoutineParent>()
  const [selectedInspectionId, setSelectedInspectionId] = useState('')
  const [toolResultsToImportCount, setToolResultsToImportCount] = useState(0)
  const [open, setOpen] = useState(false)

  const routineInspectionsLoadingRef = useRef(false)

  const routineInspectionsData = useData(getterKeys.toolParentFilteredInspections('generate-tool-results-inspections'))

  const inspectionIds = useMemo(() => {
    if (selectedInspectionId) return [selectedInspectionId]
    if (selectedRoutineParent?.id) {
      return routineInspectionsData?.results.map(inspection => inspection.id)
    }
  }, [routineInspectionsData?.results, selectedInspectionId, selectedRoutineParent?.id])

  useEffect(() => {
    if (!selectedRoutineParent?.id || selectedInspectionId) return
    const fetchRoutineInspections = async () => {
      routineInspectionsLoadingRef.current = true
      await query(
        getterKeys.toolParentFilteredInspections('generate-tool-results-inspections'),
        () => service.getInspections({ routine_parent_id: selectedRoutineParent.id, has_items: true }),
        { dispatch },
      )
      routineInspectionsLoadingRef.current = false
    }

    fetchRoutineInspections()
  }, [dispatch, selectedInspectionId, selectedRoutineParent?.id])

  useEffect(() => {
    if (selectedRoutineParent?.id && (routineInspectionsLoadingRef.current || !routineInspectionsData?.results)) return
    const fetchToolResultsToImportCount = async () => {
      const createEmptyToolResultsRes = await service.createEmptyToolResults(toolId, {
        count: 1000,
        dry_run: true,
        inspection_ids: inspectionIds,
        routine_parent_id: selectedRoutineParent?.id || undefined,
      })
      if (createEmptyToolResultsRes.type !== 'success') return setToolResultsToImportCount(0)
      setToolResultsToImportCount(createEmptyToolResultsRes.data.can_be_created)
    }
    fetchToolResultsToImportCount()
  }, [inspectionIds, routineInspectionsData?.results, selectedInspectionId, selectedRoutineParent?.id, toolId])

  const onCreateEmptyResults = async () => {
    const emptyResultRes = await service.createEmptyToolResults(toolId, {
      count: 1000,
      routine_parent_id: selectedRoutineParent?.id,
      inspection_ids: inspectionIds,
    })
    if (emptyResultRes.type !== 'success') {
      error({ title: 'There was an error importing older images, please try again.' })
      return onClose()
    }

    // This can happen if a user on a separate compute clicks the button before our user does.
    if (emptyResultRes.data.created === 0) error({ title: 'There are no older images to label.' })

    // Start fetching the newly generated tool results
    fetchToolResults()

    // Figure out if there are more available to create
    const canGenerate = await canGenerateMoreToolResults()

    onClose(canGenerate)
  }

  return (
    <Modal
      id="import-more-images"
      onClose={onClose}
      size="largeSimpleForm"
      className={Styles.modalWrapper}
      data-testid="labeling-screen-old-images-modal"
      header="Import More Images"
      headerClassName={Styles.modalHeader}
      modalBodyClassName={Styles.modalBody}
      modalFooterClassName={Styles.modalFooter}
      okText={`Import ${toolResultsToImportCount ? toolResultsToImportCount : ''}`}
      onOk={onCreateEmptyResults}
      disableSave={!toolResultsToImportCount}
    >
      <div className={Styles.modalDescription}>
        This tool has access to previously inspected images. You can import up to 1,000 at a time.
      </div>

      <Token label="source" className={Styles.importWrapper} labelClassName={Styles.importLabel}>
        <div className={Styles.importContainer}>
          <div className={Styles.filtersWrapper}>
            <SearchableSelect
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              value={selectedRoutineParent?.id || null}
              onSelect={(_, routineParent) => {
                setSelectedRoutineParent(routineParent)
                setSelectedInspectionId('')
              }}
              fetcher={search =>
                service.getRoutineParents({
                  tool_parent_id: toolParentId,
                  component_recipe_routine_name: search || undefined,
                  is_deleted: false,
                })
              }
              getterKey={getterKeys.toolParentRoutineParents(toolParentId)}
              missingOptionFetcher={id => service.getRoutineParent(id)}
              formatter={routineParent => {
                return (
                  <OverflowingTooltip
                    label={{ parent: routineParent.recipe_parent.name, child: routineParent.name }}
                    containerIsRendered={open}
                    placement="right"
                  />
                )
              }}
              placeholder="All Views"
              size="large"
              onInitialLoad={routineParents => {
                // If there's only one routine, select it by default
                if (routineParents.length === 1) {
                  setSelectedRoutineParent(routineParents[0])
                }
              }}
              wrapperClassName={Styles.moreImagesFilter}
            />

            <SearchableSelect
              value={selectedInspectionId || null}
              onSelect={inspectionId => setSelectedInspectionId(inspectionId)}
              fetcher={search =>
                service.getInspections({
                  name: search || undefined,
                  routine_parent_id: selectedRoutineParent?.id,
                  has_items: true,
                })
              }
              getterKey={getterKeys.toolParentFilteredInspections(toolParentId)}
              missingOptionFetcher={id => service.getInspection(id)}
              formatter={inspection => inspection.name}
              placeholder="All Batches"
              size="large"
              disabled={!selectedRoutineParent}
              preventFetch={!selectedRoutineParent}
              queryOptions={{ refetchKey: selectedRoutineParent?.id }}
              wrapperClassName={Styles.moreImagesFilter}
            />
          </div>

          <div className={Styles.importCaption}>
            Import from all of this tool’s views and batches or filter to a specific source
          </div>
        </div>
      </Token>
    </Modal>
  )
}
