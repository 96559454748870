import React, { RefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Konva from 'konva'
import { Ellipse } from 'konva/lib/shapes/Ellipse'
import { Rect } from 'konva/lib/shapes/Rect'
import { UseFormMethods } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Divider } from 'components/Divider/Divider'
import FullScreen, { FullScreenFooter, FullScreenHeader } from 'components/FullScreen/FullScreen'
import { ImagePreprocessingPreview } from 'components/ImagePreprocessingPreview/ImagePreprocessingPreview'
import ImageWithBoxes from 'components/ImageWithBoxes/ImageWithBoxes'
import { PrismEllipseIcon, PrismPolygonIcon, PrismRectangleIcon } from 'components/prismIcons'
import { PrismInput, PrismInputNumber } from 'components/PrismInput/PrismInput'
import { error } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { PrismToggleGroup } from 'components/PrismToggleGroup/PrismToggleGroup'
import { InputNumberValue } from 'components/ProtectedInputNumber/ProtectedInputNumber'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { IS_QA } from 'env'
import { useImageElement } from 'hooks'
import {
  AreaOfInterestConfiguration,
  AreaOfInterestShapeField,
  Box,
  BoxWithShape,
  EllipseShapeField,
  MaxBounds,
  PreprocessingOptions,
  PreprocessingOptionsAlt,
  RecipeExpanded,
  RectangleShapeField,
  RoutineWithAois,
  Shape,
  Tool,
} from 'types'
import {
  constrainBoxInBounds,
  convertAltToPreprocessingOptions,
  getRad,
  getUniqueName,
  protectedOnChange,
  rotatePoint,
  ulidUuid,
} from 'utils'

import QaPreviewDownload from '../Common/QaPreviewDownload'
import { useColorDropper, useImageToAoiCrop } from '../Common/toolSettingsHooks'
import { ResetFormArgs } from '../ToolTemplate'
import AoisContainer from './AoisContainer'
import Styles from './EditAoisHelper.module.scss'
import { EllipseData } from './Shapes/EditableEllipse'
import { Point } from './Shapes/EditablePolygon/Point'
import { RectangleData, SNAP_ROTATION_ANGLES } from './Shapes/EditableRectangle'
import { roundBox, scaleBox } from './Utils'

type ToolBarButton = 'delete' | 'duplicate' | 'rename'

const DECIMAL_NUMBER_REGEX = /[-+]?([0-9]+(\.[0-9]*)?|\.[0-9]+)/g

interface PreviewOptions {
  showEyeDropper?: boolean
  showGrid?: boolean
  inferenceArgs?: PreprocessingOptions | PreprocessingOptionsAlt | undefined
  onPixelCountChange?: (whitePixelCount: number, totalImagePixelCount: number) => any
  src?: string
  setShowEyeDropper?: (showEyeDropper: boolean) => void
  setValue?: UseFormMethods['setValue']
}

type Props = {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  tool: Tool
  single?: boolean
  hideAois?: boolean
  aois: AreaOfInterestConfiguration[]
  setAois: React.Dispatch<SetStateAction<AreaOfInterestConfiguration[]>>
  mode?: 'aois' | 'anchors'
  enabledShapes?: Shape[]
  disableRectangleRotation?: boolean
  onSelectedAoiChange?: (aoi: AreaOfInterestConfiguration | undefined) => void
  selectFirstAoiByDefault?: boolean
  showSizeBoxes?: boolean
  minSizeBoxWidth?: number
  minSizeBoxHeight?: number
  maxSizeBoxWidth?: number
  maxSizeBoxHeight?: number
  activeSizeBox?: 'min' | 'max'
  showPreview?: boolean
  previewOptions?: PreviewOptions
  disableToggleButtons?: boolean
  resetForm: ({ routine, tool }: ResetFormArgs) => any
}

/**
 * Renders the right side of tool config, where can create, delete, rename, drag
 * and resize AOIs.
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param single - Whether only one AOI is allowed (hides duplicate button)
 * @param hideAois - Whether AOIs should be shown
 * @param aois - Current Routine Aois
 * @param setAois - Called whenever aois are changed (any CRUD)
 * @param enabledShapes - Which shapes can be created / viewed here.
 * @param disableRectangleRotation - Do we prevent users from rotating rectangular AOIs
 * @param previewOptions.inferenceArgs - The tool inference args
 * @param previewOptions.showEyeDropper - Whether the eyedropper is active.
 * @param previewOptions.showGrid - Indicates if the grid overlay is active.
 * @param previewOptions.src - AOI image source.
 * @param previewOptions.onPixelCountChange - Callback that runs when the pixe count for an AOI changes
 * @param previewOptions.setShowEyeDropper - Function that sets whether the eye dropper is shown.
 * @param previewOptions.setValue - Function to set a value to a key.
 */
const EditAoisHelper = ({
  routine,
  recipe,
  tool,
  single,
  hideAois,
  aois,
  setAois,
  mode = 'aois',
  enabledShapes = ['rectangle'],
  disableRectangleRotation = true,
  onSelectedAoiChange,
  selectFirstAoiByDefault,
  showSizeBoxes,
  minSizeBoxWidth,
  minSizeBoxHeight,
  maxSizeBoxHeight,
  maxSizeBoxWidth,
  activeSizeBox,
  showPreview,
  previewOptions,
  disableToggleButtons,
  resetForm,
}: Props) => {
  const history = useHistory()

  const [showFullscreen, setShowFullscreen] = useState(false)
  const [activeButton, setActiveButton] = useState<ToolBarButton | undefined>()
  const showDrawNotificationRef = useRef(true)

  const [imageSize, setImageSize] = useState<{ width: number; height: number } | undefined>(undefined)

  const [selectedAoiId, setSelectedAoiId] = useState<string | undefined>(
    selectFirstAoiByDefault ? aois[0]?.id : undefined,
  )
  const selectedAoi = aois.find(aoi => (selectedAoiId ? aoi.id === selectedAoiId : undefined))

  const [renameAoiModalVisible, setRenameAoiModalVisible] = useState(false)
  const [aoiName, setAoiName] = useState('')

  const [aoiShapeType, setAoiShapeType] = useState<Shape>()
  const [aoiImage, setAoiImage] = useState<HTMLImageElement>()

  const [bounds, setBounds] = useState<MaxBounds>({ maxWidth: 0, maxHeight: 0 })
  const [scaling, setScaling] = useState(0)
  const [allRectanglesData, setAllRectanglesData] = useState<{ [aoiId: string]: RectangleData }>({})
  const [allEllipseData, settAllEllipseData] = useState<{ [aoiId: string]: EllipseData }>({})
  const [allRefs, setAllRefs] = useState<{
    [aoiId: string]: {
      rect: RefObject<Rect>
      tranformer: RefObject<Konva.Transformer>
      ellipse: RefObject<Konva.Ellipse>
    }
  }>({})

  const currentRectangleData = selectedAoiId ? allRectanglesData[selectedAoiId] : undefined
  const currentEllipseData = selectedAoiId ? allEllipseData[selectedAoiId] : undefined
  const currentRectRef = selectedAoiId ? allRefs[selectedAoiId]?.rect : undefined
  const currentEllipseRef = selectedAoiId ? allRefs[selectedAoiId]?.ellipse : undefined
  const currentTransformerRef = selectedAoiId ? allRefs[selectedAoiId]?.tranformer : undefined

  const polygonIncomplete = selectedAoi?.shape?.type === 'polygon' && !selectedAoi?.shape?.data
  const { showEyeDropper, inferenceArgs, src, setShowEyeDropper, setValue, showGrid, onPixelCountChange } =
    previewOptions || {}

  const setRectangleData = (aoiId: string, newRectangleData: RectangleData) => {
    setAllRectanglesData(prev => {
      const updatedRectanglesData = { ...prev }
      updatedRectanglesData[aoiId] = newRectangleData
      return updatedRectanglesData
    })
  }

  const setEllipseData = (aoiId: string, newEllipseData: EllipseData) => {
    settAllEllipseData(prev => {
      const updatedEllipseData = { ...prev }
      updatedEllipseData[aoiId] = newEllipseData
      return updatedEllipseData
    })
  }

  useEffect(() => {
    setAllRefs(updatedRefs => {
      aois.forEach(aoi => {
        updatedRefs[aoi.id] ??= {
          rect: React.createRef<Rect>(),
          tranformer: React.createRef<Konva.Transformer>(),
          ellipse: React.createRef<Konva.Ellipse>(),
        }
      })
      return updatedRefs
    })
  }, [aois])

  // This is a hacky way of reloading the image and setting the origin as anonymous. Necessary for OCR preprocessing
  // Explanation: canvas elements must receive an image with the tag "crossorigin=anonymous", otherwise, the canvas will
  // break with a message of "Tainted image". That's why specifically for this case, we're setting the anonymous flag as true.
  // Secondly, when fetching an image for the first time without the crossorigin=anonymous tag, and then fetching it a second
  // time WITH crossorigin=anonymous, browsers complain, as they cannot fetch the image from their cache with a different
  // header. The workaround here was to set a different query parameter for the image we're fetching here specifically. By adding
  // \?_ or &_ to our src url, we're forcing the browser to refetch the image, but this time including the crossorigin=anonymous
  // tag.
  const imageSrc =
    showPreview && routine.image
      ? routine.image.includes('?')
        ? `${routine?.image}&_`
        : `${routine?.image}?_`
      : routine?.image

  const [imageElement] = useImageElement(imageSrc)

  const settings = useMemo(() => {
    if (!showPreview || !inferenceArgs) return
    if ('hsv_min' in inferenceArgs) return inferenceArgs
    else return convertAltToPreprocessingOptions(inferenceArgs)
  }, [inferenceArgs, showPreview])

  useImageToAoiCrop({
    imageElement,
    toolSpecificationName: tool.specification_name,
    setAoiImage,
    aoi: selectedAoi,
    src,
    showPreview,
  })

  useColorDropper({
    imageElement,
    inferenceArgs,
    setShowEyeDropper,
    setValue,
    showEyeDropper,
    showPreview,
  })

  const onBeforeCreateNewRoutineVersion = useCallback(() => {
    if (!tool) return
    resetForm({ routine, tool })
  }, [resetForm, routine, tool])

  const handleAoiChange = useCallback(
    (aoi: AreaOfInterestConfiguration | undefined) => {
      if (!aoi) setAoiImage(undefined)
      onSelectedAoiChange?.(aoi)
    },
    [onSelectedAoiChange],
  )

  useEffect(() => {
    handleAoiChange?.(selectedAoi)
  }, [handleAoiChange, selectedAoi])

  // Duplicate AOI
  const handleDuplicateAoi = useCallback(() => {
    if (single || !selectedAoi || !imageElement) return

    const protectedChange = protectedOnChange(
      () => {
        // Place duplicated AOI smack in the middle of the Routine image
        const newShape = selectedAoi.shape ? { type: selectedAoi.shape.type, data: selectedAoi.shape.data } : undefined
        const newAoi: AreaOfInterestConfiguration = {
          shape: newShape as AreaOfInterestShapeField,
          id: ulidUuid(),
          x: Math.round((imageElement.width - selectedAoi.width) / 2),
          y: Math.round((imageElement.height - selectedAoi.height) / 2),
          width: Math.round(selectedAoi.width),
          routine_id: routine.id,
          height: Math.round(selectedAoi.height),
          parentName:
            mode === 'aois'
              ? getUniqueName(
                  selectedAoi.parentName,
                  aois.map(aoi => aoi.parentName),
                )
              : '',
          toolIds: [tool.id],
          img_h: imageElement.height,
          img_w: imageElement.width,
        }
        setSelectedAoiId(newAoi.id)
        setAois([...aois, newAoi])
        tempActivateButton('duplicate')
      },
      { routine, recipe, history, onBeforeCreate: onBeforeCreateNewRoutineVersion },
    )

    protectedChange()
  }, [
    aois,
    tool.id,
    history,
    imageElement,
    mode,
    routine,
    recipe,
    selectedAoi,
    setAois,
    single,
    onBeforeCreateNewRoutineVersion,
  ])

  // Delete AOI
  const handleDeleteAoi = useCallback(() => {
    if (aois.length <= 1 || !selectedAoiId) return

    const protectedChange = protectedOnChange(
      () => {
        const newAois = aois.filter(aoi => aoi.id !== selectedAoiId)

        setAois(newAois)
        // after removing, select the next aoi from the list
        setSelectedAoiId(newAois[newAois.length - 1]?.id)

        tempActivateButton('delete')
      },
      { routine, recipe, history, onBeforeCreate: onBeforeCreateNewRoutineVersion },
    )

    protectedChange()
  }, [aois, history, routine, recipe, selectedAoiId, setAois, onBeforeCreateNewRoutineVersion])

  const handleRenameAoi = useCallback(() => {
    if (!selectedAoi || mode !== 'aois') return

    setAoiName(selectedAoi.parentName)
    setRenameAoiModalVisible(true)
    tempActivateButton('rename')
  }, [mode, selectedAoi])

  const tempActivateButton = (buttonName: ToolBarButton) => {
    setActiveButton(buttonName)
    setTimeout(() => {
      setActiveButton(undefined)
    }, 250)
  }

  // Ensure any click inside of preview and image wrapper div but outside the selected aoi deselects it.
  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const closestButton = target.closest('button')
      const closestCanvas = target.closest('canvas')
      // But not if the clicked element was a button (delete, duplicate or rename)
      // or the canvas, which by itself handles the deselect of an AOI.
      if (closestButton || closestCanvas) return
      // Or if user is editing AOI name
      if (renameAoiModalVisible) return
      // And not if we're editing a polygon that has not been closed
      if (selectedAoi?.shape?.type === 'polygon' && !selectedAoi.shape.data) return
      setSelectedAoiId(undefined)
    },
    [renameAoiModalVisible, selectedAoi],
  )

  useEffect(() => {
    const aoiContainer = document.getElementById('aoi-container')

    aoiContainer?.addEventListener('mousedown', handleMouseDown)
    return () => {
      aoiContainer?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleMouseDown])

  // Add an AOI if we somehow get here without tool having an AOI linked to it
  useEffect(() => {
    if (aois.length === 1) {
      setSelectedAoiId(aois[0]!.id)
      setAoiShapeType(aois[0]!.shape?.type || 'rectangle')
    }

    if (aois.length > 0 || !imageElement) return
    setAois([
      {
        id: ulidUuid(),
        x: Math.round(imageElement.width / 4),
        y: Math.round(imageElement.height / 4),
        width: Math.round(imageElement.width / 2),
        height: Math.round(imageElement.height / 2),
        routine_id: routine.id,
        parentName: 'Area',
        toolIds: [tool.id],
        img_w: imageElement.width,
        img_h: imageElement.height,
      },
    ])
    setAoiShapeType('rectangle')
  }, [aois, tool.id, imageElement, setAois, routine.id])

  const handleSelectAoi = (aoi: AreaOfInterestConfiguration) => {
    setSelectedAoiId(aoi.id)
  }

  const renderRenameAoiModal = () => {
    if (mode !== 'aois' || !selectedAoiId) return
    return (
      renameAoiModalVisible && (
        <Modal
          id="rename-aoi"
          overlayClassName={Styles.toolsModalWrapper}
          data-testid="edit-aois-helper-rename-modal"
          size="largeSimpleForm"
          header="Rename"
          okText="Update"
          onOk={async () => {
            if (!aoiName) return error({ title: "Name can't be empty" })
            handleSaveAoi(selectedAoiId, { parentName: aoiName })
            setRenameAoiModalVisible(false)
          }}
          onClose={() => setRenameAoiModalVisible(false)}
        >
          <PrismInput
            placeholder="Name"
            value={aoiName}
            onChange={e => setAoiName(e.target.value)}
            data-testid="edit-aois-helper-rename-input"
            autoFocus
          />
        </Modal>
      )
    )
  }

  const handleUpdateAoi = (box: Box) => {
    handleSaveAoi((box as AreaOfInterestConfiguration).id, box)
  }

  const handleSaveAoi = (id: string, fields: Partial<AreaOfInterestConfiguration>) => {
    const aoiIdx = aois.findIndex(aoi => aoi.id === id)
    const aoiToSave = aois[aoiIdx]
    if (!aoiToSave) return
    const editedAoi = constrainBoxInBounds(
      { ...aoiToSave, ...fields },
      imageElement ? { maxWidth: imageElement.width, maxHeight: imageElement.height } : undefined,
    )

    setAois([...aois.slice(0, aoiIdx), editedAoi, ...aois.slice(aoiIdx + 1)])
  }

  const handleSwitchEditingMode = (type: Shape) => {
    // We should only be able to enter this callback when there is a single AOI since we disable the shape mode buttons
    // whenver there is more than one AOI.
    if (selectedAoi) {
      const prevType = selectedAoi.shape?.type
      if (type === 'polygon' && prevType !== type) {
        showDrawNotificationRef.current = true
      }

      // We take the selected AOI and set its shape field to the new shape type while keeping the bounding box.
      // Each of the EditableShape components will then set the data attribute properly when mounting.
      setAois([{ ...selectedAoi, shape: { type: type, data: undefined } }])

      // We also need to cleanup the stored shape data by aoi, so that it is recalculated
      // whenever the shape data in a EditableShape component is set.
      setAllRectanglesData({})
      settAllEllipseData({})

      setAoiShapeType(type)
    }
  }

  // rotates shape around its center
  const rotateRectangleAroundCenter = (rect: Rect, rotation: number) => {
    const topLeft = { x: -rect.width() / 2, y: -rect.height() / 2 }
    const current = rotatePoint(topLeft, getRad(rect.rotation()))
    const rotated = rotatePoint(topLeft, getRad(rotation))
    const dx = rotated.x - current.x,
      dy = rotated.y - current.y

    rect.rotation(rotation)
    rect.x(rect.x() + dx)
    rect.y(rect.y() + dy)
  }

  const rotateEllipsis = (ellipse: Ellipse, rotation: number) => {
    ellipse.rotation(rotation)
  }

  const handleAoiRotationChange = protectedOnChange(
    (val: InputNumberValue) => {
      if (!selectedAoi || !selectedAoiId || typeof val !== 'number' || !currentRectRef || !currentEllipseRef) return
      if (selectedAoi.shape?.type === 'polygon') return

      let newRotation = val

      // we need to use rotations from -180 to 180
      if (val > 180) {
        newRotation = val - 360
      }

      if (val < -180) {
        newRotation = val + 360
      }

      if (isRectangle(selectedAoi)) {
        const rect = currentRectRef.current
        if (!rect) return

        rotateRectangleAroundCenter(rect, newRotation)
      }
      if (isEllipse(selectedAoi)) {
        const ellipse = currentEllipseRef.current
        if (!ellipse) return

        rotateEllipsis(ellipse, newRotation)
      }
      handleCommonTransformEnd(selectedAoiId)
    },
    { routine, recipe, history, onBeforeCreate: onBeforeCreateNewRoutineVersion },
  )

  const showRotationInput = useMemo(() => {
    if (!selectedAoi || !selectedAoiId || disableRectangleRotation || aois.length > 1) return false
    if (selectedAoi.shape?.type === 'polygon') return false
    return true
  }, [aois.length, disableRectangleRotation, selectedAoi, selectedAoiId])

  const rotationValue = useMemo(() => {
    if (!selectedAoi || !selectedAoiId || disableRectangleRotation || aois.length > 1) return
    if (selectedAoi.shape?.type === 'polygon') return
    return selectedAoi.shape?.data?.rotationDegrees ?? 0
  }, [aois.length, disableRectangleRotation, selectedAoi, selectedAoiId])

  const renderHotkeys = () => (
    <>
      <ToggleButton
        title="Full Screen"
        hotkey="F"
        disabled={renameAoiModalVisible || disableToggleButtons}
        active={showFullscreen}
        onClick={() => setShowFullscreen(!showFullscreen)}
        isOnTop
        data-testid="edit-aois-helper-fullscreen"
        data-test-attribute={
          showFullscreen ? 'edit-aois-helper-fullscreen-active' : 'edit-aois-helper-fullscreen-inactive'
        }
        className={Styles.toolsAoiControl}
        childrenClassName={Styles.toolsAoiControlText}
      />

      {selectedAoiId && <Divider type="vertical" className={Styles.hotkeysDivider} />}

      {selectedAoiId && !hideAois && enabledShapes.length > 1 && (
        <div className={Styles.shapesContainer}>
          <PrismToggleGroup
            data-testid="edit-aois-helper-shapes"
            onChange={(shape: String) => {
              if (aoiShapeType === shape) return

              handleSwitchEditingMode(shape as Shape)
            }}
            value={aoiShapeType}
            options={enabledShapes.map(shape => {
              const icon = (
                <>
                  {shape === 'rectangle' && <PrismRectangleIcon isActive={aoiShapeType === shape} />}
                  {shape === 'ellipse' && <PrismEllipseIcon isActive={aoiShapeType === shape} />}
                  {shape === 'polygon' && <PrismPolygonIcon isActive={aoiShapeType === shape} />}
                </>
              )

              return {
                icon: icon,
                value: shape,
                dataTestId: 'edit-aois-helper-shapes-' + shape,
              }
            })}
            isOnTop
            size="medium"
          />
        </div>
      )}

      {selectedAoiId && !hideAois && !single && (
        <ToggleButton
          title={`Duplicate ${mode === 'anchors' ? 'Anchor' : 'Area'}`}
          hotkey="D"
          disabled={polygonIncomplete || renameAoiModalVisible || disableToggleButtons}
          active={activeButton === 'duplicate'}
          onClick={handleDuplicateAoi}
          data-testid="edit-aois-helper-add-anchor"
          isOnTop
          className={Styles.toolsAoiControl}
          childrenClassName={Styles.toolsAoiControlText}
        />
      )}

      {selectedAoi && !hideAois && mode === 'aois' && (
        <ToggleButton
          title="Rename Area"
          hotkey="R"
          disabled={renameAoiModalVisible || disableToggleButtons}
          active={activeButton === 'rename'}
          onClick={handleRenameAoi}
          data-testid="edit-aois-helper-rename-area"
          isOnTop
          className={Styles.toolsAoiControl}
          childrenClassName={Styles.toolsAoiControlText}
        />
      )}

      {selectedAoiId && !hideAois && aois.length > 1 && (
        <ToggleButton
          disabled={renameAoiModalVisible || disableToggleButtons}
          title={`Delete ${mode === 'anchors' ? 'Anchor' : 'Area'}`}
          hotkey="x"
          hiddenHotkeys={['backspace', 'delete']}
          active={activeButton === 'delete'}
          onClick={handleDeleteAoi}
          isOnTop
          className={Styles.toolsAoiControl}
          childrenClassName={Styles.toolsAoiControlText}
        />
      )}

      {showRotationInput && (
        <PrismInputNumber
          size="extraSmall"
          precision={1}
          value={rotationValue}
          wrapperClassName={`${Styles.rotationInputWrapper} ${Styles.toolsAoiControl}`}
          className={Styles.rotationInput}
          formatter={val => `Rotate: ${val}°`}
          parser={(val: string | undefined) => {
            // Extracting the float number instead of replacing the 'Rotate' string and degrees symbol avoids weird behavior when deleting chars other than the float number.
            const floats = val?.match(DECIMAL_NUMBER_REGEX)
            return floats?.[0] || '0'
          }}
          onChange={handleAoiRotationChange}
        />
      )}
    </>
  )

  const handleEllipseTransform = (aoiId: string) => {
    if (!currentEllipseData || !currentEllipseRef) return
    const ellipse = currentEllipseRef.current as Konva.Ellipse
    ellipse.radiusX(ellipse.radiusX() * ellipse.scaleX())
    ellipse.radiusY(ellipse.radiusY() * ellipse.scaleY())
    ellipse.scaleX(1)
    ellipse.scaleY(1)
    const bbox = getCurrentEllipseBoundingBox(currentEllipseRef)
    if (outOfBounds({ ...bbox, scaling, bounds })) {
      ellipse.x(currentEllipseData.originX)
      ellipse.y(currentEllipseData.originY)
      ellipse.rotation(currentEllipseData.rotationDegrees)
      ellipse.radiusX(currentEllipseData.radiusX)
      ellipse.radiusY(currentEllipseData.radiusY)
    } else {
      const newEllipseData = {
        originX: ellipse.x(),
        originY: ellipse.y(),
        radiusX: ellipse.radiusX(),
        radiusY: ellipse.radiusY(),
        rotationDegrees: ellipse.rotation(),
      }
      setEllipseData(aoiId, newEllipseData)
      return newEllipseData
    }
  }

  const toExportEllipseAoi = (newEllipseData?: EllipseData): BoxWithShape | undefined => {
    if (!currentEllipseData || !currentEllipseRef) return
    const ellipseDataToUse = newEllipseData ?? currentEllipseData
    const { radiusX, radiusY, rotationDegrees } = ellipseDataToUse
    const bbox = getCurrentEllipseBoundingBox(currentEllipseRef)
    const unscaledBbox = roundBox(scaleBox(bbox, 1 / scaling))
    const unscaledData = { radiusX: radiusX / scaling, radiusY: radiusY / scaling }
    const finalShape = {
      radiusX: unscaledData.radiusX / unscaledBbox.width,
      radiusY: unscaledData.radiusY / unscaledBbox.height,
      rotationDegrees: rotationDegrees,
    }
    return { ...unscaledBbox, shape: { type: 'ellipse', data: finalShape } as EllipseShapeField }
  }

  const handleRectangleTransform = (aoiId: string) => {
    if (!currentRectangleData || !currentRectRef || !currentTransformerRef) return
    let newRectangleData: RectangleData | undefined = undefined
    const { originX, originY, width, height, rotationDegrees } = currentRectangleData
    const rect = currentRectRef.current as Konva.Rect
    // Transforming tool is not changing width and height properties of nodes when you resize them.
    // Instead it changes scaleX and scaleY properties. We do the resizing by taking the scale of the transformation event
    // and then resetting scale
    rect.width(rect.width() * rect.scaleX())
    rect.height(rect.height() * rect.scaleY())
    rect.scaleX(1)
    rect.scaleY(1)
    const bbox = getCurrentRectangleBoundingBox({ rectRef: currentRectRef })

    if (rect.rotation() !== 0) {
      if (outOfBounds({ ...bbox, bounds, scaling })) {
        const { x, y } = computeCornerFromOrigin(originX, originY, width, height, rotationDegrees)

        rect.x(x)
        rect.y(y)
        rect.rotation(rotationDegrees)
        rect.width(width)
        rect.height(height)
      } else {
        const { originX, originY } = computeOriginFromCorner(
          rect.x(),
          rect.y(),
          rect.width(),
          rect.height(),
          rect.rotation(),
        )
        newRectangleData = {
          originX,
          originY,
          width: rect.width(),
          height: rect.height(),
          rotationDegrees: rect.rotation(),
        }
        setRectangleData(aoiId, newRectangleData)
      }
    } else {
      // This makes it easier to get an aoi to be the size of the image.
      const xOut = outOfBoundsX({ ...bbox, bounds, scaling })
      const yOut = outOfBoundsY({ ...bbox, bounds, scaling })
      const newRectangle = { ...currentRectangleData }

      const { x, y } = computeCornerFromOrigin(originX, originY, width, height, rotationDegrees)
      if (xOut) {
        rect.x(x)
        rect.rotation(rotationDegrees)
        rect.width(width)
      }

      if (yOut) {
        rect.y(y)
        rect.rotation(rotationDegrees)

        rect.height(height)
      }

      if (!xOut || !yOut) {
        const { originX, originY } = computeOriginFromCorner(
          rect.x(),
          rect.y(),
          rect.width(),
          rect.height(),
          rect.rotation(),
        )

        if (!xOut) {
          newRectangle.originX = originX
          newRectangle.width = rect.width()
        }

        if (!yOut) {
          newRectangle.originY = originY
          newRectangle.height = rect.height()
        }
        newRectangleData = { ...newRectangle, rotationDegrees: rect.rotation() }
        setRectangleData(aoiId, newRectangleData)
      }
    }
    currentTransformerRef.current!.rotation(rotationDegrees)
    updateToMatchBox(rect.getLayer()!, currentRectRef)
    return newRectangleData
  }

  /** Transforms client-side coordinate shape into image coordinates */
  const toExportRectangleAoi = (updatedRectangleData?: RectangleData): BoxWithShape | undefined => {
    if (!currentRectangleData || !currentRectRef) return
    const rectDataToUse = updatedRectangleData || currentRectangleData
    const bbox = getCurrentRectangleBoundingBox({ rectRef: currentRectRef })
    const unscaledBbox = roundBox(scaleBox(bbox, 1 / scaling))
    if (SNAP_ROTATION_ANGLES.includes(rectDataToUse.rotationDegrees)) {
      return { ...unscaledBbox, shape: undefined }
    }
    const unscaledData = { width: rectDataToUse.width / scaling, height: rectDataToUse.height / scaling }
    const finalShape = {
      width: unscaledData.width / unscaledBbox.width,
      height: unscaledData.height / unscaledBbox.height,
      rotationDegrees: rectDataToUse.rotationDegrees,
    }

    return { ...unscaledBbox, shape: { type: 'rectangle', data: finalShape } as RectangleShapeField }
  }

  const handleCommonTransformEnd = (aoiId: string) => {
    if (!selectedAoi) return

    if (isRectangle(selectedAoi)) {
      const newRectangleData = handleRectangleTransform(aoiId)

      const updatedAoi = { ...selectedAoi, ...toExportRectangleAoi(newRectangleData) }
      handleUpdateAoi({ ...selectedAoi, ...updatedAoi })
    }

    if (isEllipse(selectedAoi)) {
      const newEllipseData = handleEllipseTransform(aoiId)
      handleUpdateAoi({ ...selectedAoi, ...toExportEllipseAoi(newEllipseData) })
    }
  }

  const handleCommonTransform = (aoiId: string) => {
    if (!selectedAoi) return
    if (isRectangle(selectedAoi)) {
      handleRectangleTransform(aoiId)
    }
    if (isEllipse(selectedAoi)) {
      handleEllipseTransform(aoiId)
    }
  }

  const commonToExportAoi = () => {
    if (!selectedAoi) return
    if (isRectangle(selectedAoi)) {
      return toExportRectangleAoi()
    }
    if (isEllipse(selectedAoi)) {
      return toExportEllipseAoi()
    }
  }

  const onNewRoutineVersionCancel = () => {
    if (!selectedAoi || !selectedAoiId) return

    if (isRectangle(selectedAoi) && currentRectangleData) {
      setRectangleData(selectedAoiId, currentRectangleData)
    }

    if (isEllipse(selectedAoi) && currentEllipseData) {
      setEllipseData(selectedAoiId, currentEllipseData)
    }

    setSelectedAoiId(undefined)
  }

  const renderImageWithBoxes = () => (
    <div className={Styles.feedContainer}>
      <ImageWithBoxes
        src={routine.image}
        onClick={e => {
          // Ensure any click on image but not on AOI deselects AOI
          if (selectedAoiId && (e.target as HTMLElement).nodeName.toLowerCase() === 'img') setSelectedAoiId(undefined)
        }}
        onResize={(width, height) => setImageSize({ width, height })}
        showOverlay={aois.length > 0 && !selectedAoiId}
      >
        {!hideAois && imageSize && (
          <AoisContainer
            redrawKey={`${imageSize.width}:${imageSize.height}`}
            onUpdateAoiWithShape={protectedOnChange(handleUpdateAoi, {
              routine,
              recipe,
              history,
              onCancel: onNewRoutineVersionCancel,
              onBeforeCreate: onBeforeCreateNewRoutineVersion,
            })}
            onSelectAoi={handleSelectAoi}
            onDeselectAois={() => {
              setSelectedAoiId(undefined)
            }}
            disableTransform={mode === 'aois' && aois.length > 1}
            disableRectangleRotation={disableRectangleRotation}
            aoiConfigs={aois.map(aoi => {
              // We should only mark an AOI as inactive if there is another AOI that is selected instead of it.
              const isInactive = aoi.id !== selectedAoiId

              // We change the key whenever we select or deselect an AOI to ensure a re-mount happens. This lets
              // us simplify the implementation of the EditableShapes since we can assume they stay selected or
              // deselected through their lifespan.
              const key = aoi.id + (isInactive ? 'inactive' : 'selected')
              return { aoi: aoi, key: key, isInactive }
            })}
            showSizeBoxes={showSizeBoxes}
            minSizeBoxWidth={minSizeBoxWidth}
            minSizeBoxHeight={minSizeBoxHeight}
            maxSizeBoxWidth={maxSizeBoxWidth}
            maxSizeBoxHeight={maxSizeBoxHeight}
            activeSizeBox={activeSizeBox}
            onScaleChange={setScaling}
            bounds={bounds}
            setBounds={setBounds}
            setRectangleData={setRectangleData}
            rectangleData={currentRectangleData}
            handleTransformEnd={protectedOnChange(handleCommonTransformEnd, {
              routine,
              recipe,
              history,
              onBeforeCreate: onBeforeCreateNewRoutineVersion,
            })}
            handleTransform={handleCommonTransform}
            toExportAoi={commonToExportAoi}
            setEllipseData={setEllipseData}
            allRectanglesData={allRectanglesData}
            allEllipseData={allEllipseData}
            allRefs={allRefs}
            showDrawNotificationRef={showDrawNotificationRef}
          />
        )}
      </ImageWithBoxes>
    </div>
  )

  const renderPreviewImage = () => {
    if (!showPreview) return null

    return (
      <>
        {imageElement && selectedAoi && (
          <canvas
            className={Styles.aoiHelperCanvas}
            id="aoiCanvas"
            width={selectedAoi.width}
            height={selectedAoi.height}
          ></canvas>
        )}
        <div className={Styles.ocrPreviewContainer}>
          <div className={`${Styles.grid} ${showGrid ? '' : Styles.hide}`}>
            <div className={Styles.horizontalLineContainer}>{renderGridLines(18, Styles.horizontalLine)}</div>
            <div className={Styles.verticalLineContainer}>{renderGridLines(18, Styles.verticalLine)}</div>
          </div>

          {!showEyeDropper && (
            <div className={`${Styles.previewTitleContainer} ${showFullscreen ? Styles.fullscreenPreviewTitle : ''}`}>
              <div className={Styles.previewTitle}>Preview</div>

              {IS_QA && <QaPreviewDownload className={Styles.qaPreviewDownload} />}
            </div>
          )}

          <ImagePreprocessingPreview
            showEmptyPreview={!selectedAoi}
            className={Styles.ocrCanvas}
            imgRef={aoiImage || null}
            settings={settings}
            onPixelCountChange={onPixelCountChange}
          />
        </div>
      </>
    )
  }

  const AoiParentName = ({
    selectedAoi,
    className,
  }: {
    selectedAoi?: AreaOfInterestConfiguration
    className?: string
  }) => {
    if (!selectedAoi) return
    return <span className={className}>{selectedAoi.parentName}</span>
  }

  return (
    <div className={Styles.videoFeedSection} onMouseDown={e => e.stopPropagation()}>
      {mode === 'aois' && !hideAois && <AoiParentName selectedAoi={selectedAoi} className={Styles.aoiName} />}

      {renderRenameAoiModal()}

      {showFullscreen && (
        <FullScreen id="fullscreen-aoi" onClose={() => setShowFullscreen(false)}>
          <FullScreenHeader
            onCloseClick={() => setShowFullscreen(false)}
            leftSide={<AoiParentName selectedAoi={selectedAoi} className={Styles.fullscreenAoiName} />}
          />
          <div className={`${Styles.aoiContainer} ${showPreview && Styles.aoiAndpreviewWrapper}`}>
            <div className={Styles.aoiBodyContainer}>
              {renderImageWithBoxes()}
              {renderPreviewImage()}
            </div>
          </div>
          <FullScreenFooter>{renderHotkeys()}</FullScreenFooter>
        </FullScreen>
      )}

      {!showFullscreen && (
        <>
          <div
            className={`${Styles.aoiContainer} ${showPreview ? Styles.aoiAndpreviewWrapper : ''}`}
            id="aoi-container"
          >
            <div className={Styles.aoiBodyContainer}>
              {showEyeDropper && imageElement && <div className={Styles.dropperPreview} id="dropper-preview" />}

              {showPreview && showEyeDropper && imageElement && (
                <div className={Styles.dropperCanvasContainer}>
                  <canvas
                    className={Styles.dropperCanvas}
                    id="eye-dropper-canvas"
                    width={imageElement.width}
                    height={imageElement.height}
                  />
                </div>
              )}

              {renderImageWithBoxes()}
              {renderPreviewImage()}
            </div>
          </div>

          <div className={Styles.hotkeysContainer}>{renderHotkeys()}</div>
        </>
      )}
    </div>
  )
}

export default EditAoisHelper

const renderGridLines = (amount: number, className?: string) => {
  const arr = []

  for (let i = 0; i < amount; i++) {
    arr.push(<div key={`${className}${i}`} className={className} />)
  }
  return arr
}

export const computeOriginFromCorner = (
  x: number,
  y: number,
  width: number,
  height: number,
  rotationDegrees: number,
): { originX: number; originY: number } => {
  // Graphic in this answer might help understand this https://stackoverflow.com/a/9972699
  const angle = (rotationDegrees * Math.PI) / 180
  return {
    originX: x + (Math.cos(angle) * width) / 2 + (Math.cos(angle + Math.PI / 2) * height) / 2,
    originY: y + (Math.sin(angle) * width) / 2 + (Math.sin(angle + Math.PI / 2) * height) / 2,
  }
}

const getRectangleBoundingBox = ({
  originX,
  originY,
  width,
  height,
  rotationDegrees,
}: {
  originX: number
  originY: number
  width: number
  height: number
  rotationDegrees: number
}): { x: number; y: number; width: number; height: number } => {
  const angle = (rotationDegrees * Math.PI) / 180
  const origin = new Point(originX, originY)
  const dir1 = new Point((width / 2) * Math.cos(angle), (width / 2) * Math.sin(angle))
  const dir2 = new Point((height / 2) * Math.cos(angle + Math.PI / 2), (height / 2) * Math.sin(angle + Math.PI / 2))
  const tr = origin.add(dir1).add(dir2)
  const tl = origin.subtract(dir1).add(dir2)
  const br = origin.add(dir1).subtract(dir2)
  const bl = origin.subtract(dir1).subtract(dir2)
  const xMax = Math.max(tr.x, tl.x, br.x, bl.x)
  const xMin = Math.min(tr.x, tl.x, br.x, bl.x)
  const yMax = Math.max(tr.y, tl.y, br.y, bl.y)
  const yMin = Math.min(tr.y, tl.y, br.y, bl.y)
  return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin }
}

export const getCurrentRectangleBoundingBox = ({ rectRef }: { rectRef: React.RefObject<Rect> }) => {
  const rect = rectRef.current as Konva.Rect
  return getRectangleBoundingBox({
    width: rect.width(),
    height: rect.height(),
    rotationDegrees: rect.rotation(),
    ...computeOriginFromCorner(rect.x(), rect.y(), rect.width(), rect.height(), rect.rotation()),
  })
}

const getEllipseBoundingBox = ({
  originX,
  originY,
  radiusX,
  radiusY,
  rotationDegrees,
}: EllipseData): { x: number; y: number; width: number; height: number } => {
  // See here for commentary and a derivation of this formula:
  // https://stackoverflow.com/questions/87734/how-do-you-calculate-the-axis-aligned-bounding-box-of-an-ellipse It ends
  // up following from using trying to find the maximum x-coordinate/y-coordinate in the parametric representation of
  // the ellipse which requires setting the derivative to zero and inverting.
  const angle = (rotationDegrees * Math.PI) / 180
  const a = radiusX * Math.cos(angle)
  const b = radiusY * Math.sin(angle)
  const c = radiusX * Math.sin(angle)
  const d = radiusY * Math.cos(angle)
  const width = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)) * 2
  const height = Math.sqrt(Math.pow(c, 2) + Math.pow(d, 2)) * 2
  var x = originX - width * 0.5
  var y = originY - height * 0.5
  return { x, y, width, height }
}

export const getCurrentEllipseBoundingBox = (ellipseRef: React.RefObject<Ellipse>) => {
  const ellipse = ellipseRef.current!
  return getEllipseBoundingBox({
    originX: ellipse.x(),
    originY: ellipse.y(),
    radiusX: ellipse.radiusX() * ellipse.scaleX(),
    radiusY: ellipse.radiusY() * ellipse.scaleY(),
    rotationDegrees: ellipse.rotation(),
  })
}

const outOfBounds = ({
  x,
  y,
  width,
  height,
  bounds,
  scaling,
}: {
  x: number
  y: number
  width: number
  height: number
  bounds: MaxBounds
  scaling: number
}): boolean => {
  return x + width > bounds.maxWidth * scaling || y + height > bounds.maxHeight * scaling || x < 0 || y < 0
}

const outOfBoundsX = ({
  x,
  width,
  bounds,
  scaling,
}: {
  x: number
  y: number
  width: number
  height: number
  bounds: MaxBounds
  scaling: number
}): boolean => {
  return x + width > bounds.maxWidth * scaling || x < 0
}

const outOfBoundsY = ({
  y,
  height,
  bounds,
  scaling,
}: {
  x: number
  y: number
  width: number
  height: number
  bounds: MaxBounds
  scaling: number
}): boolean => {
  return y + height > bounds.maxHeight * scaling || y < 0
}

/** Same as computeOriginFromCorner, but inverted (solve for x and y). */
export const computeCornerFromOrigin = (
  originX: number,
  originY: number,
  width: number,
  height: number,
  rotationDegrees: number,
): { x: number; y: number } => {
  const angle = (rotationDegrees * Math.PI) / 180
  return {
    x: originX - (Math.cos(angle) * width) / 2 - (Math.cos(angle + Math.PI / 2) * height) / 2,
    y: originY - (Math.sin(angle) * width) / 2 - (Math.sin(angle + Math.PI / 2) * height) / 2,
  }
}

const updateToMatchBox = (layer: Konva.Layer, rectRef: React.RefObject<Rect>) => {
  const strokeRect = layer!.findOne<Konva.Rect>('.aoi-stroke')
  const rect = rectRef.current! as Konva.Rect
  strokeRect.position(rect.position())
  strokeRect.size(rect.size())
  strokeRect.rotation(rect.rotation())
}

const isRectangle = (aoi: AreaOfInterestConfiguration) => !aoi.shape || aoi.shape.type === 'rectangle'

const isEllipse = (aoi: AreaOfInterestConfiguration) => aoi.shape?.type === 'ellipse'
