import React, {
  ComponentType,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ColumnsType, ColumnType, TableProps } from 'antd/lib/table'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { FixedSizeGrid, GridChildComponentProps, GridProps, VariableSizeGrid } from 'react-window'
import { GridElementScrollerChildrenProps, ReactWindowElementScroller } from 'react-window-element-scroller'

import { BreadcrumbLabel } from 'components/Breadcrumbs/Breadcrumbs'
import { Button } from 'components/Button/Button'
import LabelsList from 'components/LabelList/LabelsList'
import Layout from 'components/Layout/Layout'
import { PrismShareIcon } from 'components/prismIcons'
import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'
import PrismSideNavLayout from 'components/PrismSideNavLayout/PrismSideNavLayout'
import { useContainerDimensions, useGridDimensions, useIsColocated, useQueryParams, useTypedSelector } from 'hooks'
import CountGraph from 'pages/StationDetail/Components/CountGraph'
import { GraphSeries, YieldGraph } from 'pages/StationDetail/Components/YieldGraph'
import paths from 'paths'
import { AnalyzeDefect, DateValue, PdfData, TimeSeriesDatePeriod, TimeSeriesResult, ToolLabel } from 'types'
import { calculatePercentage, combineOutcomeCounts, getLastAnalyzeTab, renderLargeNumber } from 'utils'
import {
  ANALYZE_MINI_CHARTS_WIDTH,
  ANALYZE_ROW_WITH_CHART_HEIGHT,
  ANALYZE_TABLE_PAGINATION_ROWS,
  ANALYZE_TOOLTIP_Y_DISTANCE,
  TABLE_HEADER_HEIGHT,
} from 'utils/constants'

import Styles from './Analyze.module.scss'
import { AnalyzeBatches, InspectionWithMetrics } from './AnalyzeBatches'
import { AnalyzeItems } from './AnalyzeItems'
import { AnalyzeOverview } from './AnalyzeOverview'
import { AnalyzeProducts, ComponentWithMetrics } from './AnalyzeProducts'
import { AnalyzeStations, StationWithMetrics } from './AnalyzeStations'
import { AnalyzeTools } from './AnalyzeTools'
import ShareModal from './ShareModal'

export type AnalyzeWithGalleryProps = {
  setPdfData: (data: PdfData | undefined) => void
  isGalleryExpanded: boolean
  setIsGalleryExpanded: React.Dispatch<React.SetStateAction<boolean>>
  galleryTransitionInProgress: boolean
  setGalleryTransitionInProgress: React.Dispatch<React.SetStateAction<boolean>>
  tableRerenderKey?: string
}

export const ANALYZE_VERTICAL_PADDING = 32
export const ANALYZE_HORIZONTAL_PADDING = 32
export const ANALYZE_SMALL_HORIZONTAL_PADDING = 24
const GRAPHS_COLUMN_WIDTH_WHEN_GALLERY_IS_OPEN = 76

const AnalyzeBase = () => {
  const history = useHistory()

  const pdfDataRef = useRef<PdfData>()

  const locationHistory = useTypedSelector(state => state.locationHistory)
  const [params] = useQueryParams()

  const [isGalleryExpanded, setIsGalleryExpanded] = useState(false)
  const [galleryTransitionInProgress, setGalleryTransitionInProgress] = useState<boolean>(false)
  const [tableRerenderKey, setTableRerenderKey] = useState('')

  const [showShareModal, setShowShareModal] = useState(false)
  const setPdfData = (pdfData: PdfData | undefined) => {
    pdfDataRef.current = pdfData
  }

  // Send user to last analyze tab when component first loads
  useEffect(() => {
    if (history.location.search) return
    const lastTab = getLastAnalyzeTab(locationHistory)
    history.replace(paths.analyze({ mode: lastTab, params }))
    // eslint-disable-next-line
  }, [history])

  // We need to grab the html tag to add special styles while using the Analyze filters
  // The styles added helps to calculate the gap when the virtual keyboard is visible
  useEffect(() => {
    const analyzeHTMLTag = document.querySelector('html')
    analyzeHTMLTag?.classList.add('virtualKeyboardGap')

    return () => analyzeHTMLTag?.classList.remove('virtualKeyboardGap')
  }, [])

  // After the sidebar gallery finishes the opening/closing transition, force tables to rerender to recalculate space
  useEffect(() => {
    // Do this on a 0 timeout to force it to be the last action in the JS event loop
    setTimeout(() => {
      setTableRerenderKey(key => '' + (+key + 1))
    }, 0)
  }, [galleryTransitionInProgress])

  return (
    <Layout
      title={
        <div data-testid="analyze-breadcrumb" className={Styles.analyzeHeader}>
          <BreadcrumbLabel className={Styles.analyzeTitle}>Analyze</BreadcrumbLabel>

          <ShareButton onClick={() => setShowShareModal(true)} />
        </div>
      }
    >
      <PrismSideNavLayout
        getPath={paths.analyze}
        forceParams={{ start: params.start, end: params.end, period: params.period }}
        data-testid="analyze-sidenav"
        listItems={[
          {
            label: 'Overview',
            value: 'overview',
            component: (
              <AnalyzeOverview
                isGalleryExpanded={isGalleryExpanded}
                setIsGalleryExpanded={setIsGalleryExpanded}
                setPdfData={setPdfData}
                galleryTransitionInProgress={galleryTransitionInProgress}
                setGalleryTransitionInProgress={setGalleryTransitionInProgress}
              />
            ),
          },
          {
            label: 'Products',
            value: 'products',
            component: (
              <AnalyzeProducts
                isGalleryExpanded={isGalleryExpanded}
                setIsGalleryExpanded={setIsGalleryExpanded}
                setPdfData={setPdfData}
                galleryTransitionInProgress={galleryTransitionInProgress}
                setGalleryTransitionInProgress={setGalleryTransitionInProgress}
                tableRerenderKey={tableRerenderKey}
              />
            ),
          },
          {
            label: 'Stations',
            value: 'stations',
            component: (
              <AnalyzeStations
                isGalleryExpanded={isGalleryExpanded}
                setIsGalleryExpanded={setIsGalleryExpanded}
                setPdfData={setPdfData}
                galleryTransitionInProgress={galleryTransitionInProgress}
                setGalleryTransitionInProgress={setGalleryTransitionInProgress}
                tableRerenderKey={tableRerenderKey}
              />
            ),
          },
          {
            label: 'Batches',
            value: 'batches',
            component: (
              <AnalyzeBatches
                isGalleryExpanded={isGalleryExpanded}
                setIsGalleryExpanded={setIsGalleryExpanded}
                setPdfData={setPdfData}
                galleryTransitionInProgress={galleryTransitionInProgress}
                setGalleryTransitionInProgress={setGalleryTransitionInProgress}
                tableRerenderKey={tableRerenderKey}
              />
            ),
          },
          {
            label: 'Items',
            value: 'items',
            component: <AnalyzeItems setPdfData={setPdfData} />,
          },
          {
            label: 'Tools',
            value: 'tools',
            component: <AnalyzeTools setPdfData={setPdfData} />,
          },
        ]}
        className={Styles.analyzeBody}
        leftContainerClassName={Styles.leftWrapper}
      />
      {showShareModal && <ShareModal onClose={() => setShowShareModal(false)} pdfData={pdfDataRef.current} />}
    </Layout>
  )
}

export default AnalyzeBase

const RenderAnalyzeRecordName = ({ record }: { record: AnalyzeTableMetrics }) => {
  return (
    <div>
      <PrismOverflowTooltip content={record.name} className={Styles.analyzeRecordTitle} />

      <PrismOverflowTooltip
        content={record.subTitle}
        className={Styles.analyzeRecordSubtitle}
        tooltipPlacement="bottomLeft"
      />
    </div>
  )
}

export type AnalyzeTableMetrics = {
  series: GraphSeries[]
  yield: number
  itemsInspected: number
  name: string
  subTitle?: string
  defects: AnalyzeDefect[]
  id: string
}

export type ObjectWithMetrics<T> = AnalyzeTableMetrics & T
export const TOOLTIP_POSITION = { x: 0, y: ANALYZE_TOOLTIP_Y_DISTANCE }
export const getAnalyzeTableColumns = <T extends AnalyzeTableMetrics>({
  title,
  period,
  isGalleryExpanded,
  initialSortByYield,
  sortable = true,
}: {
  title: string
  period: TimeSeriesDatePeriod | undefined
  isGalleryExpanded: boolean
  initialSortByYield: boolean
  sortable?: boolean
}): ColumnsType<T> => {
  const testIdTab = title.toLowerCase()
  return [
    {
      title,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,

      render: (_, record) => <RenderAnalyzeRecordName record={record} />,
    },
    {
      title: 'Yield',
      dataIndex: 'yield',
      key: 'yield',
      sorter: sortable && ((a, b) => a.yield - b.yield),
      width: isGalleryExpanded ? GRAPHS_COLUMN_WIDTH_WHEN_GALLERY_IS_OPEN : undefined,
      defaultSortOrder: initialSortByYield ? 'ascend' : undefined,

      render: (_, record) => (
        <RowGraph
          isGalleryExpanded={isGalleryExpanded}
          rowGraphValue={`${record.yield.toFixed(1)}%`}
          graphType="yield"
          record={record}
          period={period}
          syncId={`${title}-${record.id}`}
          data-testid={`analyze-${testIdTab}-yield-${record.name}`}
        />
      ),
    },
    {
      title: <span className={Styles.itemsInspectedColumn}>Items {!isGalleryExpanded && <span>Inspected</span>}</span>,
      dataIndex: 'itemsInspected',
      key: 'itemsInspected',
      sorter: sortable && ((a, b) => a.itemsInspected - b.itemsInspected),
      width: isGalleryExpanded ? GRAPHS_COLUMN_WIDTH_WHEN_GALLERY_IS_OPEN : undefined,

      render: (_, record) => {
        return (
          <RowGraph
            isGalleryExpanded={isGalleryExpanded}
            rowGraphValue={renderLargeNumber(record.itemsInspected, 1000)}
            graphType="count"
            record={record}
            period={period}
            syncId={`${title}-${record.id}`}
            data-testid={`analyze-${testIdTab}-items-inspected-${record.name}`}
          />
        )
      },
    },
    {
      title: 'Most Common Defects',
      dataIndex: 'mostCommonDefects',
      key: 'mostCommonDefects',
      ellipsis: true,
      render: (_, record) => {
        const toolLabels = record.defects.map(defect => ({
          ...defect.toolLabel,
          extraContent: `${defect.percentage.toFixed(1)}%`,
        }))
        return <LabelsList labels={toolLabels} data-testid={`analyze-${testIdTab}-common-defects-${record.name}`} />
      },
    },
  ]
}

const GridCellRenderer = React.memo(
  <T,>({
    rowIndex,
    columnIndex,
    data,
    style,
  }: GridChildComponentProps<{
    rows: T[] | undefined
    columnsWithWidths: ColumnType<T>[]
    onClick?: (row: T) => void
    isRowActive?: (row: T) => boolean
    rowClassName?: string
    hideHoverStyles?: boolean
    getTestId?: (row: T) => string
  }>) => {
    const rowRef = useRef<HTMLDivElement>(null)
    const { columnsWithWidths, onClick, rows, isRowActive, rowClassName, hideHoverStyles, getTestId } = data
    const item = rows?.[rowIndex]

    const column = columnsWithWidths?.[columnIndex]

    const dataIndex = column?.dataIndex as keyof T
    const value = item?.[dataIndex]

    const toRender = useMemo(
      () => (item && column?.render?.(value, item, rowIndex)) || value,
      [column, item, rowIndex, value],
    )

    // Returns whether the tooltip would pass the screen height when rendered
    const shouldChangeTooltipPosition = () => {
      if (!rowRef.current) return
      // TODO : if the tooltip height changes this will break
      const tooltipHeight = 146
      const rowRect = rowRef.current.getBoundingClientRect()
      return rowRect.bottom + tooltipHeight > window.innerHeight
    }

    // We have to handle hover this way because grid renders cells and not rows,
    // to have we use the data-table-row attribute to be able to identify the row,
    // and then add the hover class to all of the elements in the row
    const handleMouseEnter = useCallback(() => {
      document.querySelectorAll(`[data-table-row="${rowIndex}"`).forEach(el => {
        const tooltipClassNames = hideHoverStyles ? [] : [Styles.hoveredRow || '']
        if (shouldChangeTooltipPosition()) tooltipClassNames.push(Styles.tooltipTopPosition || '')
        el.classList.add(...tooltipClassNames)
      })
    }, [rowIndex, hideHoverStyles])

    const handleMouseLeave = useCallback(() => {
      document.querySelectorAll(`[data-table-row="${rowIndex}"`).forEach(el => {
        el.classList.remove(Styles.hoveredRow || '', Styles.tooltipTopPosition || '')
      })
    }, [rowIndex])

    if (!item || !column) return null
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ ...style }}
        className={`${Styles.tableRow} ${Styles.tableColumn} ${isRowActive?.(item) ? Styles.activeTableRow : ''} ${
          rowClassName ?? ''
        }`}
        data-table-row={rowIndex} // Use this to identify rows in the table and apply hover effects
        onClick={() => onClick?.(item)}
        data-test={`analyze-base-${column.key?.toString().replace(/\s+/g, '-').toLowerCase()}`}
        ref={rowRef}
        data-testid={getTestId?.(item)}
      >
        <span
          className={`${Styles.tableRowContent} ${
            ['itemsInspected', 'yield'].includes(dataIndex.toString()) ? Styles.tableRowWithOverflow : ''
          }`}
        >
          {toRender as ReactNode}
        </span>
      </div>
    )
  },
)

const LIST_VIEW_HEADER_HEIGHT = TABLE_HEADER_HEIGHT
let LIST_VIEW_TABLE_PADDING = 32
const GRID_VIEW_GAP = 16
const GRID_VIEW_CARD_MIN_WIDTH = 202
const GRID_VIEW_CARD_HEIGHT = 230
const GRID_VIEW_RIGHT_PADDING = 32

export const useVirtualizedTable = <T,>(
  columns: ColumnType<T>[],
  options: {
    onClick?: (row: T) => any
    handleEndReached?: () => any
    rowHeight?: number
    breakPointsByColumnKey?: { [key: string]: { [key: number]: number } }
    isRowActive?: (row: T) => boolean
    rowClassName?: string
    tableCustomHeaderHeight?: number
    hideHoverStyles?: boolean
    removeAnalyzeTablePadding?: boolean
    getTestId?: (row: T) => string
  },
) => {
  const {
    handleEndReached,
    onClick,
    rowHeight = 64,
    isRowActive,
    rowClassName,
    tableCustomHeaderHeight,
    hideHoverStyles,
    removeAnalyzeTablePadding,
    getTestId,
  } = options
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableDimensions = useContainerDimensions(tableContainerRef)
  const { height: tableHeight, width: tableWidth } = tableDimensions
  const gridRef = useRef<VariableSizeGrid>(null)
  const isFetchingMoreRef = useRef(false)
  const windowDimensions = useContainerDimensions()
  const { width } = windowDimensions

  const columnsWithWidths = useMemo(() => {
    const breakpointColumns = columns.map(column => {
      const breakPointsByScreenSize = options.breakPointsByColumnKey?.[column.key || '']

      if (!breakPointsByScreenSize || !width) return column

      const breakpoints = Object.keys(breakPointsByScreenSize)
        .map(key => parseInt(key))
        // Sort by columns with width first, so that we have an accurate usedWidth and can divide evenly
        // among columns without defined widths
        .sort((a, b) => a - b)

      for (let i = 0; i < breakpoints.length; i++) {
        const breakpoint = breakpoints[i]
        if ((breakpoint || 0) >= width) {
          return { ...column, width: breakPointsByScreenSize[breakpoint!] }
        }
      }

      return column
    })

    const displayColumns = breakpointColumns.filter(column => column?.width !== 0)

    const columnsWithoutWidthCount = displayColumns.filter(({ width }) => !width).length
    let usedWidth = 0

    displayColumns.forEach(column => {
      if (column.width) {
        usedWidth += +column.width
      }
    })

    return displayColumns.map(column => {
      if (column.width) {
        return column
      }
      const newWidth = ((tableWidth || 0) - usedWidth) / columnsWithoutWidthCount

      return { ...column, width: newWidth }
    })
  }, [columns, options.breakPointsByColumnKey, tableWidth, width])

  useEffect(() => {
    // When the window is resized and width changes, we need to re-render the grid because
    // element positions may have changed
    gridRef.current?.resetAfterColumnIndex(0)
  }, [tableWidth])

  if (width && width <= 1024) {
    LIST_VIEW_TABLE_PADDING = 24
  }

  const renderVirtualTable: NonNullable<TableProps<T>['components']>['body'] = rawData => {
    if (!tableHeight || !tableWidth) return null

    let variableSizeGridHeight = tableHeight - LIST_VIEW_HEADER_HEIGHT
    if (tableCustomHeaderHeight) variableSizeGridHeight = tableHeight - tableCustomHeaderHeight

    let tableWidthToUse = tableWidth + LIST_VIEW_TABLE_PADDING
    if (removeAnalyzeTablePadding) tableWidthToUse = tableWidth

    return (
      <VariableSizeGrid
        ref={gridRef}
        columnCount={columnsWithWidths.length}
        rowCount={rawData.length}
        columnWidth={index => {
          return index !== columnsWithWidths.length - 1
            ? +(columnsWithWidths[index]?.width || 0)
            : +(columnsWithWidths[index]?.width || 0)
        }}
        rowHeight={() => rowHeight}
        height={variableSizeGridHeight}
        width={tableWidthToUse}
        itemData={{ rows: rawData, columnsWithWidths, onClick, isRowActive, rowClassName, hideHoverStyles, getTestId }}
        className={Styles.tableGrid}
        onItemsRendered={async ({ visibleRowStopIndex }) => {
          if (isFetchingMoreRef.current) return

          if (visibleRowStopIndex + ANALYZE_TABLE_PAGINATION_ROWS >= rawData.length) {
            isFetchingMoreRef.current = true
            await handleEndReached?.()
            isFetchingMoreRef.current = false
          }
        }}
      >
        {GridCellRenderer as GridProps['children']}
      </VariableSizeGrid>
    )
  }

  return {
    tableContainerRef,
    tableHeight,
    tableWidth,
    columnsWithWidths,
    renderVirtualTable,
    removeAnalyzeTablePadding,
  }
}

export interface CardRendererProps<T> {
  columnCount: number
  elements: T[] | undefined
  onClick: (item: T) => void
  showInsights: boolean
  allToolLabels: ToolLabel[] | undefined
  toIALimitDate?: string
}

export const useVirtualizedGallery = <T,>(
  items: T[] | undefined,
  allToolLabels: ToolLabel[] | undefined,
  handleEndReached: () => any,
  onClick: (item: T) => any,
  showInsights: boolean,
  toIALimitDate: string | undefined,
  renderer: ComponentType<GridChildComponentProps<CardRendererProps<T>>>,
) => {
  const isFetchingMoreRef = useRef(false)
  const galleryBodyRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<FixedSizeGrid>(null)
  const containerDimensions = useContainerDimensions(galleryBodyRef)
  const { rowCount, columnCount, columnWidth, rowHeight } = useGridDimensions(
    (containerDimensions?.width || 0) - GRID_VIEW_RIGHT_PADDING,
    items?.length,
    { gridGap: GRID_VIEW_GAP, minWidth: GRID_VIEW_CARD_MIN_WIDTH, elementRowHeight: GRID_VIEW_CARD_HEIGHT },
  )
  const outerRef = useRef<HTMLDivElement>(null)

  const galleryGrid = (
    <ReactWindowElementScroller
      type="grid"
      scrollerElementRef={galleryBodyRef}
      gridRef={gridRef}
      outerRef={outerRef}
      childrenStyle={{ height: 'fit-content' }}
    >
      {({ style, onScroll }: GridElementScrollerChildrenProps) => (
        <FixedSizeGrid
          rowCount={rowCount}
          columnCount={columnCount}
          columnWidth={columnWidth}
          rowHeight={rowHeight}
          height={containerDimensions.height || 0}
          width={(containerDimensions.width || 0) - GRID_VIEW_RIGHT_PADDING}
          style={style}
          ref={gridRef}
          itemData={{
            elements: items,
            columnCount,
            onClick,
            showInsights,
            allToolLabels,
            toIALimitDate,
          }}
          onScroll={onScroll}
          onItemsRendered={async ({ visibleRowStopIndex }) => {
            if (isFetchingMoreRef.current) return

            if (visibleRowStopIndex + ANALYZE_TABLE_PAGINATION_ROWS >= rowCount) {
              isFetchingMoreRef.current = true
              await handleEndReached?.()
              isFetchingMoreRef.current = false
            }
          }}
        >
          {renderer}
        </FixedSizeGrid>
      )}
    </ReactWindowElementScroller>
  )

  return { galleryBodyRef, gridRef, outerRef, galleryGrid }
}

/**
 * Renders a share button conditionally, depending on whether the user is colocated with
 * the robot.
 *
 * @param onClick
 */
const ShareButton = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
  const { isColocated } = useIsColocated()

  if (isColocated !== false) return null

  return (
    <Button onClick={onClick} type="secondary" size="small" badge={<PrismShareIcon />}>
      Share
    </Button>
  )
}

export const renderToolResultsColumn = (sortedLabels: ToolLabel[]) => {
  return (
    <LabelsList
      labels={sortedLabels}
      labelClassName={Styles.labelInTable}
      popoverClassName={Styles.labelinTablePopover}
      className={Styles.labelsList}
    />
  )
}

/**
 * Returns an array of AnalyzeDefects used by the analyze screens
 *
 * @param groupedLabelMetricsByLabelId - rts series grouped by label id
 * @param count - total Items count
 * @param allToolLabels - all org Tool Labels
 */
export const getAnalyzeDefects = ({
  groupedLabelMetricsByLabelId,
  count,
  allToolLabels,
}: {
  groupedLabelMetricsByLabelId: { [toolLabelId: string]: TimeSeriesResult[] }
  count: number
  allToolLabels: ToolLabel[] | undefined
}): AnalyzeDefect[] => {
  const labelsInMetrics = allToolLabels?.filter(label => groupedLabelMetricsByLabelId[label.id])

  const defectLabels = labelsInMetrics?.filter(label => ['critical', 'minor'].includes(label.severity))

  const mappedDefects = defectLabels?.map(toolLabel => {
    const defectCount = combineOutcomeCounts(groupedLabelMetricsByLabelId[toolLabel.id]!).reduce(
      (prev, current) => prev + current.count,
      0,
    )

    return {
      toolLabel,
      defectCount,
      percentage: calculatePercentage(defectCount, count),
    }
  })

  return (
    mappedDefects?.filter(defect => defect.defectCount).sort((defA, defB) => defB.percentage - defA.percentage) || []
  )
}

// Generates a dummy period that renders the graphs when there's no data.
export const dummyData = ({ start, end }: { start: DateValue; end: DateValue }) => {
  return [
    {
      ts: start ? start.unix() : moment('20200101').valueOf(),
      count: 0,
      passYield: 0,
      failYield: 0,
      unknownYield: 0,
      pass: 0,
      fail: 0,
      unknown: 0,
      error: 0,
      'needs-data': 0,
      deleted: 0,
      pass_because_muted: 0,
    },
    {
      ts: end ? end.unix() : moment().valueOf(),
      count: 0,
      passYield: 0,
      failYield: 0,
      unknownYield: 0,
      pass: 0,
      fail: 0,
      unknown: 0,
      error: 0,
      'needs-data': 0,
      deleted: 0,
      pass_because_muted: 0,
    },
  ]
}

// Generates the empty states graphs styles. hides the tooltip, push the number 0 and the blue line for items inspected.
export const forceZeroPosition = (
  componentMetrics: ComponentWithMetrics | StationWithMetrics | InspectionWithMetrics | undefined,
) => {
  return !componentMetrics || componentMetrics?.itemsInspected === 0
}

/**
 * Renders a Cell Graph container with its position in the viewport
 *
 *
 * @param isGalleryExpanded - A boolean to get the state of the gallery panel
 * @param rowGraphValue - The value to the left of the graph
 * @param graphType - Choose between yield or item graph
 * @param record - Data record from the table
 * @param period - Date period
 * @param syncId - If other charts are rendered at the same time, passing the same syncId to the charts syncs their tooltip
 */
const RowGraph = ({
  isGalleryExpanded,
  rowGraphValue,
  graphType,
  record,
  period,
  syncId,
  'data-testid': dataTestId,
}: {
  isGalleryExpanded: boolean
  rowGraphValue: string
  graphType: 'yield' | 'count'
  record: AnalyzeTableMetrics
  period?: TimeSeriesDatePeriod
  syncId: string
  'data-testid'?: string
}) => {
  return (
    <div className={Styles.rowGraphContainer}>
      <div className={Styles.rowGraphValue} data-testid={`${dataTestId}-value`}>
        {rowGraphValue}
      </div>

      {!isGalleryExpanded && (
        <div className={Styles.rowChartWrapper}>
          {graphType === 'yield' && (
            <YieldGraph
              height={ANALYZE_ROW_WITH_CHART_HEIGHT}
              width={ANALYZE_MINI_CHARTS_WIDTH}
              yieldSeries={record.series}
              period={period}
              mode="metrics"
              hideYAxis
              hideTicks
              syncId={syncId}
              fixedTooltipCoords={TOOLTIP_POSITION}
            />
          )}
          {graphType === 'count' && (
            <CountGraph
              height={ANALYZE_ROW_WITH_CHART_HEIGHT}
              width={ANALYZE_MINI_CHARTS_WIDTH}
              graphSeries={record.series}
              period={period}
              mode="metrics"
              hideTicks
              hideXAxis
              hideYAxis
              syncId={syncId}
              fixedTooltipCoords={TOOLTIP_POSITION}
            />
          )}
        </div>
      )}
    </div>
  )
}
