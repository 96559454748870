import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Gear Icon.
 *
 * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1405%3A184
 *
 * @param hasBackground - adds a background color around the icon when hover and active.
 * @param className - String for external styles
 */
export const PrismGearIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        id="Vector"
        d="M13.5 3.5H10.5L9.5 6L7.05025 4.92893L4.92893 7.05025L6 9.5L3.5 10.5V13.5L6 14.5L4.92893 16.9497L7.05025 19.0711L9.5 18L10.5 20.5H13.5L14.5 18L16.9497 19.0711L19.0711 16.9497L18 14.5L20.5 13.5V10.5L18 9.5L19.0711 7.05025L16.9497 4.92893L14.5 6L13.5 3.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle id="Ellipse" cx="12" cy="12" r="2.5" stroke="currentColor" />
    </svg>
  )
}
