/**
 * The functions in this module are really action creators and not actions:
 * <https://redux.js.org/basics/actions#action-creators>. They all take a
 * payload and return an action that can be dispatched to Redux to update the
 * state tree.
 */
import { Action as HistoryAction, Location } from 'history'
import { save, update } from 'react-redux-query'

import { GetterData, getterKeys } from 'api'
import {
  AtomElement,
  Auth,
  ConnectionStatus,
  LogoutAction,
  RecipeExpanded,
  RootState,
  RoutineSettings,
  RoutineWithAois,
  StreamMessage,
  SuccessResponseOnlyData,
  UserOrg,
} from 'types'

import { LOCAL_STORAGE_KEYS } from './reducers/localStorage'

interface GetterSave<T extends keyof typeof getterKeys, U extends ReturnType<(typeof getterKeys)[T]>> {
  key: U
  data: SuccessResponseOnlyData<GetterData<U>>
}
/**
 * Saves data to getter branch, overwriting whatever is at key.
 */
export function getterSave<T extends keyof typeof getterKeys, U extends ReturnType<(typeof getterKeys)[T]>>(
  payload: GetterSave<T, U>,
) {
  return save(payload)
}

interface GetterUpdate<T extends keyof typeof getterKeys, U extends ReturnType<(typeof getterKeys)[T]>> {
  key: U
  updater: (
    data: SuccessResponseOnlyData<GetterData<U>> | undefined,
  ) => SuccessResponseOnlyData<GetterData<U>> | undefined | null
}
/**
 * Like getterSave, but takes an updater function, which receives the data at
 * key and must return updated data, undefined, or null.
 */
export function getterUpdate<T extends keyof typeof getterKeys, U extends ReturnType<(typeof getterKeys)[T]>>(
  payload: GetterUpdate<T, U>,
) {
  return update(payload)
}

export interface AuthSet {
  auth: Auth
  rockwellIdpLogin: boolean
}

export interface OrgSet {
  organization_id: string
}
/**
 * Updates auth information, and persists it to local storage so it can be read
 * back and used to hydrate state tree on app reload.
 */
export function authSet(payload: AuthSet): Action {
  localStorage.setItem('elementary:auth', JSON.stringify(payload))

  return {
    type: 'AUTH_SET',
    payload: payload,
  }
}

export function orgSet(payload: UserOrg): Action {
  return {
    type: 'ORG_SET',
    payload,
  }
}

export function orgUpdate(payload: Partial<UserOrg>): Action {
  return {
    type: 'ORG_UPDATE',
    payload,
  }
}

export interface AuthUnsetSet {
  logoutAction: LogoutAction
}
/**
 * Unsets auth information and removes it from local storage. If user is
 * currently at any `PrivateRoute`, this immediately redirects user to login.
 */
export function authUnset(logoutAction: LogoutAction = 'userLogout'): Action {
  localStorage.removeItem('elementary:auth')

  return {
    type: 'AUTH_UNSET',
    payload: logoutAction,
  }
}

export function robotCapabilitesSet(payload: RootState['robotDiscoveriesById']): Action {
  return {
    type: 'ROBOT_DISCOVERIES_SET',
    payload,
  }
}

export type InspectorUpdate = Partial<RootState['inspector']>
/**
 * Stores some piece of inspector screen state or settings so that it can be
 * persisted across unmount/mount of inspector screen components.
 */
export function inspectorUpdate(payload: InspectorUpdate): Action {
  return {
    type: 'INSPECTOR_UPDATE',
    payload,
  }
}

export type EdgeSet = RootState['edge']
/**
 * Sets edge information, e.g. about IOT FastAPI server(s).
 */
export function edgeSet(payload: EdgeSet): Action {
  return {
    type: 'EDGE_SET',
    payload,
  }
}

export type LocationUpdate = { location: Location; action: HistoryAction }
/**
 * Prepends new `location` object to list of locations (URLs) visited by app.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Location
 */
export function locationUpdate(payload: LocationUpdate): Action {
  return {
    type: 'LOCATION_UPDATE',
    payload,
  }
}

export interface Event {
  key: string
  messages: StreamMessage[]
  ignoreStaleMessage?: boolean
  bufferLength?: number
}
/**
 * Prepends new Redis messages / events read over websocket, e.g. using the
 * `StreamListener` component, to messages already stored at `key`. `key` is
 * usually unique per ws URL path, and should be similar to ws URL path.
 */
export function event(payload: Event): Action {
  return {
    type: 'EVENT',
    payload,
  }
}

/**
 * Updates the routine overview version drawer settings for a specific routine parent
 */
export function itemUploadUpdate(
  payload: { files: File[]; routine: RoutineWithAois; recipe: RecipeExpanded } | null,
): Action {
  return {
    type: 'ITEM_UPLOAD_UPDATE',
    payload,
  }
}

/**
 * Updates most recent frame and timestamp for given video key
 */
export function videoUpdate(payload: {
  frame: Blob | undefined
  robot_id: string
  element: AtomElement | ''
  stream: string
  message_id: string
}): Action {
  const { frame, robot_id, element, stream, message_id } = payload
  return {
    type: 'VIDEO_UPDATE',
    payload: { frame, message_id, key: `${element}:${stream}:${robot_id}` },
  }
}

interface TriggerModeUpdatePayload {
  robotId: string
  triggerMode: RoutineSettings['camera_trigger_mode']
}

/**
 * Updates most recent trigger setting for a robotId
 */
export function triggerModeUpdate(payload: TriggerModeUpdatePayload): Action {
  return {
    type: 'TRIGGER_MODE_UPDATE',
    payload,
  }
}

interface ConnectionStatusUpdate {
  status: ConnectionStatus
}

/**
 * Updates cloud connection status
 */
export function connectionStatusUpdate(payload: ConnectionStatusUpdate): Action {
  return {
    type: 'CONNECTION_UPDATE',
    payload,
  }
}

interface LocalStorageUpdate<T extends keyof RootState['localStorage']> {
  key: T
  data: RootState['localStorage'][T]
}

export function localStorageUpdate<T extends keyof RootState['localStorage']>(payload: LocalStorageUpdate<T>): Action {
  localStorage.setItem(LOCAL_STORAGE_KEYS[payload.key], JSON.stringify(payload.data))
  return {
    type: 'LOCAL_STORAGE_UPDATE',
    payload,
  }
}

interface ModalsUpdate {
  operation: 'add' | 'remove'
  modalId: string
}

export function modalsUpdate(payload: ModalsUpdate): Action {
  return {
    type: 'MODALS_UPDATE',
    payload,
  }
}

export type Action =
  | { type: 'RESET'; payload?: Partial<RootState> } // RESET FOR TESTING ONLY
  | { type: 'EVENT'; payload: Event }
  | { type: 'AUTH_SET'; payload: AuthSet }
  | { type: 'AUTH_UNSET'; payload: LogoutAction }
  | { type: 'ROBOT_DISCOVERIES_SET'; payload: RootState['robotDiscoveriesById'] }
  | { type: 'INSPECTOR_UPDATE'; payload: InspectorUpdate }
  | { type: 'LOCATION_UPDATE'; payload: LocationUpdate }
  | { type: 'EDGE_SET'; payload: EdgeSet }
  | { type: 'ITEM_UPLOAD_UPDATE'; payload: { files: File[]; routine: RoutineWithAois; recipe: RecipeExpanded } | null }
  | { type: 'VIDEO_UPDATE'; payload: { frame: Blob | undefined; key: string; message_id: string } }
  | { type: 'TRIGGER_MODE_UPDATE'; payload: TriggerModeUpdatePayload }
  | { type: 'CONNECTION_UPDATE'; payload: ConnectionStatusUpdate }
  | { type: 'LOCAL_STORAGE_UPDATE'; payload: LocalStorageUpdate<any> }
  | { type: 'MODALS_UPDATE'; payload: ModalsUpdate }
  | { type: 'ORG_SET'; payload: UserOrg }
  | { type: 'ORG_UPDATE'; payload: Partial<UserOrg> }
