import React, { useState } from 'react'

import { service } from 'api'
import OptionMenu, { Option } from 'components/OptionMenu/OptionMenu'
import { PrismDiscardIcon, PrismEditIcon } from 'components/prismIcons'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { modal } from 'components/PrismModal/PrismModal'

import Styles from '.././QualityEvents.module.scss'
import { EventTypeWithCounts } from '../QualityEvents'
import AddOrEditQualityEventModal from './AddOrEditQualityEventModal'

type EventModalType = 'edit' | 'delete'

/**
 * Renders the Event Option Menu
 *
 */
const EventTypeOptionMenu = ({
  eventType,
  handleSuccess,
}: {
  handleSuccess: () => Promise<void>
  eventType: EventTypeWithCounts
}) => {
  const [open, setOpen] = useState(false)
  const [activeModalType, setActiveModalType] = useState<EventModalType>()

  const isDefaultEventType = eventType.kind === 'default'

  const options: Option<string>[] = [
    {
      value: 'edit',
      'data-testid': 'edit',
      badge: <PrismEditIcon />,
      disabled: isDefaultEventType,
    },
    {
      value: 'delete',
      'data-testid': 'delete',
      badge: <PrismDiscardIcon />,
      disabled: isDefaultEventType,
    },
  ]

  const handleMenuItemClick = (value: string) => {
    if (value === 'edit') {
      setActiveModalType(value)
    }

    if (value === 'delete') {
      modal.warning({
        id: 'delete-quality-event-confirmation',
        'data-testid': 'delete-quality-event-modal',
        header: <div>Delete Quality Event?</div>,
        content: 'Are you sure you want to delete this quality event? This can not be undone.',
        okText: 'Delete',
        onOk: async close => {
          const deleteRes = await service.patchEventType(eventType.id, { is_deleted: true })
          if (deleteRes.type !== 'success') {
            return error({ title: 'An error occurred, try again later.' })
          }
          await handleSuccess()

          success({ title: 'Quality event deleted', 'data-testid': 'quality-event-deleted' })
          close()
        },
        onCancel: () => setActiveModalType(undefined),
      })
    }
  }

  return (
    <>
      <OptionMenu
        data-testid={`event-type-${eventType.name}-settings-menu`}
        options={options}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onMenuItemClick={handleMenuItemClick}
        menuContainerClassName={Styles.rowOptionMenu}
        openWithClick
        closeOnClick
        iconButtonSize="small"
        iconButtonType="tertiary"
        iconButtonClassName={`${Styles.rowMenuIcon} ${open ? Styles.iconButtonActive : ''}`}
      />

      {activeModalType === 'edit' && (
        <AddOrEditQualityEventModal
          id="edit-quality-event"
          eventType={eventType}
          isEditMode
          onSuccess={async () => {
            await handleSuccess()
            setActiveModalType(undefined)
          }}
          onClose={() => setActiveModalType(undefined)}
        />
      )}
    </>
  )
}

export default EventTypeOptionMenu
