import React from 'react'

import { BrandLogo } from 'components/prismIcons'

import Styles from './PrismHeaderLogo.module.scss'

export const PrismHeaderLogo = ({ className = '', dataTestId }: { className?: string; dataTestId?: string }) => {
  return (
    <div className={`${Styles.headerLogoContainer} ${className}`} data-testid={dataTestId}>
      <BrandLogo className={Styles.headerLogo} />
    </div>
  )
}
