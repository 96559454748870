import React, { useEffect, useMemo, useRef, useState } from 'react'

import { History } from 'history'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'

import { getterKeys, query, service, useQuery } from 'api'
import { PrismAlignToolIcon } from 'components/prismIcons'
import { TOOL_ICONS } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { BetaTag } from 'components/Tag/Tag'
import { useData, useImageElement } from 'hooks'
import { AlignmentAnchor, RecipeExpanded, RoutineWithAois, Tool, ToolSpecificationName } from 'types'
import { getAoisAndToolsFromRoutine, getUniqueName, isRecipeOrRoutineResponseProtected, ulidUuid } from 'utils'
import { TOOL_NAMES, TOOLS_DESCRIPTIONS } from 'utils/constants'

import Styles from './Configure.module.scss'
import ExistingTools from './ExistingTools'

type ToolModeTab = 'new' | 'existing'
interface Props {
  closeModal: () => any
  visible: boolean
  routine: RoutineWithAois
  recipe: RecipeExpanded
  onCreateTool: (toolId?: string, routineParentId?: string, recipeId?: string) => any
}

/**
 * Renders a modal that shows all supported tools a user can add to his routine.
 *
 * The object `supportedSpecifications` contains all abstraction and logic necessary to
 * add a new tool to the app.
 *
 * @param closeModal - Callback to close the modal.
 * @param visible - Whether the modal is open or not.
 * @param routine - The routine we are working on.
 * @param onCreateTool - Callback to transition into the just created
 *     tool screen.
 */
const ToolCreationModal = ({ closeModal, visible, routine, recipe, onCreateTool }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [addToolMode, setAddToolMode] = useState<ToolModeTab>('new')

  const modalRef = useRef<HTMLDivElement>(null)

  const toolSpecifications = useQuery(getterKeys.toolSpecifications(), service.getToolSpecifications).data?.data.results
  const org = useData(getterKeys.organization())

  useEffect(() => {
    setAddToolMode('new')
  }, [visible])

  // this image has already been fetched in the GoldenImage component, so this will be instantaneous
  const [imageElement] = useImageElement(routine.image)

  const sortedToolSpecifications = useMemo(() => {
    if (!toolSpecifications) return []
    const sortedDisplayNames = Object.entries(TOOL_SPECIFICATIONS_CREATION_CONFIG)
      .filter(([toolSpecName]) => org?.supported_tool_specs.includes(toolSpecName as ToolSpecificationName))
      .sort((supportedSpecificationAEntry, supportedSpecificationBEntry) => {
        const supportedSpecificationAValue = supportedSpecificationAEntry[1]
        const supportedSpecificationBValue = supportedSpecificationBEntry[1]
        // We assert non null here since we can only find the undefined case when indexing into supportedSpecifications[key], but when
        // we destructure it like Object.entries(supportedSpecifications) all elements will be defined.
        if (supportedSpecificationAValue!.displayName > supportedSpecificationBValue!.displayName) return 1
        return -1
      })

    // Now that we have sorted display names alphabetically, we need to sort the toolSpecifications by the same order, only way to link
    // these two lists is by the specification.name which is the keys on supportedSpecifications
    const sortedSpecifications = sortedDisplayNames.map(supportedSpecificationEntry =>
      toolSpecifications.find(specification => specification.name === supportedSpecificationEntry[0]),
    )
    return sortedSpecifications
  }, [org?.supported_tool_specs, toolSpecifications])

  return (
    <>
      {visible && (
        <Modal
          id="add-tool"
          modalBodyRef={modalRef}
          className={Styles.modalWrapper}
          modalBodyClassName={addToolMode === 'existing' ? Styles.existingModalBodyWrapper : ''}
          size="wide"
          showCancel={false}
          onClose={closeModal}
          header="Add Tool"
          modalNav={{
            selectedItems: [addToolMode],
            onSelect: tabMenu => setAddToolMode(tabMenu as ToolModeTab),
            modalNavClassName: 'Styles.addToolNav',
            items: [{ value: 'new' }, { value: 'existing' }],
          }}
          data-testid="tool-creation-modal"
        >
          {(!toolSpecifications || loading) && (
            <div className={Styles.spinModalContainer}>
              <div className={Styles.spinOnCenter}>
                <PrismLoader />
              </div>
            </div>
          )}

          {addToolMode === 'new' && (
            <div className={Styles.newModalBody}>
              {!loading &&
                sortedToolSpecifications.map(specification => {
                  if (!specification) return null
                  const helper = TOOL_SPECIFICATIONS_CREATION_CONFIG[specification.name]
                  if (!helper) return null
                  const disabledCheck = helper.disabled && helper.disabled(routine)

                  const { disabled, disabledText } = disabledCheck || { disabled: false }
                  return (
                    // If title is undefined tooltip doesn't render.
                    <PrismTooltip title={disabled ? disabledText : undefined} arrowPointAtCenter key={specification.id}>
                      <div
                        className={`${Styles.modalToolOption} ${disabled ? Styles.toolOptionDisabled : ''} ${
                          specification.name === 'random' ? Styles.randomTool : ''
                        }`}
                        onClick={async () => {
                          if (disabled) return
                          if (!imageElement) {
                            return error({ title: 'Take a reference photo to add this tool' })
                          }

                          setLoading(true)
                          await helper.onCreate(specification.id, {
                            routine,
                            recipe,
                            imageElement,
                            history,
                            dispatch,
                            desiredName: helper.displayName,
                            callback: (newTool, routine, recipe) => {
                              onCreateTool(newTool?.id, routine?.parent.id, recipe?.id)
                            },
                          })
                          closeModal()
                          setLoading(false)
                        }}
                        data-testid={`tool-creation-modal-${specification.name}`}
                      >
                        {specification.name === 'alignment' && (
                          <span className={Styles.iconColor}>
                            <PrismAlignToolIcon hasHover />
                          </span>
                        )}
                        {specification.name !== 'alignment' && <span className={Styles.iconColor}>{helper.icon}</span>}

                        <div className={Styles.modalToolOptionTitle}>{helper.displayName}</div>
                        <div className={Styles.modalToolOptionDescription}>
                          {TOOLS_DESCRIPTIONS[specification.name]}
                        </div>
                        {(specification.name === 'color-check' || specification.name === 'measurement') && (
                          <BetaTag className={Styles.modalToolTag} />
                        )}
                      </div>
                    </PrismTooltip>
                  )
                })}
            </div>
          )}
          {addToolMode === 'existing' && <ExistingTools modalRef={modalRef} routine={routine} recipe={recipe} />}
        </Modal>
      )}
    </>
  )
}

export default ToolCreationModal

const createAlignmentTool = async (
  specificationId: string,
  { routine, recipe, imageElement, history, dispatch, callback }: ToolCreationArgs,
) => {
  const initialAnchor: AlignmentAnchor = {
    x: Math.round(imageElement.width / 4),
    y: Math.round(imageElement.height / 4),
    width: Math.round(imageElement.width / 2),
    height: Math.round(imageElement.height / 2),
    id: ulidUuid(),
  }

  // All previously existing tools will point to this tool for previous thanks to the useEffect in RoutineOverview.
  const res = await service.createTool({
    // Alignment tool is special and needs to create a full image size aoi, as opposed to all other tools
    ...getAlignmentToolConfig({ imageElement, specificationId, name: 'Align', routineId: routine.id }),
    inference_args: { b_boxes: [initialAnchor] },
    inference_assets: routine.image,
    set_inference_assets_md5: true,
  })

  if (
    isRecipeOrRoutineResponseProtected(res, {
      routineParentId: routine.parent.id,
      recipe: recipe,
      history,
      onCreate: async (newRecipe, newRoutine) => {
        if (!newRoutine) return
        await createAlignmentTool(specificationId, {
          routine: newRoutine,
          recipe: newRecipe,
          imageElement,
          history,
          dispatch,
          callback,
        })
      },
    })
  )
    return

  if (res.type !== 'success') {
    callback()
    return somethingWentWrong()
  }

  const routineRes = await query(getterKeys.routine(routine.id), () => service.getRoutine(routine.id), {
    dispatch,
  })

  if (routineRes?.type !== 'success') {
    callback()
    return somethingWentWrong()
  }

  success({ title: 'Tool created', 'data-testid': 'tool-creation-modal-success' })
  // The tool res doesn't include tool.specification_name. Which is why we find it from the routine res
  callback(
    getAoisAndToolsFromRoutine(routineRes.data).tools.find(tool => tool?.id === res.data.id),
    routineRes.data,
    recipe,
  )
}

const disableAlignmentOption = (routine: RoutineWithAois) => {
  const alreadyPresent = getAoisAndToolsFromRoutine(routine).tools.some(
    tool => tool?.specification_name === 'alignment',
  )

  if (alreadyPresent) {
    return {
      disabled: true,
      disabledText: 'Align tool already exists',
    }
  }

  return { disabled: false, disabledText: '' }
}

const getBaseToolConfig = ({
  imageElement,
  specificationId,
  name,
  routineId,
}: {
  imageElement: HTMLImageElement
  specificationId: string
  name: string
  routineId: string
}) => {
  return {
    parent: { specification: specificationId, name },
    aoi: {
      x: Math.round(imageElement.width / 4),
      y: Math.round(imageElement.height / 4),
      width: Math.round(imageElement.width / 2),
      height: Math.round(imageElement.height / 2),
      routine: routineId,
      parent_name: 'Area',
      img_w: imageElement.width,
      img_h: imageElement.height,
    },
  }
}

const getAlignmentToolConfig = ({
  imageElement,
  specificationId,
  name,
  routineId,
}: {
  imageElement: HTMLImageElement
  specificationId: string
  name: string
  routineId: string
}) => {
  return {
    parent: { specification: specificationId, name },
    aoi: {
      x: 0,
      y: 0,
      width: imageElement.width,
      height: imageElement.height,
      routine: routineId,
      parent_name: 'Area',
      img_w: imageElement.width,
      img_h: imageElement.height,
    },
  }
}

function createToolFactory(specification: Exclude<ToolSpecificationName, 'alignment'>) {
  const toolName = TOOL_NAMES[specification]

  const createTool = async (
    specificationId: string,
    { routine, recipe, imageElement, history, dispatch, desiredName, callback }: ToolCreationArgs,
  ) => {
    const name = getUniqueToolName(desiredName || toolName, routine)

    const res = await service.createTool(
      getBaseToolConfig({ imageElement, specificationId, name, routineId: routine.id }),
    )

    if (
      isRecipeOrRoutineResponseProtected(res, {
        routineParentId: routine.parent.id,
        recipe: recipe,
        history,
        onCreate: async (newRecipe, newRoutine) => {
          if (!newRoutine) return
          await createTool(specificationId, {
            routine: newRoutine,
            recipe: newRecipe,
            imageElement,
            history,
            dispatch,
            desiredName,
            callback,
          })
        },
      })
    )
      return

    if (res.type !== 'success') {
      callback()
      return somethingWentWrong()
    }

    const routineRes = await query(getterKeys.routine(routine.id), () => service.getRoutine(routine.id), {
      dispatch,
    })

    if (routineRes?.type !== 'success') {
      callback()
      return somethingWentWrong()
    }

    // The tool res doesn't include tool.specification_name. Which is why we find it from the routine res
    const createdTool = getAoisAndToolsFromRoutine(routineRes.data).tools.find(tool => tool?.id === res.data.id)

    success({ title: 'Tool created', 'data-testid': `tool-creation-modal-${specification}-success` })
    callback(createdTool, routineRes.data, recipe)
  }
  return createTool
}

function getUniqueToolName(baseName: string, routine: RoutineWithAois) {
  const names = getAoisAndToolsFromRoutine(routine).tools.map(tool => tool.parent_name)

  return getUniqueName(baseName, names)
}

const somethingWentWrong = (text?: string) => {
  error({ title: text || 'An error occurred creating your tool, please try again' })
  return undefined
}

interface ToolCreationArgs {
  routine: RoutineWithAois
  recipe: RecipeExpanded
  imageElement: HTMLImageElement
  history: History
  dispatch: Dispatch
  desiredName?: string
  callback: (tool?: Tool, routine?: RoutineWithAois, recipe?: RecipeExpanded) => void
}

const createAnomalyTool = createToolFactory('deep-svdd')
const createGradedAnomalyTool = createToolFactory('graded-anomaly')
const createRandomTool = createToolFactory('random')
const createBarcodeTool = createToolFactory('detect-barcode')
const createPassFailTool = createToolFactory('classifier')
const createMatchTool = createToolFactory('match-classifier')
const createOcrTool = createToolFactory('ocr')
const createColorTool = createToolFactory('color-check')
const createMeasureTool = createToolFactory('measurement')

/**
 * This object controls the logic and abstractions for all supported tools.
 *
 * @param displayName - The name of the tool for the user.
 * @param description - The description of what the tool does.
 * @param onClick - Callback to trigger the creation of said tool. Every case is complex and different!
 * @param disabled - Optional. A callback to determine if the option should be disabled.
 */

const TOOL_SPECIFICATIONS_CREATION_CONFIG: {
  [K in ToolSpecificationName]: {
    displayName: string
    icon: React.ReactNode
    onCreate: (specificationId: string, args: ToolCreationArgs) => Promise<Tool | undefined>
    disabled?: (routine: RoutineWithAois) => { disabled: boolean; disabledText?: string }
  } | null
} = {
  alignment: {
    displayName: TOOL_NAMES.alignment,
    icon: TOOL_ICONS('alignment', true),
    onCreate: createAlignmentTool,
    disabled: disableAlignmentOption,
  },
  'detect-barcode': {
    displayName: TOOL_NAMES['detect-barcode'],
    icon: TOOL_ICONS('detect-barcode', true),
    onCreate: createBarcodeTool,
  },
  'deep-svdd': {
    displayName: TOOL_NAMES['deep-svdd'],
    icon: TOOL_ICONS('deep-svdd', true),
    onCreate: createAnomalyTool,
  },
  'graded-anomaly': {
    displayName: TOOL_NAMES['graded-anomaly'],
    icon: TOOL_ICONS('graded-anomaly', true),
    onCreate: createGradedAnomalyTool,
  },
  random: {
    displayName: TOOL_NAMES.random,
    icon: TOOL_ICONS('random', true),
    onCreate: createRandomTool,
  },
  classifier: {
    displayName: TOOL_NAMES.classifier,
    icon: TOOL_ICONS('classifier', true),
    onCreate: createPassFailTool,
  },
  'match-classifier': {
    displayName: TOOL_NAMES['match-classifier'],
    icon: TOOL_ICONS('match-classifier', true),
    onCreate: createMatchTool,
  },
  ocr: {
    displayName: TOOL_NAMES.ocr,
    icon: TOOL_ICONS('ocr', true),
    onCreate: createOcrTool,
  },
  'color-check': {
    displayName: TOOL_NAMES['color-check'],
    icon: TOOL_ICONS('color-check', true),
    onCreate: createColorTool,
  },
  measurement: {
    displayName: TOOL_NAMES.measurement,
    icon: TOOL_ICONS('measurement', true),
    onCreate: createMeasureTool,
  },
} as const
