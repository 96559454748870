import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Zoom In Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=3318%3A385
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param className - String for external styles.
 */
export const PrismZoomInIcon = ({ hasBackground, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${hasBackground ? Styles.hasBackground : ''} ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3318_347">
        <path
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0271 17.2276C14.4515 17.2276 17.2276 14.4515 17.2276 11.0271C17.2276 7.60275 14.4515 4.82672 11.0271 4.82672C7.60275 4.82672 4.82672 7.60275 4.82672 11.0271C4.82672 14.4515 7.60275 17.2276 11.0271 17.2276ZM11.0271 18.0543C14.9081 18.0543 18.0543 14.9081 18.0543 11.0271C18.0543 7.14616 14.9081 4 11.0271 4C7.14616 4 4 7.14616 4 11.0271C4 14.9081 7.14616 18.0543 11.0271 18.0543Z"
        />
      </mask>
      <path
        d="M16.2276 11.0271C16.2276 13.8993 13.8993 16.2276 11.0271 16.2276V18.2276C15.0038 18.2276 18.2276 15.0038 18.2276 11.0271H16.2276ZM11.0271 5.82672C13.8993 5.82672 16.2276 8.15503 16.2276 11.0271H18.2276C18.2276 7.05046 15.0038 3.82672 11.0271 3.82672V5.82672ZM5.82672 11.0271C5.82672 8.15503 8.15503 5.82672 11.0271 5.82672V3.82672C7.05046 3.82672 3.82672 7.05046 3.82672 11.0271H5.82672ZM11.0271 16.2276C8.15503 16.2276 5.82672 13.8993 5.82672 11.0271H3.82672C3.82672 15.0038 7.05046 18.2276 11.0271 18.2276V16.2276ZM17.0543 11.0271C17.0543 14.3558 14.3558 17.0543 11.0271 17.0543V19.0543C15.4604 19.0543 19.0543 15.4604 19.0543 11.0271H17.0543ZM11.0271 5C14.3558 5 17.0543 7.69845 17.0543 11.0271H19.0543C19.0543 6.59388 15.4604 3 11.0271 3V5ZM5 11.0271C5 7.69845 7.69845 5 11.0271 5V3C6.59388 3 3 6.59388 3 11.0271H5ZM11.0271 17.0543C7.69845 17.0543 5 14.3558 5 11.0271H3C3 15.4604 6.59388 19.0543 11.0271 19.0543V17.0543Z"
        mask="url(#path-1-inside-1_3318_347)"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.334 11.0271C14.334 11.2554 14.1489 11.4405 13.9206 11.4405H8.13358C7.90528 11.4405 7.72021 11.2554 7.72021 11.0271C7.72021 10.7988 7.90528 10.6137 8.13358 10.6137H13.9206C14.1489 10.6137 14.334 10.7988 14.334 11.0271Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8665 15.2818L19.7078 19.1231C19.8692 19.2846 19.8692 19.5463 19.7078 19.7077C19.5464 19.8691 19.2847 19.8691 19.1232 19.7077L15.2819 15.8664L15.8665 15.2818Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0271 7.7202C11.2554 7.7202 11.4405 7.90527 11.4405 8.13356V13.9206C11.4405 14.1489 11.2554 14.334 11.0271 14.334C10.7988 14.334 10.6138 14.1489 10.6138 13.9206V8.13356C10.6138 7.90527 10.7988 7.7202 11.0271 7.7202Z"
      />
    </svg>
  )
}
