import React, { memo, ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismArrowIcon, PrismCloseIcon } from 'components/prismIcons'
import { ModalContext, useModalsSync } from 'components/PrismModal/PrismModal'

import Styles from './FullScreen.module.scss'

type ArrowProps = {
  onClick: () => void
  isVisible: boolean
}

interface Props {
  className?: string
  onClose: () => any
  id: string
  arrows?: {
    left: ArrowProps
    right: ArrowProps
  }
  header?: {
    headerLeft?: React.ReactNode
    headerLeftClassName?: string
    headerRightButtons?: {
      closeButton: {
        isVisible: boolean
        onClick: () => void
      }
      extraButtons?: React.ReactNode
    }
    headerRightClassName?: string
  }
  footer?: React.ReactNode
}

/**
 * Renders a React component at the center of the viewport against a black
 * background, mainly for displaying images at their full size.
 * @param onClose - handler for closing the fullscreen modal
 * @param onClose - Function called when clicking on the closing icon
 * @param arrows - allow the user pass a Callback function to each arrow, and user can decide when to show/hide
 * @param header - Create container with position on the header
 * @param headerLeft - Reserved for the main title, positioned on the left top , can receive styles by using headerLeftClassnName
 * @param headerRightButtons - can display a close icon button . extraButtons reserves some space for elements on the left side of the close icon button. The main container can receive styles by using headerRightClassName.
 * @param footer - adds an space on the footer of the component
 */
function FullScreen({ className = '', onClose, children, id, arrows, header, footer }: React.PropsWithChildren<Props>) {
  const portalContainerRef = useRef<HTMLDivElement>(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(portalContainerRef.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      document.body.removeChild(portalContainerRef.current)
    }
  }, [])

  useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [onClose])

  useModalsSync(id)

  if (!children) return null

  // Create a portal with react so that this component gets rendered at the root of the DOM.
  // This is important due to some issues regarding css stacking context.
  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ id }}>
      <div className={`${Styles.fullScreen} ${className}`}>
        {children}
        {header && header.headerLeft && (
          <header className={`${Styles.mainTitle} ${header.headerLeftClassName ?? ''}`}>{header.headerLeft}</header>
        )}
        {header && header.headerRightButtons && (
          <div className={`${Styles.headerRightButtons} ${header.headerRightClassName ?? ''}`}>
            {header.headerRightButtons.extraButtons}
            {header.headerRightButtons.closeButton && (
              <IconButton
                data-testid="full-screen-close"
                className={Styles.closeButton}
                icon={<PrismCloseIcon />}
                size="small"
                type="tertiary"
                isOnTop
                onClick={header.headerRightButtons.closeButton.onClick}
              />
            )}
          </div>
        )}
        {arrows?.right.isVisible && <FullScreenArrow direction="right" onClick={arrows.right.onClick} />}
        {arrows?.left.isVisible && <FullScreenArrow direction="left" onClick={arrows.left.onClick} />}
        {footer && <FullScreenFooter className={Styles.footer}>{footer}</FullScreenFooter>}
      </div>
    </ModalContext.Provider>,
    portalContainerRef.current,
  )
}

export default memo(FullScreen)

/**
 * Renders a header with a close icon in the Fullscreen component, plus any optional content passed in via props
 * @param onCloseClick - Function called when clicking on the closing icon
 * @param leftSide - Optional node to render alongside the close button on the left side of the header, if not passed, a default Close button appears
 * @param rightSide - Optional node to render on the right side of the header
 */
export function FullScreenHeader({
  onCloseClick,
  rightSide,
  leftSide,
}: {
  onCloseClick: () => any
  leftSide?: ReactNode
  rightSide?: ReactNode
}) {
  return (
    <div className={`${Styles.headerBar} ${rightSide ? Styles.leftAndRightHeader : ''}`}>
      <div className={`${Styles.leftContainer} ${Styles.headerSection}`}>{leftSide}</div>
      <div className={`${Styles.rightContainer} ${Styles.headerSection}`}>{rightSide}</div>
      <IconButton
        data-testid="full-screen-close"
        isOnTop
        onClick={onCloseClick}
        icon={<PrismCloseIcon />}
        size="small"
        type="secondary"
        className={Styles.closeContainer}
      />
    </div>
  )
}

export const FullScreenFooter = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
  <div className={`${Styles.fullscreenFooter} ${className}`}>{children}</div>
)

export const FullScreenImage = ({ src, addBorder }: { src: React.ReactNode; addBorder: Boolean }) => {
  return <figure className={`${Styles.fullScreenVideo} ${addBorder ? Styles.addBorder : ''}`}>{src}</figure>
}

const FullScreenArrow = ({ direction, onClick }: { direction: 'left' | 'right'; onClick: () => void }) => {
  return (
    <IconButton
      isOnTop
      type="secondary"
      icon={<PrismArrowIcon direction={direction} />}
      onClick={onClick}
      className={`${Styles.arrowContainer} ${Styles[direction]}`}
      hotkey={direction === 'left' ? 'ArrowLeft' : 'ArrowRight'}
      data-testid={`fullscree-arrow-${direction}`}
    />
  )
}
