import React, { useEffect } from 'react'

import moment from 'moment-timezone'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { getterKeys, service } from 'api'
import { Button } from 'components/Button/Button'
import LeavePagePrompt from 'components/LeavePagePrompt/LeavePagePrompt'
import { PrismContainer } from 'components/PrismContainer/PrismContainer'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import { useData } from 'hooks'
import * as Actions from 'rdx/actions'
import Shared from 'styles/Shared.module.scss'
import { Me, UserTimeFormats } from 'types'
import { getterUpdateData, getTimeFormat, getTimezoneDetails } from 'utils'
import TIMEZONES, { TIMEZONE_SELECT_OPTIONS } from 'utils/timezones'

import Styles from './Settings.module.scss'

const DateTimeForm = () => {
  const dispatch = useDispatch()

  const me = useData(getterKeys.me())
  const defaultValues = getDefaultValues(me)
  const {
    formState: { isDirty, errors },
    control,
    reset,
    trigger,
    getValues,
    watch,
  } = useForm({ defaultValues, mode: 'onChange' })

  useEffect(() => {
    if (!me) return
    reset(getDefaultValues(me))
  }, [me, reset])

  const handleSubmit = async () => {
    const valid = await trigger()
    if (!valid) return

    const { timezone, timeFormat, dateFormat } = getValues()

    const res = await service.updateMe({ timezone, time_format: timeFormat, date_format: dateFormat })
    if (res.type === 'success') {
      dispatch(Actions.getterUpdate({ key: 'me', updater: prevRes => getterUpdateData(prevRes, res.data) }))
      success({ title: 'Information updated', 'data-testid': 'datetime-update-success' })
    } else {
      error({ title: 'There was a problem updating your date & time preferences' })
    }
  }

  const disableButton = !isDirty || Object.keys(errors).length > 0

  const { timeFormat: currentTimeFormat } = watch(['timeFormat'])

  return (
    <>
      <LeavePagePrompt when={isDirty} />

      <PrismContainer
        headerElementsAlign="vertical"
        title={'Date & Time'}
        className={Shared.rightSectionContainer}
        bodyClassName={Styles.bodyContainer}
        actions={
          <Button
            htmlType="submit"
            disabled={disableButton}
            onClick={handleSubmit}
            size="small"
            data-testid="datetime-save-button"
          >
            Save
          </Button>
        }
      >
        <div className={Styles.formWrapper}>
          <div className={Shared.verticalChildrenGap32}>
            <div className={Shared.verticalChildrenGap8}>
              <div className={`${Styles.formLabel} ${Styles.labelAndSelector}`}>Timezone</div>

              <Controller
                name="timezone"
                control={control}
                render={props => (
                  <>
                    <PrismSelect
                      size="large"
                      {...props}
                      showSearch
                      optionFilterProp="title"
                      data-testid="datetime-timezone"
                      data-test-attribute={TIMEZONES.length.toString()}
                      options={[
                        {
                          value: '',
                          title: 'sync with device',
                          dataTestId: 'datetime-timezone-sync-with-device',
                          dataTest: 'datetime-timezone-option',
                        },
                        ...TIMEZONE_SELECT_OPTIONS.map(timezone => ({
                          value: timezone.value,
                          key: timezone.value,
                          title: timezone.label,
                          dataTestId: `datetime-timezone-option-${timezone.index}`,
                          dataTest: 'datetime-timezone-option',
                        })),
                      ]}
                    />

                    {!props.value && (
                      <div className={Styles.timeCaption}>
                        {getTimezoneCopy({ timezone: props.value, timeFormat: currentTimeFormat })}
                      </div>
                    )}

                    {props.value && (
                      <div className={Styles.timeCaption}>{getCurrentTimezoneTime(props.value, currentTimeFormat)}</div>
                    )}
                  </>
                )}
              />
            </div>
            <div className={Shared.verticalChildrenGap8}>
              <div className={`${Styles.formLabel} ${Styles.labelAndSelector}`}>Date Format</div>

              <Controller
                name="dateFormat"
                control={control}
                render={props => (
                  <PrismSelect
                    size="large"
                    {...props}
                    data-testid="date-format-timezone"
                    options={[
                      { value: 'm/d/y', title: '12/31/2021' },
                      { value: 'd/m/y', title: '31/12/2021' },
                    ]}
                  />
                )}
              />
            </div>
            <div className={Shared.verticalChildrenGap8}>
              <div className={`${Styles.formLabel} ${Styles.labelAndSelector}`}>Time Format</div>

              <Controller
                name="timeFormat"
                control={control}
                render={props => (
                  <PrismSelect
                    size="large"
                    {...props}
                    data-testid="time-format-timezone"
                    options={[
                      { value: '12hr', title: '1:00pm' },
                      { value: '24hr', title: '13:00' },
                    ]}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </PrismContainer>
    </>
  )
}

const getTimezoneCopy = ({
  timezone,
  timeFormat,
}: {
  timezone?: string
  dateFormat?: string
  timeFormat: '12hr' | '24hr'
}) => {
  const currentTimeZone = timezone || moment.tz.guess()
  const { gmtOffset, abbr } = getTimezoneDetails(currentTimeZone)
  return `Your device is on GMT${gmtOffset} (${abbr}) ${currentTimeZone} and currently reads ${getCurrentTimezoneTime(
    currentTimeZone,
    timeFormat,
  )}`
}

const getCurrentTimezoneTime = (timeZone: string, timeFormat: UserTimeFormats) => {
  return moment()
    .tz(timeZone)
    .format(`${getTimeFormat(timeFormat)}, MMM DD, YYYY`)
}

const getDefaultValues = (me: Me | undefined) => {
  return {
    timezone: me?.timezone || '',
    dateFormat: me?.date_format || 'm/d/y',
    timeFormat: me?.time_format || '24hr',
  }
}

export default DateTimeForm
