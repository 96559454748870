import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Success Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1405%3A139
 *
 * @param hasBackground - adds a background color around the icon when hover and active. Useful when the icon is not inside an IconButton or Button but we need to show the states.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismSuccessIcon = ({ hasBackground, className, isActive }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.default} ${Styles.successIcon} ${isActive ? Styles.active : ''} ${
        hasBackground ? Styles.hasBackground : ''
      } ${className ?? ''} `}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        id="Check Mark"
        d="M16.5 8.5L10.5 15.5L8 13"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector"
        d="M12 3C7.02995 3 3 7.02995 3 12C3 16.9701 7.02995 21 12 21C16.9701 21 21 16.9701 21 12C21 7.02995 16.9701 3 12 3Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
