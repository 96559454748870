import React from 'react'

import { Tooltip } from 'antd'

import { Button } from 'components/Button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper/ConditionalWrapper'
import { TagType } from 'types'

import Styles from './PrismToggleGroup.module.scss'

const sizeClasses = {
  small: Styles.small,
  medium: Styles.medium,
  auto: Styles.auto,
}

const typeClasses: { [tag in TagType]?: string | undefined } = {
  success: Styles.prismToggleOption__pass,
  danger: Styles.prismToggleOption__fail,
  unknown: Styles.prismToggleOption__unknown,
  default: Styles.prismToggleOption__default,
}
interface ToggleGroupOption<T extends string> {
  label?: React.ReactNode
  value: T
  type?: TagType
  icon?: React.ReactNode
  tooltipTitle?: string
  disabled?: boolean
  dataTestId?: string
  dataAllowedHotkeys?: string
}

interface ToggleGroupProps<T extends string> {
  options: ToggleGroupOption<T>[]
  onChange?: (value: T) => void
  value?: T
  className?: string
  optionClassName?: string
  disabled?: boolean
  isOnTop?: boolean
  'data-testid'?: string
  size?: 'small' | 'medium' | 'auto'
}

/**
 * Renders custom Toggle Group component
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6577%3A12034
 *
 * @param options - handle the data inside an object, where label: displays the name/label of the button. type: shows different colors for the activated item, the default value is "defaul" which renders a blue border and blue background. value: Used for setting the currently selected value.
 * @param onChange - The callback function that is triggered when the state changes
 * @param value - Used for setting the currently selected value
 * @param className - string class that will be passed down to the element
 * @param disabled - it prevents button from being clicked
 * @param isOnTop - whether the toggle group is on top of another container
 */
export function PrismToggleGroup<T extends string>({
  options,
  onChange,
  value,
  className,
  optionClassName,
  disabled,
  isOnTop,
  'data-testid': dataTestId,
  size = 'small',
}: ToggleGroupProps<T>) {
  if (!options?.length) return null

  const sizeClass = sizeClasses[size]

  return (
    <div className={`${Styles.prismToggleWrapper} ${sizeClass}  ${className ?? ''} `} data-testid={dataTestId}>
      {options.map(option => {
        return (
          <PrismToggleOption
            data-testid={option.dataTestId}
            type={option.type}
            onClick={() => {
              if (!disabled && onChange) {
                onChange(option.value)
              }
            }}
            active={option.value === value}
            disabled={disabled || option.disabled}
            isOnTop={isOnTop}
            key={option.value}
            optionClassName={optionClassName}
            hasIcon={!!option.icon}
            tooltipTitle={option.tooltipTitle}
          >
            {option.label || option.icon}
          </PrismToggleOption>
        )
      })}
    </div>
  )
}

interface ToggleOptionProp {
  children: string | React.ReactNode
  type?: TagType
  disabled?: boolean
  active?: boolean
  isOnTop?: boolean
  onClick?: () => void
  optionClassName?: string
  'data-testid'?: string
  hasIcon?: boolean
  tooltipTitle?: string
  'data-allowedhotkeys'?: string
}

/**
 * Renders a toggle option
 *
 * @param type - tag type
 * @param disabled - if the toggle option is disabled
 * @param active - if the toggle option is active
 * @param isOnTop - if the toggle group is on top of another element
 * @param onClick - option click handler
 * @param optionClassName - button className
 * @param tooltipTitle - adds a tooltip
 * @param hasIcon - adds special styles if icon is used instead of text
 */
const PrismToggleOption = ({
  children,
  type = 'default',
  disabled,
  active,
  isOnTop,
  onClick,
  optionClassName = '',
  'data-testid': dataTestId,
  hasIcon,
  tooltipTitle,
  'data-allowedhotkeys': dataAllowedHotkeys,
}: ToggleOptionProp) => {
  const typeClass = typeClasses[type]

  return (
    <ConditionalWrapper
      condition={!!tooltipTitle}
      wrapper={ch => (
        <Tooltip title={tooltipTitle} placement="bottom" overlayClassName={Styles.tooltip}>
          <div>{ch}</div>
        </Tooltip>
      )}
    >
      <Button
        type="default"
        onClick={onClick}
        disabled={disabled}
        data-testid={dataTestId}
        data-allowedhotkeys={dataAllowedHotkeys}
        className={`${Styles.prismToggleOption}  ${typeClass ?? ''} ${isOnTop ? Styles.isOnTop : ''}
       ${active ? Styles.active : ''} ${disabled ? Styles.disabled : ''} ${optionClassName}
       ${hasIcon ? Styles.iconContainer : ''}
       `}
      >
        {children}
      </Button>
    </ConditionalWrapper>
  )
}
