import React, { useEffect, useMemo, useState } from 'react'

import { Tag } from 'antd'
import { useDispatch } from 'react-redux'

import { getterKeys, service, useQuery } from 'api'
import ImgFallback from 'components/Img/ImgFallback'
import { PrismElementaryCube } from 'components/prismIcons'
import { modal } from 'components/PrismModal/PrismModal'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { PrismSelect } from 'components/PrismSelect/PrismSelect'
import ToolSettingsModalTemplate, {
  ToolSettingsModalProps,
} from 'components/ToolSettingsModalTemplate/ToolSettingsModalTemplate'
import { useAllToolLabels } from 'hooks'
import { addExistingLabelsToToolAndUpdate } from 'pages/RoutineOverview/LabelingScreen/AddLabelModal'
import { Tool } from 'types'
import { getDisplaySeverity, getLabelName, getToolLabelImagesToShow, pluralize, titleCase } from 'utils'

import Styles from './MatchToolSettingsModal.module.scss'

interface Props extends Omit<ToolSettingsModalProps, 'inferenceUserArg' | 'children'> {}
/**
 * Renders a modal that allows the user to change the expected labels of a match tool.
 *
 */
const MatchToolSettingsModal = (props: Props) => {
  const { tool } = props
  const dispatch = useDispatch()

  const [expectedClasses, setExpectedClasses] = useState<string[]>([])

  const { allToolLabels } = useAllToolLabels()

  // We don't refetch here because this component renders twice on the same screen, and because the only other way to have
  // fetched this is through the routine creation flow, where this key is updated on every label change or creation,
  // so it'll always be up to date
  const labels = useQuery(getterKeys.toolLabels(tool.id), () =>
    service.getToolLabels({ tool_parent_id: tool.parent_id }),
  ).data?.data.results

  useEffect(() => {
    const expectedClasses: string[] = tool.inference_user_args?.expected_classes || []
    setExpectedClasses(expectedClasses)
  }, [tool])

  const handleBeforeSave = async (saveCallback: () => void | Promise<void>) => {
    if (!allToolLabels) return await saveCallback()
    const toolExpectedClasses: string[] = tool.inference_user_args?.expected_classes || []
    const newExpectedClasses = expectedClasses.filter(expectedClass => !toolExpectedClasses.includes(expectedClass))

    const newLabels = allToolLabels.filter(lbl => newExpectedClasses.includes(lbl.id))
    const labelsArchived = newLabels.filter(lbl => lbl.is_deleted)

    if (labelsArchived.length) {
      modal.confirm({
        id: 'unarchive-label-confirmation',
        header: 'Are you sure?',
        content: `Unarchive the ${pluralize({ wordCount: labelsArchived.length, word: 'label' })}
        "${labelsArchived.map(lbl => titleCase(lbl.value)).join('", "')}"?
        This will restore ${labelsArchived.length > 1 ? 'them' : 'it'} as an option for labeling`,
        okText: 'Unarchive',
        onClose: async () => {
          // We still want to save the labels, but only the unarchived ones in this case
          const unarchivedLabels = newLabels.filter(lbl => !lbl.is_deleted)
          await addExistingLabelsToToolAndUpdate({
            toolLabels: unarchivedLabels,
            currentToolLabels: labels,
            dispatch,
            toolParentId: tool.parent_id,
          })
        },
        onOk: async close => {
          // The function addExistingLabelsToToolAndUpdate contains functionality to unarchive tools as well
          await addExistingLabelsToToolAndUpdate({
            toolLabels: newLabels,
            currentToolLabels: labels,
            toolParentId: tool.parent_id,
            dispatch,
          })
          await saveCallback()
          close()
        },
      })
    } else {
      await addExistingLabelsToToolAndUpdate({
        toolLabels: newLabels,
        currentToolLabels: labels,
        toolParentId: tool.parent_id,
        dispatch,
      })
      // Add label id to expected classes
      await saveCallback()
    }
  }

  return (
    <ToolSettingsModalTemplate
      inferenceUserArg={{
        key: 'expected_classes',
        value: expectedClasses,
      }}
      {...props}
      title="Edit Expected Label"
      onBeforeSave={handleBeforeSave}
    >
      <div className={Styles.title}>Expected Label</div>

      <div className={Styles.specificMatchContainer}>
        <ExpectedLabelsSelect expectedClasses={expectedClasses} tool={tool} setExpectedClasses={setExpectedClasses} />

        <div className={Styles.specificMatchHint}>
          {tool.state === 'successful'
            ? 'The tool will Pass if one of the above labels is found. If none of the above are found, it will Fail.'
            : 'You can set the expected label for this tool after you have trained it.'}
        </div>
      </div>
    </ToolSettingsModalTemplate>
  )
}

export default MatchToolSettingsModal

const ExpectedLabelsSelect = ({
  tool,
  expectedClasses,
  setExpectedClasses,
}: {
  tool: Tool
  expectedClasses: string[]
  setExpectedClasses: (expectedClasses: string[]) => void
}) => {
  const { allToolLabels } = useAllToolLabels()

  const trainedLabels = useMemo(
    () => allToolLabels?.filter(label => tool.metadata.classes?.includes(label.id)),
    [allToolLabels, tool.metadata.classes],
  )

  return (
    <PrismSelect
      value={expectedClasses}
      onChange={(e: string[] | string) => {
        let expectedLabels = e
        if (!Array.isArray(expectedLabels)) expectedLabels = [expectedLabels]
        if (expectedLabels.includes('')) expectedLabels = []

        setExpectedClasses(expectedLabels)
      }}
      mode="multiple"
      size="large"
      disabled={!trainedLabels || tool.state !== 'successful'}
      loading={!trainedLabels}
      multiClassName={Styles.multiTagContainer}
      tagRender={({ closable, onClose, value }) => {
        const foundLabel = allToolLabels?.find(label => label.id === value)
        if (!foundLabel) return <span>--</span>
        return (
          <Tag closable={closable} onClose={onClose} className={Styles.labelTag}>
            <PrismResultButton value={getLabelName(foundLabel)} severity="neutral" />
          </Tag>
        )
      }}
      optionFilterProp="filterName"
      data-testid="match-tool-settings-modal-input"
      showSelectedOptionCheck
      options={trainedLabels?.map(label => {
        const image = getToolLabelImagesToShow(label)[0]
        const labelName = getLabelName(label)
        return {
          key: label.id,
          value: label.id,
          filterName: labelName,
          dataTestId: `match-tool-settings-modal-input-option-${label.value.toLowerCase()}`,
          className: Styles.selectItemList,
          content: (
            <PrismResultButton
              severity={getDisplaySeverity(label)}
              value={labelName}
              type="noFill"
              className={Styles.selectItemText}
              size="small"
            />
          ),
          image: {
            content: image ? (
              <ImgFallback src={image} className={Styles.labelFormImage} loaderType="skeleton" />
            ) : (
              <PrismElementaryCube />
            ),
          },
        }
      })}
    />
  )
}
