import React, { useEffect, useMemo } from 'react'

import { useHistory } from 'react-router-dom'

import { getterKeys, service, useQuery } from 'api'
import { Button } from 'components/Button/Button'
import FullScreen, { FullScreenImage } from 'components/FullScreen/FullScreen'
import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import { ToggleButton } from 'components/ToggleButton/ToggleButton'
import { useDefaultToolLabels, useQueryParams, useToolLabels } from 'hooks'
import paths from 'paths'
import { Threshold, Tool, TrainingResult, TrainingResultFlat } from 'types'
import {
  appendDataToQueryString,
  calculateTrainingResultPrediction,
  extractLabelIdFromCalculatedLabels,
  getDisplaySeverity,
  getDisplayThreshold,
  getLabelName,
} from 'utils'

import Styles from './FullScreenTrainingReport.module.scss'
import { ThresholdByRoutineParentId } from './TrainingReport'

type Props = {
  trainingResultById?: { [id: string]: TrainingResult }
  trainingResultsFlat?: TrainingResultFlat[]
  tool: Tool | undefined
  threshold: Threshold | undefined
  thresholdByRoutine?: ThresholdByRoutineParentId
  insightsEnabled?: boolean
  onFetchMoreTrainingResults?: (trainingResults: TrainingResultFlat[]) => void
}

const FETCH_ADJACENT_OFFSET = 3

export const FullScreenTrainingResult = ({
  trainingResultById,
  trainingResultsFlat,
  tool,
  threshold,
  thresholdByRoutine,
  insightsEnabled,
  onFetchMoreTrainingResults,
}: Props) => {
  const history = useHistory()
  const defaultLabels = useDefaultToolLabels()
  const [params] = useQueryParams<'training_result_id'>()
  const toolLabels = useToolLabels(tool ? [tool] : undefined)

  const { training_result_id } = params
  const shouldFetch = training_result_id && !trainingResultById?.[training_result_id]
  const fetchedTrainingResult = useQuery(
    shouldFetch ? getterKeys.fullScreenTrainingResultsExpanded() : undefined,
    shouldFetch ? () => service.getToolTrainingResultsByIds([params.training_result_id!]) : undefined,
  ).data?.data.results[0]

  const trainingResult = useMemo(() => {
    if (!training_result_id) return
    return fetchedTrainingResult || trainingResultById?.[training_result_id]
  }, [fetchedTrainingResult, training_result_id, trainingResultById])

  const { nextElementId, prevElementId, trainingResultIdx } = useMemo(() => {
    if (!trainingResultsFlat || !trainingResult) return {}
    const trainingResultIdx = trainingResultsFlat.findIndex(tr => tr.id === trainingResult.id)
    let nextElementId: string | undefined = undefined
    let prevElementId: string | undefined = undefined

    if (trainingResultIdx !== -1 && trainingResultIdx !== trainingResultsFlat.length - 1) {
      nextElementId = trainingResultsFlat[trainingResultIdx + 1]?.id
    }
    if (trainingResultIdx > 0) prevElementId = trainingResultsFlat[trainingResultIdx - 1]?.id
    return { nextElementId, prevElementId, trainingResultIdx }
  }, [trainingResult, trainingResultsFlat])

  // We have two different shapes for training results: Flattened and expanded. Since the flattened results
  // include the full list to use, but the expanded list contains only a subset, this effect calculates
  // if we must fetch more results of the expanded type according to the new list of prefetched elements.
  useEffect(() => {
    if (!trainingResultsFlat || !onFetchMoreTrainingResults || !trainingResultIdx) return

    if (trainingResultIdx > -1) {
      const newTrainingResults = []
      for (let idx = trainingResultIdx; idx < trainingResultIdx + FETCH_ADJACENT_OFFSET; idx++) {
        if (trainingResultsFlat[idx]) newTrainingResults.push(trainingResultsFlat[idx]!)
      }

      onFetchMoreTrainingResults(newTrainingResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingResultIdx])

  const calculatedLabelId = extractLabelIdFromCalculatedLabels(
    toolLabels || [],
    trainingResult?.calculated_labels || [],
  )
  const calculatedLabel = toolLabels?.find(label => label.id === calculatedLabelId)

  const { predictedLabel } = useMemo(() => {
    if (!trainingResult || !tool || !threshold || !toolLabels || !defaultLabels) return { predictedLabel: undefined }

    return calculateTrainingResultPrediction({
      trainingResult,
      specName: tool?.specification_name,
      threshold,
      defaultLabels,
      allToolLabels: toolLabels,
      thresholdByRoutine,
    })
  }, [defaultLabels, threshold, thresholdByRoutine, tool, toolLabels, trainingResult])

  function setInsightsEnabled(insightsEnabled: boolean) {
    appendDataToQueryString(history, { insights_on: insightsEnabled || undefined })
  }

  const renderHeaderTitle = () => {
    return (
      <div className={Styles.titleContainer}>
        {calculatedLabel && (
          <div className={Styles.labeled}>
            Labeled
            <PrismResultButton
              size="small"
              type="noFill"
              className={Styles.labeledResult}
              value={getLabelName(calculatedLabel)}
              severity={getDisplaySeverity(calculatedLabel)}
              data-testid="fullscreen-training-result-label"
            />
          </div>
        )}
        {predictedLabel && tool && (
          <div className={Styles.predicted}>
            Predicted
            <PrismResultButton
              size="small"
              type="noFill"
              className={Styles.predictedResult}
              value={
                tool?.specification_name === 'match-classifier' ? getLabelName(predictedLabel) : predictedLabel.severity
              }
              severity={getDisplaySeverity(predictedLabel)}
              data-testid="fullscreen-training-result-prediction"
            />
            , Scoring {getDisplayThreshold(trainingResult?.prediction_score, tool?.specification_name)}
          </div>
        )}
      </div>
    )
  }

  function handleOnClose() {
    appendDataToQueryString(history, {
      training_result_id: undefined,
    })
  }
  if (!trainingResult) return

  return (
    <FullScreen
      onClose={handleOnClose}
      id={trainingResult.id}
      arrows={{
        left: {
          isVisible: !!prevElementId,
          onClick: () => appendDataToQueryString(history, { training_result_id: prevElementId }),
        },
        right: {
          isVisible: !!nextElementId,
          onClick: () => appendDataToQueryString(history, { training_result_id: nextElementId }),
        },
      }}
      header={{
        headerLeftClassName: Styles.headerLeft,
        headerRightClassName: Styles.headerRight,
        headerLeft: renderHeaderTitle(),
        headerRightButtons: {
          closeButton: { isVisible: true, onClick: handleOnClose },
          extraButtons: (
            <Button
              type="tertiary"
              size="small"
              isOnTop
              onClick={() => {
                history.push(
                  paths.labelingScreen(trainingResult.tool_result.tool?.parent_id || '', 'focus', {
                    tool_result_id: trainingResult.tool_result.id,
                  }),
                )
              }}
              disabled={!trainingResult.tool_result.tool?.parent_id}
              data-testid="fullscreen-training-result-change-label-button"
            >
              Change Label
            </Button>
          ),
        },
      }}
      footer={
        <ToggleButton
          onClick={() => {
            setInsightsEnabled(!insightsEnabled)
          }}
          isOnTop
          title="Insights"
          hotkey="I"
          active={insightsEnabled}
          className={Styles.toggleButton}
          tooltip={!trainingResult?.insight_image ? 'Insights are unavailable' : undefined}
          disabled={!trainingResult?.insight_image}
        />
      }
    >
      <FullScreenImage
        addBorder
        src={
          trainingResult.tool_result.aoi && (
            <ImageCloseUp
              loaderType="skeleton"
              src={trainingResult.tool_result.picture.image || trainingResult.tool_result.picture.image_thumbnail}
              region={trainingResult.tool_result.aoi}
              overlaySrc={insightsEnabled ? trainingResult.insight_image : undefined}
            />
          )
        }
      />
    </FullScreen>
  )
}

export default FullScreenTrainingResult
