import React from 'react'

import { FixedSizeGrid, GridChildComponentProps, GridOnScrollProps } from 'react-window'

import { useContainerDimensions, useGridDimensions } from 'hooks'
import { Threshold, Tool, ToolLabel, TrainingResult, TrainingResultFlat } from 'types'

import { CardItem } from './CardItem'
import { ThresholdByRoutineParentId } from './TrainingReport'
import Styles from './TrainingReport.module.scss'

/**
 * This is a wrapper around FixedSizeGrid that displays a grid of training results
 *
 * @param trainingResultsFlat - list of training results obtained for the current label
 * @param trainingResultsExpanded - list of loaded training results with full data. We're only loading
 * chunks of 100
 * @param threshold - Threshold saved or that's being adjusted
 * @param modalLoaded - Whether the modal has loaded
 * @param toolLabels - Lsit of labels existing in the current tool
 * @param gridRef - The React ref for the grid
 * @param galleryBodyRef - The React ref for the gallery body
 * @param onAfterUpdate - Handler for when the expanded grid loads a new list of items into the DOM
 * @param tool - The Tool related to the current training result
 * @param outerRef - The React ref for the outer container, used to handle the scroll bar
 * @param style - Styles provided by ReactWindowElementScroller
 * @param onScroll - function provided by ReactWindowElementScroller
 * @param thresholdByRoutine - Object including current threshold by routine,
 * used to calculate predictions for overriden threholds
 */
const ExpandedLabelGrid = ({
  trainingResultsFlat,
  trainingResultsExpanded,
  threshold,
  modalLoaded,
  toolLabels,
  gridRef,
  galleryBodyRef,
  onAfterUpdate,
  outerRef,
  style,
  onScroll,
  tool,
  thresholdByRoutine,
}: {
  trainingResultsFlat: TrainingResultFlat[] | undefined
  trainingResultsExpanded?: { [trainingResultId: string]: TrainingResult }
  threshold: Threshold | undefined
  modalLoaded: boolean
  toolLabels: ToolLabel[] | undefined
  gridRef?: React.RefObject<FixedSizeGrid>
  galleryBodyRef: React.RefObject<HTMLElement>
  onAfterUpdate?: (trainingResults: TrainingResultFlat[]) => void
  outerRef?: React.RefObject<HTMLElement>
  style?: React.CSSProperties
  onScroll: (props: GridOnScrollProps) => any
  tool?: Tool
  thresholdByRoutine: ThresholdByRoutineParentId | undefined
}) => {
  const containerDimensions = useContainerDimensions(galleryBodyRef)
  const { rowCount, columnCount, columnWidth, rowHeight } = useGridDimensions(
    (containerDimensions?.width || 0) - 58,
    trainingResultsFlat?.length,
    { gridGap: 16, minWidth: 165, elementRowHeight: 222 },
  )

  const handleItemsRendered = ({
    overscanRowStartIndex,
    overscanRowStopIndex,
  }: {
    overscanRowStartIndex: number
    overscanRowStopIndex: number
  }) => {
    const firstIndexDispayed = overscanRowStartIndex * columnCount
    const lastIndexDispayed = overscanRowStopIndex * columnCount + columnCount
    const trainingResultsDisplayed = trainingResultsFlat?.slice(firstIndexDispayed, lastIndexDispayed)
    if (!trainingResultsDisplayed?.length) return

    return onAfterUpdate?.(trainingResultsDisplayed)
  }

  return (
    <FixedSizeGrid
      onItemsRendered={handleItemsRendered}
      ref={gridRef}
      width={containerDimensions?.width || 0}
      height={containerDimensions?.height || 0}
      rowCount={rowCount}
      columnCount={columnCount}
      columnWidth={columnWidth}
      rowHeight={rowHeight}
      className={Styles.virtualizedGridContainer}
      itemData={{
        threshold,
        modalLoaded,
        toolLabels,
        trainingResultsFlat,
        trainingResultsExpanded,
        columnCount,
        tool,
        thresholdByRoutine,
      }}
      outerRef={outerRef}
      style={style}
      onScroll={onScroll}
    >
      {GalleryCardGridRenderer}
    </FixedSizeGrid>
  )
}

/**
 * GalleryCard component used for performant pagination implementations.
 */
const GalleryCardGridRenderer = React.memo(
  ({
    rowIndex,
    columnIndex,
    style,
    data,
  }: GridChildComponentProps<{
    threshold: Threshold | undefined
    modalLoaded: boolean
    toolLabels: ToolLabel[] | undefined
    columnCount: number
    trainingResultsFlat: TrainingResultFlat[] | undefined
    trainingResultsExpanded: { [trainingResultId: string]: TrainingResult } | undefined
    tool?: Tool
    thresholdByRoutine: ThresholdByRoutineParentId | undefined
  }>) => {
    const {
      trainingResultsFlat,
      trainingResultsExpanded,
      columnCount,
      threshold,
      modalLoaded,
      toolLabels,
      tool,
      thresholdByRoutine,
    } = data

    const listIndex = rowIndex * columnCount + columnIndex

    const trainingResult = trainingResultsFlat?.[listIndex]

    const toolResult = trainingResult && trainingResultsExpanded?.[trainingResult.id]?.tool_result

    if (!trainingResult) return null

    return (
      <div className={Styles.galleryCardWrapper} style={style}>
        <CardItem
          type="ghost4"
          threshold={threshold}
          modalLoaded={modalLoaded}
          toolLabels={toolLabels}
          trainingResult={trainingResult}
          toolResult={toolResult}
          key={trainingResult.id}
          tool={tool}
          thresholdByRoutine={thresholdByRoutine}
        />
      </div>
    )
  },
)

export default ExpandedLabelGrid
