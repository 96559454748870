type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: JSX.Element) => JSX.Element
  children: JSX.Element
}

/**
 * This component allows us to render some content conditionally wrapped around some JSX.
 * Code taken from
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 *
 */
export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children
