import React, { useCallback, useMemo } from 'react'

import { batch, shallowEqual, useDispatch } from 'react-redux'

import { wsPaths } from 'api'
import StreamListener from 'components/StreamListener'
import { CLOUD_FASTAPI_WS_URL } from 'env'
import { useTypedSelector } from 'hooks'
import * as Actions from 'rdx/actions'
import { AtomElement, HalSettingsStreamMessage, StreamDescriptor, StreamMultiMeta } from 'types'

type Props = {
  robotIds: string[]
  element: AtomElement
  stream: string
}

/**
 * Renders nothing. This component is responsible for subscribing to a single multi-video stream,
 * fetching frames for every single camera and sticking those frames into redux so that they can be
 * read individually.
 *
 * @param robotIds - Array of robot ids to subscribe to
 * @param element - The element to subscribe to
 * @param stream - The stream name to subscribe to
 *
 * Business Rules: The redux branch at which we'll stick these frames will be indexed by the
 * combination of the element name and the stream, and within that index, they will be subindexed
 * by robot ID. That means we'll have for example
 * {
 *   "basler-medium-compressed": {
 *     "id1": Blob,
 *     "id2": Blob
 *   },
 *   "basler-thumbnail-compressed": {
 *     "id1": Blob,
 *     "id3": Blob,
 *   }}
 */
function MultiVideoListener({ robotIds, element, stream }: Props) {
  const dispatch = useDispatch()
  // Even if client code is bad and robotIds change shape, this component won't need to reconnect to the websockets
  const robotIdsKey = robotIds.sort().join()

  const isHardwareTriggerByRobotId = useTypedSelector(state => {
    if (!robotIds) return
    const statusByRobotId: { [robotId: string]: boolean } = {}

    robotIds.forEach(robotId => {
      statusByRobotId[robotId] = state.triggerMode?.[robotId]?.triggerMode === 'hardware'
    })
    return statusByRobotId
  }, shallowEqual)

  // TODO: We only need to know the trigger type for a robot if we have no frames from it. The moment we have a frame from a camera, this
  // component doesn't care what trigger type it is, it no longer needs to get `last_n: 1` for it
  const streams: StreamDescriptor[] = useMemo(() => {
    return robotIds.map(id => ({
      element,
      stream,
      robot_id: id,
      last_n: isHardwareTriggerByRobotId?.[id] ? 1 : undefined,
    }))
    // `useTypedSelector` creates a new object on every render, it DOES NOT memo the value it returns,
    // it only forces the component to rerender whenever `shallowEquals` returns false
  }, [robotIdsKey, JSON.stringify(isHardwareTriggerByRobotId)]) // eslint-disable-line

  const handleFrame = useCallback(
    ({ frame, meta }: { frame: Blob; meta?: StreamMultiMeta }) => {
      // We're connecting to a multi stream, so we should always have a meta field with the pertinent data
      const message_id = meta?.message_id
      if (!meta || !message_id) return

      dispatch(Actions.videoUpdate({ frame, ...meta, message_id }))
    },
    [dispatch],
  )

  const halSettingsStreams: StreamDescriptor[] = useMemo(() => {
    return robotIds.map(id => ({ element: 'hal', stream: 'settings', robot_id: id, last_n: 1 }))
  }, [robotIdsKey]) // eslint-disable-line

  const handleSettingsMessage = useCallback(
    (messages: HalSettingsStreamMessage[]) => {
      if (messages.length === 0) return

      batch(() => {
        messages.forEach(msg => {
          const robotId = msg.meta?.robot_id
          if (!robotId) return

          dispatch(Actions.triggerModeUpdate({ robotId, triggerMode: msg.payload.data.camera_trigger_mode }))
        })
      })
    },
    [dispatch],
  )

  // TODO: add effect that cleans up most recent frame for each video feed when component unmounts
  return (
    <>
      {/* TODO: Once camera consolidation work goes in: colocated robots need to hook up to their video stream directly, not through cloud */}
      {streams.length > 0 && (
        <StreamListener
          connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.videoMultiStream()}` }}
          onFrame={handleFrame}
          streams={streams}
          mode="multi_video"
        />
      )}
      {halSettingsStreams.length > 0 && (
        <StreamListener
          connect={{ url: `${CLOUD_FASTAPI_WS_URL}${wsPaths.multiStream()}` }}
          onMessages={handleSettingsMessage}
          streams={halSettingsStreams}
          mode="message"
        />
      )}
    </>
  )
}

export default MultiVideoListener
