import React from 'react'

import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { Token } from 'components/Token/Token'
import Shared from 'styles/Shared.module.scss'

import Styles from './PrismGraphWrapper.module.scss'

/**
 * Renders the Analyze graph container
 *
 * https://www.figma.com/file/LNV0RsogObs9SSxNvwOtFC/Analyze-(main)?node-id=304%3A72086
 *
 * @param children – The body container, a specific space for the graph container which is below the title
 * @param graphName - renders the name of the graph placed on the top left side
 * @param graphValue - shows the total value of the current graph, which is placed below the title, if graphValue is used, graphCaption is going to be hidden, this props switch places and adds different styles fo each one, graphValue should be used with numbers only.
 * @param graphCaption - shows a caption below the name of the graph (some graphs displays it instead of the caption). Displays a description below the title, only 1 graphCaption or 1 graphValue can be used on each PrismWrapper.
 * @param resetButton - A link to reset the graphs, it's positioned to the top right side
 * @param className – The class name of the container
 */
export const PrismGraphWrapper = ({
  children,
  graphName,
  graphValue,
  graphCaption,
  resetButton,
  className,
  isLoading,
  'data-testid': dataTestId,
}: {
  children?: React.ReactNode
  graphName: string
  graphValue?: number | string
  graphCaption?: string
  resetButton?: React.ReactNode
  className?: string
  isLoading?: boolean
  'data-testid'?: string
}) => {
  return (
    <div className={`${Styles.prismGraphWrapper} ${Shared.verticalChildrenGap32} ${className ?? ''}`}>
      <header className={Styles.graphHeader}>
        <Token
          label={graphName}
          secondaryLabel={resetButton}
          labelClassName={Styles.graphLabel}
          data-testid={dataTestId}
        >
          {isLoading ? (
            <div />
          ) : (
            <>
              {graphValue && <div className={Styles.graphValue}>{graphValue}</div>}
              {graphCaption && <div className={Styles.graphCaption}>{graphCaption}</div>}
            </>
          )}
        </Token>
      </header>
      <div className={`${Styles.graphContainer}  ${isLoading ? Styles.skeletonLoadingContainer : ''}`}>
        {isLoading ? <PrismLoader /> : children}
      </div>
    </div>
  )
}
