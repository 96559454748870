import uniqBy from 'lodash/uniqBy'
import { QueryBranch, QueryState } from 'react-redux-query'

import { GetterData, getterKeys } from 'api'
import { DbObject, ListResponseData, SuccessResponseOnlyData } from 'types'

type QueryData<D extends {}> = QueryState<D>['data']

/**
 * Retrieves an object from the getter branch of the state tree.
 *
 * @param getter - Current getter branch of the state tree
 * @param key - Key in getter branch
 *
 * @returns Data object at key if present
 */
export function getData<T extends keyof typeof getterKeys, U extends ReturnType<(typeof getterKeys)[T]>>(
  getter: QueryBranch<SuccessResponseOnlyData<GetterData<U>>>,
  key?: U,
) {
  if (!key) return undefined
  return getter[key]?.data?.data
}

// These types are used to determine if the type extends List, and if not, then don't allow them
export type ListItem<ListType> = ListType extends ListResponseData<infer T> ? T : never
export type ListOrNever<ListType, ItemType> = [ListType] extends [ListResponseData<ItemType>] ? ListType : never

export function getResults<
  T extends keyof typeof getterKeys,
  U extends ReturnType<(typeof getterKeys)[T]>,
  V extends ListOrNever<GetterData<U>, ListItem<GetterData<U>>>,
>(getter: QueryBranch<SuccessResponseOnlyData<V>>, key?: U) {
  if (!key) return undefined
  return getter[key]?.data?.data?.results
}
/**
 * Getter update utils (for use with actions.getterUpdate)
 */

/**
 * Updates API response stored at `key`.
 */
export function getterUpdateData<T>(prevRes: QueryData<{ data: T }>, data: T) {
  if (!prevRes) return { data }
  return { ...prevRes, data: { ...prevRes.data, ...data } }
}

/**
 * Finds API response stored at `key`. Then concatenates a new "page" of results
 * to the existing results. The `next` page is updated via spreading ...data
 *
 * @param prevRes - Previous response with page(s) of data
 * @param data - Data with new page
 * @param dedupeOnId - Should we dedupe results on result id? Normally we should, because API can return duplicated
 *     results in consecutive pages in some edge cases
 */
export function getterAddPage<T extends ListResponseData<DbObject>>(
  prevRes: QueryData<{ data: T }>,
  data: T,
  dedupeOnId: boolean = true,
) {
  if (!prevRes) return { data }

  let newResults = data.results
  if (dedupeOnId) {
    const prevIds = new Set(prevRes.data.results.map(result => result.id))
    newResults = newResults.filter(result => !prevIds.has(result.id))
  }
  return { ...prevRes, data: { ...data, results: [...prevRes.data.results, ...newResults] } }
}

/**
 * Finds API response stored at `key`. Adds array of results passed in to
 * existing and dedupes on id, preferring new results.
 *
 * If `sort` function is passed, sorts list using sort function
 * If `maxResults` is passed, the resulting list is truncated to this size
 */
export function getterAddOrUpdateResultsAndSort<T extends DbObject>(
  prevRes: QueryData<{ data: ListResponseData<T> }> | null,
  payload: {
    results: T[]
    sort?: (a: any, b: any) => number
    sliceStartIdx?: number
    sliceEndIdx?: number
    keepOlder?: boolean
  },
) {
  const { results: newResults, sort, sliceStartIdx, sliceEndIdx, keepOlder } = payload

  // Don't bother sorting or deduping if there's no previous response here
  if (!prevRes) return { data: { results: newResults } }

  let results = []
  if (!keepOlder) {
    // Putting newResults first ensures deduping with uniqBy prefers new results
    results = [...newResults, ...prevRes.data.results]
  } else {
    results = [...prevRes.data.results, ...newResults]
  }

  results = uniqBy(results, result => result.id)

  if (sort) results.sort(sort)
  if (sliceEndIdx || sliceStartIdx) {
    results = results.slice(sliceStartIdx, sliceEndIdx)
  }

  return { ...prevRes, data: { ...prevRes.data, results } }
}

// One-off getter update utils
