import React from 'react'

import { ColumnsType } from 'antd/lib/table'

import Styles from './GridTableHeader.module.scss'

const sizeClasses = {
  small: Styles.small,
  large: Styles.large,
}

/**
 * Renders a grid table header
 *
 * Offers a flexible way of creating table headers by using a grid container.
 *
 * @param columns - columns to be passed for the table header
 * @param size - The header size : small | large, this will change the overall looks.
 * @param className - string class for the wrapper container
 */
const GridTableHeader = <T extends {}>({
  columns,
  size,
  className = '',
}: {
  columns: ColumnsType<T>
  size: 'small' | 'large'
  className?: string
}) => {
  const sizeClass = sizeClasses[size]
  return (
    <div className={`${Styles.gridTableHeader} ${sizeClass} ${className}`}>
      {columns.map((col, idx) => (
        <div key={col.key || idx}>{col.title as React.ReactNode}</div>
      ))}
    </div>
  )
}

export default GridTableHeader
