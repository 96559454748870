import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'

import { getterKeys, query, service } from 'api'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'
import { useData, useTypedSelector } from 'hooks'
import paths from 'paths'
import * as Actions from 'rdx/actions'
import { UserRole } from 'types'
import { getLocationMinifiedOrgId, getLoginPath, getMinifiedOrgId, matchRole } from 'utils'

import EulaModal from './Login/EulaModal'
import NotFound from './NotFound/NotFound'

export type PrivateRouteProps = {
  component: (args: any) => JSX.Element
  requiredRole?: UserRole
  bypassOrgActive?: boolean
  bypassCurrentOrgActive?: boolean
  bypassEula?: boolean
} & RouteProps

/**
 * Renders `component` if `auth` branch of state tree is truthy, else redirects
 * user to login. If auth is set but token is invalid, this still renders
 * `component`; it's not this component's responsibility to validate auth token.
 *
 * @param component - Component to render if user is authenticated
 */
const PrivateRoute = ({
  component: Component,
  requiredRole,
  bypassCurrentOrgActive,
  bypassOrgActive,
  bypassEula,
  ...rest
}: PrivateRouteProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useTypedSelector(state => state.auth)
  const currentOrg = useTypedSelector(state => state.currentOrg)
  const me = useData(getterKeys.me())
  const minifiedOrgId = getLocationMinifiedOrgId()

  const org = currentOrg?.organization
  const eulaAccepted = org?.accepted_eula
  const userDomain = me?.email.split('@')[1]
  const elementaryUser = userDomain && org?.email_domain_skip_eula_allowlist.includes(userDomain)

  const showEulaModal = !eulaAccepted && !elementaryUser && !bypassEula && !org?.rw_tenant_id

  useEffect(() => {
    if (!auth || !org || !me || !showEulaModal) return
    if (currentOrg.role !== 'owner') {
      // We push a new path to prevent the user being stuck from repeatedly trying to log into an org that hasn't signed the EULA
      history.push('/')
      dispatch(Actions.authUnset('eulaNotSigned'))
    }
  }, [auth, currentOrg, dispatch, history, me, org, showEulaModal])

  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.auth) {
          return (
            <Redirect
              push
              to={{
                pathname: getLoginPath(auth),
                state: { from: props.location, loggedOut: auth.logoutAction ? true : false },
              }}
            />
          )
        }

        if (!me || !currentOrg) return <PrismLoader fullScreen />

        if (!currentOrg.is_active && !bypassCurrentOrgActive) return <NotFound />

        if (!currentOrg.organization.is_active && !bypassOrgActive) return <NotFound />

        if (showEulaModal && currentOrg.role !== 'owner')
          // Show loader until we logout the user
          <PrismLoader fullScreen />

        if (showEulaModal && currentOrg.role === 'owner') {
          return (
            <EulaModal
              onOk={async () => {
                const res = await service.acceptEula()
                if (res.type !== 'success') {
                  dispatch(Actions.authUnset('eulaNotSigned'))
                  return
                }

                await query(getterKeys.me(), service.me, { dispatch })
                const orgRes = await query(getterKeys.organization(), service.getOrganization, { dispatch })
                if (orgRes?.type === 'success') {
                  dispatch(Actions.orgUpdate({ organization: orgRes.data }))
                }
              }}
              onClose={async () => {
                service.logout({ retry: { retries: 3, delay: 5000 } })
                dispatch(Actions.authUnset('eulaNotSigned'))
              }}
            />
          )
        }

        if (getMinifiedOrgId(currentOrg.organization.id) !== minifiedOrgId) return <NotFound />

        if (requiredRole && !matchRole(requiredRole)) {
          return <Redirect to={{ pathname: paths.inspect({ mode: 'site' }) }} />
        }

        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
