import { Action } from 'rdx/actions'
import { RootState } from 'types'

export default function reduce(
  state: RootState['robotDiscoveriesById'] = {},
  action: Action,
): RootState['robotDiscoveriesById'] {
  if (action.type === 'ROBOT_DISCOVERIES_SET') {
    return action.payload
  }
  return state
}
