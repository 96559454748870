import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Measure Tool Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=6431%3A12096
 *
 * @param hasHover - Fill the icon with light_100 when hovering the icon.
 * @param isActive - Adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismMeasureToolIcon = ({ isActive, hasHover, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${hasHover ? Styles.hoverInBlue : ''} ${isActive ? Styles.active : ''} ${
        className ?? ''
      }`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M17.3321 1.96447L20.5141 5.14645C20.7094 5.34171 20.7094 5.6583 20.5141 5.85356L17.3321 9.03554C17.1368 9.2308 16.8203 9.2308 16.625 9.03554C16.4297 8.84028 16.4297 8.52369 16.625 8.32843L18.9534 6H6.03553L6.0355 19.0999L8.36392 16.7714C8.55919 16.5762 8.87577 16.5762 9.07103 16.7714C9.26629 16.9667 9.26629 17.2833 9.07103 17.4786L5.88905 20.6605C5.69379 20.8558 5.37721 20.8558 5.18194 20.6605L1.99996 17.4786C1.8047 17.2833 1.8047 16.9667 1.99996 16.7714C2.19522 16.5762 2.51181 16.5762 2.70707 16.7714L5.0355 19.0999L5.03553 5H18.9534L16.625 2.67158C16.4297 2.47631 16.4297 2.15973 16.625 1.96447C16.8203 1.76921 17.1368 1.76921 17.3321 1.96447Z"
        fill="currentColor"
      />
      <path
        d="M14 20.5001C14 20.2239 13.7761 20.0001 13.5 20.0001H10.5C10.2239 20.0001 10 20.2239 10 20.5001C10 20.7762 10.2239 21.0001 10.5 21.0001H13.5C13.7761 21.0001 14 20.7762 14 20.5001Z"
        fill="currentColor"
      />
      <path
        d="M20.5 14C20.7761 14 21 13.7761 21 13.5V10.5C21 10.2239 20.7761 10 20.5 10C20.2239 10 20 10.2239 20 10.5V13.5C20 13.7761 20.2239 14 20.5 14Z"
        fill="currentColor"
      />
      <path
        d="M20 19C20 19.5523 19.5523 20 19 20H17.5C17.2239 20 17 20.2239 17 20.5C17 20.7761 17.2239 21 17.5 21H20C20.5523 21 21 20.5523 21 20V17.5C21 17.2239 20.7761 17 20.5 17C20.2239 17 20 17.2239 20 17.5V19Z"
        fill="currentColor"
      />
    </svg>
  )
}
