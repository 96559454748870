import React from 'react'

import Styles from './PrismIcons.module.scss'
import { PrismIconProps } from './prismIconsTypes'

/**
 * Prism Analyze Icon.
 *
 * * Reference: https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=1332%3A115
 *
 * @param isActive - adds special styles to the icon.
 * @param className - String for external styles.
 */
export const PrismAnalyzeIcon = ({ isActive, className }: PrismIconProps) => {
  return (
    <svg
      className={`${Styles.icon} ${Styles.analyzeIcon}  ${isActive ? Styles.active : ''} ${className ?? ''}`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="State=Inactive">
        <path id="Line" className={Styles.line} d="M16.5 22.5V14.5" strokeLinecap="round" />
        <path id="Line_2" className={Styles.line} d="M13.5 22.5V16.5" strokeLinecap="round" />
        <path id="Line_3" className={Styles.line} d="M10.5 22.5V18.5" strokeLinecap="round" />
        <path id="Line_4" className={Styles.line} d="M19.5 22.5V15.5" strokeLinecap="round" />
        <path id="Line_5" className={Styles.line} d="M22.5 22.5V11.5" strokeLinecap="round" />
        <g id="Vector">
          <mask id="path-6-inside-1" className={Styles.pieChartStrokeMask}>
            <path d="M7 2C5.61553 2 4.26216 2.41054 3.11101 3.17971C1.95987 3.94888 1.06266 5.04213 0.532846 6.32122C0.00303302 7.6003 -0.13559 9.00776 0.134506 10.3656C0.404603 11.7235 1.07129 12.9708 2.05026 13.9497C3.02922 14.9287 4.2765 15.5954 5.63437 15.8655C6.99224 16.1356 8.3997 15.997 9.67879 15.4672C10.9579 14.9373 12.0511 14.0401 12.8203 12.889C13.5895 11.7378 14 10.3845 14 9H7V2Z" />
          </mask>
          <path
            d="M7 2C5.61553 2 4.26216 2.41054 3.11101 3.17971C1.95987 3.94888 1.06266 5.04213 0.532846 6.32122C0.00303302 7.6003 -0.13559 9.00776 0.134506 10.3656C0.404603 11.7235 1.07129 12.9708 2.05026 13.9497C3.02922 14.9287 4.2765 15.5954 5.63437 15.8655C6.99224 16.1356 8.3997 15.997 9.67879 15.4672C10.9579 14.9373 12.0511 14.0401 12.8203 12.889C13.5895 11.7378 14 10.3845 14 9H7V2Z"
            className={Styles.pieChartBackground}
          />
          <path
            d="M7 2H8C8 1.44772 7.55229 1 7 1V2ZM14 9H15C15 8.44771 14.5523 8 14 8V9ZM7 9H6V10H7V9ZM7 1C5.41775 1 3.87103 1.46919 2.55544 2.34824L3.66658 4.01118C4.65328 3.35189 5.81331 3 7 3V1ZM2.55544 2.34824C1.23985 3.22729 0.214468 4.47672 -0.391033 5.93853L1.45673 6.7039C1.91085 5.60754 2.67989 4.67047 3.66658 4.01118L2.55544 2.34824ZM-0.391033 5.93853C-0.996534 7.40034 -1.15496 9.00887 -0.846279 10.5607L1.11529 10.1705C0.88378 9.00665 1.0026 7.80025 1.45673 6.7039L-0.391033 5.93853ZM-0.846279 10.5607C-0.537597 12.1126 0.224328 13.538 1.34315 14.6569L2.75736 13.2426C1.91825 12.4035 1.3468 11.3344 1.11529 10.1705L-0.846279 10.5607ZM1.34315 14.6569C2.46197 15.7757 3.88743 16.5376 5.43928 16.8463L5.82946 14.8847C4.66557 14.6532 3.59648 14.0818 2.75736 13.2426L1.34315 14.6569ZM5.43928 16.8463C6.99113 17.155 8.59966 16.9965 10.0615 16.391L9.2961 14.5433C8.19975 14.9974 6.99335 15.1162 5.82946 14.8847L5.43928 16.8463ZM10.0615 16.391C11.5233 15.7855 12.7727 14.7602 13.6518 13.4446L11.9888 12.3334C11.3295 13.3201 10.3925 14.0891 9.2961 14.5433L10.0615 16.391ZM13.6518 13.4446C14.5308 12.129 15 10.5822 15 9H13C13 10.1867 12.6481 11.3467 11.9888 12.3334L13.6518 13.4446ZM14 8H7V10H14V8ZM8 9V2H6V9H8Z"
            mask="url(#path-6-inside-1)"
            className={Styles.pieChartStroke}
          />
        </g>
        <g id="Ellipse">
          <mask id="path-8-inside-2" className={Styles.pieChartStrokeMask}>
            <path d="M14.5 8C14.5 7.14641 14.3319 6.30117 14.0052 5.51256C13.6786 4.72394 13.1998 4.00739 12.5962 3.40381C11.9926 2.80023 11.2761 2.32144 10.4874 1.99478C9.69883 1.66813 8.85359 1.5 8 1.5L8 8H14.5Z" />
          </mask>
          <path
            d="M14.5 8C14.5 7.14641 14.3319 6.30117 14.0052 5.51256C13.6786 4.72394 13.1998 4.00739 12.5962 3.40381C11.9926 2.80023 11.2761 2.32144 10.4874 1.99478C9.69883 1.66813 8.85359 1.5 8 1.5L8 8H14.5Z"
            className={Styles.pieChartSmallStrokeColors}
            strokeWidth="2"
            mask="url(#path-8-inside-2)"
          />
        </g>
      </g>
    </svg>
  )
}
