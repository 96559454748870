import React from 'react'

import { Moment } from 'moment-timezone'

import { LiveFeed } from 'components/LiveFeed/LiveFeed'
import { useDateTimePreferences } from 'hooks'

import Styles from './Timeline.module.scss'

interface TimelineProps {
  status?: 'pass' | 'fail' | 'unknown' | 'default' | 'minor'
  description: string | React.ReactNode
  date?: Moment
  className?: string
}

/**
 * Renders a timeline container with a timeline item component
 *
 * https://www.figma.com/file/0YKkgzjRIApn3KEsFRfWC7/%E2%9D%96-Prism?node-id=4174%3A11631
 *
 * Timeline Item will render the status and description, In case you need the line that link each item you need to use a Timeline container and place the TimelineItems inside of it.
 * @param status - render the square color
 * @param description - timeline description
 * @param date - timeline date
 * @param className - string class that will be passed down to the button
 *     element
 *
 */
export const TimelineItem = ({ status, description, date, className }: TimelineProps) => {
  const { timeFormat, timeZone } = useDateTimePreferences()
  return (
    <div className={`${Styles.timelineItem} ${className ?? ''}`}>
      <LiveFeed status={status} />
      <div className={Styles.timelineDescriptioncontainer}>
        <div className={Styles.timelineDescription}>{description}</div>

        <div className={Styles.timelineDate}>{date?.tz(timeZone).format(`MMM D, ${timeFormat}`)}</div>
      </div>
    </div>
  )
}

export const Timeline = ({ children }: { children: React.ReactNode }) => {
  return <div className={Styles.timelineContainer}>{children}</div>
}
