import React, { useCallback, useMemo, useRef } from 'react'

import { useHistory } from 'react-router-dom'

import ImageCloseUp from 'components/ImageCloseUp/ImageCloseUp'
import { LabelCard } from 'components/LabelCard/LabelCard'
import LabelsList from 'components/LabelList/LabelsList'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { useAllToolLabels, useQueryParams } from 'hooks'
import paths from 'paths'
import { ToolResult } from 'types'
import { filterOutDerivativeLabels, getDisplayThreshold } from 'utils'
import { LABELING_HOTKEYS } from 'utils/constants'

import { getToolResultCardId } from '../LabelingScreen'
import {
  areSmartGroupsActive,
  MAX_SELECTED_GALLERY_CLASSIFICATIONS,
  ToolResultsMap,
  ToolResultsMapDispatch,
} from './LabelingGallery'
import Styles from './LabelingGallery.module.scss'

interface Props {
  toolResult: ToolResult
  toolParentId: string
  showInsights: boolean
  setLastSelectedToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  onHoveredRangeChange: (toolResultId: string) => any
  hoveredRange: ToolResultsMap
  setHoveredRange: ToolResultsMapDispatch
  shiftPressed: boolean
  isHovered: boolean
  setHoveredToolResultId: React.Dispatch<React.SetStateAction<string | null>>
  selectedToolResults: ToolResultsMap
  onSelectedToolResultsChange: (toolResults: ToolResultsMap) => any
  allSelectedToolResultsCount: number
  groupId?: string
  cardRef?: React.Ref<HTMLDivElement>
  'data-test-attribute'?: string
}

/**
 * Renders a toolResult card used by Labelling Gallery.
 * This card renders the checkbox that is used to select the toolResult.
 * It is also in charge of handling the selection of ranges by holding shift and clicking on another card.
 *
 * @param toolResult - The toolResult to represent in the card
 * @param toolParentId - The current tool parent id
 * @param showInsights - Wheter to show the insights
 * @param setLastSelectedToolResultId - Function to set the last selected toolResult id
 * @param onHoveredRangeChange - Function to handle the hovered toolResults range
 * @param hoveredRange - The hovered toolResults range
 * @param setHoveredRange - State handler for hoveredRange, used to reset the hovered range
 * @param shiftPressed - Whether the shift key is pressed
 * @param isHovered - Wheter the card is hovered
 * @param setHoveredToolResultId - Function to set the current hovered toolResult id
 * @param selectedToolResults - The selected toolResults object
 * @param onSelectedToolResultsChange - Function to handle the selected toolResults change
 * @param allSelectedToolResultsCount - The total number of selected toolResults, this is mainly used when the "Group by" options is enabled, this allow us to keep track of the cards limit across all the groups.
 * @param groupId - The current group id if any
 * @param cardRef - Ref passed to the LabelCard component
 *
 */
export const GalleryCard = ({
  toolResult,
  toolParentId,
  showInsights,
  setLastSelectedToolResultId,
  onHoveredRangeChange,
  hoveredRange,
  setHoveredRange,
  shiftPressed,
  isHovered,
  setHoveredToolResultId,
  selectedToolResults,
  onSelectedToolResultsChange,
  allSelectedToolResultsCount,
  groupId,
  cardRef,
  'data-test-attribute': dataTestAttribute,
}: Props) => {
  const history = useHistory()
  const checkboxRef = useRef<HTMLInputElement>(null)
  const [params] = useQueryParams()

  const { allToolLabels } = useAllToolLabels()

  const isInHoveredRange = !!hoveredRange[toolResult.id]

  const isSelected = !!selectedToolResults[toolResult.id]

  const showCheckbox = isHovered || isSelected

  const selectingCards = allSelectedToolResultsCount > 0

  const showPredictionScore = params.showPredictionScore

  const toggleCard = useCallback(() => {
    // If we have a hovered toolResults range active, we select or unselect the whole range.
    if (Object.keys(hoveredRange).length > 0) {
      const hoveredIds = Object.keys(hoveredRange)

      const allSelected = hoveredIds.length > 0 && hoveredIds.every(id => !!selectedToolResults[id])

      // if all cards in the range are selected, we need to unselect them
      if (allSelected) {
        const updatedSelectedToolResults = { ...selectedToolResults }
        hoveredIds.forEach(id => delete updatedSelectedToolResults[id])
        onSelectedToolResultsChange(updatedSelectedToolResults)

        setLastSelectedToolResultId(null)
      } else {
        // if some cards in the range are unselected, we select every card.
        if (!hoveredRange[toolResult.id]) return

        setHoveredRange({})
        onSelectedToolResultsChange({ ...selectedToolResults, ...hoveredRange })
        setLastSelectedToolResultId(toolResult.id)
      }
    } else {
      // This handles single card toggle, without a hovered range.
      if (isSelected) {
        const updatedSelectedToolResults = { ...selectedToolResults }
        delete updatedSelectedToolResults[toolResult.id]

        onSelectedToolResultsChange(updatedSelectedToolResults)
        setLastSelectedToolResultId(null)
      } else {
        if (Object.keys(selectedToolResults).length >= MAX_SELECTED_GALLERY_CLASSIFICATIONS) return

        const updatedSelelectedToolResults = { ...selectedToolResults }
        updatedSelelectedToolResults[toolResult.id] = toolResult

        onSelectedToolResultsChange(updatedSelelectedToolResults)
        setLastSelectedToolResultId(toolResult.id)
      }
    }
  }, [
    toolResult,
    hoveredRange,
    isSelected,
    onSelectedToolResultsChange,
    selectedToolResults,
    setHoveredRange,
    setLastSelectedToolResultId,
  ])

  const setHovered = () => {
    setHoveredToolResultId(toolResult.id)
    if (shiftPressed) {
      onHoveredRangeChange(toolResult.id)
    }
  }

  const handleClick = useCallback(() => {
    if (selectingCards) {
      toggleCard()
    } else {
      const newParams: { [key: string]: string | undefined } = {
        ...params,
        tool_result_id: toolResult.id,
      }

      // If smart groups are active we set a different qs param, in this case, group id will be the smart group index.
      if (areSmartGroupsActive(params)) {
        newParams.smart_group_idx = groupId
      } else {
        newParams.group_id = groupId
      }

      history.push(paths.labelingScreen(toolParentId, 'focus', newParams), { redirectedFromOtherMode: true })
    }
  }, [toolResult.id, toolParentId, groupId, history, params, selectingCards, toggleCard])

  const toolLabels = useMemo(() => {
    if (!toolResult.active_user_label_set || !allToolLabels) return []
    // Ignore derivative labels on FocusCard
    const labelsWithoutDerivativeLabels = filterOutDerivativeLabels(allToolLabels)
    const labelIdsToUse = toolResult.active_user_label_set.tool_labels
    return labelsWithoutDerivativeLabels.filter(toolLabel => labelIdsToUse.includes(toolLabel.id))
  }, [allToolLabels, toolResult.active_user_label_set])

  return (
    <LabelCard
      cardRef={cardRef}
      data-test="gallery-card-item"
      data-test-attribute={dataTestAttribute}
      key={toolResult.id}
      id={getToolResultCardId(toolResult.id)}
      type="ghost4"
      active={params.tool_result_id === toolResult.id}
      className={`gallery-card ${Styles.galleryLabelCard} ${isInHoveredRange ? Styles.selected : ''} ${
        isSelected ? Styles.confirmed : ''
      }`}
      figureClassName={Styles.noPointerEvents}
      image={
        toolResult.aoi ? (
          <ImageCloseUp
            loaderType="skeleton"
            src={toolResult.picture.image}
            thumbnailSrc={toolResult.picture.image_thumbnail}
            region={toolResult.aoi}
            overlaySrc={showInsights ? toolResult.insight_image : undefined}
          />
        ) : null
      }
      label={
        <>
          {showPredictionScore && toolResult.tool?.specification_name && (
            <div className={Styles.cardPredictionScore}>
              {getDisplayThreshold(toolResult.prediction_score, toolResult.tool.specification_name)}
            </div>
          )}

          {toolLabels && <LabelsList labels={toolLabels} data-testid="gallery-card" data-test="gallery-card-label" />}

          {showCheckbox && (
            <PrismCheckbox
              ref={checkboxRef}
              onMouseEnter={setHovered}
              className={Styles.checkboxPosition}
              onChange={toggleCard}
              checked={isSelected}
              data-test="gallery-card-item-checkbox"
              data-test-attribute={isSelected ? 'gallery-card-item-selected' : ''}
              data-allowedhotkeys={`${LABELING_HOTKEYS}|i`}
              shape="circle"
            />
          )}
        </>
      }
      onClick={handleClick}
      onMouseEnter={setHovered}
      onMouseLeave={() => {
        setHoveredToolResultId(null)
        setHoveredRange({})
      }}
    />
  )
}

export const EmptyCard = () => {
  return <div className={`gallery-card ${Styles.galleryLabelCard}`}></div>
}

export default GalleryCard

export const MemoGalleryCard = React.memo(GalleryCard)
