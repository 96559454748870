import React, { SyntheticEvent } from 'react'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismReorderingArrowIcon } from 'components/prismIcons'
import { PrismLoader } from 'components/PrismLoaders/PrismLoaders'

import Styles from './ReorderingArrows.module.scss'

type ReorderinArrowsProps = {
  onClickUp?: () => void
  onClickDown?: () => void
  className?: string
  disabled?: {
    upArrowIsdisabled?: boolean
    downArrowIsdisabled?: boolean
  }
  isLoading?: boolean
  dataTestId?: string
}

const SHARED_PROPS = {
  icon: <PrismReorderingArrowIcon />,
  hideLoader: true,
  size: 'xsmall' as const,
  type: 'tertiary' as const,
}

/**
 * Renders a couple of arrows 1 pointing up and the other down.
 *
 * @param className - String to add additional selector to the main component
 * @param onClickUp - Callback to be called when the up arrow is clicked
 * @param onClickDown - Callback to be called when the down arrow is clicked
 * @param disabled - Stop the user from clicking the component and additional styles are added
 */
const ReorderingArrows = ({
  onClickUp,
  onClickDown,
  className = '',
  isLoading,
  disabled,
  dataTestId,
}: ReorderinArrowsProps) => {
  const handleClickUp = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClickUp?.()
  }
  const handleClickDown = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClickDown?.()
  }
  return (
    <div className={`${Styles.reorderingArrowsContainer} ${className}`}>
      {!isLoading && (
        <>
          <IconButton
            onClick={handleClickUp}
            className={Styles.reorderingArrow}
            disabled={disabled?.upArrowIsdisabled}
            data-testid={`${dataTestId}-up-arrow`}
            {...SHARED_PROPS}
          />
          <IconButton
            onClick={handleClickDown}
            className={`${Styles.reorderingArrow} ${Styles.downDirection}`}
            disabled={disabled?.downArrowIsdisabled}
            data-testid={`${dataTestId}-down-arrow`}
            {...SHARED_PROPS}
          />
        </>
      )}
      {isLoading && <PrismLoader dataTestId="loading-reordering-arrows" />}
    </div>
  )
}

export default ReorderingArrows
