import React, { useCallback, useEffect, useState } from 'react'

import { Radio } from 'antd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { service } from 'api'
import { Divider } from 'components/Divider/Divider'
import PrismCheckbox from 'components/PrismCheckbox/PrismCheckbox'
import { PrismDropperIcon } from 'components/prismIcons'
import { PrismInput } from 'components/PrismInput/PrismInput'
import { PrismSlider, PrismSliderRange } from 'components/PrismSlider/PrismSlider'
import { usePrevious } from 'hooks'
import {
  AreaOfInterestConfiguration,
  NonTrainableToolSettingsProps,
  PreprocessingOptions,
  RoutineWithAois,
  Tool,
} from 'types'
import {
  calculatePercentage,
  commaSeparatedNumbers,
  convertAltToPreprocessingOptions,
  convertPreprocessingOptionsToAlt,
  getRgbFromHsvRanges,
  protectedOnChange,
} from 'utils'

import EditAoisHelper from './AOIEditing/EditAoisHelper'
import Styles from './ToolStyles.module.scss'
import { getToolNameAndAois, ResetFormArgs, ToolTemplate } from './ToolTemplate'

/**
 * Renders the left side of the Ocr Tool. The user can set all preprocessing
 * parameters in this panel, expected text, hsv values and transformation values
 *
 * @param routine - The routine we are working on.
 * @param tool - The selected tool.
 * @param onExit - Callback to deselect tool and return to default view, changes done but not saved are discarded.
 * @param readOnly - Whether we are in read only mode.
 */
export const ColorToolSettings = ({ routine, recipe, tool, onExit, readOnly }: NonTrainableToolSettingsProps) => {
  const history = useHistory()

  const [whitePixelCount, setWhitePixelCount] = useState<number>()
  const [totalPixelCount, setTotalPixelCount] = useState<number>()
  const [showEyeDropper, setShowEyeDropper] = useState<boolean>(false)
  const [selectedAoi, setSelectedAoi] = useState<AreaOfInterestConfiguration | undefined>()

  const defaultValues = getDefaultFormValues(routine, tool)

  const formData = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    reset,
    formState: { errors },
    formState,
    control,
    getValues,
    watch,
    setError,
    clearErrors,
    setValue,
  } = formData

  useEffect(() => {
    // Manually set a form error for white pixels. We do this since using a `rules` prop only validates when clicking the save button
    if (whitePixelCount === undefined) setError('inferenceArgs', { type: 'whitePixel', message: 'AA' })
    if (whitePixelCount !== undefined && errors.inferenceArgs) clearErrors('inferenceArgs')
  }, [whitePixelCount, errors, setError, clearErrors])
  const { inferenceArgs } = watch(['inferenceArgs'])

  const resetForm = ({ routine, tool }: ResetFormArgs) => {
    reset(getDefaultFormValues(routine, tool))
  }

  const handlerFunction = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') setShowEyeDropper(false)
  }, [])

  const saveChanges = () => {
    const { inferenceArgs } = getValues()

    const { dirtyFields } = formState

    const promises = []

    if (dirtyFields.inferenceArgs && inferenceArgs) {
      promises.push(
        service.patchTool(tool.id, {
          inference_args: { ...tool.inference_args, ...convertAltToPreprocessingOptions(inferenceArgs) },
        }),
      )
    }
    return promises
  }

  useEffect(() => {
    if (showEyeDropper) {
      window.addEventListener('keydown', handlerFunction)
    } else {
      window.removeEventListener('keydown', handlerFunction)
    }
  }, [showEyeDropper, handlerFunction])

  const pixelToPercentage = useCallback(
    (value: number, pxCt: number | undefined = whitePixelCount) => {
      return Math.round(calculatePercentage(value, pxCt))
    },
    [whitePixelCount],
  )

  const percentageToPixel = useCallback(
    (value: number, pxCt: number | undefined = whitePixelCount) => {
      return pxCt ? Math.round((value * pxCt) / 100) : 0
    },
    [whitePixelCount],
  )

  const handlePixelCountChange = useCallback(
    (newWhitePixelCount: number, newTotalPixelCount: number) => {
      setWhitePixelCount(newWhitePixelCount)
      setTotalPixelCount(newTotalPixelCount)
    },
    [setWhitePixelCount, setTotalPixelCount],
  )

  const previousWhitePixelCount = usePrevious(whitePixelCount)
  useEffect(() => {
    // This effect recalculates the pixel count ranges considering the currently selected percentages, whenever the reference pixelCount changes
    if (previousWhitePixelCount !== undefined) {
      const percentageMin = pixelToPercentage(inferenceArgs?.pixel_count_min || 0, previousWhitePixelCount)
      const percentageMax = pixelToPercentage(inferenceArgs?.pixel_count_max || 0, previousWhitePixelCount)

      setValue('inferenceArgs', {
        ...inferenceArgs,
        pixel_count_min: percentageToPixel(percentageMin, whitePixelCount),
        pixel_count_max: percentageToPixel(percentageMax, whitePixelCount),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitePixelCount, previousWhitePixelCount])

  const handleSelectedAoiChange = useCallback((selectedAoi?: AreaOfInterestConfiguration) => {
    setSelectedAoi(selectedAoi)
  }, [])

  return (
    <div className={Styles.configureLayout}>
      <div className={Styles.formContainer}>
        <FormProvider {...formData}>
          <ToolTemplate
            routine={routine}
            recipe={recipe}
            tool={tool}
            resetForm={resetForm}
            readOnly={readOnly}
            onExit={onExit}
            saveChanges={saveChanges}
          >
            <div className={Styles.settingsItem}>
              <Controller
                control={control}
                name="name"
                rules={{ required: 'Name is required' }}
                render={({ onChange, ...rest }) => (
                  <PrismInput
                    {...rest}
                    label="Name"
                    placeholder="Name this tool"
                    onChange={e => onChange(e.target.value)}
                    disabled={readOnly}
                    size="small"
                    errors={errors}
                    wrapperClassName={Styles.settingsItemInput}
                  />
                )}
              />
              <div className={Styles.settingsItemDescription}>What you're looking for</div>
            </div>

            <Controller
              control={control}
              name="inferenceArgs"
              render={({ onChange, value }) => {
                function setOptions<T>(options: { [key: string]: T }) {
                  onChange({
                    ...value,
                    ...options,
                  })
                }

                return (
                  <>
                    <div className={Styles.settingsItem}>
                      <div className={Styles.settingsItemTitleWithSwitch}>
                        <div className={Styles.settingsTwoTitles}>Target Color</div>

                        <div className={Styles.dropperContainer}>
                          <div
                            className={Styles.dropper}
                            onClick={protectedOnChange(
                              e => {
                                e.stopPropagation()
                                setShowEyeDropper(!showEyeDropper)
                              },
                              { routine, history, recipe },
                            )}
                          >
                            <PrismDropperIcon />
                          </div>

                          <div
                            className={Styles.settingsDropperPreview}
                            style={{
                              backgroundColor: `${getRgbFromHsvRanges(value).rgbString}`,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className={Styles.settingsSubContainer}>
                        <PrismSliderRange
                          label="Hue"
                          min={0}
                          max={179}
                          valueStart={value.hsv_min_h}
                          valueEnd={value.hsv_max_h}
                          onChange={protectedOnChange(val => setOptions({ hsv_min_h: val[0], hsv_max_h: val[1] }), {
                            routine,
                            history,
                            recipe,
                          })}
                        />

                        <PrismSliderRange
                          label="Saturation"
                          min={0}
                          max={255}
                          valueStart={value.hsv_min_s}
                          valueEnd={value.hsv_max_s}
                          onChange={protectedOnChange(val => setOptions({ hsv_min_s: val[0], hsv_max_s: val[1] }), {
                            routine,
                            history,
                            recipe,
                          })}
                        />

                        <PrismSliderRange
                          label="Value"
                          min={0}
                          max={255}
                          valueStart={value.hsv_min_v}
                          valueEnd={value.hsv_max_v}
                          onChange={protectedOnChange(val => setOptions({ hsv_min_v: val[0], hsv_max_v: val[1] }), {
                            routine,
                            history,
                            recipe,
                          })}
                        />
                      </div>
                    </div>

                    <div className={Styles.settingsItem}>
                      <div className={Styles.settingsItemTitle}>Transform Filter</div>

                      <div className={`${Styles.grayCover} ${Styles.settingsTransformFilters}`}>
                        <div className={Styles.settingsTransformFilterItem}>
                          <PrismCheckbox
                            className={Styles.filterCheckbox}
                            onChange={protectedOnChange(e => setOptions({ dilation_enable: e.target.checked }), {
                              routine,
                              history,
                              recipe,
                            })}
                            checked={value.dilation_enable}
                            label="Dilate"
                          />

                          {value.dilation_enable && (
                            <>
                              <div className={Styles.settingsSubContainer}>
                                <PrismSlider
                                  value={value.dilation_width}
                                  label="Kernel Width"
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ dilation_width: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  value={value.dilation_height}
                                  label="Kernel Height"
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ dilation_height: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Iterations"
                                  value={value.dilation_iterations}
                                  onChange={protectedOnChange(val => setOptions({ dilation_iterations: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                  min={1}
                                  max={10}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className={Styles.settingsTransformFilterItem}>
                          <PrismCheckbox
                            className={Styles.filterCheckbox}
                            onChange={protectedOnChange(e => setOptions({ erosion_enable: e.target.checked }), {
                              routine,
                              history,
                              recipe,
                            })}
                            checked={value.erosion_enable}
                            label="Erode"
                          />

                          {value.erosion_enable && (
                            <>
                              <div className={Styles.settingsSubContainer}>
                                <PrismSlider
                                  label="Kernel Width"
                                  value={value.erosion_width}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ erosion_width: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Kernel Height"
                                  value={value.erosion_height}
                                  min={3}
                                  max={19}
                                  step={2}
                                  onChange={protectedOnChange(val => setOptions({ erosion_height: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />

                                <PrismSlider
                                  label="Iterations"
                                  value={value.erosion_iterations}
                                  min={1}
                                  max={10}
                                  onChange={protectedOnChange(val => setOptions({ erosion_iterations: val }), {
                                    routine,
                                    history,
                                    recipe,
                                  })}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        {value.erosion_enable && value.dilation_enable && (
                          <div className={Styles.settingsTransformFilterItem}>
                            <div className={Styles.settingsItemTitle}>Transform Order</div>

                            <Radio.Group
                              className={Styles.transformOrderOptions}
                              onChange={protectedOnChange(
                                e => setOptions({ erosion_first: e.target.value === 'erosion' }),
                                { routine, history, recipe },
                              )}
                              value={value.erosion_first ? 'erosion' : 'dilation'}
                            >
                              <Radio value="dilation">Dilate First</Radio>

                              <Radio value="erosion">Erode First</Radio>
                            </Radio.Group>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={Styles.settingsItem}>
                      <div className={Styles.settingsItemTitle}>Pass Criteria</div>
                      <div className={Styles.settingsSubContainer}>
                        {/* Hardcoded */}
                        <PrismSliderRange
                          label="Pixel Count"
                          min={0}
                          max={totalPixelCount ? pixelToPercentage(totalPixelCount) : 0}
                          disabled={whitePixelCount === undefined}
                          valueStart={pixelToPercentage(value.pixel_count_min)}
                          valueEnd={pixelToPercentage(value.pixel_count_max)}
                          onChange={protectedOnChange(
                            val =>
                              onChange({
                                ...value,
                                pixel_count_min: percentageToPixel(val[0]),
                                pixel_count_max: percentageToPixel(val[1]),
                              }),
                            { routine, history, recipe },
                          )}
                        />

                        <div className={Styles.description}>
                          {selectedAoi && (
                            <>
                              <span>{whitePixelCount ? commaSeparatedNumbers(whitePixelCount) : 0}</span> pixels in
                              Reference {selectedAoi.parentName} match your target color.
                              <br />
                              <br />
                            </>
                          )}
                          This tool will Pass if the observed pixel count is between{' '}
                          <span>{commaSeparatedNumbers(value.pixel_count_min || 0)}</span> and{' '}
                          <span>{commaSeparatedNumbers(value.pixel_count_max || 0)}</span>. Otherwise, it will Fail.
                        </div>
                      </div>
                    </div>
                  </>
                )
              }}
            />
          </ToolTemplate>
        </FormProvider>
      </div>

      <Divider type="vertical" className={Styles.configureDivider} />
      <div className={Styles.toolsAoiContainer}>
        <Controller
          control={control}
          name="aois"
          render={({ onChange, value }) => (
            <EditAoisHelper
              routine={routine}
              recipe={recipe}
              tool={tool}
              aois={value}
              setAois={onChange}
              showPreview
              previewOptions={{
                inferenceArgs,
                showEyeDropper,
                setShowEyeDropper,
                setValue,
                onPixelCountChange: handlePixelCountChange,
              }}
              onSelectedAoiChange={handleSelectedAoiChange}
              resetForm={resetForm}
            />
          )}
        />
      </div>
    </div>
  )
}

const getDefaultFormValues = (routine: RoutineWithAois, tool: Tool) => {
  const { name, aois } = getToolNameAndAois(routine, tool)

  return {
    aois,
    name,
    inferenceArgs: convertPreprocessingOptionsToAlt(tool.inference_args as PreprocessingOptions | undefined),
  }
}
