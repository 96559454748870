import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { service } from 'api'
import { error, success } from 'components/PrismMessage/PrismMessage'
import { Modal } from 'components/PrismModal/PrismModal'
import { Token } from 'components/Token/Token'
import ProductSearch, { createProductOnTheFly } from 'pages/RoutineOverview/ProductSearch'
import Shared from 'styles/Shared.module.scss'

import Styles from './RecipesList.module.scss'

export type RenameRecipeModalProps = {
  recipeParentId: string
  componentId: string
  onClose: () => void
  onSuccess: () => void
}

const CHANGE_PRODUCT_ERROR = 'There was an error changing the product, please try again'

const ChangeRecipeProductModal = ({ recipeParentId, componentId, onClose, onSuccess }: RenameRecipeModalProps) => {
  const defaultValues = { componentId }
  const [newComponentName, setNewComponentName] = useState<string>()
  const {
    formState: { isDirty, isValid },
    control,
    trigger,
    getValues,
  } = useForm({ defaultValues, mode: 'onChange' })

  const handleSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    const valid = await trigger()
    if (!valid) return

    const { componentId } = getValues()

    await createProductOnTheFly({
      productSelectedId: componentId,
      newProductNameState: newComponentName,
      onError: async () => error({ title: CHANGE_PRODUCT_ERROR }),
      onSuccess: async productId => {
        const res = await service.updateRecipeParent(recipeParentId, {
          component_id: productId,
        })

        if (res.type !== 'success') return error({ title: CHANGE_PRODUCT_ERROR })
        onSuccess()
        success({ title: 'Product changed', 'data-testid': 'change-product-recipe-notification' })
        onClose()
      },
    })
  }

  return (
    <Modal
      id="change-product"
      header="Change Product"
      headerClassName={Styles.recipeModalHeader}
      onClose={onClose}
      onOk={handleSave}
      disableSave={!isDirty || !isValid}
      okText="Save"
      data-testid="change-product-recipe-modal"
      size="largeSimpleForm"
      modalFooterClassName={Styles.recipeModalFooter}
      modalBodyClassName={Shared.verticalChildrenGap8}
    >
      <form onSubmit={handleSave}>
        <Token label="product" className={Styles.selectWrapper}>
          <Controller
            name="componentId"
            rules={{ required: 'Required' }}
            control={control}
            render={({ value, onChange }) => (
              <ProductSearch
                hideDashEmptyOption
                value={value}
                onSelect={id => onChange(id)}
                size="large"
                newProductNameState={newComponentName}
                setNewProductNameState={setNewComponentName}
                data-test="change-product-recipe-option"
                data-testid="change-product-recipe-select"
              />
            )}
          />
        </Token>
      </form>

      <div className={Styles.caption}>
        Changing a recipe's product will only affect future inspections. Historical results are preserved.
      </div>
    </Modal>
  )
}

export default ChangeRecipeProductModal
