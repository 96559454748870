import React, { useMemo } from 'react'

import RobotsVpListener from 'components/RobotsVpListener'
import { RobotsVpnListener } from 'components/RobotsVpnListener'
import { useIsColocated } from 'hooks'
export interface Props {
  robotIds?: string[]
  last_n?: number
  excludeColocatedRobots?: boolean
}

/**
 * Renders nothing. Mounts multiplexed vision-processing status and VPN
 * event/heartbeat listeners for robotIds. This gives us all the data we need to
 * compute robot status.
 *
 * @param robotIds - Array of robot ids to subscribe to (ignoring colocated robot ids included in it),
 *  if array is not passed in, it will subscribe to all colocated robots.
 * @param last_n - Passed through to RobotsVpListener
 */
function RobotsStatusListener({ robotIds, last_n = 5 }: Props) {
  const { colocatedRobotIds } = useIsColocated()

  const memoizedRobotIds = useMemo(() => {
    if (!robotIds) return colocatedRobotIds
    return robotIds.filter(robotId => !colocatedRobotIds.includes(robotId))
  }, [robotIds, colocatedRobotIds])

  return (
    <>
      <RobotsVpListener robotIds={memoizedRobotIds} last_n={last_n} />
      <RobotsVpnListener robotIds={memoizedRobotIds} />
    </>
  )
}

export default RobotsStatusListener
