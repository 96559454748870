import React from 'react'

import { DropzoneInputProps, DropzoneRootProps, FileRejection, useDropzone } from 'react-dropzone'

import { IconButton } from 'components/IconButton/IconButton'
import { PrismDiscardIcon, PrismElementaryCube, PrismUploadIcon } from 'components/prismIcons'
import { getFileErrors, getMostImportantError } from 'components/UploadModal/UploadModal'
import { FileCustomError } from 'types'
import {
  MAX_IMAGE_UPLOAD_HEIGHT,
  MAX_IMAGE_UPLOAD_WIDTH,
  MIN_IMAGE_UPLOAD_HEIGHT,
  MIN_IMAGE_UPLOAD_WIDTH,
} from 'utils/constants'

import Styles from './PrismImageDropzone.module.scss'

const imageUploadErrorMessages = {
  'file-too-large': `The maximum image size is ${MAX_IMAGE_UPLOAD_WIDTH} by ${MAX_IMAGE_UPLOAD_HEIGHT} pixels`,
  'file-too-small': `The minimum image size is ${MIN_IMAGE_UPLOAD_WIDTH} by ${MIN_IMAGE_UPLOAD_HEIGHT} pixels`,
  'too-many-files': 'Only 3 example images are allowed.',
  'file-invalid-type': 'Only JPG and PNG files are supported',
  'reference-image-mismatch': '',
}
interface DropzoneProps {
  setFilesToUpload: (files: File[]) => void
  setError: (error: FileCustomError) => void
  maxFiles: number
  render: (getRootProps: () => DropzoneRootProps, getInputProps: () => DropzoneInputProps) => JSX.Element
}

interface PrismImageDropzoneProps extends Omit<DropzoneProps, 'render'> {
  className?: string
  error?: FileCustomError
  caption?: string
  image?: React.ReactNode
  showCube?: boolean
  isEmpty?: boolean
  onRemoveImage?: () => void
}

const getFileErrorMessage = (error: FileCustomError) => {
  return error && imageUploadErrorMessages[error]
}

/**
 * Renders an initial custom caption, which can change to errorMessages
 *
 * @param title - the initial caption
 * @param hasError - shows one of the errorMessages and add a red color
 * */
export const ImageDropzoneCaption = ({ title, error }: { title: string; error?: FileCustomError }) => {
  return (
    <p className={`${Styles.uploadImageCaption} ${error ? Styles.hasError : ''}`}>
      {error && getFileErrorMessage(error)}
      {!error && title}
    </p>
  )
}

const DropZone = ({ setFilesToUpload, setError, maxFiles, render }: DropzoneProps) => {
  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const fileErrors = await getFileErrors({ acceptedFiles })
    const dropZoneErrors = rejectedFiles.flatMap(rj => rj.errors.flatMap(err => err.code))

    const mostImportantError = getMostImportantError(fileErrors, dropZoneErrors)

    setError(mostImportantError)

    const newFilesToUpload = acceptedFiles.filter((file, idx) => !fileErrors[idx])

    setFilesToUpload(newFilesToUpload)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles,
    accept: 'image/jpeg, image/png',
    multiple: true,
  })

  return render(getRootProps, getInputProps)
}

/**
 * Renders a wrapper for the drop zone ,
 *
 * @param setError -
 * @param setFilesToUpload -
 * @param render -
 * @param maxFiles -
 * @param hasError - changes the border and the caption color
 * @param hasCaption - show a caption below the image
 * @param className - string to add styles for the main component
 * @param image - image holder
 * @param isEmpty - When isEmpty the container shows an upload icon and a caption when hover on it, when !isEmpty it can display 2 images (upload image | cube) and on hover it shows 2 icon buttons
 * @param onRemoveImage - callback to be passed to the remove icon button
 *
 * */
const PrismImageDropzone = ({
  setError,
  setFilesToUpload,
  maxFiles,
  error,
  caption,
  className = '',
  image,
  isEmpty,
  onRemoveImage,
}: PrismImageDropzoneProps) => {
  return (
    <>
      <figure className={`${Styles.imageDropzone} ${error ? Styles.hasBorderError : ''} ${className}`}>
        <DropZone
          setError={setError}
          setFilesToUpload={setFilesToUpload}
          maxFiles={maxFiles}
          render={(getRootProps, getInputProps) => (
            <div {...getRootProps()} className={Styles.dropzone}>
              {!isEmpty && (
                <>
                  {image && image}
                  {!image && <PrismElementaryCube addBackground />}
                  <span className={Styles.imageOverlay}>
                    <IconButton
                      icon={<PrismDiscardIcon />}
                      type="tertiary"
                      onClick={e => {
                        e.stopPropagation()
                        onRemoveImage?.()
                      }}
                    />
                    <input {...getInputProps()} />
                    <IconButton icon={<PrismUploadIcon />} type="tertiary" />
                  </span>
                </>
              )}
              {isEmpty && (
                <div className={Styles.emptyImageContainer}>
                  <input {...getInputProps()} />
                  <PrismUploadIcon className={Styles.emptyImageIcon} />
                  <span className={Styles.emptyImageCaption}>jpg or png</span>
                </div>
              )}
            </div>
          )}
        />
      </figure>
      {!!caption && <ImageDropzoneCaption title={caption} error={error} />}
    </>
  )
}

export default PrismImageDropzone
