import React from 'react'

import PrismOverflowTooltip from 'components/PrismOverflowTooltip/PrismOverflowTooltip'

/**
 * Renders the robot name.
 * Displays a native browser tooltip when the text overflows.
 *
 * @param robotName - the robot name
 * @param className - string class for the wrapper element.
 */
export const RobotDisplayName = ({
  className = '',
  robotName,
  dataTestId,
}: {
  className?: string
  robotName?: string
  dataTestId?: string
}) => {
  return <PrismOverflowTooltip className={className} content={robotName} data-testid={dataTestId} canBeResized />
}
