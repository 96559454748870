import React, { useMemo } from 'react'

import copy from 'copy-to-clipboard'

import { IconButton } from 'components/IconButton/IconButton'
import ImgFallback from 'components/Img/ImgFallback'
import LabelInfo from 'components/LabelInfo/LabelInfo'
import { PrismCopyIcon, PrismInfoIcon } from 'components/prismIcons'
import { success } from 'components/PrismMessage/PrismMessage'
import { PrismResultButton } from 'components/PrismResultButton/PrismResultButton'
import PrismTooltip from 'components/PrismTooltip/PrismTooltip'
import { useIsFlourish } from 'hooks'
import { ToolLabel } from 'types'
import {
  findDefaultDescription,
  getDisplaySeverity,
  getLabelName,
  getToolLabelImagesToShow,
  showCausesAndActions,
  titleCase,
} from 'utils'

import Styles from './LabelingScreen.module.scss'

const ToolLabelDetail = ({ toolLabel }: { toolLabel: ToolLabel }) => {
  const { organizationData } = useIsFlourish()

  const name = titleCase(getLabelName(toolLabel))

  return (
    <>
      {toolLabel.kind === 'default' && (
        <LabelInfo addDescriptionGap>
          <div className={Styles.standardCaption}>
            <PrismInfoIcon className={Styles.iconCaption} />
            This is a standard {organizationData.company} label and cannot be edited
          </div>
        </LabelInfo>
      )}

      <LabelInfo title="Name" addDescriptionGap>
        {name}
      </LabelInfo>

      <ToolLabelDetailFields toolLabel={toolLabel} smallSeverity addDescriptionFieldGap />
    </>
  )
}

export const ToolLabelDetailFields = ({
  toolLabel,
  smallSeverity,
  addDescriptionFieldGap,
  onImageClick,
}: {
  toolLabel: ToolLabel
  smallSeverity?: boolean
  addDescriptionFieldGap?: boolean
  onImageClick?: (idx: number) => void
}) => {
  const images = getToolLabelImagesToShow(toolLabel)
  const showRootAndCorrectiveActions = showCausesAndActions(toolLabel.severity)

  const description = useMemo(() => {
    if (toolLabel.kind === 'default') return findDefaultDescription(toolLabel)

    return toolLabel.description || ''
  }, [toolLabel])

  return (
    <>
      <LabelInfo title="Severity" addDescriptionGap>
        <PrismResultButton
          severity={getDisplaySeverity(toolLabel)}
          value={toolLabel.severity}
          className={Styles.severityContainer}
          type="fill"
          size={smallSeverity ? 'small' : undefined}
        />
      </LabelInfo>

      {images.length > 0 && (
        <LabelInfo title="Example Images" addDescriptionGap>
          <div className={Styles.examplesImagesWrapper}>
            {images.map((img, idx) => (
              <figure key={img} className={Styles.exampleImageContainer} onClick={() => onImageClick?.(idx)}>
                <ImgFallback src={img} retries={5} className={Styles.labelFormImage} loaderType="skeleton" />
              </figure>
            ))}
          </div>
        </LabelInfo>
      )}

      {description && (
        <LabelInfo title="Description" addDescriptionGap={addDescriptionFieldGap}>
          <p data-testid="label-popover-description" className={Styles.itemDescription}>
            {description}
          </p>
        </LabelInfo>
      )}

      {showRootAndCorrectiveActions && toolLabel.root_cause && (
        <LabelInfo title="Root Causes">
          <p data-testid="label-popover-root-causes" className={Styles.itemDescription}>
            {toolLabel.root_cause}
          </p>
        </LabelInfo>
      )}

      {showRootAndCorrectiveActions && toolLabel.corrective_actions && (
        <LabelInfo title="Corrective Actions">
          <p data-testid="label-popover-corrective-actions" className={Styles.itemDescription}>
            {toolLabel.corrective_actions}
          </p>
        </LabelInfo>
      )}

      <ToolLabelPLCId toolLabel={toolLabel} />
    </>
  )
}

export const ToolLabelPLCId = ({ toolLabel }: { toolLabel: ToolLabel }) => {
  const handleCopyClick = () => {
    copy(toolLabel.id)
    success({ title: 'Copied' })
  }
  return (
    <LabelInfo title="PLC Label Code">
      <div className={Styles.plcIdContainer}>
        <p className={Styles.itemDescription}>{toolLabel.id}</p>
        <PrismTooltip title="Copy">
          <IconButton icon={<PrismCopyIcon />} type="tertiary" onClick={handleCopyClick} size="xsmall" />
        </PrismTooltip>
      </div>
    </LabelInfo>
  )
}

export default ToolLabelDetail
