import React from 'react'

import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { Group, Layer, Transformer } from 'react-konva'

import { info } from 'components/PrismMessage/PrismMessage'
import { AreaOfInterestConfiguration, Box, BoxWithShape, MaxBounds, PolygonShapeField } from 'types'
import { clipBoxInBounds } from 'utils'

import { MaskingRectangle, roundBox, scaleBox } from '../../Utils'
import { ACTIVE_STROKE_WIDTH, ANCHOR_SIZE, EditableShapeProps, HANDLE_STROKE_WIDTH } from '../EditableShape'
import { Point } from './Point'
import { recordPoint } from './PointController'
import { InertClosedBoundary, LiveBoundary, Polygon, Vertex } from './Polygon'

const DELTA = 1

/**
 * This captures all the states used for the EditablePolygon component along with the auxiliary data tracked in a given
 * state. Much of the behavior of the EditablePolygon component is controlled by branching on the cases of the 'tag'
 * field of an instance of EditData.
 */
type EditData =
  | { tag: 'incomplete' }
  | { tag: 'incomplete_placing_vertex'; point: Point; atEnd: boolean; prevPoints?: Point[] }
  // We may want to add an incomplete_vertex_selected state later. At the moment, if you have a completed triangle and
  // delete one vertex, you're left with a line segment and you are unable to delete any additional vertices directly
  // because when you click on a vertex you enter the incomplete_placing_vertex state. If we later allow users to delete
  // such a vertex we'll need to add a state to allow for it. Such a state would probably look like:
  //  `| { tag: 'incomplete_vertex_selected'; selected: number }`
  | { tag: 'complete'; mouseOver?: boolean }
  | { tag: 'complete_vertex_selected'; selected: number }
  | { tag: 'complete_vertex_dragging' }
  | { tag: 'complete_poly_selected'; mouseOver?: boolean }
  | { tag: 'complete_placing_boundary_vertex'; point: Point }

/**
 * Reports whether the polygon is currently incomplete
 * @param data
 * @returns Is the polygon currently incomplete?
 */
function isIncompletePolygon(data: EditData): boolean {
  return data.tag === 'incomplete' || data.tag === 'incomplete_placing_vertex'
}

/**
 * This captures all the state for the polygon editor.
 * @var data This is the main state for the state machine underlying the polygon editor
 * @var points The ground truth about the current vertices in the polygon
 * @var x The x-distance from its starting location the polygon has been dragged in the current dragging action
 * @var y The y-distance from its starting locatino the polygon has been dragged in the current dragging action
 * @var isDragging Are we currently dragging the polygon?
 */
type EditorState = {
  // We'll often set just the data field of the EditablePolygon state object. In order to ensure that we don't do a
  // shallow merge when we update the EditData properties, we add a layer of indirection and have the EditData object
  // nested within the EditorState object instead of extending EditData directly. If we were to do a shallow merge, we
  // could end up with, for example, {tag: 'complete', mouseOver: true, point: {...}, atEnd: false, ...} which would
  // eliminate the benefits of having disjoint states with distinct fields
  data: EditData
  points: Point[]
  x: number
  y: number
  isDragging: boolean
}

interface Props extends EditableShapeProps {
  showNotificationRef: React.MutableRefObject<boolean>
  shapeBorderColor: string
}

/**
 * This component renders a polygon that can be edited and built up from a single vertex.
 */
export default class EditablePolygon extends React.Component<Props, EditorState> {
  // A ref for the polygon Konva node.
  polygonRef: React.RefObject<Konva.Line>
  // A ref for the transformer used to resize the polygon
  transformerRef: React.RefObject<Konva.Transformer>
  // A callback called when the polygon changes
  onUpdateAoiWithShape: (aoi: AreaOfInterestConfiguration) => any
  // The aoi containing the polygon
  aoi: AreaOfInterestConfiguration
  // Is the polygon inactive, and unavailable for interaction
  isInactive?: boolean
  // The image size in pixels
  bounds: MaxBounds
  // The scaling from the image size to the client size
  scaling: number
  // A callback for when the polygon is selected
  onSelect: (aoi: AreaOfInterestConfiguration) => any
  // Have we disabled transforming the polygon?
  disableTransform?: boolean
  // A callback used to deselect this polygon and any others at the same time.
  deselectAois: (stage: Konva.Stage) => void

  constructor(props: Props) {
    super(props)
    const shape = props.aoi.shape as PolygonShapeField
    const points = (shape.data || []).map(pt => new Point(pt[0], pt[1]))
    this.bounds = props.bounds
    this.polygonRef = React.createRef<Konva.Line>()
    this.transformerRef = React.createRef<Konva.Transformer>()
    this.onUpdateAoiWithShape = props.onUpdateAoiWithShape
    this.onSelect = props.onSelect
    this.aoi = props.aoi
    this.isInactive = !!props.isInactive
    this.scaling = props.scaling
    this.deselectAois = props.deselectAois
    // If we begin with at least 3 points, we assume that represents a complete polygon. Otherwise we ignore all the
    // points passed in and just start a new polygon with a provisional vertex at the center of the image.
    this.state = {
      data:
        points.length >= 3
          ? { tag: this.isInactive ? 'complete' : 'complete_poly_selected' }
          : {
              tag: 'incomplete_placing_vertex',
              point: new Point((this.bounds.maxWidth / 2) * this.scaling, (this.bounds.maxHeight / 2) * this.scaling),
              atEnd: true,
            },
      points: this.toClientCoordinates(points, this.aoi),
      x: 0,
      y: 0,
      isDragging: false,
    }
    this.disableTransform = props.disableTransform

    if (points.length < 3 && props.showNotificationRef.current) {
      info({
        id: 'polygon-editor-few-points',
        title: 'Click to draw, hold Shift to free draw',
        position: 'top-center',
        duration: 3000,
        'data-testid': 'editable-polygon-draw-notification',
      })
      props.showNotificationRef.current = false
    }
  }

  /**
   * Takes points with relative coordinates in [0,1] and a box for an AOI and
   * outputs pixel coordinates on the screen
   */
  toClientCoordinates = (points: Point[], box: Box): Point[] => {
    const scaling = this.scaling
    return points.map(pt => {
      return new Point((box.x + box.width * pt.x) * scaling, (box.y + box.height * pt.y) * scaling)
    })
  }

  toExportAoi = (points: Point[]): BoxWithShape => {
    if (isIncompletePolygon(this.state.data)) {
      return { ...this.aoi, shape: { type: 'polygon', data: undefined } }
    }
    const scaledBox = this.polygonRef.current!.getClientRect()
    const unscaledBox = roundBox(scaleBox(scaledBox, 1 / this.scaling))

    const unscaledPoints = points.map(pt => new Point(pt.x / this.scaling, pt.y / this.scaling))
    const relativePoints = unscaledPoints.map(
      pt => new Point((pt.x - unscaledBox.x) / unscaledBox.width, (pt.y - unscaledBox.y) / unscaledBox.height),
    )
    const finalShape = { type: 'polygon', data: relativePoints.map(pt => [pt.x, pt.y]) } as PolygonShapeField
    return { ...unscaledBox, shape: finalShape }
  }

  getStage = (): Konva.Stage | undefined => {
    return this.transformerRef.current?.getStage() || undefined
  }

  componentDidMount = () => {
    if (this.isInactive) return
    this.getStage()?.on('click', this.onStageClick)
    this.getStage()?.on('mousemove', this.onStageMouseMove)
    window.addEventListener('keydown', this.onKeyDown)
    const transformer = this.transformerRef?.current
    const polygon = this.polygonRef.current as Konva.Line

    if (this.state.points.length >= 3) {
      transformer?.nodes([polygon])
      transformer?.getLayer()?.batchDraw()
    }

    if (this.state.data.tag === 'complete' || this.state.data.tag === 'complete_poly_selected') {
      const container = this.getStage()?.container()
      if (container) {
        container.style.cursor = this.state.data.mouseOver ? 'move' : 'default'
      }
    }
  }

  componentWillUnmount = () => {
    if (this.isInactive) return
    this.getStage()?.off('click', this.onStageClick)
    this.getStage()?.off('mousemove', this.onStageMouseMove)
    window.removeEventListener('keydown', this.onKeyDown)
  }

  componentDidUpdate = (_prevProps: EditableShapeProps, prevState: EditorState) => {
    const wasSelected = prevState.data.tag === 'complete_poly_selected'
    const isSelected = this.state.data.tag === 'complete_poly_selected'
    const transformer = this.transformerRef?.current
    const polygon = this.polygonRef.current as Konva.Line
    if (!wasSelected && isSelected) {
      transformer?.nodes([polygon])
      transformer?.getLayer()?.batchDraw()
    } else if (!isSelected && wasSelected) {
      transformer?.nodes([])
    }

    if (this.state.data.tag === 'complete' || this.state.data.tag === 'complete_poly_selected') {
      const container = this.getStage()?.container()
      if (container) {
        container.style.cursor = this.state.data.mouseOver ? 'move' : 'default'
      }
    }

    // Decide whether to call the onUpdateAoiWithShape handler
    const oldPoints = prevState.points
    const points = this.state.points
    const wasComplete = !isIncompletePolygon(prevState.data)
    const isCurrComplete = !isIncompletePolygon(this.state.data)
    var pointsChanged = false
    if (oldPoints.length !== points.length) {
      pointsChanged = true
    } else {
      for (var i = 0; i < points.length; i++) {
        if (points[i]!.x !== oldPoints[i]!.x || points[i]!.y !== oldPoints[i]!.y) {
          pointsChanged = true
        }
      }
    }

    // We'll only call the callback if the polygon switched completion status or at least one point moved and we're not
    // currently dragging the polygon
    if ((isCurrComplete !== wasComplete || pointsChanged) && !this.state.isDragging) {
      this.onUpdateAoiWithShape({ ...this.aoi, ...this.toExportAoi(points) })
    }
  }

  onVertexDragEnd = (ix: number, e: KonvaEventObject<DragEvent>) => {
    const points = this.state.points.slice()
    points[ix] = new Point(e.target.x(), e.target.y())
    this.setState({ data: { tag: 'complete' }, points: points })
  }

  onVertexDrag = (ix: number, e: KonvaEventObject<DragEvent>) => {
    const points = this.state.points.slice()
    points[ix] = new Point(e.target.x(), e.target.y())
    this.setState({ data: { tag: 'complete_vertex_dragging' }, points: points })
  }

  onVertexClick = (ix: number, e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true
    const data = this.state.data
    const points = this.state.points
    const point = Point.fromObj(e.target?.getStage()?.getPointerPosition() as Konva.Vector2d)
    switch (data.tag) {
      case 'complete':
      case 'complete_poly_selected':
        this.setState({ data: { tag: 'complete_vertex_selected', selected: ix } })
        break
      case 'complete_vertex_selected':
        this.setState({
          data: data.selected === ix ? { tag: 'complete' } : { tag: 'complete_vertex_selected', selected: ix },
        })
        break
      case 'incomplete':
        if (ix === 0 || ix === points.length - 1) {
          this.setState({
            points: points,
            data: {
              tag: 'incomplete_placing_vertex',
              point: point,
              atEnd: ix === points.length - 1,
            },
          })
          return
        }
        break
      case 'incomplete_placing_vertex':
        if ((data.atEnd && ix === 0) || (!data.atEnd && ix === points.length - 1)) {
          this.setState({
            points: points,
            data: { tag: 'complete_poly_selected', mouseOver: true },
          })
        }
        break
      default:
        // At the moment this is only the state 'complete_placing_boundary_vertex' and we can just ignore vertex clicks
        // in that case.
        return
    }
  }

  onPolygonDragMove = (e: KonvaEventObject<DragEvent>) => {
    const { x, y } = this.constrainDragToBounds({ x: e.target.x(), y: e.target.y() })
    e.target.x(x)
    e.target.y(y)
    this.setState({ x, y })
  }

  onPolygonDragEnd = () => {
    this.setState(state => {
      if (this.polygonRef.current) {
        const polygon = this.polygonRef.current!
        polygon.x(0)
        polygon.y(0)
      }
      const x = state.x
      const y = state.y
      const offset = new Point(x, y)
      const points = state.points.map(pt => pt.add(offset))
      return { x: 0, y: 0, points, isDragging: false }
    })
  }

  onPolygonDragStart = () => {
    this.setState({ isDragging: true })
  }

  onPolygonTransform = () => {
    const polygon = this.polygonRef.current!
    const matrix = polygon.getAbsoluteTransform()
    const points = this.state.points.map(pt => Point.fromObj(matrix.point(pt)))
    this.setState({ points }, () => {
      this.polygonRef.current?.x(0)
      this.polygonRef.current?.y(0)
      this.polygonRef.current?.scaleX(1)
      this.polygonRef.current?.scaleY(1)
    })
  }

  onPolygonMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (this.isInactive) {
      this.onSelect(this.aoi)
      // We need to stop propagation so that the stage doesn't receive a mousedown event and then deselect the polygon
      e.evt.stopPropagation()
    }
  }

  onPolygonClick = () => {
    const data = this.state.data
    switch (data.tag) {
      case 'complete':
        if (this.disableTransform) return
        this.setState({ data: { tag: 'complete_poly_selected', mouseOver: true } })
        break
      case 'complete_vertex_selected':
        this.setState({ data: { tag: 'complete_poly_selected', mouseOver: true } })
        break
      case 'complete_poly_selected':
        this.setState({ data: { tag: 'complete', mouseOver: true } })
        break
      default:
        return
    }
  }

  onPolygonMouseOver = () => {
    if (this.state.data.tag === 'complete' || this.state.data.tag === 'complete_poly_selected') {
      this.setState({ data: { tag: this.state.data.tag, mouseOver: true } })
    }
  }

  onPolygonMouseOut = () => {
    if (this.state.data.tag === 'complete' || this.state.data.tag === 'complete_poly_selected') {
      this.setState({ data: { tag: this.state.data.tag, mouseOver: false } })
    }
  }

  onStageClick = (e: KonvaEventObject<MouseEvent>) => {
    const data = this.state.data
    const points = this.state.points.slice()
    const point = Point.fromObj(this.getStage()?.getPointerPosition() as Konva.Vector2d)
    switch (data.tag) {
      case 'incomplete_placing_vertex':
        if (data.atEnd) {
          points.push(data.point)
        } else {
          points.unshift(data.point)
        }
        this.setState({ points: points, data: { tag: 'incomplete_placing_vertex', atEnd: data.atEnd, point: point } })
        break
      case 'complete_poly_selected':
      case 'complete':
      case 'complete_vertex_selected': {
        const stage = this.getStage()
        const targetIsStage = e.target === stage
        if (stage && targetIsStage) {
          // We'll de-select the polygon when we click on the stage if the polygon was selected.
          this.deselectAois(stage)
        }
        break
      }

      default:
        return
    }
  }

  onStageMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    e.cancelBubble = true
    const point = Point.fromObj(this.getStage()?.getPointerPosition() as Konva.Vector2d)
    const data = this.state.data
    const points = this.state.points.slice()
    if (data.tag === 'incomplete_placing_vertex') {
      if (e.evt.shiftKey) {
        // If we're in the free-drawing mode, we go ahead and drop points and update prevPoints according to
        // recordPoints.
        const [shouldPlacePoint, prevPoints] = recordPoint(point, data.prevPoints || [])
        if (shouldPlacePoint) {
          if (data.atEnd) {
            points.push(data.point)
          } else {
            points.unshift(data.point)
          }
        }
        // We also update the location of our cursor
        this.setState({
          points: points,
          data: { tag: 'incomplete_placing_vertex', point: point, atEnd: data.atEnd, prevPoints: prevPoints },
        })
      } else {
        this.setState({ data: { tag: 'incomplete_placing_vertex', point: point, atEnd: data.atEnd } })
      }
    }
  }

  onBoundaryHover = () => {
    if (this.disableTransform) return
    const tag = this.state.data.tag
    switch (tag) {
      case 'complete':
      case 'complete_placing_boundary_vertex':
        const pointer = this.getStage()?.getPointerPosition() || { x: 0, y: 0 }
        this.setState({ data: { tag: 'complete_placing_boundary_vertex', point: Point.fromObj(pointer) } })
        break
      default:
        return
    }
  }

  onBoundaryHoverEnd = () => {
    if (this.disableTransform) return
    const tag = this.state.data.tag
    switch (tag) {
      case 'complete_placing_boundary_vertex':
        this.setState({ data: { tag: 'complete' } })
        break
      default:
        return
    }
  }

  onBoundaryMouseDown = (ix: number) => {
    if (this.disableTransform) return
    const data = this.state.data
    switch (data.tag) {
      case 'complete_placing_boundary_vertex':
        const points = this.state.points.slice()
        points.splice(ix + 1, 0, data.point)
        this.setState({ data: { tag: 'complete' }, points: points })
        break
      default:
        return
    }
  }

  renderVertices = () => {
    if (this.disableTransform || this.isInactive || this.state.data.tag === 'complete_poly_selected') {
      return <></>
    }
    const { data, points, x, y } = this.state
    var vertices
    switch (data.tag) {
      case 'complete':
      case 'complete_placing_boundary_vertex':
      case 'complete_vertex_dragging':
      case 'complete_vertex_selected':
      case 'incomplete':
      case 'incomplete_placing_vertex':
        let selected: number | null = null
        if (data.tag === 'complete_vertex_selected') {
          selected = data.selected
        } else if (data.tag === 'incomplete_placing_vertex') {
          selected = data.atEnd ? points.length - 1 : 0
        }

        vertices = points.map((pt, ix) => {
          return (
            <Vertex
              point={pt.add(new Point(x, y))}
              key={ix.toString()}
              onDragMove={e => this.onVertexDrag(ix, e)}
              selected={selected === ix}
              onClick={e => this.onVertexClick(ix, e)}
              onDragEnd={e => this.onVertexDragEnd(ix, e)}
              scaledWidth={this.bounds.maxWidth * this.scaling}
              scaledHeight={this.bounds.maxHeight * this.scaling}
            />
          )
        })
        if (data.tag === 'complete_placing_boundary_vertex' || data.tag === 'incomplete_placing_vertex') {
          vertices.push(
            <Vertex
              point={data.point}
              provisional
              key={(-1).toString()}
              scaledWidth={this.bounds.maxWidth * this.scaling}
              scaledHeight={this.bounds.maxHeight * this.scaling}
            />,
          )
        }
        return <Group>{vertices}</Group>
      default:
        return
    }
  }

  constrainDragToBounds = ({ x, y }: { x: number; y: number }): { x: number; y: number } => {
    const { y: top, x: left, width, height } = this.polygonRef.current!.getClientRect()
    const maxHeight = this.bounds.maxHeight * this.scaling
    const maxWidth = this.bounds.maxWidth * this.scaling

    let deltaX = 0
    let deltaY = 0

    if (top < 0) {
      deltaY = -top
    } else if (top + height > maxHeight) {
      deltaY = maxHeight - top - height
    }

    if (left < 0) {
      deltaX = -left
    } else if (left + width > maxWidth) {
      deltaX = maxWidth - left - width
    }
    return { x: x + deltaX, y: y + deltaY }
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (document.activeElement?.nodeName === 'INPUT' || e.ctrlKey) return // This lets us write in the inputs
    e.preventDefault()
    const data = this.state.data
    const points = this.state.points.slice()
    // TODO: Refactor this function, it's pretty hard to read
    switch (data.tag) {
      case 'complete_vertex_selected':
        const selectedPoint = points[data.selected]
        if (selectedPoint) {
          switch (e.key) {
            case 'Backspace': // Backspace
            case 'Delete': // Delete
              points.splice(data.selected, 1)
              this.setState({
                points: points,
                data: { tag: points.length > 2 ? 'complete' : 'incomplete' },
              })
              break
            case 'ArrowLeft': {
              // Left arrow
              points[data.selected] = selectedPoint.clone()
              const clonedPoint = points[data.selected]
              if (clonedPoint) clonedPoint.x -= DELTA
              this.setState({
                points: points,
                data: { tag: 'complete_vertex_selected', selected: data.selected },
              })

              break
            }
            case 'ArrowUp': {
              // Up arrow
              points[data.selected] = selectedPoint.clone()
              const clonedPoint = points[data.selected]
              if (clonedPoint) clonedPoint.y -= DELTA
              this.setState({
                points: points,
                data: { tag: 'complete_vertex_selected', selected: data.selected },
              })
              break
            }
            case 'ArrowRight': {
              // Right arrow
              points[data.selected] = selectedPoint.clone()
              const clonedPoint = points[data.selected]
              if (clonedPoint) clonedPoint.x += DELTA
              this.setState({
                points: points,
                data: { tag: 'complete_vertex_selected', selected: data.selected },
              })
              break
            }
            case 'ArrowDown': {
              // Down arrow
              points[data.selected] = selectedPoint.clone()
              const clonedPoint = points[data.selected]
              if (clonedPoint) clonedPoint.y += DELTA
              this.setState({
                points: points,
                data: { tag: 'complete_vertex_selected', selected: data.selected },
              })
              break
            }
            default:
              return
          }
        }
        break
      case 'complete_poly_selected': {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          const point = Point.fromObj(this.getStage()?.getPointerPosition() as Konva.Vector2d)
          this.setState({ points: [], data: { tag: 'incomplete_placing_vertex', point: point, atEnd: true } })
        } else if (e.key === 'Escape') {
          const stage = this.getStage()
          if (stage) this.deselectAois(stage)
        }
        break
      }
      case 'complete':
        if (e.key === 'Escape') {
          const stage = this.getStage()
          if (stage) this.deselectAois(stage)
        }
        break
      case 'incomplete':
      case 'incomplete_placing_vertex': {
        if (e.key !== 'Escape') return
        const point = Point.fromObj(this.getStage()?.getPointerPosition() as Konva.Vector2d)
        this.setState({ points: [], data: { tag: 'incomplete_placing_vertex', point: point, atEnd: true } })
        break
      }
      default:
        return
    }
  }

  renderBoundary = () => {
    const data = this.state.data
    const x = this.state.x
    const y = this.state.y
    const offset = new Point(x, y)
    const points = this.state.points.slice().map(pt => pt.add(offset))
    if (this.disableTransform || data.tag === 'complete_poly_selected' || this.isInactive) {
      return (
        <InertClosedBoundary
          selected={data.tag === 'complete_poly_selected' || (!this.isInactive && !!this.disableTransform)}
          inactive={!!this.isInactive}
          mouseOver={data.tag === 'complete' && data.mouseOver}
          points={points}
        />
      )
    }

    if (data.tag === 'incomplete_placing_vertex') {
      const renderPoints = points.slice()
      if (data.atEnd) {
        renderPoints.push(data.point.add(offset))
      } else {
        renderPoints.unshift(data.point.add(offset))
      }
      return (
        <LiveBoundary
          points={renderPoints}
          closed={false}
          onHover={this.onBoundaryHover}
          onEndHover={this.onBoundaryHoverEnd}
          onMouseDown={this.onBoundaryMouseDown}
          selectedSegmentIx={data.atEnd ? renderPoints.length - 2 : 0}
        />
      )
    } else if (data.tag === 'incomplete') {
      return (
        <LiveBoundary
          points={points}
          closed={false}
          onHover={this.onBoundaryHover}
          onEndHover={this.onBoundaryHoverEnd}
          onMouseDown={this.onBoundaryMouseDown}
        />
      )
    } else {
      return (
        <LiveBoundary
          points={points}
          closed
          onHover={this.onBoundaryHover}
          onEndHover={this.onBoundaryHoverEnd}
          onMouseDown={this.onBoundaryMouseDown}
        />
      )
    }
  }

  render = () => {
    const { points, data } = this.state

    const outlineEditable = !this.disableTransform && !this.isInactive && data.tag !== 'complete_poly_selected'

    return (
      <>
        <Layer>
          {!this.isInactive && <MaskingRectangle bounds={this.bounds} scaling={this.scaling} />}
          {data.tag !== 'incomplete_placing_vertex' && data.tag !== 'incomplete' && (
            <Polygon
              points={points}
              onDragMove={this.onPolygonDragMove}
              onDragEnd={this.onPolygonDragEnd}
              onTransform={this.onPolygonTransform}
              onMouseDown={this.onPolygonMouseDown}
              onMouseOver={this.onPolygonMouseOver}
              onMouseOut={this.onPolygonMouseOut}
              onClick={this.onPolygonClick}
              ref={this.polygonRef}
            />
          )}
          {this.renderBoundary()}
          {outlineEditable && this.renderVertices()}
          {!this.isInactive && !this.disableTransform && (
            <Transformer
              ref={this.transformerRef}
              padding={0}
              rotateEnabled={false}
              flipEnabled={false}
              anchorStrokeWidth={HANDLE_STROKE_WIDTH}
              anchorStroke={this.props.shapeBorderColor}
              borderStroke={this.props.shapeBorderColor}
              borderStrokeWidth={ACTIVE_STROKE_WIDTH}
              anchorSize={ANCHOR_SIZE}
              boundBoxFunc={(_, newBox) =>
                clipBoxInBounds(newBox, {
                  maxWidth: this.bounds.maxWidth * this.scaling,
                  maxHeight: this.bounds.maxHeight * this.scaling,
                })
              }
            />
          )}
        </Layer>
      </>
    )
  }
}
