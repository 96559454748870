import { PartialToolLabel } from 'types'

export const GOOD_NORMAL_LABEL: PartialToolLabel = {
  value: 'normal',
  severity: 'good',
}

export const MINOR_ANOMALY_LABEL: PartialToolLabel = {
  value: 'anomaly',
  severity: 'minor',
}

export const CRITICAL_ANOMALY_LABEL: PartialToolLabel = {
  value: 'anomaly',
  severity: 'critical',
}

export const UNTRAINED_LABEL: PartialToolLabel = {
  value: 'untrained',
  severity: 'neutral',
}

export const CORRECT_MATCH_LABEL: PartialToolLabel = {
  value: 'correct_match',
  severity: 'good',
}

export const WRONG_MATCH_LABEL: PartialToolLabel = {
  value: 'wrong_match',
  severity: 'critical',
}

export const CORRECT_ALIGNMENT_LABEL: PartialToolLabel = {
  value: 'correct_alignment',
  severity: 'good',
}

export const WRONG_ALIGNMENT_LABEL: PartialToolLabel = {
  value: 'wrong_alignment',
  severity: 'critical',
}

export const CORRECT_BARCODE_LABEL: PartialToolLabel = {
  value: 'correct_barcode',
  severity: 'good',
}

export const NO_BARCODE_LABEL: PartialToolLabel = {
  value: 'no_barcode',
  severity: 'critical',
}

export const WRONG_BARCODE_LABEL: PartialToolLabel = {
  value: 'wrong_barcode',
  severity: 'critical',
}

export const CORRECT_COLOR_LABEL: PartialToolLabel = {
  value: 'correct_color',
  severity: 'good',
}

export const WRONG_COLOR_LABEL: PartialToolLabel = {
  value: 'wrong_color',
  severity: 'critical',
}

export const CORRECT_SIZE_LABEL: PartialToolLabel = {
  value: 'correct_size',
  severity: 'good',
}

export const WRONG_SIZE_LABEL: PartialToolLabel = {
  value: 'wrong_size',
  severity: 'critical',
}

export const CORRECT_TEXT_LABEL: PartialToolLabel = {
  value: 'correct_text',
  severity: 'good',
}

export const NO_TEXT_LABEL: PartialToolLabel = {
  value: 'no_text',
  severity: 'critical',
}

export const WRONG_TEXT_LABEL: PartialToolLabel = {
  value: 'wrong_text',
  severity: 'critical',
}

export const DISCARD_LABEL: PartialToolLabel = {
  value: 'discard',
  severity: 'neutral',
}

export const UNCERTAIN_LABEL: PartialToolLabel = {
  value: 'uncertain',
  severity: 'neutral',
}

export const TEST_SET_LABEL: PartialToolLabel = {
  value: 'test_set',
  severity: 'neutral',
}
